import { useHistory } from "react-router-dom"
import { useModal } from "../../../../../../../utils/hook/useModal"
import { useForm } from "antd/lib/form/Form"
import { useState } from "react"
import { ContactFormDataType } from "../../../../models/contact"

export const useVariables = () => {
  const { push } = useHistory()
  const [form] = useForm()
  const {
    isOpen: isOpenConfirm,
    onCancel: onCancelConfirm,
    onOpen: onOpenConfirm,
  } = useModal()
  const {
    isOpen: isOpenClear,
    onCancel: onCancelClear,
    onOpen: onOpenClear,
  } = useModal()
  const [values, setValues] = useState<ContactFormDataType>()
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)

  return {
    form,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isOpenClear,
    isOpenConfirm,
    onCancelClear,
    onCancelConfirm,
    onOpenClear,
    onOpenConfirm,
    push,
    setIsDisabledSubmit,
    setIsLoading,
    setIsLoadingLayout,
    setValues,
    values,
  }
}
