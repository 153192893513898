import { useEffect } from "react"
import { Routers } from "../../../../../../../constant/Routers"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { AttentionTravelersDataType } from "../../../../models/attention"
import { AttentionSuccessType } from "../../../../models/success"
import { useVariables } from "../useVariables"

const { SCR920 } = Routers

export const useAttentionsSuccess =
  (): AttentionSuccessType<AttentionTravelersDataType> => {
    const {
      columns,
      isAttentionsSendAll,
      isCommonRefer,
      isLoadingLayout,
      push,
      safetiesSelected,
      setIsLoadingLayout,
      setTransmissionInfo,
      setTravelerInfoList,
      state,
      transmissionInfo,
      travelerInfoList,
    } = useVariables()

    const goToHome = () => {
      //「SCR920_ホ－ム画面」に遷移する。
      push(SCR920)
    }

    useEffect(() => {
      // ユ－ザ－の画面アクセス許可チェックを処理する。
      if (!isAttentionsSendAll && !isCommonRefer) {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const { pathname, state } = backToLogin()
        push({ pathname, state })
      } else {
        if (state) {
          //「travelerInfoList」オブジェクトを初期化する。
          setTravelerInfoList(state.travelerInfoList)

          // 「transmissionInfo」オブジェクトの値を更新する。
          setTransmissionInfo(state.transmissionInfo)
        }
        setTimeout(() => {
          setIsLoadingLayout(false)
        }, 500)
      }
    }, [])

    return {
      columns,
      goToHome,
      isCommonRefer,
      isLoadingLayout,
      safetiesSelected,
      transmissionInfo,
      travelerInfoList,
    }
  }
