import { useEffect } from "react"
import { Routers } from "../../../../../../../constant/Routers"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import {
  UseSafetiesAnswerType,
  SafetiesAnswerFormType,
} from "../../../../models/answer"
import { useVariables } from "../useVariables"

const { SCR419 } = Routers

export const useSafetiesAnswer =
  (): UseSafetiesAnswerType<SafetiesAnswerFormType> => {
    const {
      breadcrumbItems,
      form,
      gridItems,
      handleGetSafetyDetail,
      isCommonTravelerRefer,
      isHiddenSubmit,
      isLoading,
      isLoadingLayout,
      isRoleAdmin,
      onFinish,
      push,
      safetyDetailInfo,
      state,
      titleHeading,
    } = useVariables()

    const onGoBack = () => {
      if (state) {
        push({
          pathname: state.sourceURL,
          state: {
            ...state,
            sourceURL: SCR419,
          },
        })
      }
    }

    useEffect(() => {
      if (!isCommonTravelerRefer) {
        const { pathname, state } = backToLogin()
        push({ pathname, state })
      } else {
        handleGetSafetyDetail()
      }
    }, [])

    return {
      breadcrumbItems,
      form,
      gridItems,
      isHiddenSubmit,
      isLoading,
      isLoadingLayout,
      isRoleAdmin,
      onFinish,
      onGoBack,
      safetyDetailInfo,
      titleHeading,
    }
  }
