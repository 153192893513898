import { FC } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { ButtonGroup } from "../../../../../../_webui/layout/components/Button/Group"
import { Card } from "../../../../../../_webui/layout/components/Card"
import { Table } from "../../../../../../_webui/layout/components/Table"
import { TotalRecords } from "../../../../../../_webui/layout/components/Typography/TotalRecords"
import { AttentionsTableProps } from "../../../models/list"

const { TEXT_T331, TEXT_T332, TEXT_T336, TEXT_T334, TEXT_T335 } = Translate

export const AttentionsTable: FC<AttentionsTableProps> = (props) => {
  const {
    attentionInfoList,
    columns,
    goToAttentionsDetail,
    goToAttentionsInfo,
    isDisabledDetailBtn,
    isDisabledResponseBtn,
    isDisabledResponseHistoryBtn,
    isHiddenDetailBtn,
    isHiddenResponseBtn,
    isHiddenResponseHistoryBtn,
    isLoading,
    onChange,
    onOpen,
    perItem,
    rowSelection,
  } = props

  return (
    <Card
      heading={TEXT_T331}
      isBackground
      isLoading={isLoading}
      isSearch
      isSearchIcon
      onClick={onOpen}
      searchText={TEXT_T332}
      type="ghost"
    >
      <TotalRecords perItem={perItem} total={attentionInfoList?.length} />
      <Table
        columns={columns}
        dataSource={attentionInfoList}
        onChange={onChange}
        rowKey="id"
        rowSelection={rowSelection}
      />
      <ButtonGroup
        buttonItems={[
          {
            isDisabled: isDisabledResponseBtn,
            isHidden: !isHiddenResponseBtn,
            onClick: goToAttentionsInfo,
            text: TEXT_T334,
            type: "primary",
          },
          {
            isDisabled: isDisabledResponseHistoryBtn,
            isHidden: !isHiddenResponseHistoryBtn,
            onClick: () => goToAttentionsDetail(true),
            text: TEXT_T335,
            type: "primary",
          },
          {
            isDisabled: isDisabledDetailBtn,
            isHidden: !isHiddenDetailBtn,
            onClick: () => goToAttentionsDetail(false),
            text: TEXT_T336,
            type: "primary",
          },
        ]}
      />
    </Card>
  )
}
