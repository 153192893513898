import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const { isCommonTravelerRefer, isRoleAdmin, isRoleUser, isRoleSubAdmin } =
    useAuthority()

  return {
    isCommonTravelerRefer,
    isRoleAdmin,
    isRoleSubAdmin,
    isRoleUser,
  }
}
