/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Keycloak from "keycloak-js"
import { Environment } from "../../constant/Environment"

const { CLIENT_ID, ON_LOAD, PKCE_METHOD, REALM, URL } = Environment.KEYCLOAK

const initOptions = {
  clientId: CLIENT_ID!,
  onLoad: ON_LOAD,
  pkceMethod: PKCE_METHOD,
  realm: REALM!,
  url: URL,
}

const keycloak = new Keycloak(initOptions)

export default keycloak
