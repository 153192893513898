import { FC } from "react"
import { useSystemsManual } from "./modules/useSystemsManual"
import { SystemsManualButtonGroup } from "../../components/manual/ButtonGroup"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { TotalRecords } from "../../../../../_webui/layout/components/Typography/TotalRecords"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Table } from "../../../../../_webui/layout/components/Table"
import { Translate } from "../../../../../constant/Translate"
import { Modal } from "../../../../../_webui/layout/components/Modal"

const { MESSAGE_C0052, TEXT_T189 } = Translate

export const SystemsManualList: FC = () => {
  const {
    breadcrumbItems,
    columns,
    dataSource,
    goToHome,
    handleDownload,
    handlePreviewFile,
    isDisabledBtnDownload,
    isDisabledBtnPreview,
    isLoading,
    isLoadingLayout,
    isOpenPreviewFile,
    isVisibleBtn,
    layoutTitle,
    onCancelPreviewFile,
    onChangePagination,
    onChangeTable,
    perItem,
    rowSelection,
  } = useSystemsManual()

  return (
    <Layout
      breadcrumbItems={breadcrumbItems}
      isLoadingLayout={isLoadingLayout}
      title={layoutTitle}
    >
      <Card heading={layoutTitle} isLoading={isLoading}>
        <TotalRecords perItem={perItem} total={dataSource?.length} />
        <Table
          columns={columns}
          dataSource={dataSource}
          onChange={onChangePagination}
          onChangeTable={onChangeTable}
          rowKey="uploadId"
          rowSelection={rowSelection}
        />
        <SystemsManualButtonGroup
          goToHome={goToHome}
          handleDownload={handleDownload}
          handlePreviewFile={handlePreviewFile}
          isDisabledBtnDownload={isDisabledBtnDownload}
          isDisabledBtnPreview={isDisabledBtnPreview}
          isVisibleBtn={isVisibleBtn}
        />
        <Modal
          isCenter
          isOpen={isOpenPreviewFile}
          okText={TEXT_T189}
          onOk={onCancelPreviewFile}
        >
          {MESSAGE_C0052}
        </Modal>
      </Card>
    </Layout>
  )
}
