import { useEffect } from "react"
import { Routers } from "../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { safetyApi } from "../../../../apis"
import {
  SafetiesCloneDataType,
  SafetiesCloneResType,
  UseSafetiesCloneType,
} from "../../../../models/clone"
import { useVariables } from "../useVariables"
import { convertDataSource } from "./convertDataSource"

const { SCR412, SCR413, SCR973, SCR962 } = Routers

export const useSafetiesClone =
  (): UseSafetiesCloneType<SafetiesCloneDataType> => {
    const {
      columns,
      dataSource,
      isDisabledBtnCopy,
      isHiddenBtnBack,
      isHiddenBtnCopy,
      isLoadingLayout,
      isSafetiesSendAll,
      onChange,
      onChangeTable,
      pathname,
      perItem,
      push,
      rowSelection,
      selectedRowKeys,
      setDataSource,
      setIsLoadingLayout,
      state,
    } = useVariables()

    // 「SCR412_安否確認の内容確認画面」に戻る。
    const onClickBack = () => {
      push(SCR412, { ...state, sourceURL: pathname })
    }

    // 「SCR413_安否確認の内容変更画面」に遷移する。
    const onClickCopy = () => {
      const transmissionInfo = dataSource.find(
        (item) => item.safetyId === selectedRowKeys[0],
      )
      if (transmissionInfo) {
        push(SCR413, {
          ...state,
          sourceURL: SCR962,
          transmissionInfo,
        })
      }
    }

    useEffect(() => {
      // ユーザの画面アクセス許可チェックを処理する。
      if (!isSafetiesSendAll) {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const { pathname, state } = backToLogin()
        push({ pathname, state })
      } else {
        // 安否確認情報を取得する。
        safetyApi
          .getSafeties({ isMenuSubAdmin: true })
          .then((res: SafetiesCloneResType[]) => {
            const data = convertDataSource(res)
            setDataSource(data)
            setIsLoadingLayout(false)
          })
          .catch((error: AxiosErrorType) => {
            // 「SCR973_エラー情報の表示画面」に遷移する。
            const stateError = pushStateError(error)
            push({ pathname: SCR973, state: stateError })
          })
      }
    }, [])

    return {
      columns,
      dataSource,
      isDisabledBtnCopy,
      isHiddenBtnBack,
      isHiddenBtnCopy,
      isLoadingLayout,
      onChange,
      onChangeTable,
      onClickBack,
      onClickCopy,
      perItem,
      rowSelection,
    }
  }
