import styled from "styled-components"
import { Title } from "../../../../../../../_webui/layout/components/Typography/Title"

const StyledTitle = styled(Title)`
  width: 100%;
  margin-right: 15px;
  margin-bottom: 15px;
`
const StyledTitle2 = styled(StyledTitle)`
  padding-right: 15px;
`

export const Styled = {
  StyledTitle,
  StyledTitle2,
}
