import { PushStateErrorType } from "../../models"

const INITIAL_VALUES_STATE_ERROR: PushStateErrorType = {
  message: "",
  textButton: "",
  title: "",
  url: "",
}

export const initialValues = {
  INITIAL_VALUES_STATE_ERROR,
}
