import { Constants } from "./constants"

const { HEADING_FOCUS_ROUTERS } = Constants

export const focusBreadcrumbs = () => {
  setTimeout(() => {
    const isRouter = HEADING_FOCUS_ROUTERS.some((item) =>
      window.location.pathname.includes(item),
    )
    if (isRouter) focusHeading()
    else {
      const elmBreadcrumbs = document.getElementById("breadcrumbs")
      if (elmBreadcrumbs) elmBreadcrumbs.focus()
    }
  }, 500)
}

export const focusHeading = () => {
  setTimeout(() => {
    const elmHeading = document.querySelector(
      ".card-heading-text",
    ) as HTMLDivElement
    if (elmHeading) elmHeading.focus()
  }, 500)
}

export const focusPreviousElement = (id: string) => {
  setTimeout(() => {
    const elmButton = document.getElementById(id)
    if (elmButton) elmButton.focus()
  }, 500)
}
