import { Form } from "antd"
import { useState } from "react"
import { ItemInput } from "../../../../../../../_webui/layout/components/Form/Item/Input"
import { ItemSelect } from "../../../../../../../_webui/layout/components/Form/Item/Select"
import { ItemTextArea } from "../../../../../../../_webui/layout/components/Form/Item/TextArea"
import { GridItemType } from "../../../../../../../_webui/layout/components/Grid/models"
import { Tooltip } from "../../../../../../../_webui/layout/components/Tooltip"
import { GeneralCode } from "../../../../../../../constant/GeneralCode"
import { Translate } from "../../../../../../../constant/Translate"
import { TranslateHelp } from "../../../../../../../constant/Translate/Help"
import { getGeneralMasters } from "../../../../../../../utils/apis/getGeneralMasters"
import { validatorRequiredField } from "../../../../../../../utils/validator/requiredField"
import { AttentionsEditCategory } from "../../../../components/edit/Category"

const { TEXT_T312, TEXT_T314, TEXT_T315, TEXT_T316 } = Translate
const { HELP_H0028 } = TranslateHelp
const { CTGR, IPTC, CTGR_OTHER } = GeneralCode

export const useGridConfig = () => {
  // APIを呼び出し、「カテゴリ」コンボボックスのデータを取得する。
  const { generalMasters: categoryList } = getGeneralMasters(CTGR)
  const { generalMasters: severityList } = getGeneralMasters(IPTC)
  const [form] = Form.useForm()
  const [isShowCategoryOther, setIsShowCategoryOther] = useState(false)

  const onChangeCategory = (value?: string) => {
    form.setFieldsValue({ categoryId: value, categoryValue: "" })

    if (value === CTGR_OTHER) {
      setIsShowCategoryOther(true)
    } else {
      setIsShowCategoryOther(false)
    }
  }

  const gridItems: GridItemType[] = [
    {
      key: <label htmlFor="categoryName">{TEXT_T312}</label>,
      text: (
        <AttentionsEditCategory
          categoryList={categoryList}
          isShowCategoryOther={isShowCategoryOther}
          onChangeCategory={onChangeCategory}
        />
      ),
    },
    {
      key: <label htmlFor="severity">{TEXT_T314}</label>,
      text: (
        <ItemSelect
          name="severity"
          rules={[validatorRequiredField]}
          selectItem={severityList}
        />
      ),
    },
    {
      key: (
        <Tooltip title={HELP_H0028}>
          <label htmlFor="attentionTitle">{TEXT_T315}</label>
        </Tooltip>
      ),
      text: (
        <ItemInput
          maxLength={50}
          name="attentionTitle"
          rules={[validatorRequiredField]}
        />
      ),
    },
    {
      key: <label htmlFor="bodyContent">{TEXT_T316}</label>,
      text: (
        <ItemTextArea
          name="bodyContent"
          maxLength={10000}
          required
          rules={[validatorRequiredField]}
          rows={7}
        />
      ),
    },
  ]

  return {
    categoryList,
    form,
    gridItems,
    isShowCategoryOther,
    setIsShowCategoryOther,
    severityList,
  }
}
