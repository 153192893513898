import { FC } from "react"
import { Button } from "../../../../../_webui/layout/components/Button"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Center } from "../../../../../_webui/layout/components/Center"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { Translate } from "../../../../../constant/Translate"
import { useLogout } from "./modules/useLogout"
import { Styled } from "./styled"

const { StyledTitle } = Styled
const { TEXT_T420, TEXT_T421, TEXT_T422 } = Translate

export const Logout: FC = () => {
  const { handleLogin, isLoadingLayout } = useLogout()

  return (
    <Layout
      isHeaderRightNone
      isLoadingLayout={isLoadingLayout}
      isSideBar={false}
      title={TEXT_T420}
    >
      <Card heading={TEXT_T420} isBreadcrumbs={false}>
        <StyledTitle titleLeft={TEXT_T421} />
        <Center>
          <Button onClick={handleLogin} text={TEXT_T422} type="ghost" />
        </Center>
      </Card>
    </Layout>
  )
}
