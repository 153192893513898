import { FC, Fragment } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { ButtonGroup } from "../../../../../../_webui/layout/components/Button/Group"
import { Title } from "../../../../../../_webui/layout/components/Typography/Title"
import { SafetiesTitleProps } from "../../../models/safety"
import { PreLine } from "../../../../../../_webui/layout/components/Typography/PreLine"

const { TEXT_T310, TEXT_T311, TEXT_T362, TEXT_T360, TEXT_T366 } = Translate

export const SafetiesTitle: FC<SafetiesTitleProps> = (props) => {
  const { goToSafetiesClone, goToSafetiesEdit, isHiddenCopyBtn } = props

  return (
    <Fragment>
      <Title titleLeft={<PreLine>{TEXT_T362}</PreLine>} />
      <Title titleLeft={TEXT_T360} />
      <Title
        alignItems="flex-end"
        titleLeft={TEXT_T366}
        titleRight={
          <ButtonGroup
            isMargin={false}
            buttonItems={[
              {
                isHidden: isHiddenCopyBtn,
                onClick: goToSafetiesClone,
                order: 1,
                text: TEXT_T310,
                type: "default",
              },
              {
                isDisabled: isHiddenCopyBtn,
                onClick: goToSafetiesEdit,
                order: 2,
                text: TEXT_T311,
                type: "default",
              },
            ]}
          />
        }
      />
    </Fragment>
  )
}
