/* eslint-disable complexity */
import { Regex } from "../../../../../../../constant/Regex"
import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

const { REGEX_IS_DESKTOP } = Regex

export const useVariablesAuthority = (
  id: string,
  isFromSCR114UserSearch: boolean,
  isFromSCR211: boolean,
  isSubBumon: boolean,
  travelerId?: string,
) => {
  const {
    isCommonTravelerRefer,
    isRoleAdmin,
    isRoleSubAdmin,
    isRoleUser,
    isScheduleSearch,
    isTravelerChange,
    isTravelerDelete,
    isTravelerRefer,
    isTravelerSearch,
  } = useAuthority()

  const isFromMenu = id === travelerId
  const isNotAccessScreen =
    (isFromMenu && !isCommonTravelerRefer) || (!isFromMenu && !isTravelerRefer)
  const isSubBumonOrFromSCR211 = isSubBumon || isFromSCR211

  //ロール条件に応じてボタンと項目を画面に表示する。
  const isHiddenBtn = isRoleAdmin || isRoleSubAdmin || isRoleUser

  const isHiddenBtnEdit =
    isHiddenBtn &&
    ((isFromMenu && isCommonTravelerRefer) ||
      (!isFromMenu && isTravelerChange)) &&
    isSubBumonOrFromSCR211 &&
    !isFromSCR114UserSearch

  const isHiddenBtnSchedules =
    isHiddenBtn &&
    ((isFromMenu && isCommonTravelerRefer) ||
      (!isFromMenu && isScheduleSearch)) &&
    isSubBumonOrFromSCR211

  const isHiddenBtnBack =
    isHiddenBtn &&
    ((isFromMenu && isCommonTravelerRefer) || (!isFromMenu && isTravelerSearch))

  const isHiddenBtnRequestDelete =
    !isFromMenu &&
    !isFromSCR114UserSearch &&
    isRoleSubAdmin &&
    isTravelerDelete &&
    isSubBumonOrFromSCR211

  const isHiddenBtnDelete =
    !isFromMenu &&
    !isFromSCR114UserSearch &&
    isRoleAdmin &&
    isTravelerDelete &&
    REGEX_IS_DESKTOP

  return {
    isHiddenBtnBack,
    isHiddenBtnDelete,
    isHiddenBtnEdit,
    isHiddenBtnRequestDelete,
    isHiddenBtnSchedules,
    isNotAccessScreen,
    isRoleAdmin,
    isRoleSubAdmin,
  }
}
