import { FC } from "react"
import { Grid } from "../../../../../../_webui/layout/components/Grid"
import { Translate } from "../../../../../../constant/Translate"
import { TravelersEditSuccessCollapseProps } from "../../../models/editSuccess"
import { CollapsePersonalInfo } from "../../detail/Collapse/PersonalInfo"
import { Styled } from "../../edit/Collapse/styled"

const { StyledCollapse } = Styled
const {
  TEXT_T520,
  TEXT_T522,
  TEXT_T523,
  TEXT_T524,
  TEXT_T525,
  TEXT_T526,
  TEXT_T527,
  TEXT_T528,
} = Translate

export const TravelersEditSuccessCollapse: FC<
  TravelersEditSuccessCollapseProps
> = (props) => {
  const {
    assignmentInfor,
    columns,
    contactJapan,
    contactPerson,
    insuranceInfor,
    isLoading,
    numberOfPeople,
    postingInfor,
    relationshipInfo,
    spouseInfor,
    trainingInfor,
    travelerDetail,
  } = props

  return (
    <StyledCollapse
      defaultActiveKey={["1", "2", "3", "4", "5", "6", "7", "8"]}
      panelItems={[
        {
          content: (
            <CollapsePersonalInfo
              relationshipInfo={relationshipInfo}
              columns={columns}
              isLoading={isLoading}
              numberOfPeople={numberOfPeople}
              travelerDetail={travelerDetail}
            />
          ),
          header: TEXT_T520,
          key: 1,
        },
        {
          content: <Grid gridItems={contactJapan} />,
          header: TEXT_T522,
          key: 2,
        },
        {
          content: <Grid gridItems={contactPerson} />,
          header: TEXT_T523,
          key: 3,
        },
        {
          content: <Grid gridItems={postingInfor} />,
          header: TEXT_T524,
          key: 4,
        },
        {
          content: <Grid gridItems={assignmentInfor} />,
          header: TEXT_T525,
          key: 5,
        },
        {
          content: <Grid gridItems={insuranceInfor} />,
          header: TEXT_T526,
          key: 6,
        },
        {
          content: <Grid gridItems={trainingInfor} />,
          header: TEXT_T527,
          key: 7,
        },
        {
          content: <Grid gridItems={spouseInfor} />,
          header: TEXT_T528,
          key: 8,
        },
      ]}
    />
  )
}
