import { FC } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { Button } from "../../../../../../_webui/layout/components/Button"
import { Space } from "../../../../../../_webui/layout/components/Space"
import { Table } from "../../../../../../_webui/layout/components/Table"
import { Title } from "../../../../../../_webui/layout/components/Typography/Title"
import { AttentionTravelersGroupProps } from "../../../models/attention"
import { Styled } from "./styled"
import { AttentionButtonGroup } from "../ButtonGroup"
import { Frame } from "../../../../../../_webui/layout/components/Frame"

const { StyledTitle } = Styled
const { TEXT_T265, TEXT_T317, TEXT_T318, TEXT_T319 } = Translate

export const AttentionTravelersGroup: FC<AttentionTravelersGroupProps> = (
  props,
) => {
  const {
    columns,
    isDisabledSubmit,
    isHiddenBackBtn,
    isHiddenResetBtn,
    isHiddenSubmitBtn,
    isLoading,
    onGoBack,
    onOpen,
    onOpenForm,
    onReset,
    safetiesSelected,
    travelerInfoList,
  } = props

  return (
    <Space isSpaceBottom isSpaceTop>
      <Title titleLeft={TEXT_T317} />
      <StyledTitle
        type="danger"
        alignItems="flex-end"
        titleLeft={TEXT_T318}
        isTitleRightFullWidth
        titleRight={
          <Button
            onClick={onReset}
            text={TEXT_T319}
            isHidden={isHiddenResetBtn}
            isFullWidth
          />
        }
      />
      <Table
        columns={columns}
        dataSource={travelerInfoList}
        pagination={false}
        isLoading={isLoading}
        rowKey="travelScheduleId"
      />
      {safetiesSelected && (
        <Frame titleLeft={TEXT_T265}>{safetiesSelected}</Frame>
      )}
      <AttentionButtonGroup
        isDisabledSubmit={isDisabledSubmit}
        isHiddenBackBtn={isHiddenBackBtn}
        isHiddenSubmitBtn={isHiddenSubmitBtn}
        onGoBack={onGoBack}
        onOpen={onOpen}
        onOpenForm={onOpenForm}
      />
    </Space>
  )
}
