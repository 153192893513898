import {
  TravelersDetailCollapseType,
  TravelersDetailResType,
} from "../../../../../models/detail"
import { convertDataAccompanies } from "./accompanies"
import { convertDataBase } from "./base"
import { convertDataDetail } from "./detail"

export const convertData = (
  data: TravelersDetailResType,
  isRoleAdmin: boolean,
  isRoleSubAdmin: boolean,
): TravelersDetailCollapseType => {
  const { base, detail, accompanies } = data
  const dataAccompanies = convertDataAccompanies(accompanies)
  const dataBase = convertDataBase(base)
  const dataDetail = convertDataDetail(
    detail,
    base.dispatchTypeDetailId,
    base.updateDate,
    isRoleAdmin,
    isRoleSubAdmin,
  )
  return {
    ...dataAccompanies,
    ...dataBase,
    ...dataDetail,
  }
}
