import { Translate } from "../../../../../../../../../constant/Translate"
import { getFullName } from "../../../../../../../../../utils/getFullName"
import {
  TravelersEditSuccessAccompaniesType,
  TravelersEditSuccessCollapseAccompaniesType,
} from "../../../../../../models/editSuccess"

const { TEXT_T044, TEXT_T067 } = Translate

export const convertDataAccompanies = (
  data: TravelersEditSuccessAccompaniesType[],
): TravelersEditSuccessCollapseAccompaniesType => ({
  numberOfPeople: [{ key: TEXT_T044, text: `${data.length}${TEXT_T067}` }],
  relationshipInfo: data.map((item, index) => ({
    familyFullNameRomaji: getFullName(
      item.familyRomajiSurname,
      item.familyRomajiName,
    ),
    familyRelationshipName: item.familyRelationshipName,
    no: `${index + 1}`,
  })),
})
