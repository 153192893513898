import { Key, useState } from "react"
import { useTable } from "../../../../../../../utils/hook/useTable"
import { AttentionsListType } from "../../../../models/list"

export const useVariablesRowSelection = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [currentData, setCurrentData] = useState<AttentionsListType[]>([])
  const [isDisabledDetailBtn, setIsDisabledDetailBtn] = useState(true)
  const [isDisabledResponseBtn, setIsDisabledResponseBtn] = useState(true)
  const [isDisabledResponseHistoryBtn, setIsDisabledResponseHistoryBtn] =
    useState(true)
  const [attentionId, setAttentionId] = useState("")
  const [attentionDetailId, setAttentionDetailId] = useState("")
  const { onChange: onChangePage, perItem } = useTable(currentData.length)

  return {
    attentionDetailId,
    attentionId,
    currentData,
    isDisabledDetailBtn,
    isDisabledResponseBtn,
    isDisabledResponseHistoryBtn,
    onChangePage,
    perItem,
    selectedRowKeys,
    setAttentionDetailId,
    setAttentionId,
    setCurrentData,
    setIsDisabledDetailBtn,
    setIsDisabledResponseBtn,
    setIsDisabledResponseHistoryBtn,
    setSelectedRowKeys,
  }
}
