import { Endpoint } from "../../../constant/Endpoint"
import { Environment } from "../../../constant/Environment"
import { replaceStringParam } from "../../../utils/replaceStringParam"
import { apiService } from "../../apiService"

const { COMMON_ENDPOINT } = Environment
const { FNC9_Get_All_Countries, FNC9_Get_Cities } = Endpoint

export const countriesApi = {
  getAllCountries: () =>
    apiService({
      method: "GET",
      url: `${COMMON_ENDPOINT}${FNC9_Get_All_Countries}`,
    }),
  getCities: (countryCode: string) =>
    apiService({
      method: "GET",
      url: `${COMMON_ENDPOINT}${replaceStringParam(
        FNC9_Get_Cities,
        countryCode,
      )}`,
    }),
  getCitiesByCountry: (countryCode: string) =>
    apiService({
      method: "GET",
      url: `${COMMON_ENDPOINT}${`${FNC9_Get_Cities}?countryCode=${countryCode}`}`,
    }),
}
