import styled from "styled-components"
import { Collapse } from "../../../../../../../_webui/layout/components/Collapse"

const StyledCollapse = styled(Collapse)`
  .ant-collapse-item:first-child {
    margin-top: 15px;
  }
`

export const Styled = {
  StyledCollapse,
}
