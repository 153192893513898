import { Form } from "antd"
import { FC } from "react"
import { Translate } from "../../../../../../constant/Translate"
import {
  TravelersEditButtonSubmitProps,
  TravelersEditFormDataType,
} from "../../../models/edit"
import { ButtonGroup } from "../../../../../../_webui/layout/components/Button/Group"

const { Item } = Form
const { TEXT_T012, TEXT_T013, TEXT_T390 } = Translate

export const TravelersEditButtonSubmit: FC<
  TravelersEditButtonSubmitProps<TravelersEditFormDataType>
> = (props) => {
  const {
    form,
    goToTravelersDetailOrAddSuccess,
    isDisabledSubmit,
    isHiddenBtnCancel,
    isHiddenBtnSubmit,
    onOpenTravelerDraft,
  } = props

  return (
    <Item shouldUpdate className="ant-form-btn-submit">
      {() => {
        const _isDisabledSubmit =
          isDisabledSubmit ||
          (form &&
            !!form.getFieldsError().filter(({ errors }) => errors.length)
              .length)
        return (
          <ButtonGroup
            buttonItems={[
              {
                htmlType: "reset",
                isHidden: !isHiddenBtnCancel,
                onClick: goToTravelersDetailOrAddSuccess,
                order: 3,
                text: TEXT_T012,
                type: "ghost",
              },
              {
                htmlType: "submit",
                isDisabled: _isDisabledSubmit,
                isHidden: !isHiddenBtnSubmit,
                order: 1,
                text: TEXT_T013,
                type: "primary",
              },
              {
                absolutePosition: "right",
                htmlType: "button",
                isAbsolute: true,
                isHidden: !_isDisabledSubmit,
                onClick: onOpenTravelerDraft,
                order: 2,
                text: TEXT_T390,
                type: "warning",
              },
            ]}
          />
        )
      }}
    </Item>
  )
}
