import { useState } from "react"
import { exportCsv } from "../../../../../../../utils/exportCsv"
import { UseAttentionsDetailExportFileType } from "../../../../models/detail"
import { convertDataExport } from "./convertDataExport"
import { Translate } from "../../../../../../../constant/Translate"

const { EXPORT_EP0195 } = Translate

export const useExportFile = (props: UseAttentionsDetailExportFileType) => {
  const { attentionInfo, selectedRowKeys, userInfoList } = props
  const [isLoadingExport, setIsLoadingExport] = useState(false)

  const handleExportFile = () => {
    setIsLoadingExport(true)
    try {
      const _dataExport = selectedRowKeys?.length
        ? userInfoList.filter((item) =>
            selectedRowKeys.includes(item.attentionDetailId),
          )
        : userInfoList

      const convertData = convertDataExport(_dataExport, attentionInfo)
      exportCsv(convertData, EXPORT_EP0195)
    } finally {
      setIsLoadingExport(false)
    }
  }

  return {
    handleExportFile,
    isLoadingExport,
  }
}
