import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Routers } from "../../../constant/Routers"
import { AxiosErrorType } from "../../../infrastructure/axiosError/models"
import { CitiesResType } from "../../../infrastructure/handlers/countries/models"
import { countriesApi } from "../../../infrastructure/handlers/countries"
import { OptionItemType } from "../../../_webui/layout/components/Select/models"
import { CitiesType } from "./models"
import { pushStateError } from "../../pushStateError"

const { SCR973 } = Routers

export const getCities = (countryCode: string): CitiesType => {
  const { push } = useHistory()
  const [cities, setCities] = useState<OptionItemType[]>([])

  useEffect(() => {
    if (countryCode)
      // APIを呼び出し、都市一覧情報を取得する。
      countriesApi
        .getCitiesByCountry(countryCode)
        .then((res: CitiesResType[]) => {
          const optionItem: OptionItemType[] = res.map((item) => ({
            text: item.cityDistrict,
            value: item.cityCode,
          }))
          setCities(optionItem)
        })
        .catch((error: AxiosErrorType) => {
          // 「SCR973_エラー情報の表示画面」に遷移する。
          const state = pushStateError(error)
          push({ pathname: SCR973, state })
        })
  }, [countryCode])

  return {
    cities,
  }
}
