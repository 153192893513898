import { useHistory } from "react-router-dom"
import { Routers } from "../../../../../../../constant/Routers"
import { UseContactSuccessType } from "../../../../models/contactSuccess"
import { useEffect, useState } from "react"

const { SCR920 } = Routers

export const useContactSuccess = (): UseContactSuccessType => {
  const { push } = useHistory()
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)

  const goToHome = () => {
    push(SCR920)
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoadingLayout(false)
    }, 500)
  }, [])

  return {
    goToHome,
    isLoadingLayout,
  }
}
