/* eslint-disable complexity */
import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const {
    isCommonRefer,
    isCommonTravelerRefer,
    isRoleAdmin,
    isRoleSubAdmin,
    isRoleUser,
    isScheduleDelete,
  } = useAuthority()

  const isNotAccessScreen = !isCommonTravelerRefer && !isScheduleDelete

  const isVisibleBtnBtn = isRoleAdmin || isRoleSubAdmin || isRoleUser
  const isVisibleBtnCancel = isVisibleBtnBtn && isCommonRefer
  const isVisibleBtnDelete =
    isVisibleBtnBtn && (isCommonTravelerRefer || isScheduleDelete)

  return {
    isNotAccessScreen,
    isVisibleBtnCancel,
    isVisibleBtnDelete,
  }
}
