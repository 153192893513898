import { FC } from "react"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { ModalConfirm } from "../../../../../_webui/layout/components/Modal/Confirm"
import { Translate } from "../../../../../constant/Translate"
import { TravelersDetailButtonGroup } from "../../components/detail/ButtonGroup"
import { TravelersDetailCollapse } from "../../components/detail/Collapse"
import { useTravelersDetail } from "./modules/useDetail"

const { MESSAGE_C0003, MESSAGE_C0004, TEXT_T035 } = Translate

export const TravelersDetail: FC = () => {
  const {
    breadcrumbItems,
    columns,
    goBack,
    goToSchedulesList,
    goToTravelersEdit,
    isHiddenBtnBack,
    isHiddenBtnDelete,
    isHiddenBtnEdit,
    isHiddenBtnRequestDelete,
    isHiddenBtnSchedules,
    isLoading,
    isLoadingLayout,
    isOpen,
    isRoleAdmin,
    isRoleSubAdmin,
    isSystemIdentifier,
    onCancel,
    onOk,
    onOpen,
    travelerInfo,
  } = useTravelersDetail()

  return (
    <Layout
      breadcrumbItems={breadcrumbItems}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T035}
    >
      <Card heading={TEXT_T035} isLoading={isLoading}>
        {travelerInfo && (
          <TravelersDetailCollapse
            travelerInfo={travelerInfo}
            columns={columns}
          />
        )}
        <TravelersDetailButtonGroup
          goBack={goBack}
          goToSchedulesList={goToSchedulesList}
          goToTravelersEdit={goToTravelersEdit}
          isHiddenBtnBack={isHiddenBtnBack}
          isHiddenBtnDelete={isHiddenBtnDelete}
          isHiddenBtnEdit={isHiddenBtnEdit}
          isHiddenBtnRequestDelete={isHiddenBtnRequestDelete}
          isHiddenBtnSchedules={isHiddenBtnSchedules}
          isSystemIdentifier={isSystemIdentifier}
          onOpen={onOpen}
        />
      </Card>
      {isRoleAdmin && (
        <ModalConfirm isOpen={isOpen} onCancel={onCancel} onOk={onOk}>
          {MESSAGE_C0004}
        </ModalConfirm>
      )}
      {isRoleSubAdmin && (
        <ModalConfirm isOpen={isOpen} onCancel={onCancel} onOk={onOk}>
          {MESSAGE_C0003}
        </ModalConfirm>
      )}
    </Layout>
  )
}
