import styled from "styled-components"

const StyledHeaderActionArrow = styled.span`
  margin-left: 10px;
`

const StyledHeaderActionText = styled.span`
  margin-left: 7px;
`

export const Styled = {
  StyledHeaderActionArrow,
  StyledHeaderActionText,
}
