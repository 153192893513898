import styled from "styled-components"

const StyledBreadcrumbs = styled.ul`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 0 16px 0;
  line-height: 18px;
  padding: 0;
  &:focus-visible {
    outline: none;
  }
`

const StyledBreadcrumbsItem = styled.li`
  min-height: 18px;
  &:not(:last-child) {
    &::after {
      content: "＞";
      margin-left: 13px;
      margin-right: 13px;
    }
  }
`

export const Styled = {
  StyledBreadcrumbs,
  StyledBreadcrumbsItem,
}
