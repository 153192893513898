import { FC } from "react"
import { TitleProps } from "./models"
import { Styled } from "./styled"

const { StyledTitle, StyledTitleRight } = Styled

export const Title: FC<TitleProps> = (props) => {
  const {
    className,
    alignItems,
    titleLeft,
    titleRight,
    type,
    isTitleRightFullWidth,
    ...res
  } = props

  return (
    <StyledTitle
      alignItems={alignItems}
      className={className}
      type={type}
      {...res}
    >
      <div className="title-left">{titleLeft}</div>
      {titleRight && (
        <StyledTitleRight isTitleRightFullWidth={isTitleRightFullWidth}>
          {titleRight}
        </StyledTitleRight>
      )}
    </StyledTitle>
  )
}
