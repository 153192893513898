/* eslint-disable max-statements */
import { Form } from "antd"
import { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import {
  RolesSettingFormDataSubmitType,
  RolesSettingStateType,
} from "../../../../../models/roles/setting"
import { useRouters } from "../useRouters"
import { getBreadcrumbItems } from "./getBreadcrumbItems"
import { useVariablesModal } from "../useVariablesModal"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { PatternId } from "../../../../../../../../constant/PatternId"
import { getAuthorityPatterns } from "../../../../../../../../utils/apis/getAuthorityPatterns"

const { PATTERN_ADMIN, PATTERN_USER, PATTERN_USER_1 } = PatternId

export const useVariables = () => {
  const [form] = Form.useForm()
  const { push } = useHistory()
  const { state } = useLocation<RolesSettingStateType>()
  const { isCommonPermissionSettings, isHiddenBtn } = useVariablesAuthority()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const patternIds = [PATTERN_ADMIN, PATTERN_USER, PATTERN_USER_1]
  const _patternId = !patternIds.includes(
    String(state?.searchCondition?.patternId),
  )
    ? state?.searchCondition?.patternId
    : ""
  const [patternId, setPatternId] = useState(_patternId)
  const [values, setValues] = useState<RolesSettingFormDataSubmitType>()
  const [updateDate, setUpdateDate] = useState("")
  const { authorityPatterns } = getAuthorityPatterns()
  const { isOpen, isOpenLink, onCancel, onCancelLink, onOpen, onOpenLink } =
    useVariablesModal()
  const { goBack, goToSubAdminSetting } = useRouters(patternId)

  const breadcrumbItems = getBreadcrumbItems(
    state?.isFromSCR950,
    state?.isFromSCR951,
    state?.isFromSCR952,
  )

  const _authorityPatterns = authorityPatterns?.filter(
    (item) => !patternIds.includes(String(item.value)),
  )

  return {
    authorityPatterns: _authorityPatterns,
    breadcrumbItems,
    form,
    goBack,
    goToSubAdminSetting,
    isCommonPermissionSettings,
    isHiddenBtn,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenLink,
    onCancel,
    onCancelLink,
    onOpen,
    onOpenLink,
    patternId,
    push,
    setIsLoading,
    setIsLoadingLayout,
    setPatternId,
    setUpdateDate,
    setValues,
    updateDate,
    values,
  }
}
