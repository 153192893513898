import React, { FC, Fragment } from "react"
import { Translate } from "../../../../../../../constant/Translate"
import { ItemInput } from "../../../../../../../_webui/layout/components/Form/Item/Input"
import { validatorRequiredField } from "../../../../../../../utils/validator/requiredField"
import { validatorEmailAddress } from "../../../../../../../utils/validator/emailAddress"
import { TravelersEditOthersProps } from "../../../../models/edit"

const {
  TEXT_T078,
  TEXT_T079,
  TEXT_T080,
  TEXT_T081,
  TEXT_T082,
  TEXT_T095,
  TEXT_T246,
  TEXT_T247,
  TEXT_T248,
  TEXT_T249,
  TEXT_T250,
} = Translate

export const Others: FC<TravelersEditOthersProps> = (props) => {
  const { isChangeUser, isRoleAdmin, isRoleSubAdmin } = props
  const checkValue = isRoleAdmin || isRoleSubAdmin

  return (
    <Fragment>
      <ItemInput label={TEXT_T246} name="othersRemarks1" maxLength={200} />
      <ItemInput label={TEXT_T247} name="othersRemarks2" maxLength={200} />
      <ItemInput label={TEXT_T248} name="othersRemarks3" maxLength={200} />
      <ItemInput label={TEXT_T249} name="othersRemarks4" maxLength={200} />
      <ItemInput label={TEXT_T250} name="othersRemarks5" maxLength={200} />
      {checkValue && (
        <Fragment>
          <ItemInput
            label={TEXT_T078}
            maxLength={200}
            name="othersAdministratorRemarks1"
          />
          <ItemInput
            label={TEXT_T079}
            maxLength={200}
            name="othersAdministratorRemarks2"
          />
          <ItemInput
            label={TEXT_T080}
            maxLength={200}
            name="othersAdministratorRemarks3"
          />
          <ItemInput
            label={TEXT_T081}
            maxLength={200}
            name="othersAdministratorRemarks4"
          />
          <ItemInput
            label={TEXT_T082}
            maxLength={200}
            name="othersAdministratorRemarks5"
          />
        </Fragment>
      )}
      {isRoleAdmin && (
        <ItemInput
          label={TEXT_T095}
          maxLength={255}
          name="userIdForChangeRequest"
          required={isChangeUser}
          rules={
            isChangeUser
              ? [validatorRequiredField, validatorEmailAddress]
              : [validatorEmailAddress]
          }
        />
      )}
    </Fragment>
  )
}
