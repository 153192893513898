import { useModal } from "../../../../../../../utils/hook/useModal"

export const useVariablesModal = () => {
  const {
    isOpen: isOpenLoadData,
    onCancel: onCancelLoadData,
    onOpen: onOpenLoadData,
  } = useModal()
  const {
    isOpen: isOpenShowData,
    onCancel: onCancelShowData,
    onOpen: onOpenShowData,
  } = useModal()
  return {
    isOpenLoadData,
    isOpenShowData,
    onCancelLoadData,
    onCancelShowData,
    onOpenLoadData,
    onOpenShowData,
  }
}
