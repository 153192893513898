import { FC } from "react"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { Upload } from "../../../../../_webui/layout/components/Upload"
import { File } from "../../../../../constant/File"
import { Translate } from "../../../../../constant/Translate"
import { SchedulesBatchImportButtonSubmit } from "../../components/batchImport/ButtonSubmit"
import { SchedulesBatchImportModalConfirm } from "../../components/batchImport/ModalConfirm"
import { SchedulesBatchImportTable } from "../../components/batchImport/Table"
import { useSchedulesBatchImport } from "./modules/useBatchImport"

const { TEXT_T301 } = Translate
const { FILE_TYPE_ALLOW_UPLOAD_ACCEPT_ALL } = File

export const SchedulesBatchImport: FC = () => {
  const {
    beforeUpload,
    breadcrumbItems,
    bulkAdd,
    bulkDeleteDataConfirm,
    bulkFileAdd,
    columnErrors,
    columns,
    countRecord,
    dataTemps,
    errorList,
    fileNameList,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenUploaded,
    isResetTable,
    isVisibleBtnCancel,
    isVisibleBtnRegister,
    onCancel,
    onCancelUploaded,
    onChange,
    onOpen,
    onOpenDeletedOrGoToHome,
    perItem,
  } = useSchedulesBatchImport()

  return (
    <Layout
      breadcrumbItems={breadcrumbItems}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T301}
    >
      <Card heading={TEXT_T301} isLoading={isLoading}>
        <Upload
          accept={FILE_TYPE_ALLOW_UPLOAD_ACCEPT_ALL}
          customRequest={({ file }) =>
            beforeUpload(file, (upload: boolean) => {
              if (upload) {
                bulkFileAdd(file)
              }
            })
          }
          fileNameList={fileNameList}
        />
        <SchedulesBatchImportTable
          columnErrors={columnErrors}
          columns={columns}
          countRecord={countRecord}
          dataTemps={dataTemps}
          errorList={errorList}
          isResetTable={isResetTable}
          onChange={onChange}
          perItem={perItem}
        />
        <SchedulesBatchImportButtonSubmit
          isDisabledSubmit={isDisabledSubmit}
          isVisibleBtnCancel={isVisibleBtnCancel}
          isVisibleBtnRegister={isVisibleBtnRegister}
          onOpen={onOpen}
          onOpenDeletedOrGoToHome={onOpenDeletedOrGoToHome}
        />
        <SchedulesBatchImportModalConfirm
          bulkAdd={bulkAdd}
          bulkDeleteDataConfirm={bulkDeleteDataConfirm}
          isOpen={isOpen}
          isOpenUploaded={isOpenUploaded}
          onCancel={onCancel}
          onCancelUploaded={onCancelUploaded}
        />
      </Card>
    </Layout>
  )
}
