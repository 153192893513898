import { GridItemType } from "../../../../../_webui/layout/components/Grid/models"
import { Translate } from "../../../../../constant/Translate"

const { TEXT_T445, TEXT_T446 } = Translate

const TIMEZONE_GRID_ITEMS: GridItemType[] = [
  {
    key: TEXT_T445,
    text: "",
  },
  {
    key: TEXT_T446,
    text: "",
  },
]

export const Constant = {
  TIMEZONE_GRID_ITEMS,
}
