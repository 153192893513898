import { GeneralCode } from "../../../../../../../constant/GeneralCode"
import { getAllCountries } from "../../../../../../../utils/apis/getAllCountries"
import { getCities } from "../../../../../../../utils/apis/getCities"
import { getDepartments } from "../../../../../../../utils/apis/getDepartments"
import { getGeneralMasters } from "../../../../../../../utils/apis/getGeneralMasters"

const { DPTD, PRFT, RLTS } = GeneralCode

export const useVariablesUtils = (countryCode: string) => {
  // APIを呼び出し、「派遣種別（詳細）」のデータ一覧を取得する。
  const { generalMasters: dispatchType } = getGeneralMasters(DPTD)
  // API を呼び出し、「随伴家族の続柄」のデータ一覧を取得する。
  const { generalMasters: familyRelationship } = getGeneralMasters(RLTS)
  // APIを呼び出し、「居住している都道府県」のデータ一覧を取得する。
  const { generalMasters: prefectureList } = getGeneralMasters(PRFT)
  // APIを呼び出し、「主管部署」のデータ一覧を取得する。
  const { departments } = getDepartments()
  // APIを呼び出し、「国」のデータ一覧を取得する。
  const { allCountries } = getAllCountries({ isFilterJapan: true })
  // APIを呼び出し、APIからの応答結果を「都市一覧の応答」変数に設定する。
  const { cities } = getCities(countryCode)

  return {
    allCountries,
    cities,
    departments,
    dispatchType,
    familyRelationship,
    prefectureList,
  }
}
