import { FC } from "react"
import { AlertProps } from "./models"
import { Image } from "../Image"
import { Images } from "../../../../constant/Images"
import { Styled } from "./styled"
import { PreLine } from "../Typography/PreLine"

const { IMAGE_CHECK_CIRCLE, IMAGE_EXCLAMATION } = Images
const {
  StyledAlert,
  StyledAlertContent,
  StyledAlertHeading,
  StyledAlertIcon,
  StyledAlertTitle,
} = Styled

// eslint-disable-next-line complexity
export const Alert: FC<AlertProps> = (props) => {
  const { content, heading, isIcon = true, title, type } = props
  const src = type === "danger" ? IMAGE_EXCLAMATION : IMAGE_CHECK_CIRCLE

  return (
    <StyledAlert type={type}>
      {heading && (
        <StyledAlertHeading type={type}>{heading}</StyledAlertHeading>
      )}
      <StyledAlertIcon type={type}>
        {isIcon && <Image alt="" src={src} />}
        {title && <StyledAlertTitle type={type}>{title}</StyledAlertTitle>}
      </StyledAlertIcon>
      {typeof content === "string" ? (
        <StyledAlertContent>
          <PreLine>{content}</PreLine>
        </StyledAlertContent>
      ) : (
        <StyledAlertContent>{content}</StyledAlertContent>
      )}
    </StyledAlert>
  )
}
