/* eslint-disable max-statements */
/* eslint-disable max-lines */
import { BreakMessage } from "../../../../../../../_webui/layout/components/Typography/BreakMessage"
import { UploadFileType } from "../../../../../../../_webui/layout/components/Upload/models"
import { Routers } from "../../../../../../../constant/Routers"
import { Translate } from "../../../../../../../constant/Translate"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { openNotification } from "../../../../../../../utils/openNotification"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { SchedulesBatchImportErrorType } from "../../../../../schedules/models/batchImport"
import { travelerApi } from "../../../../apis"
import {
  TravelersBatchDeleteCheck,
  TravelersBatchDeleteErrorType,
  UseTravelersBatchDeleteApiProps,
} from "../../../../models/batchDelete"
import { useVariablesApi } from "../useVariablesApi"
import { TravelersFileAddBatchImportType } from "../../../../models/batchImport"
import { File } from "../../../../../../../constant/File"
import { TravelersBatchDeleteDataType } from "../../../../models/batchDelete/data"
import { getFileNameRcFile } from "../../../../../../../utils/getFileNameRcFile"

const { SCR973 } = Routers
const {
  MESSAGE_E0022,
  MESSAGE_E0023,
  MESSAGE_E0037,
  MESSAGE_I0005,
  MESSAGE_I0006,
} = Translate

const {
  FILE_COLUMN_LIMIT_TRAVELERS,
  FILE_SIZE_LIMIT_KB,
  FILE_SIZE_LIMIT_MB,
  FILE_TYPE_ALLOW,
  SCR124,
} = File

export const useApi = (props: UseTravelersBatchDeleteApiProps) => {
  const { onCancel, onCancelDeleted } = props
  const {
    countRecord,
    dataTemps,
    errorList,
    fileNameList,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isResetTable,
    onChange,
    perItem,
    push,
    setDataTemps,
    setErrorList,
    setFileNameList,
    setIsDisabledSubmit,
    setIsLoading,
    setIsLoadingLayout,
    setIsResetTable,
    setTotalReport,
  } = useVariablesApi()

  const handlePushStateError = (error: AxiosErrorType) => {
    const stateError = pushStateError(error)
    push({ pathname: SCR973, state: stateError })
  }

  const bulkDeleteData = () => {
    // APIを呼び出し、APIからの応答結果を「M.deleteTemp」変数に設定する。
    travelerApi
      .bulkDeleteData(SCR124)
      .then(() => {
        // モーダルクローズを実行する
        sessionStorage.removeItem("dataUpload")
        onCancelDeleted()
        onCancel()
        setIsLoadingLayout(false)
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        handlePushStateError(error)
      })
  }

  const bulkCheck = () => {
    // APIを呼び出し、APIからの応答結果を「M.bulkCheckTemp」変数に設定する。
    travelerApi
      .bulkCheck(SCR124)
      .then((bulkCheckTemp: TravelersBatchDeleteCheck) => {
        const { message } = bulkCheckTemp
        if (message) {
          //「TBL_TEMP_FOR_UPLOAD」一時テーブルのデータ削除の確認ダイアログを表示する。
          bulkDeleteData()
        } else {
          setIsLoadingLayout(false)
        }
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        handlePushStateError(error)
      })
  }

  const bulkData = () => {
    setIsLoading(true)
    setIsResetTable(true)
    setTimeout(() => {
      // APIを呼び出し、APIからの応答結果を「返却一時データ」変数に設定する。
      travelerApi
        .bulkData(SCR124)
        .then((dataTempsResponse: TravelersBatchDeleteDataType[]) => {
          //「M.dataTemps」の一覧にデータを設定する。
          sessionStorage.setItem("dataUpload", SCR124)
          setDataTemps(dataTempsResponse)
          setErrorList([])
          onChange(1, 25)
        })
        .catch((error: AxiosErrorType) => {
          // 「SCR973_エラー情報の表示画面」に遷移する。
          handlePushStateError(error)
        })
        .finally(() => {
          setIsLoading(false)
          setIsDisabledSubmit(false)
          setIsResetTable(false)
        })
    }, 500)
  }

  const handleSubmitDelete = (
    deleteTemp: TravelersBatchDeleteErrorType,
    message: string,
  ) => {
    // ComponentErrorを表示する。
    if (deleteTemp.errors.length) {
      setErrorList(deleteTemp.errors)
    }

    // 「渡航者情報の結果件数」コンポーネントの値を表示する。
    if (deleteTemp?.execute?.fail === 0) {
      openNotification(message)
      setDataTemps([])
    }

    //「渡航者情報の結果件数」コンポーネントの値を表示する。
    setTotalReport(deleteTemp.execute)
    onCancel()
    setIsLoading(false)
  }

  const bulkDelete = () => {
    setTimeout(() => {
      // APIを呼び出し、渡航者情報を論理削除する。
      travelerApi
        .bulkDelete(SCR124)
        .then((disableTravelers: TravelersBatchDeleteErrorType) => {
          // バックエンドから戻す「I0005」のメッセージを表示する。
          sessionStorage.removeItem("dataUpload")
          handleSubmitDelete(disableTravelers, MESSAGE_I0005)
          setIsDisabledSubmit(true)
          bulkDeleteData()
        })
        .catch((error: AxiosErrorType) => {
          // 「SCR973_エラー情報の表示画面」に遷移する。
          handlePushStateError(error)
        })
    }, 500)
  }

  const bulkRequestDelete = () => {
    setTimeout(() => {
      // APIを呼び出し、渡航者情報の一覧の論理削除依頼を作成する。
      travelerApi
        .bulkRequestDelete(SCR124)
        .then((requestDisableTravelers: TravelersBatchDeleteErrorType) => {
          // バックエンドから戻す「I0006」のメッセージを表示する。
          handleSubmitDelete(requestDisableTravelers, MESSAGE_I0006)
          setIsDisabledSubmit(true)
          bulkDeleteData()
        })
        .catch((error: AxiosErrorType) => {
          // 「SCR973_エラー情報の表示画面」に遷移する。
          handlePushStateError(error)
        })
    }, 500)
  }

  const setDataBeforeUploadError = (message: string, validValue: string) => {
    openNotification(<BreakMessage message={message} validValue={validValue} />)
    setDataTemps([])
    setErrorList([])
    setFileNameList([])
  }

  const bulkFileDelete = (fileInfoCSV: UploadFileType) => {
    const formData = new FormData()
    formData.append("file", fileInfoCSV)
    setIsLoading(true)
    setIsResetTable(true)
    // APIを呼び出し、APIからの応答結果を「M.uploadTemp」変数に設定する。
    travelerApi
      .bulkFileDelete(formData)
      .then((uploadTemp: SchedulesBatchImportErrorType) => {
        // バックエンドから戻す「E0037」のメッセージが表示される。
        onChangeUploadFile({ response: uploadTemp, status: "done" })
        if (getFileNameRcFile(fileInfoCSV)) {
          setFileNameList([fileInfoCSV?.name])
        }
      })
      .catch((error) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        onChangeUploadFile({ response: error, status: "error" })
        setFileNameList([])
      })
      .finally(() => {
        setIsLoading(false)
        setIsResetTable(false)
      })
  }

  const beforeUpload = (
    fileInfoCSV: UploadFileType,
    uploadCallback: (upload: boolean) => void,
  ) => {
    if (typeof fileInfoCSV !== "string") {
      const { type, size } = fileInfoCSV
      const fileSize = size / 1024
      const isValidFileType = type.includes(FILE_TYPE_ALLOW)
      const isValidFileSize = fileSize < FILE_SIZE_LIMIT_KB
      const isValidFileSizeZero = fileSize === 0

      // ファイル拡張子のバリデーションチェックを行う。
      if (!isValidFileType) {
        setDataBeforeUploadError(MESSAGE_E0022, FILE_TYPE_ALLOW)
        uploadCallback(false)
        return false
      }

      // ファイルサイズのバリデーションチェックを行う。
      if (!isValidFileSize) {
        setDataBeforeUploadError(MESSAGE_E0023, String(FILE_SIZE_LIMIT_MB))
        uploadCallback(false)
        return false
      }

      // ファイルの項目個数のバリデーションチェックを行う。
      if (isValidFileSizeZero) {
        setDataBeforeUploadError(
          MESSAGE_E0037,
          String(FILE_COLUMN_LIMIT_TRAVELERS),
        )
        uploadCallback(false)
        return false
      }

      const reader = new FileReader()

      reader.onload = (event: ProgressEvent<FileReader>) => {
        const fileData = String(event.target?.result)
        const columnNames = fileData?.substring(0, fileData.indexOf("\r\n"))
        const columns = columnNames.split(",")
        const isValidColumns = columns.length === FILE_COLUMN_LIMIT_TRAVELERS

        if (!isValidColumns) {
          // ファイルの項目個数のバリデーションチェックを行う。
          setDataBeforeUploadError(
            MESSAGE_E0037,
            String(FILE_COLUMN_LIMIT_TRAVELERS),
          )
          uploadCallback(false)
        } else {
          uploadCallback(true)
        }
      }

      reader.readAsText(fileInfoCSV)
    }
  }

  const onChangeUploadFile = (info: TravelersFileAddBatchImportType) => {
    const { status, response } = info
    if (status === "done") {
      if (response.errors?.length) {
        setErrorList(response.errors)
        setIsDisabledSubmit(true)
      } else {
        bulkData()
      }
      setTotalReport(response?.execute)
    } else if (status === "error") {
      setDataBeforeUploadError(
        MESSAGE_E0037,
        String(FILE_COLUMN_LIMIT_TRAVELERS),
      )
      setIsDisabledSubmit(true)
    }
  }

  return {
    beforeUpload,
    bulkCheck,
    bulkDelete,
    bulkDeleteData,
    bulkFileDelete,
    bulkRequestDelete,
    countRecord,
    dataTemps,
    errorList,
    fileNameList,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isResetTable,
    onChange,
    perItem,
    setIsLoading,
  }
}
