import { GeneralCode } from "../../../../../../../constant/GeneralCode"
import { Translate } from "../../../../../../../constant/Translate"
import { getFullName } from "../../../../../../../utils/getFullName"
import { GridItemType } from "../../../../../../../_webui/layout/components/Grid/models"
import { AttentionInfoType } from "../../../../models/attention"
import { PreLine } from "../../../../../../../_webui/layout/components/Typography/PreLine"

const { TEXT_T312, TEXT_T314, TEXT_T315, TEXT_T316 } = Translate
const { CTGR_OTHER } = GeneralCode

export const useGridConfig = (data: AttentionInfoType) => {
  const gridItems: GridItemType[] = [
    {
      key: TEXT_T312,
      text: data?.categoryId?.startsWith(CTGR_OTHER)
        ? getFullName(data?.categoryName, data?.categoryValue, true)
        : data?.categoryName,
    },
    {
      key: TEXT_T314,
      text: data?.severityName,
    },
    { key: TEXT_T315, text: data?.attentionTitle },
    {
      key: TEXT_T316,
      text: <PreLine>{data?.bodyContent}</PreLine>,
    },
  ]
  return {
    gridItems,
  }
}
