import "./style/index.scss"
import { FC } from "react"
import { TabsLabelProps } from "./models"
import { Styled } from "./styled"

const { StyledTabsLabel, StyledTabsTotal } = Styled

export const TabsLabel: FC<TabsLabelProps> = (props) => {
  const { total, text } = props

  return (
    <StyledTabsLabel>
      <StyledTabsTotal className="ant-tabs-label-total">
        {total}
      </StyledTabsTotal>
      <div>{text}</div>
    </StyledTabsLabel>
  )
}
