/* eslint-disable max-lines */
import { Fragment } from "react"
import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../_webui/layout/components/Table/models"
import { Translate } from "../../../../../../../constant/Translate"
import { concat } from "../../../../../../../utils/concat"
import { getDay } from "../../../../../../../utils/getDate"
import { getSortOrder } from "../../../../../../../utils/getSortOrder"
import { getValueByLanguage } from "../../../../../../../utils/getValueByLanguage"
import { SortedInfoType } from "../../../../../../../utils/hook/useSort/models"
import { SchedulesListDataType } from "../../../../models/list"
import { getColumItem1, getColumItem2 } from "./columnsItems"
import { getFullName } from "../../../../../../../utils/getFullName"
import { Expandable } from "../../../../../../../_webui/layout/components/Typography/Expandable"

const {
  TEXT_T009,
  TEXT_T194,
  TEXT_T196,
  TEXT_T197,
  TEXT_T212,
  TEXT_T213,
  TEXT_T214,
  TEXT_T215,
  TEXT_T237,
  TEXT_T238,
  TEXT_T373,
} = Translate

export const useTableConfig = (
  isPathnameSchedules: boolean,
  sortedInfo: SortedInfoType,
): UseTableConfigType<SchedulesListDataType> => {
  const columns: ColumnType<SchedulesListDataType>[] = [
    ...getColumItem1(isPathnameSchedules, sortedInfo),
    {
      dataIndex: "minDepartureDateTime",
      render: (_, data) => {
        const minDepartureDateTime = getDay(data.info.minDepartureDateTime)
        return data.info.isOneWay
          ? getValueByLanguage(
              getFullName(TEXT_T373, minDepartureDateTime, true),
              getFullName(TEXT_T373, minDepartureDateTime),
            )
          : minDepartureDateTime
      },
      sortOrder: getSortOrder(sortedInfo, "minDepartureDateTime"),
      sorter: (a, b) =>
        a.info.minDepartureDateTime.localeCompare(b.info.minDepartureDateTime),
      title: TEXT_T196,
      width: 250,
    },
    {
      dataIndex: "maxArrivalDateTime",
      render: (_, data) => getDay(data.info.maxArrivalDateTime),
      sortOrder: getSortOrder(sortedInfo, "maxArrivalDateTime"),
      sorter: (a, b) =>
        a.info.maxArrivalDateTime.localeCompare(b.info.maxArrivalDateTime),
      title: TEXT_T197,
      width: 250,
    },
    {
      dataIndex: "departureCountry",
      render: (_, data) => data.info.departureCountry,
      sortOrder: getSortOrder(sortedInfo, "departureCountry"),
      sorter: (a, b) =>
        a.info.departureCountry.localeCompare(b.info.departureCountry),
      title: TEXT_T212,
      width: getValueByLanguage(250, 220),
    },
    {
      dataIndex: "transitCountries",
      render: (_, data) =>
        data.info.transitCountries?.split("_").map((item, index) => (
          <Fragment key={index}>
            {item}
            <br />
          </Fragment>
        )),
      sortOrder: getSortOrder(sortedInfo, "transitCountryOrders"),
      sorter: (a, b) =>
        a.info.transitCountryOrders.localeCompare(b.info.transitCountryOrders),
      title: TEXT_T213,
      width: getValueByLanguage(250, 220),
    },
    {
      dataIndex: "arrivalCountry",
      render: (_, data) => data.info.arrivalCountry,
      sortOrder: getSortOrder(sortedInfo, "arrivalCountry"),
      sorter: (a, b) =>
        a.info.arrivalCountry.localeCompare(b.info.arrivalCountry),
      title: TEXT_T214,
      width: getValueByLanguage(250, 220),
    },
    ...getColumItem2(isPathnameSchedules, sortedInfo),
    {
      dataIndex: "travelPurposeName",
      render: (_, data) => data.info.travelPurposeName,
      sortOrder: getSortOrder(sortedInfo, "travelPurposeName"),
      sorter: (a, b) =>
        a.info.travelPurposeName.localeCompare(b.info.travelPurposeName),
      title: TEXT_T215,
      width: getValueByLanguage(150, 170),
    },
    {
      dataIndex: "bumonName",
      render: (_, data) => data.info.bumonName,
      sortOrder: getSortOrder(sortedInfo, "bumonName"),
      sorter: (a, b) => a.info.bumonName.localeCompare(b.info.bumonName),
      title: TEXT_T009,
      width: 300,
    },
    {
      dataIndex: "dispatchStartDate",
      render: (_, data) => getDay(data.info.dispatchStartDate),
      sortOrder: getSortOrder(sortedInfo, "dispatchStartDate"),
      sorter: (a, b) =>
        a.info.dispatchStartDate.localeCompare(b.info.dispatchStartDate),
      title: TEXT_T237,
      width: 250,
    },
    {
      dataIndex: "dispatchEndDate",
      render: (_, data) => getDay(data.info.dispatchEndDate),
      sortOrder: getSortOrder(sortedInfo, "dispatchEndDate"),
      sorter: (a, b) =>
        a.info.dispatchEndDate.localeCompare(b.info.dispatchEndDate),
      title: TEXT_T238,
      width: 250,
    },
    {
      dataIndex: "projectName",
      render: (_, data) => (
        <Expandable
          title={TEXT_T194}
          content={getValueByLanguage(
            data.info.projectNameJp,
            data.info.projectNameEn,
          ).toString()}
        />
      ),
      sortOrder: getSortOrder(sortedInfo, "projectName"),
      sorter: (a, b) =>
        concat(a.info.projectNameJp, a.info.projectNameEn).localeCompare(
          concat(b.info.projectNameJp, b.info.projectNameEn),
        ),
      title: TEXT_T194,
      width: 150,
    },
  ]

  return {
    columns,
  }
}
