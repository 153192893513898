import { FC, Fragment } from "react"
import { Divider } from "../../../../../../../../_webui/layout/components/Divider"
import { Space } from "../../../../../../../../_webui/layout/components/Space"
import { Tabs } from "../../../../../../../../_webui/layout/components/Tabs"
import { TravelersEditButtonGroup } from "../../../ButtonGroup"
import { PersonalInfoTabsProps } from "./models"

export const PersonalInfoTabs: FC<PersonalInfoTabsProps> = (props) => {
  const {
    accompanies,
    addItems,
    indexTabActive,
    onTabClick,
    removeItems,
    tabsItems,
  } = props

  return (
    <Fragment>
      <Divider />

      <Space isSpaceTop>
        {accompanies.length > 0 ? (
          <Tabs
            activeKey={indexTabActive}
            defaultActiveKey={"1"}
            items={tabsItems}
            onTabClick={onTabClick}
          />
        ) : (
          <TravelersEditButtonGroup
            addItems={addItems}
            isDisabledBtnDelete={true}
            removeItems={removeItems}
          />
        )}
      </Space>
    </Fragment>
  )
}
