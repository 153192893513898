import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { Key, useEffect, useState } from "react"
import { CheckboxItem } from "../../../../../../../_webui/layout/components/RowSelection/CheckboxItem"
import { TableRowSelection } from "../../../../../../../_webui/layout/components/Table/models"
import { Translate } from "../../../../../../../constant/Translate"
import { useTable } from "../../../../../../../utils/hook/useTable"
import { SchedulesCloneDataType } from "../../../../models/clone"

const { TEXT_T023 } = Translate

export const useRowSelection = (dataSource: SchedulesCloneDataType[]) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [isDisabledBtnClone, setIsDisabledBtnClone] = useState(true)

  const { onChange: onChangePage, perItem } = useTable(dataSource.length)

  const onSelectChange = () => {
    const newSelectedRowKeys = selectedRowKeys.length !== 1
    setIsDisabledBtnClone(newSelectedRowKeys)
  }

  const onChangeCheckItem = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    const value = event.target.value

    if (checked) setSelectedRowKeys([...selectedRowKeys, value])
    else {
      const _selectedRowKeys = selectedRowKeys.filter((item) => item !== value)
      setSelectedRowKeys(_selectedRowKeys)
    }
  }

  const rowSelection: TableRowSelection<SchedulesCloneDataType> = {
    columnTitle: <div className="d-none">{TEXT_T023}</div>,
    hideSelectAll: true,
    renderCell: (_, record) => (
      <CheckboxItem
        checked={selectedRowKeys.includes(record.scheduleId)}
        onChange={onChangeCheckItem}
        value={record.scheduleId}
      />
    ),
    selectedRowKeys,
  }

  const onChange = (page: number, pageSize: number) => {
    onChangePage(page, pageSize)
    setSelectedRowKeys([])
  }

  const onChangeTable = () => {
    setSelectedRowKeys([])
  }

  useEffect(() => {
    onSelectChange()
  }, [selectedRowKeys])

  return {
    isDisabledBtnClone,
    onChange,
    onChangeTable,
    perItem,
    rowSelection,
    selectedRowKeys,
  }
}
