export const BUMON_CD_INITIAL_VALUES = {
  bumonCd: "",
}

export const PATTERN_ID_INITIAL_VALUES = {
  patternId: "",
}

const FORM_SEARCH_INITIAL_VALUES = {
  ...BUMON_CD_INITIAL_VALUES,
  ...PATTERN_ID_INITIAL_VALUES,
}

const SEARCH_CONDITION_INITIAL_VALUES = {
  bumonCd: "",
  fullName: "",
  patternId: "",
  travelerIdList: [],
}

export const InitialValues = {
  BUMON_CD_INITIAL_VALUES,
  FORM_SEARCH_INITIAL_VALUES,
  PATTERN_ID_INITIAL_VALUES,
  SEARCH_CONDITION_INITIAL_VALUES,
}
