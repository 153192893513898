/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable max-lines */
import { RadioChangeEvent } from "antd"
import { FocusEvent, KeyboardEvent, useEffect } from "react"
import { format as formatDate } from "../../../../../../../_webui/layout/components/Form/Item/DatePicker/constants"
import { OptionItemType } from "../../../../../../../_webui/layout/components/Select/models"
import { Routers } from "../../../../../../../constant/Routers"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { getValueByLanguage } from "../../../../../../../utils/getValueByLanguage"
import { InitialValues } from "../../../../constants/list/initialValues"
import {
  SchedulesListDataType,
  UseSchedulesListType,
} from "../../../../models/list"
import {
  setFormDataAdvanced,
  setFormDataBasic,
} from "../../utils/getDataSubmit"
import { useVariables } from "../useVariables"
import { formatDateSaveDB } from "../../../../../../../utils/getDate"
import moment from "moment"
import { convertAdvancedSearchDataSubmit } from "../useApi/convertAdvancedSearchDataSubmit"
import { convertSearchDataSubmit } from "../useApi/convertSearchDataSubmit"
import { RangePickerProps } from "antd/lib/date-picker"
import { replaceStringParam } from "../../../../../../../utils/replaceStringParam"
import { Translate } from "../../../../../../../constant/Translate"
import { convertBasicToAdvance } from "../useApi/convertBasicToAdvance"

const {
  SCHEDULES_ADVANCED_SEARCH_INITIAL_VALUES,
  SCHEDULES_BASIC_SEARCH_INITIAL_VALUES,
} = InitialValues
const { SCR210 } = Routers
const { MESSAGE_E0143 } = Translate

export const useSchedulesList =
  (): UseSchedulesListType<SchedulesListDataType> => {
    const {
      allCountries,
      arrivalHourItems,
      arrivalMinuteItems,
      breadcrumbItems,
      cities,
      columns,
      countries,
      dataSource,
      departments,
      dispatchType,
      dispatchTypeAll,
      dispatchTypeDetail,
      form,
      formAdvanced,
      fullName,
      getData,
      getSchedules,
      goBack,
      goToAttention,
      goToSafety,
      goToSchedulesAdd,
      goToSchedulesConfirmDelete,
      goToSchedulesDetail,
      goToSchedulesEdit,
      handleAdvancedSearch,
      handleCancelModalExport,
      handleExportFile,
      handleOpenModalExport,
      handleSearch,
      isAuthorities,
      isDefaultValueArrivalDate,
      isDefaultValueDepartureDate,
      isDisabledBtnCreateOrSend,
      isDisabledBtnDelete,
      isDisabledBtnRefer,
      isDisabledDispatchTypeDetail,
      isFromSCR114UserSearch,
      isGetCountries,
      isHiddenBtnCreateOrSendAttentions,
      isHiddenBtnCreateOrSendSafeties,
      isHiddenBtnDelete,
      isHiddenBtnEdit,
      isHiddenBtnExportCsv,
      isHiddenBtnExportExcel,
      isHiddenBtnExportExcelSafeties,
      isHiddenBtnExportExcelTravelers,
      isHiddenBtnRefer,
      isHiddenBtnResetForm,
      isHiddenBtnSearchBasic,
      isLoadingLayout,
      isLoadingTable,
      isOpen,
      isOpenLoadData,
      isOpenModalExport,
      isOpenShowData,
      isOverseasResident,
      isPathnameAttentionsOrSafeties,
      isPathnameSchedules,
      isPathnameSchedulesOrTravelers,
      isResetTable,
      isSearchAdvance,
      isSearchBasic,
      messageConfirmDownload,
      onBlurBasic,
      onCancel,
      onCancelCustomLoadData,
      onChange,
      onChangeArrivalDate,
      onChangeDepartureDate,
      onChangeTable,
      onKeyDownBasic,
      onOkLoadData,
      onOkShowData,
      onOpen,
      onValuesChangeFormSearchAdvanced,
      overRecordLabel,
      pathname,
      perItem,
      personPrefectureResidence,
      purposeTravel,
      push,
      rowSelection,
      searchCondition,
      setCountryCode,
      setDispatchType,
      setDispatchTypeDetail,
      setIsDisabledDispatchTypeDetail,
      setIsOverseasResident,
      setIsSearchAdvance,
      setSearchCondition,
      setSearchConditionAdvanced,
      setTravelerId,
      state,
      transportation,
      userInformation,
    } = useVariables()

    const clearFieldsValueDatePicker = (
      label: string,
      name: string,
      isError?: boolean,
    ) => {
      formAdvanced.setFields([
        {
          errors: isError ? [replaceStringParam(MESSAGE_E0143, label)] : [],
          name: name,
        },
      ])
      if (name === "departureDateTime") {
        formAdvanced.setFieldsValue({
          departureDateTimeDate: "",
          departureDateTimeHour: "",
          departureDateTimeMinutes: "",
        })
      }
      if (name === "arrivalDateTime") {
        formAdvanced.setFieldsValue({
          arrivalDateTimeDate: "",
          arrivalDateTimeHour: "",
          arrivalDateTimeMinutes: "",
        })
      }
    }

    const setFieldsValueDatePicker = (
      label: string,
      name: string,
      value: string,
    ) => {
      if (value) {
        const _value = moment(value, formatDate)
        if (_value.isValid()) {
          if (name === "departureDateTime") {
            formAdvanced.setFieldsValue({
              departureDateTimeDate: moment(value, formatDate),
              departureDateTimeHour: "00",
              departureDateTimeMinutes: "00",
            })
          }
          if (name === "arrivalDateTime") {
            formAdvanced.setFieldsValue({
              arrivalDateTimeDate: moment(value, formatDate),
              arrivalDateTimeHour: "23",
              arrivalDateTimeMinutes: "59",
            })
          }
          formAdvanced.setFields([
            {
              errors: [],
              name: name,
            },
          ])
        } else {
          clearFieldsValueDatePicker(label, name, true)
        }
      } else {
        clearFieldsValueDatePicker(label, name)
      }
    }

    const onBlurAdvanced = (
      event: FocusEvent<HTMLInputElement>,
      label: string,
      name: string,
    ) => {
      const { value } = event.target
      setFieldsValueDatePicker(label, name, value)
    }

    const onKeyDownAdvanced = (
      event: KeyboardEvent<HTMLInputElement>,
      label: string,
      name: string,
    ) => {
      if (event.keyCode === 13) {
        const { value } = event.currentTarget
        setFieldsValueDatePicker(label, name, value)
      }
    }

    const onChangeWorkPlaceLocation = (event: RadioChangeEvent) => {
      const { value } = event.target
      setIsOverseasResident(value === 1)
    }

    const onChangeCountryCode = (value: string) => {
      setCountryCode(value)
    }

    const onChangeDispatchType = (value: string) => {
      const _dispatchTypeDetail: OptionItemType[] =
        dispatchTypeAll
          ?.filter((item) => item.key2 === value)
          .map((item) => ({
            text: String(getValueByLanguage(item.value1, item.value2)),
            value: item.key1,
          })) || []
      setDispatchTypeDetail(_dispatchTypeDetail)
      setIsDisabledDispatchTypeDetail(!value)
      formAdvanced.resetFields(["dispatchTypeDetail"])
    }

    const onClickReset = () => {
      form.setFieldsValue({
        ...SCHEDULES_BASIC_SEARCH_INITIAL_VALUES,
      })
      formAdvanced.setFieldsValue({
        ...SCHEDULES_ADVANCED_SEARCH_INITIAL_VALUES,
      })

      // eslint-disable-next-line no-undefined
      setSearchCondition(undefined)
      setSearchConditionAdvanced(SCHEDULES_ADVANCED_SEARCH_INITIAL_VALUES)
      setIsSearchAdvance(false)
      if (state?.isFromSCR115 && pathname !== SCR210) setTravelerId("")
      if (pathname === SCR210) {
        form.setFieldValue("fullName", fullName)
        document.getElementById("countryToTravel")?.focus()
      } else {
        document.getElementById("fullName")?.focus()
      }
    }

    const onClickResetSearchAdvance = () => {
      formAdvanced.setFieldsValue(SCHEDULES_ADVANCED_SEARCH_INITIAL_VALUES)
      setIsOverseasResident(false)
      // eslint-disable-next-line no-undefined
      setSearchCondition(undefined)
      if (state?.isFromSCR115 && pathname !== SCR210) setTravelerId("")
      if (pathname === SCR210) {
        document.getElementById("projectNameJp")?.focus()
      } else {
        document.getElementById("kanjiSurname")?.focus()
      }
    }

    const onCancelSearchAdvance = () => {
      onCancel()
      setIsSearchAdvance(
        !!Object.values(formAdvanced.getFieldsValue()).filter((item) => !!item)
          .length,
      )
    }

    const onOpenSearchAdvance = () => {
      const valuesFormBasic = form.getFieldsValue([
        "arrivalDateTimeDate",
        "bumonCd",
        "countryToTravel",
        "currentLocation",
        "departureDateTimeDate",
        "fullName",
        "isTransit",
        "projectName",
      ])

      const dataBasicConvert = convertBasicToAdvance(valuesFormBasic)

      formAdvanced.setFieldsValue({
        ...searchCondition,
        ...dataBasicConvert,
      })

      onOpen()
    }

    const handleData = () => {
      const _travelerId = state?.isFromSCR115
        ? state?.travelerId
        : isPathnameSchedules
        ? userInformation?.travelerId || ""
        : ""
      setTravelerId(_travelerId)
      const departureDateTime = isPathnameAttentionsOrSafeties ? moment() : null

      if (state?.searchCondition) {
        const searchCondition = state?.searchCondition
        const values = setFormDataBasic(searchCondition)
        form.setFieldsValue(values)
        if (isSearchBasic) {
          const dataSearch = convertSearchDataSubmit({
            isPathnameAttentionsOrSafeties,
            isPathnameSchedules,
            pathname,
            searchCondition,
            values,
          })
          getSchedules(dataSearch, true)
        } else {
          const values = setFormDataAdvanced(searchCondition)
          formAdvanced.setFieldsValue(values)
          const dataSearch = convertAdvancedSearchDataSubmit({ values })
          getSchedules(dataSearch, true)
          setIsSearchAdvance(true)
        }
      } else {
        form.setFieldsValue({
          ...SCHEDULES_BASIC_SEARCH_INITIAL_VALUES,
        })
        formAdvanced.setFieldsValue(SCHEDULES_ADVANCED_SEARCH_INITIAL_VALUES)
        form.setFieldValue("fullName", state?.fullName)
        getSchedules({
          basic: {
            fullName: state?.fullName || "",
          },
          travelerId: _travelerId,
          trip: {
            departureDateTime: formatDateSaveDB(departureDateTime?.toString()),
          },
        })
      }
      if (pathname === SCR210) form.setFieldValue("fullName", fullName)
      getData()
    }

    const disabledDateTo: RangePickerProps["disabledDate"] = (current) => {
      const departureDate = form?.getFieldValue("departureDateTimeDate")
      if (departureDate) {
        return (
          current &&
          current < moment(departureDate).add("days", -1).endOf("day")
        )
      }
      return false
    }

    useEffect(() => {
      if (dispatchTypeAll) {
        const _dispatchType = dispatchTypeAll
          ?.map((item) => ({
            text: String(getValueByLanguage(item.value3, item.value4)),
            value: item.key2,
          }))
          .filter(
            (item, index, self) =>
              index === self.findIndex((_item) => _item.value === item.value),
          )
        setDispatchType(_dispatchType)
      }
    }, [dispatchTypeAll])

    useEffect(() => {
      // ユーザの画面アクセス許可チェックを処理する。
      if (isAuthorities) {
        // 「SCR973_エラー情報の表示画面」に遷
        const { pathname, state } = backToLogin()
        push({ pathname, state })
      } else handleData()
    }, [])

    return {
      allCountries,
      arrivalHourItems,
      arrivalMinuteItems,
      breadcrumbItems,
      cities,
      columns,
      countries: isGetCountries ? countries : allCountries,
      dataSource,
      departments,
      disabledDateTo,
      dispatchType,
      dispatchTypeDetail,
      form,
      formAdvanced,
      fullName,
      goBack,
      goToAttention,
      goToSafety,
      goToSchedulesAdd,
      goToSchedulesConfirmDelete,
      goToSchedulesDetail,
      goToSchedulesEdit,
      handleAdvancedSearch,
      handleCancelModalExport,
      handleExportFile,
      handleOpenModalExport,
      handleSearch,
      isDefaultValueArrivalDate,
      isDefaultValueDepartureDate,
      isDisabledBtnCreateOrSend,
      isDisabledBtnDelete,
      isDisabledBtnRefer,
      isDisabledDispatchTypeDetail,
      isFromSCR114UserSearch,
      isHiddenBtnCreateOrSendAttentions,
      isHiddenBtnCreateOrSendSafeties,
      isHiddenBtnDelete,
      isHiddenBtnEdit,
      isHiddenBtnExportCsv,
      isHiddenBtnExportExcel,
      isHiddenBtnExportExcelSafeties,
      isHiddenBtnExportExcelTravelers,
      isHiddenBtnRefer,
      isHiddenBtnResetForm,
      isHiddenBtnSearchBasic,
      isLoading: isLoadingTable,
      isLoadingLayout,
      isOpen,
      isOpenLoadData,
      isOpenModalExport,
      isOpenShowData,
      isOverseasResident,
      isPathnameSchedules,
      isPathnameSchedulesOrTravelers,
      isResetTable,
      isSearchAdvance,
      messageConfirmDownload,
      onBlurAdvanced,
      onBlurBasic,
      onCancelCustomLoadData,
      onCancelSearchAdvance,
      onChange,
      onChangeArrivalDate,
      onChangeCountryCode,
      onChangeDepartureDate,
      onChangeDispatchType,
      onChangeTable,
      onChangeWorkPlaceLocation,
      onClickReset,
      onClickResetSearchAdvance,
      onKeyDownAdvanced,
      onKeyDownBasic,
      onOkLoadData,
      onOkShowData,
      onOpenSearchAdvance,
      onValuesChangeFormSearchAdvanced,
      overRecordLabel,
      perItem,
      personPrefectureResidence,
      purposeTravel,
      rowSelection,
      transportation,
    }
  }
