import { useEffect } from "react"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { useVariables } from "../useVariables"
import { Routers } from "../../../../../../../constant/Routers"
import { UseAttentionsAnswerType } from "../../../../models/answer"
import { replaceString } from "../../../../../../../utils/replaceString"

const { SCR316, SCR316_HISTORY, SCR317, SCR920 } = Routers

export const useAttentionsAnswer = (): UseAttentionsAnswerType => {
  const {
    breadcrumbItems,
    isFromEmail,
    isLoadingLayout,
    isNotAccessScreen,
    isVisibleBtnBack,
    isVisibleBtnDetail,
    isVisibleBtnList,
    push,
    setIsLoadingLayout,
    state,
  } = useVariables()

  const goToHome = () => {
    //「SCR920_ホ－ム画面」に遷移する。
    push(SCR920)
  }

  const goToAttentionsList = () => {
    //「SCR316_注意喚起の一覧画面」に遷移する。
    push(state?.isFromSCR316 ? SCR316 : SCR316_HISTORY)
  }

  const goToAttentionsDetail = () => {
    // 「SCR317_注意喚起の詳細画面」に遷移する。
    if (state?.attentionId) {
      push(replaceString(SCR317, state.attentionId))
    }
  }

  const handleCloseBrowser = () => {
    // ブラウザを閉じる。
    window.close()
  }

  useEffect(() => {
    // ユーザの画面アクセス許可チェックを処理する。
    if (isNotAccessScreen) {
      // 「SCR973_エラー情報の表示画面」に遷移する。
      const { pathname, state } = backToLogin()
      push({ pathname, state })
    } else {
      setTimeout(() => {
        setIsLoadingLayout(false)
      }, 500)
    }
  }, [])

  return {
    breadcrumbItems,
    goToAttentionsDetail,
    goToAttentionsList,
    goToHome,
    handleCloseBrowser,
    isFromEmail,
    isLoadingLayout,
    isVisibleBtnBack,
    isVisibleBtnDetail,
    isVisibleBtnList,
  }
}
