import { useHistory, useLocation } from "react-router-dom"
import { Routers } from "../../../../../../../../constant/Routers"
import { RolesSettingStateType } from "../../../../../models/roles/setting"

const { SCR920, SCR950, SCR953 } = Routers

export const useRouters = (patternId?: string) => {
  const { push } = useHistory()
  const { state } = useLocation<RolesSettingStateType>()

  // 「SCR950_機能限定管理者設定画面」に遷移する。
  const goToSubAdminSetting = () => {
    push({
      pathname: SCR950,
      state: {
        searchCondition: {
          ...state?.searchCondition,
          patternId: patternId || state?.searchCondition?.patternId,
        },
        sourceURL: SCR953,
      },
    })
  }

  const goBack = () => {
    // 「SCR952_機能限定管理者一覧画面」から遷移する場合
    // 「SCR951_機能限定管理者設定完了画面」から遷移する場合
    // 「SCR950_機能限定管理者設定画面」から遷移する場合
    if (state?.sourceURL)
      push({
        pathname: state.sourceURL,
        state: {
          ...state,
          sourceURL: SCR953,
        },
      })
    // メニューから遷移する場合
    else push(SCR920)
  }

  return {
    goBack,
    goToSubAdminSetting,
  }
}
