import { useEffect } from "react"
import { Routers } from "../../../../../../../constant/Routers"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import {
  TravelersFormDataType,
  UseTravelersAddType,
} from "../../../../models/add"
import { useVariables } from "../useVariables"
import { getUserInformation } from "../../../../../../../utils/getUserInformation"

const { SCR920 } = Routers

export const useTravelersAdd =
  (): UseTravelersAddType<TravelersFormDataType> => {
    const {
      callApi,
      departments,
      dispatchType,
      form,
      isAccompanyingFamily,
      isDisabledSubmit,
      isLoading,
      isLoadingLayout,
      isOpen,
      isRoleSubAdmin,
      isTravelerRegister,
      onBlurUserId,
      onCancel,
      onFinish,
      onOk,
      onValuesChange,
      push,
    } = useVariables()

    // 「キャンセル」ボタンを押下した時に画面遷移処理のイベントを作成する。
    const goToHome = () => {
      // 「SCR920_ホーム画面」に遷移する。
      push(SCR920)
    }

    useEffect(() => {
      // ユーザーの画面アクセス許可チェックを処理する。
      if (!isTravelerRegister) {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const { pathname, state } = backToLogin()
        push({ pathname, state })
      } else {
        callApi()
        if (isRoleSubAdmin) {
          const userInformation = getUserInformation()
          form.setFieldValue("registerRequestUserId", userInformation?.userId)
        }
      }
    }, [])

    return {
      departments,
      dispatchType,
      form,
      goToHome,
      isAccompanyingFamily,
      isDisabledSubmit,
      isLoading,
      isLoadingLayout,
      isOpen,
      onBlurUserId,
      onCancel,
      onFinish,
      onOk,
      onValuesChange,
    }
  }
