import { FC } from "react"
import { Translate } from "../../../../../constant/Translate"
import { Alert } from "../../../../../_webui/layout/components/Alert"
import { ButtonGroup } from "../../../../../_webui/layout/components/Button/Group"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { BreakText } from "../../../../../_webui/layout/components/Typography/BreakText"
import { useAnswerSuccess } from "./modules/useAnswerSuccess"

const {
  TEXT_T018,
  TEXT_T411,
  TEXT_T410,
  TEXT_T291,
  TEXT_T412,
  TEXT_T336,
  TEXT_T413,
} = Translate

export const SafetiesAnswerSuccess: FC = () => {
  const {
    breadcrumbItems,
    goToHome,
    goToSafeties,
    goToSafetiesDetail,
    handleCloseTab,
    isFromEmail,
    isLoadingLayout,
    isVisibleBtnDetail,
    isVisibleBtnHome,
    isVisibleBtnList,
  } = useAnswerSuccess()

  return (
    <Layout
      breadcrumbItems={breadcrumbItems}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T410}
    >
      <Card heading={TEXT_T410}>
        <Alert
          type="success"
          content={<BreakText above={TEXT_T411} below={TEXT_T412} />}
        />
        <ButtonGroup
          buttonItems={[
            {
              isHidden: !isVisibleBtnHome,
              onClick: goToHome,
              order: 2,
              text: TEXT_T018,
              type: "ghost",
            },
            {
              isHidden: !isVisibleBtnDetail,
              onClick: goToSafetiesDetail,
              order: 1,
              text: TEXT_T336,
              type: "primary",
            },
            {
              isHidden: !isVisibleBtnList,
              onClick: goToSafeties,
              order: 1,
              text: TEXT_T291,
            },
            {
              isHidden: !isFromEmail,
              onClick: handleCloseTab,
              order: 1,
              text: TEXT_T413,
              type: "primary",
            },
          ]}
        />
      </Card>
    </Layout>
  )
}
