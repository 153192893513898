import { RuleObject } from "antd/lib/form"
import { Regex } from "../../../constant/Regex"
import { Translate } from "../../../constant/Translate"

const { REGEX_ROMAJI_NAME } = Regex
const { MESSAGE_E0007 } = Translate

export const validatorRomajiName = () => ({
  validator(_: RuleObject, value: string) {
    if (value && !REGEX_ROMAJI_NAME.exec(value)) {
      return Promise.reject(new Error(MESSAGE_E0007))
    }
    return Promise.resolve()
  },
})
