/* eslint-disable max-lines */
import { FC, Fragment } from "react"
import { Translate } from "../../../../../../../constant/Translate"
import { ButtonGroup } from "../../../../../../../_webui/layout/components/Button/Group"
import { FilesManagerButtonGroupProps } from "../../../../models/files/manager"
import { Regex } from "../../../../../../../constant/Regex"

const { REGEX_IS_DESKTOP } = Regex
const { TEXT_T034, TEXT_T033, TEXT_T505, TEXT_T510 } = Translate

export const FilesManagerButtonGroup: FC<FilesManagerButtonGroupProps> = (
  props,
) => {
  const {
    goToFilesUpload,
    handleDownload,
    handlePreviewFile,
    isDisabledBtnDelete,
    isDisabledBtnDownload,
    isDisabledBtnPreview,
    isVisibleBtnDelete,
    isVisibleBtnReference,
    isVisibleBtnRegister,
    onOpen,
  } = props

  return REGEX_IS_DESKTOP ? (
    <Fragment>
      <ButtonGroup
        buttonItems={[
          {
            isDisabled: isDisabledBtnDelete,
            isHidden: !isVisibleBtnDelete,
            // 確認ダイアログボックスを作成する。
            onClick: onOpen,
            order: 4,
            text: TEXT_T034,
            type: "danger",
          },
          {
            isDisabled: isDisabledBtnPreview,
            isHidden: !isVisibleBtnReference,
            onClick: handlePreviewFile,
            order: 3,
            text: TEXT_T033,
          },
        ]}
      />
      <ButtonGroup
        buttonItems={[
          {
            isDisabled: isDisabledBtnDownload,
            isHidden: !isVisibleBtnReference,
            onClick: handleDownload,
            order: 2,
            text: TEXT_T510,
            type: "primary",
          },
          {
            isHidden: !isVisibleBtnRegister,
            onClick: goToFilesUpload,
            order: 1,
            text: TEXT_T505,
            type: "primary",
          },
        ]}
        isMargin={false}
      />
    </Fragment>
  ) : (
    <ButtonGroup
      buttonItems={[
        {
          isDisabled: isDisabledBtnDelete,
          isHidden: !isVisibleBtnDelete,
          // 確認ダイアログボックスを作成する。
          onClick: onOpen,
          order: 4,
          text: TEXT_T034,
          type: "danger",
        },
        {
          isDisabled: isDisabledBtnPreview,
          isHidden: !isVisibleBtnReference,
          onClick: handlePreviewFile,
          order: 3,
          text: TEXT_T033,
        },
        {
          isDisabled: isDisabledBtnDownload,
          isHidden: !isVisibleBtnReference,
          onClick: handleDownload,
          order: 2,
          text: TEXT_T510,
          type: "primary",
        },
        {
          isHidden: !isVisibleBtnRegister,
          onClick: goToFilesUpload,
          order: 1,
          text: TEXT_T505,
          type: "primary",
        },
      ]}
    />
  )
}
