import "./style/index.scss"
import "../../Tooltip/style/index.scss"
import { Form } from "antd"
import { FC, Fragment } from "react"
import { FormItemProps } from "./models"
import { ItemLabel } from "./Label"
import { GlobalStyleFormItem } from "./styled"

const { Item } = Form

export const FormItem: FC<FormItemProps> = (props) => {
  const {
    children,
    className,
    extra,
    isBgBlue,
    isHidden,
    isHiddenLabel,
    label,
    name,
    required,
    rules,
    style,
    tooltip,
    valuePropName,
    ...res
  } = props

  return (
    <Fragment>
      <GlobalStyleFormItem />
      <Item
        className={className}
        extra={extra}
        hidden={isHidden}
        label={
          label && (
            <ItemLabel
              isBgBlue={isBgBlue}
              isHiddenLabel={isHiddenLabel}
              label={label}
              required={required}
              tooltip={tooltip}
            />
          )
        }
        name={name}
        required={required}
        rules={rules}
        style={style}
        valuePropName={valuePropName}
        {...res}
      >
        {children}
      </Item>
    </Fragment>
  )
}
