/* eslint-disable max-lines */
import { Routers } from "../../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../../infrastructure/axiosError/models"
import { pushStateError } from "../../../../../../../../utils/pushStateError"
import { travelerApi } from "../../../../../../travelers/apis"
import { commonApi } from "../../../../../apis"
import { SubAdminFormSearchConditionType } from "../../../../../models/subAdmin/list"
import { GetUserAuthorityTypeSettingResType } from "../../../../../models/subAdmin/setting"
import { convertDataSource } from "./convertDataSource"
import { useVariablesApi } from "../useVariablesApi"
import { authoritiesApi } from "../../../../../../../../infrastructure/handlers/authorities"
import { AuthorityPatternResType } from "../../../../../../../../infrastructure/handlers/authorities/models"
import { OptionItemType } from "../../../../../../../../_webui/layout/components/Select/models"

const { SCR953, SCR973 } = Routers

export const useApi = () => {
  const {
    authorityPatterns,
    form,
    formSearch,
    goBack,
    goToRolesSetting,
    goToSubAdmin,
    goToSubAdminConfirm,
    handleSetDefaultSortedInfo,
    isDisabledBtnSave,
    isLoading,
    isLoadingLayout,
    onChangePage,
    onChangeTable,
    patternId,
    patternIds,
    perItem,
    push,
    rowSelection,
    searchCondition,
    selectedRowKeys,
    setAuthorityPatterns,
    setIsDisabledBtnSave,
    setIsLoading,
    setIsLoadingLayout,
    setPatternId,
    setSearchCondition,
    setSelectedRowKeys,
    setTravelerList,
    setValues,
    sortedInfo,
    state,
    travelerList,
    values,
  } = useVariablesApi()

  const getUserAuthorityTypeSetting = (
    data: SubAdminFormSearchConditionType,
  ) => {
    setTravelerList([])
    setSelectedRowKeys([])
    handleSetDefaultSortedInfo()

    // APIを呼び出し、機能限定管理者設定ユーザ情報を受け取る。
    commonApi
      .getUserAuthorityTypeSetting(data)
      .then(
        (
          userAuthorityTypeSettingResponse: GetUserAuthorityTypeSettingResType[],
        ) => {
          const _travelerList = convertDataSource(
            userAuthorityTypeSettingResponse,
          )
          setTravelerList(_travelerList)
          setIsLoading(false)
          setIsLoadingLayout(false)
        },
      )
      .catch((error: AxiosErrorType) => {
        const stateError = pushStateError(error)
        push({ pathname: SCR973, state: stateError })
      })
  }

  const onOk = () => {
    if (typeof values === "object") {
      setIsLoading(true)
      // APIを呼び出し、ユーザに権限を付与する。
      travelerApi
        .grantAuthorityPattern(values)
        .then(() => {
          goToSubAdminConfirm()
        })
        .catch((error: AxiosErrorType) => {
          // 「SCR973_エラー情報の表示画面」に遷移する。
          const stateError = pushStateError(error)
          if (error?.debug?.response?.data?.message === "E0033") {
            const params = error?.debug?.response?.data?.params
            const paramList = params.split(",")
            const _travelerList = travelerList.filter((item) =>
              paramList.includes(item.travelerId),
            )
            const errorList = _travelerList
              .map((item) => item.userId)
              .join(", ")
            push({
              pathname: SCR973,
              state: {
                ...stateError,
                message: stateError.message.replace(params, errorList),
              },
            })
          } else {
            push({ pathname: SCR973, state: stateError })
          }
        })
    }
  }

  const callApi = () => {
    // APIを呼び出し、権限パターン一覧を取得する。
    authoritiesApi
      .getAuthorityPatterns(true)
      .then((res: AuthorityPatternResType[]) => {
        const optionItem: OptionItemType[] = res
          .filter((item) => !patternIds.includes(String(item.patternId)))
          .map((item) => ({
            text: item.patternName,
            value: item.patternId,
          }))
        setAuthorityPatterns(optionItem)
        if (searchCondition?.patternId) {
          const _patternId =
            optionItem
              .find((item) => item.value === searchCondition.patternId)
              ?.value?.toString() || ""
          form.setFieldsValue({
            patternId: _patternId,
          })
        }
        formSearch.setFieldsValue(searchCondition)
        if (state?.sourceURL !== SCR953 && !state?.isFromSCR954)
          getUserAuthorityTypeSetting({ ...searchCondition, patternId: "" })
      })
      .catch((error: AxiosErrorType) => {
        const stateError = pushStateError(error)
        push({ pathname: SCR973, state: stateError })
      })
  }

  return {
    authorityPatterns,
    callApi,
    form,
    formSearch,
    getUserAuthorityTypeSetting,
    goBack,
    goToRolesSetting,
    goToSubAdmin,
    isDisabledBtnSave,
    isLoading,
    isLoadingLayout,
    onChangePage,
    onChangeTable,
    onOk,
    patternId,
    perItem,
    rowSelection,
    searchCondition,
    selectedRowKeys,
    setIsDisabledBtnSave,
    setIsLoading,
    setPatternId,
    setSearchCondition,
    setSelectedRowKeys,
    setValues,
    sortedInfo,
    travelerList,
  }
}
