import styled, { css } from "styled-components"
import { StyledCenterProps } from "../models"

const StyledCenter = styled.div<StyledCenterProps>`
  ${(props) =>
    props.isCenter &&
    css`
      .ant-form {
        &-item-control {
          transform: translateY(4px) !important;
          &-input-content {
            text-align: center;
          }
        }
      }
    `}
`

export const Styled = {
  StyledCenter,
}
