import { Translate } from "../../../../../../../constant/Translate"
import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../_webui/layout/components/Table/models"
import {
  TravelerDeleteType,
  UseTravelerConfirmDeleteTableConfigProps,
} from "../../../../models/confirmDelete"

const { TEXT_T003, TEXT_T006, TEXT_T025 } = Translate

export const useTableConfig = (
  props: UseTravelerConfirmDeleteTableConfigProps,
): UseTableConfigType<TravelerDeleteType> => {
  const { isRoleAdmin, isRoleSubAdmin } = props
  const columns: ColumnType<TravelerDeleteType>[] =
    isRoleAdmin || isRoleSubAdmin
      ? [
          {
            dataIndex: "fullNameKanji",
            title: TEXT_T003,
            width: 250,
          },
          {
            dataIndex: "fullNameRomaji",
            title: TEXT_T006,
            width: 250,
          },
          {
            dataIndex: "dispatchTypeDetail",
            title: TEXT_T025,
            width: 300,
          },
        ]
      : []

  return {
    columns,
  }
}
