import styled from "styled-components"

const StyledContainer = styled.div`
  position: relative;
  .ant-form {
    position: absolute;
    right: 0;
    top: -18px;
    min-width: 700px;
    &-item {
      margin: 0;
    }
  }
`

export const Styled = {
  StyledContainer,
}
