/* eslint-disable max-lines */
import { FC, Fragment } from "react"
import { ItemAutoComplete } from "../../../../../../../../../_webui/layout/components/Form/Item/AutoComplete"
import { ItemInput } from "../../../../../../../../../_webui/layout/components/Form/Item/Input"
import { ItemSelect } from "../../../../../../../../../_webui/layout/components/Form/Item/Select"
import { GeneralCode } from "../../../../../../../../../constant/GeneralCode"
import { Translate } from "../../../../../../../../../constant/Translate"
import { validatorHalfSize } from "../../../../../../../../../utils/validator/halfSize"
import { validatorRangeDate } from "../../../../../../../../../utils/validator/rangeDate"
import { validatorRequiredField } from "../../../../../../../../../utils/validator/requiredField"
import { SchedulesAddDetailRow } from "../../../../../../constants/add/addDetailRow"
import { SchedulesAddTableButtonGroup } from "../../../../Table/ButtonGroup"
import { SchedulesAddDetailTabProps } from "./models"
import { Styled } from "../styled"
import { ItemDatePicker } from "../../../../../../../../../_webui/layout/components/Form/Item/DatePicker"
import { ItemTimePicker2 } from "../../../../../../../../../_webui/layout/components/Form/Item/TimePicker2"
import { requiredTime } from "../../../../../../../../../utils/validator/requiredTime"
import { ItemCheckbox } from "../../../../../../../../../_webui/layout/components/Form/Item/Checkbox"
import { requiredField } from "../../../../../../../../../utils/requiredField"

const {
  TEXT_T152,
  TEXT_T155,
  TEXT_T163,
  TEXT_T169,
  TEXT_T171,
  TEXT_T173,
  TEXT_T175,
  TEXT_T183,
  TEXT_T202,
  TEXT_T205,
  TEXT_T206,
  TEXT_T208,
  TEXT_T209,
  TEXT_T220,
  TEXT_T282,
  TEXT_T283,
} = Translate

const { OTHER, TPTT001 } = GeneralCode
const { ARRIVAL, DEPARTURE } = SchedulesAddDetailRow
const { StyledTitle } = Styled

export const SchedulesAddDetailTab: FC<SchedulesAddDetailTabProps> = (
  props,
) => {
  const {
    allCountries,
    currentIndex,
    flightNumberList,
    handleAddDetailItem,
    handleDeleteDetailItem,
    isSubmitValidate,
    isVisibleBtn,
    onBlur,
    onChangeCity,
    onChangeCountry,
    onChangeDate,
    onChangeTransportation,
    onClearDepartureFlight,
    onKeyDown,
    onSelectDepartureFlight,
    schedulesAddDetailItem,
    transportationList,
  } = props

  const { index } = schedulesAddDetailItem

  const itemName = `schedulesAddDetail.${index}`
  const arrivalDate = `${itemName}.arrivalDate`
  const departureDate = `${itemName}.departureDate`

  const _validatorRangeDate = isSubmitValidate
    ? [
        validatorRangeDate({
          namePathEndDate: arrivalDate,
          namePathStartDate: departureDate,
        }),
      ]
    : []

  return (
    <Fragment>
      <SchedulesAddTableButtonGroup
        addText={TEXT_T282}
        deleteText={TEXT_T283}
        handleAdd={handleAddDetailItem}
        handleDelete={() =>
          handleDeleteDetailItem(schedulesAddDetailItem.travelDetailId)
        }
        isDisabled={false}
        isVisibleBtn={isVisibleBtn}
      />

      <ItemInput isHidden name={`${itemName}.travelDetailId`} />

      <ItemSelect
        label={TEXT_T202}
        name={`${itemName}.transportation`}
        onChange={(value: string) =>
          onChangeTransportation(value, currentIndex)
        }
        required
        rules={[validatorRequiredField]}
        selectItem={transportationList}
      />

      <ItemCheckbox
        label={TEXT_T220}
        name={`${itemName}.isTransit`}
        text={TEXT_T220}
      />

      <ItemAutoComplete
        clearIndex={index}
        isBgBlue
        label={TEXT_T173}
        maxLength={100}
        name={`${itemName}.departureFlightNumber`}
        onClear={() =>
          onClearDepartureFlight(
            schedulesAddDetailItem.travelDetailId,
            schedulesAddDetailItem.index,
          )
        }
        onSelect={(value) =>
          onSelectDepartureFlight(
            value,
            schedulesAddDetailItem.travelDetailId,
            schedulesAddDetailItem.index,
          )
        }
        options={flightNumberList}
        required
        rules={[
          ...requiredField(schedulesAddDetailItem.transportation === TPTT001),
          validatorHalfSize,
        ]}
      />

      <ItemSelect
        label={TEXT_T205}
        name={`${itemName}.departureCountryCode`}
        onChange={(value) =>
          onChangeCountry(
            schedulesAddDetailItem.travelDetailId,
            value,
            DEPARTURE,
          )
        }
        required
        rules={[validatorRequiredField]}
        selectItem={allCountries}
      />

      <ItemSelect
        label={TEXT_T206}
        name={`${itemName}.departureCityCode`}
        onChange={(value) =>
          onChangeCity(schedulesAddDetailItem.travelDetailId, value, DEPARTURE)
        }
        required
        rules={[validatorRequiredField]}
        selectItem={schedulesAddDetailItem.departureCities}
      />

      <ItemInput
        isBgBlue
        isDisabled={schedulesAddDetailItem.departureCityCode !== OTHER}
        label={TEXT_T175}
        maxLength={200}
        name={`${itemName}.departureCityName`}
        required
        rules={requiredField(
          schedulesAddDetailItem.departureCityCode === OTHER,
        )}
      />

      <ItemDatePicker
        label={TEXT_T155}
        name={departureDate}
        onBlur={(event) => onBlur(event, TEXT_T155, departureDate)}
        onKeyDown={(event) => onKeyDown(event, TEXT_T155, departureDate)}
        required
        rules={[validatorRequiredField, ..._validatorRangeDate]}
      />
      <ItemTimePicker2
        label={TEXT_T163}
        name={`${itemName}.departureTime`}
        required
        rules={[
          requiredTime({
            namePath: `${itemName}.departureTime`,
            namePathHour: `${itemName}.departureHour`,
            namePathMinute: `${itemName}.departureMinute`,
          }),
        ]}
        selectHourName={`${itemName}.departureHour`}
        selectMinutesName={`${itemName}.departureMinute`}
      />

      <ItemSelect
        label={TEXT_T208}
        name={`${itemName}.arrivalCountryCode`}
        onChange={(value) =>
          onChangeCountry(schedulesAddDetailItem.travelDetailId, value, ARRIVAL)
        }
        required
        rules={[validatorRequiredField]}
        selectItem={allCountries}
      />

      <ItemSelect
        label={TEXT_T209}
        name={`${itemName}.arrivalCityCode`}
        onChange={(value) =>
          onChangeCity(schedulesAddDetailItem.travelDetailId, value, ARRIVAL)
        }
        required
        rules={[validatorRequiredField]}
        selectItem={schedulesAddDetailItem.arrivalCities}
      />

      <ItemInput
        isBgBlue
        isDisabled={schedulesAddDetailItem.arrivalCityCode !== OTHER}
        label={TEXT_T183}
        maxLength={200}
        name={`${itemName}.arrivalCityName`}
        required
        rules={requiredField(schedulesAddDetailItem.arrivalCityCode === OTHER)}
      />

      <ItemDatePicker
        label={TEXT_T169}
        name={arrivalDate}
        onBlur={(event) => onBlur(event, TEXT_T169, arrivalDate)}
        onChange={() => onChangeDate(departureDate)}
        onKeyDown={(event) => onKeyDown(event, TEXT_T169, arrivalDate)}
        required
        rules={[validatorRequiredField, ..._validatorRangeDate]}
      />
      <ItemTimePicker2
        label={TEXT_T171}
        required
        name={`${itemName}.arrivalTime`}
        rules={[
          requiredTime({
            namePath: `${itemName}.arrivalTime`,
            namePathHour: `${itemName}.arrivalHour`,
            namePathMinute: `${itemName}.arrivalMinute`,
          }),
        ]}
        selectHourName={`${itemName}.arrivalHour`}
        selectMinutesName={`${itemName}.arrivalMinute`}
      />

      <ItemInput
        label={
          <Fragment>
            <StyledTitle titleLeft={TEXT_T152} />
            {schedulesAddDetailItem?.remarkNames?.remarksName1}
          </Fragment>
        }
        maxLength={200}
        name={`${itemName}.othersRemarks1`}
      />

      <ItemInput
        name={`${itemName}.othersRemarks2`}
        maxLength={200}
        label={schedulesAddDetailItem?.remarkNames?.remarksName2}
      />

      <ItemInput
        name={`${itemName}.othersRemarks3`}
        maxLength={200}
        label={schedulesAddDetailItem?.remarkNames?.remarksName3}
      />

      <ItemInput
        name={`${itemName}.othersRemarks4`}
        maxLength={200}
        label={schedulesAddDetailItem?.remarkNames?.remarksName4}
      />

      <ItemInput
        name={`${itemName}.othersRemarks5`}
        maxLength={200}
        label={schedulesAddDetailItem?.remarkNames?.remarksName5}
      />
    </Fragment>
  )
}
