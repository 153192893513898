import { Translate } from "../../../../../../../../../../constant/Translate"
import { GridItemType } from "../../../../../../../../../../_webui/layout/components/Grid/models"
import { TravelersEditSuccessDetailType } from "../../../../../../../models/editSuccess"

const {
  TEXT_T078,
  TEXT_T079,
  TEXT_T080,
  TEXT_T081,
  TEXT_T082,
  TEXT_T095,
  TEXT_T246,
  TEXT_T247,
  TEXT_T248,
  TEXT_T249,
  TEXT_T250,
} = Translate

export const getSpouseInfor = (
  data: TravelersEditSuccessDetailType,
  isRoleAdmin: boolean,
  isRoleSubAdmin: boolean,
  userIdForChangeRequest: string,
): GridItemType[] => {
  const spouseInfor = [
    { key: TEXT_T246, text: data.othersRemarks1 },
    { key: TEXT_T247, text: data.othersRemarks2 },
    { key: TEXT_T248, text: data.othersRemarks3 },
    { key: TEXT_T249, text: data.othersRemarks4 },
    { key: TEXT_T250, text: data.othersRemarks5 },
  ]
  const spouseInforSubAdmin = [
    ...spouseInfor,
    { key: TEXT_T078, text: data.othersAdministratorRemarks1 },
    { key: TEXT_T079, text: data.othersAdministratorRemarks2 },
    { key: TEXT_T080, text: data.othersAdministratorRemarks3 },
    { key: TEXT_T081, text: data.othersAdministratorRemarks4 },
    { key: TEXT_T082, text: data.othersAdministratorRemarks5 },
  ]
  const spouseInforAdmin = [
    ...spouseInforSubAdmin,
    { key: TEXT_T095, text: userIdForChangeRequest },
  ]
  return isRoleAdmin
    ? spouseInforAdmin
    : isRoleSubAdmin
    ? spouseInforSubAdmin
    : spouseInfor
}
