import { Routers } from "../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { scheduleApi } from "../../../../apis"
import { ScheduleAddSuccessDataType } from "../../../../models/addSuccess"
import { useVariablesApi } from "../useVariablesApi"
import { convertDataSource } from "./convertDataSource"

const { SCR973 } = Routers

export const useApi = (isVisibleOtherRemarks: boolean) => {
  const {
    assignmentGridItem,
    confirmGridItem,
    detailsColumnsFirst,
    detailsGridItem,
    detailsTabsItems,
    generalMastersRemark,
    insuranceGridItem,
    isLoadingLayout,
    isVisibleBtnDelete,
    lodgingsColumns,
    lodgingsTabsItems,
    otherGridItem,
    personalGridItem,
    push,
    scheduleDetails,
    scheduleLodgings,
    scheduleStays,
    schedulesData,
    setIsLoadingLayout,
    setIsVisibleBtnDelete,
    setSchedulesData,
    staysColumns,
    staysTabsItems,
    travelScheduleId,
  } = useVariablesApi(isVisibleOtherRemarks)

  const getSchedule = () => {
    // 渡航者予定情報を取得する。
    scheduleApi
      .getSchedule(travelScheduleId)
      .then((scheduleInfoResponse: ScheduleAddSuccessDataType) => {
        const data = convertDataSource(scheduleInfoResponse)
        const dataDetails = data.details.map((item) => ({
          ...item,
          remarkNames: generalMastersRemark?.find(
            (itemRemark) => itemRemark.key1 === item.arrivalCountryCode,
          ),
        }))
        setSchedulesData({ ...data, details: dataDetails })
        setIsLoadingLayout(false)
        setIsVisibleBtnDelete(!!data.info.isSubBumon)
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const stateError = pushStateError(error)
        push({ pathname: SCR973, state: stateError })
      })
  }

  return {
    assignmentGridItem,
    confirmGridItem,
    detailsColumnsFirst,
    detailsGridItem,
    detailsTabsItems,
    generalMastersRemark,
    getSchedule,
    insuranceGridItem,
    isLoadingLayout,
    isVisibleBtnDelete,
    lodgingsColumns,
    lodgingsTabsItems,
    otherGridItem,
    personalGridItem,
    scheduleDetails,
    scheduleLodgings,
    scheduleStays,
    schedulesData,
    setIsLoadingLayout,
    staysColumns,
    staysTabsItems,
    travelScheduleId,
  }
}
