/* eslint-disable complexity */
import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const {
    isAttentionsBrowseResponse,
    isCommonRefer,
    isCommonTravelerRefer,
    isRoleSubAdmin,
    isRoleUser,
  } = useAuthority()

  const isNotAccessScreen = !isCommonTravelerRefer

  const isVisibleBtnBack = (isRoleSubAdmin || isRoleUser) && isCommonRefer
  const isVisibleBtnDetail = isRoleSubAdmin && isAttentionsBrowseResponse
  const isVisibleBtnList =
    (isRoleSubAdmin || isRoleUser) && isCommonTravelerRefer

  return {
    isNotAccessScreen,
    isVisibleBtnBack,
    isVisibleBtnDetail,
    isVisibleBtnList,
  }
}
