const FNC2_Add_Attention = "/v1"
const FNC2_Confirm_Attention = "/v1/travelers/confirm"
const FNC2_Count = "/v1/{0}/travelers/count"
const FNC2_Export = "/v1/{0}/travelers/export"
const FNC2_Get_Attention = "/v1/{0}"
const FNC2_Get_Attention_Travelers = "/v1/{0}/travelers"
const FNC2_Get_Attentions = "/v1/browse"

export const ATTENTION_ENDPOINT = {
  FNC2_Add_Attention,
  FNC2_Confirm_Attention,
  FNC2_Count,
  FNC2_Export,
  FNC2_Get_Attention,
  FNC2_Get_Attention_Travelers,
  FNC2_Get_Attentions,
}
