import { FC } from "react"
import { Route, Switch } from "react-router-dom"
import PrivateRoute from "../../../../app/routing/privateRoute"
import { Routers } from "../../../../constant/Routers"
import { SafetiesAnswerPage } from "../../../../app/pages/safeties/answer"
import { SafetiesAnswerSuccessPage } from "../../../../app/pages/safeties/answerSuccess"
import { SafetiesClonePage } from "../../../../app/pages/safeties/clone"
import { SafetiesDetailPage } from "../../../../app/pages/safeties/detail"
import { SafetiesEditPage } from "../../../../app/pages/safeties/edit"
import { SafetiesListHistoryPage } from "../../../../app/pages/safeties/list/history"
import { SafetiesInfoPage } from "../../../../app/pages/safeties/info"
import { SafetiesListPage } from "../../../../app/pages/safeties/list"
import { SafetyPage } from "../../../../app/pages/safeties/safety"
import { SafetiesSuccessPage } from "../../../../app/pages/safeties/success"

const {
  SCR412,
  SCR413,
  SCR415,
  SCR416_HISTORY,
  SCR416,
  SCR417,
  SCR418,
  SCR419,
  SCR420,
  SCR962,
} = Routers

export const SafetiesRoute: FC = () => (
  <Switch>
    <PrivateRoute component={SafetyPage} exact path={SCR412} />
    <PrivateRoute component={SafetiesEditPage} exact path={SCR413} />
    <PrivateRoute component={SafetiesSuccessPage} exact path={SCR415} />
    <PrivateRoute
      component={SafetiesListHistoryPage}
      exact
      path={SCR416_HISTORY}
    />
    <PrivateRoute component={SafetiesListPage} exact path={SCR416} />
    <PrivateRoute component={SafetiesDetailPage} exact path={SCR417} />
    <Route component={SafetiesInfoPage} exact path={SCR418} />
    <PrivateRoute component={SafetiesAnswerPage} exact path={SCR419} />
    <PrivateRoute component={SafetiesAnswerSuccessPage} exact path={SCR420} />
    <PrivateRoute component={SafetiesClonePage} exact path={SCR962} />
  </Switch>
)
