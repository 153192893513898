import { Form } from "antd"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import { useModal } from "../../../../../../../utils/hook/useModal"
import { TravelersFormDataType } from "../../../../models/add"
import { OptionItemType } from "../../../../../../../_webui/layout/components/Select/models"
import { useVariablesAuthority } from "../useVariablesAuthority"

export const useVariablesForm = () => {
  const [form] = Form.useForm()
  const { isOpen, onCancel, onOpen } = useModal()
  const { push } = useHistory()
  const { isRoleSubAdmin, isTravelerRegister } = useVariablesAuthority()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(true)
  const [values, setValues] = useState<TravelersFormDataType>()
  const [isAccompanyingFamily, setIsAccompanyingFamily] = useState(false)
  const [departments, setDepartments] = useState<OptionItemType[]>([])
  const [dispatchType, setDispatchType] = useState<OptionItemType[]>([])

  return {
    departments,
    dispatchType,
    form,
    isAccompanyingFamily,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isOpen,
    isRoleSubAdmin,
    isTravelerRegister,
    onCancel,
    onOpen,
    push,
    setDepartments,
    setDispatchType,
    setIsAccompanyingFamily,
    setIsDisabledSubmit,
    setIsLoading,
    setIsLoadingLayout,
    setValues,
    values,
  }
}
