import styled from "styled-components"
import { Colors } from "../../../../../constant/Styled/Colors"

const { BORDER } = Colors

const StyledDivider = styled.div`
  background-color: ${BORDER};
  height: 1px;
  width: 100%;
`

export const Styled = {
  StyledDivider,
}
