import { Key, useState } from "react"
import { useTable } from "../../../../../../../../utils/hook/useTable"
import { FilesListDataSourceType } from "../../../../../models/files/list"

export const useVariablesRowSelection = (dataLength: number) => {
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [isDisabledBtnDelete, setIsDisabledBtnDelete] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [fileInfoListCurrent, setFileInfoListCurrent] = useState<
    FilesListDataSourceType[]
  >([])
  const [dataCurrent, setDataCurrent] = useState<FilesListDataSourceType[]>([])
  const [isDisabledBtnDownload, setIsDisabledBtnDownload] = useState(true)
  const [isDisabledBtnPreview, setIsDisabledBtnPreview] = useState(true)

  const {
    onChange: onChangePage,
    perItem,
    perItemEnd,
    perItemStart,
  } = useTable(dataLength)

  return {
    dataCurrent,
    fileInfoListCurrent,
    isCheckAll,
    isDisabledBtnDelete,
    isDisabledBtnDownload,
    isDisabledBtnPreview,
    onChangePage,
    perItem,
    perItemEnd,
    perItemStart,
    selectedRowKeys,
    setDataCurrent,
    setFileInfoListCurrent,
    setIsCheckAll,
    setIsDisabledBtnDelete,
    setIsDisabledBtnDownload,
    setIsDisabledBtnPreview,
    setSelectedRowKeys,
  }
}
