/* eslint-disable complexity */
import { useHistory } from "react-router-dom"
import { Routers } from "../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../infrastructure/axiosError/models"
import { pushStateError } from "../../../../../../utils/pushStateError"
import { UploadChangeParam, UploadFile, UseUploadProps } from "../../models"

const { SCR973 } = Routers

export const useUpload = (props: UseUploadProps) => {
  const { push } = useHistory()
  const { onChange } = props

  const handleChangeFile = (info: UploadChangeParam<UploadFile>) => {
    if (info?.file?.status === "done") {
      if (typeof onChange === "function") {
        onChange(info)
      }
    }
    if (info?.file?.status === "error") {
      if (info?.file?.response) {
        const { message, status, title } = info.file.response
        if (message && title) {
          const errors: AxiosErrorType = {
            debug: {
              response: {
                data: {
                  entityName: "",
                  message,
                  params: "",
                  status,
                  title,
                },
              },
            },
            message,
            title,
          }
          const state = pushStateError(errors)
          push({ pathname: SCR973, state })
        }
      }
    }
  }

  return {
    handleChangeFile,
  }
}
