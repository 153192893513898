import "./style/index.scss"
import { FC, Fragment } from "react"
import { ExpandableProps } from "./models"
import { useModal } from "../../../../../utils/hook/useModal"
import { Popup } from "../../Modal/Popup"
import { PreLine } from "../PreLine"

export const Expandable: FC<ExpandableProps> = (props) => {
  const { cancelText, content, title } = props
  const { isOpen, onCancel, onOpen } = useModal()
  const contentLength = new TextEncoder().encode(content).length
  const contentBytes = new TextEncoder().encode(content.slice(0, 25)).length
  const _content =
    contentBytes > 30 ? content.slice(0, 25) : content.slice(0, 50)

  return contentLength > 50 ? (
    <Fragment>
      <span className="expandable" onClick={onOpen}>
        <PreLine>{_content}...</PreLine>
      </span>
      <Popup
        cancelText={cancelText}
        isOpen={isOpen}
        onCancel={onCancel}
        title={title}
      >
        <PreLine textAlign="left">{content}</PreLine>
      </Popup>
    </Fragment>
  ) : (
    <PreLine>{content}</PreLine>
  )
}
