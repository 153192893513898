import { getDateTime, getDay } from "../../../../../../../../utils/getDate"
import { ScheduleAddSuccessDataType } from "../../../../../models/addSuccess"

export const convertDataSource = (
  res: ScheduleAddSuccessDataType,
): ScheduleAddSuccessDataType => ({
  details: res.details.map((item) => {
    item.departureDateTime = getDateTime(item.departureDateTime)
    item.arrivalDateTime = getDateTime(item.arrivalDateTime)

    return item
  }),
  info: {
    ...res.info,
    dispatchEndDate: getDay(res.info.dispatchEndDate),
    dispatchStartDate: getDay(res.info.dispatchStartDate),
  },
  lodgings: res.lodgings.map((item) => {
    item.lodgingCheckin = getDay(item.lodgingCheckin)
    item.lodgingCheckout = getDay(item.lodgingCheckout)

    return item
  }),
  stays: res.stays,
})
