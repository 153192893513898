import { FC, Fragment } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { ModalConfirm } from "../../../../../../_webui/layout/components/Modal/Confirm"
import { TravelersBatchUpdateModalConfirmProps } from "../../../models/batchUpdate"

const { MESSAGE_C0017, MESSAGE_C0019 } = Translate

export const TravelersBatchUpdateModalConfirm: FC<
  TravelersBatchUpdateModalConfirmProps
> = (props) => {
  const {
    bulkDeleteDataConfirm,
    bulkUpdate,
    isOpen,
    isOpenUpdate,
    onCancel,
    onCancelUpdate,
  } = props

  return (
    <Fragment>
      <ModalConfirm isOpen={isOpen} onCancel={onCancel} onOk={bulkUpdate}>
        {MESSAGE_C0017}
      </ModalConfirm>
      <ModalConfirm
        isOpen={isOpenUpdate}
        onCancel={onCancelUpdate}
        onOk={bulkDeleteDataConfirm}
      >
        {MESSAGE_C0019}
      </ModalConfirm>
    </Fragment>
  )
}
