import styled from "styled-components"
import { Regex } from "../../../../../constant/Regex"
import { Colors } from "../../../../../constant/Styled/Colors"
import { Upload } from "antd"
import { Title } from "../../Typography/Title"

const { BORDER } = Colors
const { REGEX_IS_DESKTOP } = Regex

const StyledAntUpload = styled(Upload)`
  border: 1px dashed ${BORDER};
  border-radius: 10px;
  text-align: center;
  .ant {
    &-upload-drag-container {
      padding: 20px;
    }
    &-tooltip {
      display: none;
    }
  }
`

const StyledAntUploadContainer = styled.div`
  .ant-upload-list {
    display: none;
  }
`

const StyledAntUploadText = styled.p`
  max-width: 416px;
  margin: 0 auto 16px;
  text-align: ${!REGEX_IS_DESKTOP && "left"};
`

const StyledAntUploadHint = styled.p`
  margin-bottom: 0;
`

const StyledTitle = styled(Title)`
  margin-top: 32px;
`

export const Styled = {
  StyledAntUpload,
  StyledAntUploadContainer,
  StyledAntUploadHint,
  StyledAntUploadText,
  StyledTitle,
}
