import { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { useGridConfig } from "../useGridConfig"
import { useTableConfig } from "../useTableConfig"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { getBreadcrumbItems } from "./getBreadcrumbItems"
import { SafetiesDataType, SafetiesStateType } from "../../../../models/safety"

export const useVariables = () => {
  const { state } = useLocation<SafetiesStateType>()
  const { push } = useHistory()
  const { gridItems, generalMasters: categoryValueList, form } = useGridConfig()
  const [travelerInfoList, setTravelerInfoList] = useState<SafetiesDataType[]>(
    [],
  )
  const { columns } = useTableConfig()
  const { isSafetiesSendAll, isHiddenEditBtn } = useVariablesAuthority()
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabledBtnSubmit, setIsDisabledBtnSubmit] = useState(true)
  const breadcrumbItems = getBreadcrumbItems(state?.sourceURL)

  return {
    breadcrumbItems,
    categoryValueList,
    columns,
    form,
    gridItems,
    isDisabledBtnSubmit,
    isHiddenEditBtn,
    isLoading,
    isLoadingLayout,
    isSafetiesSendAll,
    push,
    setIsDisabledBtnSubmit,
    setIsLoading,
    setIsLoadingLayout,
    setTravelerInfoList,
    state,
    travelerInfoList,
  }
}
