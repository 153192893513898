import { FC } from "react"
import { ItemCheckbox } from "../../../../../../../../_webui/layout/components/Form/Item/Checkbox"
import { Translate } from "../../../../../../../../constant/Translate"
import { validatorRequiredCheckbox } from "../../../../../../../../utils/validator/requiredCheckbox"

const { TEXT_T262, TEXT_T699 } = Translate

export const SchedulesAddConfirm: FC = () => (
  <ItemCheckbox
    isHiddenLabel
    extra={TEXT_T699}
    label={TEXT_T262}
    name="codeConductConfirmation"
    required
    rules={[validatorRequiredCheckbox]}
    text={TEXT_T262}
  />
)
