/* eslint-disable max-lines */
import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../_webui/layout/components/Table/models"
import { Translate } from "../../../../../../../constant/Translate"
import { TravelersRequestDeleteResType } from "../../../../models/requestDelete"
import { useAuthority } from "../../../../../../../utils/hook/useAuthority"
import { getFullName } from "../../../../../../../utils/getFullName"
import { BreakText } from "../../../../../../../_webui/layout/components/Typography/BreakText"
import { concat } from "../../../../../../../utils/concat"

const {
  TEXT_T009,
  TEXT_T012,
  TEXT_T025,
  TEXT_T026,
  TEXT_T068,
  TEXT_T069,
  TEXT_T177,
  TEXT_T178,
  TEXT_T179,
  TEXT_T180,
} = Translate

export const useTableConfig =
  (): UseTableConfigType<TravelersRequestDeleteResType> => {
    const { isRoleAdmin } = useAuthority()
    const status = [TEXT_T068, TEXT_T069, TEXT_T012, ""]
    const getStatus = (_status: string) =>
      _status === "" ? status[3] : status[+_status]
    const columns: ColumnType<TravelersRequestDeleteResType>[] = [
      {
        dataIndex: "fullName",
        render: (_, data) => (
          <BreakText
            above={getFullName(data.kanjiSurname, data.kanjiName, true)}
            below={getFullName(data.romajiSurname, data.romajiName)}
          />
        ),
        sorter: (a, b) =>
          concat(a.romajiSurname, a.romajiName).localeCompare(
            concat(b.romajiSurname, b.romajiName),
          ),
        title: <BreakText above={TEXT_T177} below={TEXT_T178} />,
        width: 350,
      },
      {
        dataIndex: "status",
        render: (_, data) => getStatus(data.status),
        sorter: (a, b) =>
          getStatus(a.status).localeCompare(getStatus(b.status)),
        title: TEXT_T179,
        width: 200,
      },
      {
        dataIndex: "dispatchTypeDetailName",
        sorter: (a, b) =>
          a.dispatchTypeDetailName.localeCompare(b.dispatchTypeDetailName),
        title: TEXT_T025,
        width: 260,
      },
      {
        dataIndex: "workPlaceName",
        sorter: (a, b) => a.workPlaceName.localeCompare(b.workPlaceName),
        title: TEXT_T026,
        width: 350,
      },
      {
        dataIndex: "bumonName",
        sorter: (a, b) => a.bumonName.localeCompare(b.bumonName),
        title: TEXT_T009,
        width: 350,
      },
    ]

    if (isRoleAdmin) {
      columns.push.apply(columns, [
        {
          dataIndex: "requestAuthor",
          sorter: (a, b) => a.requestAuthor.localeCompare(b.requestAuthor),
          title: TEXT_T180,
          width: 350,
        },
      ])
    }

    return {
      columns,
    }
  }
