import { FC } from "react"
import { Routers } from "../../../../constant/Routers"
import { Route, Switch } from "react-router-dom"
import { RulesPage } from "../../../../app/pages/users/rules"
import { ResetPasswordPage } from "../../../../app/pages/users/resetPassword"
import { ChangePasswordPage } from "../../../../app/pages/users/changePassword"
import { LogoutPage } from "../../../../app/pages/users/logout"
import PrivateRoute from "../../../../app/routing/privateRoute"

const { SCR911, SCR915, SCR916, SCR917 } = Routers

export const UsersRoute: FC = () => (
  <Switch>
    <Route component={LogoutPage} exact path={SCR911} />
    <PrivateRoute component={RulesPage} exact path={SCR915} />
    <Route component={ResetPasswordPage} exact path={SCR916} />
    <Route component={ChangePasswordPage} exact path={SCR917} />
  </Switch>
)
