/* eslint-disable max-lines */
import styled, { css } from "styled-components"
import { Colors } from "../../../../../constant/Styled/Colors"
import {
  StyledMenuProps,
  StyledSidebarToggleProps,
} from "../components/Toggle/models"
import { HeaderUser } from "../../Header/User"
import { Fonts } from "../../../../../constant/Styled/Fonts"
import { Regex } from "../../../../../constant/Regex"

const {
  BORDER,
  BOX_SHADOW,
  LIGHT,
  MAIN,
  MENU_GRADIENT_1,
  MENU_GRADIENT_2,
  TEXT,
} = Colors
const { FS_LARGE } = Fonts
const { REGEX_IS_DESKTOP, REGEX_IS_IOS_DEVICE } = Regex

const StyledNav = styled.nav<StyledSidebarToggleProps>`
  background-image: linear-gradient(
    270deg,
    rgba(${MENU_GRADIENT_1}, 0.75) 0%,
    rgba(${MENU_GRADIENT_2}, 0.75) 100%
  );
  box-shadow: 3px 0px 6px ${BOX_SHADOW};
  overflow: auto;
  transition: ${(props) =>
    props.isExpandableMenu &&
    REGEX_IS_DESKTOP &&
    "width 0.3s cubic-bezier(0.075, 0.82, 0.165, 1)"};
  width: ${(props) =>
    props.isExpandableMenu && REGEX_IS_DESKTOP ? "60px" : "255px"};
  ${!REGEX_IS_DESKTOP &&
  css`
    background-image: linear-gradient(
      270deg,
      rgb(${MENU_GRADIENT_1}) 0%,
      rgb(${MENU_GRADIENT_2}) 100%
    );
    width: 100vw;
  `}
`

const StyledSidebarAction = styled.div`
  background-color: ${MAIN};
  padding: 20px 25px;
  padding-bottom: ${REGEX_IS_IOS_DEVICE && "140px"};
`

const StyledMenu = styled.ul<StyledMenuProps>`
  list-style: none;
  margin: 0;
  padding: 0;
  ${!REGEX_IS_DESKTOP &&
  css`
    max-height: calc(100vh - ${REGEX_IS_IOS_DEVICE ? "300px" : "188px"});
    min-height: calc(100vh - ${REGEX_IS_IOS_DEVICE ? "300px" : "188px"});
    overflow: auto;
  `}
  ${(props) =>
    props.isDesktop
      ? css`
          > li:nth-child(2n) {
            display: none;
          }
        `
      : css`
          > li:nth-child(2n - 1) {
            display: none;
          }
        `}
  li {
    .sub-menu {
      margin-left: ${(props) => !props.isExpandableMenu && "10px"};
      a {
        padding-left: 50px;
      }
    }
    li .sub-menu a {
      padding-left: 80px;
    }
  }
`

const CssSidebarActionItem = css`
  background-color: ${LIGHT};
  border-radius: 8px;
  border: 1px solid ${BORDER};
  color: ${TEXT};
  cursor: pointer;
  font-size: ${FS_LARGE};
  height: 44px;
  margin: 0;
  margin-bottom: 20px;
  padding: 6px 8px 7px;
  text-align: center;
  width: 100%;
`

const StyledSidebarActionItem = styled.div`
  ${CssSidebarActionItem};
  margin-bottom: 0;
  position: relative;
  z-index: 1;
`

const StyledSidebarHeaderUser = styled(HeaderUser)`
  ${CssSidebarActionItem};
  justify-content: space-between;
`

export const Styled = {
  StyledMenu,
  StyledNav,
  StyledSidebarAction,
  StyledSidebarActionItem,
  StyledSidebarHeaderUser,
}
