import { FC } from "react"
import { Translate } from "../../../../../constant/Translate"
import { Alert } from "../../../../../_webui/layout/components/Alert"
import { ButtonSubmit } from "../../../../../_webui/layout/components/Button/Submit"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { Table } from "../../../../../_webui/layout/components/Table"
import { BreakText } from "../../../../../_webui/layout/components/Typography/BreakText"
import { Title } from "../../../../../_webui/layout/components/Typography/Title"
import { useSafetiesSuccess } from "./modules/useSuccess"
import { Breadcrumbs } from "../../../../../constant/Breadcrumbs"
import { SuccessAlertHeading } from "../../components/success/SuccessAlertHeading"
import { Frame } from "../../../../../_webui/layout/components/Frame"

const {
  TEXT_T016,
  TEXT_T018,
  TEXT_T265,
  TEXT_T336,
  TEXT_T367,
  TEXT_T368,
  TEXT_T369,
  TEXT_T666,
} = Translate

const { BRCR_SCR415 } = Breadcrumbs

export const SafetiesSuccess: FC = () => {
  const {
    columns,
    goToHome,
    goToSafetiesDetail,
    isLoadingLayout,
    isVisibleBtnDetail,
    isVisibleBtnHome,
    safetiesSelected,
    transmissionInfo,
    travelerInfoList,
  } = useSafetiesSuccess()

  return (
    <Layout
      breadcrumbItems={BRCR_SCR415}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T367}
    >
      <Card heading={TEXT_T367}>
        <Alert
          type="success"
          content={<BreakText above={TEXT_T368} below={TEXT_T016} />}
        />
        <Title titleLeft={TEXT_T369} />
        <Alert
          heading={<SuccessAlertHeading transmissionInfo={transmissionInfo} />}
          content={transmissionInfo?.bodyContent}
          isIcon={false}
          title={transmissionInfo?.safetyTitle}
          type="info"
        />
        <Title titleLeft={TEXT_T666} />
        <Table
          columns={columns}
          dataSource={travelerInfoList}
          rowKey="travelScheduleId"
          pagination={false}
        />
        {safetiesSelected && (
          <Frame titleLeft={TEXT_T265}>{safetiesSelected}</Frame>
        )}
        <ButtonSubmit
          isHiddenSubmit={!isVisibleBtnDetail}
          isReset={isVisibleBtnHome}
          onClickReset={goToHome}
          onClickSubmit={goToSafetiesDetail}
          resetText={TEXT_T018}
          resetType="ghost"
          submitText={TEXT_T336}
          submitType="default"
        />
      </Card>
    </Layout>
  )
}
