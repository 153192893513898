/* eslint-disable max-lines */
/* eslint-disable max-statements */
import { useHistory, useLocation, useParams } from "react-router-dom"
import {
  AttentionsDetailCountType,
  AttentionsDetailDataSourceType,
  AttentionsDetailStateProps,
  AttentionsInfoDataType,
} from "../../../../models/detail"
import { useRouters } from "../useRouters"
import { useTableConfig } from "../useTableConfig"
import { useRowSelection } from "../useRowSelection"
import { useTable } from "../../../../../../../utils/hook/useTable"
import { useModal } from "../../../../../../../utils/hook/useModal"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { useState } from "react"
import { getBreadcrumbItems } from "./getBreadcrumbItems"
import { UseParamsType } from "../../../../../../../utils/useParams"
import { useExportFile } from "../useExportFile"
import { GeneralCode } from "../../../../../../../constant/GeneralCode"
import { getGeneralMasters } from "../../../../../../../utils/apis/getGeneralMasters"

const { IPTC, IPTC003 } = GeneralCode

export const useVariables = () => {
  const { push } = useHistory()
  const { id: attentionId } = useParams<UseParamsType>()
  const { state } = useLocation<AttentionsDetailStateProps>()
  const { isOpen, onCancel, onOpen } = useModal()
  const {
    isOpen: isOpenExport,
    onCancel: onCancelExport,
    onOpen: onOpenExport,
  } = useModal()
  const { columns } = useTableConfig()
  const {
    isAttentionsBrowseResponse,
    isCommonTravelerRefer,
    isHiddenAnswerBtn,
    isHiddenBackBtn,
    isHiddenExportBtn,
  } = useVariablesAuthority(!state?.isHiddenSubmit)

  const {
    userInfoListSelected,
    rowSelection,
    selectedRowKeys,
    setSelectedRowKeys,
  } = useRowSelection()

  const { goBack, goToAttentionsInfo } = useRouters({
    attentionId,
    isFromSCR316: state?.isFromSCR316,
    isFromSCR316History: state?.isFromSCR316History,
    isHiddenSubmit: state?.isHiddenSubmit,
    searchCondition: state?.searchCondition,
    sourceURL: state?.sourceURL,
    travelerList: userInfoListSelected,
  })

  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [isLoadingTable, setIsLoadingTable] = useState(false)
  const [activeKey, setActiveKey] = useState("")
  const [countAttentionStatus, setCountAttentionStatus] =
    useState<AttentionsDetailCountType>()
  const [attentionInfo, setAttentionInfo] = useState<AttentionsInfoDataType>()
  const [isResetTable, setIsResetTable] = useState(false)
  const [userInfoList, setUserInfoList] = useState<
    AttentionsDetailDataSourceType[]
  >([])
  const [severity, setSeverity] = useState("")
  const { generalMasters: severityList } = getGeneralMasters(IPTC)

  const { onChange: onChangePage, perItem } = useTable(userInfoList?.length)
  const isSeverityLow = attentionInfo?.severity === IPTC003
  const isResponse =
    selectedRowKeys.length === 0 || !isAttentionsBrowseResponse || isSeverityLow
  const breadcrumbItems = getBreadcrumbItems(state?.sourceURL)

  const { handleExportFile, isLoadingExport } = useExportFile({
    attentionInfo,
    selectedRowKeys,
    userInfoList,
  })

  return {
    activeKey,
    attentionId,
    attentionInfo,
    breadcrumbItems,
    columns,
    countAttentionStatus,
    goBack,
    goToAttentionsInfo,
    handleExportFile,
    isAttentionsBrowseResponse,
    isCommonTravelerRefer,
    isHiddenAnswerBtn,
    isHiddenBackBtn,
    isHiddenExportBtn,
    isLoading: isLoadingTable || isLoadingExport,
    isLoadingLayout,
    isOpen,
    isOpenExport,
    isResetTable,
    isResponse,
    onCancel,
    onCancelExport,
    onChangePage,
    onOpen,
    onOpenExport,
    perItem,
    push,
    rowSelection,
    setActiveKey,
    setAttentionInfo,
    setCountAttentionStatus,
    setIsLoadingLayout,
    setIsLoadingTable,
    setIsResetTable,
    setSelectedRowKeys,
    setSeverity,
    setUserInfoList,
    severity,
    severityList,
    userInfoList,
  }
}
