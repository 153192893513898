import { ColumnType } from "../../../../../../../_webui/layout/components/Table/models"
import { BreakText } from "../../../../../../../_webui/layout/components/Typography/BreakText"
import { AttentionsInfoDataSourceType } from "../../../../models/info"
import { Translate } from "../../../../../../../constant/Translate"

const { TEXT_T003, TEXT_T006 } = Translate

export const useTableConfig = () => {
  const columns: ColumnType<AttentionsInfoDataSourceType>[] = [
    {
      dataIndex: "fullName",
      render: (_, data) => (
        <BreakText above={data.fullNameKanji} below={data.fullNameRomaji} />
      ),
      title: <BreakText above={TEXT_T003} below={TEXT_T006} />,
      width: 350,
    },
  ]
  return { columns }
}
