import { FC } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { useAuthority } from "../../../../../../utils/hook/useAuthority"
import { ButtonSubmit } from "../../../../../../_webui/layout/components/Button/Submit"
import { TravelersBatchDeleteModalButtonSubmitProps } from "../../../models/batchDelete"

const { HELP_H0032, TEXT_T012, TEXT_T030, TEXT_T034 } = Translate

export const TravelersBatchDeleteModalButtonSubmit: FC<
  TravelersBatchDeleteModalButtonSubmitProps
> = (props) => {
  const {
    isDisabledSubmit,
    isVisibleBtnCancel,
    isVisibleBtnDelete,
    onOpen,
    onOpenDeletedOrGoToHome,
  } = props
  const { isRoleAdmin } = useAuthority()

  return (
    <ButtonSubmit
      isDisabledSubmit={isDisabledSubmit}
      isHiddenSubmit={!isVisibleBtnDelete}
      isReset={isVisibleBtnCancel}
      onClickReset={onOpenDeletedOrGoToHome}
      onClickSubmit={onOpen}
      resetText={TEXT_T012}
      submitText={isRoleAdmin ? TEXT_T034 : TEXT_T030}
      submitTitle={isRoleAdmin ? "" : HELP_H0032}
    />
  )
}
