import { Translate } from "../../../../../../../constant/Translate"
import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../_webui/layout/components/Table/models"
import { BreakText } from "../../../../../../../_webui/layout/components/Typography/BreakText"
import { TravelersListDataSourceType } from "../../../../models/list/data"

const {
  TEXT_T002,
  TEXT_T003,
  TEXT_T006,
  TEXT_T024,
  TEXT_T025,
  TEXT_T026,
  TEXT_T027,
} = Translate

export const useTableConfig =
  (): UseTableConfigType<TravelersListDataSourceType> => {
    const columns: ColumnType<TravelersListDataSourceType>[] = [
      {
        dataIndex: "delete",
        sorter: (a, b) => String(a.delete).localeCompare(String(b.delete)),
        title: TEXT_T024,
        width: 100,
      },
      {
        dataIndex: "fullName",
        render: (_, data) => (
          <BreakText above={data.fullNameKanji} below={data.fullNameRomaji} />
        ),
        sorter: (a, b) => a.fullNameRomaji.localeCompare(b.fullNameRomaji),
        title: <BreakText above={TEXT_T003} below={TEXT_T006} />,
        width: 180,
      },
      {
        dataIndex: "dispatchTypeDetailName",
        sorter: (a, b) =>
          a.dispatchTypeDetailName.localeCompare(b.dispatchTypeDetailName),
        title: TEXT_T025,
        width: 260,
      },
      {
        dataIndex: "placeAndWorkLocation",
        sorter: (a, b) =>
          a.placeAndWorkLocation.localeCompare(b.placeAndWorkLocation),
        title: TEXT_T026,
        width: 250,
      },
      {
        dataIndex: "userId",
        sorter: (a, b) => a.userId.localeCompare(b.userId),
        title: TEXT_T002,
        width: 250,
      },
      {
        dataIndex: "others",
        render: (_, data) => <pre>{data.others}</pre>,
        sorter: (a, b) => a.others.localeCompare(b.others),
        title: TEXT_T027,
        width: 350,
      },
    ]
    return {
      columns,
    }
  }
