import { getValueByLanguage } from "../../../../../../../../utils/getValueByLanguage"
import { SchedulesCloneDataType } from "../../../../../models/clone"
import { SchedulesListDataType } from "../../../../../models/list"

export const getDataSource = (
  travelScheduleList: SchedulesListDataType[],
): SchedulesCloneDataType[] =>
  travelScheduleList.map((item) => ({
    departureCountryName: item.info.departureCountry,
    destinationCountryName: item.info.arrivalCountry,
    dispatchEndDate: item.info.dispatchEndDate,
    dispatchStartDate: item.info.dispatchStartDate,
    maxArrivalDateTime: item.info.maxArrivalDateTime,
    minDepartureDateTime: item.info.minDepartureDateTime,
    projectName: getValueByLanguage(
      item.info.projectNameJp,
      item.info.projectNameEn,
    ).toString(),
    scheduleId: item.info.travelScheduleId,
    transitCountryName: item.info.transitCountries,
    transitCountryOrders: item.info.transitCountryOrders,
    travelPurposeName: item.info.travelPurposeName,
    travelerId: item.info.travelerId,
  }))
