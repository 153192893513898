import styled from "styled-components"
import { ItemTextArea } from "../../../../../../../_webui/layout/components/Form/Item/TextArea"

const StyledItemTextArea = styled(ItemTextArea)`
  .ant-form-item-control-input-content {
    .ant-form-item {
      margin: 0;
      &-control {
        max-width: 100%;
      }
    }
  }
`

export const Styled = {
  StyledItemTextArea,
}
