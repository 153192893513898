import { Key, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { Routers } from "../../../../../../../constant/Routers"
import { SafetiesDataType, SafetiesStateType } from "../../../../models/safety"

const { SCR210_SAFETIES, SCR412, SCR413, SCR962 } = Routers

export const useRouters = (selectedKeys: Key[]) => {
  const { state } = useLocation<SafetiesStateType>()
  const [travelerInfoList, setTravelerInfoList] = useState<SafetiesDataType[]>(
    [],
  )
  const { push } = useHistory()

  const handlePush = (pathname: string) => {
    if (pathname) {
      push({
        pathname: pathname,
        state: {
          ...state,
          selectedKeys,
          sourceURL: SCR412,
        },
      })
    }
  }

  /**
   * 「SCR210_渡航予定情報の一覧画面」に遷移する。
   * 「SCR413_安否確認の内容変更画面」に遷移する。
   * 「SCR962_安否確認の複製画面」に遷移する。
   */
  const goBack = () => {
    handlePush(state?.sourceURL)
  }

  const goToSchedulesList = () => {
    handlePush(SCR210_SAFETIES)
  }

  const goToSafetiesClone = () => {
    handlePush(SCR962)
  }

  const goToSafetiesEdit = () => {
    handlePush(SCR413)
  }

  return {
    goBack,
    goToSafetiesClone,
    goToSafetiesEdit,
    goToSchedulesList,
    setTravelerInfoList,
    state,
    travelerInfoList,
  }
}
