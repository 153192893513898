import { Endpoint } from "../../../../../constant/Endpoint"
import { Environment } from "../../../../../constant/Environment"
import { apiService } from "../../../../../infrastructure/apiService"

const { TRAVELER_ENDPOINT } = Environment
const { FNC1A_Menus } = Endpoint

export const menusApi = {
  menus: (acceptLanguage?: string) =>
    apiService(
      {
        data: {},
        method: "GET",
        url: `${TRAVELER_ENDPOINT}${FNC1A_Menus}`,
      },
      acceptLanguage,
    ),
}
