import styled, { css } from "styled-components"
import { Regex } from "../../../../../constant/Regex"
import { Colors } from "../../../../../constant/Styled/Colors"
import { StyledCardProps } from "../models"

const { REGEX_IS_DESKTOP } = Regex
const { BOX_SHADOW, LIGHT } = Colors

const StyledCard = styled.section<StyledCardProps>`
  background-color: ${LIGHT};
  padding: ${REGEX_IS_DESKTOP ? "25px" : "25px 20px"};
  position: relative;
  width: 100%;
  ${(props) =>
    props.isFullHeight &&
    css`
      min-height: calc(100% - 34px);
    `}
  ${(props) =>
    !props.isBreadcrumbs &&
    css`
      min-height: 100%;
    `}
  ${() =>
    REGEX_IS_DESKTOP
      ? css`
          border-radius: 10px;
          box-shadow: 0px 0px 6px ${BOX_SHADOW};
        `
      : css`
          .card {
            &-heading {
              &-button {
                width: 100%;
              }
              &-divider {
                margin-bottom: 20px;
              }
            }
          }
        `}
`

export const Styled = {
  StyledCard,
}
