import { Translate } from "../../../../../../../../constant/Translate"
import { exportCsv } from "../../../../../../../../utils/exportCsv"
import { TableErrorTravelersRowType } from "../../../../../models/requestDelete"

const { TEXT_T002, TEXT_T381 } = Translate

const handleDecoder = (errorList: TableErrorTravelersRowType[]) => {
  const dataCSV = errorList
  let csvContent = `${TEXT_T002},${TEXT_T381}`

  // Convert errors array to a string of comma-separated values
  dataCSV.forEach((error) => {
    const row = `\r\n${error.userId},${error.message}`
    csvContent += row
  })

  // Convert content from ASCII to UTF-8
  csvContent = encodeURIComponent(csvContent)
  csvContent = decodeURIComponent(csvContent)

  return csvContent
}

export const handleExportCSV = (errorList: TableErrorTravelersRowType[]) => {
  const convertData = handleDecoder(errorList)

  exportCsv(convertData, "ログ")
}
