import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const { isSafetiesSendAll, isRoleSubAdmin, isCommonRefer } = useAuthority()

  const isNotAccessScreen = !isSafetiesSendAll

  const isVisibleBtnHome = isRoleSubAdmin && isCommonRefer
  const isVisibleBtnDetail = isRoleSubAdmin && isSafetiesSendAll

  return {
    isNotAccessScreen,
    isVisibleBtnDetail,
    isVisibleBtnHome,
  }
}
