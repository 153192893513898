import { FC, Fragment } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { TranslateHelp } from "../../../../../../constant/Translate/Help"
import { Tabs } from "../../../../../../_webui/layout/components/Tabs"
import { TabsLabel } from "../../../../../../_webui/layout/components/Tabs/TabsLabel"
import { Tooltip } from "../../../../../../_webui/layout/components/Tooltip"
import { AttentionsDetailTabsProps } from "../../../models/detail"
import { Center } from "../../../../../../_webui/layout/components/Center"
import { TabItemsProps } from "../../../../../../_webui/layout/components/Tabs/models"

const { TEXT_T344, TEXT_T346, TEXT_T348, TEXT_T349 } = Translate
const { HELP_H0030 } = TranslateHelp

export const AttentionsDetailTabs: FC<AttentionsDetailTabsProps> = (props) => {
  const { countAttentionStatus, onTabClick, tableItem } = props
  // eslint-disable-next-line no-undefined

  const tabsItems: TabItemsProps[] = [
    {
      children: tableItem,
      key: "1",
      label: (
        <TabsLabel
          text={TEXT_T344}
          total={countAttentionStatus?.respondedCount}
        />
      ),
    },
    {
      children: tableItem,
      key: "0",
      label: (
        <TabsLabel
          text={TEXT_T346}
          total={countAttentionStatus?.notResponseCount}
        />
      ),
    },
    {
      children: tableItem,
      key: "2",
      label: (
        <Fragment>
          <TabsLabel
            text={TEXT_T348}
            total={countAttentionStatus?.undeliveredEmailCount}
          />
          <Tooltip title={HELP_H0030} />
        </Fragment>
      ),
    },
    {
      children: tableItem,
      key: "",
      label: <Center>{TEXT_T349}</Center>,
    },
  ]
  return (
    <Tabs
      isCustom
      items={tabsItems}
      onTabClick={onTabClick}
      defaultActiveKey=""
    />
  )
}
