import {
  NotificationItemType,
  NotificationResType,
} from "../../../../../../../../_webui/layout/components/Notification/models"
import { getDateHome } from "../../../../../../../../utils/getDate"
import { getValueByLanguage } from "../../../../../../../../utils/getValueByLanguage"

export const convertData = (
  data: NotificationResType[],
): NotificationItemType[] =>
  data.map((item) => {
    const [noticeContentsJp, ...noticeContentsEn] =
      item.noticeContents.split("/")

    const content = getValueByLanguage(
      noticeContentsJp,
      noticeContentsEn.join("/"),
    ).toString()

    const _noticeContents = getValueByLanguage(
      content,
      content.replaceAll("「", ' "').replaceAll("」", '" ').trim(),
    )

    return {
      content: _noticeContents,
      title: getDateHome(item.implementationDate),
    }
  })
