import { ScheduleLodgingsType } from "../../../../models/addSuccess"
import { useLodgingTableConfig } from "../useTableConfigs/useLodgingTableConfig"
import { useLodgingTabsConfig } from "../useTabsConfigs/useLodgingTabsConfig"

export const useLodgings = (schedulesLodgings: ScheduleLodgingsType[]) => {
  const lodgingsColumns = useLodgingTableConfig()

  const lodgingsTabsItems = useLodgingTabsConfig(schedulesLodgings)

  return {
    lodgingsColumns,
    lodgingsTabsItems,
  }
}
