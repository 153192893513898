import { FC, Fragment } from "react"
import { Translate } from "../../../../constant/Translate"
import { Images } from "../../../../constant/Images"
import { Styled } from "./styled"
import { Image } from "../Image"
import { ChangeLanguageProps } from "./models"

const { TEXT_T419, TEXT_T514, TEXT_T515 } = Translate
const { IMAGE_HEADER_ICON_01 } = Images
const { StyledArrow, StyledDropdown, StyledText } = Styled

export const ChangeLanguage: FC<ChangeLanguageProps> = (props) => {
  const { setLanguageToEnglish, setLanguageToJapanese, ...rest } = props
  const items = [
    {
      key: "ja",
      label: TEXT_T514,
      onClick: setLanguageToJapanese,
    },
    {
      key: "en",
      label: TEXT_T515,
      onClick: setLanguageToEnglish,
    },
  ]

  return (
    <StyledDropdown
      content={
        <Fragment>
          <Image src={IMAGE_HEADER_ICON_01} />
          <StyledText className="change-language-text">{TEXT_T419}</StyledText>
          <StyledArrow className="arrow arrow-down" />
        </Fragment>
      }
      menu={{ items }}
      {...rest}
    />
  )
}
