import { Endpoint } from "../../../constant/Endpoint"
import { Environment } from "../../../constant/Environment"
import { replaceStringParam } from "../../../utils/replaceStringParam"
import { apiService } from "../../apiService"

const { COMMON_ENDPOINT } = Environment
const { FNC9_Get_General_Master } = Endpoint

export const generalMastersApi = {
  getGeneralMaster: (generalCode: string) =>
    apiService({
      data: {},
      method: "GET",
      url: `${COMMON_ENDPOINT}${replaceStringParam(
        FNC9_Get_General_Master,
        generalCode,
      )}`,
    }),
}
