import {
  ScheduleAddSuccessDataType,
  ScheduleInfoType,
  TravelerInfoType,
} from "../../../models/addSuccess"

const INITIAL_VALUES_TRAVELER_INFO: TravelerInfoType = {
  bumonTravelerName: "",
  dispatchTypeDetailName: "",
  fullNameKanji: "",
  fullNameRomaji: "",
  kanjiPassportFullName: "",
  romajiPassportFullName: "",
}

const INITIAL_VALUES_SCHEDULES_INFO: ScheduleInfoType = {
  bumonName: "",
  codeConductConfirmation: false,
  differentInforCheck: false,
  dispatchEndDate: "",
  dispatchStartDate: "",
  insuranceCompanyName: "",
  insuranceCompanyPhoneNumber: "",
  insuranceSonsignor: "",
  insuranceTicketNumber: "",
  locationPersonName: "",
  mainPersonName: "",
  managerRemarks1: "",
  managerRemarks2: "",
  managerRemarks3: "",
  managerRemarks4: "",
  managerRemarks5: "",
  procurementControlNumber: "",
  projectNameEn: "",
  projectNameJp: "",
  travelPurposeName: "",
  travelerId: "",
  updateDate: "",
}

const INITIAL_VALUES_SCHEDULE_DATA: ScheduleAddSuccessDataType = {
  details: [],
  info: INITIAL_VALUES_SCHEDULES_INFO,
  lodgings: [],
  stays: [],
}

export const InitialValues = {
  INITIAL_VALUES_SCHEDULE_DATA,
  INITIAL_VALUES_TRAVELER_INFO,
}
