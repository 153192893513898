import { useEffect } from "react"
import { GeneralCode } from "../../../../../../../../constant/GeneralCode"
import { Routers } from "../../../../../../../../constant/Routers"
import { backToLogin } from "../../../../../../../../utils/backToLogin"
import {
  FilesListDataSourceType,
  UseFilesListType,
} from "../../../../../models/files/list"
import { useVariables } from "../useVariables"

const { SCR920 } = Routers
const { MEASURES_TYPE, SAFETY_MEASURES_MANUAL_TYPE, UNIVERSAL_COUNTRY_CODE } =
  GeneralCode

export const useFiles = (): UseFilesListType<FilesListDataSourceType> => {
  const {
    allCountries,
    columns,
    fileInfoList,
    fileTypeList,
    form,
    getFiles,
    handleDownload,
    handleGetDataSource,
    handlePreviewFile,
    isDisabledBtnDownload,
    isDisabledBtnPreview,
    isLoading,
    isLoadingLayout,
    isNotAccessScreen,
    isOpenPreviewFile,
    isVisibleBtnCancel,
    isVisibleBtnDownload,
    isVisibleBtnReference,
    onCancelPreviewFile,
    onChangePagination,
    onChangeTable,
    perItem,
    push,
    rowSelection,
    setFileTypeList,
    typeInfo,
  } = useVariables()

  // 「SCR920_ホーム画面」へ遷移する。
  const goToHome = () => {
    push(SCR920)
  }

  useEffect(() => {
    // ユーザの画面アクセス許可チェックを処理する。
    if (isNotAccessScreen) {
      // 「SCR973_エラー情報の表示画面」に遷移する。
      const { pathname, state } = backToLogin()
      push({ pathname, state })
    } else {
      getFiles({ countryCode: UNIVERSAL_COUNTRY_CODE })
      form.setFieldValue("countryCode", UNIVERSAL_COUNTRY_CODE)
    }
  }, [])

  useEffect(() => {
    if (typeInfo?.length) {
      const _typeInfo = typeInfo?.filter((item) =>
        [SAFETY_MEASURES_MANUAL_TYPE, MEASURES_TYPE].includes(
          String(item.value),
        ),
      )
      setFileTypeList(_typeInfo)
    }
  }, [typeInfo])

  return {
    allCountries,
    columns,
    dataSource: fileInfoList,
    fileTypeList,
    form,
    goToHome,
    handleDownload,
    handleGetDataSource,
    handlePreviewFile,
    isDisabledBtnDownload,
    isDisabledBtnPreview,
    isLoading,
    isLoadingLayout,
    isOpenPreviewFile,
    isVisibleBtnCancel,
    isVisibleBtnDownload,
    isVisibleBtnReference,
    onCancelPreviewFile,
    onChangePagination,
    onChangeTable,
    perItem,
    rowSelection,
  }
}
