import React, { FC, Fragment } from "react"
import { Translate } from "../../../../../../../constant/Translate"
import { ItemInput } from "../../../../../../../_webui/layout/components/Form/Item/Input"
import { validatorHalfSize } from "../../../../../../../utils/validator/halfSize"

const { TEXT_T050, TEXT_T051, TEXT_T052, TEXT_T053 } = Translate

export const CollapseUrgent: FC = () => (
  <Fragment>
    <ItemInput
      label={TEXT_T050}
      maxLength={20}
      name="urgentContactHome"
      rules={[validatorHalfSize]}
    />
    <ItemInput label={TEXT_T051} maxLength={100} name="urgentContactName" />
    <ItemInput
      label={TEXT_T052}
      maxLength={20}
      name="urgentAffiliationContact"
      rules={[validatorHalfSize]}
    />
    <ItemInput
      label={TEXT_T053}
      maxLength={100}
      name="urgentAffiliationContactName"
    />
  </Fragment>
)
