import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { Key, useEffect, useState } from "react"
import { ApplicationFormDataSourceType } from "../../../../models/list"
import { useTable } from "../../../../../../../utils/hook/useTable"
import { Translate } from "../../../../../../../constant/Translate"
import { TableRowSelection } from "../../../../../../../_webui/layout/components/Table/models"
import { CheckboxItem } from "../../../../../../../_webui/layout/components/RowSelection/CheckboxItem"

const { TEXT_T023 } = Translate

export const useRowSelection = (
  fileInfoList: ApplicationFormDataSourceType[],
) => {
  const [isDisabledBtnDownload, setIsDisabledBtnDownload] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])

  const { onChange: onChangePage, perItem } = useTable(fileInfoList.length)

  const onSelectChange = () => {
    const _isDisabledBtnDownload = !selectedRowKeys.length
    setIsDisabledBtnDownload(_isDisabledBtnDownload)
  }

  const onChangeCheckItem = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    const value = event.target.value

    if (checked) setSelectedRowKeys([...selectedRowKeys, value])
    else {
      const _selectedRowKeys = selectedRowKeys.filter((item) => item !== value)
      setSelectedRowKeys(_selectedRowKeys)
    }
  }

  const rowSelection: TableRowSelection<ApplicationFormDataSourceType> = {
    columnTitle: <div className="d-none">{TEXT_T023}</div>,
    hideSelectAll: true,
    renderCell: (_, record) => (
      <CheckboxItem
        checked={selectedRowKeys.includes(record.uploadId)}
        onChange={onChangeCheckItem}
        value={record.uploadId}
      />
    ),
    selectedRowKeys,
  }

  const onChangePagination = (page: number, pageSize: number) => {
    onChangePage(page, pageSize)
    setSelectedRowKeys([])
  }

  const onChangeTable = () => {
    setSelectedRowKeys([])
  }

  useEffect(() => {
    onSelectChange()
  }, [selectedRowKeys])

  return {
    isDisabledBtnDownload,
    onChangePagination,
    onChangeTable,
    perItem,
    rowSelection,
    selectedRowKeys,
    setIsDisabledBtnDownload,
    setSelectedRowKeys,
  }
}
