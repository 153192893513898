import { useModal } from "../../../../../../../utils/hook/useModal"

export const useVariablesModal = () => {
  const { isOpen, onCancel, onOpen } = useModal()
  const {
    isOpen: isOpenPopup,
    onCancel: onCancelPopup,
    onOpen: onOpenPopup,
  } = useModal()
  const {
    isOpen: isOpenTravelerDraft,
    onCancel: onCancelTravelerDraft,
    onOpen: onOpenTravelerDraft,
  } = useModal()

  return {
    isOpen,
    isOpenPopup,
    isOpenTravelerDraft,
    onCancel,
    onCancelPopup,
    onCancelTravelerDraft,
    onOpen,
    onOpenPopup,
    onOpenTravelerDraft,
  }
}
