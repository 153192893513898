/* eslint-disable max-lines */
import styled, { createGlobalStyle, css } from "styled-components"
import { Regex } from "../../../../../constant/Regex"
import { Colors } from "../../../../../constant/Styled/Colors"
import { Fonts } from "../../../../../constant/Styled/Fonts"
import {
  StyledGridItemKeyProps,
  StyledGridItemTextProps,
  StyledGridProps,
} from "../models"

const { BORDER, GRID_KEY, LIGHT } = Colors
const { FS_LARGE, FW_MEDIUM } = Fonts
const { REGEX_IS_DESKTOP } = Regex

const StyledGrid = styled.div<StyledGridProps>`
  position: relative;
  ${(props) =>
    !props.isGhostLayout &&
    css`
      border: 1px solid ${BORDER};
      font-size: ${FS_LARGE};
      font-weight: ${FW_MEDIUM};
    `}
  ${(props) =>
    REGEX_IS_DESKTOP &&
    props.isCustom &&
    css`
      display: flex;
      flex-wrap: wrap;
    `}
`

const StyledGridItem = styled.div<StyledGridProps>`
  ${REGEX_IS_DESKTOP &&
  css`
    display: flex;
    width: 100%;
  `}
  ${(props) =>
    REGEX_IS_DESKTOP &&
    !props.isGhostLayout &&
    css`
      &:not(:last-child) {
        border-bottom: 1px solid ${BORDER};
      }
    `}
  ${(props) =>
    !REGEX_IS_DESKTOP &&
    props.isGhostLayout &&
    css`
      &:not(:last-child) {
        border-bottom: 1px solid ${BORDER};
      }
    `}
    ${(props) =>
    REGEX_IS_DESKTOP &&
    props.isCustom &&
    css`
      ---grid-item-width-custom: 280px;
      &:nth-child(1) {
        max-width: calc(100% - var(---grid-item-width-custom));
        .grid {
          &-item {
            &-text {
              padding: 22px 19px;
            }
          }
        }
      }
      &:nth-child(2) {
        max-width: var(---grid-item-width-custom);
        .grid {
          &-item {
            &-key {
              border-left: 1px solid ${BORDER};
            }
            &-text {
              padding: 22px 19px;
            }
          }
        }
      }
      &-key {
        &:nth-child(2) {
          border-left: 1px solid ${BORDER};
        }
      }
    `}
`

const StyledGridItemKey = styled.div<StyledGridItemKeyProps>`
  align-items: center;
  background-color: ${(props) => (props.isGhostLayout ? LIGHT : GRID_KEY)};
  display: flex;
  padding: 13px 0 12px 20px;
  ${(props) =>
    props.isItemKeyCenter &&
    css`
      padding-left: 0;
      justify-content: center;
    `};
  ${REGEX_IS_DESKTOP &&
  css`
    max-width: ${(props: StyledGridItemKeyProps) =>
      props.isItemKeySmall ? "110px" : "340px"};
    min-width: ${(props: StyledGridItemKeyProps) =>
      props.isItemKeySmall ? "110px" : "340px"};
  `}
  ${(props) =>
    !REGEX_IS_DESKTOP &&
    props.isGhostLayout &&
    css`
      padding: 0;
      margin-top: 13px;
      margin-bottom: 13px;
    `}

  ${(props) =>
    REGEX_IS_DESKTOP &&
    !props.isGhostLayout &&
    css`
      border-right: 1px solid ${BORDER};
    `}
`
const StyledGridItemText = styled.div<StyledGridItemTextProps>`
  background-color: ${LIGHT};
  min-height: ${!REGEX_IS_DESKTOP && "50px"};
  padding: 13px 0 12px 20px;
  width: 100%;
  word-break: break-word;
  ${(props) =>
    !REGEX_IS_DESKTOP &&
    props.isGhostLayout &&
    css`
      padding: 0;
      margin-bottom: 13px;
      min-height: initial;
    `}
  ${(props) =>
    REGEX_IS_DESKTOP &&
    props.isForm &&
    css`
      padding: 22px 36px 22px 19px;
    `}
  ${(props) =>
    !REGEX_IS_DESKTOP &&
    props.isForm &&
    css`
      padding: 10px;
      .ant-form-item {
        &:not(:first-child) {
          margin-top: 10px;
        }
        &-explain {
          height: initial;
        }
      }
    `}
`

export const GlobalStyleGridForm = createGlobalStyle`
  .grid {
    .ant-form-item {
      margin: 0;
      &-checkbox,
      &-radio {
        .ant-form-item-control-input {
          height: initial;
        }
      }
    }
  }
`

export const Styled = {
  StyledGrid,
  StyledGridItem,
  StyledGridItemKey,
  StyledGridItemText,
}
