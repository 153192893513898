import { FC } from "react"
import { Translate } from "../../../../../constant/Translate"
import { Button } from "../../../../../_webui/layout/components/Button"
import { Center } from "../../../../../_webui/layout/components/Center"
import { Divider } from "../../../../../_webui/layout/components/Divider"
import { Form } from "../../../../../_webui/layout/components/Form"
import { Grid } from "../../../../../_webui/layout/components/Grid"
import { Space } from "../../../../../_webui/layout/components/Space"
import { Table } from "../../../../../_webui/layout/components/Table"
import { Title } from "../../../../../_webui/layout/components/Typography/Title"
import { Layout } from "./../../../../../_webui/layout/components/Layout/index"
import { useSafetiesEdit } from "./modules/useEdit"

const {
  TEXT_T072,
  TEXT_T666,
  TEXT_T325,
  TEXT_T360,
  TEXT_T364,
  TEXT_T365,
  TEXT_T366,
} = Translate

export const SafetiesEdit: FC = () => {
  const {
    breadcrumbItems,
    columns,
    form,
    goBack,
    gridItems,
    isDisabledBtnSubmit,
    isHiddenEditBtn,
    isLoading,
    isLoadingLayout,
    onFinish,
    onValuesChange,
    travelerInfoList,
  } = useSafetiesEdit()

  return (
    <Layout
      breadcrumbItems={breadcrumbItems}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T364}
    >
      <Form
        form={form}
        heading={TEXT_T364}
        isLoading={isLoading}
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Title titleLeft={TEXT_T365} />
        <Title titleLeft={TEXT_T360} />

        <Space isSpaceTop isSpaceBottom>
          <Title titleLeft={TEXT_T366} />
          <Grid isForm gridItems={gridItems} isItemKeySmall />
          <Center isMarginTop>
            <Button
              htmlType="submit"
              isDisabled={isDisabledBtnSubmit}
              isFullWidth
              isHidden={isHiddenEditBtn}
              text={TEXT_T325}
              type="primary"
            />
          </Center>
        </Space>

        <Divider />
        <Space isSpaceTop isSpaceBottom>
          <Title titleLeft={TEXT_T666} />
          <Table
            columns={columns}
            dataSource={travelerInfoList}
            rowKey="travelScheduleId"
            pagination={false}
          />
        </Space>
        <Button
          htmlType="button"
          isSmall
          onClick={goBack}
          text={TEXT_T072}
          type="ghost"
          isHidden={isHiddenEditBtn}
          isFullWidth
        />
      </Form>
    </Layout>
  )
}
