import { FC } from "react"
import { ButtonGroup } from "../../../../../_webui/layout/components/Button/Group"
import { Form } from "../../../../../_webui/layout/components/Form"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { Breadcrumbs } from "../../../../../constant/Breadcrumbs"
import { Translate } from "../../../../../constant/Translate"
import { TravelersSearchCollapse } from "../../components/search/Collapse"
import { useTravelersSearch } from "./modules/useSearch"

const { TEXT_T012, TEXT_T022, TEXT_T108, TEXT_T113 } = Translate
const { BRCR_SCR120 } = Breadcrumbs

export const TravelersSearch: FC = () => {
  const {
    allCountries,
    cities,
    countryCode,
    departments,
    dispatchType,
    familyRelationship,
    form,
    goToTravelersList,
    isLoadingLayout,
    isOtherCity,
    onBlur,
    onClickReset,
    onCountryChange,
    onFinish,
    onKeyDown,
    onWorkPlaceCityChange,
    onWorkPlaceLocationChange,
    prefectureList,
    workPlaceLocation,
  } = useTravelersSearch()

  return (
    <Layout
      breadcrumbItems={BRCR_SCR120}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T108}
    >
      <Form form={form} heading={TEXT_T108} onFinish={onFinish}>
        <TravelersSearchCollapse
          allCountries={allCountries}
          cities={cities}
          countryCode={countryCode}
          departments={departments}
          dispatchType={dispatchType}
          familyRelationship={familyRelationship}
          isOtherCity={isOtherCity}
          onBlur={onBlur}
          onCountryChange={onCountryChange}
          onKeyDown={onKeyDown}
          onWorkPlaceCityChange={onWorkPlaceCityChange}
          onWorkPlaceLocationChange={onWorkPlaceLocationChange}
          prefectureList={prefectureList}
          workPlaceLocation={workPlaceLocation}
        />
        <ButtonGroup
          buttonItems={[
            {
              htmlType: "button",
              onClick: goToTravelersList,
              order: 3,
              text: TEXT_T012,
              type: "ghost",
            },
            {
              htmlType: "reset",
              onClick: onClickReset,
              order: 2,
              text: TEXT_T113,
              type: "ghost",
            },
            {
              htmlType: "submit",
              order: 1,
              text: TEXT_T022,
              type: "primary",
            },
          ]}
        />
      </Form>
    </Layout>
  )
}
