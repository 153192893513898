import styled from "styled-components"
import { Title } from "../../../../../../../../../_webui/layout/components/Typography/Title"
import { Colors } from "../../../../../../../../../constant/Styled/Colors"

const { FORM_ENTRY_BORDER, TABLE_HEADER } = Colors

const StyledTableRemark = styled.div`
  .ant-table-cell .ant-form-item .ant-form-item-label {
    height: initial;
    min-height: initial;
    text-indent: initial;
    width: 100%;
    > label {
      height: initial;
    }
    &-focused {
      transform: translateY(15px);
    }
    &-left {
      .form-label {
        font-size: 14px;
        height: initial;
        min-height: initial;
        text-indent: initial;
        width: initial;
      }
    }
  }
`

const StyledTitle = styled(Title)`
  background-color: ${TABLE_HEADER};
  border: 1px solid ${FORM_ENTRY_BORDER};
  padding: 6px;
`

const StyledErrorTitle = styled(Title)`
  margin-left: 15px;
`

export const Styled = {
  StyledErrorTitle,
  StyledTableRemark,
  StyledTitle,
}
