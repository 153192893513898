import { Form } from "antd"
import { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { Routers } from "../../../../../../../constant/Routers"
import { useAuthority } from "../../../../../../../utils/hook/useAuthority"
import {
  TravelersSearchFormDataType,
  TravelerSearchProps,
} from "../../../../models/search"
import { useVariablesUtils } from "../useVariablesUtils"
import { useDatePicker } from "../../../../../../../utils/hook/useDatePicker"

const { SCR114, SCR114_USER } = Routers

export const useVariables = () => {
  const [form] = Form.useForm<TravelersSearchFormDataType>()
  const { push } = useHistory()
  const { state } = useLocation<TravelerSearchProps>()
  const { isTravelerSearch } = useAuthority()
  const { onBlur, onKeyDown } = useDatePicker({ form })
  const [countryCode, setCountryCode] = useState("")
  const [workPlaceLocation, setWorkPlaceLocation] = useState<string | number>(0)
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [isOtherCity, setIsOtherCity] = useState(false)
  const {
    allCountries,
    cities,
    departments,
    dispatchType,
    familyRelationship,
    prefectureList,
  } = useVariablesUtils(countryCode)

  const pathname = state?.sourceURL === SCR114_USER ? SCR114_USER : SCR114

  return {
    allCountries,
    cities,
    countryCode,
    departments,
    dispatchType,
    familyRelationship,
    form,
    isLoadingLayout,
    isOtherCity,
    isTravelerSearch,
    onBlur,
    onKeyDown,
    pathname,
    prefectureList,
    push,
    setCountryCode,
    setIsLoadingLayout,
    setIsOtherCity,
    setWorkPlaceLocation,
    state,
    workPlaceLocation,
  }
}
