import { Translate } from "../../../../../../../constant/Translate"

const {
  TEXT_T020,
  TEXT_T022,
  TEXT_T147,
  TEXT_T033,
  TEXT_T034,
  TEXT_T471,
  TEXT_T472,
  TEXT_T473,
  TEXT_T474,
  TEXT_T467,
  TEXT_T468,
  TEXT_T469,
  TEXT_T470,
} = Translate

const ROLE_TRAVELER = [
  { label: `${TEXT_T467} ${TEXT_T147}`, value: "SUF0000001" },
  { label: `${TEXT_T467} ${TEXT_T020}`, value: "SUF0000002" },
  { label: `${TEXT_T467} ${TEXT_T033}`, value: "SUF0000003" },
  { label: `${TEXT_T467} ${TEXT_T034}`, value: "SUF0000004" },
  { label: `${TEXT_T467} ${TEXT_T022}`, value: "SUF0000005" },
  { label: `${TEXT_T467} ${TEXT_T471}`, value: "" },
  { label: `${TEXT_T467} ${TEXT_T472}`, value: "" },
  { label: `${TEXT_T467} ${TEXT_T473}`, value: "" },
  { label: `${TEXT_T467} ${TEXT_T474}`, value: "" },
]
const ROLE_SCHEDULE = [
  { label: `${TEXT_T468} ${TEXT_T147}`, value: "SUF0000007" },
  { label: `${TEXT_T468} ${TEXT_T020}`, value: "SUF0000008" },
  { label: `${TEXT_T468} ${TEXT_T033}`, value: "SUF0000009" },
  { label: `${TEXT_T468} ${TEXT_T034}`, value: "SUF0000010" },
  { label: `${TEXT_T468} ${TEXT_T022}`, value: "SUF0000011" },
  { label: `${TEXT_T468} ${TEXT_T471}`, value: "" },
  { label: `${TEXT_T468} ${TEXT_T472}`, value: "" },
  { label: `${TEXT_T468} ${TEXT_T473}`, value: "" },
  { label: `${TEXT_T468} ${TEXT_T474}`, value: "SUF0000012" },
]
const ROLE_ATTENTION = [
  { label: `${TEXT_T469} ${TEXT_T147}`, value: "" },
  { label: `${TEXT_T469} ${TEXT_T020}`, value: "" },
  { label: `${TEXT_T469} ${TEXT_T033}`, value: "" },
  { label: `${TEXT_T469} ${TEXT_T034}`, value: "" },
  { label: `${TEXT_T469} ${TEXT_T022}`, value: "" },
  { label: `${TEXT_T469} ${TEXT_T471}`, value: "SUF0000013" },
  { label: `${TEXT_T469} ${TEXT_T472}`, value: "SUF0000014" },
  { label: `${TEXT_T469} ${TEXT_T473}`, value: "SUF0000015" },
  { label: `${TEXT_T469} ${TEXT_T474}`, value: "SUF0000016" },
]
const ROLE_SAFETY = [
  { label: `${TEXT_T470} ${TEXT_T147}`, value: "" },
  { label: `${TEXT_T470} ${TEXT_T020}`, value: "" },
  { label: `${TEXT_T470} ${TEXT_T033}`, value: "" },
  { label: `${TEXT_T470} ${TEXT_T034}`, value: "" },
  { label: `${TEXT_T470} ${TEXT_T022}`, value: "" },
  { label: `${TEXT_T470} ${TEXT_T471}`, value: "SUF0000017" },
  { label: `${TEXT_T470} ${TEXT_T472}`, value: "SUF0000018" },
  { label: `${TEXT_T470} ${TEXT_T473}`, value: "SUF0000019" },
  { label: `${TEXT_T470} ${TEXT_T474}`, value: "SUF0000020" },
]

export const RolesConfig = {
  ROLE_ATTENTION,
  ROLE_SAFETY,
  ROLE_SCHEDULE,
  ROLE_TRAVELER,
}
