import { FC } from "react"
import { ModalProps } from "../models"
import { Styled } from "./styled"

const { StyledModalFooter, StyledModalFooterBtn } = Styled

export const ModalButton: FC<ModalProps> = (props) => {
  const {
    cancelCustomText,
    cancelCustomType,
    cancelText,
    cancelType,
    isCenter,
    isShowFooter,
    okText,
    onCancel,
    onCancelCustom,
    onOk,
  } = props

  return isShowFooter ? (
    <StyledModalFooter isCenter={isCenter}>
      {okText && (
        <StyledModalFooterBtn
          className="ant-modal-btn"
          htmlType="button"
          onClick={onOk}
          text={okText}
          type="primary"
        />
      )}
      {cancelText && (
        <StyledModalFooterBtn
          className="ant-modal-btn"
          htmlType="button"
          onClick={onCancel}
          text={cancelText}
          type={cancelType}
        />
      )}
      {cancelCustomText && (
        <StyledModalFooterBtn
          className="ant-modal-btn"
          htmlType="button"
          onClick={onCancelCustom}
          text={cancelCustomText}
          type={cancelCustomType}
        />
      )}
    </StyledModalFooter>
  ) : null
}
