import { FC } from "react"
import { Translate } from "../../../../../../../constant/Translate"
import { ItemInput } from "../../../../../../../_webui/layout/components/Form/Item/Input"
import { ItemSelect } from "../../../../../../../_webui/layout/components/Form/Item/Select"
import { FormSearch } from "../../../../../../../_webui/layout/components/Form/Search"
import { SubAdminFormSearchProps } from "../../../../models/subAdmin/list"
import { InitialValues } from "../../../../constants/subAdmin/list/initialValues"
import { FormSearchLeft } from "../../../../../../../_webui/layout/components/Form/Search/Left"

const { TEXT_T219, TEXT_T451, TEXT_T452 } = Translate
const { BUMON_CD_INITIAL_VALUES } = InitialValues

export const SubAdminSettingFormSearch: FC<SubAdminFormSearchProps> = (
  props,
) => {
  const { departments, handleResetForm, onFinish, form } = props

  return (
    <FormSearch
      heading={TEXT_T451}
      layout="vertical"
      labelAlign="left"
      onFinish={onFinish}
      onClickReset={handleResetForm}
      form={form}
      initialValues={BUMON_CD_INITIAL_VALUES}
    >
      <FormSearchLeft>
        <ItemSelect label={TEXT_T452} name="bumonCd" selectItem={departments} />

        <ItemInput label={TEXT_T219} maxLength={100} name="fullName" />
      </FormSearchLeft>
    </FormSearch>
  )
}
