/* eslint-disable complexity */
import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = (isHiddenSubmit: boolean) => {
  const {
    isCommonTravelerRefer,
    isRoleUser,
    isRoleSubAdmin,
    isSafetiesReport,
    isSafetiesBrowseResponse,
  } = useAuthority()

  const isHiddenBackBtn = !isRoleSubAdmin && !isRoleUser
  const isHiddenExportBtn = !(
    isRoleSubAdmin &&
    isSafetiesReport &&
    isHiddenSubmit
  )
  const isHiddenAnswerBtn = !(
    isRoleSubAdmin &&
    isSafetiesBrowseResponse &&
    isHiddenSubmit
  )

  return {
    isCommonTravelerRefer,
    isHiddenAnswerBtn,
    isHiddenBackBtn,
    isHiddenExportBtn,
    isSafetiesBrowseResponse,
  }
}
