import { Translate } from "../../../../../../../../constant/Translate"
import {
  formatDateTimeExport,
  getDateTimeZoneByUserInformation,
} from "../../../../../../../../utils/getDate"
import { getFullName } from "../../../../../../../../utils/getFullName"
import { getProxyResponser } from "../../../../../../../../utils/getProxyResponser"
import {
  AttentionsDetailDataSourceType,
  AttentionsInfoDataType,
} from "../../../../../models/detail"
import { heading } from "./heading"

const { TEXT_T389, TEXT_T344, TEXT_T348 } = Translate

export const convertDataExport = (
  data: AttentionsDetailDataSourceType[],
  attentionInfo?: AttentionsInfoDataType,
) => {
  let convertData = heading
  data.map((item) => {
    convertData += `\r\n"${
      attentionInfo?.attentionTitle
    }","${getDateTimeZoneByUserInformation(
      attentionInfo?.implementationDateTime,
    )}","${formatDateTimeExport(item.responseDate)}","${
      item.responseOption === "0"
        ? TEXT_T389
        : item.responseOption === "1"
        ? TEXT_T344
        : TEXT_T348
    }","${getProxyResponser(item.proxyResponser)}","${getFullName(
      item.kanjiSurname,
      item.kanjiName,
      true,
    )}","${getFullName(item.romajiSurname, item.romajiName)}",${
      item.dispatchTypeName
    },"${item.dispatchTypeDetailName}","${item.userId}","${
      item.emailAddress1
    }","${item.locationPhoneNumber1}","${item.othersStaffNumber}","${
      item.othersDispatchNumber
    }","${item.othersMemberNumber}"`
  })
  return convertData
}
