/* eslint-disable max-lines */
import { FC } from "react"
import { PanelProps } from "../../../../../../_webui/layout/components/Collapse/models"
import { Translate } from "../../../../../../constant/Translate"
import { TravelersEditCollapseProps } from "../../../models/edit"
import { Assignee } from "./Assignee"
import { Insurance } from "./Insurance"
import { Others } from "./Others"
import { Person } from "./Person"
import { PersonalInfo } from "./PersonalInfo"
import { Posting } from "./Posting"
import { TrainingInfo } from "./TrainingInfo"
import { Urgent } from "./Urgent"
import { Styled } from "./styled"

const { StyledCollapse } = Styled
const {
  TEXT_T520,
  TEXT_T522,
  TEXT_T523,
  TEXT_T524,
  TEXT_T525,
  TEXT_T526,
  TEXT_T527,
  TEXT_T528,
} = Translate

export const TravelersEditCollapse: FC<TravelersEditCollapseProps> = (
  props,
) => {
  const {
    accompanies,
    addItems,
    allCountries,
    cities,
    columns,
    departments,
    dispatchType,
    indexTabActive,
    isAccompanyingFamily,
    isChangeUser,
    isCopyEmailAddress,
    isDifferentInforCheck,
    isDisabledBtnDelete,
    isHiddenTrainingDate,
    isInsuranceRequired,
    isOtherCity,
    isOverseasResident,
    isPersonUrl,
    isRequiredUrgentContact,
    isRoleAdmin,
    isRoleSubAdmin,
    isRoleUser,
    onBlur,
    onChangeTrainingType,
    onCopyEmailAddress,
    onDifferentInforCheckChange,
    onDispatchTypeDetailChange,
    onKeyDown,
    onTabClick,
    onWorkPlaceCityChange,
    onWorkPlaceCountryChange,
    onWorkPlaceLocationChange,
    othersStaffNumber,
    prefectures,
    removeItems,
    rowSelection,
    tabsItems,
  } = props

  const panelItems: PanelProps[] = [
    {
      content: (
        <PersonalInfo
          accompanies={accompanies}
          addItems={addItems}
          columns={columns}
          departments={departments}
          dispatchType={dispatchType}
          indexTabActive={indexTabActive}
          isAccompanyingFamily={isAccompanyingFamily}
          isDifferentInforCheck={isDifferentInforCheck}
          isDisabledBtnDelete={isDisabledBtnDelete}
          isRoleAdmin={isRoleAdmin}
          isRoleUser={isRoleUser}
          onDifferentInforCheckChange={onDifferentInforCheckChange}
          onDispatchTypeDetailChange={onDispatchTypeDetailChange}
          onTabClick={onTabClick}
          othersStaffNumber={othersStaffNumber}
          isPersonUrl={isPersonUrl}
          removeItems={removeItems}
          rowSelection={rowSelection}
          tabsItems={tabsItems}
        />
      ),
      header: TEXT_T520,
      key: 1,
    },
    {
      content: (
        <Person
          isAccompanyingFamily={isAccompanyingFamily}
          isCopyEmailAddress={isCopyEmailAddress}
          onCopyEmailAddress={onCopyEmailAddress}
          othersStaffNumber={othersStaffNumber}
          prefectures={prefectures}
        />
      ),
      header: TEXT_T522,
      key: 2,
    },
    {
      content: <Urgent isRequiredUrgentContact={isRequiredUrgentContact} />,
      header: TEXT_T523,
      key: 3,
    },
    {
      content: (
        <Posting
          allCountries={allCountries}
          cities={cities}
          isAccompanyingFamily={isAccompanyingFamily}
          isOtherCity={isOtherCity}
          isOverseasResident={isOverseasResident}
          onWorkPlaceCityChange={onWorkPlaceCityChange}
          onWorkPlaceCountryChange={onWorkPlaceCountryChange}
          onWorkPlaceLocationChange={onWorkPlaceLocationChange}
        />
      ),
      header: TEXT_T524,
      key: 4,
    },
    {
      content: <Assignee />,
      header: TEXT_T525,
      key: 5,
    },
    {
      content: <Insurance isInsuranceRequired={isInsuranceRequired} />,
      header: TEXT_T526,
      key: 6,
    },
    {
      content: (
        <TrainingInfo
          isAccompanyingFamily={isAccompanyingFamily}
          isHiddenTrainingDate={isHiddenTrainingDate}
          onBlur={onBlur}
          onChangeTrainingType={onChangeTrainingType}
          onKeyDown={onKeyDown}
        />
      ),
      header: TEXT_T527,
      key: 7,
    },
    {
      content: (
        <Others
          isChangeUser={isChangeUser}
          isRoleAdmin={isRoleAdmin}
          isRoleSubAdmin={isRoleSubAdmin}
        />
      ),
      header: TEXT_T528,
      key: 8,
    },
  ]

  return (
    <StyledCollapse
      defaultActiveKey={["1", "2", "3", "4", "5", "6", "7", "8", "9"]}
      panelItems={panelItems}
    />
  )
}
