import { useEffect } from "react"
import { Routers } from "../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { checkIsArray } from "../../../../../../../utils/checkIsArray"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { systemsApi } from "../../../../apis"
import { RequiredFields } from "../../../../constants/contact/RequiredFields"
import { ContactFormDataType, UseContactType } from "../../../../models/contact"
import { useVariables } from "../useVariables"

const { CONTACT_REQUIRED_FIELDS } = RequiredFields
const { SCR920, SCR973, SCR993 } = Routers

export const useContact = (): UseContactType<ContactFormDataType> => {
  const {
    form,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isOpenClear,
    isOpenConfirm,
    onCancelClear,
    onCancelConfirm,
    onOpenClear,
    onOpenConfirm,
    push,
    setIsDisabledSubmit,
    setIsLoading,
    setIsLoadingLayout,
    setValues,
    values,
  } = useVariables()

  const onOkClear = () => {
    setIsDisabledSubmit(true)
    form.resetFields()
  }

  const onValuesChange = () => {
    const formValues = form.getFieldsValue(CONTACT_REQUIRED_FIELDS)
    setTimeout(() => {
      const formErrors = !!form
        .getFieldsError()
        .filter(({ errors }) => errors.length).length
      setIsDisabledSubmit(
        checkIsArray(Object.values(formValues).filter((item) => !item)) ||
          formErrors,
      )
    })
  }

  const onFinish = (values: ContactFormDataType) => {
    setValues(values)
    onOpenConfirm()
  }

  const onOkConfirm = () => {
    if (typeof values === "object") {
      setIsLoading(true)
      systemsApi
        .sendFAQ(values)
        .then(() => {
          push(SCR993)
        })
        .catch((error: AxiosErrorType) => {
          // 「SCR973_エラー情報の表示画面」に遷移する。
          const stateError = pushStateError(error)
          push({ pathname: SCR973, state: stateError })
        })
    }
  }

  const goToHome = () => {
    push(SCR920)
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoadingLayout(false)
    }, 500)
  }, [])

  return {
    form,
    goToHome,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isOpenClear,
    isOpenConfirm,
    onCancelClear,
    onCancelConfirm,
    onFinish,
    onOkClear,
    onOkConfirm,
    onOpenClear,
    onValuesChange,
  }
}
