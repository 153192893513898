import { getTextConfirm } from "../../../../../../../../utils/getTextConfirm"
import { getTextYesNo } from "../../../../../../../../utils/getTextYesNo"
import { getDay } from "../../../../../../../../utils/getDate"
import { joinDate } from "../../../../../../../../utils/joinDate"
import { SchedulesBatchImportDataResType } from "../../../../../models/batchImport"
import { SchedulesBatchImportDataType } from "../../../../../models/batchImport/data"

export const convertDataSource = (
  data: SchedulesBatchImportDataResType[],
): SchedulesBatchImportDataType[] =>
  data?.map((item) => ({
    ...item,
    arrivalDateTime: joinDate(item.arrivalDateTime),
    codeConductConfirmation: getTextConfirm(item.codeConductConfirmation),
    departureDateTime: joinDate(item.departureDateTime),
    differentInforCheck: getTextYesNo(item.differentInforCheck),
    dispatchEndDate: getDay(item.dispatchEndDate),
    dispatchStartDate: getDay(item.dispatchStartDate),
    lodgingCheckin: joinDate(item.lodgingCheckin, true),
    lodgingCheckout: joinDate(item.lodgingCheckout, true),
  }))
