import { RadioItemType } from "../../_webui/layout/components/Radio/models"
import { Translate } from "../Translate"

const { TEXT_T090, TEXT_T091, TEXT_T092, TEXT_T093, TEXT_T138, TEXT_T612 } =
  Translate

const WORK_PLACE_LOCATION_SEARCH: RadioItemType[] = [
  { text: TEXT_T612, value: "" },
  { text: TEXT_T090, value: 1 },
  { text: TEXT_T091, value: 2 },
]

const WORK_PLACE_LOCATION_EDIT: RadioItemType[] = [
  { text: TEXT_T138, value: 0 },
  { text: TEXT_T090, value: 1 },
  { text: TEXT_T091, value: 2 },
]

const TRAINING_TYPE_SEARCH: RadioItemType[] = [
  { text: TEXT_T612, value: "" },
  { text: TEXT_T092, value: 1 },
  { text: TEXT_T093, value: 2 },
]

const TRAINING_TYPE_EDIT: RadioItemType[] = [
  { text: TEXT_T138, value: 0 },
  { text: TEXT_T092, value: 1 },
  { text: TEXT_T093, value: 2 },
]

export const RadioItems = {
  TRAINING_TYPE_EDIT,
  TRAINING_TYPE_SEARCH,
  WORK_PLACE_LOCATION_EDIT,
  WORK_PLACE_LOCATION_SEARCH,
}
