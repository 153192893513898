import { Translate } from "../../../../../../../../constant/Translate"
import { ColumnType } from "../../../../../../../../_webui/layout/components/Table/models"
import { ScheduleLodgingsType } from "../../../../../models/addSuccess"
import { getValueByLanguage } from "../../../../../../../../utils/getValueByLanguage"

const { TEXT_T210, TEXT_T240, TEXT_T251, TEXT_T252, TEXT_T253, TEXT_T254 } =
  Translate

export const useLodgingTableConfig = (): ColumnType<ScheduleLodgingsType>[] => [
  {
    render: (_, _data, index) => index + 1,
    title: TEXT_T240,
    width: 65,
  },
  {
    dataIndex: "lodgingCheckin",
    title: TEXT_T251,
    width: 200,
  },
  {
    dataIndex: "lodgingCheckout",
    title: TEXT_T252,
    width: 200,
  },
  {
    dataIndex: "lodgingName",
    title: TEXT_T210,
    width: 200,
  },
  {
    dataIndex: "lodgingPhoneNumber",
    title: TEXT_T254,
    width: 200,
  },
  {
    dataIndex: "lodgingAddress",
    title: TEXT_T253,
    width: getValueByLanguage(250, 300),
  },
]
