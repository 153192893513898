/* eslint-disable max-statements */
/* eslint-disable max-lines */
import { OptionItemType } from "../../../../../../../_webui/layout/components/Select/models"
import { Routers } from "../../../../../../../constant/Routers"
import { Translate } from "../../../../../../../constant/Translate"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { AllCountriesResType } from "../../../../../../../infrastructure/handlers/countries/models"
import { GeneralMastersResType } from "../../../../../../../infrastructure/handlers/generalMasters/models"
import { getFullName } from "../../../../../../../utils/getFullName"
import { getValueByLanguage } from "../../../../../../../utils/getValueByLanguage"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { replaceStringParam } from "../../../../../../../utils/replaceStringParam"
import { scheduleApi } from "../../../../apis"
import { InitialValues } from "../../../../constants/list/initialValues"
import { Variables } from "../../../../constants/list/variables"
import {
  SchedulesListDataType,
  SchedulesListPostType,
  UseSchedulesListApiProps,
} from "../../../../models/list"
import {
  SchedulesAdvancedSearchFormDataType,
  SchedulesSearchFormDataType,
} from "../../../../models/list/search"
import { useVariablesApi } from "../useVariablesApi"
import { convertAdvancedSearchDataSubmit } from "./convertAdvancedSearchDataSubmit"
import { convertSearchDataSubmit } from "./convertSearchDataSubmit"
import { getDataSearchAdvanced } from "./getDataSearchAdvanced"
import { getDataSearchBasic } from "./getDataSearchBasic"
import { getDataSubmit } from "./getDataSubmit"
import { convertDataSource } from "./convertDataSource"
import { DepartmentsResType } from "../../../../../../../infrastructure/handlers/departments/models"
import { departmentsApi } from "../../../../../../../infrastructure/handlers/departments"
import { convertBasicToAdvance } from "./convertBasicToAdvance"
import { GeneralCode } from "../../../../../../../constant/GeneralCode"

const { UNIVERSAL_COUNTRY_CODE } = GeneralCode
const { SCHEDULES_ADVANCED_SEARCH_INITIAL_VALUES } = InitialValues
const { SCR210_USER_SEARCH, SCR973 } = Routers
const { MESSAGE_I0022 } = Translate
const { SCHEDULE_LIST_OVER_RECORD } = Variables

export const useApi = (props: UseSchedulesListApiProps) => {
  const { allCountries, isGetCountries, isRoleSubAdmin } = props
  const {
    countries,
    dataSource,
    dataSourceSelected,
    dataSubmit,
    dataTemp,
    departments,
    form,
    formAdvanced,
    fullName,
    handleSetDefaultSortedInfo,
    isDisabledBtnCreateOrSend,
    isDisabledBtnDelete,
    isDisabledBtnRefer,
    isLoadingLayout,
    isLoadingTable,
    isOpen,
    isOpenLoadData,
    isOpenShowData,
    isOverseasResident,
    isPathnameAttentionsOrSafeties,
    isPathnameSchedules,
    isResetTable,
    isSearchAdvance,
    isSearchBasic,
    onCancel,
    onCancelLoadData,
    onCancelShowData,
    onChange,
    onChangeTable,
    onOpen,
    onOpenLoadData,
    onOpenShowData,
    overRecordLabel,
    pathname,
    perItem,
    purposeTravel,
    push,
    rowSelection,
    searchCondition,
    searchConditionAdvanced,
    searchConditionBasic,
    selectedRowKeys,
    setCountries,
    setDataSource,
    setDataSubmit,
    setDataTemp,
    setDepartments,
    setIsLoadingLayout,
    setIsLoadingTable,
    setIsOverseasResident,
    setIsResetTable,
    setIsSearchAdvance,
    setIsSearchBasic,
    setOverRecordLabel,
    setPurposeTravel,
    setSearchCondition,
    setSearchConditionAdvanced,
    setSearchConditionBasic,
    setTravelerId,
    sortedInfo,
    state,
    travelScheduleId,
    travelerId,
    userInformation,
  } = useVariablesApi(isRoleSubAdmin)

  const getSchedules = async (
    data: SchedulesListPostType,
    isSearchCondition?: boolean,
  ) => {
    const _data = getDataSubmit({
      data,
      isFromSCR114UserSearch: pathname === SCR210_USER_SEARCH,
      isFromSCR115: state?.isFromSCR115,
      pathname,
      searchCondition: state?.searchCondition,
      travelerId:
        data?.["travelerId" as keyof SchedulesListPostType] ?? travelerId,
    })
    setDataSubmit(_data)

    handleSetDefaultSortedInfo()
    // APIを呼び出し、「渡航予定情報の一覧」のデータ一覧を取得する。
    await scheduleApi
      .getSchedules(_data)
      .then((res: SchedulesListDataType[]) => {
        if (res.length > SCHEDULE_LIST_OVER_RECORD) {
          const _isSearchCondition = isSearchCondition || isSearchBasic
          const data = convertDataSource(res)
          if (_isSearchCondition) {
            onOpenLoadData()
            setDataTemp(data)
          } else {
            setDataSource(data.slice(0, SCHEDULE_LIST_OVER_RECORD))
            setOverRecordLabel(
              replaceStringParam(MESSAGE_I0022, SCHEDULE_LIST_OVER_RECORD),
            )
          }
        } else {
          const data = convertDataSource(res)
          setOverRecordLabel("")
          setDataSource(data)
        }
        setIsLoadingLayout(false)
        setIsLoadingTable(false)
        document.getElementById("title-right")?.focus()
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const stateError = pushStateError(error)
        push({ pathname: SCR973, state: stateError })
      })
  }

  const onOkLoadData = () => {
    onCancelLoadData()
    const _data = getDataSubmit({
      data: dataSubmit,
      isFromSCR115: state?.isFromSCR115,
      isGetAllData: true,
      pathname,
      searchCondition: state?.searchCondition,
      travelerId:
        dataSubmit?.["travelerId" as keyof SchedulesListPostType] ?? travelerId,
    })

    handleSetDefaultSortedInfo()
    setIsLoadingTable(true)
    // APIを呼び出し、「渡航予定情報の一覧」のデータ一覧を取得する。
    scheduleApi
      .getSchedules(_data)
      .then((res: SchedulesListDataType[]) => {
        setDataSource(res)
        setIsLoadingTable(false)
        setOverRecordLabel("")
        setTimeout(() => {
          document.getElementById("title-right")?.focus()
        }, 600)
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const stateError = pushStateError(error)
        push({ pathname: SCR973, state: stateError })
      })
  }

  const onCancelCustomLoadData = () => {
    onCancelLoadData()
    onOpenShowData()
  }

  const onOkShowData = () => {
    const _dataTemp = dataTemp.slice(0, SCHEDULE_LIST_OVER_RECORD)
    setDataSource(_dataTemp)
    setOverRecordLabel(
      replaceStringParam(MESSAGE_I0022, SCHEDULE_LIST_OVER_RECORD),
    )
    onCancelShowData()
    setTimeout(() => {
      document.getElementById("title-right")?.focus()
    }, 600)
  }

  const handleAdvancedSearch = (
    values: SchedulesAdvancedSearchFormDataType,
  ) => {
    const {
      arrivalCountryCode,
      arrivalDateTimeDate,
      bumonCd,
      currentLocation,
      departureDateTimeDate,
      isTransit,
      kanjiName,
      kanjiSurname,
      projectNameEn,
      projectNameJp,
      romajiName,
      romajiSurname,
    }: SchedulesAdvancedSearchFormDataType = formAdvanced.getFieldsValue([
      "arrivalCountryCode",
      "arrivalDateTimeDate",
      "bumonCd",
      "currentLocation",
      "departureDateTimeDate",
      "isTransit",
      "kanjiName",
      "kanjiSurname",
      "projectNameEn",
      "projectNameJp",
      "romajiName",
      "romajiSurname",
    ])

    const fullNameJp = getFullName(kanjiSurname, kanjiName, true)
    const fullNameEn = getFullName(romajiSurname, romajiName)
    const projectName = getValueByLanguage(projectNameJp, projectNameEn)
    const countryToTravel = allCountries?.find(
      (item) => item.value === arrivalCountryCode,
    )?.value

    form.setFieldsValue({
      arrivalDateTimeDate: arrivalDateTimeDate,
      bumonCd,
      countryToTravel,
      currentLocation,
      departureDateTimeDate: departureDateTimeDate,
      fullName: fullNameEn ? fullNameEn : fullNameJp,
      isTransit,
      projectName,
    })

    const data = convertAdvancedSearchDataSubmit({
      isPathnameAttentionsOrSafeties,
      pathname,
      values,
    })

    if (isPathnameSchedules) form.setFieldValue("fullName", fullName)

    onCancel()
    setIsResetTable(true)
    setIsLoadingTable(true)
    onChange(1, 25)
    setTimeout(async () => {
      const isValues = Object.values(values).filter((item) => !!item).length
      const dataSearch = getDataSearchAdvanced(values)
      if (!isOverseasResident) {
        formAdvanced.setFieldsValue({
          workPlace: "",
          workPlaceCity: "",
          workPlaceCountry: "",
        })
      }
      setSearchConditionAdvanced(dataSearch)
      setSearchCondition({
        ...searchConditionBasic,
        ...dataSearch,
      })
      setIsResetTable(false)
      setIsSearchAdvance(!!isValues)
      setIsSearchBasic(!isValues)
      await getSchedules(data, true)
    }, 500)
  }

  const handleSearch = async (values: SchedulesSearchFormDataType) => {
    const dataSearch = getDataSearchBasic(values)
    const data = convertSearchDataSubmit({
      isPathnameAttentionsOrSafeties,
      isPathnameSchedules,
      pathname,
      searchCondition: {
        ...searchConditionAdvanced,
        ...dataSearch,
        kanjiName: "",
        kanjiSurname: "",
        projectNameEn: "",
        projectNameJp: "",
        romajiName: "",
        romajiSurname: "",
      },
      values,
    })

    formAdvanced.setFieldsValue(SCHEDULES_ADVANCED_SEARCH_INITIAL_VALUES)
    setIsOverseasResident(false)
    setIsResetTable(true)
    setIsLoadingTable(true)
    onChange(1, 25)
    setTimeout(async () => {
      setSearchConditionBasic(dataSearch)
      setSearchCondition({
        ...searchConditionAdvanced,
        ...dataSearch,
      })
      const isNotConditionAdvanced = !!Object.values(
        searchConditionAdvanced,
      ).filter((item) => !!item).length

      const dataBasicConvert = convertBasicToAdvance(dataSearch)
      const isValues = Object.values({
        ...searchConditionAdvanced,
        ...dataBasicConvert,
      }).filter((item) => !!item).length
      setIsResetTable(false)
      setIsSearchAdvance(isNotConditionAdvanced && !!isValues)
      setIsSearchBasic(true)
      await getSchedules(data, true)
    }, 500)
  }

  const getData = () => {
    const newPromise = new Promise<AllCountriesResType[]>((res) => res([]))
    const getCountriesAPI: Promise<AllCountriesResType[]> = isGetCountries
      ? scheduleApi.getCountries()
      : newPromise

    const getPurposeTravelAPI: Promise<GeneralMastersResType[]> =
      scheduleApi.getPurposeTravel()
    const getAllDepartments: Promise<DepartmentsResType[]> =
      departmentsApi.getAllDepartments()

    Promise.all([getCountriesAPI, getPurposeTravelAPI, getAllDepartments])
      .then(async (res) => {
        if (res[0]) {
          const _countries: OptionItemType[] = res[0]
            .map((item) => ({
              text: item.countryName,
              value: item.countryCode,
            }))
            .filter((item) => item.value !== UNIVERSAL_COUNTRY_CODE)
          setCountries(_countries)
        }
        const _purposeTravel: OptionItemType[] = res[1].map((item) => ({
          text: String(getValueByLanguage(item.value1, item.value2)),
          value: item.key3,
        }))
        setPurposeTravel(_purposeTravel)
        const _departments: OptionItemType[] = res[2].map((item) => ({
          text: String(
            getValueByLanguage(
              item.departmentNameJapan,
              item.departmentNameEnglish,
            ),
          ),
          value: item.bumonCd,
        }))
        setDepartments(_departments)
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const stateError = pushStateError(error)
        push({ pathname: SCR973, state: stateError })
      })
  }

  return {
    countries,
    dataSource,
    dataSourceSelected,
    departments,
    form,
    formAdvanced,
    fullName,
    getData,
    getSchedules,
    handleAdvancedSearch,
    handleSearch,
    isDisabledBtnCreateOrSend,
    isDisabledBtnDelete,
    isDisabledBtnRefer,
    isLoadingLayout,
    isLoadingTable,
    isOpen,
    isOpenLoadData,
    isOpenShowData,
    isOverseasResident,
    isPathnameAttentionsOrSafeties,
    isResetTable,
    isSearchAdvance,
    isSearchBasic,
    onCancel,
    onCancelCustomLoadData,
    onChange,
    onChangeTable,
    onOkLoadData,
    onOkShowData,
    onOpen,
    overRecordLabel,
    perItem,
    purposeTravel,
    rowSelection,
    searchCondition,
    selectedRowKeys,
    setIsOverseasResident,
    setIsSearchAdvance,
    setSearchCondition,
    setSearchConditionAdvanced,
    setTravelerId,
    sortedInfo,
    travelScheduleId,
    userInformation,
  }
}
