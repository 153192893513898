import { FormInstance } from "antd"
import { RequiredFields } from "../../../../../constants/add/requiredFields"
import { TravelersFormDataType } from "../../../../../models/add"

const { TRAVELERS_ADD_REQUIRED_FIELDS } = RequiredFields

export const getFormValues = (
  form: FormInstance<TravelersFormDataType>,
  isAccompanyingFamily: boolean,
) => {
  const bumonCdRequiredField = isAccompanyingFamily ? [] : ["bumonCd"]

  const formValues = form.getFieldsValue([
    ...TRAVELERS_ADD_REQUIRED_FIELDS,
    ...bumonCdRequiredField,
  ])

  if (isAccompanyingFamily)
    form.setFields([
      {
        errors: [],
        name: "bumonCd",
      },
    ])

  return formValues
}
