import { FC } from "react"
import { ButtonGroup } from "../../../../../_webui/layout/components/Button/Group"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { ModalConfirm } from "../../../../../_webui/layout/components/Modal/Confirm"
import { Table } from "../../../../../_webui/layout/components/Table"
import { ErrorList } from "../../../../../_webui/layout/components/Typography/ErrorList"
import { Title } from "../../../../../_webui/layout/components/Typography/Title"
import { Translate } from "../../../../../constant/Translate"
import { useSchedulesConfirmDelete } from "./modules/useConfirmDelete"

const { MESSAGE_E0045, TEXT_T012, TEXT_T663, TEXT_T189, TEXT_T295 } = Translate

export const SchedulesConfirmDelete: FC = () => {
  const {
    breadcrumbItems,
    columns,
    errorItems,
    goBack,
    isLoading,
    isLoadingLayout,
    isOpen,
    isVisibleBtnCancel,
    isVisibleBtnYes,
    onCancel,
    onOk,
    onOpen,
    scheduleList,
  } = useSchedulesConfirmDelete()

  return (
    <Layout
      breadcrumbItems={breadcrumbItems}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T295}
    >
      <Card heading={TEXT_T295} isFullHeight isLoading={isLoading}>
        <Title titleLeft={TEXT_T663} />
        <ErrorList errorItems={errorItems} heading={MESSAGE_E0045} />
        <Table
          columns={columns}
          dataSource={scheduleList}
          isLoading={false}
          pagination={false}
          key="travelScheduleId"
        />
        <ButtonGroup
          buttonItems={[
            {
              isHidden: !isVisibleBtnCancel,
              onClick: goBack,
              order: 2,
              text: TEXT_T012,
              type: "ghost",
            },
            {
              isHidden: !isVisibleBtnYes,
              // ユーザが渡航予定情報削除依頼を作成する旨の確認ダイアログボックスを作成する。
              onClick: onOpen,
              order: 1,
              text: TEXT_T189,
              type: "primary",
            },
          ]}
        />
        <ModalConfirm isOpen={isOpen} onCancel={onCancel} onOk={onOk} />
      </Card>
    </Layout>
  )
}
