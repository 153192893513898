/* eslint-disable max-statements */
import { useHistory, useParams } from "react-router-dom"
import { GeneralCode } from "../../../../../../../constant/GeneralCode"
import { getGeneralMasters } from "../../../../../../../utils/apis/getGeneralMasters"
import { useLodgings } from "../useLodging"
import { useStays } from "../useStay"
import { useDetailTableConfigFirst } from "../useTableConfigs/useDetailTableConfigFirst"
import { useDetailTabsConfig } from "../useTabsConfigs/useDetailTabsConfig"
import { UseParamsType } from "../../../../../../../utils/useParams"
import { useState } from "react"
import { ScheduleAddSuccessDataType } from "../../../../models/addSuccess"
import { InitialValues } from "../../../../constants/addSuccess/initialValues"
import { useGridConfigs } from "../useGridConfigs"

const { COUNTRYRM } = GeneralCode
const { INITIAL_VALUES_SCHEDULE_DATA } = InitialValues

export const useVariablesApi = (isVisibleOtherRemarks: boolean) => {
  const { push } = useHistory()
  const { id: travelScheduleId } = useParams<UseParamsType>()
  const [schedulesData, setSchedulesData] =
    useState<ScheduleAddSuccessDataType>(INITIAL_VALUES_SCHEDULE_DATA)
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [isVisibleBtnDelete, setIsVisibleBtnDelete] = useState(false)

  const {
    info: { travelerId },
    info: scheduleInfo,
    details: scheduleDetails,
    lodgings: scheduleLodgings,
    stays: scheduleStays,
  } = schedulesData

  const {
    assignmentGridItem,
    confirmGridItem,
    detailsGridItem,
    insuranceGridItem,
    otherGridItem,
    personalGridItem,
  } = useGridConfigs({
    isVisibleOtherRemarks,
    scheduleInfo,
    travelerId,
  })

  const { generalMastersRemark } = getGeneralMasters(COUNTRYRM)
  const detailsColumnsFirst = useDetailTableConfigFirst()
  const detailsTabsItems = useDetailTabsConfig(scheduleDetails)
  const { lodgingsColumns, lodgingsTabsItems } = useLodgings(scheduleLodgings)
  const { staysColumns, staysTabsItems } = useStays(scheduleStays)

  return {
    assignmentGridItem,
    confirmGridItem,
    detailsColumnsFirst,
    detailsGridItem,
    detailsTabsItems,
    generalMastersRemark,
    insuranceGridItem,
    isLoadingLayout,
    isVisibleBtnDelete,
    lodgingsColumns,
    lodgingsTabsItems,
    otherGridItem,
    personalGridItem,
    push,
    scheduleDetails,
    scheduleLodgings,
    scheduleStays,
    schedulesData,
    setIsLoadingLayout,
    setIsVisibleBtnDelete,
    setSchedulesData,
    staysColumns,
    staysTabsItems,
    travelScheduleId,
  }
}
