import { FC } from "react"
import { ButtonGroup } from "../../../../../_webui/layout/components/Button/Group"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { Table } from "../../../../../_webui/layout/components/Table"
import { Title } from "../../../../../_webui/layout/components/Typography/Title"
import { TotalRecords } from "../../../../../_webui/layout/components/Typography/TotalRecords"
import { Translate } from "../../../../../constant/Translate"
import { useSchedulesClone } from "./modules/useClone"

const { TEXT_T072, TEXT_T310, TEXT_T486, TEXT_T487, TEXT_T073 } = Translate

export const SchedulesClone: FC = () => {
  const {
    breadcrumbItems,
    columns,
    dataSource,
    goBackSchedulesAdd,
    goToSchedulesAdd,
    isDisabledBtnClone,
    isLoadingLayout,
    isVisibleBtnBack,
    isVisibleBtnCopy,
    onChange,
    onChangeTable,
    perItem,
    rowSelection,
  } = useSchedulesClone()

  return (
    <Layout
      breadcrumbItems={breadcrumbItems}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T486}
    >
      <Card heading={TEXT_T486} isFullHeight>
        <Title titleLeft={TEXT_T487} />
        <TotalRecords
          titleLeft={TEXT_T073}
          perItem={perItem}
          total={dataSource?.length}
        />
        <Table
          columns={columns}
          dataSource={dataSource}
          onChange={onChange}
          onChangeTable={onChangeTable}
          rowKey="scheduleId"
          rowSelection={rowSelection}
        />
        <ButtonGroup
          buttonItems={[
            {
              isAbsolute: true,
              isHidden: !isVisibleBtnBack,
              isSmall: true,
              onClick: goBackSchedulesAdd,
              order: 2,
              text: TEXT_T072,
              type: "ghost",
            },
            {
              isDisabled: isDisabledBtnClone,
              isHidden: !isVisibleBtnCopy,
              onClick: goToSchedulesAdd,
              order: 1,
              text: TEXT_T310,
              type: "primary",
            },
          ]}
        />
      </Card>
    </Layout>
  )
}
