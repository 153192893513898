import { FC } from "react"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Center } from "../../../../../_webui/layout/components/Center"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { List } from "../../../../../_webui/layout/components/List"
import { Title } from "../../../../../_webui/layout/components/Typography/Title"
import { Translate } from "../../../../../constant/Translate"
import { usePrivacyPolicy } from "./modules/usePrivacyPolicy"
import { Button } from "../../../../../_webui/layout/components/Button"
import { Breadcrumbs } from "../../../../../constant/Breadcrumbs"

const { BRCR_SCR994 } = Breadcrumbs
const { TEXT_T072, TEXT_T531, TEXT_T696, TEXT_T697 } = Translate

export const PrivacyPolicy: FC = () => {
  const { acquisitionInfo, goToHome, isLoadingLayout } = usePrivacyPolicy()

  return (
    <Layout
      breadcrumbItems={BRCR_SCR994}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T696}
    >
      <Card heading={TEXT_T696} isFullHeight>
        <Title titleLeft={TEXT_T697} />
        <List heading={TEXT_T531} listItems={acquisitionInfo} />
        <Center>
          <Button
            isFullWidth
            onClick={goToHome}
            text={TEXT_T072}
            type="default"
          />
        </Center>
      </Card>
    </Layout>
  )
}
