import { OptionItemType } from "../../../../../../../../_webui/layout/components/Select/models"
import { GeneralCode } from "../../../../../../../../constant/GeneralCode"
import { Routers } from "../../../../../../../../constant/Routers"
import { Translate } from "../../../../../../../../constant/Translate"
import { AxiosErrorType } from "../../../../../../../../infrastructure/axiosError/models"
import { generalMastersApi } from "../../../../../../../../infrastructure/handlers/generalMasters"
import { GeneralMastersResType } from "../../../../../../../../infrastructure/handlers/generalMasters/models"
import { timezoneApi } from "../../../../../../../../infrastructure/handlers/timezone"
import { TimezoneResType } from "../../../../../../../../infrastructure/handlers/timezone/models"
import { getValueByLanguage } from "../../../../../../../../utils/getValueByLanguage"
import { pushStateError } from "../../../../../../../../utils/pushStateError"
import {
  summerTimeText,
  summerTimeValue,
} from "../../../../../../../../utils/summerTime"
import { commonApi } from "../../../../../apis"
import { useVariablesApi } from "../useVariablesApi"

const { TIFM } = GeneralCode
const { SCR973 } = Routers
const { TEXT_T120, TEXT_T127 } = Translate

export const useApi = () => {
  const {
    form,
    isLoadingLayout,
    push,
    setIsLoadingLayout,
    setTimeFormatItems,
    setTimeZoneItems,
    timeFormatItems,
    timeZoneItems,
  } = useVariablesApi()

  const callApi = async () => {
    // APIを呼び出し、「タイムゾ－ン」コンボボックスの情報を取得する。
    const getTimezones: GeneralMastersResType[] = await commonApi.getTimezones()
    // APIを呼び出し、「タイムフォ－マット」コンボボックスの情報を取得する。
    const getGeneralTimeFormat: GeneralMastersResType[] =
      await generalMastersApi.getGeneralMaster(TIFM)
    // APIを呼び出し、タイムゾーン情報を取得する。
    const getTimezone: TimezoneResType = await timezoneApi.getTimezone()

    Promise.all([getTimezones, getGeneralTimeFormat, getTimezone])
      .then((res) => {
        const _timeZoneItems: OptionItemType[] = res[0].map((item) => ({
          text: `${item.value2} (GMT${item.value3})${summerTimeText(
            item?.key2,
          )}`,
          value: `${item.key1}_${item.key2}_${item.key5}`,
        }))
        setTimeZoneItems(_timeZoneItems)
        const _timeFormatItems: OptionItemType[] = res[1].map((item) => ({
          text: `${getValueByLanguage(item.value1, item.value2)} ${
            item.value1.endsWith(" a") ? TEXT_T127 : TEXT_T120
          }`,
          value: item.key1,
        }))
        setTimeFormatItems(_timeFormatItems)

        const timeFormat = _timeFormatItems.find(
          (item) => item.value === res[2].timeFormat,
        )?.value
        const timeZone = _timeZoneItems.find(
          (item) =>
            item.value ===
            `${res[2].timeZone}_${summerTimeValue(res[2].isSummerTime)}_${
              res[2].key5
            }`,
        )?.value
        form.setFieldsValue({
          timeFormat,
          timeZone,
        })
        setIsLoadingLayout(false)
      })
      .catch((error: AxiosErrorType) => {
        const state = pushStateError(error)
        push({ pathname: SCR973, state })
      })
  }

  return {
    callApi,
    form,
    isLoadingLayout,
    timeFormatItems,
    timeZoneItems,
  }
}
