/* eslint-disable complexity */
import { Translate } from "../../constant/Translate"
import { AxiosErrorType } from "../../infrastructure/axiosError/models"
import { concat } from "../concat"
import { getBackUrl } from "../getBackUrl"
import { replaceStringParam } from "../replaceStringParam"
import { translateMessage } from "../translateMessage"
import { initialValues } from "./constant/initialValues"
import { PushStateErrorType } from "./models"

const { TEXT_T072 } = Translate
const { INITIAL_VALUES_STATE_ERROR } = initialValues

export const pushStateError = (
  error?: AxiosErrorType | null,
  textButton?: string,
  url?: string,
): PushStateErrorType => {
  const stateError: PushStateErrorType = {
    ...INITIAL_VALUES_STATE_ERROR,
    textButton: textButton || TEXT_T072,
    url: getBackUrl(
      url,
      concat(window.location.pathname, window.location.search),
    ),
  }
  if (error?.debug?.response) {
    const { message, title } = error.debug.response.data
    if (message && title) {
      if (error.debug.response.data?.params)
        return {
          ...stateError,
          message: replaceStringParam(
            translateMessage(`MESSAGE.${message}`),
            error.debug.response.data.params,
          ),
          title,
        }
      return {
        ...stateError,
        message: translateMessage(`MESSAGE.${message}`),
        title,
      }
    }
  }
  return stateError
}
