import { useAuthority } from "../../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const { isCommonUploadRefer, isRoleSubAdmin, isRoleUser, isCommonRefer } =
    useAuthority()

  const isNotAccessScreen = !isCommonUploadRefer

  const isVisibleBtn = isRoleUser || isRoleSubAdmin
  // 画面上のボタン表示条件
  const isVisibleBtnCancel = isVisibleBtn && isCommonRefer
  const isVisibleBtnDownload = isVisibleBtn && isCommonUploadRefer
  const isVisibleBtnReference = isVisibleBtn && isCommonUploadRefer

  return {
    isNotAccessScreen,
    isVisibleBtnCancel,
    isVisibleBtnDownload,
    isVisibleBtnReference,
  }
}
