import { FC, Fragment } from "react"
import { Translate } from "../../../../../../../constant/Translate"
import { ItemInput } from "../../../../../../../_webui/layout/components/Form/Item/Input"
import { ItemSelect } from "../../../../../../../_webui/layout/components/Form/Item/Select"
import { CollapsePersonProps } from "./models"

const { TEXT_T017, TEXT_T046, TEXT_T047, TEXT_T048, TEXT_T049, TEXT_T218 } =
  Translate

export const CollapsePerson: FC<CollapsePersonProps> = (props) => {
  const { prefectureList } = props

  return (
    <Fragment>
      <ItemSelect
        label={TEXT_T218}
        name="personPrefectureResidence"
        selectItem={prefectureList}
      />
      <ItemInput
        label={TEXT_T046}
        maxLength={10}
        name="personCountryPhoneCode"
      />
      <ItemInput label={TEXT_T047} maxLength={20} name="personPhoneNumber" />
      <ItemInput label={TEXT_T017} maxLength={255} name="personEmailAddress" />
      <ItemInput
        label={TEXT_T048}
        maxLength={200}
        name="personCompanyPosition"
      />
      <ItemInput
        label={TEXT_T049}
        maxLength={100}
        name="personDispatcherName"
      />
    </Fragment>
  )
}
