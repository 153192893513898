import { Routers } from "../../../../../../../constant/Routers"
import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

const { SCR991_TEMPLATE } = Routers

export const useVariablesAuthority = (pathname: string) => {
  const { isRoleSubAdmin, isCommonRefer } = useAuthority()

  const isNotAccessScreen = !isCommonRefer

  // 画面上のボタン表示条件
  const isVisibleBtn = isRoleSubAdmin && isCommonRefer
  const isTemplate = pathname !== SCR991_TEMPLATE
  const isVisibleBtnDelete = isVisibleBtn && isTemplate
  const isVisibleBtnReference = isVisibleBtn
  const isVisibleBtnRegister = isVisibleBtn && isTemplate

  return {
    isNotAccessScreen,
    isVisibleBtnDelete,
    isVisibleBtnReference,
    isVisibleBtnRegister,
  }
}
