import { useEffect, useState } from "react"
import { UseTableType } from "./models"

export const useTable = (total: number): UseTableType => {
  const [perItem, setPerItem] = useState("")
  const [perItemStart, setPerItemStart] = useState(0)
  const [perItemEnd, setPerItemEnd] = useState(25)

  const onChange = (page: number, pageSize: number) => {
    const _total = total || 0
    const _perItem1 = _total === 0 ? 0 : (page - 1) * pageSize + 1
    const _perItem2 = page * pageSize > _total ? _total : page * pageSize
    const _perItem = `${_perItem1}～${_perItem2}`

    setPerItemStart(_perItem1 - 1)
    setPerItemEnd(_perItem2)
    setPerItem(_perItem)
  }

  useEffect(() => {
    onChange(1, 25)
  }, [total])

  return {
    onChange,
    perItem,
    perItemEnd,
    perItemStart,
  }
}
