import { Routers } from "../../../../../../../constant/Routers"
import {
  UseTravelersRoutersType,
  UseTravelersRoutersProps,
} from "../../../../models/list"
import { useHistory } from "react-router-dom"
import { replaceString } from "../../../../../../../utils/replaceString"

const {
  SCR114_USER,
  SCR114_USER_SEARCH,
  SCR115_USER,
  SCR116,
  SCR120,
  SCR126,
  SCR212_USER,
} = Routers

export const useTravelersRouters = (
  props: UseTravelersRoutersProps,
): UseTravelersRoutersType => {
  const { dataSourceSelected, pathname, searchCondition, state, travelerId } =
    props
  const { push } = useHistory()
  // 「渡航予定情報登録」ボタンを押下した時に画面遷移処理のイベントを作成する。
  const goToSchedulesAdd = () => {
    // 「SCR212_渡航予定情報の登録画面」に遷移する。
    push({
      pathname: replaceString(SCR212_USER, travelerId),
      state: {
        searchCondition: state?.searchCondition,
        travelersURL: pathname,
      },
    })
  }
  const goToSchedulesAddDraft = () => {
    // 「SCR212_渡航予定情報の登録画面」に遷移する。
    push({
      pathname: replaceString(SCR212_USER, travelerId),
      state: {
        isTemporarilySchedule: true,
        searchCondition: state?.searchCondition,
        travelersURL: pathname,
      },
    })
  }

  // 「削除依頼」ボタンを押下した時に画面遷移処理のイベントを作成する。
  const goToTravelersDelete = () => {
    // 「SCR126_渡航者情報の削除依頼確認画面」に遷移する。
    push({
      pathname: SCR126,
      state: {
        searchCondition: state?.searchCondition,
        userInfoList: dataSourceSelected,
      },
    })
  }

  // 「参照機能」ボタンを押下した時に画面遷移処理のイベントを作成する。
  const goToTravelersDetail = () => {
    // 「SCR115_渡航者情報の参照画面」に遷移する。
    push({
      pathname: replaceString(SCR115_USER, travelerId),
      state: {
        isFromSCR114User: pathname === SCR114_USER,
        isFromSCR114UserSearch: pathname === SCR114_USER_SEARCH,
        searchCondition:
          pathname === SCR114_USER ? state?.searchCondition : searchCondition,
        sourceURL: pathname,
      },
    })
  }

  // 「検索」ボタンを押下した時に画面遷移処理のイベントを作成する。
  const goToTravelersSearch = () => {
    // 「SCR120_渡航者情報の検索画面」に遷移する。
    push({
      pathname: SCR120,
      state: {
        searchCondition: state?.searchCondition,
        sourceURL: pathname,
      },
    })
  }

  const goToTravelersEditDraft = () => {
    //「SCR116_渡航者情報の変更画面」に遷移する。
    push(replaceString(SCR116, travelerId), {
      ...state,
      isTemporarilyTraveler: true,
      sourceURL: pathname,
    })
  }

  return {
    goToSchedulesAdd,
    goToSchedulesAddDraft,
    goToTravelersDelete,
    goToTravelersDetail,
    goToTravelersEditDraft,
    goToTravelersSearch,
  }
}
