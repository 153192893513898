/* eslint-disable max-lines */
import { useEffect } from "react"
import { backToLogin } from "../../../../../../../../utils/backToLogin"
import { InitialValues } from "../../../../../constants/subAdmin/list/initialValues"
import { SubAdminFormSearchConditionType } from "../../../../../models/subAdmin/list"
import {
  SubAdminSettingFormDataPostType,
  SubAdminSettingFormSearchType,
  UseFormSubAdminSettingType,
} from "../../../../../models/subAdmin/setting"
import { useVariables } from "../useVariables"

const { SEARCH_CONDITION_INITIAL_VALUES } = InitialValues

export const useSubAdminSetting =
  (): UseFormSubAdminSettingType<SubAdminSettingFormSearchType> => {
    const {
      authorityPatterns,
      callApi,
      columns,
      departments,
      form,
      formSearch,
      getUserAuthorityTypeSetting,
      goBack,
      goToRolesSetting,
      goToSubAdmin,
      isCommonPermissionSettings,
      isDisabledBtnSave,
      isHiddenBtn,
      isLoading,
      isLoadingLayout,
      isOpen,
      onCancel,
      onChangePage,
      onChangeTable,
      onOk,
      onOpen,
      patternId,
      perItem,
      push,
      rowSelection,
      searchCondition,
      selectedRowKeys,
      setIsDisabledBtnSave,
      setIsLoading,
      setPatternId,
      setSearchCondition,
      setSelectedRowKeys,
      setValues,
      travelerList,
    } = useVariables()

    const onChangeAuthorityPatterns = (value: string) => {
      setIsDisabledBtnSave(!value || !selectedRowKeys.length)
      setPatternId(value)
    }

    const onChange = (page: number, pageSize: number) => {
      onChangePage(page, pageSize)
      setSelectedRowKeys([])
    }

    const onFinish = (value: SubAdminSettingFormSearchType) => {
      const data: SubAdminFormSearchConditionType = {
        bumonCd: value.bumonCd,
        fullName: value.fullName,
        patternId: patternId,
        travelerIdList: [],
      }
      setSearchCondition(data)
      setIsLoading(true)
      getUserAuthorityTypeSetting({ ...data, patternId: "" })
      setTimeout(() => {
        document.getElementById("title-right")?.focus()
      }, 600)
    }

    const handleResetForm = () => {
      form.resetFields()
      formSearch.resetFields()
      setSearchCondition(SEARCH_CONDITION_INITIAL_VALUES)
      getUserAuthorityTypeSetting(SEARCH_CONDITION_INITIAL_VALUES)
    }

    const onFinishSubmit = () => {
      const _values: SubAdminSettingFormDataPostType = {
        patternId: patternId,
        travelerIdList: selectedRowKeys,
      }
      setSearchCondition({
        ...searchCondition,
        patternId: patternId,
        travelerIdList: selectedRowKeys,
      })
      setValues(_values)
      onOpen()
    }

    useEffect(() => {
      // ユーザの画面アクセス許可チェックを処理する。
      if (!isCommonPermissionSettings) {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const { pathname, state } = backToLogin()
        push({ pathname, state })
      } else callApi()
    }, [])

    return {
      authorityPatterns,
      columns,
      dataSource: travelerList,
      departments,
      form,
      formSearch,
      goBack,
      goToRolesSetting,
      goToSubAdmin,
      handleResetForm,
      isDisabledBtnSave,
      isHiddenBtn,
      isLoading,
      isLoadingLayout,
      isOpen,
      onCancel,
      onChange,
      onChangeAuthorityPatterns,
      onChangeTable,
      onFinish,
      onFinishSubmit,
      onOk,
      perItem,
      rowSelection,
    }
  }
