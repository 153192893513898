import { FC } from "react"
import { Spin } from "../Spin"
import { NotificationProps } from "./models"
import { Styled } from "./styled"

const { StyledNotification, StyledNotificationItem, StyledNotificationTitle } =
  Styled

export const Notification: FC<NotificationProps> = (props) => {
  const { isLoading, notificationItems } = props

  return (
    <StyledNotification>
      <Spin isLoading={isLoading} />
      {notificationItems.map((item, index) => (
        <StyledNotificationItem key={index}>
          <StyledNotificationTitle>{item.title}</StyledNotificationTitle>
          <div>{item.content}</div>
        </StyledNotificationItem>
      ))}
    </StyledNotification>
  )
}
