import { useAuthority } from "../../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const { isCommonUploadRegister, isRoleAdmin, isRoleSubAdmin } = useAuthority()
  // 画面上のボタン表示条件
  const isHiddenBtn = (isRoleAdmin || isRoleSubAdmin) && isCommonUploadRegister

  return {
    isCommonUploadRegister,
    isHiddenBtn,
    isRoleAdmin,
    isRoleSubAdmin,
  }
}
