import { TranslateExportFile } from "./ExportFile"
import { TranslateHelp } from "./Help"
import { TranslateMessage } from "./Message"
import { TranslateText } from "./Text"

export const Translate = {
  ...TranslateExportFile,
  ...TranslateHelp,
  ...TranslateMessage,
  ...TranslateText,
}
