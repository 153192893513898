import { useHistory } from "react-router-dom"
import { useApi } from "../useApi"
import { useRouters } from "../useRouters"
import { useTableConfig } from "../useTableConfig"

export const useVariables = () => {
  const { push } = useHistory()

  const {
    authorityPatternSettings,
    authorityPatterns,
    callApi,
    departments,
    form,
    isHiddenBtnReset,
    isHiddenBtnRoleSetting,
    isHiddenBtnSearch,
    isHiddenBtnSubAdminSetting,
    isLoading,
    isLoadingLayout,
    isNotAccessScreen,
    onChange,
    onChangeTable,
    onFinish,
    perItem,
    rowSelection,
    searchCondition,
    selectedRowKeys,
  } = useApi()
  const { columns } = useTableConfig()
  const { goToRoleSetting, goToSubAdminSetting } = useRouters({
    searchCondition,
    selectedRowKeys,
  })

  return {
    authorityPatternSettings,
    authorityPatterns,
    callApi,
    columns,
    departments,
    form,
    goToRoleSetting,
    goToSubAdminSetting,
    isHiddenBtnReset,
    isHiddenBtnRoleSetting,
    isHiddenBtnSearch,
    isHiddenBtnSubAdminSetting,
    isLoading,
    isLoadingLayout,
    isNotAccessScreen,
    onChange,
    onChangeTable,
    onFinish,
    perItem,
    push,
    rowSelection,
  }
}
