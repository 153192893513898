import styled from "styled-components"
import { Title } from "../../../../../../_webui/layout/components/Typography/Title"

const StyledTitle = styled(Title)`
  margin-bottom: 50px;
  margin-top: 50px;
`

export const Styled = {
  StyledTitle,
}
