import "./style/index.scss"
import { TimePicker } from "antd"
import { FC } from "react"
import { ItemTimePickerProps } from "./models"
import { FormItem } from ".."
import { format } from "./constants"

export const ItemTimePicker: FC<ItemTimePickerProps> = (props) => {
  const {
    className,
    isBgBlue,
    isHiddenLabel,
    label,
    name,
    onBlur,
    onChange,
    onFocus,
    onKeyDown,
    placeholder,
    required,
    rules,
    tooltip,
  } = props

  return (
    <FormItem
      className={className}
      isBgBlue={isBgBlue}
      isHiddenLabel={isHiddenLabel}
      label={label}
      name={name}
      required={required}
      rules={rules}
      tooltip={tooltip}
    >
      <TimePicker
        format={format}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        showNow={false}
        suffixIcon=""
      />
    </FormItem>
  )
}
