import { FC } from "react"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { ModalConfirm } from "../../../../../_webui/layout/components/Modal/Confirm"
import { Translate } from "../../../../../constant/Translate"
import { ScheduleAddSuccessCollapse } from "../../components/addSuccess/ContentCollapse"
import { useSchedulesDetail } from "./modules/useDetail"
import { DetailButtonGroup } from "../../components/detail/ButtonGroup"

const { TEXT_T230 } = Translate

export const SchedulesDetail: FC = () => {
  const {
    assignmentGridItem,
    breadcrumbItems,
    confirmGridItem,
    detailsColumnsFirst,
    detailsGridItem,
    detailsTabsItems,
    goToTravelerDetail,
    insuranceGridItem,
    isLoadingLayout,
    isOpen,
    isVisibleBtnDelete,
    isVisibleBtnEdit,
    isVisibleBtnRefer,
    isVisibleBtnSearch,
    isVisibleOtherRemarks,
    lodgingsColumns,
    lodgingsTabsItems,
    onCancel,
    onEdit,
    onOk,
    onOpen,
    onSearch,
    otherGridItem,
    personalGridItem,
    scheduleDetails,
    scheduleLodgings,
    scheduleStays,
    staysColumns,
    staysTabsItems,
  } = useSchedulesDetail()

  return (
    <Layout
      breadcrumbItems={breadcrumbItems}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T230}
    >
      <Card heading={TEXT_T230}>
        <ScheduleAddSuccessCollapse
          assignmentGridItem={assignmentGridItem}
          confirmGridItem={confirmGridItem}
          detailsColumnsFirst={detailsColumnsFirst}
          detailsGridItem={detailsGridItem}
          detailsTabsItems={detailsTabsItems}
          insuranceGridItem={insuranceGridItem}
          isVisibleOtherRemarks={isVisibleOtherRemarks}
          lodgingsColumns={lodgingsColumns}
          lodgingsTabsItems={lodgingsTabsItems}
          otherGridItem={otherGridItem}
          personalGridItem={personalGridItem}
          scheduleDetails={scheduleDetails}
          scheduleLodgings={scheduleLodgings}
          scheduleStays={scheduleStays}
          staysColumns={staysColumns}
          staysTabsItems={staysTabsItems}
        />
        <DetailButtonGroup
          goToTravelerDetail={goToTravelerDetail}
          isVisibleBtnDelete={isVisibleBtnDelete}
          isVisibleBtnEdit={isVisibleBtnEdit}
          isVisibleBtnRefer={isVisibleBtnRefer}
          isVisibleBtnSearch={isVisibleBtnSearch}
          onEdit={onEdit}
          onOpen={onOpen}
          onSearch={onSearch}
        />
      </Card>
      <ModalConfirm isOpen={isOpen} onCancel={onCancel} onOk={onOk} />
    </Layout>
  )
}
