import { FC, Fragment } from "react"
import { ItemDatePicker } from "../../../../../../../_webui/layout/components/Form/Item/DatePicker"
import { ItemRadio } from "../../../../../../../_webui/layout/components/Form/Item/Radio"
import { RadioItems } from "../../../../../../../constant/RadioItems"
import { Translate } from "../../../../../../../constant/Translate"
import { DatePickerInputProps } from "../../../../../../../_webui/layout/components/Form/Item/DatePicker/models"

const { TRAINING_TYPE_SEARCH } = RadioItems
const { TEXT_T065, TEXT_T066 } = Translate

export const TrainingInfo: FC<Omit<DatePickerInputProps, "onChangeDate">> = (
  props,
) => {
  const { onBlur, onKeyDown } = props
  return (
    <Fragment>
      <ItemRadio
        label={TEXT_T065}
        name="trainingType"
        radioItems={TRAINING_TYPE_SEARCH}
      />
      <ItemDatePicker
        label={TEXT_T066}
        name="trainingDate"
        onBlur={(event) => onBlur(event, TEXT_T066, "trainingDate")}
        onKeyDown={(event) => onKeyDown(event, TEXT_T066, "trainingDate")}
      />
    </Fragment>
  )
}
