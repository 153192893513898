/* eslint-disable max-statements */
import { Form } from "antd"
import { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { concat } from "../../../../../../../utils/concat"
import {
  SafetiesInfoDataDetailType,
  SafetiesInfoDataType,
  SafetiesInfoType,
} from "../../../../models/info"

export const useVariablesApi = () => {
  const { push } = useHistory()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const safetyId = concat(query.get("safetyId"))
  const safetyDetailId = concat(query.get("safetyDetailId"))
  const [form] = Form.useForm<SafetiesInfoDataDetailType>()
  const [safetyInfo, setSafetyInfo] = useState<SafetiesInfoDataType>()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [isShowFormSuccess, setIsShowFormSuccess] = useState(false)
  const [safetiesInfo, setSafetiesInfo] = useState<SafetiesInfoType>()
  const [updateDate, setUpdateDate] = useState("")

  return {
    form,
    isLoading,
    isLoadingLayout,
    isShowFormSuccess,
    push,
    safetiesInfo,
    safetyDetailId,
    safetyId,
    safetyInfo,
    setIsLoading,
    setIsLoadingLayout,
    setIsShowFormSuccess,
    setSafetiesInfo,
    setSafetyInfo,
    setUpdateDate,
    updateDate,
  }
}
