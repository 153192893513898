import "./style/index.scss"
import { FC, Fragment } from "react"
import { ButtonProps } from "./models"
import { getStyle } from "../../../../utils/getStyle"
import { Styled } from "./styled"

const {
  StyledButton,
  StyledButtonAddonAfter,
  StyledButtonAddonBefore,
  StyledTooltip,
} = Styled

export const Button: FC<ButtonProps> = (props) => {
  const {
    absolutePosition = "left",
    addonAfter,
    addonBefore,
    className,
    htmlType,
    id,
    isAbsolute,
    isDisabled,
    isFullWidth,
    isHidden,
    isSmall,
    onClick,
    text,
    title,
    type,
    ...rest
  } = props
  if (isHidden) return null

  const styleClassName = getStyle(className, ` ${className}`)
  const styleDisabled = getStyle(isDisabled, " ant-btn-disabled")
  const styleType = getStyle(type, ` ant-btn-${type}`, " ant-btn-default")
  const styleButton = `ant-btn${styleClassName}${styleType}${styleDisabled}`

  return (
    <Fragment>
      <StyledTooltip title={title} />
      <StyledButton
        absolutePosition={absolutePosition}
        className={styleButton}
        disabled={isDisabled}
        id={id}
        isAbsolute={isAbsolute}
        isFullWidth={isFullWidth}
        isSmall={isSmall}
        onClick={onClick}
        type={htmlType}
        {...rest}
      >
        {addonBefore && (
          <StyledButtonAddonBefore className="ant-btn-addon-before">
            {addonBefore}
          </StyledButtonAddonBefore>
        )}
        {text}
        {addonAfter && (
          <StyledButtonAddonAfter className="ant-btn-addon-after">
            {addonAfter}
          </StyledButtonAddonAfter>
        )}
      </StyledButton>
    </Fragment>
  )
}
