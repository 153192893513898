/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable max-depth */
import { AxiosErrorType } from "../../infrastructure/axiosError/models"
import { DownloadFilesType } from "./models"
import { openNotification } from "../openNotification"
import { saveAs } from "file-saver"
import { Translate } from "../../constant/Translate"
import JSZip from "jszip"
import { replaceStringParam } from "../replaceStringParam"

const { MESSAGE_E0134, MESSAGE_E0146 } = Translate

const handleError = (error: AxiosErrorType, fileName: string) => {
  const status = error?.debug?.response?.data?.status
  if (status === 403 || status === 404) {
    openNotification(replaceStringParam(MESSAGE_E0134, fileName))
  }
}

export const downloadFiles = async (data: DownloadFilesType) => {
  const { fileName, files, selectedRowKeys } = data
  if (files?.length) {
    const _files = files.filter((item) => selectedRowKeys.includes(item.id))

    if (_files.length > 1) {
      const zip = new JSZip()
      let isError = false
      for (let i = 0; i < _files.length; i++) {
        if (_files[i].filePath) {
          const blob = await fetch(_files[i].filePath, {
            method: "GET",
          }).then((res) => {
            if (res.status === 403 || res.status === 404) {
              openNotification(
                replaceStringParam(MESSAGE_E0134, _files[i].fileName),
              )
              isError = true
            } else {
              return res.blob()
            }
          })
          if (blob)
            zip.folder(_files[i].fileTypeName)?.file(_files[i].fileName, blob)
        } else {
          openNotification(
            replaceStringParam(MESSAGE_E0134, _files[i].fileName),
          )
          isError = true
        }
      }
      if (!isError)
        zip
          .generateAsync({ type: "blob" })
          .then((blob) => {
            saveAs(blob, fileName)
          })
          .catch((error: AxiosErrorType) => {
            handleError(error, fileName)
          })
      else openNotification(MESSAGE_E0146)
    } else if (_files.length === 1) {
      if (_files[0].filePath) {
        await fetch(_files[0].filePath, {
          method: "GET",
        })
          .then((res) => {
            if (res.status === 403 || res.status === 404) {
              openNotification(
                replaceStringParam(MESSAGE_E0134, _files[0].fileName),
              )
            } else {
              return res.blob()
            }
          })
          .then((blob) => {
            if (blob) saveAs(blob, _files[0].fileName)
            else openNotification(MESSAGE_E0146)
          })
          .catch((error: AxiosErrorType) => {
            handleError(error, _files[0].fileName)
          })
      } else {
        openNotification(replaceStringParam(MESSAGE_E0134, _files[0].fileName))
      }
    }
  }
}
