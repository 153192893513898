import styled, { css } from "styled-components"
import { Frame } from "../../../../../../../_webui/layout/components/Frame"
import { Regex } from "../../../../../../../constant/Regex"

const { REGEX_IS_DESKTOP } = Regex

const StyledFrame = styled(Frame)`
  ${REGEX_IS_DESKTOP &&
  css`
    padding: 0 45px 0 30px;
    .ant {
      &-btn {
        margin-left: 15px;
      }
      &-form-horizontal {
        display: flex;
        align-items: baseline;
        .ant-form-item {
          &-label {
            width: 180px;
          }
          &-control {
            max-width: initial;
          }
        }
      }
    }
  `}
`

export const Styled = {
  StyledFrame,
}
