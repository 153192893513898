import { FC } from "react"
import { SystemsManualButtonGroupProps } from "../../../models/manual"
import { Translate } from "../../../../../../constant/Translate"
import { ButtonGroup } from "../../../../../../_webui/layout/components/Button/Group"

const { TEXT_T012, TEXT_T033, TEXT_T510 } = Translate

export const SystemsManualButtonGroup: FC<SystemsManualButtonGroupProps> = (
  props,
) => {
  const {
    goToHome,
    handleDownload,
    handlePreviewFile,
    isDisabledBtnDownload,
    isDisabledBtnPreview,
    isVisibleBtn,
  } = props

  return (
    <ButtonGroup
      buttonItems={[
        {
          isHidden: !isVisibleBtn,
          onClick: goToHome,
          order: 3,
          text: TEXT_T012,
          type: "ghost",
        },
        {
          isDisabled: isDisabledBtnDownload,
          isHidden: !isVisibleBtn,
          onClick: handleDownload,
          order: 1,
          text: TEXT_T510,
          type: "primary",
        },
        {
          isDisabled: isDisabledBtnPreview,
          isHidden: !isVisibleBtn,
          onClick: handlePreviewFile,
          order: 2,
          text: TEXT_T033,
        },
      ]}
      position="space-between"
    />
  )
}
