import styled from "styled-components"
import { Title } from "../../../../../../../_webui/layout/components/Typography/Title"
import { getValueByLanguage } from "../../../../../../../utils/getValueByLanguage"
import { Regex } from "../../../../../../../constant/Regex"

const { REGEX_IS_DESKTOP } = Regex

const StyledTitle = styled(Title)`
  .title-left {
    max-width: ${REGEX_IS_DESKTOP &&
    getValueByLanguage("calc(100% - 220px)", "calc(100% - 270px)")};
  }
`

export const Styled = {
  StyledTitle,
}
