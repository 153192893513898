import { Breadcrumbs } from "../../../../../../../../constant/Breadcrumbs"
import { Routers } from "../../../../../../../../constant/Routers"

const { SCR210_ATTENTIONS, SCR210_SAFETIES, SCR210_TRAVELERS } = Routers
const {
  BRCR_SCR210_ATTENTIONS,
  BRCR_SCR210_FROM_SCR114,
  BRCR_SCR210_SAFETIES,
  BRCR_SCR210_TRAVELERS,
  BRCR_SCR210,
} = Breadcrumbs

export const getBreadcrumbItems = (
  pathname: string,
  isFromSCR114UserSearch?: boolean,
) =>
  isFromSCR114UserSearch
    ? BRCR_SCR210_FROM_SCR114
    : pathname === SCR210_TRAVELERS
    ? BRCR_SCR210_TRAVELERS
    : pathname === SCR210_ATTENTIONS
    ? BRCR_SCR210_ATTENTIONS
    : pathname === SCR210_SAFETIES
    ? BRCR_SCR210_SAFETIES
    : BRCR_SCR210
