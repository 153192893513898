import { getFullName } from "../../../../../../../../../../utils/getFullName"
import { SchedulesListDataType } from "../../../../../../../models/list"
import { heading } from "./heading"
import { getValueByLanguage } from "../../../../../../../../../../utils/getValueByLanguage"
import { Timezone } from "../../../../../../../../../../constant/Timezone"
import { getDayWithFormat } from "../../../../../../../../../../utils/getDate"
import { join } from "lodash"
import { getDispatchTypeDetailName } from "../../../../../../../../../../utils/getDispatchTypeDetailName"
import { Variables } from "../../../../../../../../travelers/constants/edit/variables"

const { DATE_FORMAT_EN } = Timezone
const { KEYS_ACCOMPANYING_FAMILY } = Variables

const getDisplayDateTime = (csvDateTime: string) => {
  return csvDateTime
    ? getDayWithFormat(DATE_FORMAT_EN, csvDateTime)
    : csvDateTime
}

const getPhoneNumber = (data: SchedulesListDataType) => {
  return join(
    [
      data.info.locationPhoneNumber1,
      data.info.locationPhoneNumber2,
      data.info.satellitePhoneNumber,
      getPersonPhoneNumber(data),
    ],
    "｜",
  )
}

const getPersonPhoneNumber = (data: SchedulesListDataType) => {
  let phoneNumber = data.info.personPhoneNumber
  if (phoneNumber.length > 6) {
    phoneNumber = `${phoneNumber.substring(0, 2)}-${phoneNumber.substring(
      2,
      6,
    )}-${phoneNumber.substring(6)}`
  }
  return `(+${data.info.personCountryPhoneCode})${phoneNumber}`
}
export const convertDataExport = (data: SchedulesListDataType[]) => {
  let convertData = heading
  data.map(
    (item) =>
      (convertData += `\r\n"${getFullName(
        item.info.kanjiSurname,
        item.info.kanjiName,
        true,
      )}","${getFullName(
        item.info.romajiSurname,
        item.info.romajiName,
      )}","${getFullName(
        item.info.kanjiSurnamePassport,
        item.info.kanjiNamePassport,
        true,
      )}","${getFullName(
        item.info.romajiSurnamePassport,
        item.info.romajiNamePassport,
      )}","${item.info.dispatchTypeName}","${getDispatchTypeDetailName(
        item.info.bumonName,
        item.info.dispatchTypeDetailId,
        item.info.dispatchTypeDetailName,
        true,
      )}","${
        KEYS_ACCOMPANYING_FAMILY.includes(item.info.dispatchTypeDetailId)
          ? item.info.personDispatcherName
          : item.info.organizationName.split("・")[0]
      }","${getPhoneNumber(item)}","${item.info.emailAddress1}｜${
        item.info.emailAddress2
      }","${item.info.workPlaceCountry} ${item.info.workPlaceCity}","${
        item.info.travelerLocation
      }","${item.info.travelPurposeName}","${getValueByLanguage(
        item.info.projectNameJp,
        item.info.projectNameEn,
      )}","${getDisplayDateTime(
        item.info.dispatchStartDate,
      )}～${getDisplayDateTime(item.info.dispatchEndDate)}","${
        item.info.locationPersonName
      }","${item.csv.othersRemarks1}"`),
  )
  return convertData
}
