import { FC } from "react"
import { Regex } from "../../../../../../../constant/Regex"
import { Table } from "../../../../../../../_webui/layout/components/Table"
import { Tabs } from "../../../../../../../_webui/layout/components/Tabs"
import { SchedulesAddSuccessLodgingProps } from "./models"

const { REGEX_IS_DESKTOP } = Regex

export const SchedulesAddSuccessLodging: FC<SchedulesAddSuccessLodgingProps> = (
  props,
) => {
  const { scheduleLodgings, lodgingsColumns, lodgingsTabsItems } = props

  return REGEX_IS_DESKTOP ? (
    <Table
      columns={lodgingsColumns}
      dataSource={scheduleLodgings}
      pagination={false}
      rowKey="travelLodgingId"
    />
  ) : (
    <Tabs defaultActiveKey={"1"} items={lodgingsTabsItems} />
  )
}
