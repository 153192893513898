/* eslint-disable max-lines */
import { useEffect } from "react"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { useVariables } from "../useVariables"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { Routers } from "../../../../../../../constant/Routers"
import { Translate } from "../../../../../../../constant/Translate"
import { openNotification } from "../../../../../../../utils/openNotification"
import {
  UploadResultsDownloadType,
  UploadResultsType,
  UseUploadResultsType,
} from "../../../../model/uploadResults"
import { batchApi } from "../../../../apis"

const { SCR973 } = Routers
const { MESSAGE_I0009, MESSAGE_E0040 } = Translate

export const useUploadResults = (): UseUploadResultsType<UploadResultsType> => {
  const {
    batchLogInfoList,
    columns,
    isCommonExternalSystemLinkage,
    isHiddenBtn,
    isLoading,
    isLoadingLayout,
    onChange,
    onChangeTable,
    perItem,
    push,
    rowSelection,
    idBatchLogSelected,
    setBatchLogInfoList,
    setIsLoading,
    setIsLoadingLayout,
    setSelectedRowKeys,
  } = useVariables()

  const handlePushStateError = (error: AxiosErrorType) => {
    const stateError = pushStateError(error)
    push({ pathname: SCR973, state: stateError })
  }

  const downloadFile = async (file: UploadResultsDownloadType) => {
    if (file?.fileURL) {
      const { fileName, fileURL } = file
      const link = document.createElement("a")
      link.href = fileURL
      link.download = fileName
      link.target = "_blank"
      link.rel = "noopener noreferrer"

      document.body.appendChild(link)
      link.click()
      URL.revokeObjectURL(fileURL)
      // 成功した旨の通知メッセージを表示する
      openNotification(MESSAGE_I0009)
    } else {
      // 失敗した旨の通知メッセージを表示する。
      openNotification(MESSAGE_E0040)
    }
  }

  const handleExportCSV = () => {
    setIsLoading(true)
    const idBatchLogList = idBatchLogSelected?.length
      ? idBatchLogSelected
      : batchLogInfoList?.map((item) => item.id)
    // APIを呼び出し、指定されているファイルアップロード結果一覧をCSVファイルの形式で出力し、返却結果を「M.fileInfo」変数に設定する。
    batchApi
      .downloadLog(idBatchLogList)
      .then((fileInfo: UploadResultsDownloadType) => {
        //ダウンロードに成功した場合
        downloadFile(fileInfo)
        setIsLoading(false)
      })
      .catch((error) => {
        //「SCR973_エラー情報の表示画面」に遷移する。
        handlePushStateError(error)
      })
  }

  const handleRefreshBatchLog = () => {
    setIsLoading(true)
    getBatchLogInfoList()
    setSelectedRowKeys([])
  }

  const getBatchLogInfoList = () => {
    // APIを呼び出し、APIからの応答結果を「戻りファイル情報」変数に設定する。
    batchApi
      .getBatchLogs()
      .then((batchLogInfoListJson: UploadResultsType[]) => {
        // ログバッチ情報の一覧」の値を設定する。
        setBatchLogInfoList(batchLogInfoListJson)
        setIsLoading(false)
        setIsLoadingLayout(false)
      })
      .catch((error) => {
        //「SCR973_エラー情報の表示画面」に遷移する。
        handlePushStateError(error)
      })
  }

  useEffect(() => {
    // ユーザの画面アクセス許可チェックを処理する。
    if (!isCommonExternalSystemLinkage) {
      // 「SCR973_エラー情報の表示画面」に遷移する。
      const { pathname, state } = backToLogin()
      push({ pathname, state })
    } else {
      getBatchLogInfoList()
    }
  }, [])

  return {
    batchLogInfoList,
    columns,
    handleExportCSV,
    handleRefreshBatchLog,
    isHiddenBtn,
    isLoading,
    isLoadingLayout,
    onChange,
    onChangeTable,
    perItem,
    rowSelection,
  }
}
