import { Translate } from "../../../../../../../../constant/Translate"
import { ItemInputProps } from "../../../../../../../../_webui/layout/components/Form/Item/Input/models"

const { TEXT_T004, TEXT_T005, TEXT_T007, TEXT_T008 } = Translate
const KANJI_FULL_NAME: ItemInputProps[] = [
  {
    label: TEXT_T004,
    maxLength: 50,
    name: "kanjiSurname",
  },
  {
    label: TEXT_T005,
    maxLength: 50,
    name: "kanjiName",
  },
]

const KANJI_PASSPORT_FULL_NAME: ItemInputProps[] = [
  {
    label: TEXT_T004,
    maxLength: 50,
    name: "kanjiPassportSurname",
  },
  {
    label: TEXT_T005,
    maxLength: 50,
    name: "kanjiPassportName",
  },
]

const ROMAJI_FULL_NAME: ItemInputProps[] = [
  {
    label: TEXT_T007,
    maxLength: 50,
    name: "romajiSurname",
  },
  {
    label: TEXT_T008,
    maxLength: 50,
    name: "romajiName",
  },
]

const ROMAJI_PASSPORT_FULL_NAME: ItemInputProps[] = [
  {
    label: TEXT_T007,
    maxLength: 50,
    name: "romajiPassportSurname",
  },
  {
    label: TEXT_T008,
    maxLength: 50,
    name: "romajiPassportName",
  },
]

export const InputItems = {
  KANJI_FULL_NAME,
  KANJI_PASSPORT_FULL_NAME,
  ROMAJI_FULL_NAME,
  ROMAJI_PASSPORT_FULL_NAME,
}
