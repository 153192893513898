import { GeneralCode } from "../../../../../../../../constant/GeneralCode"
import { getFullName } from "../../../../../../../../utils/getFullName"
import { getValueCombobox } from "../../../../../../../../utils/getValueCombobox"
import { SafetiesType } from "../../../../../../attentions/models/attention"
import {
  SafetiesDataType,
  SafetiesDestinationsType,
  SafetiesSendReqType,
  SafetiesTransmissionInfoType,
} from "../../../../../models/safety"

const { CTGR_OTHER } = GeneralCode

export const getAddSafetyParam = (
  transmissionInfo: SafetiesTransmissionInfoType,
  travelerInfoList: SafetiesDataType[],
  safeties: SafetiesType[],
): SafetiesSendReqType => {
  const { bodyContent, categoryId, categoryName, categoryValue, safetyTitle } =
    transmissionInfo
  const destinations: SafetiesDestinationsType[] = travelerInfoList.map(
    (item) => ({
      emailAddress1: item.emailAddress1,
      emailAddress2: item.emailAddress2,
      fullNameKanji: getFullName(item.kanjiSurname, item.kanjiName, true),
      fullNameRomaji: getFullName(item.romajiSurname, item.romajiName),
      travelScheduleId: item.travelScheduleId,
    }),
  )

  const requestParams = {
    destinations: destinations,
    info: {
      bodyContent: bodyContent,
      categoryId: getValueCombobox(categoryId, categoryName),
      categoryValue: categoryId === CTGR_OTHER ? categoryValue : "",
      safetyTitle: safetyTitle,
    },
    safeties,
  }
  return requestParams
}
