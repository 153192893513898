/* eslint-disable complexity */
import { useAuthority } from "../../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const {
    isCommonUploadDelete,
    isCommonUploadRefer,
    isCommonUploadRegister,
    isRoleAdmin,
    isRoleSubAdmin,
    isRoleUser,
  } = useAuthority()

  // 「権限区分」欄と「小機能ID」欄の条件を満たした場合にボタンが表示される。
  const isVisibleBtn = isRoleAdmin || isRoleSubAdmin || isRoleUser
  const isVisibleBtnDelete = isVisibleBtn && isCommonUploadDelete
  const isVisibleBtnRegister = isVisibleBtn && isCommonUploadRegister

  const isVisibleBtnReference = isVisibleBtn && isCommonUploadRefer

  return {
    isCommonUploadRegister,
    isRoleAdmin,
    isRoleSubAdmin,
    isVisibleBtnDelete,
    isVisibleBtnReference,
    isVisibleBtnRegister,
  }
}
