import { concat } from "../../../../../../../../../utils/concat"
import { getDate } from "../../../../../../../../../utils/getDate"
import { ScheduleInfoLodgingsType } from "../../../../../../models/add"
import { SchedulesAddLodgingsAddType } from "../../../../../../models/add/DataAdd"

export const getLodgingDeleteData = (
  detailDeleteData: ScheduleInfoLodgingsType[],
  id: string,
): SchedulesAddLodgingsAddType[] =>
  detailDeleteData.map((item) => ({
    lodgingAddress: item.lodgingAddress,
    lodgingCheckin: getDate(item.lodgingCheckin),
    lodgingCheckout: getDate(item.lodgingCheckout),
    lodgingName: item.lodgingName,
    lodgingPhoneNumber: item.lodgingPhoneNumber,
    travelLodgingId: item.travelLodgingId,
    travelScheduleId: concat(id),
    type: "Delete",
  }))
