/* eslint-disable complexity */
import { Breadcrumbs } from "../../../../../../../../constant/Breadcrumbs"
import { Routers } from "../../../../../../../../constant/Routers"

const {
  BRCR_SCR318_FROM_SCR316_HISTORY,
  BRCR_SCR318_FROM_SCR316_USER,
  BRCR_SCR318_FROM_SCR316,
  BRCR_SCR318_FROM_SCR317_HISTORY,
  BRCR_SCR318,
} = Breadcrumbs
const { SCR317 } = Routers

export const getBreadcrumbItems = (
  isFromSCR316: boolean,
  isFromSCR316History: boolean,
  sourceURL?: string,
) => {
  if (sourceURL === SCR317) {
    if (isFromSCR316History) return BRCR_SCR318_FROM_SCR317_HISTORY
    else if (isFromSCR316) return BRCR_SCR318_FROM_SCR316
    return BRCR_SCR318
  }

  if (isFromSCR316) return BRCR_SCR318_FROM_SCR316_USER
  if (isFromSCR316History) return BRCR_SCR318_FROM_SCR316_HISTORY

  return BRCR_SCR318
}
