import { FC } from "react"
import { Images } from "../../../../../../../constant/Images"
import { ButtonGroup } from "../../../../../../../_webui/layout/components/Button/Group"
import { Image } from "../../../../../../../_webui/layout/components/Image"
import { SchedulesAddTableButtonGroupProps } from "./models"

const { IMAGE_BTN_ADDITION, IMAGE_BTN_DELETE } = Images

export const SchedulesAddTableButtonGroup: FC<
  SchedulesAddTableButtonGroupProps
> = (props) => {
  const {
    addText,
    deleteText,
    handleAdd,
    handleDelete,
    isDisabled,
    isVisibleBtn,
  } = props

  return (
    <ButtonGroup
      buttonItems={[
        {
          addonBefore: <Image src={IMAGE_BTN_ADDITION} />,
          htmlType: "button",
          isHidden: !isVisibleBtn,
          onClick: handleAdd,
          text: addText,
        },
        {
          addonBefore: <Image src={IMAGE_BTN_DELETE} />,
          htmlType: "button",
          isDisabled: isDisabled,
          isHidden: !isVisibleBtn,
          onClick: () => handleDelete(),
          text: deleteText,
        },
      ]}
      position="flex-start"
    />
  )
}
