import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Routers } from "../../../constant/Routers"
import { generalMastersApi } from "../../../infrastructure/handlers/generalMasters"
import { GeneralMastersResType } from "../../../infrastructure/handlers/generalMasters/models"
import { AxiosErrorType } from "../../../infrastructure/axiosError/models"
import { OptionItemType } from "../../../_webui/layout/components/Select/models"
import { GeneralMastersRemarkType, GeneralMastersType } from "./models"
import { RadioItemType } from "../../../_webui/layout/components/Radio/models"
import { getValueByLanguage } from "../../getValueByLanguage"
import { pushStateError } from "../../pushStateError"

const { SCR973 } = Routers

export const getGeneralMasters = (
  generalCode: string,
  isDispatchType?: boolean,
): GeneralMastersType => {
  const { push } = useHistory()
  const [generalMasters, setGeneralMasters] = useState<OptionItemType[]>([])
  const [generalMastersAll, setGeneralMastersAll] =
    useState<GeneralMastersResType[]>()
  const [generalMastersRemark, setGeneralMastersRemark] =
    useState<GeneralMastersRemarkType[]>()
  const [timezoneItems, setTimezoneItems] = useState<OptionItemType[]>()

  useEffect(() => {
    generalMastersApi
      .getGeneralMaster(generalCode)
      .then((res: GeneralMastersResType[]) => {
        const optionItem: OptionItemType[] | RadioItemType[] = res.map((item) =>
          isDispatchType
            ? {
                text: String(getValueByLanguage(item.value3, item.value4)),
                value: item.key2,
              }
            : {
                text: String(getValueByLanguage(item.value1, item.value2)),
                value: item.key1,
              },
        )
        const remarks: GeneralMastersRemarkType[] = res.map((item) => ({
          key1: item.key1,
          remarksName1: String(getValueByLanguage(item.value1, item.value6)),
          remarksName2: String(getValueByLanguage(item.value2, item.value7)),
          remarksName3: String(getValueByLanguage(item.value3, item.value8)),
          remarksName4: String(getValueByLanguage(item.value4, item.value9)),
          remarksName5: String(getValueByLanguage(item.value5, item.value10)),
        }))

        const timezone: OptionItemType[] = res.map((item) => ({
          text: `${getValueByLanguage(item.value1, item.value2)} (GMT${
            item.value3
          })`,
          value: item.key1,
        }))

        setGeneralMasters(optionItem)
        setGeneralMastersAll(res)
        setGeneralMastersRemark(remarks)
        setTimezoneItems(timezone)
      })
      .catch((error: AxiosErrorType) => {
        const state = pushStateError(error)
        push({ pathname: SCR973, state })
      })
  }, [])

  return {
    generalMasters,
    generalMastersAll,
    generalMastersRemark,
    timezoneItems,
  }
}
