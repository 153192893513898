import { FC } from "react"
import { Translate } from "../../../../../../../constant/Translate"
import { ButtonGroup } from "../../../../../../../_webui/layout/components/Button/Group"
import { RolesSettingButtonGroupProps } from "../../../../models/roles/setting"

const { TEXT_T072, TEXT_T454, TEXT_T450 } = Translate

export const RolesSettingButtonGroup: FC<RolesSettingButtonGroupProps> = (
  props,
) => {
  const { goBack, goToSubAdminSetting, patternId, isHiddenBtn } = props

  return (
    <ButtonGroup
      buttonItems={[
        {
          isHidden: !isHiddenBtn,
          onClick: goBack,
          order: 3,
          text: TEXT_T072,
          type: "ghost",
        },
        {
          htmlType: "submit",
          isHidden: !patternId || !isHiddenBtn,
          order: 1,
          text: TEXT_T454,
          type: "primary",
        },
        {
          isHidden: !isHiddenBtn,
          onClick: goToSubAdminSetting,
          order: 2,
          text: TEXT_T450,
          type: "default",
        },
      ]}
      position="space-between"
    />
  )
}
