import styled, { css } from "styled-components"
import { Regex } from "../../../../../../../constant/Regex"
import { ItemInput } from "../../../../../../../_webui/layout/components/Form/Item/Input"

const { REGEX_IS_DESKTOP } = Regex

const AttentionEditGridCategory = styled.div`
  display: ${REGEX_IS_DESKTOP && "flex"};
`

const AttentionEditGridCategoryInput = styled(ItemInput)`
  ${REGEX_IS_DESKTOP &&
  css`
    margin-left: 19px !important;
    max-width: 440px;
  `};
  .ant-form-item-label {
    width: 0px;
  }
`

export const Styled = {
  AttentionEditGridCategory,
  AttentionEditGridCategoryInput,
}
