/* eslint-disable max-lines */
import { Translate } from "../../../../../../../constant/Translate"
import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../_webui/layout/components/Table/models"
import { SchedulesBatchDeleteDataType } from "../../../../models/batchDelete/data"
import { getValueByLanguage } from "../../../../../../../utils/getValueByLanguage"

const {
  TEXT_T002,
  TEXT_T192,
  TEXT_T199,
  TEXT_T200,
  TEXT_T201,
  TEXT_T215,
  TEXT_T224,
  TEXT_T234,
  TEXT_T235,
  TEXT_T236,
  TEXT_T237,
  TEXT_T238,
} = Translate

export const useTableConfig =
  (): UseTableConfigType<SchedulesBatchDeleteDataType> => {
    const columns: ColumnType<SchedulesBatchDeleteDataType>[] = [
      {
        dataIndex: "userId",
        sorter: (a, b) => a.userId.localeCompare(b.userId),
        title: TEXT_T002,
        width: 250,
      },
      {
        dataIndex: "fullName",
        sorter: (a, b) => a.fullName.localeCompare(b.fullName),
        title: TEXT_T192,
        width: 130,
      },
      {
        dataIndex: "travelScheduleId",
        sorter: (a, b) => a.travelScheduleId.localeCompare(b.travelScheduleId),
        title: TEXT_T224,
        width: 170,
      },
      {
        dataIndex: "travelPurposeName",
        sorter: (a, b) =>
          a.travelPurposeName.localeCompare(b.travelPurposeName),
        title: TEXT_T215,
        width: getValueByLanguage(150, 170),
      },
      {
        dataIndex: "projectNameJp",
        sorter: (a, b) => a.projectNameJp.localeCompare(b.projectNameJp),
        title: TEXT_T199,
        width: 370,
      },
      {
        dataIndex: "projectNameEn",
        sorter: (a, b) => a.projectNameEn.localeCompare(b.projectNameEn),
        title: TEXT_T200,
        width: 370,
      },
      {
        dataIndex: "procurementControlNumber",
        sorter: (a, b) =>
          a.procurementControlNumber.localeCompare(b.procurementControlNumber),
        title: TEXT_T201,
        width: 200,
      },
      {
        dataIndex: "bumonName",
        sorter: (a, b) => a.bumonName.localeCompare(b.bumonName),
        title: TEXT_T234,
        width: 200,
      },
      {
        dataIndex: "mainPersonName",
        sorter: (a, b) => a.mainPersonName.localeCompare(b.mainPersonName),
        title: TEXT_T235,
        width: 210,
      },
      {
        dataIndex: "locationPersonName",
        sorter: (a, b) =>
          a.locationPersonName.localeCompare(b.locationPersonName),
        title: TEXT_T236,
        width: 210,
      },
      {
        dataIndex: "dispatchStartDate",
        sorter: (a, b) =>
          a.dispatchStartDate.localeCompare(b.dispatchStartDate),
        title: TEXT_T237,
        width: 170,
      },
      {
        dataIndex: "dispatchEndDate",
        sorter: (a, b) => a.dispatchEndDate.localeCompare(b.dispatchEndDate),
        title: TEXT_T238,
        width: 170,
      },
    ]
    return {
      columns,
    }
  }
