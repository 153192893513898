import { useEffect } from "react"
import { Routers } from "../../../../../../../../constant/Routers"
import { backToLogin } from "../../../../../../../../utils/backToLogin"
import { UseFormSubAdminConfirmType } from "../../../../../models/subAdmin/confirm"
import { useVariables } from "../useVariables"

const { SCR950, SCR953 } = Routers

export const useSubAdminConfirm = (): UseFormSubAdminConfirmType => {
  const {
    callApi,
    columns,
    gridItems,
    isCommonPermissionSettings,
    isHiddenBtn,
    isLoadingLayout,
    onChange,
    perItem,
    push,
    state,
    travelerList,
  } = useVariables()

  // 「SCR950_機能限定管理者設定画面」に戻る。
  const goBack = () => {
    push({ pathname: SCR950, state })
  }

  // 「SCR953_権限メンテナンス画面」画面に遷移する。
  const goToRolesSetting = () => {
    push({
      pathname: SCR953,
      state: {
        ...state,
        isFromSCR951: true,
        sourceURL: SCR953,
      },
    })
  }

  useEffect(() => {
    // ユーザの画面アクセス許可チェックを処理する。
    if (!isCommonPermissionSettings) {
      // 「SCR973_エラー情報の表示画面」に遷移する。
      const { pathname, state } = backToLogin()
      push({ pathname, state })
    } else callApi()
  }, [])

  return {
    columns,
    dataSource: travelerList,
    goBack,
    goToRolesSetting,
    gridItems,
    isHiddenBtn,
    isLoadingLayout,
    onChange,
    perItem,
  }
}
