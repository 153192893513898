import { FC } from "react"
import { useAttention } from "./modules/useAttention"
import { Grid } from "../../../../../_webui/layout/components/Grid"
import { Divider } from "../../../../../_webui/layout/components/Divider"
import { Translate } from "../../../../../constant/Translate"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { ModalConfirm } from "../../../../../_webui/layout/components/Modal/Confirm"
import { AttentionTravelersGroup } from "../../components/attention/TravelersGroup"
import { Space } from "../../../../../_webui/layout/components/Space"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Breadcrumbs } from "../../../../../constant/Breadcrumbs"
import { AttentionForm } from "../../components/attention/Form"
import { AttentionTitle } from "../../components/attention/Title"

const { TEXT_T307 } = Translate
const { MESSAGE_C0008 } = Translate
const { BRCR_SCR312 } = Breadcrumbs

export const Attention: FC = () => {
  const {
    columns,
    form,
    gridItems,
    isDisabledBtnSelect,
    isDisabledSubmit,
    isHiddenBackBtn,
    isHiddenCopyBtn,
    isHiddenResetBtn,
    isHiddenSubmitBtn,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenForm,
    onAddOrRemoveSafeties,
    onCancel,
    onCancelForm,
    onCopy,
    onEdit,
    onFinish,
    onGoBack,
    onOpen,
    onOpenForm,
    onReset,
    onSend,
    rowSelectionAttention,
    safetiesColumns,
    safetiesList,
    safetiesSelected,
    travelerInfoList,
  } = useAttention()

  return (
    <Layout
      isLoadingLayout={isLoadingLayout}
      breadcrumbItems={BRCR_SCR312}
      title={TEXT_T307}
    >
      <Card heading={TEXT_T307} isLoading={isLoading}>
        <AttentionTitle
          isHiddenCopyBtn={isHiddenCopyBtn}
          onCopy={onCopy}
          onEdit={onEdit}
        />
        <Space isSpaceBottom>
          <Grid gridItems={gridItems} isCustom isItemKeySmall />
        </Space>
        <Divider />
        <AttentionTravelersGroup
          columns={columns}
          isDisabledSubmit={isDisabledSubmit}
          isHiddenBackBtn={isHiddenBackBtn}
          isHiddenResetBtn={isHiddenResetBtn}
          isHiddenSubmitBtn={isHiddenSubmitBtn}
          onGoBack={onGoBack}
          onOpen={onOpen}
          onOpenForm={onOpenForm}
          onReset={onReset}
          safetiesSelected={safetiesSelected}
          travelerInfoList={travelerInfoList}
        />
      </Card>
      <ModalConfirm isOpen={isOpen} onCancel={onCancel} onOk={onSend}>
        {MESSAGE_C0008}
      </ModalConfirm>
      <AttentionForm
        form={form}
        isDisabledBtnSelect={isDisabledBtnSelect}
        isOpenForm={isOpenForm}
        onAddOrRemoveSafeties={onAddOrRemoveSafeties}
        onCancelForm={onCancelForm}
        onFinish={onFinish}
        rowSelectionAttention={rowSelectionAttention}
        safetiesColumns={safetiesColumns}
        safetiesList={safetiesList}
      />
    </Layout>
  )
}
