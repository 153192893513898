import { Translate } from "../../../../../../../../constant/Translate"
import { SchedulesStateType } from "../../../../../models/list"

const { TEXT_T091 } = Translate

export const convertDataSelected = (
  dataSourceSelected: SchedulesStateType[],
): SchedulesStateType[] =>
  dataSourceSelected?.map((item) => ({
    ...item,
    workPlace:
      item.workPlace === "2" ? TEXT_T091 : item.workPlace ? item.workPlace : "",
  }))
