/* eslint-disable max-lines */
import { useEffect } from "react"
import { Routers } from "../../../../../../../../constant/Routers"
import { Translate } from "../../../../../../../../constant/Translate/index"
import { AxiosErrorType } from "../../../../../../../../infrastructure/axiosError/models"
import { backToLogin } from "../../../../../../../../utils/backToLogin"
import { openNotification } from "../../../../../../../../utils/openNotification"
import { pushStateError } from "../../../../../../../../utils/pushStateError"
import { replaceString } from "../../../../../../../../utils/replaceString"
import { commonApi } from "../../../../../apis"
import { RolesConfig } from "../../../../../constants/roles/setting/rolesConfig"
import {
  AuthorityPatternSettingResType,
  RolesSettingFormDataType,
  UseFormRolesSettingType,
} from "../../../../../models/roles/setting"
import { useVariables } from "../useVariables"
import { convertDataSource } from "./convertDataSource"
import { RolesCheckboxOptionsType } from "../../../../../components/roles/Table/CheckboxGroup/models"

const { ROLE_ATTENTION, ROLE_SAFETY, ROLE_SCHEDULE, ROLE_TRAVELER } =
  RolesConfig
const { SCR954, SCR973 } = Routers
const { MESSAGE_E0034 } = Translate

export const useRolesSetting =
  (): UseFormRolesSettingType<RolesSettingFormDataType> => {
    const {
      authorityPatterns,
      breadcrumbItems,
      form,
      goBack,
      goToSubAdminSetting,
      isCommonPermissionSettings,
      isHiddenBtn,
      isLoading,
      isLoadingLayout,
      isOpen,
      isOpenLink,
      onCancel,
      onCancelLink,
      onOpen,
      onOpenLink,
      patternId,
      push,
      setIsLoading,
      setIsLoadingLayout,
      setPatternId,
      setUpdateDate,
      setValues,
      updateDate,
      values,
    } = useVariables()

    const onChangePatternId = (value: string) => {
      setIsLoading(true)
      setPatternId(value)
      getAuthorityPatternSetting(value)
    }

    const getAuthorityPatternSetting = (_patternId: string) => {
      if (form.getFieldValue("patternId")) {
        commonApi
          .getAuthorityPatternSetting(_patternId)
          .then((res: AuthorityPatternSettingResType) => {
            const getValue = (roles: RolesCheckboxOptionsType[]) => {
              const ids = roles.map((item) => item.value)
              return res.subFunctionIds
                .map((item) => (ids.includes(item) ? item : ""))
                .filter((item) => item !== "")
            }

            form.setFieldsValue({
              authorityAttention: getValue(ROLE_ATTENTION),
              authoritySafetyConfirm: getValue(ROLE_SAFETY),
              authorityTravelScheduleInfo: getValue(ROLE_SCHEDULE),
              authorityTravelerInfo: getValue(ROLE_TRAVELER),
              patternId: form.getFieldValue("patternId"),
            })
            setUpdateDate(res.updateDate)
            setIsLoading(false)
            setIsLoadingLayout(false)
          })
          .catch((error: AxiosErrorType) => {
            const state = pushStateError(error)
            push({ pathname: SCR973, state })
          })
      } else {
        setIsLoading(false)
      }
    }

    const onFinish = (values: RolesSettingFormDataType) => {
      const _values = convertDataSource(values, updateDate)
      if (!_values?.subFunctionIds.length) {
        openNotification(MESSAGE_E0034)
        return
      }

      setValues(_values)
      onOpen()
    }

    // APIを呼び出し、権限パターン設定情報を更新し。
    const onOk = () => {
      if (typeof values === "object") {
        setIsLoading(true)
        commonApi
          .updateAuthorityPatternSetting(values)
          .then(() => {
            push(replaceString(SCR954, values?.patternId || patternId))
          })
          .catch((error: AxiosErrorType) => {
            const stateError = pushStateError(error)
            push({ pathname: SCR973, state: stateError })
          })
      }
    }

    useEffect(() => {
      // ユーザの画面アクセス許可チェックを処理する。
      if (!isCommonPermissionSettings) {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const { pathname, state } = backToLogin()
        push({ pathname, state })
      } else if (patternId) getAuthorityPatternSetting(patternId)
      else {
        setTimeout(() => {
          setIsLoadingLayout(false)
        }, 500)
      }
    }, [])

    return {
      authorityPatterns,
      breadcrumbItems,
      form,
      goBack,
      goToSubAdminSetting,
      isHiddenBtn,
      isLoading,
      isLoadingLayout,
      isOpen,
      isOpenLink,
      onCancel,
      onCancelLink,
      onChangePatternId,
      onFinish,
      onOk,
      onOpenLink,
      patternId,
    }
  }
