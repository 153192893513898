/* eslint-disable max-lines */
import { Routers } from "../../../../../../../constant/Routers"
import { Translate } from "../../../../../../../constant/Translate"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { openNotification } from "../../../../../../../utils/openNotification"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { travelerApi } from "../../../../apis"
import {
  RequestBrowseTravelersResType,
  RequestDeleteAPIResType,
  TableErrorTravelersRowType,
  TravelersRequestDeleteReqType,
} from "../../../../models/requestDelete"
import { useVariablesApi } from "../useVariablesApi"

const { SCR973 } = Routers
const { MESSAGE_I0001, MESSAGE_I0002, MESSAGE_I0003, MESSAGE_I0004 } = Translate

export const useApi = () => {
  const {
    activeKey,
    dataGridItems,
    dataSourceLength,
    executeDetail,
    isDisabledButton,
    isLoading,
    isLoadingLayout,
    isOpenAccept,
    isOpenReActive,
    isOpenReEnable,
    isOpenRequestCancel,
    isResetTable,
    isShowTableError,
    onCancelAccept,
    onCancelReActive,
    onCancelReEnable,
    onCancelRequestCancel,
    onChange,
    onChangeTable,
    onOpenAccept,
    onOpenReActive,
    onOpenReEnable,
    onOpenRequestCancel,
    perItem,
    push,
    rowSelection,
    searchCondition,
    selectedRowKeys,
    setActiveKey,
    setDataGridItems,
    setDataSourceLength,
    setExecuteDetail,
    setIsCheckAll,
    setIsDisabledButton,
    setIsLoading,
    setIsLoadingLayout,
    setIsResetTable,
    setIsShowTableError,
    setSearchCondition,
    setSelectedRowKeys,
    setTableErrorDataSource,
    tableErrorDataSource,
  } = useVariablesApi()

  // 「SCR973_エラー情報の表示画面」に遷移する。
  const handleError = (error: AxiosErrorType) => {
    const stateError = pushStateError(error)
    push({ pathname: SCR973, state: stateError })
  }

  const onClickSearchSubmit = (values: TravelersRequestDeleteReqType) => {
    // を呼び出し、指定されている検索条件に従がって渡航者情報一覧を取得する。
    setIsLoading(true)
    handleGetBrowseTravelers(values)
    setSearchCondition(values)
    setTimeout(() => {
      document.getElementById("title-right")?.focus()
    }, 600)
  }

  const handleGetBrowseTravelers = (data?: TravelersRequestDeleteReqType) => {
    // 「渡航者情報の一覧」を取得する。
    travelerApi
      .requestBrowseTravelers(data)
      .then((res: RequestBrowseTravelersResType) => {
        setIsCheckAll(false)
        setSelectedRowKeys([])
        setDataGridItems(res)
        const _dataSource = Object.values(res[activeKey])
        setDataSourceLength(_dataSource.length)
        setIsLoading(false)
        setIsLoadingLayout(false)
      })
      .catch((error: AxiosErrorType) => {
        handleError(error)
      })
  }

  const onTabClick = (_activeKey: keyof RequestBrowseTravelersResType) => {
    setIsResetTable(true)
    setTimeout(() => {
      setIsResetTable(false)
      const _dataSource = dataGridItems
        ? Object.values(dataGridItems[_activeKey])
        : []

      setActiveKey(_activeKey)
      setDataSourceLength(_dataSource.length)
      setIsCheckAll(false)
      setSelectedRowKeys([])
    }, 100)
  }

  const onOk = (res: RequestDeleteAPIResType, message: string) => {
    // 「渡航者情報の結果件数」コンポーネントの値を表示する。
    if (res?.execute) setExecuteDetail(res.execute)

    if (res?.errors?.length) {
      // ComponentErrorを表示する。
      setIsShowTableError(true)
      setIsDisabledButton(true)

      const dataTableError: TableErrorTravelersRowType[] = res.errors.map(
        (item, index) => ({
          id: index,
          message: item.message,
          userId: item.userId,
        }),
      )

      setTableErrorDataSource(dataTableError)
    } else {
      openNotification(message)
      setIsShowTableError(false)
      handleGetBrowseTravelers(searchCondition)
    }
  }

  const onOkAccept = () => {
    onCancelAccept()
    setIsLoading(true)
    setTimeout(() => {
      // 渡航者情報削除依頼を承認する。
      travelerApi
        .acceptRequestDisable(selectedRowKeys)
        .then((res: RequestDeleteAPIResType) => {
          onOk(res, MESSAGE_I0001)
          setIsLoading(false)
        })
        .catch((error: AxiosErrorType) => {
          handleError(error)
        })
    }, 500)
  }

  const onOkReEnable = () => {
    onCancelReEnable()
    setIsLoading(true)
    setTimeout(() => {
      // 渡航者のアカウントを復活する。
      travelerApi
        .enable(selectedRowKeys)
        .then((res: RequestDeleteAPIResType) => {
          onOk(res, MESSAGE_I0002)
          setIsLoading(false)
        })
        .catch((error: AxiosErrorType) => {
          handleError(error)
        })
    }, 500)
  }

  const onOkRequestDisable = () => {
    onCancelRequestCancel()
    setIsLoading(true)
    setTimeout(() => {
      // 渡航者情報の削除依頼を取り消す。
      travelerApi
        .cancelRequestDisable(selectedRowKeys)
        .then((res: RequestDeleteAPIResType) => {
          onOk(res, MESSAGE_I0003)
          setIsLoading(false)
        })
        .catch((error: AxiosErrorType) => {
          handleError(error)
        })
    }, 500)
  }

  const onOkReActive = () => {
    onCancelReActive()
    setIsLoading(true)
    setTimeout(() => {
      // 渡航者情報の再有効化依頼を行う。
      travelerApi
        .requestEnable(selectedRowKeys)
        .then((res: RequestDeleteAPIResType) => {
          onOk(res, MESSAGE_I0004)
          setIsLoading(false)
        })
        .catch((error: AxiosErrorType) => {
          handleError(error)
        })
    }, 500)
  }

  return {
    activeKey,
    dataGridItems,
    dataSourceLength,
    executeDetail,
    handleGetBrowseTravelers,
    isDisabledButton,
    isLoading,
    isLoadingLayout,
    isOpenAccept,
    isOpenReActive,
    isOpenReEnable,
    isOpenRequestCancel,
    isResetTable,
    isShowTableError,
    onCancelAccept,
    onCancelReActive,
    onCancelReEnable,
    onCancelRequestCancel,
    onChange,
    onChangeTable,
    onClickSearchSubmit,
    onOkAccept,
    onOkReActive,
    onOkReEnable,
    onOkRequestDisable,
    onOpenAccept,
    onOpenReActive,
    onOpenReEnable,
    onOpenRequestCancel,
    onTabClick,
    perItem,
    push,
    rowSelection,
    selectedRowKeys,
    tableErrorDataSource,
  }
}
