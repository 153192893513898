/* eslint-disable max-statements */
import Excel from "exceljs"
import { Translate } from "../../../../../../../../../../../constant/Translate"
import { statusesCountHeader } from "../../config/header/statusesCountHeader"
import {
  SafetiesDetailExcelSheetType,
  SafetyInfoHeaderType,
} from "../../../../../../../../models/detail/export"

const {
  EXPORT_EP0019, // 安否状況    Safety status
  EXPORT_EP0098, // 代理応答    Respond on Behalf
  EXPORT_EP0024, // 応答時刻    Response time
  EXPORT_EP0150, // 氏名    Full name
  EXPORT_EP0151, // パスポート    表記Passport notation
  EXPORT_EP0158, // 人材種別（詳細）    Dispatch type (Detail)
  EXPORT_EP0012, // パスポート番号    Passport number
  EXPORT_EP0103, // 電話番号    Phone number
  EXPORT_EP0139, // 緊急連絡先    Emergency contact
  EXPORT_EP0165, // 所属先名    Affiliation name
  EXPORT_EP0149, // 所属先連絡先    Affiliation contact information
  EXPORT_EP0135, // 保険会社名    Insurance company name
  EXPORT_EP0136, // 保険会社連絡先    Insurance company contact information
  EXPORT_EP0137, // ②保険証券番号    [2]Insurance policy number
  EXPORT_EP0021, // 委任者    Delegator
} = Translate

export const columnsConfig = (
  data: SafetiesDetailExcelSheetType[],
  header: SafetyInfoHeaderType,
  worksheet: Excel.Worksheet,
) => {
  const { currentDate, implementationDate, safetyTitle, totalCountHeader } =
    header
  worksheet.columns = [
    { width: 3.5 },
    { width: 17.25 },
    { width: 9.5 },
    { width: 17 },
    { width: 17.5 },
    { width: 28 },
    { width: 25.5 },
    { width: 30.38 },
    { width: 30.38 },
    { width: 35.5 },
    { width: 12.38 },
    { width: 17.38 },
    { width: 14.88 },
    { width: 23.5 },
  ]
  worksheet.getCell("A1").value = safetyTitle
  worksheet.getCell("N1").value = implementationDate
  worksheet.getCell("N2").value = currentDate
  worksheet.getCell("A3").value = totalCountHeader
  worksheet.getCell("A4").value = statusesCountHeader(data)
  worksheet.getCell("A6").value = ""
  worksheet.getCell("B6").value = EXPORT_EP0019
  worksheet.getCell("C6").value = EXPORT_EP0098
  worksheet.getCell("D6").value = EXPORT_EP0024
  worksheet.getCell("E6").value = `${EXPORT_EP0150}\n${EXPORT_EP0151}`
  worksheet.getCell("F6").value = `${EXPORT_EP0158}\n${EXPORT_EP0012}`
  worksheet.getCell("G6").value = EXPORT_EP0103
  worksheet.getCell("H6").value = EXPORT_EP0139
  worksheet.getCell("I6").value = EXPORT_EP0165
  worksheet.getCell("J6").value = EXPORT_EP0149
  worksheet.getCell("K6").value = EXPORT_EP0135
  worksheet.getCell("L6").value = EXPORT_EP0136
  worksheet.getCell("M6").value = EXPORT_EP0137
  worksheet.getCell("N6").value = EXPORT_EP0021
}
