/* eslint-disable max-statements */
import Excel from "exceljs"
import { Translate } from "../../../../../../../../../../../constant/Translate"
import { statusesCountHeader } from "../../config/header/statusesCountHeader"
import {
  SafetiesDetailExcelSheetType,
  SafetyInfoHeaderType,
} from "../../../../../../../../models/detail/export"

const {
  EXPORT_EP0019, // 安否状況    Safety status
  EXPORT_EP0098, // 代理応答    Respond on Behalf
  EXPORT_EP0024, // 応答時刻    Response time
  EXPORT_EP0150, // 氏名    Full name
  EXPORT_EP0151, // パスポート    表記Passport notation
  EXPORT_EP0158, // 人材種別（詳細）    Dispatch type (Detail)
  EXPORT_EP0012, // パスポート番号    Passport number
  EXPORT_EP0078, // 主管部署    Department
  EXPORT_EP0103, // 電話番号    Phone number
  EXPORT_EP0138, // 本人コメント    Personal comment
  EXPORT_EP0043, // 現在地    Current location
  EXPORT_EP0025, // 家族安否状況    Family safety status
  EXPORT_EP0172, // 備考欄1（随伴家族情報）   Remarks 1
  EXPORT_EP0035, // 拠点担当者名    Name of person in charge of overseas office
} = Translate

export const columnsConfig = (
  data: SafetiesDetailExcelSheetType[],
  header: SafetyInfoHeaderType,
  worksheet: Excel.Worksheet,
) => {
  const { currentDate, implementationDate, safetyTitle, totalCountHeader } =
    header
  worksheet.columns = [
    { width: 3.5 },
    { width: 17.25 },
    { width: 9.5 },
    { width: 17 },
    { width: 17.5 },
    { width: 28 },
    { width: 25.5 },
    { width: 43.5 },
    { width: 16.5 },
    { width: 12.5 },
    { width: 14 },
    { width: 38 },
    { width: 15.5 },
  ]
  worksheet.getCell("A1").value = safetyTitle
  worksheet.getCell("L1").value = implementationDate
  worksheet.getCell("L2").value = currentDate
  worksheet.getCell("A3").value = totalCountHeader
  worksheet.getCell("A4").value = statusesCountHeader(data)
  worksheet.getCell("A6").value = ""
  worksheet.getCell("B6").value = EXPORT_EP0019
  worksheet.getCell("C6").value = EXPORT_EP0098
  worksheet.getCell("D6").value = EXPORT_EP0024
  worksheet.getCell("E6").value = `${EXPORT_EP0150}\n${EXPORT_EP0151}`
  worksheet.getCell("F6").value = `${EXPORT_EP0158}\n${EXPORT_EP0012}`
  worksheet.getCell("G6").value = EXPORT_EP0078
  worksheet.getCell("H6").value = EXPORT_EP0103
  worksheet.getCell("I6").value = EXPORT_EP0138
  worksheet.getCell("J6").value = EXPORT_EP0043
  worksheet.getCell("K6").value = EXPORT_EP0025
  worksheet.getCell("L6").value = EXPORT_EP0172
  worksheet.getCell("M6").value = EXPORT_EP0035
}
