import { useLocation } from "react-router-dom"
import { Translate } from "../../../../../../../constant/Translate"
import { PushStateErrorType } from "../../../../../../../utils/pushStateError/models"
import { UseErrorType } from "../../../../models"
import keycloak from "../../../../../../../utils/keycloak"

const { MESSAGE_E9996, MESSAGE_E9999, MESSAGE_E9999_TITLE } = Translate

export const useError = (): UseErrorType => {
  const { state } = useLocation<PushStateErrorType>()

  return {
    isSideBar: keycloak.token ? !!state?.isSideBar : true,
    message: keycloak.token ? state?.message || MESSAGE_E9999 : MESSAGE_E9996,
    title: state?.title || MESSAGE_E9999_TITLE,
  }
}
