import { Variables } from "../../../../../../constants/filesUpload"
import { CheckItemLengthProps } from "../../../../../../model/filesUpload"

const { BORAN_SHINHAKEN_ZAIGAI_REGEX, SYSTEM_IDS, SYSTEM_NAMES } = Variables
const { INVALID } = SYSTEM_IDS
const { ERROR_SYSTEM_LENGTH } = SYSTEM_NAMES

export const checkItemLength = async (item: CheckItemLengthProps) => {
  const { file, itemLength, systemId, systemName } = item
  let check = { systemId, systemName }
  if (file.size > 0) {
    await file.text().then((value) => {
      value.split("\r\n").forEach((item) => {
        const splitChar = BORAN_SHINHAKEN_ZAIGAI_REGEX.test(file.name)
          ? '","'
          : ","
        const _itemLength = item.split(splitChar).length
        if (item.match(/[^\r\n]+/g) !== null && _itemLength !== itemLength) {
          check = {
            systemId: INVALID,
            systemName: ERROR_SYSTEM_LENGTH,
          }
        }
      })
    })
  }
  return check
}
