/* eslint-disable complexity */
/* eslint-disable max-statements */
import { FC } from "react"
import { HeaderUserProps } from "./models"
import { HeaderDropdown } from "../Dropdown"
import { Translate } from "../../../../../constant/Translate"
import { Images } from "../../../../../constant/Images"
import { Styled } from "../styled"
import { getUserInformation } from "../../../../../utils/getUserInformation"
import { Constant } from "../../../../../constant"
import keycloak from "../../../../../utils/keycloak"
import { Environment } from "../../../../../constant/Environment"
import { LoginActions } from "../../../../../constant/Login"
import { translateHelper } from "../../../../../utils/translateHelper"
import { checkPublicRouter } from "../../../../../utils/checkPublicRouter"

const { CHECK_SELECTED_LANG } = Constant
const { IMAGE_HEADER_ICON_02 } = Images
const { KEY, SESSION, TIMEOUT } = LoginActions
const { StyledHeaderActionItem } = Styled
const { TEXT_T516 } = Translate

export const HeaderUser: FC<HeaderUserProps> = (props) => {
  const { handleLogout, ...rest } = props
  const userInformation = getUserInformation()
  const fullName = CHECK_SELECTED_LANG
    ? userInformation?.fullNameKanji || userInformation?.fullNameRomaji
    : userInformation?.fullNameRomaji
  if (!userInformation && keycloak.authenticated) {
    if (localStorage.getItem(KEY) === SESSION) {
      localStorage.removeItem(KEY)
      keycloak.logout({ redirectUri: `${Environment.URL}/home` })
    } else {
      if (localStorage.getItem(KEY) !== TIMEOUT && !checkPublicRouter()) {
        localStorage.setItem(KEY, SESSION)
        alert(translateHelper.getSessionTimeOutMessage())
      }
      setTimeout(() => {
        if (localStorage.getItem(KEY) === SESSION) {
          localStorage.removeItem(KEY)
        }
        keycloak.logout({ redirectUri: `${Environment.URL}/home` })
      }, 500)
    }
  }
  const items = [
    {
      key: "item-1",
      label: TEXT_T516,
      onClick: handleLogout,
    },
  ]

  return (
    <StyledHeaderActionItem
      ariaLabel={fullName}
      content={<HeaderDropdown src={IMAGE_HEADER_ICON_02} text={fullName} />}
      menu={{ items }}
      {...rest}
    />
  )
}
