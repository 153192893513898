/* eslint-disable max-lines */
import { useLocation, useParams } from "react-router-dom"
import { getUserInformation } from "../../../../../../../utils/getUserInformation"
import { UseParamsType } from "../../../../../../../utils/useParams"
import { TravelersEditProps } from "../../../../models/edit"
import { useForm } from "../useForm"
import { useTableConfig } from "../useTableConfig"
import { useTabsConfig } from "../useTabsConfig"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { getBreadcrumbItems } from "./getBreadcrumbItems"
import { useDatePicker } from "../../../../../../../utils/hook/useDatePicker"

export const useVariables = () => {
  const { id } = useParams<UseParamsType>()
  const { state } = useLocation<TravelersEditProps>()
  const userInformation = getUserInformation()
  const isPersonUrl = id === userInformation?.travelerId

  // ロール条件に応じて項目を画面に表示する。
  const {
    isHiddenBtnCancel,
    isHiddenBtnSubmit,
    isNotAccessScreen,
    isRoleAdmin,
    isRoleSubAdmin,
    isRoleUser,
  } = useVariablesAuthority(id, userInformation?.travelerId)

  const {
    accompanies,
    addItems,
    allCountries,
    cities,
    departments,
    dispatchType,
    form,
    fullName,
    getTravelerDetails,
    handleGetTravelerDraft,
    indexTabActive,
    isAccompanyingFamily,
    isChangeUser,
    isCopyEmailAddress,
    isDifferentInforCheck,
    isDisabledBtnDelete,
    isDisabledSubmit,
    isHiddenBtnTravelerDraft,
    isHiddenTrainingDate,
    isInsuranceRequired,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenPopup,
    isOpenTravelerDraft,
    isOtherCity,
    isOverseasResident,
    isRequiredUrgentContact,
    listKey1IsAccompanyingFamily,
    onCancel,
    onCancelTravelerDraft,
    onChangeTrainingType,
    onCopyEmailAddress,
    onDifferentInforCheckChange,
    onDispatchTypeDetailChange,
    onFinish,
    onOk,
    onOkPopup,
    onOkTravelerDraft,
    onOpenTravelerDraft,
    onTabClick,
    onValuesChange,
    onWorkPlaceCityChange,
    onWorkPlaceCountryChange,
    onWorkPlaceLocationChange,
    othersStaffNumber,
    prefectures,
    relationships,
    removeItems,
    rowSelection,
  } = useForm(id, isRoleSubAdmin)
  const { onBlur, onKeyDown } = useDatePicker({
    form,
    isRequired: true,
    onValuesChange,
  })

  const { columns } = useTableConfig({ relationships })

  const { tabsItems } = useTabsConfig({
    accompanies,
    addItems,
    relationships,
    removeItems,
  })

  const breadcrumbItems = getBreadcrumbItems(
    isPersonUrl,
    state?.isFromSCR113,
    state?.isFromSCR115,
  )

  return {
    accompanies,
    addItems,
    allCountries,
    breadcrumbItems,
    cities,
    columns,
    departments,
    dispatchType,
    form,
    fullName,
    getTravelerDetails,
    handleGetTravelerDraft,
    id,
    indexTabActive,
    isAccompanyingFamily,
    isChangeUser,
    isCopyEmailAddress,
    isDifferentInforCheck,
    isDisabledBtnDelete,
    isDisabledSubmit,
    isHiddenBtnCancel,
    isHiddenBtnSubmit,
    isHiddenBtnTravelerDraft,
    isHiddenTrainingDate,
    isInsuranceRequired,
    isLoading,
    isLoadingLayout,
    isNotAccessScreen,
    isOpen,
    isOpenPopup,
    isOpenTravelerDraft,
    isOtherCity,
    isOverseasResident,
    isPersonUrl,
    isRequiredUrgentContact,
    isRoleAdmin,
    isRoleSubAdmin,
    isRoleUser,
    listKey1IsAccompanyingFamily,
    onBlur,
    onCancel,
    onCancelTravelerDraft,
    onChangeTrainingType,
    onCopyEmailAddress,
    onDifferentInforCheckChange,
    onDispatchTypeDetailChange,
    onFinish,
    onKeyDown,
    onOk,
    onOkPopup,
    onOkTravelerDraft,
    onOpenTravelerDraft,
    onTabClick,
    onValuesChange,
    onWorkPlaceCityChange,
    onWorkPlaceCountryChange,
    onWorkPlaceLocationChange,
    othersStaffNumber,
    prefectures,
    removeItems,
    rowSelection,
    state,
    tabsItems,
  }
}
