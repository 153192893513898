import { useEffect } from "react"
import { Routers } from "../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { scheduleApi } from "../../../../apis"
import {
  SchedulesCloneDataType,
  UseSchedulesCloneType,
} from "../../../../models/clone"
import { SchedulesListDataType } from "../../../../models/list"
import { useVariables } from "../useVariables"
import { getDataSource } from "./getDataSource"

const { SCR973 } = Routers

export const useSchedulesClone =
  (): UseSchedulesCloneType<SchedulesCloneDataType> => {
    const {
      breadcrumbItems,
      columns,
      dataSource,
      goBackSchedulesAdd,
      goToSchedulesAdd,
      isDisabledBtnClone,
      isLoadingLayout,
      isNotAccessScreen,
      isVisibleBtnBack,
      isVisibleBtnCopy,
      onChange,
      onChangeTable,
      perItem,
      push,
      rowSelection,
      setDataSource,
      setIsLoadingLayout,
      setScheduleList,
      travelerId,
    } = useVariables()

    const getSchedules = () => {
      // 各情報を取得する。
      scheduleApi
        .getSchedules({ isScheduleCloning: true, travelerId })
        .then((travelScheduleList: SchedulesListDataType[]) => {
          setScheduleList(travelScheduleList)
          const data = getDataSource(travelScheduleList)
          setDataSource(data)
          setIsLoadingLayout(false)
        })
        .catch((error: AxiosErrorType) => {
          // 「SCR973_エラー情報の表示画面」に遷移する。
          const stateError = pushStateError(error)
          push({ pathname: SCR973, state: stateError })
        })
    }

    useEffect(() => {
      // ユーザの画面アクセス許可チェックを処理する。
      if (isNotAccessScreen) {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const { pathname, state } = backToLogin()
        push({ pathname, state })
      } else getSchedules()
    }, [])

    return {
      breadcrumbItems,
      columns,
      dataSource,
      goBackSchedulesAdd,
      goToSchedulesAdd,
      isDisabledBtnClone,
      isLoadingLayout,
      isVisibleBtnBack,
      isVisibleBtnCopy,
      onChange,
      onChangeTable,
      perItem,
      rowSelection,
    }
  }
