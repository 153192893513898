import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import keycloak from "../../../../../../utils/keycloak"
import { setLanguage } from "../../../../../i18n/OjicaI18n"
import { LoginActions } from "../../../../../../constant/Login"
import { Routers } from "../../../../../../constant/Routers"
import { UseHeaderType } from "../../models"
import { clearLocalStorage } from "../../../../../../utils/clearLocalStorage"
import { useModal } from "../../../../../../utils/hook/useModal"
import { AxiosErrorType } from "../../../../../../infrastructure/axiosError/models"
import { scheduleApi } from "../../../../../../app/module/schedules/apis"
import { pushStateError } from "../../../../../../utils/pushStateError"
import { travelerApi } from "../../../../../../app/module/travelers/apis"

const { KEY, LOGIN } = LoginActions
const { SCR911, SCR973 } = Routers

export const useHeader = (): UseHeaderType => {
  const { push } = useHistory()
  const { isOpen, onCancel, onOpen } = useModal()
  const [isToggleMenu, setIsToggleMenu] = useState(false)
  const handleToggleMenu = () => {
    setIsToggleMenu(!isToggleMenu)
  }

  const keycloakLogout = () => {
    clearLocalStorage()
    localStorage.setItem(KEY, LOGIN)
    sessionStorage.removeItem("app_token")
    push(SCR911)
    keycloak.logout()
  }

  // セッションから権限一覧をクリアする。
  const handleLogout = () => {
    if (keycloak.authenticated) {
      const dataUpload = sessionStorage.getItem("dataUpload")
      if (dataUpload) {
        onOpen()
      } else {
        keycloakLogout()
      }
    }
  }

  const onOk = () => {
    const dataUpload = sessionStorage.getItem("dataUpload")
    // APIを呼び出し、APIからの応答結果を「一時削除応答」変数に設定する。
    if (typeof dataUpload === "string") {
      if (dataUpload.startsWith("SCR1")) {
        travelerApi
          .bulkDeleteData(dataUpload)
          .then(() => {
            keycloakLogout()
          })
          .catch((error: AxiosErrorType) => {
            // 「SCR973_エラー情報の表示画面」に遷移する。
            const stateError = pushStateError(error)
            push({ pathname: SCR973, state: stateError })
          })
      }
      if (dataUpload.startsWith("SCR2")) {
        scheduleApi
          .bulkDeleteData(dataUpload)
          .then(() => {
            keycloakLogout()
          })
          .catch((error: AxiosErrorType) => {
            // 「SCR973_エラー情報の表示画面」に遷移する。
            const stateError = pushStateError(error)
            push({ pathname: SCR973, state: stateError })
          })
      }
    }
  }

  const setLanguageToEnglish = () => {
    setLanguage("en")
  }

  const setLanguageToJapanese = () => {
    setLanguage("ja")
  }

  useEffect(() => {
    if (isToggleMenu) {
      document.body.classList.add("no-scroll")
    } else {
      document.body.classList.remove("no-scroll")
    }
  }, [isToggleMenu])

  return {
    handleLogout,
    handleToggleMenu,
    isOpen,
    isToggleMenu,
    onCancel,
    onOk,
    setLanguageToEnglish,
    setLanguageToJapanese,
  }
}
