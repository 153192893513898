import { FC, Fragment } from "react"
import { ItemDatePicker } from "../../../../../../../_webui/layout/components/Form/Item/DatePicker"
import { ItemRadio } from "../../../../../../../_webui/layout/components/Form/Item/Radio"
import { RadioItems } from "../../../../../../../constant/RadioItems"
import { Translate } from "../../../../../../../constant/Translate"
import { TrainingInfoProps } from "./models"
import { requiredField } from "../../../../../../../utils/requiredField"

const { HELP_H0017, HELP_H0018, TEXT_T065, TEXT_T066 } = Translate
const { TRAINING_TYPE_EDIT } = RadioItems

export const TrainingInfo: FC<TrainingInfoProps> = (props) => {
  const {
    isAccompanyingFamily,
    isHiddenTrainingDate,
    onBlur,
    onChangeTrainingType,
    onKeyDown,
  } = props

  const requiredItem = requiredField(!isAccompanyingFamily)
  const radioItems = isAccompanyingFamily
    ? [TRAINING_TYPE_EDIT[0]]
    : TRAINING_TYPE_EDIT.slice(1)

  return (
    <Fragment>
      <ItemRadio
        label={TEXT_T065}
        name="trainingType"
        onChange={onChangeTrainingType}
        radioItems={radioItems}
        required={isAccompanyingFamily}
        rules={requiredField(isAccompanyingFamily)}
        tooltip={HELP_H0017}
      />
      {isHiddenTrainingDate && !isAccompanyingFamily && (
        <ItemDatePicker
          isBgBlue
          label={TEXT_T066}
          name="trainingDate"
          onBlur={(event) => onBlur(event, TEXT_T066, "trainingDate")}
          onKeyDown={(event) => onKeyDown(event, TEXT_T066, "trainingDate")}
          required
          rules={requiredItem}
          tooltip={HELP_H0018}
        />
      )}
    </Fragment>
  )
}
