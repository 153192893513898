import { FC } from "react"
import { Alert } from "../../../../../../_webui/layout/components/Alert"
import { ButtonGroup } from "../../../../../../_webui/layout/components/Button/Group"
import { Card } from "../../../../../../_webui/layout/components/Card"
import { Grid } from "../../../../../../_webui/layout/components/Grid"
import { Layout } from "../../../../../../_webui/layout/components/Layout"
import { Space } from "../../../../../../_webui/layout/components/Space"
import { Breadcrumbs } from "../../../../../../constant/Breadcrumbs"
import { Translate } from "../../../../../../constant/Translate"
import { RolesTable } from "../../../components/roles/Table"
import { useRolesSuccess } from "./modules/useSuccess"

const { TEXT_T072, TEXT_T399, TEXT_T450, TEXT_T483 } = Translate
const { BRCR_SCR954 } = Breadcrumbs

export const RolesSuccess: FC = () => {
  const {
    dataSource,
    detail,
    goToRolesSetting,
    goToSubAdminSetting,
    isHiddenBtn,
    isLoadingLayout,
  } = useRolesSuccess()
  const { attentionOptions, safetyOptions, scheduleOptions, travelerOptions } =
    dataSource
  return (
    <Layout
      breadcrumbItems={BRCR_SCR954}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T399}
    >
      <Card heading={TEXT_T399}>
        <Alert type="success" content={TEXT_T483} />
        <Grid gridItems={detail} />
        <Space isSpaceTop>
          <RolesTable
            attentionOptions={attentionOptions}
            isSuccess
            safetyOptions={safetyOptions}
            scheduleOptions={scheduleOptions}
            travelerOptions={travelerOptions}
          />
        </Space>
        <ButtonGroup
          buttonItems={[
            {
              isHidden: !isHiddenBtn,
              onClick: goToRolesSetting,
              order: 2,
              text: TEXT_T072,
              type: "ghost",
            },
            {
              isHidden: !isHiddenBtn,
              onClick: goToSubAdminSetting,
              order: 1,
              text: TEXT_T450,
            },
          ]}
          position="space-between"
        />
      </Card>
    </Layout>
  )
}
