/* eslint-disable max-depth */
/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable max-lines */
import { useEffect } from "react"
import { Routers } from "../../../../../../../constant/Routers"
import { Translate } from "../../../../../../../constant/Translate"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { checkIsArray } from "../../../../../../../utils/checkIsArray"
import keycloak from "../../../../../../../utils/keycloak"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { setFieldsErrors } from "../../../../../../../utils/setFieldsErrors"
import { translateMessage } from "../../../../../../../utils/translateMessage"
import { usersApi } from "../../../../apis"
import { RequiredFields } from "../../../../constant/changePassword/requiredFields"
import {
  ChangePasswordFormDataType,
  UseChangePasswordType,
} from "../../../../models/changePassword"
import { useVariables } from "../useVariables"
import { setLanguage } from "../../../../../../../_webui/i18n/OjicaI18n"
import { getSelectedLang } from "../../../../../../../utils/getSelectedLang"
import { clearLocalStorage } from "../../../../../../../utils/clearLocalStorage"
import { Environment } from "../../../../../../../constant/Environment"

const { URL } = Environment
const { SCR917, SCR920, SCR973 } = Routers
const { CHANGE_PASSWORD_REQUIRED_FIELDS } = RequiredFields
const { MESSAGE_E0019, MESSAGE_E0052, MESSAGE_W0012 } = Translate

export const useChangePassword =
  (): UseChangePasswordType<ChangePasswordFormDataType> => {
    const {
      breadcrumbItems,
      form,
      isCommonRefer,
      isDisabledSubmit,
      isHiddenBtnBack,
      isHiddenBtnSubmit,
      isLoading,
      isLoadingLayout,
      isRemind,
      isSideBar,
      langParam,
      othersStaffNumber,
      push,
      setIsDisabledSubmit,
      setIsLoading,
      setIsLoadingLayout,
      state,
      stateParam,
    } = useVariables()

    const onValuesChange = () => {
      const formValues = form.getFieldsValue(CHANGE_PASSWORD_REQUIRED_FIELDS)
      const newPassword = form.getFieldValue("newPassword")
      const confirmNewPassword = form.getFieldValue("confirmNewPassword")
      if (confirmNewPassword) {
        if (newPassword !== confirmNewPassword) {
          form.setFields([
            { errors: [MESSAGE_E0019], name: "confirmNewPassword" },
          ])
        } else {
          form.setFields([{ errors: [], name: "confirmNewPassword" }])
        }
      }
      const _isDisabledSubmit =
        checkIsArray(
          form.getFieldsError().filter(({ errors }) => errors.length),
        ) || checkIsArray(Object.values(formValues).filter((item) => !item))
      setIsDisabledSubmit(_isDisabledSubmit)
    }

    const goBack = () => {
      push(state?.sourceURL || SCR920)
    }

    const acceptAgreements = () => {
      usersApi
        .acceptAgreements()
        .then(() => {
          // 「SCR920_ホーム画面」に遷移する」に遷移する。
          if (!stateParam) {
            push(SCR920)
          } else {
            keycloak.login()
          }
        })
        .catch((error: AxiosErrorType) => {
          stateParam && clearLocalStorage()
          const stateError = pushStateError(error)
          push({
            pathname: SCR973,
            state: { ...stateError, isSideBar: stateParam },
          })
        })
    }

    const onFinish = (values: ChangePasswordFormDataType) => {
      setIsLoading(true)
      usersApi
        .changePassword({ ...values, state: stateParam || "" })
        .then(() => {
          if (stateParam) {
            keycloak.logout({ redirectUri: `${URL}` })
          } else {
            usersApi
              .updateFistLogin()
              .then(() => {
                acceptAgreements()
              })
              .catch((error: AxiosErrorType) => {
                stateParam && clearLocalStorage()
                const stateError = pushStateError(error)
                push({
                  pathname: SCR973,
                  state: { ...stateError, isSideBar: stateParam },
                })
              })
          }
        })
        .catch((error: AxiosErrorType) => {
          const dataError = error?.debug?.response?.data
          if (dataError?.message === "W0012") {
            alert(MESSAGE_W0012)
            if (!stateParam) {
              push(SCR920)
            } else {
              stateParam && clearLocalStorage()
              keycloak.login()
            }
          } else if (dataError?.message === "E0042") {
            form?.setFields([
              {
                errors: [""],
                name: "newPassword",
              },
              {
                errors: [translateMessage(`MESSAGE.${dataError?.message}`)],
                name: "confirmNewPassword",
              },
            ])
            form?.scrollToField("newPassword")
            setIsLoading(false)
          } else if (dataError?.entityName) {
            setFieldsErrors(error, form)
            setIsLoading(false)
          } else {
            const stateError = pushStateError(error)
            push({ pathname: SCR973, state: stateError })
          }
        })
    }

    useEffect(() => {
      if (!stateParam && !isRemind) {
        if (!isCommonRefer) {
          const isRemind = sessionStorage.getItem("isRemind")
          if (keycloak?.idTokenParsed?.locale !== "ja") {
            console.log(keycloak?.idTokenParsed?.locale)
            window.location.reload()
            if (isRemind) {
              clearLocalStorage()
              sessionStorage.removeItem("isRemind")
            } else {
              alert(MESSAGE_E0052)
            }
            keycloak.login()
          } else {
            if (isRemind) {
              clearLocalStorage()
              sessionStorage.removeItem("isRemind")
            } else {
              alert(MESSAGE_E0052)
            }
            keycloak.login()
          }
        } else if (othersStaffNumber) {
          sessionStorage.removeItem("isRemind")
          if (keycloak?.idTokenParsed?.locale !== "ja") {
            console.log(keycloak?.idTokenParsed?.locale)
            window.location.reload()
            alert(MESSAGE_W0012)
            push(SCR920)
          } else {
            alert(MESSAGE_W0012)
            push(SCR920)
          }
        } else {
          setTimeout(() => {
            setIsLoadingLayout(false)
          }, 500)
        }
      } else {
        localStorage.removeItem("login.action")
        if (langParam && getSelectedLang() !== langParam) {
          setLanguage(langParam)
        }
        if (isRemind) {
          sessionStorage.setItem("isRemind", "true")
          keycloak.logout({ redirectUri: `${URL}${SCR917}` })
        }
        setTimeout(() => {
          setIsLoadingLayout(false)
        }, 500)
      }
    }, [])

    return {
      breadcrumbItems,
      form,
      goBack,
      isDisabledSubmit,
      isHiddenBtnBack,
      isHiddenBtnSubmit,
      isLoading,
      isLoadingLayout,
      isSideBar,
      onFinish,
      onValuesChange,
    }
  }
