import { FC } from "react"
import { Translate } from "../../../../constant/Translate"
import { Styled } from "./styled"

const { TEXT_T540 } = Translate
const { StyledFooter } = Styled

export const Footer: FC = () => {
  return <StyledFooter>{TEXT_T540}</StyledFooter>
}
