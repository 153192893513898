import { Key, useState } from "react"
import {
  RequestBrowseTravelersResType,
  TravelersRequestDeleteResType,
} from "../../../../models/requestDelete"
import { useTable } from "../../../../../../../utils/hook/useTable"

export const useVariablesRowSelection = (dataSourceLength: number) => {
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [dataCurrent, setDataCurrent] = useState<
    TravelersRequestDeleteResType[]
  >([])
  const [dataGridItemsCurrent, setDataGridItemsCurrent] =
    useState<RequestBrowseTravelersResType>()

  const {
    onChange: onChangePage,
    perItem,
    perItemEnd,
    perItemStart,
  } = useTable(dataSourceLength)

  return {
    dataCurrent,
    dataGridItemsCurrent,
    isCheckAll,
    onChangePage,
    perItem,
    perItemEnd,
    perItemStart,
    selectedRowKeys,
    setDataCurrent,
    setDataGridItemsCurrent,
    setIsCheckAll,
    setSelectedRowKeys,
  }
}
