import { Form } from "antd"
import { useHistory } from "react-router-dom"
import { Routers } from "../../../../../../../constant/Routers"
import { replaceString } from "../../../../../../../utils/replaceString"
import {
  SafetiesListType,
  UseSafetiesListRouters,
} from "../../../../models/list"

const { SCR416, SCR416_HISTORY, SCR417, SCR419 } = Routers

export const useRouters = (props: UseSafetiesListRouters<SafetiesListType>) => {
  const [form] = Form.useForm()
  const {
    isFromSCR416,
    isFromSCR416History,
    pathname,
    safetyDetailId,
    safetyId,
    searchCondition,
  } = props
  const { push } = useHistory()
  const sourceURL = pathname === SCR416_HISTORY ? SCR416_HISTORY : SCR416

  const goToSafetiesDetail = (isHiddenSubmit: boolean) => {
    //「SCR417_安否確認の詳細画面」に遷移する。
    push({
      pathname: replaceString(SCR417, safetyId),
      state: {
        isFromSCR416,
        isFromSCR416History,
        isHiddenSubmit,
        safetyId: safetyId,
        searchCondition: searchCondition,
        sourceURL,
      },
    })
  }

  const goToSafetiesResponse = () => {
    //「SCR419_安否確認情報の応答画面」に遷移する。
    push({
      pathname: SCR419,
      state: {
        isFromSCR416,
        isFromSCR416History,
        safetyDetailId,
        safetyId,
        searchCondition,
        sourceURL,
      },
    })
  }

  return {
    form,
    goToSafetiesDetail,
    goToSafetiesResponse,
    pathname,
  }
}
