import { FC, Fragment } from "react"
import { Translate } from "../../../../../../../../constant/Translate"
import { ItemInput } from "../../../../../../../../_webui/layout/components/Form/Item/Input"
import { ItemSelect } from "../../../../../../../../_webui/layout/components/Form/Item/Select"
import { CollapseContractPersonProps } from "../models"

const { TEXT_T048, TEXT_T049, TEXT_T218 } = Translate

export const CollapseContractPerson: FC<CollapseContractPersonProps> = (
  props,
) => {
  const { personPrefectureResidence } = props

  return (
    <Fragment>
      <ItemSelect
        label={TEXT_T218}
        name="personPrefectureResidence"
        selectItem={personPrefectureResidence}
      />
      <ItemInput
        label={TEXT_T048}
        maxLength={200}
        name="personCompanyPosition"
      />
      <ItemInput
        label={TEXT_T049}
        maxLength={100}
        name="personDispatcherName"
      />
    </Fragment>
  )
}
