import { Key, useState } from "react"
import { useSort } from "../../../../../../../utils/hook/useSort"
import { useTable } from "../../../../../../../utils/hook/useTable"
import {
  SchedulesListDataType,
  SchedulesStateType,
} from "../../../../models/list"

export const useVariablesRowSelection = (dataLength: number) => {
  const [dataCurrent, setDataCurrent] = useState<SchedulesListDataType[]>([])
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [isDisabledBtnDelete, setIsDisabledBtnDelete] = useState(true)
  const [isDisabledBtnRefer, setIsDisabledBtnRefer] = useState(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [travelScheduleId, setTravelScheduleId] = useState("")
  const [isDisabledBtnCreateOrSend, setIsDisabledBtnCreateOrSend] =
    useState(true)
  const [dataSourceSelected, setDataSourceSelected] = useState<
    SchedulesStateType[]
  >([])
  const [dataSourceCurrent, setDataSourceCurrent] = useState<
    SchedulesListDataType[]
  >([])

  const { handleSetDefaultSortedInfo, handleSetSortedInfo, sortedInfo } =
    useSort()

  const {
    onChange: onChangePage,
    perItem,
    perItemEnd,
    perItemStart,
  } = useTable(dataLength)

  return {
    dataCurrent,
    dataSourceCurrent,
    dataSourceSelected,
    handleSetDefaultSortedInfo,
    handleSetSortedInfo,
    isCheckAll,
    isDisabledBtnCreateOrSend,
    isDisabledBtnDelete,
    isDisabledBtnRefer,
    onChangePage,
    perItem,
    perItemEnd,
    perItemStart,
    selectedRowKeys,
    setDataCurrent,
    setDataSourceCurrent,
    setDataSourceSelected,
    setIsCheckAll,
    setIsDisabledBtnCreateOrSend,
    setIsDisabledBtnDelete,
    setIsDisabledBtnRefer,
    setSelectedRowKeys,
    setTravelScheduleId,
    sortedInfo,
    travelScheduleId,
  }
}
