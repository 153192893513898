/* eslint-disable complexity */
import { MouseEvent, useEffect, useRef } from "react"
import {
  focusBreadcrumbs,
  focusHeading,
  focusPreviousElement,
} from "../../../../../../utils/focus"
import { UseModalProps } from "../../models"
import { Variables } from "../../../Form/FormProvider/constants"

const { TEXT_IS_FORM_CHANGE } = Variables

export const useModal = (props: UseModalProps) => {
  const {
    idPreviousElement,
    isFocusHeading,
    isFocusPreviousElement,
    onCancel,
    onCancelCustom,
    onOk,
  } = props
  const searchString = window.location.search

  const onCancelModal = (event: MouseEvent<HTMLElement>) => {
    typeof onCancel === "function" && onCancel(event)
    if (isFocusHeading) focusHeading()
    else if (idPreviousElement) focusPreviousElement(idPreviousElement)
  }

  const onOkModal = (event: MouseEvent<HTMLElement>) => {
    document.body.classList.remove("no-scroll")
    typeof onOk === "function" && onOk(event)
    typeof onCancel === "function" && onCancel(event)
    if (!searchString.includes(TEXT_IS_FORM_CHANGE)) {
      if (isFocusPreviousElement && idPreviousElement)
        focusPreviousElement(idPreviousElement)
      else focusBreadcrumbs()
    }
  }

  const onCancelCustom2 = (event: MouseEvent<HTMLElement>) => {
    document.body.classList.remove("no-scroll")
    typeof onCancelCustom === "function" && onCancelCustom(event)
    if (isFocusHeading) focusHeading()
    else if (idPreviousElement) focusPreviousElement(idPreviousElement)
  }

  const refModal = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (refModal.current) {
      refModal.current.focus()
    }
  })

  return {
    onCancelCustom2,
    onCancelModal,
    onOkModal,
    refModal,
  }
}
