import { Translate } from "../../../../../../../../constant/Translate"
import { replaceString } from "../../../../../../../../utils/replaceString"
import { Grid } from "../../../../../../../../_webui/layout/components/Grid"
import { GridItemType } from "../../../../../../../../_webui/layout/components/Grid/models"
import { TabItemsProps } from "../../../../../../../../_webui/layout/components/Tabs/models"
import { ScheduleDetailsType } from "../../../../../models/addSuccess"
import { Image } from "../../../../../../../../_webui/layout/components/Image"
import { Images } from "../../../../../../../../constant/Images"

const { IMAGE_LIST_CHECK } = Images
const {
  TEXT_T202,
  TEXT_T203,
  TEXT_T204,
  TEXT_T205,
  TEXT_T206,
  TEXT_T207,
  TEXT_T208,
  TEXT_T209,
  TEXT_T220,
  TEXT_T244,
  TEXT_T245,
  TEXT_T266,
} = Translate

export const useDetailTabsConfig = (
  schedulesDetails: ScheduleDetailsType[],
): TabItemsProps[] =>
  schedulesDetails.map((item, index) => {
    const itemGrid: GridItemType[] = [
      { key: TEXT_T202, text: item.transportationName },
      {
        key: TEXT_T220,
        text: item.isTransit && (
          <Image
            src={IMAGE_LIST_CHECK}
            alt={`${TEXT_T220} ${item.isTransit}`}
          />
        ),
      },
      { key: TEXT_T203, text: item.departureFlightNumber },
      { key: TEXT_T205, text: item.departureCountryName },
      { key: TEXT_T206, text: item.departureCityName },
      { key: TEXT_T244, text: item.departureCityNameOther },
      { key: TEXT_T204, text: item.departureDateTime },
      { key: TEXT_T208, text: item.arrivalCountryName },
      { key: TEXT_T209, text: item.arrivalCityName },
      { key: TEXT_T245, text: item.arrivalCityNameOther },
      { key: TEXT_T207, text: item.arrivalDateTime },
      { key: item.remarkNames?.remarksName1, text: item.othersRemarks1 },
      { key: item.remarkNames?.remarksName2, text: item.othersRemarks2 },
      { key: item.remarkNames?.remarksName3, text: item.othersRemarks3 },
      { key: item.remarkNames?.remarksName4, text: item.othersRemarks4 },
      { key: item.remarkNames?.remarksName5, text: item.othersRemarks5 },
    ]

    return {
      children: <Grid gridItems={itemGrid} />,
      key: `${index + 1}`,
      label: replaceString(TEXT_T266, ` ${index + 1}`),
    }
  })
