import { useEffect } from "react"
import { useVariables } from "../useVariables"
import {
  ApplicationFormDataSourceType,
  UseApplicationFormType,
} from "../../../../models/list"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { Routers } from "../../../../../../../constant/Routers"

const { SCR971 } = Routers

export const useApplicationForm =
  (): UseApplicationFormType<ApplicationFormDataSourceType> => {
    const {
      breadcrumbItems,
      columns,
      deleteFiles,
      fileInfoList,
      getFiles,
      handleDownload,
      isDisabledBtnDownload,
      isLoading,
      isLoadingLayout,
      isNotAccessScreen,
      isOpen,
      isVisibleBtnDelete,
      isVisibleBtnReference,
      isVisibleBtnRegister,
      layoutTitle,
      onCancel,
      onChangePagination,
      onChangeTable,
      onOpen,
      pathname,
      perItem,
      push,
      rowSelection,
      type,
    } = useVariables()

    const goToFilesUpload = () => {
      // 「SCR971_ファイルアップロード画面」に遷移する。
      push({
        pathname: SCR971,
        state: {
          fileType: type,
          isCountry: true,
          sourceURL: pathname,
        },
      })
    }

    useEffect(() => {
      // ユーザの画面アクセス許可チェックを処理する。
      if (isNotAccessScreen) {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const { pathname, state } = backToLogin()
        push({ pathname, state })
      } else getFiles()
    }, [])

    return {
      breadcrumbItems,
      columns,
      dataSource: fileInfoList,
      deleteFiles,
      goToFilesUpload,
      handleDownload,
      isDisabledBtnDelete: isDisabledBtnDownload,
      isDisabledBtnDownload,
      isLoading,
      isLoadingLayout,
      isOpen,
      isVisibleBtnDelete,
      isVisibleBtnReference,
      isVisibleBtnRegister,
      layoutTitle,
      onCancel,
      onChangePagination,
      onChangeTable,
      onOpen,
      perItem,
      rowSelection,
    }
  }
