import { FC } from "react"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { Translate } from "../../../../../constant/Translate"
import { Title } from "../../../../../_webui/layout/components/Typography/Title"

import { useContact } from "./modules/useContact"
import { Space } from "../../../../../_webui/layout/components/Space"
import { Styled } from "./styled"
import { ModalConfirm } from "../../../../../_webui/layout/components/Modal/Confirm"
import { ContactForm } from "../../components/contact/Form"
import { Breadcrumbs } from "../../../../../constant/Breadcrumbs"

const { BRCR_SCR992 } = Breadcrumbs
const { StyledHeading } = Styled
const {
  MESSAGE_C0041,
  MESSAGE_C0042,
  TEXT_T671,
  TEXT_T672,
  TEXT_T673,
  TEXT_T689,
} = Translate

export const Contact: FC = () => {
  const {
    form,
    goToHome,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isOpenClear,
    isOpenConfirm,
    onCancelClear,
    onCancelConfirm,
    onFinish,
    onOkClear,
    onOkConfirm,
    onOpenClear,
    onValuesChange,
  } = useContact()

  return (
    <Layout
      breadcrumbItems={BRCR_SCR992}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T689}
    >
      <Space isSpaceBottom>
        <Card isHeading={false}>
          <StyledHeading>{TEXT_T689}</StyledHeading>
          <Title titleLeft={TEXT_T671} />
          <Title titleLeft={TEXT_T672} titleRight={TEXT_T673} />
        </Card>
      </Space>
      <ContactForm
        form={form}
        goToHome={goToHome}
        isDisabledSubmit={isDisabledSubmit}
        isLoading={isLoading}
        onFinish={onFinish}
        onOpenClear={onOpenClear}
        onValuesChange={onValuesChange}
      />
      <ModalConfirm
        isOpen={isOpenClear}
        onCancel={onCancelClear}
        onOk={onOkClear}
      >
        {MESSAGE_C0041}
      </ModalConfirm>
      <ModalConfirm
        isOpen={isOpenConfirm}
        onCancel={onCancelConfirm}
        onOk={onOkConfirm}
      >
        {MESSAGE_C0042}
      </ModalConfirm>
    </Layout>
  )
}
