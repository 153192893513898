import { Translate } from "../../../../../../constant/Translate"

const {
  TEXT_T402,
  TEXT_T385,
  TEXT_T387,
  TEXT_T479,
  TEXT_T481,
  TEXT_T482,
  TEXT_T488,
  TEXT_T489,
} = Translate

const SFTS_LIST = [
  {
    text: TEXT_T402,
    value: "SFTS001",
  },
  {
    text: TEXT_T385,
    value: "SFTS002",
  },
  {
    text: TEXT_T387,
    value: "SFTS003",
  },
]

const FSST_LIST = [
  {
    text: TEXT_T479,
    value: "FSST001",
  },
  {
    text: TEXT_T481,
    value: "FSST002",
  },
  {
    text: TEXT_T482,
    value: "FSST003",
  },
  {
    text: TEXT_T488,
    value: "FSST004",
  },
  {
    text: TEXT_T489,
    value: "FSST005",
  },
]

export const Variables = {
  FSST_LIST,
  SFTS_LIST,
}
