import styled, { css } from "styled-components"
import { Regex } from "../../../../../../constant/Regex"
import { Button } from "../../../Button"
import { StyledModalFooterProps } from "../../models"

const { REGEX_IS_DESKTOP } = Regex

const StyledModalFooter = styled.div<StyledModalFooterProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.isCenter ? "center" : "space-between")};
  padding: 24px;
`

const StyledModalFooterBtn = styled(Button)`
  ${!REGEX_IS_DESKTOP &&
  css`
    min-width: 100%;
    &:last-child {
      margin-top: 15px;
    }
  `}
`

export const Styled = {
  StyledModalFooter,
  StyledModalFooterBtn,
}
