import { GeneralCode } from "../../../../../../../../constant/GeneralCode"
import { getDay } from "../../../../../../../../utils/getDate"
import { getValueByLanguage } from "../../../../../../../../utils/getValueByLanguage"
import {
  AttentionsCloneDataType,
  AttentionsCloneResType,
} from "../../../../../models/clone"

const { CTGR_OTHER } = GeneralCode

export const convertDataSource = (
  data: AttentionsCloneResType[],
): AttentionsCloneDataType[] =>
  data.map((item) => ({
    ...item,
    category:
      item.categoryId === CTGR_OTHER
        ? getValueByLanguage(
            `${item.categoryName}＿${item.categoryValue}`,
            `${item.categoryName}_${item.categoryValue}`,
          ).toString()
        : item.categoryName,
    implementationDateTimeFormat: getDay(item.implementationDateTime),
    severity: item.severity,
    severityName: item.severityName,
  }))
