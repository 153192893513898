/* eslint-disable max-lines */
import { FC } from "react"
import { Switch } from "react-router-dom"
import PrivateRoute from "../../../../app/routing/privateRoute"
import { Routers } from "../../../../constant/Routers"
import { SchedulesListPage } from "../../../../app/pages/schedules/list"
import { SchedulesAddPage } from "../../../../app/pages/schedules/add"
import { BatchUpdatePage } from "../../../../app/pages/schedules/batchUpdate"
import { SchedulesAddSuccessPage } from "../../../../app/pages/schedules/addSuccess"
import { SchedulesClonePage } from "../../../../app/pages/schedules/clone"
import { SchedulesDeleteSuccessPage } from "../../../../app/pages/schedules/deleteSuccess"
import { SchedulesEditPage } from "../../../../app/pages/schedules/edit"
import { SchedulesDetailPage } from "../../../../app/pages/schedules/detail"
import { SchedulesEditSuccessPage } from "../../../../app/pages/schedules/editSuccess"
import { SchedulesBatchImportPage } from "../../../../app/pages/schedules/batchImport"
import { SchedulesBatchDeletePage } from "../../../../app/pages/schedules/batchDelete"
import { SchedulesConfirmDeletePage } from "../../../../app/pages/schedules/confirmDelete"
import { SchedulesCollectiveBatchImportPage } from "../../../../app/pages/schedules/batchImport/collective"
import { SchedulesCollectiveBatchUpdatePage } from "../../../../app/pages/schedules/batchUpdate/collective"
import { SchedulesCollectiveBatchDeletePage } from "../../../../app/pages/schedules/batchDelete/collective"
import { SchedulesListAttentionsPage } from "../../../../app/pages/schedules/list/attentions"
import { SchedulesSafetiesListPage } from "../../../../app/pages/schedules/list/safeties"
import { SchedulesTravelersListPage } from "../../../../app/pages/schedules/list/travelers"
import { SchedulesAddUserPage } from "../../../../app/pages/schedules/add/userAdd"
import { SchedulesEditUserPage } from "../../../../app/pages/schedules/edit/user"
import { SchedulesUserSearchListPage } from "../../../../app/pages/schedules/list/userSearch"

const {
  SCR210_ATTENTIONS,
  SCR210_SAFETIES,
  SCR210_TRAVELERS,
  SCR210_USER_SEARCH,
  SCR210,
  SCR211,
  SCR212,
  SCR212_USER,
  SCR213,
  SCR214,
  SCR214_USER,
  SCR215,
  SCR216,
  SCR217,
  SCR220,
  SCR220_USER,
  SCR221,
  SCR221_USER,
  SCR222,
  SCR222_USER,
  SCR960,
} = Routers

export const SchedulesRoute: FC = () => (
  <Switch>
    <PrivateRoute
      component={SchedulesListAttentionsPage}
      exact
      path={SCR210_ATTENTIONS}
    />
    <PrivateRoute
      component={SchedulesSafetiesListPage}
      exact
      path={SCR210_SAFETIES}
    />
    <PrivateRoute
      component={SchedulesTravelersListPage}
      exact
      path={SCR210_TRAVELERS}
    />
    <PrivateRoute component={SchedulesListPage} exact path={SCR210} />
    <PrivateRoute
      component={SchedulesUserSearchListPage}
      exact
      path={SCR210_USER_SEARCH}
    />
    <PrivateRoute component={SchedulesDetailPage} exact path={SCR211} />
    <PrivateRoute component={SchedulesAddPage} exact path={SCR212} />
    <PrivateRoute component={SchedulesAddUserPage} exact path={SCR212_USER} />
    <PrivateRoute component={SchedulesAddSuccessPage} exact path={SCR213} />
    <PrivateRoute component={SchedulesEditPage} exact path={SCR214} />
    <PrivateRoute component={SchedulesEditUserPage} exact path={SCR214_USER} />
    <PrivateRoute component={SchedulesEditSuccessPage} exact path={SCR215} />
    <PrivateRoute component={SchedulesConfirmDeletePage} exact path={SCR216} />
    <PrivateRoute component={SchedulesDeleteSuccessPage} exact path={SCR217} />
    <PrivateRoute component={SchedulesBatchImportPage} exact path={SCR220} />
    <PrivateRoute
      component={SchedulesCollectiveBatchImportPage}
      exact
      path={SCR220_USER}
    />
    <PrivateRoute component={BatchUpdatePage} exact path={SCR221} />
    <PrivateRoute
      component={SchedulesCollectiveBatchUpdatePage}
      exact
      path={SCR221_USER}
    />
    <PrivateRoute
      component={SchedulesCollectiveBatchDeletePage}
      exact
      path={SCR222}
    />
    <PrivateRoute
      component={SchedulesBatchDeletePage}
      exact
      path={SCR222_USER}
    />
    <PrivateRoute component={SchedulesClonePage} exact path={SCR960} />
  </Switch>
)
