import { UserInformationType } from "./models"

export const getUserInformation = () => {
  const ls = localStorage.getItem("userInformation")
  if (ls) {
    const userInformation: UserInformationType = JSON.parse(ls)

    return {
      ...userInformation,
    }
  }
}
