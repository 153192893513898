import { Breadcrumbs } from "../../../../../../../../constant/Breadcrumbs"
import { Routers } from "../../../../../../../../constant/Routers"

const { BRCR_SCR313, BRCR_SCR313_FROM_SCR312, BRCR_SCR313_FROM_SCR961 } =
  Breadcrumbs
const { SCR312, SCR961 } = Routers

export const getBreadcrumbItems = (sourceURL?: string) => {
  if (sourceURL === SCR312) return BRCR_SCR313_FROM_SCR312

  if (sourceURL === SCR961) return BRCR_SCR313_FROM_SCR961

  return BRCR_SCR313
}
