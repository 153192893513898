import "./style/index.scss"
import { Input } from "antd"
import { FC } from "react"
import { ItemInputProps } from "./models"
import { FormItem } from ".."
import { formatter } from "./constants"

export const ItemInput: FC<ItemInputProps> = (props) => {
  const {
    addonAfter,
    addonBefore,
    className,
    isBgBlue,
    isDisabled,
    isHidden,
    isHiddenLabel,
    isReadOnly,
    label,
    maxLength,
    name,
    onBlur,
    onFocus,
    placeholder,
    required,
    rules,
    tooltip,
    type,
    ...res
  } = props

  return (
    <FormItem
      className={className}
      isBgBlue={isBgBlue}
      isHidden={isHidden}
      isHiddenLabel={isHiddenLabel}
      label={label}
      name={name}
      required={required}
      rules={rules}
      tooltip={tooltip}
      {...res}
    >
      <Input
        addonAfter={addonAfter}
        addonBefore={addonBefore}
        disabled={isDisabled}
        maxLength={maxLength}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={placeholder}
        readOnly={isReadOnly}
        showCount={
          !!maxLength && {
            formatter,
          }
        }
        type={type}
      />
    </FormItem>
  )
}
