import { ItemRadio } from "../../../../../../../_webui/layout/components/Form/Item/Radio"
import { ItemTextArea } from "../../../../../../../_webui/layout/components/Form/Item/TextArea"
import { GridItemType } from "../../../../../../../_webui/layout/components/Grid/models"
import { Tooltip } from "../../../../../../../_webui/layout/components/Tooltip"
import { UseSafetiesInfoGridItemProps } from "../../../../models/info"
import { getLang } from "../../../../../../../utils/translate"

export const useGridConfig = (props: UseSafetiesInfoGridItemProps) => {
  const { lang, safetiesInfo } = props

  const SFTS_LIST = [
    {
      text: getLang(lang, "TEXT_T025"),
      value: "SFTS001",
    },
    {
      text: getLang(lang, "TEXT_T026"),
      value: "SFTS002",
    },
    {
      text: getLang(lang, "TEXT_T027"),
      value: "SFTS003",
    },
  ]

  const FSST_LIST = [
    {
      text: getLang(lang, "TEXT_T028"),
      value: "FSST001",
    },
    {
      text: getLang(lang, "TEXT_T029"),
      value: "FSST002",
    },
    {
      text: getLang(lang, "TEXT_T030"),
      value: "FSST003",
    },
    {
      text: getLang(lang, "TEXT_T031"),
      value: "FSST004",
    },
    {
      text: getLang(lang, "TEXT_T032"),
      value: "FSST005",
    },
  ]

  const gridItems: GridItemType[] = [
    {
      key: getLang(lang, "TEXT_T019"),
      text:
        lang === "jp"
          ? safetiesInfo?.travelerNameJp
          : safetiesInfo?.travelerNameEn,
    },
    {
      key: getLang(lang, "TEXT_T023"),
      text:
        lang === "jp"
          ? safetiesInfo?.dispatchTypeDetailNameJp
          : safetiesInfo?.dispatchTypeDetailNameEn,
    },
    {
      key: getLang(lang, "TEXT_T020"),
      text: <ItemRadio name="safetyStatus" radioItems={SFTS_LIST} />,
    },
    {
      key: (
        <Tooltip title={getLang(lang, "TEXT_T018")}>
          {getLang(lang, "TEXT_T021")}
        </Tooltip>
      ),
      text: <ItemRadio name="familySafetyStatus" radioItems={FSST_LIST} />,
    },
    {
      key: <label htmlFor="message">{getLang(lang, "TEXT_T022")}</label>,
      text: (
        <div>
          <ItemTextArea maxLength={500} name="message" rows={7} />
        </div>
      ),
    },
  ]

  return { gridItems }
}
