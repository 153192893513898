/* eslint-disable max-lines */
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { TableCurrentDataSource } from "antd/lib/table/interface"
import { useEffect } from "react"
import { CheckboxAll } from "../../../../../../../_webui/layout/components/RowSelection/CheckboxAll"
import { CheckboxItem } from "../../../../../../../_webui/layout/components/RowSelection/CheckboxItem"
import { TableRowSelection } from "../../../../../../../_webui/layout/components/Table/models"
import {
  TravelersRequestDeleteResType,
  UseRequestDeleteRowSelectionProps,
} from "../../../../models/requestDelete"
import { useVariablesRowSelection } from "../useVariablesRowSelection"

export const useRowSelection = (props: UseRequestDeleteRowSelectionProps) => {
  const { activeKey, dataGridItems, dataSourceLength } = props

  const {
    dataCurrent,
    dataGridItemsCurrent,
    isCheckAll,
    onChangePage,
    perItem,
    perItemEnd,
    perItemStart,
    selectedRowKeys,
    setDataCurrent,
    setDataGridItemsCurrent,
    setIsCheckAll,
    setSelectedRowKeys,
  } = useVariablesRowSelection(dataSourceLength)

  const onSelectChange = () => {
    if (dataCurrent.length > 0 && selectedRowKeys.length === dataCurrent.length)
      setIsCheckAll(true)
    else setIsCheckAll(false)
  }

  const onChangeCheckAll = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    setIsCheckAll(checked)
    if (checked) {
      const _selectedRowKeys = dataCurrent.map((item) => item.travelerId)
      setSelectedRowKeys(_selectedRowKeys)
    } else {
      setSelectedRowKeys([])
    }
  }

  const onChangeCheckItem = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    const value = event.target.value
    if (checked) {
      setSelectedRowKeys([...selectedRowKeys, value])
    } else {
      const _selectedRowKeys = selectedRowKeys.filter((item) => item !== value)
      setSelectedRowKeys(_selectedRowKeys)
    }
  }

  const rowSelection: TableRowSelection<TravelersRequestDeleteResType> = {
    columnTitle: (
      <CheckboxAll
        checked={isCheckAll}
        isDisabled={
          dataGridItemsCurrent && !dataGridItemsCurrent[activeKey].length
        }
        onChange={onChangeCheckAll}
      />
    ),
    renderCell: (_, record) => (
      <CheckboxItem
        checked={selectedRowKeys.includes(record.travelerId)}
        onChange={onChangeCheckItem}
        value={record.travelerId}
      />
    ),
    selectedRowKeys: selectedRowKeys,
  }

  const onChange = (page: number, pageSize: number) => {
    onChangePage(page, pageSize)
    setIsCheckAll(false)
    setSelectedRowKeys([])
  }

  const onChangeTable = (
    extra: TableCurrentDataSource<TravelersRequestDeleteResType>,
  ) => {
    if (dataGridItemsCurrent)
      setDataGridItemsCurrent({
        ...dataGridItemsCurrent,
        [activeKey]: extra.currentDataSource,
      })
    setIsCheckAll(false)
    setSelectedRowKeys([])
  }

  useEffect(() => {
    if (dataGridItemsCurrent) {
      const _dataCurrent = dataGridItemsCurrent[activeKey].slice(
        perItemStart,
        perItemEnd,
      )
      setDataCurrent(_dataCurrent)
    }
  }, [
    dataGridItemsCurrent,
    activeKey,
    setDataCurrent,
    perItemStart,
    perItemEnd,
  ])

  useEffect(() => {
    if (dataGridItems) setDataGridItemsCurrent(dataGridItems)
  }, [dataGridItems])

  useEffect(() => {
    if (dataGridItemsCurrent && dataGridItemsCurrent[activeKey]?.length)
      onSelectChange()
  }, [selectedRowKeys])

  return {
    onChange,
    onChangeTable,
    perItem,
    rowSelection,
    selectedRowKeys,
    setIsCheckAll,
    setSelectedRowKeys,
  }
}
