import { HeaderUser } from "./User"
import { useHeader } from "./modules/useHeader"
import { FC } from "react"
import { HeaderProps } from "./models"
import { Images } from "../../../../constant/Images"
import { getStyle } from "../../../../utils/getStyle"
import { Styled } from "./styled"
import { Regex } from "../../../../constant/Regex"
import { Menu } from "../Menu"
import { ModalConfirm } from "../Modal/Confirm"
import { Translate } from "../../../../constant/Translate"

const { IMAGE_LOGO } = Images
const {
  StyledHeader,
  StyledHeaderAction,
  StyledHeaderImage,
  StyledHeaderLogo,
  StyledHeaderMenu,
  StyledHeaderMenuIcon,
  StyledHeaderMenuIconBar,
  StyledHeaderMenuSideBar,
} = Styled
const { REGEX_IS_DESKTOP } = Regex
const { MESSAGE_C0022 } = Translate

export const Header: FC<HeaderProps> = (props) => {
  const { isHeaderRightNone } = props
  const {
    handleLogout,
    handleToggleMenu,
    isOpen,
    isToggleMenu,
    onCancel,
    onOk,
  } = useHeader()

  return (
    <StyledHeader className={getStyle(isToggleMenu, " active")}>
      <StyledHeaderLogo className="header-logo">
        <StyledHeaderImage className="header-logo-img" src={IMAGE_LOGO} />
      </StyledHeaderLogo>
      {isHeaderRightNone ? null : REGEX_IS_DESKTOP ? (
        <StyledHeaderAction>
          <HeaderUser handleLogout={handleLogout} />
        </StyledHeaderAction>
      ) : (
        <StyledHeaderMenu className={getStyle(isToggleMenu, " active")}>
          <StyledHeaderMenuIcon
            className="header-menu-icon"
            onClick={handleToggleMenu}
          >
            <StyledHeaderMenuIconBar className="header-menu-icon-bar-1"></StyledHeaderMenuIconBar>
            <StyledHeaderMenuIconBar className="header-menu-icon-bar-2"></StyledHeaderMenuIconBar>
            <StyledHeaderMenuIconBar className="header-menu-icon-bar-3"></StyledHeaderMenuIconBar>
          </StyledHeaderMenuIcon>
          <StyledHeaderMenuSideBar className="header-menu-sidebar">
            <Menu
              handleToggleMenu={handleToggleMenu}
              handleLogout={handleLogout}
            />
          </StyledHeaderMenuSideBar>
        </StyledHeaderMenu>
      )}
      <ModalConfirm isOpen={isOpen} onCancel={onCancel} onOk={onOk}>
        {MESSAGE_C0022}
      </ModalConfirm>
    </StyledHeader>
  )
}
