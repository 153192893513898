import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const { isRoleSubAdmin, isTravelerRegister } = useAuthority()

  return {
    isRoleSubAdmin,
    isTravelerRegister,
  }
}
