import { FC, Fragment } from "react"
import { SchedulesAddModalProps } from "./models"
import { ModalConfirm } from "../../../../../../../_webui/layout/components/Modal/Confirm"
import { Popup } from "../../../../../../../_webui/layout/components/Modal/Popup"
import { Translate } from "../../../../../../../constant/Translate"

const {
  MESSAGE_C0020,
  MESSAGE_C0021,
  MESSAGE_C0053,
  MESSAGE_C0054,
  MESSAGE_W0018,
  TEXT_T229,
} = Translate

export const SchedulesAddModal: FC<SchedulesAddModalProps> = (props) => {
  const {
    isOpen,
    isOpenPopup,
    isOpenRoundTrip,
    isOpenScheduleDraft,
    isSchedulesAdd,
    isSchedulesEdit,
    onCancel,
    onCancelPopup,
    onCancelRoundTrip,
    onCancelScheduleDraft,
    onOk,
    onOkRoundTrip,
    onOkScheduleDraft,
  } = props

  return (
    <Fragment>
      <ModalConfirm isOpen={isOpen} onCancel={onCancel} onOk={onOk}>
        {isSchedulesAdd ? MESSAGE_C0020 : isSchedulesEdit ? MESSAGE_C0021 : ""}
      </ModalConfirm>
      <ModalConfirm
        isOpen={isOpenScheduleDraft}
        onCancel={onCancelScheduleDraft}
        onOk={onOkScheduleDraft}
      >
        {MESSAGE_C0054}
      </ModalConfirm>
      <ModalConfirm
        cancelCustomText={TEXT_T229}
        cancelText=""
        isOpen={isOpenRoundTrip}
        onOk={onOkRoundTrip}
        onCancelCustom={onCancelRoundTrip}
      >
        {MESSAGE_C0053}
      </ModalConfirm>
      <Popup
        okText="OK"
        cancelText=""
        isOpen={isOpenPopup}
        onOk={onCancelPopup}
      >
        {MESSAGE_W0018}
      </Popup>
    </Fragment>
  )
}
