/* eslint-disable max-lines */
import { FC, Fragment } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { Collapse } from "../../../../../../_webui/layout/components/Collapse"
import { Grid } from "../../../../../../_webui/layout/components/Grid"
import { SchedulesAddSuccessDetail } from "./Detail"
import { SchedulesAddSuccessLodging } from "./Lodging"
import { SchedulesAddSuccessCollapseProps } from "./models"
import { SchedulesAddSuccessStay } from "./Stay"

const {
  TEXT_T520,
  TEXT_T526,
  TEXT_T528,
  TEXT_T546,
  TEXT_T549,
  TEXT_T550,
  TEXT_T551,
  TEXT_T669,
  TEXT_T699,
} = Translate

export const ScheduleAddSuccessCollapse: FC<
  SchedulesAddSuccessCollapseProps
> = (props) => {
  const {
    assignmentGridItem,
    confirmGridItem,
    detailsColumnsFirst,
    detailsGridItem,
    detailsTabsItems,
    insuranceGridItem,
    isVisibleOtherRemarks,
    lodgingsColumns,
    lodgingsTabsItems,
    otherGridItem,
    personalGridItem,
    scheduleDetails,
    scheduleLodgings,
    scheduleStays,
    staysColumns,
    staysTabsItems,
  } = props

  const panelItems = [
    {
      content: <Grid gridItems={personalGridItem} />,
      header: TEXT_T520,
      key: "1",
    },
    {
      content: (
        <Fragment>
          <Grid gridItems={confirmGridItem} />
          <div className="ant-form-item-extra">{TEXT_T699}</div>
        </Fragment>
      ),
      header: TEXT_T669,
      key: "2",
    },
    {
      content: <Grid gridItems={assignmentGridItem} />,
      header: TEXT_T551,
      key: "3",
    },
    {
      content: <Grid gridItems={insuranceGridItem} />,
      header: TEXT_T526,
      key: "4",
    },
    {
      content: (
        <SchedulesAddSuccessDetail
          detailsColumnsFirst={detailsColumnsFirst}
          detailsGridItem={detailsGridItem}
          detailsTabsItems={detailsTabsItems}
          scheduleDetails={scheduleDetails}
        />
      ),
      header: TEXT_T546,
      key: "5",
    },
    {
      content: (
        <SchedulesAddSuccessLodging
          scheduleLodgings={scheduleLodgings}
          lodgingsColumns={lodgingsColumns}
          lodgingsTabsItems={lodgingsTabsItems}
        />
      ),
      header: TEXT_T549,
      key: "6",
    },
    {
      content: (
        <SchedulesAddSuccessStay
          scheduleStays={scheduleStays}
          staysColumns={staysColumns}
          staysTabsItems={staysTabsItems}
        />
      ),
      header: TEXT_T550,
      key: "7",
    },
  ]

  if (isVisibleOtherRemarks)
    panelItems.push({
      content: <Grid gridItems={otherGridItem} />,
      header: TEXT_T528,
      key: "8",
    })

  return (
    <Collapse
      defaultActiveKey={["1", "2", "3", "4", "5", "6", "7", "8"]}
      panelItems={panelItems}
    />
  )
}
