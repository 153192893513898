/* eslint-disable max-lines */
import { Translate } from "../../Translate"

const {
  TEXT_T119, // 作成・発信
  TEXT_T190, // 渡航予定情報の一覧
  TEXT_T307, // 注意喚起の内容確認
  TEXT_T322, // 注意喚起の内容作成・変更
  TEXT_T326, // 注意喚起の発信完了
  TEXT_T331, // 注意喚起の一覧
  TEXT_T334, // 応答登録
  TEXT_T335, // 応答履歴
  TEXT_T337, // 注意喚起の詳細
  TEXT_T355, // 注意喚起の応答
  TEXT_T469, // 注意喚起
  TEXT_T519, // ホーム
  TEXT_T570, // 注意喚起の表示
} = Translate
const BRCR_SCR312 = [TEXT_T519, TEXT_T469, TEXT_T119, TEXT_T190, TEXT_T307]
const BRCR_SCR313 = [TEXT_T322]
const BRCR_SCR313_FROM_SCR312 = [
  TEXT_T519,
  TEXT_T469,
  TEXT_T119,
  TEXT_T190,
  TEXT_T307,
  TEXT_T322,
]
const BRCR_SCR313_FROM_SCR961 = [
  TEXT_T519,
  TEXT_T469,
  TEXT_T119,
  TEXT_T190,
  TEXT_T322,
]
const BRCR_SCR315 = [
  TEXT_T519,
  TEXT_T469,
  TEXT_T119,
  TEXT_T190,
  TEXT_T307,
  TEXT_T326,
]
const BRCR_SCR316 = [TEXT_T519, TEXT_T469, TEXT_T334, TEXT_T331]
const BRCR_SCR316_HISTORY = [TEXT_T519, TEXT_T469, TEXT_T335, TEXT_T331]
const BRCR_SCR317 = [TEXT_T337]
const BRCR_SCR317_FROM_SCR316 = [
  TEXT_T519,
  TEXT_T469,
  TEXT_T334,
  TEXT_T331,
  TEXT_T337,
]
const BRCR_SCR317_FROM_SCR316_HISTORY = [
  TEXT_T519,
  TEXT_T469,
  TEXT_T335,
  TEXT_T331,
  TEXT_T337,
]
const BRCR_SCR318 = [TEXT_T570]
const BRCR_SCR318_FROM_SCR316 = [
  TEXT_T519,
  TEXT_T469,
  TEXT_T334,
  TEXT_T331,
  TEXT_T337,
  TEXT_T570,
]
const BRCR_SCR318_FROM_SCR317_HISTORY = [
  TEXT_T519,
  TEXT_T469,
  TEXT_T335,
  TEXT_T331,
  TEXT_T337,
  TEXT_T570,
]
const BRCR_SCR318_FROM_SCR316_HISTORY = [
  TEXT_T519,
  TEXT_T469,
  TEXT_T335,
  TEXT_T331,
  TEXT_T570,
]

const BRCR_SCR318_FROM_SCR316_USER = [
  TEXT_T519,
  TEXT_T469,
  TEXT_T334,
  TEXT_T331,
  TEXT_T570,
]

const BRCR_SCR319 = [TEXT_T355]
const BRCR_SCR319_FROM_SCR316 = [
  TEXT_T519,
  TEXT_T469,
  TEXT_T334,
  TEXT_T331,
  TEXT_T337,
  TEXT_T570,
  TEXT_T355,
]
const BRCR_SCR319_FROM_SCR316_HISTORY = [
  TEXT_T519,
  TEXT_T469,
  TEXT_T335,
  TEXT_T331,
  TEXT_T337,
  TEXT_T570,
  TEXT_T355,
]

export const BreadcrumbsAttentions = {
  BRCR_SCR312,
  BRCR_SCR313,
  BRCR_SCR313_FROM_SCR312,
  BRCR_SCR313_FROM_SCR961,
  BRCR_SCR315,
  BRCR_SCR316,
  BRCR_SCR316_HISTORY,
  BRCR_SCR317,
  BRCR_SCR317_FROM_SCR316,
  BRCR_SCR317_FROM_SCR316_HISTORY,
  BRCR_SCR318,
  BRCR_SCR318_FROM_SCR316,
  BRCR_SCR318_FROM_SCR316_HISTORY,
  BRCR_SCR318_FROM_SCR316_USER,
  BRCR_SCR318_FROM_SCR317_HISTORY,
  BRCR_SCR319,
  BRCR_SCR319_FROM_SCR316,
  BRCR_SCR319_FROM_SCR316_HISTORY,
}
