import { ButtonGroup } from "../../../../../_webui/layout/components/Button/Group"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { ModalConfirm } from "../../../../../_webui/layout/components/Modal/Confirm"
import { Table } from "../../../../../_webui/layout/components/Table"
import { ErrorList } from "../../../../../_webui/layout/components/Typography/ErrorList"
import { Title } from "../../../../../_webui/layout/components/Typography/Title"
import { Translate } from "../../../../../constant/Translate"
import { useConfirmDelete } from "./modules/useConfirmDelete"

const { TEXT_T012, TEXT_T189 } = Translate

export const TravelersConfirmDelete = () => {
  const {
    breadcrumbItems,
    columns,
    confirmDeleteTitle,
    confirmDeleteTitleLeft,
    dataSource,
    errorHeading,
    errorItems,
    goToTravelersList,
    isHiddenBtnCancel,
    isHiddenBtnSubmit,
    isLoading,
    isLoadingLayout,
    isOpen,
    modalConfirmMessage,
    onCancel,
    onOk,
    onOpen,
  } = useConfirmDelete()

  return (
    <Layout
      breadcrumbItems={breadcrumbItems}
      isLoadingLayout={isLoadingLayout}
      title={confirmDeleteTitle}
    >
      <Card heading={confirmDeleteTitle} isFullHeight isLoading={isLoading}>
        <Title titleLeft={confirmDeleteTitleLeft} />
        <ErrorList errorItems={errorItems} heading={errorHeading} />
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          rowKey="travelerId"
        />
        <ButtonGroup
          buttonItems={[
            {
              isHidden: !isHiddenBtnCancel,
              onClick: goToTravelersList,
              order: 2,
              text: TEXT_T012,
              type: "ghost",
            },
            {
              isHidden: !isHiddenBtnSubmit,
              // ユーザが渡航者情報削除依頼を作成する旨の確認ダイアログボックスを作成する。
              onClick: onOpen,
              order: 1,
              text: TEXT_T189,
              type: "primary",
            },
          ]}
        />
      </Card>
      {modalConfirmMessage && (
        <ModalConfirm isOpen={isOpen} onCancel={onCancel} onOk={onOk}>
          {modalConfirmMessage}
        </ModalConfirm>
      )}
    </Layout>
  )
}
