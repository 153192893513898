import { apiService } from "../../../../infrastructure/apiService"
import { Environment } from "../../../../constant/Environment"
import { Endpoint } from "../../../../constant/Endpoint"
import { UploadFileType } from "../model/filesUpload"
import { Key } from "react"

const { BATCH_ENDPOINT } = Environment
const { FNC9_Download_Log, FNC9_Get_Batch_Logs, FNC9_Run_Batch } = Endpoint

export const batchApi = {
  downloadLog: (idBatchLogList: Key[]) =>
    apiService({
      data: idBatchLogList,
      method: "POST",
      url: `${BATCH_ENDPOINT}${FNC9_Download_Log}`,
    }),
  getBatchLogs: () =>
    apiService({
      method: "GET",
      url: `${BATCH_ENDPOINT}${FNC9_Get_Batch_Logs}`,
    }),
  runBatch: (uploadFiles: UploadFileType[]) =>
    apiService({
      data: uploadFiles,
      method: "POST",
      url: `${BATCH_ENDPOINT}${FNC9_Run_Batch}`,
    }),
}
