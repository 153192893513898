import { useEffect } from "react"
import { GeneralCode } from "../../../../../../../constant/GeneralCode"
import { Routers } from "../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { generalMastersApi } from "../../../../../../../infrastructure/handlers/generalMasters"
import { GeneralMastersResType } from "../../../../../../../infrastructure/handlers/generalMasters/models"
import { useVariables } from "../useVariables"
import { convertDataGeneralMasters } from "./convertDataGeneralMasters"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { UsePrivacyPolicyType } from "../../../../models/privacyPolicy"
import { backToLogin } from "../../../../../../../utils/backToLogin"

const { TRMCNT } = GeneralCode
const { SCR920, SCR973 } = Routers

export const usePrivacyPolicy = (): UsePrivacyPolicyType => {
  const {
    acquisitionInfo,
    isCommonRefer,
    isLoadingLayout,
    push,
    setAcquisitionInfo,
    setIsLoadingLayout,
  } = useVariables()

  // 「SCR920_ホーム画面」へ遷移する。
  const goToHome = () => {
    push(SCR920)
  }

  const getGeneralMaster = () => {
    generalMastersApi
      .getGeneralMaster(TRMCNT)
      .then((res: GeneralMastersResType[]) => {
        setIsLoadingLayout(false)
        const _acquisitionInfo = convertDataGeneralMasters(res)
        setAcquisitionInfo(_acquisitionInfo)
        setIsLoadingLayout(false)
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const stateError = pushStateError(error)
        push({ pathname: SCR973, state: stateError })
      })
  }

  useEffect(() => {
    // ユーザの画面アクセス許可チェックを処理する。
    if (!isCommonRefer) {
      // 「SCR973_エラー情報の表示画面」に遷移する。
      const { pathname, state } = backToLogin()
      push({ pathname, state })
    } else getGeneralMaster()
  }, [])

  return {
    acquisitionInfo,
    goToHome,
    isLoadingLayout,
  }
}
