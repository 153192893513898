import { AttentionsRouters } from "./Attentions"
import { CommonRouters } from "./Common"
import { SafetiesRouters } from "./Safeties"
import { SchedulesRouters } from "./Schedules"
import { TravelersRouters } from "./Travelers"

export const Routers = {
  ...AttentionsRouters,
  ...CommonRouters,
  ...SafetiesRouters,
  ...SchedulesRouters,
  ...TravelersRouters,
}
