const SCR112 = "/travelers/add"
const SCR113 = "/travelers/add-success/:id"
const SCR114 = "/travelers"
const SCR114_USER = "/travelers/user"
const SCR114_USER_SEARCH = "/travelers/user-search"
const SCR115 = "/travelers/detail/:id"
const SCR115_USER = "/travelers/user/detail/:id"
const SCR116 = "/travelers/edit/:id"
const SCR117 = "/travelers/edit-success/:id"
const SCR119 = "/travelers/delete-success"
const SCR120 = "/travelers/search"
const SCR122 = "/travelers/batch-import"
const SCR123 = "/travelers/batch-update"
const SCR124 = "/travelers/batch-delete"
const SCR125 = "/travelers/request-delete"
const SCR126 = "/travelers/confirm-delete"

export const TravelersRouters = {
  SCR112,
  SCR113,
  SCR114,
  SCR114_USER,
  SCR114_USER_SEARCH,
  SCR115,
  SCR115_USER,
  SCR116,
  SCR117,
  SCR119,
  SCR120,
  SCR122,
  SCR123,
  SCR124,
  SCR125,
  SCR126,
}
