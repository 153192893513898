import { FC } from "react"
import { ButtonGroup } from "../../../../../_webui/layout/components/Button/Group"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { Table } from "../../../../../_webui/layout/components/Table"
import { Title } from "../../../../../_webui/layout/components/Typography/Title"
import { TotalRecords } from "../../../../../_webui/layout/components/Typography/TotalRecords"
import { Breadcrumbs } from "../../../../../constant/Breadcrumbs"
import { Translate } from "../../../../../constant/Translate"
import { useSafetiesClone } from "./modules/useClone"

const { TEXT_T072, TEXT_T310, TEXT_T495, TEXT_T496, TEXT_T532 } = Translate
const { BRCR_SCR962 } = Breadcrumbs

export const SafetiesClone: FC = () => {
  const {
    columns,
    dataSource,
    isDisabledBtnCopy,
    isHiddenBtnBack,
    isHiddenBtnCopy,
    onChange,
    onChangeTable,
    onClickBack,
    onClickCopy,
    perItem,
    rowSelection,
  } = useSafetiesClone()

  return (
    <Layout breadcrumbItems={BRCR_SCR962} title={TEXT_T495}>
      <Card heading={TEXT_T495} isFullHeight>
        <Title titleLeft={TEXT_T496} />
        <TotalRecords
          titleLeft={TEXT_T532}
          perItem={perItem}
          total={dataSource?.length}
        />
        <Table
          columns={columns}
          dataSource={dataSource}
          onChange={onChange}
          onChangeTable={onChangeTable}
          rowKey="safetyId"
          rowSelection={rowSelection}
        />
        <ButtonGroup
          buttonItems={[
            {
              isAbsolute: true,
              isHidden: !isHiddenBtnBack,
              isSmall: true,
              onClick: onClickBack,
              order: 2,
              text: TEXT_T072,
              type: "ghost",
            },
            {
              isDisabled: !isDisabledBtnCopy,
              isHidden: !isHiddenBtnCopy,
              onClick: onClickCopy,
              order: 1,
              text: TEXT_T310,
              type: "primary",
            },
          ]}
        />
      </Card>
    </Layout>
  )
}
