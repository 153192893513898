import { useHistory, useLocation, useParams } from "react-router-dom"
import { getUserInformation } from "../../../../../../../utils/getUserInformation"
import { useModal } from "../../../../../../../utils/hook/useModal"
import { UseParamsType } from "../../../../../../../utils/useParams"
import { TravelersDetailStateType } from "../../../../models/detail"
import { useApi } from "../useApi"
import { useRouters } from "../useRouters"
import { useTableConfig } from "../useTableConfig"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { getBreadcrumbItems } from "./getBreadcrumbItems"

export const useVariables = () => {
  const { id } = useParams<UseParamsType>()
  const { push } = useHistory()
  const { state } = useLocation<TravelersDetailStateType>()
  const userInformation = getUserInformation()
  const {
    isHiddenBtnBack,
    isHiddenBtnDelete,
    isHiddenBtnEdit,
    isHiddenBtnRequestDelete,
    isHiddenBtnSchedules,
    isNotAccessScreen,
    isRoleAdmin,
    isRoleSubAdmin,
  } = useVariablesAuthority(
    id,
    state?.isFromSCR114UserSearch,
    !state?.isFromSCR211,
    state?.isSubBumon,
    userInformation?.travelerId,
  )
  const { columns } = useTableConfig()
  const { isOpen, onCancel, onOpen } = useModal()
  const {
    fullNameRomaji,
    getTravelerDetails,
    isLoading,
    isLoadingLayout,
    isSystemIdentifier,
    onOk,
    travelerInfo,
  } = useApi({ isRoleAdmin, isRoleSubAdmin })
  const { goToSchedulesList, goToTravelersEdit, goBack } = useRouters({
    fullNameRomaji,
    isFromSCR114User: state?.isFromSCR114User,
  })
  const breadcrumbItems = getBreadcrumbItems(
    !!state?.isFromSCR114User,
    !!state?.isFromSCR114UserSearch,
  )

  return {
    breadcrumbItems,
    columns,
    getTravelerDetails,
    goBack,
    goToSchedulesList,
    goToTravelersEdit,
    id,
    isHiddenBtnBack,
    isHiddenBtnDelete,
    isHiddenBtnEdit,
    isHiddenBtnRequestDelete,
    isHiddenBtnSchedules,
    isLoading,
    isLoadingLayout,
    isNotAccessScreen,
    isOpen,
    isRoleAdmin,
    isRoleSubAdmin,
    isSystemIdentifier,
    onCancel,
    onOk,
    onOpen,
    push,
    travelerInfo,
  }
}
