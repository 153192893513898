import { FC, Fragment } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { Button } from "../../../../../../_webui/layout/components/Button"
import { Table } from "../../../../../../_webui/layout/components/Table"
import { Title } from "../../../../../../_webui/layout/components/Typography/Title"
import { SafetiesDataType, SafetiesTableProps } from "../../../models/safety"
import { Styled } from "./styled"

const { StyledTitle } = Styled
const { TEXT_T666, TEXT_T318, TEXT_T319 } = Translate

export const SafetiesTable: FC<SafetiesTableProps<SafetiesDataType>> = (
  props,
) => {
  const {
    columns,
    goToSchedulesList,
    isHiddenScheduleListBtn,
    isLoading,
    travelerInfoList,
  } = props

  return (
    <Fragment>
      <Title titleLeft={TEXT_T666} />
      <StyledTitle
        type="danger"
        titleLeft={TEXT_T318}
        isTitleRightFullWidth
        titleRight={
          <Button
            isFullWidth
            isHidden={isHiddenScheduleListBtn}
            onClick={goToSchedulesList}
            text={TEXT_T319}
            type="default"
          />
        }
      />
      <Table
        columns={columns}
        dataSource={travelerInfoList}
        isLoading={isLoading}
        rowKey="travelScheduleId"
        pagination={false}
      />
    </Fragment>
  )
}
