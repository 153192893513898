export const scrollToCollapseItem = (index: number) => {
  const elmCollapseItem = document.querySelectorAll(".ant-collapse-item")[
    index
  ] as HTMLDivElement

  if (elmCollapseItem)
    window.scrollTo({
      behavior: "smooth",
      top: elmCollapseItem.offsetTop + 110,
    })
}
