import { OptionItemType } from "../../_webui/layout/components/Select/models"
import { GeneralCode } from "../GeneralCode"

const { OTHER } = GeneralCode

const OTHER_CITY_SELECT_ITEMS: OptionItemType = {
  text: "Other",
  value: OTHER,
}

export const SelectItems = {
  OTHER_CITY_SELECT_ITEMS,
}
