import { FC } from "react"
import { useLang } from "../OjicaI18n"
import { IntlProvider } from "react-intl"
import "@formatjs/intl-relativetimeformat/polyfill"
import "@formatjs/intl-relativetimeformat/locale-data/en"
import "@formatjs/intl-relativetimeformat/locale-data/ja"

import { I18nProviderProps } from "./models"
import { messages } from "../messages"

export const I18nProvider: FC<I18nProviderProps> = (props) => {
  const locale = useLang()

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      {props.children}
    </IntlProvider>
  )
}
