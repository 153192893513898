/* eslint-disable max-lines */
/* eslint-disable max-statements */
import { formatDateSaveDB } from "../../../../../../../../utils/getDate"
import { getOverseasResident } from "../../../../../../../../utils/getOverseasResident"
import { getTextByKey } from "../../../../../../../../utils/getTextByKey"
import { getValueCombobox } from "../../../../../../../../utils/getValueCombobox"
import { Variables } from "../../../../../constants/edit/variables"
import {
  ConvertDataSubmitDraftProps,
  TravelersEditResType,
} from "../../../../../models/edit"

const { KEYS_ACCOMPANYING_FAMILY } = Variables

export const convertDataSubmitDraft = (
  data: ConvertDataSubmitDraftProps,
): TravelersEditResType => {
  const {
    departments,
    dispatchType,
    prefectures,
    allCountries,
    cities,
    values,
    travelerDetails,
  } = data
  let trainingType = values.trainingType
  let trainingDate = values.trainingDate

  if (!KEYS_ACCOMPANYING_FAMILY.includes(values.dispatchTypeDetailId)) {
    if (values.trainingType === 0) {
      trainingDate = ""
      trainingType = null
    }
  }

  const bumonName = getTextByKey(values.bumonCd, departments)
  const dispatchTypeDetailName = getTextByKey(
    values.dispatchTypeDetailId,
    dispatchType,
  )
  const personPrefectureResidenceName = getTextByKey(
    values.personPrefectureResidence,
    prefectures,
  )
  const workPlaceCountryName = getTextByKey(
    values.workPlaceCountry,
    allCountries,
  )
  const workPlaceCityName = getTextByKey(values.workPlaceCity, cities)
  const overseasResident = getOverseasResident(
    values.workPlaceLocation === 1,
    values.workPlace,
    getValueCombobox(values.workPlaceCity, workPlaceCityName),
    getValueCombobox(values.workPlaceCountry, workPlaceCountryName),
  )

  return {
    base: {
      authorityType: travelerDetails.base.authorityType,
      bumonCd: getValueCombobox(values.bumonCd, bumonName),
      bumonName: bumonName,
      createdAuthor: travelerDetails.base.createdAuthor,
      createdDate: travelerDetails.base.createdDate,
      deleteFlag: travelerDetails.base.deleteFlag,
      differentInforCheck: values.differentInforCheck,
      dispatchTypeDetailId: getValueCombobox(
        values.dispatchTypeDetailId,
        dispatchTypeDetailName,
      ),
      dispatchTypeDetailName: dispatchTypeDetailName,
      dispatchTypeName:
        values.dispatchTypeName || travelerDetails.base.dispatchTypeName,
      kanjiName: values.kanjiName,
      kanjiPassportName: values.kanjiPassportName,
      kanjiPassportSurname: values.kanjiPassportSurname,
      kanjiSurname: values.kanjiSurname,
      languageNotation: travelerDetails.base.languageNotation,
      numberOfPeople: values.numberOfPeople,
      passportNumber: values.passportNumber,
      patternId: travelerDetails.base.patternId,
      personDispatcherName: travelerDetails.base.passportNumber,
      personEmailAddress: travelerDetails.base.passportNumber,
      registerRequestUserId: travelerDetails.base.passportNumber,
      romajiName: values.romajiName,
      romajiPassportName: values.romajiPassportName,
      romajiPassportSurname: values.romajiPassportSurname,
      romajiSurname: values.romajiSurname,
      travelerId: travelerDetails.base.travelerId,
      updateAuthor: travelerDetails.base.updateAuthor,
      updateDate: values.updateDate,
      userId: values.userId,
    },
    detail: {
      assigneeInstitutionName: values.assigneeInstitutionName,
      assigneeName: values.assigneeName,
      assigneePhoneNumber: values.assigneePhoneNumber,
      gdprAgreement: travelerDetails.detail.gdprAgreement,
      insuranceCompanyName: values.insuranceCompanyName,
      insuranceCompanyPhoneNumber: values.insuranceCompanyPhoneNumber,
      insuranceSonsignor: values.insuranceSonsignor,
      insuranceTicketNumber: values.insuranceTicketNumber,
      othersAdministratorRemarks1: values.othersAdministratorRemarks1,
      othersAdministratorRemarks2: values.othersAdministratorRemarks2,
      othersAdministratorRemarks3: values.othersAdministratorRemarks3,
      othersAdministratorRemarks4: values.othersAdministratorRemarks4,
      othersAdministratorRemarks5: values.othersAdministratorRemarks5,
      othersDispatchNumber: travelerDetails.detail.othersDispatchNumber,
      othersMemberNumber: travelerDetails.detail.othersMemberNumber,
      othersRemarks1: values.othersRemarks1,
      othersRemarks2: values.othersRemarks2,
      othersRemarks3: values.othersRemarks3,
      othersRemarks4: values.othersRemarks4,
      othersRemarks5: values.othersRemarks5,
      othersStaffNumber: travelerDetails.detail.othersStaffNumber,
      personCompanyPosition: values.personCompanyPosition,
      personCountryPhoneCode: values.personCountryPhoneCode,
      personDispatcherName: values.personDispatcherName,
      personEmailAddress: values.personEmailAddress,
      personPhoneNumber: values.personPhoneNumber,
      personPrefectureResidence: getValueCombobox(
        values.personPrefectureResidence,
        personPrefectureResidenceName,
      ),
      personPrefectureResidenceName: personPrefectureResidenceName,
      systemIdentifier: travelerDetails.detail.systemIdentifier,
      tabirejAgreement: travelerDetails.detail.tabirejAgreement,
      timeFormat: travelerDetails.detail.timeFormat,
      timezone: travelerDetails.detail.timezone,
      trainingDate: trainingDate ? formatDateSaveDB(trainingDate) : "",
      trainingType,
      travelerId: travelerDetails.detail.travelerId,
      urgentAffiliationContact: values.urgentAffiliationContact,
      urgentAffiliationContactName: values.urgentAffiliationContactName,
      urgentContactHome: values.urgentContactHome,
      urgentContactName: values.urgentContactName,
      usageAgreement: travelerDetails.detail.usageAgreement,
      userIdForChangeRequest: values.userIdForChangeRequest,
      workPlaceCityName: workPlaceCityName,
      workPlaceCountryName: workPlaceCountryName,
      workPlaceLocation: values.workPlaceLocation,
      ...overseasResident,
    },
    hasTemporarilySchedule: travelerDetails.hasTemporarilySchedule,
    hasTemporarilyTraveler: true,
  }
}
