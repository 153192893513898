/* eslint-disable complexity */
import { Routers } from "../../../../../../../../constant/Routers"
import { useAuthority } from "../../../../../../../../utils/hook/useAuthority"

const { SCR950, SCR953 } = Routers

export const useVariablesAuthority = (sourceURL?: string) => {
  const {
    isCommonPermissionSetting,
    isCommonPermissionSettings,
    isRoleAdmin,
    isRoleSubAdmin,
  } = useAuthority()

  const isNotAccessScreen =
    ((sourceURL === SCR950 || sourceURL === SCR953) &&
      !isCommonPermissionSettings) ||
    (!sourceURL && !isCommonPermissionSetting)

  const isHiddenBtn1 =
    (isRoleAdmin || isRoleSubAdmin) && isCommonPermissionSetting

  const isHiddenBtn2 = isRoleAdmin && isCommonPermissionSettings

  const isHiddenBtnSearch = isHiddenBtn1
  const isHiddenBtnReset = isHiddenBtn1
  const isHiddenBtnSubAdminSetting = isHiddenBtn2
  const isHiddenBtnRoleSetting = isHiddenBtn2

  return {
    isHiddenBtnReset,
    isHiddenBtnRoleSetting,
    isHiddenBtnSearch,
    isHiddenBtnSubAdminSetting,
    isNotAccessScreen,
    isRoleSubAdmin,
  }
}
