import { Key, MutableRefObject, useEffect, useState } from "react"
import { useTable } from "../../../../../../../utils/hook/useTable"
import { UploadFileType } from "../../../../model/filesUpload"
import { TableRowSelection } from "../../../../../../../_webui/layout/components/Table/models"
import { CheckboxAll } from "../../../../../../../_webui/layout/components/RowSelection/CheckboxAll"
import { CheckboxItem } from "../../../../../../../_webui/layout/components/RowSelection/CheckboxItem"
import { CheckboxChangeEvent } from "antd/lib/checkbox"

export const useRowSelection = (selectedKeys: MutableRefObject<Key[]>) => {
  const [fileUploadInfoList, setFileUploadInfoList] = useState<
    UploadFileType[]
  >([])
  const { onChange: onChangePage, perItem } = useTable(
    fileUploadInfoList?.length,
  )
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [isCheckAll, setIsCheckAll] = useState(false)

  const onSelectChange = () => {
    selectedKeys.current = []
    if (selectedRowKeys.length === fileUploadInfoList.length) {
      setIsCheckAll(true)
    } else {
      setIsCheckAll(false)
    }
  }

  const onChangeCheckAll = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    setIsCheckAll(checked)
    if (checked) {
      const _selectedRowKeys = fileUploadInfoList.map((item) => item.index)
      setSelectedRowKeys(_selectedRowKeys)
    } else {
      setSelectedRowKeys([])
    }
  }

  const onChangeCheckItem = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    const value = event.target.value
    if (checked) {
      setSelectedRowKeys([...selectedRowKeys, value])
    } else {
      const _selectedRowKeys = selectedRowKeys.filter((item) => item !== value)
      if (_selectedRowKeys.length) {
        setIsCheckAll(true)
      } else {
        setIsCheckAll(false)
      }
      setSelectedRowKeys(_selectedRowKeys)
    }
  }

  const rowSelection: TableRowSelection<UploadFileType> = {
    columnTitle: (
      <CheckboxAll
        checked={isCheckAll}
        isDisabled={!fileUploadInfoList.length}
        onChange={onChangeCheckAll}
      />
    ),
    renderCell: (_, record) => (
      <CheckboxItem
        checked={selectedRowKeys.includes(record.index)}
        onChange={onChangeCheckItem}
        value={record.index}
      />
    ),
    selectedRowKeys: selectedRowKeys,
  }

  const onChangePagination = (page: number, pageSize: number) => {
    onChangePage(page, pageSize)
    setSelectedRowKeys([])
  }

  useEffect(() => {
    if (selectedRowKeys?.length) {
      onSelectChange()
    }
  }, [selectedRowKeys])

  return {
    fileUploadInfoList,
    onChangePagination,
    perItem,
    rowSelection,
    selectedRowKeys,
    setFileUploadInfoList,
    setIsCheckAll,
    setSelectedRowKeys,
  }
}
