import { Breadcrumbs } from "../../../../../../../../constant/Breadcrumbs"
import { Routers } from "../../../../../../../../constant/Routers"

const { SCR210, SCR210_TRAVELERS } = Routers
const {
  BRCR_SCR211_FROM_SCR114,
  BRCR_SCR211_FROM_SCR210_TRAVELERS,
  BRCR_SCR211_FROM_SCR210,
  BRCR_SCR211,
} = Breadcrumbs

export const getBreadcrumbItems = (
  isFromSCR210: boolean,
  isFromSCR114UserSearch: boolean,
  schedulesURL?: string,
) =>
  isFromSCR114UserSearch
    ? BRCR_SCR211_FROM_SCR114
    : isFromSCR210
    ? schedulesURL === SCR210
      ? BRCR_SCR211_FROM_SCR210
      : schedulesURL === SCR210_TRAVELERS
      ? BRCR_SCR211_FROM_SCR210_TRAVELERS
      : BRCR_SCR211
    : BRCR_SCR211
