import { FC } from "react"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { Translate } from "../../../../../constant/Translate"
import { Alert } from "../../../../../_webui/layout/components/Alert"
import { BreakText } from "../../../../../_webui/layout/components/Typography/BreakText"
import { ButtonGroup } from "../../../../../_webui/layout/components/Button/Group"
import { Button } from "../../../../../_webui/layout/components/Button"
import { Center } from "../../../../../_webui/layout/components/Center"
import { useAttentionsAnswer } from "./modules/useAnswer"

const {
  TEXT_T018,
  TEXT_T291,
  TEXT_T355,
  TEXT_T356,
  TEXT_T357,
  TEXT_T336,
  TEXT_T413,
} = Translate

export const AttentionsAnswer: FC = () => {
  const {
    breadcrumbItems,
    goToAttentionsDetail,
    goToAttentionsList,
    goToHome,
    handleCloseBrowser,
    isFromEmail,
    isLoadingLayout,
    isVisibleBtnBack,
    isVisibleBtnDetail,
    isVisibleBtnList,
  } = useAttentionsAnswer()

  return (
    <Layout
      isLoadingLayout={isLoadingLayout}
      isSideBar={!isFromEmail}
      isHeader={!isFromEmail}
      isFooter={!isFromEmail}
      breadcrumbItems={isFromEmail ? [] : breadcrumbItems}
      title={TEXT_T355}
    >
      <Card heading={TEXT_T355}>
        <Alert
          content={<BreakText above={TEXT_T356} below={TEXT_T357} />}
          type="success"
        />
        {isFromEmail ? (
          <Center>
            <Button
              isFullWidth
              isHidden
              onClick={handleCloseBrowser}
              text={TEXT_T413}
              type="primary"
            />
          </Center>
        ) : (
          <ButtonGroup
            isMargin
            buttonItems={[
              {
                isHidden: !isVisibleBtnBack,
                onClick: goToHome,
                order: 3,
                text: TEXT_T018,
                type: "ghost",
              },
              {
                isHidden: !isVisibleBtnDetail,
                onClick: goToAttentionsDetail,
                order: 1,
                text: TEXT_T336,
                type: "primary",
              },
              {
                isHidden: !isVisibleBtnList,
                onClick: goToAttentionsList,
                order: 2,
                text: TEXT_T291,
              },
            ]}
          />
        )}
      </Card>
    </Layout>
  )
}
