import { FC } from "react"
import { useApplicationForm } from "./modules/useApplicationForm"
import { ApplicationFormButtonGroup } from "../../components/list/ButtonGroup"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { TotalRecords } from "../../../../../_webui/layout/components/Typography/TotalRecords"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Table } from "../../../../../_webui/layout/components/Table"
import { Translate } from "../../../../../constant/Translate"
import { ModalConfirm } from "../../../../../_webui/layout/components/Modal/Confirm"

const { MESSAGE_C0009 } = Translate

export const ApplicationFormList: FC = () => {
  const {
    breadcrumbItems,
    columns,
    dataSource,
    deleteFiles,
    goToFilesUpload,
    handleDownload,
    isDisabledBtnDelete,
    isDisabledBtnDownload,
    isLoading,
    isLoadingLayout,
    isOpen,
    isVisibleBtnDelete,
    isVisibleBtnReference,
    isVisibleBtnRegister,
    layoutTitle,
    onCancel,
    onChangePagination,
    onChangeTable,
    onOpen,
    perItem,
    rowSelection,
  } = useApplicationForm()

  return (
    <Layout
      breadcrumbItems={breadcrumbItems}
      isLoadingLayout={isLoadingLayout}
      title={layoutTitle}
    >
      <Card heading={layoutTitle} isLoading={isLoading}>
        <TotalRecords perItem={perItem} total={dataSource?.length} />
        <Table
          columns={columns}
          dataSource={dataSource}
          onChange={onChangePagination}
          onChangeTable={onChangeTable}
          rowKey="uploadId"
          rowSelection={rowSelection}
        />
        <ApplicationFormButtonGroup
          goToFilesUpload={goToFilesUpload}
          handleDownload={handleDownload}
          isDisabledBtnDelete={isDisabledBtnDelete}
          isDisabledBtnDownload={isDisabledBtnDownload}
          isVisibleBtnDelete={isVisibleBtnDelete}
          isVisibleBtnReference={isVisibleBtnReference}
          isVisibleBtnRegister={isVisibleBtnRegister}
          onOpen={onOpen}
        />

        <ModalConfirm isOpen={isOpen} onCancel={onCancel} onOk={deleteFiles}>
          {MESSAGE_C0009}
        </ModalConfirm>
      </Card>
    </Layout>
  )
}
