import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const { isRoleSubAdmin, isSafetiesSendAll } = useAuthority()
  // 画面上のボタン表示条件
  const isHiddenBtn = isRoleSubAdmin && isSafetiesSendAll
  const isHiddenBtnBack = isHiddenBtn
  const isHiddenBtnCopy = isHiddenBtn

  return {
    isHiddenBtnBack,
    isHiddenBtnCopy,
    isSafetiesSendAll,
  }
}
