import { FC } from "react"
import { Images } from "../../../../constant/Images"
import { Translate } from "../../../../constant/Translate"
import { ModalButton } from "./Button"
import { ModalProps } from "./models"
import { Styled } from "./styled"
import { useModal } from "./modules/useModal"

const { IMAGE_BTN_DELETE } = Images
const {
  StyledModal,
  StyledModalBody,
  StyledModalBtnClose,
  StyledModalContent,
  StyledModalImage,
  StyledModalMask,
} = Styled
const { TEXT_T413 } = Translate

export const Modal: FC<ModalProps> = (props) => {
  const {
    cancelCustomText,
    cancelCustomType = "ghost",
    cancelText,
    cancelType = "ghost",
    children,
    idPreviousElement,
    isCenter,
    isClose,
    isFocusHeading,
    isFocusPreviousElement,
    isForm,
    isFull,
    isOpen,
    isShowFooter = true,
    okText,
    onCancel,
    onCancelCustom,
    onOk,
    ...res
  } = props

  const { onCancelCustom2, onCancelModal, onOkModal, refModal } = useModal({
    idPreviousElement,
    isFocusHeading,
    isFocusPreviousElement,
    onCancel,
    onCancelCustom,
    onOk,
  })

  return isOpen ? (
    <StyledModal
      isOpen={isOpen}
      ref={refModal}
      role={isForm ? "form" : "dialog"}
      tabIndex={0}
      {...res}
    >
      <StyledModalMask onClick={onCancelModal}></StyledModalMask>
      <StyledModalContent
        className="modal-content"
        isForm={isForm}
        isFull={isFull}
      >
        {isClose && (
          <StyledModalBtnClose
            aria-label={TEXT_T413}
            onClick={onCancelModal}
            type="button"
          >
            <StyledModalImage src={IMAGE_BTN_DELETE} />
          </StyledModalBtnClose>
        )}
        <StyledModalBody isForm={isForm} isFull={isFull}>
          {children}
        </StyledModalBody>
        <ModalButton
          cancelCustomText={cancelCustomText}
          cancelCustomType={cancelCustomType}
          cancelText={cancelText}
          cancelType={cancelType}
          isCenter={isCenter}
          isShowFooter={isShowFooter}
          okText={okText}
          onCancel={onCancelModal}
          onCancelCustom={onCancelCustom2}
          onOk={onOkModal}
        />
      </StyledModalContent>
    </StyledModal>
  ) : null
}
