/* eslint-disable max-lines */
import { Card } from "../../../../../_webui/layout/components/Card"
import { Table } from "../../../../../_webui/layout/components/Table"
import { FC } from "react"
import { useTravelersList } from "./modules/useList"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { Translate } from "../../../../../constant/Translate"
import { TotalRecords } from "../../../../../_webui/layout/components/Typography/TotalRecords"
import { TravelersButtonGroup } from "../../components/list/ButtonGroup"
import { FormSearchTravelersUser } from "../../components/list/FormSearch"
import { TravelersModalConfirm } from "../../components/list/ModalConfirm"
import { Styled } from "./styled"
import { Button } from "../../../../../_webui/layout/components/Button"

const { StyledOverRecord } = Styled
const { TEXT_T021, TEXT_T022, TEXT_T400 } = Translate

export const TravelersList: FC = () => {
  const {
    breadcrumbItems,
    columns,
    dataSource,
    departments,
    form,
    goToSchedulesAdd,
    goToSchedulesAddDraft,
    goToTravelersDelete,
    goToTravelersDetail,
    goToTravelersEditDraft,
    goToTravelersSearch,
    handleExportFile,
    isDisabledBtnDelete,
    isDisabledBtnRefer,
    isHiddenBtnDelete,
    isHiddenBtnExportFile,
    isHiddenBtnRefer,
    isHiddenBtnRegister,
    isHiddenBtnRequestDelete,
    isHiddenBtnScheduleDraft,
    isHiddenBtnSearch,
    isHiddenBtnTravelerDraft,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenLoadData,
    isOpenShowData,
    isPathnameTravelers,
    isPathnameTravelersUser,
    isPathnameTravelersUserSearch,
    onCancel,
    onCancelCustomLoadData,
    onChange,
    onChangeTable,
    onFinish,
    onOkLoadData,
    onOkShowData,
    onOpen,
    overRecordLabel,
    perItem,
    rowSelection,
  } = useTravelersList()

  return (
    <Layout
      breadcrumbItems={breadcrumbItems}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T021}
    >
      <Card
        childrenRight={
          isPathnameTravelersUser ? (
            <Button
              isHidden={isHiddenBtnTravelerDraft}
              className="card-heading-button"
              htmlType="button"
              onClick={goToTravelersEditDraft}
              text={TEXT_T400}
              type="warning"
            />
          ) : (
            isPathnameTravelers && (
              <Button
                isHidden={isHiddenBtnScheduleDraft}
                className="card-heading-button"
                htmlType="button"
                onClick={goToSchedulesAddDraft}
                text={TEXT_T400}
                type="warning"
              />
            )
          )
        }
        heading={TEXT_T021}
        isSearch={isHiddenBtnSearch}
        isLoading={isLoading}
        onClick={goToTravelersSearch}
        searchText={TEXT_T022}
      >
        {isPathnameTravelersUserSearch && (
          <FormSearchTravelersUser
            onFinish={onFinish}
            departments={departments}
            form={form}
          />
        )}
        <TotalRecords
          titleLeft={isPathnameTravelersUserSearch && TEXT_T021}
          titleRight={<StyledOverRecord>{overRecordLabel}</StyledOverRecord>}
          perItem={perItem}
          total={dataSource?.length}
        />
        <Table
          columns={columns}
          dataSource={dataSource}
          onChange={onChange}
          onChangeTable={onChangeTable}
          rowKey="travelerId"
          rowSelection={rowSelection}
        />
        <TravelersButtonGroup
          goToSchedulesAdd={goToSchedulesAdd}
          goToTravelersDelete={goToTravelersDelete}
          goToTravelersDetail={goToTravelersDetail}
          onOpen={onOpen}
          isDisabledBtnDelete={isDisabledBtnDelete}
          isDisabledBtnRefer={isDisabledBtnRefer}
          isHiddenBtnDelete={isHiddenBtnDelete}
          isHiddenBtnExportFile={isHiddenBtnExportFile}
          isHiddenBtnRefer={isHiddenBtnRefer}
          isHiddenBtnRegister={isHiddenBtnRegister}
          isHiddenBtnRequestDelete={isHiddenBtnRequestDelete}
        />
        <TravelersModalConfirm
          isOpen={isOpen}
          onCancel={onCancel}
          onOk={handleExportFile}
          isOpenLoadData={isOpenLoadData}
          isOpenShowData={isOpenShowData}
          onCancelCustomLoadData={onCancelCustomLoadData}
          onOkLoadData={onOkLoadData}
          onOkShowData={onOkShowData}
          selectedRowLength={rowSelection?.selectedRowKeys?.length}
        />
      </Card>
    </Layout>
  )
}
