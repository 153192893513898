import { FC } from "react"
import { AttentionButtonGroupProps } from "./models"
import { ButtonGroup } from "../../../../../../_webui/layout/components/Button/Group"
import { Translate } from "../../../../../../constant/Translate"

const { TEXT_T232, TEXT_T072, TEXT_T321 } = Translate

export const AttentionButtonGroup: FC<AttentionButtonGroupProps> = (props) => {
  const {
    isDisabledSubmit,
    isHiddenBackBtn,
    isHiddenSubmitBtn,
    onGoBack,
    onOpen,
    onOpenForm,
  } = props
  return (
    <ButtonGroup
      buttonItems={[
        {
          absolutePosition: "left",
          isAbsolute: true,
          isHidden: isHiddenBackBtn,
          isSmall: true,
          onClick: onGoBack,
          order: 3,
          text: TEXT_T072,
          type: "ghost",
        },
        {
          htmlType: "submit",
          isDisabled: isDisabledSubmit,
          isHidden: isHiddenSubmitBtn,
          onClick: onOpen,
          order: 1,
          text: TEXT_T321,
          type: "primary",
        },
        {
          htmlType: "button",
          onClick: onOpenForm,
          order: 2,
          text: TEXT_T232,
          type: "primary",
        },
      ]}
    />
  )
}
