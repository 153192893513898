/* eslint-disable max-lines */
import { useEffect } from "react"
import { Routers } from "../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { GeneralMastersResType } from "../../../../../../../infrastructure/handlers/generalMasters/models"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { getValueByLanguage } from "../../../../../../../utils/getValueByLanguage"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { NotificationResType } from "../../../../../../../_webui/layout/components/Notification/models"
import { homeApi } from "../../../../apis"
import { Variables } from "../../../../constants/variables"
import { UseHomeType } from "../../../../models"
import { useVariables } from "../useVariables"
import { convertData } from "./convertData"
import {
  summerTimeText,
  summerTimeValue,
} from "../../../../../../../utils/summerTime"
import { commonApi } from "../../../../../settings/apis"
import { timezoneApi } from "../../../../../../../infrastructure/handlers/timezone"
import { TimezoneResType } from "../../../../../../../infrastructure/handlers/timezone/models"

const { SCR930, SCR973 } = Routers
const { NOTICE_CATEGORY_SYSTEM, NOTICE_DISPLAY_HIDDEN } = Variables

export const useHome = (): UseHomeType => {
  const {
    generalMasters,
    isCommonRefer,
    isLoading,
    isLoadingLayout,
    isOpen,
    notificationItems,
    notifications,
    push,
    setIsLoading,
    setIsLoadingLayout,
    setIsOpen,
    setLanguageToEnglish,
    setLanguageToJapanese,
    setNotificationItems,
    setNotifications,
    setTimezone,
    setTimezoneValue,
    timezone,
    timezoneValue,
  } = useVariables()

  const goToTimezoneSetting = () => {
    push(SCR930)
  }

  // ・「M.noticeDisplay.key1」 ===  "NTDP001"の場合、「M.notificationList」で返却されたお知らせを全て表示する。
  // ・「M.noticeDisplay.key1」 ===  "NTDP002"の場合、「M.notificationList.noticeCategory」 ==="NTCG001"のお知らせのみ表示する。
  const onChangeNoticeCategory = (value: string) => {
    const _data =
      value === NOTICE_DISPLAY_HIDDEN
        ? notifications.filter(
            (item) => item.noticeCategory === NOTICE_CATEGORY_SYSTEM,
          )
        : notifications
    const data = convertData(_data)
    setIsLoading(true)
    setTimeout(() => {
      setNotificationItems(data)
      setIsLoading(false)
    }, 500)
  }

  const callApi = async () => {
    // APIを呼び出し、APIからの応答結果を「timeZoneInfoResponse」変数に設定する。
    const getTimezones: GeneralMastersResType[] = await commonApi.getTimezones()
    // APIを呼び出し、APIからの応答結果を「お知らせ応答」変数に設定する。
    const notifications: Promise<NotificationResType[]> =
      await homeApi.notifications()
    const getTimezone: TimezoneResType = await timezoneApi.getTimezone()
    await Promise.all([getTimezones, notifications, getTimezone])
      .then((res) => {
        // timeZoneDisplayの値を更新する。
        const timezone = res[0].find(
          (item) =>
            item.key1 === res[2].timeZone &&
            item.key2 === summerTimeValue(res[2].isSummerTime) &&
            item.key5 === res[2].key5,
        )
        if (timezone) {
          setTimezone(
            `${getValueByLanguage(timezone.value1, timezone.value2)} (GMT${
              timezone.value3
            })${summerTimeText(timezone?.key2)}`,
          )
          setTimezoneValue(timezone.value3)
        } else {
          setIsOpen(true)
        }

        // 「notificationList」に「M.notificationsResponse.notifications」を設定する。
        const data = convertData(res[1])
        setNotifications(res[1])
        setNotificationItems(data)
        setIsLoadingLayout(false)
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const stateError = pushStateError(error)
        push({ pathname: SCR973, state: stateError })
      })
  }

  useEffect(() => {
    // ユーザの画面アクセス許可チェックを処理する
    if (!isCommonRefer) {
      // 「SCR973_エラー情報の表示画面」に遷移する。
      const { pathname, state } = backToLogin()
      push({ pathname, state })
    } else {
      callApi()
    }
  }, [])

  return {
    generalMasters,
    goToTimezoneSetting,
    isLoading,
    isLoadingLayout,
    isOpen,
    notificationItems,
    onChangeNoticeCategory,
    setLanguageToEnglish,
    setLanguageToJapanese,
    timezone,
    timezoneValue,
  }
}
