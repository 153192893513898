/* eslint-disable max-lines */
import moment from "moment"
import { GeneralCode } from "../../../../../../../../constant/GeneralCode"
import { Routers } from "../../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../../infrastructure/axiosError/models"
import { pushStateError } from "../../../../../../../../utils/pushStateError"
import { commonApi } from "../../../../../apis"
import {
  FilesListDataSourceType,
  GetFilesParamType,
} from "../../../../../models/files/list"
import { useVariablesApi } from "../useVariablesApi"

const { MEASURES_TYPE, SAFETY_MEASURES_MANUAL_TYPE } = GeneralCode
const { SCR973 } = Routers

export const useApi = () => {
  const {
    currentUsage,
    fileInfoList,
    form,
    initDateTime,
    isCommonUploadRegister,
    isCountry,
    isDisabledBtnDelete,
    isDisabledBtnDownload,
    isDisabledBtnPreview,
    isDisabledCountries,
    isLoading,
    isLoadingLayout,
    isOpen,
    isResetTable,
    isRoleAdmin,
    isVisibleBtnDelete,
    isVisibleBtnReference,
    isVisibleBtnRegister,
    onCancel,
    onChangePagination,
    onChangeTable,
    onOpen,
    perItem,
    push,
    rowSelection,
    selectedRowKeys,
    setCurrentUsage,
    setFileInfoList,
    setInitDateTime,
    setIsDisabledBtnDownload,
    setIsDisabledBtnPreview,
    setIsDisabledCountries,
    setIsLoading,
    setIsLoadingLayout,
    setIsResetTable,
    setSelectedRowKeys,
    typeInfoSubAdmin,
  } = useVariablesApi()

  const handleGetDataSource = async () => {
    setIsLoading(true)
    setIsResetTable(true)
    const { countryCode, fileType } = form.getFieldsValue()
    await getFiles({ countryCode, isFileManager: true, type: fileType })
  }

  const getFiles = async (params: GetFilesParamType) => {
    // APIを呼び出し、アップロードファイル一覧を取得し
    await commonApi
      .getFiles({
        ...params,
        isCountry,
      })
      .then((res: FilesListDataSourceType[]) => {
        setFileInfoList(res)
        setCurrentUsage(
          res.reduce((result, item) => +result + +item.fileSize, 0),
        )
        setInitDateTime(moment())
        setIsDisabledBtnDownload(true)
        setIsDisabledBtnPreview(true)
        setIsLoading(false)
        setIsLoadingLayout(false)
        setIsResetTable(false)
        setSelectedRowKeys([])
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const stateError = pushStateError(error)
        push({ pathname: SCR973, state: stateError })
      })
  }

  const deleteFiles = async () => {
    setIsLoading(true)
    // APIを呼び出して選択したファイルを削除します
    await commonApi
      .deleteFiles(selectedRowKeys)
      .then(async () => {
        await handleGetDataSource()
        onCancel()
        setIsLoading(false)
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const stateError = pushStateError(error)
        push({ pathname: SCR973, state: stateError })
      })
  }

  const onChangeFileType = (value: string) => {
    setSelectedRowKeys([])
    if (value === MEASURES_TYPE || value === SAFETY_MEASURES_MANUAL_TYPE) {
      setIsDisabledCountries(false)
    } else {
      setIsDisabledCountries(true)
      form.setFieldValue("countryCode", "")
    }
    handleGetDataSource()
  }

  const onChangeCountryCode = () => {
    setSelectedRowKeys([])
    handleGetDataSource()
  }

  return {
    currentUsage,
    deleteFiles,
    fileInfoList,
    form,
    getFiles,
    initDateTime,
    isCommonUploadRegister,
    isCountry,
    isDisabledBtnDelete,
    isDisabledBtnDownload,
    isDisabledBtnPreview,
    isDisabledCountries,
    isLoading,
    isLoadingLayout,
    isOpen,
    isResetTable,
    isRoleAdmin,
    isVisibleBtnDelete,
    isVisibleBtnReference,
    isVisibleBtnRegister,
    onCancel,
    onChangeCountryCode,
    onChangeFileType,
    onChangePagination,
    onChangeTable,
    onOpen,
    perItem,
    rowSelection,
    selectedRowKeys,
    setIsDisabledCountries,
    typeInfoSubAdmin,
  }
}
