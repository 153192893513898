import { FC, Fragment } from "react"
import { ItemInputGroup } from "../../../../../../../_webui/layout/components/Form/Item/Input/Group"
import { InputItems } from "../../../../../../../constant/InputItems"
import { Translate } from "../../../../../../../constant/Translate"

const { HELP_H0002, TEXT_T003, TEXT_T006 } = Translate
const { KANJI_FULL_NAME, ROMAJI_FULL_NAME_REQUIRED } = InputItems

export const TravelersItemInputGroup: FC = () => {
  return (
    <Fragment>
      <ItemInputGroup
        className="ant-form-item-group-first"
        inputItems={KANJI_FULL_NAME}
        label={TEXT_T003}
        name="fullNameKanji"
      />
      <ItemInputGroup
        inputItems={ROMAJI_FULL_NAME_REQUIRED}
        label={TEXT_T006}
        name="fullNameRomaji"
        required
        tooltip={HELP_H0002}
      />
    </Fragment>
  )
}
