import { FC, useLayoutEffect } from "react"
import { useLocation } from "react-router-dom"

export const ScrollToTop: FC = () => {
  const { pathname } = useLocation()

  useLayoutEffect(() => {
    window.requestAnimationFrame(() =>
      window.scroll({ behavior: "smooth", left: 0, top: 0 }),
    )
  }, [pathname])

  return null
}
