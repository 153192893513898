/* eslint-disable max-statements */
import Excel from "exceljs"
import { Translate } from "../../../../../../../../../constant/Translate"

const {
  EXPORT_EP0020, // 案件名    Project name
  EXPORT_EP0056, // 時刻    Time
  EXPORT_EP0065, // 宿泊先名称    Accommodation name
  EXPORT_EP0106, // 渡航目的    Purpose of travel
  EXPORT_EP0115, // 日付    Date
  EXPORT_EP0119, // 年月    YYYYMM
  EXPORT_EP0133, // 便名/交通手段    Fright No/Transportation
  EXPORT_EP0037, // 緊急時連絡先（自宅）
  EXPORT_EP0146, // 連絡者名（自宅）
  EXPORT_EP0038, // 緊急時連絡先（所属先）
  EXPORT_EP0147, // 連絡者名（所属先）
  EXPORT_EP0178, // 保険情報取得先
  EXPORT_EP0135, // 保険会社名
  EXPORT_EP0136, // 保険会社連絡先
  EXPORT_EP0190, // 保険証券番号
  EXPORT_EP0183, // 緊急移送判断委託者
} = Translate

export const columnsConfig = (worksheet: Excel.Worksheet) => {
  worksheet.columns = [
    { width: 38 },
    { width: 22 }, // width = 22 * 8 = 176px
    { width: 22 },
    { width: 30 },
    { width: 22 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 38 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
  ]
  worksheet.getCell("A2").value = EXPORT_EP0020
  worksheet.getCell("B2").value = EXPORT_EP0119
  worksheet.getCell("C2").value = EXPORT_EP0115
  worksheet.getCell("D2").value = EXPORT_EP0056
  worksheet.getCell("E2").value = EXPORT_EP0106
  worksheet.getCell("F2").value = EXPORT_EP0133
  worksheet.getCell("G2").value = "FROM"
  worksheet.getCell("H2").value = ""
  worksheet.getCell("I2").value = "TO"
  worksheet.getCell("J2").value = ""
  worksheet.getCell("K2").value = EXPORT_EP0065
  worksheet.getCell("L2").value = EXPORT_EP0037
  worksheet.getCell("M2").value = EXPORT_EP0146
  worksheet.getCell("N2").value = EXPORT_EP0038
  worksheet.getCell("O2").value = EXPORT_EP0147
  worksheet.getCell("P2").value = EXPORT_EP0178
  worksheet.getCell("Q2").value = EXPORT_EP0135
  worksheet.getCell("R2").value = EXPORT_EP0136
  worksheet.getCell("S2").value = EXPORT_EP0190
  worksheet.getCell("T2").value = EXPORT_EP0183
}
