/* eslint-disable max-lines */
import { Translate } from "../../../../../../../constant/Translate"
import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../_webui/layout/components/Table/models"
import { TravelersBatchDeleteDataType } from "../../../../models/batchDelete/data"
import { getValueByLanguage } from "../../../../../../../utils/getValueByLanguage"

const {
  TEXT_T002,
  TEXT_T017,
  TEXT_T046,
  TEXT_T047,
  TEXT_T123,
  TEXT_T124,
  TEXT_T125,
  TEXT_T126,
  TEXT_T128,
  TEXT_T129,
  TEXT_T130,
  TEXT_T131,
  TEXT_T218,
} = Translate

export const useTableConfig =
  (): UseTableConfigType<TravelersBatchDeleteDataType> => {
    const columns: ColumnType<TravelersBatchDeleteDataType>[] = [
      {
        dataIndex: "userId",
        sorter: (a, b) => a.userId.localeCompare(b.userId),
        title: TEXT_T002,
        width: 250,
      },
      {
        dataIndex: "kanjiSurname",
        sorter: (a, b) => a.kanjiSurname.localeCompare(b.kanjiSurname),
        title: TEXT_T123,
        width: getValueByLanguage(150, 200),
      },
      {
        dataIndex: "kanjiName",
        sorter: (a, b) => a.kanjiName.localeCompare(b.kanjiName),
        title: TEXT_T124,
        width: getValueByLanguage(150, 220),
      },
      {
        dataIndex: "romajiSurname",
        sorter: (a, b) => a.romajiSurname.localeCompare(b.romajiSurname),
        title: TEXT_T125,
        width: getValueByLanguage(150, 250),
      },
      {
        dataIndex: "romajiName",
        sorter: (a, b) => a.romajiName.localeCompare(b.romajiName),
        title: TEXT_T126,
        width: getValueByLanguage(150, 270),
      },
      {
        dataIndex: "kanjiPassportSurname",
        sorter: (a, b) =>
          a.kanjiPassportSurname.localeCompare(b.kanjiPassportSurname),
        title: TEXT_T128,
        width: getValueByLanguage(200, 250),
      },
      {
        dataIndex: "kanjiPassportName",
        sorter: (a, b) =>
          a.kanjiPassportName.localeCompare(b.kanjiPassportName),
        title: TEXT_T129,
        width: getValueByLanguage(200, 250),
      },
      {
        dataIndex: "romajiPassportSurname",
        sorter: (a, b) =>
          a.romajiPassportSurname.localeCompare(b.romajiPassportSurname),
        title: TEXT_T130,
        width: getValueByLanguage(200, 250),
      },
      {
        dataIndex: "romajiPassportName",
        sorter: (a, b) =>
          a.romajiPassportName.localeCompare(b.romajiPassportName),
        title: TEXT_T131,
        width: getValueByLanguage(200, 250),
      },
      {
        dataIndex: "personPrefectureResidenceName",
        sorter: (a, b) =>
          a.personPrefectureResidenceName.localeCompare(
            b.personPrefectureResidenceName,
          ),
        title: TEXT_T218,
        width: getValueByLanguage(150, 260),
      },
      {
        dataIndex: "personCountryPhoneCode",
        sorter: (a, b) =>
          a.personCountryPhoneCode.localeCompare(b.personCountryPhoneCode),
        title: TEXT_T046,
        width: getValueByLanguage(150, 250),
      },
      {
        dataIndex: "personPhoneNumber",
        sorter: (a, b) =>
          a.personPhoneNumber.localeCompare(b.personPhoneNumber),
        title: TEXT_T047,
        width: 150,
      },
      {
        dataIndex: "personEmailAddress",
        sorter: (a, b) =>
          a.personEmailAddress.localeCompare(b.personEmailAddress),
        title: TEXT_T017,
        width: getValueByLanguage(250, 300),
      },
    ]
    return {
      columns,
    }
  }
