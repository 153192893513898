import { FC, Fragment } from "react"
import { Title } from "../../../../../../../../_webui/layout/components/Typography/Title"
import { Regex } from "../../../../../../../../constant/Regex"
import { Translate } from "../../../../../../../../constant/Translate"
import { ScheduleInfoLodgingsType } from "../../../../../models/add"
import { SchedulesAddTable } from "../../../Table"
import { SchedulesAddLodgingProps } from "./models"

const { REGEX_IS_DESKTOP } = Regex
const { TEXT_T284, TEXT_T285, TEXT_T491, TEXT_T492 } = Translate

export const SchedulesAddLodging: FC<SchedulesAddLodgingProps> = (props) => {
  const {
    handleAddLodgingItem,
    handleDeleteLodgingItem,
    isTableLoading,
    isVisibleBtn,
    lodgingColumns,
    lodgingDataSource,
    lodgingIndexTabActive,
    lodgingRowSelection,
    lodgingSelectedRowKeys,
    lodgingTabsItems,
    lodgingValidMsg,
    onClickLodgingTab,
  } = props

  return (
    <Fragment>
      {lodgingValidMsg && <Title type="danger" titleLeft={lodgingValidMsg} />}

      <SchedulesAddTable
        activeKey={lodgingIndexTabActive}
        addText={REGEX_IS_DESKTOP ? TEXT_T284 : TEXT_T491}
        columns={lodgingColumns}
        dataSource={lodgingDataSource}
        dataTableSelected={lodgingSelectedRowKeys}
        deleteText={REGEX_IS_DESKTOP ? TEXT_T285 : TEXT_T492}
        handleAdd={handleAddLodgingItem}
        handleDelete={handleDeleteLodgingItem}
        id="table-schedules-add-lodging"
        isLoading={isTableLoading}
        isVisibleBtn={isVisibleBtn}
        items={lodgingTabsItems}
        onTabClick={onClickLodgingTab}
        rowKey={(record: ScheduleInfoLodgingsType) => record.travelLodgingId}
        rowSelection={lodgingRowSelection}
      />
    </Fragment>
  )
}
