import { useHistory, useLocation } from "react-router-dom"
import { Routers } from "../../../../../../../constant/Routers"
import { replaceString } from "../../../../../../../utils/replaceString"
import {
  SchedulesEditSuccessStateType,
  UseSchedulesEditSuccessType,
} from "../../../../models/editSuccess"
import { useSchedulesAddSuccess } from "../../../addSuccess/modules/useAddSuccess"
import { getBreadcrumbItems } from "./getBreadcrumbItems"

const { SCR210, SCR214, SCR214_USER } = Routers

export const useSchedulesEditSuccess = (): UseSchedulesEditSuccessType => {
  const { state, pathname } = useLocation<SchedulesEditSuccessStateType>()
  const { push } = useHistory()

  const breadcrumbItems = getBreadcrumbItems(
    state?.schedulesURL,
    state?.isFromSCR210,
    state?.isFromSCR211,
    state?.isFromSCR214,
  )

  const { travelScheduleId, travelerId, ...rest } = useSchedulesAddSuccess()

  // 「SCR214_渡航予定情報の変更画面」に遷移する。
  const goToSchedulesEdit = () => {
    const _pathname = state?.schedulesURL === SCR210 ? SCR214 : SCR214_USER
    push({
      pathname: replaceString(_pathname, travelScheduleId),
      state: {
        ...state,
        sourceURL: pathname,
        travelerId,
      },
    })
  }

  return {
    ...rest,
    breadcrumbItems,
    goToSchedulesEdit,
  }
}
