import { notification } from "antd"
import { ReactNode } from "react"

export const openNotification = (message: ReactNode) => {
  notification.open({
    duration: 10,
    message: <span style={{ whiteSpace: "pre-wrap" }}>{message}</span>,
    placement: "top",
  })
}
