/* eslint-disable complexity */
import { FC, Fragment } from "react"
import { MenusType } from "../../models"
import { Styled } from "./styled"
import { Routers } from "../../../../../../constant/Routers"
import { useMenuItem } from "./modules/useMenuItem"
import { replaceString } from "../../../../../../utils/replaceString"
import { concat } from "../../../../../../utils/concat"
import { Translate } from "../../../../../../constant/Translate"

const {
  SCR114_USER_SEARCH,
  SCR115,
  SCR212,
  SCR917,
  SCR920,
  SCR991_FAQ,
  SCR992,
} = Routers
const {
  StyledArrow,
  StyledArrowIcon,
  StyledImage,
  StyledImageAfter,
  StyledImageBefore,
  StyledLabel,
  StyledNavContent,
  StyledNavItem,
  StyledNavLabel,
  StyledNavLink,
  StyledNavTitle,
} = Styled
const { TEXT_T277 } = Translate

export const MenuItem: FC<MenusType> = (props) => {
  const { iconUrl, menuName, menuUrl, handleNavLinkClick, subMenus } = props
  const { active, handleActiveSubMenu, travelerId } = useMenuItem()
  const _active = active ? "active" : ""
  const ariaLabel = TEXT_T277.replaceAll("{0}", concat(menuName))

  return subMenus?.length ? (
    <StyledNavItem className={_active}>
      <StyledNavTitle
        aria-expanded={active}
        aria-haspopup={true}
        aria-label={ariaLabel}
        className="nav-menu-title"
        onClick={handleActiveSubMenu}
      >
        {iconUrl && <StyledImageBefore alt="" src={iconUrl} />}
        <StyledNavLabel>{menuName}</StyledNavLabel>
        {!!subMenus.length && (
          <StyledArrow className="nav-arrow">
            <StyledArrowIcon className={`nav-arrow-icon ${_active}`} />
          </StyledArrow>
        )}
      </StyledNavTitle>
      <StyledNavContent>
        {subMenus.map((child, index) => (
          <MenuItem
            handleNavLinkClick={handleNavLinkClick}
            key={index}
            {...child}
          />
        ))}
      </StyledNavContent>
    </StyledNavItem>
  ) : menuUrl ? (
    <StyledNavItem className={menuUrl === SCR920 ? "" : "sub-menu"}>
      <StyledNavLink
        aria-label={menuName}
        exact
        to={{
          pathname:
            menuUrl === SCR115 || menuUrl === SCR212
              ? replaceString(menuUrl, concat(travelerId))
              : menuUrl,
          state: menuUrl === SCR917 ? { isChangePassword: true } : "",
        }}
        target={menuUrl.startsWith("http") ? "_blank" : ""}
        onClick={handleNavLinkClick}
      >
        {[SCR114_USER_SEARCH, SCR920, SCR991_FAQ, SCR992].includes(menuUrl) ? (
          <Fragment>
            {iconUrl && <StyledImageBefore alt="" src={iconUrl} />}
            {menuName}
          </Fragment>
        ) : iconUrl ? (
          <StyledImage>
            <StyledLabel>{menuName}</StyledLabel>
            {iconUrl && <StyledImageAfter alt="" src={iconUrl} />}
          </StyledImage>
        ) : (
          menuName
        )}
      </StyledNavLink>
    </StyledNavItem>
  ) : null
}
