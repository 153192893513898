import { Key, useState } from "react"
import { useTable } from "../../../../../../../../utils/hook/useTable"
import { UploadFileType } from "../../../../../models/files/upload"

export const useVariablesRowSelection = (dataLength: number) => {
  const [fileInfoListCurrent, setFileInfoListCurrent] = useState<
    UploadFileType[]
  >([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [dataCurrent, setDataCurrent] = useState<UploadFileType[]>([])

  const {
    onChange: onChangePage,
    perItem,
    perItemEnd,
    perItemStart,
  } = useTable(dataLength)

  return {
    dataCurrent,
    fileInfoListCurrent,
    isCheckAll,
    onChangePage,
    perItem,
    perItemEnd,
    perItemStart,
    selectedRowKeys,
    setDataCurrent,
    setFileInfoListCurrent,
    setIsCheckAll,
    setSelectedRowKeys,
  }
}
