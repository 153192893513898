/* eslint-disable complexity */
/* eslint-disable max-lines */
import { Routers } from "../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { SafetiesInfoDataType } from "../../../../models/info"
import {
  SafetiesAnswerConfirmReqType,
  SafetiesAnswerDataDetailType,
  SafetiesAnswerFormType,
} from "../../../../models/answer"
import { safetyApi } from "../../../../apis"
import { Variables } from "../../../../constants/answer/variables"
import { Translate } from "../../../../../../../constant/Translate"
import { setFieldsErrors } from "../../../../../../../utils/setFieldsErrors"
import keycloak from "../../../../../../../utils/keycloak"
import { useVariablesApi } from "../useVariablesApi"

const { SCR419, SCR420, SCR973 } = Routers
const { MESSAGE_E9997, MESSAGE_E9998, MESSAGE_E9999_TITLE } = Translate
const { FSST_LIST, SFTS_LIST } = Variables

export const useApi = () => {
  const {
    form,
    isLoading,
    isLoadingLayout,
    push,
    safetyDetailInfo,
    safetyInfo,
    setIsLoading,
    setIsLoadingLayout,
    setSafetyDetailInfo,
    setSafetyInfo,
    state,
  } = useVariablesApi()

  const handleGetSafetyDetail = async () => {
    if (state) {
      const { safetyDetailId, safetyId } = state
      const getDetailSafetyTraveler: Promise<SafetiesAnswerDataDetailType> =
        await safetyApi.getDetailSafetyTraveler({ safetyDetailId, safetyId })
      const getSafety: Promise<SafetiesInfoDataType> =
        await safetyApi.getSafety({
          safetyDetailId,
          safetyId,
        })
      Promise.all([getDetailSafetyTraveler, getSafety])
        .then((res) => {
          const { message, familySafetyStatus, safetyStatus } = res[0]
          form.setFieldsValue({
            familySafetyStatus: FSST_LIST.some(
              (item) => item.value === familySafetyStatus,
            )
              ? familySafetyStatus
              : FSST_LIST[0].value,
            message: message,
            safetyStatus: SFTS_LIST.some((item) => item.value === safetyStatus)
              ? safetyStatus
              : SFTS_LIST[0].value,
          })
          setSafetyDetailInfo(res[0])
          setSafetyInfo(res[1])
          setIsLoadingLayout(false)
        })
        .catch((error: AxiosErrorType) => {
          const stateError = pushStateError(error)
          push({ pathname: SCR973, state: stateError })
        })
    }
  }

  const onFinish = (values: SafetiesAnswerFormType) => {
    const data: SafetiesAnswerConfirmReqType = {
      ...values,
      isCheckExpired: state.isFromSCR416,
      updateDate: safetyDetailInfo?.updateDate || "",
    }
    safetyApi
      .confirmSafety(data, state?.safetyId, state?.safetyDetailId)
      .then(() => {
        push({
          pathname: SCR420,
          state: {
            isFromSCR416: state?.isFromSCR416,
            isFromSCR416History: state?.isFromSCR416History,
            isFromSCR417: state?.isFromSCR417,
            safetyId: state?.safetyId,
            searchCondition: state?.searchCondition,
            sourceURL: SCR419,
          },
        })
      })
      .catch((error: AxiosErrorType) => {
        if (error?.debug?.response?.data?.entityName) {
          setFieldsErrors(error, form)
          setIsLoading(false)
        } else if (error?.debug?.response?.data?.message) {
          const state = pushStateError(error)
          push({
            pathname: SCR973,
            state: { ...state, isSideBar: keycloak.token ? false : true },
          })
        } else {
          push({
            pathname: SCR973,
            state: {
              isSideBar: keycloak.token ? false : true,
              message: keycloak.token ? MESSAGE_E9997 : MESSAGE_E9998,
              title: MESSAGE_E9999_TITLE,
            },
          })
        }
      })
  }

  return {
    form,
    handleGetSafetyDetail,
    isLoading,
    isLoadingLayout,
    onFinish,
    push,
    safetyDetailInfo,
    safetyInfo,
    state,
  }
}
