/* eslint-disable max-lines */
import { useVariables } from "../useVariables"
import { useEffect } from "react"

export const useAttentionsInfo = () => {
  const {
    attentionInfo,
    getAttention,
    getAttentionTravelers,
    isLoading,
    isLoadingLayout,
    isShowFormSuccess,
    lang,
    setLang,
  } = useVariables()

  const changeLanguage = (value: string) => {
    setLang(value)
  }

  const handleCloseBrowser = () => {
    // ブラウザを閉じる。
    window.close()
  }

  useEffect(() => {
    getAttention()
  }, [])

  return {
    attentionInfo,
    changeLanguage,
    getAttentionTravelers,
    handleCloseBrowser,
    isLoading,
    isLoadingLayout,
    isShowFormSuccess,
    lang,
  }
}
