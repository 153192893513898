import { FC } from "react"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { Translate } from "../../../../../constant/Translate"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Alert } from "../../../../../_webui/layout/components/Alert"
import { BreakText } from "../../../../../_webui/layout/components/Typography/BreakText"
import { Table } from "../../../../../_webui/layout/components/Table"
import { Title } from "../../../../../_webui/layout/components/Typography/Title"
import { useAttentionsSuccess } from "./modules/useSuccess"
import { SuccessAlertHeading } from "../../components/success/SuccessAlertHeading"
import { Center } from "../../../../../_webui/layout/components/Center"
import { Button } from "../../../../../_webui/layout/components/Button"
import { Space } from "../../../../../_webui/layout/components/Space"
import { Breadcrumbs } from "../../../../../constant/Breadcrumbs"
import { Frame } from "../../../../../_webui/layout/components/Frame"

const {
  TEXT_T016,
  TEXT_T018,
  TEXT_T265,
  TEXT_T317,
  TEXT_T326,
  TEXT_T327,
  TEXT_T369,
} = Translate
const { BRCR_SCR315 } = Breadcrumbs

export const AttentionsSuccess: FC = () => {
  const {
    columns,
    goToHome,
    isCommonRefer,
    safetiesSelected,
    transmissionInfo,
    travelerInfoList,
  } = useAttentionsSuccess()

  return (
    <Layout breadcrumbItems={BRCR_SCR315} title={TEXT_T326}>
      <Card heading={TEXT_T326}>
        <Alert
          type="success"
          content={<BreakText above={TEXT_T327} below={TEXT_T016} />}
        />
        <Title titleLeft={TEXT_T369} />
        <Alert
          content={transmissionInfo?.bodyContent}
          heading={<SuccessAlertHeading transmissionInfo={transmissionInfo} />}
          isIcon={false}
          title={transmissionInfo?.attentionTitle}
          type="info"
        />
        <Title titleLeft={TEXT_T317} />
        <Table
          columns={columns}
          dataSource={travelerInfoList}
          pagination={false}
          rowKey="travelScheduleId"
        />
        {safetiesSelected && (
          <Frame titleLeft={TEXT_T265}>{safetiesSelected}</Frame>
        )}
        <Space isSpaceTop>
          <Center>
            <Button
              isFullWidth
              isHidden={!isCommonRefer}
              onClick={goToHome}
              text={TEXT_T018}
              type="ghost"
            />
          </Center>
        </Space>
      </Card>
    </Layout>
  )
}
