const FONT_YU_GOTHIC = "tkk-yu-gothic"
const FS_X_SMALL = "12px"
const FS_SMALL = "13px"
const FS_NORMAL = "14px"
const FS_LARGE = "16px"
const FS_X_LARGE = "18px"
const FS_XX_LARGE = "20px"
const FS_XXX_LARGE = "30px"
const FW_NORMAL = 400
const FW_MEDIUM = 500
const FW_BOLD = 600

export const Fonts = {
  FONT_YU_GOTHIC,
  FS_LARGE,
  FS_NORMAL,
  FS_SMALL,
  FS_XXX_LARGE,
  FS_XX_LARGE,
  FS_X_LARGE,
  FS_X_SMALL,
  FW_BOLD,
  FW_MEDIUM,
  FW_NORMAL,
}
