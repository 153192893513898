import { FC, Fragment } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { useAuthority } from "../../../../../../utils/hook/useAuthority"
import { ModalConfirm } from "../../../../../../_webui/layout/components/Modal/Confirm"
import { TravelersBatchDeleteModalConfirmProps } from "../../../models/batchDelete"

const { MESSAGE_C0003, MESSAGE_C0016, MESSAGE_C0019 } = Translate

export const TravelersBatchDeleteModalConfirm: FC<
  TravelersBatchDeleteModalConfirmProps
> = (props) => {
  const {
    bulkDeleteDataConfirm,
    isOpen,
    isOpenDeleted,
    onCancel,
    onCancelDeleted,
    onOk,
  } = props
  const { isRoleAdmin } = useAuthority()

  return (
    <Fragment>
      <ModalConfirm isOpen={isOpen} onCancel={onCancel} onOk={onOk}>
        {isRoleAdmin ? MESSAGE_C0016 : MESSAGE_C0003}
      </ModalConfirm>
      <ModalConfirm
        isOpen={isOpenDeleted}
        onCancel={onCancelDeleted}
        onOk={bulkDeleteDataConfirm}
      >
        {MESSAGE_C0019}
      </ModalConfirm>
    </Fragment>
  )
}
