import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../_webui/layout/components/Table/models"
import { Routers } from "../../../../../../../constant/Routers"
import { Translate } from "../../../../../../../constant/Translate"
import { getDay } from "../../../../../../../utils/getDate"
import { SchedulesConfirmDeleteDataType } from "../../../../models/confirmDelete"

const { TEXT_T003, TEXT_T006, TEXT_T025, TEXT_T237, TEXT_T238 } = Translate
const { SCR210_TRAVELERS } = Routers

export const useTableConfig = (
  schedulesURL: string,
): UseTableConfigType<SchedulesConfirmDeleteDataType> => {
  const columns: ColumnType<SchedulesConfirmDeleteDataType>[] = [
    {
      dataIndex: "travelStartDate",
      render: (_, data) => getDay(data.travelStartDate),
      title: TEXT_T237,
      width: 180,
    },
    {
      dataIndex: "travelEndDate",
      render: (_, data) => getDay(data.travelEndDate),
      title: TEXT_T238,
      width: 180,
    },
  ]

  if (schedulesURL === SCR210_TRAVELERS)
    columns.unshift.apply(columns, [
      {
        dataIndex: "fullNameKanji",
        title: TEXT_T003,
        width: 250,
      },
      {
        dataIndex: "fullNameRomaji",
        title: TEXT_T006,
        width: 250,
      },
      {
        dataIndex: "dispatchType",
        title: TEXT_T025,
        width: 250,
      },
    ])

  return {
    columns,
  }
}
