import { FC } from "react"
import { Form } from "../../../../../../_webui/layout/components/Form"
import { Layout } from "../../../../../../_webui/layout/components/Layout"
import { ModalConfirm } from "../../../../../../_webui/layout/components/Modal/Confirm"
import { Heading } from "../../../../../../_webui/layout/components/Typography/Heading"
import { Translate } from "../../../../../../constant/Translate"
import { validatorRequiredField } from "../../../../../../utils/validator/requiredField"
import { RolesSettingButtonGroup } from "../../../components/roles/setting/ButtonGroup"
import { RolesTable } from "../../../components/roles/Table"
import { RolesConfig } from "../../../constants/roles/setting/rolesConfig"
import { useRolesSetting } from "./modules/useSetting"
import { Styled } from "./styled"
import { RolesSettingTitle } from "../../../components/roles/setting/Title"

const { MESSAGE_C0006, TEXT_T398, TEXT_T453, TEXT_T477 } = Translate
const { ROLE_ATTENTION, ROLE_SAFETY, ROLE_SCHEDULE, ROLE_TRAVELER } =
  RolesConfig
const { StyledItemSelect } = Styled

export const RolesSetting: FC = () => {
  const {
    authorityPatterns,
    breadcrumbItems,
    form,
    goBack,
    goToSubAdminSetting,
    isHiddenBtn,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenLink,
    onCancel,
    onCancelLink,
    onChangePatternId,
    onFinish,
    onOk,
    onOpenLink,
    patternId,
  } = useRolesSetting()

  return (
    <Layout
      breadcrumbItems={breadcrumbItems}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T398}
    >
      <Form
        form={form}
        heading={TEXT_T398}
        isLoading={isLoading}
        layout="vertical"
        onFinish={onFinish}
      >
        <StyledItemSelect
          label={TEXT_T453}
          name="patternId"
          onChange={onChangePatternId}
          rules={[validatorRequiredField]}
          selectItem={authorityPatterns}
        />
        <Heading title={TEXT_T477} />
        <RolesSettingTitle
          isOpenLink={isOpenLink}
          onCancelLink={onCancelLink}
          onOpenLink={onOpenLink}
        />
        {patternId && (
          <RolesTable
            attentionOptions={ROLE_ATTENTION}
            safetyOptions={ROLE_SAFETY}
            scheduleOptions={ROLE_SCHEDULE}
            travelerOptions={ROLE_TRAVELER}
          />
        )}
        <RolesSettingButtonGroup
          goBack={goBack}
          goToSubAdminSetting={goToSubAdminSetting}
          isHiddenBtn={isHiddenBtn}
          patternId={patternId}
        />
      </Form>
      <ModalConfirm isOpen={isOpen} onCancel={onCancel} onOk={onOk}>
        {MESSAGE_C0006}
      </ModalConfirm>
    </Layout>
  )
}
