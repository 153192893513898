import { GridItemType } from "../../../../../../../../_webui/layout/components/Grid/models"
import { Translate } from "../../../../../../../../constant/Translate"
import { getTextConfirm } from "../../../../../../../../utils/getTextConfirm"
import { ScheduleInfoType } from "../../../../../models/addSuccess"

const { TEXT_T262 } = Translate

export const getConfirmGridConfig = (
  scheduleInfo: ScheduleInfoType,
): GridItemType[] => [
  {
    key: TEXT_T262,
    text: getTextConfirm(scheduleInfo.codeConductConfirmation),
  },
]
