import styled from "styled-components"
import { getValueByDevice } from "../../../../../../../utils/getValueByDevice"
import { ItemSelect } from "../../../../../../../_webui/layout/components/Form/Item/Select"

const StyledItemSelect = styled(ItemSelect)`
  max-width: ${getValueByDevice("300px", "100%")};
`

export const Styled = {
  StyledItemSelect,
}
