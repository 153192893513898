import { FC } from "react"
import { Switch } from "react-router-dom"
import PrivateRoute from "../../../../app/routing/privateRoute"
import { Routers } from "../../../../constant/Routers"
import { RolesSettingPage } from "../../../../app/pages/settings/roles/setting"
import { RolesSuccessPage } from "../../../../app/pages/settings/roles/success"
import { SubAdminPage } from "../../../../app/pages/settings/subAdmin"
import { SubAdminConfirmPage } from "../../../../app/pages/settings/subAdmin/confirm"
import { SubAdminSettingPage } from "../../../../app/pages/settings/subAdmin/setting"
import { TimezoneSettingPage } from "../../../../app/pages/settings/timezone/setting"
import { TimezoneSuccessPage } from "../../../../app/pages/settings/timezone/success"
import { FilesUploadPage } from "../../../../app/pages/settings/files/upload"
import { FilesManagerPage } from "../../../../app/pages/settings/files/manager"
import { FilesListPage } from "../../../../app/pages/settings/files/list"

const {
  SCR930,
  SCR931,
  SCR950,
  SCR951,
  SCR952,
  SCR953,
  SCR954,
  SCR970,
  SCR971,
  SCR972,
} = Routers

export const SettingsRoute: FC = () => (
  <Switch>
    <PrivateRoute component={TimezoneSettingPage} exact path={SCR930} />
    <PrivateRoute component={TimezoneSuccessPage} exact path={SCR931} />
    <PrivateRoute component={SubAdminSettingPage} exact path={SCR950} />
    <PrivateRoute component={SubAdminConfirmPage} exact path={SCR951} />
    <PrivateRoute component={SubAdminPage} exact path={SCR952} />
    <PrivateRoute component={RolesSettingPage} exact path={SCR953} />
    <PrivateRoute component={RolesSuccessPage} exact path={SCR954} />
    <PrivateRoute component={FilesUploadPage} exact path={SCR971} />
    <PrivateRoute component={FilesManagerPage} exact path={SCR970} />
    <PrivateRoute component={FilesListPage} exact path={SCR972} />
  </Switch>
)
