import { useEffect } from "react"
import { Routers } from "../../../../../../../../constant/Routers"
import { backToLogin } from "../../../../../../../../utils/backToLogin"
import { UseFormTimezoneSuccessType } from "../../../../../models/timezone/success"
import { useVariables } from "../useVariables/index"
import { TimezoneResType } from "../../../../../../../../infrastructure/handlers/timezone/models"
import { timezoneApi } from "../../../../../../../../infrastructure/handlers/timezone"
import { GeneralCode } from "../../../../../../../../constant/GeneralCode"
import { generalMastersApi } from "../../../../../../../../infrastructure/handlers/generalMasters"
import { AxiosErrorType } from "../../../../../../../../infrastructure/axiosError/models"
import { pushStateError } from "../../../../../../../../utils/pushStateError"
import { GeneralMastersResType } from "../../../../../../../../infrastructure/handlers/generalMasters/models"
import { getValueByLanguage } from "../../../../../../../../utils/getValueByLanguage"
import { commonApi } from "../../../../../apis"
import {
  summerTimeText,
  summerTimeValue,
} from "../../../../../../../../utils/summerTime"
import { Constant } from "../../../../../constants/timezone"

const { TIMEZONE_GRID_ITEMS } = Constant
const { TIFM } = GeneralCode
const { SCR920, SCR930, SCR973 } = Routers

export const useTimezoneSuccess = (): UseFormTimezoneSuccessType => {
  const {
    detail,
    isCommonRefer,
    isHiddenReset,
    isHiddenSubmit,
    isLoadingLayout,
    push,
    setDetail,
    setIsLoadingLayout,
  } = useVariables()

  // 「SCR920_ホ－ム画面」に遷移する。
  const goToHome = () => {
    push(SCR920)
  }

  // 「SCR930_タイムゾ－ンの選択画面」に遷移する
  const goToTimeZoneSetting = () => {
    push(SCR930)
  }

  const callApi = async () => {
    // APIを呼び出し、APIからの応答結果を「M.timeZoneInfo」変数に設定する。
    const getTimezones: GeneralMastersResType[] = await commonApi.getTimezones()
    // APIを呼び出し、APIからの応答結果を「M.timeFormatInfo」変数に設定する。
    const getGeneralTimeFormat: GeneralMastersResType[] =
      await generalMastersApi.getGeneralMaster(TIFM)
    // APIを呼び出し、APIからの応答結果を「M.timeZoneInfo」変数に設定する。
    const getTimezone: TimezoneResType = await timezoneApi.getTimezone()

    Promise.all([getTimezones, getGeneralTimeFormat, getTimezone])
      .then((res) => {
        const timeZoneItem = res[0].find(
          (item) =>
            item.key1 === res[2].timeZone &&
            item.key2 === summerTimeValue(res[2].isSummerTime) &&
            item.key5 === res[2].key5,
        )
        if (timeZoneItem) {
          TIMEZONE_GRID_ITEMS[0].text = `${getValueByLanguage(
            timeZoneItem.value1,
            timeZoneItem.value2,
          )} (GMT${timeZoneItem.value3})${summerTimeText(timeZoneItem?.key2)}`
        }
        const timeFormatItem = res[1].find(
          (item) => item.key1 === res[2].timeFormat,
        )
        if (timeFormatItem) {
          TIMEZONE_GRID_ITEMS[1].text = getValueByLanguage(
            timeFormatItem.value1,
            timeFormatItem.value2,
          )
        }
        setDetail(TIMEZONE_GRID_ITEMS)
        setIsLoadingLayout(false)
      })
      .catch((error: AxiosErrorType) => {
        const state = pushStateError(error)
        push({ pathname: SCR973, state })
      })
  }

  useEffect(() => {
    // ユーザーの画面アクセス許可チェックを処理する。
    if (!isCommonRefer) {
      // 「SCR973_エラー情報の表示画面」に遷移する。
      const { pathname, state } = backToLogin()
      push({ pathname, state })
    } else callApi()
  }, [])

  return {
    detail,
    goToHome,
    goToTimeZoneSetting,
    isHiddenReset,
    isHiddenSubmit,
    isLoadingLayout,
  }
}
