/* eslint-disable max-lines */
import styled, { css } from "styled-components"
import { Regex } from "../../../../../constant/Regex"
import { Colors } from "../../../../../constant/Styled/Colors"
import { Fonts } from "../../../../../constant/Styled/Fonts"
import { StyledAlertTypeProps } from "../models"

const {
  BORDER,
  BORDER_EXCEPTION,
  BOX_HEADER,
  BG_EXCEPTION,
  DANGER,
  LIGHT,
  PRIMARY,
} = Colors
const {
  FS_LARGE,
  FS_NORMAL,
  FS_X_LARGE,
  FS_XX_LARGE,
  FS_XXX_LARGE,
  FW_BOLD,
  FW_MEDIUM,
} = Fonts
const { REGEX_IS_DESKTOP } = Regex

const StyledAlertContent = styled.div`
  word-break: break-word;
`

const StyledAlertIcon = styled.div<StyledAlertTypeProps>`
  ${() =>
    !REGEX_IS_DESKTOP &&
    css`
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    `}
  ${(props) =>
    props.type === "success" &&
    css`
      margin-right: 30px;
      min-width: 50px;
    `}
`

const StyledAlertTypeDanger = (props: StyledAlertTypeProps) => css`
  ${props.type === "danger" &&
  css`
    border: 1px solid ${DANGER};
    border-top: none;
    border-radius: 10px;
    flex-wrap: wrap;
    overflow: hidden;
    ${StyledAlertContent} {
      padding-bottom: 50px;
      padding-left: 20px;
      padding-right: 20px;
    }
    ${StyledAlertIcon} {
      align-items: center;
      display: flex;
      margin-bottom: 0;
      padding: 20px;
      text-align: left;
      width: 100%;
      word-break: break-word;
    }
  `}
`

const StyledAlertTypeInfo = (props: StyledAlertTypeProps) => css`
  ${props.type === "info" &&
  css`
    border: 1px solid ${BORDER};
    border-radius: 10px;
    flex-wrap: wrap;
    overflow: hidden;
    ${StyledAlertContent} {
      padding: 20px;
    }
    ${StyledAlertIcon} {
      width: 100%;
      margin-bottom: 0;
    }
  `}
`

const StyledAlertTypeException = (props: StyledAlertTypeProps) => css`
  ${props.type === "exception" &&
  css`
    background-color: ${BG_EXCEPTION};
    border: 2px solid ${BORDER_EXCEPTION};
    border-radius: 4px;
    flex-wrap: wrap;
    padding: 20px;
    ${StyledAlertContent} {
      font-size: ${FS_NORMAL};
    }
    ${StyledAlertIcon} {
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
    }
  `}
`

const StyledAlertTypeSuccess = (props: StyledAlertTypeProps) => css`
  ${props.type === "success" &&
  css`
    flex-wrap: nowrap;
    padding: 20px;
  `}
`

const StyledAlert = styled.div<StyledAlertTypeProps>`
  display: flex;
  font-size: ${FS_LARGE};
  font-weight: ${FW_MEDIUM};
  ${(props) => StyledAlertTypeDanger(props)}
  ${(props) => StyledAlertTypeInfo(props)}
  ${(props) => StyledAlertTypeException(props)}
  ${(props) => StyledAlertTypeSuccess(props)}
  ${() =>
    REGEX_IS_DESKTOP
      ? css`
          align-items: center;
        `
      : css`
          flex-wrap: wrap;
          padding-left: 0;
          padding-right: 0;
        `}
`

const StyledAlertHeading = styled.div<StyledAlertTypeProps>`
  width: 100%;
  ${(props) =>
    props.type === "danger" &&
    css`
      background-color: ${DANGER};
      color: ${LIGHT};
      font-size: ${FS_XX_LARGE};
      line-height: 40px;
      padding-left: 20px;
      padding-right: 20px;
    `}
  ${(props) =>
    props.type === "info" &&
    css`
      background-color: ${BOX_HEADER};
      padding: 10px 17px;
    `}
`

const StyledAlertTitle = styled.span<StyledAlertTypeProps>`
  ${(props) =>
    props.type === "danger" &&
    css`
      color: ${DANGER};
      margin-left: 5px;
      ${REGEX_IS_DESKTOP
        ? css`
            font-size: ${FS_XXX_LARGE};
          `
        : css`
            font-size: ${FS_X_LARGE};
            font-weight: ${FW_BOLD};
          `};
    `}
  ${(props) =>
    props.type === "exception" &&
    css`
      font-size: ${FS_XX_LARGE};
      font-weight: ${FW_BOLD};
    `}
  ${(props) =>
    props.type === "info" &&
    css`
      background-color: ${PRIMARY};
      color: ${LIGHT};
      display: block;
      padding: 10px 20px;
      text-align: left;
      word-break: break-word;
    `}
`

export const Styled = {
  StyledAlert,
  StyledAlertContent,
  StyledAlertHeading,
  StyledAlertIcon,
  StyledAlertTitle,
}
