import styled from "styled-components"
import { Regex } from "../../../../../../constant/Regex"
import { Colors } from "../../../../../../constant/Styled/Colors"

const { REGEX_IS_DESKTOP } = Regex
const { BORDER } = Colors

const StyledTabsLabel = styled.div`
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
`

const StyledTabsTotal = styled.div`
  border: 1px solid ${BORDER};
  border-radius: 4px;
  height: 25px;
  line-height: 25px;
  margin-right: ${REGEX_IS_DESKTOP ? "10px" : "5px"};
  text-align: center;
  min-width: 36px;
`

export const Styled = {
  StyledTabsLabel,
  StyledTabsTotal,
}
