import { FC } from "react"
import {
  AttentionsDetailDataSourceType,
  AttentionsDetailTableProps,
} from "../../../models/detail"
import { Table } from "../../../../../../_webui/layout/components/Table"

export const AttentionsDetailTable: FC<
  AttentionsDetailTableProps<AttentionsDetailDataSourceType>
> = (props) => {
  const {
    columns,
    isAttentionsBrowseResponse,
    isResetTable,
    onChange,
    perItem,
    rowSelection,
    userInfoList,
  } = props

  return (
    <Table
      columns={columns}
      dataSource={userInfoList}
      isResetTable={isResetTable}
      onChange={onChange}
      pagination={isAttentionsBrowseResponse}
      perItem={perItem}
      rowKey="attentionDetailId"
      // eslint-disable-next-line no-undefined
      rowSelection={isAttentionsBrowseResponse ? rowSelection : undefined}
    />
  )
}
