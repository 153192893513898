import styled, { css } from "styled-components"
import { Regex } from "../../../../../../../../constant/Regex"
import { ItemSelect } from "../../../../../../../../_webui/layout/components/Form/Item/Select"
import { ItemInput } from "../../../../../../../../_webui/layout/components/Form/Item/Input"

const { REGEX_IS_DESKTOP } = Regex

const StyledGridCategory = styled.div`
  display: ${REGEX_IS_DESKTOP && "flex"};
`

const StyledGridCategorySelect = styled(ItemSelect)`
  ${REGEX_IS_DESKTOP &&
  css`
    max-width: 250px;
  `}
`

const StyledGridCategoryInput = styled(ItemInput)`
  ${REGEX_IS_DESKTOP &&
  css`
    margin-left: 20px !important;
  `};
  .ant-form-item-label {
    width: 0px;
  }
`

export const Styled = {
  StyledGridCategory,
  StyledGridCategoryInput,
  StyledGridCategorySelect,
}
