const SCR910 = "/login"
const SCR911 = "/logout"
const SCR915 = "/rules"
const SCR916 = "/reset-password"
const SCR917 = "/change-password"
const SCR920 = "/home"
const SCR930 = "/timezone-setting"
const SCR931 = "/timezone-success"
const SCR950 = "/sub-admin-setting"
const SCR951 = "/sub-admin-confirm"
const SCR952 = "/sub-admin"
const SCR953 = "/roles-setting"
const SCR954 = "/roles-success/:id"
const SCR960 = "/schedules/clone"
const SCR961 = "/attentions/clone"
const SCR962 = "/safeties/clone"
const SCR970 = "/files-manager"
const SCR971 = "/files-upload"
const SCR972 = "/files"
const SCR973 = "/error"
const SCR980 = "/external/file-upload"
const SCR981 = "/external/upload-results"
const SCR991_FAQ = "/faq"
const SCR991_SAFETY_MEASURES = "/systems/safety-measures-handbook"
const SCR991_SYSTEM_MANUAL = "/user-support/system-manual"
const SCR991_USER = "/systems/user"
const SCR991_TEMPLATE = "/application-form/template"
const SCR991_REGISTRATION_APPLICATION_FORM =
  "/application-form/registration-application-form"
const SCR991_CHANGE_APPLICATION_FORM =
  "/application-form/change-application-form"
const SCR991_AUTHORITY_PATTERN = "/application-form/authority-pattern"
const SCR991_SUMMER_TIME = "/application-form/summertime"
const SCR992 = "/contact"
const SCR993 = "/contact-success"
const SCR994 = "/user-support/privacy-policy"

export const CommonRouters = {
  SCR910,
  SCR911,
  SCR915,
  SCR916,
  SCR917,
  SCR920,
  SCR930,
  SCR931,
  SCR950,
  SCR951,
  SCR952,
  SCR953,
  SCR954,
  SCR960,
  SCR961,
  SCR962,
  SCR970,
  SCR971,
  SCR972,
  SCR973,
  SCR980,
  SCR981,
  SCR991_AUTHORITY_PATTERN,
  SCR991_CHANGE_APPLICATION_FORM,
  SCR991_FAQ,
  SCR991_REGISTRATION_APPLICATION_FORM,
  SCR991_SAFETY_MEASURES,
  SCR991_SUMMER_TIME,
  SCR991_SYSTEM_MANUAL,
  SCR991_TEMPLATE,
  SCR991_USER,
  SCR992,
  SCR993,
  SCR994,
}
