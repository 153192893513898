import { RuleObject } from "antd/lib/form"
import { Regex } from "../../../constant/Regex"
import { Translate } from "../../../constant/Translate"

const { REGEX_ALPHANUMERIC } = Regex
const { MESSAGE_E0005 } = Translate

export const validatorAlphanumeric = () => ({
  validator(_: RuleObject, value: string) {
    if (value && !REGEX_ALPHANUMERIC.exec(value)) {
      return Promise.reject(new Error(MESSAGE_E0005))
    }
    return Promise.resolve()
  },
})
