import { FC } from "react"
import { ButtonGroup } from "../../../../../_webui/layout/components/Button/Group"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { Table } from "../../../../../_webui/layout/components/Table"
import { Title } from "../../../../../_webui/layout/components/Typography/Title"
import { TotalRecords } from "../../../../../_webui/layout/components/Typography/TotalRecords"
import { Breadcrumbs } from "../../../../../constant/Breadcrumbs"
import { Translate } from "../../../../../constant/Translate"
import { useAttentionsClone } from "./modules/useClone"

const { TEXT_T072, TEXT_T310, TEXT_T493, TEXT_T494, TEXT_T456 } = Translate
const { BRCR_SCR961 } = Breadcrumbs

export const AttentionsClone: FC = () => {
  const {
    attentionInfoList,
    columns,
    goBack,
    isActiveBtnClone,
    isHiddenCopyBtn,
    isLoadingLayout,
    onChange,
    onChangeTable,
    onCopy,
    perItem,
    rowSelection,
  } = useAttentionsClone()

  return (
    <Layout
      breadcrumbItems={BRCR_SCR961}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T493}
    >
      <Card heading={TEXT_T493} isFullHeight>
        <Title titleLeft={TEXT_T494} />
        <TotalRecords
          perItem={perItem}
          titleLeft={TEXT_T456}
          total={attentionInfoList?.length}
        />
        <Table
          columns={columns}
          dataSource={attentionInfoList}
          onChange={onChange}
          onChangeTable={onChangeTable}
          rowKey="attentionId"
          rowSelection={rowSelection}
        />
        <ButtonGroup
          buttonItems={[
            {
              isAbsolute: true,
              isHidden: isHiddenCopyBtn,
              isSmall: true,
              onClick: goBack,
              order: 2,
              text: TEXT_T072,
              type: "ghost",
            },
            {
              isDisabled: !isActiveBtnClone,
              isHidden: isHiddenCopyBtn,
              onClick: onCopy,
              order: 1,
              text: TEXT_T310,
              type: "primary",
            },
          ]}
        />
      </Card>
    </Layout>
  )
}
