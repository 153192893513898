import {
  RolesSettingFormDataSubmitType,
  RolesSettingFormDataType,
} from "../../../../../../models/roles/setting"

export const convertDataSource = (
  data: RolesSettingFormDataType,
  updateDate: string,
): RolesSettingFormDataSubmitType => ({
  patternId: data.patternId,
  subFunctionIds: [
    ...data.authorityAttention,
    ...data.authoritySafetyConfirm,
    ...data.authorityTravelScheduleInfo,
    ...data.authorityTravelerInfo,
  ],
  updateDate,
})
