import { FC } from "react"
import { SpaceProps } from "./models"
import { Styled } from "./styled"

const { StyledSpace } = Styled

export const Space: FC<SpaceProps> = (props) => {
  const {
    children,
    isSpaceAll,
    isSpaceBottom,
    isSpaceLeft,
    isSpaceRight,
    isSpaceTop,
  } = props

  return (
    <StyledSpace
      isSpaceAll={isSpaceAll}
      isSpaceBottom={isSpaceBottom}
      isSpaceLeft={isSpaceLeft}
      isSpaceRight={isSpaceRight}
      isSpaceTop={isSpaceTop}
    >
      {children}
    </StyledSpace>
  )
}
