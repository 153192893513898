import { useHistory, useLocation } from "react-router-dom"
import { useModal } from "../../../../../../../utils/hook/useModal"
import { useRouters } from "../useRouters"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { getBreadcrumbItems } from "./getBreadcrumbItems"
import { SchedulesDetailsStateType } from "../../../../models/detail"
import { useApi } from "../../../addSuccess/modules/useApi"

export const useVariables = () => {
  const { push } = useHistory()
  const { state, pathname } = useLocation<SchedulesDetailsStateType>()
  const { isOpen, onCancel, onOpen } = useModal()

  const {
    isNotAccessScreen,
    isVisibleBtnDelete: isVisibleBtnDeleteAuthority,
    isVisibleBtnEdit,
    isVisibleBtnRefer,
    isVisibleBtnSearch,
    isVisibleOtherRemarks,
  } = useVariablesAuthority(state?.isFromSCR114UserSearch, state?.schedulesURL)

  const breadcrumbItems = getBreadcrumbItems(
    !!state?.isFromSCR210,
    !!state?.isFromSCR114UserSearch,
    state?.schedulesURL,
  )

  const {
    assignmentGridItem,
    confirmGridItem,
    detailsColumnsFirst,
    detailsGridItem,
    detailsTabsItems,
    generalMastersRemark,
    getSchedule,
    insuranceGridItem,
    isLoadingLayout,
    isVisibleBtnDelete: _isVisibleBtnDelete,
    lodgingsColumns,
    lodgingsTabsItems,
    otherGridItem,
    personalGridItem,
    scheduleDetails,
    scheduleLodgings,
    scheduleStays,
    schedulesData,
    setIsLoadingLayout,
    staysColumns,
    staysTabsItems,
    travelScheduleId,
  } = useApi(isVisibleOtherRemarks)
  const { goToTravelerDetail, onEdit, onSearch } = useRouters({
    isSubBumon: schedulesData.info.isSubBumon,
    travelerId: schedulesData?.info?.travelerId,
  })

  return {
    assignmentGridItem,
    breadcrumbItems,
    confirmGridItem,
    detailsColumnsFirst,
    detailsGridItem,
    detailsTabsItems,
    generalMastersRemark,
    getSchedule,
    goToTravelerDetail,
    insuranceGridItem,
    isLoadingLayout,
    isNotAccessScreen,
    isOpen,
    isVisibleBtnDelete: isVisibleBtnDeleteAuthority && _isVisibleBtnDelete,
    isVisibleBtnEdit,
    isVisibleBtnRefer,
    isVisibleBtnSearch,
    isVisibleOtherRemarks,
    lodgingsColumns,
    lodgingsTabsItems,
    onCancel,
    onEdit,
    onOpen,
    onSearch,
    otherGridItem,
    pathname,
    personalGridItem,
    push,
    scheduleDetails,
    scheduleLodgings,
    scheduleStays,
    schedulesData,
    setIsLoadingLayout,
    state,
    staysColumns,
    staysTabsItems,
    travelScheduleId,
  }
}
