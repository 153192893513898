/* eslint-disable max-lines */
import styled, { css } from "styled-components"
import { Regex } from "../../../../../../constant/Regex"
import { Colors } from "../../../../../../constant/Styled/Colors"
import { Fonts } from "../../../../../../constant/Styled/Fonts"

const { DANGER, LIGHT, PRIMARY } = Colors
const { FS_XX_LARGE, FW_BOLD } = Fonts
const { REGEX_IS_DESKTOP } = Regex

const StyledTimezone = styled.div`
  display: flex;
  justify-content: center;
  ${REGEX_IS_DESKTOP
    ? css`
        align-items: center;
      `
    : css`
        flex-wrap: wrap;
      `}
`

const StyledTimezoneClockNumber = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  &::after {
    background-color: ${PRIMARY};
    content: "";
    height: 8px;
    left: 50%;
    position: absolute;
    top: 4px;
    transform: translateX(-50%);
    width: 1px;
  }
`

const StyledTimezoneClock = styled.div`
  background-color: ${LIGHT};
  border-radius: 250px;
  border: 3px solid ${PRIMARY};
  height: 109px;
  margin-bottom: 20px;
  max-width: 109px;
  min-width: 109px;
  overflow: hidden;
  position: relative;
  ${StyledTimezoneClockNumber} {
    &:nth-child(3),
    &:nth-child(6),
    &:nth-child(9),
    &:nth-child(12) {
      &::after {
        width: 2px;
      }
    }
    &:nth-child(1) {
      transform: rotate(30deg);
    }
    &:nth-child(2) {
      transform: rotate(60deg);
    }
    &:nth-child(3) {
      transform: rotate(90deg);
    }
    &:nth-child(4) {
      transform: rotate(120deg);
    }
    &:nth-child(5) {
      transform: rotate(150deg);
    }
    &:nth-child(6) {
      transform: rotate(180deg);
    }
    &:nth-child(7) {
      transform: rotate(210deg);
    }
    &:nth-child(8) {
      transform: rotate(240deg);
    }
    &:nth-child(9) {
      transform: rotate(270deg);
    }
    &:nth-child(10) {
      transform: rotate(300deg);
    }
    &:nth-child(11) {
      transform: rotate(330deg);
    }
    &:nth-child(12) {
      transform: rotate(360deg);
    }
  }
  ${REGEX_IS_DESKTOP &&
  css`
    margin-top: 20px;
  `}
`

const StyledTimezoneFormat = styled.div`
  color: ${DANGER};
  font-size: ${FS_XX_LARGE};
  font-weight: ${FW_BOLD};
  ${REGEX_IS_DESKTOP
    ? css`
        margin-left: 53px;
      `
    : css`
        margin-bottom: 20px;
        text-align: center;
        width: 100%;
      `}
`

const StyledTimezoneHand = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`

const CssTimezoneGeneral = styled.div`
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
`

const StyledTimezoneHandIcon = styled(CssTimezoneGeneral)`
  background-color: ${DANGER};
`

const StyledTimezoneHandMinutes = styled(StyledTimezoneHandIcon)`
  border-radius: 2px;
  max-height: 46px;
  min-height: 46px;
  max-width: 2px;
  min-width: 2px;
  transform: translateY(-16px);
`

const StyledTimezoneHandHour = styled(StyledTimezoneHandIcon)`
  border-radius: 4px;
  max-height: 36px;
  min-height: 36px;
  max-width: 5px;
  min-width: 5px;
  transform: translateY(-10px);
`

const StyledTimezoneDot = styled(CssTimezoneGeneral)`
  background-color: ${LIGHT};
  border-radius: 50%;
  height: 3px;
  width: 3px;
`

export const Styled = {
  StyledTimezone,
  StyledTimezoneClock,
  StyledTimezoneClockNumber,
  StyledTimezoneDot,
  StyledTimezoneFormat,
  StyledTimezoneHand,
  StyledTimezoneHandHour,
  StyledTimezoneHandMinutes,
}
