import { useHistory, useLocation, useParams } from "react-router-dom"
import { Routers } from "../../../../../../../constant/Routers"
import { getUserInformation } from "../../../../../../../utils/getUserInformation"
import { replaceString } from "../../../../../../../utils/replaceString"
import { UseParamsType } from "../../../../../../../utils/useParams"
import {
  TravelersDetailStateType,
  UseRoutersProps,
} from "../../../../models/detail"

const {
  SCR114_USER,
  SCR114_USER_SEARCH,
  SCR116,
  SCR210,
  SCR210_TRAVELERS,
  SCR210_USER_SEARCH,
  SCR211,
  SCR920,
} = Routers

export const useRouters = (props: UseRoutersProps) => {
  const { fullNameRomaji, isFromSCR114User } = props
  const { id } = useParams<UseParamsType>()
  const { pathname, state } = useLocation<TravelersDetailStateType>()
  const { push } = useHistory()
  const userInformation = getUserInformation()

  const goBack = () => {
    if (state?.isFromSCR211)
      push(replaceString(SCR211, state.travelScheduleId), { ...state })
    //「SCR920_ホーム画面」に遷移する。
    else if (id === userInformation?.travelerId) push(SCR920)
    //「SCR114_渡航者情報の一覧画面」に遷移する。
    else if (state?.isFromSCR114User) {
      push({
        pathname: SCR114_USER,
        state: {
          searchCondition: state?.searchCondition,
        },
      })
    } else {
      push({
        pathname: SCR114_USER_SEARCH,
        state: {
          isFromSCR115: true,
          searchCondition: state?.searchCondition,
        },
      })
    }
  }

  const goToTravelersEdit = () => {
    //「SCR116_渡航者情報の変更画面」に遷移する。
    push(replaceString(SCR116, id), {
      ...state,
      isFromSCR115: true,
      sourceURL: pathname,
    })
  }

  const goToSchedulesList = () => {
    //「SCR210_渡航予定情報の一覧画面」に遷移する。
    if (isFromSCR114User) {
      push(SCR210_TRAVELERS, {
        fullName: fullNameRomaji,
        isFromSCR115: true,
        travelerId: id,
      })
    } else if (state?.isFromSCR114UserSearch) {
      push(SCR210_USER_SEARCH, {
        isFromSCR114UserSearch: true,
        isFromSCR115: true,
        searchConditionTraveler: state?.searchCondition,
        travelerId: id,
      })
    } else if (state.isFromSCR211) {
      push(SCR210_TRAVELERS)
    } else push(SCR210)
  }

  return {
    goBack,
    goToSchedulesList,
    goToTravelersEdit,
  }
}
