import Select, {
  RefSelectProps,
  SelectProps,
  SelectValue,
} from "antd/lib/select"
import { Children, forwardRef, Ref } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { pick } from "lodash"
import { AnyValue } from "../../../../models"

const { TEXT_T517 } = Translate

const _selectComponentClass = <T extends SelectValue>(
  { children, ...props }: SelectProps<T>,
  ref: Ref<RefSelectProps>,
) => (
  <Select ref={ref} {...props}>
    {Children.map(children as AnyValue, (child) => (
      <Select.Option key={child.props.value} value={child.props.value}>
        {child.props.value} {TEXT_T517}
      </Select.Option>
    ))}
  </Select>
)

export const selectComponentClass = forwardRef(
  _selectComponentClass,
) as unknown as typeof Select

Object.assign(
  selectComponentClass,
  pick(Select, ["SECRET_COMBOBOX_MODE_DO_NOT_USE", "Option", "OptGroup"]),
)
