import styled, { css } from "styled-components"
import { Constant } from "../../../../../../../constant"
import { Colors } from "../../../../../../../constant/Styled/Colors"
import { Fonts } from "../../../../../../../constant/Styled/Fonts"
import { StyledItemLabelProps, StyledItemLabelRequiredProps } from "../models"

const { DANGER, LABEL_BLUE, LIGHT } = Colors
const { FS_LARGE, FS_SMALL, FW_MEDIUM } = Fonts
const { CHECK_SELECTED_LANG } = Constant

const StyledItemLabel = styled.span<StyledItemLabelProps>`
  flex-grow: 1;
  font-size: ${FS_LARGE};
  font-weight: ${FW_MEDIUM};
  text-align: right;
  ${(props) =>
    props.isHiddenLabel &&
    css`
      height: 0;
      margin-right: 0;
      min-height: initial;
      overflow: hidden;
      text-indent: 100%;
      width: 0;
    `}
`

const StyledItemLabelRequired = styled.div<StyledItemLabelRequiredProps>`
  background-color: ${(props) => (props.isBgBlue ? LABEL_BLUE : DANGER)};
  border-radius: 4px;
  color: ${LIGHT};
  font-size: ${FS_SMALL};
  height: 29px;
  margin-left: 10px;
  min-width: ${CHECK_SELECTED_LANG ? "40px" : "62px"};
  padding: 4px;
  text-align: center;
`

export const Styled = {
  StyledItemLabel,
  StyledItemLabelRequired,
}
