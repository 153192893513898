import styled, { css } from "styled-components"
import { Regex } from "../../../../../constant/Regex"
import { StyledCenterProps } from "../models"

const { REGEX_IS_DESKTOP } = Regex

const StyledCenter = styled.div<StyledCenterProps>`
  text-align: center;
  position: relative;
  ${(props) =>
    props.isFlex &&
    css`
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    `}
  ${(props) =>
    props.isMarginTop &&
    css`
      margin-top: 40px;
      margin-bottom: ${REGEX_IS_DESKTOP && "15px"};
    `}
  ${(props) =>
    !REGEX_IS_DESKTOP &&
    props.isBtnFullWidth &&
    css`
      .ant-btn {
        width: 100%;
      }
    `}
`

export const Styled = {
  StyledCenter,
}
