import { FC } from "react"
import { Card } from "../../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../../_webui/layout/components/Layout"
import { Table } from "../../../../../../_webui/layout/components/Table"
import { TotalRecords } from "../../../../../../_webui/layout/components/Typography/TotalRecords"
import { Breadcrumbs } from "../../../../../../constant/Breadcrumbs"
import { Translate } from "../../../../../../constant/Translate"
import { FilesListButtonGroup } from "../../../components/files/list/ButtonGroup"
import { useFiles } from "./modules/useFiles"
import { Modal } from "../../../../../../_webui/layout/components/Modal"
import { FilesUploadForm } from "../../../components/files/upload/Form"

const { MESSAGE_C0052, TEXT_T189, TEXT_T509 } = Translate
const { BRCR_SCR972 } = Breadcrumbs

export const FilesList: FC = () => {
  const {
    allCountries,
    columns,
    dataSource,
    fileTypeList,
    form,
    goToHome,
    handleDownload,
    handleGetDataSource,
    handlePreviewFile,
    isDisabledBtnDownload,
    isDisabledBtnPreview,
    isLoading,
    isLoadingLayout,
    isOpenPreviewFile,
    isVisibleBtnCancel,
    isVisibleBtnDownload,
    isVisibleBtnReference,
    onCancelPreviewFile,
    onChangePagination,
    onChangeTable,
    perItem,
    rowSelection,
  } = useFiles()

  return (
    <Layout
      breadcrumbItems={BRCR_SCR972}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T509}
    >
      <Card heading={TEXT_T509} isLoading={isLoading}>
        <FilesUploadForm
          allCountries={allCountries}
          fileTypeList={fileTypeList}
          form={form}
          isCountry={true}
          isStateFileType={false}
          onChangeCountryCode={handleGetDataSource}
          onChangeFileType={handleGetDataSource}
        />
        <TotalRecords perItem={perItem} total={dataSource?.length} />
        <Table
          columns={columns}
          dataSource={dataSource}
          onChange={onChangePagination}
          onChangeTable={onChangeTable}
          rowKey="uploadId"
          rowSelection={rowSelection}
        />
        <FilesListButtonGroup
          goToHome={goToHome}
          handleDownload={handleDownload}
          handlePreviewFile={handlePreviewFile}
          isDisabledBtnDownload={isDisabledBtnDownload}
          isDisabledBtnPreview={isDisabledBtnPreview}
          isVisibleBtnCancel={isVisibleBtnCancel}
          isVisibleBtnDownload={isVisibleBtnDownload}
          isVisibleBtnReference={isVisibleBtnReference}
        />
        <Modal
          isCenter
          isOpen={isOpenPreviewFile}
          okText={TEXT_T189}
          onOk={onCancelPreviewFile}
        >
          {MESSAGE_C0052}
        </Modal>
      </Card>
    </Layout>
  )
}
