import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { Key, useEffect, useState } from "react"
import { CheckboxItem } from "../../../../../../../_webui/layout/components/RowSelection/CheckboxItem"
import { TableRowSelection } from "../../../../../../../_webui/layout/components/Table/models"
import { Translate } from "../../../../../../../constant/Translate"
import { useTable } from "../../../../../../../utils/hook/useTable"
import { AttentionsCloneDataType } from "../../../../models/clone"

const { TEXT_T023 } = Translate

export const useRowSelection = (
  attentionInfoList: AttentionsCloneDataType[],
) => {
  const [isActiveBtnClone, setIsActiveBtnClone] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])

  const { onChange: onChangePage, perItem } = useTable(attentionInfoList.length)

  const onSelectChange = () => {
    const _isActiveBtnClone = selectedRowKeys.length === 1
    setIsActiveBtnClone(_isActiveBtnClone)
  }

  const onChangeCheckItem = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    const value = event.target.value

    if (checked) setSelectedRowKeys([...selectedRowKeys, value])
    else {
      const _selectedRowKeys = selectedRowKeys.filter((item) => item !== value)
      setSelectedRowKeys(_selectedRowKeys)
    }
  }

  const rowSelection: TableRowSelection<AttentionsCloneDataType> = {
    columnTitle: <div className="d-none">{TEXT_T023}</div>,
    hideSelectAll: true,
    renderCell: (_, record) => (
      <CheckboxItem
        checked={selectedRowKeys.includes(record.attentionId)}
        onChange={onChangeCheckItem}
        value={record.attentionId}
      />
    ),
    selectedRowKeys,
  }

  const onChange = (page: number, pageSize: number) => {
    onChangePage(page, pageSize)
    setSelectedRowKeys([])
  }

  const onChangeTable = () => {
    setSelectedRowKeys([])
  }

  useEffect(() => {
    onSelectChange()
  }, [selectedRowKeys])

  return {
    isActiveBtnClone,
    onChange,
    onChangeTable,
    perItem,
    rowSelection,
    selectedRowKeys,
  }
}
