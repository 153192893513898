import { Routers } from "../Routers"

const { SCR318_MAIL, SCR418, SCR911, SCR916, SCR917, SCR973 } = Routers

export const PublicRouters = [
  SCR318_MAIL,
  SCR418,
  SCR911,
  SCR916,
  SCR917,
  SCR973,
]
