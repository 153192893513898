import styled from "styled-components"
import { Fonts } from "../../../../../../constant/Styled/Fonts"
import { Colors } from "../../../../../../constant/Styled/Colors"

const { PRIMARY } = Colors
const { FS_XXX_LARGE } = Fonts

const StyledHeading = styled.h2`
  color: ${PRIMARY};
  font-size: ${FS_XXX_LARGE};
  margin-top: 0;
  text-align: center;
`

export const Styled = {
  StyledHeading,
}
