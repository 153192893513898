/* eslint-disable complexity */
/* eslint-disable max-lines */
import { FC, Fragment } from "react"
import { Table } from "../../../../../../../../_webui/layout/components/Table"
import { Regex } from "../../../../../../../../constant/Regex"
import { Title } from "../../../../../../../../_webui/layout/components/Typography/Title"
import { Translate } from "../../../../../../../../constant/Translate"
import { ScheduleInfoDetailsType } from "../../../../../models/add"
import { SchedulesAddTable } from "../../../Table"
import { SchedulesAddDetailProps } from "./models"
import { Styled } from "./styled"
import { ItemSelect } from "../../../../../../../../_webui/layout/components/Form/Item/Select"
import { ItemInput } from "../../../../../../../../_webui/layout/components/Form/Item/Input"
import { validatorRequiredField } from "../../../../../../../../utils/validator/requiredField"
import { ItemDatePicker } from "../../../../../../../../_webui/layout/components/Form/Item/DatePicker"
import { requiredField } from "../../../../../../../../utils/requiredField"
import { validatorRangeDate2 } from "../../../../../../../../utils/validator/rangeDate2"
import { PreLine } from "../../../../../../../../_webui/layout/components/Typography/PreLine"

const { REGEX_IS_DESKTOP } = Regex
const { StyledErrorTitle, StyledTableRemark } = Styled
const {
  HELP_H0034,
  MESSAGE_E0151,
  TEXT_T215,
  TEXT_T234,
  TEXT_T235,
  TEXT_T237,
  TEXT_T238,
  TEXT_T282,
  TEXT_T283,
  TEXT_T382,
} = Translate

export const SchedulesAddDetail: FC<SchedulesAddDetailProps> = (props) => {
  const {
    assignmentValidMsg,
    departments,
    detailColumnsFirst,
    detailColumnsSecond,
    detailDataSource,
    detailIndexTabActive,
    detailRowSelection,
    detailSelectedRowKeys,
    detailTabsItems,
    detailValidMsg,
    handleAddDetailItem,
    handleDeleteDetailItem,
    isActiveBumon,
    isRequiredDispatchDate,
    isSubmitValidate,
    isTableLoading,
    isTwoWay,
    isVisibleBtn,
    onBlur,
    onChangeDate,
    onChangeTravelPurpose,
    onClickDetailTab,
    onKeyDown,
    purposeTravelList,
  } = props

  const _validatorRangeDate2 = isSubmitValidate
    ? [
        validatorRangeDate2({
          namePathEndDate: "dispatchEndDate",
          namePathStartDate: "dispatchStartDate",
        }),
      ]
    : []

  return (
    <Fragment>
      <ItemSelect
        label={TEXT_T215}
        name="travelPurposeId"
        onChange={onChangeTravelPurpose}
        required
        rules={[validatorRequiredField]}
        selectItem={purposeTravelList}
      />

      <ItemDatePicker
        isBgBlue
        isDisabled={!isRequiredDispatchDate}
        label={TEXT_T237}
        name="dispatchStartDate"
        onBlur={(event) => onBlur(event, TEXT_T237, "dispatchStartDate")}
        onKeyDown={(event) => onKeyDown(event, TEXT_T237, "dispatchStartDate")}
        required={isRequiredDispatchDate}
        rules={[
          ...requiredField(isRequiredDispatchDate),
          ..._validatorRangeDate2,
        ]}
      />

      <ItemDatePicker
        isBgBlue
        isDisabled={!isRequiredDispatchDate}
        label={TEXT_T238}
        name="dispatchEndDate"
        onBlur={(event) => onBlur(event, TEXT_T238, "dispatchEndDate")}
        onChange={() => onChangeDate("dispatchStartDate")}
        onKeyDown={(event) => onKeyDown(event, TEXT_T238, "dispatchEndDate")}
        required={isRequiredDispatchDate}
        rules={requiredField(isRequiredDispatchDate)}
      />

      {assignmentValidMsg && (
        <StyledErrorTitle
          type="danger"
          titleLeft={<PreLine>{assignmentValidMsg}</PreLine>}
        />
      )}

      {isActiveBumon && (
        <ItemSelect
          isBgBlue
          label={TEXT_T234}
          name="bumonCd"
          required
          rules={[validatorRequiredField]}
          selectItem={departments}
          tooltip={HELP_H0034}
        />
      )}

      <ItemInput label={TEXT_T235} maxLength={100} name="mainPersonName" />

      <Title type="danger" titleLeft={TEXT_T382} />
      {detailValidMsg && <Title type="danger" titleLeft={detailValidMsg} />}
      {isTwoWay && <Title type="danger" titleLeft={MESSAGE_E0151} />}
      <SchedulesAddTable
        activeKey={detailIndexTabActive}
        addText={TEXT_T282}
        columns={detailColumnsFirst}
        dataSource={detailDataSource}
        dataTableSelected={detailSelectedRowKeys}
        deleteText={TEXT_T283}
        handleAdd={handleAddDetailItem}
        handleDelete={handleDeleteDetailItem}
        id="table-schedules-add-detail"
        isLoading={isTableLoading}
        isVisibleBtn={isVisibleBtn}
        items={detailTabsItems}
        onTabClick={onClickDetailTab}
        rowKey={(record: ScheduleInfoDetailsType) => record.travelDetailId}
        rowSelection={detailRowSelection}
      />

      {REGEX_IS_DESKTOP && (
        <StyledTableRemark>
          <Table
            columns={detailColumnsSecond}
            dataSource={detailDataSource}
            isBackgroundFirstChild
            isLoading={isTableLoading}
            pagination={false}
          />
        </StyledTableRemark>
      )}
    </Fragment>
  )
}
