import { FC } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { ButtonGroup } from "../../../../../../_webui/layout/components/Button/Group"
import { Center } from "../../../../../../_webui/layout/components/Center"
import { TravelersDetailButtonGroupProps } from "../../../models/detail"

const { HELP_H0032, TEXT_T020, TEXT_T030, TEXT_T034, TEXT_T072, TEXT_T073 } =
  Translate

export const TravelersDetailButtonGroup: FC<TravelersDetailButtonGroupProps> = (
  props,
) => {
  const {
    goBack,
    goToSchedulesList,
    goToTravelersEdit,
    isHiddenBtnBack,
    isHiddenBtnDelete,
    isHiddenBtnEdit,
    isHiddenBtnRequestDelete,
    isHiddenBtnSchedules,
    isSystemIdentifier,
    onOpen,
  } = props

  return (
    <Center>
      <ButtonGroup
        buttonItems={[
          {
            isHidden: !isHiddenBtnBack,
            isSmall: true,
            onClick: goBack,
            order: 1,
            text: TEXT_T072,
            type: "ghost",
          },
          {
            isHidden: !isHiddenBtnRequestDelete || isSystemIdentifier,
            // 削除の確認ダイアログを表示する。
            onClick: onOpen,
            order: 3,
            text: TEXT_T030,
            title: HELP_H0032,
            type: "danger",
          },
          {
            isHidden: !isHiddenBtnDelete || isSystemIdentifier,
            // 渡航者情報削除の確認ダイアログを表示する。
            onClick: onOpen,
            order: 3,
            text: TEXT_T034,
            type: "danger",
          },
          {
            isHidden: !isHiddenBtnEdit,
            onClick: goToTravelersEdit,
            order: 2,
            text: TEXT_T020,
            type: "primary",
          },
          {
            isHidden: !isHiddenBtnSchedules,
            onClick: goToSchedulesList,
            order: 4,
            text: TEXT_T073,
          },
        ]}
      />
    </Center>
  )
}
