import { FC } from "react"
import { GridProps } from "./models"
import { Spin } from "../Spin"
import { Styled, GlobalStyleGridForm } from "./styled"

const { StyledGrid, StyledGridItem, StyledGridItemKey, StyledGridItemText } =
  Styled

export const Grid: FC<GridProps> = (props) => {
  const {
    gridItems,
    isCustom,
    isForm,
    isGhostLayout,
    isItemKeySmall,
    isLoading,
    style,
    isItemKeyCenter,
  } = props
  return gridItems.length ? (
    <StyledGrid
      className="grid"
      isCustom={isCustom}
      isGhostLayout={isGhostLayout}
    >
      <Spin isLoading={isLoading} />
      <GlobalStyleGridForm />
      {gridItems.map(
        (item, index) =>
          item.key && (
            <StyledGridItem
              isCustom={isCustom}
              isGhostLayout={isGhostLayout}
              key={index}
              style={style}
            >
              <StyledGridItemKey
                className="grid-item-key"
                isItemKeyCenter={isItemKeyCenter}
                isGhostLayout={isGhostLayout}
                isItemKeySmall={isItemKeySmall}
              >
                {item.key}
              </StyledGridItemKey>
              <StyledGridItemText
                className="grid-item-text"
                isForm={isForm}
                isGhostLayout={isGhostLayout}
              >
                {item.text}
              </StyledGridItemText>
            </StyledGridItem>
          ),
      )}
    </StyledGrid>
  ) : null
}
