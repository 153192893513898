import { FC } from "react"
import { Route, Switch } from "react-router-dom"
import PrivateRoute from "../../../../app/routing/privateRoute"
import { Routers } from "../../../../constant/Routers"
import { AttentionPage } from "../../../../app/pages/attentions/attention"
import { AttentionsAnswerPage } from "../../../../app/pages/attentions/answer"
import { AttentionsClonePage } from "../../../../app/pages/attentions/clone"
import { AttentionsInfoPage } from "../../../../app/pages/attentions/info"
import { AttentionsInfoMailPage } from "../../../../app/pages/attentions/infoMail"
import { AttentionsDetailPage } from "../../../../app/pages/attentions/detail"
import { AttentionsListPage } from "../../../../app/pages/attentions/list"
import { AttentionsEditPage } from "../../../../app/pages/attentions/edit"
import { AttentionsSuccessPage } from "../../../../app/pages/attentions/success"
import { AttentionsListHistoryPage } from "../../../../app/pages/attentions/list/history"

const {
  SCR312,
  SCR313,
  SCR315,
  SCR316,
  SCR316_HISTORY,
  SCR317,
  SCR318,
  SCR318_MAIL,
  SCR319,
  SCR961,
} = Routers

export const AttentionsRoute: FC = () => (
  <Switch>
    <PrivateRoute component={AttentionPage} exact path={SCR312} />
    <PrivateRoute component={AttentionsEditPage} exact path={SCR313} />
    <PrivateRoute component={AttentionsSuccessPage} exact path={SCR315} />
    <PrivateRoute component={AttentionsListPage} exact path={SCR316} />
    <PrivateRoute
      component={AttentionsListHistoryPage}
      exact
      path={SCR316_HISTORY}
    />
    <PrivateRoute component={AttentionsDetailPage} exact path={SCR317} />
    <PrivateRoute component={AttentionsInfoPage} exact path={SCR318} />
    <Route component={AttentionsInfoMailPage} exact path={SCR318_MAIL} />
    <PrivateRoute component={AttentionsAnswerPage} exact path={SCR319} />
    <PrivateRoute component={AttentionsClonePage} exact path={SCR961} />
  </Switch>
)
