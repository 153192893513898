import { Variables } from "../../app/module/travelers/constants/edit/variables"
import { getValueByLanguage } from "../getValueByLanguage"

const { KEYS_ACCOMPANYING_FAMILY } = Variables

export const getDispatchTypeDetailName = (
  personDispatcherName: string,
  dispatchTypeDetailId: string,
  dispatchTypeDetailName: string,
  isFRM301?: boolean,
) => {
  const dispatchTypeDetail = isFRM301 ? ["DPTD001"] : KEYS_ACCOMPANYING_FAMILY
  return dispatchTypeDetail.includes(dispatchTypeDetailId)
    ? getValueByLanguage(
        `${dispatchTypeDetailName}（${personDispatcherName}）`,
        `${dispatchTypeDetailName} (${personDispatcherName})`,
      ).toString()
    : dispatchTypeDetailName
}
