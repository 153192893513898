/* eslint-disable max-lines */
import { FC, Fragment } from "react"
import { FormId } from "../../../../../../constant/FormId"
import { Translate } from "../../../../../../constant/Translate"
import { ButtonGroup } from "../../../../../../_webui/layout/components/Button/Group"
import { SchedulesButtonGroupProps } from "../../../models/list"
import { Regex } from "../../../../../../constant/Regex"

const { REGEX_IS_DESKTOP } = Regex
const {
  TEXT_T020,
  TEXT_T031,
  TEXT_T032,
  TEXT_T033,
  TEXT_T034,
  TEXT_T072,
  TEXT_T119,
  TEXT_T216,
  TEXT_T225,
  TEXT_T226,
  TEXT_T227,
} = Translate
const { DEFAULT, FRM101, FRM102, FRM301 } = FormId

export const SchedulesButtonGroup: FC<SchedulesButtonGroupProps> = (props) => {
  const {
    goBack,
    goToAttention,
    goToSafety,
    goToSchedulesAdd,
    goToSchedulesConfirmDelete,
    goToSchedulesDetail,
    goToSchedulesEdit,
    handleOpenModalExport,
    isDisabledBtnCreateOrSend,
    isDisabledBtnDelete,
    isDisabledBtnRefer,
    isFromSCR114UserSearch,
    isHiddenBtnCreateOrSendAttentions,
    isHiddenBtnCreateOrSendSafeties,
    isHiddenBtnDelete,
    isHiddenBtnEdit,
    isHiddenBtnExportCsv,
    isHiddenBtnExportExcel,
    isHiddenBtnExportExcelSafeties,
    isHiddenBtnExportExcelTravelers,
    isHiddenBtnRefer,
    isPathnameSchedulesOrTravelers,
  } = props

  return isPathnameSchedulesOrTravelers ? (
    <Fragment>
      <ButtonGroup
        buttonItems={[
          {
            isHidden: !isFromSCR114UserSearch,
            onClick: goBack,
            order: 1,
            text: TEXT_T072,
            type: "ghost",
          },
          {
            isDisabled: isDisabledBtnDelete,
            isHidden: !isHiddenBtnDelete,
            onClick: goToSchedulesConfirmDelete,
            order: 4,
            text: TEXT_T034,
            type: "danger",
          },
          {
            isDisabled: isDisabledBtnDelete || isDisabledBtnRefer,
            isHidden: !isHiddenBtnEdit,
            onClick: goToSchedulesEdit,
            order: 2,
            text: TEXT_T020,
            type: "primary",
          },
          {
            isDisabled: isDisabledBtnRefer,
            isHidden: !isHiddenBtnRefer,
            onClick: goToSchedulesDetail,
            order: 5,
            text: TEXT_T033,
          },
          {
            isHidden: !isFromSCR114UserSearch,
            onClick: goToSchedulesAdd,
            order: 3,
            text: TEXT_T031,
            type: "primary",
          },
        ]}
      />
      {REGEX_IS_DESKTOP && (
        <ButtonGroup
          buttonItems={[
            {
              isHidden: !isHiddenBtnExportCsv,
              onClick: () => handleOpenModalExport(DEFAULT),
              text: TEXT_T032,
            },
            {
              isHidden: !isHiddenBtnExportExcel,
              onClick: () => handleOpenModalExport(FRM101),
              text: TEXT_T216,
            },
            {
              isHidden: !isHiddenBtnExportExcelTravelers,
              onClick: () => handleOpenModalExport(FRM102),
              text: TEXT_T225,
            },
          ]}
          isMargin={false}
        />
      )}
    </Fragment>
  ) : (
    <ButtonGroup
      buttonItems={[
        {
          isDisabled: isDisabledBtnCreateOrSend,
          isHidden: !isHiddenBtnCreateOrSendSafeties,
          onClick: () => goToSafety(),
          text: TEXT_T226,
        },
        {
          isHidden: !REGEX_IS_DESKTOP || !isHiddenBtnExportExcelSafeties,
          onClick: () => handleOpenModalExport(FRM301),
          text: TEXT_T227,
        },
        {
          isDisabled: isDisabledBtnCreateOrSend,
          isHidden: !isHiddenBtnCreateOrSendAttentions,
          onClick: () => goToAttention(),
          text: TEXT_T119,
        },
      ]}
    />
  )
}
