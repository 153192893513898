import { FC, Fragment } from "react"
import { Translate } from "../../../../constant/Translate"
import { Button } from "../Button"
import { UploadProps } from "./models"
import keycloak from "../../../../utils/keycloak"
import { Regex } from "../../../../constant/Regex"
import { useUpload } from "./modules/useUpload"
import { UploadInfo } from "./Info"
import { Styled } from "./styled"

const { REGEX_IS_DESKTOP } = Regex
const {
  StyledAntUpload,
  StyledAntUploadContainer,
  StyledAntUploadHint,
  StyledAntUploadText,
  StyledTitle,
} = Styled
const { TEXT_T115, TEXT_T117, TEXT_T118, TEXT_T148, TEXT_T306 } = Translate

export const Upload: FC<UploadProps> = (props) => {
  const {
    accept = ".csv",
    action,
    beforeUpload,
    customRequest,
    fileNameList,
    isHiddenUploadHint = false,
    isMaxCount = true,
    multiple = false,
    name,
    onChange,
    onDrop,
    titleLeft = TEXT_T115,
  } = props
  const { handleChangeFile } = useUpload({ onChange })

  return (
    <Fragment>
      <StyledTitle titleLeft={titleLeft} />
      <StyledAntUploadContainer>
        <label className="hidden-label" htmlFor="ant-upload-file">
          {TEXT_T117}
        </label>
        <StyledAntUpload
          accept={accept}
          action={action}
          beforeUpload={beforeUpload}
          headers={{ Authorization: `Bearer ${keycloak.token}` }}
          listType="text"
          // eslint-disable-next-line no-undefined
          maxCount={isMaxCount ? 1 : undefined}
          multiple={multiple}
          name={name}
          onChange={handleChangeFile}
          onDrop={onDrop}
          customRequest={customRequest}
          type="drag"
          id="ant-upload-file"
        >
          <StyledAntUploadText>
            {REGEX_IS_DESKTOP ? TEXT_T306 : TEXT_T148}
          </StyledAntUploadText>
          <Button text={TEXT_T117} type="primary" />
          {fileNameList && (
            <Fragment>
              {!isHiddenUploadHint && (
                <StyledAntUploadHint>{TEXT_T118}</StyledAntUploadHint>
              )}
              {[...new Set(fileNameList)].map((item, index) => (
                <UploadInfo key={index} name={item} />
              ))}
            </Fragment>
          )}
        </StyledAntUpload>
      </StyledAntUploadContainer>
    </Fragment>
  )
}
