import { useState } from "react"
import { useHistory } from "react-router-dom"
import { AttentionTravelersDataType } from "../../../../models/attention"
import { useRouters } from "../useRouters"
import { useApi } from "../useApi"
import { useTableConfig } from "../useTableConfig"
import { useGridConfig } from "../useGridConfig"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { useTableFormConfig } from "../useTableFormConfig"

export const useVariables = () => {
  const { push } = useHistory()
  const {
    isHiddenBackBtn,
    isHiddenResetBtn,
    isHiddenSubmitBtn,
    isHiddenCopyBtn,
    isAttentionsSendAll,
  } = useVariablesAuthority()
  const [travelerInfoList, setTravelerInfoList] = useState<
    AttentionTravelersDataType[]
  >([])
  const { columns } = useTableConfig()
  const {
    form,
    isDisabledBtnSelect,
    isLoading,
    isOpen,
    isOpenForm,
    onAddOrRemoveSafeties,
    onCancel,
    onCancelForm,
    onFinish,
    onOpen,
    onOpenForm,
    onSend,
    rowSelectionAttention,
    safetiesList,
    safetiesSelected,
    selectedKeys,
    setSafetiesSelected,
    setSelectedKeys,
    state,
  } = useApi()
  const { columns: safetiesColumns } = useTableFormConfig()
  const { gridItems } = useGridConfig(state?.transmissionInfo)
  const { onCopy, onEdit, onGoBack, onReset } = useRouters(selectedKeys)
  const isDisabledSubmit =
    !state?.travelerInfoList?.length || !state?.transmissionInfo
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)

  return {
    columns,
    form,
    gridItems,
    isAttentionsSendAll,
    isDisabledBtnSelect,
    isDisabledSubmit,
    isHiddenBackBtn,
    isHiddenCopyBtn,
    isHiddenResetBtn,
    isHiddenSubmitBtn,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenForm,
    onAddOrRemoveSafeties,
    onCancel,
    onCancelForm,
    onCopy,
    onEdit,
    onFinish,
    onGoBack,
    onOpen,
    onOpenForm,
    onReset,
    onSend,
    push,
    rowSelectionAttention,
    safetiesColumns,
    safetiesList,
    safetiesSelected,
    setIsLoadingLayout,
    setSafetiesSelected,
    setSelectedKeys,
    setTravelerInfoList,
    state,
    travelerInfoList,
  }
}
