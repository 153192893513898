import { ScheduleStaysType } from "../../../../models/addSuccess"
import { useStayTableConfig } from "../useTableConfigs/useStayTableConfig"
import { useStayTabsConfig } from "../useTabsConfigs/useStayTabsConfig"

export const useStays = (schedulesStays: ScheduleStaysType[]) => {
  const staysColumns = useStayTableConfig()

  const staysTabsItems = useStayTabsConfig(schedulesStays)

  return {
    staysColumns,
    staysTabsItems,
  }
}
