import { Breadcrumbs } from "../../../../../../../../constant/Breadcrumbs"
import { Routers } from "../../../../../../../../constant/Routers"

const { BRCR_SCR413, BRCR_SCR413_FROM_SCR412, BRCR_SCR413_FROM_SCR962 } =
  Breadcrumbs
const { SCR412, SCR962 } = Routers

export const getBreadcrumbItems = (sourceURL?: string) => {
  if (sourceURL === SCR412) return BRCR_SCR413_FROM_SCR412

  if (sourceURL === SCR962) return BRCR_SCR413_FROM_SCR962

  return BRCR_SCR413
}
