import { FC } from "react"
import { Alert } from "../../../../../../_webui/layout/components/Alert"
import { ButtonSubmit } from "../../../../../../_webui/layout/components/Button/Submit"
import { Card } from "../../../../../../_webui/layout/components/Card"
import { Grid } from "../../../../../../_webui/layout/components/Grid"
import { Layout } from "../../../../../../_webui/layout/components/Layout"
import { Breadcrumbs } from "../../../../../../constant/Breadcrumbs"
import { Translate } from "../../../../../../constant/Translate"
import { useTimezoneSuccess } from "./modules/useSuccess"

const { TEXT_T020, TEXT_T018, TEXT_T448, TEXT_T449 } = Translate
const { BRCR_SCR931 } = Breadcrumbs

export const TimezoneSuccess: FC = () => {
  const {
    detail,
    goToHome,
    goToTimeZoneSetting,
    isHiddenReset,
    isHiddenSubmit,
    isLoadingLayout,
  } = useTimezoneSuccess()
  return (
    <Layout
      breadcrumbItems={BRCR_SCR931}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T448}
    >
      <Card heading={TEXT_T448} isFullHeight>
        <Alert type="success" content={TEXT_T449} />
        <Grid gridItems={detail} />
        <ButtonSubmit
          isHiddenReset={isHiddenReset}
          isHiddenSubmit={isHiddenSubmit}
          isReset
          onClickReset={goToHome}
          onClickSubmit={goToTimeZoneSetting}
          resetText={TEXT_T018}
          submitText={TEXT_T020}
          submitType="default"
        />
      </Card>
    </Layout>
  )
}
