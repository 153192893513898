import { useHistory } from "react-router-dom"
import { Routers } from "../../../../../../../../constant/Routers"
import { UseSubAdminRoutersProps } from "../../../../../models/subAdmin/list"

const { SCR950, SCR952, SCR953 } = Routers

export const useRouters = (props: UseSubAdminRoutersProps) => {
  const { searchCondition, selectedRowKeys } = props

  const { push } = useHistory()

  // SCR950_機能限定管理者設定画面」に遷移する。
  const goToSubAdminSetting = () => {
    push({
      pathname: SCR950,
      state: {
        searchCondition: {
          ...searchCondition,
          travelerIdList: selectedRowKeys,
        },
        sourceURL: SCR952,
      },
    })
  }

  // 「SCR953_権限メンテナンス画面」に遷移する。
  const goToRoleSetting = () => {
    push({
      pathname: SCR953,
      state: {
        isFromSCR952: true,
        searchCondition: searchCondition,
        sourceURL: SCR952,
      },
    })
  }

  return {
    goToRoleSetting,
    goToSubAdminSetting,
  }
}
