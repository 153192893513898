import styled from "styled-components"
import { Form } from "../../../../../../../_webui/layout/components/Form"
import { Regex } from "../../../../../../../constant/Regex"

const { REGEX_IS_DESKTOP } = Regex

const StyledForm = styled(Form)`
  ${REGEX_IS_DESKTOP &&
  `.ant-form-inline {
      .ant-form-item {
        min-width: calc(50% - 16px);
        max-width: calc(50% - 16px);
        &:first-child {
          margin-right: 16px;
        }
        &:not(:first-child) {
          margin-left: 16px;
        }
      }
    }
  `}
`

export const Styled = {
  StyledForm,
}
