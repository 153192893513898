import styled, { css } from "styled-components"
import { Button } from "../.."
import { Regex } from "../../../../../../constant/Regex"

const { REGEX_IS_DESKTOP } = Regex

const StyledButtonSubmit = styled.div`
  ${() =>
    REGEX_IS_DESKTOP
      ? css`
          text-align: center;
          margin-top: 30px;
          width: 100%;
        `
      : css`
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          margin-top: 30px;
        `}
`
const StyledButtonItem = styled(Button)`
  ${() =>
    !REGEX_IS_DESKTOP &&
    css`
      width: 100%;
    `}
`
const StyledButtonItemReset = styled(StyledButtonItem)`
  ${() =>
    REGEX_IS_DESKTOP
      ? css`
          margin-right: 30px;
        `
      : css`
          order: 2;
          margin-top: 20px;
        `}
`

export const Styled = {
  StyledButtonItem,
  StyledButtonItemReset,
  StyledButtonSubmit,
}
