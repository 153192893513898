import "./style/index.scss"
import { FC } from "react"
import { CardProps } from "./models"
import { getStyle } from "../../../../utils/getStyle"
import { CardSpin } from "./Spin"
import { CardHeading } from "./Heading"
import { Styled } from "./styled"

const { StyledCard } = Styled

export const Card: FC<CardProps> = (props) => {
  const {
    children,
    childrenCustom,
    childrenRight,
    className,
    heading,
    isBackground,
    isBreadcrumbs = true,
    isCenter,
    isFullHeight,
    isHeading = true,
    isLoading,
    isSearch,
    isSearchIcon,
    isSmall,
    onClick,
    searchIcon,
    searchText,
    style,
    type,
    ...rest
  } = props
  const styleClassName = getStyle(className, ` ${className}`)
  const styleCard = `card${styleClassName}`

  return (
    <StyledCard
      className={styleCard}
      isBreadcrumbs={isBreadcrumbs}
      isFullHeight={isFullHeight}
      style={style}
      {...rest}
    >
      <CardSpin isSpin isFixed={false} isLoading={isLoading} />
      <CardHeading
        childrenCustom={childrenCustom}
        childrenRight={childrenRight}
        heading={heading}
        isBackground={isBackground}
        isCenter={isCenter}
        isHeading={isHeading}
        isSearch={isSearch}
        isSearchIcon={isSearchIcon}
        isSmall={isSmall}
        onClick={onClick}
        searchIcon={searchIcon}
        searchText={searchText}
        type={type}
      />
      {children}
    </StyledCard>
  )
}
