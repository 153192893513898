/* eslint-disable max-lines */
import { Images } from "../../../../../../../../constant/Images"
import { Translate } from "../../../../../../../../constant/Translate"
import { getValueByLanguage } from "../../../../../../../../utils/getValueByLanguage"
import { Image } from "../../../../../../../../_webui/layout/components/Image"
import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../../_webui/layout/components/Table/models"
import { SubAdminListType } from "../../../../../models/subAdmin/list"
import { getFullName } from "../../../../../../../../utils/getFullName"
import { concat } from "../../../../../../../../utils/concat"

const {
  TEXT_T003,
  TEXT_T006,
  TEXT_T020,
  TEXT_T022,
  TEXT_T025,
  TEXT_T033,
  TEXT_T034,
  TEXT_T147,
  TEXT_T465,
  TEXT_T466,
  TEXT_T467,
  TEXT_T468,
  TEXT_T469,
  TEXT_T470,
  TEXT_T471,
  TEXT_T472,
  TEXT_T473,
  TEXT_T474,
} = Translate
const { IMAGE_LIST_CHECK } = Images

const IconCheck = (isIcon: string) =>
  Boolean(isIcon) && <Image src={IMAGE_LIST_CHECK} />

export const useTableConfig = (): UseTableConfigType<SubAdminListType> => {
  const columns: ColumnType<SubAdminListType>[] = [
    {
      dataIndex: "fullNameKanji",
      render: (_, data) =>
        getFullName(data.base.kanjiSurname, data.base.kanjiName, true),
      sorter: (a, b) =>
        concat(a.base.kanjiSurname, a.base.kanjiName).localeCompare(
          concat(b.base.kanjiSurname, b.base.kanjiName),
        ),
      title: TEXT_T003,
      width: 250,
    },
    {
      dataIndex: "fullNameRomaji",
      render: (_, data) =>
        getFullName(data.base.romajiSurname, data.base.romajiName),
      sorter: (a, b) =>
        concat(a.base.romajiSurname, a.base.romajiName).localeCompare(
          concat(b.base.romajiSurname, b.base.romajiName),
        ),
      title: TEXT_T006,
      width: 250,
    },
    {
      dataIndex: "bumonName",
      render: (_, data) => data.base.bumonName,
      sorter: (a, b) => a.base.bumonName.localeCompare(b.base.bumonName),
      title: TEXT_T465,
      width: getValueByLanguage(145, 175),
    },
    {
      dataIndex: "patternName",
      render: (_, data) =>
        getValueByLanguage(
          data.base.patternName.split("　")[0],
          data.base.patternName.split(" ").slice(0, 2).join(" "),
        ),
      sorter: (a, b) => a.base.patternName.localeCompare(b.base.patternName),
      title: TEXT_T466,
      width: getValueByLanguage(145, 175),
    },
    {
      dataIndex: "dispatchTypeDetailName",
      render: (_, data) => data.base.dispatchTypeDetailName,
      sorter: (a, b) =>
        a.base.dispatchTypeDetailName.localeCompare(
          b.base.dispatchTypeDetailName,
        ),
      title: TEXT_T025,
      width: 260,
    },
    {
      align: "center",
      children: [
        {
          align: "center",
          dataIndex: "register",
          render: (_, data) =>
            IconCheck(data.authorities.travelerInfoSetting.register),
          title: TEXT_T147,
          width: getValueByLanguage(48, 80),
        },
        {
          align: "center",
          dataIndex: "edit",
          render: (_, data) =>
            IconCheck(data.authorities.travelerInfoSetting.edit),
          title: TEXT_T020,
          width: getValueByLanguage(48, 80),
        },
        {
          align: "center",
          dataIndex: "reference",
          render: (_, data) =>
            IconCheck(data.authorities.travelerInfoSetting.reference),
          title: TEXT_T033,
          width: getValueByLanguage(48, 90),
        },
        {
          align: "center",
          dataIndex: "delete",
          render: (_, data) =>
            IconCheck(data.authorities.travelerInfoSetting.delete),
          title: TEXT_T034,
          width: getValueByLanguage(48, 80),
        },
        {
          align: "center",
          dataIndex: "search",
          render: (_, data) =>
            IconCheck(data.authorities.travelerInfoSetting.search),
          title: TEXT_T022,
          width: getValueByLanguage(48, 80),
        },
        {
          align: "center",
          dataIndex: "reportOutputFunction",
          render: (_, data) =>
            IconCheck(
              data.authorities.travelerInfoSetting.reportOutputFunction,
            ),
          title: TEXT_T474,
          width: getValueByLanguage(80, 130),
        },
      ],
      dataIndex: "travelerInfoSetting",
      title: TEXT_T467,
    },
    {
      align: "center",
      children: [
        {
          align: "center",
          dataIndex: "register",
          render: (_, data) =>
            IconCheck(data.authorities.travelScheduleInfoSetting.register),
          title: TEXT_T147,
          width: getValueByLanguage(48, 80),
        },
        {
          align: "center",
          dataIndex: "edit",
          render: (_, data) =>
            IconCheck(data.authorities.travelScheduleInfoSetting.edit),
          title: TEXT_T020,
          width: getValueByLanguage(48, 80),
        },
        {
          align: "center",
          dataIndex: "reference",
          render: (_, data) =>
            IconCheck(data.authorities.travelScheduleInfoSetting.reference),
          title: TEXT_T033,
          width: getValueByLanguage(48, 90),
        },
        {
          align: "center",
          dataIndex: "delete",
          render: (_, data) =>
            IconCheck(data.authorities.travelScheduleInfoSetting.delete),
          title: TEXT_T034,
          width: getValueByLanguage(48, 80),
        },
        {
          align: "center",
          dataIndex: "search",
          render: (_, data) =>
            IconCheck(data.authorities.travelScheduleInfoSetting.search),
          title: TEXT_T022,
          width: getValueByLanguage(48, 80),
        },
        {
          align: "center",
          dataIndex: "reportOutputFunction",
          render: (_, data) =>
            IconCheck(
              data.authorities.travelScheduleInfoSetting.reportOutputFunction,
            ),
          title: TEXT_T474,
          width: getValueByLanguage(80, 130),
        },
      ],
      dataIndex: "travelScheduleInfoSetting",
      title: TEXT_T468,
    },
    {
      align: "center",
      children: [
        {
          align: "center",
          dataIndex: "targetSearch",
          render: (_, data) =>
            IconCheck(data.authorities.attentionSetting.targetSearch),
          title: TEXT_T471,
          width: getValueByLanguage(90, 110),
        },
        {
          align: "center",
          dataIndex: "bulkSending",
          render: (_, data) =>
            IconCheck(data.authorities.attentionSetting.bulkSending),
          title: TEXT_T472,
          width: 80,
        },
        {
          align: "center",
          dataIndex: "responseResultReference",
          render: (_, data) =>
            IconCheck(
              data.authorities.attentionSetting.responseResultReference,
            ),
          title: TEXT_T473,
          width: getValueByLanguage(110, 130),
        },
        {
          align: "center",
          dataIndex: "reportOutputFunction",
          render: (_, data) =>
            IconCheck(data.authorities.attentionSetting.reportOutputFunction),
          title: TEXT_T474,
          width: getValueByLanguage(80, 130),
        },
      ],
      dataIndex: "attentionSetting",
      title: TEXT_T469,
      width: 240,
    },
    {
      align: "center",
      children: [
        {
          align: "center",
          dataIndex: "targetSearch",
          render: (_, data) =>
            IconCheck(data.authorities.safetyConfirmSetting.targetSearch),
          title: TEXT_T471,
          width: getValueByLanguage(90, 110),
        },
        {
          align: "center",
          dataIndex: "bulkSending",
          render: (_, data) =>
            IconCheck(data.authorities.safetyConfirmSetting.bulkSending),
          title: TEXT_T472,
          width: 80,
        },
        {
          align: "center",
          dataIndex: "responseResultReference",
          render: (_, data) =>
            IconCheck(
              data.authorities.safetyConfirmSetting.responseResultReference,
            ),
          title: TEXT_T473,
          width: getValueByLanguage(110, 130),
        },
        {
          align: "center",
          dataIndex: "reportOutputFunction",
          render: (_, data) =>
            IconCheck(
              data.authorities.safetyConfirmSetting.reportOutputFunction,
            ),
          title: TEXT_T474,
          width: getValueByLanguage(80, 130),
        },
      ],
      dataIndex: "safetyConfirmSetting",
      title: TEXT_T470,
    },
  ]
  return {
    columns,
  }
}
