/* eslint-disable complexity */
/* eslint-disable max-lines */
import { FC, Fragment } from "react"
import { Divider } from "../../../../../../../_webui/layout/components/Divider"
import { ItemInput } from "../../../../../../../_webui/layout/components/Form/Item/Input"
import { ItemSelect } from "../../../../../../../_webui/layout/components/Form/Item/Select"
import { Regex } from "../../../../../../../constant/Regex"
import { Translate } from "../../../../../../../constant/Translate"
import { validatorAlphanumeric } from "../../../../../../../utils/validator/alphanumeric"
import { validatorEmailAddress } from "../../../../../../../utils/validator/emailAddress"
import { validatorRequiredField } from "../../../../../../../utils/validator/requiredField"
import { TravelersEditPersonalInfoProps } from "../../../../models/edit"
import { PersonalInfoInputGroup } from "./InputGroup"
import { PersonalInfoTable } from "./Table"
import { PersonalInfoTabs } from "./Tabs"
import { requiredField } from "../../../../../../../utils/requiredField"

const { REGEX_IS_DESKTOP } = Regex
const {
  HELP_H0006,
  TEXT_T002,
  TEXT_T009,
  TEXT_T025,
  TEXT_T040,
  TEXT_T044,
  TEXT_T263,
} = Translate

export const PersonalInfo: FC<TravelersEditPersonalInfoProps> = (props) => {
  const {
    accompanies,
    addItems,
    columns,
    departments,
    dispatchType,
    indexTabActive,
    isAccompanyingFamily,
    isDifferentInforCheck,
    isDisabledBtnDelete,
    isPersonUrl,
    isRoleAdmin,
    isRoleUser,
    onDifferentInforCheckChange,
    onDispatchTypeDetailChange,
    onTabClick,
    othersStaffNumber,
    removeItems,
    rowSelection,
    tabsItems,
  } = props

  const bumonCdRequired = requiredField(!isAccompanyingFamily)

  return (
    <Fragment>
      <ItemInput isHidden label={TEXT_T263} name="updateDate" />
      {isRoleAdmin && !othersStaffNumber ? (
        <ItemInput
          label={TEXT_T002}
          maxLength={255}
          name="userId"
          required
          rules={[validatorRequiredField, validatorEmailAddress]}
        />
      ) : (
        <ItemInput isReadOnly label={TEXT_T002} name="userId" />
      )}
      <PersonalInfoInputGroup
        isDifferentInforCheck={isDifferentInforCheck}
        onDifferentInforCheckChange={onDifferentInforCheckChange}
      />
      {isRoleUser ? (
        <Fragment>
          <ItemInput isReadOnly label={TEXT_T009} name="bumonName" />
          <ItemInput isHidden name="bumonCd" />
          <ItemInput
            isReadOnly
            label={TEXT_T025}
            name="dispatchTypeDetailName"
          />
          <ItemInput isHidden name="dispatchTypeDetailId" />
        </Fragment>
      ) : (
        <Fragment>
          {(othersStaffNumber || isPersonUrl) && !isRoleAdmin ? (
            <Fragment>
              <ItemInput isReadOnly label={TEXT_T009} name="bumonName" />
              <ItemInput isHidden name="bumonCd" />
            </Fragment>
          ) : (
            <ItemSelect
              label={TEXT_T009}
              name="bumonCd"
              required={!isAccompanyingFamily}
              rules={bumonCdRequired}
              selectItem={departments}
            />
          )}
          <ItemSelect
            label={TEXT_T025}
            name="dispatchTypeDetailId"
            onChange={onDispatchTypeDetailChange}
            required
            rules={[validatorRequiredField]}
            selectItem={dispatchType}
          />
        </Fragment>
      )}
      <Divider />
      <ItemInput
        label={TEXT_T040}
        maxLength={9}
        name="passportNumber"
        required
        rules={[validatorRequiredField, validatorAlphanumeric]}
        tooltip={HELP_H0006}
      />

      {!isAccompanyingFamily && (
        <Fragment>
          {REGEX_IS_DESKTOP ? (
            <PersonalInfoTable
              accompanies={accompanies}
              addItems={addItems}
              columns={columns}
              isDisabledBtnDelete={isDisabledBtnDelete}
              removeItems={removeItems}
              rowSelection={rowSelection}
            />
          ) : (
            <PersonalInfoTabs
              accompanies={accompanies}
              addItems={addItems}
              indexTabActive={indexTabActive}
              onTabClick={onTabClick}
              removeItems={removeItems}
              tabsItems={tabsItems}
            />
          )}
          <ItemInput isReadOnly label={TEXT_T044} name="accompaniesLength" />
        </Fragment>
      )}
    </Fragment>
  )
}
