import { FC, Fragment } from "react"
import { Translate } from "../../../../../../../constant/Translate"
import { validatorHalfSize } from "../../../../../../../utils/validator/halfSize"
import { ItemInput } from "../../../../../../../_webui/layout/components/Form/Item/Input"

const { HELP_H0012, HELP_H0013, TEXT_T058, TEXT_T059, TEXT_T060 } = Translate

export const Assignee: FC = () => (
  <Fragment>
    <ItemInput
      label={TEXT_T058}
      maxLength={100}
      name="assigneeInstitutionName"
      tooltip={HELP_H0012}
    />
    <ItemInput
      label={TEXT_T059}
      maxLength={20}
      name="assigneePhoneNumber"
      rules={[validatorHalfSize]}
      tooltip={HELP_H0013}
    />
    <ItemInput name="assigneeName" label={TEXT_T060} maxLength={100} />
  </Fragment>
)
