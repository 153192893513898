import moment from "moment"
import { ApplicationFormDataSourceType } from "../../../../models/list"
import { Routers } from "../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { commonApi } from "../../../../../settings/apis"
import { useVariablesApi } from "../useVariablesApi"

const { SCR973 } = Routers

export const useApi = () => {
  const {
    breadcrumbItems,
    fileInfoList,
    initDateTime,
    isDisabledBtnDownload,
    isLoading,
    isLoadingLayout,
    isOpen,
    layoutTitle,
    onCancel,
    onChangePagination,
    onChangeTable,
    onOpen,
    pathname,
    perItem,
    push,
    rowSelection,
    selectedRowKeys,
    setFileInfoList,
    setInitDateTime,
    setIsLoading,
    setIsLoadingLayout,
    type,
  } = useVariablesApi()

  const getFiles = async () => {
    // APIを呼び出し、APIからの応答結果を「戻りファイル情報」変数に設定する。
    await commonApi
      .getFiles({ type })
      .then((res: ApplicationFormDataSourceType[]) => {
        setFileInfoList(res)
        setInitDateTime(moment())
        setIsLoadingLayout(false)
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const stateError = pushStateError(error)
        push({ pathname: SCR973, state: stateError })
      })
  }

  const deleteFiles = async () => {
    setIsLoading(true)
    // APIを呼び出して選択したファイルを削除します
    await commonApi
      .deleteFiles(selectedRowKeys)
      .then(async () => {
        await getFiles()
        onCancel()
        setIsLoading(false)
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const stateError = pushStateError(error)
        push({ pathname: SCR973, state: stateError })
      })
  }

  return {
    breadcrumbItems,
    deleteFiles,
    fileInfoList,
    getFiles,
    initDateTime,
    isDisabledBtnDownload,
    isLoading,
    isLoadingLayout,
    isOpen,
    layoutTitle,
    onCancel,
    onChangePagination,
    onChangeTable,
    onOpen,
    pathname,
    perItem,
    rowSelection,
    selectedRowKeys,
    type,
  }
}
