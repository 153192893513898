/* eslint-disable max-lines */
import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../_webui/layout/components/Table/models"
import { Translate } from "../../../../../../../constant/Translate"
import { getTextYesNo } from "../../../../../../../utils/getTextYesNo"
import { getDay } from "../../../../../../../utils/getDate"
import { getTextBoolean } from "../../../../../../../utils/getTextBoolean"
import { getTrainingType } from "../../../../../../../utils/getTrainingType"

import { getValueByLanguage } from "../../../../../../../utils/getValueByLanguage"
import { getWorkPlaceLocation } from "../../../../../../../utils/getWorkPlaceLocation"
import { TravelersBatchUpdateDataType } from "../../../../models/batchUpdate/data"

const {
  TEXT_T002,
  TEXT_T009,
  TEXT_T017,
  TEXT_T025,
  TEXT_T036,
  TEXT_T039,
  TEXT_T040,
  TEXT_T043,
  TEXT_T046,
  TEXT_T047,
  TEXT_T048,
  TEXT_T049,
  TEXT_T050,
  TEXT_T051,
  TEXT_T052,
  TEXT_T053,
  TEXT_T054,
  TEXT_T055,
  TEXT_T056,
  TEXT_T057,
  TEXT_T058,
  TEXT_T059,
  TEXT_T060,
  TEXT_T061,
  TEXT_T062,
  TEXT_T063,
  TEXT_T064,
  TEXT_T065,
  TEXT_T066,
  TEXT_T075,
  TEXT_T076,
  TEXT_T077,
  TEXT_T078,
  TEXT_T079,
  TEXT_T080,
  TEXT_T081,
  TEXT_T082,
  TEXT_T123,
  TEXT_T124,
  TEXT_T125,
  TEXT_T126,
  TEXT_T128,
  TEXT_T129,
  TEXT_T130,
  TEXT_T131,
  TEXT_T132,
  TEXT_T133,
  TEXT_T134,
  TEXT_T136,
  TEXT_T137,
  TEXT_T139,
  TEXT_T142,
  TEXT_T143,
  TEXT_T144,
  TEXT_T218,
  TEXT_T246,
  TEXT_T247,
  TEXT_T248,
  TEXT_T249,
  TEXT_T250,
} = Translate

export const useTableConfig =
  (): UseTableConfigType<TravelersBatchUpdateDataType> => {
    const columns: ColumnType<TravelersBatchUpdateDataType>[] = [
      {
        dataIndex: "userId",
        sorter: (a, b) => a.userId.localeCompare(b.userId),
        title: TEXT_T002,
        width: 250,
      },
      {
        dataIndex: "kanjiSurname",
        sorter: (a, b) => a.kanjiSurname.localeCompare(b.kanjiSurname),
        title: TEXT_T123,
        width: getValueByLanguage(150, 200),
      },
      {
        dataIndex: "kanjiName",
        sorter: (a, b) => a.kanjiName.localeCompare(b.kanjiName),
        title: TEXT_T124,
        width: getValueByLanguage(150, 220),
      },
      {
        dataIndex: "romajiSurname",
        sorter: (a, b) => a.romajiSurname.localeCompare(b.romajiSurname),
        title: TEXT_T125,
        width: 200,
      },
      {
        dataIndex: "romajiName",
        sorter: (a, b) => a.romajiName.localeCompare(b.romajiName),
        title: TEXT_T126,
        width: 200,
      },
      {
        dataIndex: "differentInforCheck",
        render: (_, data) => getTextYesNo(data.differentInforCheck),
        sorter: (a, b) =>
          String(a.differentInforCheck).localeCompare(
            String(b.differentInforCheck),
          ),
        title: TEXT_T036,
        width: getValueByLanguage(150, 200),
      },
      {
        dataIndex: "kanjiPassportSurname",
        sorter: (a, b) =>
          a.kanjiPassportSurname.localeCompare(b.kanjiPassportSurname),
        title: TEXT_T128,
        width: getValueByLanguage(200, 250),
      },
      {
        dataIndex: "kanjiPassportName",
        sorter: (a, b) =>
          a.kanjiPassportName.localeCompare(b.kanjiPassportName),
        title: TEXT_T129,
        width: getValueByLanguage(200, 250),
      },
      {
        dataIndex: "romajiPassportSurname",
        sorter: (a, b) =>
          a.romajiPassportSurname.localeCompare(b.romajiPassportSurname),
        title: TEXT_T130,
        width: getValueByLanguage(200, 250),
      },
      {
        dataIndex: "romajiPassportName",
        sorter: (a, b) =>
          a.romajiPassportName.localeCompare(b.romajiPassportName),
        title: TEXT_T131,
        width: getValueByLanguage(200, 250),
      },
      {
        dataIndex: "bumonName",
        sorter: (a, b) => a.bumonName.localeCompare(b.bumonName),
        title: TEXT_T009,
        width: 150,
      },
      {
        dataIndex: "dispatchTypeId",
        sorter: (a, b) => a.dispatchTypeId.localeCompare(b.dispatchTypeId),
        title: TEXT_T039,
        width: 150,
      },
      {
        dataIndex: "dispatchTypeDetailName",
        sorter: (a, b) =>
          a.dispatchTypeDetailName.localeCompare(b.dispatchTypeDetailName),
        title: TEXT_T025,
        width: 260,
      },
      {
        dataIndex: "passportNumber",
        sorter: (a, b) => a.passportNumber.localeCompare(b.passportNumber),
        title: TEXT_T040,
        width: getValueByLanguage(150, 200),
      },
      {
        dataIndex: "familyRomajiSurname",
        sorter: (a, b) =>
          a.familyRomajiSurname.localeCompare(b.familyRomajiSurname),
        title: TEXT_T132,
        width: getValueByLanguage(150, 250),
      },
      {
        dataIndex: "familyRomajiName",
        sorter: (a, b) => a.familyRomajiName.localeCompare(b.familyRomajiName),
        title: TEXT_T133,
        width: getValueByLanguage(150, 250),
      },
      {
        dataIndex: "familyRelationshipName",
        sorter: (a, b) =>
          a.familyRelationshipName.localeCompare(b.familyRelationshipName),
        title: TEXT_T043,
        width: getValueByLanguage(150, 250),
      },
      {
        dataIndex: "familyCountNumber",
        sorter: (a, b) =>
          a.familyCountNumber
            .toString()
            .localeCompare(b.familyCountNumber.toString()),
        title: TEXT_T134,
        width: getValueByLanguage(150, 250),
      },
      {
        dataIndex: "personPrefectureResidenceName",
        sorter: (a, b) =>
          a.personPrefectureResidenceName.localeCompare(
            b.personPrefectureResidenceName,
          ),
        title: TEXT_T218,
        width: getValueByLanguage(150, 260),
      },
      {
        dataIndex: "personCountryPhoneCode",
        sorter: (a, b) =>
          a.personCountryPhoneCode
            .toString()
            .localeCompare(b.personCountryPhoneCode.toString()),
        title: TEXT_T046,
        width: getValueByLanguage(150, 250),
      },
      {
        dataIndex: "personPhoneNumber",
        sorter: (a, b) =>
          a.personPhoneNumber
            .toString()
            .localeCompare(b.personPhoneNumber.toString()),
        title: TEXT_T047,
        width: 150,
      },
      {
        dataIndex: "personEmailAddress",
        sorter: (a, b) =>
          a.personEmailAddress.localeCompare(b.personEmailAddress),
        title: TEXT_T017,
        width: getValueByLanguage(250, 300),
      },
      {
        dataIndex: "personCompanyPosition",
        sorter: (a, b) =>
          a.personCompanyPosition.localeCompare(b.personCompanyPosition),
        title: TEXT_T048,
        width: getValueByLanguage(150, 220),
      },
      {
        dataIndex: "personDispatcherName",
        sorter: (a, b) =>
          a.personDispatcherName.localeCompare(b.personDispatcherName),
        title: TEXT_T049,
        width: getValueByLanguage(150, 220),
      },
      {
        dataIndex: "urgentContactHome",
        sorter: (a, b) =>
          a.urgentContactHome
            .toString()
            .localeCompare(b.urgentContactHome.toString()),
        title: TEXT_T050,
        width: getValueByLanguage(150, 220),
      },
      {
        dataIndex: "urgentContactName",
        sorter: (a, b) =>
          a.urgentContactName.localeCompare(b.urgentContactName),
        title: TEXT_T051,
        width: getValueByLanguage(150, 220),
      },
      {
        dataIndex: "urgentAffiliationContact",
        sorter: (a, b) =>
          a.urgentAffiliationContact
            .toString()
            .localeCompare(b.urgentAffiliationContact.toString()),
        title: TEXT_T052,
        width: getValueByLanguage(150, 250),
      },
      {
        dataIndex: "urgentAffiliationContactName",
        sorter: (a, b) =>
          a.urgentAffiliationContactName.localeCompare(
            b.urgentAffiliationContactName,
          ),
        title: TEXT_T053,
        width: getValueByLanguage(150, 250),
      },
      {
        dataIndex: "workPlaceLocation",
        render: (_, data) => getWorkPlaceLocation(data.workPlaceLocation),
        sorter: (a, b) =>
          a.workPlaceLocation
            .toString()
            .localeCompare(b.workPlaceLocation.toString()),
        title: TEXT_T054,
        width: getValueByLanguage(150, 250),
      },
      {
        dataIndex: "workPlaceCountryName",
        sorter: (a, b) =>
          a.workPlaceCountryName.localeCompare(b.workPlaceCountryName),
        title: TEXT_T055,
        width: 150,
      },
      {
        dataIndex: "workPlaceCountry",
        sorter: (a, b) => a.workPlaceCountry.localeCompare(b.workPlaceCountry),
        title: TEXT_T136,
        width: 150,
      },

      {
        dataIndex: "workPlaceCityName",
        sorter: (a, b) =>
          a.workPlaceCityName.localeCompare(b.workPlaceCityName),
        title: TEXT_T056,
        width: 150,
      },
      {
        dataIndex: "workPlaceCity",
        sorter: (a, b) => a.workPlaceCity.localeCompare(b.workPlaceCity),
        title: TEXT_T137,
        width: 150,
      },
      {
        dataIndex: "workPlace",
        sorter: (a, b) => a.workPlace.localeCompare(b.workPlace),
        title: TEXT_T057,
        width: 150,
      },
      {
        dataIndex: "assigneeInstitutionName",
        sorter: (a, b) =>
          a.assigneeInstitutionName.localeCompare(b.assigneeInstitutionName),
        title: TEXT_T058,
        width: getValueByLanguage(150, 200),
      },
      {
        dataIndex: "assigneePhoneNumber",
        sorter: (a, b) =>
          a.assigneePhoneNumber
            .toString()
            .localeCompare(b.assigneePhoneNumber.toString()),
        title: TEXT_T059,
        width: getValueByLanguage(150, 200),
      },
      {
        dataIndex: "assigneeName",
        sorter: (a, b) => a.assigneeName.localeCompare(b.assigneeName),
        title: TEXT_T060,
        width: 150,
      },

      {
        dataIndex: "insuranceCompanyName",
        sorter: (a, b) =>
          a.insuranceCompanyName.localeCompare(b.insuranceCompanyName),
        title: TEXT_T061,
        width: getValueByLanguage(150, 200),
      },
      {
        dataIndex: "insuranceCompanyPhoneNumber",
        sorter: (a, b) =>
          a.insuranceCompanyPhoneNumber
            .toString()
            .localeCompare(b.insuranceCompanyPhoneNumber.toString()),
        title: TEXT_T062,
        width: getValueByLanguage(150, 200),
      },
      {
        dataIndex: "insuranceTicketNumber",
        sorter: (a, b) =>
          a.insuranceTicketNumber.localeCompare(b.insuranceTicketNumber),
        title: TEXT_T063,
        width: getValueByLanguage(150, 200),
      },
      {
        dataIndex: "insuranceSonsignor",
        sorter: (a, b) =>
          a.insuranceSonsignor.localeCompare(b.insuranceSonsignor),
        title: TEXT_T064,
        width: getValueByLanguage(250, 350),
      },
      {
        dataIndex: "trainingType",
        render: (_, data) =>
          getTrainingType({
            dispatchTypeDetailId: data.dispatchTypeDetailId,
            trainingType: data.trainingType,
          }),
        sorter: (a, b) =>
          a.trainingType.toString().localeCompare(b.trainingType.toString()),
        title: TEXT_T065,
        width: 250,
      },
      {
        dataIndex: "trainingDate",
        render: (_, data) => getDay(data.trainingDate, true),
        sorter: (a, b) => a.trainingDate.localeCompare(b.trainingDate),
        title: TEXT_T066,
        width: getValueByLanguage(150, 250),
      },
      {
        dataIndex: "systemIdentifier",
        sorter: (a, b) =>
          a.systemIdentifier
            .toString()
            .localeCompare(b.systemIdentifier.toString()),
        title: TEXT_T139,
        width: 150,
      },
      {
        dataIndex: "othersDispatchNumber",
        sorter: (a, b) =>
          a.othersDispatchNumber.localeCompare(b.othersDispatchNumber),
        title: TEXT_T075,
        width: 200,
      },
      {
        dataIndex: "othersStaffNumber",
        sorter: (a, b) =>
          a.othersStaffNumber.localeCompare(b.othersStaffNumber),
        title: TEXT_T076,
        width: 200,
      },
      {
        dataIndex: "othersMemberNumber",
        sorter: (a, b) =>
          a.othersMemberNumber.localeCompare(b.othersMemberNumber),
        title: TEXT_T077,
        width: 200,
      },
      {
        dataIndex: "usageAgreement",
        render: (_, data) => getTextBoolean(data.usageAgreement),
        sorter: (a, b) =>
          String(a.usageAgreement).localeCompare(String(b.usageAgreement)),
        title: TEXT_T142,
        width: getValueByLanguage(200, 250),
      },
      {
        dataIndex: "tabirejAgreement",
        render: (_, data) => getTextBoolean(data.tabirejAgreement),
        sorter: (a, b) =>
          String(a.tabirejAgreement).localeCompare(String(b.tabirejAgreement)),
        title: TEXT_T143,
        width: getValueByLanguage(200, 250),
      },
      {
        dataIndex: "gdprAgreement",
        render: (_, data) => getTextBoolean(data.gdprAgreement),
        sorter: (a, b) =>
          String(a.gdprAgreement).localeCompare(String(b.gdprAgreement)),
        title: TEXT_T144,
        width: 400,
      },
      {
        dataIndex: "othersRemarks1",
        sorter: (a, b) => a.othersRemarks1.localeCompare(b.othersRemarks1),
        title: TEXT_T246,
        width: 200,
      },
      {
        dataIndex: "othersRemarks2",
        sorter: (a, b) => a.othersRemarks2.localeCompare(b.othersRemarks2),
        title: TEXT_T247,
        width: 200,
      },
      {
        dataIndex: "othersRemarks3",
        sorter: (a, b) => a.othersRemarks3.localeCompare(b.othersRemarks3),
        title: TEXT_T248,
        width: 200,
      },
      {
        dataIndex: "othersRemarks4",
        sorter: (a, b) => a.othersRemarks4.localeCompare(b.othersRemarks4),
        title: TEXT_T249,
        width: 200,
      },
      {
        dataIndex: "othersRemarks5",
        sorter: (a, b) => a.othersRemarks5.localeCompare(b.othersRemarks5),
        title: TEXT_T250,
        width: 200,
      },
      {
        dataIndex: "othersAdministratorRemarks1",
        sorter: (a, b) =>
          a.othersAdministratorRemarks1.localeCompare(
            b.othersAdministratorRemarks1,
          ),
        title: TEXT_T078,
        width: 200,
      },
      {
        dataIndex: "othersAdministratorRemarks2",
        sorter: (a, b) =>
          a.othersAdministratorRemarks2.localeCompare(
            b.othersAdministratorRemarks2,
          ),
        title: TEXT_T079,
        width: 200,
      },
      {
        dataIndex: "othersAdministratorRemarks3",
        sorter: (a, b) =>
          a.othersAdministratorRemarks3.localeCompare(
            b.othersAdministratorRemarks3,
          ),
        title: TEXT_T080,
        width: 200,
      },
      {
        dataIndex: "othersAdministratorRemarks4",
        sorter: (a, b) =>
          a.othersAdministratorRemarks4.localeCompare(
            b.othersAdministratorRemarks4,
          ),
        title: TEXT_T081,
        width: 200,
      },
      {
        dataIndex: "othersAdministratorRemarks5",
        sorter: (a, b) =>
          a.othersAdministratorRemarks5.localeCompare(
            b.othersAdministratorRemarks5,
          ),
        title: TEXT_T082,
        width: 200,
      },
    ]
    return {
      columns,
    }
  }
