import { FC, Fragment } from "react"
import { useMenu } from "./modules/useMenu"
import { Styled } from "./styled"
import { MenuItem } from "./components/MenuItem"
import { SidebarToggle } from "./components/Toggle"
import { MenuIcon } from "./components/MenuIcon"
import { Regex } from "../../../../constant/Regex"
import { MenuProps } from "./models"
import { Translate } from "../../../../constant/Translate"
import { ModalConfirm } from "../Modal/Confirm"

const { REGEX_IS_DESKTOP } = Regex
const {
  StyledMenu,
  StyledNav,
  StyledSidebarAction,
  StyledSidebarActionItem,
  StyledSidebarHeaderUser,
} = Styled
const { MESSAGE_C0022, MESSAGE_C0040, TEXT_T413 } = Translate

export const Menu: FC<MenuProps> = (props) => {
  const { handleToggleMenu, handleLogout, ...res } = props
  const {
    handleExpandableMenu,
    handleNavLinkClick,
    isExpandableMenu,
    isOpen,
    isOpenFormChange,
    menus,
    onCancel,
    onCancelFormChange,
    onOk,
    onOkFormChange,
    scrollDirection,
  } = useMenu()
  const isDesktop = Boolean(REGEX_IS_DESKTOP && isExpandableMenu)
  return (
    <StyledNav isExpandableMenu={isExpandableMenu} {...res}>
      <SidebarToggle
        isExpandableMenu={isExpandableMenu}
        onClick={handleExpandableMenu}
      />
      <StyledMenu
        className={isDesktop ? "nav-menu-icon" : "nav-menu"}
        isDesktop={isDesktop}
        isExpandableMenu={isExpandableMenu}
        scrollDirection={scrollDirection}
      >
        {menus.map((item, index) => (
          <Fragment key={index}>
            <MenuIcon handleNavLinkClick={handleNavLinkClick} {...item} />
            <MenuItem handleNavLinkClick={handleNavLinkClick} {...item} />
          </Fragment>
        ))}
      </StyledMenu>
      {!REGEX_IS_DESKTOP && (
        <StyledSidebarAction>
          <StyledSidebarHeaderUser handleLogout={handleLogout} />
          <StyledSidebarActionItem onClick={handleToggleMenu}>
            {TEXT_T413}
          </StyledSidebarActionItem>
        </StyledSidebarAction>
      )}
      <ModalConfirm isOpen={isOpen} onCancel={onCancel} onOk={onOk}>
        {MESSAGE_C0022}
      </ModalConfirm>
      <ModalConfirm
        isOpen={isOpenFormChange}
        onCancel={onCancelFormChange}
        onOk={onOkFormChange}
      >
        {MESSAGE_C0040}
      </ModalConfirm>
    </StyledNav>
  )
}
