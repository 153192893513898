/* eslint-disable complexity */
import { Route, Switch, useLocation } from "react-router-dom"
import PrivateRoute from "../../app/routing/privateRoute"
import { FC, Fragment, useEffect } from "react"
import { Routers } from "../../constant/Routers"
import { HomePage } from "../../app/pages/home"
import { TravelersRoute } from "./routing/travelers"
import { SchedulesRoute } from "./routing/schedules"
import { SettingsRoute } from "./routing/settings"
import { AttentionsRoute } from "./routing/attentions"
import { SafetiesRoute } from "./routing/safeties"
import { UsersRoute } from "./routing/users"
import { ScrollToTop } from "./components/ScrollToTop"
import { ErrorPage } from "../../app/pages/error"
import { ExternalRoute } from "./routing/external"
import { defaultLang, getConfig, setLanguage } from "../i18n/OjicaI18n"
import keycloak from "../../utils/keycloak"
import { LoginActions } from "../../constant/Login"
import { SystemsRoute } from "./routing/systems"
import { notification } from "antd"
import { focusBreadcrumbs } from "../../utils/focus"
import { ApplicationFormRoute } from "./routing/applicationForm"

const { SCR318_MAIL, SCR418, SCR911, SCR916, SCR920, SCR973 } = Routers
export const Layout: FC = () => {
  const location = useLocation()

  if (window.location.pathname === "/") window.location.href = SCR920
  const locale = keycloak.idTokenParsed?.locale
    ? keycloak.idTokenParsed?.locale
    : defaultLang

  if (
    locale !== getConfig().selectedLang &&
    localStorage.getItem(LoginActions.KEY) === LoginActions.LOGIN &&
    window.location.pathname !== SCR911
  ) {
    const listPath = [SCR318_MAIL, SCR418, SCR916]
    if (!listPath.includes(window.location.pathname)) setLanguage(locale)
  }

  useEffect(() => {
    notification.destroy()
    focusBreadcrumbs()
  }, [location])

  return (
    <Fragment>
      <ScrollToTop />
      <Switch>
        <Route component={ErrorPage} exact path={SCR973} />
        <PrivateRoute component={HomePage} exact path={SCR920} />
      </Switch>
      <AttentionsRoute />
      <ExternalRoute />
      <SafetiesRoute />
      <SchedulesRoute />
      <SettingsRoute />
      <TravelersRoute />
      <UsersRoute />
      <SystemsRoute />
      <ApplicationFormRoute />
    </Fragment>
  )
}
