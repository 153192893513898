import { FC, Fragment } from "react"
import { GeneralCode } from "../../../../../../constant/GeneralCode"
import { Translate } from "../../../../../../constant/Translate"
import { Styled } from "./styled"
import { SafetiesSuccessAlertType } from "../../../models/success"
import { getValueByLanguage } from "../../../../../../utils/getValueByLanguage"
import { getFullName } from "../../../../../../utils/getFullName"

const { TEXT_T328 } = Translate
const { CTGR_OTHER } = GeneralCode
const { StyledAttentionsCategory } = Styled
const separator = getValueByLanguage("", " ")

export const SuccessAlertHeading: FC<SafetiesSuccessAlertType> = (props) => {
  const { transmissionInfo } = props

  return (
    <Fragment>
      <StyledAttentionsCategory>
        {TEXT_T328}
        {separator}
        <b>
          {transmissionInfo?.categoryId === CTGR_OTHER
            ? getValueByLanguage(
                getFullName(
                  transmissionInfo.categoryName,
                  transmissionInfo.categoryValue,
                  true,
                ),
                getFullName(
                  transmissionInfo.categoryName,
                  transmissionInfo.categoryValue,
                ),
              )
            : transmissionInfo?.categoryName}
        </b>
      </StyledAttentionsCategory>
    </Fragment>
  )
}
