import { getDateRange } from "../../../../../../../../utils/getDate"
import { SchedulesSearchConditionBasicType } from "../../../../../models/list"
import { SchedulesSearchFormDataType } from "../../../../../models/list/search"

export const getDataSearchBasic = (
  data: SchedulesSearchFormDataType,
): SchedulesSearchConditionBasicType => ({
  ...data,
  arrivalDateTimeDate: data.arrivalDateTimeDate
    ? getDateRange(data.arrivalDateTimeDate)
    : "",
  departureDateTimeDate: data.departureDateTimeDate
    ? getDateRange(data.departureDateTimeDate)
    : "",
})
