import styled, { css } from "styled-components"
import { Regex } from "../../../../../../../../constant/Regex"

const { REGEX_IS_DESKTOP } = Regex

const StyledRangePicker = styled.div`
  ${REGEX_IS_DESKTOP
    ? css`
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        .ant-form-item {
          margin: 0;
          min-width: calc(50% - 17px);
          &-control {
            max-width: 100% !important;
          }
          &-label {
            margin-right: 0;
            width: 0;
          }
        }
      `
    : css`
        .ant-form-item:nth-child(2) {
          margin: 0;
        }
      `}
`

const StyledSeparator = styled.span`
  min-width: 34px;
  text-align: center;
`

export const Styled = {
  StyledRangePicker,
  StyledSeparator,
}
