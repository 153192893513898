import { FC, Fragment } from "react"
import { BreakTextProps } from "./models"

export const BreakText: FC<BreakTextProps> = (props) => {
  const { above, below } = props
  return (
    <Fragment>
      {above}
      <br />
      {below}
    </Fragment>
  )
}
