/* eslint-disable max-statements */
/* eslint-disable max-lines */
import { OptionItemType } from "../../../../../../../../_webui/layout/components/Select/models"
import { PatternId } from "../../../../../../../../constant/PatternId"
import { Routers } from "../../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../../infrastructure/axiosError/models"
import { authoritiesApi } from "../../../../../../../../infrastructure/handlers/authorities"
import { AuthorityPatternResType } from "../../../../../../../../infrastructure/handlers/authorities/models"
import { departmentsApi } from "../../../../../../../../infrastructure/handlers/departments"
import { DepartmentsResType } from "../../../../../../../../infrastructure/handlers/departments/models"
import { concat } from "../../../../../../../../utils/concat"
import { getValueByLanguage } from "../../../../../../../../utils/getValueByLanguage"
import { pushStateError } from "../../../../../../../../utils/pushStateError"
import { commonApi } from "../../../../../apis"
import {
  SubAdminFormSearchDataType,
  SubAdminListType,
} from "../../../../../models/subAdmin/list"
import { useVariablesApi } from "../useVariablesApi"

const { PATTERN_ADMIN, PATTERN_USER, PATTERN_USER_1 } = PatternId
const { SCR953, SCR973 } = Routers

export const useApi = () => {
  const {
    authorityPatternSettings,
    authorityPatterns,
    bumonCd,
    departments,
    form,
    isHiddenBtnReset,
    isHiddenBtnRoleSetting,
    isHiddenBtnSearch,
    isHiddenBtnSubAdminSetting,
    isLoading,
    isLoadingLayout,
    isNotAccessScreen,
    isRoleSubAdmin,
    onChange,
    onChangeTable,
    perItem,
    push,
    rowSelection,
    searchCondition,
    selectedRowKeys,
    setAuthorityPatternSettings,
    setAuthorityPatterns,
    setDepartments,
    setIsLoading,
    setIsLoadingLayout,
    setSearchCondition,
    state,
  } = useVariablesApi()

  // APIを呼び出し、権限パターン設定一覧を取得する。
  const getAuthorityPatternSetting = (data: SubAdminFormSearchDataType) => {
    commonApi
      .getUserAuthorityPatternSetting(data)
      .then((res: SubAdminListType[]) => {
        setAuthorityPatternSettings(res)
        setIsLoading(false)
        setIsLoadingLayout(false)
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const stateError = pushStateError(error)
        push({ pathname: SCR973, state: stateError })
      })
  }

  const onFinish = (values: SubAdminFormSearchDataType) => {
    setIsLoading(true)
    getAuthorityPatternSetting(values)
    setSearchCondition(values)
    setTimeout(() => {
      document.getElementById("title-right")?.focus()
    }, 600)
  }

  const callApi = async () => {
    // APIを呼び出し、部署一覧を取得する。
    const getDepartments: DepartmentsResType[] =
      await departmentsApi.getDepartments()
    // APIを呼び出し、権限パターン一覧を取得する。
    const getAuthorityPatterns: AuthorityPatternResType[] =
      await authoritiesApi.getAuthorityPatterns(true)
    Promise.all([getDepartments, getAuthorityPatterns])
      .then((res) => {
        const departmentItems: OptionItemType[] = res[0].map((item) => ({
          text: getValueByLanguage(
            item.departmentNameJapan,
            item.departmentNameEnglish,
          ).toString(),
          value: item.bumonCd,
        }))
        setDepartments(departmentItems)

        const patternIds = [PATTERN_ADMIN, PATTERN_USER, PATTERN_USER_1]
        const authorityPatternsItems: OptionItemType[] = res[1]
          .filter((item) => !patternIds.includes(String(item.patternId)))
          .map((item) => ({
            text: item.patternName,
            value: item.patternId,
          }))
        setAuthorityPatterns(authorityPatternsItems)

        if (isRoleSubAdmin) {
          const _bumonCd = departmentItems
            .find((item) => item.value === bumonCd)
            ?.value?.toString()
          form.setFieldValue("bumonCd", _bumonCd)
          getAuthorityPatternSetting({
            ...searchCondition,
            bumonCd: concat(_bumonCd),
          })
        } else {
          const _patternId = authorityPatternsItems
            .find((item) => item.value === searchCondition.patternId)
            ?.value?.toString()
          if (state?.sourceURL === SCR953 && _patternId)
            form.setFieldValue("patternId", _patternId)
          else {
            form.setFieldsValue({
              ...searchCondition,
              patternId: _patternId,
            })
          }
          getAuthorityPatternSetting(searchCondition)
        }
      })
      .catch((error: AxiosErrorType) => {
        const state = pushStateError(error)
        push({ pathname: SCR973, state })
      })
  }

  return {
    authorityPatternSettings,
    authorityPatterns,
    callApi,
    departments,
    form,
    isHiddenBtnReset,
    isHiddenBtnRoleSetting,
    isHiddenBtnSearch,
    isHiddenBtnSubAdminSetting,
    isLoading,
    isLoadingLayout,
    isNotAccessScreen,
    onChange,
    onChangeTable,
    onFinish,
    perItem,
    rowSelection,
    searchCondition,
    selectedRowKeys,
  }
}
