import { useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { UseParamsType } from "../../../../../../../../utils/useParams"
import { GridItemType } from "../../../../../../../../_webui/layout/components/Grid/models"
import { InitialValues } from "../../../../../constants/roles/success/initialValues"
import { RolesOptionsType } from "../../../../../models/roles/setting"
import { useVariablesAuthority } from "../useVariablesAuthority"

const { INITIAL_VALUES_AUTHORITY_PATTERN_SETTINGS } = InitialValues

export const useVariables = () => {
  const { push } = useHistory()
  const { id: patternId } = useParams<UseParamsType>()
  const { isCommonPermissionSettings, isHiddenBtn } = useVariablesAuthority()
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [authorityPattern, setAuthorityPattern] = useState<GridItemType[]>([])
  const [authorityPatternSettings, setAuthorityPatternSettings] =
    useState<RolesOptionsType>(INITIAL_VALUES_AUTHORITY_PATTERN_SETTINGS)

  return {
    authorityPattern,
    authorityPatternSettings,
    isCommonPermissionSettings,
    isHiddenBtn,
    isLoadingLayout,
    patternId,
    push,
    setAuthorityPattern,
    setAuthorityPatternSettings,
    setIsLoadingLayout,
  }
}
