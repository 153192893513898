/* eslint-disable max-lines */
import styled from "styled-components"
import { Colors } from "../../../../../../../constant/Styled/Colors"
import { NavLink } from "react-router-dom"
import { Fonts } from "../../../../../../../constant/Styled/Fonts"
import { Image } from "../../../../Image"

const { DANGER, MENU_ACTIVE, MENU_SUB, PRIMARY } = Colors
const { FONT_YU_GOTHIC, FS_LARGE, FW_BOLD, FW_MEDIUM } = Fonts

const StyledNavLink = styled(NavLink)`
  align-items: center;
  color: ${PRIMARY};
  display: flex;
  padding: 10px 10px 10px 30px;
  position: relative;
  &.active {
    background-color: ${MENU_ACTIVE};
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    font-weight: ${FW_BOLD};
    overflow: hidden;
    &::before {
      background-color: ${DANGER};
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 6px;
    }
  }
`

const StyledNavTitle = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  color: ${PRIMARY};
  cursor: pointer;
  display: flex;
  font-family: ${FONT_YU_GOTHIC};
  font-size: ${FS_LARGE};
  font-weight: ${FW_MEDIUM};
  justify-content: space-between;
  padding: 10px 10px 10px 30px;
  text-align: left;
  width: 100%;
`
const StyledNavLabel = styled.span`
  cursor: pointer;
  min-width: calc(100% - 50px);
`

const StyledNavContent = styled.ul`
  background-color: ${MENU_SUB};
  height: 0;
  list-style: none;
  margin: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  transform: translateY(-16px);
  transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  visibility: hidden;
  ${StyledNavTitle} {
    padding-left: 60px;
  }
`

const StyledNavItem = styled.li`
  &.active {
    > ${StyledNavContent} {
      height: initial;
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }
  }
`

const StyledArrow = styled.div`
  min-width: 20px;
  text-align: center;
`
const StyledArrowIcon = styled.div`
  border-style: solid;
  border-width: 1px 1px 0 0;
  color: ${PRIMARY};
  content: "";
  cursor: pointer;
  display: inline-block;
  height: 8px;
  position: relative;
  top: -3px;
  transform: rotate(135deg);
  transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  width: 8px;
  &.active {
    transform: rotate(-45deg);
  }
`

const StyledImageBefore = styled(Image)`
  height: 17px;
  margin-right: 10px;
  width: 20px;
`

const StyledImageAfter = styled(Image)`
  height: 12px;
  margin-left: 10px;
  min-width: 14px;
  max-width: 14px;
`

const StyledLabel = styled.label`
  width: calc(100% - 24px);
`

const StyledImage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const Styled = {
  StyledArrow,
  StyledArrowIcon,
  StyledImage,
  StyledImageAfter,
  StyledImageBefore,
  StyledLabel,
  StyledNavContent,
  StyledNavItem,
  StyledNavLabel,
  StyledNavLink,
  StyledNavTitle,
}
