import { UseFilesListHandleProps } from "../../../../../models/files/list"
import { checkTimeoutS3 } from "../../../../../../../../utils/checkTimeoutS3"
import { downloadFiles } from "../../../../../../../../utils/downloadFiles"
import { previewFile } from "../../../../../../../../utils/previewFile"
import { useState } from "react"
import { useModal } from "../../../../../../../../utils/hook/useModal"
import { commonApi } from "../../../../../apis"
import { AxiosErrorType } from "../../../../../../../../infrastructure/axiosError/models"
import { pushStateError } from "../../../../../../../../utils/pushStateError"
import { Routers } from "../../../../../../../../constant/Routers"
import { useHistory } from "react-router-dom"
import { openNotification } from "../../../../../../../../utils/openNotification"
import { Translate } from "../../../../../../../../constant/Translate"
import { replaceStringParam } from "../../../../../../../../utils/replaceStringParam"

const { SCR973 } = Routers
const { MESSAGE_E0134 } = Translate

export const useHandle = (props: UseFilesListHandleProps) => {
  const { fileInfoList, fileName, initDateTime, selectedRowKeys } = props
  const { push } = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const {
    isOpen: isOpenPreviewFile,
    onCancel: onCancelPreviewFile,
    onOpen: onOpenPreviewFile,
  } = useModal()

  const handleDownload = async () => {
    if (checkTimeoutS3(initDateTime)) {
      const files = fileInfoList.filter((item) =>
        selectedRowKeys.includes(item.id),
      )
      setIsLoading(true)
      // 画面上に選択されているファイルをダウンロードする。
      await downloadFiles({
        fileName: fileName,
        files,
        selectedRowKeys,
      }).finally(() => {
        setIsLoading(false)
      })
    }
  }

  const handlePreviewFile = () => {
    if (checkTimeoutS3(initDateTime)) {
      const file = fileInfoList.find((item) =>
        selectedRowKeys.includes(item.id),
      )
      if (file && /^.*\.(pdf|txt)$/.test(file.fileName)) {
        setIsLoading(true)
        commonApi
          .getUrl(file.id)
          .then((data: string) => {
            previewFile(file.fileName, data)
          })
          .catch((error: AxiosErrorType) => {
            const status = error?.debug?.response?.status
            if (status === 403 || status === 404) {
              openNotification(replaceStringParam(MESSAGE_E0134, file.fileName))
            } else {
              // 「SCR973_エラー情報の表示画面」に遷移する。
              const stateError = pushStateError(error)
              push({ pathname: SCR973, state: stateError })
            }
          })
          .finally(() => {
            setIsLoading(false)
          })
      } else if (file) {
        onOpenPreviewFile()
      }
    }
  }

  return {
    handleDownload,
    handlePreviewFile,
    isLoading,
    isOpenPreviewFile,
    onCancelPreviewFile,
  }
}
