import { defaultLang } from "../../_webui/i18n/OjicaI18n"

const i18nConfig = localStorage.getItem("i18nConfig") || ""
export const getSelectedLang = () => {
  let selectedLang = defaultLang
  if (i18nConfig) {
    const json = JSON.parse(i18nConfig)
    selectedLang = json.selectedLang
  }
  return selectedLang
}
