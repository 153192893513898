import { FC } from "react"
import { ButtonSubmit } from "../../../../../_webui/layout/components/Button/Submit"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { Upload } from "../../../../../_webui/layout/components/Upload"
import { File } from "../../../../../constant/File"
import { Translate } from "../../../../../constant/Translate"
import { SchedulesBatchUpdateModalConfirm } from "../../components/batchUpdate/Modal"
import { SchedulesBatchUpdateTable } from "../../components/batchUpdate/Table"
import { useSchedulesBatchUpdate } from "./modules/useBatchUpdate"

const { TEXT_T012, TEXT_T020, TEXT_T304 } = Translate
const { FILE_TYPE_ALLOW_UPLOAD_ACCEPT_ALL } = File

export const SchedulesBatchUpdate: FC = () => {
  const {
    beforeUpload,
    breadcrumbItems,
    bulkDeleteDataConfirm,
    bulkFileUpdate,
    bulkUpdate,
    columnErrors,
    columns,
    countRecord,
    dataTemps,
    errorList,
    fileNameList,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenUploaded,
    isResetTable,
    isVisibleBtnCancel,
    isVisibleBtnUpdate,
    onCancel,
    onCancelUploaded,
    onChange,
    onOpen,
    onOpenDeletedOrGoToHome,
    perItem,
  } = useSchedulesBatchUpdate()

  return (
    <Layout
      isLoadingLayout={isLoadingLayout}
      breadcrumbItems={breadcrumbItems}
      title={TEXT_T304}
    >
      <Card heading={TEXT_T304} isLoading={isLoading}>
        <Upload
          accept={FILE_TYPE_ALLOW_UPLOAD_ACCEPT_ALL}
          customRequest={({ file }) =>
            beforeUpload(file, (upload: boolean) => {
              if (upload) {
                bulkFileUpdate(file)
              }
            })
          }
          fileNameList={fileNameList}
        />

        <SchedulesBatchUpdateTable
          columnErrors={columnErrors}
          columns={columns}
          countRecord={countRecord}
          dataTemps={dataTemps}
          errorList={errorList}
          isResetTable={isResetTable}
          onChange={onChange}
          perItem={perItem}
        />

        <ButtonSubmit
          isDisabledSubmit={isDisabledSubmit}
          isHiddenSubmit={!isVisibleBtnUpdate}
          isReset={isVisibleBtnCancel}
          onClickReset={onOpenDeletedOrGoToHome}
          onClickSubmit={onOpen}
          resetText={TEXT_T012}
          submitText={TEXT_T020}
        />
      </Card>

      <SchedulesBatchUpdateModalConfirm
        bulkDeleteDataConfirm={bulkDeleteDataConfirm}
        bulkUpdate={bulkUpdate}
        isOpen={isOpen}
        isOpenUploaded={isOpenUploaded}
        onCancel={onCancel}
        onCancelUploaded={onCancelUploaded}
      />
    </Layout>
  )
}
