import { FC, Fragment } from "react"
import { ItemInput } from "../../../../../../../../_webui/layout/components/Form/Item/Input"
import { Translate } from "../../../../../../../../constant/Translate"
import { validatorHalfSize } from "../../../../../../../../utils/validator/halfSize"
import { SchedulesAddAssignmentProps } from "./models"

const { HELP_H0019, TEXT_T199, TEXT_T200, TEXT_T201, TEXT_T236 } = Translate

export const SchedulesAddAssignment: FC<SchedulesAddAssignmentProps> = (
  props,
) => {
  const { isRoleAdmin } = props

  return (
    <Fragment>
      <ItemInput label={TEXT_T199} maxLength={200} name="projectNameJp" />

      <ItemInput
        label={TEXT_T200}
        maxLength={200}
        name="projectNameEn"
        rules={[validatorHalfSize]}
      />

      {isRoleAdmin ? (
        <ItemInput
          label={TEXT_T201}
          maxLength={50}
          name="procurementControlNumber"
          rules={[validatorHalfSize]}
          tooltip={HELP_H0019}
        />
      ) : (
        <ItemInput
          isReadOnly
          label={TEXT_T201}
          name="procurementControlNumber"
        />
      )}

      <ItemInput label={TEXT_T236} maxLength={100} name="locationPersonName" />
    </Fragment>
  )
}
