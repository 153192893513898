import { useModal } from "../../../../../../../utils/hook/useModal"

export const useVariablesModal = () => {
  const { isOpen, onCancel, onOpen } = useModal()
  const {
    isOpen: isOpenDeleted,
    onCancel: onCancelDeleted,
    onOpen: onOpenDeleted,
  } = useModal()

  return {
    isOpen,
    isOpenDeleted,
    onCancel,
    onCancelDeleted,
    onOpen,
    onOpenDeleted,
  }
}
