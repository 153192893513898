const ODAL_REGEX = /ODAL_\d{8}_\d+\.csv/
const ZAIGAI_REGEX =
  /ZAIGAI_TOKOUSHA_\d{8}_\d{6}\.csv|ZAIGAI_TOKOUYOTEI_\d{8}_\d{6}\.csv/
const SHINHAKEN_REGEX =
  /SHINHAKEN_TOKOUSHA_\d{14}\.csv|SHINHAKEN_TOKOUYOTEI_\d{14}\.csv/
const BORAN_REGEX = /BORAN_TOKOUSHA_\d{14}\.csv|BORAN_TOKOUYOTEI_\d{14}\.csv/
const BORAN_SHINHAKEN_ZAIGAI_REGEX = /BORAN|SHINHAKEN|ZAIGAI/

const SYSTEM_LENGTH = {
  ODAL_SYSTEM_LENGTH: 74,
  TOKOUSHA_SYSTEM_LENGTH: 55,
  TOKOUYOTEI_SYSTEM_LENGTH: 54,
}

const SYSTEM_NAMES = {
  BORAN_NAME: "ボランティアシステム",
  ERROR_SYSTEM_LENGTH:
    "アップロードされたファイルのフォーマットが不正です。再度ご確認ください。 項目数:「{0}」",
  FILE_TYPE_NAME: "再度ご確認ください。項目数: csv",
  IDM_NAME: "IDMシステム",
  INVALID_NAME: "該当するシステムがありません",
  SHINHAKEN_NAME: "新派遣システム",
  ZAIGAI_NAME: "在外職員システム",
}

const SYSTEM_IDS = {
  BORAN: "4",
  IDM: "1",
  INVALID: "0",
  SHINHAKEN: "3",
  ZAIGAI: "2",
}

export const Variables = {
  BORAN_REGEX,
  BORAN_SHINHAKEN_ZAIGAI_REGEX,
  ODAL_REGEX,
  SHINHAKEN_REGEX,
  SYSTEM_IDS,
  SYSTEM_LENGTH,
  SYSTEM_NAMES,
  ZAIGAI_REGEX,
}
