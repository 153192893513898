/* eslint-disable max-lines */
import { Table } from "antd"
import { ItemDatePicker } from "../../../../../../../../_webui/layout/components/Form/Item/DatePicker"
import { ItemInput } from "../../../../../../../../_webui/layout/components/Form/Item/Input"
import { ItemLabel } from "../../../../../../../../_webui/layout/components/Form/Item/Label"
import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../../_webui/layout/components/Table/models"
import { LabelRequired } from "../../../../../../../../_webui/layout/components/Typography/LabelRequired"
import { Translate } from "../../../../../../../../constant/Translate"
import { getValueByLanguage } from "../../../../../../../../utils/getValueByLanguage"
import { validatorHalfSize } from "../../../../../../../../utils/validator/halfSize"
import { validatorRangeDate } from "../../../../../../../../utils/validator/rangeDate"
import { UseTableLodgingsConfigProps } from "../../../../../components/add/Form/ContentCollapse/Detail/models"
import { ScheduleInfoLodgingsType } from "../../../../../models/add"
import { setNameRowLodging } from "../../../utils/setNameRowLodging"
import { requiredField } from "../../../../../../../../utils/requiredField"

const {
  HELP_H0022,
  TEXT_T210,
  TEXT_T240,
  TEXT_T251,
  TEXT_T252,
  TEXT_T253,
  TEXT_T254,
  TEXT_T255,
} = Translate

const { SELECTION_COLUMN } = Table

export const useTableLodgingsConfig = (
  props: UseTableLodgingsConfigProps,
): UseTableConfigType<ScheduleInfoLodgingsType> => {
  const { isRequiredLabel, isSubmitValidate, onBlur, onChangeDate, onKeyDown } =
    props
  const isRequired = requiredField(isRequiredLabel)
  const getLabel = (label: string) =>
    isRequiredLabel ? <LabelRequired label={label} /> : label

  const columns: ColumnType<ScheduleInfoLodgingsType>[] = [
    {
      render: (_, _data, index) => index + 1,
      title: TEXT_T240,
      width: 65,
    },
    SELECTION_COLUMN,
    {
      render: (_, data) => (
        <ItemInput
          isHidden
          isHiddenLabel
          label={TEXT_T255}
          name={`${setNameRowLodging(data.index)}.travelLodgingId`}
        />
      ),
      title: <div className="d-none">{TEXT_T255}</div>,
      width: 0,
    },
    {
      render: (_, data) => {
        const lodgingCheckin = `${setNameRowLodging(data.index)}.lodgingCheckin`
        const lodgingCheckout = `${setNameRowLodging(
          data.index,
        )}.lodgingCheckout`

        const _validatorRangeDate = isSubmitValidate
          ? [
              validatorRangeDate({
                isLodging: true,
                namePathEndDate: lodgingCheckout,
                namePathStartDate: lodgingCheckin,
              }),
            ]
          : []
        return (
          <ItemDatePicker
            name={lodgingCheckin}
            onBlur={(event) => onBlur(event, TEXT_T251, lodgingCheckin)}
            onKeyDown={(event) => onKeyDown(event, TEXT_T251, lodgingCheckin)}
            rules={[...isRequired, ..._validatorRangeDate]}
          />
        )
      },
      title: getLabel(TEXT_T251),
      width: 220,
    },
    {
      render: (_, data) => {
        const lodgingCheckin = `${setNameRowLodging(data.index)}.lodgingCheckin`
        const lodgingCheckout = `${setNameRowLodging(
          data.index,
        )}.lodgingCheckout`
        return (
          <ItemDatePicker
            name={lodgingCheckout}
            onBlur={(event) => onBlur(event, TEXT_T252, lodgingCheckout)}
            onChange={() => onChangeDate(lodgingCheckin)}
            onKeyDown={(event) => onKeyDown(event, TEXT_T252, lodgingCheckout)}
            rules={isRequired}
          />
        )
      },
      title: getLabel(TEXT_T252),
      width: 220,
    },
    {
      render: (_, data) => (
        <ItemInput
          isHiddenLabel
          label={TEXT_T210}
          maxLength={200}
          name={`${setNameRowLodging(data.index)}.lodgingName`}
          rules={isRequired}
        />
      ),
      title: getLabel(TEXT_T210),
      width: 250,
    },
    {
      render: (_, data) => (
        <ItemInput
          isHiddenLabel
          label={TEXT_T254}
          maxLength={20}
          name={`${setNameRowLodging(data.index)}.lodgingPhoneNumber`}
          rules={[validatorHalfSize]}
        />
      ),
      title: <ItemLabel label={TEXT_T254} tooltip={HELP_H0022} />,
      width: 330,
    },
    {
      render: (_, data) => (
        <ItemInput
          isHiddenLabel
          label={TEXT_T253}
          maxLength={500}
          name={`${setNameRowLodging(data.index)}.lodgingAddress`}
        />
      ),
      title: TEXT_T253,
      width: getValueByLanguage(250, 300),
    },
  ]

  return {
    columns,
  }
}
