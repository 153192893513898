import { FC } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { ButtonSubmit } from "../../../../../../_webui/layout/components/Button/Submit"
import { SchedulesBatchDeleteModalButtonSubmitProps } from "../../../models/batchDelete"
const { TEXT_T012, TEXT_T034 } = Translate

export const SchedulesBatchDeleteModalButtonSubmit: FC<
  SchedulesBatchDeleteModalButtonSubmitProps
> = (props) => {
  const {
    isDisabledSubmit,
    isVisibleBtnCancel,
    isVisibleBtnDelete,
    onOpen,
    onOpenDeletedOrGoToHome,
  } = props

  return (
    <ButtonSubmit
      isDisabledSubmit={isDisabledSubmit}
      isHiddenSubmit={!isVisibleBtnDelete}
      isReset={isVisibleBtnCancel}
      onClickReset={onOpenDeletedOrGoToHome}
      onClickSubmit={onOpen}
      resetText={TEXT_T012}
      submitText={TEXT_T034}
      submitType="danger"
    />
  )
}
