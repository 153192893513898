import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const { isSafetiesSendAll, isRoleSubAdmin } = useAuthority()

  const isHiddenEditBtn = !(isRoleSubAdmin && isSafetiesSendAll)

  return {
    isHiddenEditBtn,
    isSafetiesSendAll,
  }
}
