import { Breadcrumbs } from "../../../../../../../../constant/Breadcrumbs"
import { Routers } from "../../../../../../../../constant/Routers"

const {
  BRCR_SCR319,
  BRCR_SCR319_FROM_SCR316,
  BRCR_SCR319_FROM_SCR316_HISTORY,
} = Breadcrumbs
const { SCR318 } = Routers

export const getBreadcrumbItems = (
  isFromSCR316: boolean,
  isFromSCR316History: boolean,
  sourceURL?: string,
) => {
  if (sourceURL === SCR318 && isFromSCR316History) {
    if (isFromSCR316History) return BRCR_SCR319_FROM_SCR316_HISTORY
    else if (isFromSCR316) return BRCR_SCR319_FROM_SCR316
    return BRCR_SCR319
  }

  return BRCR_SCR319
}
