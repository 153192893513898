import { BreadcrumbsAttentions } from "./Attentions"
import { BreadcrumbsCommon } from "./Common"
import { BreadcrumbsSafeties } from "./Safeties"
import { BreadcrumbsSchedules } from "./Schedules"
import { BreadcrumbsTravelers } from "./Travelers"

export const Breadcrumbs = {
  ...BreadcrumbsAttentions,
  ...BreadcrumbsCommon,
  ...BreadcrumbsSafeties,
  ...BreadcrumbsSchedules,
  ...BreadcrumbsTravelers,
}
