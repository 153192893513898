/* eslint-disable complexity */
import { Routers } from "../../../../../../../constant/Routers"
import { useAuthority } from "../../../../../../../utils/hook/useAuthority"
import { SchedulesAddSuccessStateType } from "../../../../models/addSuccess"

const { SCR210 } = Routers

export const useVariablesAuthority = (
  pathname: string,
  state: SchedulesAddSuccessStateType,
) => {
  const {
    isCommonRefer,
    isCommonTravelerRefer,
    isRoleAdmin,
    isRoleSubAdmin,
    isRoleUser,
    isScheduleChange,
  } = useAuthority()

  const isSchedulesAddSuccess = pathname.includes("add-success")
  const isSchedulesEditSuccess = pathname.includes("edit-success")

  const isNotAccessScreen =
    !isCommonTravelerRefer &&
    !isCommonRefer &&
    (isSchedulesAddSuccess || (isSchedulesEditSuccess && !isScheduleChange))

  const isVisibleOtherRemarks =
    (isRoleAdmin || isRoleSubAdmin) && state?.schedulesURL !== SCR210

  // 「権限区分」欄と「小機能ID」欄の条件を満たした場合にボタンが表示される。
  const isVisibleBtn = isRoleAdmin || isRoleSubAdmin || isRoleUser

  const isVisibleBtnEdit =
    isSchedulesEditSuccess &&
    isVisibleBtn &&
    (isCommonTravelerRefer || isScheduleChange)

  const isVisibleBtnSearch =
    isVisibleBtn &&
    (isCommonTravelerRefer ||
      isCommonRefer ||
      (isSchedulesEditSuccess && isScheduleChange))

  return {
    isNotAccessScreen,
    isVisibleBtnEdit,
    isVisibleBtnSearch,
    isVisibleOtherRemarks,
  }
}
