import styled, { css } from "styled-components"
import { Regex } from "../../../../../../../constant/Regex"
import { StyledPickerCustomProps } from "../../DatePicker/models"

const { REGEX_IS_DESKTOP } = Regex

const StyledPickerCustom = styled.div<StyledPickerCustomProps>`
  ${!REGEX_IS_DESKTOP &&
  css`
    display: flex;
    flex-wrap: wrap;
    .ant {
      &-time {
        &-picker {
          &-minutes {
            padding-left: 5px;
          }
          &-hour,
          &-minutes {
            max-width: calc(50%);
            margin: 0;
            &::before {
              display: none;
            }
          }
        }
      }
    }
  `}
`

export const Styled = {
  StyledPickerCustom,
}
