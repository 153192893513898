import { Endpoint } from "../../../constant/Endpoint"
import { Environment } from "../../../constant/Environment"
import { apiService } from "../../apiService"

const { TRAVELER_ENDPOINT } = Environment
const { FNC1A_Get_Timezone } = Endpoint

export const timezoneApi = {
  getTimezone: () =>
    apiService({
      data: {},
      method: "GET",
      url: `${TRAVELER_ENDPOINT}${FNC1A_Get_Timezone}`,
    }),
}
