import ReactDOM from "react-dom/client"
import App from "./app"
import reportWebVitals from "./reportWebVitals"
import { OjicaI18nProvider } from "./_webui/i18n/OjicaI18n"
import { StyledGlobal } from "./styled"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <OjicaI18nProvider>
    <StyledGlobal />
    <App />
  </OjicaI18nProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
