import { FC } from "react"
import { Regex } from "../../../../../../../constant/Regex"
import { Translate } from "../../../../../../../constant/Translate"
import { Styled } from "../styled"

const {
  TEXT_T471,
  TEXT_T474,
  TEXT_T654,
  TEXT_T655,
  TEXT_T656,
  TEXT_T657,
  TEXT_T658,
  TEXT_T659,
  TEXT_T660,
} = Translate
const {
  StyledItem,
  StyledItemCheckboxHeader,
  StyledItemCheckboxHeaderLast,
  StyledRow,
} = Styled
const { REGEX_IS_DESKTOP } = Regex

export const RolesTableHeader: FC = () => {
  return (
    <StyledRow>
      <StyledItem></StyledItem>
      <StyledItemCheckboxHeader>{TEXT_T654}</StyledItemCheckboxHeader>
      <StyledItemCheckboxHeader>{TEXT_T655}</StyledItemCheckboxHeader>
      <StyledItemCheckboxHeader>{TEXT_T656}</StyledItemCheckboxHeader>
      <StyledItemCheckboxHeader>{TEXT_T657}</StyledItemCheckboxHeader>
      <StyledItemCheckboxHeader>{TEXT_T658}</StyledItemCheckboxHeader>
      <StyledItemCheckboxHeader>{TEXT_T471}</StyledItemCheckboxHeader>
      <StyledItemCheckboxHeader>{TEXT_T659}</StyledItemCheckboxHeader>
      <StyledItemCheckboxHeader>{TEXT_T660}</StyledItemCheckboxHeader>
      <StyledItemCheckboxHeader>{TEXT_T474}</StyledItemCheckboxHeader>
      {REGEX_IS_DESKTOP && (
        <StyledItemCheckboxHeaderLast></StyledItemCheckboxHeaderLast>
      )}
    </StyledRow>
  )
}
