import styled, { css } from "styled-components"
import { Colors } from "../../../../../../constant/Styled/Colors"
import { Fonts } from "../../../../../../constant/Styled/Fonts"
import { StyledHeadingProps } from "../models"

const { DANGER, PRIMARY } = Colors
const { FS_X_LARGE, FW_MEDIUM } = Fonts

const StyledHeading = styled.div<StyledHeadingProps>`
  ${(props) =>
    props.type === "primary" &&
    css`
      border-bottom: 1px solid ${PRIMARY};
      margin-top: 30px;
      padding-bottom: 4px;
    `};
  color: ${(props) => (props.type === "primary" ? PRIMARY : DANGER)};
  font-size: ${FS_X_LARGE};
  font-weight: ${FW_MEDIUM};
`

export const Styled = {
  StyledHeading,
}
