/* eslint-disable max-lines */
import { useEffect } from "react"
import { Routers } from "../../../../../../../constant/Routers"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { getDateMoment } from "../../../../../../../utils/getDate"
import { replaceString } from "../../../../../../../utils/replaceString"
import { UseAttentionsListType } from "../../../../models/list"
import { useVariables } from "../useVariables"

const { SCR316, SCR316_HISTORY, SCR317, SCR318 } = Routers

export const useAttentionsList = (): UseAttentionsListType => {
  const {
    attentionDetailId,
    attentionId,
    attentionInfoList,
    breadcrumbItems,
    categoryList,
    columns,
    form,
    getAttentions,
    isCommonRefer,
    isDisabledDetailBtn,
    isDisabledResponseBtn,
    isDisabledResponseHistoryBtn,
    isHiddenDetailBtn,
    isHiddenResponseBtn,
    isHiddenResponseHistoryBtn,
    isLoading,
    isLoadingLayout,
    isOpen,
    isRoleSubAdmin,
    onBlur,
    onCancelPopup,
    onChange,
    onFinish,
    onKeyDown,
    onOpenPopup,
    pathname,
    perItem,
    push,
    rowSelection,
    searchCondition,
    severityList,
    statusList,
  } = useVariables()

  const goToAttentionsDetail = (isHiddenSubmit: boolean) => {
    //「SCR317_注意喚起の詳細画面」に遷移する。
    push({
      pathname: replaceString(SCR317, attentionId.toString()),
      state: {
        attentionId: attentionId,
        isFromSCR316: pathname === SCR316,
        isFromSCR316History: pathname === SCR316_HISTORY,
        isHiddenSubmit,
        searchCondition: {
          attentionTitle: searchCondition?.attentionTitle,
          categoryId: searchCondition?.categoryId,
          implementationDateTime: searchCondition?.implementationDateTime,
          severity: searchCondition?.severity,
          statusId: searchCondition?.statusId,
        },
        sourceURL: pathname || SCR316_HISTORY,
      },
    })
  }

  const goToAttentionsInfo = () => {
    const listAttentionDetailId = attentionInfoList
      .filter((item) => item.attentionDetailId === attentionDetailId)
      .map((item) => item.attentionDetailId)
    //「SCR318_注意喚起情報の表示画面」に遷移する。
    push({
      pathname: replaceString(SCR318, attentionId.toString()),
      state: {
        attentionId: attentionId,
        isFromSCR316: pathname === SCR316,
        isFromSCR316History: pathname === SCR316_HISTORY,
        listAttentionDetailId: listAttentionDetailId,
        searchCondition: searchCondition,
        sourceURL: pathname || SCR316_HISTORY,
      },
    })
  }

  useEffect(() => {
    // ユーザの画面アクセス許可チェックを処理する。
    if (!isCommonRefer) {
      // 「SCR973_エラー情報の表示画面」に遷移する。
      const { pathname, state } = backToLogin()
      push({ pathname, state })
    } else {
      getAttentions({
        ...searchCondition,
        isMenuSubAdmin: isRoleSubAdmin,
      })

      form.setFieldsValue({
        ...searchCondition,
        implementationDateTime:
          searchCondition?.implementationDateTime &&
          getDateMoment(searchCondition.implementationDateTime),
      })
    }
  }, [])

  return {
    attentionInfoList,
    breadcrumbItems,
    categoryList,
    columns,
    form,
    goToAttentionsDetail,
    goToAttentionsInfo,
    isDisabledDetailBtn,
    isDisabledResponseBtn,
    isDisabledResponseHistoryBtn,
    isHiddenDetailBtn,
    isHiddenResponseBtn,
    isHiddenResponseHistoryBtn,
    isLoading,
    isLoadingLayout,
    isOpen,
    onBlur,
    onCancelPopup,
    onChange,
    onFinish,
    onKeyDown,
    onOpenPopup,
    perItem,
    rowSelection,
    severityList,
    statusList,
  }
}
