const FILE_COLUMN_LIMIT_SCHEDULES = 49
const FILE_COLUMN_LIMIT_SCHEDULES_USER = 44
const FILE_COLUMN_LIMIT_TRAVELERS = 64
const FILE_MAX_UPLOAD = 10
const FILE_NAME_LIMIT = 250
const FILE_SIZE_LIMIT_KB = 10240
const FILE_SIZE_LIMIT_MB = 10
const FILE_TYPE_ALLOW = "csv"
const FILE_TYPE_ALLOW_UPLOAD_ACCEPT =
  ".doc, .docx, .pdf, .ppt, .pptx, .txt, .xls, .xlsx"
const FILE_TYPE_ALLOW_UPLOAD_ACCEPT_ALL =
  ".doc, .docx, .pdf, .ppt, .pptx, .txt, .xls, .xlsx, .csv"
const FILE_TYPE_ALLOW_UPLOAD_EXTENSION = [
  "application/msword",
  "application/pdf",
  "application/vnd.ms-excel",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "text/plain",
]
const SCR122 = "SCR122"
const SCR123 = "SCR123"
const SCR124 = "SCR124"
const SCR220 = "SCR220"
const SCR221 = "SCR221"
const SCR222 = "SCR222"
const SCR220_MENU_ID = "MEN0000008"
const SCR220_USER_MENU_ID = "MEN0000020"
const SCR221_MENU_ID = "MEN0000009"
const SCR221_USER_MENU_ID = "MEN0000021"
const SCR222_MENU_ID = "MEN0000010"
const SCR222_USER_MENU_ID = "MEN0000022"

export const File = {
  FILE_COLUMN_LIMIT_SCHEDULES,
  FILE_COLUMN_LIMIT_SCHEDULES_USER,
  FILE_COLUMN_LIMIT_TRAVELERS,
  FILE_MAX_UPLOAD,
  FILE_NAME_LIMIT,
  FILE_SIZE_LIMIT_KB,
  FILE_SIZE_LIMIT_MB,
  FILE_TYPE_ALLOW,
  FILE_TYPE_ALLOW_UPLOAD_ACCEPT,
  FILE_TYPE_ALLOW_UPLOAD_ACCEPT_ALL,
  FILE_TYPE_ALLOW_UPLOAD_EXTENSION,
  SCR122,
  SCR123,
  SCR124,
  SCR220,
  SCR220_MENU_ID,
  SCR220_USER_MENU_ID,
  SCR221,
  SCR221_MENU_ID,
  SCR221_USER_MENU_ID,
  SCR222,
  SCR222_MENU_ID,
  SCR222_USER_MENU_ID,
}
