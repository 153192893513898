/* eslint-disable max-statements */
/* eslint-disable max-lines */
import { useState } from "react"
import { useLocation } from "react-router-dom"
import { OptionItemType } from "../../../../../../../_webui/layout/components/Select/models"
import { SchedulesListStateType } from "../../../../models/list"
import { useExportFile } from "../useExportFile"
import { useRouters } from "../useRouters"
import { useApi } from "../useApi"
import { useTableConfig } from "../useTableConfig"
import { useVariablesUtils } from "../useVariablesUtils"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { getBreadcrumbItems } from "./getBreadcrumbItems"
import { useFormAdvanced } from "../useFormAdvanced"
import { useDatePicker } from "../../../../../../../utils/hook/useDatePicker"
import { Routers } from "../../../../../../../constant/Routers"

const { SCR210 } = Routers

export const useVariables = () => {
  const { state, pathname } = useLocation<SchedulesListStateType>()
  const breadcrumbItems = getBreadcrumbItems(
    pathname,
    state?.isFromSCR114UserSearch,
  )
  const [countryCode, setCountryCode] = useState("")
  const {
    allCountries,
    cities,
    dispatchTypeAll,
    personPrefectureResidence,
    transportation,
  } = useVariablesUtils(countryCode)
  // 画面のメインボタンの初期表示処理を行う。
  const {
    isAuthorities,
    isHiddenBtnCreateOrSendAttentions,
    isHiddenBtnCreateOrSendSafeties,
    isHiddenBtnDelete,
    isHiddenBtnEdit,
    isHiddenBtnExportCsv,
    isHiddenBtnExportExcel,
    isHiddenBtnExportExcelSafeties,
    isHiddenBtnExportExcelTravelers,
    isHiddenBtnRefer,
    isHiddenBtnResetForm,
    isHiddenBtnSearchBasic,
    isPathnameSchedules,
    isPathnameSchedulesOrTravelers,
    isRoleSubAdmin,
  } = useVariablesAuthority(state?.isFromSCR114UserSearch)

  const isGetCountries = pathname !== SCR210 && isRoleSubAdmin

  const {
    countries,
    dataSource,
    dataSourceSelected,
    departments,
    form,
    formAdvanced,
    fullName,
    getData,
    getSchedules,
    handleAdvancedSearch,
    handleSearch,
    isDisabledBtnCreateOrSend,
    isDisabledBtnDelete,
    isDisabledBtnRefer,
    isLoadingLayout,
    isLoadingTable,
    isOpen,
    isOpenLoadData,
    isOpenShowData,
    isOverseasResident,
    isPathnameAttentionsOrSafeties,
    isResetTable,
    isSearchAdvance,
    isSearchBasic,
    onCancel,
    onCancelCustomLoadData,
    onChange,
    onChangeTable,
    onOkLoadData,
    onOkShowData,
    onOpen,
    overRecordLabel,
    perItem,
    purposeTravel,
    rowSelection,
    searchCondition,
    selectedRowKeys,
    setIsOverseasResident,
    setIsSearchAdvance,
    setSearchCondition,
    setSearchConditionAdvanced,
    setTravelerId,
    sortedInfo,
    travelScheduleId,
    userInformation,
  } = useApi({ allCountries, isGetCountries, isRoleSubAdmin })

  const {
    handleCancelModalExport,
    handleExportFile,
    handleOpenModalExport,
    isOpenModalExport,
    messageConfirmDownload,
    push,
  } = useExportFile({ dataSource, selectedRowKeys })

  const {
    goBack,
    goToAttention,
    goToSafety,
    goToSchedulesAdd,
    goToSchedulesConfirmDelete,
    goToSchedulesDetail,
    goToSchedulesEdit,
  } = useRouters({
    dataSourceSelected,
    isFromSCR114UserSearch: state?.isFromSCR114UserSearch,
    isSearchBasic,
    searchCondition,
    searchConditionTraveler: state?.searchConditionTraveler,
    travelScheduleId,
    travelerId: state?.travelerId,
  })
  const { onBlur: onBlurBasic, onKeyDown: onKeyDownBasic } = useDatePicker({
    form,
  })
  const [dispatchType, setDispatchType] = useState<OptionItemType[]>([])
  const [dispatchTypeDetail, setDispatchTypeDetail] = useState<
    OptionItemType[]
  >([])
  const [isDisabledDispatchTypeDetail, setIsDisabledDispatchTypeDetail] =
    useState(true)

  const { columns } = useTableConfig(isPathnameSchedules, sortedInfo)
  const {
    arrivalHourItems,
    arrivalMinuteItems,
    isDefaultValueArrivalDate,
    isDefaultValueDepartureDate,
    onChangeArrivalDate,
    onChangeDepartureDate,
    onValuesChangeFormSearchAdvanced,
  } = useFormAdvanced(formAdvanced)

  return {
    allCountries,
    arrivalHourItems,
    arrivalMinuteItems,
    breadcrumbItems,
    cities,
    columns,
    countries,
    dataSource,
    departments,
    dispatchType,
    dispatchTypeAll,
    dispatchTypeDetail,
    form,
    formAdvanced,
    fullName,
    getData,
    getSchedules,
    goBack,
    goToAttention,
    goToSafety,
    goToSchedulesAdd,
    goToSchedulesConfirmDelete,
    goToSchedulesDetail,
    goToSchedulesEdit,
    handleAdvancedSearch,
    handleCancelModalExport,
    handleExportFile,
    handleOpenModalExport,
    handleSearch,
    isAuthorities,
    isDefaultValueArrivalDate,
    isDefaultValueDepartureDate,
    isDisabledBtnCreateOrSend,
    isDisabledBtnDelete,
    isDisabledBtnRefer,
    isDisabledDispatchTypeDetail,
    isFromSCR114UserSearch: state?.isFromSCR114UserSearch,
    isGetCountries,
    isHiddenBtnCreateOrSendAttentions,
    isHiddenBtnCreateOrSendSafeties,
    isHiddenBtnDelete,
    isHiddenBtnEdit,
    isHiddenBtnExportCsv,
    isHiddenBtnExportExcel,
    isHiddenBtnExportExcelSafeties,
    isHiddenBtnExportExcelTravelers,
    isHiddenBtnRefer,
    isHiddenBtnResetForm,
    isHiddenBtnSearchBasic,
    isLoadingLayout,
    isLoadingTable,
    isOpen,
    isOpenLoadData,
    isOpenModalExport,
    isOpenShowData,
    isOverseasResident,
    isPathnameAttentionsOrSafeties,
    isPathnameSchedules,
    isPathnameSchedulesOrTravelers,
    isResetTable,
    isSearchAdvance,
    isSearchBasic,
    messageConfirmDownload,
    onBlurBasic,
    onCancel,
    onCancelCustomLoadData,
    onChange,
    onChangeArrivalDate,
    onChangeDepartureDate,
    onChangeTable,
    onKeyDownBasic,
    onOkLoadData,
    onOkShowData,
    onOpen,
    onValuesChangeFormSearchAdvanced,
    overRecordLabel,
    pathname,
    perItem,
    personPrefectureResidence,
    purposeTravel,
    push,
    rowSelection,
    searchCondition,
    setCountryCode,
    setDispatchType,
    setDispatchTypeDetail,
    setIsDisabledDispatchTypeDetail,
    setIsOverseasResident,
    setIsSearchAdvance,
    setSearchCondition,
    setSearchConditionAdvanced,
    setTravelerId,
    state,
    transportation,
    userInformation,
  }
}
