/* eslint-disable max-statements */
import Excel from "exceljs"
import { saveAs } from "file-saver"
import { SchedulesListDataType } from "../../../../../../models/list"
import { exportFileName } from "../../../../../../../../../utils/exportFileName"
import { convertDataExportExcel } from "./convertDataExport"
import { cellConfig } from "./cellConfig"
import { columnsConfig } from "./columnsConfig"
import { initialValues } from "./initialValues"
import { Translate } from "../../../../../../../../../constant/Translate"

const { INITIAL_VALUES } = initialValues
const { EXPORT_EP0196 } = Translate

export const exportExcelFRM301 = async (data: SchedulesListDataType[]) => {
  const workbook = new Excel.Workbook()

  const fullNameRomaji = "Sheet1"
  const worksheet = workbook.addWorksheet(fullNameRomaji)
  const convertData = convertDataExportExcel(data)

  columnsConfig(worksheet)

  convertData.forEach((itemRow) => {
    worksheet.addRow(itemRow)
    const dataRow2 = {
      ...INITIAL_VALUES,
      phoneNumber: itemRow.locationPhoneNumber2,
    }
    worksheet.addRow(dataRow2)

    const dataRow3 = {
      ...INITIAL_VALUES,
      personEmailAddress: itemRow.emailAddress2,
      phoneNumber: itemRow.satellitePhoneNumber,
    }
    worksheet.addRow(dataRow3)

    const dataRow4 = {
      ...INITIAL_VALUES,
      phoneNumber: itemRow.personPhoneNumber,
    }
    worksheet.addRow(dataRow4)
  })

  for (let index = 2; index < data.length * 4; index += 4) {
    const dataMergeCells = index
    worksheet.mergeCells(`$A$${dataMergeCells}:$A${dataMergeCells + 3}`)
    worksheet.mergeCells(`$B$${dataMergeCells}:$B${dataMergeCells + 3}`)
    worksheet.mergeCells(`$C$${dataMergeCells}:$C${dataMergeCells + 3}`)
    worksheet.mergeCells(`$D$${dataMergeCells}:$D${dataMergeCells + 3}`)
    worksheet.mergeCells(`$G$${dataMergeCells}:$G${dataMergeCells + 3}`)
    worksheet.mergeCells(`$H$${dataMergeCells}:$H${dataMergeCells + 3}`)
    worksheet.mergeCells(`$I$${dataMergeCells}:$I${dataMergeCells + 3}`)
    worksheet.mergeCells(`$J$${dataMergeCells}:$J${dataMergeCells + 3}`)
    worksheet.mergeCells(`$K$${dataMergeCells}:$K${dataMergeCells + 3}`)
    worksheet.mergeCells(`$L$${dataMergeCells}:$L${dataMergeCells + 3}`)
    worksheet.mergeCells(`$M$${dataMergeCells}:$M${dataMergeCells + 3}`)
  }

  for (let index = 2; index < data.length * 4 + 1; index += 2) {
    const dataMergeCells = index
    worksheet.mergeCells(`$F$${dataMergeCells}:$F${dataMergeCells + 1}`)
  }

  cellConfig(worksheet)

  const buffer = await workbook.xlsx.writeBuffer()
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  const fileExtension = ".xlsx"

  const blob = new Blob([buffer], { type: fileType })

  saveAs(blob, exportFileName(EXPORT_EP0196) + fileExtension)
}
