import { Endpoint } from "../../../../constant/Endpoint"
import { Environment } from "../../../../constant/Environment"
import { apiService } from "../../../../infrastructure/apiService"

const { FNC1A_Agreements, FNC1A_My_Login, FNC9_Get_User_Authorities } = Endpoint
const { COMMON_ENDPOINT, TRAVELER_ENDPOINT } = Environment

export const privateRouteApi = {
  agreements: () =>
    apiService({
      method: "GET",
      url: `${TRAVELER_ENDPOINT}${FNC1A_Agreements}`,
    }),
  getUserAuthorities: () =>
    apiService({
      data: {},
      method: "GET",
      url: `${COMMON_ENDPOINT}${FNC9_Get_User_Authorities}`,
    }),
  myLogin: () =>
    apiService({
      method: "GET",
      url: `${TRAVELER_ENDPOINT}${FNC1A_My_Login}`,
    }),
}
