/* eslint-disable complexity */
import { getDateMoment } from "../../../../../../../utils/getDate"
// import { getFullName } from "../../../../../../../utils/getFullName"
import {
  SchedulesListPostType,
  SchedulesSearchConditionType,
} from "../../../../models/list"
import {
  SchedulesAdvancedSearchFormDataType,
  SchedulesSearchFormDataType,
} from "../../../../models/list/search"
import { convertAdvancedSearchDataSubmit } from "../../modules/useApi/convertAdvancedSearchDataSubmit"
import { convertSearchDataSubmit } from "../../modules/useApi/convertSearchDataSubmit"

export const setFormDataAdvanced = (
  searchCondition: SchedulesSearchConditionType,
): SchedulesAdvancedSearchFormDataType => ({
  ...searchCondition,
  arrivalDateTimeDate: searchCondition.arrivalDateTimeDate
    ? getDateMoment(searchCondition.arrivalDateTimeDate)
    : null,
  departureDateTimeDate: searchCondition.departureDateTimeDate
    ? getDateMoment(searchCondition.departureDateTimeDate)
    : null,
})

export const setFormDataBasic = (
  searchCondition: SchedulesSearchConditionType,
): SchedulesSearchFormDataType => {
  const {
    arrivalCountryCode,
    arrivalDateTimeDate,
    countryToTravel,
    departureDateTimeDate,
    fullName,
    kanjiName,
    kanjiSurname,
    projectName,
    projectNameEn,
    projectNameJp,
    romajiName,
    romajiSurname,
  } = searchCondition
  const _arrivalDateTime = arrivalDateTimeDate || ""
  const _departureDateTime = departureDateTimeDate || ""
  const romajiFullName = [romajiSurname, romajiName].join(" ")
  const kanjiFullName = [kanjiSurname, kanjiName].join("　")
  const _fullName = fullName || romajiFullName || kanjiFullName
  const _projectName = projectName || projectNameEn || projectNameJp
  return {
    ...searchCondition,
    arrivalDateTimeDate: getDateMoment(_arrivalDateTime),
    countryToTravel: countryToTravel || arrivalCountryCode,
    departureDateTimeDate: getDateMoment(_departureDateTime),
    fullName: _fullName,
    projectName: _projectName,
  }
}

export const getDataSubmitAdvanced = (
  searchCondition: SchedulesSearchConditionType,
): SchedulesListPostType =>
  convertAdvancedSearchDataSubmit({
    values: setFormDataAdvanced(searchCondition),
  })

export const getDataSubmitBasic = (
  searchCondition: SchedulesSearchConditionType,
  isPathnameSchedules: boolean,
): SchedulesListPostType =>
  convertSearchDataSubmit({
    isPathnameSchedules,
    searchCondition: searchCondition,
    values: setFormDataBasic(searchCondition),
  })
