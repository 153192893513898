import { useState } from "react"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { useHistory } from "react-router-dom"
import { ListItemType } from "../../../../../../../_webui/layout/components/List/models"
import { getUserInformation } from "../../../../../../../utils/getUserInformation"

export const useVariables = () => {
  const { push } = useHistory()
  const { isCommonRefer, isHidden } = useVariablesAuthority()
  const [valueAccept, setValueAccept] = useState("")
  const [acquisitionInfo, setAcquisitionInfo] = useState<ListItemType[]>([])
  const [isAccept, setIsAccept] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const userInformation = getUserInformation()

  return {
    acquisitionInfo,
    isAccept,
    isCommonRefer,
    isHidden,
    isLoading,
    isLoadingLayout,
    othersStaffNumber: userInformation?.othersStaffNumber,
    push,
    setAcquisitionInfo,
    setIsAccept,
    setIsLoading,
    setIsLoadingLayout,
    setValueAccept,
    valueAccept,
  }
}
