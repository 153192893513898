import { Key } from "react"
import { Endpoint } from "../../../../constant/Endpoint"
import { Environment } from "../../../../constant/Environment"
import { apiService } from "../../../../infrastructure/apiService"
import { replaceStringParam } from "../../../../utils/replaceStringParam"
import { GetFilesParamType } from "../models/files/list"
import { UploadFileApiType } from "../models/files/upload"
import { RolesSettingFormDataSubmitType } from "../models/roles/setting"
import {
  SubAdminFormSearchConditionType,
  SubAdminFormSearchDataType,
} from "../models/subAdmin/list"

const { COMMON_ENDPOINT } = Environment
const {
  FNC9_Add_Files,
  FNC9_Delete_Files,
  FNC9_Get_Authority_Pattern_Setting,
  FNC9_Get_Authority_Pattern,
  FNC9_Get_Files,
  FNC9_Get_Timezones,
  FNC9_Get_Url,
  FNC9_Get_User_Authority_Pattern_Setting,
  FNC9_Get_User_Authority_Type_Setting,
  FNC9_Update_Authority_Pattern_Setting,
} = Endpoint

export const commonApi = {
  addFiles: (data: UploadFileApiType) =>
    apiService({
      data: data,
      method: "POST",
      url: `${COMMON_ENDPOINT}${FNC9_Add_Files}`,
    }),
  deleteFiles: (uploadIds: Key[]) =>
    apiService({
      data: { uploadIds: uploadIds },
      method: "DELETE",
      url: `${COMMON_ENDPOINT}${FNC9_Delete_Files}`,
    }),
  getAuthorityPattern: (patternId: string) =>
    apiService({
      method: "GET",
      url: `${COMMON_ENDPOINT}${replaceStringParam(
        FNC9_Get_Authority_Pattern,
        patternId,
      )}`,
    }),
  getAuthorityPatternSetting: (patternId: string) =>
    apiService({
      method: "GET",
      url: `${COMMON_ENDPOINT}${replaceStringParam(
        FNC9_Get_Authority_Pattern_Setting,
        patternId,
      )}`,
    }),
  getFiles: (params: GetFilesParamType) =>
    apiService({
      method: "GET",
      params,
      url: `${COMMON_ENDPOINT}${FNC9_Get_Files}`,
    }),
  getTimezones: () =>
    apiService({
      method: "GET",
      url: `${COMMON_ENDPOINT}${FNC9_Get_Timezones}`,
    }),
  getUrl: (fileId: string) =>
    apiService({
      method: "GET",
      url: `${COMMON_ENDPOINT}${FNC9_Get_Url}?fileId=${fileId}`,
    }),
  getUserAuthorityPatternSetting: (data: SubAdminFormSearchDataType) =>
    apiService({
      data,
      method: "POST",
      url: `${COMMON_ENDPOINT}${FNC9_Get_User_Authority_Pattern_Setting}`,
    }),
  getUserAuthorityTypeSetting: (data: SubAdminFormSearchConditionType) =>
    apiService({
      data,
      method: "POST",
      url: `${COMMON_ENDPOINT}${FNC9_Get_User_Authority_Type_Setting}`,
    }),
  updateAuthorityPatternSetting: (data: RolesSettingFormDataSubmitType) =>
    apiService({
      data,
      method: "PUT",
      url: `${COMMON_ENDPOINT}${replaceStringParam(
        FNC9_Update_Authority_Pattern_Setting,
        data.patternId,
      )}`,
    }),
}
