import exportFile from "../../../assets/lang/exportFile/en.json"
import help from "../../../assets/lang/help/en.json"
import messageConfirm from "../../../assets/lang/message/confirm/en.json"
import messageError from "../../../assets/lang/message/error/en.json"
import messageInfo from "../../../assets/lang/message/info/en.json"
import messageWarning from "../../../assets/lang/message/warning/en.json"
import text from "../../../assets/lang/text/en.json"

export const en = {
  ...exportFile,
  ...help,
  ...messageConfirm,
  ...messageError,
  ...messageInfo,
  ...messageWarning,
  ...text,
}
