import { ColumnType } from "../../../../../../../_webui/layout/components/Table/models"
import { Translate } from "../../../../../../../constant/Translate"
import { BreakText } from "../../../../../../../_webui/layout/components/Typography/BreakText"
import { concat } from "../../../../../../../utils/concat"
import { AttentionTravelersDataType } from "../../../../models/attention"
import { getFullName } from "../../../../../../../utils/getFullName"

const { TEXT_T003, TEXT_T006, TEXT_T025, TEXT_T221, TEXT_T320 } = Translate

export const useTableConfig = () => {
  const columns: ColumnType<AttentionTravelersDataType>[] = [
    {
      dataIndex: "fullName",
      render: (_, data) => (
        <BreakText
          above={getFullName(data.kanjiSurname, data.kanjiName, true)}
          below={getFullName(data.romajiSurname, data.romajiName)}
        />
      ),
      sorter: (a, b) =>
        concat(a.romajiSurname, a.romajiName).localeCompare(
          concat(b.romajiSurname, b.romajiName),
        ),
      title: <BreakText above={TEXT_T003} below={TEXT_T006} />,
      width: 250,
    },
    {
      dataIndex: "dispatchTypeDetailName",
      sorter: (a, b) =>
        a.dispatchTypeDetailName.localeCompare(b.dispatchTypeDetailName),
      title: TEXT_T025,
      width: 260,
    },
    {
      dataIndex: "workPlace",
      sorter: (a, b) => a.workPlace.localeCompare(b.workPlace),
      title: TEXT_T221,
      width: 270,
    },
    {
      dataIndex: "destinationAddress",
      render: (_, data) => (
        <BreakText above={data.emailAddress1} below={data.emailAddress2} />
      ),
      sorter: (a, b) =>
        concat(a.emailAddress1, a.emailAddress2).localeCompare(
          concat(b.emailAddress1, b.emailAddress2),
        ),
      title: TEXT_T320,
      width: 220,
    },
  ]

  return { columns }
}
