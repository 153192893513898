import { translateMessage } from "../../../../utils/translateMessage"
import { I18nCode } from "../../../I18nCode"

const {
  CODE_MESSAGE_I0001,
  CODE_MESSAGE_I0002,
  CODE_MESSAGE_I0003,
  CODE_MESSAGE_I0004,
  CODE_MESSAGE_I0005,
  CODE_MESSAGE_I0006,
  CODE_MESSAGE_I0007,
  CODE_MESSAGE_I0008,
  CODE_MESSAGE_I0009,
  CODE_MESSAGE_I0010,
  CODE_MESSAGE_I0011,
  CODE_MESSAGE_I0012,
  CODE_MESSAGE_I0013,
  CODE_MESSAGE_I0014,
  CODE_MESSAGE_I0015,
  CODE_MESSAGE_I0016,
  CODE_MESSAGE_I0017,
  CODE_MESSAGE_I0018,
  CODE_MESSAGE_I0019,
  CODE_MESSAGE_I0020,
  CODE_MESSAGE_I0021,
  CODE_MESSAGE_I0022,
  CODE_MESSAGE_I0023,
  CODE_MESSAGE_I0024,
  CODE_MESSAGE_I0025,
  CODE_MESSAGE_I0026,
} = I18nCode

const MESSAGE_I0001 = translateMessage(CODE_MESSAGE_I0001)
const MESSAGE_I0002 = translateMessage(CODE_MESSAGE_I0002)
const MESSAGE_I0003 = translateMessage(CODE_MESSAGE_I0003)
const MESSAGE_I0004 = translateMessage(CODE_MESSAGE_I0004)
const MESSAGE_I0005 = translateMessage(CODE_MESSAGE_I0005)
const MESSAGE_I0006 = translateMessage(CODE_MESSAGE_I0006)
const MESSAGE_I0007 = translateMessage(CODE_MESSAGE_I0007)
const MESSAGE_I0008 = translateMessage(CODE_MESSAGE_I0008)
const MESSAGE_I0009 = translateMessage(CODE_MESSAGE_I0009)
const MESSAGE_I0010 = translateMessage(CODE_MESSAGE_I0010)
const MESSAGE_I0011 = translateMessage(CODE_MESSAGE_I0011)
const MESSAGE_I0012 = translateMessage(CODE_MESSAGE_I0012)
const MESSAGE_I0013 = translateMessage(CODE_MESSAGE_I0013)
const MESSAGE_I0014 = translateMessage(CODE_MESSAGE_I0014)
const MESSAGE_I0015 = translateMessage(CODE_MESSAGE_I0015)
const MESSAGE_I0016 = translateMessage(CODE_MESSAGE_I0016)
const MESSAGE_I0017 = translateMessage(CODE_MESSAGE_I0017)
const MESSAGE_I0018 = translateMessage(CODE_MESSAGE_I0018)
const MESSAGE_I0019 = translateMessage(CODE_MESSAGE_I0019)
const MESSAGE_I0020 = translateMessage(CODE_MESSAGE_I0020)
const MESSAGE_I0021 = translateMessage(CODE_MESSAGE_I0021)
const MESSAGE_I0022 = translateMessage(CODE_MESSAGE_I0022)
const MESSAGE_I0023 = translateMessage(CODE_MESSAGE_I0023)
const MESSAGE_I0024 = translateMessage(CODE_MESSAGE_I0024)
const MESSAGE_I0025 = translateMessage(CODE_MESSAGE_I0025)
const MESSAGE_I0026 = translateMessage(CODE_MESSAGE_I0026)

export const TranslateMessageInfo = {
  MESSAGE_I0001,
  MESSAGE_I0002,
  MESSAGE_I0003,
  MESSAGE_I0004,
  MESSAGE_I0005,
  MESSAGE_I0006,
  MESSAGE_I0007,
  MESSAGE_I0008,
  MESSAGE_I0009,
  MESSAGE_I0010,
  MESSAGE_I0011,
  MESSAGE_I0012,
  MESSAGE_I0013,
  MESSAGE_I0014,
  MESSAGE_I0015,
  MESSAGE_I0016,
  MESSAGE_I0017,
  MESSAGE_I0018,
  MESSAGE_I0019,
  MESSAGE_I0020,
  MESSAGE_I0021,
  MESSAGE_I0022,
  MESSAGE_I0023,
  MESSAGE_I0024,
  MESSAGE_I0025,
  MESSAGE_I0026,
}
