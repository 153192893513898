/* eslint-disable max-statements */
import { Form } from "antd"
import { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { OptionItemType } from "../../../../../../../../_webui/layout/components/Select/models"
import { getUserInformation } from "../../../../../../../../utils/getUserInformation"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { Variables } from "../../../../../../../../constant/Variables"
import { UseFilesUploadState } from "../../../../../models/files/upload"
import { Routers } from "../../../../../../../../constant/Routers"

const { SCR970 } = Routers
const { SECURITY_MANAGEMENT_DEPARTMENT } = Variables

export const useVariablesForm = () => {
  const [form] = Form.useForm()
  const { push } = useHistory()
  const { state } = useLocation<UseFilesUploadState>()
  const userInformation = getUserInformation()
  const { isCommonUploadRegister, isHiddenBtn, isRoleAdmin, isRoleSubAdmin } =
    useVariablesAuthority()
  const [allCountries, setAllCountries] = useState<OptionItemType[]>([])
  const [countryCode, setCountryCode] = useState("")
  const [countryName, setCountryName] = useState("")
  const [fileCategory, setFileCategory] = useState("")
  const [fileType, setFileType] = useState(state?.fileType)
  const [fileTypeName, setFileTypeName] = useState("")
  const [isDisabledCountries, setIsDisabledCountries] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const bumonCd = userInformation?.bumonCd
  const typeInfoSubAdmin =
    isRoleSubAdmin && bumonCd === SECURITY_MANAGEMENT_DEPARTMENT
  const isCountry = (isRoleAdmin || typeInfoSubAdmin) && !state?.isCountry

  return {
    allCountries,
    countryCode,
    countryName,
    fileCategory,
    fileType,
    fileTypeName,
    form,
    isCommonUploadRegister,
    isCountry,
    isDisabledCountries,
    isHiddenBtn,
    isLoading,
    isRoleAdmin,
    push,
    setAllCountries,
    setCountryCode,
    setCountryName,
    setFileCategory,
    setFileType,
    setFileTypeName,
    setIsDisabledCountries,
    setIsLoading,
    sourceURL: state?.sourceURL || SCR970,
    stateFileType: state?.fileType,
    typeInfoSubAdmin,
  }
}
