import styled, { css } from "styled-components"
import { StyledPreLineProps } from "../models"

const StyledPreLine = styled.span<StyledPreLineProps>`
  white-space: pre-line;
  ${(props) =>
    props.textAlign &&
    css<StyledPreLineProps>`
      text-align: ${props.textAlign};
      display: block;
    `};
`

export const Styled = {
  StyledPreLine,
}
