import { Routers } from "../../../../../../../../constant/Routers"
import { Variables } from "../../../../../constants/list/variables"
import {
  SafetiesListSearchCondition,
  SafetiesStateProps,
} from "../../../../../models/list"

const { SCR416_HISTORY } = Routers
const { DEFAULT_SEARCH } = Variables

export const getSearchParam = (
  pathname: string,
  state: SafetiesStateProps,
  isRoleSubAdmin: boolean,
): SafetiesListSearchCondition => {
  if (state?.searchCondition) {
    const searchCondition = state.searchCondition
    const { categoryId, implementationDate, safetyTitle, statusId } =
      searchCondition
    return {
      categoryId,
      implementationDate,
      isMenuSubAdmin: isRoleSubAdmin,
      safetyTitle,
      statusId,
    }
  }

  if (pathname === SCR416_HISTORY) return { ...DEFAULT_SEARCH, statusId: "" }
  return DEFAULT_SEARCH
}
