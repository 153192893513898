import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { Routers } from "../../../../../../../constant/Routers"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { useState } from "react"
import { AttentionsInfoAttentionType } from "../../../../models/info"
import { useHistory, useLocation } from "react-router-dom"
import { attentionApi } from "../../../../apis"
import { concat } from "../../../../../../../utils/concat"

const { SCR973 } = Routers

export const useApi = () => {
  const { push } = useHistory()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const [isLoading, setIsLoading] = useState(false)
  const attentionId = concat(query.get("attentionId"))
  const attentionDetailId = concat(query.get("attentionDetailId"))
  const [attentionInfo, setAttentionInfo] =
    useState<AttentionsInfoAttentionType>()
  const [isShowFormSuccess, setIsShowFormSuccess] = useState(false)
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)

  const getAttention = () => {
    // APIを呼び出し、APIからの応答結果を「attentionInfo」変数に設定する。
    attentionApi
      .getAttention({ attentionDetailId, attentionId })
      .then((res: AttentionsInfoAttentionType) => {
        // 「attentionInfo」の値を設定する。
        setAttentionInfo(res)
        setIsLoadingLayout(false)
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const stateError = pushStateError(error)
        push({ pathname: SCR973, state: stateError })
      })
  }

  const getAttentionTravelers = () => {
    setIsLoading(true)
    // APIを呼び出し、APIからの応答結果を「attentionInfoJson」変数に設定する。
    attentionApi
      .confirmAttention({
        attentionId,
        isCheckExpired: true,
        isFromEmail: true,
        listAttentionDetailId: [attentionDetailId],
      })
      .then(() => {
        setIsShowFormSuccess(true)
        setIsLoading(false)
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const stateError = pushStateError(error)
        push({ pathname: SCR973, state: stateError })
      })
  }

  return {
    attentionInfo,
    getAttention,
    getAttentionTravelers,
    isLoading,
    isLoadingLayout,
    isShowFormSuccess,
    push,
  }
}
