import "./style/index.scss"
import { Radio } from "antd"
import { FC } from "react"
import { RadioGroupProps } from "../models"
import { Styled } from "./styled"
import { Translate } from "../../../../../constant/Translate"

const { Group } = Radio
const { StyledFieldset, StyledLegend } = Styled
const { TEXT_T513 } = Translate

export const RadioGroup: FC<RadioGroupProps> = (props) => {
  const {
    defaultValue,
    isHidden,
    label,
    onChange,
    radioItems,
    required,
    value,
  } = props
  if (!radioItems.length) return null

  return isHidden ? null : (
    <StyledFieldset>
      <StyledLegend>
        {label}
        {required && TEXT_T513}
      </StyledLegend>
      <Group defaultValue={defaultValue} onChange={onChange} value={value}>
        {radioItems?.map((item) => (
          <Radio key={item.value} value={item.value}>
            {item.text}
          </Radio>
        ))}
      </Group>
    </StyledFieldset>
  )
}
