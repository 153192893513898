import { Translate } from "../../../../../../../../../../constant/Translate"

const {
  EXPORT_EP0141, // 旅程情報識別番号    Itinerary information identification number
  EXPORT_EP0017, // ログインID    Login ID
  EXPORT_EP0054, // 氏名（日）    Full name(Japanese)
  EXPORT_EP0052, // 氏名（英）    Full name(English)
  EXPORT_EP0055, // 氏名（日）(戸籍表記)    Full name(Japanese)(described in passport)
  EXPORT_EP0053, // 氏名（英）（パスポート表記）    Full name(English)(As shown on passport)
  EXPORT_EP0122, // 派遣種別    Dispatch type
  EXPORT_EP0123, // 派遣種別（詳細）    Dispatch type(Detail)
  EXPORT_EP0079, // 所属先名    Organization name
  EXPORT_EP0117, // 任地・勤務地（国）    Place・Work location(Country)
  EXPORT_EP0118, // 任地・勤務地（都市）    Place・Work location(City)
  EXPORT_EP0181, // 職員番号    Staff member number
  EXPORT_EP0125, // 派遣番号    Dispatch number
  EXPORT_EP0097, // 隊員番号    Group member number
  EXPORT_EP0173, // 渡航目的    Travel of purpose
  EXPORT_EP0069, // 出張件名・調査団名・プロジェクト名（日）    Business trip name - Survey team name - Project name (Japanese)
  EXPORT_EP0068, // 出張件名・調査団名・プロジェクト名（英）    Business trip name - Survey team name - Project name (English)
  EXPORT_EP0100, // 調達管理番号    Procurement management number
  EXPORT_EP0067, // 出張・調査団・赴任を主管するJICA部署    JICA department in charge of supervising business trip - survey team - assignment
  EXPORT_EP0058, // 主管部署の担当者名    Name of person in charge of supervising department
  EXPORT_EP0174, // 拠点担当者名    Name of person in charge of base
  EXPORT_EP0120, // 派遣開始日    Dispatch start date
  EXPORT_EP0124, // 派遣終了日    Dispatch end date
  EXPORT_EP0071, // 出発国    Country of departure
  EXPORT_EP0072, // 出発国コード    Country of departure of code
  EXPORT_EP0074, // 出発都市名    City of departure
  EXPORT_EP0073, // 出発都市コード    Code city of departure
  EXPORT_EP0045, // 交通手段    Means of transportation
  EXPORT_EP0201, // トランジット    Transit
  EXPORT_EP0182, // 出発便名    Departure flight number
  EXPORT_EP0076, // 出発日時    Departure date and time
  EXPORT_EP0109, // 到着国    Country of destination
  EXPORT_EP0110, // 到着国コード    Code country of destination
  EXPORT_EP0112, // 到着都市名    City of destination
  EXPORT_EP0111, // 到着都市コード    Code city of destination
  EXPORT_EP0114, // 到着日時    Arrival date and time
  EXPORT_EP0128, // 備考1    Remarks 1
  EXPORT_EP0129, // 備考2    Remarks 2
  EXPORT_EP0130, // 備考3    Remarks 3
  EXPORT_EP0131, // 備考4    Remarks 4
  EXPORT_EP0132, // 備考5    Remarks 5
  EXPORT_EP0105, // 渡航者情報の保険情報と異なる場合    When it is different from the insurance information of the traveler
  EXPORT_EP0135, // 保険会社名    Insurance company name
  EXPORT_EP0134, // ①事故発生時の保険会社連絡先    [1]Insurance company contact information in case of an accident
  EXPORT_EP0137, // ②保険証券番号    [2]Insurance policy number
  EXPORT_EP0036, // ③保険外（自費/自社費）で緊急移送の判断を要する場合の判断委託者    [3]Assignor of decision when emergency transfer decision is required outside of insurance (self-paid or in-house expense)
  EXPORT_EP0065, // 宿泊先名称    Accommodation name
  EXPORT_EP0063, // 宿泊先住所、部屋番号等    Accommodation address、room number、etc
  EXPORT_EP0064, // 宿泊先電話番号（国番号＋15桁）    Accommodation phone number (Country code ＋15 digits)
  EXPORT_EP0011, // チェックイン    Check in
  EXPORT_EP0010, // チェックアウト    Check out
  EXPORT_EP0093, // 滞在地携帯番号1（国番号＋15桁）    Mobile number 1 in place of residence (Country code ＋ 15 digits)
  EXPORT_EP0094, // 滞在地携帯番号2（国番号＋15桁）    Mobile number 2 in place of residence (Country code ＋ 15 digits)
  EXPORT_EP0022, // 衛星携帯番号    Satellite mobile number
  EXPORT_EP0101, // 電子メールアドレス1    Email address 1
  EXPORT_EP0102, // 電子メールアドレス2    Email address 2
  EXPORT_EP0046, // 行動規範の確認    Code of conduct recognition
  EXPORT_EP0050, // 最終更新日時    Last update date and time
  EXPORT_EP0144, // 旅程情報識別番号（新派遣システム向け）    Itinerary information identification number (for new dispatch system)
  EXPORT_EP0142, // 旅程情報識別番号（在外職員システム向け）    Itinerary information identification number (for overseas staff system)
  EXPORT_EP0143, // 旅程情報識別番号（次期ボランティアシステム向け）    Itinerary information identification number (for the next volunteer system)
  EXPORT_EP0051, // 作成日付    Create date
  EXPORT_EP0189, // 更新日付    Update date
} = Translate

export const heading = `${EXPORT_EP0141},${EXPORT_EP0017},${EXPORT_EP0054},${EXPORT_EP0052},${EXPORT_EP0055},${EXPORT_EP0053},${EXPORT_EP0122},${EXPORT_EP0123},${EXPORT_EP0079},${EXPORT_EP0117},${EXPORT_EP0118},${EXPORT_EP0181},${EXPORT_EP0125},${EXPORT_EP0097},${EXPORT_EP0173},${EXPORT_EP0069},${EXPORT_EP0068},${EXPORT_EP0100},${EXPORT_EP0067},${EXPORT_EP0058},${EXPORT_EP0174},${EXPORT_EP0120},${EXPORT_EP0124},${EXPORT_EP0071},${EXPORT_EP0072},${EXPORT_EP0074},${EXPORT_EP0073},${EXPORT_EP0045},${EXPORT_EP0201},${EXPORT_EP0182},${EXPORT_EP0076},${EXPORT_EP0109},${EXPORT_EP0110},${EXPORT_EP0112},${EXPORT_EP0111},${EXPORT_EP0114},${EXPORT_EP0128},${EXPORT_EP0129},${EXPORT_EP0130},${EXPORT_EP0131},${EXPORT_EP0132},${EXPORT_EP0105},${EXPORT_EP0135},${EXPORT_EP0134},${EXPORT_EP0137},${EXPORT_EP0036},${EXPORT_EP0065},${EXPORT_EP0063},${EXPORT_EP0064},${EXPORT_EP0011},${EXPORT_EP0010},${EXPORT_EP0093},${EXPORT_EP0094},${EXPORT_EP0022},${EXPORT_EP0101},${EXPORT_EP0102},${EXPORT_EP0046},${EXPORT_EP0050},${EXPORT_EP0144},${EXPORT_EP0142},${EXPORT_EP0143},${EXPORT_EP0051},${EXPORT_EP0189}`
