/* eslint-disable max-lines */
/* eslint-disable max-statements */
import Excel from "exceljs"
import { saveAs } from "file-saver"
import { SchedulesListDataType } from "../../../../../models/list"
import { exportFileName } from "../../../../../../../../utils/exportFileName"
import { convertDataExport } from "./convertDataExport"
import { cellConfig } from "./cellConfig"
import { columnsConfig } from "./columnsConfig"
import { getValueByLanguage } from "../../../../../../../../utils/getValueByLanguage"
import { getFullName } from "../../../../../../../../utils/getFullName"
import { Translate } from "../../../../../../../../constant/Translate"
import { SchedulesListDataDetailsType } from "../../../../../models/list/data"
import { getInsuranceCompanyName } from "../../../../../../../../utils/getInsuranceCompanyName"

const { EXPORT_EP0193, EXPORT_EP0207, EXPORT_EP0208 } = Translate

export const exportFRM101 = async (data: SchedulesListDataType[]) => {
  const workbook = new Excel.Workbook()

  const _data: SchedulesListDataType[] = []
  data.forEach((item) => {
    const dataCurrent = _data.find(
      (itemFilter) => itemFilter.csv.userId === item.csv.userId,
    )
    const dataNew: SchedulesListDataDetailsType[] = item.details.map(
      (itemDetail) => ({
        ...itemDetail,
        differentInforCheck: item.info.differentInforCheck,
        insuranceCompanyName: getInsuranceCompanyName(
          item.info.insuranceCompanyName,
        ),
        insuranceCompanyPhoneNumber: item.info.insuranceCompanyPhoneNumber,
        insuranceSonsignor: item.info.insuranceSonsignor,
        insuranceTicketNumber: item.info.insuranceTicketNumber,
        projectNameEn: item.info.projectNameEn,
        projectNameJp: item.info.projectNameJp,
        travelPurposeName: item.info.travelPurposeName,
        urgentAffiliationContact: item.info.urgentAffiliationContact,
        urgentAffiliationContactName: item.info.urgentAffiliationContactName,
        urgentContactHome: item.info.urgentContactHome,
        urgentContactName: item.info.urgentContactName,
      }),
    )
    if (dataCurrent) {
      const currentIndex = _data.findIndex(
        (itemFilter) => itemFilter.csv.userId === item.csv.userId,
      )
      _data[currentIndex].details = [...dataCurrent.details, ...dataNew]
    } else {
      _data.push({
        ...item,
        details: dataNew,
      })
    }
  })

  _data.map((itemData) => {
    const sheetName = itemData.csv.userId.split("@")
    const worksheet = workbook.addWorksheet(sheetName[0])
    const convertData = convertDataExport(itemData.details)

    const fullNameRomaji = getFullName(
      itemData.info.romajiSurname,
      itemData.info.romajiName,
    )
    const _fullNameKanji = getFullName(
      itemData.info.kanjiSurname,
      itemData.info.kanjiName,
    )
    const fullNameKanji = _fullNameKanji
      ? `${_fullNameKanji}（${fullNameRomaji}）`
      : fullNameRomaji
    worksheet.mergeCells("A1:K1")
    worksheet.getCell("A1").value = getValueByLanguage(
      fullNameKanji,
      fullNameRomaji,
    )
    columnsConfig(worksheet)
    for (let index = 0; index < convertData.length; index++) {
      const item = convertData[index]
      worksheet.getCell(`$A${index + 3}`).value = item.projectName
      worksheet.getCell(`$B${index + 3}`).value = item.departureDate
      worksheet.getCell(`$C${index + 3}`).value = item.departureDay
      worksheet.getCell(`$D${index + 3}`).value =
        item.departureDateTimeAndArrivalDateTime
      worksheet.getCell(`$E${index + 3}`).value = item.travelPurposeName
      worksheet.getCell(`$F${index + 3}`).value =
        item.departureFlightNumberAndTransportationName
      worksheet.getCell(`$G${index + 3}`).value = item.departureCountryName
      worksheet.getCell(`$H${index + 3}`).value =
        item.departureCountryNameOrDepartureCityName
      worksheet.getCell(`$I${index + 3}`).value = item.arrivalCountryName
      worksheet.getCell(`$J${index + 3}`).value =
        item.arrivalCityValueOrArrivalCityName
      worksheet.getCell(`$K${index + 3}`).value = item.lodgingName
      worksheet.getCell(`L${index + 3}`).value = item.urgentContactHome
      worksheet.getCell(`M${index + 3}`).value = item.urgentContactName
      worksheet.getCell(`N${index + 3}`).value = item.urgentAffiliationContact
      worksheet.getCell(`O${index + 3}`).value =
        item.urgentAffiliationContactName
      worksheet.getCell(`P${index + 3}`).value = item.differentInforCheck
        ? EXPORT_EP0207
        : EXPORT_EP0208
      worksheet.getCell(`Q${index + 3}`).value = item.insuranceCompanyName
      worksheet.getCell(`R${index + 3}`).value =
        item.insuranceCompanyPhoneNumber
      worksheet.getCell(`S${index + 3}`).value = item.insuranceTicketNumber
      worksheet.getCell(`T${index + 3}`).value = item.insuranceSonsignor
    }
    const mergeIndexList: number[] = [0]
    convertData.forEach((item, index) => {
      if (item.travelScheduleId !== convertData[index + 1]?.travelScheduleId) {
        mergeIndexList.push(0)
      } else {
        mergeIndexList[mergeIndexList.length - 1] += 1
      }
    })
    let dataIndex = 0
    mergeIndexList.forEach((item, index) => {
      if (item) {
        const dataMergeCells = index + 3 + dataIndex
        worksheet.mergeCells(`A${dataMergeCells}:A${dataMergeCells + item}`)
        worksheet.mergeCells(`L${dataMergeCells}:L${dataMergeCells + item}`)
        worksheet.mergeCells(`M${dataMergeCells}:M${dataMergeCells + item}`)
        worksheet.mergeCells(`N${dataMergeCells}:N${dataMergeCells + item}`)
        worksheet.mergeCells(`O${dataMergeCells}:O${dataMergeCells + item}`)
        worksheet.mergeCells(`P${dataMergeCells}:P${dataMergeCells + item}`)
        worksheet.mergeCells(`Q${dataMergeCells}:Q${dataMergeCells + item}`)
        worksheet.mergeCells(`R${dataMergeCells}:R${dataMergeCells + item}`)
        worksheet.mergeCells(`S${dataMergeCells}:S${dataMergeCells + item}`)
        worksheet.mergeCells(`T${dataMergeCells}:T${dataMergeCells + item}`)
        dataIndex += item
      }
    })
    cellConfig(worksheet)
  })

  const buffer = await workbook.xlsx.writeBuffer()
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  const fileExtension = ".xlsx"

  const blob = new Blob([buffer], { type: fileType })

  saveAs(blob, exportFileName(EXPORT_EP0193) + fileExtension)
}
