import { FC } from "react"
import { Regex } from "../../../../../../constant/Regex"
import { Translate } from "../../../../../../constant/Translate"
import { RolesCheckboxGroup } from "./CheckboxGroup"
import { Styled } from "./styled"
import { RolesTableHeader } from "./TableHeader"
import { RolesTableProps } from "./models"

const { TEXT_T467, TEXT_T468, TEXT_T469, TEXT_T470 } = Translate
const { StyledItem, StyledItemCheckbox, StyledRolesSetting, StyledRow } = Styled
const { REGEX_IS_DESKTOP } = Regex

export const RolesTable: FC<RolesTableProps> = (props) => {
  const {
    attentionOptions,
    isSuccess,
    safetyOptions,
    scheduleOptions,
    travelerOptions,
  } = props

  return (
    <StyledRolesSetting className="role-setting">
      <RolesTableHeader />
      <RolesCheckboxGroup
        isSuccess={isSuccess}
        name="authorityTravelerInfo"
        options={travelerOptions}
        title={TEXT_T467}
      />
      <RolesCheckboxGroup
        isSuccess={isSuccess}
        name="authorityTravelScheduleInfo"
        options={scheduleOptions}
        title={TEXT_T468}
      />
      <RolesCheckboxGroup
        isSuccess={isSuccess}
        name="authorityAttention"
        options={attentionOptions}
        title={TEXT_T469}
      />
      <RolesCheckboxGroup
        isSuccess={isSuccess}
        name="authoritySafetyConfirm"
        options={safetyOptions}
        title={TEXT_T470}
      />

      {!REGEX_IS_DESKTOP && (
        <StyledRow>
          <StyledItem></StyledItem>
          <div className="ant-form-item">
            <StyledItemCheckbox></StyledItemCheckbox>
            <StyledItemCheckbox></StyledItemCheckbox>
            <StyledItemCheckbox></StyledItemCheckbox>
            <StyledItemCheckbox></StyledItemCheckbox>
            <StyledItemCheckbox></StyledItemCheckbox>
            <StyledItemCheckbox></StyledItemCheckbox>
            <StyledItemCheckbox></StyledItemCheckbox>
            <StyledItemCheckbox></StyledItemCheckbox>
            <StyledItemCheckbox></StyledItemCheckbox>
          </div>
        </StyledRow>
      )}
    </StyledRolesSetting>
  )
}
