/* eslint-disable complexity */
import { Breadcrumbs } from "../../../../../../../../constant/Breadcrumbs"

const {
  BRCR_SCR419_FROM_SCR416_HISTORY,
  BRCR_SCR419_FROM_SCR416,
  BRCR_SCR419_FROM_SCR417_HISTORY,
  BRCR_SCR419_FROM_SCR417_SUBADMIN,
  BRCR_SCR419_FROM_SCR417,
  BRCR_SCR419,
} = Breadcrumbs

export const getBreadcrumbItems = (
  isFromSCR416?: boolean,
  isFromSCR416History?: boolean,
  isFromSCR417?: boolean,
  isRoleSubAdmin?: boolean,
) => {
  if (isFromSCR417) {
    if (isFromSCR416)
      return isRoleSubAdmin
        ? BRCR_SCR419_FROM_SCR417_SUBADMIN
        : BRCR_SCR419_FROM_SCR417
    if (isFromSCR416History) return BRCR_SCR419_FROM_SCR417_HISTORY
  }

  if (isFromSCR416) return BRCR_SCR419_FROM_SCR416

  if (isFromSCR416History) return BRCR_SCR419_FROM_SCR416_HISTORY

  return BRCR_SCR419
}
