import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const { isCommonRefer, isRoleAdmin, isRoleSubAdmin, isRoleUser } =
    useAuthority()

  const isHiddenBtn =
    (isRoleAdmin || isRoleSubAdmin || isRoleUser) && isCommonRefer

  return {
    isCommonRefer,
    isHiddenBtn,
    isRoleAdmin,
    isRoleSubAdmin,
  }
}
