import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import {
  SchedulesDeleteSuccessStateType,
  UseSchedulesDeleteSuccessType,
} from "../../../../models/deleteSuccess"
import { getBreadcrumbItems } from "./getBreadcrumbItems"
import { useVariablesAuthority } from "../useVariablesAuthority"

export const useSchedulesDeleteSuccess = (): UseSchedulesDeleteSuccessType => {
  const { state } = useLocation<SchedulesDeleteSuccessStateType>()
  const { push } = useHistory()
  const { isNotAccessScreen, isVisibleBtnSearch } = useVariablesAuthority()
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)

  const breadcrumbItems = getBreadcrumbItems(
    state?.isFromSCR211,
    state?.isFromSCR216,
    state?.schedulesURL,
  )

  const goToSchedules = () => {
    // 「SCR210_渡航予定情報の一覧画面」に遷移する。
    push(state.schedulesURL, { ...state })
  }

  useEffect(() => {
    // ユーザーの画面アクセス許可チェックを処理する。
    if (isNotAccessScreen) {
      // 「SCR973_エラー情報の表示画面」に遷移する。
      const { pathname, state } = backToLogin()
      push({ pathname, state })
    } else
      setTimeout(() => {
        setIsLoadingLayout(false)
      }, 500)
  }, [])

  return {
    breadcrumbItems,
    goToSchedules,
    isLoadingLayout,
    isVisibleBtnSearch,
  }
}
