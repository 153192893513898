import { Key, useState } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { Routers } from "../../../../../../../constant/Routers"
import { concat } from "../../../../../../../utils/concat"
import { replaceString } from "../../../../../../../utils/replaceString"
import { UseParamsType } from "../../../../../../../utils/useParams"
import {
  SafetiesDetailInfoType,
  SafetiesDetailStateType,
} from "../../../../models/detail"

const { SCR415, SCR416, SCR416_HISTORY, SCR419, SCR420 } = Routers
const sourceList = [SCR416, SCR416_HISTORY, SCR419, SCR420]

export const useRouters = (selectedRowKeys: Key[]) => {
  const { id: safetyId } = useParams<UseParamsType>()
  const { push } = useHistory()
  const { pathname, state } = useLocation<SafetiesDetailStateType>()
  const [safetyInfo, setSafetyInfo] = useState<SafetiesDetailInfoType>()

  const goToSafetiesAnswer = () => {
    push({
      pathname: SCR419,
      state: {
        isFromSCR416: state?.isFromSCR416,
        isFromSCR416History: state?.isFromSCR416History,
        isFromSCR417: true,
        isHiddenSubmit: state?.isHiddenSubmit,
        safetyDetailId: selectedRowKeys?.[0],
        safetyId: safetyId,
        searchCondition: state?.searchCondition,
        sourceURL: pathname,
      },
    })
  }

  const onGoBack = () => {
    if (state) {
      const { sourceURL, isFromSCR416 } = state

      if (sourceList.includes(sourceURL)) {
        push({
          pathname: isFromSCR416 ? SCR416 : SCR416_HISTORY,
          state: {
            ...state,
            categoryId: concat(safetyInfo?.categoryId),
            implementationDate: concat(safetyInfo?.implementationDate),
            safetyTitle: concat(safetyInfo?.safetyTitle),
            sourceURL: pathname,
            statusId: concat(safetyInfo?.statusId),
          },
        })
      }

      if (sourceURL === SCR415) {
        push({
          pathname: replaceString(SCR415, safetyId),
          state: {
            ...state,
            sourceURL: pathname,
          },
        })
      }
    }
  }
  return {
    goToSafetiesAnswer,
    onGoBack,
    safetyId,
    safetyInfo,
    setSafetyInfo,
    state,
  }
}
