/* eslint-disable max-lines */
import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../_webui/layout/components/Table/models"
import { AttentionsListType } from "../../../../models/list"
import { Translate } from "../../../../../../../constant/Translate"
import { Tag } from "../../../../../../../_webui/layout/components/Tag"
import { concat } from "../../../../../../../utils/concat"
import { getDateTimeZoneByUserInformation } from "../../../../../../../utils/getDate"
import { getSafetyStatus } from "../../../../../../../utils/getSafetyStatus"
import { GeneralCode } from "../../../../../../../constant/GeneralCode"
import { getValueByLanguage } from "../../../../../../../utils/getValueByLanguage"
import { BreakText } from "../../../../../../../_webui/layout/components/Typography/BreakText"
import { getFullName } from "../../../../../../../utils/getFullName"

const { CTGR_OTHER, IPTC001, IPTC002 } = GeneralCode
const {
  TEXT_T009,
  TEXT_T102,
  TEXT_T179,
  TEXT_T312,
  TEXT_T314,
  TEXT_T315,
  TEXT_T333,
} = Translate

export const useTableConfig = (): UseTableConfigType<AttentionsListType> => {
  const columns: ColumnType<AttentionsListType>[] = [
    {
      dataIndex: "severity",
      render: (_, data) => {
        const type =
          data.severity === IPTC001
            ? "error"
            : data.severity === IPTC002
            ? "warning"
            : "stop"
        return <Tag text={data.severityName} type={type} isFullWidth />
      },

      sorter: (a, b) => a.severity.localeCompare(b.severity),
      title: TEXT_T314,
      width: 180,
    },
    {
      dataIndex: "implementationDateTime",
      render: (_, data) =>
        getDateTimeZoneByUserInformation(data?.implementationDateTime),
      sorter: (a, b) =>
        a.implementationDateTime.localeCompare(b.implementationDateTime),
      title: TEXT_T333,
      width: 300,
    },
    {
      dataIndex: "bumonName",
      sorter: (a, b) => a.bumonName.localeCompare(b.bumonName),
      title: TEXT_T009,
      width: 300,
    },
    {
      dataIndex: "attentionTitle",
      render: (_, data) => data.attentionTitle,
      sorter: (a, b) => a.attentionTitle.localeCompare(b.attentionTitle),
      title: TEXT_T315,
      width: 600,
    },
    {
      dataIndex: "statusId",
      render: (_, data) => getSafetyStatus(data.statusId),
      sorter: (a, b) => a.statusId.localeCompare(b.statusId),
      title: TEXT_T179,
      width: 300,
    },
    {
      dataIndex: "category",
      render: (_, data) =>
        data.categoryId === CTGR_OTHER
          ? getValueByLanguage(
              `${data.categoryName}＿${data.categoryValue}`,
              `${data.categoryName}_${data.categoryValue}`,
            ).toString()
          : data.categoryName,
      sorter: (a, b) =>
        concat(a.categoryName, a.categoryValue).localeCompare(
          concat(b.categoryName, b.categoryValue),
        ),
      title: TEXT_T312,
      width: 300,
    },
    {
      dataIndex: "fullName",
      render: (_, data) => (
        <BreakText
          above={getFullName(data.kanjiSurname, data.kanjiName, true)}
          below={getFullName(data.romajiSurname, data.romajiName)}
        />
      ),
      sorter: (a, b) =>
        concat(a.romajiSurname, a.romajiName).localeCompare(
          concat(b.romajiSurname, b.romajiName),
        ),
      title: TEXT_T102,
      width: 200,
    },
  ]

  return { columns }
}
