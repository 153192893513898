import { FC } from "react"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { Breadcrumbs } from "../../../../../constant/Breadcrumbs"
import { Translate } from "../../../../../constant/Translate"
import { TravelersAddForm } from "../../components/add/Form"
import { useTravelersAdd } from "./modules/useAdd"

const { TEXT_T001 } = Translate
const { BRCR_SCR112 } = Breadcrumbs

export const TravelersAdd: FC = () => {
  const {
    departments,
    dispatchType,
    form,
    goToHome,
    isAccompanyingFamily,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isOpen,
    onBlurUserId,
    onCancel,
    onFinish,
    onOk,
    onValuesChange,
  } = useTravelersAdd()

  return (
    <Layout
      breadcrumbItems={BRCR_SCR112}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T001}
    >
      <TravelersAddForm
        departments={departments}
        dispatchType={dispatchType}
        form={form}
        isAccompanyingFamily={isAccompanyingFamily}
        isDisabledSubmit={isDisabledSubmit}
        isLoading={isLoading}
        isOpen={isOpen}
        onBlurUserId={onBlurUserId}
        onCancel={onCancel}
        onClickReset={goToHome}
        onFinish={onFinish}
        onOk={onOk}
        onValuesChange={onValuesChange}
      />
    </Layout>
  )
}
