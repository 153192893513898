import { I18nCodeMessageConfirm } from "./Confirm"
import { I18nCodeMessageError } from "./Error"
import { I18nCodeMessageInfo } from "./Info"
import { I18nCodeMessageWarning } from "./Warning"

export const I18nCodeMessage = {
  ...I18nCodeMessageConfirm,
  ...I18nCodeMessageError,
  ...I18nCodeMessageInfo,
  ...I18nCodeMessageWarning,
}
