import { Pagination } from "antd"
import styled, { css } from "styled-components"
import { Regex } from "../../../../../constant/Regex"
import { Colors } from "../../../../../constant/Styled/Colors"
import { Fonts } from "../../../../../constant/Styled/Fonts"
import { Select } from "../../Select"

const { FORM_ENTRY_BORDER, PRIMARY } = Colors
const { FS_NORMAL } = Fonts
const { REGEX_IS_DESKTOP } = Regex

const StyledPagination = styled.div`
  margin-top: 20px;
  ${REGEX_IS_DESKTOP &&
  css`
    display: flex;
    flex-wrap: wrap;
    position: relative;
  `}
`
const StyledArrowIcon = styled.span`
  border-style: solid;
  border-width: 1px 1px 0 0;
  color: ${PRIMARY};
  content: "";
  cursor: pointer;
  display: inline-block;
  height: 8px;
  position: relative;
  top: -3px;
  transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  width: 8px;
`

const StyledArrowIconLeft = styled(StyledArrowIcon)`
  left: 3px;
  rotate: -135deg;
`
const StyledArrowIconRight = styled(StyledArrowIcon)`
  left: -3px;
  rotate: 45deg;
`

const StyledPaginationPerItem = styled(Select)`
  border: 1px solid ${FORM_ENTRY_BORDER};
  border-radius: 5px;
  display: inline-flex;
  margin-bottom: 20px;
  max-height: 36px;
  min-width: 130px;
  width: auto;
  .ant-select {
    &-selector {
      border-radius: 5px;
      border: none;
      font-size: ${FS_NORMAL};
      width: 100%;
    }
    &-selection {
      &-search-input {
        height: 34px;
      }
      &-item {
        padding-right: 32px;
        width: auto;
      }
    }
  }
  ${REGEX_IS_DESKTOP &&
  css`
    left: 0;
    position: absolute;
    top: 0;
  `}
`

const StyledPaginationAnt = styled(Pagination)`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
`

export const Styled = {
  StyledArrowIconLeft,
  StyledArrowIconRight,
  StyledPagination,
  StyledPaginationAnt,
  StyledPaginationPerItem,
}
