import { Routers } from "../../../../../../../constant/Routers"
import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

const { SCR416_HISTORY } = Routers

export const useVariablesAuthority = (pathname: string) => {
  const {
    isCommonTravelerRefer,
    isRoleSubAdmin,
    isRoleUser,
    isSafetiesBrowseResponse,
  } = useAuthority()

  const isFromHistory = pathname === SCR416_HISTORY
  const isHiddenBtn = isRoleUser || isRoleSubAdmin
  const isHiddenResponseBtn = isHiddenBtn && isCommonTravelerRefer
  const isHiddenResponseHistoryBtn = isFromHistory && isHiddenResponseBtn
  const isHiddenDetailBtn = isRoleSubAdmin && isSafetiesBrowseResponse

  return {
    isCommonTravelerRefer,
    isHiddenDetailBtn,
    isHiddenResponseBtn,
    isHiddenResponseHistoryBtn,
    isRoleSubAdmin,
  }
}
