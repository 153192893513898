import "./style/index.scss"
import { Tabs as TabsAnt } from "antd"
import { FC } from "react"
import { TabsProps } from "./models"
import { getStyle } from "../../../../utils/getStyle"
import { Regex } from "../../../../constant/Regex"

const { REGEX_IS_DESKTOP } = Regex

export const Tabs: FC<TabsProps> = (props) => {
  const {
    activeKey,
    className,
    defaultActiveKey,
    isChildren = true,
    isCustom,
    isMargin = true,
    items,
    onChange,
    onEdit,
    onTabClick,
  } = props
  const styleClassName = getStyle(className, ` ${className}`)
  const styleIsMargin = getStyle(isMargin, "", " ant-tabs-margin")
  const styleIsChildren = getStyle(isChildren, "", " ant-tabs-children-none")
  const styleIsDesktop = getStyle(
    REGEX_IS_DESKTOP,
    `ant-tabs-tab-custom`,
    `ant-tabs-tab-custom-mobile`,
  )
  const styleCustom = `${styleIsDesktop}${styleClassName}${styleIsMargin}${styleIsChildren}`
  const styleTabs = getStyle(
    isCustom,
    styleCustom,
    `${className}${styleIsMargin}`,
  )

  const listItems = items?.map((item) => ({
    forceRender: true,
    ...item,
  }))

  return (
    <TabsAnt
      activeKey={activeKey}
      className={styleTabs}
      defaultActiveKey={defaultActiveKey}
      onChange={onChange}
      onEdit={onEdit}
      onTabClick={onTabClick}
      items={listItems}
    />
  )
}
