import styled from "styled-components"
import { Regex } from "../../../../../../../constant/Regex"
import { Button } from "../../../../../../../_webui/layout/components/Button"

const { REGEX_IS_DESKTOP } = Regex

const StyledHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
`

const StyledButton = styled(Button)`
  margin-right: ${REGEX_IS_DESKTOP && "16px"};
`

export const Styled = {
  StyledButton,
  StyledHeading,
}
