/* eslint-disable max-lines */
import styled, { css } from "styled-components"
import { Form } from "../.."
import { Regex } from "../../../../../../constant/Regex"
import { Colors } from "../../../../../../constant/Styled/Colors"
import { Fonts } from "../../../../../../constant/Styled/Fonts"

const { BORDER, TEXT } = Colors
const { FS_LARGE } = Fonts
const { REGEX_IS_DESKTOP } = Regex

const StyledFormSearch = styled.div`
  position: relative;
  .btn-submit {
    margin-top: 0;
  }
  ${() =>
    REGEX_IS_DESKTOP &&
    css`
      margin-top: 30px;
      margin-bottom: 30px;
      .ant {
        &-form {
          display: flex;
          flex-wrap: wrap;
        }
      }
    `}
`

const StyledFormSearchForm = styled(Form)`
  padding: 0;
  .ant {
    &-form {
      border: 1px solid ${BORDER};
      border-radius: 10px;
      padding: 30px;
      &-item {
        margin-top: 0;
        &-label {
          width: 130px;
        }
      }
      &-horizontal {
        .ant {
          &-row {
            flex-wrap: nowrap;
          }
          &-form-item-control {
            max-width: calc(100% - 130px);
          }
        }
      }
      &-vertical .ant-form-item-label {
        width: 100%;
      }
    }
  }
  .card-heading {
    color: ${TEXT};
    font-size: ${FS_LARGE};
    margin-bottom: 4px;
    &-divider {
      display: none;
    }
    &-text {
      padding-left: 0;
    }
  }
  .btn-submit {
    width: 100%;
  }
  ${REGEX_IS_DESKTOP
    ? css`
        border-radius: 0;
        box-shadow: none;
      `
    : css`
        .ant-form-item:first-child {
          margin-top: 0;
        }
      `}
`

const StyledFormSearchBtn = styled.div`
  width: 100%;
  position: relative;
`

export const Styled = {
  StyledFormSearch,
  StyledFormSearchBtn,
  StyledFormSearchForm,
}
