import { FC } from "react"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { Translate } from "../../../../../constant/Translate"
import { Form } from "../../../../../_webui/layout/components/Form"
import { Title } from "../../../../../_webui/layout/components/Typography/Title"
import { Divider } from "../../../../../_webui/layout/components/Divider"
import { Table } from "../../../../../_webui/layout/components/Table"
import { useAttentionsEdit } from "./modules/useEdit"
import { ButtonSubmit } from "../../../../../_webui/layout/components/Button/Submit"
import { Button } from "../../../../../_webui/layout/components/Button"
import { Space } from "../../../../../_webui/layout/components/Space"
import { Grid } from "../../../../../_webui/layout/components/Grid"

const {
  TEXT_T072,
  TEXT_T360,
  TEXT_T317,
  TEXT_T322,
  TEXT_T323,
  TEXT_T325,
  TEXT_T366,
} = Translate

export const AttentionsEdit: FC = () => {
  const {
    breadcrumbItems,
    columns,
    form,
    goBack,
    gridItems,
    isDisabledBtnSubmit,
    isHiddenSubmitBtn,
    isLoading,
    isLoadingLayout,
    onFinish,
    onValuesChange,
    travelerInfoList,
  } = useAttentionsEdit()

  return (
    <Layout
      isLoadingLayout={isLoadingLayout}
      breadcrumbItems={breadcrumbItems}
      title={TEXT_T322}
    >
      <Form
        form={form}
        heading={TEXT_T322}
        isLoading={isLoading}
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Title titleLeft={TEXT_T323} />
        <Title titleLeft={TEXT_T360} />
        <Space isSpaceTop isSpaceBottom>
          <Title titleLeft={TEXT_T366} />
          <Grid gridItems={gridItems} isCustom isForm isItemKeySmall />
          <ButtonSubmit
            isDisabledSubmit={isDisabledBtnSubmit}
            isHiddenSubmit={isHiddenSubmitBtn}
            submitText={TEXT_T325}
          />
        </Space>
        <Divider />
        <Space isSpaceTop isSpaceBottom>
          <Title titleLeft={TEXT_T317} />
          <Table
            columns={columns}
            dataSource={travelerInfoList}
            pagination={false}
            rowKey="travelScheduleId"
          />
        </Space>
        <Space isSpaceTop>
          <Button
            htmlType="button"
            isFullWidth
            isHidden={isHiddenSubmitBtn}
            onClick={goBack}
            text={TEXT_T072}
            type="ghost"
          />
        </Space>
      </Form>
    </Layout>
  )
}
