/* eslint-disable max-lines */
import { useEffect } from "react"
import { Routers } from "../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { safetyApi } from "../../../../apis"
import {
  SafetiesDetailBaseType,
  SafetiesDetailCountType,
  // SafetiesDetailReqType,
  SafetiesDetailResType,
  UseSafetiesDetailType,
} from "../../../../models/detail"
import { useVariables } from "../useVariables"
import { convertDataSource } from "./convertDataSource"

const { SCR973 } = Routers

export const useSafetiesDetail =
  (): UseSafetiesDetailType<SafetiesDetailBaseType> => {
    const {
      activeKey,
      breadcrumbItems,
      columns,
      countSafetyStatus,
      goToSafetiesAnswer,
      handleExportFile,
      isCommonTravelerRefer,
      isHiddenAnswerBtn,
      isHiddenBackBtn,
      isHiddenExportBtn,
      isLoading,
      isLoadingLayout,
      isResetTable,
      isSafetiesBrowseResponse,
      onChange,
      onGoBack,
      perItem,
      push,
      rowSelection,
      safetyId,
      safetyInfo,
      safetyTravelers,
      selectedRowKeys,
      setActiveKey,
      setCountSafetyStatus,
      setIsLoading,
      setIsLoadingLayout,
      setIsResetTable,
      setSafetyInfo,
      setSafetyTravelers,
    } = useVariables()

    const getCount = () => {
      safetyApi
        .count(safetyId)
        .then((countSafetyResponse: SafetiesDetailCountType) => {
          setCountSafetyStatus(countSafetyResponse)
        })
        .catch((error: AxiosErrorType) => {
          const stateError = pushStateError(error)
          push({ pathname: SCR973, state: stateError })
        })
    }

    const onTabClick = (_activeKey: string) => {
      if (_activeKey !== activeKey) {
        setIsLoading(true)
        setActiveKey(_activeKey)
      }
    }

    const getSafetyTravelers = (isCallApi: boolean) => {
      safetyApi
        .getSafetyTravelers({ safetyId, safetyStatus: activeKey })
        .then((safetyInforDetailResponse: SafetiesDetailResType) => {
          if (isCallApi) {
            const { safetyInfo, safetyTravelers } = safetyInforDetailResponse
            const data = convertDataSource(safetyTravelers)
            setSafetyTravelers(data)
            setSafetyInfo(safetyInfo)
          }
        })
        .catch((error: AxiosErrorType) => {
          const stateError = pushStateError(error)
          push({ pathname: SCR973, state: stateError })
        })
        .finally(() => {
          setIsLoading(false)
          setIsLoadingLayout(false)
          setIsResetTable(false)
        })
    }

    useEffect(() => {
      let isCallApi = true
      setIsResetTable(true)
      getSafetyTravelers(isCallApi)
      if (isSafetiesBrowseResponse) {
        getCount()
      }

      return () => {
        isCallApi = false
      }
    }, [activeKey])

    useEffect(() => {
      if (!isCommonTravelerRefer) {
        const { pathname, state } = backToLogin()
        push({ pathname, state })
      }
    }, [])

    return {
      breadcrumbItems,
      columns,
      countSafetyStatus,
      goToSafetiesAnswer,
      handleExportFile,
      isHiddenAnswerBtn,
      isHiddenBackBtn,
      isHiddenExportBtn,
      isLoading,
      isLoadingLayout,
      isResetTable,
      isSafetiesBrowseResponse,
      onChange,
      onGoBack,
      onTabClick,
      perItem,
      rowSelection,
      safetyInfo,
      safetyTravelers,
      selectedRowKeys,
    }
  }
