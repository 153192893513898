/* eslint-disable complexity */
import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const {
    isCommonRefer,
    isCommonTravelerRefer,
    isRoleAdmin,
    isRoleSubAdmin,
    isTravelerDelete,
    isTravelerSearch,
  } = useAuthority()

  // ロール条件に従ってボタンを表示する。
  const isVisibleBtnRequestDelete = isRoleSubAdmin && isTravelerSearch
  const isVisibleBtnDelete = isRoleAdmin && isTravelerSearch
  const isVisibleBtnHome =
    (isRoleAdmin || isRoleSubAdmin) && (isCommonTravelerRefer || isCommonRefer)

  return {
    isRoleAdmin,
    isRoleSubAdmin,
    isTravelerDelete,
    isVisibleBtnDelete,
    isVisibleBtnHome,
    isVisibleBtnRequestDelete,
  }
}
