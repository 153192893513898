import "./style/index.scss"
import { FC } from "react"
import { LabelRequiredProps } from "./models"
import { Translate } from "../../../../../constant/Translate"
import { Tooltip } from "../../Tooltip"
import { getStyle } from "../../../../../utils/getStyle"
import { Constant } from "../../../../../constant"

const { TEXT_T513 } = Translate
const { CHECK_SELECTED_LANG } = Constant

export const LabelRequired: FC<LabelRequiredProps> = (props) => {
  const { isBgBlue, label, tooltip } = props
  const styleEnglishLanguage = getStyle(
    CHECK_SELECTED_LANG,
    "",
    " label-required-text-en",
  )
  const styleIsBgBlue = getStyle(isBgBlue, " label-required-text-blue", "")
  const styleRequiredText = `label-required-text${styleEnglishLanguage}${styleIsBgBlue}`

  return (
    <div className="label-required">
      <span className="label-required-label">{label}</span>
      <div className={styleRequiredText}>{TEXT_T513}</div>
      {tooltip && <Tooltip title={tooltip} />}
    </div>
  )
}
