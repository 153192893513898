/* eslint-disable complexity */
import { FC, Fragment } from "react"
import { RadioItems } from "../../../../../../../constant/RadioItems"
import { SelectItems } from "../../../../../../../constant/SelectItems"
import { Translate } from "../../../../../../../constant/Translate"
import { validatorRequiredField } from "../../../../../../../utils/validator/requiredField"
import { ItemInput } from "../../../../../../../_webui/layout/components/Form/Item/Input"
import { ItemRadio } from "../../../../../../../_webui/layout/components/Form/Item/Radio"
import { ItemSelect } from "../../../../../../../_webui/layout/components/Form/Item/Select"
import { PostingProps } from "./models"
import { requiredField } from "../../../../../../../utils/requiredField"

const { OTHER_CITY_SELECT_ITEMS } = SelectItems
const { TEXT_T054, TEXT_T055, TEXT_T056, TEXT_T057 } = Translate
const { WORK_PLACE_LOCATION_EDIT } = RadioItems

export const Posting: FC<PostingProps> = (props) => {
  const {
    allCountries,
    cities,
    isAccompanyingFamily,
    isOtherCity,
    isOverseasResident,
    onWorkPlaceCityChange,
    onWorkPlaceCountryChange,
    onWorkPlaceLocationChange,
  } = props

  const requiredItem = requiredField(!isAccompanyingFamily)
  const radioItems = isAccompanyingFamily
    ? [WORK_PLACE_LOCATION_EDIT[0]]
    : WORK_PLACE_LOCATION_EDIT.slice(1)

  return (
    <Fragment>
      <ItemRadio
        label={TEXT_T054}
        name="workPlaceLocation"
        onChange={onWorkPlaceLocationChange}
        radioItems={radioItems}
        required
        rules={[validatorRequiredField]}
      />
      {isOverseasResident && !isAccompanyingFamily && (
        <Fragment>
          <ItemSelect
            label={TEXT_T055}
            name="workPlaceCountry"
            onChange={onWorkPlaceCountryChange}
            required={!isAccompanyingFamily}
            rules={requiredItem}
            selectItem={allCountries}
          />
          <ItemSelect
            label={TEXT_T056}
            name="workPlaceCity"
            onChange={onWorkPlaceCityChange}
            required={!isAccompanyingFamily}
            rules={requiredItem}
            selectItem={[...(cities ? cities : []), OTHER_CITY_SELECT_ITEMS]}
          />
          {isOtherCity && (
            <ItemInput
              label={TEXT_T057}
              maxLength={200}
              name="workPlace"
              required={!isAccompanyingFamily}
              rules={requiredItem}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  )
}
