import { FC } from "react"
import { Translate } from "../../../../../constant/Translate"
import { BreakText } from "../BreakText"
import { Title } from "../Title"
import { FullNameProps } from "./models"
import { Styled } from "./styled"

const { StyledTitleRight } = Styled
const { TEXT_T651 } = Translate

export const FullName: FC<FullNameProps> = (props) => {
  const { fullName } = props

  return fullName ? (
    <Title
      titleRight={
        <StyledTitleRight>
          {TEXT_T651}
          {fullName?.kanji ? (
            <BreakText above={fullName.kanji} below={fullName.romaji} />
          ) : (
            fullName?.romaji
          )}
        </StyledTitleRight>
      }
    />
  ) : null
}
