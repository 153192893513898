import { FC } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { ItemInput } from "../../../../../../_webui/layout/components/Form/Item/Input"
import { ItemSelect } from "../../../../../../_webui/layout/components/Form/Item/Select"
import { FormSearch } from "../../../../../../_webui/layout/components/Form/Search"
import { FormSearchLeft } from "../../../../../../_webui/layout/components/Form/Search/Left"
import { FormSearchRight } from "../../../../../../_webui/layout/components/Form/Search/Right"
import { TravelersRequestDeleteFormSearchProps } from "../../../models/requestDelete"

const { TEXT_T025, TEXT_T167, TEXT_T168, TEXT_T170 } = Translate

export const TravelersRequestDeleteFormSearch: FC<
  TravelersRequestDeleteFormSearchProps
> = (props) => {
  const { dispatchType, isVisibleBtn, onClickSearchSubmit } = props

  return (
    <FormSearch
      heading={TEXT_T167}
      isHiddenReset={!isVisibleBtn}
      isHiddenSubmit={!isVisibleBtn}
      onFinish={onClickSearchSubmit}
      labelAlign="left"
    >
      <FormSearchLeft>
        <ItemInput label={TEXT_T168} name="fullName" />
      </FormSearchLeft>

      <FormSearchRight>
        <ItemSelect
          label={TEXT_T025}
          name="dispatchType"
          selectItem={dispatchType}
        />
      </FormSearchRight>

      <FormSearchLeft>
        <ItemInput label={TEXT_T170} name="workPlace" />
      </FormSearchLeft>
    </FormSearch>
  )
}
