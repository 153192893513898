import { Translate } from "../../../../../../../../../constant/Translate"

const {
  EXPORT_EP0041, // 件名    Subject
  EXPORT_EP0171, // 発信日時    Departure date and time
  EXPORT_EP0028, // 確認日時    Response date
  EXPORT_EP0023, // 応答結果    Response result
  EXPORT_EP0098, // 代理応答    Respond on Behalf
  EXPORT_EP0054, // 氏名（日）    Full name(Japanese)
  EXPORT_EP0052, // 氏名（英）    Full name(English)
  EXPORT_EP0157, // 人材種別    Dispatch type
  EXPORT_EP0158, // 人材種別（詳細）    Dispatch type (Detail)
  EXPORT_EP0017, // ログインID    Login ID
  EXPORT_EP0096, // 滞在地連絡先情報（電子メールアドレス1）   Contract information in place of residence(Email address 1)
  EXPORT_EP0095, // 滞在地連絡先情報（滞在地携帯番号1）   Contract information in place of residence(Mobile number 1 in place of residence)
  EXPORT_EP0080, // 職員番号    JICA Staff ID
  EXPORT_EP0125, // 派遣番号    Dispatch number
  EXPORT_EP0159, // 隊員番号    JICA  Volunteer’s  number
} = Translate

export const heading = `${EXPORT_EP0041},${EXPORT_EP0171},${EXPORT_EP0028},${EXPORT_EP0023},${EXPORT_EP0098},${EXPORT_EP0054},${EXPORT_EP0052},${EXPORT_EP0157},${EXPORT_EP0158},${EXPORT_EP0017},${EXPORT_EP0096},${EXPORT_EP0095},${EXPORT_EP0080},${EXPORT_EP0125},${EXPORT_EP0159}`
