/* eslint-disable complexity */
/* eslint-disable max-lines */
import { useEffect } from "react"
import { GeneralCode } from "../../../../../../../../constant/GeneralCode"
import { backToLogin } from "../../../../../../../../utils/backToLogin"
import { UseFilesManagerType } from "../../../../../models/files/manager"
import { useVariables } from "../useVariables"
import { FilesListDataSourceType } from "../../../../../models/files/list"
import { getUserInformation } from "../../../../../../../../utils/getUserInformation"

const {
  FAQ_TYPE_NOT_JICA_STAFF,
  MEASURES_TYPE,
  SAFETY_MANAGEMENT_DEPARTMENT_TYPE,
  SAFETY_MANAGEMENT_DEPARTMENT,
  SAFETY_MEASURES_TYPE,
  SUBADMIN_MANUAL_TYPE,
  TEMPLATE_FOR_REGISTRATION_TYPE,
  USER_MANUAL_TYPE,
} = GeneralCode
const ADMIN_TYPE = [
  FAQ_TYPE_NOT_JICA_STAFF,
  SAFETY_MEASURES_TYPE,
  SUBADMIN_MANUAL_TYPE,
  USER_MANUAL_TYPE,
]

export const useFilesManager =
  (): UseFilesManagerType<FilesListDataSourceType> => {
    const {
      allCountries,
      columns,
      currentUsage,
      deleteFiles,
      fileInfoList,
      fileTypeList,
      form,
      getFiles,
      goToFilesUpload,
      handleDownload,
      handlePreviewFile,
      isCommonUploadRegister,
      isCountry,
      isDisabledBtnDelete,
      isDisabledBtnDownload,
      isDisabledBtnPreview,
      isDisabledCountries,
      isLoading,
      isLoadingLayout,
      isOpen,
      isOpenPreviewFile,
      isResetTable,
      isRoleAdmin,
      isVisibleBtnDelete,
      isVisibleBtnReference,
      isVisibleBtnRegister,
      onCancel,
      onCancelPreviewFile,
      onChangeCountryCode,
      onChangeFileType,
      onChangePagination,
      onChangeTable,
      onOpen,
      perItem,
      push,
      rowSelection,
      setFileTypeList,
      setIsDisabledCountries,
      typeInfo,
      typeInfoSubAdmin,
    } = useVariables()

    useEffect(() => {
      // ユーザの画面アクセス許可チェックを処理する。
      if (!isCommonUploadRegister) {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const { pathname, state } = backToLogin()
        push({ pathname, state })
      } else {
        getFiles({ isFileManager: true })
      }
    }, [])

    useEffect(() => {
      if (typeInfo?.length) {
        const isMeasuresType = !!typeInfo.filter(
          (item) => item.value === MEASURES_TYPE,
        ).length

        setIsDisabledCountries(isMeasuresType)
        const _typeInfo = isRoleAdmin
          ? typeInfo
          : typeInfoSubAdmin
          ? typeInfo.filter((item) => !ADMIN_TYPE.includes(String(item.value)))
          : typeInfo.filter(
              (item) =>
                item.value !== MEASURES_TYPE &&
                !ADMIN_TYPE.includes(String(item.value)),
            )
        const userInformation = getUserInformation()
        if (isRoleAdmin) {
          setFileTypeList(_typeInfo)
        } else if (SAFETY_MANAGEMENT_DEPARTMENT === userInformation?.bumonCd) {
          const tempFileTypeList = _typeInfo.filter((item) =>
            SAFETY_MANAGEMENT_DEPARTMENT_TYPE.includes(String(item.value)),
          )
          setFileTypeList(tempFileTypeList)
        } else {
          const tempFileTypeList = _typeInfo.filter(
            (item) => item.value === TEMPLATE_FOR_REGISTRATION_TYPE,
          )
          setFileTypeList(tempFileTypeList)
        }
      }
    }, [typeInfo])

    return {
      allCountries,
      columns,
      currentUsage,
      dataSource: fileInfoList,
      deleteFiles,
      fileTypeList,
      form,
      goToFilesUpload,
      handleDownload,
      handlePreviewFile,
      isCountry,
      isDisabledBtnDelete,
      isDisabledBtnDownload,
      isDisabledBtnPreview,
      isDisabledCountries,
      isLoading,
      isLoadingLayout,
      isOpen,
      isOpenPreviewFile,
      isResetTable,
      isVisibleBtnDelete,
      isVisibleBtnReference,
      isVisibleBtnRegister,
      onCancel,
      onCancelPreviewFile,
      onChangeCountryCode,
      onChangeFileType,
      onChangePagination,
      onChangeTable,
      onOpen,
      perItem,
      rowSelection,
    }
  }
