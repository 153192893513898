/* eslint-disable max-lines */
import { useEffect } from "react"
import { GeneralCode } from "../../../../../../../constant/GeneralCode"
import { Routers } from "../../../../../../../constant/Routers"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { getTextByKey } from "../../../../../../../utils/getTextByKey"
import { getValueCombobox } from "../../../../../../../utils/getValueCombobox"
import { RequiredFields } from "../../../../constants/edit/requiredFields"
import {
  AttentionsEditDataType,
  UseAttentionsEditType,
} from "../../../../models/edit"
import { useVariables } from "../useVariables"

const { SCR312, SCR313 } = Routers
const { CTGR_OTHER } = GeneralCode
const { ATTENTIONS_EDIT_REQUIRED_FIELDS } = RequiredFields

export const useAttentionsEdit =
  (): UseAttentionsEditType<AttentionsEditDataType> => {
    const {
      breadcrumbItems,
      categoryList,
      columns,
      form,
      gridItems,
      isAttentionsSendAll,
      isDisabledBtnSubmit,
      isHiddenSubmitBtn,
      isLoading,
      isLoadingLayout,
      isShowCategoryOther,
      push,
      setIsDisabledBtnSubmit,
      setIsLoading,
      setIsLoadingLayout,
      setIsShowCategoryOther,
      setTravelerInfoList,
      severityList,
      state,
      travelerInfoList,
    } = useVariables()

    const onValuesChange = () => {
      // 「カテゴリ 」コンボボックスの値を変更する。
      const fieldsRequiredNew =
        form.getFieldValue("categoryName") === CTGR_OTHER
          ? [...ATTENTIONS_EDIT_REQUIRED_FIELDS, "categoryValue"]
          : ATTENTIONS_EDIT_REQUIRED_FIELDS

      const formValues = form.getFieldsValue(fieldsRequiredNew)

      setIsDisabledBtnSubmit(Object.values(formValues).some((item) => !item))
    }

    /**
     * 「SCR312_注意喚起の内容確認画面」に遷移する。
     * 「SCR961_注意喚起の複製画面」に遷移する。
     */
    const goBack = () => {
      if (state?.sourceURL) {
        push({
          pathname: state?.sourceURL,
          state: { ...state, sourceURL: SCR313 },
        })
      }
    }

    const onFinish = (values: AttentionsEditDataType) => {
      setIsLoading(true)
      const categoryName = getTextByKey(values.categoryId, categoryList)
      const severityName = severityList?.find(
        (item) => item.value === values?.severity,
      )?.text

      const data = {
        ...values,
        categoryId: getValueCombobox(values.categoryId, categoryName),
        categoryName,
        severityName: severityName,
      }

      setTimeout(() => {
        // 「SCR312_注意喚起の内容確認画面」に遷移する。
        push({
          pathname: SCR312,
          state: {
            ...state,
            sourceURL: SCR313,
            transmissionInfo: data,
          },
        })
        setIsLoading(false)
      }, 500)
    }

    useEffect(() => {
      // ユーザの画面アクセス許可チェックを処理する。
      if (!isAttentionsSendAll) {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const { pathname, state } = backToLogin()
        push({ pathname, state })
      } else if (state) {
        const { transmissionInfo, travelerInfoList } = state

        if (transmissionInfo) {
          const categoryId = transmissionInfo.categoryId.split("_")[0]

          form.setFieldsValue({
            attentionTitle: transmissionInfo.attentionTitle,
            bodyContent: transmissionInfo.bodyContent,
            categoryId,
            categoryName: categoryId,
            severity: transmissionInfo.severity,
            severityName: transmissionInfo.severityName,
          })

          if (categoryId === CTGR_OTHER) setIsShowCategoryOther(true)
        }

        setTravelerInfoList(travelerInfoList)

        setTimeout(() => {
          onValuesChange()
        }, 100)
        setTimeout(() => {
          setIsLoadingLayout(false)
        }, 500)
      }
    }, [])

    useEffect(() => {
      const { transmissionInfo } = state

      if (isShowCategoryOther && transmissionInfo)
        form.setFieldValue("categoryValue", transmissionInfo.categoryValue)
    }, [isShowCategoryOther])

    return {
      breadcrumbItems,
      columns,
      form,
      goBack,
      gridItems,
      isDisabledBtnSubmit,
      isHiddenSubmitBtn,
      isLoading,
      isLoadingLayout,
      onFinish,
      onValuesChange,
      travelerInfoList,
    }
  }
