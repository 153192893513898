import { useState, Key } from "react"
import { useTable } from "../../../../../../../utils/hook/useTable"
import { TravelersConfirmDeleteType } from "../../../../models/list"
import { TravelersListDataSourceType } from "../../../../models/list/data"

export const useVariablesRowSelection = (dataLength: number) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [isDisabledBtnDelete, setIsDisabledBtnDelete] = useState(true)
  const [isDisabledBtnRefer, setIsDisabledBtnRefer] = useState(true)
  const [isHiddenBtnTravelerDraft, setIsHiddenBtnTravelerDraft] = useState(true)
  const [isHiddenBtnScheduleDraft, setIsHiddenBtnScheduleDraft] = useState(true)
  const [travelerId, setTravelerId] = useState("")
  const [dataCurrent, setDataCurrent] = useState<TravelersListDataSourceType[]>(
    [],
  )
  const [dataSource, setDataSource] = useState<TravelersListDataSourceType[]>(
    [],
  )
  const [dataSourceSelected, setDataSourceSelected] = useState<
    TravelersConfirmDeleteType[]
  >([])
  const {
    onChange: onChangePage,
    perItem,
    perItemStart,
    perItemEnd,
  } = useTable(dataLength)

  return {
    dataCurrent,
    dataSource,
    dataSourceSelected,
    isCheckAll,
    isDisabledBtnDelete,
    isDisabledBtnRefer,
    isHiddenBtnScheduleDraft,
    isHiddenBtnTravelerDraft,
    onChangePage,
    perItem,
    perItemEnd,
    perItemStart,
    selectedRowKeys,
    setDataCurrent,
    setDataSource,
    setDataSourceSelected,
    setIsCheckAll,
    setIsDisabledBtnDelete,
    setIsDisabledBtnRefer,
    setIsHiddenBtnScheduleDraft,
    setIsHiddenBtnTravelerDraft,
    setSelectedRowKeys,
    setTravelerId,
    travelerId,
  }
}
