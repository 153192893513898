import { concat } from "../../../../../../../../../utils/concat"
import { ScheduleInfoStaysType } from "../../../../../../models/add"
import { SchedulesAddStaysAddType } from "../../../../../../models/add/DataAdd"

export const getStayDeleteData = (
  detailDeleteData: ScheduleInfoStaysType[],
  id: string,
): SchedulesAddStaysAddType[] =>
  detailDeleteData.map((item) => ({
    emailAddress1: item.emailAddress1,
    emailAddress2: item.emailAddress2,
    locationPhoneNumber1: item.locationPhoneNumber1,
    locationPhoneNumber2: item.locationPhoneNumber2,
    satellitePhoneNumber: item.satellitePhoneNumber,
    travelScheduleId: concat(id),
    travelStayId: item.travelStayId,
    type: "Delete",
  }))
