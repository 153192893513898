import { useHistory, useLocation } from "react-router-dom"
import { useTableErrorConfig } from "../../../../../../../_webui/layout/components/Table/Error/modules/useTableErrorConfig"
import { useApi } from "../useApi"
import { useTableConfig } from "../useTableConfig"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { useVariablesModal } from "../useVariablesModal"
import { getBreadcrumbItems } from "./getBreadcrumbItems"

export const useVariables = () => {
  const { pathname } = useLocation()
  const { push } = useHistory()
  const { columnErrors } = useTableErrorConfig()

  const { isNotAccessScreen, isVisibleBtnCancel, isVisibleBtnUpdate } =
    useVariablesAuthority()

  const {
    isOpen,
    isOpenUploaded,
    onCancel,
    onCancelUploaded,
    onOpen,
    onOpenDeleted,
  } = useVariablesModal()

  const {
    beforeUpload,
    bulkCheck,
    bulkDeleteData,
    bulkFileUpdate,
    bulkUpdate,
    countRecord,
    dataTemps,
    errorList,
    fileNameList,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isResetTable,
    onChange,
    perItem,
  } = useApi({
    onCancel,
    onCancelUploaded,
  })

  const { columns } = useTableConfig()

  const breadcrumbItems = getBreadcrumbItems(pathname)

  return {
    beforeUpload,
    breadcrumbItems,
    bulkCheck,
    bulkDeleteData,
    bulkFileUpdate,
    bulkUpdate,
    columnErrors,
    columns,
    countRecord,
    dataTemps,
    errorList,
    fileNameList,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isNotAccessScreen,
    isOpen,
    isOpenUploaded,
    isResetTable,
    isVisibleBtnCancel,
    isVisibleBtnUpdate,
    onCancel,
    onCancelUploaded,
    onChange,
    onOpen,
    onOpenDeleted,
    perItem,
    push,
  }
}
