import { FC } from "react"
import { FormSearchLeftProps } from "./models"
import { Styled } from "./styled"

const { StyledFormSearchLeft } = Styled

export const FormSearchLeft: FC<FormSearchLeftProps> = (props) => {
  const { children } = props
  return <StyledFormSearchLeft>{children}</StyledFormSearchLeft>
}
