import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const { isCommonRefer, isRoleAdmin, isRoleSubAdmin, isTravelerChange } =
    useAuthority()

  const isNotAccessScreen = !isTravelerChange

  const isVisibleBtnBtn = isRoleAdmin || isRoleSubAdmin
  const isVisibleBtnCancel = isVisibleBtnBtn && isCommonRefer
  const isVisibleBtnUpdate = isVisibleBtnBtn && isTravelerChange

  return {
    isNotAccessScreen,
    isRoleSubAdmin,
    isVisibleBtnCancel,
    isVisibleBtnUpdate,
  }
}
