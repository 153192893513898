import { Breadcrumbs } from "../../../../../../../../constant/Breadcrumbs"
import { Translate } from "../../../../../../../../constant/Translate"

const { BRCR_SCR119_FROM_SCR115, BRCR_SCR119_FROM_SCR126 } = Breadcrumbs
const { TEXT_T187, TEXT_T611 } = Translate

export const getBreadcrumbItems = (
  isRoleAdmin: boolean,
  titleHeading: string,
  isFromSCR115?: boolean,
  isFromSCR126?: boolean,
) =>
  isFromSCR115
    ? [...BRCR_SCR119_FROM_SCR115, titleHeading]
    : isFromSCR126
    ? [
        ...BRCR_SCR119_FROM_SCR126,
        isRoleAdmin ? TEXT_T611 : TEXT_T187,
        titleHeading,
      ]
    : [titleHeading]
