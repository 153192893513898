import { FC } from "react"
import { ButtonSubmit } from "../../../../../../_webui/layout/components/Button/Submit"
import { Form } from "../../../../../../_webui/layout/components/Form"
import { ItemDatePicker } from "../../../../../../_webui/layout/components/Form/Item/DatePicker"
import { ItemInput } from "../../../../../../_webui/layout/components/Form/Item/Input"
import { ItemSelect } from "../../../../../../_webui/layout/components/Form/Item/Select"
import { Modal } from "../../../../../../_webui/layout/components/Modal"
import { Translate } from "../../../../../../constant/Translate"
import { SafetiesFormSearchProps } from "../../../models/list"

const { TEXT_T012, TEXT_T179, TEXT_T211, TEXT_T312, TEXT_T315, TEXT_T333 } =
  Translate

export const SafetiesFormSearch: FC<SafetiesFormSearchProps> = (props) => {
  const {
    categoryList,
    form,
    isOpen,
    onBlur,
    onCancel,
    onFinish,
    onKeyDown,
    statusList,
  } = props
  return (
    <Modal isOpen={isOpen} onCancel={onCancel} isShowFooter={false} isForm>
      <Form
        form={form}
        isFullHeight
        isHeading={false}
        labelAlign="left"
        onFinish={onFinish}
      >
        <ItemDatePicker
          label={TEXT_T333}
          name="implementationDate"
          onBlur={(event) => onBlur(event, TEXT_T333, "implementationDate")}
          onKeyDown={(event) =>
            onKeyDown(event, TEXT_T333, "implementationDate")
          }
        />
        <ItemInput label={TEXT_T315} maxLength={50} name="safetyTitle" />
        <ItemSelect label={TEXT_T179} name="statusId" selectItem={statusList} />
        <ItemSelect
          label={TEXT_T312}
          name="categoryId"
          selectItem={categoryList}
        />
        <ButtonSubmit
          isReset
          onClickReset={onCancel}
          resetText={TEXT_T012}
          submitText={TEXT_T211}
        />
      </Form>
    </Modal>
  )
}
