import { getFullName } from "../../../../../../../../../utils/getFullName"
import { SchedulesListDataType } from "../../../../../../models/list"
import { heading, headingAdmin } from "./heading"
import { Routers } from "../../../../../../../../../constant/Routers"
import { concat } from "../../../../../../../../../utils/concat"
import { getValueByLanguage } from "../../../../../../../../../utils/getValueByLanguage"
import moment from "moment"
import { getTextYesNo } from "../../../../../../../../../utils/getTextYesNo"
import { joinString } from "../../../../../../../../../utils/joinString"

const { SCR210 } = Routers

const formatDateExport = (date?: string) =>
  date ? moment(date).format("YYYYMMDD") : ""

export const convertDataExport = (
  data: SchedulesListDataType[],
  pathname: string,
) => {
  const isRoleUser = pathname === SCR210
  const _heading = isRoleUser ? heading : headingAdmin
  let convertData = _heading
  data.map((item) => {
    const fullNameKanji = getFullName(
      item.info.kanjiSurname,
      item.info.kanjiName,
      true,
    )
    const fullNameRomaji = getFullName(
      item.info.romajiSurname,
      item.info.romajiName,
    )
    const fullName = concat(item.info.kanjiSurname, item.info.kanjiName)
      ? getValueByLanguage(fullNameKanji, fullNameRomaji)
      : fullNameRomaji
    const _convertData = `\r\n"${item.csv.userId}","${fullName}","${
      item.info.travelScheduleId
    }","${item.csv.travelPurpose}","${item.info.projectNameJp}","${
      item.info.projectNameEn
    }","${item.info.procurementControlNumber}","${
      item.csv.bumonCd ? joinString([item.csv.bumonCd, item.csv.bumonName]) : ""
    }","${item.info.mainPersonName}","${
      item.info.locationPersonName
    }","${formatDateExport(item.info.dispatchStartDate)}","${formatDateExport(
      item.info.dispatchEndDate,
    )}","${item.csv.departureDateTime}","${item.csv.arrivalDateTime}","${
      item.csv.departureCountryCode
    }","${item.csv.arrivalCountryCode}","${item.csv.departureCityCode}","${
      item.csv.departureCityName
    }","${item.csv.arrivalCityCode}","${item.csv.arrivalCityName}","${
      item.csv.transportation
    }","${item.csv.isTransit}","${item.csv.departureFlightNumber}","${
      item.csv.othersRemarks1
    }","${item.csv.othersRemarks2}","${item.csv.othersRemarks3}","${
      item.csv.othersRemarks4
    }","${item.csv.othersRemarks5}","${getTextYesNo(
      item.info.differentInforCheck,
    )}","${item.info.insuranceCompanyName}","${
      item.info.insuranceCompanyPhoneNumber
    }","${item.info.insuranceTicketNumber}","${
      item.info.insuranceSonsignor
    }","${item.csv.lodgingCheckin}","${item.csv.lodgingCheckout}","${
      item.csv.lodgingName
    }","${item.csv.lodgingAddress}","${item.csv.lodgingPhoneNumber}","${
      item.csv.locationPhoneNumber1
    }","${item.csv.locationPhoneNumber2}","${item.csv.satellitePhoneNumber}","${
      item.csv.emailAddress1
    }","${item.csv.emailAddress2}","${getTextYesNo(
      item.info.codeConductConfirmation,
    )}"`
    if (isRoleUser) {
      convertData += `${_convertData}`
    } else {
      convertData += `${_convertData},"${item.info.managerRemarks1}","${item.info.managerRemarks2}","${item.info.managerRemarks3}","${item.info.managerRemarks4}","${item.info.managerRemarks5}"`
    }
  })
  return convertData
}
