/* eslint-disable max-lines */
import moment, { Moment } from "moment"
import { useVariablesFormAdvanced } from "../useVariablesFormAdvanced"
import { Options } from "../../../../../../../_webui/layout/components/Form/Item/DateTimePicker/constant"
import { OptionItemType } from "../../../../../../../_webui/layout/components/Select/models"
import { FormInstance } from "antd"
import { SchedulesAdvancedSearchFormDataType } from "../../../../models/list/search"
import { getStyle } from "../../../../../../../utils/getStyle"

const { SELECT_HOUR_ITEMS, SELECT_MINUTE_ITEMS } = Options

export const useFormAdvanced = (
  formAdvanced: FormInstance<SchedulesAdvancedSearchFormDataType>,
) => {
  const {
    arrivalHourItems,
    arrivalMinuteItems,
    isDefaultValueArrivalDate,
    isDefaultValueDepartureDate,
    setArrivalHourItems,
    setArrivalMinuteItems,
    setIsDefaultValueArrivalDate,
    setIsDefaultValueDepartureDate,
  } = useVariablesFormAdvanced()

  // eslint-disable-next-line complexity, max-statements
  const onValuesChangeFormSearchAdvanced = () => {
    const arrivalDateTimeDate = formAdvanced.getFieldValue(
      "arrivalDateTimeDate",
    )
    const departureDateTimeDate = formAdvanced.getFieldValue(
      "departureDateTimeDate",
    )
    setIsDefaultValueArrivalDate(!arrivalDateTimeDate)
    setIsDefaultValueDepartureDate(!departureDateTimeDate)
    const arrivalDateTimeDateMoment = moment(arrivalDateTimeDate)
    const departureDateTimeDateMoment = moment(departureDateTimeDate)
    const arrivalDateTimeDateFormat = arrivalDateTimeDateMoment
      .format("DD/MM/YYYY")
      .toString()
    const departureDateTimeDateFormat = departureDateTimeDateMoment
      .format("DD/MM/YYYY")
      .toString()
    const departureDateTimeHour = parseInt(
      formAdvanced.getFieldValue("departureDateTimeHour"),
    )
    const departureDateTimeMinutes = parseInt(
      formAdvanced.getFieldValue("departureDateTimeMinutes"),
    )
    const arrivalDateTimeHour = parseInt(
      formAdvanced.getFieldValue("arrivalDateTimeHour"),
    )
    if (
      arrivalDateTimeDateFormat === departureDateTimeDateFormat &&
      departureDateTimeHour
    ) {
      const data: OptionItemType[] = SELECT_HOUR_ITEMS.map((item, index) => ({
        ...item,
        disabled: index < departureDateTimeHour,
      }))
      setArrivalHourItems(data)
      if (departureDateTimeHour > arrivalDateTimeHour) {
        formAdvanced.setFieldValue("arrivalDateTimeHour", "")
      }
    } else {
      setArrivalHourItems(SELECT_HOUR_ITEMS)
    }
    if (
      arrivalDateTimeDateFormat === departureDateTimeDateFormat &&
      departureDateTimeMinutes
    ) {
      const data: OptionItemType[] = SELECT_MINUTE_ITEMS.map((item, index) => ({
        ...item,
        disabled: index < departureDateTimeMinutes,
      }))
      setArrivalMinuteItems(data)
    } else {
      setArrivalMinuteItems(SELECT_MINUTE_ITEMS)
    }
  }

  const onChangeArrivalDate = (value: Moment | null) => {
    formAdvanced.setFieldsValue({
      arrivalDateTimeHour: getStyle(!!value, "23", ""),
      arrivalDateTimeMinutes: getStyle(!!value, "59", ""),
    })
  }

  const onChangeDepartureDate = (value: Moment | null) => {
    formAdvanced.setFieldsValue({
      departureDateTimeHour: getStyle(!!value, "00", ""),
      departureDateTimeMinutes: getStyle(!!value, "00", ""),
    })
  }

  return {
    arrivalHourItems,
    arrivalMinuteItems,
    isDefaultValueArrivalDate,
    isDefaultValueDepartureDate,
    onChangeArrivalDate,
    onChangeDepartureDate,
    onValuesChangeFormSearchAdvanced,
  }
}
