import { GetOverseasResidentType } from "./models"

export const getOverseasResident = (
  isOverseasResident: boolean,
  workPlace: string,
  workPlaceCity: string,
  workPlaceCountry: string,
): GetOverseasResidentType =>
  isOverseasResident
    ? {
        workPlace: workPlace,
        workPlaceCity: workPlaceCity,
        workPlaceCountry: workPlaceCountry,
      }
    : {
        workPlace: "",
        workPlaceCity: "",
        workPlaceCountry: "",
      }
