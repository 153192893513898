import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Routers } from "../../../constant/Routers"
import { AuthorityPatternResType } from "../../../infrastructure/handlers/authorities/models"
import { AxiosErrorType } from "../../../infrastructure/axiosError/models"
import { OptionItemType } from "../../../_webui/layout/components/Select/models"
import { AuthorityPatternsType } from "./models"
import { pushStateError } from "../../pushStateError"
import { authoritiesApi } from "../../../infrastructure/handlers/authorities"

const { SCR973 } = Routers

export const getAuthorityPatterns = (
  isInUseOnly?: boolean,
): AuthorityPatternsType => {
  const { push } = useHistory()
  const [authorityPatterns, setAuthorityPatterns] = useState<OptionItemType[]>(
    [],
  )

  useEffect(() => {
    authoritiesApi
      .getAuthorityPatterns(isInUseOnly)
      .then((res: AuthorityPatternResType[]) => {
        const optionItem: OptionItemType[] = res.map((item) => ({
          text: item.patternName,
          value: item.patternId,
        }))
        setAuthorityPatterns(optionItem)
      })
      .catch((error: AxiosErrorType) => {
        const state = pushStateError(error)
        push({ pathname: SCR973, state })
      })
  }, [])

  return {
    authorityPatterns,
  }
}
