import { FC } from "react"
import { Heading } from "../Heading"
import { Title } from "../Title"
import { ErrorListProps } from "./models"

export const ErrorList: FC<ErrorListProps> = (props) => {
  const { errorItems, heading } = props
  return errorItems?.length ? (
    <div>
      <Heading title={heading} type="danger" />
      {errorItems.map((item, index) => (
        <Title key={index} titleLeft={item.message} type="danger" />
      ))}
    </div>
  ) : null
}
