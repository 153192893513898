import { FC } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { ButtonGroup } from "../../../../../../_webui/layout/components/Button/Group"
import { SafetiesDetailButtonGroupProps } from "../../../models/detail"
import { Regex } from "../../../../../../constant/Regex"

const { REGEX_IS_DESKTOP } = Regex
const { TEXT_T072, TEXT_T393, TEXT_T394 } = Translate

export const SafetiesDetailButtonGroup: FC<SafetiesDetailButtonGroupProps> = (
  props,
) => {
  const {
    goToSafetiesAnswer,
    handleExportFile,
    isHiddenAnswerBtn,
    isHiddenBackBtn,
    isHiddenExportBtn,
    onGoBack,
    selectedRowKeys,
  } = props

  return (
    <ButtonGroup
      buttonItems={[
        {
          absolutePosition: "left",
          isAbsolute: true,
          isHidden: isHiddenBackBtn,
          isSmall: true,
          onClick: onGoBack,
          order: 3,
          text: TEXT_T072,
          type: "ghost",
        },
        {
          isHidden: !REGEX_IS_DESKTOP || isHiddenExportBtn,
          onClick: handleExportFile,
          order: 1,
          text: TEXT_T393,
          type: "primary",
        },
        {
          isDisabled: selectedRowKeys?.length !== 1,
          isHidden: isHiddenAnswerBtn,
          onClick: goToSafetiesAnswer,
          order: 2,
          text: TEXT_T394,
          type: "primary",
        },
      ]}
    />
  )
}
