import { FC } from "react"
import { CardSearchProps } from "./models"
import { Image } from "../../Image"
import { Images } from "../../../../../constant/Images"
import { Styled } from "./styled"

const { IMAGE_SEARCH } = Images
const { StyledButton } = Styled

export const CardSearch: FC<CardSearchProps> = (props) => {
  const {
    isSearch,
    isSearchIcon,
    isSmall,
    onClick,
    searchIcon = IMAGE_SEARCH,
    searchText,
    type,
  } = props
  return isSearch ? (
    <StyledButton
      addonBefore={isSearchIcon && <Image src={searchIcon} />}
      className="card-heading-button"
      isSmall={isSmall}
      onClick={onClick}
      text={searchText}
      type={type}
    />
  ) : null
}
