/* eslint-disable max-lines */
import { FC, Fragment } from "react"
import { ItemDatePicker } from "../../../../../../../../../_webui/layout/components/Form/Item/DatePicker"
import { ItemInput } from "../../../../../../../../../_webui/layout/components/Form/Item/Input"
import { Regex } from "../../../../../../../../../constant/Regex"
import { Translate } from "../../../../../../../../../constant/Translate"
import { validatorHalfSize } from "../../../../../../../../../utils/validator/halfSize"
import { validatorRangeDate } from "../../../../../../../../../utils/validator/rangeDate"
import { SchedulesAddTableButtonGroup } from "../../../../Table/ButtonGroup"
import { SchedulesAddLodgingTabProps } from "./models"

const { REGEX_IS_DESKTOP } = Regex
const {
  HELP_H0022,
  TEXT_T210,
  TEXT_T251,
  TEXT_T252,
  TEXT_T253,
  TEXT_T254,
  TEXT_T284,
  TEXT_T285,
  TEXT_T491,
  TEXT_T492,
} = Translate

export const SchedulesAddLodgingTab: FC<SchedulesAddLodgingTabProps> = (
  props,
) => {
  const {
    getItemTableRequiredField,
    handleAddLodgingItem,
    handleDeleteLodgingItem,
    isRequiredItemTableByPPTV,
    isSubmitValidate,
    isVisibleBtn,
    onBlur,
    onChangeDate,
    onKeyDown,
    schedulesAddLodgingItem,
  } = props

  const { index } = schedulesAddLodgingItem

  const itemName = `schedulesAddLodging.${index}`
  const lodgingCheckin = `${itemName}.lodgingCheckin`
  const lodgingCheckout = `${itemName}.lodgingCheckout`

  const _validatorRangeDate = isSubmitValidate
    ? [
        validatorRangeDate({
          isLodging: true,
          namePathEndDate: lodgingCheckout,
          namePathStartDate: lodgingCheckin,
        }),
      ]
    : []

  return (
    <Fragment>
      <SchedulesAddTableButtonGroup
        addText={REGEX_IS_DESKTOP ? TEXT_T284 : TEXT_T491}
        deleteText={REGEX_IS_DESKTOP ? TEXT_T285 : TEXT_T492}
        handleAdd={handleAddLodgingItem}
        handleDelete={() =>
          handleDeleteLodgingItem(schedulesAddLodgingItem.travelLodgingId)
        }
        isDisabled={false}
        isVisibleBtn={isVisibleBtn}
      />

      <ItemInput isHidden name={`${itemName}.travelLodgingId`} />

      <ItemDatePicker
        label={TEXT_T251}
        name={lodgingCheckin}
        onBlur={(event) => onBlur(event, TEXT_T251, lodgingCheckin)}
        onKeyDown={(event) => onKeyDown(event, TEXT_T251, lodgingCheckin)}
        required={isRequiredItemTableByPPTV}
        rules={[...getItemTableRequiredField(), ..._validatorRangeDate]}
      />

      <ItemDatePicker
        label={TEXT_T252}
        name={lodgingCheckout}
        onBlur={(event) => onBlur(event, TEXT_T252, lodgingCheckout)}
        onChange={() => onChangeDate(lodgingCheckin)}
        onKeyDown={(event) => onKeyDown(event, TEXT_T252, lodgingCheckout)}
        required={isRequiredItemTableByPPTV}
        rules={getItemTableRequiredField()}
      />

      <ItemInput
        label={TEXT_T210}
        maxLength={200}
        name={`${itemName}.lodgingName`}
        required={isRequiredItemTableByPPTV}
        rules={getItemTableRequiredField()}
      />

      <ItemInput
        label={TEXT_T253}
        maxLength={500}
        name={`${itemName}.lodgingAddress`}
      />

      <ItemInput
        label={TEXT_T254}
        maxLength={20}
        name={`${itemName}.lodgingPhoneNumber`}
        rules={[validatorHalfSize]}
        tooltip={HELP_H0022}
      />
    </Fragment>
  )
}
