import { useState } from "react"
import { useHistory } from "react-router-dom"
import { exportCsv } from "../../../../../../../utils/exportCsv"
import { useModal } from "../../../../../../../utils/hook/useModal"
import { UseTravelersExportFileProps } from "../../../../models/list"
import { convertDataExport } from "./convertDataExport"
import { Translate } from "../../../../../../../constant/Translate"

const { EXPORT_EP0192 } = Translate

export const useExportFile = (props: UseTravelersExportFileProps) => {
  const { selectedRowKeys, dataSourceExport } = props
  const { push } = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const { isOpen, onCancel, onOpen } = useModal()

  const handleExportFile = () => {
    setIsLoading(true)
    try {
      const _dataExport = selectedRowKeys?.length
        ? dataSourceExport.filter((item) =>
            selectedRowKeys.includes(item.base.travelerId),
          )
        : dataSourceExport

      const convertData = convertDataExport(_dataExport)
      exportCsv(convertData, EXPORT_EP0192)
    } finally {
      onCancel()
      setIsLoading(false)
    }
  }
  return {
    handleExportFile,
    isLoading,
    isOpen,
    onCancel,
    onOpen,
    push,
    setIsLoading,
  }
}
