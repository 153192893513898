import { createContext, FC, useContext } from "react"
import { Environment } from "../../../constant/Environment"
import { OjicaI18nProviderProps, InitialStateProps } from "./models"

const { KEY } = Environment.I18N_CONFIG

export const I18N_CONFIG_KEY = KEY || "i18nConfig"
export const defaultLang = "ja"

const initialState: InitialStateProps = {
  selectedLang: defaultLang,
}

export const getConfig = (): InitialStateProps => {
  const ls = localStorage.getItem(I18N_CONFIG_KEY)
  if (ls) {
    try {
      const langProps = JSON.parse(ls) as InitialStateProps
      if (langProps.selectedLang) {
        return JSON.parse(ls) as InitialStateProps
      }
    } catch (er) {
      console.error(er)
    }
  }
  return initialState
}

export const setLanguage = (lang: string) => {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: lang }))
  window.location.reload()
}

const I18nContext = createContext<InitialStateProps>(initialState)

export const useLang = () => {
  return useContext(I18nContext).selectedLang
}

export const OjicaI18nProvider: FC<OjicaI18nProviderProps> = (props) => {
  const lang = getConfig()
  return (
    <I18nContext.Provider value={lang}>{props.children}</I18nContext.Provider>
  )
}
