import { FC } from "react"
import { Form } from "../../../../../_webui/layout/components/Form"
import { ItemInputPassword } from "../../../../../_webui/layout/components/Form/Item/Input/Password"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { Translate } from "../../../../../constant/Translate"
import { validatorRequiredField } from "../../../../../utils/validator/requiredField"
import { useChangePassword } from "./modules/useChangePassword"
import { Styled } from "./styled"

const { StyledButton, StyledButtonGroup, StyledContainer, StyledValid } = Styled
const {
  MESSAGE_E0019,
  TEXT_T020,
  TEXT_T072,
  TEXT_T162,
  TEXT_T435,
  TEXT_T436,
  TEXT_T437,
} = Translate

export const ChangePassword: FC = () => {
  const {
    breadcrumbItems,
    form,
    goBack,
    isDisabledSubmit,
    isHiddenBtnBack,
    isHiddenBtnSubmit,
    isLoading,
    isLoadingLayout,
    isSideBar,
    onFinish,
    onValuesChange,
  } = useChangePassword()

  return (
    <Layout
      breadcrumbItems={breadcrumbItems}
      isHeaderRightNone={!isSideBar}
      isLoadingLayout={isLoadingLayout}
      isSideBar={isSideBar}
      title={TEXT_T162}
    >
      <Form
        form={form}
        heading={TEXT_T162}
        isBreadcrumbs={isHiddenBtnBack}
        isFullHeight
        isLoading={isLoading}
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <StyledContainer>
          <ItemInputPassword
            label={TEXT_T435}
            maxLength={99}
            name="newPassword"
            rules={[validatorRequiredField]}
          />
          <ItemInputPassword
            label={TEXT_T436}
            maxLength={99}
            name="confirmNewPassword"
            rules={[
              validatorRequiredField,
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") !== value) {
                    return Promise.reject(new Error(MESSAGE_E0019))
                  }
                  return Promise.resolve()
                },
              }),
            ]}
          />
        </StyledContainer>
        <StyledValid>{TEXT_T437}</StyledValid>
        <StyledButtonGroup>
          <StyledButton
            htmlType="submit"
            isDisabled={isDisabledSubmit}
            isFullWidth={true}
            isHidden={!isHiddenBtnSubmit}
            text={TEXT_T020}
            type="primary"
          />
          <StyledButton
            htmlType="button"
            isFullWidth={true}
            isHidden={!isHiddenBtnBack}
            onClick={goBack}
            text={TEXT_T072}
            type="ghost"
          />
        </StyledButtonGroup>
      </Form>
    </Layout>
  )
}
