import { useHistory, useLocation } from "react-router-dom"
import { useApi } from "../useApi"
import { useTableConfig } from "../useTableConfig"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { useRowSelection } from "../useRowSelection"
import { useHandle } from "../../../../../settings/containers/files/list/modules/useHandle"
import { FilesType } from "../../../../../../../utils/downloadFiles/models"
import { ConstantManual } from "../../../../constants/manual"

const { BREADCRUMB_ITEMS } = ConstantManual

export const useVariables = () => {
  const { push } = useHistory()
  const { pathname } = useLocation()
  const { isNotAccessScreen, isRoleUser, isVisibleBtn } =
    useVariablesAuthority()
  // APIを呼び出し、APIからの応答結果を「戻り国情報」変数に設定する。
  const { columns } = useTableConfig()
  const { breadcrumbItems, layoutTitle, type } = BREADCRUMB_ITEMS[pathname]

  const { fileInfoList, getFiles, initDateTime, isLoadingLayout } = useApi()

  const {
    isDisabledBtnDownload,
    isDisabledBtnPreview,
    onChangePagination,
    onChangeTable,
    perItem,
    rowSelection,
    selectedRowKeys,
  } = useRowSelection(fileInfoList)
  const _fileInfoList: FilesType[] = fileInfoList.map((item) => ({
    fileName: item.fileName,
    filePath: item.filePath,
    fileTypeName: item.fileTypeName,
    id: item.uploadId,
  }))
  const {
    handleDownload,
    handlePreviewFile,
    isLoading,
    isOpenPreviewFile,
    onCancelPreviewFile,
  } = useHandle({
    fileInfoList: _fileInfoList,
    fileName: layoutTitle,
    initDateTime,
    selectedRowKeys,
  })

  return {
    breadcrumbItems,
    columns,
    fileInfoList,
    getFiles,
    handleDownload,
    handlePreviewFile,
    isDisabledBtnDownload,
    isDisabledBtnPreview,
    isLoading,
    isLoadingLayout,
    isNotAccessScreen,
    isOpenPreviewFile,
    isRoleUser,
    isVisibleBtn,
    layoutTitle,
    onCancelPreviewFile,
    onChangePagination,
    onChangeTable,
    pathname,
    perItem,
    push,
    rowSelection,
    type,
  }
}
