import styled from "styled-components"
import { Regex } from "../../../../../constant/Regex"
import { Colors } from "../../../../../constant/Styled/Colors"
import { Fonts } from "../../../../../constant/Styled/Fonts"

const { REGEX_IS_DESKTOP } = Regex
const { FOOTER } = Colors
const { FS_LARGE, FS_X_SMALL } = Fonts

const StyledFooter = styled.footer`
  align-items: center;
  background-color: ${FOOTER};
  display: flex;
  font-size: ${REGEX_IS_DESKTOP ? FS_LARGE : FS_X_SMALL};
  height: 50px;
  justify-content: center;
  min-width: ${REGEX_IS_DESKTOP && "1200px"};
  text-align: center;
`

export const Styled = {
  StyledFooter,
}
