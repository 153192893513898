/* eslint-disable max-statements */
/* eslint-disable max-lines */
import { useState } from "react"
import { useLocation } from "react-router-dom"
import {
  TravelersListDataType,
  TravelersListPostType,
  TravelersSearchConditionType,
} from "../../../../models/list"
import { useExportFile } from "../useExportFile"
import { useTravelersRouters } from "../useRouters"
import { useRowSelection } from "../useRowSelection"
import { useTableConfig } from "../useTableConfig"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { getBreadcrumbItems } from "./getBreadcrumbItems"
import { TravelersListDataSourceType } from "../../../../models/list/data"
import { useVariablesModal } from "../useVariablesModal"
import { Form } from "antd"
import { OptionItemType } from "../../../../../../../_webui/layout/components/Select/models"

export const useVariables = () => {
  const { pathname, state } = useLocation<TravelersSearchConditionType>()
  const [form] = Form.useForm()

  const {
    isHiddenBtnDelete,
    isHiddenBtnExportFile,
    isHiddenBtnRefer,
    isHiddenBtnRegister,
    isHiddenBtnRequestDelete,
    isHiddenBtnSearch,
    isNotAccessScreen,
    isPathnameTravelers,
    isPathnameTravelersUser,
    isPathnameTravelersUserSearch,
  } = useVariablesAuthority()
  const { columns } = useTableConfig()
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [travelerInfo, setTravelerInfo] = useState<
    TravelersListDataSourceType[]
  >([])
  const [dataTemp, setDataTemp] = useState<TravelersListDataSourceType[]>([])
  const [dataSourceExport, setDataSourceExport] = useState<
    TravelersListDataType[]
  >([])
  const [searchCondition, setSearchCondition] = useState<TravelersListPostType>(
    { ...state?.searchCondition },
  )
  const [departments, setDepartments] = useState<OptionItemType[]>([])
  const [overRecordLabel, setOverRecordLabel] = useState("")
  const {
    dataSourceSelected,
    isDisabledBtnDelete,
    isDisabledBtnRefer,
    isHiddenBtnScheduleDraft,
    isHiddenBtnTravelerDraft,
    onChange,
    onChangeTable,
    perItem,
    rowSelection,
    selectedRowKeys,
    travelerId,
  } = useRowSelection(travelerInfo)
  const {
    handleExportFile,
    isLoading,
    isOpen,
    onCancel,
    onOpen,
    push,
    setIsLoading,
  } = useExportFile({ dataSourceExport, selectedRowKeys })
  const {
    goToSchedulesAdd,
    goToSchedulesAddDraft,
    goToTravelersDelete,
    goToTravelersDetail,
    goToTravelersEditDraft,
    goToTravelersSearch,
  } = useTravelersRouters({
    dataSourceSelected,
    pathname,
    searchCondition,
    state,
    travelerId,
  })
  const breadcrumbItems = getBreadcrumbItems(pathname)
  const {
    isOpenLoadData,
    isOpenShowData,
    onCancelLoadData,
    onCancelShowData,
    onOpenLoadData,
    onOpenShowData,
  } = useVariablesModal()

  return {
    breadcrumbItems,
    columns,
    dataTemp,
    departments,
    form,
    goToSchedulesAdd,
    goToSchedulesAddDraft,
    goToTravelersDelete,
    goToTravelersDetail,
    goToTravelersEditDraft,
    goToTravelersSearch,
    handleExportFile,
    isDisabledBtnDelete,
    isDisabledBtnRefer,
    isHiddenBtnDelete,
    isHiddenBtnExportFile,
    isHiddenBtnRefer,
    isHiddenBtnRegister,
    isHiddenBtnRequestDelete,
    isHiddenBtnScheduleDraft,
    isHiddenBtnSearch,
    isHiddenBtnTravelerDraft,
    isLoading,
    isLoadingLayout,
    isNotAccessScreen,
    isOpen,
    isOpenLoadData,
    isOpenShowData,
    isPathnameTravelers,
    isPathnameTravelersUser,
    isPathnameTravelersUserSearch,
    onCancel,
    onCancelLoadData,
    onCancelShowData,
    onChange,
    onChangeTable,
    onOpen,
    onOpenLoadData,
    onOpenShowData,
    overRecordLabel,
    perItem,
    push,
    rowSelection,
    searchCondition,
    setDataSourceExport,
    setDataTemp,
    setDepartments,
    setIsLoading,
    setIsLoadingLayout,
    setOverRecordLabel,
    setSearchCondition,
    setTravelerInfo,
    state,
    travelerInfo,
  }
}
