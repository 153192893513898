/* eslint-disable max-lines */
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { SorterResult, TableCurrentDataSource } from "antd/lib/table/interface"
import { useEffect } from "react"
import { CheckboxAll } from "../../../../../../../_webui/layout/components/RowSelection/CheckboxAll"
import { CheckboxItem } from "../../../../../../../_webui/layout/components/RowSelection/CheckboxItem"
import { TableRowSelection } from "../../../../../../../_webui/layout/components/Table/models"
import { AnyValue } from "../../../../../../../_webui/layout/models"
import {
  SchedulesListDataType,
  SchedulesStateType,
} from "../../../../models/list"
import { useVariablesRowSelection } from "../useVariablesRowSelection"

export const useRowSelection = (
  dataSource: SchedulesListDataType[],
  isRoleSubAdmin: boolean,
) => {
  const {
    dataCurrent,
    dataSourceCurrent,
    dataSourceSelected,
    handleSetDefaultSortedInfo,
    handleSetSortedInfo,
    isCheckAll,
    isDisabledBtnCreateOrSend,
    isDisabledBtnDelete,
    isDisabledBtnRefer,
    onChangePage,
    perItem,
    perItemEnd,
    perItemStart,
    selectedRowKeys,
    setDataCurrent,
    setDataSourceCurrent,
    setDataSourceSelected,
    setIsCheckAll,
    setIsDisabledBtnCreateOrSend,
    setIsDisabledBtnDelete,
    setIsDisabledBtnRefer,
    setSelectedRowKeys,
    setTravelScheduleId,
    sortedInfo,
    travelScheduleId,
  } = useVariablesRowSelection(dataSource.length)

  const onSelectChange = () => {
    const selectedRows = dataCurrent.filter((item) =>
      selectedRowKeys.includes(item.info.travelScheduleId),
    )
    const newSelectedRows: SchedulesStateType[] = selectedRows.map((item) => ({
      dispatchEndDate: item.info.dispatchEndDate,
      dispatchStartDate: item.info.dispatchStartDate,
      dispatchTypeDetailId: item.info.dispatchTypeDetailId,
      dispatchTypeDetailName: item.info.dispatchTypeDetailName,
      emailAddress1: item.info.emailAddress1,
      emailAddress2: item.info.emailAddress2,
      kanjiName: item.info.kanjiName,
      kanjiSurname: item.info.kanjiSurname,
      romajiName: item.info.romajiName,
      romajiSurname: item.info.romajiSurname,
      travelScheduleId: item.info.travelScheduleId,
      travelerId: item.info.travelerId,
      workPlace: item.info.workPlace,
    }))
    const cantDelete = selectedRows.length
      ? isRoleSubAdmin
        ? selectedRows.find((item) => !item.info.isSubBumon)
        : false
      : true
    const cantRefer = selectedRows.length !== 1
    const idSelected = selectedRows.length
      ? selectedRows[0].info.travelScheduleId
      : ""
    const cantCreateOrSend = !selectedRows.length

    setDataSourceSelected(newSelectedRows)
    setIsDisabledBtnCreateOrSend(cantCreateOrSend)
    setIsDisabledBtnDelete(Boolean(cantDelete))
    setIsDisabledBtnRefer(cantRefer)
    setTravelScheduleId(idSelected)

    if (selectedRowKeys.length === dataCurrent.length) setIsCheckAll(true)
    else setIsCheckAll(false)
  }

  const onChangeCheckAll = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    setIsCheckAll(checked)
    if (checked) {
      const _selectedRowKeys = dataCurrent.map(
        (item) => item.info.travelScheduleId,
      )
      setSelectedRowKeys(_selectedRowKeys)
    } else {
      setSelectedRowKeys([])
    }
  }

  const onChangeCheckItem = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    const value = event.target.value
    if (checked) {
      setSelectedRowKeys([...selectedRowKeys, value])
    } else {
      const _selectedRowKeys = selectedRowKeys.filter((item) => item !== value)
      setSelectedRowKeys(_selectedRowKeys)
    }
  }

  const rowSelection: TableRowSelection<SchedulesListDataType> = {
    columnTitle: (
      <CheckboxAll
        checked={isCheckAll}
        isDisabled={!dataSource.length}
        onChange={onChangeCheckAll}
      />
    ),
    renderCell: (_, record) => (
      <CheckboxItem
        checked={selectedRowKeys.includes(record.info.travelScheduleId)}
        onChange={onChangeCheckItem}
        value={record.info.travelScheduleId}
      />
    ),
    selectedRowKeys: selectedRowKeys,
  }

  const onChange = (page: number, pageSize: number) => {
    onChangePage(page, pageSize)
    setIsCheckAll(false)
    setSelectedRowKeys([])
    setIsDisabledBtnDelete(true)
    setIsDisabledBtnRefer(true)
  }

  const onChangeTable = (
    extra: TableCurrentDataSource<SchedulesListDataType>,
    sorter: SorterResult<AnyValue> | SorterResult<AnyValue>[],
  ) => {
    handleSetSortedInfo(sorter)

    setDataSourceCurrent(extra.currentDataSource)

    setIsCheckAll(false)
    setSelectedRowKeys([])
  }

  useEffect(() => {
    if (dataSourceCurrent?.length) {
      const _dataCurrent = dataSourceCurrent.slice(perItemStart, perItemEnd)
      setDataCurrent(_dataCurrent)
    }
  }, [dataSourceCurrent, setDataCurrent, perItemStart, perItemEnd])

  useEffect(() => {
    if (dataSourceCurrent?.length) onSelectChange()
  }, [selectedRowKeys, dataCurrent])

  useEffect(() => {
    if (dataSource?.length) setDataSourceCurrent(dataSource)
  }, [dataSource])

  return {
    dataSourceSelected,
    handleSetDefaultSortedInfo,
    isDisabledBtnCreateOrSend,
    isDisabledBtnDelete,
    isDisabledBtnRefer,
    onChange,
    onChangeTable,
    perItem,
    rowSelection,
    selectedRowKeys,
    sortedInfo,
    travelScheduleId,
  }
}
