import styled, { css } from "styled-components"
import { StyledShowCountProps } from "../models"

const StyledSuffix = styled.span<StyledShowCountProps>`
  ${(props) =>
    props.isFocus &&
    css`
      height: initial;
      visibility: visible;
      transform: translateY(0);
      opacity: 1;
    `};
`

export const Styled = {
  StyledSuffix,
}
