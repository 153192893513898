import { validatorRequiredField } from "../../utils/validator/requiredField"
import { validatorRomajiName } from "../../utils/validator/romajiName"
import { ItemInputProps } from "../../_webui/layout/components/Form/Item/Input/models"
import { Translate } from "../Translate"

const { TEXT_T004, TEXT_T005, TEXT_T007, TEXT_T008 } = Translate

const _KANJI_SURNAME: ItemInputProps = { label: TEXT_T004, maxLength: 50 }
const _KANJI_GIVEN_NAME: ItemInputProps = { label: TEXT_T005, maxLength: 50 }
const _ROMAJI_SURNAME: ItemInputProps = {
  label: TEXT_T007,
  maxLength: 50,
  rules: [validatorRomajiName],
}
const _ROMAJI_GIVEN_NAME: ItemInputProps = {
  label: TEXT_T008,
  maxLength: 50,
  rules: [validatorRomajiName],
}
const _ROMAJI_REQUIRED: ItemInputProps = {
  required: true,
  rules: [validatorRequiredField, validatorRomajiName],
}

const KANJI_SURNAME: ItemInputProps = {
  ..._KANJI_SURNAME,
  name: "kanjiSurname",
}
const KANJI_GIVEN_NAME: ItemInputProps = {
  ..._KANJI_GIVEN_NAME,
  name: "kanjiName",
}
const KANJI_FULL_NAME: ItemInputProps[] = [KANJI_SURNAME, KANJI_GIVEN_NAME]

const KANJI_PASSPORT_SURNAME: ItemInputProps = {
  ..._KANJI_SURNAME,
  name: "kanjiPassportSurname",
}
const KANJI_PASSPORT_GIVEN_NAME: ItemInputProps = {
  ..._KANJI_GIVEN_NAME,
  name: "kanjiPassportName",
}
const KANJI_PASSPORT_FULL_NAME: ItemInputProps[] = [
  KANJI_PASSPORT_SURNAME,
  KANJI_PASSPORT_GIVEN_NAME,
]

const ROMAJI_SURNAME: ItemInputProps = {
  ..._ROMAJI_SURNAME,
  name: "romajiSurname",
}
const ROMAJI_GIVEN_NAME: ItemInputProps = {
  ..._ROMAJI_GIVEN_NAME,
  name: "romajiName",
}
const ROMAJI_FULL_NAME: ItemInputProps[] = [ROMAJI_SURNAME, ROMAJI_GIVEN_NAME]
const ROMAJI_FULL_NAME_REQUIRED: ItemInputProps[] = [
  { ...ROMAJI_SURNAME, ..._ROMAJI_REQUIRED },
  { ...ROMAJI_GIVEN_NAME, ..._ROMAJI_REQUIRED },
]

const ROMAJI_PASSPORT_SURNAME: ItemInputProps = {
  ..._ROMAJI_SURNAME,
  name: "romajiPassportSurname",
}
const ROMAJI_PASSPORT_GIVEN_NAME: ItemInputProps = {
  ..._ROMAJI_GIVEN_NAME,
  name: "romajiPassportName",
}
const ROMAJI_PASSPORT_FULL_NAME: ItemInputProps[] = [
  ROMAJI_PASSPORT_SURNAME,
  ROMAJI_PASSPORT_GIVEN_NAME,
]
const ROMAJI_PASSPORT_FULL_NAME_REQUIRED: ItemInputProps[] = [
  { ...ROMAJI_PASSPORT_SURNAME, ..._ROMAJI_REQUIRED },
  { ...ROMAJI_PASSPORT_GIVEN_NAME, ..._ROMAJI_REQUIRED },
]

const ROMAJI_FAMILY_FULL_NAME: ItemInputProps[] = [
  {
    ..._ROMAJI_SURNAME,
    name: "familyRomajiSurname",
  },
  {
    ..._ROMAJI_GIVEN_NAME,
    name: "familyRomajiName",
  },
]

export const InputItems = {
  KANJI_FULL_NAME,
  KANJI_PASSPORT_FULL_NAME,
  ROMAJI_FAMILY_FULL_NAME,
  ROMAJI_FULL_NAME,
  ROMAJI_FULL_NAME_REQUIRED,
  ROMAJI_PASSPORT_FULL_NAME,
  ROMAJI_PASSPORT_FULL_NAME_REQUIRED,
}
