import styled, { css } from "styled-components"
import { Button } from "../../../Button"
import { StyledButtonProps } from "../../../Button/models"

const StyledButton = styled(Button)<StyledButtonProps>`
  ${(props) =>
    props.isSmall &&
    css`
      min-width: initial;
      .ant-btn-addon-before {
        margin-right: 0;
        position: initial;
      }
    `}
`

export const Styled = {
  StyledButton,
}
