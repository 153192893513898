import { concat } from "../../../../../../../../../utils/concat"
import { getDate } from "../../../../../../../../../utils/getDate"
import { ScheduleInfoDetailsType } from "../../../../../../models/add"
import { SchedulesAddDetailsAddType } from "../../../../../../models/add/DataAdd"

export const getDetailDeleteData = (
  detailDeleteData: ScheduleInfoDetailsType[],
  id: string,
): SchedulesAddDetailsAddType[] =>
  detailDeleteData.map((item) => ({
    arrivalCityCode: item.arrivalCityCode,
    arrivalCityName: item.arrivalCityName,
    arrivalCountryCode: item.arrivalCountryCode,
    arrivalDateTime: getDate(item.arrivalDateTime),
    departureCityCode: item.departureCityCode,
    departureCityName: item.departureCityName,
    departureCountryCode: item.departureCountryCode,
    departureDateTime: getDate(item.departureDateTime),
    departureFlightNumber: item.departureFlightNumber,
    index: item.index,
    isTransit: item.isTransit,
    othersRemarks1: item.othersRemarks1,
    othersRemarks2: item.othersRemarks2,
    othersRemarks3: item.othersRemarks3,
    othersRemarks4: item.othersRemarks4,
    othersRemarks5: item.othersRemarks5,
    transportation: item.transportation,
    travelDetailId: item.travelDetailId,
    travelScheduleId: concat(id),
    type: "Delete",
  }))
