import { Checkbox, Form } from "antd"
import { FC } from "react"
import { Image } from "../../../../../../../_webui/layout/components/Image"
import { RolesCheckboxGroupProps } from "./models"
import { Images } from "../../../../../../../constant/Images"
import { Styled } from "../styled"
import { Regex } from "../../../../../../../constant/Regex"

const { Group } = Checkbox
const { Item } = Form
const { IMAGE_LIST_CHECK } = Images
const { REGEX_IS_DESKTOP } = Regex
const { StyledItem, StyledItemCheckbox, StyledItemCheckboxLast, StyledRow } =
  Styled

export const RolesCheckboxGroup: FC<RolesCheckboxGroupProps> = (props) => {
  const { isSuccess, name, options, title } = props

  return (
    <StyledRow>
      <StyledItem>{title}</StyledItem>
      <Item name={name}>
        <Group>
          {!!options.length &&
            options.map((item, index) => (
              <StyledItemCheckbox key={index}>
                {item.value &&
                  (isSuccess ? (
                    <Image src={IMAGE_LIST_CHECK} alt={item.label} />
                  ) : (
                    <Checkbox id={item.value} value={item.value}>
                      <label className="hidden-label" htmlFor={item.value}>
                        {item.label}
                      </label>
                    </Checkbox>
                  ))}
              </StyledItemCheckbox>
            ))}
          {REGEX_IS_DESKTOP && (
            <StyledItemCheckboxLast></StyledItemCheckboxLast>
          )}
        </Group>
      </Item>
    </StyledRow>
  )
}
