import { FC, Fragment } from "react"
import { ItemInput } from "../../../../../../../../_webui/layout/components/Form/Item/Input"
import { Translate } from "../../../../../../../../constant/Translate"

const { TEXT_T078, TEXT_T079, TEXT_T080, TEXT_T081, TEXT_T082 } = Translate

export const SchedulesAddOther: FC = () => (
  <Fragment>
    <ItemInput label={TEXT_T078} maxLength={200} name="managerRemarks1" />

    <ItemInput label={TEXT_T079} maxLength={200} name="managerRemarks2" />

    <ItemInput label={TEXT_T080} maxLength={200} name="managerRemarks3" />

    <ItemInput label={TEXT_T081} maxLength={200} name="managerRemarks4" />

    <ItemInput label={TEXT_T082} maxLength={200} name="managerRemarks5" />
  </Fragment>
)
