import styled from "styled-components"
import { Colors } from "../../../../../../constant/Styled/Colors"
import { Fonts } from "../../../../../../constant/Styled/Fonts"
import { Button } from "../../../../../../_webui/layout/components/Button"

const { DANGER } = Colors
const { FS_LARGE, FW_BOLD } = Fonts

const StyledContainer = styled.div`
  margin: 50px auto 0;
  max-width: 400px;
`
const StyledValid = styled.div`
  color: ${DANGER};
  font-size: ${FS_LARGE};
  font-weight: ${FW_BOLD};
  margin: 0 auto;
  max-width: 600px;
`
const StyledButtonGroup = styled.div`
  margin: 0 auto;
  max-width: 400px;
`
const StyledButton = styled(Button)`
  margin-top: 40px;
  width: 100%;
`

export const Styled = {
  StyledButton,
  StyledButtonGroup,
  StyledContainer,
  StyledValid,
}
