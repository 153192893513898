import { ListItemType } from "../../../../../../../../_webui/layout/components/List/models"
import { AnyValue } from "../../../../../../../../_webui/layout/models"
import { GeneralMastersResType } from "../../../../../../../../infrastructure/handlers/generalMasters/models"
import { getValueByLanguage } from "../../../../../../../../utils/getValueByLanguage"

export const convertDataGeneralMasters = (
  data: GeneralMastersResType[],
): ListItemType[] =>
  Object.values(
    data.reduce((prevValue: AnyValue, currentValue) => {
      if (!currentValue.key2) {
        prevValue[currentValue.key1] = {
          content: [],
          title: getValueByLanguage(currentValue.value1, currentValue.value2),
        }
      } else {
        prevValue[currentValue.key2].content = [
          ...prevValue[currentValue.key2].content,
          getValueByLanguage(currentValue.value1, currentValue.value2),
        ]
      }
      return prevValue
    }, {}),
  )
