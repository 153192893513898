import { Breadcrumbs } from "../../../../../../../../constant/Breadcrumbs"

const {
  BRCR_SCR960,
  BRCR_SCR960_FROM_SCR212,
  BRCR_SCR960_FROM_SCR212_USER,
  BRCR_SCR960_FROM_SCR210_USER_SEARCH,
} = Breadcrumbs

export const getBreadcrumbItems = (
  isFromSCR114UserSearch?: boolean,
  isFromSCR212?: boolean,
  isFromSCR212User?: boolean,
) => {
  if (isFromSCR212) return
  BRCR_SCR960_FROM_SCR212

  if (isFromSCR212User)
    return isFromSCR114UserSearch
      ? BRCR_SCR960_FROM_SCR210_USER_SEARCH
      : BRCR_SCR960_FROM_SCR212_USER

  return BRCR_SCR960
}
