import { FC } from "react"
import { BreadcrumbsProps } from "./models"
import { Regex } from "../../../../constant/Regex"
import { Styled } from "./styled"

const { REGEX_IS_DESKTOP } = Regex
const { StyledBreadcrumbs, StyledBreadcrumbsItem } = Styled

export const Breadcrumbs: FC<BreadcrumbsProps> = (props) => {
  const { breadcrumbItems } = props

  return REGEX_IS_DESKTOP && breadcrumbItems?.length ? (
    <StyledBreadcrumbs id="breadcrumbs" tabIndex={0}>
      {breadcrumbItems.map((item, index) => (
        <StyledBreadcrumbsItem key={`${item}_${index}`}>
          {item}
        </StyledBreadcrumbsItem>
      ))}
    </StyledBreadcrumbs>
  ) : null
}
