/* eslint-disable max-statements */
import { useHistory, useLocation } from "react-router-dom"
import { useVariablesAuthority } from "../useVariablesAuthority"
import {
  SubAdminFormSearchConditionType,
  SubAdminListType,
  UseSubAdminStateType,
} from "../../../../../models/subAdmin/list"
import { useState } from "react"
import { useRowSelection } from "../useRowSelection"
import { InitialValues } from "../../../../../constants/subAdmin/list/initialValues"
import { PatternId } from "../../../../../../../../constant/PatternId"
import { getUserInformation } from "../../../../../../../../utils/getUserInformation"
import { Form } from "antd"
import { OptionItemType } from "../../../../../../../../_webui/layout/components/Select/models"

const { SEARCH_CONDITION_INITIAL_VALUES } = InitialValues
const { PATTERN_ADMIN, PATTERN_USER, PATTERN_USER_1 } = PatternId

export const useVariablesApi = () => {
  const [form] = Form.useForm()
  const { state } = useLocation<UseSubAdminStateType>()
  const { push } = useHistory()
  const {
    isHiddenBtnReset,
    isHiddenBtnRoleSetting,
    isHiddenBtnSearch,
    isHiddenBtnSubAdminSetting,
    isNotAccessScreen,
    isRoleSubAdmin,
  } = useVariablesAuthority(state?.sourceURL)
  const userInformation = getUserInformation()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const patternIds = [PATTERN_ADMIN, PATTERN_USER, PATTERN_USER_1]
  const [searchCondition, setSearchCondition] =
    useState<SubAdminFormSearchConditionType>(
      state?.searchCondition
        ? {
            ...state.searchCondition,
            patternId: !patternIds.includes(
              String(state.searchCondition.patternId),
            )
              ? state.searchCondition.patternId
              : "",
          }
        : SEARCH_CONDITION_INITIAL_VALUES,
    )
  const [authorityPatternSettings, setAuthorityPatternSettings] = useState<
    SubAdminListType[]
  >([])
  const [authorityPatterns, setAuthorityPatterns] = useState<OptionItemType[]>(
    [],
  )
  const [departments, setDepartments] = useState<OptionItemType[]>([])

  const { onChange, onChangeTable, perItem, rowSelection, selectedRowKeys } =
    useRowSelection(authorityPatternSettings, isRoleSubAdmin)

  return {
    authorityPatternSettings,
    authorityPatterns,
    bumonCd: userInformation?.bumonCd,
    departments,
    form,
    isHiddenBtnReset,
    isHiddenBtnRoleSetting,
    isHiddenBtnSearch,
    isHiddenBtnSubAdminSetting,
    isLoading,
    isLoadingLayout,
    isNotAccessScreen,
    isRoleSubAdmin,
    onChange,
    onChangeTable,
    perItem,
    push,
    rowSelection,
    searchCondition,
    selectedRowKeys,
    setAuthorityPatternSettings,
    setAuthorityPatterns,
    setDepartments,
    setIsLoading,
    setIsLoadingLayout,
    setSearchCondition,
    state,
  }
}
