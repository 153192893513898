import { Images } from "../../../../../../constant/Images"
import { Translate } from "../../../../../../constant/Translate"
import { ButtonGroup } from "../../../../../../_webui/layout/components/Button/Group"
import { Image } from "../../../../../../_webui/layout/components/Image"
import { FC } from "react"
import { TravelersEditButtonGroupProps } from "./models"

const { TEXT_T086, TEXT_T087 } = Translate

const { IMAGE_BTN_ADDITION, IMAGE_BTN_DELETE } = Images

export const TravelersEditButtonGroup: FC<TravelersEditButtonGroupProps> = (
  props,
) => {
  const { addItems, isDisabledBtnDelete, removeItems } = props

  return (
    <ButtonGroup
      buttonItems={[
        {
          addonBefore: <Image src={IMAGE_BTN_ADDITION} />,
          htmlType: "button",
          onClick: addItems,
          text: TEXT_T086,
        },
        {
          addonBefore: <Image src={IMAGE_BTN_DELETE} />,
          htmlType: "button",
          isDisabled: isDisabledBtnDelete,
          onClick: () => removeItems(),
          text: TEXT_T087,
          type: "ghost",
        },
      ]}
      position="flex-start"
    />
  )
}
