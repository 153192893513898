/* eslint-disable max-lines */
import { GeneralCode } from "../../../../../../../constant/GeneralCode"
import { getGeneralMasters } from "../../../../../../../utils/apis/getGeneralMasters"
import { useApi } from "../useApi"
import { useTableConfig } from "../useTableConfig"
import { useTableErrorConfig } from "../useTableErrorConfig"
import { useVariablesAuthority } from "../useVariablesAuthority"

const { DPTD } = GeneralCode
export const useVariables = () => {
  // APIを呼び出し、マスタから「派遣種別（詳細）」のデータ一覧を取得する。
  const { generalMasters: dispatchType } = getGeneralMasters(DPTD)
  const { columns } = useTableConfig()
  const { columnErrors } = useTableErrorConfig()
  const {
    isNotAccessScreen,
    isVisibleBtn,
    isVisibleBtnRoleAdmin,
    isVisibleBtnRoleSubAdmin,
    isVisibleTabRoleAdmin,
    isVisibleTabRoleSubAdmin,
  } = useVariablesAuthority()
  const {
    activeKey,
    dataGridItems,
    dataSourceLength,
    executeDetail,
    handleGetBrowseTravelers,
    isDisabledButton,
    isLoading,
    isLoadingLayout,
    isOpenAccept,
    isOpenReActive,
    isOpenReEnable,
    isOpenRequestCancel,
    isResetTable,
    isShowTableError,
    onCancelAccept,
    onCancelReActive,
    onCancelReEnable,
    onCancelRequestCancel,
    onChange,
    onChangeTable,
    onClickSearchSubmit,
    onOkAccept,
    onOkReActive,
    onOkReEnable,
    onOkRequestDisable,
    onOpenAccept,
    onOpenReActive,
    onOpenReEnable,
    onOpenRequestCancel,
    onTabClick,
    perItem,
    push,
    rowSelection,
    selectedRowKeys,
    tableErrorDataSource,
  } = useApi()

  return {
    activeKey,
    columnErrors,
    columns,
    dataGridItems,
    dataSourceLength,
    dispatchType,
    executeDetail,
    handleGetBrowseTravelers,
    isDisabledButton,
    isLoading,
    isLoadingLayout,
    isNotAccessScreen,
    isOpenAccept,
    isOpenReActive,
    isOpenReEnable,
    isOpenRequestCancel,
    isResetTable,
    isShowTableError,
    isVisibleBtn,
    isVisibleBtnRoleAdmin,
    isVisibleBtnRoleSubAdmin,
    isVisibleTabRoleAdmin,
    isVisibleTabRoleSubAdmin,
    onCancelAccept,
    onCancelReActive,
    onCancelReEnable,
    onCancelRequestCancel,
    onChange,
    onChangeTable,
    onClickSearchSubmit,
    onOkAccept,
    onOkReActive,
    onOkReEnable,
    onOkRequestDisable,
    onOpenAccept,
    onOpenReActive,
    onOpenReEnable,
    onOpenRequestCancel,
    onTabClick,
    perItem,
    push,
    rowSelection,
    selectedRowKeys,
    tableErrorDataSource,
  }
}
