import "./style/index.scss"
import { FC } from "react"
import { Dropdown as DropdownAnt } from "antd"
import { DropdownProps } from "./models"

export const Dropdown: FC<DropdownProps> = (props) => {
  const { ariaLabel, className, content, menu, ...rest } = props
  return (
    <DropdownAnt
      dropdownRender={() => (
        <ul className="ant-dropdown-menu" tabIndex={0}>
          {menu.items?.map((item) => (
            <li
              className="ant-dropdown-menu-item"
              key={item?.key}
              onClick={item.onClick}
            >
              <span className="ant-dropdown-menu-title-content">
                {item.label}
              </span>
            </li>
          ))}
        </ul>
      )}
      menu={menu}
      trigger={["click"]}
      {...rest}
    >
      <button
        aria-label={ariaLabel}
        className={className}
        onClick={(event) => event.preventDefault()}
        type="button"
      >
        {content}
      </button>
    </DropdownAnt>
  )
}
