import { createGlobalStyle, css } from "styled-components"
import { Regex } from "../../../../../../constant/Regex"

const { REGEX_IS_DESKTOP } = Regex

export const GlobalStyleFormItem = createGlobalStyle`
${
  REGEX_IS_DESKTOP
    ? css`
        .ant {
          &-form {
            &-horizontal {
              .ant {
                &-form {
                  &-item {
                    &-group {
                      .ant-form-item-label {
                        margin-top: 22px;
                      }
                    }
                    &-checkbox,
                    &-radio {
                      .ant-form-item-control {
                        transform: translateY(8px);
                      }
                    }
                  }
                }
              }
            }
            &-inline {
              display: flex;
              flex-wrap: wrap;
              .ant-form-item {
                &:not(:first-child) {
                  margin-left: 32px;
                }
              }
            }
          }
        }
      `
    : css`
        .ant {
          &-form {
            &-vertical {
              .ant {
                &-form {
                  &-item {
                    &-radio,
                    &-checkbox {
                      .ant-form-item-control-input {
                        height: initial;
                        line-height: initial;
                      }
                    }
                    .ant {
                      &-input {
                        &-group {
                          display: block;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `
}
`
