import { FC, Fragment } from "react"
import { Alert } from "../../../../../../_webui/layout/components/Alert"
import { GeneralCode } from "../../../../../../constant/GeneralCode"
import { Translate } from "../../../../../../constant/Translate"
import { getDateTimeZoneByUserInformation } from "../../../../../../utils/getDate"
import { AttentionsDetailAlertProps } from "../../../models/detail"
import { Styled } from "./styled"
import { getValueByLanguage } from "../../../../../../utils/getValueByLanguage"

const { TEXT_T338, TEXT_T328, TEXT_T330 } = Translate
const { CTGR_OTHER } = GeneralCode
const { StyledAttentionsLabel } = Styled

export const AttentionsDetailAlert: FC<AttentionsDetailAlertProps> = (
  props,
) => {
  const { attentionInfo, severity } = props
  const separator = getValueByLanguage("", " ")

  return (
    <Alert
      content={attentionInfo?.bodyContent}
      heading={
        <Fragment>
          <StyledAttentionsLabel>
            {TEXT_T338}
            {separator}
            <b>
              {getDateTimeZoneByUserInformation(
                attentionInfo?.implementationDateTime,
              )}
            </b>
          </StyledAttentionsLabel>
          <StyledAttentionsLabel>
            {TEXT_T328}
            {separator}
            <b>
              {attentionInfo?.categoryId === CTGR_OTHER
                ? getValueByLanguage(
                    `${attentionInfo.categoryName}＿${attentionInfo.categoryValue}`,
                    `${attentionInfo.categoryName}_${attentionInfo.categoryValue}`,
                  )
                : attentionInfo?.categoryName}
            </b>
          </StyledAttentionsLabel>
          <span>
            {TEXT_T330}
            {separator}
            <b>{severity}</b>
          </span>
        </Fragment>
      }
      isIcon={false}
      title={attentionInfo?.attentionTitle}
      type="info"
    />
  )
}
