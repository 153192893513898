/* eslint-disable max-lines */
import { Routers } from "../../../../../../../constant/Routers"
import {
  SchedulesCreateOrSendType,
  UseSchedulesRoutersType,
  UseSchedulesRowSelectionProps,
} from "../../../../models/list"
import { useHistory, useLocation } from "react-router-dom"
import { replaceString } from "../../../../../../../utils/replaceString"
import { convertDataSelected } from "./convertDataSelected"

const {
  SCR114_USER_SEARCH,
  SCR210,
  SCR211,
  SCR216,
  SCR212_USER,
  SCR214,
  SCR214_USER,
  SCR312,
  SCR412,
} = Routers

export const useRouters = (
  props: UseSchedulesRowSelectionProps,
): UseSchedulesRoutersType => {
  const {
    dataSourceSelected,
    isFromSCR114UserSearch,
    isSearchBasic,
    searchCondition,
    searchConditionTraveler,
    travelerId: _travelerId,
    travelScheduleId,
  } = props
  const { push } = useHistory()
  const { state, pathname } = useLocation<SchedulesCreateOrSendType>()
  const travelerId = dataSourceSelected[0]?.travelerId

  const goToSchedulesAdd = () => {
    // 「SCR212_渡航予定情報の登録画面」に遷移する。
    push({
      pathname: replaceString(SCR212_USER, _travelerId),
      state: {
        isFromSCR114UserSearch: true,
        searchConditionTraveler,
        travelerId: _travelerId,
      },
    })
  }

  const goBack = () => {
    push({
      pathname: SCR114_USER_SEARCH,
      state: {
        isFromSCR210Travelers: true,
        searchCondition: searchConditionTraveler,
      },
    })
  }

  const goToAttention = () => {
    const travelerInfoList = convertDataSelected(dataSourceSelected)
    // 「SCR312_注意喚起の内容確認画面」に遷移する。
    push({
      pathname: SCR312,
      state: {
        isSearchBasic: isSearchBasic,
        searchCondition,
        sourceURL: pathname,
        transmissionInfo: state?.transmissionInfo,
        travelerInfoList: travelerInfoList,
      },
    })
  }

  const goToSafety = () => {
    const travelerInfoList = convertDataSelected(dataSourceSelected)
    //「SCR412_安否確認の内容確認画面」に遷移する。
    push({
      pathname: SCR412,
      state: {
        isSearchBasic: isSearchBasic,
        searchCondition,
        sourceURL: pathname,
        transmissionInfo: state?.transmissionInfo,
        travelerInfoList: travelerInfoList,
      },
    })
  }

  const goToSchedulesEdit = () => {
    // 「SCR214_渡航予定情報の変更画面」に遷移する。
    const _pathname = pathname === SCR210 ? SCR214 : SCR214_USER
    push({
      pathname: replaceString(_pathname, travelScheduleId),
      state: {
        isFromSCR210: true,
        isSearchBasic: isSearchBasic,
        schedulesURL: pathname,
        searchCondition,
        sourceURL: pathname,
        travelerId,
      },
    })
  }

  const goToSchedulesConfirmDelete = () => {
    // 「SCR216_渡航予定情報の削除確認画面」に遷移する。
    push({
      pathname: SCR216,
      state: {
        isFromSCR210: true,
        isSearchBasic: isSearchBasic,
        scheduleInfo: dataSourceSelected,
        schedulesURL: pathname,
        searchCondition,
        sourceURL: pathname,
      },
    })
  }

  const goToSchedulesDetail = () => {
    const state = isFromSCR114UserSearch
      ? {
          isFromSCR114UserSearch: true,
          searchConditionTraveler,
          travelerId,
        }
      : {
          isSearchBasic: isSearchBasic,
          searchCondition,
        }
    // 「SCR211_渡航予定情報の参照画面」に遷移する。
    push({
      pathname: replaceString(SCR211, travelScheduleId),
      state: {
        ...state,
        isFromSCR210: true,
        schedulesURL: pathname,
        sourceURL: pathname,
      },
    })
  }

  return {
    goBack,
    goToAttention,
    goToSafety,
    goToSchedulesAdd,
    goToSchedulesConfirmDelete,
    goToSchedulesDetail,
    goToSchedulesEdit,
  }
}
