/* eslint-disable max-lines */
import moment, { Moment } from "moment"
import "moment/locale/en-gb"
import "moment/locale/ja"
import { Timezone } from "../../constant/Timezone"
import { concat } from "../concat"
import { getUserInformation } from "../getUserInformation"
import { getValueByLanguage } from "../getValueByLanguage"

const {
  DATE_FORMAT,
  DATE_FORMAT_EN,
  DATE_FORMAT_EXPORT_EN,
  DATE_FORMAT_EXPORT_JP,
  DATE_FORMAT_JP,
  DATE_TIME_FORMAT_DEFAULT_EN,
  DATE_TIME_FORMAT_DEFAULT_JP,
  DATE_TIME_FORMAT_EN,
  DATE_TIME_FORMAT_JP,
  TIME_FORMAT,
} = Timezone
const format = `${DATE_FORMAT} ${TIME_FORMAT}`
const locale = getValueByLanguage("ja", "en-gb").toString()
export const dateTimeDefaultFormat = getValueByLanguage(
  DATE_TIME_FORMAT_DEFAULT_JP,
  DATE_TIME_FORMAT_DEFAULT_EN,
).toString()

export const getDate = (date: string, time?: string) =>
  date &&
  (time
    ? `${moment(date).format(DATE_FORMAT)} ${time}`
    : moment(date).format(format))

export const getDateRange = (
  date?: Moment | null,
  isFormatDefault?: boolean,
) => {
  const formatDate = isFormatDefault ? DATE_FORMAT : format
  return date ? date.format(formatDate) : ""
}

export const getDay = (day?: string, isTimezone?: boolean) => {
  const format = getValueByLanguage(DATE_FORMAT_JP, DATE_FORMAT_EN).toString()
  return day
    ? isTimezone
      ? moment(day).format(format)
      : moment(day).utc().format(format)
    : ""
}

export const getDayWithFormat = (
  format: string,
  day?: string,
  isTimezone?: boolean,
) => {
  return day
    ? isTimezone
      ? moment(day).format(format)
      : moment(day).utc().format(format)
    : ""
}

export const getDateTime = (day?: string) => {
  const format = getValueByLanguage(
    DATE_TIME_FORMAT_JP,
    DATE_TIME_FORMAT_EN,
  ).toString()
  return day ? moment(day).utc().format(format) : ""
}

export const getDateTimeNow = () => {
  const format = getValueByLanguage(
    DATE_TIME_FORMAT_JP,
    DATE_TIME_FORMAT_EN,
  ).toString()
  const currentDate = moment().toString()
  const timeZoneValue = getTimeZoneValue(currentDate)
  return moment(convertTimezone(currentDate, +concat(timeZoneValue))).format(
    format,
  )
}

export const getDateTimeDefault = (day?: string) =>
  day ? moment(day).utc().format(dateTimeDefaultFormat) : ""

export const getDayByLocale = (day?: string) => {
  if (day) {
    moment.locale(locale)
    return moment(day).utc().format("LL")
  }
  return ""
}

export const getDateUtc = (date?: string) => (date ? moment(date).utc() : "")

export const getDateMoment = (date: string) => (date ? moment(date) : null)

export const convertTimezone = (date: string, timezone: number) => {
  const _date = new Date(date)
  const utc = _date.getTime() + _date.getTimezoneOffset() * 60000
  return new Date(utc + 3600000 * timezone).toLocaleString()
}

export const getTimeZoneValue = (date?: string) => {
  const userInformation = getUserInformation()
  let timeZoneValue = userInformation?.timeZoneValue
  if (userInformation?.isSummerTime) {
    userInformation?.summerTimeData.forEach((item) => {
      if (
        moment(date).isBetween(
          moment(item.validStartDate),
          moment(item.validEndDate),
        )
      ) {
        timeZoneValue = item.timezone
      }
    })
  }
  return timeZoneValue
}

export const getDateHome = (date: string) => {
  moment.locale(locale)
  const userInformation = getUserInformation()
  const timeFormat = getValueByLanguage(
    concat(userInformation?.timeFormatValueJP),
    concat(userInformation?.timeFormatValueEN),
  )
  const timeZoneValue = getTimeZoneValue(date)
  return moment(convertTimezone(date, +concat(timeZoneValue)))
    ?.format(timeFormat.toString())
    .replace("am", "AM")
    .replace("pm", "PM")
}

export const formatDateCSV = (): string => {
  const date = moment(new Date()).utc().toString()
  const timeZoneValue = getTimeZoneValue(date)
  return moment(convertTimezone(date, +concat(timeZoneValue))).format(
    "YYYYMMDDHHmmss",
  )
}

export const formatDateExport = (date?: string) => {
  const format = getValueByLanguage(
    DATE_FORMAT_EXPORT_JP,
    DATE_FORMAT_EXPORT_EN,
  ).toString()
  return date ? moment(date).utc().format(format) : ""
}

export const formatDateTimeExport = (date?: string) => {
  const format = getValueByLanguage(
    DATE_TIME_FORMAT_JP,
    DATE_TIME_FORMAT_EN,
  ).toString()
  const timeZoneValue = getTimeZoneValue(date)
  return date
    ? moment(convertTimezone(date, +concat(timeZoneValue))).format(format)
    : ""
}

export const getDateTimeDefaultExport = (date?: string) =>
  date ? moment(date).format(dateTimeDefaultFormat) : ""

export const getDayExport = (date?: string) => {
  moment.locale(locale)
  return date ? moment(date).utc().format("DD (ddd)") : ""
}

export const formatDateSaveDB = (date?: string | null, isEndDay?: boolean) =>
  date
    ? isEndDay
      ? moment(date).format(`${DATE_FORMAT} 23:59:59`).toString()
      : moment(date).format(`${DATE_FORMAT} 00:00:00`).toString()
    : ""

export const getDateTimeZoneByUserInformation = (date?: string) => {
  const timeZoneValue = getTimeZoneValue(date)
  if (!timeZoneValue) return getDateTimeDefault(date)
  return date
    ? moment(convertTimezone(date, +concat(timeZoneValue)))?.format(
        dateTimeDefaultFormat,
      )
    : ""
}

export const formatDateTimeZoneByUserInformation = (date?: string) => {
  const format = getValueByLanguage(
    DATE_TIME_FORMAT_JP,
    DATE_TIME_FORMAT_EN,
  ).toString()
  const timeZoneValue = getTimeZoneValue(date)
  if (!timeZoneValue) return getDateTimeDefault(date)
  return date
    ? moment(convertTimezone(date, +concat(timeZoneValue)))?.format(format)
    : ""
}

export const getTimestamp = (datetime: string) => moment(datetime).unix()
