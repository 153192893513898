import { FC } from "react"
import { Checkbox } from "../../Checkbox"
import { CheckboxItemProps } from "./models"

export const CheckboxItem: FC<CheckboxItemProps> = (props) => {
  const { checked, isDisabled, onChange, value } = props
  return (
    <Checkbox
      checked={checked}
      isDisabled={isDisabled}
      onChange={onChange}
      text={value}
      value={value}
    />
  )
}
