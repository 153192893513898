import { useEffect } from "react"
import { backToLogin } from "../../../../../../../../utils/backToLogin"
import { UseFormSubAdminType } from "../../../../../models/subAdmin/list"
import { useVariables } from "../useVariables"

export const useSubAdmin = (): UseFormSubAdminType => {
  const {
    authorityPatterns,
    authorityPatternSettings,
    callApi,
    columns,
    departments,
    form,
    goToRoleSetting,
    goToSubAdminSetting,
    isHiddenBtnReset,
    isHiddenBtnRoleSetting,
    isHiddenBtnSearch,
    isHiddenBtnSubAdminSetting,
    isLoading,
    isLoadingLayout,
    isNotAccessScreen,
    onChange,
    onChangeTable,
    onFinish,
    perItem,
    push,
    rowSelection,
  } = useVariables()

  useEffect(() => {
    // ユーザの画面アクセス許可チェックを処理する。
    if (isNotAccessScreen) {
      // 「SCR973_エラー情報の表示画面」に遷移する。
      const { pathname, state } = backToLogin()
      push({ pathname, state })
    } else callApi()
  }, [])

  return {
    authorityPatterns,
    columns,
    dataSource: authorityPatternSettings,
    departments,
    form,
    goToRoleSetting,
    goToSubAdminSetting,
    isHiddenBtnReset,
    isHiddenBtnRoleSetting,
    isHiddenBtnSearch,
    isHiddenBtnSubAdminSetting,
    isLoading,
    isLoadingLayout,
    onChange,
    onChangeTable,
    onFinish,
    perItem,
    rowSelection,
  }
}
