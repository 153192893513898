import { getFullName } from "../../../../../../../../utils/getFullName"
import { SchedulesConfirmDeleteDataType } from "../../../../../models/confirmDelete"
import { SchedulesStateType } from "../../../../../models/list"

export const convertDataSource = (
  data: SchedulesStateType[],
): SchedulesConfirmDeleteDataType[] =>
  data.map((item) => ({
    dispatchType: item.dispatchTypeDetailName,
    fullNameKanji: getFullName(item.kanjiSurname, item.kanjiName, true),
    fullNameRomaji: getFullName(item.romajiSurname, item.romajiName),
    key: item.travelScheduleId,
    travelEndDate: item.dispatchEndDate,
    travelScheduleId: item.travelScheduleId,
    travelStartDate: item.dispatchStartDate,
  }))
