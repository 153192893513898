import { useHistory, useLocation } from "react-router-dom"
import {
  SafetiesAnswerBackType,
  SafetiesAnswerDataDetailType,
} from "../../../../models/answer"
import { Form } from "antd"
import { useState } from "react"
import { SafetiesInfoDataType } from "../../../../models/info"

export const useVariablesApi = () => {
  const { state } = useLocation<SafetiesAnswerBackType>()
  const [form] = Form.useForm()
  const { push } = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [safetyInfo, setSafetyInfo] = useState<SafetiesInfoDataType>()
  const [safetyDetailInfo, setSafetyDetailInfo] =
    useState<SafetiesAnswerDataDetailType>()

  return {
    form,
    isLoading,
    isLoadingLayout,
    push,
    safetyDetailInfo,
    safetyInfo,
    setIsLoading,
    setIsLoadingLayout,
    setSafetyDetailInfo,
    setSafetyInfo,
    state,
  }
}
