/* eslint-disable max-lines */
import { FC, Fragment } from "react"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { ModalConfirm } from "../../../../../_webui/layout/components/Modal/Confirm"
import { Space } from "../../../../../_webui/layout/components/Space"
import { Title } from "../../../../../_webui/layout/components/Typography/Title"
import { TotalRecords } from "../../../../../_webui/layout/components/Typography/TotalRecords"
import { Translate } from "../../../../../constant/Translate"
import { AttentionsDetailAlert } from "../../components/detail/Alert"
import { AttentionsDetailButton } from "../../components/detail/Button"
import { AttentionsDetailTabs } from "../../components/detail/Tabs"
import { useAttentionsDetail } from "./modules/useDetail"
import { AttentionsDetailTable } from "../../components/detail/Table"

const {
  MESSAGE_C0027,
  MESSAGE_C0048,
  MESSAGE_C0049,
  TEXT_T337,
  TEXT_T342,
  TEXT_T609,
} = Translate

export const AttentionsDetail: FC = () => {
  const {
    attentionInfo,
    breadcrumbItems,
    columns,
    countAttentionStatus,
    goBack,
    goToAttentionsInfo,
    handleExportFile,
    isAttentionsBrowseResponse,
    isHiddenAnswerBtn,
    isHiddenBackBtn,
    isHiddenExportBtn,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenExport,
    isResetTable,
    isResponse,
    onCancelExport,
    onCancel,
    onChange,
    onOpen,
    onOpenExport,
    onTabClick,
    perItem,
    rowSelection,
    severity,
    userInfoList,
  } = useAttentionsDetail()

  return (
    <Layout
      breadcrumbItems={breadcrumbItems}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T337}
    >
      <Card heading={TEXT_T337} isLoading={isLoading}>
        <Space isSpaceBottom>
          <Title titleLeft={TEXT_T609} />
          <AttentionsDetailAlert
            attentionInfo={attentionInfo}
            severity={severity}
          />
        </Space>
        {isAttentionsBrowseResponse ? (
          <Fragment>
            <TotalRecords
              perItem={perItem}
              titleLeft={TEXT_T342}
              total={userInfoList?.length}
            />
            <AttentionsDetailTabs
              countAttentionStatus={countAttentionStatus}
              onTabClick={onTabClick}
              tableItem={
                <AttentionsDetailTable
                  columns={columns}
                  isAttentionsBrowseResponse={isAttentionsBrowseResponse}
                  isResetTable={isResetTable}
                  onChange={onChange}
                  perItem={perItem}
                  rowSelection={rowSelection}
                  userInfoList={userInfoList}
                />
              }
            />
          </Fragment>
        ) : (
          <Fragment>
            <Title titleLeft={TEXT_T342} />
            <AttentionsDetailTable
              columns={columns}
              isAttentionsBrowseResponse={isAttentionsBrowseResponse}
              isLoading={isLoading}
              onChange={onChange}
              rowSelection={rowSelection}
              userInfoList={userInfoList}
            />
          </Fragment>
        )}
        <AttentionsDetailButton
          goBack={goBack}
          isHiddenAnswerBtn={isHiddenAnswerBtn}
          isHiddenBackBtn={isHiddenBackBtn}
          isHiddenExportBtn={isHiddenExportBtn}
          isResponse={isResponse}
          onOpen={onOpen}
          onOpenExport={onOpenExport}
        />
      </Card>
      <ModalConfirm
        isOpen={isOpen}
        onCancel={onCancel}
        onOk={goToAttentionsInfo}
      >
        {MESSAGE_C0027}
      </ModalConfirm>
      <ModalConfirm
        isOpen={isOpenExport}
        onCancel={onCancelExport}
        onOk={handleExportFile}
      >
        {rowSelection?.selectedRowKeys?.length ? MESSAGE_C0048 : MESSAGE_C0049}
      </ModalConfirm>
    </Layout>
  )
}
