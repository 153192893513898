import { useEffect, useState } from "react"
import { UseTableProps } from "../../models"
import { AnyValue } from "../../../../models"

export const useTable = (props: UseTableProps<AnyValue>) => {
  const { dataSource, isResetTable, onChange } = props

  const [current, setCurrent] = useState(1)
  const [pageSize, setPageSize] = useState(25)

  const handleAddRowIndex = () => {
    setTimeout(() => {
      const elmData = document.querySelectorAll(".ant-table-tbody > tr")
      if (elmData) {
        elmData.forEach((elm, index) => {
          const row = (current - 1) * pageSize + index
          elm.setAttribute("style", `--row-index: 'Row ${row}'`)
        })
      }
    }, 500)
  }

  const onChangeCurrent = (page: number) => {
    handleAddRowIndex()
    setCurrent(page)
    const emlInput = document.querySelector(
      ".ant-table-tbody tr:nth-child(2) td .ant-checkbox-input",
    ) as HTMLInputElement
    emlInput?.focus()
  }
  const onChangePageSize = (value: number) => {
    setPageSize(value)
    setCurrent(1)
  }

  // useEffect(() => {
  //   document.querySelector(".ant-table-body")?.scroll({
  //     behavior: "smooth",
  //     left: 0,
  //     top: 0,
  //   })
  // }, [dataSource])

  useEffect(() => {
    handleAddRowIndex()
  }, [dataSource])

  useEffect(() => {
    if (typeof onChange === "function") {
      onChange(current, pageSize)
      handleAddRowIndex()
    }
  }, [current, pageSize])

  useEffect(() => {
    if (isResetTable) {
      onChangePageSize(25)
    }
  }, [isResetTable, current, pageSize])

  return {
    current,
    handleAddRowIndex,
    onChangeCurrent,
    onChangePageSize,
    pageSize,
  }
}
