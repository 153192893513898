/* eslint-disable complexity */
import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = (
  isFromSCR114UserSearch?: boolean,
  isFromSCR212?: boolean,
  isFromSCR212User?: boolean,
) => {
  const {
    isCommonTravelerRefer,
    isRoleAdmin,
    isRoleSubAdmin,
    isRoleUser,
    isScheduleRegister,
  } = useAuthority()

  const isNotAccessScreen =
    (isFromSCR212 && !isCommonTravelerRefer) ||
    (isFromSCR212User && !isScheduleRegister && !isFromSCR114UserSearch)

  // 画面上のボタン表示条件

  const isVisibleBtn =
    (isRoleAdmin || isRoleSubAdmin || isRoleUser) &&
    ((!!isFromSCR212 && isCommonTravelerRefer) ||
      (!!isFromSCR212User && isScheduleRegister) ||
      (!!isFromSCR212User && !isScheduleRegister && !!isFromSCR114UserSearch))

  const isVisibleBtnCopy = isVisibleBtn

  const isVisibleBtnBack = isVisibleBtn

  return {
    isNotAccessScreen,
    isVisibleBtnBack,
    isVisibleBtnCopy,
  }
}
