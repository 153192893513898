import { Endpoint } from "../../../../constant/Endpoint"
import { Environment } from "../../../../constant/Environment"
import { apiService } from "../../../../infrastructure/apiService"
import { ChangePasswordFormDataType } from "../models/changePassword"
import { ResetPasswordFormDataType } from "../models/resetPassword"

const { TRAVELER_ENDPOINT } = Environment
const {
  FNC1A_Accept_Agreements,
  FNC1A_Change_Password,
  FNC1A_Reset_Password,
  FNC1A_Update_Fist_Login,
} = Endpoint

export const usersApi = {
  acceptAgreements: () =>
    apiService({
      data: {},
      method: "PUT",
      url: `${TRAVELER_ENDPOINT}${FNC1A_Accept_Agreements}`,
    }),
  changePassword: (data: ChangePasswordFormDataType) =>
    apiService({
      data: data,
      method: "PUT",
      url: `${TRAVELER_ENDPOINT}${FNC1A_Change_Password}`,
    }),
  resetPassword: (data: ResetPasswordFormDataType) =>
    apiService({
      data: data,
      method: "PUT",
      url: `${TRAVELER_ENDPOINT}${FNC1A_Reset_Password}`,
    }),
  updateFistLogin: () =>
    apiService({
      data: {},
      method: "PUT",
      url: `${TRAVELER_ENDPOINT}${FNC1A_Update_Fist_Login}`,
    }),
}
