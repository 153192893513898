import { Key, useState } from "react"
import { useSort } from "../../../../../../../../utils/hook/useSort"
import { useTable } from "../../../../../../../../utils/hook/useTable"
import { SubAdminSettingListType } from "../../../../../models/subAdmin/setting"

export const useVariablesRowSelection = (dataLength: number) => {
  const [dataCurrent, setDataCurrent] = useState<SubAdminSettingListType[]>([])
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [isDisabledBtnSave, setIsDisabledBtnSave] = useState(true)
  const [isDisabledCheckAll, setIsDisabledCheckAll] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [travelerListCurrent, setTravelerListCurrent] = useState<
    SubAdminSettingListType[]
  >([])

  const { handleSetDefaultSortedInfo, handleSetSortedInfo, sortedInfo } =
    useSort()

  const {
    onChange: onChangePage,
    perItem,
    perItemEnd,
    perItemStart,
  } = useTable(dataLength)

  return {
    dataCurrent,
    handleSetDefaultSortedInfo,
    handleSetSortedInfo,
    isCheckAll,
    isDisabledBtnSave,
    isDisabledCheckAll,
    onChangePage,
    perItem,
    perItemEnd,
    perItemStart,
    selectedRowKeys,
    setDataCurrent,
    setIsCheckAll,
    setIsDisabledBtnSave,
    setIsDisabledCheckAll,
    setSelectedRowKeys,
    setTravelerListCurrent,
    sortedInfo,
    travelerListCurrent,
  }
}
