const SCR210 = "/schedules"
const SCR210_ATTENTIONS = "/attentions/schedules"
const SCR210_SAFETIES = "/safeties/schedules"
const SCR210_TRAVELERS = "/travelers/schedules"
const SCR210_USER_SEARCH = "/user-search/schedules"
const SCR211 = "/schedules/detail/:id"
const SCR212 = "/schedules/add/:id"
const SCR212_USER = "/schedules/add/user/:id"
const SCR213 = "/schedules/add-success/:id"
const SCR214 = "/schedules/edit/:id"
const SCR214_USER = "/schedules/edit/user/:id"
const SCR215 = "/schedules/edit-success/:id"
const SCR216 = "/schedules/confirm-delete"
const SCR217 = "/schedules/delete-success"
const SCR220 = "/schedules/batch-import"
const SCR220_USER = "/schedules/batch-import/user"
const SCR221 = "/schedules/batch-update"
const SCR221_USER = "/schedules/batch-update/user"
const SCR222 = "/schedules/batch-delete"
const SCR222_USER = "/schedules/batch-delete/user"

export const SchedulesRouters = {
  SCR210,
  SCR210_ATTENTIONS,
  SCR210_SAFETIES,
  SCR210_TRAVELERS,
  SCR210_USER_SEARCH,
  SCR211,
  SCR212,
  SCR212_USER,
  SCR213,
  SCR214,
  SCR214_USER,
  SCR215,
  SCR216,
  SCR217,
  SCR220,
  SCR220_USER,
  SCR221,
  SCR221_USER,
  SCR222,
  SCR222_USER,
}
