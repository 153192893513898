import moment from "moment"
import { getFullName } from "../../../../../../../../../utils/getFullName"
import { SchedulesListDataType } from "../../../../../../models/list"
import { heading } from "./heading"
import { getTextYesNo } from "../../../../../../../../../utils/getTextYesNo"
import { extractCode } from "../../../../../../../../../utils/extractCode"
import { extractName } from "../../../../../../../../../utils/extractName"
import {
  convertTimezone,
  getTimeZoneValue,
} from "../../../../../../../../../utils/getDate"
import { concat } from "../../../../../../../../../utils/concat"
import { Variables } from "../../../../../../../travelers/constants/edit/variables"

const { KEYS_ACCOMPANYING_FAMILY } = Variables

const formatDateExport = (date?: string) =>
  date ? moment(date).format("YYYYMMDD") : ""

export const getDay = (day?: string) => {
  if (!day) return ""
  const date = moment(day).utc().toString()
  const timeZoneValue = getTimeZoneValue(date)
  return moment(convertTimezone(date, +concat(timeZoneValue))).format(
    "YYYYMMDDHHmm",
  )
}

export const convertDataExport = (data: SchedulesListDataType[]) => {
  let convertData = heading
  data.map(
    (item, index) =>
      (convertData += `\r\n"${index + 1}","${item.csv.userId}","${getFullName(
        item.info.kanjiSurname,
        item.info.kanjiName,
        true,
      )}","${getFullName(
        item.info.romajiSurname,
        item.info.romajiName,
      )}","${getFullName(
        item.info.kanjiSurnamePassport,
        item.info.kanjiNamePassport,
        true,
      )}","${getFullName(
        item.info.romajiSurnamePassport,
        item.info.romajiNamePassport,
      )}","${item.info.dispatchTypeName}","${
        item.info.dispatchTypeDetailName
      }","${
        item.info.otherStaffNumber
          ? item.info.bumonName
          : KEYS_ACCOMPANYING_FAMILY.includes(item.info.dispatchTypeDetailId)
          ? item.info.personDispatcherName
          : item.info.organizationName.split("・")[0]
      }","${item.info.workPlaceCountry}","${item.info.workPlaceCity}","${
        item.info.otherStaffNumber
      }","${item.info.otherDispatchNumber}","${item.info.otherMemberNumber}","${
        item.info.travelPurposeName
      }","${item.info.projectNameJp}","${item.info.projectNameEn}","${
        item.info.procurementControlNumber
      }","${item.csv.bumonName}","${item.info.mainPersonName}","${
        item.info.locationPersonName
      }","${formatDateExport(item.info.dispatchStartDate)}","${formatDateExport(
        item.info.dispatchEndDate,
      )}","${item.csv.departureCountryName}","${extractCode(
        item.csv.departureCountryCode,
      )}","${extractName(item.csv.departureCityCode)}","${extractCode(
        item.csv.departureCityCode,
      )}","${extractName(item.csv.transportation)}","${item.csv.isTransit}","${
        item.csv.departureFlightNumber
      }","${item.csv.departureDateTime}","${
        item.csv.arrivalCountryName
      }","${extractCode(item.csv.arrivalCountryCode)}","${extractName(
        item.csv.arrivalCityCode,
      )}","${extractCode(item.csv.arrivalCityCode)}","${
        item.csv.arrivalDateTime
      }","${item.csv.othersRemarks1}","${item.csv.othersRemarks2}","${
        item.csv.othersRemarks3
      }","${item.csv.othersRemarks4}","${
        item.csv.othersRemarks5
      }","${getTextYesNo(item.info.differentInforCheck)}","${
        item.info.insuranceCompanyName
      }","${item.info.insuranceCompanyPhoneNumber}","${
        item.info.insuranceTicketNumber
      }","${item.info.insuranceSonsignor}","${item.csv.lodgingName}","${
        item.csv.lodgingAddress
      }","${item.csv.lodgingPhoneNumber}","${item.csv.lodgingCheckin}","${
        item.csv.lodgingCheckout
      }","${item.csv.locationPhoneNumber1}","${
        item.csv.locationPhoneNumber2
      }","${item.csv.satellitePhoneNumber}","${item.csv.emailAddress1}","${
        item.csv.emailAddress2
      }","${getTextYesNo(item.info.codeConductConfirmation)}","${getDay(
        item.info.updateDate,
      )}","${item.info.hakenIdentityNumber}","${
        item.info.zaigaiIdentityNumber
      }","${item.info.volunteerIdentityNumber}","${getDay(
        item.info.createdDate,
      )}","${getDay(item.info.updateDate)}"`),
  )
  return convertData
}
