/* eslint-disable max-lines, max-statements */
import { formatDateSaveDB } from "../../../../../../../../utils/getDate"
import { getOverseasResident } from "../../../../../../../../utils/getOverseasResident"
import { getTextByKey } from "../../../../../../../../utils/getTextByKey"
import { getValueCombobox } from "../../../../../../../../utils/getValueCombobox"
import { Variables } from "../../../../../constants/edit/variables"
import {
  ConvertDataSubmitProps,
  TravelersEditResType,
} from "../../../../../models/edit"

const { KEYS_ACCOMPANYING_FAMILY } = Variables

export const convertDataSubmit = (
  data: ConvertDataSubmitProps,
): TravelersEditResType => {
  const {
    allCountries,
    cities,
    departments,
    dispatchType,
    prefectures,
    travelerId,
    values,
  } = data

  let trainingType = values.trainingType
  let trainingDate = values.trainingDate

  if (!KEYS_ACCOMPANYING_FAMILY.includes(values.dispatchTypeDetailId)) {
    if (values.trainingType === 0) {
      trainingDate = ""
      trainingType = null
    }
  }

  const bumonName = getTextByKey(values.bumonCd, departments)
  const dispatchTypeDetailName = getTextByKey(
    values.dispatchTypeDetailId,
    dispatchType,
  )
  const personPrefectureResidenceName = getTextByKey(
    values.personPrefectureResidence,
    prefectures,
  )
  const workPlaceCountryName = getTextByKey(
    values.workPlaceCountry,
    allCountries,
  )
  const workPlaceCityName = getTextByKey(values.workPlaceCity, cities)
  const overseasResident = getOverseasResident(
    values.workPlaceLocation === 1,
    values.workPlace,
    getValueCombobox(values.workPlaceCity, workPlaceCityName),
    getValueCombobox(values.workPlaceCountry, workPlaceCountryName),
  )

  return {
    base: {
      bumonCd: getValueCombobox(values.bumonCd, bumonName),
      bumonName: values.bumonName,
      differentInforCheck: values.differentInforCheck,
      dispatchTypeDetailId: getValueCombobox(
        values.dispatchTypeDetailId,
        dispatchTypeDetailName,
      ),
      dispatchTypeDetailName: values.dispatchTypeDetailName,
      dispatchTypeName: values.dispatchTypeName,
      kanjiName: values.kanjiName,
      kanjiPassportName: values.kanjiPassportName,
      kanjiPassportSurname: values.kanjiPassportSurname,
      kanjiSurname: values.kanjiSurname,
      numberOfPeople: values.numberOfPeople,
      passportNumber: values.passportNumber,
      romajiName: values.romajiName,
      romajiPassportName: values.romajiPassportName,
      romajiPassportSurname: values.romajiPassportSurname,
      romajiSurname: values.romajiSurname,
      updateDate: values.updateDate,
      userId: values.userId,
    },
    detail: {
      assigneeInstitutionName: values.assigneeInstitutionName,
      assigneeName: values.assigneeName,
      assigneePhoneNumber: values.assigneePhoneNumber,
      insuranceCompanyName: values.insuranceCompanyName,
      insuranceCompanyPhoneNumber: values.insuranceCompanyPhoneNumber,
      insuranceSonsignor: values.insuranceSonsignor,
      insuranceTicketNumber: values.insuranceTicketNumber,
      othersAdministratorRemarks1: values.othersAdministratorRemarks1,
      othersAdministratorRemarks2: values.othersAdministratorRemarks2,
      othersAdministratorRemarks3: values.othersAdministratorRemarks3,
      othersAdministratorRemarks4: values.othersAdministratorRemarks4,
      othersAdministratorRemarks5: values.othersAdministratorRemarks5,
      othersRemarks1: values.othersRemarks1,
      othersRemarks2: values.othersRemarks2,
      othersRemarks3: values.othersRemarks3,
      othersRemarks4: values.othersRemarks4,
      othersRemarks5: values.othersRemarks5,
      personCompanyPosition: values.personCompanyPosition,
      personCountryPhoneCode: values.personCountryPhoneCode,
      personDispatcherName: values.personDispatcherName,
      personEmailAddress: values.personEmailAddress,
      personPhoneNumber: values.personPhoneNumber,
      personPrefectureResidence: getValueCombobox(
        values.personPrefectureResidence,
        personPrefectureResidenceName,
      ),
      trainingDate: trainingDate ? formatDateSaveDB(trainingDate) : "",
      trainingType,
      travelerId,
      urgentAffiliationContact: values.urgentAffiliationContact,
      urgentAffiliationContactName: values.urgentAffiliationContactName,
      urgentContactHome: values.urgentContactHome,
      urgentContactName: values.urgentContactName,
      userIdForChangeRequest: values.userIdForChangeRequest,
      workPlaceLocation: values.workPlaceLocation,
      ...overseasResident,
    },
  }
}
