import { useState } from "react"
import { useHistory } from "react-router-dom"
import { GridItemType } from "../../../../../../../../_webui/layout/components/Grid/models"
import { useVariablesAuthority } from "../useVariablesAuthority"

export const useVariables = () => {
  const { push } = useHistory()

  const [detail, setDetail] = useState<GridItemType[]>([])
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const { isCommonRefer, isHiddenReset, isHiddenSubmit } =
    useVariablesAuthority()

  return {
    detail,
    isCommonRefer,
    isHiddenReset,
    isHiddenSubmit,
    isLoadingLayout,
    push,
    setDetail,
    setIsLoadingLayout,
  }
}
