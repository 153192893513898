import { FC, Fragment } from "react"
import { Translate } from "../../../../../../../constant/Translate"
import { ModalConfirm } from "../../../../../../../_webui/layout/components/Modal/Confirm"
import { FilesUploadModalConfirmProps } from "../../../../models/files/upload"

const { MESSAGE_C0009, MESSAGE_C0015 } = Translate

export const FilesUploadModalConfirm: FC<FilesUploadModalConfirmProps> = (
  props,
) => {
  const {
    isOpen,
    isOpenConfirm,
    isOpenConfirmDuplicate,
    messageConfirmDuplicate,
    onCancel,
    onCancelConfirm,
    onCancelConfirmDuplicate,
    onDisableFiles,
    onOkConfirm,
    onOkConfirmDuplicate,
  } = props

  return (
    <Fragment>
      <ModalConfirm onCancel={onCancel} onOk={onDisableFiles} isOpen={isOpen}>
        {MESSAGE_C0009}
      </ModalConfirm>
      <ModalConfirm
        isOpen={isOpenConfirm}
        onCancel={onCancelConfirm}
        onOk={onOkConfirm}
      >
        {MESSAGE_C0015}
      </ModalConfirm>
      <ModalConfirm
        isOpen={isOpenConfirmDuplicate}
        onCancel={onCancelConfirmDuplicate}
        onOk={onOkConfirmDuplicate}
      >
        {messageConfirmDuplicate}
      </ModalConfirm>
    </Fragment>
  )
}
