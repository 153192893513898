import styled, { css } from "styled-components"
import { Regex } from "../../../../../../../../constant/Regex"

const { REGEX_IS_DESKTOP } = Regex

const StyledSubAdminForm = styled.div`
  ${REGEX_IS_DESKTOP &&
  css`
    display: flex;
    flex-wrap: wrap;
    width: 630px;
  `}
`

export const Styled = {
  StyledSubAdminForm,
}
