import { UseTableErrorConfigType } from "../../../../../../../_webui/layout/components/Table/Error/models"
import { ColumnType } from "../../../../../../../_webui/layout/components/Table/models"
import { Translate } from "../../../../../../../constant/Translate"
import { TableErrorTravelersRowType } from "../../../../models/requestDelete"

const { TEXT_T002, TEXT_T240, TEXT_T381 } = Translate

export const useTableErrorConfig =
  (): UseTableErrorConfigType<TableErrorTravelersRowType> => {
    const columnErrors: ColumnType<TableErrorTravelersRowType>[] = [
      {
        render: (_, _data, index) => index + 1,
        title: TEXT_T240,
        width: 65,
      },
      {
        dataIndex: "userId",
        sorter: (a, b) => a.userId.localeCompare(b.userId),
        title: TEXT_T002,
        width: 250,
      },
      {
        dataIndex: "message",
        sorter: (a, b) => a.message.localeCompare(b.message),
        title: TEXT_T381,
        width: 500,
      },
    ]
    return {
      columnErrors,
    }
  }
