/* eslint-disable max-lines */
/* eslint-disable max-statements */
import { Regex } from "../../../../../../../../constant/Regex"
import { concat } from "../../../../../../../../utils/concat"
import { getDate } from "../../../../../../../../utils/getDate"
import { getTextByKey } from "../../../../../../../../utils/getTextByKey"
import { getTimeMoment } from "../../../../../../../../utils/getTime"
import { getValueComboboxTable } from "../../../../../../../../utils/getValueComboboxTable"
import { Variables } from "../../../../../constants/add/variables"
import {
  GetSchedulesAddDetailsSubmitProps,
  SchedulesAddDetailsAddType,
} from "../../../../../models/add/DataAdd"

const { REGEX_IS_DESKTOP } = Regex
const { SCHEDULES_DETAIL_ROW_PREFIX } = Variables

export const getSchedulesAddDetailsSubmit = (
  data: GetSchedulesAddDetailsSubmitProps,
): SchedulesAddDetailsAddType => {
  const {
    allCountries,
    detailDataSource,
    id,
    isSchedulesAdd,
    isSchedulesEdit,
    item,
    transportationList,
  } = data

  const itemNew = { ...item }

  const currentDetailDataSource = detailDataSource.find(
    (item) => item.index === item["index"],
  )

  const transportationName = getTextByKey(
    itemNew["transportation"],
    transportationList,
  )

  const departureCountryName = getTextByKey(
    itemNew["departureCountryCode"],
    allCountries,
  )

  const arrivalCountryName = getTextByKey(
    itemNew["arrivalCountryCode"],
    allCountries,
  )

  const departureCityName = getTextByKey(
    itemNew["departureCityCode"],
    currentDetailDataSource?.departureCities,
  )

  const arrivalCityName = getTextByKey(
    itemNew["arrivalCityCode"],
    currentDetailDataSource?.arrivalCities,
  )

  itemNew["transportation"] = getValueComboboxTable(
    itemNew["transportation"],
    transportationName,
    itemNew["index"],
    SCHEDULES_DETAIL_ROW_PREFIX,
  )

  itemNew["departureCountryCode"] = getValueComboboxTable(
    itemNew["departureCountryCode"],
    departureCountryName,
    itemNew["index"],
    SCHEDULES_DETAIL_ROW_PREFIX,
  )

  itemNew["arrivalCountryCode"] = getValueComboboxTable(
    itemNew["arrivalCountryCode"],
    arrivalCountryName,
    itemNew["index"],
    SCHEDULES_DETAIL_ROW_PREFIX,
  )

  itemNew["departureCityCode"] = getValueComboboxTable(
    itemNew["departureCityCode"],
    departureCityName,
    itemNew["index"],
    SCHEDULES_DETAIL_ROW_PREFIX,
  )

  itemNew["arrivalCityCode"] = getValueComboboxTable(
    itemNew["arrivalCityCode"],
    arrivalCityName,
    itemNew["index"],
    SCHEDULES_DETAIL_ROW_PREFIX,
  )

  if (REGEX_IS_DESKTOP) {
    itemNew["departureDateTime"] = getDate(
      itemNew["departureDate"],
      getTimeMoment(itemNew["departureTime"]),
    )

    itemNew["arrivalDateTime"] = getDate(
      itemNew["arrivalDate"],
      getTimeMoment(itemNew["arrivalTime"]),
    )

    delete itemNew["departureDate"]
    delete itemNew["departureTime"]
    delete itemNew["arrivalDate"]
    delete itemNew["arrivalTime"]
  } else {
    itemNew["departureDateTime"] = getDate(
      itemNew["departureDate"],
      `${itemNew["departureHour"]}:${itemNew["departureMinute"]}:00`,
    )

    itemNew["arrivalDateTime"] = getDate(
      itemNew["arrivalDate"],
      `${itemNew["arrivalHour"]}:${itemNew["arrivalMinute"]}:00`,
    )

    delete itemNew["departureDate"]
    delete itemNew["departureHour"]
    delete itemNew["departureMinute"]
    delete itemNew["arrivalDate"]
    delete itemNew["arrivalHour"]
    delete itemNew["arrivalMinute"]
  }

  if (isSchedulesAdd) delete itemNew["travelDetailId"]
  else if (isSchedulesEdit) itemNew["travelScheduleId"] = concat(id)

  return itemNew
}
