import { FC } from "react"
import { DividerProps } from "./models"
import { Styled } from "./styled"

const { StyledDivider } = Styled

export const Divider: FC<DividerProps> = (props) => {
  const { className } = props

  return <StyledDivider className={className}></StyledDivider>
}
