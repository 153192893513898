const KEY = "login.action"
const LOGIN = "login"
const RULE = "rule"
const SESSION = "session"
const TIMEOUT = "timeout"
const UPDATE = "update"

export const LoginActions = {
  KEY,
  LOGIN,
  RULE,
  SESSION,
  TIMEOUT,
  UPDATE,
}
