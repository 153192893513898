import { FC } from "react"
import { FormSearch } from "../../../../../../../_webui/layout/components/Form/Search"
import { ItemInput } from "../../../../../../../_webui/layout/components/Form/Item/Input"
import { ItemSelect } from "../../../../../../../_webui/layout/components/Form/Item/Select"
import { SubAdminFormSearchProps } from "../../../../models/subAdmin/list"
import { Translate } from "../../../../../../../constant/Translate"
import { InitialValues } from "../../../../constants/subAdmin/list/initialValues"
import { FormSearchRight } from "../../../../../../../_webui/layout/components/Form/Search/Right"
import { FormSearchLeft } from "../../../../../../../_webui/layout/components/Form/Search/Left"
import { Styled } from "./styled"

const { TEXT_T219, TEXT_T452, TEXT_T453, TEXT_T464 } = Translate
const { FORM_SEARCH_INITIAL_VALUES } = InitialValues
const { StyledSubAdminForm } = Styled

export const SubAdminFormSearch: FC<SubAdminFormSearchProps> = (props) => {
  const {
    authorityPatterns,
    departments,
    form,
    isHiddenBtnReset,
    isHiddenBtnSearch,
    onFinish,
  } = props

  return (
    <FormSearch
      form={form}
      heading={TEXT_T464}
      labelAlign="left"
      layout="vertical"
      onFinish={onFinish}
      initialValues={FORM_SEARCH_INITIAL_VALUES}
      isHiddenReset={!isHiddenBtnReset}
      isHiddenSubmit={!isHiddenBtnSearch}
    >
      <StyledSubAdminForm>
        <FormSearchLeft>
          <ItemSelect
            label={TEXT_T452}
            name="bumonCd"
            selectItem={departments}
          />
        </FormSearchLeft>

        <FormSearchRight>
          <ItemSelect
            label={TEXT_T453}
            name="patternId"
            selectItem={authorityPatterns}
          />
        </FormSearchRight>

        <FormSearchLeft>
          <ItemInput label={TEXT_T219} maxLength={100} name="fullName" />
        </FormSearchLeft>
      </StyledSubAdminForm>
    </FormSearch>
  )
}
