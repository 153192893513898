/* eslint-disable no-undefined */
import { useEffect } from "react"
import { Routers } from "../../../../../../../constant/Routers"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { UseSchedulesAddSuccessType } from "../../../../models/addSuccess"
import { useVariables } from "../useVariables"

const { SCR210_TRAVELERS } = Routers

export const useSchedulesAddSuccess = (): UseSchedulesAddSuccessType => {
  const {
    assignmentGridItem,
    breadcrumbItems,
    confirmGridItem,
    detailsColumnsFirst,
    detailsGridItem,
    detailsTabsItems,
    generalMastersRemark,
    getSchedule,
    insuranceGridItem,
    isLoadingLayout,
    isNotAccessScreen,
    isVisibleBtnEdit,
    isVisibleBtnSearch,
    isVisibleOtherRemarks,
    lodgingsColumns,
    lodgingsTabsItems,
    otherGridItem,
    personalGridItem,
    push,
    scheduleDetails,
    scheduleLodgings,
    scheduleStays,
    schedulesData,
    state,
    staysColumns,
    staysTabsItems,
    travelScheduleId,
  } = useVariables()

  const {
    info: { travelerId },
  } = schedulesData

  const goToSchedules = () => {
    // 「SCR210_渡航予定情報の一覧画面」に遷移する。
    push({
      pathname: state?.travelersURL ? SCR210_TRAVELERS : state.schedulesURL,
      state: {
        ...state,
        isFromSCR115: state?.isFromSCR114UserSearch,
        searchCondition: state.travelersURL
          ? undefined
          : state?.searchCondition,
      },
    })
  }

  useEffect(() => {
    // ユーザーの画面アクセス許可チェックを処理する。
    if (isNotAccessScreen) {
      // 「SCR973_エラー情報の表示画面」に遷移する。
      const { pathname, state } = backToLogin()
      push({ pathname, state })
    } else if (generalMastersRemark) {
      getSchedule()
    }
  }, [generalMastersRemark])

  return {
    assignmentGridItem,
    breadcrumbItems,
    confirmGridItem,
    detailsColumnsFirst,
    detailsGridItem,
    detailsTabsItems,
    goToSchedules,
    insuranceGridItem,
    isLoadingLayout,
    isVisibleBtnEdit,
    isVisibleBtnSearch,
    isVisibleOtherRemarks,
    lodgingsColumns,
    lodgingsTabsItems,
    otherGridItem,
    personalGridItem,
    scheduleDetails,
    scheduleLodgings,
    scheduleStays,
    staysColumns,
    staysTabsItems,
    travelScheduleId,
    travelerId,
  }
}
