import { FC } from "react"
import { SidebarToggleProps } from "./models"
import { Regex } from "../../../../../../constant/Regex"
import { Styled } from "./styled"
import { Translate } from "../../../../../../constant/Translate"

const { REGEX_IS_DESKTOP } = Regex
const {
  StyledIcon,
  StyledIconItemFirst,
  StyledIconItemSecond,
  StyledIconItemThird,
  StyledToggle,
  StyledToggleText,
} = Styled
const { TEXT_T070, TEXT_T140 } = Translate

export const SidebarToggle: FC<SidebarToggleProps> = (props) => {
  const { isExpandableMenu, onClick } = props

  return REGEX_IS_DESKTOP ? (
    <StyledToggle isExpandableMenu={isExpandableMenu}>
      <StyledIcon
        aria-label={TEXT_T140}
        id="sidebar-toggle-icon"
        onClick={onClick}
      >
        <StyledIconItemFirst></StyledIconItemFirst>
        <StyledIconItemSecond></StyledIconItemSecond>
        <StyledIconItemThird></StyledIconItemThird>
      </StyledIcon>
      <StyledToggleText>{TEXT_T070}</StyledToggleText>
    </StyledToggle>
  ) : null
}
