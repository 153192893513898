import { Translate } from "../../../../../../../../../../../constant/Translate"

const {
  EXPORT_EP0018, // 安否確認件名    Safety confirmation subject
  EXPORT_EP0127, // 発出日時    Sending date and time
  EXPORT_EP0099, // 帳票出力日時    Form output date and time
  EXPORT_EP0019, // 安否状況    Safety status
  EXPORT_EP0098, // 代理応答    Respond on Behalf
  EXPORT_EP0024, // 応答時刻    Response time
  EXPORT_EP0054, // 氏名（日）    Full name(Japanese)
  EXPORT_EP0052, // 氏名（英）    Full name(English)
  EXPORT_EP0055, // 氏名（日）(戸籍表記)    Full name(Japanese)(described in passport)
  EXPORT_EP0053, // 氏名（英）(パスポート表記)    Full name(English)(As shown on passport)
  EXPORT_EP0157, // 人材種別    Dispatch type
  EXPORT_EP0158, // 人材種別（詳細）    Dispatch type (Detail)
  EXPORT_EP0078, // 主管部署    Department
  EXPORT_EP0012, // パスポート番号    Passport number
  EXPORT_EP0103, // 電話番号    Phone number
  EXPORT_EP0138, // 本人コメント    Personal comment
  EXPORT_EP0043, // 現在地    Current location
  EXPORT_EP0025, // 家族安否状況    Family safety status
  EXPORT_EP0160, // 備考欄    Remarks 1
  EXPORT_EP0161, // 備考欄    Remarks 2
  EXPORT_EP0162, // 備考欄    Remarks 3
  EXPORT_EP0163, // 備考欄    Remarks 4
  EXPORT_EP0164, // 備考欄    Remarks 5
  EXPORT_EP0035, // 拠点担当者名    Name of person in charge of overseas office
  EXPORT_EP0065, // 宿泊先名称    Accommodation name
  EXPORT_EP0062, // 宿泊先住所    Accommodation address
  EXPORT_EP0066, // 宿泊先連絡先    Accommodation contact information
  EXPORT_EP0061, // 宿泊期間    Accommodation period
  EXPORT_EP0020, // 案件名    Project name
  EXPORT_EP0057, // 主管部署・担当者名    Name of department/person in charge
  EXPORT_EP0104, // 渡航期間    Travel period
  EXPORT_EP0077, // 出発便名    Flight number
  EXPORT_EP0045, // 交通手段    Means of transportation
  EXPORT_EP0071, // 出発国    Country of departure
  EXPORT_EP0154, // 出発都市    City of departure
  EXPORT_EP0076, // 出発日時    Departure date and time
  EXPORT_EP0109, // 到着国    Country of destination
  EXPORT_EP0155, // 到着都市    City of destination
  EXPORT_EP0114, // 到着日時    Arrival date and time
  EXPORT_EP0139, // 緊急連絡先    Emergency contact
  EXPORT_EP0165, // 所属先名    Affiliation name
  EXPORT_EP0149, // 所属先連絡先    Affiliation contact information
  EXPORT_EP0135, // 保険会社名    Insurance company name
  EXPORT_EP0136, // 保険会社連絡先    Insurance company contact information
  EXPORT_EP0137, // ②保険証券番号    [2]Insurance policy number
  EXPORT_EP0021, // 委任者    Delegator
  EXPORT_EP0166, // 管理用備考欄    Remarks for administrators 1
  EXPORT_EP0167, // 管理用備考欄    Remarks for administrators 2
  EXPORT_EP0168, // 管理用備考欄    Remarks for administrators 3
  EXPORT_EP0169, // 管理用備考欄    Remarks for administrators 4
  EXPORT_EP0170, // 管理用備考欄    Remarks for administrators
} = Translate

export const heading = `${EXPORT_EP0018},${EXPORT_EP0127},${EXPORT_EP0099},${EXPORT_EP0019},${EXPORT_EP0098},${EXPORT_EP0024},${EXPORT_EP0054},${EXPORT_EP0052},${EXPORT_EP0055},${EXPORT_EP0053},${EXPORT_EP0157},${EXPORT_EP0158},${EXPORT_EP0078},${EXPORT_EP0012},${EXPORT_EP0103},${EXPORT_EP0138},${EXPORT_EP0043},${EXPORT_EP0025},${EXPORT_EP0160},${EXPORT_EP0161},${EXPORT_EP0162},${EXPORT_EP0163},${EXPORT_EP0164},${EXPORT_EP0035},${EXPORT_EP0065},${EXPORT_EP0062},${EXPORT_EP0066},${EXPORT_EP0061},${EXPORT_EP0020},${EXPORT_EP0057},${EXPORT_EP0104},${EXPORT_EP0077},${EXPORT_EP0045},${EXPORT_EP0071},${EXPORT_EP0154},${EXPORT_EP0076},${EXPORT_EP0109},${EXPORT_EP0155},${EXPORT_EP0114},${EXPORT_EP0139},${EXPORT_EP0165},${EXPORT_EP0149},${EXPORT_EP0135},${EXPORT_EP0136},${EXPORT_EP0137},${EXPORT_EP0021},${EXPORT_EP0166},${EXPORT_EP0167},${EXPORT_EP0168},${EXPORT_EP0169},${EXPORT_EP0170}`
