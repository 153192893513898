import { SchedulesListDataType } from "../../../../../models/list"

export const convertDataSource = (
  data: SchedulesListDataType[],
): SchedulesListDataType[] =>
  data.map((item) => ({
    ...item,
    info: {
      ...item.info,
      bumonName: item.info.scheduleBumonName || item.info.bumonName,
    },
  }))
