import { FC } from "react"
import { Modal } from ".."
import { Translate } from "../../../../../constant/Translate"
import { ModalConfirmProps } from "./models"

const { TEXT_T229, TEXT_T189, MESSAGE_C0002 } = Translate

export const ModalConfirm: FC<ModalConfirmProps> = (props) => {
  const {
    cancelCustomText,
    cancelText = TEXT_T229,
    children = MESSAGE_C0002,
    isCenter,
    isOpen,
    onCancel,
    onCancelCustom,
    onOk,
  } = props

  return (
    <Modal
      cancelCustomText={cancelCustomText}
      cancelText={cancelText}
      isCenter={isCenter}
      isOpen={isOpen}
      okText={TEXT_T189}
      onCancel={onCancel}
      onCancelCustom={onCancelCustom}
      onOk={onOk}
    >
      {children}
    </Modal>
  )
}
