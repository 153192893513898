import styled from "styled-components"
import { ItemInput } from "../../../../../../../../../_webui/layout/components/Form/Item/Input"

const StyledItemInput = styled(ItemInput)`
  .ant {
    &-form {
      &-item-row {
        .ant {
          &-form {
            &-item {
              &-extra {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
`

export const Styled = {
  StyledItemInput,
}
