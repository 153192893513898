import "./style/index.scss"
import { Input } from "antd"
import { FC } from "react"
import { ItemTextAreaProps } from "./models"
import { FormItem } from ".."
import { useShowCount } from "../../../ShowCount/modules/useShowCount"
import { ShowCount } from "../../../ShowCount"

const { TextArea } = Input

export const ItemTextArea: FC<ItemTextAreaProps> = (props) => {
  const {
    className,
    isBgBlue,
    isHiddenLabel,
    label,
    maxLength,
    name,
    placeholder,
    required,
    rows,
    rules,
    tooltip,
  } = props

  const { countValue, isFocus, onBlur, onChange, onFocus } = useShowCount({
    maxLength,
  })

  return (
    <FormItem
      className={className}
      isBgBlue={isBgBlue}
      isHiddenLabel={isHiddenLabel}
      label={label}
      required={required}
      tooltip={tooltip}
    >
      <FormItem name={name} rules={rules}>
        <TextArea
          maxLength={maxLength}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          placeholder={placeholder}
          rows={rows}
        />
      </FormItem>
      <ShowCount countValue={countValue} isFocus={isFocus} />
    </FormItem>
  )
}
