import { FC, Fragment } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { ModalConfirm } from "../../../../../../_webui/layout/components/Modal/Confirm"
import { TravelersRequestDeleteModalProps } from "./models"

const { MESSAGE_C0010, MESSAGE_C0011, MESSAGE_C0012, MESSAGE_C0013 } = Translate

export const TravelersRequestDeleteModal: FC<
  TravelersRequestDeleteModalProps
> = (props) => {
  const {
    isOpenAccept,
    isOpenReActive,
    isOpenReEnable,
    isOpenRequestCancel,
    onCancelAccept,
    onCancelReActive,
    onCancelReEnable,
    onCancelRequestCancel,
    onOkAccept,
    onOkReActive,
    onOkReEnable,
    onOkRequestDisable,
  } = props

  return (
    <Fragment>
      <ModalConfirm
        isOpen={isOpenAccept}
        onCancel={onCancelAccept}
        onOk={onOkAccept}
      >
        {MESSAGE_C0010}
      </ModalConfirm>

      <ModalConfirm
        isOpen={isOpenReEnable}
        onCancel={onCancelReEnable}
        onOk={onOkReEnable}
      >
        {MESSAGE_C0011}
      </ModalConfirm>

      <ModalConfirm
        isOpen={isOpenRequestCancel}
        onCancel={onCancelRequestCancel}
        onOk={onOkRequestDisable}
      >
        {MESSAGE_C0012}
      </ModalConfirm>

      <ModalConfirm
        isOpen={isOpenReActive}
        onCancel={onCancelReActive}
        onOk={onOkReActive}
      >
        {MESSAGE_C0013}
      </ModalConfirm>
    </Fragment>
  )
}
