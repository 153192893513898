import styled from "styled-components"
import { Image } from "../../../../../../../../_webui/layout/components/Image"

const StyledStatusImage = styled(Image)`
  margin-left: 10px;
  vertical-align: top;
`

export const Styled = {
  StyledStatusImage,
}
