/* eslint-disable complexity */
/* eslint-disable max-lines */
import { FC } from "react"
import { Alert } from "../../../../../_webui/layout/components/Alert"
import { Button } from "../../../../../_webui/layout/components/Button"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Center } from "../../../../../_webui/layout/components/Center"
import { BreakText } from "../../../../../_webui/layout/components/Typography/BreakText"
import { Layout } from "./../../../../../_webui/layout/components/Layout/index"
import { useSafetiesInfo } from "./modules/useInfo"
import { Styled } from "./styled"
import { Heading } from "../../../../../_webui/layout/components/Typography/Heading"
import { getFullName } from "../../../../../utils/getFullName"
import { Space } from "../../../../../_webui/layout/components/Space"
import { Form } from "../../../../../_webui/layout/components/Form"
import { Grid } from "../../../../../_webui/layout/components/Grid"
import { ButtonSubmit } from "../../../../../_webui/layout/components/Button/Submit"
import { ChangeLanguage } from "../../../../../_webui/layout/components/ChangeLanguage"
import { getLang } from "../../../../../utils/translate"
import moment from "moment"
import { Timezone } from "../../../../../constant/Timezone"

const { StyledTitle } = Styled
const { DATE_FORMAT_JP, DATE_FORMAT_EN } = Timezone

export const SafetiesInfo: FC = () => {
  const {
    changeLanguage,
    form,
    gridItems,
    handleCloseTab,
    isLoading,
    isLoadingLayout,
    isShowFormSuccess,
    lang,
    onFinish,
    safetyInfo,
  } = useSafetiesInfo()
  const format = lang === "jp" ? DATE_FORMAT_JP : DATE_FORMAT_EN
  const date = safetyInfo?.implementationDate
  const implementationDate = date ? moment(date).utc().format(format) : ""

  return (
    <Layout
      isFooter={false}
      isHeader={false}
      isHeaderRightNone
      isLoadingLayout={isLoadingLayout}
      isSideBar={false}
      title={
        isShowFormSuccess
          ? getLang(lang, "TEXT_T010")
          : getLang(lang, "TEXT_T011")
      }
    >
      {isShowFormSuccess ? (
        <Card heading={getLang(lang, "TEXT_T010")}>
          <Alert
            type="success"
            content={
              <BreakText
                above={getLang(lang, "TEXT_T012")}
                below={getLang(lang, "TEXT_T013")}
              />
            }
          />
          <Center>
            <Button
              onClick={handleCloseTab}
              text={getLang(lang, "TEXT_T007")}
              type="primary"
              isFullWidth
            />
          </Center>
        </Card>
      ) : (
        <Card
          isHeading={false}
          heading={getLang(lang, "TEXT_T014")}
          isBackground
          isLoading={isLoading}
        >
          {safetyInfo?.flagJudgmentTime === 0 && (
            <Heading type="danger" title={getLang(lang, "TEXT_T015")} />
          )}
          <StyledTitle
            titleLeft={getFullName(
              getLang(lang, "TEXT_T001"),
              implementationDate,
              true,
            )}
            titleRight={
              <ChangeLanguage
                setLanguageToEnglish={() => changeLanguage("en")}
                setLanguageToJapanese={() => changeLanguage("jp")}
              />
            }
          />
          <Alert
            content={safetyInfo?.bodyContent}
            heading={safetyInfo?.safetyTitle}
            isIcon
            title={getLang(lang, "TEXT_T016")}
            type="danger"
          />
          {safetyInfo?.flagJudgmentTime === 1 ? (
            <Space isSpaceTop>
              <Form
                form={form}
                heading={getLang(lang, "TEXT_T017")}
                isBackground
                isCard={false}
                layout="vertical"
                onFinish={onFinish}
              >
                <Grid isForm gridItems={gridItems} />
                <ButtonSubmit submitText={getLang(lang, "TEXT_T024")} />
              </Form>
            </Space>
          ) : null}
        </Card>
      )}
    </Layout>
  )
}
