import { Breadcrumbs } from "../../../../../../../../constant/Breadcrumbs"
import { Routers } from "../../../../../../../../constant/Routers"

const { SCR210, SCR210_TRAVELERS } = Routers
const {
  BRCR_SCR216_FROM_SCR210_TRAVELERS,
  BRCR_SCR216_FROM_SCR210,
  BRCR_SCR216,
} = Breadcrumbs

export const getBreadcrumbItems = (
  isFromSCR210?: boolean,
  schedulesURL?: string,
) =>
  isFromSCR210
    ? schedulesURL === SCR210
      ? BRCR_SCR216_FROM_SCR210
      : schedulesURL === SCR210_TRAVELERS
      ? BRCR_SCR216_FROM_SCR210_TRAVELERS
      : BRCR_SCR216
    : BRCR_SCR216
