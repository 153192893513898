import { FC } from "react"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { Upload } from "../../../../../_webui/layout/components/Upload"
import { Breadcrumbs } from "../../../../../constant/Breadcrumbs"
import { File } from "../../../../../constant/File"
import { Translate } from "../../../../../constant/Translate"
import { TravelersBatchDeleteModalButtonSubmit } from "../../components/batchDelete/ButtonSubmit"
import { TravelersBatchDeleteModalConfirm } from "../../components/batchDelete/ModalConfirm"
import { TravelersBatchDeleteModalTable } from "../../components/batchDelete/Table"
import { useTravelersBatchDelete } from "./modules/useBatchDelete"

const { TEXT_T161 } = Translate
const { BRCR_SCR124 } = Breadcrumbs
const { FILE_TYPE_ALLOW_UPLOAD_ACCEPT_ALL } = File

export const BatchDelete: FC = () => {
  const {
    beforeUpload,
    bulkDeleteDataConfirm,
    bulkFileDelete,
    columnErrors,
    columns,
    countRecord,
    dataTemps,
    errorList,
    fileNameList,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenDeleted,
    isResetTable,
    isVisibleBtnCancel,
    isVisibleBtnDelete,
    onCancel,
    onCancelDeleted,
    onChange,
    onOk,
    onOpen,
    onOpenDeletedOrGoToHome,
    perItem,
  } = useTravelersBatchDelete()

  return (
    <Layout
      breadcrumbItems={BRCR_SCR124}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T161}
    >
      <Card heading={TEXT_T161} isLoading={isLoading}>
        <Upload
          accept={FILE_TYPE_ALLOW_UPLOAD_ACCEPT_ALL}
          customRequest={({ file }) =>
            beforeUpload(file, (upload: boolean) => {
              if (upload) {
                bulkFileDelete(file)
              }
            })
          }
          fileNameList={fileNameList}
        />
        <TravelersBatchDeleteModalTable
          columnErrors={columnErrors}
          columns={columns}
          countRecord={countRecord}
          dataTemps={dataTemps}
          errorList={errorList}
          isResetTable={isResetTable}
          onChange={onChange}
          perItem={perItem}
        />
        <TravelersBatchDeleteModalButtonSubmit
          isDisabledSubmit={isDisabledSubmit}
          isVisibleBtnCancel={isVisibleBtnCancel}
          isVisibleBtnDelete={isVisibleBtnDelete}
          onOpen={onOpen}
          onOpenDeletedOrGoToHome={onOpenDeletedOrGoToHome}
        />
        <TravelersBatchDeleteModalConfirm
          bulkDeleteDataConfirm={bulkDeleteDataConfirm}
          isOpen={isOpen}
          isOpenDeleted={isOpenDeleted}
          onCancel={onCancel}
          onCancelDeleted={onCancelDeleted}
          onOk={onOk}
        />
      </Card>
    </Layout>
  )
}
