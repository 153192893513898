import styled from "styled-components"
import { Regex } from "../../../../../../../constant/Regex"

const { REGEX_IS_DESKTOP } = Regex

const StyledAttentionsInfo = styled.div`
  padding-bottom: 20px;
  width: ${REGEX_IS_DESKTOP ? "30%" : "100%"};
`

export const Styled = {
  StyledAttentionsInfo,
}
