import { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import {
  AttentionStateBackType,
  AttentionTravelersDataType,
} from "../../../../models/attention"
import { useGridConfig } from "../useGridConfig"
import { useTableConfig } from "../useTableConfig"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { getBreadcrumbItems } from "./getBreadcrumbItems"

export const useVariables = () => {
  const { state } = useLocation<AttentionStateBackType>()
  const { push } = useHistory()
  const [travelerInfoList, setTravelerInfoList] = useState<
    AttentionTravelersDataType[]
  >([])
  const { columns } = useTableConfig()
  const {
    categoryList,
    form,
    gridItems,
    isShowCategoryOther,
    setIsShowCategoryOther,
    severityList,
  } = useGridConfig()
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabledBtnSubmit, setIsDisabledBtnSubmit] = useState(true)
  const { isAttentionsSendAll, isHiddenSubmitBtn } = useVariablesAuthority()

  const breadcrumbItems = getBreadcrumbItems(state?.sourceURL)

  return {
    breadcrumbItems,
    categoryList,
    columns,
    form,
    gridItems,
    isAttentionsSendAll,
    isDisabledBtnSubmit,
    isHiddenSubmitBtn,
    isLoading,
    isLoadingLayout,
    isShowCategoryOther,
    push,
    setIsDisabledBtnSubmit,
    setIsLoading,
    setIsLoadingLayout,
    setIsShowCategoryOther,
    setTravelerInfoList,
    severityList,
    state,
    travelerInfoList,
  }
}
