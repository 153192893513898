import { useHistory, useLocation } from "react-router-dom"
import { useTableErrorConfig } from "../../../../../../../_webui/layout/components/Table/Error/modules/useTableErrorConfig"
import { useTableConfig } from "../useTableConfig"
import { useApi } from "../useApi"
import { useVariablesModal } from "../useVariablesModal"
import { getBreadcrumbItems } from "./getBreadcrumbItems"
import { useVariablesAuthority } from "../useVariablesAuthority"

export const useVariables = () => {
  const { pathname } = useLocation()
  const { push } = useHistory()
  const { columnErrors } = useTableErrorConfig()
  const { columns } = useTableConfig()

  const { isNotAccessScreen, isVisibleBtnCancel, isVisibleBtnDelete } =
    useVariablesAuthority()

  const {
    isOpen,
    isOpenDeleted,
    onCancel,
    onCancelDeleted,
    onOpen,
    onOpenDeleted,
  } = useVariablesModal()
  const {
    beforeUpload,
    bulkCheck,
    bulkDelete,
    bulkDeleteData,
    bulkFileDelete,
    countRecord,
    dataTemps,
    errorList,
    fileNameList,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isResetTable,
    onChange,
    perItem,
    setIsLoading,
  } = useApi({
    onCancel,
    onCancelDeleted,
  })

  const breadcrumbItems = getBreadcrumbItems(pathname)

  return {
    beforeUpload,
    breadcrumbItems,
    bulkCheck,
    bulkDelete,
    bulkDeleteData,
    bulkFileDelete,
    columnErrors,
    columns,
    countRecord,
    dataTemps,
    errorList,
    fileNameList,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isNotAccessScreen,
    isOpen,
    isOpenDeleted,
    isResetTable,
    isVisibleBtnCancel,
    isVisibleBtnDelete,
    onCancel,
    onCancelDeleted,
    onChange,
    onOpen,
    onOpenDeleted,
    perItem,
    push,
    setIsLoading,
  }
}
