import { FC } from "react"
import { Modal } from ".."
import { Translate } from "../../../../../constant/Translate"
import { PopupProps } from "./models"
import { Styled } from "./styled"

const { TEXT_T072 } = Translate
const { StyledPopupContent, StyledPopupTitle } = Styled

export const Popup: FC<PopupProps> = (props) => {
  const {
    cancelText = TEXT_T072,
    children,
    isOpen,
    okText,
    onCancel,
    onOk,
    title,
  } = props
  return (
    <Modal
      cancelText={cancelText}
      isCenter
      isOpen={isOpen}
      okText={okText}
      onCancel={onCancel}
      onOk={onOk}
    >
      {title && <StyledPopupTitle alignItems="center" titleLeft={title} />}
      <StyledPopupContent>{children}</StyledPopupContent>
    </Modal>
  )
}
