import "./style/index.scss"
import { Input } from "antd"
import { FC } from "react"
import { ItemInputGroupProps } from "./models"
import { FormItem } from "../.."
import { ItemInput } from ".."

const { Group } = Input

export const ItemInputGroup: FC<ItemInputGroupProps> = (props) => {
  const {
    className,
    inputItems,
    isHiddenLabel,
    label,
    name,
    required,
    tooltip,
  } = props
  const styleItemGroupPc = `${className ?? ""} ant-form-item-group`

  return (
    <FormItem
      className={styleItemGroupPc}
      isHiddenLabel={isHiddenLabel}
      label={label}
      name={name}
      required={required}
      tooltip={tooltip}
    >
      <Group>
        {inputItems?.map((item) => (
          <ItemInput
            isBgBlue={item.isBgBlue}
            isHidden={item.isHidden}
            key={item.name}
            label={item.label}
            maxLength={item.maxLength}
            name={item.name}
            placeholder={item.placeholder}
            rules={item.rules}
            tooltip={item.tooltip}
          />
        ))}
      </Group>
    </FormItem>
  )
}
