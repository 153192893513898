import { TravelersSearchFormDataType } from "../../../models/search"

const TRAVELERS_SEARCH_INITIAL_VALUES: TravelersSearchFormDataType = {
  assigneeInstitutionName: "",
  assigneeName: "",
  assigneePhoneNumber: "",
  bumonCd: "",
  dispatchTypeDetailId: "",
  familyRelationship: "",
  familyRomajiName: "",
  familyRomajiSurname: "",
  insuranceCompanyName: "",
  insuranceCompanyPhoneNumber: "",
  insuranceSonsignor: "",
  insuranceTicketNumber: "",
  kanjiName: "",
  kanjiPassportName: "",
  kanjiPassportSurname: "",
  kanjiSurname: "",
  othersAdministratorRemarks1: "",
  othersAdministratorRemarks2: "",
  othersAdministratorRemarks3: "",
  othersAdministratorRemarks4: "",
  othersAdministratorRemarks5: "",
  othersRemarks1: "",
  othersRemarks2: "",
  othersRemarks3: "",
  othersRemarks4: "",
  othersRemarks5: "",
  passportNumber: "",
  personCompanyPosition: "",
  personCountryPhoneCode: "",
  personDispatcherName: "",
  personEmailAddress: "",
  personPhoneNumber: "",
  personPrefectureResidence: "",
  romajiName: "",
  romajiPassportName: "",
  romajiPassportSurname: "",
  romajiSurname: "",
  trainingDate: "",
  trainingType: "",
  urgentAffiliationContact: "",
  urgentAffiliationContactName: "",
  urgentContactHome: "",
  urgentContactName: "",
  workPlace: "",
  workPlaceCity: "",
  workPlaceCountry: "",
  workPlaceLocation: "",
}

export const InitialValues = {
  TRAVELERS_SEARCH_INITIAL_VALUES,
}
