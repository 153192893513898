/* eslint-disable max-statements */
import { useHistory } from "react-router-dom"
import { useTableConfig } from "../useTableConfig"
import { useRowSelection } from "../useRowSelection"
import { Key, useRef, useState } from "react"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { useVariablesModal } from "../useVariablesModal"
import { UploadFileType } from "../../../../model/filesUpload"

export const useVariables = () => {
  const { push } = useHistory()
  const selectedKeys = useRef<Key[]>([])
  const newData = useRef<UploadFileType[]>([])
  selectedKeys
  const {
    isOpen,
    isOpenConfirm,
    isOpenReload,
    isOpenSuccess,
    onCancel,
    onCancelConfirm,
    onCancelReload,
    onOkSuccess,
    onOpen,
    onOpenConfirm,
    onOpenReload,
    onOpenSuccess,
  } = useVariablesModal()

  const { columns } = useTableConfig()
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const { isCommonExternalSystemLinkage, isHiddenBtn } = useVariablesAuthority()
  const {
    fileUploadInfoList,
    rowSelection,
    selectedRowKeys,
    setFileUploadInfoList,
    setIsCheckAll,
    setSelectedRowKeys,
  } = useRowSelection(selectedKeys)
  const isDisableUpload = selectedRowKeys?.length === 0

  return {
    columns,
    fileUploadInfoList,
    isCommonExternalSystemLinkage,
    isDisableUpload,
    isHiddenBtn,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenConfirm,
    isOpenReload,
    isOpenSuccess,
    newData,
    onCancel,
    onCancelConfirm,
    onCancelReload,
    onOkSuccess,
    onOpen,
    onOpenConfirm,
    onOpenReload,
    onOpenSuccess,
    push,
    rowSelection,
    selectedKeys,
    selectedRowKeys,
    setFileUploadInfoList,
    setIsCheckAll,
    setIsLoading,
    setIsLoadingLayout,
    setSelectedRowKeys,
  }
}
