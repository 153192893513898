import { useEffect } from "react"
import { Routers } from "../../../../../../../constant/Routers"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { replaceString } from "../../../../../../../utils/replaceString/index"
import { UseAnswerSuccessType } from "../../../../models/answerSuccess"
import { useVariables } from "../useVariables"

const { SCR416, SCR416_HISTORY, SCR417, SCR420, SCR920 } = Routers

export const useAnswerSuccess = (): UseAnswerSuccessType => {
  const {
    breadcrumbItems,
    isFromEmail,
    isLoadingLayout,
    isNotAccessScreen,
    isVisibleBtnDetail,
    isVisibleBtnHome,
    isVisibleBtnList,
    push,
    setIsLoadingLayout,
    state,
  } = useVariables()

  const goToSafetiesDetail = () => {
    push({
      pathname: replaceString(SCR417, state?.safetyId),
      state: {
        ...state,
        safetyId: state?.safetyId,
        sourceURL: SCR420,
      },
    })
  }

  const goToHome = () => {
    push({
      pathname: SCR920,
      state: {
        sourceURL: SCR420,
      },
    })
  }

  const goToSafeties = () => {
    push({
      pathname: state.isFromSCR416 ? SCR416 : SCR416_HISTORY,
      state: {
        ...state,
        sourceURL: SCR420,
      },
    })
  }

  const handleCloseTab = () => {
    open("", "_self")?.close()
  }

  useEffect(() => {
    if (isNotAccessScreen) {
      const { pathname, state } = backToLogin()
      push({ pathname, state })
    } else {
      setTimeout(() => {
        setIsLoadingLayout(false)
      }, 500)
    }
  }, [])

  return {
    breadcrumbItems,
    goToHome,
    goToSafeties,
    goToSafetiesDetail,
    handleCloseTab,
    isFromEmail,
    isLoadingLayout,
    isVisibleBtnDetail,
    isVisibleBtnHome,
    isVisibleBtnList,
  }
}
