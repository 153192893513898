import { Translate } from "../../../../../../../../constant/Translate"
import { ColumnType } from "../../../../../../../../_webui/layout/components/Table/models"
import { ScheduleDetailsType } from "../../../../../models/addSuccess"
import { getValueByLanguage } from "../../../../../../../../utils/getValueByLanguage"
import { Image } from "../../../../../../../../_webui/layout/components/Image"
import { Images } from "../../../../../../../../constant/Images"
import { Center } from "../../../../../../../../_webui/layout/components/Center"

const { IMAGE_LIST_CHECK } = Images
const {
  TEXT_T202,
  TEXT_T203,
  TEXT_T204,
  TEXT_T205,
  TEXT_T206,
  TEXT_T207,
  TEXT_T208,
  TEXT_T209,
  TEXT_T220,
  TEXT_T240,
  TEXT_T244,
  TEXT_T245,
} = Translate

export const useDetailTableConfigFirst =
  (): ColumnType<ScheduleDetailsType>[] => [
    {
      render: (_, _data, index) => index + 1,
      title: TEXT_T240,
      width: 65,
    },
    {
      dataIndex: "transportationName",
      title: TEXT_T202,
      width: 200,
    },
    {
      dataIndex: "isTransit",
      render: (_, data) =>
        data.isTransit && (
          <Center>
            <Image
              src={IMAGE_LIST_CHECK}
              alt={`${TEXT_T220} ${data.isTransit}`}
            />
          </Center>
        ),
      title: TEXT_T220,
      width: 110,
    },
    {
      dataIndex: "departureFlightNumber",
      title: TEXT_T203,
      width: 200,
    },
    {
      dataIndex: "departureCountryName",
      title: TEXT_T205,
      width: 200,
    },
    {
      dataIndex: "departureCityName",
      title: TEXT_T206,
      width: 200,
    },
    {
      dataIndex: "departureCityNameOther",
      title: TEXT_T244,
      width: 200,
    },
    {
      dataIndex: "departureDateTime",
      title: TEXT_T204,
      width: getValueByLanguage(240, 200),
    },
    {
      dataIndex: "arrivalCountryName",
      title: TEXT_T208,
      width: 200,
    },
    {
      dataIndex: "arrivalCityName",
      title: TEXT_T209,
      width: 200,
    },
    {
      dataIndex: "arrivalCityNameOther",
      title: TEXT_T245,
      width: 200,
    },
    {
      dataIndex: "arrivalDateTime",
      title: TEXT_T207,
      width: getValueByLanguage(240, 200),
    },
  ]
