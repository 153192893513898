/* eslint-disable max-lines */
import { translateMessage } from "../../../../utils/translateMessage"
import { I18nCode } from "../../../I18nCode"

const {
  CODE_MESSAGE_C0001,
  CODE_MESSAGE_C0002,
  CODE_MESSAGE_C0003,
  CODE_MESSAGE_C0004,
  CODE_MESSAGE_C0005,
  CODE_MESSAGE_C0006,
  CODE_MESSAGE_C0007,
  CODE_MESSAGE_C0008,
  CODE_MESSAGE_C0009,
  CODE_MESSAGE_C0010,
  CODE_MESSAGE_C0011,
  CODE_MESSAGE_C0012,
  CODE_MESSAGE_C0013,
  CODE_MESSAGE_C0014,
  CODE_MESSAGE_C0015,
  CODE_MESSAGE_C0016,
  CODE_MESSAGE_C0017,
  CODE_MESSAGE_C0018,
  CODE_MESSAGE_C0019,
  CODE_MESSAGE_C0020,
  CODE_MESSAGE_C0021,
  CODE_MESSAGE_C0022,
  CODE_MESSAGE_C0023,
  CODE_MESSAGE_C0024,
  CODE_MESSAGE_C0025,
  CODE_MESSAGE_C0026,
  CODE_MESSAGE_C0027,
  CODE_MESSAGE_C0028,
  CODE_MESSAGE_C0029,
  CODE_MESSAGE_C0030,
  CODE_MESSAGE_C0031,
  CODE_MESSAGE_C0032,
  CODE_MESSAGE_C0033,
  CODE_MESSAGE_C0034,
  CODE_MESSAGE_C0035,
  CODE_MESSAGE_C0036,
  CODE_MESSAGE_C0037,
  CODE_MESSAGE_C0038,
  CODE_MESSAGE_C0039,
  CODE_MESSAGE_C0040,
  CODE_MESSAGE_C0041,
  CODE_MESSAGE_C0042,
  CODE_MESSAGE_C0043,
  CODE_MESSAGE_C0044,
  CODE_MESSAGE_C0045,
  CODE_MESSAGE_C0046,
  CODE_MESSAGE_C0047,
  CODE_MESSAGE_C0048,
  CODE_MESSAGE_C0049,
  CODE_MESSAGE_C0050,
  CODE_MESSAGE_C0051,
  CODE_MESSAGE_C0052,
  CODE_MESSAGE_C0053,
  CODE_MESSAGE_C0054,
  CODE_MESSAGE_C0055,
} = I18nCode

const MESSAGE_C0001 = translateMessage(CODE_MESSAGE_C0001)
const MESSAGE_C0002 = translateMessage(CODE_MESSAGE_C0002)
const MESSAGE_C0003 = translateMessage(CODE_MESSAGE_C0003)
const MESSAGE_C0004 = translateMessage(CODE_MESSAGE_C0004)
const MESSAGE_C0005 = translateMessage(CODE_MESSAGE_C0005)
const MESSAGE_C0006 = translateMessage(CODE_MESSAGE_C0006)
const MESSAGE_C0007 = translateMessage(CODE_MESSAGE_C0007)
const MESSAGE_C0008 = translateMessage(CODE_MESSAGE_C0008)
const MESSAGE_C0009 = translateMessage(CODE_MESSAGE_C0009)
const MESSAGE_C0010 = translateMessage(CODE_MESSAGE_C0010)
const MESSAGE_C0011 = translateMessage(CODE_MESSAGE_C0011)
const MESSAGE_C0012 = translateMessage(CODE_MESSAGE_C0012)
const MESSAGE_C0013 = translateMessage(CODE_MESSAGE_C0013)
const MESSAGE_C0014 = translateMessage(CODE_MESSAGE_C0014)
const MESSAGE_C0015 = translateMessage(CODE_MESSAGE_C0015)
const MESSAGE_C0016 = translateMessage(CODE_MESSAGE_C0016)
const MESSAGE_C0017 = translateMessage(CODE_MESSAGE_C0017)
const MESSAGE_C0018 = translateMessage(CODE_MESSAGE_C0018)
const MESSAGE_C0019 = translateMessage(CODE_MESSAGE_C0019)
const MESSAGE_C0020 = translateMessage(CODE_MESSAGE_C0020)
const MESSAGE_C0021 = translateMessage(CODE_MESSAGE_C0021)
const MESSAGE_C0022 = translateMessage(CODE_MESSAGE_C0022)
const MESSAGE_C0023 = translateMessage(CODE_MESSAGE_C0023)
const MESSAGE_C0024 = translateMessage(CODE_MESSAGE_C0024)
const MESSAGE_C0025 = translateMessage(CODE_MESSAGE_C0025)
const MESSAGE_C0026 = translateMessage(CODE_MESSAGE_C0026)
const MESSAGE_C0027 = translateMessage(CODE_MESSAGE_C0027)
const MESSAGE_C0028 = translateMessage(CODE_MESSAGE_C0028)
const MESSAGE_C0029 = translateMessage(CODE_MESSAGE_C0029)
const MESSAGE_C0030 = translateMessage(CODE_MESSAGE_C0030)
const MESSAGE_C0031 = translateMessage(CODE_MESSAGE_C0031)
const MESSAGE_C0032 = translateMessage(CODE_MESSAGE_C0032)
const MESSAGE_C0033 = translateMessage(CODE_MESSAGE_C0033)
const MESSAGE_C0034 = translateMessage(CODE_MESSAGE_C0034)
const MESSAGE_C0035 = translateMessage(CODE_MESSAGE_C0035)
const MESSAGE_C0036 = translateMessage(CODE_MESSAGE_C0036)
const MESSAGE_C0037 = translateMessage(CODE_MESSAGE_C0037)
const MESSAGE_C0038 = translateMessage(CODE_MESSAGE_C0038)
const MESSAGE_C0039 = translateMessage(CODE_MESSAGE_C0039)
const MESSAGE_C0040 = translateMessage(CODE_MESSAGE_C0040)
const MESSAGE_C0041 = translateMessage(CODE_MESSAGE_C0041)
const MESSAGE_C0042 = translateMessage(CODE_MESSAGE_C0042)
const MESSAGE_C0043 = translateMessage(CODE_MESSAGE_C0043)
const MESSAGE_C0044 = translateMessage(CODE_MESSAGE_C0044)
const MESSAGE_C0045 = translateMessage(CODE_MESSAGE_C0045)
const MESSAGE_C0046 = translateMessage(CODE_MESSAGE_C0046)
const MESSAGE_C0047 = translateMessage(CODE_MESSAGE_C0047)
const MESSAGE_C0048 = translateMessage(CODE_MESSAGE_C0048)
const MESSAGE_C0049 = translateMessage(CODE_MESSAGE_C0049)
const MESSAGE_C0050 = translateMessage(CODE_MESSAGE_C0050)
const MESSAGE_C0051 = translateMessage(CODE_MESSAGE_C0051)
const MESSAGE_C0052 = translateMessage(CODE_MESSAGE_C0052)
const MESSAGE_C0053 = translateMessage(CODE_MESSAGE_C0053)
const MESSAGE_C0054 = translateMessage(CODE_MESSAGE_C0054)
const MESSAGE_C0055 = translateMessage(CODE_MESSAGE_C0055)

export const TranslateMessageConfirm = {
  MESSAGE_C0001,
  MESSAGE_C0002,
  MESSAGE_C0003,
  MESSAGE_C0004,
  MESSAGE_C0005,
  MESSAGE_C0006,
  MESSAGE_C0007,
  MESSAGE_C0008,
  MESSAGE_C0009,
  MESSAGE_C0010,
  MESSAGE_C0011,
  MESSAGE_C0012,
  MESSAGE_C0013,
  MESSAGE_C0014,
  MESSAGE_C0015,
  MESSAGE_C0016,
  MESSAGE_C0017,
  MESSAGE_C0018,
  MESSAGE_C0019,
  MESSAGE_C0020,
  MESSAGE_C0021,
  MESSAGE_C0022,
  MESSAGE_C0023,
  MESSAGE_C0024,
  MESSAGE_C0025,
  MESSAGE_C0026,
  MESSAGE_C0027,
  MESSAGE_C0028,
  MESSAGE_C0029,
  MESSAGE_C0030,
  MESSAGE_C0031,
  MESSAGE_C0032,
  MESSAGE_C0033,
  MESSAGE_C0034,
  MESSAGE_C0035,
  MESSAGE_C0036,
  MESSAGE_C0037,
  MESSAGE_C0038,
  MESSAGE_C0039,
  MESSAGE_C0040,
  MESSAGE_C0041,
  MESSAGE_C0042,
  MESSAGE_C0043,
  MESSAGE_C0044,
  MESSAGE_C0045,
  MESSAGE_C0046,
  MESSAGE_C0047,
  MESSAGE_C0048,
  MESSAGE_C0049,
  MESSAGE_C0050,
  MESSAGE_C0051,
  MESSAGE_C0052,
  MESSAGE_C0053,
  MESSAGE_C0054,
  MESSAGE_C0055,
}
