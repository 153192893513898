/* eslint-disable max-statements */
import { useState } from "react"
import { useHistory } from "react-router-dom"
import {
  SafetiesDetailCountType,
  SafetiesDetailDataSourceType,
} from "../../../../models/detail"
import { useExportFile } from "../useExportFile"
import { useRouters } from "../useRouters"
import { useRowSelection } from "../useRowSelection"
import { useTableConfig } from "../useTableConfig"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { getBreadcrumbItems } from "./getBreadcrumbItems"

export const useVariables = () => {
  const { push } = useHistory()
  const [safetyTravelers, setSafetyTravelers] = useState<
    SafetiesDetailDataSourceType[]
  >([])
  const [countSafetyStatus, setCountSafetyStatus] =
    useState<SafetiesDetailCountType>()
  const [activeKey, setActiveKey] = useState("")

  const { columns } = useTableConfig()
  const { onChange, perItem, rowSelection, selectedRowKeys } = useRowSelection(
    safetyTravelers?.length,
  )
  const {
    goToSafetiesAnswer,
    onGoBack,
    safetyId,
    safetyInfo,
    setSafetyInfo,
    state,
  } = useRouters(selectedRowKeys)

  const { handleExportFile, isLoading, setIsLoading } = useExportFile({
    safetyInfo,
    safetyTravelers,
    selectedRowKeys,
  })

  const {
    isCommonTravelerRefer,
    isHiddenAnswerBtn,
    isHiddenBackBtn,
    isHiddenExportBtn,
    isSafetiesBrowseResponse,
  } = useVariablesAuthority(!state?.isHiddenSubmit)

  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [isResetTable, setIsResetTable] = useState(false)
  const breadcrumbItems = getBreadcrumbItems(
    state?.isFromSCR415,
    state?.isFromSCR416,
    state?.isFromSCR416History,
  )

  return {
    activeKey,
    breadcrumbItems,
    columns,
    countSafetyStatus,
    goToSafetiesAnswer,
    handleExportFile,
    isCommonTravelerRefer,
    isHiddenAnswerBtn,
    isHiddenBackBtn,
    isHiddenExportBtn,
    isLoading,
    isLoadingLayout,
    isResetTable,
    isSafetiesBrowseResponse,
    onChange,
    onGoBack,
    perItem,
    push,
    rowSelection,
    safetyId,
    safetyInfo,
    safetyTravelers,
    selectedRowKeys,
    setActiveKey,
    setCountSafetyStatus,
    setIsLoading,
    setIsLoadingLayout,
    setIsResetTable,
    setSafetyInfo,
    setSafetyTravelers,
  }
}
