import { translateMessage } from "../../../../utils/translateMessage"
import { I18nCode } from "../../../I18nCode"

const {
  CODE_MESSAGE_W0001,
  CODE_MESSAGE_W0002,
  CODE_MESSAGE_W0003,
  CODE_MESSAGE_W0004,
  CODE_MESSAGE_W0005,
  CODE_MESSAGE_W0006,
  CODE_MESSAGE_W0007,
  CODE_MESSAGE_W0008,
  CODE_MESSAGE_W0009,
  CODE_MESSAGE_W0010,
  CODE_MESSAGE_W0011,
  CODE_MESSAGE_W0012,
  CODE_MESSAGE_W0013,
  CODE_MESSAGE_W0014,
  CODE_MESSAGE_W0015,
  CODE_MESSAGE_W0016,
  CODE_MESSAGE_W0017,
  CODE_MESSAGE_W0018,
} = I18nCode

const MESSAGE_W0001 = translateMessage(CODE_MESSAGE_W0001)
const MESSAGE_W0002 = translateMessage(CODE_MESSAGE_W0002)
const MESSAGE_W0003 = translateMessage(CODE_MESSAGE_W0003)
const MESSAGE_W0004 = translateMessage(CODE_MESSAGE_W0004)
const MESSAGE_W0005 = translateMessage(CODE_MESSAGE_W0005)
const MESSAGE_W0006 = translateMessage(CODE_MESSAGE_W0006)
const MESSAGE_W0007 = translateMessage(CODE_MESSAGE_W0007)
const MESSAGE_W0008 = translateMessage(CODE_MESSAGE_W0008)
const MESSAGE_W0009 = translateMessage(CODE_MESSAGE_W0009)
const MESSAGE_W0010 = translateMessage(CODE_MESSAGE_W0010)
const MESSAGE_W0011 = translateMessage(CODE_MESSAGE_W0011)
const MESSAGE_W0012 = translateMessage(CODE_MESSAGE_W0012)
const MESSAGE_W0013 = translateMessage(CODE_MESSAGE_W0013)
const MESSAGE_W0014 = translateMessage(CODE_MESSAGE_W0014)
const MESSAGE_W0015 = translateMessage(CODE_MESSAGE_W0015)
const MESSAGE_W0016 = translateMessage(CODE_MESSAGE_W0016)
const MESSAGE_W0017 = translateMessage(CODE_MESSAGE_W0017)
const MESSAGE_W0018 = translateMessage(CODE_MESSAGE_W0018)

export const TranslateMessageWarning = {
  MESSAGE_W0001,
  MESSAGE_W0002,
  MESSAGE_W0003,
  MESSAGE_W0004,
  MESSAGE_W0005,
  MESSAGE_W0006,
  MESSAGE_W0007,
  MESSAGE_W0008,
  MESSAGE_W0009,
  MESSAGE_W0010,
  MESSAGE_W0011,
  MESSAGE_W0012,
  MESSAGE_W0013,
  MESSAGE_W0014,
  MESSAGE_W0015,
  MESSAGE_W0016,
  MESSAGE_W0017,
  MESSAGE_W0018,
}
