import exportFile from "../../../assets/lang/exportFile/ja.json"
import help from "../../../assets/lang/help/ja.json"
import messageConfirm from "../../../assets/lang/message/confirm/ja.json"
import messageError from "../../../assets/lang/message/error/ja.json"
import messageInfo from "../../../assets/lang/message/info/ja.json"
import messageWarning from "../../../assets/lang/message/warning/ja.json"
import text from "../../../assets/lang/text/ja.json"

export const ja = {
  ...exportFile,
  ...help,
  ...messageConfirm,
  ...messageError,
  ...messageInfo,
  ...messageWarning,
  ...text,
}
