/* eslint-disable max-lines */
import { Translate } from "../../Translate"

const {
  TEXT_T190, // 渡航予定情報の一覧
  TEXT_T226, // 作成・発出
  TEXT_T334, // 応答登録
  TEXT_T335, // 応答履歴
  TEXT_T358, // 安否確認の内容確認
  TEXT_T364, // 安否確認の内容作成・変更
  TEXT_T367, // 安否確認の発出完了
  TEXT_T371, // 安否確認の一覧
  TEXT_T372, // 安否確認の詳細
  TEXT_T406, // 安否確認の応答
  TEXT_T407, // 安否確認情報の代理応答
  TEXT_T410, // 安否確認情報の応答完了
  TEXT_T470, // 安否確認
  TEXT_T519, // ホーム
  TEXT_T668, // 安否確認の表示
} = Translate

const BRCR_SCR412 = [TEXT_T519, TEXT_T470, TEXT_T226, TEXT_T190, TEXT_T358]
const BRCR_SCR413 = [TEXT_T364]
const BRCR_SCR413_FROM_SCR412 = [
  TEXT_T519,
  TEXT_T470,
  TEXT_T226,
  TEXT_T190,
  TEXT_T358,
  TEXT_T364,
]
const BRCR_SCR413_FROM_SCR962 = [
  TEXT_T519,
  TEXT_T470,
  TEXT_T226,
  TEXT_T190,
  TEXT_T364,
]
const BRCR_SCR415 = [
  TEXT_T519,
  TEXT_T470,
  TEXT_T226,
  TEXT_T190,
  TEXT_T358,
  TEXT_T367,
]
const BRCR_SCR416 = [TEXT_T519, TEXT_T470, TEXT_T334, TEXT_T371]
const BRCR_SCR416_HISTORY = [TEXT_T519, TEXT_T470, TEXT_T335, TEXT_T371]
const BRCR_SCR417 = [TEXT_T372]

const BRCR_SCR417_FROM_SCR415 = [
  TEXT_T519,
  TEXT_T470,
  TEXT_T226,
  TEXT_T190,
  TEXT_T358,
  TEXT_T367,
  TEXT_T372,
]

const BRCR_SCR417_FROM_SCR416 = [
  TEXT_T519,
  TEXT_T470,
  TEXT_T334,
  TEXT_T371,
  TEXT_T372,
]
const BRCR_SCR417_FROM_SCR416_HISTORY = [
  TEXT_T519,
  TEXT_T470,
  TEXT_T335,
  TEXT_T371,
  TEXT_T372,
]
const BRCR_SCR418 = [TEXT_T668]
const BRCR_SCR418_FROM_SCR416 = [
  TEXT_T519,
  TEXT_T470,
  TEXT_T334,
  TEXT_T371,
  TEXT_T372,
  TEXT_T668,
]
const BRCR_SCR418_FROM_SCR416_HISTORY = [
  TEXT_T519,
  TEXT_T470,
  TEXT_T335,
  TEXT_T371,
  TEXT_T372,
  TEXT_T668,
]
const BRCR_SCR419 = [TEXT_T406]
const BRCR_SCR419_FROM_SCR416 = [
  TEXT_T519,
  TEXT_T470,
  TEXT_T334,
  TEXT_T371,
  TEXT_T406,
]

const BRCR_SCR419_FROM_SCR416_SUBADMIN = [
  TEXT_T519,
  TEXT_T470,
  TEXT_T334,
  TEXT_T371,
  TEXT_T407,
]
const BRCR_SCR419_FROM_SCR416_HISTORY = [
  TEXT_T519,
  TEXT_T470,
  TEXT_T335,
  TEXT_T371,
  TEXT_T406,
]

const BRCR_SCR419_FROM_SCR417 = [
  TEXT_T519,
  TEXT_T470,
  TEXT_T334,
  TEXT_T371,
  TEXT_T372,
  TEXT_T407,
]

const BRCR_SCR419_FROM_SCR417_SUBADMIN = [
  TEXT_T519,
  TEXT_T470,
  TEXT_T334,
  TEXT_T371,
  TEXT_T372,
  TEXT_T407,
]

const BRCR_SCR419_FROM_SCR417_HISTORY = [
  TEXT_T519,
  TEXT_T470,
  TEXT_T335,
  TEXT_T371,
  TEXT_T372,
  TEXT_T407,
]

const BRCR_SCR420 = [TEXT_T410]
const BRCR_SCR420_FROM_SCR416 = [
  TEXT_T519,
  TEXT_T470,
  TEXT_T334,
  TEXT_T371,
  TEXT_T406,
  TEXT_T410,
]
const BRCR_SCR420_FROM_SCR416_HISTORY = [
  TEXT_T519,
  TEXT_T470,
  TEXT_T335,
  TEXT_T371,
  TEXT_T406,
  TEXT_T410,
]
const BRCR_SCR420_FROM_SCR417 = [
  TEXT_T519,
  TEXT_T470,
  TEXT_T334,
  TEXT_T371,
  TEXT_T372,
  TEXT_T406,
  TEXT_T410,
]

export const BreadcrumbsSafeties = {
  BRCR_SCR412,
  BRCR_SCR413,
  BRCR_SCR413_FROM_SCR412,
  BRCR_SCR413_FROM_SCR962,
  BRCR_SCR415,
  BRCR_SCR416,
  BRCR_SCR416_HISTORY,
  BRCR_SCR417,
  BRCR_SCR417_FROM_SCR415,
  BRCR_SCR417_FROM_SCR416,
  BRCR_SCR417_FROM_SCR416_HISTORY,
  BRCR_SCR418,
  BRCR_SCR418_FROM_SCR416,
  BRCR_SCR418_FROM_SCR416_HISTORY,
  BRCR_SCR419,
  BRCR_SCR419_FROM_SCR416,
  BRCR_SCR419_FROM_SCR416_HISTORY,
  BRCR_SCR419_FROM_SCR416_SUBADMIN,
  BRCR_SCR419_FROM_SCR417,
  BRCR_SCR419_FROM_SCR417_HISTORY,
  BRCR_SCR419_FROM_SCR417_SUBADMIN,
  BRCR_SCR420,
  BRCR_SCR420_FROM_SCR416,
  BRCR_SCR420_FROM_SCR416_HISTORY,
  BRCR_SCR420_FROM_SCR417,
}
