/* eslint-disable complexity */
/* eslint-disable max-statements */
import { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { FormId } from "../../../../../../../constant/FormId"
import { UseSchedulesExportFileProps } from "../../../../models/list"
import { exportFRMDefault } from "./exportDefault"
import { exportFRM101 } from "./exportFRM101"
import { exportFRM102 } from "./exportFRM102"
import { exportFRM301 } from "./exportFRM301"
import { Translate } from "../../../../../../../constant/Translate"

const { DEFAULT, FRM101, FRM102, FRM301 } = FormId

const {
  MESSAGE_C0033,
  MESSAGE_C0034,
  MESSAGE_C0035,
  MESSAGE_C0036,
  MESSAGE_C0044,
  MESSAGE_C0045,
  MESSAGE_C0046,
  MESSAGE_C0047,
} = Translate

export const useExportFile = (props: UseSchedulesExportFileProps) => {
  const { dataSource, selectedRowKeys } = props
  const { push } = useHistory()
  const { pathname } = useLocation()
  const [isOpenModalExport, setIsOpenModalExport] = useState(false)
  const [formId, setFormId] = useState("")
  const [messageConfirmDownload, setMessageConfirmDownload] = useState("")

  const handleCancelModalExport = () => {
    setIsOpenModalExport(false)
  }

  const handleOpenModalExport = (_formId: string) => {
    const getMessage = (msg1: string, msg2: string) =>
      selectedRowKeys?.length ? msg1 : msg2
    if (_formId === DEFAULT) {
      const msg = getMessage(MESSAGE_C0033, MESSAGE_C0034)
      setMessageConfirmDownload(msg)
    }
    if (_formId === FRM101) {
      const msg = getMessage(MESSAGE_C0044, MESSAGE_C0045)
      setMessageConfirmDownload(msg)
    }
    if (_formId === FRM102) {
      const msg = getMessage(MESSAGE_C0046, MESSAGE_C0047)
      setMessageConfirmDownload(msg)
    }
    if (_formId === FRM301) {
      const msg = getMessage(MESSAGE_C0035, MESSAGE_C0036)
      setMessageConfirmDownload(msg)
    }

    setIsOpenModalExport(true)
    setFormId(_formId)
  }
  const handleExportFile = () => {
    try {
      const _dataSource = selectedRowKeys?.length
        ? dataSource.filter((item) =>
            selectedRowKeys.includes(item.info.travelScheduleId),
          )
        : dataSource

      if (formId === DEFAULT) exportFRMDefault(_dataSource, pathname)

      if (formId === FRM101) exportFRM101(_dataSource)

      if (formId === FRM102) exportFRM102(_dataSource)

      if (formId === FRM301) exportFRM301(_dataSource)
    } finally {
      handleCancelModalExport()
    }
  }

  return {
    handleCancelModalExport,
    handleExportFile,
    handleOpenModalExport,
    isOpenModalExport,
    messageConfirmDownload,
    push,
  }
}
