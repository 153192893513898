import { Breadcrumbs } from "../../../../../../../../constant/Breadcrumbs"
import { Routers } from "../../../../../../../../constant/Routers"

const { SCR114, SCR114_USER } = Routers
const { BRCR_SCR114, BRCR_SCR114_USER, BRCR_SCR114_USER_SEARCH } = Breadcrumbs

export const getBreadcrumbItems = (pathname: string) =>
  pathname === SCR114
    ? BRCR_SCR114
    : pathname === SCR114_USER
    ? BRCR_SCR114_USER
    : BRCR_SCR114_USER_SEARCH
