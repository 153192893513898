import { useModal } from "../../../../../../../utils/hook/useModal"

export const useVariablesModal = () => {
  const { isOpen, onCancel, onOpen } = useModal()
  const {
    isOpen: isOpenConfirm,
    onCancel: onCancelConfirm,
    onOpen: onOpenConfirm,
  } = useModal()

  const {
    isOpen: isOpenReload,
    onCancel: onCancelReload,
    onOpen: onOpenReload,
  } = useModal()
  const {
    isOpen: isOpenSuccess,
    onCancel: onOkSuccess,
    onOpen: onOpenSuccess,
  } = useModal()

  return {
    isOpen,
    isOpenConfirm,
    isOpenReload,
    isOpenSuccess,
    onCancel,
    onCancelConfirm,
    onCancelReload,
    onOkSuccess,
    onOpen,
    onOpenConfirm,
    onOpenReload,
    onOpenSuccess,
  }
}
