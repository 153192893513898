import { useState } from "react"
import { useHistory } from "react-router-dom"
import {
  TableErrorRowType,
  CountRecordType,
} from "../../../../../../../_webui/layout/components/Table/Error/models"
import { TravelersBatchUpdateDataType } from "../../../../models/batchUpdate/data"
import { useTable } from "../../../../../../../utils/hook/useTable"
import { useForm } from "antd/lib/form/Form"

export const useVariablesApi = () => {
  const [form] = useForm()
  const { push } = useHistory()
  const [dataTemps, setDataTemps] = useState<TravelersBatchUpdateDataType[]>([])
  const [errorList, setErrorList] = useState<TableErrorRowType[]>([])
  const [countRecord, setCountRecord] = useState<CountRecordType>()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(true)
  const [isResetTable, setIsResetTable] = useState(false)
  const [fileNameList, setFileNameList] = useState<string[]>([])
  const { onChange, perItem } = useTable(dataTemps?.length)

  return {
    countRecord,
    dataTemps,
    errorList,
    fileNameList,
    form,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isResetTable,
    onChange,
    perItem,
    push,
    setCountRecord,
    setDataTemps,
    setErrorList,
    setFileNameList,
    setIsDisabledSubmit,
    setIsLoading,
    setIsLoadingLayout,
    setIsResetTable,
  }
}
