import { FC } from "react"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { Translate } from "../../../../../constant/Translate"
import { SchedulesAddForm } from "../../components/add/Form"
import { useSchedulesEdit } from "./modules/useEdit"

const { TEXT_T292 } = Translate

export const SchedulesEdit: FC = () => {
  const { breadcrumbItems, isLoading, isLoadingLayout, ...rest } =
    useSchedulesEdit()

  return (
    <Layout
      breadcrumbItems={breadcrumbItems}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T292}
    >
      <Card heading={TEXT_T292} isLoading={isLoading}>
        <SchedulesAddForm {...rest} />
      </Card>
    </Layout>
  )
}
