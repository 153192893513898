/* eslint-disable max-lines */
const IMAGE_ALERT_CIRCLE = "/assets/images/alert_circle.svg"
const IMAGE_ARROW_DOWN = "/assets/images/arrow_down.svg"
const IMAGE_ARROW_UP = "/assets/images/arrow_up.svg"
const IMAGE_BG_LOGIN = "/assets/images/bg_login.png"
const IMAGE_BG_TIMEZONE = "/assets/images/bg_top_timezone.png"
const IMAGE_BTN_ADDITION = "/assets/images/btn_addition.svg"
const IMAGE_BTN_DELETE = "/assets/images/btn_delete.svg"
const IMAGE_CALENDAR = "/assets/images/calendar.svg"
const IMAGE_CHECK_CIRCLE = "/assets/images/check_circle.svg"
const IMAGE_CLEAR_CIRCLE = "/assets/images/clear_circle.svg"
const IMAGE_CLOCK = "/assets/images/clock.svg"
const IMAGE_EXCLAMATION = "/assets/images/exclamation.svg"
const IMAGE_HEADER_ICON_01 = "/assets/images/header_icon_01.svg"
const IMAGE_HEADER_ICON_02 = "/assets/images/header_icon_02.svg"
const IMAGE_HEADER_ICON_03 = "/assets/images/header_icon_03.svg"
const IMAGE_HEADLINE_BG = "/assets/images/headline_bg.svg"
const IMAGE_HELP_CIRCLE = "/assets/images/help_circle.svg"
const IMAGE_JICA_LOGO_PC = "/assets/images/jica_logo_pc.png"
const IMAGE_LIST_CHECK = "/assets/images/list_check.svg"
const IMAGE_LOGO = "/assets/images/logo.svg"
const IMAGE_MENU_ARROW_DOWN = "/assets/images/menu_arrow_down.svg"
const IMAGE_MENU_ARROW_UP = "/assets/images/menu_arrow_up.svg"
const IMAGE_MENU_CLOSE = "/assets/images/menu_close.svg"
const IMAGE_MENU_ICON_01 = "/assets/images/menu_icon_01.svg"
const IMAGE_MENU_ICON_02 = "/assets/images/menu_icon_02.svg"
const IMAGE_MENU_ICON_03 = "/assets/images/menu_icon_03.svg"
const IMAGE_MENU_ICON_04 = "/assets/images/menu_icon_04.svg"
const IMAGE_MENU_ICON_05 = "/assets/images/menu_icon_05.svg"
const IMAGE_MENU_ICON_06 = "/assets/images/menu_icon_06.svg"
const IMAGE_MENU_ICON_07 = "/assets/images/menu_icon_07.svg"
const IMAGE_MENU_ICON_08 = "/assets/images/menu_icon_08.svg"
const IMAGE_MENU_ICON_09 = "/assets/images/menu_icon_09.svg"
const IMAGE_MENU_ICON_10 = "/assets/images/menu_icon_10.svg"
const IMAGE_MENU_OPEN = "/assets/images/menu_open.svg"
const IMAGE_OPEN_IN_NEW = "/assets/images/open_in_new.svg"
const IMAGE_PAGE_ARROW_LEFT = "/assets/images/page_arrow_left.svg"
const IMAGE_PAGE_ARROW_RIGHT = "/assets/images/page_arrow_right.svg"
const IMAGE_PASSWORD_INVISIBLE = "/assets/images/password_invisible.svg"
const IMAGE_PASSWORD_VISIBLE = "/assets/images/password_visible.svg"
const IMAGE_PATTERN_1 = "/assets/images/pattern-1.png"
const IMAGE_PATTERN_2 = "/assets/images/pattern-2.png"
const IMAGE_PATTERN_3 = "/assets/images/pattern-3.png"
const IMAGE_PATTERN_4 = "/assets/images/pattern-4.png"
const IMAGE_PATTERN_5 = "/assets/images/pattern-5.png"
const IMAGE_PATTERN_6 = "/assets/images/pattern-6.png"
const IMAGE_PATTERN_7 = "/assets/images/pattern-7.png"
const IMAGE_PRIVILEGE_MANAGEMENT_TABLE =
  "/assets/images/privilege_management_table.png"
const IMAGE_REFRESH = "/assets/images/refresh.svg"
const IMAGE_SEARCH = "/assets/images/search.svg"
const IMAGE_SP_MENU = "/assets/images/sp_menu.svg"
const IMAGE_TRIANGLE_DOWN = "/assets/images/triangle_down.svg"
const IMAGE_TRIANGLE_UP = "/assets/images/triangle_up.svg"
const IMAGE_URL = "/assets/images/url.svg"

export const Images = {
  IMAGE_ALERT_CIRCLE,
  IMAGE_ARROW_DOWN,
  IMAGE_ARROW_UP,
  IMAGE_BG_LOGIN,
  IMAGE_BG_TIMEZONE,
  IMAGE_BTN_ADDITION,
  IMAGE_BTN_DELETE,
  IMAGE_CALENDAR,
  IMAGE_CHECK_CIRCLE,
  IMAGE_CLEAR_CIRCLE,
  IMAGE_CLOCK,
  IMAGE_EXCLAMATION,
  IMAGE_HEADER_ICON_01,
  IMAGE_HEADER_ICON_02,
  IMAGE_HEADER_ICON_03,
  IMAGE_HEADLINE_BG,
  IMAGE_HELP_CIRCLE,
  IMAGE_JICA_LOGO_PC,
  IMAGE_LIST_CHECK,
  IMAGE_LOGO,
  IMAGE_MENU_ARROW_DOWN,
  IMAGE_MENU_ARROW_UP,
  IMAGE_MENU_CLOSE,
  IMAGE_MENU_ICON_01,
  IMAGE_MENU_ICON_02,
  IMAGE_MENU_ICON_03,
  IMAGE_MENU_ICON_04,
  IMAGE_MENU_ICON_05,
  IMAGE_MENU_ICON_06,
  IMAGE_MENU_ICON_07,
  IMAGE_MENU_ICON_08,
  IMAGE_MENU_ICON_09,
  IMAGE_MENU_ICON_10,
  IMAGE_MENU_OPEN,
  IMAGE_OPEN_IN_NEW,
  IMAGE_PAGE_ARROW_LEFT,
  IMAGE_PAGE_ARROW_RIGHT,
  IMAGE_PASSWORD_INVISIBLE,
  IMAGE_PASSWORD_VISIBLE,
  IMAGE_PATTERN_1,
  IMAGE_PATTERN_2,
  IMAGE_PATTERN_3,
  IMAGE_PATTERN_4,
  IMAGE_PATTERN_5,
  IMAGE_PATTERN_6,
  IMAGE_PATTERN_7,
  IMAGE_PRIVILEGE_MANAGEMENT_TABLE,
  IMAGE_REFRESH,
  IMAGE_SEARCH,
  IMAGE_SP_MENU,
  IMAGE_TRIANGLE_DOWN,
  IMAGE_TRIANGLE_UP,
  IMAGE_URL,
}
