/* eslint-disable max-statements */
/* eslint-disable max-lines */
import { File } from "../../../../../../../constant/File"
import { Routers } from "../../../../../../../constant/Routers"
import { Translate } from "../../../../../../../constant/Translate"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { openNotification } from "../../../../../../../utils/openNotification"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { BreakMessage } from "../../../../../../../_webui/layout/components/Typography/BreakMessage"
import { UploadFileType } from "../../../../../../../_webui/layout/components/Upload/models"
import { TravelersFileAddBatchImportType } from "../../../../../travelers/models/batchImport"
import { scheduleApi } from "../../../../apis"
import { SchedulesBatchImportErrorType } from "../../../../models/batchImport"
import {
  SchedulesBatchUpdateCheck,
  SchedulesBatchUpdateDataResType,
  SchedulesBatchUpdateErrorType,
  UseSchedulesBatchUpdateApiProps,
} from "../../../../models/batchUpdate"
import { convertDataSource } from "./convertDataSource"
import { getFileNameRcFile } from "../../../../../../../utils/getFileNameRcFile"
import { useVariablesApi } from "../useVariablesApi"

const { SCR221: SCR221_URL, SCR973 } = Routers
const { MESSAGE_E0022, MESSAGE_E0023, MESSAGE_E0037, MESSAGE_I0013 } = Translate
const {
  FILE_COLUMN_LIMIT_SCHEDULES,
  FILE_COLUMN_LIMIT_SCHEDULES_USER,
  FILE_SIZE_LIMIT_KB,
  FILE_SIZE_LIMIT_MB,
  FILE_TYPE_ALLOW,
  SCR221,
  SCR221_MENU_ID,
  SCR221_USER_MENU_ID,
} = File

export const useApi = (props: UseSchedulesBatchUpdateApiProps) => {
  const { onCancel, onCancelUploaded } = props
  const {
    countRecord,
    dataTemps,
    errorList,
    fileNameList,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isResetTable,
    onChange,
    pathname,
    perItem,
    push,
    setCountRecord,
    setDataTemps,
    setErrorList,
    setFileNameList,
    setIsDisabledSubmit,
    setIsLoading,
    setIsLoadingLayout,
    setIsResetTable,
  } = useVariablesApi()

  const handlePushStateError = (error: AxiosErrorType) => {
    const stateError = pushStateError(error)
    push({ pathname: SCR973, state: stateError })
  }

  const bulkCheck = () => {
    // APIを呼び出し、APIからの応答結果を「一括チェック応答」変数に設定する。
    scheduleApi
      .bulkCheck(SCR221)
      .then((bulkCheckTemp: SchedulesBatchUpdateCheck) => {
        const { message } = bulkCheckTemp
        if (message) {
          // 「TBL_TEMP_FOR_UPLOAD」一時テーブルのデータ削除の確認ダイアログを表示する。
          bulkDeleteData()
        } else {
          setIsLoadingLayout(false)
        }
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        handlePushStateError(error)
      })
  }

  const bulkData = () => {
    setIsLoading(true)
    setIsResetTable(true)
    setTimeout(() => {
      // APIを呼び出し、「TBL_TEMP_FOR_UPLOAD」の一時テーブルからデータを取得し、返却結果を「返却一時データ」変数に設定する。
      scheduleApi
        .bulkData(SCR221)
        .then((dataTempsResponse: SchedulesBatchUpdateDataResType[]) => {
          //「M.dataTemps」の一覧にデータを設定する。
          const _res = convertDataSource(dataTempsResponse)
          sessionStorage.setItem("dataUpload", SCR221)
          setDataTemps(_res)
          setErrorList([])
          onChange(1, 25)
        })
        .catch((error: AxiosErrorType) => {
          // 「SCR973_エラー情報の表示画面」に遷移する。
          handlePushStateError(error)
        })
        .finally(() => {
          setIsLoading(false)
          setIsDisabledSubmit(false)
          setIsResetTable(false)
        })
    }, 500)
  }

  const bulkUpdate = () => {
    const menuId =
      pathname === SCR221_URL ? SCR221_MENU_ID : SCR221_USER_MENU_ID
    setIsLoading(true)
    setTimeout(() => {
      // APIを呼び出し、渡航予定情報を更新し、返却結果を「M.bulkUpdate」変数に設定する。
      scheduleApi
        .bulkUpdate(menuId)
        .then((bulkUpdate: SchedulesBatchUpdateErrorType) => {
          // ComponentErrorを表示する。
          if (bulkUpdate.errors.length) {
            setErrorList(bulkUpdate.errors)
          }

          // バックエンドから戻す「I0013」のメッセージが表示される。
          if (bulkUpdate?.execute?.fail === 0) {
            openNotification(MESSAGE_I0013)
            setDataTemps([])
          }

          //「渡航予定情報の結果件数」コンポーネントの値を表示する。
          sessionStorage.removeItem("dataUpload")
          setCountRecord(bulkUpdate.execute)
          setIsLoading(false)
          setIsDisabledSubmit(true)
          bulkDeleteData()
        })
        .catch((error: AxiosErrorType) => {
          // 「SCR973_エラー情報の表示画面」に遷移する。
          handlePushStateError(error)
        })
    }, 500)
  }

  const bulkDeleteData = () => {
    // APIを呼び出し、APIからの応答結果を「一時削除応答」変数に設定する。
    scheduleApi
      .bulkDeleteData(SCR221)
      .then(() => {
        // モーダルクローズを実行する
        sessionStorage.removeItem("dataUpload")
        onCancelUploaded()
        onCancel()
        setIsLoadingLayout(false)
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        handlePushStateError(error)
      })
  }

  const setDataBeforeUploadError = (message: string, validValue: string) => {
    openNotification(<BreakMessage message={message} validValue={validValue} />)
    setDataTemps([])
    setErrorList([])
    setFileNameList([])
  }
  const bulkFileUpdate = (fileInfoCSV: UploadFileType) => {
    const formData = new FormData()
    formData.append("file", fileInfoCSV)
    setIsLoading(true)
    setIsResetTable(true)
    //「TBL_TEMP_FOR_UPLOAD」一時テーブルにCSVファイルのデータをアップロードする。
    scheduleApi
      .bulkFileUpdate(formData)
      .then((uploadTemp: SchedulesBatchImportErrorType) => {
        // バックエンドから戻す「E0037」のメッセージが表示される。
        onChangeUploadFile({ response: uploadTemp, status: "done" })
        if (getFileNameRcFile(fileInfoCSV)) {
          setFileNameList([fileInfoCSV?.name])
        }
      })
      .catch((error) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        onChangeUploadFile({ response: error, status: "error" })
        setFileNameList([])
      })
      .finally(() => {
        setIsLoading(false)
        setIsResetTable(false)
      })
  }

  const beforeUpload = (
    fileInfoCSV: UploadFileType,
    uploadCallback: (upload: boolean) => void,
  ) => {
    if (typeof fileInfoCSV !== "string") {
      const { type, size } = fileInfoCSV
      const fileSize = size / 1024
      const isValidFileType = type.includes(FILE_TYPE_ALLOW)
      const isValidFileSize = fileSize < FILE_SIZE_LIMIT_KB
      const isValidFileSizeZero = fileSize === 0

      // ファイル拡張子のバリデーションチェックを行う。
      if (!isValidFileType) {
        setDataBeforeUploadError(MESSAGE_E0022, FILE_TYPE_ALLOW)
        uploadCallback(false)
        return false
      }

      // ファイルサイズのバリデーションチェックを行う。
      if (!isValidFileSize) {
        setDataBeforeUploadError(MESSAGE_E0023, String(FILE_SIZE_LIMIT_MB))
        uploadCallback(false)
        return false
      }

      // ファイルの項目個数のバリデーションチェックを行う。
      if (isValidFileSizeZero) {
        setDataBeforeUploadError(
          MESSAGE_E0037,
          String(FILE_COLUMN_LIMIT_SCHEDULES),
        )
        uploadCallback(false)
        return false
      }

      const reader = new FileReader()

      reader.onload = (event: ProgressEvent<FileReader>) => {
        const fileData = String(event.target?.result)
        const columnNames = fileData?.substring(0, fileData.indexOf("\r\n"))
        const fileColumns = columnNames.split(",")

        const columnLimit =
          pathname === SCR221_URL
            ? FILE_COLUMN_LIMIT_SCHEDULES_USER
            : FILE_COLUMN_LIMIT_SCHEDULES
        const isValidColumns = fileColumns.length === columnLimit

        // ファイルの項目個数のバリデーションチェックを行う。
        if (!isValidColumns) {
          setDataBeforeUploadError(MESSAGE_E0037, String(columnLimit))
          uploadCallback(false)
        } else {
          uploadCallback(true)
        }
      }

      reader.readAsText(fileInfoCSV)
    }
  }

  const onChangeUploadFile = (info: TravelersFileAddBatchImportType) => {
    const { status, response } = info
    if (status === "done") {
      if (response.errors?.length) {
        setErrorList(response.errors)
        setIsDisabledSubmit(true)
      } else {
        bulkData()
      }
      setCountRecord(response?.execute)
    } else if (status === "error") {
      setDataBeforeUploadError(
        MESSAGE_E0037,
        String(FILE_COLUMN_LIMIT_SCHEDULES),
      )
      setIsDisabledSubmit(true)
    }
  }

  return {
    beforeUpload,
    bulkCheck,
    bulkDeleteData,
    bulkFileUpdate,
    bulkUpdate,
    countRecord,
    dataTemps,
    errorList,
    fileNameList,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isResetTable,
    onChange,
    perItem,
  }
}
