/* eslint-disable max-statements */
/* eslint-disable max-lines */
import { File } from "../../../../../../../constant/File"
import { Routers } from "../../../../../../../constant/Routers"
import { Translate } from "../../../../../../../constant/Translate"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { openNotification } from "../../../../../../../utils/openNotification"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { BreakMessage } from "../../../../../../../_webui/layout/components/Typography/BreakMessage"
import { UploadFileType } from "../../../../../../../_webui/layout/components/Upload/models"
import { SchedulesBatchImportErrorType } from "../../../../../schedules/models/batchImport"
import { travelerApi } from "../../../../apis"
import { TravelersFileAddBatchImportType } from "../../../../models/batchImport"
import {
  TravelersBatchUpdateCheck,
  TravelersBatchUpdateErrorType,
  UseTravelersBatchUpdateApiProps,
} from "../../../../models/batchUpdate"
import { TravelersBatchUpdateDataType } from "../../../../models/batchUpdate/data"
import { useVariablesApi } from "../useVariablesApi"
import { getFileNameRcFile } from "../../../../../../../utils/getFileNameRcFile"
import { setFieldsErrors } from "../../../../../../../utils/setFieldsErrors"

const { SCR973 } = Routers
const { MESSAGE_E0022, MESSAGE_E0023, MESSAGE_E0037, MESSAGE_I0007 } = Translate
const {
  FILE_COLUMN_LIMIT_TRAVELERS,
  FILE_SIZE_LIMIT_KB,
  FILE_SIZE_LIMIT_MB,
  FILE_TYPE_ALLOW,
  SCR123,
} = File

export const useApi = (props: UseTravelersBatchUpdateApiProps) => {
  const { onCancel, onCancelUpdate } = props
  const {
    countRecord,
    dataTemps,
    errorList,
    fileNameList,
    form,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isResetTable,
    onChange,
    perItem,
    push,
    setCountRecord,
    setDataTemps,
    setErrorList,
    setFileNameList,
    setIsDisabledSubmit,
    setIsLoading,
    setIsLoadingLayout,
    setIsResetTable,
  } = useVariablesApi()

  const handlePushStateError = (error: AxiosErrorType) => {
    const stateError = pushStateError(error)
    push({ pathname: SCR973, state: stateError })
  }

  const handleSubmitUpdate = (
    bulkUpdate: TravelersBatchUpdateErrorType,
    message: string,
  ) => {
    setIsLoading(false)

    // ComponentErrorを表示する。
    if (bulkUpdate.errors.length) {
      setErrorList(bulkUpdate.errors)
    }

    // 「渡航者情報の結果件数」コンポーネントの値を表示する。
    if (bulkUpdate?.execute?.fail === 0) {
      openNotification(message)
      setDataTemps([])
    }

    // 「渡航者情報の結果件数」コンポーネントの値を表示する。
    setCountRecord(bulkUpdate.execute)
    onCancel()
  }

  const bulkUpdate = () => {
    setIsLoading(true)
    setTimeout(() => {
      // APIを呼び出し、APIからの応答結果を「一括更新応答」変数に設定する。
      travelerApi
        .bulkUpdate({
          registerRequestUserId: form.getFieldValue("registerRequestUserId"),
          screenId: SCR123,
        })
        .then((bulkUpdate: TravelersBatchUpdateErrorType) => {
          // バックエンドから戻す「I0007」のメッセージが表示される。
          sessionStorage.removeItem("dataUpload")
          handleSubmitUpdate(bulkUpdate, MESSAGE_I0007)
          setIsDisabledSubmit(true)
          bulkDeleteData()
        })
        .catch((error: AxiosErrorType) => {
          if (error?.debug?.response?.data?.entityName)
            setFieldsErrors(error, form)
          else handlePushStateError(error) // 「SCR973_エラー情報の表示画面」に遷移する。
          setIsLoading(false)
        })
    }, 500)
  }

  const bulkDeleteData = () => {
    // APIを呼び出し、APIからの応答結果を「一時削除応答」変数に設定する。
    travelerApi
      .bulkDeleteData(SCR123)
      .then(() => {
        // モーダルクローズを実行する
        sessionStorage.removeItem("dataUpload")
        onCancelUpdate()
        onCancel()
        setIsLoadingLayout(false)
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        handlePushStateError(error)
      })
  }

  const bulkCheck = () => {
    // APIを呼び出し、APIからの応答結果を「一括チェック応答」変数に設定する。
    travelerApi
      .bulkCheck(SCR123)
      .then((bulkCheckTemp: TravelersBatchUpdateCheck) => {
        const { message } = bulkCheckTemp
        if (message) {
          // 「TBL_TEMP_FOR_UPLOAD」一時テーブルのデータ削除の確認ダイアログを表示する。
          bulkDeleteData()
        } else {
          setIsLoadingLayout(false)
        }
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        handlePushStateError(error)
      })
  }

  const bulkData = () => {
    setIsLoading(true)
    setIsResetTable(true)
    setTimeout(() => {
      // APIを呼び出し、APIからの応答結果を「返却一時データ」変数に設定する。
      travelerApi
        .bulkData(SCR123)
        .then((dataTemps: TravelersBatchUpdateDataType[]) => {
          // 「M.dataTemps」の一覧にデータを設定する。
          sessionStorage.setItem("dataUpload", SCR123)
          setDataTemps(dataTemps)
          setErrorList([])
          onChange(1, 25)
        })
        .catch((error: AxiosErrorType) => {
          // 「SCR973_エラー情報の表示画面」に遷移する。
          handlePushStateError(error)
        })
        .finally(() => {
          setIsLoading(false)
          setIsDisabledSubmit(false)
          setIsResetTable(false)
        })
    }, 500)
  }

  const setDataBeforeUploadError = (message: string, validValue: string) => {
    openNotification(<BreakMessage message={message} validValue={validValue} />)
    setDataTemps([])
    setErrorList([])
    setFileNameList([])
  }

  const bulkFileUpdate = (fileInfoCSV: UploadFileType) => {
    const formData = new FormData()
    formData.append("file", fileInfoCSV)
    setIsLoading(true)
    setIsResetTable(true)
    // APIを呼び出し、APIからの応答結果を「一時アップロード応答」変数に設定する。
    travelerApi
      .bulkFileUpdate(formData)
      .then((uploadTemp: SchedulesBatchImportErrorType) => {
        // バックエンドから戻す「E0037」のメッセージが表示される。
        onChangeUploadFile({ response: uploadTemp, status: "done" })
        if (getFileNameRcFile(fileInfoCSV)) {
          setFileNameList([fileInfoCSV?.name])
        }
      })
      .catch((error) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        onChangeUploadFile({ response: error, status: "error" })
        setFileNameList([])
      })
      .finally(() => {
        setIsLoading(false)
        setIsResetTable(false)
      })
  }

  const beforeUpload = (
    fileInfoCSV: UploadFileType,
    uploadCallback: (upload: boolean) => void,
  ) => {
    if (typeof fileInfoCSV !== "string") {
      const { type, size } = fileInfoCSV
      const fileSize = size / 1024
      const isValidFileType = type.includes(FILE_TYPE_ALLOW)
      const isValidFileSize = fileSize < FILE_SIZE_LIMIT_KB
      const isValidFileSizeZero = fileSize === 0

      // ファイル拡張子のバリデーションチェックを行う。
      if (!isValidFileType) {
        setDataBeforeUploadError(MESSAGE_E0022, FILE_TYPE_ALLOW)
        uploadCallback(false)
        return false
      }

      // ファイルサイズのバリデーションチェックを行う。
      if (!isValidFileSize) {
        setDataBeforeUploadError(MESSAGE_E0023, String(FILE_SIZE_LIMIT_MB))
        uploadCallback(false)
        return false
      }

      // ファイルの項目個数のバリデーションチェックを行う。
      if (isValidFileSizeZero) {
        setDataBeforeUploadError(
          MESSAGE_E0037,
          String(FILE_COLUMN_LIMIT_TRAVELERS),
        )
        uploadCallback(false)
        return false
      }

      const reader = new FileReader()
      reader.onload = (event: ProgressEvent<FileReader>) => {
        const fileData = String(event.target?.result)
        const columnNames = fileData?.substring(0, fileData.indexOf("\r\n"))
        const columns = columnNames.split(",")
        const isValidColumns = columns.length === FILE_COLUMN_LIMIT_TRAVELERS

        // ファイルの項目個数のバリデーションチェックを行う。
        if (!isValidColumns) {
          setDataBeforeUploadError(
            MESSAGE_E0037,
            String(FILE_COLUMN_LIMIT_TRAVELERS),
          )
          uploadCallback(false)
        } else {
          uploadCallback(true)
        }
      }

      reader.readAsText(fileInfoCSV)
    }
  }

  const onChangeUploadFile = (info: TravelersFileAddBatchImportType) => {
    const { status, response } = info
    if (status === "done") {
      if (response.errors?.length) {
        setErrorList(response.errors)
        setIsDisabledSubmit(true)
      } else {
        bulkData()
      }
      setCountRecord(response?.execute)
    } else if (status === "error") {
      setDataBeforeUploadError(
        MESSAGE_E0037,
        String(FILE_COLUMN_LIMIT_TRAVELERS),
      )
      setIsDisabledSubmit(true)
    }
  }

  return {
    beforeUpload,
    bulkCheck,
    bulkDeleteData,
    bulkFileUpdate,
    bulkUpdate,
    countRecord,
    dataTemps,
    errorList,
    fileNameList,
    form,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isResetTable,
    onChange,
    onChangeUploadFile,
    perItem,
  }
}
