/* eslint-disable max-lines */
import { GeneralCode } from "../../../../../../../constant/GeneralCode"
import { Routers } from "../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { concat } from "../../../../../../../utils/concat"
import { openNotification } from "../../../../../../../utils/openNotification"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { attentionApi } from "../../../../apis"
import {
  AttentionInfoReqType,
  AttentionSendResType,
  DestinationsType,
} from "../../../../models/attention"
import { translateMessage } from "../../../../../../../utils/translateMessage"
import { getFullName } from "../../../../../../../utils/getFullName"
import {
  AttentionDataTableType,
  AttentionFormType,
} from "../../../../components/attention/Form/models"
import { useVariablesApi } from "../useVariablesApi"
import { getSafetiesSelectedValue } from "./getSafetiesSelectedValue"

const { SCR312, SCR315, SCR973 } = Routers
const { CTGR_OTHER } = GeneralCode

export const useApi = () => {
  const {
    form,
    isDisabledBtnSelect,
    isLoading,
    isOpen,
    isOpenForm,
    onCancel,
    onCancelForm,
    onOpen,
    onOpenForm: _onOpenForm,
    push,
    rowSelectionAttention,
    safetiesAll,
    safetiesList,
    safetiesSelected,
    selectedKeys,
    selectedRowKeys,
    setIsLoading,
    setSafetiesAll,
    setSafetiesList,
    setSafetiesSelected,
    setSelectedKeys,
    setSelectedRowKeys,
    state,
  } = useVariablesApi()

  const getSafeties = (_isOpenForm: boolean, params?: AttentionFormType) => {
    setIsLoading(true)
    setSelectedRowKeys([])
    attentionApi
      .getSafeties(params)
      .then((res: AttentionDataTableType[]) => {
        setSafetiesList(res)
        setIsLoading(false)
        if (_isOpenForm) {
          _onOpenForm()
        }
        if (!params) {
          setSafetiesAll(res)
        }
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const stateError = pushStateError(error)
        push({ pathname: SCR973, state: stateError })
      })
  }

  const onOpenForm = () => {
    form.resetFields()
    getSafeties(true)
  }

  const onFinish = (values: AttentionFormType) => {
    getSafeties(false, values)
  }

  const onAddOrRemoveSafeties = (isAdd: boolean) => {
    const keys = [...selectedKeys, ...selectedRowKeys]
    const _selectedKeys = isAdd
      ? [...new Set(keys)]
      : [...new Set(keys.filter((item) => !selectedRowKeys.includes(item)))]
    const { safetiesSelected: _safetiesSelected } = getSafetiesSelectedValue(
      safetiesAll,
      _selectedKeys,
    )
    setSelectedKeys(_selectedKeys)
    setSafetiesSelected(_safetiesSelected)
    onCancelForm()
  }

  const onSend = () => {
    const destinations: DestinationsType[] = state?.travelerInfoList.map(
      (item) => ({
        emailAddress1: item.emailAddress1,
        emailAddress2: item.emailAddress2,
        fullNameKanji: getFullName(item.kanjiSurname, item.kanjiName, true),
        fullNameRomaji: getFullName(item.romajiSurname, item.romajiName),
        travelScheduleId: item.travelScheduleId,
      }),
    )
    const { safeties } = getSafetiesSelectedValue(safetiesList, selectedKeys)
    const attentionParams: AttentionInfoReqType = {
      destinations,
      info: {
        ...state?.transmissionInfo,
        categoryValue: concat(state?.transmissionInfo?.categoryValue),
      },
      safeties,
    }

    setIsLoading(true)
    onCancel()
    // APIを呼び出し、APIからの応答結果を「注意喚起応答」変数に設定する。
    attentionApi
      .addAttention(attentionParams)
      .then((res: AttentionSendResType) => {
        const { travelScheduleIdList } = res
        // 「SCR315_注意喚起の実施後画面」に遷移する。
        const travelerInfoList = state.travelerInfoList.filter((item) =>
          travelScheduleIdList.includes(item.travelScheduleId),
        )
        push({
          pathname: SCR315,
          state: {
            ...state,
            safetiesSelected,
            sourceURL: SCR312,
            transmissionInfo: {
              ...state.transmissionInfo,
              categoryId: state?.transmissionInfo.categoryId.startsWith(
                CTGR_OTHER,
              )
                ? CTGR_OTHER
                : state.transmissionInfo?.categoryId,
            },
            travelerInfoList,
          },
        })
      })
      .catch((error: AxiosErrorType) => {
        const data = error?.debug?.response?.data
        if (data?.message === "E0098" || data?.message === "E0046") {
          setIsLoading(false)
          openNotification(translateMessage(`MESSAGE.${data.message}`))
        } else {
          // 「SCR973_エラー情報の表示画面」に遷移する。
          const stateError = pushStateError(error)
          push({ pathname: SCR973, state: stateError })
        }
      })
  }

  return {
    form,
    isDisabledBtnSelect,
    isLoading,
    isOpen,
    isOpenForm,
    onAddOrRemoveSafeties,
    onCancel,
    onCancelForm,
    onFinish,
    onOpen,
    onOpenForm,
    onSend,
    rowSelectionAttention,
    safetiesList,
    safetiesSelected,
    selectedKeys,
    setSafetiesSelected,
    setSelectedKeys,
    state,
  }
}
