import { Translate } from "../../../../../../../../constant/Translate"
import { replaceString } from "../../../../../../../../utils/replaceString"
import { Grid } from "../../../../../../../../_webui/layout/components/Grid"
import { GridItemType } from "../../../../../../../../_webui/layout/components/Grid/models"
import { TabItemsProps } from "../../../../../../../../_webui/layout/components/Tabs/models"
import { ScheduleLodgingsType } from "../../../../../models/addSuccess"

const { TEXT_T210, TEXT_T251, TEXT_T252, TEXT_T253, TEXT_T254, TEXT_T267 } =
  Translate

export const useLodgingTabsConfig = (
  schedulesLodgings: ScheduleLodgingsType[],
): TabItemsProps[] =>
  schedulesLodgings.map((item, index) => {
    const itemGrid: GridItemType[] = [
      { key: TEXT_T251, text: item.lodgingCheckin },
      { key: TEXT_T252, text: item.lodgingCheckout },
      { key: TEXT_T210, text: item.lodgingName },
      { key: TEXT_T253, text: item.lodgingAddress },
      { key: TEXT_T254, text: item.lodgingPhoneNumber },
    ]
    return {
      children: <Grid gridItems={itemGrid} />,
      key: `${index + 1}`,
      label: replaceString(TEXT_T267, ` ${index + 1}`),
    }
  })
