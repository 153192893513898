import { apiService } from "../../../../infrastructure/apiService"
import { Environment } from "../../../../constant/Environment"
import { AttentionInfoReqType } from "../models/attention"
import { Endpoint } from "../../../../constant/Endpoint"
import { replaceStringParam } from "../../../../utils/replaceStringParam"
import { AttentionsListSearchConditionType } from "../models/list"
import { AttentionTravelersReqType } from "../models/info"
import {
  AttentionsDetailReqType,
  AttentionsExportReqType,
  AttentionsReqType,
} from "../models/detail"
import { AttentionFormType } from "../components/attention/Form/models"

const { ATTENTION_ENDPOINT, TRAVELER_ENDPOINT } = Environment
const {
  FNC2_Add_Attention,
  FNC2_Confirm_Attention,
  FNC2_Count,
  FNC2_Export,
  FNC2_Get_Attention_Travelers,
  FNC2_Get_Attention,
  FNC2_Get_Attentions,
  FNC1A_Get_Safeties,
} = Endpoint

export const attentionApi = {
  addAttention: (data: AttentionInfoReqType) =>
    apiService({
      data,
      method: "POST",
      url: `${ATTENTION_ENDPOINT}${FNC2_Add_Attention}`,
    }),
  confirmAttention: (data: AttentionTravelersReqType) =>
    apiService({
      data,
      method: "PUT",
      url: `${ATTENTION_ENDPOINT}${FNC2_Confirm_Attention}`,
    }),
  count: (attentionId: string) =>
    apiService({
      method: "GET",
      url: `${ATTENTION_ENDPOINT}${replaceStringParam(
        FNC2_Count,
        attentionId,
      )}`,
    }),

  export: (data: AttentionsExportReqType) =>
    apiService({
      data: data.listTravelerId,
      method: "POST",
      url: `${ATTENTION_ENDPOINT}${replaceStringParam(
        FNC2_Export,
        data.attentionId,
      )}`,
    }),

  getAttention: (param: AttentionsReqType) =>
    apiService({
      method: "GET",
      params: {
        attentionDetailId: param.attentionDetailId,
      },
      url: `${ATTENTION_ENDPOINT}${replaceStringParam(
        FNC2_Get_Attention,
        param.attentionId,
      )}`,
    }),

  getAttentionTravelers: (param: AttentionsDetailReqType) =>
    apiService({
      method: "GET",
      url: `${ATTENTION_ENDPOINT}${replaceStringParam(
        FNC2_Get_Attention_Travelers,
        param.attentionId,
      )}?attentionStatus=${param.attentionStatus}`,
    }),

  getAttentions: (data?: Pick<AttentionsListSearchConditionType, never>) =>
    apiService({
      data,
      method: "POST",
      url: `${ATTENTION_ENDPOINT}${FNC2_Get_Attentions}`,
    }),

  getSafeties: (params?: AttentionFormType) =>
    apiService({
      method: "GET",
      params: params,
      url: `${TRAVELER_ENDPOINT}${FNC1A_Get_Safeties}`,
    }),
}
