import { Breadcrumbs } from "../../../../../../../../constant/Breadcrumbs"

const {
  BRCR_SCR417,
  BRCR_SCR417_FROM_SCR415,
  BRCR_SCR417_FROM_SCR416,
  BRCR_SCR417_FROM_SCR416_HISTORY,
} = Breadcrumbs

export const getBreadcrumbItems = (
  isFromSCR415?: boolean,
  isFromSCR416?: boolean,
  isFromSCR416History?: boolean,
) => {
  if (isFromSCR415) return BRCR_SCR417_FROM_SCR415

  if (isFromSCR416) return BRCR_SCR417_FROM_SCR416

  if (isFromSCR416History) return BRCR_SCR417_FROM_SCR416_HISTORY

  return BRCR_SCR417
}
