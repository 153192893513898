/* eslint-disable sort-keys */
/* eslint-disable sort-keys-fix/sort-keys-fix */
import { getDayWithFormat } from "../../../../../../../../../../utils/getDate"
import { getFullName } from "../../../../../../../../../../utils/getFullName"
import { getValueByLanguage } from "../../../../../../../../../../utils/getValueByLanguage"
import { SchedulesListDataType } from "../../../../../../../models/list"
import { SchedulesExportExcelFRM301Type } from "../../../../../../../models/list/export"
import { Timezone } from "../../../../../../../../../../constant/Timezone"
import { getDispatchTypeDetailName } from "../../../../../../../../../../utils/getDispatchTypeDetailName"
import { Variables } from "../../../../../../../../travelers/constants/edit/variables"

const { DATE_FORMAT_EN } = Timezone
const { KEYS_ACCOMPANYING_FAMILY } = Variables

const getDisplayDateTime = (csvDateTime: string) => {
  return csvDateTime
    ? getDayWithFormat(DATE_FORMAT_EN, csvDateTime)
    : csvDateTime
}
const getPersonPhoneNumber = (data: SchedulesListDataType) => {
  let phoneNumber = data.info.personPhoneNumber
  if (phoneNumber.length > 6) {
    phoneNumber = `${phoneNumber.substring(0, 2)}-${phoneNumber.substring(
      2,
      6,
    )}-${phoneNumber.substring(6)}`
  }
  return `(+${data.info.personCountryPhoneCode})${phoneNumber}`
}

export const convertDataExportExcel = (
  data: SchedulesListDataType[],
): SchedulesExportExcelFRM301Type[] =>
  data.map((item) => ({
    departureDateTimeAndArrivalDateTime: `${getDisplayDateTime(
      item.info.dispatchStartDate,
    )}～${getDisplayDateTime(item.info.dispatchEndDate)}`,
    dispatchTypeDetailName: getDispatchTypeDetailName(
      item.info.bumonName,
      item.info.dispatchTypeDetailId,
      item.info.dispatchTypeDetailName,
      true,
    ),
    emailAddress1: item.info.emailAddress1,
    emailAddress2: item.info.emailAddress2,
    fullNameKanji: getFullName(
      item.info.kanjiSurname,
      item.info.kanjiName,
      true,
    ),
    fullNameRomaji: getFullName(item.info.romajiSurname, item.info.romajiName),
    groupName: KEYS_ACCOMPANYING_FAMILY.includes(item.info.dispatchTypeDetailId)
      ? item.info.personDispatcherName
      : item.info.organizationName.split("・")[0],
    locationPersonName: item.info.locationPersonName,
    locationPhoneNumber1: item.info.locationPhoneNumber1,
    locationPhoneNumber2: item.info.locationPhoneNumber2,
    othersRemarks1: item.csv.othersRemarks1,
    personEmailAddress: item.info.emailAddress1,
    personPhoneNumber: getPersonPhoneNumber(item),
    phoneNumber: item.info.locationPhoneNumber1,
    projectNameJpOrProjectNameEn: getValueByLanguage(
      item.info.projectNameJp,
      item.info.projectNameEn,
    ).toString(),
    satellitePhoneNumber: item.info.satellitePhoneNumber,
    travelerLocation: item.info.travelerLocation,
    travelPurposeName: item.info.travelPurposeName,
    workPlaceCountryAndWorkPlaceCity: `${item.info.workPlaceCountry} ${item.info.workPlaceCity}`,
  }))
