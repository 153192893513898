import { concat } from "../../../../../../../../utils/concat"
import { AnyValue } from "../../../../../../../../_webui/layout/models"

export const getItemDataTable = (
  dataSubmitEntries: [string, string | boolean][],
  index: number,
  type: string,
) => {
  const dataTemp: AnyValue = {}

  for (const [key, value] of dataSubmitEntries) {
    if (key.includes(type)) {
      const c1 = key.indexOf(".")
      const c2 = key.lastIndexOf(".")

      if (key.slice(c1 + 1, c2) === index.toString()) {
        dataTemp[`${key.slice(c2 + 1)}`] = value ?? concat(value)
        dataTemp["index"] = index
      }
    }
  }

  return dataTemp
}
