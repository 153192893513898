import { formatDateSaveDB } from "../../../../../../../../utils/getDate"
import { getOverseasResident } from "../../../../../../../../utils/getOverseasResident"
import {
  TravelersSearchDataType,
  TravelersSearchFormDataType,
} from "../../../../../models/search"

export const convertDataSubmit = (
  values: TravelersSearchFormDataType,
): TravelersSearchDataType => {
  const overseasResident = getOverseasResident(
    values.workPlaceLocation === 1,
    values.workPlace,
    values.workPlaceCity,
    values.workPlaceCountry,
  )
  return {
    accompanies: {
      familyRelationship: values.familyRelationship,
      familyRomajiName: values.familyRomajiName,
      familyRomajiSurname: values.familyRomajiSurname,
    },
    base: {
      bumonCd: values.bumonCd,
      dispatchTypeDetailId: values.dispatchTypeDetailId,
      kanjiName: values.kanjiName,
      kanjiPassportName: values.kanjiPassportName,
      kanjiPassportSurname: values.kanjiPassportSurname,
      kanjiSurname: values.kanjiSurname,
      passportNumber: values.passportNumber,
      romajiName: values.romajiName,
      romajiPassportName: values.romajiPassportName,
      romajiPassportSurname: values.romajiPassportSurname,
      romajiSurname: values.romajiSurname,
    },
    detail: {
      assigneeInstitutionName: values.assigneeInstitutionName,
      assigneeName: values.assigneeName,
      assigneePhoneNumber: values.assigneePhoneNumber,
      insuranceCompanyName: values.insuranceCompanyName,
      insuranceCompanyPhoneNumber: values.insuranceCompanyPhoneNumber,
      insuranceSonsignor: values.insuranceSonsignor,
      insuranceTicketNumber: values.insuranceTicketNumber,
      othersAdministratorRemarks1: values.othersAdministratorRemarks1,
      othersAdministratorRemarks2: values.othersAdministratorRemarks2,
      othersAdministratorRemarks3: values.othersAdministratorRemarks3,
      othersAdministratorRemarks4: values.othersAdministratorRemarks4,
      othersAdministratorRemarks5: values.othersAdministratorRemarks5,
      othersRemarks1: values.othersRemarks1,
      othersRemarks2: values.othersRemarks2,
      othersRemarks3: values.othersRemarks3,
      othersRemarks4: values.othersRemarks4,
      othersRemarks5: values.othersRemarks5,
      personCompanyPosition: values.personCompanyPosition,
      personCountryPhoneCode: values.personCountryPhoneCode,
      personDispatcherName: values.personDispatcherName,
      personEmailAddress: values.personEmailAddress,
      personPhoneNumber: values.personPhoneNumber,
      personPrefectureResidence: values.personPrefectureResidence,
      trainingDate: formatDateSaveDB(values.trainingDate?.toString()),
      trainingType: values.trainingType,
      urgentAffiliationContact: values.urgentAffiliationContact,
      urgentAffiliationContactName: values.urgentAffiliationContactName,
      urgentContactHome: values.urgentContactHome,
      urgentContactName: values.urgentContactName,
      workPlaceLocation: values.workPlaceLocation,
      ...overseasResident,
    },
  }
}
