import { useEffect } from "react"
import {
  SafetiesInfoDataDetailType,
  UseSafetiesInfoType,
} from "../../../../models/info"
import { useVariables } from "../useVariables"

export const useSafetiesInfo =
  (): UseSafetiesInfoType<SafetiesInfoDataDetailType> => {
    const {
      form,
      gridItems,
      handleGetSafetyDetail,
      isLoading,
      isLoadingLayout,
      isShowFormSuccess,
      lang,
      onFinish,
      safetyInfo,
      setLang,
    } = useVariables()

    const changeLanguage = (value: string) => {
      setLang(value)
    }

    const handleCloseTab = () => {
      window.close()
    }

    useEffect(() => {
      handleGetSafetyDetail()
    }, [])

    return {
      changeLanguage,
      form,
      gridItems,
      handleCloseTab,
      isLoading,
      isLoadingLayout,
      isShowFormSuccess,
      lang,
      onFinish,
      safetyInfo,
    }
  }
