import { FC } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { ButtonSubmit } from "../../../../../../_webui/layout/components/Button/Submit"
import { TravelersBatchUpdateModalButtonSubmitProps } from "../../../models/batchUpdate"
const { TEXT_T012, TEXT_T020 } = Translate

export const TravelersBatchUpdateModalButtonSubmit: FC<
  TravelersBatchUpdateModalButtonSubmitProps
> = (props) => {
  const {
    isDisabledSubmit,
    isVisibleBtnCancel,
    isVisibleBtnUpdate,
    onOpen,
    onOpenUpdateOrGoToHome,
  } = props

  return (
    <ButtonSubmit
      isDisabledSubmit={isDisabledSubmit}
      isHiddenSubmit={!isVisibleBtnUpdate}
      isReset={isVisibleBtnCancel}
      onClickReset={onOpenUpdateOrGoToHome}
      onClickSubmit={onOpen}
      resetText={TEXT_T012}
      submitText={TEXT_T020}
    />
  )
}
