import { FC } from "react"
import { ContainerProps } from "./models"
import { Breadcrumbs } from "../Breadcrumbs"
import { getStyle } from "../../../../utils/getStyle"
import { Styled } from "./styled"
import { Regex } from "../../../../constant/Regex"
import { Menu } from "../Menu"

const { StyledContainer, StyledContainerMain } = Styled
const { REGEX_IS_DESKTOP } = Regex

export const Container: FC<ContainerProps> = (props) => {
  const { breadcrumbItems, className, children, isSideBar = true } = props
  const styleClassName = getStyle(className, ` ${className}`)
  const styleContainer = `container${styleClassName}`

  return (
    <StyledContainer className={styleContainer}>
      {isSideBar && REGEX_IS_DESKTOP && <Menu />}
      {REGEX_IS_DESKTOP ? (
        <StyledContainerMain>
          <Breadcrumbs breadcrumbItems={breadcrumbItems} />
          {children}
        </StyledContainerMain>
      ) : (
        children
      )}
    </StyledContainer>
  )
}
