import { Table as TableAnt } from "antd"
import { FC, Fragment } from "react"
import { TableProps } from "./models"
import { selectComponentClass } from "./utils/selectComponentClass"
import { Pagination } from "../Pagination"
import { Styled } from "./styled"
import { useTable } from "./modules/useTable"

const { StyledTable, StyledTableHeading } = Styled

export const Table: FC<TableProps> = (props) => {
  const {
    columns,
    dataSource,
    heading,
    isBackgroundFirstChild,
    id,
    isGroup,
    isLoading,
    isResetTable,
    isSeparator = false,
    onChange,
    onChangeTable,
    pagination = true,
    rowKey,
    rowSelection,
    isHeadingLeft = false,
    tableLayout = "fixed",
    ...res
  } = props

  const {
    current,
    handleAddRowIndex,
    onChangeCurrent,
    onChangePageSize,
    pageSize,
  } = useTable({
    dataSource,
    isResetTable,
    onChange,
  })

  return (
    <StyledTable
      isBackgroundFirstChild={isBackgroundFirstChild}
      isGroup={isGroup}
      isSeparator={isSeparator}
      {...res}
    >
      {heading && (
        <StyledTableHeading isHeadingLeft={isHeadingLeft}>
          {heading}
        </StyledTableHeading>
      )}
      <TableAnt
        columns={columns}
        dataSource={dataSource}
        id={id}
        loading={isLoading}
        locale={{ emptyText: <Fragment></Fragment> }}
        onChange={(_pagination, _filters, sorter, extra) => {
          if (typeof onChangeTable === "function") {
            onChangeTable(extra, sorter)
            handleAddRowIndex()
          }
        }}
        pagination={
          pagination && {
            current: current,
            defaultPageSize: pageSize,
            onChange: onChange,
            pageSize: pageSize,
            pageSizeOptions: [25, 50, 100, 250, 500],
            selectComponentClass,
            showSizeChanger: true,
            total: dataSource?.length,
          }
        }
        rowKey={rowKey}
        rowSelection={rowSelection}
        scroll={{ y: 550 }}
        showSorterTooltip={false}
        tableLayout={tableLayout}
      />
      {pagination && (
        <Pagination
          current={current}
          defaultCurrent={1}
          defaultPageSize={pageSize}
          onChange={onChangeCurrent}
          onChangeSelect={onChangePageSize}
          pageSize={pageSize}
          total={dataSource?.length}
        />
      )}
    </StyledTable>
  )
}
