/* eslint-disable max-lines */
import styled, { css } from "styled-components"
import { Colors } from "../../../../../constant/Styled/Colors"
import { Fonts } from "../../../../../constant/Styled/Fonts"
import { getValueByDevice } from "../../../../../utils/getValueByDevice"
import { StyledModalContentProps, StyledModalProps } from "../models"
import { Image } from "../../Image"

const { BOX_SHADOW, LIGHT } = Colors
const { FS_X_LARGE } = Fonts

const StyledModal = styled.div<StyledModalProps>`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transform: scale(0);
  transition: opacity 0.2s ease-in-out;
  visibility: hidden;
  z-index: 1050;
  ${(props) =>
    props.isOpen &&
    css`
      left: 0;
      opacity: 1;
      top: 0;
      transform: scale(1);
      visibility: visible;
    `}
`

const StyledModalBtnClose = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 1;
`

const StyledModalMask = styled.div`
  background-color: ${BOX_SHADOW};
  height: 100vh;
  position: absolute;
  width: 100vw;
  z-index: 1;
`

const StyledModalImage = styled(Image)`
  width: 24px;
`

const StyledModalBody = styled.div<StyledModalContentProps>`
  font-size: ${FS_X_LARGE};
  text-align: center;
  word-wrap: break-word;
  ${(props) =>
    props.isFull &&
    css`
      height: 100%;
      width: 100%;
      img {
        height: 100%;
        object-fit: contain;
        width: 100%;
      }
    `}
  ${(props) =>
    props.isForm
      ? css`
          border-radius: 10px;
          max-height: ${getValueByDevice("90vh", "calc(100vh - 20px)")};
          overflow: auto;
        `
      : css`
          padding: 24px;
        `}
`

const StyledModalContent = styled.div<StyledModalContentProps>`
  background-color: ${LIGHT};
  border-radius: 16px;
  box-shadow: 0 3px 6px -4px ${BOX_SHADOW}, 0 6px 16px ${BOX_SHADOW},
    0 9px 28px 8px ${BOX_SHADOW};
  max-width: calc(100vw - 32px);
  position: relative;
  width: 520px;
  z-index: 2;
  ${(props) =>
    props.isFull &&
    css`
      height: calc(90vh - 40px);
      width: calc(100vw - 40px);
    `}
  ${(props) =>
    props.isForm &&
    css`
      max-height: ${getValueByDevice("90vh", "calc(100vh - 20px)")};
      max-width: ${getValueByDevice("60%")};
      width: ${getValueByDevice("calc(100% - 255px)", "calc(100vw - 20px)")};
    `}
`

export const Styled = {
  StyledModal,
  StyledModalBody,
  StyledModalBtnClose,
  StyledModalContent,
  StyledModalImage,
  StyledModalMask,
}
