const TRAVELERS_ADD_REQUIRED_FIELDS = [
  "dispatchTypeDetailId",
  "personEmailAddress",
  "romajiName",
  "romajiSurname",
  "userId",
]

export const RequiredFields = {
  TRAVELERS_ADD_REQUIRED_FIELDS,
}
