/* eslint-disable max-lines */
import { translateMessage } from "../../../utils/translateMessage"
import { I18nCode } from "../../I18nCode"

const {
  CODE_HELP_H0001,
  CODE_HELP_H0002,
  CODE_HELP_H0003,
  CODE_HELP_H0004,
  CODE_HELP_H0005,
  CODE_HELP_H0006,
  CODE_HELP_H0007,
  CODE_HELP_H0008,
  CODE_HELP_H0009,
  CODE_HELP_H0010,
  CODE_HELP_H0011,
  CODE_HELP_H0012,
  CODE_HELP_H0013,
  CODE_HELP_H0014,
  CODE_HELP_H0015,
  CODE_HELP_H0016,
  CODE_HELP_H0017,
  CODE_HELP_H0018,
  CODE_HELP_H0019,
  CODE_HELP_H0020,
  CODE_HELP_H0021,
  CODE_HELP_H0022,
  CODE_HELP_H0023,
  CODE_HELP_H0024,
  CODE_HELP_H0025,
  CODE_HELP_H0026,
  CODE_HELP_H0027,
  CODE_HELP_H0028,
  CODE_HELP_H0029,
  CODE_HELP_H0030,
  CODE_HELP_H0031,
  CODE_HELP_H0032,
  CODE_HELP_H0033,
  CODE_HELP_H0034,
  CODE_HELP_H0035,
} = I18nCode

const HELP_H0001 = translateMessage(CODE_HELP_H0001)
const HELP_H0002 = translateMessage(CODE_HELP_H0002)
const HELP_H0003 = translateMessage(CODE_HELP_H0003)
const HELP_H0004 = translateMessage(CODE_HELP_H0004)
const HELP_H0005 = translateMessage(CODE_HELP_H0005)
const HELP_H0006 = translateMessage(CODE_HELP_H0006)
const HELP_H0007 = translateMessage(CODE_HELP_H0007)
const HELP_H0008 = translateMessage(CODE_HELP_H0008)
const HELP_H0009 = translateMessage(CODE_HELP_H0009)
const HELP_H0010 = translateMessage(CODE_HELP_H0010)
const HELP_H0011 = translateMessage(CODE_HELP_H0011)
const HELP_H0012 = translateMessage(CODE_HELP_H0012)
const HELP_H0013 = translateMessage(CODE_HELP_H0013)
const HELP_H0014 = translateMessage(CODE_HELP_H0014)
const HELP_H0015 = translateMessage(CODE_HELP_H0015)
const HELP_H0016 = translateMessage(CODE_HELP_H0016)
const HELP_H0017 = translateMessage(CODE_HELP_H0017)
const HELP_H0018 = translateMessage(CODE_HELP_H0018)
const HELP_H0019 = translateMessage(CODE_HELP_H0019)
const HELP_H0020 = translateMessage(CODE_HELP_H0020)
const HELP_H0021 = translateMessage(CODE_HELP_H0021)
const HELP_H0022 = translateMessage(CODE_HELP_H0022)
const HELP_H0023 = translateMessage(CODE_HELP_H0023)
const HELP_H0024 = translateMessage(CODE_HELP_H0024)
const HELP_H0025 = translateMessage(CODE_HELP_H0025)
const HELP_H0026 = translateMessage(CODE_HELP_H0026)
const HELP_H0027 = translateMessage(CODE_HELP_H0027)
const HELP_H0028 = translateMessage(CODE_HELP_H0028)
const HELP_H0029 = translateMessage(CODE_HELP_H0029)
const HELP_H0030 = translateMessage(CODE_HELP_H0030)
const HELP_H0031 = translateMessage(CODE_HELP_H0031)
const HELP_H0032 = translateMessage(CODE_HELP_H0032)
const HELP_H0033 = translateMessage(CODE_HELP_H0033)
const HELP_H0034 = translateMessage(CODE_HELP_H0034)
const HELP_H0035 = translateMessage(CODE_HELP_H0035)

export const TranslateHelp = {
  HELP_H0001,
  HELP_H0002,
  HELP_H0003,
  HELP_H0004,
  HELP_H0005,
  HELP_H0006,
  HELP_H0007,
  HELP_H0008,
  HELP_H0009,
  HELP_H0010,
  HELP_H0011,
  HELP_H0012,
  HELP_H0013,
  HELP_H0014,
  HELP_H0015,
  HELP_H0016,
  HELP_H0017,
  HELP_H0018,
  HELP_H0019,
  HELP_H0020,
  HELP_H0021,
  HELP_H0022,
  HELP_H0023,
  HELP_H0024,
  HELP_H0025,
  HELP_H0026,
  HELP_H0027,
  HELP_H0028,
  HELP_H0029,
  HELP_H0030,
  HELP_H0031,
  HELP_H0032,
  HELP_H0033,
  HELP_H0034,
  HELP_H0035,
}
