import { FocusEvent, KeyboardEvent } from "react"
import { UseDatePickerProps } from "./models"
import { validatorDatetimeInput } from "../../validator/datetimeInput"

export const useDatePicker = (props: UseDatePickerProps) => {
  const { form, isRequired, onValuesChange } = props
  const onBlur = (
    event: FocusEvent<HTMLInputElement>,
    label: string,
    name: string,
  ) => {
    validatorDatetimeInput({
      form,
      isRequired,
      label,
      name,
      onValuesChange,
      value: event.target.value,
    })
  }

  const onKeyDown = (
    event: KeyboardEvent<HTMLInputElement>,
    label: string,
    name: string,
  ) => {
    if (event.keyCode === 13) {
      validatorDatetimeInput({
        form,
        isRequired,
        label,
        name,
        onValuesChange,
        value: event.currentTarget.value,
      })
    }
  }

  return {
    onBlur,
    onKeyDown,
  }
}
