import styled, { css } from "styled-components"
import { Regex } from "../../../../../constant/Regex"
import { Colors } from "../../../../../constant/Styled/Colors"

const { REGEX_IS_DESKTOP } = Regex
const { LIGHT, MAIN } = Colors

const StyledContainer = styled.main`
  ${() =>
    REGEX_IS_DESKTOP
      ? css`
          background-color: ${MAIN};
          display: flex;
          flex-wrap: wrap;
          min-height: calc(100vh - 110px);
          min-width: 1200px;
        `
      : css`
          background-color: ${LIGHT};
          min-height: calc(100vh - 90px);
        `}
`

const StyledContainerMain = styled.section`
  ${() =>
    REGEX_IS_DESKTOP &&
    css`
      flex-grow: 1;
      padding: 20px;
      width: calc(100% - 255px);
    `}
`

export const Styled = {
  StyledContainer,
  StyledContainerMain,
}
