import { FC } from "react"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { Translate } from "../../../../../constant/Translate"
import { AttentionsInfoCard } from "../../components/info/Card"
import { useAttentionsInfo } from "./modules/useInfo"

const { TEXT_T570 } = Translate

export const AttentionsInfo: FC = () => {
  const {
    attentionInfo,
    attentionInfoList,
    breadcrumbItems,
    columns,
    getAttentionTravelers,
    goBack,
    isHiddenSubmit,
    isLoading,
    isLoadingLayout,
    isRoleSubAdmin,
  } = useAttentionsInfo()

  return (
    <Layout
      breadcrumbItems={breadcrumbItems}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T570}
    >
      <AttentionsInfoCard
        attentionInfo={attentionInfo}
        attentionInfoList={attentionInfoList}
        columns={columns}
        goBack={goBack}
        isHiddenSubmit={isHiddenSubmit}
        isLoading={isLoading}
        isRoleSubAdmin={isRoleSubAdmin}
        getAttentionTravelers={getAttentionTravelers}
      />
    </Layout>
  )
}
