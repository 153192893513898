import { Key } from "react"
import { getFullName } from "../../../../../../../../utils/getFullName"
import { getValueByLanguage } from "../../../../../../../../utils/getValueByLanguage"
import { AttentionDataTableType } from "../../../../../components/attention/Form/models"
import { SafetiesType } from "../../../../../models/attention"

export const getSafetiesSelectedValue = (
  safetiesList: AttentionDataTableType[],
  selectedKeys: Key[],
) => {
  const selectedItems = safetiesList.filter((item) =>
    selectedKeys.includes(item.userId),
  )
  const valueItems = selectedItems.map((item) => {
    const fullNameKanji = getFullName(item.kanjiSurname, item.kanjiName, true)
    const fullNameRomaji = getFullName(item.romajiSurname, item.romajiName)
    const valueEn = `${fullNameRomaji} (${item.personEmailAddress})`
    const valueJp = `${fullNameKanji}（${item.personEmailAddress}）`
    return `${getValueByLanguage(valueJp, valueEn)}`
  })
  const safetiesSelected = getValueByLanguage(
    valueItems.join("、"),
    valueItems.join(", "),
  ).toString()
  const safeties: SafetiesType[] = selectedItems.map((item) => {
    const fullNameKanji = getFullName(item.kanjiSurname, item.kanjiName, true)
    const fullNameRomaji = getFullName(item.romajiSurname, item.romajiName)
    return {
      fullName: getValueByLanguage(fullNameKanji, fullNameRomaji).toString(),
      personEmailAddress: item.personEmailAddress,
    }
  })
  return {
    safeties,
    safetiesSelected,
  }
}
