import { Endpoint } from "../../../../constant/Endpoint"
import { Environment } from "../../../../constant/Environment"
import { apiService } from "../../../../infrastructure/apiService"

const { TRAVELER_ENDPOINT } = Environment
const { FNC1A_Notifications } = Endpoint

export const homeApi = {
  notifications: () =>
    apiService({
      data: {},
      method: "GET",
      url: `${TRAVELER_ENDPOINT}${FNC1A_Notifications}`,
    }),
}
