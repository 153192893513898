import {
  SafetiesDetailBaseType,
  SafetiesDetailInfoType,
} from "../../../../../../models/detail"
import { convertDataExport } from "./config/convertDataExport"
import { safetyInfoHeader } from "./config/header/safetyInfoHeader"
import { exportFileExcel1 } from "./workbook1"
import { exportFileExcel2 } from "./workbook2"
import { exportFileExcel3 } from "./workbook3"

export const exportExcelFRM303 = async (
  data: SafetiesDetailBaseType[],
  safetyInfo?: SafetiesDetailInfoType,
) => {
  const header = safetyInfoHeader(data.length, safetyInfo)
  const convertData = convertDataExport(data)
  exportFileExcel1(convertData, header)
  exportFileExcel2(convertData, header)
  exportFileExcel3(convertData, header)
}
