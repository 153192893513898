import { RolesOptionsType } from "../../../../models/roles/setting"

const INITIAL_VALUES_AUTHORITY_PATTERN_SETTINGS: RolesOptionsType = {
  attentionOptions: [],
  safetyOptions: [],
  scheduleOptions: [],
  travelerOptions: [],
}

export const InitialValues = {
  INITIAL_VALUES_AUTHORITY_PATTERN_SETTINGS,
}
