import { SafetiesDetailBaseType } from "../../../../../../../../models/detail"
import { SafetiesDetailExcelSheetType } from "../../../../../../../../models/detail/export"
import {
  formatDateTimeZoneByUserInformation,
  getDateTime,
  getDay,
} from "../../../../../../../../../../../utils/getDate"
import { getFullName } from "../../../../../../../../../../../utils/getFullName"
import { getProxyResponser } from "../../../../../../../../../../../utils/getProxyResponser"
import { Timezone } from "../../../../../../../../../../../constant/Timezone"
import moment from "moment"
import { getDispatchTypeDetailName } from "../../../../../../../../../../../utils/getDispatchTypeDetailName"

const { DATE_FORMAT_EN } = Timezone

const convertDate = (date: string) => moment(date).utc().format(DATE_FORMAT_EN)

export const convertDataExport = (
  data: SafetiesDetailBaseType[],
): SafetiesDetailExcelSheetType[] =>
  data.map((item, index) => ({
    arrivalCityName: item.arrivalCityName,
    arrivalCountryName: item.arrivalCountryName,
    arrivalDateTime: getDateTime(item.arrivalDateTime),
    bumonCd: item.bumonCd,
    bumonName: item.bumonName,
    departureCityName: item.departureCityName,
    departureCountryName: item.departureCountryName,
    departureDateTime: getDateTime(item.departureDateTime),
    departureFlightNumber: item.departureFlightNumber,
    dispatchDate: `${getDay(item.dispatchStartDate)}-${getDay(
      item.dispatchEndDate,
    )}`,
    dispatchTypeDetailName: getDispatchTypeDetailName(
      item.personDispatcherName,
      item.dispatchTypeDetailId,
      item.dispatchTypeDetailName,
    ),
    familySafetyStatusName: item.familySafetyStatusName,
    fullNameKanji: item.differentInforCheck
      ? getFullName(item.kanjiPassportSurname, item.kanjiPassportName, true)
      : getFullName(item.kanjiSurname, item.kanjiName, true),
    fullNameRomaji: item.differentInforCheck
      ? getFullName(item.romajiPassportSurname, item.romajiPassportName)
      : getFullName(item.romajiSurname, item.romajiName),
    index: (index + 1).toString(),
    insuranceCompanyName: item.insuranceCompanyName,
    insuranceCompanyPhoneNumber: item.insuranceCompanyPhoneNumber,
    insuranceSonsignor: item.insuranceSonsignor,
    insuranceTicketNumber: item.insuranceTicketNumber,
    itinerary: `${convertDate(item.departureDateTime)}～${convertDate(
      item.arrivalDateTime,
    )} ${item.arrivalCountryName}国${item.arrivalCityName}都市`,
    locationPersonName: item.locationPersonName,
    locationPhoneNumber1: `1. ${item.locationPhoneNumber1}`,
    locationPhoneNumber2: `2. ${item.locationPhoneNumber2}`,
    lodgingAddress: item.lodgingAddress,
    lodgingCheckinCheckout: `${getDay(item.lodgingCheckin)}-${getDay(
      item.lodgingCheckout,
    )}`,
    lodgingName: item.lodgingName,
    lodgingPhoneNumber: item.lodgingPhoneNumber,
    mainPersonName: item.mainPersonName,
    message: item.message,
    othersRemarks1: item.othersRemarks1,
    othersStaffNumber: item.othersStaffNumber,
    passportNumber: item.passportNumber,
    personCompanyPosition: item.othersStaffNumber
      ? item.bumonName
      : item.personCompanyPosition.split("・")[0],
    personCountryPhoneCode: item.personCountryPhoneCode,
    personPhoneNumber: `4. (${
      item.personCountryPhoneCode.startsWith("+") ? "" : "+"
    }${item.personCountryPhoneCode})${item.personPhoneNumber}`,
    projectNameEn: item.projectNameEn,
    projectNameJp: item.projectNameJp,
    proxyResponser: getProxyResponser(item.proxyResponser),
    safetyResponseDate: formatDateTimeZoneByUserInformation(
      item.safetyResponseDate,
    ),
    safetyStatus: item.safetyStatus,
    safetyStatusName: item.safetyStatusName,
    satellitePhoneNumber: `3. ${item.satellitePhoneNumber}`,
    transportation: item.transportation,
    travelerLocation: item.travelerLocation,
    urgentAffiliationContact: item.urgentAffiliationContact,
    urgentAffiliationContactName: item.urgentAffiliationContactName,
    urgentContactHome: item.urgentContactHome,
    urgentContactName: item.urgentContactName,
  }))
