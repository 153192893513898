/* eslint-disable complexity */
import { Translate } from "../../../../../../../../../constant/Translate"
import { TagType } from "../../../../../../../../../_webui/layout/components/Tag/models"
import { Variables } from "../../../../../../constants/detail/variables"

const {
  MINOR_INJURY,
  NO_RESPONSE,
  SAFETY,
  SERIOUS_ILLNESS,
  UNDELIVERED_EMAIL,
} = Variables
const { TEXT_T348, TEXT_T385, TEXT_T387, TEXT_T389, TEXT_T402 } = Translate

const getSafetyStatusValue = (text: string, type: TagType) => ({ text, type })

export const getSafetyStatus = (safetyStatus: string) => {
  if (safetyStatus === SAFETY) return getSafetyStatusValue(TEXT_T402, "success")
  if (safetyStatus === MINOR_INJURY)
    return getSafetyStatusValue(TEXT_T385, "warning")
  if (safetyStatus === SERIOUS_ILLNESS)
    return getSafetyStatusValue(TEXT_T387, "error")
  if (safetyStatus === NO_RESPONSE)
    return getSafetyStatusValue(TEXT_T389, "stop")
  if (safetyStatus === UNDELIVERED_EMAIL)
    return getSafetyStatusValue(TEXT_T348, "default")
}
