import { Translate } from "../../../../../../../../../constant/Translate"
import { getDay } from "../../../../../../../../../utils/getDate"
import { getTrainingType } from "../../../../../../../../../utils/getTrainingType"
import {
  TravelersEditSuccessCollapseDetailType,
  TravelersEditSuccessDetailType,
} from "../../../../../../models/editSuccess"
import { getPostingInfor } from "./getPostingInfor"
import { getSpouseInfor } from "./getSpouseInfor"

const {
  TEXT_T017,
  TEXT_T046,
  TEXT_T047,
  TEXT_T048,
  TEXT_T049,
  TEXT_T050,
  TEXT_T051,
  TEXT_T052,
  TEXT_T053,
  TEXT_T058,
  TEXT_T059,
  TEXT_T060,
  TEXT_T061,
  TEXT_T062,
  TEXT_T063,
  TEXT_T064,
  TEXT_T065,
  TEXT_T066,
  TEXT_T218,
} = Translate

export const convertDataDetail = (
  data: TravelersEditSuccessDetailType,
  dispatchTypeDetailId: string,
  isRoleAdmin: boolean,
  isRoleSubAdmin: boolean,
  userIdForChangeRequest: string,
): TravelersEditSuccessCollapseDetailType => {
  const spouseInfor = getSpouseInfor(
    data,
    isRoleAdmin,
    isRoleSubAdmin,
    userIdForChangeRequest,
  )
  const postingInfor = getPostingInfor(data)

  return {
    assignmentInfor: [
      { key: TEXT_T058, text: data.assigneeInstitutionName },
      { key: TEXT_T059, text: data.assigneePhoneNumber },
      { key: TEXT_T060, text: data.assigneeName },
    ],
    contactJapan: [
      { key: TEXT_T218, text: data.personPrefectureResidenceName },
      { key: TEXT_T046, text: data.personCountryPhoneCode },
      { key: TEXT_T047, text: data.personPhoneNumber },
      { key: TEXT_T017, text: data.personEmailAddress },
      { key: TEXT_T048, text: data.personCompanyPosition },
      { key: TEXT_T049, text: data.personDispatcherName },
    ],
    contactPerson: [
      { key: TEXT_T050, text: data.urgentContactHome },
      { key: TEXT_T051, text: data.urgentContactName },
      { key: TEXT_T052, text: data.urgentAffiliationContact },
      { key: TEXT_T053, text: data.urgentAffiliationContactName },
    ],
    insuranceInfor: [
      { key: TEXT_T061, text: data.insuranceCompanyName },
      { key: TEXT_T062, text: data.insuranceCompanyPhoneNumber },
      { key: TEXT_T063, text: data.insuranceTicketNumber },
      { key: TEXT_T064, text: data.insuranceSonsignor },
    ],
    postingInfor,
    spouseInfor,
    trainingInfor: [
      {
        key: TEXT_T065,
        text: getTrainingType({
          dispatchTypeDetailId: dispatchTypeDetailId,
          trainingType: data.trainingType,
        }),
      },
      {
        key: TEXT_T066,
        text: getDay(data.trainingDate),
      },
    ],
  }
}
