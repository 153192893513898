import styled from "styled-components"
import { StyledSpaceProps } from "../models"

const StyledSpace = styled.div<StyledSpaceProps>`
  margin: ${(props) => props.isSpaceAll && "25px"};
  margin-bottom: ${(props) => props.isSpaceBottom && "25px"};
  margin-left: ${(props) => props.isSpaceLeft && "25px"};
  margin-right: ${(props) => props.isSpaceRight && "25px"};
  margin-top: ${(props) => props.isSpaceTop && "25px"};
`

export const Styled = {
  StyledSpace,
}
