import Excel from "exceljs"
import { saveAs } from "file-saver"
import { exportFileName } from "../../../../../../../../../../utils/exportFileName"
import { worksheetConfig } from "./worksheetConfig"
import {
  SafetiesDetailExcelSheetType,
  SafetyInfoHeaderType,
} from "../../../../../../../models/detail/export"
import { Translate } from "../../../../../../../../../../constant/Translate"

const { EXPORT_EP0198 } = Translate

export const exportFileExcel1 = async (
  data: SafetiesDetailExcelSheetType[],
  header: SafetyInfoHeaderType,
) => {
  const workbook = new Excel.Workbook()
  worksheetConfig(data, header, workbook)

  const buffer = await workbook.xlsx.writeBuffer()
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  const fileExtension = ".xlsx"

  const blob = new Blob([buffer], { type: fileType })

  saveAs(blob, exportFileName(EXPORT_EP0198) + fileExtension)
}
