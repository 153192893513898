import { Translate } from "../../../../../../../../../constant/Translate"

const {
  EXPORT_EP0017, // ログインID
  EXPORT_EP0091, // 姓名（漢字）_姓
  EXPORT_EP0092, // 姓名（漢字）_名
  EXPORT_EP0087, // 姓名（ローマ字）_姓
  EXPORT_EP0088, // 姓名（ローマ字）_名
  EXPORT_EP0013, // パスポート表記と異なる
  EXPORT_EP0089, // 姓名（漢字）（戸籍表記）_姓
  EXPORT_EP0090, // 姓名（漢字）（戸籍表記）_名
  EXPORT_EP0085, // 姓名（ローマ字）（パスポート表記）_姓
  EXPORT_EP0086, // 姓名（ローマ字）（パスポート表記）_名
  EXPORT_EP0042, // 権限区分
  EXPORT_EP0014, // パターンID
  EXPORT_EP0078, // 主管部署
  EXPORT_EP0122, // 派遣種別
  EXPORT_EP0123, // 派遣種別（詳細）
  EXPORT_EP0012, // パスポート番号
  EXPORT_EP0082, // 随伴家族の姓名（ローマ字）_姓
  EXPORT_EP0083, // 随伴家族の姓名（ローマ字）_名
  EXPORT_EP0084, // 随伴家族の続柄
  EXPORT_EP0081, // 随伴家族・人数
  EXPORT_EP0034, // 居住している都道府県
  EXPORT_EP0039, // 携帯電話国番号
  EXPORT_EP0040, // 携帯電話番号
  EXPORT_EP0016, // メールアドレス
  EXPORT_EP0026, // 会社名・役職
  EXPORT_EP0121, // 派遣者本人名
  EXPORT_EP0037, // 緊急時連絡先（自宅）
  EXPORT_EP0146, // 連絡者名（自宅）
  EXPORT_EP0038, // 緊急時連絡先（所属先）
  EXPORT_EP0147, // 連絡者名（所属先）
  EXPORT_EP0027, // 海外在留者・本邦勤務者
  EXPORT_EP0047, // 国
  EXPORT_EP0048, // 国コード
  EXPORT_EP0107, // 都市
  EXPORT_EP0108, // 都市コード
  EXPORT_EP0116, // 任地
  EXPORT_EP0126, // 配属先機関名
  EXPORT_EP0148, // 連絡先電話番号
  EXPORT_EP0145, // 連絡者名
  EXPORT_EP0135, // 保険会社名
  EXPORT_EP0134, // ①事故発生時の保険会社連絡先
  EXPORT_EP0137, // ②保険証券番号
  EXPORT_EP0036, // ③保険外（自費/自社費）で緊急移送の判断を要する場合の判断委託者
  EXPORT_EP0059, // 種類
  EXPORT_EP0060, // 受講日・受講予定日
  EXPORT_EP0006, // システム識別子
  EXPORT_EP0125, // 派遣番号
  EXPORT_EP0080, // 職員番号
  EXPORT_EP0097, // 隊員番号
  EXPORT_EP0044, // 個人情報の取得及び利用に関する同意
  EXPORT_EP0009, // たびレジへの個人情報提供に関する同意
  EXPORT_EP0001, // EU一般データ保護規制（GDPR）に関する個人情報及びCookie利用に関する同意
  EXPORT_EP0128, // 備考1
  EXPORT_EP0129, // 備考2
  EXPORT_EP0130, // 備考3
  EXPORT_EP0131, // 備考4
  EXPORT_EP0132, // 備考5
  EXPORT_EP0029, // 管理者用備考1
  EXPORT_EP0030, // 管理者用備考2
  EXPORT_EP0031, // 管理者用備考3
  EXPORT_EP0032, // 管理者用備考4
  EXPORT_EP0033, // 管理者用備考5
  EXPORT_EP0007, // タイムゾーン
  EXPORT_EP0008, // タイムフォーマット
} = Translate

export const heading = `${EXPORT_EP0017},${EXPORT_EP0091},${EXPORT_EP0092},${EXPORT_EP0087},${EXPORT_EP0088},${EXPORT_EP0013},${EXPORT_EP0089},${EXPORT_EP0090},${EXPORT_EP0085},${EXPORT_EP0086},${EXPORT_EP0042},${EXPORT_EP0014},${EXPORT_EP0078},${EXPORT_EP0122},${EXPORT_EP0123},${EXPORT_EP0012},${EXPORT_EP0082},${EXPORT_EP0083},${EXPORT_EP0084},${EXPORT_EP0081},${EXPORT_EP0034},${EXPORT_EP0039},${EXPORT_EP0040},${EXPORT_EP0016},${EXPORT_EP0026},${EXPORT_EP0121},${EXPORT_EP0037},${EXPORT_EP0146},${EXPORT_EP0038},${EXPORT_EP0147},${EXPORT_EP0027},${EXPORT_EP0047},${EXPORT_EP0048},${EXPORT_EP0107},${EXPORT_EP0108},${EXPORT_EP0116},${EXPORT_EP0126},${EXPORT_EP0148},${EXPORT_EP0145},${EXPORT_EP0135},${EXPORT_EP0134},${EXPORT_EP0137},${EXPORT_EP0036},${EXPORT_EP0059},${EXPORT_EP0060},${EXPORT_EP0006},${EXPORT_EP0125},${EXPORT_EP0080},${EXPORT_EP0097},${EXPORT_EP0044},${EXPORT_EP0009},${EXPORT_EP0001},${EXPORT_EP0128},${EXPORT_EP0129},${EXPORT_EP0130},${EXPORT_EP0131},${EXPORT_EP0132},${EXPORT_EP0029},${EXPORT_EP0030},${EXPORT_EP0031},${EXPORT_EP0032},${EXPORT_EP0033},${EXPORT_EP0007},${EXPORT_EP0008}`
