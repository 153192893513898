/* eslint-disable complexity */
import moment from "moment"
import { Routers } from "../../../../../../../../constant/Routers"
import {
  formatDateSaveDB,
  getDate,
  getDateRange,
} from "../../../../../../../../utils/getDate"
import { getTime } from "../../../../../../../../utils/getTime"
import {
  SchedulesListPostType,
  ConvertAdvancedSearchDataSubmitType,
} from "../../../../../models/list"
import { getOverseasResident } from "../../../../../../../../utils/getOverseasResident"

const { SCR210_TRAVELERS } = Routers

export const convertAdvancedSearchDataSubmit = (
  dataConvert: ConvertAdvancedSearchDataSubmitType,
) => {
  const { isPathnameAttentionsOrSafeties, pathname, values } = dataConvert

  const travelerId = pathname === SCR210_TRAVELERS ? "" : values.travelerId

  const arrivalTime = getTime(
    values.arrivalDateTimeHour,
    values.arrivalDateTimeMinutes,
  )

  const arrivalDateTime = values.arrivalDateTimeDate
    ? arrivalTime
      ? getDate(getDateRange(values.arrivalDateTimeDate), arrivalTime)
      : formatDateSaveDB(getDateRange(values.arrivalDateTimeDate))
    : ""

  const departureTime = getTime(
    values.departureDateTimeHour,
    values.departureDateTimeMinutes,
  )

  const departureDateTime = values.departureDateTimeDate
    ? departureTime
      ? getDate(getDateRange(values.departureDateTimeDate), departureTime)
      : formatDateSaveDB(getDateRange(values.departureDateTimeDate))
    : isPathnameAttentionsOrSafeties
    ? formatDateSaveDB(moment().toString())
    : ""
  const overseasResident = getOverseasResident(
    values.workPlaceLocation === 1,
    values.workPlace,
    values.workPlaceCity,
    values.workPlaceCountry,
  )

  const data: SchedulesListPostType = {
    accommodation: {
      lodgingName: values.lodgingName,
    },
    assignment: {
      procurementControlNumber: values.procurementControlNumber,
      projectNameEn: values.projectNameEn,
      projectNameJp: values.projectNameJp,
      travelPurposeId: values.travelPurposeId,
    },
    basic: {
      bumonCd: values.bumonCd,
      dispatchType: values.dispatchType,
      dispatchTypeDetail: values.dispatchTypeDetail,
      kanjiName: values.kanjiName,
      kanjiPassportName: values.kanjiPassportName,
      kanjiPassportSurname: values.kanjiPassportSurname,
      kanjiSurname: values.kanjiSurname,
      romajiName: values.romajiName,
      romajiPassportName: values.romajiPassportName,
      romajiPassportSurname: values.romajiPassportSurname,
      romajiSurname: values.romajiSurname,
    },
    contractPersonJp: {
      personCompanyPosition: values.personCompanyPosition,
      personDispatcherName: values.personDispatcherName,
      personPrefectureResidence: values.personPrefectureResidence,
    },
    other: {
      othersDispatchNumber: values.othersDispatchNumber,
      othersMemberNumber: values.othersMemberNumber,
      othersStaffNumber: values.othersStaffNumber,
    },
    posting: {
      workPlaceLocation: values.workPlaceLocation,
      ...overseasResident,
    },
    travelerId,
    trip: {
      arrivalCity: values.arrivalCity,
      arrivalCountryCode: values.arrivalCountryCode,
      arrivalDateTime,
      currentLocation: values.currentLocation,
      departureCity: values.departureCity,
      departureCountryCode: values.departureCountryCode,
      departureDateTime,
      departureFlightNumber: values.departureFlightNumber,
      isTransit: values.isTransit,
      transportation: values.transportation,
    },
  }

  return data
}
