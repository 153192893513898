/* eslint-disable max-lines */
import { FC } from "react"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { Upload } from "../../../../../_webui/layout/components/Upload"
import { Breadcrumbs } from "../../../../../constant/Breadcrumbs"
import { File } from "../../../../../constant/File"
import { Translate } from "../../../../../constant/Translate"
import { TravelersBatchUpdateModalButtonSubmit } from "../../components/batchUpdate/ButtonSubmit"
import { TravelersBatchUpdateModalConfirm } from "../../components/batchUpdate/ModalConfirm"
import { TravelersBatchUpdateModalTable } from "../../components/batchUpdate/Table"
import { useTravelersBatchUpdate } from "./modules/useBatchUpdate"
import { Styled } from "../batchImport/styled"
import { Form } from "../../../../../_webui/layout/components/Form"
import { ItemInput } from "../../../../../_webui/layout/components/Form/Item/Input"
import { validatorEmailAddress } from "../../../../../utils/validator/emailAddress"

const { TEXT_T149, TEXT_T278 } = Translate
const { BRCR_SCR123 } = Breadcrumbs
const { FILE_TYPE_ALLOW_UPLOAD_ACCEPT_ALL } = File
const { StyledContainer } = Styled

export const TravelersBatchUpdate: FC = () => {
  const {
    beforeUpload,
    bulkDeleteDataConfirm,
    bulkFileUpdate,
    bulkUpdate,
    columnErrors,
    columns,
    countRecord,
    dataTemps,
    errorList,
    fileNameList,
    form,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenUpdate,
    isResetTable,
    isVisibleBtnCancel,
    isVisibleBtnUpdate,
    onCancel,
    onCancelUpdate,
    onChange,
    onOpen,
    onOpenUpdateOrGoToHome,
    perItem,
  } = useTravelersBatchUpdate()

  return (
    <Layout
      breadcrumbItems={BRCR_SCR123}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T149}
    >
      <Card heading={TEXT_T149} isLoading={isLoading}>
        <StyledContainer>
          <Form form={form} isCard={false} isHeading={false}>
            <ItemInput
              label={TEXT_T278}
              maxLength={255}
              name="registerRequestUserId"
              rules={[validatorEmailAddress]}
            />
          </Form>
          <Upload
            accept={FILE_TYPE_ALLOW_UPLOAD_ACCEPT_ALL}
            customRequest={({ file }) =>
              beforeUpload(file, (upload: boolean) => {
                if (upload) {
                  bulkFileUpdate(file)
                }
              })
            }
            fileNameList={fileNameList}
          />
          <TravelersBatchUpdateModalTable
            columnErrors={columnErrors}
            columns={columns}
            countRecord={countRecord}
            dataTemps={dataTemps}
            errorList={errorList}
            isResetTable={isResetTable}
            onChange={onChange}
            perItem={perItem}
          />
          <TravelersBatchUpdateModalButtonSubmit
            isDisabledSubmit={isDisabledSubmit}
            isVisibleBtnCancel={isVisibleBtnCancel}
            isVisibleBtnUpdate={isVisibleBtnUpdate}
            onOpen={onOpen}
            onOpenUpdateOrGoToHome={onOpenUpdateOrGoToHome}
          />
          <TravelersBatchUpdateModalConfirm
            bulkDeleteDataConfirm={bulkDeleteDataConfirm}
            bulkUpdate={bulkUpdate}
            isOpen={isOpen}
            isOpenUpdate={isOpenUpdate}
            onCancel={onCancel}
            onCancelUpdate={onCancelUpdate}
          />
        </StyledContainer>
      </Card>
    </Layout>
  )
}
