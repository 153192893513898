import { Switch } from "react-router-dom"
import PrivateRoute from "../../../../app/routing/privateRoute"
import { Routers } from "../../../../constant/Routers"
import { TravelersListPage } from "../../../../app/pages/travelers/list"
import { TravelersListUserPage } from "../../../../app/pages/travelers/list/user"
import { TravelersListUserSearchPage } from "../../../../app/pages/travelers/list/userSearch"
import { TravelersAddPage } from "../../../../app/pages/travelers/add"
import { TravelersAddSuccessPage } from "../../../../app/pages/travelers/addSuccess"
import { TravelersConfirmDeletePage } from "../../../../app/pages/travelers/confirmDelete"
import { TravelersDeleteSuccessPage } from "../../../../app/pages/travelers/deleteSuccess"
import { TravelersDetailPage } from "../../../../app/pages/travelers/detail"
import { TravelersDetailUserPage } from "../../../../app/pages/travelers/detail/user"
import { TravelersBatchImportPage } from "../../../../app/pages/travelers/batchImport"
import { TravelersBatchDeletePage } from "../../../../app/pages/travelers/batchDelete"
import { TravelersBatchUpdatePage } from "../../../../app/pages/travelers/batchUpdate"
import { TravelersRequestDeletePage } from "../../../../app/pages/travelers/requestDelete"
import { TravelersSearchPage } from "../../../../app/pages/travelers/search"
import { TravelersEditSuccessPage } from "../../../../app/pages/travelers/editSuccess"
import { TravelersEditPage } from "../../../../app/pages/travelers/edit"
import { FC } from "react"

const {
  SCR112,
  SCR113,
  SCR114_USER_SEARCH,
  SCR114_USER,
  SCR114,
  SCR115,
  SCR115_USER,
  SCR116,
  SCR117,
  SCR119,
  SCR120,
  SCR122,
  SCR123,
  SCR124,
  SCR125,
  SCR126,
} = Routers

export const TravelersRoute: FC = () => (
  <Switch>
    <PrivateRoute component={TravelersAddPage} exact path={SCR112} />
    <PrivateRoute component={TravelersAddSuccessPage} exact path={SCR113} />
    <PrivateRoute component={TravelersListPage} exact path={SCR114} />
    <PrivateRoute component={TravelersListUserPage} exact path={SCR114_USER} />
    <PrivateRoute
      component={TravelersListUserSearchPage}
      exact
      path={SCR114_USER_SEARCH}
    />
    <PrivateRoute component={TravelersDetailPage} exact path={SCR115} />
    <PrivateRoute
      component={TravelersDetailUserPage}
      exact
      path={SCR115_USER}
    />
    <PrivateRoute component={TravelersEditPage} exact path={SCR116} />
    <PrivateRoute component={TravelersEditSuccessPage} exact path={SCR117} />
    <PrivateRoute component={TravelersDeleteSuccessPage} exact path={SCR119} />
    <PrivateRoute component={TravelersSearchPage} exact path={SCR120} />
    <PrivateRoute component={TravelersBatchImportPage} exact path={SCR122} />
    <PrivateRoute component={TravelersBatchUpdatePage} exact path={SCR123} />
    <PrivateRoute component={TravelersBatchDeletePage} exact path={SCR124} />
    <PrivateRoute component={TravelersRequestDeletePage} exact path={SCR125} />
    <PrivateRoute component={TravelersConfirmDeletePage} exact path={SCR126} />
  </Switch>
)
