import { FC, Fragment, useEffect } from "react"
import { Container } from "../Container"
import { Footer } from "../Footer"
import { Header } from "../Header"
import { Spin } from "../Spin"
import { LayoutProps } from "./models"

export const Layout: FC<LayoutProps> = (props) => {
  const {
    breadcrumbItems = [],
    children,
    className,
    isFooter = true,
    isHeader = true,
    isHeaderRightNone,
    isLoadingLayout,
    isSideBar,
    title = "",
  } = props

  useEffect(() => {
    document.title = title
    document.head.querySelector("title")?.setAttribute("aria-live", "polite")
    document.head.querySelector("title")?.setAttribute("role", "banner")
  }, [])

  return (
    <Fragment>
      <Spin isFixed isLoading={isLoadingLayout} isOpacity={false} />
      {isHeader && <Header isHeaderRightNone={isHeaderRightNone} />}
      <Container
        breadcrumbItems={breadcrumbItems}
        className={className}
        isSideBar={isSideBar}
      >
        {children}
      </Container>
      {isFooter && <Footer />}
    </Fragment>
  )
}
