import { Key, useState } from "react"
import { getFullName } from "../../../../../../../utils/getFullName"
import {
  AttentionsDetailDataSourceType,
  AttentionsDetailResponseType,
} from "../../../../models/detail"

export const useRowSelection = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [userInfoListSelected, setUserInfoListSelected] = useState<
    AttentionsDetailResponseType[]
  >([])

  const onSelectChange = (
    newSelectedRowKeys: Key[],
    selectedRows: AttentionsDetailDataSourceType[],
  ) => {
    setSelectedRowKeys(newSelectedRowKeys)
    const newSelectedRows = selectedRows.map((item) => ({
      attentionDetailId: item.attentionDetailId,
      fullNameKanji: getFullName(item.kanjiSurname, item.kanjiName, true),
      fullNameRomaji: getFullName(item.romajiSurname, item.romajiName),
      travelerId: item.travelerId,
    }))
    setUserInfoListSelected(newSelectedRows)
  }

  const rowSelection = {
    onChange: onSelectChange,
    selectedRowKeys: selectedRowKeys,
  }

  return {
    rowSelection,
    selectedRowKeys,
    setSelectedRowKeys,
    userInfoListSelected,
  }
}
