/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable max-lines */
import { useEffect } from "react"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { useVariables } from "../useVariables"
import { File } from "../../../../../../../constant/File"
import { RcFile } from "antd/lib/upload"
import { openNotification } from "../../../../../../../utils/openNotification"
import { BreakMessage } from "../../../../../../../_webui/layout/components/Typography/BreakMessage"
import { Translate } from "../../../../../../../constant/Translate"
import { getSystemInfo } from "./getSystemInfo"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { Routers } from "../../../../../../../constant/Routers"
import {
  FilesUploadInfoUploadType,
  UploadFileType,
  UseFilesUploadType,
} from "../../../../model/filesUpload"
import { batchApi } from "../../../../apis"
import { replaceStringParam } from "../../../../../../../utils/replaceStringParam"
import { Variables } from "../../../../constants/filesUpload"
import { getItemLength } from "./getSystemInfo/getItemLength"

const { SYSTEM_NAMES } = Variables
const {
  FILE_MAX_UPLOAD,
  FILE_SIZE_LIMIT_KB,
  FILE_SIZE_LIMIT_MB,
  FILE_TYPE_ALLOW,
} = File
const { ERROR_SYSTEM_LENGTH } = SYSTEM_NAMES
const {
  MESSAGE_E0023,
  MESSAGE_E0024,
  MESSAGE_E0068,
  MESSAGE_E0070,
  MESSAGE_E0132,
  MESSAGE_I0017,
} = Translate
const { SCR973 } = Routers

export const useFilesUpload = (): UseFilesUploadType<UploadFileType> => {
  const {
    columns,
    fileUploadInfoList,
    isCommonExternalSystemLinkage,
    isDisableUpload,
    isHiddenBtn,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenConfirm,
    isOpenReload,
    isOpenSuccess,
    newData,
    onCancel,
    onCancelConfirm,
    onCancelReload,
    onOkSuccess,
    onOpen,
    onOpenConfirm,
    onOpenReload,
    onOpenSuccess,
    push,
    rowSelection,
    selectedKeys,
    selectedRowKeys,
    setFileUploadInfoList,
    setIsCheckAll,
    setIsLoading,
    setIsLoadingLayout,
    setSelectedRowKeys,
  } = useVariables()

  const setDataBeforeUploadError = (
    message: string,
    validValue: string,
    validValue2?: number | string,
  ) => {
    openNotification(
      <BreakMessage
        message={message}
        validValue={validValue}
        validValue2={validValue2}
      />,
    )
  }

  const getFileUploadInfo = (): FilesUploadInfoUploadType => {
    const uploadFiles = fileUploadInfoList?.filter(
      ({ index, systemId }) =>
        selectedRowKeys.includes(index) && systemId !== "0",
    )
    const errorFiles = fileUploadInfoList?.filter(
      ({ index, systemId }) =>
        selectedRowKeys.includes(index) && systemId === "0",
    )

    const dataAfterUploaded = fileUploadInfoList?.filter(
      ({ index, systemId }) =>
        !selectedRowKeys.includes(index) || systemId === "0",
    )

    const validIds = dataAfterUploaded.map((item) => item.index)

    const filteredKeys = selectedRowKeys.filter((item) =>
      validIds.includes(String(item)),
    )

    return { dataAfterUploaded, errorFiles, filteredKeys, uploadFiles }
  }

  const onOkUpload = () => {
    const { errorFiles, uploadFiles } = getFileUploadInfo()
    if (uploadFiles?.length > 0) {
      if (errorFiles?.length > 0) {
        onOpenConfirm()
      } else {
        handleRunBatch()
      }
    } else {
      // 画面にエラー通知を表示する。
      openNotification(MESSAGE_I0017)
    }
    onCancel()
  }

  const onOkConfirm = () => {
    handleRunBatch()
    onCancelConfirm()
  }

  const handleRunBatch = () => {
    const { uploadFiles, dataAfterUploaded, filteredKeys } = getFileUploadInfo()

    if (uploadFiles.length > FILE_MAX_UPLOAD) {
      openNotification(replaceStringParam(MESSAGE_E0024, FILE_MAX_UPLOAD))
      return
    }

    setIsLoading(true)
    //API FNC9_RunBatchを呼び出し、S3にファイルをアップロードする。アップロードファイルに該当するバッチを実行し、返却結果を"fileUploadInfoList"変数に設定する。
    batchApi
      .runBatch(uploadFiles)
      .then(() => {
        setFileUploadInfoList([])
        setSelectedRowKeys(filteredKeys)
        newData.current = dataAfterUploaded

        // 画面に実施が成功した通知を表示する。
        onOpenSuccess()
        onCancel()
        setIsLoading(false)
        setIsCheckAll(filteredKeys?.length > 0)
      })
      .catch((error) => {
        //「SCR973_エラー情報の表示画面」に遷移する。
        const stateError = pushStateError(error)
        push({ pathname: SCR973, state: stateError })
      })
  }

  const beforeUpload = async (file: RcFile) => {
    if (typeof file !== "string") {
      const { name, size, type } = file
      const fileSize = size / 1024
      const isValidFileSize = fileSize < FILE_SIZE_LIMIT_KB
      const isFileNameDuplicate = fileUploadInfoList?.some(
        (item) => item.fileName === name,
      )
      if (!type.includes(FILE_TYPE_ALLOW)) {
        setDataBeforeUploadError(MESSAGE_E0070, name, FILE_TYPE_ALLOW)
        return false
      }

      // ファイルサイズのバリデーションチェックを行う。
      if (!isValidFileSize) {
        setDataBeforeUploadError(MESSAGE_E0023, String(FILE_SIZE_LIMIT_MB))
        return false
      }

      // ファイル名の重複チェック
      if (isFileNameDuplicate) {
        setDataBeforeUploadError(MESSAGE_E0068, name)
        return false
      }

      // 「システム名」の値を設定する。
      const systemInfo = await getSystemInfo(file)
      if (systemInfo.systemName === ERROR_SYSTEM_LENGTH) {
        setDataBeforeUploadError(MESSAGE_E0132, name, getItemLength(name))
        return false
      }

      const reader = new FileReader()
      reader.onload = (event: ProgressEvent<FileReader>) => {
        if (event.isTrusted) {
          newData.current?.push({
            fileData: `${event.target?.result}`,
            fileName: file.name,
            index: file.uid,
            systemId: systemInfo.systemId,
            systemName: systemInfo.systemName,
          })

          const dataTotal = [...newData.current]
          selectedKeys.current.push(file.uid)
          setSelectedRowKeys((prevSelectedRowKeys) => [
            ...prevSelectedRowKeys,
            file.uid,
          ])
          setFileUploadInfoList(dataTotal)
        }
      }
      reader.readAsDataURL(file)
      return false
    }
  }

  const onOkReload = () => {
    newData.current = []
    setSelectedRowKeys([])
    selectedKeys.current = []
    setFileUploadInfoList([])
    onCancelReload()
    setIsCheckAll(false)
  }

  useEffect(() => {
    // ユーザの画面アクセス許可チェックを処理する。
    if (!isCommonExternalSystemLinkage) {
      //「SCR973_エラー情報の表示画面」に遷移する。
      const { pathname, state } = backToLogin()
      push({ pathname, state })
    } else {
      setTimeout(() => {
        setIsLoadingLayout(false)
      }, 500)
    }
  }, [])

  return {
    beforeUpload,
    columns,
    fileNameList: [],
    fileUploadInfoList,
    isDisableUpload,
    isHiddenBtn,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenConfirm,
    isOpenReload,
    isOpenSuccess,
    onCancel,
    onCancelConfirm,
    onCancelReload,
    onOkConfirm,
    onOkReload,
    onOkSuccess,
    onOkUpload,
    onOpen,
    onOpenReload,
    rowSelection,
  }
}
