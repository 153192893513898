import { FC, Fragment } from "react"
import { Table } from "../../../../../../../_webui/layout/components/Table"
import { TotalRecords } from "../../../../../../../_webui/layout/components/Typography/TotalRecords"
import {
  FilesUploadTableProps,
  UploadFileType,
} from "../../../../models/files/upload"
import { FilesTitleLeft } from "../../TitleLeft"

export const FilesUploadTable: FC<FilesUploadTableProps<UploadFileType>> = (
  props,
) => {
  const {
    columns,
    currentUsage,
    dataSource,
    isResetTable,
    onChangePagination,
    onChangeTable,
    perItem,
    rowSelection,
  } = props

  return dataSource?.length ? (
    <Fragment>
      <TotalRecords
        perItem={perItem}
        titleLeft={
          <FilesTitleLeft
            currentUsage={currentUsage}
            dataLength={dataSource?.length}
          />
        }
        total={dataSource?.length}
      />
      <Table
        columns={columns}
        dataSource={dataSource}
        isLoading={false}
        isResetTable={isResetTable}
        onChange={onChangePagination}
        onChangeTable={onChangeTable}
        pagination={true}
        rowKey={(record) => record.index}
        rowSelection={rowSelection}
      />
    </Fragment>
  ) : null
}
