import "./style/index.scss"
import { FC } from "react"
import { ListProps } from "./models"

export const List: FC<ListProps> = (props) => {
  const { heading, listItems } = props

  return (
    <div className="list">
      <div className="list-heading">{heading}</div>
      {listItems.map((item, index) => (
        <div className="list-item" key={index}>
          <div className="list-title">{item.title}</div>
          {item.content?.map((itemContent, index) => (
            <div className="list-content" key={index}>
              {itemContent}
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}
