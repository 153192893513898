/* eslint-disable max-lines */
import { Routers } from "../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { UseAuthorityType } from "../../../../../../../utils/hook/useAuthority/models"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { travelerApi } from "../../../../apis"
import { TravelersDetailResType } from "../../../../models/detail"
import { useVariablesApi } from "../useVariablesApi"
import { getFullName } from "../../../../../../../utils/getFullName"
import { userIdNotExist } from "../../../../../../../utils/validator/userIdNotExist"
import { convertData } from "./convertData"

const { SCR119, SCR973 } = Routers

export const useApi = (
  props: Pick<UseAuthorityType, "isRoleAdmin" | "isRoleSubAdmin">,
) => {
  const {
    fullNameRomaji,
    id,
    isLoading,
    isLoadingLayout,
    isSystemIdentifier,
    push,
    setFullNameRomaji,
    setIsLoading,
    setIsLoadingLayout,
    setIsSystemIdentifier,
    setTravelerInfo,
    setTravelerInfoJson,
    state,
    travelerInfo,
    travelerInfoJson,
  } = useVariablesApi()
  const { isRoleAdmin, isRoleSubAdmin } = props

  const onOk = () => {
    setIsLoading(true)
    if (isRoleSubAdmin)
      // APIを呼び出し、削除申請対象の渡航者情報をデータベースに登録する。
      travelerApi
        .requestToDisableTraveler(id)
        .then(() => {
          //「SCR119_渡航者情報の削除後画面」に遷移する。
          push({
            pathname: SCR119,
            state: {
              isFromSCR115: true,
              searchCondition: state?.searchCondition,
            },
          })
        })
        .catch((error: AxiosErrorType) => {
          // 「SCR973_エラー情報の表示画面」に遷移する。
          const stateError = pushStateError(error)
          const _stateError = userIdNotExist(
            [travelerInfoJson?.base],
            error,
            stateError,
          )
          push({ pathname: SCR973, state: _stateError })
        })
    else if (isRoleAdmin)
      // APIを呼び出し、データベースから渡航者情報を論理削除する。
      travelerApi
        .disableTraveler(id)
        .then(() => {
          // 「SCR119_渡航者情報の削除後画面」に遷移する。
          push({
            pathname: SCR119,
            state: {
              isFromSCR115: true,
              searchCondition: state?.searchCondition,
            },
          })
        })
        .catch((error: AxiosErrorType) => {
          // 「SCR973_エラー情報の表示画面」に遷移する。
          const stateError = pushStateError(error)
          const _stateError = userIdNotExist(
            [travelerInfoJson?.base],
            error,
            stateError,
          )
          push({ pathname: SCR973, state: _stateError })
        })
  }

  const getTravelerDetails = () => {
    // APIを呼び出し、データベースから渡航者情報を取得する。
    travelerApi
      .getTravelerDetails(id)
      .then((res: TravelersDetailResType) => {
        setTravelerInfoJson(res)
        const data = convertData(res, isRoleAdmin, isRoleSubAdmin)
        setTravelerInfo(data)
        setFullNameRomaji(
          getFullName(res.base.romajiSurname, res.base.romajiName),
        )
        setIsSystemIdentifier(!!Number(res.detail.systemIdentifier))
        setIsLoadingLayout(false)
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const stateError = pushStateError(error)
        push({ pathname: SCR973, state: stateError })
      })
  }

  return {
    fullNameRomaji,
    getTravelerDetails,
    isLoading,
    isLoadingLayout,
    isSystemIdentifier,
    onOk,
    travelerInfo,
  }
}
