export const getValueComboboxTable = (
  value1: string,
  value2: string,
  index: number,
  prefix?: string,
) => {
  const _value = `${value1}_${index}_${value2}`

  if (prefix) return `${_value}_${prefix}`

  return `${_value}`
}
