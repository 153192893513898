import { FC } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { ButtonSubmit } from "../../../../../../_webui/layout/components/Button/Submit"
import { TravelersBatchImportButtonSubmitProps } from "../../../models/batchImport"
const { TEXT_T147, TEXT_T012 } = Translate

export const TravelersBatchImportButtonSubmit: FC<
  TravelersBatchImportButtonSubmitProps
> = (props) => {
  const {
    isDisabledSubmit,
    isVisibleBtnCancel,
    isVisibleBtnRegister,
    onOpen,
    onOpenDeletedOrGoToHome,
  } = props

  return (
    <ButtonSubmit
      isDisabledSubmit={isDisabledSubmit}
      isHiddenSubmit={!isVisibleBtnRegister}
      isReset={isVisibleBtnCancel}
      onClickReset={onOpenDeletedOrGoToHome}
      onClickSubmit={onOpen}
      resetText={TEXT_T012}
      submitText={TEXT_T147}
    />
  )
}
