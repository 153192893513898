import { Table } from "antd"
import { Translate } from "../../../../../../../constant/Translate"
import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../_webui/layout/components/Table/models"
import { Expandable } from "../../../../../../../_webui/layout/components/Typography/Expandable"
import { SafetiesCloneDataType } from "../../../../models/clone"
import { getValueByLanguage } from "../../../../../../../utils/getValueByLanguage"

const { TEXT_T315, TEXT_T333, TEXT_T312, TEXT_T316 } = Translate
const { SELECTION_COLUMN } = Table

export const useTableConfig = (): UseTableConfigType<SafetiesCloneDataType> => {
  const columns: ColumnType<SafetiesCloneDataType>[] = [
    SELECTION_COLUMN,
    {
      dataIndex: "safetyTitle",
      sorter: (a, b) => a.safetyTitle.localeCompare(b.safetyTitle),
      title: TEXT_T315,
      width: 200,
    },
    {
      dataIndex: "implementationDateFormat",
      sorter: (a, b) =>
        a.implementationDateFormat.localeCompare(b.implementationDateFormat),
      title: TEXT_T333,
      width: getValueByLanguage(150, 180),
    },
    {
      dataIndex: "category",
      sorter: (a, b) => a.category.localeCompare(b.category),
      title: TEXT_T312,
      width: 160,
    },
    {
      render: (_, data) => (
        <Expandable title={TEXT_T316} content={data.bodyContent} />
      ),
      sorter: (a, b) => a.bodyContent.localeCompare(b.bodyContent),
      title: TEXT_T316,
      width: 400,
    },
  ]
  return {
    columns,
  }
}
