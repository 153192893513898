const DATE_FORMAT = "YYYY-MM-DD"
const DATE_FORMAT_EN = "YYYY/MM/DD"
const DATE_FORMAT_JP = "YYYY年MM月DD日"
const DATE_FORMAT_EXPORT_EN = "YYYY/MM"
const DATE_FORMAT_EXPORT_JP = "YYYY年MM月"
const DATE_TIME_FORMAT_DEFAULT_EN = "YYYY/MM/DD HH:mm:ss"
const DATE_TIME_FORMAT_DEFAULT_JP = "YYYY年MM月DD日　HH時mm分ss秒"
const DATE_TIME_FORMAT_EN = "YYYY/MM/DD HH:mm"
const DATE_TIME_FORMAT_JP = "YYYY年MM月DD日　HH時mm分"
const HOUR_FORMAT = "HH"
const MINUTE_FORMAT = "mm"
const TIME_FORMAT = "HH:mm:ss"

export const Timezone = {
  DATE_FORMAT,
  DATE_FORMAT_EN,
  DATE_FORMAT_EXPORT_EN,
  DATE_FORMAT_EXPORT_JP,
  DATE_FORMAT_JP,
  DATE_TIME_FORMAT_DEFAULT_EN,
  DATE_TIME_FORMAT_DEFAULT_JP,
  DATE_TIME_FORMAT_EN,
  DATE_TIME_FORMAT_JP,
  HOUR_FORMAT,
  MINUTE_FORMAT,
  TIME_FORMAT,
}
