import { useHistory, useLocation } from "react-router-dom"
import { GeneralCode } from "../../../../../../../constant/GeneralCode"
import { getGeneralMasters } from "../../../../../../../utils/apis/getGeneralMasters"
import { AttentionsListState } from "../../../../models/list"
import { useForm } from "../useForm"
import { useTableConfig } from "../useTableConfig"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { getBreadcrumbItems } from "./getBreadcrumbItems"
import { Variables } from "../../../../constants/list/variables"
import { useRowSelection } from "../useRowSelection"

const { CTGR, IPTC } = GeneralCode
const { STATUS_LIST, STATUS_LIST_HISTORY } = Variables

export const useVariables = () => {
  const { push } = useHistory()
  const { pathname } = useLocation<AttentionsListState>()
  const { columns } = useTableConfig()
  //  APIを呼び出し、カテゴリを取得する。
  const { generalMasters: categoryList } = getGeneralMasters(CTGR)
  const { generalMasters: severityList } = getGeneralMasters(IPTC)
  const {
    isCommonRefer,
    isFromHistory,
    isHiddenDetailBtn,
    isHiddenResponseBtn,
    isHiddenResponseHistoryBtn,
    isRoleSubAdmin,
  } = useVariablesAuthority(pathname)

  const {
    attentionInfoList,
    form,
    getAttentions,
    isLoading,
    isLoadingLayout,
    isOpen,
    onBlur,
    onCancelPopup,
    onFinish,
    onKeyDown,
    onOpenPopup,
    searchCondition,
  } = useForm(isRoleSubAdmin)

  const breadcrumbItems = getBreadcrumbItems(pathname)
  const {
    attentionDetailId,
    attentionId,
    isDisabledDetailBtn,
    isDisabledResponseBtn,
    isDisabledResponseHistoryBtn,
    onChange,
    perItem,
    rowSelection,
  } = useRowSelection(attentionInfoList, isRoleSubAdmin)
  const statusList = isFromHistory ? STATUS_LIST_HISTORY : STATUS_LIST

  return {
    attentionDetailId,
    attentionId,
    attentionInfoList,
    breadcrumbItems,
    categoryList,
    columns,
    form,
    getAttentions,
    isCommonRefer,
    isDisabledDetailBtn,
    isDisabledResponseBtn,
    isDisabledResponseHistoryBtn,
    isHiddenDetailBtn,
    isHiddenResponseBtn,
    isHiddenResponseHistoryBtn,
    isLoading,
    isLoadingLayout,
    isOpen,
    isRoleSubAdmin,
    onBlur,
    onCancelPopup,
    onChange,
    onFinish,
    onKeyDown,
    onOpenPopup,
    pathname,
    perItem,
    push,
    rowSelection,
    searchCondition,
    severityList,
    statusList,
  }
}
