import styled, { css } from "styled-components"
import { Colors } from "../../../../../../../constant/Styled/Colors"
import { Fonts } from "../../../../../../../constant/Styled/Fonts"
import { StyledSidebarToggleProps } from "../models"

const { LIGHT, PRIMARY } = Colors
const { FS_SMALL } = Fonts

const StyledIcon = styled.span`
  align-items: center;
  background-color: ${PRIMARY};
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  border: none;
  cursor: pointer;
  display: inline-flex;
  flex-wrap: wrap;
  height: 30px;
  padding: 4px;
  position: relative;
  width: 40px;
`

const StyledIconItem = styled.span`
  background-color: ${LIGHT};
  border-radius: 2px;
  display: inline-block;
  height: 2px;
  margin: 2px;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
`

const StyledIconItemFirst = styled(StyledIconItem)`
  transform-origin: bottom;
  transform: rotatez(45deg) translate(3px, 6px);
  width: 20px;
`

const StyledIconItemSecond = styled(StyledIconItem)`
  transform-origin: top;
  transform: rotatez(-45deg) translate(-2px, -2px);
  width: 20px;
`

const StyledIconItemThird = styled(StyledIconItem)`
  opacity: 0;
  transform: translate(2px, -11px) rotatez(45deg);
  visibility: hidden;
  width: 20px;
`

const StyledToggleText = styled.div`
  font-size: ${FS_SMALL};
  margin-left: 9px;
`

const StyledToggle = styled.div<StyledSidebarToggleProps>`
  align-items: center;
  display: flex;
  margin-bottom: 30px;
  margin-top: 12px;
  ${(props) =>
    props.isExpandableMenu === "true" &&
    css`
      ${StyledIconItemFirst},
      ${StyledIconItemSecond} {
        transform-origin: initial;
        transform: initial;
      }
      ${StyledIconItemThird} {
        display: inline-block;
        opacity: 1;
        transform: initial;
        visibility: visible;
      }
      ${StyledToggleText} {
        display: none;
      }
    `}
`

export const Styled = {
  StyledIcon,
  StyledIconItemFirst,
  StyledIconItemSecond,
  StyledIconItemThird,
  StyledToggle,
  StyledToggleText,
}
