import { useState } from "react"
import { useApi } from "../useApi"

export const useVariables = () => {
  const {
    attentionInfo,
    getAttention,
    getAttentionTravelers,
    isLoading,
    isLoadingLayout,
    isShowFormSuccess,
  } = useApi()

  const [lang, setLang] = useState("jp")

  return {
    attentionInfo,
    getAttention,
    getAttentionTravelers,
    isLoading,
    isLoadingLayout,
    isShowFormSuccess,
    lang,
    setLang,
  }
}
