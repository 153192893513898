import { useHistory } from "react-router-dom"
import { replaceString } from "../../../../../../../utils/replaceString"
import { UseRoutersProps } from "../../../../models/clone"

export const useRouters = (props: UseRoutersProps) => {
  const { push } = useHistory()
  const { scheduleList, selectedRowKeys, state } = props

  const goBackSchedulesAdd = () => {
    // 「SCR212_渡航予定情報の登録画面」に遷移する。
    if (state?.sourceURL && state?.travelerId)
      push({
        pathname: replaceString(state.sourceURL, state.travelerId),
        state,
      })
  }

  const goToSchedulesAdd = () => {
    const scheduleData = scheduleList.find(
      (item) => item.info.travelScheduleId === selectedRowKeys[0],
    )
    // 「SCR212_渡航予定情報の登録画面」に遷移する。
    if (state?.sourceURL && state?.travelerId)
      push({
        pathname: replaceString(state.sourceURL, state.travelerId),
        state: {
          ...state,
          isCloneSchedule: true,
          scheduleData,
        },
      })
  }

  return {
    goBackSchedulesAdd,
    goToSchedulesAdd,
  }
}
