import { useModal } from "../../../../../../../../utils/hook/useModal"

export const useVariablesModal = () => {
  const { isOpen, onCancel, onOpen } = useModal()
  const {
    isOpen: isOpenConfirm,
    onCancel: onCancelConfirm,
    onOpen: onOpenConfirm,
  } = useModal()
  const {
    isOpen: isOpenConfirmDuplicate,
    onCancel: onCancelConfirmDuplicate,
    onOpen: onOpenConfirmDuplicate,
  } = useModal()

  return {
    isOpen,
    isOpenConfirm,
    isOpenConfirmDuplicate,
    onCancel,
    onCancelConfirm,
    onCancelConfirmDuplicate,
    onOpen,
    onOpenConfirm,
    onOpenConfirmDuplicate,
  }
}
