import { Input } from "antd"
import { FC } from "react"
import { ItemInputProps } from "../models"
import { FormItem } from "../.."
import { formatter } from "../constants"
import { Images } from "../../../../../../../constant/Images"
import { Image } from "../../../../Image"

const { Password } = Input
const { IMAGE_PASSWORD_VISIBLE, IMAGE_PASSWORD_INVISIBLE } = Images

export const ItemInputPassword: FC<ItemInputProps> = (props) => {
  const {
    addonAfter,
    addonBefore,
    className,
    isBgBlue,
    isHidden,
    isHiddenLabel,
    isDisabled,
    isReadOnly,
    label,
    maxLength,
    name,
    placeholder,
    required,
    rules,
    tooltip,
    type,
  } = props

  return (
    <FormItem
      className={className}
      isBgBlue={isBgBlue}
      isHidden={isHidden}
      isHiddenLabel={isHiddenLabel}
      label={label}
      name={name}
      required={required}
      rules={rules}
      tooltip={tooltip}
    >
      <Password
        iconRender={(visible) =>
          visible ? (
            <Image src={IMAGE_PASSWORD_VISIBLE} />
          ) : (
            <Image src={IMAGE_PASSWORD_INVISIBLE} />
          )
        }
        addonAfter={addonAfter}
        addonBefore={addonBefore}
        disabled={isDisabled}
        maxLength={maxLength}
        placeholder={placeholder}
        showCount={
          !!maxLength && {
            formatter,
          }
        }
        type={type}
        readOnly={isReadOnly}
      />
    </FormItem>
  )
}
