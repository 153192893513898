import styled, { css } from "styled-components"
import { Regex } from "../../../../../constant/Regex"
import { Fonts } from "../../../../../constant/Styled/Fonts"
import { Tooltip } from "../../Tooltip"
import { StyledButtonProps } from "../models"
import { Colors } from "../../../../../constant/Styled/Colors"

const { FOCUS } = Colors
const { FONT_YU_GOTHIC, FS_X_LARGE, FW_MEDIUM } = Fonts
const { REGEX_IS_DESKTOP } = Regex

const StyledButton = styled.button<StyledButtonProps>`
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  display: inline-flex;
  font-family: ${FONT_YU_GOTHIC};
  font-size: ${FS_X_LARGE};
  font-weight: ${FW_MEDIUM};
  justify-content: center;
  min-width: ${(props) => (props.isSmall ? "150px" : "200px")};
  outline: none;
  padding: 6px 8px 7px;
  position: relative;
  transition: all 0.3s ease-in-out;
  white-space: break-spaces;
  &:focus {
    box-shadow: 0px 0px 4px ${FOCUS};
  }
  ${(props) =>
    REGEX_IS_DESKTOP
      ? props.isAbsolute &&
        css`
          position: absolute;
          ${props.absolutePosition === "left" ? `left: 0` : `right: 0`}
        `
      : props.isFullWidth &&
        css`
          display: block;
          width: 100%;
          margin: 0 auto;
        `}
`

const StyledButtonAddon = styled.span`
  max-height: 22px;
  display: inline-flex;
  ${!REGEX_IS_DESKTOP &&
  css`
    position: absolute;
  `}
`

const StyledButtonAddonAfter = styled(StyledButtonAddon)`
  ${!REGEX_IS_DESKTOP
    ? css`
        right: 10px;
      `
    : css`
        margin-left: 10px;
      `}
`

const StyledButtonAddonBefore = styled(StyledButtonAddon)`
  ${!REGEX_IS_DESKTOP
    ? css`
        left: 10px;
      `
    : css`
        margin-right: 10px;
      `}
`

const StyledTooltip = styled(Tooltip)`
  margin-left: 0;
  margin-right: 10px;
`

export const Styled = {
  StyledButton,
  StyledButtonAddonAfter,
  StyledButtonAddonBefore,
  StyledTooltip,
}
