import moment from "moment"
import { FormFieldValueType, NamePathType } from "./models"
import { Translate } from "../../../constant/Translate"

const { MESSAGE_E0137 } = Translate

export const validatorRangeDate2 =
  (propsItem: NamePathType) => (props: FormFieldValueType) => ({
    validator() {
      const { namePathEndDate, namePathStartDate } = propsItem
      const { getFieldValue } = props

      const startDate = getFieldValue(namePathStartDate)
      const endDate = getFieldValue(namePathEndDate)

      if (startDate && endDate && moment(endDate).diff(startDate, "days") < 1) {
        return Promise.reject(new Error(MESSAGE_E0137))
      }

      return Promise.resolve()
    },
  })
