import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const { isCommonRefer, isRoleAdmin, isRoleSubAdmin, isTravelerRegister } =
    useAuthority()

  const isNotAccessScreen = !isTravelerRegister

  const isVisibleBtnBtn = isRoleAdmin || isRoleSubAdmin
  const isVisibleBtnCancel = isVisibleBtnBtn && isCommonRefer
  const isVisibleBtnRegister = isVisibleBtnBtn && isTravelerRegister

  return {
    isNotAccessScreen,
    isRoleSubAdmin,
    isVisibleBtnCancel,
    isVisibleBtnRegister,
  }
}
