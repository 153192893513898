import { FC, Fragment } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { Alert } from "../../../../../../_webui/layout/components/Alert"
import { SafetiesDetailAlertProps } from "../../../models/detail"
import { getDateTimeZoneByUserInformation } from "../../../../../../utils/getDate"
import { GeneralCode } from "../../../../../../constant/GeneralCode"
import { getValueByLanguage } from "../../../../../../utils/getValueByLanguage"
import { getFullName } from "../../../../../../utils/getFullName"
import { Styled } from "./styled"

const { TEXT_T328, TEXT_T338 } = Translate
const { CTGR_OTHER } = GeneralCode
const { StyledSafetiesLabel } = Styled

export const SafetiesDetailAlert: FC<SafetiesDetailAlertProps> = (props) => {
  const { safetyInfo } = props
  const separator = getValueByLanguage("", " ")

  return (
    <Alert
      heading={
        <Fragment>
          <StyledSafetiesLabel>
            {TEXT_T338}
            {separator}
            <b>
              {getDateTimeZoneByUserInformation(safetyInfo?.implementationDate)}
            </b>
          </StyledSafetiesLabel>
          <StyledSafetiesLabel>
            {TEXT_T328}
            {separator}
            <b>
              {safetyInfo?.categoryId === CTGR_OTHER
                ? getValueByLanguage(
                    getFullName(
                      safetyInfo.categoryName,
                      safetyInfo.categoryValue,
                      true,
                    ),
                    getFullName(
                      safetyInfo.categoryName,
                      safetyInfo.categoryValue,
                    ),
                  )
                : safetyInfo?.categoryName}
            </b>
          </StyledSafetiesLabel>
        </Fragment>
      }
      content={safetyInfo?.bodyContent}
      isIcon={false}
      title={safetyInfo?.safetyTitle}
      type="info"
    />
  )
}
