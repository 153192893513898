import { useEffect } from "react"
import { Routers } from "../../../../../../../constant/Routers"
import {
  TravelerDeleteType,
  TravelersDeleteFormType,
} from "../../../../models/confirmDelete"
import { useVariables } from "../useVariables"
import { backToLogin } from "../../../../../../../utils/backToLogin"

const { SCR114_USER } = Routers

export const useConfirmDelete =
  (): TravelersDeleteFormType<TravelerDeleteType> => {
    const {
      breadcrumbItems,
      columns,
      confirmDeleteTitle,
      confirmDeleteTitleLeft,
      errorHeading,
      errorItems,
      isHiddenBtnCancel,
      isHiddenBtnSubmit,
      isLoading,
      isLoadingLayout,
      isOpen,
      isTravelerDelete,
      modalConfirmMessage,
      onCancel,
      onOk,
      onOpen,
      push,
      setIsLoadingLayout,
      setUserInfoList,
      state,
      userInfoList,
    } = useVariables()

    const goToTravelersList = () => {
      // 「SCR114_渡航者情報の一覧画面」に遷移する。
      push({
        pathname: SCR114_USER,
        state: { searchCondition: state?.searchCondition },
      })
    }

    useEffect(() => {
      // ユーザーの画面アクセス許可チェックを処理する
      if (!isTravelerDelete) {
        // 「SCR973_エラー情報の表示画面」に遷移する
        const { pathname, state } = backToLogin()
        push({ pathname, state })
      } else {
        if (state) {
          setUserInfoList(state.userInfoList)
        }
        setTimeout(() => {
          setIsLoadingLayout(false)
        }, 500)
      }
    }, [])

    return {
      breadcrumbItems,
      columns,
      confirmDeleteTitle,
      confirmDeleteTitleLeft,
      dataSource: userInfoList,
      errorHeading,
      errorItems,
      goToTravelersList,
      isHiddenBtnCancel,
      isHiddenBtnSubmit,
      isLoading,
      isLoadingLayout,
      isOpen,
      modalConfirmMessage,
      onCancel,
      onOk,
      onOpen,
    }
  }
