import { Form } from "antd"
import { useState } from "react"
import { useLocation } from "react-router-dom"
import { GeneralCode } from "../../../../../../../constant/GeneralCode"
import { Translate } from "../../../../../../../constant/Translate"
import { getGeneralMasters } from "../../../../../../../utils/apis/getGeneralMasters"
import { validatorRequiredField } from "../../../../../../../utils/validator/requiredField"
import { ItemInput } from "../../../../../../../_webui/layout/components/Form/Item/Input"
import { ItemTextArea } from "../../../../../../../_webui/layout/components/Form/Item/TextArea"
import { GridItemType } from "../../../../../../../_webui/layout/components/Grid/models"
import { Tooltip } from "../../../../../../../_webui/layout/components/Tooltip"
import { Styled } from "./styled"
import { SafetiesStateType } from "../../../../models/safety"

const { CTGR, CTGR_OTHER } = GeneralCode
const { TEXT_T312, TEXT_T315, TEXT_T316, HELP_H0029 } = Translate
const {
  StyledGridCategory,
  StyledGridCategorySelect,
  StyledGridCategoryInput,
} = Styled

export const useGridConfig = () => {
  // APIを呼び出し、カテゴリ情報を取得する。
  const { generalMasters } = getGeneralMasters(CTGR)
  const { state } = useLocation<SafetiesStateType>()
  const [form] = Form.useForm()
  const [isShowCategoryOther, setIsShowCategoryOther] = useState(
    state?.transmissionInfo?.categoryId === CTGR_OTHER,
  )

  const onChangeCategory = (value?: string) => {
    form.setFieldValue("categoryValue", "")
    if (value === CTGR_OTHER) {
      setIsShowCategoryOther(true)
    } else {
      setIsShowCategoryOther(false)
    }
  }

  const gridItems: GridItemType[] = [
    {
      key: <label htmlFor="categoryId">{TEXT_T312}</label>,
      text: (
        <StyledGridCategory>
          <StyledGridCategorySelect
            name="categoryId"
            onChange={onChangeCategory}
            rules={[validatorRequiredField]}
            selectItem={generalMasters}
          />
          {isShowCategoryOther && (
            <StyledGridCategoryInput
              isHiddenLabel
              label={TEXT_T312}
              maxLength={50}
              name="categoryValue"
              rules={[validatorRequiredField]}
            />
          )}
        </StyledGridCategory>
      ),
    },
    {
      key: (
        <Tooltip title={HELP_H0029}>
          <label htmlFor="safetyTitle">{TEXT_T315}</label>
        </Tooltip>
      ),
      text: (
        <ItemInput
          maxLength={50}
          name="safetyTitle"
          rules={[validatorRequiredField]}
        />
      ),
    },
    {
      key: <label htmlFor="bodyContent">{TEXT_T316}</label>,
      text: (
        <ItemTextArea
          maxLength={10000}
          name="bodyContent"
          rows={6}
          rules={[validatorRequiredField]}
        />
      ),
    },
  ]
  return { form, generalMasters, gridItems }
}
