import { getFullName } from "../../../../../../../../utils/getFullName"
import {
  TravelersAddSuccessResType,
  TravelersAddSuccessType,
} from "../../../../../models/addSuccess"

export const convertDataSource = (
  data: TravelersAddSuccessResType,
): TravelersAddSuccessType => ({
  bumonName: data.bumonName,
  dispatchTypeDetailName: data.dispatchTypeDetailName,
  fullNameKanji: getFullName(data.kanjiSurname, data.kanjiName, true),
  fullNameRomaji: getFullName(data.romajiSurname, data.romajiName),
  personEmailAddress: data.personEmailAddress,
  userId: data.userId,
})
