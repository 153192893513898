import { Endpoint } from "../../../../constant/Endpoint"
import { Environment } from "../../../../constant/Environment"
import { apiService } from "../../../../infrastructure/apiService"
import { ContactFormDataType } from "../models/contact"

const { COMMON_ENDPOINT } = Environment
const { FNC9_Send_FAQ } = Endpoint

export const systemsApi = {
  sendFAQ: (data: ContactFormDataType) =>
    apiService({
      data: data,
      method: "POST",
      url: `${COMMON_ENDPOINT}${FNC9_Send_FAQ}`,
    }),
}
