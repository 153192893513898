import { FC } from "react"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { Title } from "../../../../../_webui/layout/components/Typography/Title"
import { TotalRecords } from "../../../../../_webui/layout/components/Typography/TotalRecords"
import { Translate } from "../../../../../constant/Translate"
import { SafetiesDetailAlert } from "../../components/detail/Alert"
import { SafetiesDetailButtonGroup } from "../../components/detail/ButtonGroup"
import { SafetiesDetailTabs } from "../../components/detail/Tabs"
import { useSafetiesDetail } from "./modules/useDetail"
import { SafetiesDetailTable } from "../../components/detail/Table"

const { TEXT_T609, TEXT_T372, TEXT_T374 } = Translate

export const SafetiesDetail: FC = () => {
  const {
    breadcrumbItems,
    columns,
    countSafetyStatus,
    goToSafetiesAnswer,
    handleExportFile,
    isHiddenAnswerBtn,
    isHiddenBackBtn,
    isHiddenExportBtn,
    isLoading,
    isLoadingLayout,
    isResetTable,
    isSafetiesBrowseResponse,
    onChange,
    onGoBack,
    onTabClick,
    perItem,
    rowSelection,
    safetyInfo,
    safetyTravelers,
    selectedRowKeys,
  } = useSafetiesDetail()

  return (
    <Layout
      breadcrumbItems={breadcrumbItems}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T372}
    >
      <Card heading={TEXT_T372} isLoading={isLoading}>
        <Title titleLeft={TEXT_T609} />
        <SafetiesDetailAlert safetyInfo={safetyInfo} />
        <TotalRecords
          perItem={perItem}
          titleLeft={TEXT_T374}
          total={safetyTravelers?.length}
        />
        {isSafetiesBrowseResponse ? (
          <SafetiesDetailTabs
            countSafetyStatus={countSafetyStatus}
            onTabClick={onTabClick}
            tableItem={
              <SafetiesDetailTable
                columns={columns}
                isResetTable={isResetTable}
                isSafetiesBrowseResponse={isSafetiesBrowseResponse}
                onChange={onChange}
                perItem={perItem}
                rowSelection={rowSelection}
                safetyTravelers={safetyTravelers}
              />
            }
          />
        ) : (
          <SafetiesDetailTable
            columns={columns}
            isSafetiesBrowseResponse={isSafetiesBrowseResponse}
            isLoading={isLoading}
            onChange={onChange}
            rowSelection={rowSelection}
            safetyTravelers={safetyTravelers}
          />
        )}
        <SafetiesDetailButtonGroup
          goToSafetiesAnswer={goToSafetiesAnswer}
          handleExportFile={handleExportFile}
          isHiddenAnswerBtn={isHiddenAnswerBtn}
          isHiddenBackBtn={isHiddenBackBtn}
          isHiddenExportBtn={isHiddenExportBtn}
          onGoBack={onGoBack}
          selectedRowKeys={selectedRowKeys}
        />
      </Card>
    </Layout>
  )
}
