import { useGridConfig } from "../useGridConfig"
import { useApi } from "../useApi"
import { useState } from "react"

export const useVariables = () => {
  const {
    form,
    handleGetSafetyDetail,
    isLoading,
    isLoadingLayout,
    isShowFormSuccess,
    onFinish,
    safetiesInfo,
    safetyInfo,
  } = useApi()

  const [lang, setLang] = useState("jp")

  const { gridItems } = useGridConfig({
    lang,
    safetiesInfo,
  })

  return {
    form,
    gridItems,
    handleGetSafetyDetail,
    isLoading,
    isLoadingLayout,
    isShowFormSuccess,
    lang,
    onFinish,
    safetyInfo,
    setLang,
  }
}
