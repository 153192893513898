import { useEffect } from "react"
import { Routers } from "../../../../../../../constant/Routers"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { UseSchedulesBatchImportType } from "../../../../models/batchImport"
import { SchedulesBatchImportDataType } from "../../../../models/batchImport/data"
import { useVariables } from "../useVariables"

const { SCR920 } = Routers

export const useSchedulesBatchImport =
  (): UseSchedulesBatchImportType<SchedulesBatchImportDataType> => {
    const {
      beforeUpload,
      breadcrumbItems,
      bulkAdd,
      bulkCheck,
      bulkDeleteData,
      bulkFileAdd,
      columnErrors,
      columns,
      countRecord,
      dataTemps,
      errorList,
      fileNameList,
      isDisabledSubmit,
      isLoading,
      isLoadingLayout,
      isNotAccessScreen,
      isOpen,
      isOpenUploaded,
      isResetTable,
      isVisibleBtnCancel,
      isVisibleBtnRegister,
      onCancel,
      onCancelUploaded,
      onChange,
      onOpen,
      onOpenDeleted,
      perItem,
      push,
    } = useVariables()

    const bulkDeleteDataConfirm = () => {
      bulkDeleteData()
      push(SCR920)
    }

    const onOpenDeletedOrGoToHome = () => {
      if (dataTemps.length) {
        onOpenDeleted()
      } else {
        push(SCR920)
      }
    }

    useEffect(() => {
      // ユーザーの画面アクセス許可チェックを処理する。
      if (isNotAccessScreen) {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const { pathname, state } = backToLogin()
        push({ pathname, state })
      } else bulkCheck()
    }, [])

    return {
      beforeUpload,
      breadcrumbItems,
      bulkAdd,
      bulkDeleteDataConfirm,
      bulkFileAdd,
      columnErrors,
      columns,
      countRecord,
      dataTemps,
      errorList,
      fileNameList,
      isDisabledSubmit,
      isLoading,
      isLoadingLayout,
      isOpen,
      isOpenUploaded,
      isResetTable,
      isVisibleBtnCancel,
      isVisibleBtnRegister,
      onCancel,
      onCancelUploaded,
      onChange,
      onOpen,
      onOpenDeletedOrGoToHome,
      perItem,
    }
  }
