import "./style/index.scss"
import { FC, Fragment } from "react"
import { FormItem } from ".."
import { Options } from "./constant"
import { Image } from "../../../Image"
import { Images } from "../../../../../../constant/Images"
import { ItemDatePicker } from "../DatePicker"
import { ItemSelect } from "../Select"
import { Styled, GlobalStyleAntPicker } from "./styled"
import { ItemDateTimePickerProps } from "./models"

const { SELECT_HOUR_ITEMS, SELECT_MINUTE_ITEMS } = Options
const { IMAGE_CALENDAR } = Images
const { StyledPickerCustom } = Styled

export const ItemDateTimePicker: FC<ItemDateTimePickerProps> = (props) => {
  const {
    datePickerName,
    disabledDate,
    isBgBlue,
    isCustom,
    isDefaultValue,
    isHiddenLabel,
    label,
    name,
    onBlur,
    onChangeDate,
    onChangeHour,
    onChangeMinute,
    onFocus,
    onKeyDown,
    required,
    rules,
    selectHourItems = SELECT_HOUR_ITEMS,
    selectHourName,
    selectMinuteItems = SELECT_MINUTE_ITEMS,
    selectMinutesName,
    suffixIcon = <Image src={IMAGE_CALENDAR} />,
    tooltip,
  } = props

  return (
    <Fragment>
      <GlobalStyleAntPicker />
      <FormItem
        isBgBlue={isBgBlue}
        isHiddenLabel={isHiddenLabel}
        label={label}
        name={name}
        required={required}
        rules={rules}
        tooltip={tooltip}
      >
        <StyledPickerCustom
          className="ant-date-time-picker"
          isCustom={isCustom}
        >
          <ItemDatePicker
            isHiddenLabel
            label={label}
            className="ant-date-time-picker-date"
            disabledDate={disabledDate}
            name={datePickerName}
            onBlur={onBlur}
            onChange={onChangeDate}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            rules={rules}
            suffixIcon={suffixIcon}
          />
          <ItemSelect
            className="ant-date-time-picker-hour"
            isDefaultValue={isDefaultValue}
            isDisabled
            isHiddenLabel
            label={label}
            name={selectHourName}
            onChange={onChangeHour}
            rules={rules}
            selectItem={selectHourItems}
          />
          <ItemSelect
            className="ant-date-time-picker-minutes"
            isDefaultValue={isDefaultValue}
            isDisabled
            isHiddenLabel
            label={label}
            name={selectMinutesName}
            onChange={onChangeMinute}
            rules={rules}
            selectItem={selectMinuteItems}
          />
        </StyledPickerCustom>
      </FormItem>
    </Fragment>
  )
}
