import { Translate } from "../../../../../../../constant/Translate"
import { getFullName } from "../../../../../../../utils/getFullName"
import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../_webui/layout/components/Table/models"
import { BreakText } from "../../../../../../../_webui/layout/components/Typography/BreakText"
import { SafetiesDataType } from "../../../../models/safety"

const { TEXT_T003, TEXT_T006, TEXT_T025, TEXT_T221, TEXT_T320 } = Translate

export const useTableConfig = (): UseTableConfigType<SafetiesDataType> => {
  const columns: ColumnType<SafetiesDataType>[] = [
    {
      dataIndex: "fullName",
      render: (_, data) => (
        <BreakText
          above={getFullName(data.kanjiSurname, data.kanjiName, true)}
          below={getFullName(data.romajiSurname, data.romajiName)}
        />
      ),
      title: <BreakText above={TEXT_T003} below={TEXT_T006} />,
      width: 200,
    },
    {
      dataIndex: "dispatchTypeDetailName",
      title: TEXT_T025,
      width: 260,
    },
    {
      dataIndex: "workPlace",
      title: TEXT_T221,
      width: 270,
    },
    {
      dataIndex: "emailAddress",
      render: (_, data) => (
        <BreakText above={data.emailAddress1} below={data.emailAddress2} />
      ),
      title: TEXT_T320,
      width: 300,
    },
  ]
  return {
    columns,
  }
}
