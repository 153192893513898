/* eslint-disable max-lines */
import { FC } from "react"
import { ButtonGroup } from "../../../../../../_webui/layout/components/Button/Group"
import { Form } from "../../../../../../_webui/layout/components/Form"
import { Modal } from "../../../../../../_webui/layout/components/Modal"
import { Translate } from "../../../../../../constant/Translate"
import { SchedulesAdvancedSearchFormProps } from "../../../models/list/search"
import { SchedulesAdvancedSearchCollapse } from "./Collapse"

const { TEXT_T012, TEXT_T113, TEXT_T211, TEXT_T575 } = Translate

export const SchedulesAdvancedSearch: FC<SchedulesAdvancedSearchFormProps> = (
  props,
) => {
  const {
    allCountries,
    arrivalHourItems,
    arrivalMinuteItems,
    cities,
    departments,
    dispatchType,
    dispatchTypeDetail,
    formAdvanced,
    isDefaultValueArrivalDate,
    isDefaultValueDepartureDate,
    isDisabledDispatchTypeDetail,
    isLoading,
    isOpen,
    isOverseasResident,
    isPathnameSchedules,
    onBlurAdvanced,
    onCancelSearchAdvance,
    onChangeArrivalDate,
    onChangeCountryCode,
    onChangeDepartureDate,
    onChangeDispatchType,
    onChangeWorkPlaceLocation,
    onClickResetSearchAdvance,
    onFinish,
    onKeyDownAdvanced,
    onValuesChangeFormSearchAdvanced,
    personPrefectureResidence,
    purposeTravel,
    transportation,
  } = props

  return (
    <Modal isForm isOpen={isOpen} isShowFooter={false}>
      <Form
        form={formAdvanced}
        heading={TEXT_T575}
        initialValues={{
          workPlaceLocation: 1,
        }}
        isLoading={isLoading}
        onValuesChange={onValuesChangeFormSearchAdvanced}
        onFinish={onFinish}
      >
        <SchedulesAdvancedSearchCollapse
          allCountries={allCountries}
          arrivalHourItems={arrivalHourItems}
          arrivalMinuteItems={arrivalMinuteItems}
          cities={cities}
          departments={departments}
          dispatchType={dispatchType}
          dispatchTypeDetail={dispatchTypeDetail}
          isDisabledDispatchTypeDetail={isDisabledDispatchTypeDetail}
          isOverseasResident={isOverseasResident}
          isPathnameSchedules={isPathnameSchedules}
          isDefaultValueArrivalDate={isDefaultValueArrivalDate}
          isDefaultValueDepartureDate={isDefaultValueDepartureDate}
          onBlurAdvanced={onBlurAdvanced}
          onChangeArrivalDate={onChangeArrivalDate}
          onChangeCountryCode={onChangeCountryCode}
          onChangeDepartureDate={onChangeDepartureDate}
          onChangeDispatchType={onChangeDispatchType}
          onChangeWorkPlaceLocation={onChangeWorkPlaceLocation}
          onKeyDownAdvanced={onKeyDownAdvanced}
          personPrefectureResidence={personPrefectureResidence}
          purposeTravel={purposeTravel}
          transportation={transportation}
        />

        <ButtonGroup
          buttonItems={[
            {
              onClick: onCancelSearchAdvance,
              order: 2,
              text: TEXT_T012,
              type: "ghost",
            },
            {
              onClick: onClickResetSearchAdvance,
              order: 3,
              text: TEXT_T113,
              type: "ghost",
            },
            {
              htmlType: "submit",
              order: 1,
              text: TEXT_T211,
              type: "primary",
            },
          ]}
          isMargin={false}
        />
      </Form>
    </Modal>
  )
}
