import { FC } from "react"
import { FormItem } from ".."
import { Options } from "./constant"
import { ItemSelect } from "../Select"
import { Styled } from "./styled"
import { ItemTimePicker2Props } from "./models"

const { SELECT_HOUR_ITEMS, SELECT_MINUTE_ITEMS } = Options
const { StyledPickerCustom } = Styled

export const ItemTimePicker2: FC<ItemTimePicker2Props> = (props) => {
  const {
    isBgBlue,
    isDefaultValue,
    isHiddenLabel,
    label,
    name,
    onChangeHour,
    onChangeMinute,
    required,
    rules,
    selectHourItems = SELECT_HOUR_ITEMS,
    selectHourName,
    selectMinuteItems = SELECT_MINUTE_ITEMS,
    selectMinutesName,
    tooltip,
  } = props

  return (
    <FormItem
      isBgBlue={isBgBlue}
      isHiddenLabel={isHiddenLabel}
      label={label}
      name={name}
      required={required}
      rules={rules}
      tooltip={tooltip}
    >
      <StyledPickerCustom className="ant-time-picker">
        <ItemSelect
          isDefaultValue={isDefaultValue}
          isHiddenLabel
          className="ant-time-picker-hour"
          name={selectHourName}
          onChange={onChangeHour}
          rules={rules}
          selectItem={selectHourItems}
        />
        <ItemSelect
          isDefaultValue={isDefaultValue}
          isHiddenLabel
          className="ant-time-picker-minutes"
          name={selectMinutesName}
          onChange={onChangeMinute}
          rules={rules}
          selectItem={selectMinuteItems}
        />
      </StyledPickerCustom>
    </FormItem>
  )
}
