/* eslint-disable max-lines */
import { Translate } from "../../../../../../../constant/Translate"
import { getFullName } from "../../../../../../../utils/getFullName"
import { Center } from "../../../../../../../_webui/layout/components/Center"
import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../_webui/layout/components/Table/models"
import { Tag } from "../../../../../../../_webui/layout/components/Tag"
import { BreakText } from "../../../../../../../_webui/layout/components/Typography/BreakText"
import { Expandable } from "../../../../../../../_webui/layout/components/Typography/Expandable"
import { SafetiesDetailDataSourceType } from "../../../../models/detail"
import { getDateTimeZoneByUserInformation } from "../../../../../../../utils/getDate"
import { getValueByLanguage } from "../../../../../../../utils/getValueByLanguage"
import { getScheduleDeleted } from "../../../../../../../utils/getScheduleDeleted"
const {
  TEXT_T003,
  TEXT_T006,
  TEXT_T025,
  TEXT_T072,
  TEXT_T047,
  TEXT_T340,
  TEXT_T375,
  TEXT_T376,
  TEXT_T377,
  TEXT_T378,
  TEXT_T379,
  TEXT_T380,
  TEXT_T381,
} = Translate

export const useTableConfig =
  (): UseTableConfigType<SafetiesDetailDataSourceType> => {
    const columns: ColumnType<SafetiesDetailDataSourceType>[] = [
      {
        dataIndex: "safetyStatusName",
        render: (_, data) => (
          <Center>
            <Tag isFullWidth text={data.text} type={data.type} />
          </Center>
        ),
        sorter: (a, b) => a.safetyStatusName.localeCompare(b.safetyStatusName),
        title: TEXT_T375,
        width: 170,
      },
      {
        dataIndex: "proxyResponser",
        render: (_, data) =>
          getValueByLanguage(
            getFullName(data.proxyKanjiSurname, data.proxyKanjiName, true),
            getFullName(data.proxyRomajiSurname, data.proxyRomajiName),
          ),
        sorter: (a, b) =>
          getValueByLanguage(
            getFullName(a.proxyKanjiSurname, a.proxyKanjiName, true),
            getFullName(a.proxyRomajiSurname, a.proxyRomajiName),
          )
            .toString()
            .localeCompare(
              getValueByLanguage(
                getFullName(b.proxyKanjiSurname, b.proxyKanjiName, true),
                getFullName(b.proxyRomajiSurname, b.proxyRomajiName),
              ).toString(),
            ),
        title: TEXT_T340,
        width: 220,
      },
      {
        dataIndex: "safetyResponseDate",
        render: (_, data) =>
          getDateTimeZoneByUserInformation(data.safetyResponseDate),
        sorter: (a, b) =>
          a.safetyResponseDate?.localeCompare(b.safetyResponseDate),
        title: TEXT_T376,
        width: 250,
      },
      {
        dataIndex: "fullName",
        render: (_, data) => (
          <BreakText
            above={getFullName(data.kanjiSurname, data.kanjiName, true)}
            below={getFullName(data.romajiSurname, data.romajiName)}
          />
        ),
        sorter: (a, b) =>
          getFullName(a.romajiSurname, a.romajiName).localeCompare(
            getFullName(b.romajiSurname, b.romajiName),
          ),
        title: <BreakText above={TEXT_T003} below={TEXT_T006} />,
        width: 240,
      },
      {
        dataIndex: "locationPhoneNumber1",
        render: (_, data) =>
          getScheduleDeleted(data.isScheduleDeleted, data.locationPhoneNumber1),
        sorter: (a, b) =>
          a.locationPhoneNumber1?.localeCompare(b.locationPhoneNumber1),
        title: TEXT_T047,
        width: 300,
      },
      {
        dataIndex: "dispatchTypeDetailName",
        sorter: (a, b) =>
          a.dispatchTypeDetailName?.localeCompare(b.dispatchTypeDetailName),
        title: TEXT_T025,
        width: 260,
      },
      {
        dataIndex: "travelerLocation",
        sorter: (a, b) => a.travelerLocation?.localeCompare(b.travelerLocation),
        title: TEXT_T377,
        width: 200,
      },
      {
        dataIndex: "familySafetyStatusName",
        sorter: (a, b) =>
          a?.familySafetyStatusName?.localeCompare(b?.familySafetyStatusName),
        title: TEXT_T378,
        width: 200,
      },
      {
        dataIndex: "othersRemarks1",
        sorter: (a, b) => a.othersRemarks1?.localeCompare(b?.othersRemarks1),
        title: TEXT_T379,
        width: 400,
      },
      {
        dataIndex: "message",
        render: (_, data) => (
          <Expandable
            title={TEXT_T381}
            content={data.message}
            cancelText={TEXT_T072}
          />
        ),
        sorter: (a, b) => a.message?.localeCompare(b.message),
        title: TEXT_T380,
        width: 400,
      },
    ]
    return {
      columns,
    }
  }
