/* eslint-disable max-lines */
import { FC } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { Collapse } from "../../../../../../_webui/layout/components/Collapse"
import { CollapseAssignee } from "./Assignee"
import { CollapsePerson } from "./Person"
import { CollapseInsurance } from "./Insurance"
import { CollapseUrgent } from "./Urgent"
import { CollapseOthers } from "./Others"
import { PersonalInfo } from "./PersonalInfo"
import { CollapsePosting } from "./Posting"
import { TrainingInfo } from "./TrainingInfo"
import { TravelersSearchCollapseProps } from "./models"

const {
  TEXT_T121,
  TEXT_T520,
  TEXT_T522,
  TEXT_T523,
  TEXT_T524,
  TEXT_T525,
  TEXT_T527,
  TEXT_T528,
} = Translate

export const TravelersSearchCollapse: FC<TravelersSearchCollapseProps> = (
  props,
) => {
  const {
    allCountries,
    cities,
    countryCode,
    departments,
    dispatchType,
    familyRelationship,
    isOtherCity,
    onBlur,
    onCountryChange,
    onKeyDown,
    onWorkPlaceCityChange,
    onWorkPlaceLocationChange,
    prefectureList,
    workPlaceLocation,
  } = props

  return (
    <Collapse
      defaultActiveKey={["1", "2", "3", "4", "5", "6", "7", "8"]}
      panelItems={[
        {
          content: (
            <PersonalInfo
              departments={departments}
              dispatchType={dispatchType}
              familyRelationship={familyRelationship}
            />
          ),
          header: TEXT_T520,
          key: 1,
        },
        {
          content: <CollapsePerson prefectureList={prefectureList} />,
          header: TEXT_T522,
          key: 2,
        },
        {
          content: <CollapseUrgent />,
          header: TEXT_T523,
          key: 3,
        },
        {
          content: (
            <CollapsePosting
              allCountries={allCountries}
              cities={cities}
              countryCode={countryCode}
              isOtherCity={isOtherCity}
              onCountryChange={onCountryChange}
              onWorkPlaceCityChange={onWorkPlaceCityChange}
              onWorkPlaceLocationChange={onWorkPlaceLocationChange}
              workPlaceLocation={workPlaceLocation}
            />
          ),
          header: TEXT_T524,
          key: 4,
        },
        {
          content: <CollapseAssignee />,
          header: TEXT_T525,
          key: 5,
        },
        {
          content: <CollapseInsurance />,
          header: TEXT_T121,
          key: 6,
        },
        {
          content: <TrainingInfo onBlur={onBlur} onKeyDown={onKeyDown} />,
          header: TEXT_T527,
          key: 7,
        },
        {
          content: <CollapseOthers />,
          header: TEXT_T528,
          key: 8,
        },
      ]}
    />
  )
}
