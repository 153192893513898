/* eslint-disable max-statements */
/* eslint-disable max-lines */
/* eslint-disable complexity */
import { GeneralCode } from "../../../../../../../../constant/GeneralCode"
import { RequiredFields } from "../../../../../constants/edit/requiredFields"
import {
  HandleFormValuesChangeDataType,
  TravelersEditRequiredFieldsValueType,
} from "../../../../../models/edit"

const { OTHER } = GeneralCode
const { TRAVELERS_EDIT_REQUIRED_FIELDS } = RequiredFields

const getRequiredFields = (
  isValue: boolean,
  value1: string[],
  value2?: string[],
) =>
  isValue
    ? [...TRAVELERS_EDIT_REQUIRED_FIELDS, ...value1]
    : [...TRAVELERS_EDIT_REQUIRED_FIELDS, ...(value2 || [])]

export const handleFormValuesChange = (
  data: HandleFormValuesChangeDataType,
) => {
  const {
    form,
    isAccompanyingFamily: isRequiredTrainingType,
    isInsuranceRequired,
    listKey1IsAccompanyingFamily,
    othersStaffNumber,
  } = data

  const values: TravelersEditRequiredFieldsValueType = form.getFieldsValue([
    "differentInforCheck",
    "dispatchTypeDetailId",
    "trainingType",
    "workPlaceCity",
    "workPlaceLocation",
  ])

  const {
    differentInforCheck,
    dispatchTypeDetailId,
    trainingType,
    workPlaceCity,
    workPlaceLocation,
  } = values

  const isAccompanyingFamily =
    listKey1IsAccompanyingFamily?.includes(dispatchTypeDetailId)

  const personCompanyPositionRequiredField = getRequiredFields(
    !othersStaffNumber && !isAccompanyingFamily,
    ["personCompanyPosition"],
  )

  const personDispatcherNameRequiredField = getRequiredFields(
    isAccompanyingFamily,
    ["personDispatcherName"],
  )

  const romajiNameRequiredFields = getRequiredFields(differentInforCheck, [
    "romajiPassportName",
    "romajiPassportSurname",
  ])

  const accompanyingFamilyRequiredFields = getRequiredFields(
    !isAccompanyingFamily,
    ["bumonCd"],
  )

  const trainingDateRequiredFields = getRequiredFields(!!trainingType, [
    "trainingDate",
  ])

  const trainingTypeRequiredFields = getRequiredFields(isRequiredTrainingType, [
    "trainingType",
  ])

  const workPlaceLocationRequiredFields = getRequiredFields(
    !isAccompanyingFamily && workPlaceLocation === 1,
    ["workPlaceCountry", "workPlaceCity"],
  )

  const workPlaceRequiredFields = getRequiredFields(
    !isAccompanyingFamily && workPlaceLocation === 1 && workPlaceCity === OTHER,
    ["workPlace"],
  )

  const insuranceRequiredField = getRequiredFields(isInsuranceRequired, [
    "insuranceCompanyPhoneNumber",
    "insuranceTicketNumber",
    "insuranceSonsignor",
  ])

  const requiredFields = [
    ...accompanyingFamilyRequiredFields,
    ...insuranceRequiredField,
    ...personCompanyPositionRequiredField,
    ...personDispatcherNameRequiredField,
    ...romajiNameRequiredFields,
    ...trainingDateRequiredFields,
    ...trainingTypeRequiredFields,
    ...workPlaceLocationRequiredFields,
    ...workPlaceRequiredFields,
  ]

  !othersStaffNumber && !isAccompanyingFamily
    ? form.setFields([
        {
          errors: [],
          name: "personDispatcherName",
        },
      ])
    : form.setFields([
        {
          errors: [],
          name: "personCompanyPosition",
        },
      ])

  !differentInforCheck &&
    form.setFields([
      {
        errors: [],
        name: "romajiPassportSurname",
      },
      {
        errors: [],
        name: "romajiPassportName",
      },
    ])

  !isInsuranceRequired &&
    form.setFields([
      {
        errors: [],
        name: "insuranceCompanyPhoneNumber",
      },
      {
        errors: [],
        name: "insuranceTicketNumber",
      },
      {
        errors: [],
        name: "insuranceSonsignor",
      },
    ])

  isAccompanyingFamily &&
    form.setFields([
      {
        errors: [],
        name: "bumonCd",
      },
      {
        errors: [],
        name: "workPlaceLocation",
      },
      {
        errors: [],
        name: "workPlaceCountry",
      },
      {
        errors: [],
        name: "workPlaceCity",
      },
      {
        errors: [],
        name: "workPlace",
      },
    ])

  const formValues = form.getFieldsValue([...new Set(requiredFields)])

  return {
    formValues,
  }
}
