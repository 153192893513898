import { OptionItemType } from "../../../../../../_webui/layout/components/Select/models"
import { SafetiesListSearchCondition } from "../../../models/list"

const DEFAULT_SEARCH: SafetiesListSearchCondition = {
  categoryId: "",
  implementationDate: "",
  isMenuSubAdmin: false,
  safetyTitle: "",
  statusId: "0",
}

const STATUS_LIST_HISTORY: OptionItemType[] = [
  {
    text: "Open",
    value: "0",
  },
  {
    text: "Close",
    value: "1",
  },
]

const STATUS_LIST: OptionItemType[] = [
  {
    text: "Open",
    value: "0",
  },
]

export const Variables = {
  DEFAULT_SEARCH,
  STATUS_LIST,
  STATUS_LIST_HISTORY,
}
