/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable max-statements */
import { useEffect } from "react"
import { LoginActions } from "../../../../../constant/Login"
import { Routers } from "../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../infrastructure/axiosError/models"
import keycloak from "../../../../../utils/keycloak"
import { pushStateError } from "../../../../../utils/pushStateError"
import { privateRouteApi } from "../../apis"
import { useVariables } from "../useVariables"
import { setUserInformation } from "./setUserInformation"
import { AgreementsResType, MyLoginResType } from "../../models"
import { clearLocalStorage } from "../../../../../utils/clearLocalStorage"
import { Translate } from "../../../../../constant/Translate"
import { Environment } from "../../../../../constant/Environment"

const { KEY, LOGIN, RULE, TIMEOUT, UPDATE } = LoginActions
const { SCR318_MAIL, SCR418, SCR911, SCR915, SCR916, SCR917, SCR920, SCR973 } =
  Routers
const { MESSAGE_E0128 } = Translate

export const usePrivateRoute = () => {
  const { isAuthenticated, itemKey, push, setItemKey, userInformation } =
    useVariables()
  const userInfo = async () => {
    const myLogin: Promise<MyLoginResType> = privateRouteApi.myLogin()
    const agreements: Promise<AgreementsResType> = privateRouteApi.agreements()
    await Promise.all([myLogin, agreements])
      .then((res) => {
        setUserInformation(res[0])
        if (!res[1].gdprAgreement) {
          localStorage.setItem(KEY, RULE)
          push(SCR915)
        } else if (res[0].firstLogin && !res[0].othersStaffNumber) {
          localStorage.setItem(KEY, UPDATE)
          push(SCR917)
        } else {
          localStorage.removeItem(KEY)
          setItemKey(null)
        }
      })
      .catch((error: AxiosErrorType) => {
        setItemKey(null)
        const stateError = pushStateError(error)
        push({ pathname: SCR973, state: stateError })
      })
  }

  const myLogin = async () => {
    clearLocalStorage()
    await privateRouteApi
      .getUserAuthorities()
      .then((res) => {
        if (res) {
          keycloak.updateToken(-1).finally(userInfo)
        }
      })
      .catch((error: AxiosErrorType) => {
        setItemKey(null)
        const stateError = pushStateError(error)
        push({ pathname: SCR973, state: stateError })
      })
  }

  useEffect(() => {
    if (isAuthenticated) {
      if (localStorage.getItem(KEY) === TIMEOUT) {
        localStorage.removeItem(KEY)
        clearLocalStorage()
        keycloak.logout({ redirectUri: `${Environment.URL}/home` })
      } else if (itemKey === LOGIN && location.pathname !== SCR973) {
        myLogin()
      } else if (
        ((itemKey === RULE && window.location.pathname !== SCR915) ||
          (itemKey === UPDATE && window.location.pathname !== SCR917)) &&
        location.pathname !== SCR973
      ) {
        userInfo()
      } else {
        setItemKey(null)
      }
    }
    if (!history.state) {
      const listPath = [
        SCR318_MAIL,
        SCR418,
        SCR911,
        SCR916,
        SCR917,
        SCR920,
        "/",
      ]
      if (!listPath.includes(window.location.pathname) && userInformation) {
        alert(MESSAGE_E0128)
        window.location.href = SCR920
      }
    }
  }, [])

  return {
    isAuthenticated,
    itemKey,
  }
}
