import "./style/index.scss"
import { FC } from "react"
import { SpinProps } from "./models"
import { getStyle } from "../../../../utils/getStyle"

export const Spin: FC<SpinProps> = (props) => {
  const { isFixed, isLoading, isOpacity = true } = props
  const styleOpacity = getStyle(isOpacity, " ant-spin-mask-opacity", "")
  const styleFixed = getStyle(isFixed, " ant-spin-fixed", "")
  const styleSpin = `ant-spin${styleFixed}`
  const styleSpinMask = `ant-spin-mask${styleOpacity}`

  return isLoading ? (
    <div className={styleSpin}>
      <div className={styleSpinMask}></div>
      <span className="ant-spin-dot">
        <i className="ant-spin-dot-item"></i>
        <i className="ant-spin-dot-item"></i>
        <i className="ant-spin-dot-item"></i>
        <i className="ant-spin-dot-item"></i>
      </span>
    </div>
  ) : null
}
