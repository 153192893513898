import { FC, Fragment } from "react"
import { Translate } from "../../../../../../../constant/Translate"
import { validatorEmailAddress } from "../../../../../../../utils/validator/emailAddress"
import { validatorRequiredField } from "../../../../../../../utils/validator/requiredField"
import { ItemCheckbox } from "../../../../../../../_webui/layout/components/Form/Item/Checkbox"
import { ItemInput } from "../../../../../../../_webui/layout/components/Form/Item/Input"
import { ItemSelect } from "../../../../../../../_webui/layout/components/Form/Item/Select"
import { PersonProps } from "./models"
import { requiredField } from "../../../../../../../utils/requiredField"

const {
  HELP_H0007,
  HELP_H0008,
  HELP_H0009,
  TEXT_T017,
  TEXT_T046,
  TEXT_T047,
  TEXT_T048,
  TEXT_T049,
  TEXT_T089,
  TEXT_T218,
} = Translate

export const Person: FC<PersonProps> = (props) => {
  const {
    isAccompanyingFamily,
    isCopyEmailAddress,
    onCopyEmailAddress,
    othersStaffNumber,
    prefectures,
  } = props
  const isRequiredCompanyPosition = !othersStaffNumber && !isAccompanyingFamily
  const isRequiredDispatcherName = othersStaffNumber
    ? false
    : isAccompanyingFamily
  const personCompanyPositionRules = requiredField(isRequiredCompanyPosition)
  const personDispatcherNameRules = requiredField(isRequiredDispatcherName)

  return (
    <Fragment>
      <ItemSelect
        label={TEXT_T218}
        name="personPrefectureResidence"
        required
        rules={[validatorRequiredField]}
        selectItem={prefectures}
        tooltip={HELP_H0007}
      />
      <ItemInput
        label={TEXT_T046}
        maxLength={10}
        name="personCountryPhoneCode"
      />
      <ItemInput
        label={TEXT_T047}
        maxLength={20}
        name="personPhoneNumber"
        tooltip={HELP_H0008}
      />
      <ItemCheckbox
        isHiddenLabel
        label={TEXT_T089}
        name="samePersonInformationEmailAddress"
        onChange={onCopyEmailAddress}
        text={TEXT_T089}
      />
      <ItemInput
        isDisabled={isCopyEmailAddress}
        label={TEXT_T017}
        maxLength={255}
        name="personEmailAddress"
        required
        rules={[validatorRequiredField, validatorEmailAddress]}
      />
      <ItemInput
        label={TEXT_T048}
        maxLength={200}
        name="personCompanyPosition"
        required={isRequiredCompanyPosition}
        rules={personCompanyPositionRules}
      />
      <ItemInput
        label={TEXT_T049}
        maxLength={100}
        name="personDispatcherName"
        required={isRequiredDispatcherName}
        rules={personDispatcherNameRules}
        tooltip={HELP_H0009}
      />
    </Fragment>
  )
}
