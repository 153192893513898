/* eslint-disable complexity */
/* eslint-disable max-statements */
import { AxiosError } from "axios"
import {
  ERROR_MESSAGE,
  ERROR_TYPE,
  HTTP_STATUS_CODE,
} from "../../constant/ServerResponse"
import { Translate } from "../../constant/Translate"

const { TEXT_T592, TEXT_T667, MESSAGE_E9999 } = Translate

export const axiosError = async (error: AxiosError) => {
  if (error.response) {
    const { status } = error.response
    const { BAD_REQUEST, FORBIDDEN, INTERNAL_SERVER_ERROR, UNAUTHORIZED } =
      HTTP_STATUS_CODE
    const {
      CLIENT_ERROR,
      ERROR,
      INVALID_SESSION,
      NETWORK_ERROR,
      SERVER_ERROR,
    } = ERROR_MESSAGE
    const { SESSION_ERROR, SESSION_FORBIDDEN } = ERROR_TYPE

    if (!error || status === 0) {
      return Promise.reject({
        message: MESSAGE_E9999,
        title: TEXT_T592,
        type: NETWORK_ERROR,
      })
    }

    if (status >= INTERNAL_SERVER_ERROR) {
      return Promise.reject({
        debug: error,
        message: MESSAGE_E9999,
        title: TEXT_T592,
        type: SERVER_ERROR,
      })
    }

    if (status === BAD_REQUEST) {
      return Promise.reject({
        debug: error,
        message: CLIENT_ERROR,
        type: SERVER_ERROR,
      })
    }

    if (status === FORBIDDEN) {
      return Promise.reject({
        debug: error,
        type: SESSION_FORBIDDEN,
      })
    }

    if (status === UNAUTHORIZED) {
      if (error?.response) {
        error.response.data = { message: "E0102", title: TEXT_T667 }
      }
      return Promise.reject({
        debug: error,
        message: INVALID_SESSION,
        title: TEXT_T667,
        type: SESSION_ERROR,
      })
    }

    if (status >= BAD_REQUEST) {
      return Promise.reject({
        debug: error,
        message: ERROR,
        type: ERROR,
      })
    }

    return Promise.reject({
      debug: error,
      message: ERROR,
      type: ERROR,
    })
  }
}
