/* eslint-disable no-irregular-whitespace */
const REGEX_ALPHANUMERIC = /^[a-zA-Z0-9_]*$/
const REGEX_EMAIL = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/
const REGEX_FULL_SIZE =
  /^[ぁ-んァ-ン一-龥Ａ-ｚ０-９　，．：；！？＂＇｀＾～￣＿＆＠＃％＋－＊＝＜＞（）［］｛｝｟｠｜￤／＼￢＄￡￠￦￥]+$/
const REGEX_HALF_SIZE = /^[a-zA-Z0-9!@#\\$%\\^\\&*\\)\\(+=. _-]+$/
const REGEX_IS_DESKTOP =
  !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  )
const REGEX_IS_IOS_DEVICE = /iPhone|iPad|iPod/i.test(navigator.userAgent)
const REGEX_NUMBER = /^[0-9]*$/
const REGEX_PHONE_NUMBER = /^\d{10,11}$/
const REGEX_ROMAJI_NAME = /^[a-zA-Z・ ]+$/
const REGEX_PASSWORD =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[*/[\].{}()?"!@#$%^&,><':;|_~`=+-])[A-Za-z\d*/[\].{}()?"!@#$%^&,><':;|_~`=+-]{8,99}$/

export const Regex = {
  REGEX_ALPHANUMERIC,
  REGEX_EMAIL,
  REGEX_FULL_SIZE,
  REGEX_HALF_SIZE,
  REGEX_IS_DESKTOP,
  REGEX_IS_IOS_DEVICE,
  REGEX_NUMBER,
  REGEX_PASSWORD,
  REGEX_PHONE_NUMBER,
  REGEX_ROMAJI_NAME,
}
