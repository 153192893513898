export const previewFile = (fileName: string, filePath: string) => {
  if (/^.*\.(xls|xlsx)$/.test(fileName)) {
    window.open(
      `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
        filePath,
      )}&wdOrigin=BROWSELINK`,
      `_blank`,
    )
  } else if (/^.*\.(pdf|txt)$/.test(fileName)) {
    window.open(filePath, `_blank`)
  }
}
