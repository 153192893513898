import { useModal } from "../../../../../../../utils/hook/useModal"

export const useVariablesModal = () => {
  const { isOpen, onCancel, onOpen } = useModal()
  const {
    isOpen: isOpenUpdate,
    onCancel: onCancelUpdate,
    onOpen: onOpenUpdate,
  } = useModal()

  return {
    isOpen,
    isOpenUpdate,
    onCancel,
    onCancelUpdate,
    onOpen,
    onOpenUpdate,
  }
}
