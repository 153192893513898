import { useHistory } from "react-router-dom"
import { Routers } from "../../../../../../constant/Routers"
import { Translate } from "../../../../../../constant/Translate"
import { AxiosErrorType } from "../../../../../../infrastructure/axiosError/models"
import { pushStateError } from "../../../../../../utils/pushStateError"
import { setLanguage } from "../../../../../i18n/OjicaI18n"
import { MenusType } from "../../../Menu/models"
import { menusApi } from "../../../Menu/apis"
import keycloak from "../../../../../../utils/keycloak"

const { SCR973 } = Routers
const { MESSAGE_E9996 } = Translate

export const useChangeLanguage = () => {
  const { push } = useHistory()
  const menus = (lang: string) => {
    if (keycloak.token) {
      menusApi
        .menus(lang)
        .then((res: MenusType[]) => {
          localStorage.setItem("menus", JSON.stringify(res))
          setLanguage(lang)
        })
        .catch((error: AxiosErrorType) => {
          if (error?.debug?.response?.data?.message === "E9999") {
            const state = pushStateError(error)
            push({
              pathname: SCR973,
              state: {
                ...state,
                message: MESSAGE_E9996,
              },
            })
          } else {
            const state = pushStateError(error)
            push({ pathname: SCR973, state })
          }
        })
    } else {
      setLanguage(lang)
    }
  }

  const setLanguageToEnglish = () => {
    menus("en")
  }

  const setLanguageToJapanese = () => {
    menus("ja")
  }

  return {
    setLanguageToEnglish,
    setLanguageToJapanese,
  }
}
