import { Checkbox as CheckboxAnt } from "antd"
import { FC } from "react"
import { CheckboxProps } from "./models"

export const Checkbox: FC<CheckboxProps> = (props) => {
  const {
    checked,
    isDefaultChecked,
    isDisabled,
    isHiddenLabel,
    onChange,
    onClick,
    text,
    value,
  } = props

  return (
    <CheckboxAnt
      checked={checked}
      defaultChecked={isDefaultChecked}
      disabled={isDisabled}
      onChange={onChange}
      onClick={onClick}
      value={value}
    >
      {isHiddenLabel ? <div className="hidden-label">{text}</div> : text}
    </CheckboxAnt>
  )
}
