/* eslint-disable max-lines */
import { useEffect } from "react"
import { Routers } from "../../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../../infrastructure/axiosError/models"
import { TimezoneResType } from "../../../../../../../../infrastructure/handlers/timezone/models"
import { backToLogin } from "../../../../../../../../utils/backToLogin"
import { getTextByKey } from "../../../../../../../../utils/getTextByKey"
import { getValueCombobox } from "../../../../../../../../utils/getValueCombobox"
import { pushStateError } from "../../../../../../../../utils/pushStateError"
import { setFieldsErrors } from "../../../../../../../../utils/setFieldsErrors"
import { travelerApi } from "../../../../../../travelers/apis"
import { UseFormTimezoneSettingType } from "../../../../../models/timezone/setting"
import { useVariables } from "../useVariables/index"
import { privateRouteApi } from "../../../../../../../routing/privateRoute/apis"
import { setUserInformation } from "../../../../../../../routing/privateRoute/modules/usePrivateRoute/setUserInformation"
import { MyLoginResType } from "../../../../../../../routing/privateRoute/models"

const { SCR920, SCR931, SCR973 } = Routers

export const useTimezoneSetting =
  (): UseFormTimezoneSettingType<TimezoneResType> => {
    const {
      callApi,
      form,
      isCommonRefer,
      isHiddenReset,
      isHiddenSubmit,
      isLoading,
      isLoadingLayout,
      push,
      setIsLoading,
      timeFormatItems,
      timeZoneItems,
    } = useVariables()

    // 「SCR920_ホ－ム画面」に遷移する。
    const goToHome = () => {
      push(SCR920)
    }

    const onFinish = (values: TimezoneResType) => {
      const { timeFormat, timeZone } = values

      const timeFormatName = getTextByKey(timeFormat, timeFormatItems)
      const timeZoneName = getTextByKey(timeZone, timeZoneItems)

      const data: TimezoneResType = {
        isSummerTime: timeZone.split("_")?.[1] === "0",
        key5: timeZone.split("_")?.[2],
        timeFormat: getValueCombobox(timeFormat, timeFormatName),
        timeZone: getValueCombobox(timeZone, timeZoneName),
      }

      setIsLoading(true)
      // 選択されたタイムゾーン情報、タイムフォーマット情報を保存する。
      travelerApi
        .updateTimezone(data)
        .then(() => {
          privateRouteApi
            .myLogin()
            .then((res: MyLoginResType) => {
              setUserInformation(res)
              push(SCR931)
            })
            .catch((error: AxiosErrorType) => {
              const stateError = pushStateError(error)
              push({ pathname: SCR973, state: stateError })
            })
        })
        .catch((error: AxiosErrorType) => {
          if (error?.debug?.response?.data?.entityName) {
            setFieldsErrors(error, form)
            setIsLoading(false)
          } else {
            const stateError = pushStateError(error)
            push({ pathname: SCR973, state: stateError })
          }
        })
    }

    useEffect(() => {
      // ユーザーの画面アクセス許可チェックを処理する。
      if (!isCommonRefer) {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const { pathname, state } = backToLogin()
        push({ pathname, state })
      } else callApi()
    }, [])

    return {
      form,
      goToHome,
      isHiddenReset,
      isHiddenSubmit,
      isLoading,
      isLoadingLayout,
      onFinish,
      timeFormatItems,
      timeZoneItems,
    }
  }
