/* eslint-disable max-lines */
import { useEffect } from "react"
import { Routers } from "../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { attentionApi } from "../../../../apis"
import {
  AttentionsCloneDataType,
  AttentionsCloneResType,
  UseAttentionsCloneType,
} from "../../../../models/clone"
import { useVariables } from "../useVariables"
import { convertDataSource } from "./convertDataSource"

const { SCR312, SCR313, SCR961, SCR973 } = Routers

export const useAttentionsClone =
  (): UseAttentionsCloneType<AttentionsCloneDataType> => {
    const {
      attentionInfoList,
      columns,
      isActiveBtnClone,
      isAttentionsSendAll,
      isHiddenCopyBtn,
      isLoadingLayout,
      onChange,
      onChangeTable,
      perItem,
      push,
      rowSelection,
      selectedRowKeys,
      setAttentionInfoList,
      setIsLoadingLayout,
      state,
    } = useVariables()

    // 「SCR312_注意喚起の内容確認画面」に遷移する。
    const goBack = () => {
      push({
        pathname: SCR312,
        state: {
          ...state,
          sourceURL: SCR961,
        },
      })
    }

    // 「SCR312_注意喚起の内容確認画面」に遷移する。
    const onCopy = () => {
      const dataSelected = attentionInfoList.find(
        (item) => item.attentionId === selectedRowKeys[0],
      )
      if (dataSelected) {
        push({
          pathname: SCR313,
          state: {
            ...state,
            sourceURL: SCR961,
            transmissionInfo: dataSelected,
            travelerInfoList: state?.travelerInfoList,
          },
        })
      }
    }

    useEffect(() => {
      // ユーザの画面アクセス許可チェックを処理する。
      if (!isAttentionsSendAll) {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const { pathname, state } = backToLogin()
        push({ pathname, state })
      } else {
        // APIを呼び出し、ログインしているユーザに従って注意喚起一覧を取得する。
        attentionApi
          .getAttentions({ isMenuSubAdmin: true })
          .then((attentionInfoJson: AttentionsCloneResType[]) => {
            setAttentionInfoList(convertDataSource(attentionInfoJson))
            setIsLoadingLayout(false)
          })
          .catch((error: AxiosErrorType) => {
            // 「SCR973_エラー情報の表示画面」に遷移する。
            const stateError = pushStateError(error)
            push({ pathname: SCR973, state: stateError })
          })
      }
    }, [])

    return {
      attentionInfoList,
      columns,
      goBack,
      isActiveBtnClone,
      isHiddenCopyBtn,
      isLoadingLayout,
      onChange,
      onChangeTable,
      onCopy,
      perItem,
      rowSelection,
    }
  }
