import styled from "styled-components"

const StyledFieldset = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
`

const StyledLegend = styled.legend`
  height: 0;
  overflow: hidden;
  text-indent: 100%;
  width: 0;
`

export const Styled = {
  StyledFieldset,
  StyledLegend,
}
