import { Routers } from "../../constant/Routers"
import { Translate } from "../../constant/Translate"
import { AxiosErrorType } from "../../infrastructure/axiosError/models"
import { pushStateError } from "../pushStateError"

const { MESSAGE_E0051, TEXT_T558 } = Translate
const { SCR973 } = Routers

export const backToLogin = () => {
  const errors: AxiosErrorType = {
    debug: {
      response: {
        data: {
          entityName: "",
          message: "E0052",
          params: "",
          status: 200,
          title: MESSAGE_E0051,
        },
      },
    },
    message: "E0052",
    title: MESSAGE_E0051,
  }
  const stateError = pushStateError(errors, TEXT_T558, "/login")

  return {
    pathname: SCR973,
    state: stateError,
  }
}
