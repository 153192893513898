/* eslint-disable max-lines */
import { useEffect } from "react"
import { TableRowSelection } from "../../../../../../../_webui/layout/components/Table/models"
import { CheckboxItem } from "../../../../../../../_webui/layout/components/RowSelection/CheckboxItem"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { CheckboxAll } from "../../../../../../../_webui/layout/components/RowSelection/CheckboxAll"
import { UploadResultsType } from "../../../../model/uploadResults"
import { TableCurrentDataSource } from "antd/lib/table/interface"
import { useVariablesRowSelection } from "../useVariablesRowSelection"

export const useRowSelection = (batchLogInfoList: UploadResultsType[]) => {
  const {
    dataCurrent,
    dataSource,
    dataSourceSelected,
    idBatchLogSelected,
    isCheckAll,
    onChangePage,
    perItem,
    perItemEnd,
    perItemStart,
    selectedRowKeys,
    setDataCurrent,
    setDataSource,
    setDataSourceSelected,
    setIdBatchLogSelected,
    setIsCheckAll,
    setSelectedRowKeys,
  } = useVariablesRowSelection(batchLogInfoList.length)

  const onSelectChange = () => {
    const selectedRows = dataCurrent.filter((item) =>
      selectedRowKeys.includes(item.id),
    )
    const idsSelected = selectedRows.length
      ? selectedRows?.map((item) => item.id)
      : []

    setIdBatchLogSelected(idsSelected)
    setDataSourceSelected(selectedRows)
    if (selectedRowKeys.length === dataCurrent.length) {
      setIsCheckAll(true)
    } else {
      setIsCheckAll(false)
    }
  }

  const onChangeCheckAll = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    setIsCheckAll(checked)
    if (checked) {
      const _selectedRowKeys = dataCurrent.map((item) => item.id)
      setSelectedRowKeys(_selectedRowKeys)
    } else {
      setSelectedRowKeys([])
    }
  }

  const onChangeCheckItem = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    const value = event.target.value
    if (checked) {
      setSelectedRowKeys([...selectedRowKeys, value])
    } else {
      const _selectedRowKeys = selectedRowKeys.filter((item) => item !== value)
      setSelectedRowKeys(_selectedRowKeys)
    }
  }

  const rowSelection: TableRowSelection<UploadResultsType> = {
    columnTitle: (
      <CheckboxAll
        checked={isCheckAll}
        isDisabled={!dataSource.length}
        onChange={onChangeCheckAll}
      />
    ),
    renderCell: (_, record) => (
      <CheckboxItem
        checked={selectedRowKeys.includes(record.id)}
        onChange={onChangeCheckItem}
        value={record.id}
      />
    ),
    selectedRowKeys: selectedRowKeys,
  }

  const onChange = (page: number, pageSize: number) => {
    onChangePage(page, pageSize)
    setIsCheckAll(false)
    setSelectedRowKeys([])
  }

  const onChangeTable = (extra: TableCurrentDataSource<UploadResultsType>) => {
    setDataSource(extra.currentDataSource)
    setIsCheckAll(false)
    setSelectedRowKeys([])
  }

  useEffect(() => {
    if (dataSource?.length) {
      const _dataCurrent = dataSource.slice(perItemStart, perItemEnd)
      setDataCurrent(_dataCurrent)
    }
  }, [dataSource, setDataCurrent, perItemStart, perItemEnd])

  useEffect(() => {
    if (dataSource?.length) {
      onSelectChange()
    }
  }, [selectedRowKeys])

  useEffect(() => {
    if (batchLogInfoList?.length) {
      setDataSource(batchLogInfoList)
    }
  }, [batchLogInfoList])

  return {
    dataCurrent,
    dataSourceSelected,
    idBatchLogSelected,
    onChange,
    onChangeTable,
    perItem,
    rowSelection,
    selectedRowKeys,
    setSelectedRowKeys,
  }
}
