import { FC } from "react"
import {
  SafetiesDetailDataSourceType,
  SafetiesDetailTableProps,
} from "../../../models/detail"
import { Table } from "../../../../../../_webui/layout/components/Table"

export const SafetiesDetailTable: FC<
  SafetiesDetailTableProps<SafetiesDetailDataSourceType>
> = (props) => {
  const {
    columns,
    isResetTable,
    isSafetiesBrowseResponse,
    onChange,
    perItem,
    rowSelection,
    safetyTravelers,
  } = props

  return (
    <Table
      columns={columns}
      dataSource={safetyTravelers}
      isResetTable={isResetTable}
      onChange={onChange}
      pagination={isSafetiesBrowseResponse}
      perItem={perItem}
      rowKey="safetyDetailId"
      // eslint-disable-next-line no-undefined
      rowSelection={isSafetiesBrowseResponse ? rowSelection : undefined}
    />
  )
}
