import { Translate } from "../../../../../../../constant/Translate"
import { GridItemType } from "../../../../../../../_webui/layout/components/Grid/models"
import { TravelersAddSuccessType } from "../../../../models/addSuccess"

const { TEXT_T002, TEXT_T003, TEXT_T006, TEXT_T009, TEXT_T017, TEXT_T025 } =
  Translate

export const useGridConfig = (data?: TravelersAddSuccessType) => {
  const gridItems: GridItemType[] = [
    { key: TEXT_T002, text: data?.userId },
    { key: TEXT_T003, text: data?.fullNameKanji },
    { key: TEXT_T006, text: data?.fullNameRomaji },
    { key: TEXT_T009, text: data?.bumonName },
    { key: TEXT_T025, text: data?.dispatchTypeDetailName },
    { key: TEXT_T017, text: data?.personEmailAddress },
  ]
  return {
    gridItems,
  }
}
