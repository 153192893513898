import { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import {
  SafetiesDataType,
  SafetiesTransmissionInfoType,
} from "../../../../models/safety"
import { SafetiesSuccessStateType } from "../../../../models/success"
import { useTableConfig } from "../useTableConfig"
import { useVariablesAuthority } from "../useVariablesAuthority"

export const useVariables = () => {
  const { push } = useHistory()
  const { state } = useLocation<SafetiesSuccessStateType>()
  const [travelerInfoList, setTravelerInfoList] = useState<SafetiesDataType[]>(
    [],
  )
  const [transmissionInfo, setTransmissionInfo] =
    useState<SafetiesTransmissionInfoType>()
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const { columns } = useTableConfig()
  const { isNotAccessScreen, isVisibleBtnDetail, isVisibleBtnHome } =
    useVariablesAuthority()

  return {
    columns,
    isLoadingLayout,
    isNotAccessScreen,
    isVisibleBtnDetail,
    isVisibleBtnHome,
    push,
    safetiesSelected: state.safetiesSelected,
    setIsLoadingLayout,
    setTransmissionInfo,
    setTravelerInfoList,
    state,
    transmissionInfo,
    travelerInfoList,
  }
}
