import { Translate } from "../../../../../../../../constant/Translate"
import { ColumnType } from "../../../../../../../../_webui/layout/components/Table/models"
import { ScheduleStaysType } from "../../../../../models/addSuccess"

const { TEXT_T240, TEXT_T256, TEXT_T257, TEXT_T258, TEXT_T259, TEXT_T260 } =
  Translate

export const useStayTableConfig = (): ColumnType<ScheduleStaysType>[] => [
  {
    render: (_, _data, index) => index + 1,
    title: TEXT_T240,
    width: 65,
  },
  {
    dataIndex: "locationPhoneNumber1",
    title: TEXT_T256,
    width: 250,
  },
  {
    dataIndex: "emailAddress1",
    title: TEXT_T259,
    width: 250,
  },
  {
    dataIndex: "locationPhoneNumber2",
    title: TEXT_T257,
    width: 250,
  },
  {
    dataIndex: "satellitePhoneNumber",
    title: TEXT_T258,
    width: 250,
  },
  {
    dataIndex: "emailAddress2",
    title: TEXT_T260,
    width: 250,
  },
]
