import { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { ErrorItemType } from "../../../../../../../_webui/layout/components/Typography/ErrorList/models"
import { useModal } from "../../../../../../../utils/hook/useModal"
import {
  SchedulesConfirmDeleteDataType,
  SchedulesConfirmDeleteStateType,
} from "../../../../models/confirmDelete"
import { useTableConfig } from "../useTableConfig"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { getBreadcrumbItems } from "./getBreadcrumbItems"

export const useVariables = () => {
  const { state } = useLocation<SchedulesConfirmDeleteStateType>()
  const { push } = useHistory()
  const { isOpen, onCancel, onOpen } = useModal()
  // 「権限区分」欄と「小機能ID」欄に記載されている条件を満たした場合にボタンが表示される。
  const {
    isNotAccessScreen,
    isRoleAdmin,
    isRoleSubAdmin,
    isRoleUser,
    isVisibleBtnCancel,
    isVisibleBtnYes,
  } = useVariablesAuthority()
  const { columns } = useTableConfig(state?.schedulesURL)

  const [scheduleList, setScheduleList] = useState<
    SchedulesConfirmDeleteDataType[]
  >([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [errorItems, setErrorItems] = useState<ErrorItemType[]>([])

  const breadcrumbItems = getBreadcrumbItems(
    state?.isFromSCR210,
    state?.schedulesURL,
  )

  return {
    breadcrumbItems,
    columns,
    errorItems,
    isLoading,
    isLoadingLayout,
    isNotAccessScreen,
    isOpen,
    isRoleAdmin,
    isRoleSubAdmin,
    isRoleUser,
    isVisibleBtnCancel,
    isVisibleBtnYes,
    onCancel,
    onOpen,
    push,
    scheduleList,
    setErrorItems,
    setIsLoading,
    setIsLoadingLayout,
    setScheduleList,
    state,
  }
}
