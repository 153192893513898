const en = {
  TEXT_T001: "Implementation date",
  TEXT_T002: "The deadline for responding to the alert has expired.",
  TEXT_T003: "Attention alert response",
  TEXT_T004: "Thank you for your confirmation.",
  TEXT_T005: "Attention alert display",
  TEXT_T006: "Please continue to put safety first.",
  TEXT_T007: "Close",
  TEXT_T008: "Please be sure to read the information below.",
  TEXT_T009: "Confirmed the Security alert",
  TEXT_T010: "Response to safety confirmation completion",
  TEXT_T011: "Safety confirmation display",
  TEXT_T012: "Response of safety confirmation information has been completed.",
  TEXT_T013: "Thank you for your response.",
  TEXT_T014: "Confirmation of the contents of the safety confirmation",
  TEXT_T015:
    "The time limit for responding to the safety confirmation has expired.",
  TEXT_T016: "Please be sure to confirm the safety information below.",
  TEXT_T017: "Safety confirmation response",
  TEXT_T018: "Report must include accompanying family members.",
  TEXT_T019: "Traveler name",
  TEXT_T020: "Safety status",
  TEXT_T021: "Safety of Family",
  TEXT_T022: "Message",
  TEXT_T023: "Dispatch type (Detail)",
  TEXT_T024: "Confirm",
  TEXT_T025: "Safety",
  TEXT_T026: "Slightly injured",
  TEXT_T027: "Seriously injured",
  TEXT_T028: "All Safe",
  TEXT_T029: "Slightly injured",
  TEXT_T030: "Seriously injured",
  TEXT_T031: "Confirming",
  TEXT_T032: "No reportable person",
  TEXT_T033: "",
  TEXT_T034: "",
  TEXT_T035: "",
  TEXT_T036: "",
  TEXT_T037: "",
  TEXT_T038: "",
  TEXT_T039: "",
}
const jp = {
  TEXT_T001: "実施日",
  TEXT_T002: "注意喚起の応答期限が切れました。",
  TEXT_T003: "注意喚起の応答",
  TEXT_T004: "ご確認ありがとうございます。",
  TEXT_T005: "注意喚起の表示",
  TEXT_T006: "引き続き、安全第一で行動してください。",
  TEXT_T007: "閉じる",
  TEXT_T008: "以下の情報を必ずお読みください。",
  TEXT_T009: "注意喚起情報を確認した",
  TEXT_T010: "安否確認情報の応答完了",
  TEXT_T011: "安否確認の表示",
  TEXT_T012: "ご確認ありがとうございます。",
  TEXT_T013: "引き続き、安全第一で行動して下さい。",
  TEXT_T014: "安否確認の内容確認",
  TEXT_T015: "安否確認の応答期限が切れました。",
  TEXT_T016: "以下の安否確認を必ず実施ください。",
  TEXT_T017: "安否確認の応答",
  TEXT_T018: "報告対象は同行している家族を含む。",
  TEXT_T019: "渡航者名",
  TEXT_T020: "安否状況",
  TEXT_T021: "家族の安否",
  TEXT_T022: "メッセージ",
  TEXT_T023: "派遣種別（詳細）",
  TEXT_T024: "確認",
  TEXT_T025: "無事",
  TEXT_T026: "軽傷",
  TEXT_T027: "重傷",
  TEXT_T028: "全員無事",
  TEXT_T029: "軽傷者あり",
  TEXT_T030: "重傷者あり",
  TEXT_T031: "確認中",
  TEXT_T032: "報告対象者なし",
  TEXT_T033: "",
  TEXT_T034: "",
  TEXT_T035: "",
  TEXT_T036: "",
  TEXT_T037: "",
  TEXT_T038: "",
  TEXT_T039: "",
}

export const getLang = (lang: string, key: keyof typeof jp) =>
  lang === "jp" ? jp[key] : en[key]
