import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const { isSafetiesSendAll, isRoleSubAdmin, isScheduleSearch } = useAuthority()

  const isHiddenCopyBtn = !(isRoleSubAdmin && isSafetiesSendAll)
  const isHiddenScheduleListBtn = !(isRoleSubAdmin && isScheduleSearch)
  const isHiddenBackBtn = !(
    isRoleSubAdmin &&
    (isScheduleSearch || isSafetiesSendAll)
  )

  return {
    isHiddenBackBtn,
    isHiddenCopyBtn,
    isHiddenScheduleListBtn,
    isRoleSubAdmin,
    isSafetiesSendAll,
  }
}
