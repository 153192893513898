import { ReactKeycloakProvider } from "@react-keycloak/web"
import { BrowserRouter, Prompt } from "react-router-dom"
import { Layout } from "../_webui/layout"
import { Spin } from "../_webui/layout/components/Spin"
import { I18nProvider } from "../_webui/i18n/I18nProvider"
import keycloak from "../utils/keycloak"
import { Routers } from "../constant/Routers"
import { FC } from "react"
import { Translate } from "../constant/Translate"
import { IdleTimerProvider } from "react-idle-timer"
import { translateHelper } from "../utils/translateHelper"
import { clearLocalStorage } from "../utils/clearLocalStorage"
import { Environment } from "../constant/Environment"
import { getUserInformation } from "../utils/getUserInformation"
import { LoginActions } from "../constant/Login"
import { checkPublicRouter } from "../utils/checkPublicRouter"

const { KEY, TIMEOUT } = LoginActions

const { MESSAGE_E0058 } = Translate

const { SCR116, SCR212, SCR212_USER, SCR214, SCR214_USER, SCR911 } = Routers

const App: FC = () => {
  const userInformation = getUserInformation()
  const unloadFunction = (event: BeforeUnloadEvent) => {
    const listPath = [SCR116, SCR212, SCR212_USER, SCR214, SCR214_USER]
    if (listPath.includes(window.location.pathname) && userInformation) {
      event.preventDefault()
      return (event.returnValue = "Are you sure you want to close?")
    }
  }
  document.onkeydown = (event) => {
    if (event.altKey && event.keyCode === 37) {
      alert(MESSAGE_E0058)
      return false
    }
    if (window.location.pathname === SCR911 && event.keyCode === 8) {
      alert(MESSAGE_E0058)
      return false
    }
  }
  window.addEventListener("beforeunload", unloadFunction)

  const handleLogout = () => {
    localStorage.removeItem(KEY)
    clearLocalStorage()
    keycloak.logout({ redirectUri: `${Environment.URL}/home` })
  }

  const handleSessionTimeout = () => {
    if (localStorage.getItem("userInformation") && !checkPublicRouter()) {
      localStorage.setItem(KEY, TIMEOUT)
      window.removeEventListener("beforeunload", unloadFunction)
      alert(translateHelper.getSessionTimeOutMessage())
      setTimeout(handleLogout, 500)
    }
  }

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      LoadingComponent={<Spin isFixed isLoading />}
    >
      <I18nProvider>
        <IdleTimerProvider
          timeout={1800000}
          onIdle={handleSessionTimeout}
          events={[
            "mousemove",
            "keydown",
            "wheel",
            "DOMMouseScroll",
            "mousewheel",
            "mousedown",
            "touchstart",
            "touchmove",
            "MSPointerDown",
            "MSPointerMove",
            "focus",
          ]}
        >
          <BrowserRouter>
            <Prompt
              message={(_, action) => {
                if (action === "POP") {
                  alert(MESSAGE_E0058)
                  return false
                }

                return true
              }}
            />
            <Layout />
          </BrowserRouter>
        </IdleTimerProvider>
      </I18nProvider>
    </ReactKeycloakProvider>
  )
}

export default App
