import { FC } from "react"
import { ButtonSubmit } from "../../../../../../_webui/layout/components/Button/Submit"
import { Divider } from "../../../../../../_webui/layout/components/Divider"
import { ItemSelect } from "../../../../../../_webui/layout/components/Form/Item/Select"
import { Layout } from "../../../../../../_webui/layout/components/Layout"
import { Tooltip } from "../../../../../../_webui/layout/components/Tooltip"
import { Heading } from "../../../../../../_webui/layout/components/Typography/Heading"
import { Breadcrumbs } from "../../../../../../constant/Breadcrumbs"
import { Translate } from "../../../../../../constant/Translate"
import { validatorRequiredField } from "../../../../../../utils/validator/requiredField"
import { useTimezoneSetting } from "./modules/useSetting"
import { Styled } from "./styled"

const { BRCR_SCR930 } = Breadcrumbs
const { StyledForm } = Styled
const { HELP_H0033, TEXT_T012, TEXT_T156, TEXT_T445, TEXT_T446, TEXT_T447 } =
  Translate

export const TimezoneSetting: FC = () => {
  const {
    form,
    goToHome,
    isHiddenReset,
    isHiddenSubmit,
    isLoading,
    isLoadingLayout,
    onFinish,
    timeFormatItems,
    timeZoneItems,
  } = useTimezoneSetting()

  return (
    <Layout
      breadcrumbItems={BRCR_SCR930}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T156}
    >
      <StyledForm
        form={form}
        heading={TEXT_T156}
        isLoading={isLoading}
        layout="inline"
        onFinish={onFinish}
      >
        <ItemSelect
          label={
            <Heading
              style={{ marginBottom: "20px", marginTop: 0 }}
              title={TEXT_T445}
            />
          }
          name="timeZone"
          rules={[validatorRequiredField]}
          selectItem={timeZoneItems}
          style={{}}
        />
        <ItemSelect
          label={
            <Heading
              style={{ marginBottom: "20px", marginTop: 0 }}
              title={<Tooltip title={HELP_H0033}>{TEXT_T446}</Tooltip>}
            />
          }
          name="timeFormat"
          rules={[validatorRequiredField]}
          selectItem={timeFormatItems}
        />
        <Divider />
        <ButtonSubmit
          isHiddenReset={isHiddenReset}
          isHiddenSubmit={isHiddenSubmit}
          isReset
          onClickReset={goToHome}
          resetText={TEXT_T012}
          submitText={TEXT_T447}
        />
      </StyledForm>
    </Layout>
  )
}
