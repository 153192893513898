import { useAuthority } from "../../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const { isCommonPermissionSettings, isRoleAdmin } = useAuthority()
  const isHiddenBtn = isRoleAdmin && isCommonPermissionSettings

  return {
    isCommonPermissionSettings,
    isHiddenBtn,
  }
}
