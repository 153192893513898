import "./style/index.scss"
import { Tag as TagAnt } from "antd"
import { FC } from "react"
import { TagProps } from "./models"
import { getStyle } from "../../../../utils/getStyle"

export const Tag: FC<TagProps> = (props) => {
  const { isFullWidth, text, type } = props
  const styleTagFullWidth = getStyle(isFullWidth, " tag-full-width")
  const styleTagType = getStyle(type, ` tag-${type}`, " tag-default")
  const styleTag = `tag${styleTagType}${styleTagFullWidth}`
  return <TagAnt className={styleTag}>{text}</TagAnt>
}
