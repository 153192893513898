import "./style/index.scss"
import { Collapse as CollapseAnt } from "antd"
import { FC, useEffect, useState } from "react"
import { CollapseProps } from "./models"

const { Panel } = CollapseAnt

export const Collapse: FC<CollapseProps> = (props) => {
  const { defaultActiveKey, onChangeCollapse, panelItems, ...res } = props
  const [key, setKey] = useState(defaultActiveKey)

  const onChange = (key: string | string[]) => {
    setKey(key)
    onChangeCollapse && onChangeCollapse([...key])
  }

  useEffect(() => {
    setKey(defaultActiveKey)
  }, [defaultActiveKey, setKey])

  return (
    <CollapseAnt activeKey={key} onChange={onChange} {...res}>
      {panelItems?.map((item) => (
        <Panel key={item.key} header={item.header} forceRender>
          {item.content}
        </Panel>
      ))}
    </CollapseAnt>
  )
}
