import { useEffect } from "react"
import { Routers } from "../../../../../../../constant/Routers"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { replaceString } from "../../../../../../../utils/replaceString"
import { SafetiesDataType } from "../../../../models/safety"
import { SafetiesSuccessType } from "../../../../models/success"
import { useVariables } from "../useVariables"

const { SCR415, SCR417, SCR920 } = Routers

export const useSafetiesSuccess = (): SafetiesSuccessType<SafetiesDataType> => {
  const {
    columns,
    isLoadingLayout,
    isNotAccessScreen,
    isVisibleBtnDetail,
    isVisibleBtnHome,
    push,
    safetiesSelected,
    setIsLoadingLayout,
    setTransmissionInfo,
    setTravelerInfoList,
    state,
    transmissionInfo,
    travelerInfoList,
  } = useVariables()

  const goToHome = () => {
    //「SCR920_ホーム画面」に遷移する。
    push(SCR920)
  }

  const goToSafetiesDetail = () => {
    if (state) {
      const { safetyId } = state
      //「SCR417_安否確認の詳細画面」に遷移する。
      push({
        pathname: replaceString(SCR417, safetyId),
        state: {
          ...state,
          isFromSCR415: true,
          safetyId: state?.safetyId,
          sourceURL: SCR415,
        },
      })
    }
  }

  useEffect(() => {
    // ユーザの画面アクセス許可チェックを処理する。
    if (isNotAccessScreen) {
      //「SCR973_エラー情報の表示画面」に遷移する。
      const { pathname, state } = backToLogin()
      push({ pathname, state })
    } else {
      if (state) {
        setTravelerInfoList(state.travelerInfoList)
        setTransmissionInfo(state.transmissionInfo)
      }
      setTimeout(() => {
        setIsLoadingLayout(false)
      }, 500)
    }
  }, [])

  return {
    columns,
    goToHome,
    goToSafetiesDetail,
    isLoadingLayout,
    isVisibleBtnDetail,
    isVisibleBtnHome,
    safetiesSelected,
    transmissionInfo,
    travelerInfoList,
  }
}
