/* eslint-disable max-lines */
import { useEffect } from "react"
import { Routers } from "../../../../../../../../constant/Routers"
import { Translate } from "../../../../../../../../constant/Translate"
import { AxiosErrorType } from "../../../../../../../../infrastructure/axiosError/models"
import { AuthorityPatternResType } from "../../../../../../../../infrastructure/handlers/authorities/models"
import { backToLogin } from "../../../../../../../../utils/backToLogin"
import { pushStateError } from "../../../../../../../../utils/pushStateError"
import { GridItemType } from "../../../../../../../../_webui/layout/components/Grid/models"
import { commonApi } from "../../../../../apis"
import { RolesConfig } from "../../../../../constants/roles/setting/rolesConfig"
import { UseRolesSuccessType } from "../../../../../models/roles/success"
import { useVariables } from "../useVariables"
import { RolesCheckboxOptionsType } from "../../../../../components/roles/Table/CheckboxGroup/models"
import { AuthorityPatternSettingResType } from "../../../../../models/roles/setting"

const { ROLE_ATTENTION, ROLE_SAFETY, ROLE_SCHEDULE, ROLE_TRAVELER } =
  RolesConfig
const { SCR950, SCR953, SCR973 } = Routers
const { TEXT_T453 } = Translate

export const useRolesSuccess = (): UseRolesSuccessType => {
  const {
    authorityPattern,
    authorityPatternSettings,
    isCommonPermissionSettings,
    isHiddenBtn,
    isLoadingLayout,
    patternId,
    push,
    setAuthorityPattern,
    setAuthorityPatternSettings,
    setIsLoadingLayout,
  } = useVariables()

  // 「SCR953_権限メンテナンス画面」に遷移する。
  const goToRolesSetting = () => {
    push({
      pathname: SCR953,
      state: {
        patternId,
        sourceURL: SCR953,
      },
    })
  }

  // 「SCR950_機能限定管理者設定画面」に遷移する。
  const goToSubAdminSetting = () => {
    push({
      pathname: SCR950,
      state: {
        isFromSCR954: true,
        searchCondition: {
          patternId,
        },
        sourceURL: SCR950,
      },
    })
  }

  const callApi = async () => {
    // APIを呼び出し、権限パターン情報を取得する。
    const getAuthorityPattern: AuthorityPatternResType =
      await commonApi.getAuthorityPattern(patternId)
    // APIを呼び出し、権限パターン設定一覧を取得する。
    const getAuthorityPatternSetting: AuthorityPatternSettingResType =
      await commonApi.getAuthorityPatternSetting(patternId)
    await Promise.all([getAuthorityPattern, getAuthorityPatternSetting])
      .then((res) => {
        const gridItem: GridItemType[] = [
          {
            key: TEXT_T453,
            text: res[0].patternName,
          },
        ]
        setAuthorityPattern(gridItem)

        const getValue = (roles: RolesCheckboxOptionsType[]) => {
          const arr: RolesCheckboxOptionsType[] = []
          roles.filter((item) => {
            if (res[1].subFunctionIds.includes(item.value)) arr.push(item)
            else arr.push({ ...item, value: "" })
          })
          return arr
        }
        setAuthorityPatternSettings({
          attentionOptions: getValue(ROLE_ATTENTION),
          safetyOptions: getValue(ROLE_SAFETY),
          scheduleOptions: getValue(ROLE_SCHEDULE),
          travelerOptions: getValue(ROLE_TRAVELER),
        })

        setIsLoadingLayout(false)
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const state = pushStateError(error)
        push({ pathname: SCR973, state })
      })
  }

  useEffect(() => {
    // ユーザーの画面アクセス許可チェックを処理する。
    if (!isCommonPermissionSettings) {
      // 「SCR973_エラー情報の表示画面」に遷移する。
      const { pathname, state } = backToLogin()
      push({ pathname, state })
    } else callApi()
  }, [])

  return {
    dataSource: authorityPatternSettings,
    detail: authorityPattern,
    goToRolesSetting,
    goToSubAdminSetting,
    isHiddenBtn,
    isLoadingLayout,
  }
}
