import { Form } from "antd"
import { FC } from "react"
import { Regex } from "../../../../../../../constant/Regex"
import { SubAdminSettingButtonGroupProps } from "../../../../models/subAdmin/setting"
import { ButtonGroupPC } from "./ButtonGroupPC"
import { ButtonGroupTablet } from "./ButtonGroupTablet"

const { REGEX_IS_DESKTOP } = Regex

export const SubAdminSettingButtonGroup: FC<SubAdminSettingButtonGroupProps> = (
  props,
) => {
  const {
    goBack,
    goToRolesSetting,
    goToSubAdmin,
    isDisabledBtnSave,
    isHiddenBtn,
  } = props

  return (
    <Form.Item shouldUpdate className="ant-form-btn-submit">
      {() =>
        REGEX_IS_DESKTOP ? (
          <ButtonGroupPC
            goBack={goBack}
            goToRolesSetting={goToRolesSetting}
            goToSubAdmin={goToSubAdmin}
            isDisabledBtnSave={isDisabledBtnSave}
            isHiddenBtn={isHiddenBtn}
          />
        ) : (
          <ButtonGroupTablet
            goBack={goBack}
            goToRolesSetting={goToRolesSetting}
            goToSubAdmin={goToSubAdmin}
            isDisabledBtnSave={isDisabledBtnSave}
            isHiddenBtn={isHiddenBtn}
          />
        )
      }
    </Form.Item>
  )
}
