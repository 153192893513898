import { FC, Fragment } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { replaceStringParam } from "../../../../../../utils/replaceStringParam"
import { Table } from "../../../../../../_webui/layout/components/Table"
import { TableError } from "../../../../../../_webui/layout/components/Table/Error"
import { Title } from "../../../../../../_webui/layout/components/Typography/Title"
import { TotalRecords } from "../../../../../../_webui/layout/components/Typography/TotalRecords"
import { TravelersBatchDeleteModalTableProps } from "../../../models/batchDelete"
import { PreLine } from "../../../../../../_webui/layout/components/Typography/PreLine"

const { TEXT_T608, TEXT_T594, MESSAGE_I0010 } = Translate

export const TravelersBatchDeleteModalTable: FC<
  TravelersBatchDeleteModalTableProps
> = (props) => {
  const {
    columnErrors,
    columns,
    countRecord,
    dataTemps,
    errorList,
    isResetTable,
    onChange,
    perItem,
  } = props

  return errorList.length ? (
    <TableError
      columns={columnErrors}
      countRecord={countRecord}
      errorList={errorList}
      isResetTable={isResetTable}
      rowKey="id"
      titleLeft={replaceStringParam(TEXT_T594, String(errorList?.length))}
      total={errorList?.length}
    />
  ) : (
    <Fragment>
      {countRecord && (
        <Title
          titleLeft={
            <PreLine>
              {replaceStringParam(
                MESSAGE_I0010,
                String(countRecord.total),
                countRecord.success,
                countRecord.fail,
              )}
            </PreLine>
          }
        />
      )}
      <TotalRecords
        perItem={perItem}
        titleLeft={TEXT_T608}
        total={dataTemps?.length}
      />
      <Table
        columns={columns}
        dataSource={dataTemps}
        isResetTable={isResetTable}
        onChange={onChange}
        rowKey="userId"
      />
    </Fragment>
  )
}
