import { FC, Fragment } from "react"
import { ModalConfirm } from "../../../../../../_webui/layout/components/Modal/Confirm"
import { Translate } from "../../../../../../constant/Translate"
import { TravelersModalConfirmProps } from "./models"
import { replaceStringParam } from "../../../../../../utils/replaceStringParam"

const {
  MESSAGE_I0020,
  MESSAGE_C0037,
  MESSAGE_C0038,
  MESSAGE_C0043,
  TEXT_T229,
} = Translate

export const TravelersModalConfirm: FC<TravelersModalConfirmProps> = (
  props,
) => {
  const {
    isOpen,
    isOpenLoadData,
    isOpenShowData,
    onCancel,
    onCancelCustomLoadData,
    onOk,
    onOkLoadData,
    onOkShowData,
    selectedRowLength,
  } = props

  return (
    <Fragment>
      <ModalConfirm isOpen={isOpen} onCancel={onCancel} onOk={onOk}>
        {selectedRowLength ? MESSAGE_C0037 : MESSAGE_C0038}
      </ModalConfirm>
      <ModalConfirm
        cancelCustomText={TEXT_T229}
        cancelText=""
        isOpen={isOpenLoadData}
        onCancelCustom={onCancelCustomLoadData}
        onOk={onOkLoadData}
      >
        {replaceStringParam(MESSAGE_C0043, "1200")}
      </ModalConfirm>
      <ModalConfirm
        cancelText=""
        isCenter
        isOpen={isOpenShowData}
        onOk={onOkShowData}
      >
        {MESSAGE_I0020}
      </ModalConfirm>
    </Fragment>
  )
}
