import { FC } from "react"
import { Routers } from "../../../../constant/Routers"
import { Switch } from "react-router-dom"
import PrivateRoute from "../../../../app/routing/privateRoute"
import { ApplicationFormTemplate } from "../../../../app/pages/applicationForm/template"
import { ApplicationFormRegistration } from "../../../../app/pages/applicationForm/registration"
import { ApplicationFormChange } from "../../../../app/pages/applicationForm/change"
import { ApplicationFormAuthorityPattern } from "../../../../app/pages/applicationForm/authorityPattern"
import { ApplicationFormSummerTime } from "../../../../app/pages/applicationForm/summerTime"

const {
  SCR991_AUTHORITY_PATTERN,
  SCR991_CHANGE_APPLICATION_FORM,
  SCR991_REGISTRATION_APPLICATION_FORM,
  SCR991_SUMMER_TIME,
  SCR991_TEMPLATE,
} = Routers

export const ApplicationFormRoute: FC = () => (
  <Switch>
    <PrivateRoute
      component={ApplicationFormTemplate}
      exact
      path={SCR991_AUTHORITY_PATTERN}
    />
    <PrivateRoute
      component={ApplicationFormRegistration}
      exact
      path={SCR991_CHANGE_APPLICATION_FORM}
    />
    <PrivateRoute
      component={ApplicationFormChange}
      exact
      path={SCR991_REGISTRATION_APPLICATION_FORM}
    />
    <PrivateRoute
      component={ApplicationFormAuthorityPattern}
      exact
      path={SCR991_SUMMER_TIME}
    />
    <PrivateRoute
      component={ApplicationFormSummerTime}
      exact
      path={SCR991_TEMPLATE}
    />
  </Switch>
)
