/* eslint-disable max-statements */
/* eslint-disable complexity */
import moment from "moment"
import { FormFieldValueType, NamePathType } from "./models"
import { Translate } from "../../../constant/Translate"

const { MESSAGE_E0138, MESSAGE_E0141 } = Translate

export const validatorRangeDate =
  (propsItem: NamePathType) => (props: FormFieldValueType) => ({
    validator() {
      const { isLodging, namePathEndDate, namePathStartDate } = propsItem
      const { getFieldValue } = props

      const startDate = getFieldValue(namePathStartDate)
      const endDate = getFieldValue(namePathEndDate)

      if (
        startDate &&
        endDate &&
        moment
          .duration(endDate.startOf("day").diff(startDate.startOf("day")))
          .asDays() < 0
      ) {
        if (isLodging) {
          return Promise.reject(new Error(MESSAGE_E0141))
        }
        return Promise.reject(new Error(MESSAGE_E0138))
      }

      return Promise.resolve()
    },
  })
