/* eslint-disable max-lines */
import { FC } from "react"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { Breadcrumbs } from "../../../../../constant/Breadcrumbs"
import { Translate } from "../../../../../constant/Translate"
import { TravelersRequestButtonGroup } from "../../components/requestDelete/ButtonGroup"
import { TravelersRequestDeleteFormSearch } from "../../components/requestDelete/FormSearch"
import { TravelersRequestDeleteModal } from "../../components/requestDelete/Modal"
import { TravelersRequestDeleteTable } from "../../components/requestDelete/Table"
import { useTravelersRequestDelete } from "./modules/useRequestDelete"

const { TEXT_T166 } = Translate
const { BRCR_SCR125 } = Breadcrumbs

export const TravelersRequestDelete: FC = () => {
  const {
    activeKey,
    columnErrors,
    columns,
    dataGridItems,
    dataSourceLength,
    dispatchType,
    executeDetail,
    isDisabledButton,
    isLoading,
    isLoadingLayout,
    isOpenAccept,
    isOpenReActive,
    isOpenReEnable,
    isOpenRequestCancel,
    isResetTable,
    isShowTableError,
    isVisibleBtn,
    isVisibleBtnRoleAdmin,
    isVisibleBtnRoleSubAdmin,
    isVisibleTabRoleAdmin,
    isVisibleTabRoleSubAdmin,
    onCancelAccept,
    onCancelReActive,
    onCancelReEnable,
    onCancelRequestCancel,
    onChange,
    onChangeTable,
    onClickSearchSubmit,
    onOkAccept,
    onOkReActive,
    onOkReEnable,
    onOkRequestDisable,
    onOpenAccept,
    onOpenReActive,
    onOpenReEnable,
    onOpenRequestCancel,
    onTabClick,
    perItem,
    rowSelection,
    selectedRowKeys,
    tableErrorDataSource,
  } = useTravelersRequestDelete()

  return (
    <Layout
      breadcrumbItems={BRCR_SCR125}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T166}
    >
      <Card heading={TEXT_T166} isLoading={isLoading}>
        <TravelersRequestDeleteFormSearch
          dispatchType={dispatchType}
          isVisibleBtn={isVisibleBtn}
          onClickSearchSubmit={onClickSearchSubmit}
        />
        <TravelersRequestDeleteTable
          columnErrors={columnErrors}
          columns={columns}
          dataGridItems={dataGridItems}
          dataSourceLength={dataSourceLength}
          executeDetail={executeDetail}
          isResetTable={isResetTable}
          isShowTableError={isShowTableError}
          isVisibleBtn={isVisibleBtn}
          isVisibleTabRoleAdmin={isVisibleTabRoleAdmin}
          isVisibleTabRoleSubAdmin={isVisibleTabRoleSubAdmin}
          onChange={onChange}
          onChangeTable={onChangeTable}
          onTabClick={onTabClick}
          perItem={perItem}
          rowSelection={rowSelection}
          tableErrorDataSource={tableErrorDataSource}
        />
        <TravelersRequestButtonGroup
          activeKey={activeKey}
          isDisabledButton={isDisabledButton}
          isVisibleBtnRoleAdmin={isVisibleBtnRoleAdmin}
          isVisibleBtnRoleSubAdmin={isVisibleBtnRoleSubAdmin}
          onOpenAccept={onOpenAccept}
          onOpenReActive={onOpenReActive}
          onOpenReEnable={onOpenReEnable}
          onOpenRequestCancel={onOpenRequestCancel}
          selectedRowKeys={selectedRowKeys}
        />
        <TravelersRequestDeleteModal
          isOpenAccept={isOpenAccept}
          isOpenReActive={isOpenReActive}
          isOpenReEnable={isOpenReEnable}
          isOpenRequestCancel={isOpenRequestCancel}
          onCancelAccept={onCancelAccept}
          onCancelReActive={onCancelReActive}
          onCancelReEnable={onCancelReEnable}
          onCancelRequestCancel={onCancelRequestCancel}
          onOkAccept={onOkAccept}
          onOkReActive={onOkReActive}
          onOkReEnable={onOkReEnable}
          onOkRequestDisable={onOkRequestDisable}
        />
      </Card>
    </Layout>
  )
}
