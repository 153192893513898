import { Translate } from "../../../../../../../constant/Translate"
import { OptionItemType } from "../../../../Select/models"

const { TEXT_T578, TEXT_T579 } = Translate

const getNumber = (length: number, text: string) => {
  const numbers = []
  for (let index = 0; index < length; index++) {
    const _index = index < 10 ? `0${index}` : `${index}`
    numbers.push({
      text: `${_index}${text}`,
      value: _index,
    })
  }
  return numbers
}

const SELECT_HOUR_ITEMS: OptionItemType[] = getNumber(24, TEXT_T578)
const SELECT_MINUTE_ITEMS: OptionItemType[] = getNumber(60, TEXT_T579)

export const Options = {
  SELECT_HOUR_ITEMS,
  SELECT_MINUTE_ITEMS,
}
