/* eslint-disable max-statements */
import Excel from "exceljs"
import { cellConfig } from "../../config/cell"
import { columnsConfig } from "../columnsConfig"
import {
  SafetiesDetailExcelSheetType,
  SafetyInfoHeaderType,
} from "../../../../../../../../models/detail/export"
import { Translate } from "../../../../../../../../../../../constant/Translate"

const { EXPORT_EP0203 } = Translate

export const worksheetConfig = async (
  data: SafetiesDetailExcelSheetType[],
  header: SafetyInfoHeaderType,
  workbook: Excel.Workbook,
) => {
  const worksheet = workbook.addWorksheet(EXPORT_EP0203)
  columnsConfig(data, header, worksheet)

  for (let index = 0; index < data.length; index++) {
    const item = data[index]
    // 7
    worksheet.getCell(`$A${index * 4 + 7}`).value = item.index
    worksheet.getCell(`$B${index * 4 + 7}`).value = item.safetyStatusName
    worksheet.getCell(`$C${index * 4 + 7}`).value = item.proxyResponser
    worksheet.getCell(`$D${index * 4 + 7}`).value = item.safetyResponseDate
    worksheet.getCell(`$E${index * 4 + 7}`).value = item.fullNameKanji
    worksheet.getCell(`$F${index * 4 + 7}`).value = item.dispatchTypeDetailName
    worksheet.getCell(`$G${index * 4 + 7}`).value = item.bumonName
    worksheet.getCell(`$H${index * 4 + 7}`).value = item.locationPhoneNumber1
    worksheet.getCell(`$I${index * 4 + 7}`).value = item.message
    worksheet.getCell(`$J${index * 4 + 7}`).value = item.travelerLocation
    worksheet.getCell(`$K${index * 4 + 7}`).value = item.familySafetyStatusName
    worksheet.getCell(`$L${index * 4 + 7}`).value = item.othersRemarks1
    worksheet.getCell(`$M${index * 4 + 7}`).value = item.locationPersonName
    // 7 + 1n
    worksheet.getCell(`$H${index * 4 + 8}`).value = item.locationPhoneNumber2
    // 7 + 2n
    worksheet.getCell(`$E${index * 4 + 9}`).value = item.fullNameRomaji
    worksheet.getCell(`$F${index * 4 + 9}`).value = item.passportNumber
    worksheet.getCell(`$H${index * 4 + 9}`).value = item.satellitePhoneNumber
    // 7 + 3n
    worksheet.getCell(`$H${index * 4 + 10}`).value = `${item.personPhoneNumber}`
  }

  data.forEach((_, index) => {
    const dataMergeCells = (index + 1) * 4 + 3
    worksheet.mergeCells(`$A$${dataMergeCells}:$A${dataMergeCells + 3}`)
    worksheet.mergeCells(`$B$${dataMergeCells}:$B${dataMergeCells + 3}`)
    worksheet.mergeCells(`$C$${dataMergeCells}:$C${dataMergeCells + 3}`)
    worksheet.mergeCells(`$D$${dataMergeCells}:$D${dataMergeCells + 3}`)
    worksheet.mergeCells(`$E$${dataMergeCells}:$E${dataMergeCells + 1}`)
    worksheet.mergeCells(`$E$${dataMergeCells + 2}:$E${dataMergeCells + 3}`)
    worksheet.mergeCells(`$F$${dataMergeCells}:$F${dataMergeCells + 1}`)
    worksheet.mergeCells(`$F$${dataMergeCells + 2}:$F${dataMergeCells + 3}`)
    worksheet.mergeCells(`$G$${dataMergeCells}:$G${dataMergeCells + 3}`)
    worksheet.mergeCells(`$I$${dataMergeCells}:$I${dataMergeCells + 3}`)
    worksheet.mergeCells(`$J$${dataMergeCells}:$J${dataMergeCells + 3}`)
    worksheet.mergeCells(`$K$${dataMergeCells}:$K${dataMergeCells + 3}`)
    worksheet.mergeCells(`$L$${dataMergeCells}:$L${dataMergeCells + 3}`)
    worksheet.mergeCells(`$M$${dataMergeCells}:$M${dataMergeCells + 3}`)
  })

  cellConfig(worksheet, EXPORT_EP0203)
}
