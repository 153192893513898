import { FC } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { Tabs } from "../../../../../../_webui/layout/components/Tabs"
import { TabsLabel } from "../../../../../../_webui/layout/components/Tabs/TabsLabel"
import { Tooltip } from "../../../../../../_webui/layout/components/Tooltip"
import { Variables } from "../../../constants/detail/variables"
import { SafetiesDetailTabsProps } from "../../../models/detail"
const {
  HELP_H0031,
  TEXT_T348,
  TEXT_T385,
  TEXT_T387,
  TEXT_T389,
  TEXT_T392,
  TEXT_T402,
} = Translate
const {
  MINOR_INJURY,
  NO_RESPONSE,
  SAFETY,
  SERIOUS_ILLNESS,
  UNDELIVERED_EMAIL,
} = Variables

export const SafetiesDetailTabs: FC<SafetiesDetailTabsProps> = (props) => {
  const { countSafetyStatus, onTabClick, tableItem } = props

  return (
    <Tabs
      defaultActiveKey=""
      isCustom
      items={[
        {
          children: tableItem,
          key: SAFETY,
          label: (
            <TabsLabel
              text={TEXT_T402}
              total={countSafetyStatus?.safetyCount}
            />
          ),
        },
        {
          children: tableItem,
          key: MINOR_INJURY,
          label: (
            <TabsLabel
              text={TEXT_T385}
              total={countSafetyStatus?.slightlyInjuredCount}
            />
          ),
        },
        {
          children: tableItem,
          key: SERIOUS_ILLNESS,
          label: (
            <TabsLabel
              text={TEXT_T387}
              total={countSafetyStatus?.seriouslyInjuredCount}
            />
          ),
        },
        {
          children: tableItem,
          key: NO_RESPONSE,
          label: (
            <TabsLabel
              text={TEXT_T389}
              total={countSafetyStatus?.noResponseCount}
            />
          ),
        },
        {
          children: tableItem,
          key: UNDELIVERED_EMAIL,
          label: (
            <Tooltip title={HELP_H0031}>
              <TabsLabel
                text={TEXT_T348}
                total={countSafetyStatus?.undeliveredEmailCount}
              />
            </Tooltip>
          ),
        },
        {
          children: tableItem,
          key: "",
          label: TEXT_T392,
        },
      ]}
      onTabClick={onTabClick}
    />
  )
}
