import { Key, useEffect } from "react"
import { SafetiesListType } from "../../../../models/list"
import { useVariablesRowSelection } from "../useVariablesRowSelection"
import { concat } from "../../../../../../../utils/concat"

export const useRowSelection = (
  safetiesInfoList: SafetiesListType[],
  isRoleSubAdmin: boolean,
) => {
  const {
    currentData,
    isDisabledDetailBtn,
    isDisabledResponseBtn,
    onChangePage,
    perItem,
    safetyDetailId,
    safetyId,
    selectedRowKeys,
    setCurrentData,
    setIsDisabledDetailBtn,
    setIsDisabledResponseBtn,
    setSafetyDetailId,
    setSafetyId,
    setSelectedRowKeys,
  } = useVariablesRowSelection()

  const onSelectChange = (newSelectedRowKeys: Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
    if (newSelectedRowKeys.length === 1) {
      const findData = currentData.find(
        (item) => item.id === newSelectedRowKeys[0],
      )
      if (isRoleSubAdmin) {
        const _isDisabledBtn = !!findData?.isLoginUser
        setIsDisabledResponseBtn(!_isDisabledBtn)
      } else {
        setIsDisabledResponseBtn(false)
      }
      setIsDisabledDetailBtn(false)
      setSafetyId(concat(findData?.safetyId))
      setSafetyDetailId(concat(findData?.safetyDetailId))
    } else {
      setIsDisabledResponseBtn(true)
      setIsDisabledDetailBtn(true)
    }
  }

  const rowSelection = {
    hideSelectAll: true,
    onChange: onSelectChange,
    selectedRowKeys: selectedRowKeys,
  }

  const onChange = (page: number, pageSize: number) => {
    onChangePage(page, pageSize)
    setSelectedRowKeys([])
    setIsDisabledResponseBtn(true)
    setIsDisabledDetailBtn(true)
  }

  useEffect(() => {
    if (safetiesInfoList.length) setCurrentData(safetiesInfoList)
  }, [safetiesInfoList])

  return {
    isDisabledDetailBtn,
    isDisabledResponseBtn,
    onChange,
    onChangePage,
    perItem,
    rowSelection,
    safetyDetailId,
    safetyId,
  }
}
