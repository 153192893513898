import { useHistory, useLocation } from "react-router-dom"
import { Routers } from "../../../../../../../../constant/Routers"
import {
  UseSubAdminSettingRoutersProps,
  UseSubAdminSettingStateType,
} from "../../../../../models/subAdmin/setting"

const { SCR920, SCR950, SCR951, SCR952, SCR953 } = Routers

export const useRouters = (props: UseSubAdminSettingRoutersProps) => {
  const { patternId, searchCondition, selectedRowKeys } = props
  const { push } = useHistory()
  const { state } = useLocation<UseSubAdminSettingStateType>()

  const handlePush = (pathname: string, isFromSCR950?: boolean) => {
    push({
      pathname,
      state: {
        isFromSCR950,
        searchCondition: {
          ...searchCondition,
          patternId,
          travelerIdList: selectedRowKeys,
        },
        sourceURL: SCR950,
      },
    })
  }

  // 「SCR952_機能限定管理者一覧画面」に遷移する。
  const goToSubAdmin = () => {
    handlePush(SCR952)
  }

  // 「SCR951_機能管理者の確認画面」に遷移する。
  const goToSubAdminConfirm = () => {
    handlePush(SCR951)
  }

  // 「SCR953_権限メンテナンス画面」に遷移する。
  const goToRolesSetting = () => {
    handlePush(SCR953, true)
  }

  // 「SCR952_機能限定管理者一覧画面」に戻る。
  // 「SCR953_権限メンテナンス画面」に戻る。
  // 「SCR954_権限メンテナンス設定完了画面」に戻る。
  // メニューから遷移してきた場合に前画面に戻る。
  const goBack = () => {
    handlePush(state?.sourceURL || SCR920)
  }

  return {
    goBack,
    goToRolesSetting,
    goToSubAdmin,
    goToSubAdminConfirm,
  }
}
