import { FC } from "react"
import { Alert } from "../../../../../_webui/layout/components/Alert"
import { Button } from "../../../../../_webui/layout/components/Button"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Center } from "../../../../../_webui/layout/components/Center"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { BreakText } from "../../../../../_webui/layout/components/Typography/BreakText"
import { FullName } from "../../../../../_webui/layout/components/Typography/FullName"
import { Translate } from "../../../../../constant/Translate"
import { TravelersEditSuccessCollapse } from "../../components/editSuccess/Collapse"
import { useTravelersEditSuccess } from "./modules/useEditSuccess"

const { TEXT_T097, TEXT_T016, TEXT_T018, TEXT_T098 } = Translate

export const TravelersEditSuccess: FC = () => {
  const {
    breadcrumbItems,
    columns,
    detail,
    fullName,
    goToHome,
    isHiddenBtn,
    isLoadingLayout,
  } = useTravelersEditSuccess()

  return (
    <Layout
      breadcrumbItems={breadcrumbItems}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T097}
    >
      <Card heading={TEXT_T097}>
        <Alert
          type="success"
          content={<BreakText above={TEXT_T098} below={TEXT_T016} />}
        />
        <FullName fullName={fullName} />
        {detail && (
          <TravelersEditSuccessCollapse
            assignmentInfor={detail.assignmentInfor}
            columns={columns}
            contactJapan={detail.contactJapan}
            contactPerson={detail.contactPerson}
            insuranceInfor={detail.insuranceInfor}
            numberOfPeople={detail.numberOfPeople}
            postingInfor={detail.postingInfor}
            relationshipInfo={detail.relationshipInfo}
            spouseInfor={detail.spouseInfor}
            trainingInfor={detail.trainingInfor}
            travelerDetail={detail.travelerDetail}
          />
        )}
        <Center>
          <Button
            isFullWidth
            isHidden={!isHiddenBtn}
            onClick={goToHome}
            text={TEXT_T018}
            type="ghost"
          />
        </Center>
      </Card>
    </Layout>
  )
}
