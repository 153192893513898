/* eslint-disable max-statements */
/* eslint-disable max-lines */
import { File } from "../../../../../../../constant/File"
import { Routers } from "../../../../../../../constant/Routers"
import { Translate } from "../../../../../../../constant/Translate"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { openNotification } from "../../../../../../../utils/openNotification"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { BreakMessage } from "../../../../../../../_webui/layout/components/Typography/BreakMessage"
import { UploadFileType } from "../../../../../../../_webui/layout/components/Upload/models"
import { TravelersFileAddBatchImportType } from "../../../../../travelers/models/batchImport"
import { scheduleApi } from "../../../../apis"
import {
  SchedulesBatchDeleteCheck,
  SchedulesBatchDeleteErrorType,
  UseSchedulesBatchDeleteApiProps,
} from "../../../../models/batchDelete"
import { SchedulesBatchDeleteDataType } from "../../../../models/batchDelete/data"
import { SchedulesBatchImportErrorType } from "../../../../models/batchImport"
import { useVariablesApi } from "../useVariablesApi"
import { convertDataSource } from "./convertDataSource"
import { getFileNameRcFile } from "../../../../../../../utils/getFileNameRcFile"

const { SCR222: SCR222_URL, SCR973 } = Routers
const { MESSAGE_E0022, MESSAGE_E0023, MESSAGE_E0037, MESSAGE_I0014 } = Translate

const {
  FILE_COLUMN_LIMIT_SCHEDULES,
  FILE_COLUMN_LIMIT_SCHEDULES_USER,
  FILE_SIZE_LIMIT_KB,
  FILE_SIZE_LIMIT_MB,
  FILE_TYPE_ALLOW,
  SCR222,
  SCR222_MENU_ID,
  SCR222_USER_MENU_ID,
} = File

export const useApi = (props: UseSchedulesBatchDeleteApiProps) => {
  const { onCancel, onCancelDeleted } = props
  const {
    countRecord,
    dataTemps,
    errorList,
    fileNameList,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isResetTable,
    onChange,
    pathname,
    perItem,
    push,
    setCountRecord,
    setDataTemps,
    setErrorList,
    setFileNameList,
    setIsDisabledSubmit,
    setIsLoading,
    setIsLoadingLayout,
    setIsResetTable,
  } = useVariablesApi()

  const handlePushStateError = (error: AxiosErrorType) => {
    const stateError = pushStateError(error)
    push({ pathname: SCR973, state: stateError })
  }

  const bulkDeleteData = () => {
    // APIを呼び出し、APIからの応答結果を「一時削除応答」変数に設定する。
    scheduleApi
      .bulkDeleteData(SCR222)
      .then(() => {
        // モーダルクローズを実行する
        sessionStorage.removeItem("dataUpload")
        onCancelDeleted()
        onCancel()
        setIsLoadingLayout(false)
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        handlePushStateError(error)
      })
  }

  const bulkCheck = () => {
    // APIを呼び出し、APIからの応答結果を「一括チェック応答」変数に設定する。
    scheduleApi
      .bulkCheck(SCR222)
      .then((bulkCheckTemp: SchedulesBatchDeleteCheck) => {
        const { message } = bulkCheckTemp
        if (message) {
          // 「TBL_TEMP_FOR_UPLOAD」一時テーブルのデータ削除の確認ダイアログを表示する。
          bulkDeleteData()
        } else {
          setIsLoadingLayout(false)
        }
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        handlePushStateError(error)
      })
  }

  const bulkData = () => {
    setIsLoading(true)
    setIsResetTable(true)
    setTimeout(() => {
      // APIを呼び出し、APIからの応答結果を「返却一時データ」変数に設定する。
      scheduleApi
        .bulkData(SCR222)
        .then((dataTempsResponse: SchedulesBatchDeleteDataType[]) => {
          // 「M.dataTemps」の一覧にデータを設定する。
          const _res = convertDataSource(dataTempsResponse)
          sessionStorage.setItem("dataUpload", SCR222)
          setDataTemps(_res)
          setErrorList([])
          onChange(1, 25)
        })
        .catch((error: AxiosErrorType) => {
          // [SCR973_エラー情報の表示画面」に遷移する。
          handlePushStateError(error)
        })
        .finally(() => {
          setIsLoading(false)
          setIsDisabledSubmit(false)
          setIsResetTable(false)
        })
    }, 500)
  }

  const handleSubmitDelete = (
    bulkRes: SchedulesBatchDeleteErrorType,
    message: string,
  ) => {
    // ComponentErrorを表示する。
    if (bulkRes.errors.length) {
      setErrorList(bulkRes.errors)
    }

    //「渡航予定情報の結果件数」コンポーネントの値を表示する。
    if (bulkRes?.execute?.fail === 0) {
      openNotification(message)
      setDataTemps([])
    }

    //「渡航予定情報の結果件数」コンポーネントの値を表示する。
    setCountRecord(bulkRes.execute)
    onCancel()
    setIsLoading(false)
  }

  const bulkDelete = () => {
    const menuId =
      pathname === SCR222_URL ? SCR222_MENU_ID : SCR222_USER_MENU_ID
    setTimeout(() => {
      // APIを呼び出し、APIからの応答結果を「削除応答」変数に設定する。
      scheduleApi
        .bulkDelete(menuId)
        .then((bulkDelete: SchedulesBatchDeleteErrorType) => {
          // バックエンドから戻す「I0014」のメッセージが表示される。
          sessionStorage.removeItem("dataUpload")
          handleSubmitDelete(bulkDelete, MESSAGE_I0014)
          setIsDisabledSubmit(true)
          bulkDeleteData()
        })
        .catch((error: AxiosErrorType) => {
          // 「SCR973_エラー情報の表示画面」に遷移する。
          handlePushStateError(error)
        })
    }, 500)
  }

  const setDataBeforeUploadError = (message: string, validValue: string) => {
    openNotification(<BreakMessage message={message} validValue={validValue} />)
    setDataTemps([])
    setErrorList([])
    setFileNameList([])
  }

  const bulkFileDelete = (fileInfoCSV: UploadFileType) => {
    const formData = new FormData()
    formData.append("file", fileInfoCSV)
    setIsLoading(true)
    setIsResetTable(true)
    // APIを呼び出し、APIからの応答結果を「一時アップロード応答」変数に設定する。
    scheduleApi
      .bulkFileDelete(formData)
      .then((uploadTemp: SchedulesBatchImportErrorType) => {
        // バックエンドから戻す「E0037」のメッセージが表示される。
        onChangeUploadFile({ response: uploadTemp, status: "done" })
        if (getFileNameRcFile(fileInfoCSV)) {
          setFileNameList([fileInfoCSV?.name])
        }
      })
      .catch((error) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        onChangeUploadFile({ response: error, status: "error" })
        setFileNameList([])
      })
      .finally(() => {
        setIsLoading(false)
        setIsResetTable(false)
      })
  }

  const beforeUpload = (
    fileInfoCSV: UploadFileType,
    uploadCallback: (upload: boolean) => void,
  ) => {
    if (typeof fileInfoCSV !== "string") {
      const { size, type } = fileInfoCSV
      const fileSize = size / 1024
      const isValidFileType = type.includes(FILE_TYPE_ALLOW)
      const isValidFileSize = fileSize < FILE_SIZE_LIMIT_KB
      const isValidFileSizeZero = fileSize === 0

      // ファイル拡張子のバリデーションチェックを行う。
      if (!isValidFileType) {
        setDataBeforeUploadError(MESSAGE_E0022, FILE_TYPE_ALLOW)
        uploadCallback(false)
        return false
      }

      // ファイルサイズのバリデーションチェックを行う。
      if (!isValidFileSize) {
        setDataBeforeUploadError(MESSAGE_E0023, String(FILE_SIZE_LIMIT_MB))
        uploadCallback(false)
        return false
      }

      // ファイルの項目個数のバリデーションチェックを行う。
      if (isValidFileSizeZero) {
        setDataBeforeUploadError(
          MESSAGE_E0037,
          String(FILE_COLUMN_LIMIT_SCHEDULES),
        )
        uploadCallback(false)
        return false
      }

      const reader = new FileReader()

      reader.onload = (event: ProgressEvent<FileReader>) => {
        const fileData = String(event.target?.result)
        const columnNames = fileData?.substring(0, fileData.indexOf("\r\n"))
        const fileColumns = columnNames.split(",")
        // ファイルの項目個数のバリデーションチェックを行う。
        const columnLimit =
          pathname === SCR222_URL
            ? FILE_COLUMN_LIMIT_SCHEDULES_USER
            : FILE_COLUMN_LIMIT_SCHEDULES
        const isValidColumns = fileColumns.length === columnLimit
        if (!isValidColumns) {
          setDataBeforeUploadError(MESSAGE_E0037, String(columnLimit))
          uploadCallback(false)
        } else {
          uploadCallback(true)
        }
      }

      reader.readAsText(fileInfoCSV)
    }
  }

  const onChangeUploadFile = (info: TravelersFileAddBatchImportType) => {
    const { status, response } = info
    if (status === "done") {
      if (response.errors?.length) {
        setErrorList(response.errors)
        setIsDisabledSubmit(true)
      } else {
        bulkData()
      }
      setCountRecord(response?.execute)
    } else if (status === "error") {
      setDataBeforeUploadError(
        MESSAGE_E0037,
        String(FILE_COLUMN_LIMIT_SCHEDULES),
      )
      setIsDisabledSubmit(true)
    }
  }

  return {
    beforeUpload,
    bulkCheck,
    bulkDelete,
    bulkDeleteData,
    bulkFileDelete,
    countRecord,
    dataTemps,
    errorList,
    fileNameList,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isResetTable,
    onChange,
    perItem,
    setIsLoading,
  }
}
