import { FC } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { ButtonGroup } from "../../../../../../_webui/layout/components/Button/Group"
import { DetailButtonGroupProps } from "./models"

const { TEXT_T020, TEXT_T034, TEXT_T035, TEXT_T264 } = Translate

export const DetailButtonGroup: FC<DetailButtonGroupProps> = (props) => {
  const {
    goToTravelerDetail,
    isVisibleBtnDelete,
    isVisibleBtnEdit,
    isVisibleBtnRefer,
    isVisibleBtnSearch,
    onEdit,
    onOpen,
    onSearch,
  } = props
  return (
    <ButtonGroup
      buttonItems={[
        {
          isHidden: !isVisibleBtnDelete,
          onClick: onOpen,
          order: 3,
          text: TEXT_T034,
          type: "danger",
        },
        {
          isHidden: !isVisibleBtnEdit || !isVisibleBtnDelete,
          onClick: onEdit,
          order: 1,
          text: TEXT_T020,
          type: "primary",
        },
        {
          isAbsolute: true,
          isHidden: !isVisibleBtnRefer,
          onClick: goToTravelerDetail,
          order: 2,
          text: TEXT_T035,
        },
        {
          absolutePosition: "right",
          isAbsolute: true,
          isHidden: !isVisibleBtnSearch,
          onClick: onSearch,
          order: 4,
          text: TEXT_T264,
        },
      ]}
    />
  )
}
