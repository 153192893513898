import "../../../Select/style/index.scss"
import { FC } from "react"
import { ItemSelectProps } from "./models"
import { FormItem } from ".."
import { Select as SelectAnt } from "antd"
import { Images } from "../../../../../../constant/Images"
import { Image } from "../../../Image"

const { IMAGE_BTN_DELETE } = Images

export const ItemSelect: FC<ItemSelectProps> = (props) => {
  const {
    className,
    defaultValue,
    isBgBlue,
    isDefaultValue = true,
    isDisabled,
    isHiddenLabel,
    label,
    maxLength,
    name,
    onChange,
    placeholder,
    required,
    rules,
    selectItem = [],
    style,
    tooltip,
  } = props

  const items = isDefaultValue
    ? [{ text: "", value: "" }, ...selectItem]
    : selectItem
  const _items = items.map((item) => ({
    disabled: item.disabled,
    label: item.text || "",
    value: item.value,
  }))

  return (
    <FormItem
      className={className}
      isBgBlue={isBgBlue}
      isHiddenLabel={isHiddenLabel}
      label={label}
      name={name}
      required={required}
      rules={rules}
      style={style}
      tooltip={tooltip}
    >
      <SelectAnt
        maxLength={maxLength}
        clearIcon={<Image src={IMAGE_BTN_DELETE} />}
        defaultValue={defaultValue}
        disabled={isDisabled}
        placeholder={placeholder}
        onChange={onChange}
        showSearch
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        options={_items}
      />
    </FormItem>
  )
}
