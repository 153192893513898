/* eslint-disable max-lines */
/* eslint-disable max-statements */
import { Form } from "antd"
import { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { Constant } from "../../../../../../../constant"
import { Routers } from "../../../../../../../constant/Routers"
import { getUserInformation } from "../../../../../../../utils/getUserInformation"
import { useModal } from "../../../../../../../utils/hook/useModal"
import { InitialValues } from "../../../../constants/list/initialValues"
import {
  SchedulesListDataType,
  SchedulesListPostType,
  SchedulesListStateType,
  SchedulesSearchConditionAdvancedType,
  SchedulesSearchConditionBasicType,
  SchedulesSearchConditionType,
} from "../../../../models/list"
import { useRowSelection } from "../useRowSelection"
import { OptionItemType } from "../../../../../../../_webui/layout/components/Select/models"

const { CHECK_SELECTED_LANG } = Constant
const {
  SCHEDULES_ADVANCED_SEARCH_INITIAL_VALUES,
  SCHEDULES_BASIC_SEARCH_INITIAL_VALUES,
} = InitialValues
const { SCR210_ATTENTIONS, SCR210_SAFETIES, SCR210 } = Routers

export const useVariablesApi = (isRoleSubAdmin: boolean) => {
  const [form] = Form.useForm()
  const [formAdvanced] = Form.useForm()
  const { state, pathname } = useLocation<SchedulesListStateType>()
  const { push } = useHistory()
  const [isLoadingTable, setIsLoadingTable] = useState(false)
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [isOverseasResident, setIsOverseasResident] = useState(false)
  const [isResetTable, setIsResetTable] = useState(false)
  const [countries, setCountries] = useState<OptionItemType[]>([])
  const [purposeTravel, setPurposeTravel] = useState<OptionItemType[]>([])
  const [searchCondition, setSearchCondition] = useState<
    SchedulesSearchConditionType | undefined
  >(state?.searchCondition)
  const [isSearchBasic, setIsSearchBasic] = useState(state?.isSearchBasic)
  const [searchConditionAdvanced, setSearchConditionAdvanced] =
    useState<SchedulesSearchConditionAdvancedType>(
      SCHEDULES_ADVANCED_SEARCH_INITIAL_VALUES,
    )
  const [searchConditionBasic, setSearchConditionBasic] =
    useState<SchedulesSearchConditionBasicType>(
      SCHEDULES_BASIC_SEARCH_INITIAL_VALUES,
    )
  const [isSearchAdvance, setIsSearchAdvance] = useState(false)
  const [dataSource, setDataSource] = useState<SchedulesListDataType[]>([])
  const [dataTemp, setDataTemp] = useState<SchedulesListDataType[]>([])
  const [dataSubmit, setDataSubmit] = useState<SchedulesListPostType>()
  const [travelerId, setTravelerId] = useState("")
  const [overRecordLabel, setOverRecordLabel] = useState("")
  const [departments, setDepartments] = useState<OptionItemType[]>([])

  const { isOpen, onCancel, onOpen } = useModal()
  const {
    isOpen: isOpenLoadData,
    onCancel: onCancelLoadData,
    onOpen: onOpenLoadData,
  } = useModal()
  const {
    isOpen: isOpenShowData,
    onCancel: onCancelShowData,
    onOpen: onOpenShowData,
  } = useModal()
  const userInformation = getUserInformation()
  const isPathnameSchedules = pathname === SCR210
  const isPathnameAttentionsOrSafeties =
    pathname === SCR210_ATTENTIONS || pathname === SCR210_SAFETIES

  const {
    dataSourceSelected,
    handleSetDefaultSortedInfo,
    isDisabledBtnCreateOrSend,
    isDisabledBtnDelete,
    isDisabledBtnRefer,
    onChange,
    onChangeTable,
    perItem,
    rowSelection,
    selectedRowKeys,
    sortedInfo,
    travelScheduleId,
  } = useRowSelection(dataSource, isRoleSubAdmin)

  const fullName = CHECK_SELECTED_LANG
    ? userInformation?.fullNameKanji || userInformation?.fullNameRomaji
    : userInformation?.fullNameRomaji

  return {
    countries,
    dataSource,
    dataSourceSelected,
    dataSubmit,
    dataTemp,
    departments,
    form,
    formAdvanced,
    fullName,
    handleSetDefaultSortedInfo,
    isDisabledBtnCreateOrSend,
    isDisabledBtnDelete,
    isDisabledBtnRefer,
    isLoadingLayout,
    isLoadingTable,
    isOpen,
    isOpenLoadData,
    isOpenShowData,
    isOverseasResident,
    isPathnameAttentionsOrSafeties,
    isPathnameSchedules,
    isResetTable,
    isSearchAdvance,
    isSearchBasic,
    onCancel,
    onCancelLoadData,
    onCancelShowData,
    onChange,
    onChangeTable,
    onOpen,
    onOpenLoadData,
    onOpenShowData,
    overRecordLabel,
    pathname,
    perItem,
    purposeTravel,
    push,
    rowSelection,
    searchCondition,
    searchConditionAdvanced,
    searchConditionBasic,
    selectedRowKeys,
    setCountries,
    setDataSource,
    setDataSubmit,
    setDataTemp,
    setDepartments,
    setIsLoadingLayout,
    setIsLoadingTable,
    setIsOverseasResident,
    setIsResetTable,
    setIsSearchAdvance,
    setIsSearchBasic,
    setOverRecordLabel,
    setPurposeTravel,
    setSearchCondition,
    setSearchConditionAdvanced,
    setSearchConditionBasic,
    setTravelerId,
    sortedInfo,
    state,
    travelScheduleId,
    travelerId,
    userInformation,
  }
}
