import { getDateRange } from "../../../../../../../../utils/getDate"
import {
  SafetiesListSearchCondition,
  SafetiesListSearchFormCondition,
} from "../../../../../models/list"

export const convertDataSearch = (
  data: SafetiesListSearchFormCondition,
): SafetiesListSearchCondition => ({
  ...data,
  categoryId: data?.categoryId,
  implementationDate:
    data?.implementationDate &&
    getDateRange(data.implementationDate).toString(),
  safetyTitle: data?.safetyTitle,
  statusId: data?.statusId,
})
