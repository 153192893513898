import { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import {
  AttentionInfoType,
  AttentionTravelersDataType,
} from "../../../../models/attention"
import { AttentionSuccessProps } from "../../../../models/success"
import { useTableConfig } from "../useTableConfig"
import { useVariablesAuthority } from "../useVariablesAuthority"

export const useVariables = () => {
  const { state } = useLocation<AttentionSuccessProps>()
  const { push } = useHistory()
  const [travelerInfoList, setTravelerInfoList] = useState<
    AttentionTravelersDataType[]
  >([])
  const [transmissionInfo, setTransmissionInfo] = useState<AttentionInfoType>()
  const { columns } = useTableConfig()
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const { isAttentionsSendAll, isCommonRefer } = useVariablesAuthority()

  return {
    columns,
    isAttentionsSendAll,
    isCommonRefer,
    isLoadingLayout,
    push,
    safetiesSelected: state.safetiesSelected,
    setIsLoadingLayout,
    setTransmissionInfo,
    setTravelerInfoList,
    state,
    transmissionInfo,
    travelerInfoList,
  }
}
