import { FC } from "react"
import { Table } from "../../../../../../../_webui/layout/components/Table"
import { Tabs } from "../../../../../../../_webui/layout/components/Tabs"
import { Regex } from "../../../../../../../constant/Regex"
import { SchedulesAddSuccessStayProps } from "./models"

const { REGEX_IS_DESKTOP } = Regex

export const SchedulesAddSuccessStay: FC<SchedulesAddSuccessStayProps> = (
  props,
) => {
  const { scheduleStays, staysColumns, staysTabsItems } = props

  return REGEX_IS_DESKTOP ? (
    <Table
      columns={staysColumns}
      dataSource={scheduleStays}
      pagination={false}
      rowKey="travelStayId"
    />
  ) : (
    <Tabs defaultActiveKey={"1"} items={staysTabsItems} />
  )
}
