/* eslint-disable max-lines */
import { useEffect } from "react"
import { Routers } from "../../../../../../../constant/Routers"
import { Translate } from "../../../../../../../constant/Translate"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { getDay } from "../../../../../../../utils/getDate"
import { getValueByLanguage } from "../../../../../../../utils/getValueByLanguage"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { replaceStringParam } from "../../../../../../../utils/replaceStringParam"
import { scheduleApi } from "../../../../apis"
import {
  SchedulesConfirmDeleteDataType,
  UseSchedulesStateType,
} from "../../../../models/confirmDelete"
import { useVariables } from "../useVariables"
import { convertDataSource } from "./convertDataSource"

const { SCR217, SCR973 } = Routers
const { TEXT_T085, TEXT_T088 } = Translate

export const useSchedulesConfirmDelete =
  (): UseSchedulesStateType<SchedulesConfirmDeleteDataType> => {
    const {
      breadcrumbItems,
      columns,
      errorItems,
      isLoading,
      isLoadingLayout,
      isNotAccessScreen,
      isOpen,
      isRoleAdmin,
      isRoleSubAdmin,
      isRoleUser,
      isVisibleBtnCancel,
      isVisibleBtnYes,
      onCancel,
      onOpen,
      push,
      scheduleList,
      setErrorItems,
      setIsLoading,
      setIsLoadingLayout,
      setScheduleList,
      state,
    } = useVariables()

    const goBack = () => {
      // 「SCR210_渡航予定情報の一覧画面」に遷移する。
      push({ pathname: state.sourceURL, state })
    }

    // エラーメッセージを 「SCR216_渡航予定情報の削除確認画面」に表示する。
    const handleGetErrorItems = (data: string[]) => {
      scheduleList.forEach((item_1) => {
        data.forEach((item_2) => {
          if (item_1.travelScheduleId === item_2) {
            if (isRoleAdmin || isRoleSubAdmin)
              setErrorItems((prev) => [
                ...prev,
                {
                  message: `・${replaceStringParam(
                    TEXT_T088,
                    getValueByLanguage(
                      item_1.fullNameKanji,
                      item_1.fullNameRomaji,
                    ),
                    getDay(item_1.travelStartDate),
                    getDay(item_1.travelEndDate),
                  )}`,
                },
              ])
            else if (isRoleUser)
              setErrorItems((prev) => [
                ...prev,
                {
                  message: `・${replaceStringParam(
                    TEXT_T085,
                    getDay(item_1.travelStartDate),
                    getDay(item_1.travelEndDate),
                  )}`,
                },
              ])
          }
        })
      })
    }

    const onOk = () => {
      if (state?.scheduleInfo) {
        setIsLoading(true)
        const scheduleInfoIds = state.scheduleInfo.map(
          (item) => item.travelScheduleId,
        )

        // 渡航予定情報を削除する。
        scheduleApi
          .deleteSchedules(scheduleInfoIds)
          .then((res: string[]) => {
            if (res?.length) {
              onCancel()
              setErrorItems([])
              handleGetErrorItems(res)
              setIsLoading(false)
            }
            //「SCR217_渡航予定情報の削除後画面」に遷移する。
            else push(SCR217, { ...state, isFromSCR216: true })
          })
          .catch((error: AxiosErrorType) => {
            // 「SCR973_エラー情報の表示画面」に遷移する。
            const stateError = pushStateError(error)
            push({ pathname: SCR973, state: stateError })
          })
      }
    }

    useEffect(() => {
      // ユーザーの画面アクセス許可チェックを処理する。
      if (isNotAccessScreen) {
        const { pathname, state } = backToLogin()
        push({ pathname, state })
      } else if (state?.scheduleInfo) {
        const data = convertDataSource(state.scheduleInfo)
        setScheduleList(data)
        setTimeout(() => {
          setIsLoadingLayout(false)
        }, 500)
      }
    }, [])

    return {
      breadcrumbItems,
      columns,
      errorItems,
      goBack,
      isLoading,
      isLoadingLayout,
      isOpen,
      isVisibleBtnCancel,
      isVisibleBtnYes,
      onCancel,
      onOk,
      onOpen,
      scheduleList,
    }
  }
