const {
  NODE_ENV,
  REACT_APP_ATTENTION_URL,
  REACT_APP_BATCH_URL,
  REACT_APP_COMMON_URL,
  REACT_APP_DATA_KEY,
  REACT_APP_I18N_CONFIG_KEY,
  REACT_APP_KEYCLOAK_CLIENT,
  REACT_APP_KEYCLOAK_ONLOAD,
  REACT_APP_KEYCLOAK_PKCEMETHOD,
  REACT_APP_KEYCLOAK_REALM,
  REACT_APP_KEYCLOAK_URL,
  REACT_APP_NAME,
  REACT_APP_SAFETY_URL,
  REACT_APP_SCHEDULE_URL,
  REACT_APP_TRAVELER_URL,
  REACT_APP_URL,
  REACT_APP_VERSION,
} = process.env

export const Environment = {
  APP_NAME: REACT_APP_NAME,
  APP_VERSION: REACT_APP_VERSION,
  ATTENTION_ENDPOINT: REACT_APP_ATTENTION_URL,
  BATCH_ENDPOINT: REACT_APP_BATCH_URL,
  COMMON_ENDPOINT: REACT_APP_COMMON_URL,
  DATA_KEY: REACT_APP_DATA_KEY,
  I18N_CONFIG: {
    KEY: REACT_APP_I18N_CONFIG_KEY,
  },
  KEYCLOAK: {
    CLIENT_ID: REACT_APP_KEYCLOAK_CLIENT,
    ON_LOAD: REACT_APP_KEYCLOAK_ONLOAD,
    PKCE_METHOD: REACT_APP_KEYCLOAK_PKCEMETHOD,
    REALM: REACT_APP_KEYCLOAK_REALM,
    URL: REACT_APP_KEYCLOAK_URL,
  },
  PRODUCTION: NODE_ENV === "production",
  SAFETY_ENDPOINT: REACT_APP_SAFETY_URL,
  SCHEDULE_ENDPOINT: REACT_APP_SCHEDULE_URL,
  TRAVELER_ENDPOINT: REACT_APP_TRAVELER_URL,
  URL: REACT_APP_URL,
}
