import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Constant } from "../../../constant"
import { Routers } from "../../../constant/Routers"
import { departmentsApi } from "../../../infrastructure/handlers/departments"
import { DepartmentsResType } from "../../../infrastructure/handlers/departments/models"
import { AxiosErrorType } from "../../../infrastructure/axiosError/models"
import { OptionItemType } from "../../../_webui/layout/components/Select/models"
import { DepartmentsType } from "./models"
import { pushStateError } from "../../pushStateError"

const { SCR973 } = Routers
const { CHECK_SELECTED_LANG } = Constant

export const getDepartments = (): DepartmentsType => {
  const { push } = useHistory()
  const [departments, setDepartments] = useState<OptionItemType[]>([])

  useEffect(() => {
    departmentsApi
      .getDepartments()
      .then((res: DepartmentsResType[]) => {
        const optionItem: OptionItemType[] = res.map((item) => ({
          text: CHECK_SELECTED_LANG
            ? item.departmentNameJapan
            : item.departmentNameEnglish,
          value: item.bumonCd,
        }))
        setDepartments(optionItem)
      })
      .catch((error: AxiosErrorType) => {
        const state = pushStateError(error)
        push({ pathname: SCR973, state })
      })
  }, [])

  return {
    departments,
  }
}
