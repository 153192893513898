import { Translate } from "../../../../../../../constant/Translate"
import { replaceStringParam } from "../../../../../../../utils/replaceStringParam"
import { FormatterProps } from "../models"

const { TEXT_T557 } = Translate

export const formatter = (args: FormatterProps) => {
  if (args.maxLength) {
    const count = args.maxLength - args.count
    return replaceStringParam(TEXT_T557, count >= 0 ? count : 0)
  }
  return ""
}
