import { FC } from "react"
import { Translate } from "../../../../../../../../constant/Translate"
import { ScheduleInfoStaysType } from "../../../../../models/add"
import { SchedulesAddTable } from "../../../Table"
import { SchedulesAddStayProps } from "./models"

const { TEXT_T286, TEXT_T287 } = Translate

export const SchedulesAddStay: FC<SchedulesAddStayProps> = (props) => {
  const {
    handleAddStayItem,
    handleDeleteStayItem,
    isTableLoading,
    isVisibleBtn,
    onClickStayTab,
    stayColumns,
    stayDataSource,
    stayIndexTabActive,
    stayRowSelection,
    staySelectedRowKeys,
    stayTabsItems,
  } = props

  return (
    <SchedulesAddTable
      activeKey={stayIndexTabActive}
      addText={TEXT_T286}
      columns={stayColumns}
      dataSource={stayDataSource}
      dataTableSelected={staySelectedRowKeys}
      deleteText={TEXT_T287}
      handleAdd={handleAddStayItem}
      handleDelete={handleDeleteStayItem}
      id="table-schedules-add-stay"
      isLoading={isTableLoading}
      isVisibleBtn={isVisibleBtn}
      items={stayTabsItems}
      onTabClick={onClickStayTab}
      rowKey={(record: ScheduleInfoStaysType) => record.travelStayId}
      rowSelection={stayRowSelection}
    />
  )
}
