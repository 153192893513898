const TRAVELER_REGISTER = "SUF0000001"
const TRAVELER_CHANGE = "SUF0000002"
const TRAVELER_REFER = "SUF0000003"
const TRAVELER_DELETE = "SUF0000004"
const TRAVELER_SEARCH = "SUF0000005"
const TRAVELER_REPORT = "SUF0000006"
const SCHEDULE_REGISTER = "SUF0000007"
const SCHEDULE_CHANGE = "SUF0000008"
const SCHEDULE_REFER = "SUF0000009"
const SCHEDULE_DELETE = "SUF0000010"
const SCHEDULE_SEARCH = "SUF0000011"
const SCHEDULE_REPORT = "SUF0000012"
const ATTENTIONS_TARGET_SEARCH = "SUF0000013"
const ATTENTIONS_SEND_ALL = "SUF0000014"
const ATTENTIONS_BROWSE_RESPONSE = "SUF0000015"
const ATTENTIONS_REPORT = "SUF0000016"
const SAFETIES_TARGET_SEARCH = "SUF0000017"
const SAFETIES_SEND_ALL = "SUF0000018"
const SAFETIES_BROWSE_RESPONSE = "SUF0000019"
const SAFETIES_REPORT = "SUF0000020"
const COMMON_TRAVELER_REFER = "SUF0000021"
const COMMON_PERMISSION_SETTINGS = "SUF0000022"
const COMMON_UPLOAD_DELETE = "SUF0000023"
const COMMON_UPLOAD_REFER = "SUF0000024"
const COMMON_UPLOAD_REGISTER = "SUF0000025"
const COMMON_REFER = "SUF0000026"
const COMMON_PERMISSION_SETTING = "SUF0000027"
const COMMON_EXTERNAL_SYSTEM_LINKAGE = "SUF0000028"
const ROLE_ADMIN = "ROLE_ADMIN"
const ROLE_SUBADMIN = "ROLE_SUBADMIN"
const ROLE_USER = "ROLE_USER"

export const SubFunctionId = {
  ATTENTIONS_BROWSE_RESPONSE,
  ATTENTIONS_REPORT,
  ATTENTIONS_SEND_ALL,
  ATTENTIONS_TARGET_SEARCH,
  COMMON_EXTERNAL_SYSTEM_LINKAGE,
  COMMON_PERMISSION_SETTING,
  COMMON_PERMISSION_SETTINGS,
  COMMON_REFER,
  COMMON_TRAVELER_REFER,
  COMMON_UPLOAD_DELETE,
  COMMON_UPLOAD_REFER,
  COMMON_UPLOAD_REGISTER,
  ROLE_ADMIN,
  ROLE_SUBADMIN,
  ROLE_USER,
  SAFETIES_BROWSE_RESPONSE,
  SAFETIES_REPORT,
  SAFETIES_SEND_ALL,
  SAFETIES_TARGET_SEARCH,
  SCHEDULE_CHANGE,
  SCHEDULE_DELETE,
  SCHEDULE_REFER,
  SCHEDULE_REGISTER,
  SCHEDULE_REPORT,
  SCHEDULE_SEARCH,
  TRAVELER_CHANGE,
  TRAVELER_DELETE,
  TRAVELER_REFER,
  TRAVELER_REGISTER,
  TRAVELER_REPORT,
  TRAVELER_SEARCH,
}
