/* eslint-disable max-lines */
import { FC } from "react"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { Upload } from "../../../../../_webui/layout/components/Upload"
import { Breadcrumbs } from "../../../../../constant/Breadcrumbs"
import { File } from "../../../../../constant/File"
import { Translate } from "../../../../../constant/Translate"
import { TravelersBatchImportButtonSubmit } from "../../components/batchImport/ButtonSubmit"
import { TravelersBatchImportModalConfirm } from "../../components/batchImport/ModalConfirm"
import { TravelersBatchImportTable } from "../../components/batchImport/Table"
import { useTravelersBatchImport } from "./modules/useBatchImport"
import { ItemInput } from "../../../../../_webui/layout/components/Form/Item/Input"
import { validatorEmailAddress } from "../../../../../utils/validator/emailAddress"
import { Form } from "../../../../../_webui/layout/components/Form"
import { Styled } from "./styled"

const { TEXT_T114, TEXT_T278 } = Translate
const { BRCR_SCR122 } = Breadcrumbs
const { FILE_TYPE_ALLOW_UPLOAD_ACCEPT_ALL } = File
const { StyledContainer } = Styled

export const TravelersBatchImport: FC = () => {
  const {
    beforeUpload,
    bulkAdd,
    bulkDeleteDataConfirm,
    bulkFileAdd,
    columnErrors,
    columns,
    countRecord,
    dataTemps,
    errorList,
    fileNameList,
    form,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenUploaded,
    isResetTable,
    isVisibleBtnCancel,
    isVisibleBtnRegister,
    onCancel,
    onCancelUploaded,
    onChange,
    onOpen,
    onOpenDeletedOrGoToHome,
    perItem,
  } = useTravelersBatchImport()

  return (
    <Layout
      breadcrumbItems={BRCR_SCR122}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T114}
    >
      <Card heading={TEXT_T114} isLoading={isLoading}>
        <StyledContainer>
          <Form form={form} isCard={false} isHeading={false}>
            <ItemInput
              label={TEXT_T278}
              maxLength={255}
              name="registerRequestUserId"
              rules={[validatorEmailAddress]}
            />
          </Form>
          <Upload
            accept={FILE_TYPE_ALLOW_UPLOAD_ACCEPT_ALL}
            customRequest={({ file }) =>
              beforeUpload(file, (upload: boolean) => {
                if (upload) {
                  bulkFileAdd(file)
                }
              })
            }
            fileNameList={fileNameList}
          />
          <TravelersBatchImportTable
            columnErrors={columnErrors}
            columns={columns}
            countRecord={countRecord}
            dataTemps={dataTemps}
            errorList={errorList}
            isResetTable={isResetTable}
            onChange={onChange}
            perItem={perItem}
          />
          <TravelersBatchImportButtonSubmit
            isDisabledSubmit={isDisabledSubmit}
            isVisibleBtnCancel={isVisibleBtnCancel}
            isVisibleBtnRegister={isVisibleBtnRegister}
            onOpen={onOpen}
            onOpenDeletedOrGoToHome={onOpenDeletedOrGoToHome}
          />
          <TravelersBatchImportModalConfirm
            bulkAdd={bulkAdd}
            bulkDeleteDataConfirm={bulkDeleteDataConfirm}
            isOpen={isOpen}
            isOpenUploaded={isOpenUploaded}
            onCancel={onCancel}
            onCancelUploaded={onCancelUploaded}
          />
        </StyledContainer>
      </Card>
    </Layout>
  )
}
