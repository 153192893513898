/* eslint-disable complexity */
import { format as formatDate } from "../../../_webui/layout/components/Form/Item/DatePicker/constants"
import { format as formatTime } from "../../../_webui/layout/components/Form/Item/TimePicker/constants"
import { replaceStringParam } from "../../replaceStringParam"
import { Translate } from "../../../constant/Translate"
import moment from "moment"
import { ValidatorDatetimeInputProps } from "./models"

const { MESSAGE_E0143, MESSAGE_E0001 } = Translate

const isDate = (name: string) => {
  const _name = name.toLowerCase()
  return (
    _name.includes("date") ||
    _name.includes("lodgingcheckin") ||
    _name.includes("lodgingcheckout")
  )
}

export const validatorDatetimeInput = (props: ValidatorDatetimeInputProps) => {
  const { form, label, name, onValuesChange, value, isRequired } = props
  if (value) {
    const format = isDate(name) ? formatDate : formatTime
    const _value = moment(value, format)
    if (_value.isValid()) {
      form.setFields([
        {
          errors: [],
          name: name,
          value: moment(value, format),
        },
      ])
      form.validateFields([name])
    } else {
      form.setFields([
        {
          errors: [replaceStringParam(MESSAGE_E0143, label)],
          name: name,
          value: "",
        },
      ])
    }
  } else {
    form.setFields([
      {
        errors: isRequired ? [MESSAGE_E0001] : [],
        name: name,
        value: "",
      },
    ])
  }
  if (typeof onValuesChange === "function") onValuesChange()
}
