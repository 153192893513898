import { I18nCodeExportFile } from "./ExportFile"
import { I18nCodeHelp } from "./Help"
import { I18nCodeMessage } from "./Message"
import { I18nCodeText } from "./Text"

export const I18nCode = {
  ...I18nCodeExportFile,
  ...I18nCodeHelp,
  ...I18nCodeMessage,
  ...I18nCodeText,
}
