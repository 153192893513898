import { useHistory, useLocation } from "react-router-dom"
import { SafetiesAnswerSuccessBackType } from "../../../../models/answerSuccess"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { useState } from "react"
import { getBreadcrumbItems } from "./getBreadcrumbItems"

export const useVariables = () => {
  const { push } = useHistory()
  const { state } = useLocation<SafetiesAnswerSuccessBackType>()
  const {
    isFromEmail,
    isNotAccessScreen,
    isVisibleBtnDetail,
    isVisibleBtnHome,
    isVisibleBtnList,
  } = useVariablesAuthority(state?.sourceURL)
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const breadcrumbItems = getBreadcrumbItems(
    state?.isFromSCR416,
    state?.isFromSCR416History,
    state?.isFromSCR417,
  )

  return {
    breadcrumbItems,
    isFromEmail,
    isLoadingLayout,
    isNotAccessScreen,
    isVisibleBtnDetail,
    isVisibleBtnHome,
    isVisibleBtnList,
    push,
    setIsLoadingLayout,
    state,
  }
}
