/* eslint-disable complexity */
import { FC } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { ButtonGroup } from "../../../../../../_webui/layout/components/Button/Group"
import { TravelersRequestButtonGroupProps } from "./models"

const { TEXT_T181, TEXT_T182, TEXT_T185, TEXT_T186 } = Translate

export const TravelersRequestButtonGroup: FC<
  TravelersRequestButtonGroupProps
> = (props) => {
  const {
    activeKey,
    isDisabledButton,
    isVisibleBtnRoleAdmin,
    isVisibleBtnRoleSubAdmin,
    onOpenAccept,
    onOpenReActive,
    onOpenReEnable,
    onOpenRequestCancel,
    selectedRowKeys,
  } = props

  const _isDisabledButton = isDisabledButton || !selectedRowKeys?.length
  const isDisabledBtnAccept =
    _isDisabledButton || !(activeKey === "deletionRequest")
  const isDisabledBtnReEnable =
    _isDisabledButton || activeKey === "deletionRequest"

  return isVisibleBtnRoleAdmin ? (
    <ButtonGroup
      buttonItems={[
        {
          isDisabled: isDisabledBtnAccept,
          // 渡航者情報削除依頼承認の確認ダイアログを表示する。
          onClick: onOpenAccept,
          text: TEXT_T181,
          type: "primary",
        },
        {
          isDisabled: isDisabledBtnReEnable,
          // 渡航者のアカウント復活の確認ダイアログを表示する。
          onClick: onOpenReEnable,
          text: TEXT_T182,
          type: "primary",
        },
      ]}
    />
  ) : isVisibleBtnRoleSubAdmin ? (
    <ButtonGroup
      buttonItems={[
        {
          isDisabled: isDisabledBtnAccept,
          // 渡航者情報の削除依頼取り消しの確認ダイアログを表示する。
          onClick: onOpenRequestCancel,
          text: TEXT_T185,
          type: "primary",
        },
        {
          isDisabled: isDisabledBtnReEnable,
          // 渡航者情報の再有効化依頼の確認ダイアログを表示する。
          onClick: onOpenReActive,
          text: TEXT_T186,
          type: "primary",
        },
      ]}
    />
  ) : null
}
