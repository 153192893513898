/* eslint-disable complexity */
import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const { isRoleAdmin, isRoleSubAdmin, isTravelerDelete } = useAuthority()

  const isNotAccessScreen = !isTravelerDelete

  const isVisibleBtn = (isRoleAdmin || isRoleSubAdmin) && isTravelerDelete

  const isVisibleRoleAdmin = isRoleAdmin && isTravelerDelete

  const isVisibleTabRoleAdmin = isVisibleRoleAdmin

  const isVisibleBtnRoleAdmin = isVisibleRoleAdmin

  const isVisibleRoleSubAdmin = isRoleSubAdmin && isTravelerDelete

  const isVisibleTabRoleSubAdmin = isVisibleRoleSubAdmin

  const isVisibleBtnRoleSubAdmin = isVisibleRoleSubAdmin

  return {
    isNotAccessScreen,
    isVisibleBtn,
    isVisibleBtnRoleAdmin,
    isVisibleBtnRoleSubAdmin,
    isVisibleTabRoleAdmin,
    isVisibleTabRoleSubAdmin,
  }
}
