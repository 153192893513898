import { FormInstance } from "antd"
import { AnyValue } from "../../../../../../../../_webui/layout/models"
import { TravelersEditAccompaniesType } from "../../../../../models/edit"

export const setFieldsValueAccompanies = (
  form: FormInstance<AnyValue>,
  accompanies?: TravelersEditAccompaniesType[],
) => {
  if (accompanies) {
    for (let index = 0; index < accompanies.length; index++) {
      form.setFieldsValue({
        [`familyRomajiSurName_${index}`]:
          accompanies[index].familyRomajiSurname,
        [`familyRomajiName_${index}`]: accompanies[index].familyRomajiName,
        [`familyRelationship_${index}`]: accompanies[index].familyRelationship,
      })
    }
  }
}
