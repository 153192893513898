import { FC, Fragment } from "react"
import { ItemInput } from "../../../../../../../../_webui/layout/components/Form/Item/Input"
import { Translate } from "../../../../../../../../constant/Translate"

const { TEXT_T199, TEXT_T200, TEXT_T201 } = Translate

export const CollapseAssignment: FC = () => (
  <Fragment>
    <ItemInput label={TEXT_T199} maxLength={200} name="projectNameJp" />
    <ItemInput label={TEXT_T200} maxLength={200} name="projectNameEn" />
    <ItemInput
      label={TEXT_T201}
      maxLength={50}
      name="procurementControlNumber"
    />
  </Fragment>
)
