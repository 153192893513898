import { useState } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { UseParamsType } from "../../../../../../../utils/useParams"
import {
  TravelersDetailCollapseType,
  TravelersDetailResType,
  TravelersDetailStateType,
} from "../../../../models/detail"

export const useVariablesApi = () => {
  const { id } = useParams<UseParamsType>()
  const { state } = useLocation<TravelersDetailStateType>()
  const { push } = useHistory()
  const [travelerInfoJson, setTravelerInfoJson] =
    useState<TravelersDetailResType>()
  const [travelerInfo, setTravelerInfo] =
    useState<TravelersDetailCollapseType>()
  const [isSystemIdentifier, setIsSystemIdentifier] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [fullNameRomaji, setFullNameRomaji] = useState("")

  return {
    fullNameRomaji,
    id,
    isLoading,
    isLoadingLayout,
    isSystemIdentifier,
    push,
    setFullNameRomaji,
    setIsLoading,
    setIsLoadingLayout,
    setIsSystemIdentifier,
    setTravelerInfo,
    setTravelerInfoJson,
    state,
    travelerInfo,
    travelerInfoJson,
  }
}
