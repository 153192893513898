import { getFullName } from "../../../../../../utils/getFullName"
import { getValueByLanguage } from "../../../../../../utils/getValueByLanguage"
import { replaceDateFormat } from "../../../../../../utils/replaceDateFormat"
import { MyLoginResType } from "../../../models"

export const setUserInformation = (data: MyLoginResType) => {
  const fullNameKanji = getFullName(data.kanjiSurname, data.kanjiName, true)
  const fullNameRomaji = getFullName(data.romajiSurname, data.romajiName)
  const fullName = getValueByLanguage(
    fullNameKanji || fullNameRomaji,
    fullNameRomaji,
  )
  localStorage.setItem(
    "userInformation",
    JSON.stringify({
      authorityType: data.authorityType,
      bumonCd: data.bumonCd,
      firstLogin: data.firstLogin,
      fullName: fullName,
      fullNameKanji: fullNameKanji,
      fullNameRomaji: fullNameRomaji,
      isSummerTime: data.isSummerTime,
      languageNotation: data.languageNotation,
      othersStaffNumber: data.othersStaffNumber,
      summerTimeData: data.summerTimeData,
      timeFormatId: data.timeFormatId,
      timeFormatValue: replaceDateFormat(data.timeFormatValue),
      timeFormatValueEN: replaceDateFormat(data.timeFormatValueEN),
      timeFormatValueJP: replaceDateFormat(data.timeFormatValueJP),
      timeZoneId: data.timeZoneId,
      timeZoneValue: data.timeZoneValue,
      travelerId: data.travelerId,
      userId: data.userId,
    }),
  )
}
