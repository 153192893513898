/* eslint-disable max-lines */
import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../_webui/layout/components/Table/models"
import { AttentionsDetailDataSourceType } from "../../../../models/detail"
import { Translate } from "../../../../../../../constant/Translate"
import { BreakText } from "../../../../../../../_webui/layout/components/Typography/BreakText"
import { Tag } from "../../../../../../../_webui/layout/components/Tag"
import { getFullName } from "../../../../../../../utils/getFullName"
import { getDateTimeZoneByUserInformation } from "../../../../../../../utils/getDate"
import { getScheduleDeleted } from "../../../../../../../utils/getScheduleDeleted"

const {
  TEXT_T003,
  TEXT_T006,
  TEXT_T025,
  TEXT_T047,
  TEXT_T221,
  TEXT_T339,
  TEXT_T340,
  TEXT_T341,
  TEXT_T344,
  TEXT_T348,
  TEXT_T389,
} = Translate

export const useTableConfig =
  (): UseTableConfigType<AttentionsDetailDataSourceType> => {
    const columns: ColumnType<AttentionsDetailDataSourceType>[] = [
      {
        dataIndex: "responseOption",
        render: (_, data) =>
          data.responseOption === "0" ? (
            <Tag text={TEXT_T389} type="error" isFullWidth />
          ) : data.responseOption === "1" ? (
            <Tag text={TEXT_T344} type="success" isFullWidth />
          ) : (
            <Tag text={TEXT_T348} type="stop" isFullWidth />
          ),
        sorter: (a, b) => a.responseOption.localeCompare(b.responseOption),
        title: TEXT_T339,
        width: 180,
      },
      {
        dataIndex: "proxyResponser",
        sorter: (a, b) => a.proxyResponser.localeCompare(b.proxyResponser),
        title: TEXT_T340,
        width: 200,
      },
      {
        dataIndex: "responseDate",
        render: (_, data) =>
          getDateTimeZoneByUserInformation(data.responseDate),
        sorter: (a, b) => a.responseDate.localeCompare(b.responseDate),
        title: TEXT_T341,
        width: 220,
      },
      {
        dataIndex: "fullName",
        render: (_, data) => (
          <BreakText
            above={getFullName(data.kanjiSurname, data.kanjiName, true)}
            below={getFullName(data.romajiSurname, data.romajiName)}
          />
        ),
        sorter: (a, b) =>
          getFullName(a.romajiSurname, a.romajiName).localeCompare(
            getFullName(b.romajiSurname, b.romajiName),
          ),
        title: <BreakText above={TEXT_T003} below={TEXT_T006} />,
        width: 240,
      },
      {
        dataIndex: "locationPhoneNumber1",
        render: (_, data) =>
          getScheduleDeleted(data.isScheduleDeleted, data.locationPhoneNumber1),
        sorter: (a, b) =>
          a.locationPhoneNumber1?.localeCompare(b.locationPhoneNumber1),
        title: TEXT_T047,
        width: 300,
      },
      {
        dataIndex: "dispatchTypeDetailName",
        sorter: (a, b) =>
          a.dispatchTypeDetailName.localeCompare(b.dispatchTypeDetailName),
        title: TEXT_T025,
        width: 260,
      },
      {
        dataIndex: "workPlace",
        sorter: (a, b) => a.workPlace.localeCompare(b.workPlace),
        title: TEXT_T221,
        width: 300,
      },
    ]
    return {
      columns,
    }
  }
