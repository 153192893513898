import { FC } from "react"
import { Switch } from "react-router-dom"
import PrivateRoute from "../../../../app/routing/privateRoute"
import { Routers } from "../../../../constant/Routers"
import { FilesUploadPage } from "../../../../app/pages/external/filesUpload"
import { UploadResultsPage } from "../../../../app/pages/external/uploadResults"

const { SCR980, SCR981 } = Routers

export const ExternalRoute: FC = () => (
  <Switch>
    <PrivateRoute component={FilesUploadPage} exact path={SCR980} />
    <PrivateRoute component={UploadResultsPage} exact path={SCR981} />
  </Switch>
)
