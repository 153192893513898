import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const { isRoleAdmin, isRoleSubAdmin, isTravelerDelete, isTravelerSearch } =
    useAuthority()

  const isHiddenBtn = isRoleAdmin || isRoleSubAdmin
  const isHiddenBtnCancel = isHiddenBtn && isTravelerSearch
  const isHiddenBtnSubmit = isHiddenBtn && isTravelerDelete

  return {
    isHiddenBtnCancel,
    isHiddenBtnSubmit,
    isRoleAdmin,
    isRoleSubAdmin,
    isTravelerDelete,
  }
}
