import { Fragment } from "react"
import { Translate } from "../../../../../../../constant/Translate"
import { replaceString } from "../../../../../../../utils/replaceString"
import { validatorRomajiName } from "../../../../../../../utils/validator/romajiName"
import { ItemInputGroup } from "../../../../../../../_webui/layout/components/Form/Item/Input/Group"
import { ItemSelect } from "../../../../../../../_webui/layout/components/Form/Item/Select/index"
import { TabItemsProps } from "../../../../../../../_webui/layout/components/Tabs/models"
import { TravelersEditButtonGroup } from "../../../../components/edit/ButtonGroup"
import { UseTravelersEditTabsConfigProps } from "../../../../models/edit"

const { TEXT_T007, TEXT_T008, TEXT_T043, TEXT_T083, TEXT_T096 } = Translate

export const useTabsConfig = (props: UseTravelersEditTabsConfigProps) => {
  const { accompanies, addItems, relationships, removeItems } = props

  const tabsItems: TabItemsProps[] = accompanies.map((item, index) => ({
    children: (
      <Fragment>
        <TravelersEditButtonGroup
          addItems={addItems}
          isDisabledBtnDelete={!accompanies.length}
          removeItems={() => removeItems(item.index)}
        />

        <ItemInputGroup
          className="ant-form-item-group-first"
          inputItems={[
            {
              label: TEXT_T007,
              maxLength: 50,
              name: `familyRomajiSurName_${item.index}`,
              rules: [validatorRomajiName],
            },
            {
              label: TEXT_T008,
              maxLength: 50,
              name: `familyRomajiName_${item.index}`,
              rules: [validatorRomajiName],
            },
          ]}
          label={TEXT_T096}
          name="familyFullNameRomaji"
        />

        <ItemSelect
          name={`familyRelationship_${item.index}`}
          label={TEXT_T043}
          selectItem={relationships}
        />
      </Fragment>
    ),
    key: `${item.index + 1}`,
    label: replaceString(TEXT_T083, ` ${index + 1}`),
  }))

  return {
    tabsItems,
  }
}
