/* eslint-disable max-lines */
import { Translate } from "../../Translate"

const {
  TEXT_T021, // 渡航者情報の一覧
  TEXT_T034, // 削除
  TEXT_T111, // 確認・変更・削除
  TEXT_T112, // 渡航予定管理
  TEXT_T119, // 作成・発信
  TEXT_T141, // 一括処理
  TEXT_T145, // 一括処理（渡航予定）
  TEXT_T147, // 登録
  TEXT_T190, // 渡航予定情報の一覧
  TEXT_T226, // 作成・発出
  TEXT_T230, // 渡航予定情報の参照
  TEXT_T271, // 渡航予定情報の登録
  TEXT_T288, // 渡航予定情報の登録完了
  TEXT_T292, // 渡航予定情報の変更
  TEXT_T293, // 渡航予定情報の変更完了
  TEXT_T295, // 渡航予定情報の削除確認
  TEXT_T299, // 渡航予定情報の削除完了
  TEXT_T301, // 渡航予定情報の一括登録
  TEXT_T304, // 渡航予定情報の一括変更
  TEXT_T305, // 渡航予定情報の一括削除
  TEXT_T384, // 登録確認
  TEXT_T469, // 注意喚起
  TEXT_T470, // 安否確認
  TEXT_T476, // 変更
  TEXT_T519, // ホーム
  TEXT_T586, // 渡航スケジュール
  TEXT_T587, // 管理者メニュー
  TEXT_T591, // 新規予定登録
} = Translate

const BRCR_SCR210 = [TEXT_T519, TEXT_T586, TEXT_T111, TEXT_T190]
const BRCR_SCR210_TRAVELERS = [TEXT_T519, TEXT_T587, TEXT_T112, TEXT_T190]
const BRCR_SCR210_ATTENTIONS = [TEXT_T519, TEXT_T469, TEXT_T119, TEXT_T190]
const BRCR_SCR210_SAFETIES = [TEXT_T519, TEXT_T470, TEXT_T226, TEXT_T190]
const BRCR_SCR210_FROM_SCR114 = [TEXT_T519, TEXT_T384, TEXT_T190]
const BRCR_SCR211 = [TEXT_T230]
const BRCR_SCR211_FROM_SCR210 = [
  TEXT_T519,
  TEXT_T586,
  TEXT_T111,
  TEXT_T190,
  TEXT_T230,
]
const BRCR_SCR211_FROM_SCR210_TRAVELERS = [
  TEXT_T519,
  TEXT_T587,
  TEXT_T112,
  TEXT_T190,
  TEXT_T230,
]
const BRCR_SCR211_FROM_SCR114 = [TEXT_T519, TEXT_T384, TEXT_T190, TEXT_T230]
const BRCR_SCR212 = [TEXT_T519, TEXT_T586, TEXT_T147, TEXT_T271]
const BRCR_SCR212_USER = [TEXT_T519, TEXT_T587, TEXT_T591, TEXT_T021, TEXT_T271]
const BRCR_SCR212_FROM_210_USER_SEARCH = [
  TEXT_T519,
  TEXT_T384,
  TEXT_T190,
  TEXT_T271,
]
const BRCR_SCR213 = [TEXT_T519, TEXT_T586, TEXT_T147, TEXT_T271, TEXT_T288]
const BRCR_SCR213_FROM_SCR212 = [
  TEXT_T519,
  TEXT_T586,
  TEXT_T147,
  TEXT_T271,
  TEXT_T288,
]
const BRCR_SCR213_FROM_SCR212_USER = [
  TEXT_T519,
  TEXT_T587,
  TEXT_T591,
  TEXT_T021,
  TEXT_T271,
  TEXT_T288,
]
const BRCR_SCR213_FROM_210_USER_SEARCH = [
  TEXT_T519,
  TEXT_T384,
  TEXT_T190,
  TEXT_T271,
  TEXT_T288,
]
const BRCR_SCR214 = [TEXT_T292]
const BRCR_SCR214_FROM_SCR210 = [
  TEXT_T519,
  TEXT_T586,
  TEXT_T111,
  TEXT_T190,
  TEXT_T292,
]
const BRCR_SCR214_FROM_SCR210_TRAVELERS = [
  TEXT_T519,
  TEXT_T587,
  TEXT_T112,
  TEXT_T190,
  TEXT_T292,
]
const BRCR_SCR214_FROM_SCR211 = [
  TEXT_T519,
  TEXT_T586,
  TEXT_T111,
  TEXT_T190,
  TEXT_T230,
  TEXT_T292,
]
const BRCR_SCR214_FROM_SCR211_TRAVELERS = [
  TEXT_T519,
  TEXT_T587,
  TEXT_T112,
  TEXT_T190,
  TEXT_T230,
  TEXT_T292,
]
const BRCR_SCR215 = [TEXT_T293]
const BRCR_SCR215_FROM_SCR214_AND_SCR210 = [
  TEXT_T519,
  TEXT_T586,
  TEXT_T111,
  TEXT_T190,
  TEXT_T292,
  TEXT_T293,
]
const BRCR_SCR215_FROM_SCR214_AND_SCR210_TRAVELERS = [
  TEXT_T519,
  TEXT_T587,
  TEXT_T112,
  TEXT_T190,
  TEXT_T292,
  TEXT_T293,
]
const BRCR_SCR215_FROM_SCR214_AND_SCR211 = [
  TEXT_T519,
  TEXT_T586,
  TEXT_T111,
  TEXT_T190,
  TEXT_T230,
  TEXT_T292,
  TEXT_T293,
]
const BRCR_SCR215_FROM_SCR214_AND_SCR211_TRAVELERS = [
  TEXT_T519,
  TEXT_T587,
  TEXT_T112,
  TEXT_T190,
  TEXT_T230,
  TEXT_T292,
  TEXT_T293,
]
const BRCR_SCR216 = [TEXT_T295]
const BRCR_SCR216_FROM_SCR210 = [
  TEXT_T519,
  TEXT_T586,
  TEXT_T111,
  TEXT_T190,
  TEXT_T295,
]
const BRCR_SCR216_FROM_SCR210_TRAVELERS = [
  TEXT_T519,
  TEXT_T587,
  TEXT_T112,
  TEXT_T190,
  TEXT_T295,
]
const BRCR_SCR217 = [TEXT_T299]
const BRCR_SCR217_FROM_SCR216 = [
  TEXT_T519,
  TEXT_T586,
  TEXT_T111,
  TEXT_T190,
  TEXT_T295,
  TEXT_T299,
]
const BRCR_SCR217_FROM_SCR216_TRAVELERS = [
  TEXT_T519,
  TEXT_T587,
  TEXT_T112,
  TEXT_T190,
  TEXT_T295,
  TEXT_T299,
]
const BRCR_SCR217_FROM_SCR211 = [
  TEXT_T519,
  TEXT_T586,
  TEXT_T111,
  TEXT_T190,
  TEXT_T230,
  TEXT_T299,
]
const BRCR_SCR217_FROM_SCR211_TRAVELERS = [
  TEXT_T519,
  TEXT_T587,
  TEXT_T112,
  TEXT_T190,
  TEXT_T230,
  TEXT_T299,
]
const BRCR_SCR220 = [TEXT_T519, TEXT_T586, TEXT_T141, TEXT_T147, TEXT_T301]
const BRCR_SCR220_USER = [TEXT_T519, TEXT_T587, TEXT_T145, TEXT_T147, TEXT_T301]
const BRCR_SCR221 = [TEXT_T519, TEXT_T586, TEXT_T141, TEXT_T476, TEXT_T304]
const BRCR_SCR221_USER = [TEXT_T519, TEXT_T587, TEXT_T145, TEXT_T476, TEXT_T304]
const BRCR_SCR222 = [TEXT_T519, TEXT_T586, TEXT_T141, TEXT_T034, TEXT_T305]
const BRCR_SCR222_USER = [TEXT_T519, TEXT_T587, TEXT_T145, TEXT_T034, TEXT_T305]

export const BreadcrumbsSchedules = {
  BRCR_SCR210,
  BRCR_SCR210_ATTENTIONS,
  BRCR_SCR210_FROM_SCR114,
  BRCR_SCR210_SAFETIES,
  BRCR_SCR210_TRAVELERS,
  BRCR_SCR211,
  BRCR_SCR211_FROM_SCR114,
  BRCR_SCR211_FROM_SCR210,
  BRCR_SCR211_FROM_SCR210_TRAVELERS,
  BRCR_SCR212,
  BRCR_SCR212_FROM_210_USER_SEARCH,
  BRCR_SCR212_USER,
  BRCR_SCR213,
  BRCR_SCR213_FROM_210_USER_SEARCH,
  BRCR_SCR213_FROM_SCR212,
  BRCR_SCR213_FROM_SCR212_USER,
  BRCR_SCR214,
  BRCR_SCR214_FROM_SCR210,
  BRCR_SCR214_FROM_SCR210_TRAVELERS,
  BRCR_SCR214_FROM_SCR211,
  BRCR_SCR214_FROM_SCR211_TRAVELERS,
  BRCR_SCR215,
  BRCR_SCR215_FROM_SCR214_AND_SCR210,
  BRCR_SCR215_FROM_SCR214_AND_SCR210_TRAVELERS,
  BRCR_SCR215_FROM_SCR214_AND_SCR211,
  BRCR_SCR215_FROM_SCR214_AND_SCR211_TRAVELERS,
  BRCR_SCR216,
  BRCR_SCR216_FROM_SCR210,
  BRCR_SCR216_FROM_SCR210_TRAVELERS,
  BRCR_SCR217,
  BRCR_SCR217_FROM_SCR211,
  BRCR_SCR217_FROM_SCR211_TRAVELERS,
  BRCR_SCR217_FROM_SCR216,
  BRCR_SCR217_FROM_SCR216_TRAVELERS,
  BRCR_SCR220,
  BRCR_SCR220_USER,
  BRCR_SCR221,
  BRCR_SCR221_USER,
  BRCR_SCR222,
  BRCR_SCR222_USER,
}
