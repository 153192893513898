import { FC } from "react"
import { Translate } from "../../../../../constant/Translate"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { SafetiesFormSearch } from "../../components/list/FormSearch"
import { useSafetiesList } from "./modules/useList"
import { SafetiesTable } from "../../components/list/Table"

const { TEXT_T371 } = Translate

export const SafetiesList: FC = () => {
  const {
    breadcrumbItems,
    categoryList,
    columns,
    form,
    goToSafetiesDetail,
    goToSafetiesResponse,
    isDisabledDetailBtn,
    isDisabledResponseBtn,
    isHiddenDetailBtn,
    isHiddenResponseBtn,
    isHiddenResponseHistoryBtn,
    isLoading,
    isLoadingLayout,
    isOpen,
    onCancelPopup,
    onChange,
    onFinish,
    onOpenPopup,
    perItem,
    onBlur,
    onKeyDown,
    rowSelection,
    safetyInfoList,
    statusList,
  } = useSafetiesList()

  return (
    <Layout
      breadcrumbItems={breadcrumbItems}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T371}
    >
      <SafetiesTable
        columns={columns}
        goToSafetiesDetail={goToSafetiesDetail}
        goToSafetiesResponse={goToSafetiesResponse}
        isDisabledDetailBtn={isDisabledDetailBtn}
        isDisabledResponseBtn={isDisabledResponseBtn}
        isHiddenDetailBtn={isHiddenDetailBtn}
        isHiddenResponseBtn={isHiddenResponseBtn}
        isHiddenResponseHistoryBtn={isHiddenResponseHistoryBtn}
        isLoading={isLoading}
        onChange={onChange}
        onOpenPopup={onOpenPopup}
        perItem={perItem}
        rowSelection={rowSelection}
        safetyInfoList={safetyInfoList}
      />
      <SafetiesFormSearch
        categoryList={categoryList}
        form={form}
        isOpen={isOpen}
        onBlur={onBlur}
        onCancel={onCancelPopup}
        onFinish={onFinish}
        onKeyDown={onKeyDown}
        statusList={statusList}
      />
    </Layout>
  )
}
