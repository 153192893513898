import "./style/index.scss"
import { Tooltip as TooltipAnt } from "antd"
import { FC } from "react"
import { Image } from "../Image"
import { Images } from "../../../../constant/Images"
import { TooltipProps } from "./models"
import { PreLine } from "../Typography/PreLine"

const { IMAGE_HELP_CIRCLE } = Images

export const Tooltip: FC<TooltipProps> = (props) => {
  const { title, children, ...res } = props

  return title ? (
    <div className="ant-tooltip-item">
      {children}
      <TooltipAnt title={<PreLine>{title}</PreLine>} trigger="click" {...res}>
        <button aria-label={title} className="ant-tooltip-btn" type="button">
          <Image src={IMAGE_HELP_CIRCLE} className="ant-tooltip-icon" />
        </button>
      </TooltipAnt>
    </div>
  ) : null
}
