import { Breadcrumbs } from "../../../../../../../../constant/Breadcrumbs"
const {
  BRCR_SCR116_FROM_SCR113,
  BRCR_SCR116_FROM_SCR115_USER,
  BRCR_SCR116_FROM_SCR115,
  BRCR_SCR116,
} = Breadcrumbs

export const getBreadcrumbItems = (
  isPersonUrl: boolean,
  isFromSCR113?: boolean,
  isFromSCR115?: boolean,
) =>
  isFromSCR113
    ? BRCR_SCR116_FROM_SCR113
    : isFromSCR115
    ? isPersonUrl
      ? BRCR_SCR116_FROM_SCR115
      : !isPersonUrl
      ? BRCR_SCR116_FROM_SCR115_USER
      : BRCR_SCR116
    : BRCR_SCR116
