import { useHistory } from "react-router-dom"
import { getDepartments } from "../../../../../../../../utils/apis/getDepartments"
import { useModal } from "../../../../../../../../utils/hook/useModal"
import { useApi } from "../useApi"
import { useTableConfig } from "../useTableConfig"
import { useVariablesAuthority } from "../useVariablesAuthority"

export const useVariables = () => {
  const { push } = useHistory()
  const { isCommonPermissionSettings, isHiddenBtn } = useVariablesAuthority()

  // APIを呼び出し、部署一覧を取得する。
  const { departments } = getDepartments()

  const { isOpen, onCancel, onOpen } = useModal()

  const {
    authorityPatterns,
    callApi,
    form,
    formSearch,
    getUserAuthorityTypeSetting,
    goBack,
    goToRolesSetting,
    goToSubAdmin,
    isDisabledBtnSave,
    isLoading,
    isLoadingLayout,
    onChangePage,
    onChangeTable,
    onOk,
    patternId,
    perItem,
    rowSelection,
    searchCondition,
    selectedRowKeys,
    setIsDisabledBtnSave,
    setIsLoading,
    setPatternId,
    setSearchCondition,
    setSelectedRowKeys,
    setValues,
    sortedInfo,
    travelerList,
  } = useApi()

  const { columns } = useTableConfig(sortedInfo)

  return {
    authorityPatterns,
    callApi,
    columns,
    departments,
    form,
    formSearch,
    getUserAuthorityTypeSetting,
    goBack,
    goToRolesSetting,
    goToSubAdmin,
    isCommonPermissionSettings,
    isDisabledBtnSave,
    isHiddenBtn,
    isLoading,
    isLoadingLayout,
    isOpen,
    onCancel,
    onChangePage,
    onChangeTable,
    onOk,
    onOpen,
    patternId,
    perItem,
    push,
    rowSelection,
    searchCondition,
    selectedRowKeys,
    setIsDisabledBtnSave,
    setIsLoading,
    setPatternId,
    setSearchCondition,
    setSelectedRowKeys,
    setValues,
    travelerList,
  }
}
