import { Translate } from "../../../../../../../constant/Translate"
import { getFullName } from "../../../../../../../utils/getFullName"
import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../_webui/layout/components/Table/models"
import { AttentionDataTableType } from "../../../../components/attention/Form/models"

const { TEXT_T002, TEXT_T003, TEXT_T006, TEXT_T017 } = Translate

export const useTableFormConfig =
  (): UseTableConfigType<AttentionDataTableType> => {
    const columns: ColumnType<AttentionDataTableType>[] = [
      {
        dataIndex: "kanjiFullName",
        render: (_, data) =>
          getFullName(data.kanjiSurname, data.kanjiName, true),
        sorter: (a, b) =>
          getFullName(a.kanjiSurname, a.kanjiName, true).localeCompare(
            getFullName(b.kanjiSurname, b.kanjiName, true),
          ),
        title: TEXT_T003,
        width: 200,
      },
      {
        dataIndex: "romajiFullName",
        render: (_, data) => getFullName(data.romajiSurname, data.romajiName),
        sorter: (a, b) =>
          getFullName(a.romajiSurname, a.romajiName).localeCompare(
            getFullName(b.romajiSurname, b.romajiName),
          ),
        title: TEXT_T006,
        width: 200,
      },
      {
        dataIndex: "userId",
        sorter: (a, b) => a.userId.localeCompare(b.userId),
        title: TEXT_T002,
        width: 250,
      },
      {
        dataIndex: "personEmailAddress",
        sorter: (a, b) =>
          a.personEmailAddress.localeCompare(b.personEmailAddress),
        title: TEXT_T017,
        width: 300,
      },
    ]
    return {
      columns,
    }
  }
