import { FC, Fragment } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { getFileSize } from "../../../../../../utils/getFileSize"
import { replaceStringParam } from "../../../../../../utils/replaceStringParam"
import { FilesTitleLeftProps } from "./models"

const { TEXT_T498, TEXT_T499, TEXT_T500 } = Translate

export const FilesTitleLeft: FC<FilesTitleLeftProps> = (props) => {
  const { currentUsage, dataLength } = props

  const maximumText = `${replaceStringParam(TEXT_T498, "5 TB")}`
  const currentUsageText = `${replaceStringParam(
    TEXT_T499,
    getFileSize(currentUsage * 1024, 2),
  )}`
  const numberFilesText = `${replaceStringParam(TEXT_T500, String(dataLength))}`

  return (
    <Fragment>
      {maximumText} / {currentUsageText} / {numberFilesText}
    </Fragment>
  )
}
