/* eslint-disable max-lines */
import { FocusEvent } from "react"
import { ItemInput } from "../../../../../../../../_webui/layout/components/Form/Item/Input"
import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../../_webui/layout/components/Table/models"
import { Translate } from "../../../../../../../../constant/Translate"
import { ScheduleInfoDetailsType } from "../../../../../models/add"
import { setNameRowDetail } from "../../../utils/setNameRowDetail"
import { getValueByLanguage } from "../../../../../../../../utils/getValueByLanguage"

const {
  TEXT_T152,
  TEXT_T240,
  TEXT_T246,
  TEXT_T247,
  TEXT_T248,
  TEXT_T249,
  TEXT_T250,
} = Translate

export const useTableRemarksConfig =
  (): UseTableConfigType<ScheduleInfoDetailsType> => {
    const onFocus = (event: FocusEvent) => {
      const elmLabel = event.target
        ?.closest(".ant-form-item")
        ?.querySelector(".ant-form-item-label")
      elmLabel?.classList.add("ant-form-item-label-focused")
    }

    const onBlur = (event: FocusEvent) => {
      const elmLabel = event.target
        ?.closest(".ant-form-item")
        ?.querySelector(".ant-form-item-label")
      elmLabel?.classList.remove("ant-form-item-label-focused")
    }

    const columns: ColumnType<ScheduleInfoDetailsType>[] = [
      {
        render: (_, _data, index) => index + 1,
        title: TEXT_T240,
        width: 65,
      },
      {
        render: (_, data) => (
          <ItemInput
            isHiddenLabel
            label={data?.remarkNames?.remarksName1}
            maxLength={200}
            name={`${setNameRowDetail(data.index)}.othersRemarks1`}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        ),
        title: `${TEXT_T246}${getValueByLanguage(
          `（${TEXT_T152}）`,
          ` (${TEXT_T152})`,
        )}`,
        width: 250,
      },
      {
        render: (_, data) => (
          <ItemInput
            isHiddenLabel
            label={data?.remarkNames?.remarksName2}
            maxLength={200}
            name={`${setNameRowDetail(data.index)}.othersRemarks2`}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        ),
        title: TEXT_T247,
        width: 250,
      },
      {
        render: (_, data) => (
          <ItemInput
            isHiddenLabel
            label={data?.remarkNames?.remarksName3}
            maxLength={200}
            name={`${setNameRowDetail(data.index)}.othersRemarks3`}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        ),
        title: TEXT_T248,
        width: 250,
      },
      {
        render: (_, data) => (
          <ItemInput
            isHiddenLabel
            label={data?.remarkNames?.remarksName4}
            maxLength={200}
            name={`${setNameRowDetail(data.index)}.othersRemarks4`}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        ),
        title: TEXT_T249,
        width: 250,
      },
      {
        render: (_, data) => (
          <ItemInput
            isHiddenLabel
            label={data?.remarkNames?.remarksName5}
            maxLength={200}
            name={`${setNameRowDetail(data.index)}.othersRemarks5`}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        ),
        title: TEXT_T250,
        width: 250,
      },
    ]

    return {
      columns,
    }
  }
