import { Table } from "antd"
import { Translate } from "../../../../../../../constant/Translate"
import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../_webui/layout/components/Table/models"
import { Expandable } from "../../../../../../../_webui/layout/components/Typography/Expandable"
import { AttentionsCloneDataType } from "../../../../models/clone"
import { getValueByLanguage } from "../../../../../../../utils/getValueByLanguage"

const { TEXT_T312, TEXT_T314, TEXT_T315, TEXT_T316, TEXT_T333 } = Translate
const { SELECTION_COLUMN } = Table

export const useTableConfig =
  (): UseTableConfigType<AttentionsCloneDataType> => {
    const columns: ColumnType<AttentionsCloneDataType>[] = [
      SELECTION_COLUMN,
      {
        dataIndex: "attentionTitle",
        sorter: (a, b) => a.attentionTitle.localeCompare(b.attentionTitle),
        title: TEXT_T315,
        width: 200,
      },
      {
        dataIndex: "implementationDateTimeFormat",
        sorter: (a, b) =>
          a.implementationDateTimeFormat.localeCompare(
            b.implementationDateTimeFormat,
          ),
        title: TEXT_T333,
        width: getValueByLanguage(150, 180),
      },
      {
        dataIndex: "category",
        sorter: (a, b) => a.category.localeCompare(b.category),
        title: TEXT_T312,
        width: 160,
      },
      {
        dataIndex: "severityName",
        sorter: (a, b) => b.severity.localeCompare(a.severity),
        title: TEXT_T314,
        width: getValueByLanguage(100, 150),
      },
      {
        render: (_, data) => (
          <Expandable title={TEXT_T316} content={data.bodyContent} />
        ),
        sorter: (a, b) => a.bodyContent.localeCompare(b.bodyContent),
        title: TEXT_T316,
        width: 400,
      },
    ]
    return {
      columns,
    }
  }
