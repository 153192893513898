import { FC, Fragment } from "react"
import { ItemInput } from "../../../../../../../_webui/layout/components/Form/Item/Input"
import { ItemRadio } from "../../../../../../../_webui/layout/components/Form/Item/Radio"
import { ItemSelect } from "../../../../../../../_webui/layout/components/Form/Item/Select"
import { RadioItems } from "../../../../../../../constant/RadioItems"
import { SelectItems } from "../../../../../../../constant/SelectItems"
import { Translate } from "../../../../../../../constant/Translate"
import { CollapsePostingProps } from "./models"

const { OTHER_CITY_SELECT_ITEMS } = SelectItems
const { TEXT_T054, TEXT_T055, TEXT_T056, TEXT_T057 } = Translate
const { WORK_PLACE_LOCATION_SEARCH } = RadioItems

export const CollapsePosting: FC<CollapsePostingProps> = (props) => {
  const {
    allCountries,
    countryCode,
    cities,
    isOtherCity,
    onCountryChange,
    onWorkPlaceCityChange,
    onWorkPlaceLocationChange,
    workPlaceLocation,
  } = props

  return (
    <Fragment>
      <ItemRadio
        label={TEXT_T054}
        name="workPlaceLocation"
        onChange={onWorkPlaceLocationChange}
        radioItems={WORK_PLACE_LOCATION_SEARCH}
      />
      {workPlaceLocation === 1 && (
        <Fragment>
          <ItemSelect
            label={TEXT_T055}
            name="workPlaceCountry"
            onChange={onCountryChange}
            selectItem={allCountries}
          />
          <ItemSelect
            isDisabled={!countryCode}
            label={TEXT_T056}
            name="workPlaceCity"
            onChange={onWorkPlaceCityChange}
            selectItem={cities ? [...cities, OTHER_CITY_SELECT_ITEMS] : []}
          />
          {isOtherCity && (
            <ItemInput label={TEXT_T057} maxLength={200} name="workPlace" />
          )}
        </Fragment>
      )}
    </Fragment>
  )
}
