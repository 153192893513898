import { SorterResult } from "antd/lib/table/interface"
import { useState } from "react"
import { AnyValue } from "../../../_webui/layout/models"
import { SortedInfoType } from "./models"

export const useSort = () => {
  const [sortedInfo, setSortedInfo] = useState<SortedInfoType>({
    columnKey: "",
    order: null,
  })

  const handleSetDefaultSortedInfo = () =>
    setSortedInfo({ columnKey: "", order: null })

  const handleSetSortedInfo = (
    sorter: SorterResult<AnyValue> | SorterResult<AnyValue>[],
  ) => {
    if (!Array.isArray(sorter))
      setSortedInfo({
        columnKey: sorter?.field?.toString() ?? "",
        order: sorter?.order ?? null,
      })
  }

  return {
    handleSetDefaultSortedInfo,
    handleSetSortedInfo,
    sortedInfo,
  }
}
