import { Key, useState } from "react"
import { useTable } from "../../../../../../../utils/hook/useTable"
import { UploadResultsType } from "../../../../model/uploadResults"

export const useVariablesRowSelection = (dataLength: number) => {
  const {
    onChange: onChangePage,
    perItem,
    perItemStart,
    perItemEnd,
  } = useTable(dataLength)
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [idBatchLogSelected, setIdBatchLogSelected] = useState<Key[]>([])
  const [dataCurrent, setDataCurrent] = useState<UploadResultsType[]>([])
  const [dataSource, setDataSource] = useState<UploadResultsType[]>([])
  const [dataSourceSelected, setDataSourceSelected] = useState<
    UploadResultsType[]
  >([])
  const [isCheckAll, setIsCheckAll] = useState(false)

  return {
    dataCurrent,
    dataSource,
    dataSourceSelected,
    idBatchLogSelected,
    isCheckAll,
    onChangePage,
    perItem,
    perItemEnd,
    perItemStart,
    selectedRowKeys,
    setDataCurrent,
    setDataSource,
    setDataSourceSelected,
    setIdBatchLogSelected,
    setIsCheckAll,
    setSelectedRowKeys,
  }
}
