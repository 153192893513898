import { Regex } from "../../../../../../../constant/Regex"
import { setNameRowDetail } from "../setNameRowDetail"

const { REGEX_IS_DESKTOP } = Regex

export const getDetailRequiredFields = (index: number) => {
  const requiredFields = [
    `${setNameRowDetail(index)}.transportation`,
    `${setNameRowDetail(index)}.departureCountryCode`,
    `${setNameRowDetail(index)}.departureCityCode`,
    `${setNameRowDetail(index)}.departureDate`,
    `${setNameRowDetail(index)}.arrivalCountryCode`,
    `${setNameRowDetail(index)}.arrivalCityCode`,
    `${setNameRowDetail(index)}.arrivalDate`,
  ]

  if (REGEX_IS_DESKTOP)
    requiredFields.push.apply(requiredFields, [
      `${setNameRowDetail(index)}.departureTime`,
      `${setNameRowDetail(index)}.arrivalTime`,
    ])
  else
    requiredFields.push.apply(requiredFields, [
      `${setNameRowDetail(index)}.departureHour`,
      `${setNameRowDetail(index)}.departureMinute`,
      `${setNameRowDetail(index)}.arrivalHour`,
      `${setNameRowDetail(index)}.arrivalMinute`,
    ])

  return requiredFields
}
