import { Form } from "antd"
import { FC } from "react"
import { Variables } from "./constants"
import { FormProviderPropsType } from "./models"

const { Provider } = Form
const { TEXT_IS_FORM_CHANGE } = Variables

export const FormProvider: FC<FormProviderPropsType> = (props) => {
  const { children } = props
  const onFormChangeHandler = () => {
    const searchString = window.location.search

    const queryString = searchString
      ? `&${TEXT_IS_FORM_CHANGE}`
      : `?${TEXT_IS_FORM_CHANGE}`

    if (!searchString.includes(TEXT_IS_FORM_CHANGE))
      window.history.pushState("", "", window.location + queryString)
  }

  return <Provider onFormChange={onFormChangeHandler}>{children}</Provider>
}
