import { convertDataExport } from "./convertDataExport"
import {
  SafetiesDetailBaseType,
  SafetiesDetailInfoType,
} from "../../../../../../models/detail"
import { exportCsv } from "../../../../../../../../../utils/exportCsv"
import { Translate } from "../../../../../../../../../constant/Translate"

const { EXPORT_EP0197 } = Translate

export const exportCsvFRM303 = (
  data: SafetiesDetailBaseType[],
  safetyInfo?: SafetiesDetailInfoType,
) => {
  const convertData = convertDataExport(data, safetyInfo)
  exportCsv(convertData, EXPORT_EP0197)
}
