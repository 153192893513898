import { getTextConfirm } from "../../../../../../../../utils/getTextConfirm"
import { getTextYesNo } from "../../../../../../../../utils/getTextYesNo"
import { getDay } from "../../../../../../../../utils/getDate"
import { joinDate } from "../../../../../../../../utils/joinDate"
import {
  SchedulesBatchUpdateDataResType,
  SchedulesBatchUpdateDataType,
} from "../../../../../models/batchUpdate"

export const convertDataSource = (
  data: SchedulesBatchUpdateDataResType[],
): SchedulesBatchUpdateDataType[] =>
  data?.map((item) => ({
    ...item,
    arrivalDateTime: joinDate(item.arrivalDateTime),
    codeConductConfirmation: getTextConfirm(item.codeConductConfirmation),
    departureDateTime: joinDate(item.departureDateTime),
    differentInforCheck: getTextYesNo(item.differentInforCheck),
    dispatchEndDate: getDay(item.dispatchEndDate),
    dispatchStartDate: getDay(item.dispatchStartDate),
    lodgingCheckin: joinDate(item.lodgingCheckin, true),
    lodgingCheckout: joinDate(item.lodgingCheckout, true),
  }))
