import { FC } from "react"
import { Title } from "../../../../../_webui/layout/components/Typography/Title"
import { Translate } from "../../../../../constant/Translate"
import { validatorRequiredField } from "../../../../../utils/validator/requiredField"
import { Form } from "../../../../../_webui/layout/components/Form"
import { ItemInput } from "../../../../../_webui/layout/components/Form/Item/Input"
import { Sub } from "../../../../../_webui/layout/components/Typography/Sub"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { useResetPassword } from "./modules/useResetPassword"
import { validatorEmailAddress } from "../../../../../utils/validator/emailAddress"
import { Styled } from "./styled"
import { Center } from "../../../../../_webui/layout/components/Center"
import { Heading } from "../../../../../_webui/layout/components/Typography/Heading"

const { StyledButtonReset, StyledButtonSubmit, StyledContainer } = Styled
const { TEXT_T433, TEXT_T072, TEXT_T430, TEXT_T431, TEXT_T432 } = Translate

export const ResetPassword: FC = () => {
  const {
    form,
    goBack,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    messageError,
    onFinish,
    onValuesChange,
  } = useResetPassword()
  return (
    <Layout
      isHeaderRightNone
      isLoadingLayout={isLoadingLayout}
      isSideBar={false}
      title={TEXT_T430}
    >
      <Form
        form={form}
        heading={TEXT_T430}
        isBreadcrumbs={false}
        isLoading={isLoading}
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <StyledContainer>
          {messageError && <Heading title={messageError} type="danger" />}
          <Title titleLeft={TEXT_T431} />
          <Sub title={TEXT_T432} />
          <ItemInput
            maxLength={255}
            name="personEmailAddress"
            rules={[validatorRequiredField, validatorEmailAddress]}
          />
          <Center>
            <StyledButtonSubmit
              htmlType="submit"
              isDisabled={isDisabledSubmit}
              isFullWidth={true}
              text={TEXT_T433}
              type="primary"
            />
            <StyledButtonReset
              htmlType="button"
              isFullWidth={true}
              isSmall
              onClick={goBack}
              text={TEXT_T072}
              type="ghost"
            />
          </Center>
        </StyledContainer>
      </Form>
    </Layout>
  )
}
