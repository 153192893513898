/* eslint-disable complexity */
import { useState } from "react"
import { useHistory } from "react-router-dom"
import { Breadcrumbs } from "../../../../../../../constant/Breadcrumbs"
import { Translate } from "../../../../../../../constant/Translate"
import { TravelerDeleteType } from "../../../../models/confirmDelete"
import { useApi } from "../useApi"
import { useTableConfig } from "../useTableConfig"
import { useVariablesAuthority } from "../useVariablesAuthority"

const {
  MESSAGE_C0003,
  MESSAGE_C0004,
  MESSAGE_E0048,
  MESSAGE_E0124,
  TEXT_T187,
  TEXT_T188,
  TEXT_T611,
  TEXT_T661,
} = Translate
const { BRCR_SCR126, BRCR_SCR126_ADMIN } = Breadcrumbs

export const useVariables = () => {
  const { push } = useHistory()
  const {
    isHiddenBtnCancel,
    isHiddenBtnSubmit,
    isRoleAdmin,
    isRoleSubAdmin,
    isTravelerDelete,
  } = useVariablesAuthority()
  const { columns } = useTableConfig({ isRoleAdmin, isRoleSubAdmin })
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [userInfoList, setUserInfoList] = useState<TravelerDeleteType[]>([])
  const { errorItems, isLoading, isOpen, onCancel, onOk, onOpen, state } =
    useApi({
      isRoleAdmin,
      isRoleSubAdmin,
      userInfoList,
    })

  const errorHeading = isRoleAdmin
    ? MESSAGE_E0124
    : isRoleSubAdmin
    ? MESSAGE_E0048
    : ""
  const breadcrumbItems = isRoleAdmin ? BRCR_SCR126_ADMIN : BRCR_SCR126
  const confirmDeleteTitle = isRoleAdmin ? TEXT_T611 : TEXT_T187
  const confirmDeleteTitleLeft = isRoleAdmin ? TEXT_T661 : TEXT_T188
  const modalConfirmMessage = isRoleAdmin
    ? MESSAGE_C0004
    : isRoleSubAdmin
    ? MESSAGE_C0003
    : ""

  return {
    breadcrumbItems,
    columns,
    confirmDeleteTitle,
    confirmDeleteTitleLeft,
    errorHeading,
    errorItems,
    isHiddenBtnCancel,
    isHiddenBtnSubmit,
    isLoading,
    isLoadingLayout,
    isOpen,
    isTravelerDelete,
    modalConfirmMessage,
    onCancel,
    onOk,
    onOpen,
    push,
    setIsLoadingLayout,
    setUserInfoList,
    state,
    userInfoList,
  }
}
