/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import { useLocation } from "react-router-dom"
import { Routers } from "../../../../../../../constant/Routers"
import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

const {
  SCR210,
  SCR210_ATTENTIONS,
  SCR210_SAFETIES,
  SCR210_TRAVELERS,
  SCR210_USER_SEARCH,
} = Routers

export const useVariablesAuthority = (isFromSCR114UserSearch?: boolean) => {
  const { pathname } = useLocation()
  const {
    isAttentionsSendAll, // 014
    isCommonTravelerRefer, // 021
    isRoleAdmin,
    isRoleSubAdmin,
    isSafetiesReport, // 020
    isSafetiesSendAll, // 018
    isScheduleChange, // 008
    isScheduleDelete, // 010
    isScheduleRefer, // 009
    isScheduleRegister, // 007
    isScheduleReport, // 012
    isScheduleSearch, // 011
  } = useAuthority()

  const isPathnameSchedules = pathname === SCR210
  const isHiddenBtn = isPathnameSchedules && isCommonTravelerRefer
  const isHiddenBtnRole = isRoleAdmin || isRoleSubAdmin

  // SCR210_TRAVELERS
  const isPathnameTravelers = pathname === SCR210_TRAVELERS
  const isPathnameUserSearch = pathname === SCR210_USER_SEARCH
  const isHiddenBtnTravelers =
    (isPathnameTravelers || isPathnameUserSearch) && isHiddenBtnRole
  const isHiddenBtnTravelersResetForm = isHiddenBtnTravelers && isScheduleSearch
  const isHiddenBtnTravelersSearchBasic =
    isHiddenBtnTravelers && isScheduleSearch // 検索
  const isHiddenBtnTravelersDelete =
    isHiddenBtnTravelers && isScheduleDelete && !isFromSCR114UserSearch // 削除
  const isHiddenBtnTravelersEdit =
    isHiddenBtnTravelers && isScheduleChange && !isFromSCR114UserSearch // 変更
  const isHiddenBtnTravelersRefer = isHiddenBtnTravelers && isScheduleRefer // 参照
  const isHiddenBtnTravelersExportCsv =
    isHiddenBtnTravelers &&
    (isScheduleChange ||
      isScheduleDelete ||
      isScheduleRefer ||
      isScheduleRegister) &&
    !isFromSCR114UserSearch // CSVダウンロード
  const isHiddenBtnTravelersExportExcel =
    isPathnameTravelers &&
    isRoleSubAdmin &&
    isScheduleReport &&
    !isFromSCR114UserSearch // 箱日程出力 - SCR210
  const isHiddenBtnExportExcelTravelers = isHiddenBtnTravelersExportExcel // 実績データ出力 - SCR210_Traveler

  //SCR210_ATTENTIONS
  const isPathnameAttentions = pathname === SCR210_ATTENTIONS
  const isHiddenBtnAttentions = isPathnameAttentions && isHiddenBtnRole
  const isHiddenBtnAttentionsResetForm =
    isHiddenBtnAttentions && isScheduleSearch
  const isHiddenBtnAttentionsSearchBasic =
    isHiddenBtnAttentions && isScheduleSearch
  const isHiddenBtnCreateOrSendAttentions =
    isHiddenBtnAttentions && isAttentionsSendAll // 作成・発出

  //SCR210_SAFETIES
  const isPathnameSafeties = pathname === SCR210_SAFETIES
  const isHiddenBtnSafeties = isPathnameSafeties && isHiddenBtnRole
  const isHiddenBtnSafetiesResetForm = isHiddenBtnSafeties && isScheduleSearch
  const isHiddenBtnSafetiesSearchBasic = isHiddenBtnSafeties && isScheduleSearch
  const isHiddenBtnExportExcelSafeties = isHiddenBtnSafeties && isSafetiesReport // 安否確認対象者出力 - SCR210_Safeties
  const isHiddenBtnCreateOrSendSafeties =
    isHiddenBtnSafeties && isSafetiesSendAll // 作成・発出

  // SCR210
  const isHiddenBtnResetForm =
    isHiddenBtn ||
    isHiddenBtnTravelersResetForm ||
    isHiddenBtnAttentionsResetForm ||
    isHiddenBtnSafetiesResetForm // クリア
  const isHiddenBtnSearchBasic =
    isHiddenBtn ||
    isHiddenBtnTravelersSearchBasic ||
    isHiddenBtnAttentionsSearchBasic ||
    isHiddenBtnSafetiesSearchBasic // 検索
  const isHiddenBtnDelete = isHiddenBtn || isHiddenBtnTravelersDelete // 削除
  const isHiddenBtnEdit = isHiddenBtn || isHiddenBtnTravelersEdit // 変更
  const isHiddenBtnRefer = isHiddenBtn || isHiddenBtnTravelersRefer // 参照
  const isHiddenBtnExportCsv = isHiddenBtn || isHiddenBtnTravelersExportCsv // CSVダウンロード
  const isHiddenBtnExportExcel = isHiddenBtn || isHiddenBtnTravelersExportExcel // 箱日程出力 - SCR210

  const isPathnameSchedulesOrTravelers =
    isPathnameSchedules || isPathnameTravelers || isPathnameUserSearch
  const isAuthorities =
    (pathname === SCR210 && !isCommonTravelerRefer) ||
    (pathname === SCR210_ATTENTIONS && !isAttentionsSendAll) ||
    (pathname === SCR210_SAFETIES && !isSafetiesSendAll) ||
    (pathname === SCR210_TRAVELERS && !isScheduleSearch)

  return {
    isAuthorities,
    isHiddenBtnCreateOrSendAttentions,
    isHiddenBtnCreateOrSendSafeties,
    isHiddenBtnDelete,
    isHiddenBtnEdit,
    isHiddenBtnExportCsv,
    isHiddenBtnExportExcel,
    isHiddenBtnExportExcelSafeties,
    isHiddenBtnExportExcelTravelers,
    isHiddenBtnRefer,
    isHiddenBtnResetForm,
    isHiddenBtnSearchBasic,
    isPathnameSchedules,
    isPathnameSchedulesOrTravelers,
    isRoleSubAdmin,
  }
}
