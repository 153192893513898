import styled from "styled-components"
import { TotalRecords } from "../../../Typography/TotalRecords"
import { Colors } from "../../../../../../constant/Styled/Colors"

const { DANGER } = Colors

const StyledTotalRecords = styled(TotalRecords)`
  .title-left {
    color: ${DANGER};
  }
`

export const Styled = {
  StyledTotalRecords,
}
