import { FC } from "react"
import { CardHeadingProps } from "../models"
import { Image } from "../../../Image"
import { Images } from "../../../../../../constant/Images"

const { IMAGE_HEADLINE_BG } = Images

export const CardHeadingIcon: FC<CardHeadingProps> = (props) => {
  const { isBackground, isCenter } = props

  return isBackground && !isCenter ? (
    <Image className="card-heading-bg" src={IMAGE_HEADLINE_BG} />
  ) : null
}
