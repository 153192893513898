const FNC1B_Add_Schedule = "/v1"
const FNC1B_Add_Schedule_Draft = "/v1/{0}/draft"
const FNC1B_Update_Schedule = "/v1/{0}"
const FNC1B_Get_Schedules = "/v1/browse"
const FNC1B_Get_Schedule = "/v1/{0}"
const FNC1B_Get_Schedule_Draft = "/v1/draft/{0}"
const FNC1B_Delete_Schedules = "/v1/disable"
const FNC1B_Delete_Schedule = "/v1/{0}/disable"
const FNC1B_Bulk_File_Add = "/v1/bulk/file-add"
const FNC1B_Bulk_File_Update = "/v1/bulk/file-update  "
const FNC1B_Bulk_File_Delete = "/v1/bulk/file-delete"
const FNC1B_Bulk_Add = "/v1/bulk"
const FNC1B_Bulk_Update = "/v1/bulk"
const FNC1B_Bulk_Delete = "/v1/bulk"
const FNC1B_Bulk_Data = "/v1/bulk/data"
const FNC1B_Bulk_Delete_Data = "/v1/bulk/{0}"
const FNC1B_Bulk_Check = "/v1/bulk/{0}/check"
const FNC1B_Export = "/v1/file/export"
const FNC1B_Get_Flight_Info = "/v1/flight-information"

export const SCHEDULE_ENDPOINT = {
  FNC1B_Add_Schedule,
  FNC1B_Add_Schedule_Draft,
  FNC1B_Bulk_Add,
  FNC1B_Bulk_Check,
  FNC1B_Bulk_Data,
  FNC1B_Bulk_Delete,
  FNC1B_Bulk_Delete_Data,
  FNC1B_Bulk_File_Add,
  FNC1B_Bulk_File_Delete,
  FNC1B_Bulk_File_Update,
  FNC1B_Bulk_Update,
  FNC1B_Delete_Schedule,
  FNC1B_Delete_Schedules,
  FNC1B_Export,
  FNC1B_Get_Flight_Info,
  FNC1B_Get_Schedule,
  FNC1B_Get_Schedule_Draft,
  FNC1B_Get_Schedules,
  FNC1B_Update_Schedule,
}
