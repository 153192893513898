import { useHistory } from "react-router-dom"
import { useTableConfig } from "../useTableConfig"
import { useRowSelection } from "../useRowSelection"
import { useState } from "react"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { UploadResultsType } from "../../../../model/uploadResults"

export const useVariables = () => {
  const { push } = useHistory()
  const [batchLogInfoList, setBatchLogInfoList] = useState<UploadResultsType[]>(
    [],
  )
  const { columns } = useTableConfig()
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const { isCommonExternalSystemLinkage, isHiddenBtn } = useVariablesAuthority()
  const {
    idBatchLogSelected,
    onChange,
    onChangeTable,
    perItem,
    rowSelection,
    selectedRowKeys,
    setSelectedRowKeys,
  } = useRowSelection(batchLogInfoList)

  return {
    batchLogInfoList,
    columns,
    idBatchLogSelected,
    isCommonExternalSystemLinkage,
    isHiddenBtn,
    isLoading,
    isLoadingLayout,
    onChange,
    onChangeTable,
    perItem,
    push,
    rowSelection,
    selectedRowKeys,
    setBatchLogInfoList,
    setIsLoading,
    setIsLoadingLayout,
    setSelectedRowKeys,
  }
}
