import styled, { css } from "styled-components"
import { Regex } from "../../../../../../../constant/Regex"

const { REGEX_IS_DESKTOP } = Regex

const StyledFormSearchRight = styled.div`
  ${REGEX_IS_DESKTOP &&
  css`
    width: calc(59.25% - 30px);
    margin-left: 30px;
  `}
`

export const Styled = {
  StyledFormSearchRight,
}
