import { createIntl, createIntlCache } from "react-intl"
import { messages } from "../../_webui/i18n/messages"
import { defaultLang, getConfig } from "../../_webui/i18n/OjicaI18n"
import { AnyValue } from "../../_webui/layout/models"

const inltCache = createIntlCache()

export const translateHelper = {
  getSessionTimeOutMessage(): string {
    const enMessageE0097 =
      "A session timeout has occurred. Please log in again."
    const jaMessageE0097 =
      "セッションタイムアウトが起きました。ログインし直してください。"
    return navigator.language === defaultLang ||
      navigator.language.startsWith(defaultLang + "-")
      ? jaMessageE0097
      : enMessageE0097
  },
  translateMessage(key: string, params?: AnyValue) {
    const lang = getConfig().selectedLang
    const intl = createIntl(
      { locale: lang, messages: messages[lang] },
      inltCache,
    )
    return intl.formatMessage({ id: key }, params)
  },
}
