import { AutoComplete } from "antd"
import { FC } from "react"
import { Images } from "../../../../../../constant/Images"
import { Image } from "../../../Image"
import { ShowCount } from "../../../ShowCount"
import { useShowCount } from "../../../ShowCount/modules/useShowCount"
import { ItemAutoCompleteProps } from "./models"
import { Styled } from "./styled"

const { IMAGE_BTN_DELETE } = Images
const { StyledFormItem, StyledFormItemAutoComplete } = Styled

export const ItemAutoComplete: FC<ItemAutoCompleteProps> = (props) => {
  const {
    clearIndex,
    isBgBlue,
    isHiddenLabel,
    label,
    maxLength,
    name,
    onChange: onChange2,
    onClear: onClear2,
    onSelect,
    options,
    required,
    rules,
    tooltip,
  } = props

  const {
    countValue,
    isFocus,
    onBlur,
    onChangeAutoComplete,
    onClear,
    onFocus,
    onSearch,
    optionsNew,
    value,
  } = useShowCount({
    maxLength,
    onChange2,
    onClear2,
    options,
  })

  return (
    <StyledFormItemAutoComplete
      className="ant-form-item-auto-complete"
      isBgBlue={isBgBlue}
      label={label}
      required={required}
      tooltip={tooltip}
      isHiddenLabel={isHiddenLabel}
    >
      <StyledFormItem isHiddenLabel label={label} name={name} rules={rules}>
        <AutoComplete
          allowClear={true}
          clearIcon={<Image src={IMAGE_BTN_DELETE} />}
          filterOption={(inputValue, option) =>
            String(option?.label)
              .toUpperCase()
              .startsWith(inputValue.toUpperCase())
          }
          maxLength={maxLength}
          onBlur={onBlur}
          onChange={onChangeAutoComplete}
          onClear={() => onClear(clearIndex)}
          onFocus={onFocus}
          onSearch={onSearch}
          onSelect={onSelect}
          options={optionsNew}
          value={value}
        />
      </StyledFormItem>
      <ShowCount countValue={countValue} isFocus={isFocus} />
    </StyledFormItemAutoComplete>
  )
}
