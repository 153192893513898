import { Endpoint } from "../../../constant/Endpoint"
import { Environment } from "../../../constant/Environment"
import { apiService } from "../../apiService"

const { COMMON_ENDPOINT } = Environment
const { FNC9_Get_Departments, FNC9_Get_All_Departments } = Endpoint

export const departmentsApi = {
  getAllDepartments: () =>
    apiService({
      method: "GET",
      url: `${COMMON_ENDPOINT}${FNC9_Get_All_Departments}`,
    }),
  getDepartments: () =>
    apiService({
      data: {},
      method: "GET",
      url: `${COMMON_ENDPOINT}${FNC9_Get_Departments}`,
    }),
}
