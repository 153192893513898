import { useHistory } from "react-router-dom"
import { useTable } from "../../../../../../../../utils/hook/useTable"
import { useTableConfig } from "../useTableConfig"
import { useGridConfig } from "../useGridConfig"
import { useApi } from "../useApi"
import { useVariablesAuthority } from "../useVariablesAuthority"

export const useVariables = () => {
  const { push } = useHistory()
  const { isCommonPermissionSettings, isHiddenBtn } = useVariablesAuthority()
  const {
    bumonName,
    callApi,
    isLoadingLayout,
    patternName,
    state,
    travelerList,
  } = useApi()

  const { columns } = useTableConfig()
  const { onChange, perItem } = useTable(travelerList.length)
  const { gridItems } = useGridConfig({ bumonName, patternName })

  return {
    callApi,
    columns,
    gridItems,
    isCommonPermissionSettings,
    isHiddenBtn,
    isLoadingLayout,
    onChange,
    perItem,
    push,
    state,
    travelerList,
  }
}
