import { Key, useState } from "react"

export const useRowSelection = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [isDisabledBtnSelect, setIsDisabledBtnSelect] = useState(true)

  const onSelectChange = (newSelectedRowKeys: Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
    setIsDisabledBtnSelect(!newSelectedRowKeys.length)
  }

  const rowSelection = {
    onChange: onSelectChange,
    selectedRowKeys: selectedRowKeys,
  }

  return {
    isDisabledBtnSelect,
    rowSelection,
    selectedRowKeys,
    setSelectedRowKeys,
  }
}
