import moment, { Moment } from "moment"
import { openNotification } from "../openNotification"
import { Translate } from "../../constant/Translate"

const { MESSAGE_W0013 } = Translate

export const checkTimeoutS3 = (initDateTime?: Moment) => {
  const rangeTime = moment(moment()).diff(initDateTime, "seconds")

  if (rangeTime > 1800) {
    openNotification(MESSAGE_W0013)
    return false
  }

  return true
}
