/* eslint-disable complexity */
import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = (id: string, travelerId?: string) => {
  const {
    isCommonTravelerRefer,
    isRoleAdmin,
    isRoleSubAdmin,
    isRoleUser,
    isTravelerChange,
    isTravelerRefer,
  } = useAuthority()

  const isFromMenu = id === travelerId

  const isNotAccessScreen =
    (isFromMenu && !isCommonTravelerRefer) || (!isFromMenu && !isTravelerRefer)

  const isHiddenBtn = isRoleAdmin || isRoleSubAdmin || isRoleUser

  const isHiddenBtnCancel =
    isHiddenBtn &&
    ((isFromMenu && isCommonTravelerRefer) || (!isFromMenu && isTravelerRefer))

  const isHiddenBtnSubmit =
    isHiddenBtn &&
    ((isFromMenu && isCommonTravelerRefer) || (!isFromMenu && isTravelerChange))

  return {
    isHiddenBtnCancel,
    isHiddenBtnSubmit,
    isNotAccessScreen,
    isRoleAdmin,
    isRoleSubAdmin,
    isRoleUser,
  }
}
