/* eslint-disable max-lines */
import styled, { css } from "styled-components"
import { Regex } from "../../../../../constant/Regex"
import { Colors } from "../../../../../constant/Styled/Colors"
import { Dropdown } from "../../Dropdown"
import { Image } from "../../Image"

const { LIGHT, LIGHT_RGB, PRIMARY } = Colors
const { REGEX_IS_DESKTOP } = Regex

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  ${REGEX_IS_DESKTOP
    ? css`
        background-color: ${LIGHT};
        height: 60px;
        padding-left: 28px;
        padding-right: 48px;
        min-width: 1200px;
      `
    : css`
        background-color: rgba(${LIGHT_RGB}, 0.8);
        height: 40px;
        padding-left: 21px;
        padding-right: 21px;
        align-items: center;
        border-bottom: 2px solid ${PRIMARY};
        &.active {
          background-color: ${LIGHT};
          left: 0;
          position: fixed;
          right: 0;
          top: 0;
          z-index: 999;
        }
      `}
`

const StyledHeaderAction = styled.div`
  align-items: center;
  display: flex;
`

const StyledHeaderActionItem = styled(Dropdown)`
  align-items: center;
  background-color: ${LIGHT};
  border: none;
  cursor: pointer;
  display: flex;
  margin-left: 36px;
  margin-right: 36px;
  padding: 0;
`

const StyledHeaderLogo = styled.div`
  align-items: center;
  display: flex;
  ${!REGEX_IS_DESKTOP &&
  css`
    height: 100%;
    justify-content: center;
    width: 100%;
  `}
`

const StyledHeaderImage = styled(Image)`
  width: ${REGEX_IS_DESKTOP ? "230px" : "190px"};
`

const StyledHeaderMenu = styled.div`
  position: relative;
  &.active {
    .header {
      &-menu {
        &-icon {
          &-bar {
            &-1 {
              transform: translate(0, 8px) rotate(-45deg);
            }
            &-2 {
              opacity: 0;
            }
            &-3 {
              transform: translate(0, -8px) rotate(45deg);
            }
          }
        }
        &-sidebar {
          opacity: 1;
          transform: translateX(0);
          visibility: visible;
        }
      }
    }
  }
`

const StyledHeaderMenuIcon = styled.div`
  cursor: pointer;
  position: absolute;
  right: 0;
  top: -14px;
`

const StyledHeaderMenuIconBar = styled.div`
  background-color: ${PRIMARY};
  width: 20px;
  height: 2px;
  margin: 6px 0;
  transition: all ease-in-out 0.3s;
`

const StyledHeaderMenuSideBar = styled.div`
  opacity: 0;
  position: fixed;
  top: 40px;
  right: 0;
  transform: translateX(${REGEX_IS_DESKTOP ? "500px" : "100vw"});
  transition: all ease-in-out 0.3s;
  visibility: hidden;
  z-index: 999;
`

export const Styled = {
  StyledHeader,
  StyledHeaderAction,
  StyledHeaderActionItem,
  StyledHeaderImage,
  StyledHeaderLogo,
  StyledHeaderMenu,
  StyledHeaderMenuIcon,
  StyledHeaderMenuIconBar,
  StyledHeaderMenuSideBar,
}
