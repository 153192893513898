/* eslint-disable max-statements */
import { Key, useEffect } from "react"
import { AttentionsListType } from "../../../../models/list"
import { useVariablesRowSelection } from "../useVariablesRowSelection"
import { concat } from "../../../../../../../utils/concat"
import { GeneralCode } from "../../../../../../../constant/GeneralCode"

const { IPTC003 } = GeneralCode

export const useRowSelection = (
  attentionInfoList: AttentionsListType[],
  isRoleSubAdmin: boolean,
) => {
  const {
    attentionDetailId,
    attentionId,
    currentData,
    isDisabledDetailBtn,
    isDisabledResponseBtn,
    isDisabledResponseHistoryBtn,
    onChangePage,
    perItem,
    selectedRowKeys,
    setAttentionDetailId,
    setAttentionId,
    setCurrentData,
    setIsDisabledDetailBtn,
    setIsDisabledResponseBtn,
    setIsDisabledResponseHistoryBtn,
    setSelectedRowKeys,
  } = useVariablesRowSelection()

  const onSelectChange = (newSelectedRowKeys: Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
    if (newSelectedRowKeys.length === 1) {
      const findData = currentData.find(
        (item) => item.id === newSelectedRowKeys[0],
      )
      if (isRoleSubAdmin) {
        const isSeverityLow = findData?.severity === IPTC003
        const _isDisabledBtn = !!findData?.isLoginUser
        setIsDisabledResponseBtn(!_isDisabledBtn || isSeverityLow)
        setIsDisabledResponseHistoryBtn(!_isDisabledBtn)
      } else {
        setIsDisabledResponseBtn(false)
        setIsDisabledResponseHistoryBtn(false)
      }
      setIsDisabledDetailBtn(false)
      setAttentionId(concat(findData?.attentionId))
      setAttentionDetailId(concat(findData?.attentionDetailId))
    } else {
      setIsDisabledDetailBtn(true)
      setIsDisabledResponseBtn(true)
      setIsDisabledResponseHistoryBtn(true)
    }
  }

  const rowSelection = {
    hideSelectAll: true,
    onChange: onSelectChange,
    selectedRowKeys: selectedRowKeys,
  }

  const onChange = (page: number, pageSize: number) => {
    onChangePage(page, pageSize)
    setSelectedRowKeys([])
    setIsDisabledDetailBtn(true)
    setIsDisabledResponseBtn(true)
    setIsDisabledResponseHistoryBtn(true)
  }

  useEffect(() => {
    if (attentionInfoList.length) setCurrentData(attentionInfoList)
  }, [attentionInfoList])

  return {
    attentionDetailId,
    attentionId,
    isDisabledDetailBtn,
    isDisabledResponseBtn,
    isDisabledResponseHistoryBtn,
    onChange,
    onChangePage,
    perItem,
    rowSelection,
  }
}
