import { getFullName } from "../../../../../../../../../../utils/getFullName"
import {
  SafetiesDetailBaseType,
  SafetiesDetailInfoType,
} from "../../../../../../../models/detail"
import { heading } from "./heading"
import { getValueByLanguage } from "../../../../../../../../../../utils/getValueByLanguage"
import {
  formatDateTimeExport,
  getDateTime,
  getDateTimeNow,
  getDay,
} from "../../../../../../../../../../utils/getDate"
import { getProxyResponser } from "../../../../../../../../../../utils/getProxyResponser"
import { getDispatchTypeDetailName } from "../../../../../../../../../../utils/getDispatchTypeDetailName"

export const convertDataExport = (
  data: SafetiesDetailBaseType[],
  safetyInfo?: SafetiesDetailInfoType,
) => {
  let convertData = heading
  data.map(
    (item) =>
      (convertData += `\r\n"${safetyInfo?.safetyTitle}","${formatDateTimeExport(
        safetyInfo?.implementationDate,
      )}","${getDateTimeNow()}","${item.safetyStatusName}","${getProxyResponser(
        item.proxyResponser,
      )}","${formatDateTimeExport(item.safetyResponseDate)}","${getFullName(
        item.kanjiSurname,
        item.kanjiName,
        true,
      )}","${getFullName(item.romajiSurname, item.romajiName)}","${getFullName(
        item.kanjiPassportSurname,
        item.kanjiPassportName,
        true,
      )}","${getFullName(
        item.romajiPassportSurname,
        item.romajiPassportName,
      )}","${item.dispatchTypeName}","${getDispatchTypeDetailName(
        item.personDispatcherName,
        item.dispatchTypeDetailId,
        item.dispatchTypeDetailName,
      )}","${item.bumonName}","${item.passportNumber}","${item.phoneNumber}","${
        item.message
      }","${item.travelerLocation}","${item.familySafetyStatusName}","${
        item.othersRemarks1
      }","${item.othersRemarks2}","${item.othersRemarks3}","${
        item.othersRemarks4
      }","${item.othersRemarks5}","${item.locationPersonName}","${
        item.lodgingName
      }","${item.lodgingAddress}","${item.lodgingPhoneNumber}","${
        item.lodgingCheckin
          ? getDay(item.lodgingCheckin, true) +
            "-" +
            getDay(item.lodgingCheckout, true)
          : ""
      }","${getValueByLanguage(item.projectNameJp, item.projectNameEn)}","${
        item.mainPersonName
      }","${
        getDay(item.dispatchStartDate) + "-" + getDay(item.dispatchEndDate)
      }","${item.departureFlightNumber}","${item.transportation}","${
        item.departureCountryName
      }","${item.departureCityName}","${getDateTime(
        item.departureDateTime,
      )}","${item.arrivalCountryName}","${item.arrivalCityName}","${getDateTime(
        item.arrivalDateTime,
      )}","${item.urgentContactHome}","${
        item.personCompanyPosition.split("・")[0]
      }","${item.urgentAffiliationContact}","${item.insuranceCompanyName}","${
        item.insuranceCompanyPhoneNumber
      }","${item.insuranceTicketNumber}","${item.insuranceSonsignor}","${
        item.managerRemarks1
      }","${item.managerRemarks2}","${item.managerRemarks3}","${
        item.managerRemarks4
      }","${item.managerRemarks5}"`),
  )
  return convertData
}
