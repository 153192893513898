import styled from "styled-components"
import { Regex } from "../../../../../../../constant/Regex"

const { REGEX_IS_DESKTOP } = Regex

const StyledAttentionsLabel = styled.span`
  padding-right: 20px;
  display: ${!REGEX_IS_DESKTOP && "block"};
`

export const Styled = {
  StyledAttentionsLabel,
}
