import Excel from "exceljs"

export const cellConfig = (worksheet: Excel.Worksheet) => {
  worksheet.eachRow((sheetColumn, rowNumber) => {
    sheetColumn.eachCell((cell) => {
      if (rowNumber > 1) {
        cell.style = {
          alignment: {
            horizontal: "center",
            vertical: "middle",
            wrapText: true,
          },
        }
        cell.border = {
          bottom: {
            color: {
              argb: "000000",
            },
            style: "thin",
          },
          left: {
            color: {
              argb: "000000",
            },
            style: "thin",
          },
          right: {
            color: {
              argb: "000000",
            },
            style: "thin",
          },
          top: {
            color: {
              argb: "000000",
            },
            style: "thin",
          },
        }
      }
      if (rowNumber === 2) {
        cell.fill = {
          fgColor: { argb: "99cc01" },
          pattern: "solid",
          type: "pattern",
        }
      }
    })
    if (rowNumber > 2) {
      sheetColumn.getCell(1).alignment = {
        horizontal: "center",
        vertical: "top",
        wrapText: true,
      }
    }
  })

  worksheet.mergeCells("G2:H2")
  worksheet.mergeCells("I2:J2")
}
