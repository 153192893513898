/* eslint-disable max-lines */
import styled, { css } from "styled-components"
import { Images } from "../../../../../../constant/Images"
import { Regex } from "../../../../../../constant/Regex"
import { Colors } from "../../../../../../constant/Styled/Colors"
import { Card } from "../../../../../../_webui/layout/components/Card"
import { ChangeLanguage } from "../../../../../../_webui/layout/components/ChangeLanguage"
import { Fonts } from "../../../../../../constant/Styled/Fonts"

const { BG_WIDGET, BORDER, BOX_SHADOW, LIGHT, PRIMARY, TEXT } = Colors
const { FS_LARGE } = Fonts
const { IMAGE_BG_TIMEZONE } = Images
const { REGEX_IS_DESKTOP } = Regex

const StyledHomeCard = styled(Card)`
  padding-bottom: 0;
  padding-top: 0;
  .card-heading {
    padding-bottom: 10px;
    padding-top: 9px;
  }
  ${!REGEX_IS_DESKTOP &&
  css`
    .card-heading-divider {
      background-color: ${PRIMARY};
    }
  `}
`

const StyledHomeWidgetContent = styled(StyledHomeCard)``

const StyledHomeWidget = styled.div`
  background-color: ${BG_WIDGET};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  width: 100%;
  ${REGEX_IS_DESKTOP &&
  css`
    background-image: url(${IMAGE_BG_TIMEZONE});
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 10px;
    box-shadow: 0px 0px 6px ${BOX_SHADOW};
    min-width: 880px;
  `}
  ${StyledHomeWidgetContent} {
    background-color: transparent;
    box-shadow: none;
    min-width: initial;

    ${REGEX_IS_DESKTOP &&
    css`
      max-width: 540px;
      @media (max-width: 1400px) {
        max-width: 440px;
      }
    `}
  }
`

const StyledChangeLanguage = styled(ChangeLanguage)`
  background-color: ${LIGHT};
  border-radius: 8px;
  border: 1px solid ${BORDER};
  color: ${TEXT};
  cursor: pointer;
  font-size: ${FS_LARGE};
  height: 44px;
  margin-left: 0;
  margin-right: 0;
  padding: 6px 8px 7px;
  text-align: center;
  width: 100%;
  .change-language-text {
    text-align: left;
    width: 100%;
  }
  ${REGEX_IS_DESKTOP &&
  css`
    margin-top: 53px;
  `}
`

const StyledHomeNotice = styled(StyledHomeCard)`
  .card-heading {
    display: block;
    text-align: center;
  }
  ${!REGEX_IS_DESKTOP &&
  css`
    .card-heading {
      &-left {
        text-align: left;
      }
      &-text {
        padding-left: 0;
      }
    }
  `}
`

const StyledHomeNoticeCategory = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  min-width: ${REGEX_IS_DESKTOP ? "140px" : "110px"};
  ${!REGEX_IS_DESKTOP &&
  css`
    .ant-select {
      height: 32px;
      display: flex;
      align-items: center;
      .ant-select {
        &-arrow {
          margin-top: 0;
          position: relative;
          right: 0;
          top: 0;
          vertical-align: 0;
        }
        &-selector {
          font-size: 12px;
          width: 100%;
        }
        &-suffix {
          svg {
            width: 10px;
          }
        }
      }
    }
  `}
`

export const Styled = {
  StyledChangeLanguage,
  StyledHomeNotice,
  StyledHomeNoticeCategory,
  StyledHomeWidget,
  StyledHomeWidgetContent,
}
