import { FC } from "react"
import { Button } from "../../../../../_webui/layout/components/Button"
import { Center } from "../../../../../_webui/layout/components/Center"
import { Translate } from "../../../../../constant/Translate"
import { Alert } from "../../../../../_webui/layout/components/Alert"
import { Breadcrumbs } from "../../../../../constant/Breadcrumbs"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { Card } from "../../../../../_webui/layout/components/Card"
import { useContactSuccess } from "./modules/useContactSuccess"

const { TEXT_T018, TEXT_T680, TEXT_T682 } = Translate
const { BRCR_SCR993 } = Breadcrumbs

export const ContactSuccess: FC = () => {
  const { goToHome, isLoadingLayout } = useContactSuccess()

  return (
    <Layout
      breadcrumbItems={BRCR_SCR993}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T680}
    >
      <Card heading={TEXT_T680} isFullHeight>
        <Alert type="success" content={TEXT_T682} />
        <Center>
          <Button onClick={goToHome} text={TEXT_T018} type="ghost" />
        </Center>
      </Card>
    </Layout>
  )
}
