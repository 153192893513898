/* eslint-disable max-lines */
import { FC } from "react"
import { Card } from "../../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../../_webui/layout/components/Layout"
import { Upload } from "../../../../../../_webui/layout/components/Upload"
import { Breadcrumbs } from "../../../../../../constant/Breadcrumbs"
import { File } from "../../../../../../constant/File"
import { Translate } from "../../../../../../constant/Translate"
import { FilesUploadButtonGroup } from "../../../components/files/upload/ButtonGroup"
import { FilesUploadForm } from "../../../components/files/upload/Form"
import { FilesUploadModalConfirm } from "../../../components/files/upload/ModalConfirm"
import { FilesUploadTable } from "../../../components/files/upload/Table"
import { useFilesUpload } from "./modules/useUpload"

const { TEXT_T116, TEXT_T506 } = Translate
const { FILE_TYPE_ALLOW_UPLOAD_ACCEPT } = File
const { BRCR_SCR971 } = Breadcrumbs

export const FilesUpload: FC = () => {
  const {
    allCountries,
    beforeUpload,
    columns,
    currentUsage,
    dataSource,
    fileNameList,
    fileTypeList,
    form,
    goToFileManager,
    isCountry,
    isDisabledCountries,
    isHiddenBtn,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenConfirm,
    isOpenConfirmDuplicate,
    isResetTable,
    isStateFileType,
    messageConfirmDuplicate,
    onCancel,
    onCancelConfirm,
    onCancelConfirmDuplicate,
    onChangeCountryCode,
    onChangeFileType,
    onChangePagination,
    onChangeTable,
    onDisableFiles,
    onOkConfirm,
    onOkConfirmDuplicate,
    onOpen,
    onOpenConfirm,
    perItem,
    requiredCountryCode,
    requiredFileType,
    rowSelection,
  } = useFilesUpload()

  return (
    <Layout
      breadcrumbItems={BRCR_SCR971}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T506}
    >
      <Card heading={TEXT_T506} isLoading={isLoading}>
        <FilesUploadForm
          allCountries={allCountries}
          fileTypeList={fileTypeList}
          form={form}
          isCountry={isCountry}
          isDisabledCountries={isDisabledCountries}
          isStateFileType={isStateFileType}
          onChangeCountryCode={onChangeCountryCode}
          onChangeFileType={onChangeFileType}
          requiredCountryCode={requiredCountryCode}
          requiredFileType={requiredFileType}
        />
        <Upload
          accept={FILE_TYPE_ALLOW_UPLOAD_ACCEPT}
          beforeUpload={beforeUpload}
          customRequest={() => ({})}
          fileNameList={fileNameList}
          isMaxCount={false}
          multiple
          name={TEXT_T116}
          titleLeft={TEXT_T116}
        />
        <FilesUploadTable
          columns={columns}
          currentUsage={currentUsage}
          dataSource={dataSource}
          isResetTable={isResetTable}
          onChangePagination={onChangePagination}
          onChangeTable={onChangeTable}
          perItem={perItem}
          rowSelection={rowSelection}
        />
        <FilesUploadButtonGroup
          dataLength={dataSource?.length}
          goToFileManager={goToFileManager}
          isHiddenBtn={isHiddenBtn}
          onOpen={onOpen}
          onOpenConfirm={onOpenConfirm}
          selectedRowKeysLength={rowSelection?.selectedRowKeys?.length}
        />
        <FilesUploadModalConfirm
          isOpen={isOpen}
          isOpenConfirm={isOpenConfirm}
          isOpenConfirmDuplicate={isOpenConfirmDuplicate}
          messageConfirmDuplicate={messageConfirmDuplicate}
          onCancel={onCancel}
          onCancelConfirm={onCancelConfirm}
          onCancelConfirmDuplicate={onCancelConfirmDuplicate}
          onDisableFiles={onDisableFiles}
          onOkConfirm={onOkConfirm}
          onOkConfirmDuplicate={onOkConfirmDuplicate}
        />
      </Card>
    </Layout>
  )
}
