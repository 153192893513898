import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../_webui/layout/components/Table/models"
import { Translate } from "../../../../../../../constant/Translate"
import { getDateTimeZoneByUserInformation } from "../../../../../../../utils/getDate"
import { UploadResultsType } from "../../../../model/uploadResults"
import { Styled } from "./styled"
import { Images } from "../../../../../../../constant/Images"
import { Fragment } from "react"

const {
  TEXT_T626,
  TEXT_T627,
  TEXT_T179,
  TEXT_T501,
  TEXT_T635,
  TEXT_T634,
  TEXT_T490,
} = Translate
const { IMAGE_ALERT_CIRCLE } = Images
const { StyledStatusImage } = Styled
const getBatchLogStatus = (status: number) =>
  status === 0 ? TEXT_T635 : status === 1 ? TEXT_T634 : TEXT_T490

export const useTableConfig = (): UseTableConfigType<UploadResultsType> => {
  const columns: ColumnType<UploadResultsType>[] = [
    {
      dataIndex: "startTime",
      render: (_, data) => getDateTimeZoneByUserInformation(data.startTime),
      sorter: (a, b) => a.startTime.localeCompare(b.startTime),
      title: TEXT_T626,
      width: 250,
    },
    {
      dataIndex: "endTime",
      render: (_, data) => getDateTimeZoneByUserInformation(data.endTime),
      sorter: (a, b) => a.endTime.localeCompare(b.endTime),
      title: TEXT_T627,
      width: 250,
    },
    {
      dataIndex: "status",
      render: (_, data) => (
        <Fragment>
          {getBatchLogStatus(data.status)}
          {data.subStatus === 1 && (
            <StyledStatusImage src={IMAGE_ALERT_CIRCLE} />
          )}
        </Fragment>
      ),
      sorter: (a, b) =>
        getBatchLogStatus(a.status).localeCompare(getBatchLogStatus(b.status)),
      title: TEXT_T179,
      width: 250,
    },
    {
      dataIndex: "fileName",
      sorter: (a, b) => a.fileName.localeCompare(b.fileName),
      title: TEXT_T501,
      width: 250,
    },
  ]
  return {
    columns,
  }
}
