import { useHistory } from "react-router-dom"
import { useForm } from "../useForm"

export const useVariables = () => {
  const { push } = useHistory()
  const {
    callApi,
    departments,
    dispatchType,
    form,
    isAccompanyingFamily,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isOpen,
    isRoleSubAdmin,
    isTravelerRegister,
    onBlurUserId,
    onCancel,
    onFinish,
    onOk,
    onValuesChange,
  } = useForm()

  return {
    callApi,
    departments,
    dispatchType,
    form,
    isAccompanyingFamily,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isOpen,
    isRoleSubAdmin,
    isTravelerRegister,
    onBlurUserId,
    onCancel,
    onFinish,
    onOk,
    onValuesChange,
    push,
  }
}
