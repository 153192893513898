import styled, { createGlobalStyle, css } from "styled-components"
import { Regex } from "../../../../../../../constant/Regex"
import { Colors } from "../../../../../../../constant/Styled/Colors"
import { StyledPickerCustomProps } from "../../DatePicker/models"

const { FORM_ENTRY_BORDER } = Colors
const { REGEX_IS_DESKTOP } = Regex

const StyledPickerCustom = styled.div<StyledPickerCustomProps>`
  ${() =>
    !REGEX_IS_DESKTOP &&
    css`
      flex-wrap: wrap;
      border: none;
      .ant {
        &-date {
          &-time {
            &-picker {
              &-date {
                max-width: initial;
              }
              &-hour {
                padding-right: 5px;
              }

              &-minutes {
                padding-left: 5px;
              }
              &-hour,
              &-minutes {
                max-width: calc(50%);
                margin-top: 20px !important;
                &::before {
                  display: none;
                }
              }
            }
          }
        }
        &-picker,
        &-select-selector {
          border: 1px solid ${FORM_ENTRY_BORDER};
        }
      }
    `}
`

export const GlobalStyleAntPicker = createGlobalStyle`
${
  !REGEX_IS_DESKTOP &&
  css`
    .ant {
      &-date {
        &-time {
          &-picker {
            .ant {
              &-picker {
                margin-top: 0;
              }
            }
          }
        }
      }
    }
  `
}
`

export const Styled = {
  StyledPickerCustom,
}
