const SCR412 = "/safety"
const SCR413 = "/safeties/edit"
const SCR415 = "/safeties/success/:id"
const SCR416 = "/safeties"
const SCR416_HISTORY = "/safeties/history"
const SCR417 = "/safeties/detail/:id"
const SCR418 = "/safeties/info"
const SCR419 = "/safeties/answer"
const SCR420 = "/safeties/answer-success"

export const SafetiesRouters = {
  SCR412,
  SCR413,
  SCR415,
  SCR416,
  SCR416_HISTORY,
  SCR417,
  SCR418,
  SCR419,
  SCR420,
}
