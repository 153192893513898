import { FC, Fragment } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { ModalConfirm } from "../../../../../../_webui/layout/components/Modal/Confirm"
import { SchedulesBatchDeleteModalConfirmProps } from "../../../models/batchDelete"

const { MESSAGE_C0025, MESSAGE_C0019 } = Translate

export const SchedulesBatchDeleteModalConfirm: FC<
  SchedulesBatchDeleteModalConfirmProps
> = (props) => {
  const {
    bulkDelete,
    bulkDeleteDataConfirm,
    isOpen,
    isOpenDeleted,
    onCancel,
    onCancelDeleted,
  } = props

  return (
    <Fragment>
      <ModalConfirm isOpen={isOpen} onCancel={onCancel} onOk={bulkDelete}>
        {MESSAGE_C0025}
      </ModalConfirm>
      <ModalConfirm
        isOpen={isOpenDeleted}
        onCancel={onCancelDeleted}
        onOk={bulkDeleteDataConfirm}
      >
        {MESSAGE_C0019}
      </ModalConfirm>
    </Fragment>
  )
}
