/* eslint-disable max-lines */
import { Table } from "antd"
import { ItemInput } from "../../../../../../../../_webui/layout/components/Form/Item/Input"
import { ItemLabel } from "../../../../../../../../_webui/layout/components/Form/Item/Label"
import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../../_webui/layout/components/Table/models"
import { Tooltip } from "../../../../../../../../_webui/layout/components/Tooltip"
import { LabelRequired } from "../../../../../../../../_webui/layout/components/Typography/LabelRequired"
import { Translate } from "../../../../../../../../constant/Translate"
import { getValueByLanguage } from "../../../../../../../../utils/getValueByLanguage"
import { validatorEmailAddress } from "../../../../../../../../utils/validator/emailAddress"
import { validatorHalfSize } from "../../../../../../../../utils/validator/halfSize"
import { UseTableStaysConfigProps } from "../../../../../components/add/Form/ContentCollapse/Detail/models"
import { ScheduleInfoStaysType } from "../../../../../models/add"
import { setNameRowStay } from "../../../utils/setNameRowStay"

const {
  HELP_H0023,
  HELP_H0024,
  HELP_H0025,
  HELP_H0035,
  TEXT_T240,
  TEXT_T256,
  TEXT_T257,
  TEXT_T258,
  TEXT_T259,
  TEXT_T260,
  TEXT_T261,
} = Translate

const { SELECTION_COLUMN } = Table

export const useTableStaysConfig = (
  props: UseTableStaysConfigProps,
): UseTableConfigType<ScheduleInfoStaysType> => {
  const { getItemTableRequiredField, isRequiredItemTableByPPTV } = props

  const columns: ColumnType<ScheduleInfoStaysType>[] = [
    {
      render: (_, _data, index) => index + 1,
      title: TEXT_T240,
      width: 65,
    },
    SELECTION_COLUMN,
    {
      render: (_, data) => (
        <ItemInput
          isHidden
          isHiddenLabel
          label={TEXT_T261}
          name={`${setNameRowStay(data.index)}.travelStayId`}
        />
      ),
      title: <div className="d-none">{TEXT_T261}</div>,
      width: 0,
    },
    {
      render: (_, data) => (
        <ItemInput
          isHiddenLabel
          label={TEXT_T256}
          maxLength={20}
          name={`${setNameRowStay(data.index)}.locationPhoneNumber1`}
          rules={[...getItemTableRequiredField(), validatorHalfSize]}
        />
      ),
      title: isRequiredItemTableByPPTV ? (
        <LabelRequired label={TEXT_T256} tooltip={HELP_H0023} />
      ) : (
        <ItemLabel label={TEXT_T256} tooltip={HELP_H0023} />
      ),
      width: getValueByLanguage(330, 370),
    },
    {
      render: (_, data) => (
        <ItemInput
          isHiddenLabel
          label={TEXT_T259}
          maxLength={320}
          name={`${setNameRowStay(data.index)}.emailAddress1`}
          rules={[...getItemTableRequiredField(), validatorEmailAddress]}
        />
      ),
      title: isRequiredItemTableByPPTV ? (
        <LabelRequired label={TEXT_T259} tooltip={HELP_H0035} />
      ) : (
        TEXT_T259
      ),
      width: 250,
    },
    {
      render: (_, data) => (
        <ItemInput
          isHiddenLabel
          label={TEXT_T257}
          maxLength={20}
          name={`${setNameRowStay(data.index)}.locationPhoneNumber2`}
          rules={[validatorHalfSize]}
        />
      ),
      title: <Tooltip title={HELP_H0024}>{TEXT_T257}</Tooltip>,
      width: 300,
    },
    {
      render: (_, data) => (
        <ItemInput
          isHiddenLabel
          label={TEXT_T258}
          maxLength={20}
          name={`${setNameRowStay(data.index)}.satellitePhoneNumber`}
          rules={[validatorHalfSize]}
        />
      ),
      title: <Tooltip title={HELP_H0025}>{TEXT_T258}</Tooltip>,
      width: 250,
    },
    {
      render: (_, data) => (
        <ItemInput
          isHiddenLabel
          label={TEXT_T260}
          maxLength={320}
          name={`${setNameRowStay(data.index)}.emailAddress2`}
          rules={[validatorEmailAddress]}
        />
      ),
      title: <Tooltip title={HELP_H0035}>{TEXT_T260}</Tooltip>,
      width: 250,
    },
  ]

  return {
    columns,
  }
}
