import "./style/index.scss"
import { Select as SelectAnt } from "antd"
import { FC } from "react"
import { SelectProps } from "./models"

const { Option } = SelectAnt

export const Select: FC<SelectProps> = (props) => {
  const {
    defaultValue,
    isBordered,
    onChange,
    onClick,
    placeholder,
    selectItem,
    value,
    ...rest
  } = props
  return (
    <SelectAnt
      aria-label={placeholder}
      bordered={isBordered}
      defaultValue={defaultValue}
      onChange={onChange}
      onClick={onClick}
      placeholder={placeholder}
      value={value}
      {...rest}
    >
      {selectItem?.map((item) => (
        <Option key={item.value} value={item.value}>
          {item.text}
        </Option>
      ))}
    </SelectAnt>
  )
}
