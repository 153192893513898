import { FC } from "react"
import { Alert } from "../../../../../_webui/layout/components/Alert"
import { ButtonGroup } from "../../../../../_webui/layout/components/Button/Group"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Grid } from "../../../../../_webui/layout/components/Grid"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { BreakText } from "../../../../../_webui/layout/components/Typography/BreakText"
import { Breadcrumbs } from "../../../../../constant/Breadcrumbs"
import { Translate } from "../../../../../constant/Translate"
import { useTravelersAddSuccess } from "./modules/useAddSuccess"

const { TEXT_T015, TEXT_T016, TEXT_T018, TEXT_T019, TEXT_T020, TEXT_T534 } =
  Translate
const { BRCR_SCR113 } = Breadcrumbs

export const TravelersAddSuccess: FC = () => {
  const {
    goToHome,
    goToTravelersAdd,
    goToTravelersEdit,
    gridItems,
    isLoadingLayout,
  } = useTravelersAddSuccess()

  return (
    <Layout
      breadcrumbItems={BRCR_SCR113}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T534}
    >
      <Card heading={TEXT_T534}>
        <Alert
          type="success"
          content={<BreakText above={TEXT_T015} below={TEXT_T016} />}
        />

        <Grid gridItems={gridItems} />
        <ButtonGroup
          buttonItems={[
            {
              onClick: goToHome,
              order: 3,
              text: TEXT_T018,
              type: "ghost",
            },
            {
              onClick: goToTravelersAdd,
              order: 1,
              text: TEXT_T019,
              type: "primary",
            },
            {
              onClick: goToTravelersEdit,
              order: 2,
              text: TEXT_T020,
            },
          ]}
        />
      </Card>
    </Layout>
  )
}
