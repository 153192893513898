/* eslint-disable max-lines */
import { FC } from "react"
import { Translate } from "../../../../../../../constant/Translate"
import { Collapse } from "../../../../../../../_webui/layout/components/Collapse"
import { SchedulesAdvancedSearchCollapseProps } from "../../../../models/list/search"
import { CollapseAccommodation } from "./Accommodation"
import { CollapseAssignment } from "./Assignment"
import { CollapseContractPerson } from "./ContractPerson"
import { CollapseOther } from "./Other"
import { CollapseBasic } from "./Basic"
import { CollapsePosting } from "./Posting"
import { CollapseTrip } from "./Trip"

const {
  TEXT_T520,
  TEXT_T522,
  TEXT_T524,
  TEXT_T528,
  TEXT_T546,
  TEXT_T549,
  TEXT_T551,
} = Translate

export const SchedulesAdvancedSearchCollapse: FC<
  SchedulesAdvancedSearchCollapseProps
> = (props) => {
  const {
    allCountries,
    arrivalHourItems,
    arrivalMinuteItems,
    cities,
    departments,
    dispatchType,
    dispatchTypeDetail,
    isDefaultValueArrivalDate,
    isDefaultValueDepartureDate,
    isDisabledDispatchTypeDetail,
    isOverseasResident,
    isPathnameSchedules,
    onBlurAdvanced,
    onChangeArrivalDate,
    onChangeCountryCode,
    onChangeDepartureDate,
    onChangeDispatchType,
    onChangeWorkPlaceLocation,
    onKeyDownAdvanced,
    personPrefectureResidence,
    purposeTravel,
    transportation,
  } = props

  const panelItems = !isPathnameSchedules && [
    {
      content: (
        <CollapseBasic
          dispatchType={dispatchType}
          dispatchTypeDetail={dispatchTypeDetail}
          isDisabledDispatchTypeDetail={isDisabledDispatchTypeDetail}
          onChangeDispatchType={onChangeDispatchType}
        />
      ),
      header: TEXT_T520,
      key: 1,
    },
    {
      content: (
        <CollapseContractPerson
          personPrefectureResidence={personPrefectureResidence}
        />
      ),
      header: TEXT_T522,
      key: 2,
    },
    {
      content: (
        <CollapsePosting
          allCountries={allCountries}
          cities={cities}
          isOverseasResident={isOverseasResident}
          onChangeCountryCode={onChangeCountryCode}
          onChangeWorkPlaceLocation={onChangeWorkPlaceLocation}
        />
      ),
      header: TEXT_T524,
      key: 3,
    },
    {
      content: <CollapseOther />,
      header: TEXT_T528,
      key: 4,
    },
  ]

  return (
    <Collapse
      defaultActiveKey={["1", "2", "3", "4", "5", "6", "7"]}
      panelItems={[
        ...(panelItems || []),
        {
          content: <CollapseAssignment />,
          header: TEXT_T551,
          key: 5,
        },
        {
          content: (
            <CollapseTrip
              departments={departments}
              allCountries={allCountries}
              arrivalHourItems={arrivalHourItems}
              arrivalMinuteItems={arrivalMinuteItems}
              isDefaultValueArrivalDate={isDefaultValueArrivalDate}
              isDefaultValueDepartureDate={isDefaultValueDepartureDate}
              isPathnameSchedules={isPathnameSchedules}
              onBlurAdvanced={onBlurAdvanced}
              onChangeArrivalDate={onChangeArrivalDate}
              onChangeDepartureDate={onChangeDepartureDate}
              onKeyDownAdvanced={onKeyDownAdvanced}
              purposeTravel={purposeTravel}
              transportation={transportation}
            />
          ),
          header: TEXT_T546,
          key: 6,
        },
        {
          content: <CollapseAccommodation />,
          header: TEXT_T549,
          key: 7,
        },
      ]}
    />
  )
}
