import { useHistory, useLocation } from "react-router-dom"
import { AttentionStateType } from "../../../../models/attention"
import { useModal } from "../../../../../../../utils/hook/useModal"
import { Key, useState } from "react"
import { AttentionDataTableType } from "../../../../components/attention/Form/models"
import { useRowSelection } from "../useRowSelection"
import { Form } from "antd"

export const useVariablesApi = () => {
  const [form] = Form.useForm()
  const { push } = useHistory()
  const { state } = useLocation<AttentionStateType>()
  const { isOpen, onCancel, onOpen } = useModal()
  const {
    isOpen: isOpenForm,
    onCancel: onCancelForm,
    onOpen: onOpenForm,
  } = useModal()
  const [isLoading, setIsLoading] = useState(false)
  const [safetiesSelected, setSafetiesSelected] = useState("")
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([])
  const [safetiesList, setSafetiesList] = useState<AttentionDataTableType[]>([])
  const [safetiesAll, setSafetiesAll] = useState<AttentionDataTableType[]>([])
  const {
    isDisabledBtnSelect,
    rowSelection: rowSelectionAttention,
    selectedRowKeys,
    setSelectedRowKeys,
  } = useRowSelection()

  return {
    form,
    isDisabledBtnSelect,
    isLoading,
    isOpen,
    isOpenForm,
    onCancel,
    onCancelForm,
    onOpen,
    onOpenForm,
    push,
    rowSelectionAttention,
    safetiesAll,
    safetiesList,
    safetiesSelected,
    selectedKeys,
    selectedRowKeys,
    setIsLoading,
    setSafetiesAll,
    setSafetiesList,
    setSafetiesSelected,
    setSelectedKeys,
    setSelectedRowKeys,
    state,
  }
}
