import { Translate } from "../../../../../../../../constant/Translate"
import { getDateTimeZoneByUserInformation } from "../../../../../../../../utils/getDate"
import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../../_webui/layout/components/Table/models"
import { FilesListDataSourceType } from "../../../../../models/files/list"

const { TEXT_T055, TEXT_T065, TEXT_T501, TEXT_T502, TEXT_T503, TEXT_T504 } =
  Translate

export const useTableConfig = (
  isCountry: boolean,
): UseTableConfigType<FilesListDataSourceType> => {
  const countryNameColumn: ColumnType<FilesListDataSourceType>[] = isCountry
    ? [
        {
          dataIndex: "countryName",
          sorter: (a, b) => a.countryName.localeCompare(b.countryName),
          title: TEXT_T055,
          width: 200,
        },
      ]
    : []
  const columns: ColumnType<FilesListDataSourceType>[] = [
    {
      dataIndex: "fileName",
      sorter: (a, b) => a.fileName.localeCompare(b.fileName),
      title: TEXT_T501,
      width: 280,
    },
    {
      dataIndex: "createdDate",
      render: (data) => getDateTimeZoneByUserInformation(data),
      sorter: (a, b) => a.createdDate.localeCompare(b.createdDate),
      title: TEXT_T502,
      width: 280,
    },
    {
      dataIndex: "createdAuthor",
      sorter: (a, b) => a.createdAuthor.localeCompare(b.createdAuthor),
      title: TEXT_T503,
      width: 200,
    },
    {
      dataIndex: "fileTypeName",
      sorter: (a, b) => a.fileTypeName.localeCompare(b.fileTypeName),
      title: TEXT_T065,
      width: 200,
    },
    ...countryNameColumn,
    {
      dataIndex: "fileSize",
      render: (_, data) => `${Math.round(data.fileSize)}KB`,
      sorter: (a, b) => +a.fileSize - +b.fileSize,
      title: TEXT_T504,
      width: 100,
    },
  ]
  return {
    columns,
  }
}
