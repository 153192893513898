import { FC, Fragment } from "react"
import { ModalConfirm } from "../../../../../../_webui/layout/components/Modal/Confirm"
import { Translate } from "../../../../../../constant/Translate"
import { SchedulesModalConfirmProps } from "./models"
import { replaceStringParam } from "../../../../../../utils/replaceStringParam"

const { MESSAGE_I0020, MESSAGE_C0043, TEXT_T229 } = Translate

export const SchedulesModalConfirm: FC<SchedulesModalConfirmProps> = (
  props,
) => {
  const {
    isOpen,
    isOpenLoadData,
    isOpenShowData,
    messageConfirmDownload,
    onCancel,
    onCancelCustomLoadData,
    onOk,
    onOkLoadData,
    onOkShowData,
  } = props

  return (
    <Fragment>
      <ModalConfirm isOpen={isOpen} onCancel={onCancel} onOk={onOk}>
        {messageConfirmDownload}
      </ModalConfirm>
      <ModalConfirm
        cancelCustomText={TEXT_T229}
        cancelText=""
        isOpen={isOpenLoadData}
        onCancelCustom={onCancelCustomLoadData}
        onOk={onOkLoadData}
      >
        {replaceStringParam(MESSAGE_C0043, "240")}
      </ModalConfirm>
      <ModalConfirm
        cancelText=""
        isCenter
        isOpen={isOpenShowData}
        onOk={onOkShowData}
      >
        {MESSAGE_I0020}
      </ModalConfirm>
    </Fragment>
  )
}
