const PATTERN_ADMIN = "PAT0000001"
const PATTERN_USER = "PAT0000002"
const PATTERN_USER_1 = "PAT0000013"
const PATTERN_SUBADMIN_1 = "PAT0000003"
const PATTERN_SUBADMIN_2 = "PAT0000004"
const PATTERN_SUBADMIN_3 = "PAT0000005"
const PATTERN_SUBADMIN_4 = "PAT0000006"
const PATTERN_SUBADMIN_5 = "PAT0000007"
const PATTERN_SUBADMIN_6 = "PAT0000008"
const PATTERN_SUBADMIN_7 = "PAT0000009"
const PATTERN_SUBADMIN_8 = "PAT0000010"
const PATTERN_SUBADMIN_9 = "PAT0000011"
const PATTERN_SUBADMIN_10 = "PAT0000012"

export const PatternId = {
  PATTERN_ADMIN,
  PATTERN_SUBADMIN_1,
  PATTERN_SUBADMIN_10,
  PATTERN_SUBADMIN_2,
  PATTERN_SUBADMIN_3,
  PATTERN_SUBADMIN_4,
  PATTERN_SUBADMIN_5,
  PATTERN_SUBADMIN_6,
  PATTERN_SUBADMIN_7,
  PATTERN_SUBADMIN_8,
  PATTERN_SUBADMIN_9,
  PATTERN_USER,
  PATTERN_USER_1,
}
