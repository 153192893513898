import { FC, Fragment } from "react"
import { InputItems } from "../../../../../../../../constant/InputItems"
import { Translate } from "../../../../../../../../constant/Translate"
import { Divider } from "../../../../../../../../_webui/layout/components/Divider"
import { ItemCheckbox } from "../../../../../../../../_webui/layout/components/Form/Item/Checkbox"
import { ItemInputGroup } from "../../../../../../../../_webui/layout/components/Form/Item/Input/Group"
import { KanjiPassportFullName } from "../../../../../../../../_webui/layout/components/Typography/KanjiPassportFullName"
import { RomajiPassportFullName } from "../../../../../../../../_webui/layout/components/Typography/RomajiPassportFullName"
import { PersonalInfoInputGroupProps } from "./models"

const { HELP_H0002, HELP_H0004, TEXT_T003, TEXT_T006, TEXT_T036 } = Translate
const {
  KANJI_FULL_NAME,
  KANJI_PASSPORT_FULL_NAME,
  ROMAJI_FULL_NAME_REQUIRED,
  ROMAJI_PASSPORT_FULL_NAME_REQUIRED,
  ROMAJI_PASSPORT_FULL_NAME,
} = InputItems

export const PersonalInfoInputGroup: FC<PersonalInfoInputGroupProps> = (
  props,
) => {
  const { isDifferentInforCheck, onDifferentInforCheckChange } = props

  return (
    <Fragment>
      <ItemInputGroup
        className="ant-form-item-group-first"
        inputItems={KANJI_FULL_NAME}
        label={TEXT_T003}
        name="fullNameKanji"
      />
      <ItemInputGroup
        inputItems={ROMAJI_FULL_NAME_REQUIRED}
        label={TEXT_T006}
        name="fullNameRomaji"
        required
        tooltip={HELP_H0002}
      />
      <ItemCheckbox
        isHiddenLabel
        label={TEXT_T036}
        name="differentInforCheck"
        onChange={onDifferentInforCheckChange}
        text={TEXT_T036}
        tooltip={HELP_H0004}
      />
      <ItemInputGroup
        className="ant-form-item-group-first"
        inputItems={KANJI_PASSPORT_FULL_NAME}
        label={<KanjiPassportFullName />}
        name="kanjiPassportFullName"
      />
      {isDifferentInforCheck ? (
        <ItemInputGroup
          inputItems={ROMAJI_PASSPORT_FULL_NAME_REQUIRED}
          label={<RomajiPassportFullName />}
          name="romajiPassportFullName"
          required
          tooltip={HELP_H0002}
        />
      ) : (
        <ItemInputGroup
          inputItems={ROMAJI_PASSPORT_FULL_NAME}
          label={<RomajiPassportFullName />}
          name="romajiPassportFullName"
          tooltip={HELP_H0002}
        />
      )}
      <Divider />
    </Fragment>
  )
}
