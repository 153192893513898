import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../../_webui/layout/components/Table/models"
import { Translate } from "../../../../../../../../constant/Translate"
import { getSortOrder } from "../../../../../../../../utils/getSortOrder"
import { getValueByLanguage } from "../../../../../../../../utils/getValueByLanguage"
import { SortedInfoType } from "../../../../../../../../utils/hook/useSort/models"
import { SubAdminSettingListType } from "../../../../../models/subAdmin/setting"

const { TEXT_T002, TEXT_T003, TEXT_T006, TEXT_T025, TEXT_T461, TEXT_T466 } =
  Translate

export const useTableConfig = (
  sortedInfo: SortedInfoType,
): UseTableConfigType<SubAdminSettingListType> => {
  const columns: ColumnType<SubAdminSettingListType>[] = [
    {
      dataIndex: "fullNameKanji",
      sortOrder: getSortOrder(sortedInfo, "fullNameKanji"),
      sorter: (a, b) => a.fullNameKanji.localeCompare(b.fullNameKanji),
      title: TEXT_T003,
      width: 250,
    },
    {
      dataIndex: "fullNameRomaji",
      sortOrder: getSortOrder(sortedInfo, "fullNameRomaji"),
      sorter: (a, b) => a.fullNameRomaji.localeCompare(b.fullNameRomaji),
      title: TEXT_T006,
      width: 250,
    },
    {
      dataIndex: "dispatchTypeDetail",
      sortOrder: getSortOrder(sortedInfo, "dispatchTypeDetail"),
      sorter: (a, b) =>
        a.dispatchTypeDetail.localeCompare(b.dispatchTypeDetail),
      title: TEXT_T025,
      width: 250,
    },
    {
      dataIndex: "patternName",
      render: (_, data) =>
        getValueByLanguage(
          data.patternName.split("　")[0],
          data.patternName.split(" ").slice(0, 2).join(" "),
        ),
      sortOrder: getSortOrder(sortedInfo, "patternName"),
      sorter: (a, b) => a.patternName.localeCompare(b.patternName),
      title: TEXT_T466,
      width: 180,
    },
    {
      dataIndex: "userId",
      sortOrder: getSortOrder(sortedInfo, "userId"),
      sorter: (a, b) => a.userId.localeCompare(b.userId),
      title: TEXT_T002,
      width: 250,
    },
    {
      dataIndex: "staffNumber",
      sortOrder: getSortOrder(sortedInfo, "staffNumber"),
      sorter: (a, b) => a.staffNumber.localeCompare(b.staffNumber),
      title: TEXT_T461,
      width: getValueByLanguage(170, 200),
    },
  ]
  return {
    columns,
  }
}
