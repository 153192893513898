import { Translate } from "../../../../../../../../constant/Translate"
import { replaceString } from "../../../../../../../../utils/replaceString"
import { Grid } from "../../../../../../../../_webui/layout/components/Grid"
import { GridItemType } from "../../../../../../../../_webui/layout/components/Grid/models"
import { TabItemsProps } from "../../../../../../../../_webui/layout/components/Tabs/models"
import { ScheduleStaysType } from "../../../../../models/addSuccess"

const { TEXT_T256, TEXT_T257, TEXT_T258, TEXT_T259, TEXT_T260, TEXT_T268 } =
  Translate

export const useStayTabsConfig = (
  schedulesStays: ScheduleStaysType[],
): TabItemsProps[] =>
  schedulesStays.map((item, index) => {
    const itemGrid: GridItemType[] = [
      { key: TEXT_T256, text: item.locationPhoneNumber1 },
      { key: TEXT_T259, text: item.emailAddress1 },
      { key: TEXT_T257, text: item.locationPhoneNumber2 },
      { key: TEXT_T258, text: item.satellitePhoneNumber },
      { key: TEXT_T260, text: item.emailAddress2 },
    ]
    return {
      children: <Grid gridItems={itemGrid} />,
      key: `${index + 1}`,
      label: replaceString(TEXT_T268, ` ${index + 1}`),
    }
  })
