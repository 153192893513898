import { FC } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { ButtonGroup } from "../../../../../../_webui/layout/components/Button/Group"
import { Card } from "../../../../../../_webui/layout/components/Card"
import { Table } from "../../../../../../_webui/layout/components/Table"
import { TotalRecords } from "../../../../../../_webui/layout/components/Typography/TotalRecords"
import { SafetiesTableProps } from "../../../models/list"

const { TEXT_T332, TEXT_T336, TEXT_T334, TEXT_T335, TEXT_T371 } = Translate

export const SafetiesTable: FC<SafetiesTableProps> = (props) => {
  const {
    columns,
    goToSafetiesDetail,
    goToSafetiesResponse,
    isDisabledDetailBtn,
    isDisabledResponseBtn,
    isHiddenDetailBtn,
    isHiddenResponseBtn,
    isHiddenResponseHistoryBtn,
    isLoading,
    onChange,
    onOpenPopup,
    perItem,
    rowSelection,
    safetyInfoList,
  } = props

  return (
    <Card
      heading={TEXT_T371}
      isFullHeight
      isLoading={isLoading}
      isSearch
      isSearchIcon
      onClick={onOpenPopup}
      searchText={TEXT_T332}
      type="ghost"
    >
      <TotalRecords perItem={perItem} total={safetyInfoList?.length} />
      <Table
        columns={columns}
        dataSource={safetyInfoList}
        onChange={onChange}
        rowKey="id"
        rowSelection={rowSelection}
      />
      <ButtonGroup
        buttonItems={[
          {
            isDisabled: isDisabledResponseBtn,
            isHidden: !isHiddenResponseBtn,
            onClick: goToSafetiesResponse,
            text: TEXT_T334,
            type: "primary",
          },
          {
            isDisabled: isDisabledResponseBtn,
            isHidden: !isHiddenResponseHistoryBtn,
            onClick: () => goToSafetiesDetail(true),
            text: TEXT_T335,
            type: "primary",
          },
          {
            isDisabled: isDisabledDetailBtn,
            isHidden: !isHiddenDetailBtn,
            onClick: () => goToSafetiesDetail(false),
            text: TEXT_T336,
            type: "primary",
          },
        ]}
      />
    </Card>
  )
}
