import { Endpoint } from "../../../constant/Endpoint"
import { Environment } from "../../../constant/Environment"
import { replaceStringParam } from "../../../utils/replaceStringParam"
import { apiService } from "../../apiService"

const { COMMON_ENDPOINT } = Environment
const { FNC9_Get_Authority_Patterns, FNC9_Get_Authority_Pattern_Setting } =
  Endpoint

export const authoritiesApi = {
  getAuthorityPatternSetting: (patternId: string) =>
    apiService({
      data: {},
      method: "GET",
      url: `${COMMON_ENDPOINT}${replaceStringParam(
        FNC9_Get_Authority_Pattern_Setting,
        patternId,
      )}`,
    }),
  getAuthorityPatterns: (isInUseOnly?: boolean) =>
    apiService({
      data: {},
      method: "GET",
      url: `${COMMON_ENDPOINT}${FNC9_Get_Authority_Patterns}?isInUseOnly=${!!isInUseOnly}`,
    }),
}
