/* eslint-disable max-lines */
/* eslint-disable max-statements */
import { useEffect } from "react"
import {
  ScheduleInfoDetailsType,
  ScheduleInfoLodgingsType,
  ScheduleInfoStaysType,
} from "../../../../models/add"
import { TableRowSelection } from "../../../../../../../_webui/layout/components/Table/models"
import { CheckboxAll } from "../../../../../../../_webui/layout/components/RowSelection/CheckboxAll"
import { CheckboxItem } from "../../../../../../../_webui/layout/components/RowSelection/CheckboxItem"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { useVariablesRowSelection } from "../useVariablesRowSelection"

type UseDetailRowSelectionProps = {
  detailDataSource: ScheduleInfoDetailsType[]
  lodgingDataSource: ScheduleInfoLodgingsType[]
  stayDataSource: ScheduleInfoStaysType[]
}

export const useDetailRowSelection = (props: UseDetailRowSelectionProps) => {
  const { detailDataSource, lodgingDataSource, stayDataSource } = props
  const {
    detailSelectedRowKeys,
    isCheckAllDetail,
    isCheckAllLodging,
    isCheckAllStay,
    isDisabledCheckAllDetail,
    isDisabledCheckAllLodging,
    isDisabledCheckAllStay,
    lodgingSelectedRowKeys,
    setDetailSelectedRowKeys,
    setIsCheckAllDetail,
    setIsCheckAllLodging,
    setIsCheckAllStay,
    setIsDisabledCheckAllDetail,
    setIsDisabledCheckAllLodging,
    setIsDisabledCheckAllStay,
    setLodgingSelectedRowKeys,
    setStaySelectedRowKeys,
    staySelectedRowKeys,
  } = useVariablesRowSelection()

  const onSelectChangeDetail = () => {
    if (detailSelectedRowKeys.length === detailDataSource.length)
      setIsCheckAllDetail(true)
    else setIsCheckAllDetail(false)
  }

  const onSelectChangeLodging = () => {
    if (lodgingSelectedRowKeys.length === lodgingDataSource.length)
      setIsCheckAllLodging(true)
    else setIsCheckAllLodging(false)
  }

  const onSelectChangeStay = () => {
    if (staySelectedRowKeys.length === stayDataSource.length)
      setIsCheckAllStay(true)
    else setIsCheckAllStay(false)
  }

  const onChangeCheckAllDetail = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    setIsCheckAllDetail(checked)
    if (checked) {
      const _selectedRowKeys = detailDataSource.map(
        (item) => item.travelDetailId,
      )
      setDetailSelectedRowKeys(_selectedRowKeys)
    } else setDetailSelectedRowKeys([])
  }

  const onChangeCheckAllLodging = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    setIsCheckAllLodging(checked)
    if (checked) {
      const _selectedRowKeys = lodgingDataSource.map(
        (item) => item.travelLodgingId,
      )
      setLodgingSelectedRowKeys(_selectedRowKeys)
    } else setLodgingSelectedRowKeys([])
  }

  const onChangeCheckAllStay = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    setIsCheckAllStay(checked)
    if (checked) {
      const _selectedRowKeys = stayDataSource.map((item) => item.travelStayId)
      setStaySelectedRowKeys(_selectedRowKeys)
    } else setStaySelectedRowKeys([])
  }

  const onChangeCheckItemDetail = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    const value = event.target.value
    if (checked) setDetailSelectedRowKeys([...detailSelectedRowKeys, value])
    else {
      const _selectedRowKeys = detailSelectedRowKeys.filter(
        (item) => item !== value,
      )
      setDetailSelectedRowKeys(_selectedRowKeys)
    }
  }

  const onChangeCheckItemLodging = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    const value = event.target.value
    if (checked) setLodgingSelectedRowKeys([...lodgingSelectedRowKeys, value])
    else {
      const _selectedRowKeys = lodgingSelectedRowKeys.filter(
        (item) => item !== value,
      )
      setLodgingSelectedRowKeys(_selectedRowKeys)
    }
  }

  const onChangeCheckItemStay = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    const value = event.target.value
    if (checked) setStaySelectedRowKeys([...staySelectedRowKeys, value])
    else {
      const _selectedRowKeys = staySelectedRowKeys.filter(
        (item) => item !== value,
      )
      setStaySelectedRowKeys(_selectedRowKeys)
    }
  }

  const detailRowSelection: TableRowSelection<ScheduleInfoDetailsType> = {
    columnTitle: (
      <CheckboxAll
        checked={isCheckAllDetail}
        isDisabled={isDisabledCheckAllDetail}
        onChange={onChangeCheckAllDetail}
      />
    ),
    renderCell: (_, record) => (
      <CheckboxItem
        checked={detailSelectedRowKeys.includes(record.travelDetailId)}
        onChange={onChangeCheckItemDetail}
        value={record.travelDetailId}
      />
    ),
    selectedRowKeys: detailSelectedRowKeys,
  }

  const lodgingRowSelection: TableRowSelection<ScheduleInfoLodgingsType> = {
    columnTitle: (
      <CheckboxAll
        checked={isCheckAllLodging}
        isDisabled={isDisabledCheckAllLodging}
        onChange={onChangeCheckAllLodging}
      />
    ),
    renderCell: (_, record) => (
      <CheckboxItem
        checked={lodgingSelectedRowKeys.includes(record.travelLodgingId)}
        onChange={onChangeCheckItemLodging}
        value={record.travelLodgingId}
      />
    ),
    selectedRowKeys: lodgingSelectedRowKeys,
  }

  const stayRowSelection: TableRowSelection<ScheduleInfoStaysType> = {
    columnTitle: (
      <CheckboxAll
        checked={isCheckAllStay}
        isDisabled={isDisabledCheckAllStay}
        onChange={onChangeCheckAllStay}
      />
    ),
    renderCell: (_, record) => (
      <CheckboxItem
        checked={staySelectedRowKeys.includes(record.travelStayId)}
        onChange={onChangeCheckItemStay}
        value={record.travelStayId}
      />
    ),
    selectedRowKeys: staySelectedRowKeys,
  }

  useEffect(() => {
    if (!detailDataSource.length) {
      setIsCheckAllDetail(false)
      setIsDisabledCheckAllDetail(true)
    } else setIsDisabledCheckAllDetail(false)
  }, [detailDataSource])

  useEffect(() => {
    if (!lodgingDataSource.length) {
      setIsCheckAllLodging(false)
      setIsDisabledCheckAllLodging(true)
    } else setIsDisabledCheckAllLodging(false)
  }, [lodgingDataSource])

  useEffect(() => {
    if (!stayDataSource.length) {
      setIsCheckAllStay(false)
      setIsDisabledCheckAllStay(true)
    } else setIsDisabledCheckAllStay(false)
  }, [stayDataSource])

  useEffect(() => {
    if (detailSelectedRowKeys?.length) onSelectChangeDetail()
  }, [detailSelectedRowKeys])

  useEffect(() => {
    if (lodgingSelectedRowKeys?.length) onSelectChangeLodging()
  }, [lodgingSelectedRowKeys])

  useEffect(() => {
    if (staySelectedRowKeys?.length) onSelectChangeStay()
  }, [staySelectedRowKeys])

  return {
    detailRowSelection,
    detailSelectedRowKeys,
    lodgingRowSelection,
    lodgingSelectedRowKeys,
    setDetailSelectedRowKeys,
    setIsCheckAllDetail,
    setIsCheckAllLodging,
    setIsCheckAllStay,
    setLodgingSelectedRowKeys,
    setStaySelectedRowKeys,
    stayRowSelection,
    staySelectedRowKeys,
  }
}
