/* eslint-disable max-lines */
import styled from "styled-components"
import { Colors } from "../../../../../../../constant/Styled/Colors"
import { NavLink } from "react-router-dom"
import { Fonts } from "../../../../../../../constant/Styled/Fonts"
import { Image } from "../../../../Image"

const { MENU_ACTIVE, MENU_SUB, PRIMARY } = Colors
const { FONT_YU_GOTHIC, FS_LARGE, FW_BOLD } = Fonts

const StyledNavLink = styled(NavLink)`
  color: ${PRIMARY};
  display: block;
  padding: 10px 15px;
  &:hover {
    background-color: ${MENU_ACTIVE};
  }
  &.home {
    text-align: center;
  }
  &.active {
    background-color: ${MENU_ACTIVE};
    font-weight: ${FW_BOLD};
  }
`

const StyledNavLinkItem = styled(StyledNavLink)`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

const StyledImage = styled(Image)`
  height: 12px;
  margin-left: 10px;
  min-width: 14px;
  max-width: 14px;
`

const StyledNavContent = styled.ul`
  background-color: ${MENU_SUB};
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  list-style: none;
  margin-left: 3px;
  margin: 0;
  opacity: 0;
  padding-left: 0;
  position: absolute;
  transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  transform: translate(40px, -46px);
  visibility: hidden;
  width: 255px;
  z-index: 999;
`

const StyledNavTitle = styled.button`
  background-color: transparent;
  border: none;
  color: ${PRIMARY};
  cursor: pointer;
  font-family: ${FONT_YU_GOTHIC};
  font-size: ${FS_LARGE};
  height: 46px;
  padding: 10px 15px;
  position: relative;
  text-align: center;
  width: 100%;
  &:hover {
    background-color: ${MENU_ACTIVE};
  }
`
const StyledArrowIcon = styled.span`
  border-style: solid;
  border-width: 1px 1px 0 0;
  color: ${PRIMARY};
  content: "";
  cursor: pointer;
  display: inline-block;
  height: 8px;
  position: relative;
  top: -3px;
  transform: rotate(45deg);
  transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  width: 8px;
`

const StyledNavItem = styled.li`
  &:hover {
    > ${StyledNavContent} {
      opacity: 1;
      transform: translate(60px, -46px);
      visibility: visible;
    }
  }
  &.active {
    background-color: ${MENU_ACTIVE};
    > ${StyledNavTitle} {
      font-weight: ${FW_BOLD};
    }
  }
  &.sub-menu {
    > ${StyledNavContent} {
      transform: translate(210px, -46px);
      width: 100%;
    }
    > ${StyledNavTitle} {
      align-items: center;
      display: flex;
      justify-content: space-between;
      text-align: left;
    }
    &:hover {
      > ${StyledNavContent} {
        transform: translate(255px, -46px);
      }
    }
  }
`

export const Styled = {
  StyledArrowIcon,
  StyledImage,
  StyledNavContent,
  StyledNavItem,
  StyledNavLink,
  StyledNavLinkItem,
  StyledNavTitle,
}
