import styled, { css } from "styled-components"
import { Title } from "../../../../../../_webui/layout/components/Typography/Title"
import { Regex } from "../../../../../../constant/Regex"

const { REGEX_IS_DESKTOP } = Regex

const StyledTitle = styled(Title)`
  .ant-dropdown-trigger {
    margin-left: 0;
    margin-right: 0;
  }
  ${!REGEX_IS_DESKTOP &&
  css`
    .title-left {
      order: 2;
    }
    .ant-dropdown-trigger {
      margin-left: auto;
      margin-bottom: 16px;
    }
  `}
`

export const Styled = {
  StyledTitle,
}
