import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../_webui/layout/components/Table/models"
import { Translate } from "../../../../../../../constant/Translate"
import { TravelersDetailColumnType } from "../../../../models/detail"

const { TEXT_T240, TEXT_T042, TEXT_T043 } = Translate

export const useTableConfig =
  (): UseTableConfigType<TravelersDetailColumnType> => {
    const columns: ColumnType<TravelersDetailColumnType>[] = [
      {
        render: (_, _data, index) => index + 1,
        title: TEXT_T240,
        width: 65,
      },
      {
        dataIndex: "familyFullNameRomaji",
        title: TEXT_T042,
        width: 200,
      },
      {
        dataIndex: "familyRelationshipName",
        title: TEXT_T043,
        width: 350,
      },
    ]
    return {
      columns,
    }
  }
