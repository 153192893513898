import { Translate } from "../../../constant/Translate"
import { FormFieldValueType, NamePathType } from "./models"

const { MESSAGE_E0001 } = Translate

export const requiredTime =
  (propsItem: NamePathType) => (props: FormFieldValueType) => ({
    validator() {
      const { namePath, namePathHour, namePathMinute } = propsItem
      const { getFieldValue, setFields } = props
      const hour = getFieldValue(namePathHour)
      const minute = getFieldValue(namePathMinute)

      if (!hour || !minute) {
        setFields([
          {
            errors: [MESSAGE_E0001],
            name: namePath,
          },
        ])
        return Promise.reject()
      } else {
        setFields([
          {
            errors: [],
            name: namePath,
          },
          {
            errors: [],
            name: namePathHour,
          },
          {
            errors: [],
            name: namePathMinute,
          },
        ])
        return Promise.resolve()
      }
    },
  })
