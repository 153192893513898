import "../style/index.scss"
import { ConfigProvider } from "antd"
import { FC, Fragment } from "react"
import { ItemDatePickerRangeProps } from "./models"
import { FormItem } from "../.."
import localeJa from "antd/lib/locale/ja_JP"
import localeEn from "antd/lib/locale/en_US"
import { Constant } from "../../../../../../../constant"
import { Styled } from "./styled"
import { ItemDatePicker } from ".."
import { Regex } from "../../../../../../../constant/Regex"

const { CHECK_SELECTED_LANG } = Constant
const { REGEX_IS_DESKTOP } = Regex
const { StyledRangePicker, StyledSeparator } = Styled

export const ItemDatePickerRange: FC<ItemDatePickerRangeProps> = (props) => {
  const {
    className,
    disabledDateFrom,
    disabledDateTo,
    isBgBlue,
    isHiddenLabel,
    label,
    labelFrom,
    labelTo,
    name,
    nameFrom,
    nameTo,
    onBlurFrom,
    onBlurTo,
    onChangeFrom,
    onChangeTo,
    onFocus,
    onKeyDownFrom,
    onKeyDownTo,
    required,
    rules,
  } = props
  const locale = CHECK_SELECTED_LANG ? localeJa : localeEn

  return (
    <Fragment>
      <ConfigProvider locale={locale}>
        <FormItem
          className={className}
          isBgBlue={isBgBlue}
          isHiddenLabel={isHiddenLabel}
          label={REGEX_IS_DESKTOP && label}
          name={name}
          required={required}
          rules={rules}
        >
          <StyledRangePicker>
            <ItemDatePicker
              disabledDate={disabledDateFrom}
              isHiddenLabel={!isHiddenLabel}
              label={labelFrom}
              name={nameFrom}
              onBlur={onBlurFrom}
              onChange={onChangeFrom}
              onFocus={onFocus}
              onKeyDown={onKeyDownFrom}
            />
            {REGEX_IS_DESKTOP && <StyledSeparator>～</StyledSeparator>}
            <ItemDatePicker
              disabledDate={disabledDateTo}
              isHiddenLabel={!isHiddenLabel}
              label={labelTo}
              name={nameTo}
              onBlur={onBlurTo}
              onChange={onChangeTo}
              onFocus={onFocus}
              onKeyDown={onKeyDownTo}
            />
          </StyledRangePicker>
        </FormItem>
      </ConfigProvider>
    </Fragment>
  )
}
