const KEYS_ACCOMPANYING_FAMILY = ["DPTD006", "DPTD007"]

const DISPATCH_TYPE_DETAILS_ASSOCIATION = [
  "DPTD001",
  "DPTD002",
  "DPTD003",
  "DPTD004",
  "DPTD005",
  "DPTD006",
  "DPTD007",
  "DPTD012",
  "DPTD013",
  "DPTD014",
  "DPTD015",
  "DPTD016",
  "DPTD017",
  "DPTD023",
  "DPTD027",
]

export const Variables = {
  DISPATCH_TYPE_DETAILS_ASSOCIATION,
  KEYS_ACCOMPANYING_FAMILY,
}
