import { FC } from "react"
import { ItemCheckboxProps } from "./models"
import { FormItem } from ".."
import { Checkbox } from "../../../Checkbox"
import { Styled } from "./styled"

const { StyledCenter } = Styled

export const ItemCheckbox: FC<ItemCheckboxProps> = (props) => {
  const {
    checked,
    isBgBlue,
    isCenter,
    isHiddenLabel,
    extra,
    label,
    name,
    onChange,
    required,
    rules,
    text,
    tooltip,
  } = props

  return (
    <StyledCenter isCenter={isCenter}>
      <FormItem
        className="ant-form-item-checkbox"
        isBgBlue={isBgBlue}
        extra={extra}
        label={label}
        name={name}
        required={required}
        rules={rules}
        tooltip={tooltip}
        valuePropName="checked"
      >
        <Checkbox
          checked={checked}
          isHiddenLabel={isHiddenLabel}
          onChange={onChange}
          text={text}
        />
      </FormItem>
    </StyledCenter>
  )
}
