import styled from "styled-components"
import { Colors } from "../../../../../../../../constant/Styled/Colors"
import { Fonts } from "../../../../../../../../constant/Styled/Fonts"
import { Modal } from "../../../../../../../../_webui/layout/components/Modal"

const { PRIMARY } = Colors
const { FONT_YU_GOTHIC, FS_LARGE } = Fonts

const StyledRolesSettingLink = styled.span`
  background-color: transparent;
  border: none;
  color: ${PRIMARY};
  cursor: pointer;
  font-family: ${FONT_YU_GOTHIC};
  font-size: ${FS_LARGE};
  margin-right: 8px;
  padding: 0;
`

const StyledModal = styled(Modal)`
  .modal-content {
    height: initial;
  }
`

export const Styled = {
  StyledModal,
  StyledRolesSettingLink,
}
