import { FC } from "react"
import { Translate } from "../../../../constant/Translate"
import { replaceStringParam } from "../../../../utils/replaceStringParam"
import { ShowCountProps } from "./models"
import { Styled } from "./styled"

const { TEXT_T557 } = Translate
const { StyledSuffix } = Styled

export const ShowCount: FC<ShowCountProps> = (props) => {
  const { countValue, isFocus } = props

  return isFocus ? (
    <StyledSuffix isFocus={isFocus} className="ant-input-suffix">
      <span className="ant-input-show-count-suffix">
        {replaceStringParam(TEXT_T557, countValue)}
      </span>
    </StyledSuffix>
  ) : null
}
