import { ATTENTION_ENDPOINT } from "./Attention"
import { BATCH_ENDPOINT } from "./Batch"
import { COMMON_ENDPOINT } from "./Common"
import { SAFETY_ENDPOINT } from "./Safety"
import { SCHEDULE_ENDPOINT } from "./Schedule"
import { TRAVELER_ENDPOINT } from "./Traveler"

export const Endpoint = {
  ...ATTENTION_ENDPOINT,
  ...BATCH_ENDPOINT,
  ...COMMON_ENDPOINT,
  ...SAFETY_ENDPOINT,
  ...SCHEDULE_ENDPOINT,
  ...TRAVELER_ENDPOINT,
}
