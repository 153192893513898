import styled from "styled-components"
import { Colors } from "../../../../../constant/Styled/Colors"

const { BORDER } = Colors

const StyledFrame = styled.div`
  margin-top: 15px;
`

const StyledFrameContainer = styled.div`
  border: 1px solid ${BORDER};
  border-radius: 10px;
  padding: 15px 30px;
  word-break: break-word;
`

export const Styled = {
  StyledFrame,
  StyledFrameContainer,
}
