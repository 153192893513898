import { useState } from "react"
import { commonApi } from "../../../../../apis"
import {
  GetUserAuthorityTypeSettingResType,
  SubAdminSettingListType,
} from "../../../../../models/subAdmin/setting"
import { convertDataSource } from "./convertDataSource"
import { AxiosErrorType } from "../../../../../../../../infrastructure/axiosError/models"
import { pushStateError } from "../../../../../../../../utils/pushStateError"
import { useHistory, useLocation } from "react-router-dom"
import { UseSubAdminConfirmStateType } from "../../../../../models/subAdmin/confirm"
import { AuthorityPatternResType } from "../../../../../../../../infrastructure/handlers/authorities/models"
import { Routers } from "../../../../../../../../constant/Routers"
import { departmentsApi } from "../../../../../../../../infrastructure/handlers/departments"
import { DepartmentsResType } from "../../../../../../../../infrastructure/handlers/departments/models"
import { getValueByLanguage } from "../../../../../../../../utils/getValueByLanguage"

const { SCR973 } = Routers

export const useApi = () => {
  const { push } = useHistory()
  const { state } = useLocation<UseSubAdminConfirmStateType>()
  const [travelerList, setTravelerList] = useState<SubAdminSettingListType[]>(
    [],
  )
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [patternName, setPatternName] = useState("")
  const [bumonName, setBumonName] = useState("")

  const callApi = async () => {
    if (state?.searchCondition) {
      // APIを呼び出し、権限パターン情報を取得する。
      const getAuthorityPattern: AuthorityPatternResType =
        await commonApi.getAuthorityPattern(state.searchCondition.patternId)
      // APIを呼び出し、機能限定管理者設定ユーザ情報を受け取る。
      const getUserAuthorityTypeSetting: GetUserAuthorityTypeSettingResType[] =
        await commonApi.getUserAuthorityTypeSetting({
          bumonCd: "",
          fullName: "",
          patternId: "",
          travelerIdList: state.searchCondition.travelerIdList,
        })

      Promise.all([getAuthorityPattern, getUserAuthorityTypeSetting])
        .then(async (res) => {
          const _travelerList = convertDataSource(res[1])
          if (state.searchCondition?.bumonCd) {
            // APIを呼び出し、部署一覧を取得する。
            const getDepartments: DepartmentsResType[] =
              await departmentsApi.getDepartments()
            const department = getDepartments?.find(
              (item) => item.bumonCd === state.searchCondition?.bumonCd,
            )
            if (department) {
              const _bumonName = getValueByLanguage(
                department?.departmentNameJapan,
                department?.departmentNameEnglish,
              ).toString()
              setBumonName(_bumonName)
            }
          }
          setPatternName(res[0].patternName)
          setTravelerList(_travelerList)
          setIsLoadingLayout(false)
        })
        .catch((error: AxiosErrorType) => {
          const stateError = pushStateError(error)
          push({ pathname: SCR973, state: stateError })
        })
    }
  }

  return {
    bumonName,
    callApi,
    isLoadingLayout,
    patternName,
    state,
    travelerList,
  }
}
