/* eslint-disable max-lines */
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { SorterResult, TableCurrentDataSource } from "antd/lib/table/interface"
import { useEffect } from "react"
import { CheckboxAll } from "../../../../../../../../_webui/layout/components/RowSelection/CheckboxAll"
import { CheckboxItem } from "../../../../../../../../_webui/layout/components/RowSelection/CheckboxItem"
import { TableRowSelection } from "../../../../../../../../_webui/layout/components/Table/models"
import { AnyValue } from "../../../../../../../../_webui/layout/models"
import {
  SubAdminSettingListType,
  UseRowSelectionProps,
} from "../../../../../models/subAdmin/setting"
import { useVariablesRowSelection } from "../useVariablesRowSelection"

export const useRowSelection = (props: UseRowSelectionProps) => {
  const { patternId, travelerList } = props
  const {
    dataCurrent,
    handleSetDefaultSortedInfo,
    handleSetSortedInfo,
    isCheckAll,
    isDisabledBtnSave,
    isDisabledCheckAll,
    onChangePage,
    perItem,
    perItemEnd,
    perItemStart,
    selectedRowKeys,
    setDataCurrent,
    setIsCheckAll,
    setIsDisabledBtnSave,
    setIsDisabledCheckAll,
    setSelectedRowKeys,
    setTravelerListCurrent,
    sortedInfo,
    travelerListCurrent,
  } = useVariablesRowSelection(travelerList.length)

  const onSelectChange = () => {
    const _isDisabledBtnSave = selectedRowKeys.length && patternId
    setIsDisabledBtnSave(!_isDisabledBtnSave)

    if (selectedRowKeys.length === dataCurrent.length) setIsCheckAll(true)
    else setIsCheckAll(false)
  }

  const onChangeCheckAll = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    setIsCheckAll(checked)
    if (checked) {
      const _selectedRowKeys = dataCurrent.map((item) => item.travelerId)
      setSelectedRowKeys(_selectedRowKeys)
    } else setSelectedRowKeys([])
  }

  const onChangeCheckItem = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    const value = event.target.value
    if (checked) setSelectedRowKeys([...selectedRowKeys, value])
    else {
      const _selectedRowKeys = selectedRowKeys.filter((item) => item !== value)
      setSelectedRowKeys(_selectedRowKeys)
    }
  }

  const rowSelection: TableRowSelection<SubAdminSettingListType> = {
    columnTitle: (
      <CheckboxAll
        checked={isCheckAll}
        isDisabled={isDisabledCheckAll}
        onChange={onChangeCheckAll}
      />
    ),
    renderCell: (_, record) => (
      <CheckboxItem
        checked={selectedRowKeys.includes(record.travelerId)}
        onChange={onChangeCheckItem}
        value={record.travelerId}
      />
    ),
    selectedRowKeys,
  }

  const onChangeTable = (
    extra: TableCurrentDataSource<SubAdminSettingListType>,
    sorter: SorterResult<AnyValue> | SorterResult<AnyValue>[],
  ) => {
    handleSetSortedInfo(sorter)

    setTravelerListCurrent(extra.currentDataSource)

    setIsCheckAll(false)
    setSelectedRowKeys([])
  }

  useEffect(() => {
    const _dataCurrent = travelerListCurrent.slice(perItemStart, perItemEnd)
    setDataCurrent(_dataCurrent)
  }, [travelerListCurrent, setDataCurrent, perItemStart, perItemEnd])

  useEffect(() => {
    if (travelerListCurrent?.length) onSelectChange()
  }, [selectedRowKeys, dataCurrent])

  useEffect(() => {
    if (!dataCurrent.length) {
      setIsCheckAll(false)
      setIsDisabledCheckAll(true)
    } else setIsDisabledCheckAll(false)
  }, [dataCurrent])

  useEffect(() => {
    if (travelerList?.length) setTravelerListCurrent(travelerList)
  }, [travelerList])

  return {
    handleSetDefaultSortedInfo,
    isDisabledBtnSave,
    onChangePage,
    onChangeTable,
    perItem,
    rowSelection,
    selectedRowKeys,
    setIsDisabledBtnSave,
    setSelectedRowKeys,
    sortedInfo,
  }
}
