import { FC, Fragment } from "react"
import { Button } from "../../../../../../_webui/layout/components/Button"
import { Center } from "../../../../../../_webui/layout/components/Center"
import { Table } from "../../../../../../_webui/layout/components/Table"
import { handleExportCSV } from "../../../containers/requestDelete/modules/useApi/handleExportCSV"
import { Title } from "../../../../../../_webui/layout/components/Typography/Title"
import { TotalRecords } from "../../../../../../_webui/layout/components/Typography/TotalRecords"
import { Translate } from "../../../../../../constant/Translate"
import { replaceStringParam } from "../../../../../../utils/replaceStringParam"
import { TravelersRequestDeleteTabs } from "../Tabs"
import { TravelersRequestDeleteTableProps } from "./models"
import { PreLine } from "../../../../../../_webui/layout/components/Typography/PreLine"

const { MESSAGE_I0010, TEXT_T021, TEXT_T595 } = Translate

export const TravelersRequestDeleteTable: FC<
  TravelersRequestDeleteTableProps
> = (props) => {
  const {
    columnErrors,
    columns,
    dataGridItems,
    dataSourceLength,
    executeDetail,
    isResetTable,
    isShowTableError,
    isVisibleBtn,
    isVisibleTabRoleAdmin,
    isVisibleTabRoleSubAdmin,
    onChange,
    onChangeTable,
    onTabClick,
    perItem,
    rowSelection,
    tableErrorDataSource,
  } = props

  return (
    <Fragment>
      {executeDetail && (
        <Title
          titleLeft={
            <PreLine>
              {replaceStringParam(
                MESSAGE_I0010,
                String(executeDetail.total),
                executeDetail.success,
                executeDetail.fail,
              )}
            </PreLine>
          }
        />
      )}

      {isShowTableError ? (
        <Fragment>
          <Table
            columns={columnErrors}
            dataSource={tableErrorDataSource}
            isFixed
            rowKey="id"
          />
          <Center isMarginTop>
            <Button
              htmlType="button"
              isFullWidth
              onClick={() => handleExportCSV(tableErrorDataSource)}
              text={TEXT_T595}
              type="primary"
            />
          </Center>
        </Fragment>
      ) : (
        <Fragment>
          <TotalRecords
            perItem={perItem}
            titleLeft={TEXT_T021}
            total={dataSourceLength}
          />
          <TravelersRequestDeleteTabs
            columns={columns}
            dataGridItems={dataGridItems}
            isResetTable={isResetTable}
            isVisibleBtn={isVisibleBtn}
            isVisibleTabRoleAdmin={isVisibleTabRoleAdmin}
            isVisibleTabRoleSubAdmin={isVisibleTabRoleSubAdmin}
            onChange={onChange}
            onChangeTable={onChangeTable}
            onTabClick={onTabClick}
            perItem={perItem}
            rowKey="travelerId"
            rowSelection={rowSelection}
          />
        </Fragment>
      )}
    </Fragment>
  )
}
