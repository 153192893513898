import { Translate } from "../../../../../../../../constant/Translate"
import { getTextYesNo } from "../../../../../../../../utils/getTextYesNo"
import { GridItemType } from "../../../../../../../../_webui/layout/components/Grid/models"
import { ScheduleInfoType } from "../../../../../models/addSuccess"

const { TEXT_T239, TEXT_T061, TEXT_T062, TEXT_T063, TEXT_T064 } = Translate

export const getInsuranceGridConfig = (
  scheduleInfo: ScheduleInfoType,
): GridItemType[] => [
  {
    key: TEXT_T239,
    text: getTextYesNo(scheduleInfo.differentInforCheck),
  },
  {
    key: TEXT_T061,
    text: scheduleInfo.insuranceCompanyName,
  },
  {
    key: TEXT_T062,
    text: scheduleInfo.insuranceCompanyPhoneNumber,
  },
  {
    key: TEXT_T063,
    text: scheduleInfo.insuranceTicketNumber,
  },
  {
    key: TEXT_T064,
    text: scheduleInfo.insuranceSonsignor,
  },
]
