import { Translate } from "../../../constant/Translate"
import { RequiredCheckboxType } from "./models"

const { MESSAGE_E0001 } = Translate

export const validatorRequiredCheckbox = (): RequiredCheckboxType => ({
  required: true,
  validator(_, value: boolean) {
    if (value === false) {
      return Promise.reject(new Error(MESSAGE_E0001))
    }
    return Promise.resolve()
  },
})
