import { GeneralCode } from "../../../../../../../../constant/GeneralCode"
import { getDay } from "../../../../../../../../utils/getDate"
import { getValueByLanguage } from "../../../../../../../../utils/getValueByLanguage"
import {
  SafetiesCloneDataType,
  SafetiesCloneResType,
} from "../../../../../models/clone"

const { CTGR_OTHER } = GeneralCode

export const convertDataSource = (
  data: SafetiesCloneResType[],
): SafetiesCloneDataType[] =>
  data.map((item) => ({
    bodyContent: item.bodyContent,
    category:
      item.categoryId === CTGR_OTHER
        ? getValueByLanguage(
            `${item.categoryName}＿${item.categoryValue}`,
            `${item.categoryName}_${item.categoryValue}`,
          ).toString()
        : item.categoryName,
    categoryId: item.categoryId,
    categoryName: item.categoryName,
    categoryValue: item.categoryValue,
    implementationDate: item.implementationDate,
    implementationDateFormat: getDay(item.implementationDate),
    safetyId: item.safetyId,
    safetyTitle: item.safetyTitle,
  }))
