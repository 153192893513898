import { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { ErrorItemType } from "../../../../../../../_webui/layout/components/Typography/ErrorList/models"
import { Routers } from "../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { useModal } from "../../../../../../../utils/hook/useModal"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { travelerApi } from "../../../../apis"
import {
  TravelerDeleteProps,
  TravelersConfirmDeleteResType,
  UseTravelerConfirmDeleteApiProps,
} from "../../../../models/confirmDelete"

const { SCR119, SCR973 } = Routers

export const useApi = (props: UseTravelerConfirmDeleteApiProps) => {
  const { isRoleAdmin, isRoleSubAdmin, userInfoList } = props
  const { state } = useLocation<TravelerDeleteProps>()
  const { push } = useHistory()
  const { isOpen, onCancel, onOpen } = useModal()
  const [isLoading, setIsLoading] = useState(false)
  const [errorItems, setErrorItems] = useState<ErrorItemType[]>([])

  const handleRequestToDisableTravelers = (
    data: TravelersConfirmDeleteResType,
  ) => {
    if (data?.errors?.length) {
      // エラーメッセージを 「SCR126_渡航予定情報の削除確認画面」に表示する。
      setErrorItems([])
      userInfoList.forEach((userInfoItem) => {
        data.errors.forEach((item) => {
          if (userInfoItem.travelerId === item.userId) {
            setErrorItems((prev) => [
              ...prev,
              {
                message: `・${
                  userInfoItem.fullNameKanji ?? userInfoItem.fullNameRomaji
                }、 ${userInfoItem.dispatchTypeDetail}`,
              },
            ])
          }
        })
      })
    } else {
      // 「SCR119_渡航者情報の削除後画面」に遷移する。
      push({
        pathname: SCR119,
        state: {
          ...state,
          isFromSCR126: true,
        },
      })
    }
  }

  const handleError = (error: AxiosErrorType) => {
    // 「SCR973_エラー情報の表示画面」に遷移する。
    const stateError = pushStateError(error)
    push({ pathname: SCR973, state: stateError })
  }

  const onOk = () => {
    const travelerIds: string[] = userInfoList.map((item) => item.travelerId)
    setIsLoading(true)
    onCancel()

    // 渡航者情報削除依頼を作成する。
    if (isRoleSubAdmin)
      travelerApi
        .requestToDisableTravelers(travelerIds)
        .then((res: TravelersConfirmDeleteResType) => {
          handleRequestToDisableTravelers(res)
          setIsLoading(false)
        })
        .catch((error: AxiosErrorType) => {
          handleError(error)
        })
    // 渡航者情報を削除する。
    else if (isRoleAdmin)
      travelerApi
        .disableTravelers(travelerIds)
        .then((res: TravelersConfirmDeleteResType) => {
          handleRequestToDisableTravelers(res)
          setIsLoading(false)
        })
        .catch((error: AxiosErrorType) => {
          handleError(error)
        })
  }

  return {
    errorItems,
    isLoading,
    isOpen,
    onCancel,
    onOk,
    onOpen,
    state,
  }
}
