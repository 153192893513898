/* eslint-disable max-lines */
import { createGlobalStyle, css } from "styled-components"
import { Regex } from "../constant/Regex"
import { Colors } from "../constant/Styled/Colors"
import { Fonts } from "../constant/Styled/Fonts"

const {
  BORDER_CHECKBOX,
  BORDER,
  BOX_SHADOW,
  BTN_PRIMARY,
  DISABLED,
  FOCUS,
  LIGHT,
  PRIMARY,
  TABLE_HEADER,
  TABLE_HEADER_BORDER,
  TABLE_SELECTED,
  TEXT,
} = Colors
const { FS_LARGE, FS_NORMAL, FW_MEDIUM, FW_BOLD, FONT_YU_GOTHIC } = Fonts
const { REGEX_IS_DESKTOP } = Regex

export const StyledGlobal = createGlobalStyle`
  @keyframes antCheckboxEffect {
    0% {
      transform: scale(1);
      opacity: 0.5;
    }
    to {
      transform: scale(1.6);
      opacity: 0;
    }
  }
  @keyframes NotificationFadeOut {
    0% {
      max-height: 150px;
      margin-bottom: 16px;
      opacity: 1;
    }
    100% {
      max-height: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      opacity: 0;
    }
  }
  @keyframes NotificationTopFadeIn {
    0% {
      margin-top: -100%;
      opacity: 0;
    }
    100% {
      margin-top: 0;
      opacity: 1;
    }
  }
  @keyframes antSlideUpIn {
    0% {
      opacity: 0;
      transform: scaleY(0.8);
      transform-origin: 0% 0%;
    }
    to {
      opacity: 1;
      transform: scaleY(1);
      transform-origin: 0% 0%;
    }
  }
  @keyframes antSlideUpOut {
    0% {
      opacity: 1;
      transform: scaleY(1);
      transform-origin: 0% 0%;
    }
    to {
      opacity: 0;
      transform: scaleY(0.8);
      transform-origin: 0% 0%;
    }
  }
  @keyframes antZoomBigIn {
    0% {
      transform: scale(0.8);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes antZoomBigOut {
    0% {
      transform: scale(1);
    }
    to {
      transform: scale(0.8);
      opacity: 0;
    }
  }
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  input[type="search"] & {
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }
  a {
    color: ${TEXT};
    text-decoration: none;
  }
  button {
    color: ${TEXT};
  }
  body,
  html {
    height: 100%;
  }
  body {
    color: ${TEXT};
    margin: 0;
    font-family: ${FONT_YU_GOTHIC};
    font-size: ${FS_LARGE};
    font-weight: ${FW_MEDIUM};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html {
    scroll-behavior: smooth;
  }
  pre {
    font-family: ${FONT_YU_GOTHIC};
    margin: 0;
  }
  .ant {
    &-carousel {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      .slick {
        &-arrow {
          border: 0;
          cursor: pointer;
          display: block;
          font-size: 0;
          height: 48px;
          line-height: 0;
          margin-top: -10px;
          outline: none;
          padding: 0;
          position: absolute;
          background-color: transparent;
          top: 50%;
          width: 48px;
          z-index: 1;
          &::before {
            border-style: solid;
            border-width: 4px 4px 0 0;
            content: "";
            display: inline-block;
            height: 24px;
            position: relative;
            top: -3px;
            width: 24px;
          }
          &.slick-disabled {
            opacity: 0.2;
          }
        }
        &-prev {
          left: 0;
          transform: rotate(-135deg);
        }
        &-next {
          right: 0;
          transform: rotate(45deg);
        }
        &-slider {
          box-sizing: border-box;
          display: block;
          position: relative;
          .slick-track,
          .slick-list {
            touch-action: pan-y;
            transform: translate3d(0, 0, 0);
          }
        }
        &-track {
          display: block;
          left: 0;
          position: relative;
          top: 0;
          &::before,
          &::after {
            display: table;
            content: "";
          }
          &::after {
            clear: both;
          }
        }
        &-slide {
          display: none;
          float: left;
          height: 100%;
          min-height: 1px;
          img {
            display: block;
          }
          .slick-loading img {
            display: none;
          }
        }
        &-initialized .slick-slide {
          display: block;
        }
        &-list {
          display: block;
          margin: 0;
          overflow: hidden;
          padding: 0;
          position: relative;
          & :focus {
            outline: none;
          }
          .slick-slide {
            pointer-events: none;
          }
        }
      }
    }
    &-table {
      table {
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
      }
      &-body {
        border: 1px solid ${BORDER};
        border-top: none;
        overflow: auto !important;
      }
      &-tbody {
        tr:not(:first-child) {
          > td {
            &:first-child {
              &::before {
                content: var(--row-index);
                display: inline-block;
                height: 0;
                overflow: hidden;
                text-indent: 100%;
                width: 0;
              }
            }
          }
        }
      }
      &-cell {
        font-size: ${FS_LARGE};
        font-weight: ${FW_MEDIUM};
        height: 51px;
        padding-left: 10px;
        padding-right: 10px;
        position: relative;
        text-align: left;
        transition: 0.3s all ease-in-out;
        white-space: normal;
        word-break: break-word;
        &-scrollbar {
          display: none;
        }
        
        .ant {
          &-checkbox + span {
            display: none;
          }
          &-form-item {
            .ant-form-item-label {
              height: 0;
              margin-right: 0;
              min-height: initial;
              overflow: hidden;
              text-indent: 100%;
              width: 0;
            }
          }
        }
      }
      &-column {
        &-sort {
          .ant-table-column-sorter {
            &-up,
            &-down {
              display: none;
              &.active {
                display: inline-block;
              }
            }
          }
        }
        &-sorter {
          &-inner {
            display: flex;
            flex-wrap: wrap;
            margin-left: 10px;
            max-width: 14px;
            min-width: 14px;
          }
        }
        &-sorters {
          align-items: center;
          cursor: pointer;
          display: inline-flex;
          flex-wrap: nowrap;
        }
      }
      &-content {
        width: 100%;
        overflow: auto;
        max-width: calc(100vw - 40px);
      }
      &-header {
        background-color: ${TABLE_HEADER};
        border: 1px solid ${BORDER};
        border-bottom: none;
      }
      &-row {
        &-selected {
          background-color: ${TABLE_SELECTED};
        }
        .ant-table-cell {
          border-top: 1px solid ${BORDER};
        }
      }
      &-selection {
        &-col,
        &-column {
          width: 66px;
          text-align: center;
        }
      }
      &-thead {
        background-color: ${TABLE_HEADER};
        .ant-table-cell {
          font-size: ${FS_NORMAL};
          &::before {
            background-color: ${TABLE_HEADER_BORDER};
            content: "";
            height: 20px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
          }
          &:first-child {
            &::before {
              display: none;
            }
          }
        }
      }
      &-wrapper {
        .ant-pagination {
          display: none;
        }
      }
    }
    &-pagination {
      &-item,
      &-jump-next,
      &-jump-prev,
      &-next,
      &-prev {
        cursor: pointer;
        margin-bottom: 20px;
        margin-right: 10px;
        height: 35px;
        line-height: 35px;
        text-align: center;
      }
      &-item,
      &-next,
      &-prev {
        background-color: ${LIGHT};
        border: 1px solid ${BORDER};
        border-radius: 5px;
        width: 35px;
      }
      &-item {
        &-active {
          background-color: ${BTN_PRIMARY};
          border-color: ${BTN_PRIMARY};
          span {
            color: ${LIGHT};
          }
        }
        &-link {
          background-color: transparent;
          border: none;
          cursor: pointer;
          outline: none;
          &-icon {
            display: none;
          }
        }
      }
      &-disabled {
        background-color: ${DISABLED};
        border-color: ${DISABLED};
        pointer-events: none;
        .anticon {
          color: ${LIGHT};
        }
      }
      &-options {
        display: none;
      }
      li {
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &-upload {
      &-info {
        max-width: 416px;
        margin: 0 auto;
      }
      &-list-item {
        &-name {
          font-weight: ${FW_BOLD};
          overflow-wrap: break-word;
        }
        &-card-actions {
          display: none;
        }
      }
      &-text {
        &-icon {
          display: none;
        }
      }
    }
    &-checkbox {
      position: relative;
      white-space: nowrap;
      outline: none;
      cursor: pointer;
      &:hover {
        &::after {
          visibility: visible;
        }
        .ant-checkbox-inner {
          border-color: ${PRIMARY};
        }
      }
      &-checked {
        &:after {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 1px solid ${PRIMARY};
          border-radius: 4px;
          visibility: hidden;
          animation: antCheckboxEffect 0.36s ease-in-out;
          animation-fill-mode: backwards;
          content: "";
        }
        .ant-checkbox-inner {
          background-color: ${PRIMARY};
          border-color: ${PRIMARY};
          &:after {
            transform: rotate(45deg) scale(1) translate(-50%, -50%);
            opacity: 1;
            transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
          }
        }
      }
      &-group {
        display: inline-flex;
        &-item {
          margin-right: 8px;
          :last-child {
            margin-right: 0;
          }
          &:last-child {
            margin-right: 0;
          }
          + .ant-checkbox-group-item {
            margin-left: 0;
          }
        }
      }
      &-inner {
        position: relative;
        display: block;
        width: 24px;
        height: 24px;
        background-color: ${LIGHT};
        border: 1px solid ${BORDER_CHECKBOX};
        border-radius: 4px;
        transition: all 0.3s;
        &::after {
          position: absolute;
          top: 50%;
          left: 21.5%;
          display: table;
          width: 10px;
          height: 14px;
          border: 3px solid ${LIGHT};
          border-top: 0;
          border-left: 0;
          transform: rotate(45deg) scale(0) translate(-50%, -50%);
          opacity: 0;
          transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6),
            opacity 0.1s;
          content: " ";
        }
      }
      &-input {
        position: absolute;
        padding: 0;
        margin: 0;
        inset: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
        &:focus {
          + .ant-checkbox-inner {
            border-color: ${PRIMARY};
            box-shadow: 0px 0px 4px ${FOCUS};
          }
        }
      }
      &-wrapper {
        display: inline-flex;
        align-items: center;
        line-height: unset;
        cursor: pointer;
        &::after {
          display: inline-block;
          width: 0;
          overflow: hidden;
          content: "";
        }
        &:hover {
          .ant-checkbox {
            &::after {
              visibility: visible;
            }
            &-inner {
              border-color: ${PRIMARY};
            }
          }
        }
        &-disabled {
          cursor: default;
          &:hover {
            .ant-checkbox-inner {
              border-color: ${DISABLED};
            }
          }
        }
        + .ant-checkbox-wrapper {
          margin-left: 8px;
        }
      }
      + span {
        padding-right: 8px;
        padding-left: 8px;
        font-weight: ${FW_MEDIUM};
      }
      &-disabled {
        cursor: default;
        pointer-events: none;
        .ant {
          &-checkbox {
            &-inner {
              background-color: ${DISABLED};
              border-color: ${DISABLED};
            }
            &-input {
              &:focus + .ant-checkbox-inner {
                border-color: ${DISABLED};
                box-shadow: none;
              }
            }
          }
        }
      }
    }
    &-empty {
      text-align: center;
      &-image {
        height: 35px;
        margin-bottom: 8px;
        margin-top: 8px;
      }
      &-img {
        &-simple {
          &-ellipse {
            fill: #f5f5f5;
          }
          &-g {
            stroke: #d9d9d9;
          }
          &-path {
            fill: #fafafa;
          }
        }
      }
    }
    &-notification {
      position: fixed;
      z-index: 1010;
      &-notice {
        position: relative;
        width: 384px;
        max-width: calc(100vw - 24px * 2);
        padding: 16px 24px;
        margin-bottom: 24px;
        margin-right: auto;
        margin-left: auto;
        overflow: hidden;
        word-wrap: break-word;
        background-color: ${LIGHT};
        border-radius: 16px;
        box-shadow: 0 3px 6px -4px ${BOX_SHADOW}, 0 6px 16px 0 ${BOX_SHADOW},
          0 9px 28px 8px ${BOX_SHADOW};
        &-close,
        &-icon {
          display: none;
        }
        .notification-fade-effect {
          animation-duration: 0.24s;
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-fill-mode: both;
        }
      }
      &-fade {
        &-enter,
        &-appear,
        &-leave {
          animation-duration: 0.24s;
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-fill-mode: both;
          animation-play-state: paused;
        }
        &-enter,
        &-appear {
          opacity: 0;
          &-active {
            animation-name: NotificationTopFadeIn;
            animation-play-state: running;
          }
        }
        &-leave {
          animation-duration: 0.2s;
          &-active {
            animation-name: NotificationFadeOut;
            animation-play-state: running;
          }
        }
      }
    }
    &-zoom {
      &-big {
        &-leave,
        &-fast-leave {
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          &.ant-zoom-big {
            &-leave,
            &-fast-leave {
              &-active {
                animation-name: antZoomBigOut;
                animation-play-state: running;
                pointer-events: none;
              }
            }
          }
        }
        &-enter,
        &-appear,
        &-fast-enter,
        &-fast-appear {
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          opacity: 0;
          transform: scale(0);
          &-prepare {
            transform: none;
          }
          &.ant-zoom-big {
            &-enter,
            &-appear,
            &-fast-enter,
            &-fast-appear {
              &-active {
                animation-name: antZoomBigIn;
                animation-play-state: running;
              }
            }
          }
        }
        &-fast {
          &-enter,
          &-appear,
          &-leave {
            animation-duration: 0.2s;
            animation-fill-mode: both;
            animation-play-state: paused;
          }
        }
      }
    }
  }
  .rc-virtual-list {
    &-scrollbar {
      &-show {
        display: block !important;
      }
    }
  }
  .no-scroll {
    overflow: hidden;
  }
  .arrow {
    &::before {
      border-style: solid;
      border-width: 1px 1px 0 0;
      content: "";
      display: inline-block;
      height: 8px;
      position: relative;
      width: 8px;
    }
    &-down::before {
      transform: rotate(135deg);
      top: -3px;
    }
    &-right::before {
      transform: rotate(45deg);
    }
  }
  .mr-0 {
    margin-right: 0;
  }
  .mr-20 {
    margin-right: 20px;
  }
  .mt-50 {
    margin-top: 50px !important;
  }
  .mb-50 {
    margin-bottom: 50px !important;
  }
  .mt-0 {
    margin-top: 0 !important;
  }
  .mb-0 {
    margin-bottom: 0 !important;
  }
  .d-none {
    display: none !important;
  }
  .hidden-label {
    display: block;
    height: 0;
    margin-right: 0;
    min-height: initial;
    overflow: hidden;
    text-indent: 100%;
    width: 0;
  }
  ${
    REGEX_IS_DESKTOP
      ? css`
          ::-webkit-scrollbar {
            width: 12px;
            height: 12px;
          }
          ::-webkit-scrollbar-thumb {
            background-color: ${BORDER};
            border-radius: 6px;
          }
          ::-webkit-scrollbar-track {
            background-color: ${TABLE_HEADER};
          }
        `
      : css`
          .ant {
            &-pagination {
              &-next,
              &-prev {
                width: calc(50% - 10px);
              }
              &-prev {
                order: 1;
              }
              &-next {
                order: 2;
              }
              &-item,
              &-jump-next,
              &-jump-prev {
                order: 3;
              }
            }
          }
        `
  }
`
