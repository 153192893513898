/* eslint-disable complexity */
import { Routers } from "../../../../../../../constant/Routers"
import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

const { SCR210, SCR210_TRAVELERS, SCR210_USER_SEARCH } = Routers

export const useVariablesAuthority = (
  isFromSCR114UserSearch?: boolean,
  schedulesURL?: string,
) => {
  const {
    isCommonTravelerRefer,
    isRoleAdmin,
    isRoleSubAdmin,
    isRoleUser,
    isScheduleChange,
    isScheduleDelete,
    isScheduleRefer,
    isScheduleSearch,
  } = useAuthority()

  const isNotAccessScreen = !isCommonTravelerRefer && !isScheduleRefer

  const isSchedulesTraveler = schedulesURL === SCR210_TRAVELERS
  const isSchedulesUserSearch = schedulesURL === SCR210_USER_SEARCH
  const isSchedulesAndCommonTravelerRefer =
    schedulesURL === SCR210 && isCommonTravelerRefer

  const isVisibleBtn = isRoleAdmin || isRoleSubAdmin || isRoleUser
  const isVisibleBtnRefer = isSchedulesTraveler && !isFromSCR114UserSearch

  // 画面上のボタン表示条件
  const isVisibleBtnDelete =
    isVisibleBtn &&
    ((isSchedulesTraveler && isScheduleDelete) ||
      isSchedulesAndCommonTravelerRefer) &&
    !isFromSCR114UserSearch

  const isVisibleBtnEdit =
    isVisibleBtn &&
    ((isSchedulesTraveler && isScheduleChange) ||
      isSchedulesAndCommonTravelerRefer) &&
    !isFromSCR114UserSearch

  const isVisibleBtnSearch =
    isVisibleBtn &&
    ((isSchedulesTraveler && isScheduleSearch) ||
      isSchedulesAndCommonTravelerRefer ||
      isSchedulesUserSearch)

  const isVisibleOtherRemarks =
    (isRoleAdmin || isRoleSubAdmin) && schedulesURL !== SCR210

  return {
    isNotAccessScreen,
    isVisibleBtnDelete,
    isVisibleBtnEdit,
    isVisibleBtnRefer,
    isVisibleBtnSearch,
    isVisibleOtherRemarks,
  }
}
