import { Translate } from "../../../../../../../../../../constant/Translate"

const {
  EXPORT_EP0017, // ログインID    Login ID
  EXPORT_EP0150, // 姓名    Full name
  EXPORT_EP0153, // 渡航予定ID    Travel schedule ID
  EXPORT_EP0173, // 渡航目的    Travel of purpose
  EXPORT_EP0069, // 出張件名・調査団名・プロジェクト名（日）    Business trip name - Survey team name - Project name (Japanese)
  EXPORT_EP0068, // 出張件名・調査団名・プロジェクト名（英）    Business trip name - Survey team name - Project  name (English)
  EXPORT_EP0100, // 調達管理番号    Procurement management number
  EXPORT_EP0067, // 出張・調査団・赴任を主管するJICA部署    JICA department in charge of supervising business trip - survey team - assignment
  EXPORT_EP0058, // 主管部署の担当者名    Name of person in charge of supervising department
  EXPORT_EP0174, // 拠点担当者名    Name of person in charge of base
  EXPORT_EP0120, // 派遣開始日    Dispatch start date
  EXPORT_EP0124, // 派遣終了日    Dispatch end date
  EXPORT_EP0175, // 出発日時    Departure time
  EXPORT_EP0176, // 到着日時    Arrival time
  EXPORT_EP0071, // 出発国    Country of departure
  EXPORT_EP0109, // 到着国    Country of destination
  EXPORT_EP0154, // 出発都市    City of departure
  EXPORT_EP0185, // 出発都市名    City Name of departure
  EXPORT_EP0155, // 到着都市    City of destination
  EXPORT_EP0177, // 到着都市名    City of destination of name
  EXPORT_EP0045, // 交通手段    Means of transportation
  EXPORT_EP0201, // トランジット    Transit
  EXPORT_EP0077, // 出発便名    Flight number
  EXPORT_EP0128, // 備考1   Remark 1
  EXPORT_EP0129, // 備考2   Remark 2
  EXPORT_EP0130, // 備考3   Remark 3
  EXPORT_EP0131, // 備考4   Remark 4
  EXPORT_EP0132, // 備考5   Remark 5
  EXPORT_EP0105, // 渡航者情報の保険情報と異なる場合    When it is different from the insurance information of the traveler
  EXPORT_EP0135, // 保険会社名    Insurance company name
  EXPORT_EP0134, // ①事故発生時の保険会社連絡先    [1]Insurance company contact information in case of an accident
  EXPORT_EP0137, // ②保険証券番号    [2]Insurance policy number
  EXPORT_EP0036, // ③保険外（自費/自社費）で緊急移送の判断を要する場合の判断委託者    If your insurance does not cover emergency transport services, who would you designate that decision making to?
  EXPORT_EP0011, // チェックイン    Check in
  EXPORT_EP0010, // チェックアウト    Check out
  EXPORT_EP0065, // 宿泊先名称    Accommodation name
  EXPORT_EP0063, // 宿泊先住所、部屋番号等    Accommodation address, room number, etc
  EXPORT_EP0064, // 宿泊先電話番号（国番号＋15桁）    Accommodation phone number（Country code ＋15 digits）
  EXPORT_EP0093, // 滞在地携帯番号1（国番号＋15桁）   Mobile number 1 at place of destination （Country code ＋15 digits）
  EXPORT_EP0094, // 滞在地携帯番号2（国番号＋15桁）   Mobile number 2 at place of destination （Country code ＋15 digits）
  EXPORT_EP0022, // 衛星携帯番号    Satellite mobile number
  EXPORT_EP0101, // 電子メールアドレス1   Email address 1
  EXPORT_EP0102, // 電子メールアドレス2   Email address 2
  EXPORT_EP0179, // 行動規範の確認    Code of conduct recognition
  EXPORT_EP0029, // 管理者用備考1   Remarks for administrators 1
  EXPORT_EP0030, // 管理者用備考2   Remarks for administrators 2
  EXPORT_EP0031, // 管理者用備考3   Remarks for administrators 3
  EXPORT_EP0032, // 管理者用備考4   Remarks for administrators 4
  EXPORT_EP0033, // 管理者用備考5   Remarks for administrators 5
} = Translate

export const heading = `${EXPORT_EP0017},${EXPORT_EP0150},${EXPORT_EP0153},${EXPORT_EP0173},${EXPORT_EP0069},${EXPORT_EP0068},${EXPORT_EP0100},${EXPORT_EP0067},${EXPORT_EP0058},${EXPORT_EP0174},${EXPORT_EP0120},${EXPORT_EP0124},${EXPORT_EP0175},${EXPORT_EP0176},${EXPORT_EP0071},${EXPORT_EP0109},${EXPORT_EP0154},${EXPORT_EP0185},${EXPORT_EP0155},${EXPORT_EP0177},${EXPORT_EP0045},${EXPORT_EP0201},${EXPORT_EP0077},${EXPORT_EP0128},${EXPORT_EP0129},${EXPORT_EP0130},${EXPORT_EP0131},${EXPORT_EP0132},${EXPORT_EP0105},${EXPORT_EP0135},${EXPORT_EP0134},${EXPORT_EP0137},${EXPORT_EP0036},${EXPORT_EP0011},${EXPORT_EP0010},${EXPORT_EP0065},${EXPORT_EP0063},${EXPORT_EP0064},${EXPORT_EP0093},${EXPORT_EP0094},${EXPORT_EP0022},${EXPORT_EP0101},${EXPORT_EP0102},${EXPORT_EP0179}`

export const headingAdmin = `${heading},${EXPORT_EP0029},${EXPORT_EP0030},${EXPORT_EP0031},${EXPORT_EP0032},${EXPORT_EP0033}`
