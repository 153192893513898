/* eslint-disable max-lines */
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { TableCurrentDataSource } from "antd/lib/table/interface"
import { useEffect } from "react"
import { CheckboxAll } from "../../../../../../../../_webui/layout/components/RowSelection/CheckboxAll"
import { CheckboxItem } from "../../../../../../../../_webui/layout/components/RowSelection/CheckboxItem"
import { TableRowSelection } from "../../../../../../../../_webui/layout/components/Table/models"
import { SubAdminListType } from "../../../../../models/subAdmin/list"
import { useVariablesRowSelection } from "../useVariablesRowSelection"

export const useRowSelection = (
  authorityPatternSettings: SubAdminListType[],
  isRoleSubAdmin: boolean,
) => {
  const {
    authorityPatternSettingsCurrent,
    dataCurrent,
    isCheckAll,
    isDisabledCheckAll,
    onChangePage,
    perItem,
    perItemEnd,
    perItemStart,
    selectedRowKeys,
    setAuthorityPatternSettingsCurrent,
    setDataCurrent,
    setIsCheckAll,
    setIsDisabledCheckAll,
    setSelectedRowKeys,
  } = useVariablesRowSelection(authorityPatternSettings.length)

  const onSelectChange = () => {
    if (selectedRowKeys.length === dataCurrent.length) setIsCheckAll(true)
    else setIsCheckAll(false)
  }

  const onChangeCheckAll = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    setIsCheckAll(checked)
    if (checked) {
      const _selectedRowKeys = dataCurrent.map((item) => item.base.travelerId)
      setSelectedRowKeys(_selectedRowKeys)
    } else setSelectedRowKeys([])
  }

  const onChangeCheckItem = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    const value = event.target.value
    if (checked) setSelectedRowKeys([...selectedRowKeys, value])
    else {
      const _selectedRowKeys = selectedRowKeys.filter((item) => item !== value)
      setSelectedRowKeys(_selectedRowKeys)
    }
  }

  const rowSelection: TableRowSelection<SubAdminListType> = {
    columnTitle: (
      <CheckboxAll
        checked={isCheckAll}
        isDisabled={isRoleSubAdmin || isDisabledCheckAll}
        onChange={onChangeCheckAll}
      />
    ),
    renderCell: (_, record) => (
      <CheckboxItem
        checked={selectedRowKeys.includes(record.base.travelerId)}
        isDisabled={isRoleSubAdmin}
        onChange={onChangeCheckItem}
        value={record.base.travelerId}
      />
    ),
    selectedRowKeys,
  }

  const onChange = (page: number, pageSize: number) => {
    onChangePage(page, pageSize)
    setIsCheckAll(false)
    setSelectedRowKeys([])
  }

  const onChangeTable = (extra: TableCurrentDataSource<SubAdminListType>) => {
    setAuthorityPatternSettingsCurrent(extra.currentDataSource)

    setIsCheckAll(false)
    setSelectedRowKeys([])
  }

  useEffect(() => {
    const _dataCurrent = authorityPatternSettingsCurrent.slice(
      perItemStart,
      perItemEnd,
    )
    setDataCurrent(_dataCurrent)
  }, [
    authorityPatternSettingsCurrent,
    setDataCurrent,
    perItemStart,
    perItemEnd,
  ])

  useEffect(() => {
    if (!dataCurrent.length) {
      setIsCheckAll(false)
      setIsDisabledCheckAll(true)
    } else setIsDisabledCheckAll(false)
  }, [dataCurrent])

  useEffect(() => {
    if (authorityPatternSettings?.length)
      setAuthorityPatternSettingsCurrent(authorityPatternSettings)
  }, [authorityPatternSettings])

  useEffect(() => {
    if (authorityPatternSettingsCurrent?.length) onSelectChange()
  }, [selectedRowKeys])

  return {
    onChange,
    onChangeTable,
    perItem,
    rowSelection,
    selectedRowKeys,
  }
}
