import { FC } from "react"
import { Regex } from "../../../../../constant/Regex"
import { Translate } from "../../../../../constant/Translate"
import { ButtonGroup } from "../../Button/Group"
import { FormSearchProps } from "./models"
import { Styled } from "./styled"

const { TEXT_T113, TEXT_T022, TEXT_T198 } = Translate
const { StyledFormSearch, StyledFormSearchBtn, StyledFormSearchForm } = Styled
const { REGEX_IS_DESKTOP } = Regex

export const FormSearch: FC<FormSearchProps> = (props) => {
  const {
    children,
    form,
    heading,
    initialValues,
    isAdvanced,
    isHiddenReset,
    isHiddenSubmit,
    isLoading,
    labelAlign = REGEX_IS_DESKTOP ? "right" : "left",
    layout,
    name,
    onClickAdvanced,
    onClickReset,
    onClickSubmit,
    onFinish,
    onFinishFailed,
    onValuesChange,
    resetHtmlType = "reset",
  } = props

  return (
    <StyledFormSearch>
      <StyledFormSearchForm
        form={form}
        heading={heading}
        initialValues={initialValues}
        isBackground={false}
        isLoading={isLoading}
        layout={layout}
        labelAlign={labelAlign}
        name={name}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
      >
        {children}
        <StyledFormSearchBtn className="form-search-btn">
          <ButtonGroup
            buttonItems={[
              {
                htmlType: resetHtmlType,
                isHidden: isHiddenReset,
                onClick: onClickReset,
                order: 3,
                text: TEXT_T113,
                type: "ghost",
              },
              {
                htmlType: "submit",
                isHidden: isHiddenSubmit,
                onClick: onClickSubmit,
                order: 2,
                text: TEXT_T022,
                type: "primary",
              },
              {
                htmlType: "button",
                isHidden: !isAdvanced,
                onClick: onClickAdvanced,
                order: 1,
                text: TEXT_T198,
              },
            ]}
            isMargin={false}
          />
        </StyledFormSearchBtn>
      </StyledFormSearchForm>
    </StyledFormSearch>
  )
}
