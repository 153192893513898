/* eslint-disable complexity */
import { FC } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { Table } from "../../../../../../_webui/layout/components/Table"
import { Tabs } from "../../../../../../_webui/layout/components/Tabs"
import { TabItemsProps } from "../../../../../../_webui/layout/components/Tabs/models"
import { TabsLabel } from "../../../../../../_webui/layout/components/Tabs/TabsLabel"
import { TravelersRequestDeleteTabsProps } from "../../../models/requestDelete"

const { TEXT_T172, TEXT_T174, TEXT_T176, TEXT_T184 } = Translate

export const TravelersRequestDeleteTabs: FC<TravelersRequestDeleteTabsProps> = (
  props,
) => {
  const {
    columns,
    dataGridItems,
    isResetTable,
    isVisibleBtn,
    isVisibleTabRoleAdmin,
    isVisibleTabRoleSubAdmin,
    onChange,
    onChangeTable,
    onTabClick,
    perItem,
    rowSelection,
  } = props

  const items: TabItemsProps[] = []

  if (isVisibleTabRoleAdmin || isVisibleTabRoleSubAdmin)
    items.push({
      children: (
        <Table
          columns={columns}
          dataSource={dataGridItems?.deletionRequest}
          isResetTable={isResetTable}
          onChange={onChange}
          onChangeTable={onChangeTable}
          perItem={perItem}
          rowKey="travelerId"
          rowSelection={rowSelection}
        />
      ),
      key: "deletionRequest",
      label: (
        <TabsLabel
          text={isVisibleTabRoleAdmin ? TEXT_T172 : TEXT_T184}
          total={dataGridItems?.deletionRequest.length}
        />
      ),
    })

  if (isVisibleBtn)
    items.push({
      children: (
        <Table
          columns={columns}
          dataSource={dataGridItems?.logicalDeleteAccount}
          isResetTable={isResetTable}
          onChange={onChange}
          onChangeTable={onChangeTable}
          perItem={perItem}
          rowKey="travelerId"
          rowSelection={rowSelection}
        />
      ),
      key: "logicalDeleteAccount",
      label: (
        <TabsLabel
          text={TEXT_T174}
          total={dataGridItems?.logicalDeleteAccount.length}
        />
      ),
    })

  if (isVisibleTabRoleAdmin)
    items.push({
      children: (
        <Table
          columns={columns}
          dataSource={dataGridItems?.reactivationRequest}
          isResetTable={isResetTable}
          onChange={onChange}
          onChangeTable={onChangeTable}
          perItem={perItem}
          rowKey="travelerId"
          rowSelection={rowSelection}
        />
      ),
      key: "reactivationRequest",
      label: (
        <TabsLabel
          text={TEXT_T176}
          total={dataGridItems?.reactivationRequest.length}
        />
      ),
    })

  return <Tabs isMargin isCustom items={items} onTabClick={onTabClick} />
}
