import { GridItemType } from "../../../../../../../../_webui/layout/components/Grid/models"
import { Translate } from "../../../../../../../../constant/Translate"
import { UseSubAdminConfirmGridConfig } from "../../../../../models/subAdmin/confirm"

const { TEXT_T452, TEXT_T459 } = Translate

export const useGridConfig = (props: UseSubAdminConfirmGridConfig) => {
  const { bumonName, patternName } = props

  const gridItems: GridItemType[] = [
    {
      key: TEXT_T452,
      text: bumonName,
    },
    {
      key: TEXT_T459,
      text: patternName,
    },
  ]

  return {
    gridItems,
  }
}
