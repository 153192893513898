import { useState } from "react"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { useHistory } from "react-router-dom"
import { ListItemType } from "../../../../../../../_webui/layout/components/List/models"

export const useVariables = () => {
  const { push } = useHistory()
  const { isCommonRefer } = useVariablesAuthority()
  const [acquisitionInfo, setAcquisitionInfo] = useState<ListItemType[]>([])
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)

  return {
    acquisitionInfo,
    isCommonRefer,
    isLoadingLayout,
    push,
    setAcquisitionInfo,
    setIsLoadingLayout,
  }
}
