import styled, { css } from "styled-components"
import { FormItem } from "../.."
import { Regex } from "../../../../../../../constant/Regex"

const { REGEX_IS_DESKTOP } = Regex

const StyledFormItemAutoComplete = styled(FormItem)`
  ${REGEX_IS_DESKTOP
    ? css`
        margin-bottom: 0;
        margin-top: 0;
      `
    : css`
        .ant-form-item {
          margin-bottom: 0;
          margin-top: 0;
          &-control {
            .ant-form-item-label {
              height: 0;
            }
          }
        }
      `}
  .ant {
    &-select {
      &-clear {
        position: absolute;
        top: 12px;
        right: 11px;
        cursor: pointer;
      }
    }
    &-input-show-count-suffix {
      bottom: 20px;
    }
  }
`

const StyledFormItem = styled(FormItem)``

export const Styled = {
  StyledFormItem,
  StyledFormItemAutoComplete,
}
