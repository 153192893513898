import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = (isHiddenSubmit: boolean) => {
  const {
    isAttentionsBrowseResponse,
    isAttentionsReport,
    isCommonTravelerRefer,
    isRoleSubAdmin,
  } = useAuthority()

  const isHiddenBackBtn = !isCommonTravelerRefer
  const isHiddenExportBtn = !(
    isRoleSubAdmin &&
    isAttentionsReport &&
    isHiddenSubmit
  )
  const isHiddenAnswerBtn = !(
    isRoleSubAdmin &&
    isAttentionsBrowseResponse &&
    isHiddenSubmit
  )

  return {
    isAttentionsBrowseResponse,
    isCommonTravelerRefer,
    isHiddenAnswerBtn,
    isHiddenBackBtn,
    isHiddenExportBtn,
  }
}
