import { FC } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { ButtonGroup } from "../../../../../../_webui/layout/components/Button/Group"
import { ContactButtonGroupProps } from "../../../models/contact"

const { TEXT_T072, TEXT_T113, TEXT_T321 } = Translate

export const ContactButtonGroup: FC<ContactButtonGroupProps> = (props) => {
  const { goToHome, isDisabledSubmit, onOpenClear } = props

  return (
    <ButtonGroup
      buttonItems={[
        {
          htmlType: "button",
          isSmall: true,
          onClick: goToHome,
          order: 1,
          text: TEXT_T072,
          type: "ghost",
        },
        {
          htmlType: "button",
          isSmall: true,
          onClick: onOpenClear,
          order: 1,
          text: TEXT_T113,
          type: "ghost",
        },
        {
          htmlType: "submit",
          isDisabled: isDisabledSubmit,
          isSmall: true,
          order: 1,
          text: TEXT_T321,
          type: "primary",
        },
      ]}
      isMargin={false}
    />
  )
}
