import { useModal } from "../../../../../../../utils/hook/useModal"

export const useVariablesModal = () => {
  const {
    isOpen: isOpenAccept,
    onCancel: onCancelAccept,
    onOpen: onOpenAccept,
  } = useModal()
  const {
    isOpen: isOpenReEnable,
    onCancel: onCancelReEnable,
    onOpen: onOpenReEnable,
  } = useModal()
  const {
    isOpen: isOpenRequestCancel,
    onCancel: onCancelRequestCancel,
    onOpen: onOpenRequestCancel,
  } = useModal()
  const {
    isOpen: isOpenReActive,
    onCancel: onCancelReActive,
    onOpen: onOpenReActive,
  } = useModal()

  return {
    isOpenAccept,
    isOpenReActive,
    isOpenReEnable,
    isOpenRequestCancel,
    onCancelAccept,
    onCancelReActive,
    onCancelReEnable,
    onCancelRequestCancel,
    onOpenAccept,
    onOpenReActive,
    onOpenReEnable,
    onOpenRequestCancel,
  }
}
