/* eslint-disable max-statements */
import Excel from "exceljs"
import { Translate } from "../../../../../../../../../../../constant/Translate"
import { statusesCountHeader } from "../../config/header/statusesCountHeader"
import {
  SafetiesDetailExcelSheetType,
  SafetyInfoHeaderType,
} from "../../../../../../../../models/detail/export"

const {
  EXPORT_EP0019, // 安否状況    Safety status
  EXPORT_EP0098, // 代理応答    Respond on Behalf
  EXPORT_EP0024, // 応答時刻    Response time
  EXPORT_EP0150, // 氏名    Full name
  EXPORT_EP0151, // パスポート表記    Passport notation
  EXPORT_EP0158, // 人材種別（詳細）    Dispatch type (Detail)
  EXPORT_EP0012, // パスポート番号    Passport number
  EXPORT_EP0078, // 主管部署    Department
  EXPORT_EP0103, // 電話番号    Phone number
  EXPORT_EP0065, // 宿泊先名称    Accommodation name
  EXPORT_EP0062, // 宿泊先住所    Accommodation address
  EXPORT_EP0066, // 宿泊先連絡先    Accommodation contact information
  EXPORT_EP0061, // 宿泊期間    Accommodation period
  EXPORT_EP0020, // 案件名    Project name
  EXPORT_EP0057, // 主管部署・担当者名    Name of department/person in charge
  EXPORT_EP0104, // 渡航期間    Travel period
  EXPORT_EP0191, // 旅程    Itinerary
} = Translate

export const columnsConfig = (
  data: SafetiesDetailExcelSheetType[],
  header: SafetyInfoHeaderType,
  worksheet: Excel.Worksheet,
) => {
  const { currentDate, implementationDate, safetyTitle, totalCountHeader } =
    header
  worksheet.columns = [
    { width: 3.5 },
    { width: 17.25 },
    { width: 9.5 },
    { width: 17 },
    { width: 17.5 },
    { width: 28 },
    { width: 25.5 },
    { width: 23.5 },
    { width: 35.13 },
    { width: 28.63 },
    { width: 24.25 },
    { width: 50.75 },
    { width: 35.38 },
    { width: 20.75 },
    { width: 36.88 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
    { width: 25 },
  ]
  worksheet.getCell("A1").value = safetyTitle
  worksheet.getCell("O1").value = implementationDate
  worksheet.getCell("O2").value = currentDate
  worksheet.getCell("A3").value = totalCountHeader
  worksheet.getCell("A4").value = statusesCountHeader(data)
  worksheet.getCell("A6").value = ""
  worksheet.getCell("B6").value = EXPORT_EP0019
  worksheet.getCell("C6").value = EXPORT_EP0098
  worksheet.getCell("D6").value = EXPORT_EP0024
  worksheet.getCell("E6").value = `${EXPORT_EP0150}\n${EXPORT_EP0151}`
  worksheet.getCell("F6").value = `${EXPORT_EP0158}\n${EXPORT_EP0012}`
  worksheet.getCell("G6").value = EXPORT_EP0078
  worksheet.getCell("H6").value = EXPORT_EP0103
  worksheet.getCell("I6").value = EXPORT_EP0065
  worksheet.getCell("J6").value = EXPORT_EP0062
  worksheet.getCell("K6").value = EXPORT_EP0066
  worksheet.getCell("L6").value = EXPORT_EP0061
  worksheet.getCell("M6").value = EXPORT_EP0020
  worksheet.getCell("N6").value = EXPORT_EP0057
  worksheet.getCell("O6").value = EXPORT_EP0104
  worksheet.getCell("P6").value = EXPORT_EP0191
}
