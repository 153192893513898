import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { Routers } from "../../../../../../../constant/Routers"
import { Translate } from "../../../../../../../constant/Translate"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import {
  TravelerDeleteSuccessProps,
  UseTravelersDeleteSuccessType,
} from "../../../../models/deleteSuccess"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { getBreadcrumbItems } from "./getBreadcrumbItems"

const { SCR114_USER, SCR920 } = Routers
const { TEXT_T104, TEXT_T105, TEXT_T636, TEXT_T652 } = Translate

export const useTravelersDeleteSuccess = (): UseTravelersDeleteSuccessType => {
  const { push } = useHistory()
  const { state } = useLocation<TravelerDeleteSuccessProps>()
  const {
    isRoleAdmin,
    isRoleSubAdmin,
    isTravelerDelete,
    isVisibleBtnDelete,
    isVisibleBtnHome,
    isVisibleBtnRequestDelete,
  } = useVariablesAuthority()
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)

  const titleAlert = isRoleSubAdmin ? TEXT_T652 : TEXT_T105
  const titleHeading = isRoleSubAdmin ? TEXT_T636 : TEXT_T104

  const breadcrumbItems = getBreadcrumbItems(
    isRoleAdmin,
    titleHeading,
    state?.isFromSCR115,
    state?.isFromSCR126,
  )

  const goToHome = () => {
    // 「SCR920_ホーム画面」に遷移する。
    push(SCR920)
  }

  const goToTravelersList = () => {
    // 「SCR114_渡航者情報の一覧画面」に遷移する。
    push({
      pathname: SCR114_USER,
      state: { searchCondition: state?.searchCondition },
    })
  }

  useEffect(() => {
    // ユーザの画面アクセス許可チェックを処理する。
    if (!isTravelerDelete) {
      // 「SCR973_エラー情報の表示画面」に遷移する。
      const { pathname, state } = backToLogin()
      push({ pathname, state })
    } else {
      setTimeout(() => {
        setIsLoadingLayout(false)
      }, 500)
    }
  }, [])

  return {
    breadcrumbItems,
    goToHome,
    goToTravelersList,
    isLoadingLayout,
    isVisibleBtnDelete,
    isVisibleBtnHome,
    isVisibleBtnRequestDelete,
    titleAlert,
    titleHeading,
  }
}
