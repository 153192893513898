import { FC } from "react"
import { Translate } from "../../../../../constant/Translate"
import { getValueByDevice } from "../../../../../utils/getValueByDevice"
import { PreLine } from "../PreLine"

const { TEXT_T038, TEXT_T038_1 } = Translate

export const RomajiPassportFullName: FC = () => (
  <PreLine>{getValueByDevice(TEXT_T038_1, TEXT_T038)}</PreLine>
)
