import styled from "styled-components"
import { Button } from "../../../../../../_webui/layout/components/Button"
import { Regex } from "../../../../../../constant/Regex"

const { REGEX_IS_DESKTOP } = Regex

const StyledContainer = styled.div`
  margin: 50px auto 0;
  max-width: 400px;
`
const StyledButtonSubmit = styled(Button)`
  margin-top: ${REGEX_IS_DESKTOP ? "15px" : "40px"};
  width: 100%;
`
const StyledButtonReset = styled(Button)`
  margin-top: 40px;
`

export const Styled = {
  StyledButtonReset,
  StyledButtonSubmit,
  StyledContainer,
}
