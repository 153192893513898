import { FC, Fragment } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { Button } from "../../../../../../_webui/layout/components/Button"
import { ButtonGroup } from "../../../../../../_webui/layout/components/Button/Group"
import { Center } from "../../../../../../_webui/layout/components/Center"
import { TravelersButtonGroupProps } from "../../../models/list"
import { Regex } from "../../../../../../constant/Regex"

const { REGEX_IS_DESKTOP } = Regex
const { HELP_H0032, TEXT_T030, TEXT_T031, TEXT_T032, TEXT_T033, TEXT_T034 } =
  Translate

export const TravelersButtonGroup: FC<TravelersButtonGroupProps> = (props) => {
  const {
    goToSchedulesAdd,
    goToTravelersDelete,
    goToTravelersDetail,
    onOpen,
    isDisabledBtnDelete,
    isDisabledBtnRefer,
    isHiddenBtnDelete,
    isHiddenBtnExportFile,
    isHiddenBtnRefer,
    isHiddenBtnRegister,
    isHiddenBtnRequestDelete,
  } = props

  return (
    <Fragment>
      <Center isMarginTop isBtnFullWidth>
        <Button
          isDisabled={isDisabledBtnDelete}
          isHidden={!isHiddenBtnDelete}
          onClick={goToTravelersDelete}
          text={TEXT_T034}
          type={"danger"}
        />
        <Button
          isDisabled={isDisabledBtnDelete}
          isHidden={!isHiddenBtnRequestDelete}
          onClick={goToTravelersDelete}
          text={TEXT_T030}
          title={HELP_H0032}
          type={"danger"}
        />
        <Button
          isDisabled={isDisabledBtnRefer}
          isHidden={!isHiddenBtnRegister}
          onClick={goToSchedulesAdd}
          order={1}
          text={TEXT_T031}
          type="primary"
        />
      </Center>
      <ButtonGroup
        buttonItems={[
          {
            isHidden: !REGEX_IS_DESKTOP || !isHiddenBtnExportFile,
            onClick: onOpen,
            text: TEXT_T032,
          },
          {
            isDisabled: isDisabledBtnRefer,
            isHidden: !isHiddenBtnRefer,
            onClick: goToTravelersDetail,
            text: TEXT_T033,
          },
        ]}
        isMargin={false}
      />
    </Fragment>
  )
}
