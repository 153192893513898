import { FC, Fragment } from "react"
import { Translate } from "../../../../../../../../constant/Translate"
import { Divider } from "../../../../../../../../_webui/layout/components/Divider"
import { ItemInputGroup } from "../../../../../../../../_webui/layout/components/Form/Item/Input/Group"
import { ItemSelect } from "../../../../../../../../_webui/layout/components/Form/Item/Select"
import { KanjiPassportFullName } from "../../../../../../../../_webui/layout/components/Typography/KanjiPassportFullName"
import { RomajiPassportFullName } from "../../../../../../../../_webui/layout/components/Typography/RomajiPassportFullName"
import { InputItems } from "../constant"
import { CollapseBasicProps } from "../models"

const { TEXT_T003, TEXT_T006, TEXT_T025, TEXT_T039 } = Translate
const {
  KANJI_FULL_NAME,
  KANJI_PASSPORT_FULL_NAME,
  ROMAJI_FULL_NAME,
  ROMAJI_PASSPORT_FULL_NAME,
} = InputItems

export const CollapseBasic: FC<CollapseBasicProps> = (props) => {
  const {
    dispatchType,
    dispatchTypeDetail,
    isDisabledDispatchTypeDetail,
    onChangeDispatchType,
  } = props

  return (
    <Fragment>
      <ItemInputGroup
        className="ant-form-item-group-first"
        inputItems={KANJI_FULL_NAME}
        label={TEXT_T003}
        name="fullNameKanji"
      />
      <ItemInputGroup
        inputItems={ROMAJI_FULL_NAME}
        label={TEXT_T006}
        name="fullNameRomaji"
      />
      <ItemInputGroup
        className="ant-form-item-group-first"
        inputItems={KANJI_PASSPORT_FULL_NAME}
        label={<KanjiPassportFullName />}
        name="kanjiPassportFullName"
      />
      <ItemInputGroup
        inputItems={ROMAJI_PASSPORT_FULL_NAME}
        label={<RomajiPassportFullName />}
        name="romajiPassportFullName"
      />
      <Divider />
      <ItemSelect
        label={TEXT_T039}
        name="dispatchType"
        selectItem={dispatchType}
        onChange={onChangeDispatchType}
      />
      <ItemSelect
        isDisabled={isDisabledDispatchTypeDetail}
        label={TEXT_T025}
        name="dispatchTypeDetail"
        selectItem={dispatchTypeDetail}
      />
    </Fragment>
  )
}
