import { FormInstance } from "antd"
import { AxiosErrorType } from "../../infrastructure/axiosError/models"
import { AnyValue } from "../../_webui/layout/models"
import { replaceStringParam } from "../replaceStringParam"
import { translateMessage } from "../translateMessage"

export const setFieldsErrors = (
  error: AxiosErrorType,
  form?: FormInstance<AnyValue>,
) => {
  if (error?.debug?.response?.data) {
    const data = error?.debug?.response?.data
    const { entityName, message, params } = data
    if (entityName && message) {
      form?.setFields([
        {
          errors: [
            replaceStringParam(
              translateMessage(`MESSAGE.${message}`),
              params.split(",")[0],
              params.split(",")[1],
              params.split(",")[2],
            ),
          ],
          name: entityName,
        },
      ])
      form?.scrollToField(entityName)
    }
  }
}
