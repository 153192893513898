import styled from "styled-components"

const StyledSpace = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
`

export const Styled = {
  StyledSpace,
}
