import { useHistory } from "react-router-dom"
import keycloak from "../../../../../utils/keycloak"
import { useState } from "react"
import { LoginActions } from "../../../../../constant/Login"
import { getUserInformation } from "../../../../../utils/getUserInformation"

const { KEY } = LoginActions
export const useVariables = () => {
  const { push } = useHistory()
  const isAuthenticated = keycloak?.authenticated
  const userInformation = getUserInformation()
  const [itemKey, setItemKey] = useState(localStorage.getItem(KEY))

  return {
    isAuthenticated,
    itemKey,
    push,
    setItemKey,
    userInformation,
  }
}
