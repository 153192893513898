/* eslint-disable max-lines */
/* eslint-disable max-statements */
import { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { OptionItemType } from "../../../../../../../../_webui/layout/components/Select/models"
import { GeneralCode } from "../../../../../../../../constant/GeneralCode/index"
import { getGeneralMasters } from "../../../../../../../../utils/apis/getGeneralMasters"
import { UploadFileType } from "../../../../../models/files/upload"
import { useForm } from "../useForm"
import { useRowSelection } from "../useRowSelection"
import { useTableConfig } from "../useTableConfig"
import { Routers } from "../../../../../../../../constant/Routers"
import { useVariablesModal } from "../useVariablesModal"
import { Translate } from "../../../../../../../../constant/Translate"

const { TYPE } = GeneralCode
const { SCR971 } = Routers
const { MESSAGE_C0039 } = Translate

export const useVariables = () => {
  const { push } = useHistory()
  const { pathname } = useLocation()

  // APIを呼び出し、種類一覧を取得し
  const { generalMasters: typeInfo, generalMastersAll: typeInfoAll } =
    getGeneralMasters(TYPE)

  const {
    isOpen,
    isOpenConfirm,
    isOpenConfirmDuplicate,
    onCancel,
    onCancelConfirm,
    onCancelConfirmDuplicate,
    onOpen,
    onOpenConfirm,
    onOpenConfirmDuplicate,
  } = useVariablesModal()
  const {
    allCountries,
    countryCode,
    countryName,
    fileCategory,
    fileType,
    fileTypeName,
    form,
    isCommonUploadRegister,
    isCountry,
    isDisabledCountries,
    isHiddenBtn,
    isLoading,
    isRoleAdmin,
    onChangeCountryCode,
    onChangeFileType,
    setFileCategory,
    setFileTypeName,
    setIsDisabledCountries,
    setIsLoading,
    sourceURL,
    stateFileType,
    typeInfoSubAdmin,
  } = useForm(typeInfo, typeInfoAll)
  const [fileInfoList, setFileInfoList] = useState<UploadFileType[]>([])
  const [currentUsage, setCurrentUsage] = useState(0)
  const [isResetTable, setIsResetTable] = useState(false)
  const [messageConfirmDuplicate, setMessageConfirmDuplicate] =
    useState(MESSAGE_C0039)
  const {
    onChangePagination,
    onChangeTable,
    perItem,
    rowSelection,
    selectedRowKeys,
  } = useRowSelection(fileInfoList)

  const [fileTypeList, setFileTypeList] = useState<OptionItemType[]>([])
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [fileNameList, setFileNameList] = useState<string[]>([])
  const { columns } = useTableConfig(isCountry)

  const requiredFileType = pathname === SCR971
  const requiredCountryCode = requiredFileType && !isDisabledCountries

  return {
    allCountries,
    columns,
    countryCode,
    countryName,
    currentUsage,
    fileCategory,
    fileInfoList,
    fileNameList,
    fileType,
    fileTypeList,
    fileTypeName,
    form,
    isCommonUploadRegister,
    isCountry,
    isDisabledCountries,
    isHiddenBtn,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenConfirm,
    isOpenConfirmDuplicate,
    isResetTable,
    isRoleAdmin,
    messageConfirmDuplicate,
    onCancel,
    onCancelConfirm,
    onCancelConfirmDuplicate,
    onChangeCountryCode,
    onChangeFileType,
    onChangePagination,
    onChangeTable,
    onOpen,
    onOpenConfirm,
    onOpenConfirmDuplicate,
    perItem,
    push,
    requiredCountryCode,
    requiredFileType,
    rowSelection,
    selectedRowKeys,
    setCurrentUsage,
    setFileCategory,
    setFileInfoList,
    setFileNameList,
    setFileTypeList,
    setFileTypeName,
    setIsDisabledCountries,
    setIsLoading,
    setIsLoadingLayout,
    setIsResetTable,
    setMessageConfirmDuplicate,
    sourceURL,
    stateFileType,
    typeInfo,
    typeInfoAll,
    typeInfoSubAdmin,
  }
}
