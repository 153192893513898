import { FC } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { ButtonGroup } from "../../../../../../_webui/layout/components/Button/Group"
import { AttentionsDetailButtonProps } from "../../../models/detail"
import { Regex } from "../../../../../../constant/Regex"

const { REGEX_IS_DESKTOP } = Regex
const { TEXT_T072, TEXT_T351, TEXT_T352 } = Translate

export const AttentionsDetailButton: FC<AttentionsDetailButtonProps> = (
  props,
) => {
  const {
    goBack,
    isHiddenAnswerBtn,
    isHiddenBackBtn,
    isHiddenExportBtn,
    isResponse,
    onOpen,
    onOpenExport,
  } = props

  return (
    <ButtonGroup
      buttonItems={[
        {
          absolutePosition: "left",
          isAbsolute: true,
          isHidden: isHiddenBackBtn,
          isSmall: true,
          onClick: goBack,
          order: 3,
          text: TEXT_T072,
          type: "ghost",
        },
        {
          isHidden: !REGEX_IS_DESKTOP || isHiddenExportBtn,
          onClick: onOpenExport,
          order: 1,
          text: TEXT_T351,
          type: "primary",
        },
        {
          isDisabled: isResponse,
          isHidden: isHiddenAnswerBtn,
          onClick: onOpen,
          order: 2,
          text: TEXT_T352,
          type: "primary",
        },
      ]}
    />
  )
}
