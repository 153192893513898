import { FC } from "react"
import { Alert } from "../../../../../_webui/layout/components/Alert"
import { ButtonGroup } from "../../../../../_webui/layout/components/Button/Group"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { BreakText } from "../../../../../_webui/layout/components/Typography/BreakText"
import { Translate } from "../../../../../constant/Translate"
import { useTravelersDeleteSuccess } from "./modules/useDeleteSuccess"

const { TEXT_T016, TEXT_T018, TEXT_T106, TEXT_T107 } = Translate

export const TravelersDeleteSuccess: FC = () => {
  const {
    breadcrumbItems,
    goToHome,
    goToTravelersList,
    isLoadingLayout,
    isVisibleBtnDelete,
    isVisibleBtnHome,
    isVisibleBtnRequestDelete,
    titleAlert,
    titleHeading,
  } = useTravelersDeleteSuccess()

  return (
    <Layout
      breadcrumbItems={breadcrumbItems}
      isLoadingLayout={isLoadingLayout}
      title={titleHeading}
    >
      <Card heading={titleHeading}>
        <Alert
          type="success"
          content={<BreakText above={titleAlert} below={TEXT_T016} />}
        />
        <ButtonGroup
          buttonItems={[
            {
              isHidden: !isVisibleBtnHome,
              onClick: goToHome,
              order: 2,
              text: TEXT_T018,
              type: "ghost",
            },
            {
              isHidden: !isVisibleBtnDelete,
              onClick: goToTravelersList,
              order: 1,
              text: TEXT_T106,
              type: "primary",
            },
            {
              isHidden: !isVisibleBtnRequestDelete,
              onClick: goToTravelersList,
              order: 1,
              text: TEXT_T107,
              type: "primary",
            },
          ]}
        />
      </Card>
    </Layout>
  )
}
