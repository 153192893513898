import moment, { Moment } from "moment"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import {
  SystemsManualDataSourceType,
  GetFilesParamType,
} from "../../../../models/manual"
import { Routers } from "../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { commonApi } from "../../../../../settings/apis"

const { SCR973 } = Routers

export const useApi = () => {
  const { push } = useHistory()
  const [fileInfoList, setFileInfoList] = useState<
    SystemsManualDataSourceType[]
  >([])
  const [initDateTime, setInitDateTime] = useState<Moment>()
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)

  const getFiles = (params: GetFilesParamType) => {
    // APIを呼び出し、APIからの応答結果を「戻りファイル情報」変数に設定する。
    commonApi
      .getFiles({ ...params })
      .then((res: SystemsManualDataSourceType[]) => {
        setFileInfoList(res)
        setInitDateTime(moment())
        setIsLoadingLayout(false)
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const stateError = pushStateError(error)
        push({ pathname: SCR973, state: stateError })
      })
  }

  return {
    fileInfoList,
    getFiles,
    initDateTime,
    isLoadingLayout,
  }
}
