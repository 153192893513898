import { FC } from "react"
import { Translate } from "../../../../../constant/Translate"
import { getValueByDevice } from "../../../../../utils/getValueByDevice"
import { PreLine } from "../PreLine"

const { TEXT_T037, TEXT_T037_1 } = Translate

export const KanjiPassportFullName: FC = () => (
  <PreLine>{getValueByDevice(TEXT_T037_1, TEXT_T037)}</PreLine>
)
