/* eslint-disable complexity */
import { Moment } from "moment"
import { GeneralCode } from "../../../../../../../constant/GeneralCode"
import { getDateUtc } from "../../../../../../../utils/getDate"
import {
  getHourMoment,
  getMinuteMoment,
} from "../../../../../../../utils/getTime"
import { SetSchedulesAddRowDataTableProps } from "../../../../models/add/DataAdd"

const { OTHER } = GeneralCode

export const setDetailFieldValue = async (
  props: SetSchedulesAddRowDataTableProps,
) => {
  const { detailData, form, index, isSelectDepartureFlight } = props
  const getValueOtherCity = (value1: string, value2?: string) =>
    value1 === OTHER && value2 ? value2 : ""
  const getValueAutoComplete = (value: string | Moment) =>
    isSelectDepartureFlight ? "" : value
  const fieldName = `schedulesAddDetail.${index}`

  form?.setFieldsValue({
    [`${fieldName}.arrivalCityCode`]: detailData.arrivalCityCode,
    [`${fieldName}.arrivalCityName`]: getValueOtherCity(
      detailData.arrivalCityCode,
      detailData.arrivalCityNameOther,
    ),
    [`${fieldName}.arrivalCountryCode`]: detailData.arrivalCountryCode,
    [`${fieldName}.arrivalDate`]: getValueAutoComplete(
      getDateUtc(detailData.arrivalDateTime),
    ),
    [`${fieldName}.arrivalHour`]: getHourMoment(detailData.arrivalDateTime),
    [`${fieldName}.arrivalMinute`]: getMinuteMoment(detailData.arrivalDateTime),
    [`${fieldName}.arrivalTime`]: getDateUtc(detailData.arrivalDateTime),
    [`${fieldName}.departureCityCode`]: detailData.departureCityCode,
    [`${fieldName}.departureCityName`]: getValueOtherCity(
      detailData.departureCityCode,
      detailData.departureCityNameOther,
    ),
    [`${fieldName}.departureCountryCode`]: detailData.departureCountryCode,
    [`${fieldName}.departureDate`]: getValueAutoComplete(
      getDateUtc(detailData.departureDateTime),
    ),
    [`${fieldName}.departureFlightNumber`]: detailData.departureFlightNumber,
    [`${fieldName}.departureHour`]: getHourMoment(detailData.departureDateTime),
    [`${fieldName}.departureMinute`]: getMinuteMoment(
      detailData.departureDateTime,
    ),
    [`${fieldName}.departureTime`]: getDateUtc(detailData.departureDateTime),
    [`${fieldName}.isTransit`]: detailData.isTransit,
    [`${fieldName}.othersRemarks1`]: detailData.othersRemarks1,
    [`${fieldName}.othersRemarks2`]: detailData.othersRemarks2,
    [`${fieldName}.othersRemarks3`]: detailData.othersRemarks3,
    [`${fieldName}.othersRemarks4`]: detailData.othersRemarks4,
    [`${fieldName}.othersRemarks5`]: detailData.othersRemarks5,
    [`${fieldName}.transportation`]: detailData.transportation,
    [`${fieldName}.travelDetailId`]: detailData.travelDetailId,
  })
}
