import { FC } from "react"
import { FrameProps } from "./models"
import { Styled } from "./styled"
import { Title } from "../Typography/Title"

const { StyledFrame, StyledFrameContainer } = Styled

export const Frame: FC<FrameProps> = (props) => {
  const { children, titleLeft, ...res } = props
  return (
    <StyledFrame>
      {titleLeft && <Title titleLeft={titleLeft} />}
      <StyledFrameContainer {...res}>{children}</StyledFrameContainer>
    </StyledFrame>
  )
}
