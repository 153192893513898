import { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { SafetiesCloneDataType } from "../../../../models/clone"
import { useRowSelection } from "../useRowSelection"
import { useTableConfig } from "../useTableConfig"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { SafetiesStateType } from "../../../../models/safety"

export const useVariables = () => {
  const { pathname, state } = useLocation<SafetiesStateType>()
  const { push } = useHistory()
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const { columns } = useTableConfig()
  const [dataSource, setDataSource] = useState<SafetiesCloneDataType[]>([])
  const {
    isActiveBtnClone,
    onChange,
    onChangeTable,
    perItem,
    rowSelection,
    selectedRowKeys,
  } = useRowSelection(dataSource)
  const { isHiddenBtnBack, isHiddenBtnCopy, isSafetiesSendAll } =
    useVariablesAuthority()

  return {
    columns,
    dataSource,
    isDisabledBtnCopy: isActiveBtnClone,
    isHiddenBtnBack,
    isHiddenBtnCopy,
    isLoadingLayout,
    isSafetiesSendAll,
    onChange,
    onChangeTable,
    pathname,
    perItem,
    push,
    rowSelection,
    selectedRowKeys,
    setDataSource,
    setIsLoadingLayout,
    state,
  }
}
