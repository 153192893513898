import { Table } from "antd"
import { Translate } from "../../../../../../../constant/Translate"
import { UseTableConfigProps } from "../../../../../../../utils/apis/getGeneralMasters/models"
import { validatorRomajiName } from "../../../../../../../utils/validator/romajiName"
import { ItemInput } from "../../../../../../../_webui/layout/components/Form/Item/Input"
import { ItemSelect } from "../../../../../../../_webui/layout/components/Form/Item/Select"
import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../_webui/layout/components/Table/models"
import { TravelersEditColumnType } from "../../../../models/edit"

const { TEXT_T007, TEXT_T008, TEXT_T042, TEXT_T043, TEXT_T240 } = Translate
const { SELECTION_COLUMN } = Table

export const useTableConfig = (
  props: UseTableConfigProps,
): UseTableConfigType<TravelersEditColumnType> => {
  const { relationships } = props
  const columns: ColumnType<TravelersEditColumnType>[] = [
    {
      render: (_, _data, index) => index + 1,
      title: TEXT_T240,
      width: 65,
    },
    SELECTION_COLUMN,
    {
      align: "left",
      children: [
        {
          dataIndex: "familyRomajiSurName",
          render: (_, data) => (
            <ItemInput
              isHiddenLabel
              label={TEXT_T007}
              maxLength={50}
              name={`familyRomajiSurName_${data.index}`}
              rules={[validatorRomajiName]}
            />
          ),
          title: TEXT_T007,
          width: "50%",
        },
        {
          dataIndex: "familyRomajiName",
          render: (_, data) => (
            <ItemInput
              isHiddenLabel
              label={TEXT_T008}
              maxLength={50}
              name={`familyRomajiName_${data.index}`}
              rules={[validatorRomajiName]}
            />
          ),
          title: TEXT_T008,
          width: "50%",
        },
      ],
      title: TEXT_T042,
      width: "calc(100% - 310px)",
    },
    {
      dataIndex: "familyRelationship",
      render: (_, data) => (
        <ItemSelect
          isHiddenLabel
          label={TEXT_T043}
          name={`familyRelationship_${data.index}`}
          selectItem={relationships}
        />
      ),
      title: TEXT_T043,
      width: 250,
    },
  ]
  return {
    columns,
  }
}
