import moment from "moment"
import { Timezone } from "../../constant/Timezone"

const { HOUR_FORMAT, MINUTE_FORMAT, TIME_FORMAT } = Timezone

const time = (_time?: string) => _time || "00"

export const getTime = (hour: string, minute: string, second?: string) =>
  hour && minute && `${time(hour)}:${time(minute)}:${time(second)}`

export const getHourMoment = (hour?: string) =>
  hour ? moment(hour).utc().format(HOUR_FORMAT) : ""
export const getMinuteMoment = (minute?: string) =>
  minute ? moment(minute).utc().format(MINUTE_FORMAT) : ""
export const getTimeMoment = (time?: string) =>
  time ? moment(time).format(TIME_FORMAT) : ""

export const getHourExport = (date?: string) => {
  const format = `${HOUR_FORMAT}:${MINUTE_FORMAT}`
  return date ? moment(date).utc().format(format) : ""
}
