/* eslint-disable no-undefined */
export const replaceStringParam = (
  text: string,
  param1: number | string,
  param2?: number | string,
  param3?: number | string,
) => {
  const content = text.replace("{0}", param1.toString())
  if (param2 !== undefined && param3 !== undefined)
    return content
      .replace("{1}", param2.toString())
      .replace("{2}", param3.toString())
  if (param2 !== undefined) return content.replace("{1}", param2.toString())
  return content
}
