import { FC } from "react"
import { Translate } from "../../../../../../../constant/Translate"
import { ButtonGroup } from "../../../../../../../_webui/layout/components/Button/Group"
import { FilesUploadButtonGroupProps } from "../../../../models/files/upload"

const { TEXT_T034, TEXT_T072, TEXT_T507 } = Translate

export const FilesUploadButtonGroup: FC<FilesUploadButtonGroupProps> = (
  props,
) => {
  const {
    dataLength,
    goToFileManager,
    isHiddenBtn,
    onOpen,
    onOpenConfirm,
    selectedRowKeysLength,
  } = props
  const isHidden = !dataLength || !isHiddenBtn

  return (
    <ButtonGroup
      buttonItems={[
        {
          isAbsolute: true,
          isHidden: !isHiddenBtn,
          isSmall: true,
          onClick: goToFileManager,
          order: 3,
          text: TEXT_T072,
          type: "default",
        },
        {
          isDisabled: !selectedRowKeysLength,
          isHidden: isHidden,
          // 選択されているファイル削除の確認ダイアログを初期表示する。
          onClick: onOpen,
          order: 2,
          text: TEXT_T034,
          type: "danger",
        },
        {
          isDisabled: !selectedRowKeysLength,
          isHidden: isHidden,
          // ファイルアップロードの確認ダイアログを表示する。
          onClick: onOpenConfirm,
          order: 1,
          text: TEXT_T507,
          type: "primary",
        },
      ]}
    />
  )
}
