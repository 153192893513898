import { FC, Fragment } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { ModalConfirm } from "../../../../../../_webui/layout/components/Modal/Confirm"
import { SchedulesBatchUpdateModalConfirmProps } from "../../../models/batchUpdate"

const { MESSAGE_C0019, MESSAGE_C0024 } = Translate

export const SchedulesBatchUpdateModalConfirm: FC<
  SchedulesBatchUpdateModalConfirmProps
> = (props) => {
  const {
    bulkDeleteDataConfirm,
    bulkUpdate,
    isOpen,
    isOpenUploaded,
    onCancel,
    onCancelUploaded,
  } = props

  return (
    <Fragment>
      <ModalConfirm isOpen={isOpen} onCancel={onCancel} onOk={bulkUpdate}>
        {MESSAGE_C0024}
      </ModalConfirm>
      <ModalConfirm
        isOpen={isOpenUploaded}
        onCancel={onCancelUploaded}
        onOk={bulkDeleteDataConfirm}
      >
        {MESSAGE_C0019}
      </ModalConfirm>
    </Fragment>
  )
}
