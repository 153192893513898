/* eslint-disable max-lines */
/* eslint-disable max-statements */
import { OptionItemType } from "../../../../../../../../_webui/layout/components/Select/models"
import { GeneralCode } from "../../../../../../../../constant/GeneralCode"
import { Routers } from "../../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../../infrastructure/axiosError/models"
import { countriesApi } from "../../../../../../../../infrastructure/handlers/countries"
import { AllCountriesResType } from "../../../../../../../../infrastructure/handlers/countries/models"
import { GeneralMastersResType } from "../../../../../../../../infrastructure/handlers/generalMasters/models"
import { pushStateError } from "../../../../../../../../utils/pushStateError"
import { OptionType } from "../../../../../../../../_webui/layout/components/Form/Item/Select/models"
import { useVariablesForm } from "../useVariablesForm"

const { MEASURES_TYPE, SAFETY_MEASURES_MANUAL_TYPE } = GeneralCode
const { SCR973 } = Routers

export const useForm = (
  typeInfo?: OptionItemType[],
  typeInfoAll?: GeneralMastersResType[],
) => {
  const {
    allCountries,
    countryCode,
    countryName,
    fileCategory,
    fileType,
    fileTypeName,
    form,
    isCommonUploadRegister,
    isCountry,
    isDisabledCountries,
    isHiddenBtn,
    isLoading,
    isRoleAdmin,
    push,
    setAllCountries,
    setCountryCode,
    setCountryName,
    setFileCategory,
    setFileType,
    setFileTypeName,
    setIsDisabledCountries,
    setIsLoading,
    sourceURL,
    stateFileType,
    typeInfoSubAdmin,
  } = useVariablesForm()

  const onChangeFileType = async (value: string) => {
    form.setFields([{ errors: [], name: "countryCode", value: "" }])

    if (value === MEASURES_TYPE || value === SAFETY_MEASURES_MANUAL_TYPE) {
      setIsDisabledCountries(false)
      setIsLoading(true)
      // APIを呼び出し、国情報一覧を取得し
      await countriesApi
        .getAllCountries()
        .then((res: AllCountriesResType[]) => {
          const optionItem: OptionItemType[] = res.map((item) => ({
            text: item.countryName,
            value: item.countryCode,
          }))

          setAllCountries(optionItem)
          setIsLoading(false)
        })
        .catch((error: AxiosErrorType) => {
          // 「SCR973_エラー情報の表示画面」に遷移する。
          const state = pushStateError(error)
          push({ pathname: SCR973, state })
        })
    } else {
      setIsDisabledCountries(true)
      setCountryCode("")
      setCountryName("")
    }

    const _fileTypeName =
      typeInfo?.find((item) => item.value === value)?.text || ""
    setFileType(value)
    setFileTypeName(_fileTypeName)

    const _fileCategory = typeInfoAll?.find(
      (item) => item.key1 === value,
    )?.value3
    if (_fileCategory) setFileCategory(_fileCategory)
  }

  const onChangeCountryCode = (value: string, option: OptionType) => {
    if (!Array.isArray(option)) setCountryName(option.label)
    setCountryCode(value)
  }

  return {
    allCountries,
    countryCode,
    countryName,
    fileCategory,
    fileType,
    fileTypeName,
    form,
    isCommonUploadRegister,
    isCountry,
    isDisabledCountries,
    isHiddenBtn,
    isLoading,
    isRoleAdmin,
    onChangeCountryCode,
    onChangeFileType,
    setFileCategory,
    setFileTypeName,
    setIsDisabledCountries,
    setIsLoading,
    sourceURL,
    stateFileType,
    typeInfoSubAdmin,
  }
}
