import { FC, Fragment } from "react"
import { ApplicationFormButtonGroupProps } from "../../../models/list"
import { Translate } from "../../../../../../constant/Translate"
import { ButtonGroup } from "../../../../../../_webui/layout/components/Button/Group"
import { Regex } from "../../../../../../constant/Regex"

const { REGEX_IS_DESKTOP } = Regex
const { TEXT_T034, TEXT_T505, TEXT_T510 } = Translate

export const ApplicationFormButtonGroup: FC<ApplicationFormButtonGroupProps> = (
  props,
) => {
  const {
    goToFilesUpload,
    handleDownload,
    isDisabledBtnDelete,
    isDisabledBtnDownload,
    isVisibleBtnDelete,
    isVisibleBtnReference,
    isVisibleBtnRegister,
    onOpen,
  } = props

  return REGEX_IS_DESKTOP ? (
    <Fragment>
      {isVisibleBtnDelete && (
        <ButtonGroup
          buttonItems={[
            {
              isDisabled: isDisabledBtnDelete,
              isHidden: !isVisibleBtnDelete,
              // 確認ダイアログボックスを作成する。
              onClick: onOpen,
              order: 4,
              text: TEXT_T034,
              type: "danger",
            },
            {
              isHidden: !isVisibleBtnRegister,
              onClick: goToFilesUpload,
              order: 1,
              text: TEXT_T505,
              type: "primary",
            },
          ]}
        />
      )}
      <ButtonGroup
        buttonItems={[
          {
            isDisabled: isDisabledBtnDownload,
            isHidden: !isVisibleBtnReference,
            onClick: handleDownload,
            order: 2,
            text: TEXT_T510,
            type: "primary",
          },
        ]}
        isMargin={!isVisibleBtnDelete}
      />
    </Fragment>
  ) : (
    <ButtonGroup
      buttonItems={[
        {
          isDisabled: isDisabledBtnDelete,
          isHidden: !isVisibleBtnDelete,
          // 確認ダイアログボックスを作成する。
          onClick: onOpen,
          order: 4,
          text: TEXT_T034,
          type: "danger",
        },
        {
          isDisabled: isDisabledBtnDownload,
          isHidden: !isVisibleBtnReference,
          onClick: handleDownload,
          order: 2,
          text: TEXT_T510,
          type: "primary",
        },
        {
          isHidden: !isVisibleBtnRegister,
          onClick: goToFilesUpload,
          order: 1,
          text: TEXT_T505,
          type: "primary",
        },
      ]}
    />
  )
}
