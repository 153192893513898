import styled from "styled-components"
import { Colors } from "../../../../../../constant/Styled/Colors"

const { DANGER } = Colors

const StyledOverRecord = styled.div`
  color: ${DANGER};
  @media (min-width: 680px) {
    display: inline;
    margin-right: 10px;
  }
`

export const Styled = {
  StyledOverRecord,
}
