/* eslint-disable complexity */
import { Breadcrumbs } from "../../../../../../../../constant/Breadcrumbs"
import { Routers } from "../../../../../../../../constant/Routers"

const { SCR210, SCR210_TRAVELERS } = Routers
const {
  BRCR_SCR214_FROM_SCR210_TRAVELERS,
  BRCR_SCR214_FROM_SCR210,
  BRCR_SCR214_FROM_SCR211_TRAVELERS,
  BRCR_SCR214_FROM_SCR211,
  BRCR_SCR214,
} = Breadcrumbs

export const getBreadcrumbItems = (
  isFromSCR210?: boolean,
  isFromSCR211?: boolean,
  schedulesURL?: string,
) => {
  if (isFromSCR210) {
    if (!isFromSCR211) {
      if (schedulesURL === SCR210) return BRCR_SCR214_FROM_SCR210

      if (schedulesURL === SCR210_TRAVELERS)
        return BRCR_SCR214_FROM_SCR210_TRAVELERS

      return BRCR_SCR214
    }

    if (isFromSCR211) {
      if (schedulesURL === SCR210) return BRCR_SCR214_FROM_SCR211

      if (schedulesURL === SCR210_TRAVELERS)
        return BRCR_SCR214_FROM_SCR211_TRAVELERS

      return BRCR_SCR214
    }

    return BRCR_SCR214
  }

  return BRCR_SCR214
}
