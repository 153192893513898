/* eslint-disable max-lines */
import { useEffect } from "react"
import { Routers } from "../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { openNotification } from "../../../../../../../utils/openNotification"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { replaceString } from "../../../../../../../utils/replaceString"
import { translateMessage } from "../../../../../../../utils/translateMessage"
import { safetyApi } from "../../../../apis"
import {
  SafetiesDataType,
  SafetiesSendResType,
  UseSafetiesType,
} from "../../../../models/safety"
import { useVariables } from "../useVariables"
import { getAddSafetyParam } from "./getAddSafetyParam"
import { getSafetiesSelectedValue } from "../../../../../attentions/containers/attention/modules/useApi/getSafetiesSelectedValue"
import { attentionApi } from "../../../../../attentions/apis"
import { AttentionDataTableType } from "../../../../../attentions/components/attention/Form/models"

const { SCR412, SCR415, SCR973 } = Routers

export const useSafety = (): UseSafetiesType<SafetiesDataType> => {
  const {
    columns,
    form,
    goBack,
    goToSafetiesClone,
    goToSafetiesEdit,
    goToSchedulesList,
    gridItems,
    isDisabledBtnSelect,
    isDisabledSubmit,
    isHiddenBackBtn,
    isHiddenCopyBtn,
    isHiddenScheduleListBtn,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenForm,
    isSafetiesSendAll,
    onAddOrRemoveSafeties,
    onCancel,
    onCancelForm,
    onFinish,
    onOpen,
    onOpenForm,
    push,
    rowSelectionAttention,
    safetiesColumns,
    safetiesList,
    safetiesSelected,
    selectedKeys,
    setIsLoading,
    setIsLoadingLayout,
    setSafetiesSelected,
    setSelectedKeys,
    setTravelerInfoList,
    state,
    travelerInfoList,
  } = useVariables()

  const onOk = () => {
    if (state?.transmissionInfo) {
      const { safeties } = getSafetiesSelectedValue(safetiesList, selectedKeys)
      const addSafetyParams = getAddSafetyParam(
        state?.transmissionInfo,
        travelerInfoList,
        safeties,
      )
      setIsLoading(true)
      // APIを呼び出し、APIからの応答結果を「statusAddSafety」変数に設定する。
      safetyApi
        .addSafety(addSafetyParams)
        .then((res: SafetiesSendResType) => {
          if (res) {
            const { safetyId, travelScheduleIdList } = res
            const _travelerInfoList = travelerInfoList.filter((item) =>
              travelScheduleIdList.includes(item.travelScheduleId),
            )
            // 「SCR415_安否確認の実施後画面」に遷移する。
            push({
              pathname: replaceString(SCR415, safetyId),
              state: {
                ...state,
                safetiesSelected,
                safetyId,
                sourceURL: SCR412,
                travelerInfoList: _travelerInfoList,
              },
            })
          }
        })
        .catch((error: AxiosErrorType) => {
          const data = error?.debug?.response?.data
          if (data?.message === "E0098") {
            setIsLoading(false)
            openNotification(translateMessage(`MESSAGE.${data.message}`))
          } else {
            // 「SCR973_エラー情報の表示画面」に遷移する。
            const stateError = pushStateError(error)
            push({ pathname: SCR973, state: stateError })
          }
        })
    }
  }

  const getSafeties = () => {
    if (state?.selectedKeys?.length) {
      attentionApi
        .getSafeties()
        .then((res: AttentionDataTableType[]) => {
          setIsLoadingLayout(false)
          const { safetiesSelected: _safetiesSelected } =
            getSafetiesSelectedValue(res, state.selectedKeys)
          setSelectedKeys(state.selectedKeys)
          setSafetiesSelected(_safetiesSelected)
        })
        .catch((error: AxiosErrorType) => {
          // 「SCR973_エラー情報の表示画面」に遷移する。
          const stateError = pushStateError(error)
          push({ pathname: SCR973, state: stateError })
        })
    } else {
      setTimeout(() => {
        setIsLoadingLayout(false)
      }, 500)
    }
  }

  useEffect(() => {
    // ユーザの画面アクセス許可チェックを処理する。
    if (!isSafetiesSendAll) {
      //「SCR973_エラー情報の表示画面」に遷移する。
      const { pathname, state } = backToLogin()
      push({ pathname, state })
    } else {
      if (state?.travelerInfoList) {
        const { travelerInfoList } = state
        setTravelerInfoList(travelerInfoList)
      }
      getSafeties()
    }
  }, [])

  return {
    columns,
    form,
    goBack,
    goToSafetiesClone,
    goToSafetiesEdit,
    goToSchedulesList,
    gridItems,
    isDisabledBtnSelect,
    isDisabledSubmit,
    isHiddenBackBtn,
    isHiddenCopyBtn,
    isHiddenScheduleListBtn,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenForm,
    onAddOrRemoveSafeties,
    onCancel,
    onCancelForm,
    onFinish,
    onOk,
    onOpen,
    onOpenForm,
    rowSelectionAttention,
    safetiesColumns,
    safetiesList,
    safetiesSelected,
    travelerInfoList,
  }
}
