import "./style/index.scss"
import { FC } from "react"
import { SubProps } from "./models"
import { getStyle } from "../../../../../utils/getStyle"

export const Sub: FC<SubProps> = (props) => {
  const { className, color, title } = props
  const styleTitle = getStyle(className, `sub ${className}`, "sub")

  return (
    <div className={styleTitle} style={{ color: color }}>
      {title}
    </div>
  )
}
