import { FC, Fragment } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { ModalConfirm } from "../../../../../../_webui/layout/components/Modal/Confirm"
import { SchedulesBatchImportModalConfirmProps } from "../../../models/batchImport"

const { MESSAGE_C0019, MESSAGE_C0023 } = Translate

export const SchedulesBatchImportModalConfirm: FC<
  SchedulesBatchImportModalConfirmProps
> = (props) => {
  const {
    bulkAdd,
    bulkDeleteDataConfirm,
    isOpen,
    isOpenUploaded,
    onCancel,
    onCancelUploaded,
  } = props

  return (
    <Fragment>
      <ModalConfirm isOpen={isOpen} onCancel={onCancel} onOk={bulkAdd}>
        {MESSAGE_C0023}
      </ModalConfirm>
      <ModalConfirm
        isOpen={isOpenUploaded}
        onCancel={onCancelUploaded}
        onOk={bulkDeleteDataConfirm}
      >
        {MESSAGE_C0019}
      </ModalConfirm>
    </Fragment>
  )
}
