import { formatDateSaveDB } from "../../../../../../../../utils/getDate"
import { getTextByKey } from "../../../../../../../../utils/getTextByKey"
import { getValueCombobox } from "../../../../../../../../utils/getValueCombobox"
import { GetScheduleInfoAddProps } from "../../../../../models/add"
import { ScheduleInfoAddType } from "../../../../../models/add/DataAdd"

export const getScheduleInfoAdd = (
  data: GetScheduleInfoAddProps,
): ScheduleInfoAddType => {
  const {
    dataSubmit,
    departments,
    isOtherStaffNumber,
    purposeTravelList,
    travelerId,
  } = data
  const bumonName = getTextByKey(dataSubmit.bumonCd, departments)
  const travelPurposeName = getTextByKey(
    dataSubmit.travelPurposeId,
    purposeTravelList,
  )

  const getValue = (value: string) => (isOtherStaffNumber ? "" : value)

  return {
    bumonCd: getValueCombobox(dataSubmit.bumonCd, bumonName),
    codeConductConfirmation: dataSubmit.codeConductConfirmation,
    differentInforCheck: !!dataSubmit.differentInforCheck,
    dispatchEndDate: formatDateSaveDB(dataSubmit.dispatchEndDate, true),
    dispatchStartDate: formatDateSaveDB(dataSubmit.dispatchStartDate),
    dispatchTypeDetailId: "",
    hakenIdentityNumber: "",
    insuranceCompanyName: dataSubmit.insuranceCompanyName,
    insuranceCompanyPhoneNumber: dataSubmit.insuranceCompanyPhoneNumber,
    insuranceSonsignor: dataSubmit.insuranceSonsignor,
    insuranceTicketNumber: dataSubmit.insuranceTicketNumber,
    locationPersonName: dataSubmit.locationPersonName,
    mainPersonName: dataSubmit.mainPersonName,
    managerRemarks1: dataSubmit.managerRemarks1,
    managerRemarks2: dataSubmit.managerRemarks2,
    managerRemarks3: dataSubmit.managerRemarks3,
    managerRemarks4: dataSubmit.managerRemarks4,
    managerRemarks5: dataSubmit.managerRemarks5,
    procurementControlNumber: dataSubmit.procurementControlNumber,
    projectNameEn: dataSubmit.projectNameEn,
    projectNameJp: dataSubmit.projectNameJp,
    travelPurposeId: getValueCombobox(
      dataSubmit.travelPurposeId,
      travelPurposeName,
    ),
    travelerBumonCd: getValue(dataSubmit.travelerBumonCd),
    travelerDispatchTypeDetailId: getValue(
      dataSubmit.travelerDispatchTypeDetailId,
    ),
    travelerId,
    volunteerIdentityNumber: "",
    workPlaceCountryCode: "",
    zaigaiIdentityNumber: "",
  }
}
