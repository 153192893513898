/* eslint-disable complexity */
import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const {
    isCommonTravelerRefer,
    isRoleAdmin,
    isRoleSubAdmin,
    isRoleUser,
    isScheduleDelete,
    isScheduleSearch,
  } = useAuthority()

  const isNotAccessScreen = !isCommonTravelerRefer && !isScheduleDelete
  // 画面上のボタン表示条件
  const isVisibleBtnSearch =
    (isRoleAdmin || isRoleSubAdmin || isRoleUser) &&
    (isCommonTravelerRefer || isScheduleSearch)
  return {
    isNotAccessScreen,
    isVisibleBtnSearch,
  }
}
