import { FC, Fragment } from "react"
import { Divider } from "../../../../../../../../_webui/layout/components/Divider"
import { Space } from "../../../../../../../../_webui/layout/components/Space"
import { Table } from "../../../../../../../../_webui/layout/components/Table"
import { TravelersEditPersonalInfoTableProps } from "../../../../../models/edit"
import { TravelersEditButtonGroup } from "../../../ButtonGroup"

export const PersonalInfoTable: FC<TravelersEditPersonalInfoTableProps> = (
  props,
) => {
  const {
    accompanies,
    addItems,
    columns,
    isDisabledBtnDelete,
    removeItems,
    rowSelection,
  } = props

  return (
    <Fragment>
      <Divider />

      <Space isSpaceTop>
        <Table
          columns={columns}
          dataSource={accompanies}
          isBackgroundFirstChild
          id="table-travelers-edit-accompanies"
          isGroup
          isSeparator
          pagination={false}
          rowKey="familyId"
          rowSelection={rowSelection}
        />

        <TravelersEditButtonGroup
          addItems={addItems}
          isDisabledBtnDelete={isDisabledBtnDelete}
          removeItems={removeItems}
        />
      </Space>
    </Fragment>
  )
}
