/* eslint-disable max-statements */
import { Form } from "antd"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import { useModal } from "../../../../../../../../utils/hook/useModal"
import { useRowSelection } from "../useRowSelection"
import { Moment } from "moment"
import { FilesListDataSourceType } from "../../../../../models/files/list"
import { getUserInformation } from "../../../../../../../../utils/getUserInformation"
import { Variables } from "../../../../../../../../constant/Variables"
import { useVariablesAuthority } from "../useVariablesAuthority"

const { SECURITY_MANAGEMENT_DEPARTMENT } = Variables

export const useVariablesApi = () => {
  const [form] = Form.useForm()
  const { push } = useHistory()
  const userInformation = getUserInformation()
  const {
    isCommonUploadRegister,
    isRoleAdmin,
    isRoleSubAdmin,
    isVisibleBtnDelete,
    isVisibleBtnReference,
    isVisibleBtnRegister,
  } = useVariablesAuthority()
  const [fileInfoList, setFileInfoList] = useState<FilesListDataSourceType[]>(
    [],
  )
  const [currentUsage, setCurrentUsage] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [isDisabledCountries, setIsDisabledCountries] = useState(true)
  const [isResetTable, setIsResetTable] = useState(false)
  const [initDateTime, setInitDateTime] = useState<Moment>()

  const {
    isDisabledBtnDelete,
    isDisabledBtnDownload,
    isDisabledBtnPreview,
    onChangePagination,
    onChangeTable,
    perItem,
    rowSelection,
    selectedRowKeys,
    setIsDisabledBtnDownload,
    setIsDisabledBtnPreview,
    setSelectedRowKeys,
  } = useRowSelection(fileInfoList)

  const { isOpen, onCancel, onOpen } = useModal()
  const bumonCd = userInformation?.bumonCd
  const typeInfoSubAdmin =
    isRoleSubAdmin && bumonCd === SECURITY_MANAGEMENT_DEPARTMENT
  const isCountry = isRoleAdmin || typeInfoSubAdmin

  return {
    currentUsage,
    fileInfoList,
    form,
    initDateTime,
    isCommonUploadRegister,
    isCountry,
    isDisabledBtnDelete,
    isDisabledBtnDownload,
    isDisabledBtnPreview,
    isDisabledCountries,
    isLoading,
    isLoadingLayout,
    isOpen,
    isResetTable,
    isRoleAdmin,
    isVisibleBtnDelete,
    isVisibleBtnReference,
    isVisibleBtnRegister,
    onCancel,
    onChangePagination,
    onChangeTable,
    onOpen,
    perItem,
    push,
    rowSelection,
    selectedRowKeys,
    setCurrentUsage,
    setFileInfoList,
    setInitDateTime,
    setIsDisabledBtnDownload,
    setIsDisabledBtnPreview,
    setIsDisabledCountries,
    setIsLoading,
    setIsLoadingLayout,
    setIsResetTable,
    setSelectedRowKeys,
    typeInfoSubAdmin,
  }
}
