/* eslint-disable complexity */
import { FC } from "react"
import { MenusType } from "../../models"
import { Styled } from "./styled"
import { Routers } from "../../../../../../constant/Routers"
import { useMenuItem } from "./modules/useMenuItem"
import { replaceString } from "../../../../../../utils/replaceString"
import { concat } from "../../../../../../utils/concat"
import { Image } from "../../../Image"
import { Translate } from "../../../../../../constant/Translate"

const {
  SCR114_USER_SEARCH,
  SCR115,
  SCR212,
  SCR917,
  SCR920,
  SCR991_FAQ,
  SCR992,
} = Routers
const {
  StyledArrowIcon,
  StyledImage,
  StyledNavContent,
  StyledNavItem,
  StyledNavLink,
  StyledNavLinkItem,
  StyledNavTitle,
} = Styled
const { TEXT_T277 } = Translate

export const MenuIcon: FC<MenusType> = (props) => {
  const { iconUrl, menuName, menuUrl, handleNavLinkClick, subMenus } = props
  const { travelerId } = useMenuItem()
  const ariaLabel = TEXT_T277.replaceAll("{0}", concat(menuName))

  return subMenus?.length ? (
    <StyledNavItem className={iconUrl ? "" : "sub-menu"}>
      {iconUrl ? (
        <StyledNavTitle aria-haspopup={true} aria-label={ariaLabel}>
          <Image alt="" src={iconUrl} />
          <span className="hidden-label">{menuName}</span>
        </StyledNavTitle>
      ) : (
        <StyledNavTitle aria-haspopup={true} aria-label={ariaLabel}>
          <span>{menuName}</span>
          {!!subMenus.length && <StyledArrowIcon className="nav-arrow-icon" />}
        </StyledNavTitle>
      )}
      <StyledNavContent>
        {subMenus.map((child, index) => (
          <MenuIcon
            handleNavLinkClick={handleNavLinkClick}
            key={index}
            {...child}
          />
        ))}
      </StyledNavContent>
    </StyledNavItem>
  ) : menuUrl ? (
    <StyledNavItem>
      {[SCR114_USER_SEARCH, SCR920, SCR991_FAQ, SCR992].includes(menuUrl) ? (
        <StyledNavLink className="home" exact title={menuName} to={menuUrl}>
          <Image alt="" src={iconUrl} />
          <span className="hidden-label">{menuName}</span>
        </StyledNavLink>
      ) : (
        <StyledNavLinkItem
          aria-label={menuName}
          exact
          onClick={handleNavLinkClick}
          target={menuUrl.startsWith("http") ? "_blank" : ""}
          to={{
            pathname:
              menuUrl === SCR115 || menuUrl === SCR212
                ? replaceString(menuUrl, concat(travelerId))
                : menuUrl,
            state: menuUrl === SCR917 ? { isChangePassword: true } : "",
          }}
          title={menuUrl === SCR920 ? menuName : ""}
        >
          {menuName}
          {iconUrl && <StyledImage alt="" src={iconUrl} />}
        </StyledNavLinkItem>
      )}
    </StyledNavItem>
  ) : null
}
