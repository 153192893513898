import { Translate } from "../../constant/Translate"
import { Constants } from "./constants"

const { TEXT_T100 } = Translate
const { COOPERATION_AND_ASSOCIATION } = Constants

export const getInsuranceCompanyName = (insuranceCompanyName: string) =>
  COOPERATION_AND_ASSOCIATION.find(
    (item) => item.toLowerCase() === insuranceCompanyName.toLowerCase(),
  )
    ? TEXT_T100
    : insuranceCompanyName
