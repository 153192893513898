import styled, { css } from "styled-components"
import { Table } from "../../../../../../../_webui/layout/components/Table"
import { Constant } from "../../../../../../../constant"

const { CHECK_SELECTED_LANG } = Constant

const StyledTable = styled(Table)`
  ${CHECK_SELECTED_LANG
    ? ""
    : css`
        .ant-table-thead tr:nth-child(1) .ant-table-cell::before {
          top: 24px;
        }
      `}
`

export const Styled = {
  StyledTable,
}
