import { useState } from "react"
import { useHistory } from "react-router-dom"
import {
  NotificationItemType,
  NotificationResType,
} from "../../../../../../../_webui/layout/components/Notification/models"
import { GeneralCode } from "../../../../../../../constant/GeneralCode"
import { getGeneralMasters } from "../../../../../../../utils/apis/getGeneralMasters"
import { useAuthority } from "../../../../../../../utils/hook/useAuthority"
import { useChangeLanguage } from "../../../../../../../_webui/layout/components/ChangeLanguage/modules/useChangeLanguage"
import { Translate } from "../../../../../../../constant/Translate"

const { NTDP } = GeneralCode
const { TEXT_T281 } = Translate

export const useVariables = () => {
  const { push } = useHistory()
  const { setLanguageToEnglish, setLanguageToJapanese } = useChangeLanguage()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const [timezone, setTimezone] = useState(TEXT_T281)
  const [timezoneValue, setTimezoneValue] = useState("")
  const [notifications, setNotifications] = useState<NotificationResType[]>([])
  const [notificationItems, setNotificationItems] = useState<
    NotificationItemType[]
  >([])

  // APIを呼び出し、APIからの応答結果を「お知らせカテゴリ応答」変数に設定する。
  const { generalMasters } = getGeneralMasters(NTDP)
  const { isCommonRefer } = useAuthority()

  return {
    generalMasters,
    isCommonRefer,
    isLoading,
    isLoadingLayout,
    isOpen,
    notificationItems,
    notifications,
    push,
    setIsLoading,
    setIsLoadingLayout,
    setIsOpen,
    setLanguageToEnglish,
    setLanguageToJapanese,
    setNotificationItems,
    setNotifications,
    setTimezone,
    setTimezoneValue,
    timezone,
    timezoneValue,
  }
}
