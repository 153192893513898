import { useEffect } from "react"
import { Routers } from "../../../../../../../constant/Routers"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { UseTravelersBatchUpdateType } from "../../../../models/batchUpdate"
import { TravelersBatchUpdateDataType } from "../../../../models/batchUpdate/data"
import { useVariables } from "../useVariables"
import { getUserInformation } from "../../../../../../../utils/getUserInformation"

const { SCR920 } = Routers

export const useTravelersBatchUpdate =
  (): UseTravelersBatchUpdateType<TravelersBatchUpdateDataType> => {
    const {
      beforeUpload,
      bulkCheck,
      bulkDeleteData,
      bulkFileUpdate,
      bulkUpdate,
      columnErrors,
      columns,
      countRecord,
      dataTemps,
      errorList,
      fileNameList,
      form,
      isDisabledSubmit,
      isLoading,
      isLoadingLayout,
      isNotAccessScreen,
      isOpen,
      isOpenUpdate,
      isResetTable,
      isRoleSubAdmin,
      isVisibleBtnCancel,
      isVisibleBtnUpdate,
      onCancel,
      onCancelUpdate,
      onChange,
      onChangeUploadFile,
      onOpen,
      onOpenUpdate,
      perItem,
      push,
    } = useVariables()

    const bulkDeleteDataConfirm = () => {
      bulkDeleteData()
      push(SCR920)
    }

    const onOpenUpdateOrGoToHome = () => {
      if (dataTemps.length) {
        onOpenUpdate()
      } else {
        push(SCR920)
      }
    }

    useEffect(() => {
      // ユーザの画面アクセス許可チェックを処理する。
      if (isNotAccessScreen) {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const { pathname, state } = backToLogin()
        push({ pathname, state })
      } else {
        bulkCheck()
        if (isRoleSubAdmin) {
          const userInformation = getUserInformation()
          form.setFieldValue("registerRequestUserId", userInformation?.userId)
        }
      }
    }, [])

    return {
      beforeUpload,
      bulkDeleteDataConfirm,
      bulkFileUpdate,
      bulkUpdate,
      columnErrors,
      columns,
      countRecord,
      dataTemps,
      errorList,
      fileNameList,
      form,
      isDisabledSubmit,
      isLoading,
      isLoadingLayout,
      isOpen,
      isOpenUpdate,
      isResetTable,
      isVisibleBtnCancel,
      isVisibleBtnUpdate,
      onCancel,
      onCancelUpdate,
      onChange,
      onChangeUploadFile,
      onOpen,
      onOpenUpdateOrGoToHome,
      perItem,
    }
  }
