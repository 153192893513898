import { FC } from "react"
import { TotalRecordsProps } from "./models"
import { Translate } from "../../../../../constant/Translate"
import { Title } from "../Title"
import { replaceStringParam } from "../../../../../utils/replaceStringParam"

const { TEXT_T518 } = Translate

export const TotalRecords: FC<TotalRecordsProps> = (props) => {
  const { perItem = "", titleLeft, titleRight, total = "", ...res } = props
  const _perItem = replaceStringParam(TEXT_T518, perItem, total)

  return (
    <Title
      titleLeft={titleLeft}
      titleRight={
        <span id="title-right" tabIndex={0}>
          {titleRight}
          {_perItem}
        </span>
      }
      {...res}
    />
  )
}
