import { FC, Fragment } from "react"
import { Button } from "../../Button"
import { Table } from ".."
import { TableErrorProps } from "./models"
import { handleExportCSV } from "./utils/handleExportCSV"
import { Center } from "../../Center/index"
import { Translate } from "../../../../../constant/Translate"
import { replaceStringParam } from "../../../../../utils/replaceStringParam"
import { Title } from "../../Typography/Title"
import { useTable } from "../../../../../utils/hook/useTable"
import { PreLine } from "../../Typography/PreLine"
import { Styled } from "./styled"

const { StyledTotalRecords } = Styled
const { MESSAGE_I0010, TEXT_T595 } = Translate

export const TableError: FC<TableErrorProps> = (props) => {
  const {
    columns,
    countRecord,
    errorList,
    isLoading,
    isResetTable,
    rowKey,
    titleLeft,
    total,
  } = props
  const dataSource = errorList?.map((item, index) => ({
    ...item,
    id: index + 1,
  }))
  const { perItem, onChange } = useTable(errorList?.length)

  return (
    <Fragment>
      {countRecord && (
        <Title
          titleLeft={
            <PreLine>
              {replaceStringParam(
                MESSAGE_I0010,
                String(countRecord.total),
                countRecord.success,
                countRecord.fail,
              )}
            </PreLine>
          }
        />
      )}
      <StyledTotalRecords
        perItem={perItem}
        titleLeft={titleLeft}
        total={total}
      />
      <Table
        columns={columns}
        dataSource={dataSource}
        isLoading={isLoading}
        isResetTable={isResetTable}
        onChange={onChange}
        rowKey={rowKey}
      />
      <Center isMarginTop>
        <Button
          htmlType="button"
          isFullWidth
          onClick={() => handleExportCSV(dataSource)}
          text={TEXT_T595}
          type="primary"
        />
      </Center>
    </Fragment>
  )
}
