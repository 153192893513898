import styled, { css } from "styled-components"
import { Regex } from "../../../../../../constant/Regex"
import { ButtonProps } from "../../models"
import { StyledButtonGroupItemProps } from "../models"

const { REGEX_IS_DESKTOP } = Regex

const StyledButtonGroup = styled.div<StyledButtonGroupItemProps>`
  ${(props) =>
    REGEX_IS_DESKTOP &&
    css`
      height: 47px;
      position: relative;
      justify-content: ${props.position};
      ${props.isMargin &&
      css`
        margin-top: 40px;
        margin-bottom: 15px;
      `}
    `}
`

const StyledButtonGroupItem = styled.div<ButtonProps>`
  ${(props) =>
    REGEX_IS_DESKTOP
      ? css`
          margin-right: 30px;
          &:last-child {
            margin-right: 0;
          }
          ${props.title &&
          css`
            position: relative;
            .ant {
              &-btn {
                margin-right: 10px;
              }
              &-tooltip-btn {
                position: absolute;
                right: -20px;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          `}
        `
      : css`
          margin-top: 20px;
          width: 100%;
          .ant-btn {
            width: 100%;
          }
        `}
`

export const Styled = {
  StyledButtonGroup,
  StyledButtonGroupItem,
}
