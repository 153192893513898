import { exportFileName } from "../exportFileName"

export const exportCsv = (data: string, fileName: string) => {
  const universalBOM = "\uFEFF"
  const a = document.createElement("a")
  a.setAttribute(
    "href",
    "data:text/csv; charset=utf-8," + encodeURIComponent(universalBOM + data),
  )
  a.setAttribute("download", exportFileName(fileName))
  document.body.appendChild(a)
  a.click()
}
