import { FC, Fragment } from "react"
import { Translate } from "../../../../../../../../constant/Translate"
import { ItemInput } from "../../../../../../../../_webui/layout/components/Form/Item/Input"

const { TEXT_T075, TEXT_T076, TEXT_T077 } = Translate

export const CollapseOther: FC = () => {
  return (
    <Fragment>
      <ItemInput label={TEXT_T075} maxLength={20} name="othersDispatchNumber" />
      <ItemInput label={TEXT_T076} maxLength={20} name="othersStaffNumber" />
      <ItemInput label={TEXT_T077} maxLength={20} name="othersMemberNumber" />
    </Fragment>
  )
}
