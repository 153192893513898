import {
  TravelersEditSuccessCollapseType,
  TravelersEditSuccessResType,
} from "../../../../../models/editSuccess"
import { convertDataAccompanies } from "./accompanies"
import { convertDataBase } from "./base"
import { convertDataDetail } from "./detail"

export const convertData = (
  data: TravelersEditSuccessResType,
  isRoleAdmin: boolean,
  isRoleSubAdmin: boolean,
  userIdForChangeRequest: string,
): TravelersEditSuccessCollapseType => {
  const { base, detail, accompanies } = data
  const dataAccompanies = convertDataAccompanies(accompanies)
  const dataBase = convertDataBase(base)
  const dataDetail = convertDataDetail(
    detail,
    base.dispatchTypeDetailId,
    isRoleAdmin,
    isRoleSubAdmin,
    userIdForChangeRequest,
  )
  return {
    ...dataAccompanies,
    ...dataBase,
    ...dataDetail,
  }
}
