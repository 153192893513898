import { FC, Fragment } from "react"
import { GeneralCode } from "../../../../../../constant/GeneralCode"
import { Translate } from "../../../../../../constant/Translate"
import { SuccessAlertHeadingProps } from "../../../models/success"
import { Styled } from "./styled"
import { getValueByLanguage } from "../../../../../../utils/getValueByLanguage"

const { TEXT_T328, TEXT_T330 } = Translate
const { CTGR_OTHER } = GeneralCode
const { StyledAttentionsCategory, StyledAttentionsValue } = Styled

export const SuccessAlertHeading: FC<SuccessAlertHeadingProps> = (props) => {
  const { transmissionInfo } = props
  const separator = getValueByLanguage("", " ")

  return (
    <Fragment>
      <StyledAttentionsCategory>
        {TEXT_T328}
        {separator}
        <b>{transmissionInfo?.categoryName}</b>
        {transmissionInfo?.categoryId === CTGR_OTHER && (
          <StyledAttentionsValue>
            <b>{transmissionInfo?.categoryValue}</b>
          </StyledAttentionsValue>
        )}
      </StyledAttentionsCategory>
      <span>
        {TEXT_T330}
        {separator}
        <b>{transmissionInfo?.severityName}</b>
      </span>
    </Fragment>
  )
}
