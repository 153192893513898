/* eslint-disable max-lines */
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import {
  AttentionsListType,
  AttentionsListSearchConditionType,
  AttentionsListSearchFormType,
} from "../../../../models/list"
import { Routers } from "../../../../../../../constant/Routers"
import { attentionApi } from "../../../../apis"
import { getDateMoment } from "../../../../../../../utils/getDate"
import { convertDataSearch } from "./convertDataSearch"
import { useVariablesForm } from "../useVariablesForm"
import { convertData } from "./convertData"

const { SCR973 } = Routers

export const useForm = (isRoleSubAdmin: boolean) => {
  const {
    attentionInfoList,
    form,
    isFromHistoryMenu,
    isLoading,
    isLoadingLayout,
    isOpen,
    onBlur,
    onCancel,
    onKeyDown,
    onOpen,
    push,
    searchCondition,
    setAttentionInfoList,
    setIsLoading,
    setIsLoadingLayout,
    setSearchCondition,
  } = useVariablesForm()

  const onFinish = (values: AttentionsListSearchFormType) => {
    onCancel()
    setIsLoading(true)
    const data = convertDataSearch(values)
    setSearchCondition(data)
    getAttentions({
      ...data,
      isMenuSubAdmin: isRoleSubAdmin,
      statusId: isFromHistoryMenu ? data.statusId : "0",
    })
    setTimeout(() => {
      document.getElementById("title-right")?.focus()
    }, 600)
  }

  const onOpenPopup = () => {
    // 「絞り込み条件」モーダルを画面に表示する。
    if (searchCondition) {
      const { implementationDateTime } = searchCondition
      form.setFieldsValue({
        ...searchCondition,
        implementationDateTime:
          implementationDateTime && getDateMoment(implementationDateTime),
      })
    } else {
      form.resetFields()
    }
    onOpen()
  }

  const onCancelPopup = () => {
    // 「絞り込み条件」モーダルを閉じる。
    onCancel()
    form.setFieldsValue({
      ...searchCondition,
      implementationDateTime:
        searchCondition?.implementationDateTime &&
        getDateMoment(searchCondition.implementationDateTime),
    })
  }

  const getAttentions = (
    searchCondition?: Pick<AttentionsListSearchConditionType, never>,
  ) => {
    // APIを呼び出し、検索条件に従って注意喚起情報を取得し、APIからの応答結果を「注意喚起情報一覧」変数に設定する。
    attentionApi
      .getAttentions({
        statusId: isFromHistoryMenu ? "" : "0",
        ...searchCondition,
      })
      .then((attentionInfoJson: AttentionsListType[]) => {
        // 「attentionInfoList」の値を設定する。
        const data = convertData(attentionInfoJson)
        setAttentionInfoList(data)
        setIsLoading(false)
        setIsLoadingLayout(false)
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const stateError = pushStateError(error)
        push({ pathname: SCR973, state: stateError })
      })
  }

  return {
    attentionInfoList,
    form,
    getAttentions,
    isFromHistoryMenu,
    isLoading,
    isLoadingLayout,
    isOpen,
    onBlur,
    onCancelPopup,
    onFinish,
    onKeyDown,
    onOpenPopup,
    searchCondition,
    setSearchCondition,
  }
}
