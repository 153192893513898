import { getFullName } from "../../../../../../../../utils/getFullName"
import {
  TravelerInfoResponseType,
  TravelerInfoType,
} from "../../../../../models/addSuccess"

export const convertDataTravelerInfo = (
  data: TravelerInfoResponseType,
): TravelerInfoType => ({
  bumonTravelerName: data.bumonName,
  dispatchTypeDetailName: data.dispatchTypeDetailName,
  fullNameKanji: getFullName(data.kanjiSurname, data.kanjiName, true),
  fullNameRomaji: getFullName(data.romajiSurname, data.romajiName),
  kanjiPassportFullName: getFullName(
    data.kanjiPassportSurname,
    data.kanjiPassportName,
    true,
  ),
  romajiPassportFullName: getFullName(
    data.romajiPassportSurname,
    data.romajiPassportName,
  ),
})
