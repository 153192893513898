/* eslint-disable max-lines */
import styled, { css } from "styled-components"
import { Constant } from "../../../../../../../constant"
import { Regex } from "../../../../../../../constant/Regex"
import { Colors } from "../../../../../../../constant/Styled/Colors"
import { Fonts } from "../../../../../../../constant/Styled/Fonts"

const { BORDER, TABLE_HEADER, TABLE_HEADER_BORDER } = Colors
const { FS_NORMAL } = Fonts
const { REGEX_IS_DESKTOP } = Regex
const { CHECK_SELECTED_LANG } = Constant

const StyledRolesSetting = styled.div`
  border: 1px solid ${BORDER};
  font-size: ${FS_NORMAL};
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  .ant {
    &-form-item {
      margin: 0;
    }
  }
  ${REGEX_IS_DESKTOP
    ? css`
        .ant {
          &-checkbox {
            &-group {
              display: flex;
              &-item {
                justify-content: center;
                margin-left: 0;
                margin-right: 0;
                max-width: 100px;
                min-width: 100px;
              }
            }
            + span {
              padding-left: 0;
              padding-right: 0;
            }
          }
          &-form-item {
            height: 50px;
            width: initial;
          }
        }
      `
    : css`
        border: 1px solid ${BORDER};
        display: flex;
        .ant {
          &-checkbox-group {
            display: block;
          }
        }
      `}
`

const StyledItem = styled.div`
  align-items: center;
  background-color: ${TABLE_HEADER};
  border-bottom: 1px solid ${BORDER};
  display: flex;
  height: 50px;
  padding-left: 10px;
  padding-right: 10px;
  ${REGEX_IS_DESKTOP
    ? css`
        max-width: 150px;
        min-width: 150px;
      `
    : css`
        justify-content: center;
        padding-left: 4px;
        padding-right: 4px;
        position: relative;
        &::before {
          background-color: ${TABLE_HEADER_BORDER};
          content: "";
          height: 20px;
          left: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
        }
        &:last-child {
          border-bottom: none;
        }
      `}
`

const StyledItemCheckbox = styled.div`
  align-items: center;
  border-bottom: 1px solid ${BORDER};
  height: 50px;
  justify-content: center;
  ${REGEX_IS_DESKTOP
    ? css`
        display: inline-flex;
        max-width: 150px;
        min-width: 150px;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
      `
    : css`
        display: flex;
        &:last-child {
          border-bottom: none;
        }
      `}
`

const StyledItemCheckboxLast = styled(StyledItemCheckbox)`
  ${REGEX_IS_DESKTOP &&
  css`
    max-width: calc(100% - 1350px);
    min-width: calc(100% - 1350px);
    padding: 0;
  `}
`

const StyledItemCheckboxHeader = styled(StyledItemCheckbox)`
  ${REGEX_IS_DESKTOP
    ? css`
        position: relative;
        background-color: ${TABLE_HEADER};
        border-bottom: 1px solid ${BORDER};
        &::before {
          background-color: ${TABLE_HEADER_BORDER};
          content: "";
          height: 20px;
          left: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
        }
      `
    : css`
        background-color: ${TABLE_HEADER};
        justify-content: flex-start;
        padding-left: 10px;
        padding-right: 10px;
        text-align: left;
      `}
`

const StyledItemCheckboxHeaderLast = styled(StyledItemCheckboxHeader)`
  ${REGEX_IS_DESKTOP &&
  css`
    background-color: ${TABLE_HEADER};
    max-width: calc(100% - 1500px);
    min-width: calc(100% - 1500px);
    padding: 0;
    &::before {
      display: none;
    }
  `}
`

const StyledRow = styled.div`
  ${REGEX_IS_DESKTOP
    ? css`
        align-items: center;
        display: flex;
        &:first-child {
          background-color: ${TABLE_HEADER};
        }
        &:last-child {
          ${StyledItemCheckbox},
          ${StyledItem} {
            border-bottom: none;
          }
        }
      `
    : css`
        --minWidth: ${CHECK_SELECTED_LANG ? "80px" : "150px"};
        --minWidthLast: calc(100% - ${CHECK_SELECTED_LANG ? "400px" : "750px"});
        min-width: var(--minWidth);
        max-width: var(--minWidth);
        text-align: center;
        &:last-child {
          min-width: var(--minWidthLast);
          max-width: var(--minWidthLast);
        }
        &:first-child,
        &:nth-last-child(1) {
          ${StyledItem} {
            &::before {
              display: none;
            }
          }
        }
      `}
`

export const Styled = {
  StyledItem,
  StyledItemCheckbox,
  StyledItemCheckboxHeader,
  StyledItemCheckboxHeaderLast,
  StyledItemCheckboxLast,
  StyledRolesSetting,
  StyledRow,
}
