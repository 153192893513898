import moment from "moment-timezone"
import { FC, useEffect } from "react"
import { TimezoneProps } from "./models"
import { Styled } from "./styled"

const {
  StyledTimezone,
  StyledTimezoneClock,
  StyledTimezoneClockNumber,
  StyledTimezoneDot,
  StyledTimezoneFormat,
  StyledTimezoneHand,
  StyledTimezoneHandHour,
  StyledTimezoneHandMinutes,
} = Styled

export const Timezone: FC<TimezoneProps> = (props) => {
  const { timezone, timezoneValue } = props

  const clockTime = () => {
    const time = moment.tz("UTC")

    const minutes = time.minute() / 60

    const totalHour = time.hour() + +timezoneValue

    const _hour =
      totalHour < 0
        ? totalHour + 24
        : totalHour > 24
        ? totalHour - 24
        : totalHour

    const hour = (_hour + minutes) / 12

    const elmMinutes = document.getElementById("time-zone-clock-hand-minutes")
    const elmHour = document.getElementById("time-zone-clock-hand-hour")

    const _minutes = minutes + (_hour % 1)

    if (elmMinutes && elmHour) {
      elmMinutes.style.transform = `rotate(${_minutes * 360}deg)`
      elmHour.style.transform = `rotate(${hour * 360}deg)`
    }
  }

  useEffect(() => {
    clockTime()
    const intervalId = setInterval(clockTime, 1000)

    return () => clearInterval(intervalId)
  }, [timezoneValue])

  return (
    <StyledTimezone>
      <StyledTimezoneClock>
        <StyledTimezoneClockNumber></StyledTimezoneClockNumber>
        <StyledTimezoneClockNumber></StyledTimezoneClockNumber>
        <StyledTimezoneClockNumber></StyledTimezoneClockNumber>
        <StyledTimezoneClockNumber></StyledTimezoneClockNumber>
        <StyledTimezoneClockNumber></StyledTimezoneClockNumber>
        <StyledTimezoneClockNumber></StyledTimezoneClockNumber>
        <StyledTimezoneClockNumber></StyledTimezoneClockNumber>
        <StyledTimezoneClockNumber></StyledTimezoneClockNumber>
        <StyledTimezoneClockNumber></StyledTimezoneClockNumber>
        <StyledTimezoneClockNumber></StyledTimezoneClockNumber>
        <StyledTimezoneClockNumber></StyledTimezoneClockNumber>
        <StyledTimezoneClockNumber></StyledTimezoneClockNumber>
        <StyledTimezoneHand id="time-zone-clock-hand-minutes">
          <StyledTimezoneHandMinutes></StyledTimezoneHandMinutes>
        </StyledTimezoneHand>
        <StyledTimezoneHand id="time-zone-clock-hand-hour">
          <StyledTimezoneHandHour></StyledTimezoneHandHour>
        </StyledTimezoneHand>
        <StyledTimezoneDot></StyledTimezoneDot>
      </StyledTimezoneClock>
      <StyledTimezoneFormat>{timezone}</StyledTimezoneFormat>
    </StyledTimezone>
  )
}
