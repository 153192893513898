import React, { FC, Fragment } from "react"
import { Regex } from "../../../../../../../constant/Regex"
import { Translate } from "../../../../../../../constant/Translate"
import { replaceString } from "../../../../../../../utils/replaceString"
import { Grid } from "../../../../../../../_webui/layout/components/Grid"
import { GridItemType } from "../../../../../../../_webui/layout/components/Grid/models"
import { Table } from "../../../../../../../_webui/layout/components/Table"
import { Tabs } from "../../../../../../../_webui/layout/components/Tabs"
import { TravelersPersonalInfoDataProps } from "../../../../models/detail"
import { Styled } from "./styled"

const { TEXT_T083, TEXT_T042, TEXT_T043 } = Translate
const { REGEX_IS_DESKTOP } = Regex
const { StyledSpace } = Styled

export const CollapsePersonalInfo: FC<TravelersPersonalInfoDataProps> = (
  props,
) => {
  const {
    columns,
    isLoading,
    numberOfPeople,
    relationshipInfo,
    travelerDetail,
  } = props

  // 渡航者の随伴家族数に応じてタブ数を生成する。
  const tabItems = relationshipInfo.map((item, index) => {
    const itemGrid: GridItemType[] = [
      { key: TEXT_T042, text: item.familyFullNameRomaji },
      { key: TEXT_T043, text: item.familyRelationshipName },
    ]
    return {
      children: <Grid gridItems={itemGrid} />,
      key: `${index + 1}`,
      label: replaceString(TEXT_T083, ` ${index + 1}`),
    }
  })

  return (
    <Fragment>
      <Grid gridItems={travelerDetail} />
      <StyledSpace>
        {REGEX_IS_DESKTOP ? (
          <Table
            columns={columns}
            dataSource={relationshipInfo}
            isLoading={isLoading}
            pagination={false}
            rowKey="no"
          />
        ) : (
          // タブ毎に応じた渡航者の随伴家族情報を表示する。
          <Tabs defaultActiveKey={"1"} items={tabItems} />
        )}
      </StyledSpace>
      <Grid gridItems={numberOfPeople} />
    </Fragment>
  )
}
