import { useHistory } from "react-router-dom"
import { AttentionsDetailStateProps } from "../../../../models/detail"
import { Routers } from "../../../../../../../constant/Routers"
import { replaceString } from "../../../../../../../utils/replaceString"

const { SCR316, SCR317, SCR318, SCR316_HISTORY } = Routers

export const useRouters = (props: AttentionsDetailStateProps) => {
  const { push } = useHistory()

  const goToAttentionsInfo = () => {
    // 「SCR318_注意喚起情報の表示画面」に遷移する。
    push({
      pathname: replaceString(SCR318, props.attentionId),
      state: { ...props, sourceURL: SCR317 },
    })
  }

  /**
   * 「SCR316_注意喚起の一覧画面」に遷移する。
   * 「SCR316_注意喚起の一覧画面」に遷移する。
   */
  const goBack = () => {
    if (props) {
      const { isFromSCR316History, searchCondition } = props
      push({
        pathname: isFromSCR316History ? SCR316_HISTORY : SCR316,
        state: {
          searchCondition: {
            ...searchCondition,
            statusId: isFromSCR316History ? "" : "0",
          },
          sourceURL: SCR317,
        },
      })
    }
  }

  return {
    goBack,
    goToAttentionsInfo,
  }
}
