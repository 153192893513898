import { Form } from "antd"
import { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { Breadcrumbs } from "../../../../../../../constant/Breadcrumbs"
import { Routers } from "../../../../../../../constant/Routers"
import { getUserInformation } from "../../../../../../../utils/getUserInformation"
import { ChangePasswordStateType } from "../../../../models/changePassword"
import { useVariablesAuthority } from "../useVariablesAuthority"

const { SCR915 } = Routers
const { BRCR_SCR917 } = Breadcrumbs

export const useVariables = () => {
  const { push } = useHistory()
  const { search, state } = useLocation<ChangePasswordStateType>()
  const searchParams = new URLSearchParams(search)
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(true)
  const { isCommonRefer, isHiddenBtnBack, isHiddenBtnSubmit } =
    useVariablesAuthority()
  const userInformation = getUserInformation()

  const isFromSCR915OrMail =
    state?.sourceURL === SCR915 || !!searchParams.get("state")
  const breadcrumbItems = !isFromSCR915OrMail ? BRCR_SCR917 : []

  return {
    breadcrumbItems,
    form,
    isCommonRefer,
    isDisabledSubmit,
    isHiddenBtnBack,
    isHiddenBtnSubmit,
    isLoading,
    isLoadingLayout,
    isRemind: searchParams.get("remind"),
    isSideBar: !!state?.isChangePassword,
    langParam: searchParams.get("lang"),
    othersStaffNumber: userInformation?.othersStaffNumber,
    push,
    setIsDisabledSubmit,
    setIsLoading,
    setIsLoadingLayout,
    state,
    stateParam: searchParams.get("state"),
  }
}
