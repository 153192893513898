import "./style/index.scss"
import { FC } from "react"
import { ButtonSubmitProps } from "./models"
import { getStyle } from "../../../../../utils/getStyle"
import { Styled } from "./styled"

const { StyledButtonItem, StyledButtonItemReset, StyledButtonSubmit } = Styled

export const ButtonSubmit: FC<ButtonSubmitProps> = (props) => {
  const {
    addonAfterReset,
    addonAfterSubmit,
    addonBeforeReset,
    addonBeforeSubmit,
    className,
    isAbsoluteReset,
    isDisabledReset,
    isDisabledSubmit,
    isFlex,
    isFlexColumn,
    isHiddenReset,
    isHiddenSubmit,
    isOrder,
    isReset,
    onClickReset,
    onClickSubmit,
    resetHtmlType = "reset",
    resetText,
    resetTitle,
    resetType = "ghost",
    submitHtmlType = "submit",
    submitText,
    submitTitle,
    submitType = "primary",
  } = props
  const styleClassName = getStyle(className, ` ${className}`)
  const styleBtnFlexColumn = getStyle(isFlexColumn, " btn-submit-flex-column")
  const styleBtnOrder = getStyle(isOrder, " btn-submit-flex-order")
  const styleBtnFlex = getStyle(
    isFlex,
    ` btn-submit-flex${styleBtnFlexColumn}${styleBtnOrder}`,
  )
  const styleBtn = `btn-submit${styleBtnFlex}${styleClassName}`

  return (
    <StyledButtonSubmit className={styleBtn}>
      {isReset && (
        <StyledButtonItemReset
          addonAfter={addonAfterReset}
          addonBefore={addonBeforeReset}
          className="btn-submit-item btn-reset"
          htmlType={resetHtmlType}
          isAbsolute={isAbsoluteReset}
          isDisabled={isDisabledReset}
          isFullWidth
          isHidden={isHiddenReset}
          onClick={onClickReset}
          text={resetText}
          title={resetTitle}
          type={resetType}
        />
      )}
      <StyledButtonItem
        addonAfter={addonAfterSubmit}
        addonBefore={addonBeforeSubmit}
        className="btn-submit-item"
        htmlType={submitHtmlType}
        isDisabled={isDisabledSubmit}
        isFullWidth
        isHidden={isHiddenSubmit}
        onClick={onClickSubmit}
        text={submitText}
        title={submitTitle}
        type={submitType}
      />
    </StyledButtonSubmit>
  )
}
