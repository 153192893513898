import { getDepartments } from "../../../../../../../utils/apis/getDepartments"
import { getGeneralMasters } from "../../../../../../../utils/apis/getGeneralMasters"
import { GeneralCode } from "../../../../../../../constant/GeneralCode"
import { getAllCountries } from "../../../../../../../utils/apis/getAllCountries"
import { getCities } from "../../../../../../../utils/apis/getCities"

const { PRFT, RLTS } = GeneralCode

export const useVariablesUtils = (countryCode: string) => {
  // APIを呼び出し、「主管部署」コンボボックスの表示一覧を取得する。
  const { departments } = getDepartments()
  // APIを呼び出し、「随伴家族の続柄」コンボボックスの表示一覧を取得する。
  const { generalMasters: relationships } = getGeneralMasters(RLTS)
  // APIを呼び出し、「居住している都道府県」コンボボックスの表示一覧を取得する。
  const { generalMasters: prefectures } = getGeneralMasters(PRFT)
  // APIを呼び出し、「国」コンボボックスの表示一覧を取得する。
  const { allCountries } = getAllCountries({ isFilterJapan: true })
  // APIを呼び出し、「都市」コンボボックスの表示一覧を取得する。
  const { cities } = getCities(countryCode)

  return {
    allCountries,
    cities,
    departments,
    prefectures,
    relationships,
  }
}
