const HEADING_FOCUS_ROUTERS = [
  "/travelers/add-success",
  "/travelers/detail",
  "/travelers/user/detail",
  "/travelers/edit-success",
  "/travelers/delete-success",
  "/travelers/search",
  "/travelers/confirm-delete",
  "/schedules/detail",
  "/schedules/add-success",
  "/schedules/edit",
  "/schedules/edit/user",
  "/schedules/edit-success",
  "/schedules/confirm-delete",
  "/schedules/delete-success",
]

export const Constants = {
  HEADING_FOCUS_ROUTERS,
}
