import styled from "styled-components"
import { Dropdown } from "../../Dropdown"
import { Colors } from "../../../../../constant/Styled/Colors"

const { LIGHT } = Colors

const StyledDropdown = styled(Dropdown)`
  align-items: center;
  background-color: ${LIGHT};
  border: none;
  cursor: pointer;
  display: flex;
  margin-left: 36px;
  margin-right: 36px;
  padding: 0;
`

const StyledArrow = styled.span`
  margin-right: 2px;
`

const StyledText = styled.span`
  margin-left: 7px;
  margin-right: 7px;
`

export const Styled = {
  StyledArrow,
  StyledDropdown,
  StyledText,
}
