import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const { isCommonRefer, isRoleAdmin, isRoleSubAdmin, isTravelerDelete } =
    useAuthority()

  const isNotAccessScreen = !isTravelerDelete

  const isVisibleBtnBtn = isRoleAdmin || isRoleSubAdmin
  const isVisibleBtnCancel = isVisibleBtnBtn && isCommonRefer
  const isVisibleBtnDelete = isVisibleBtnBtn && isTravelerDelete

  return {
    isNotAccessScreen,
    isRoleAdmin,
    isVisibleBtnCancel,
    isVisibleBtnDelete,
  }
}
