/* eslint-disable complexity */
import { Routers } from "../../../../../../../../constant/Routers"
import { getValueOrUndefined } from "../../../../../../../../utils/getValueOrUndefined"
import {
  SchedulesGetDataSubmitProps,
  SchedulesListPostType,
} from "../../../../../models/list"
import {
  getDataSubmitAdvanced,
  getDataSubmitBasic,
} from "../../../utils/getDataSubmit"

const {
  SCR210_ATTENTIONS,
  SCR210_SAFETIES,
  SCR210_TRAVELERS,
  SCR210_USER_SEARCH,
  SCR210,
} = Routers

export const getDataSubmit = (
  props: SchedulesGetDataSubmitProps,
): SchedulesListPostType => {
  const {
    data,
    isFromSCR114UserSearch,
    isFromSCR115,
    isGetAllData,
    pathname,
    searchCondition,
    travelerId,
  } = props
  const isSearchCondition = !data && !!searchCondition
  const _dataSearchAdvanced =
    isSearchCondition && getDataSubmitAdvanced(searchCondition)
  const isPathnameSchedules =
    pathname === SCR210 ||
    (pathname === SCR210_TRAVELERS && isFromSCR115) ||
    (pathname === SCR210_USER_SEARCH && isFromSCR115)
  const _dataSearchBasic =
    isSearchCondition &&
    getDataSubmitBasic(searchCondition, isPathnameSchedules)

  const isOnABusinessTripOnly =
    pathname === SCR210_ATTENTIONS || pathname === SCR210_SAFETIES
      ? { isOnABusinessTripOnly: true }
      : {}

  return {
    ...data,
    ..._dataSearchAdvanced,
    ..._dataSearchBasic,
    ...isOnABusinessTripOnly,
    isFromSCR114UserSearch,
    isGetAllData,
    travelerId: getValueOrUndefined(isPathnameSchedules, travelerId),
  }
}
