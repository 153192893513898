/* eslint-disable max-lines */
/* eslint-disable max-statements */
import { useState } from "react"
import { useHistory } from "react-router-dom"
import {
  RequestBrowseTravelersResType,
  TableErrorTravelersRowType,
  TravelersRequestDeleteExecuteType,
  TravelersRequestDeleteReqType,
} from "../../../../models/requestDelete"
import { useVariablesModal } from "../useVariablesModal"
import { useRowSelection } from "../useRowSelection"

export const useVariablesApi = () => {
  const { push } = useHistory()
  const [activeKey, setActiveKey] =
    useState<keyof RequestBrowseTravelersResType>("deletionRequest")
  const [isLoading, setIsLoading] = useState(false)
  const [executeDetail, setExecuteDetail] =
    useState<TravelersRequestDeleteExecuteType>()
  const [tableErrorDataSource, setTableErrorDataSource] = useState<
    TableErrorTravelersRowType[]
  >([])
  const [isShowTableError, setIsShowTableError] = useState(false)
  const [isDisabledButton, setIsDisabledButton] = useState(false)

  const [dataSourceLength, setDataSourceLength] = useState(0)
  const [searchCondition, setSearchCondition] =
    useState<TravelersRequestDeleteReqType>()
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [dataGridItems, setDataGridItems] =
    useState<RequestBrowseTravelersResType>()
  const [isResetTable, setIsResetTable] = useState(false)

  const {
    isOpenAccept,
    isOpenReActive,
    isOpenReEnable,
    isOpenRequestCancel,
    onCancelAccept,
    onCancelReActive,
    onCancelReEnable,
    onCancelRequestCancel,
    onOpenAccept,
    onOpenReActive,
    onOpenReEnable,
    onOpenRequestCancel,
  } = useVariablesModal()

  const {
    onChange,
    onChangeTable,
    perItem,
    rowSelection,
    selectedRowKeys,
    setIsCheckAll,
    setSelectedRowKeys,
  } = useRowSelection({
    activeKey,
    dataGridItems,
    dataSourceLength,
  })

  return {
    activeKey,
    dataGridItems,
    dataSourceLength,
    executeDetail,
    isDisabledButton,
    isLoading,
    isLoadingLayout,
    isOpenAccept,
    isOpenReActive,
    isOpenReEnable,
    isOpenRequestCancel,
    isResetTable,
    isShowTableError,
    onCancelAccept,
    onCancelReActive,
    onCancelReEnable,
    onCancelRequestCancel,
    onChange,
    onChangeTable,
    onOpenAccept,
    onOpenReActive,
    onOpenReEnable,
    onOpenRequestCancel,
    perItem,
    push,
    rowSelection,
    searchCondition,
    selectedRowKeys,
    setActiveKey,
    setDataGridItems,
    setDataSourceLength,
    setExecuteDetail,
    setIsCheckAll,
    setIsDisabledButton,
    setIsLoading,
    setIsLoadingLayout,
    setIsResetTable,
    setIsShowTableError,
    setSearchCondition,
    setSelectedRowKeys,
    setTableErrorDataSource,
    tableErrorDataSource,
  }
}
