import styled from "styled-components"
import { Colors } from "../../../../../../../../constant/Styled/Colors"

const { BORDER, TABLE_HEADER } = Colors

const StyledRemark = styled.div`
  border-left: 1px solid ${BORDER};
  border-right: 1px solid ${BORDER};
  border-top: 1px solid ${BORDER};
  min-width: 870px;
  overflow-x: auto;
`
const StyledRemarkItem = styled.div`
  display: flex;
  width: 100%;
`
const StyledRemarkWrap = styled.div``

const StyledRemarkContent = styled.div`
  align-items: center;
  border-bottom: 1px solid ${BORDER};
  display: flex;
  flex-wrap: wrap;
  max-width: calc((100% - 90px) / 5);
  min-height: 51px;
  min-width: 200px;
  padding-left: 10px;
  padding-right: 10px;
  white-space: pre-wrap;
  width: 100%;
  &:first-child,
  &:nth-child(7) {
    max-width: 90px;
    min-width: 90px;
  }
`

const StyledRemarkHeader = styled(StyledRemarkContent)`
  background-color: ${TABLE_HEADER};
`

export const Styled = {
  StyledRemark,
  StyledRemarkContent,
  StyledRemarkHeader,
  StyledRemarkItem,
  StyledRemarkWrap,
}
