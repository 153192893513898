/* eslint-disable max-lines */
import { FocusEvent } from "react"
import { OptionItemType } from "../../../../../../../_webui/layout/components/Select/models"
import { GeneralCode } from "../../../../../../../constant/GeneralCode"
import { Routers } from "../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { departmentsApi } from "../../../../../../../infrastructure/handlers/departments"
import { DepartmentsResType } from "../../../../../../../infrastructure/handlers/departments/models"
import { generalMastersApi } from "../../../../../../../infrastructure/handlers/generalMasters"
import { GeneralMastersResType } from "../../../../../../../infrastructure/handlers/generalMasters/models"
import { checkIsArray } from "../../../../../../../utils/checkIsArray"
import { getTextByKey } from "../../../../../../../utils/getTextByKey"
import { getValueByLanguage } from "../../../../../../../utils/getValueByLanguage"
import { getValueCombobox } from "../../../../../../../utils/getValueCombobox"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { replaceString } from "../../../../../../../utils/replaceString"
import { setFieldsErrors } from "../../../../../../../utils/setFieldsErrors"
import { travelerApi } from "../../../../apis"
import { Variables } from "../../../../constants/edit/variables"
import { TravelersFormDataType } from "../../../../models/add"
import { useVariablesForm } from "../useVariablesForm"
import { getFormValues } from "./getFormValues"

const { DPTD, DPTD_SUBADMIN } = GeneralCode
const { SCR113, SCR973 } = Routers
const { KEYS_ACCOMPANYING_FAMILY } = Variables

export const useForm = () => {
  const {
    departments,
    dispatchType,
    form,
    isAccompanyingFamily,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isOpen,
    isRoleSubAdmin,
    isTravelerRegister,
    onCancel,
    onOpen,
    push,
    setDepartments,
    setDispatchType,
    setIsAccompanyingFamily,
    setIsDisabledSubmit,
    setIsLoading,
    setIsLoadingLayout,
    setValues,
    values,
  } = useVariablesForm()

  const callApi = async () => {
    // 主管部署一覧を部署マスタから取得する。
    const getDepartments: DepartmentsResType[] =
      await departmentsApi.getDepartments()
    // 派遣種別（詳細）一覧を汎用マスタから取得する。
    const getGeneralMaster: GeneralMastersResType[] =
      await generalMastersApi.getGeneralMaster(DPTD)
    Promise.all([getDepartments, getGeneralMaster])
      .then((res) => {
        const departmentItems: OptionItemType[] = res[0].map((item) => ({
          text: getValueByLanguage(
            item.departmentNameJapan,
            item.departmentNameEnglish,
          ).toString(),
          value: item.bumonCd,
        }))
        const dispatchTypeItems: OptionItemType[] = res[1].map((item) => ({
          text: getValueByLanguage(item.value1, item.value2).toString(),
          value: item.key1,
        }))
        const _dispatchTypeItems = isRoleSubAdmin
          ? dispatchTypeItems.filter(
              (item) => !DPTD_SUBADMIN.includes(String(item.value)),
            )
          : dispatchTypeItems
        setDepartments(departmentItems)
        setDispatchType(_dispatchTypeItems)
        setIsLoadingLayout(false)
      })
      .catch((error: AxiosErrorType) => {
        const state = pushStateError(error)
        push({ pathname: SCR973, state })
      })
  }

  const onOk = () => {
    if (typeof values === "object") {
      const { bumonCd, dispatchTypeDetailId } = values

      const bumonName = getTextByKey(bumonCd, departments)
      const dispatchTypeDetailName = getTextByKey(
        dispatchTypeDetailId,
        dispatchType,
      )

      const data = {
        ...values,
        bumonCd: getValueCombobox(bumonCd, bumonName),
        dispatchTypeDetailId: getValueCombobox(
          dispatchTypeDetailId,
          dispatchTypeDetailName,
        ),
        personDispatcherName: isAccompanyingFamily
          ? values.personDispatcherName
          : "",
      }

      setIsLoading(true)
      // 渡航者情報を登録する。
      travelerApi
        .addTraveler(data)
        .then((travelerId: string) => {
          // 「SCR113_渡航者の登録後画面」に遷移する。
          push(replaceString(SCR113, travelerId))
        })
        .catch((error: AxiosErrorType) => {
          // バックエンドから戻す「M.registerTravelerResponse.message」のメッセージが表示される。
          if (error?.debug?.response?.data?.entityName)
            setFieldsErrors(error, form)
          else {
            // 「SCR973_エラー情報の表示画面」に遷移する。
            const stateError = pushStateError(error)
            push({ pathname: SCR973, state: stateError })
          }
        })
        .finally(() => {
          onCancel()
          setIsLoading(false)
        })
    }
  }

  // 渡航者情報登録確認のダイアログボックスを作成する。
  const onFinish = (values: TravelersFormDataType) => {
    setValues(values)
    onOpen()
  }

  // 画面上の入力値のバリデーションチェックを行う。
  const onValuesChange = () => {
    const _isAccompanyingFamily = KEYS_ACCOMPANYING_FAMILY.includes(
      form.getFieldValue("dispatchTypeDetailId"),
    )

    setIsAccompanyingFamily(_isAccompanyingFamily)

    const formValues = getFormValues(form, _isAccompanyingFamily)

    setIsDisabledSubmit(
      checkIsArray(Object.values(formValues).filter((item) => !item)),
    )
  }

  const onBlurUserId = (event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target

    form.setFieldValue("personEmailAddress", value)
  }

  return {
    callApi,
    departments,
    dispatchType,
    form,
    isAccompanyingFamily,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isOpen,
    isRoleSubAdmin,
    isTravelerRegister,
    onBlurUserId,
    onCancel,
    onFinish,
    onOk,
    onValuesChange,
  }
}
