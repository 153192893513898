import { FC } from "react"
import { ButtonGroup } from "../../../../../../_webui/layout/components/Button/Group"
import { Card } from "../../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../../_webui/layout/components/Layout"
import { TotalRecords } from "../../../../../../_webui/layout/components/Typography/TotalRecords"
import { Breadcrumbs } from "../../../../../../constant/Breadcrumbs"
import { Translate } from "../../../../../../constant/Translate"
import { SubAdminFormSearch } from "../../../components/subAdmin/list/FormSearch"
import { SubAdminListType } from "../../../models/subAdmin/list"
import { useSubAdmin } from "./modules/useSubAdmin"
import { Styled } from "./styled"

const { BRCR_SCR952 } = Breadcrumbs
const { StyledTable } = Styled
const { TEXT_T397, TEXT_T450, TEXT_T463 } = Translate

export const SubAdmin: FC = () => {
  const {
    authorityPatterns,
    columns,
    dataSource,
    departments,
    form,
    goToRoleSetting,
    goToSubAdminSetting,
    isHiddenBtnReset,
    isHiddenBtnRoleSetting,
    isHiddenBtnSearch,
    isHiddenBtnSubAdminSetting,
    isLoading,
    isLoadingLayout,
    onChange,
    onChangeTable,
    onFinish,
    perItem,
    rowSelection,
  } = useSubAdmin()

  return (
    <Layout
      breadcrumbItems={BRCR_SCR952}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T397}
    >
      <Card className="sub-admin" heading={TEXT_T397} isLoading={isLoading}>
        <SubAdminFormSearch
          authorityPatterns={authorityPatterns}
          departments={departments}
          form={form}
          isHiddenBtnReset={isHiddenBtnReset}
          isHiddenBtnSearch={isHiddenBtnSearch}
          onFinish={onFinish}
        />
        <TotalRecords perItem={perItem} total={dataSource?.length} />
        <StyledTable
          columns={columns}
          dataSource={dataSource}
          isGroup
          onChange={onChange}
          onChangeTable={onChangeTable}
          rowKey={(record: SubAdminListType) => record.base.travelerId}
          rowSelection={rowSelection}
        />
        <ButtonGroup
          buttonItems={[
            {
              isHidden: !isHiddenBtnSubAdminSetting,
              onClick: goToSubAdminSetting,
              text: TEXT_T450,
              type: "primary",
            },
            {
              isHidden: !isHiddenBtnRoleSetting,
              onClick: goToRoleSetting,
              text: TEXT_T463,
              type: "primary",
            },
          ]}
        />
      </Card>
    </Layout>
  )
}
