import styled from "styled-components"
import { Regex } from "../../../../../../../constant/Regex"

const { REGEX_IS_DESKTOP } = Regex

const StyledAttentionsCategory = styled.span`
  padding-right: 24px;
  display: ${!REGEX_IS_DESKTOP && "block"};
`

const StyledAttentionsValue = styled.span`
  padding-left: 12px;
`

export const Styled = {
  StyledAttentionsCategory,
  StyledAttentionsValue,
}
