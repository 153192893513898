/* eslint-disable max-lines */
import { useEffect } from "react"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { useVariables } from "../useVariables"

export const useTravelersRequestDelete = () => {
  const {
    activeKey,
    columnErrors,
    columns,
    dataGridItems,
    dataSourceLength,
    dispatchType,
    executeDetail,
    handleGetBrowseTravelers,
    isDisabledButton,
    isLoading,
    isLoadingLayout,
    isNotAccessScreen,
    isOpenAccept,
    isOpenReActive,
    isOpenReEnable,
    isOpenRequestCancel,
    isResetTable,
    isShowTableError,
    isVisibleBtn,
    isVisibleBtnRoleAdmin,
    isVisibleBtnRoleSubAdmin,
    isVisibleTabRoleAdmin,
    isVisibleTabRoleSubAdmin,
    onCancelAccept,
    onCancelReActive,
    onCancelReEnable,
    onCancelRequestCancel,
    onChange,
    onChangeTable,
    onClickSearchSubmit,
    onOkAccept,
    onOkReActive,
    onOkReEnable,
    onOkRequestDisable,
    onOpenAccept,
    onOpenReActive,
    onOpenReEnable,
    onOpenRequestCancel,
    onTabClick,
    perItem,
    push,
    rowSelection,
    selectedRowKeys,
    tableErrorDataSource,
  } = useVariables()

  useEffect(() => {
    // ユーザの画面アクセス許可チェックを処理する。
    if (isNotAccessScreen) {
      // 「SCR973_エラー情報の表示画面」に遷移する。
      const { pathname, state } = backToLogin()
      push({ pathname, state })
    } else handleGetBrowseTravelers()
  }, [])

  return {
    activeKey,
    columnErrors,
    columns,
    dataGridItems,
    dataSourceLength,
    dispatchType,
    executeDetail,
    isDisabledButton,
    isLoading,
    isLoadingLayout,
    isOpenAccept,
    isOpenReActive,
    isOpenReEnable,
    isOpenRequestCancel,
    isResetTable,
    isShowTableError,
    isVisibleBtn,
    isVisibleBtnRoleAdmin,
    isVisibleBtnRoleSubAdmin,
    isVisibleTabRoleAdmin,
    isVisibleTabRoleSubAdmin,
    onCancelAccept,
    onCancelReActive,
    onCancelReEnable,
    onCancelRequestCancel,
    onChange,
    onChangeTable,
    onClickSearchSubmit,
    onOkAccept,
    onOkReActive,
    onOkReEnable,
    onOkRequestDisable,
    onOpenAccept,
    onOpenReActive,
    onOpenReEnable,
    onOpenRequestCancel,
    onTabClick,
    perItem,
    rowSelection,
    selectedRowKeys,
    tableErrorDataSource,
  }
}
