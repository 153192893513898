import { FC, Fragment } from "react"
import { InputItems } from "../../../../../../../constant/InputItems"
import { Translate } from "../../../../../../../constant/Translate"
import { ItemInput } from "../../../../../../../_webui/layout/components/Form/Item/Input"
import { ItemInputGroup } from "../../../../../../../_webui/layout/components/Form/Item/Input/Group"
import { ItemSelect } from "../../../../../../../_webui/layout/components/Form/Item/Select"
import { KanjiPassportFullName } from "../../../../../../../_webui/layout/components/Typography/KanjiPassportFullName"
import { RomajiPassportFullName } from "../../../../../../../_webui/layout/components/Typography/RomajiPassportFullName"
import { CollapsePersonalInfoProps } from "../models"

const {
  TEXT_T003,
  TEXT_T006,
  TEXT_T009,
  TEXT_T025,
  TEXT_T040,
  TEXT_T042,
  TEXT_T043,
} = Translate
const {
  KANJI_FULL_NAME,
  KANJI_PASSPORT_FULL_NAME,
  ROMAJI_FAMILY_FULL_NAME,
  ROMAJI_FULL_NAME,
  ROMAJI_PASSPORT_FULL_NAME,
} = InputItems

export const PersonalInfo: FC<CollapsePersonalInfoProps> = (props) => {
  const { departments, dispatchType, familyRelationship } = props

  return (
    <Fragment>
      <ItemInputGroup
        className="ant-form-item-group-first"
        inputItems={KANJI_FULL_NAME}
        label={TEXT_T003}
        name="fullNameKanji"
      />
      <ItemInputGroup
        inputItems={ROMAJI_FULL_NAME}
        label={TEXT_T006}
        name="fullNameRomaji"
      />
      <ItemInputGroup
        className="ant-form-item-group-first"
        inputItems={KANJI_PASSPORT_FULL_NAME}
        label={<KanjiPassportFullName />}
        name="kanjiPassportFullName"
      />
      <ItemInputGroup
        inputItems={ROMAJI_PASSPORT_FULL_NAME}
        label={<RomajiPassportFullName />}
        name="romajiPassportFullName"
      />
      <ItemSelect name="bumonCd" label={TEXT_T009} selectItem={departments} />
      <ItemSelect
        name="dispatchTypeDetailId"
        label={TEXT_T025}
        selectItem={dispatchType}
      />
      <ItemInput name="passportNumber" maxLength={9} label={TEXT_T040} />
      <ItemInputGroup
        inputItems={ROMAJI_FAMILY_FULL_NAME}
        label={TEXT_T042}
        name="familyRomajiFullName"
      />
      <ItemSelect
        name="familyRelationship"
        label={TEXT_T043}
        selectItem={familyRelationship}
      />
    </Fragment>
  )
}
