/* eslint-disable max-statements */
import { Form } from "antd"
import { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { GeneralCode } from "../../../../../../../constant/GeneralCode"
import { Routers } from "../../../../../../../constant/Routers"
import { getGeneralMasters } from "../../../../../../../utils/apis/getGeneralMasters"
import { useModal } from "../../../../../../../utils/hook/useModal"
import { Variables } from "../../../../constants/list/variables"
import {
  SafetiesListSearchCondition,
  SafetiesListType,
  SafetiesStateProps,
} from "../../../../models/list"
import { useRouters } from "../useRouters"
import { useRowSelection } from "../useRowSelection"
import { useTableConfig } from "../useTableConfig"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { getBreadcrumbItems } from "./getBreadcrumbItems"
import { useDatePicker } from "../../../../../../../utils/hook/useDatePicker"

const { CTGR } = GeneralCode
const { SCR416, SCR416_HISTORY } = Routers
const { STATUS_LIST, STATUS_LIST_HISTORY } = Variables

export const useVariables = () => {
  const [form] = Form.useForm()
  const { push } = useHistory()
  const { isOpen, onCancel, onOpen } = useModal()
  const { onBlur, onKeyDown } = useDatePicker({ form })
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const { pathname, state } = useLocation<SafetiesStateProps>()
  const [safetyInfoList, setSafetyInfoList] = useState<SafetiesListType[]>([])
  const isFromSCR416 = pathname === SCR416
  const isFromSCR416History = pathname === SCR416_HISTORY
  const {
    isCommonTravelerRefer,
    isHiddenDetailBtn,
    isHiddenResponseBtn,
    isHiddenResponseHistoryBtn,
    isRoleSubAdmin,
  } = useVariablesAuthority(pathname)

  const {
    isDisabledDetailBtn,
    isDisabledResponseBtn,
    onChange,
    perItem,
    rowSelection,
    safetyDetailId,
    safetyId,
  } = useRowSelection(safetyInfoList, isRoleSubAdmin)
  const [searchCondition, setSearchCondition] =
    useState<SafetiesListSearchCondition>(state?.searchCondition)

  const { goToSafetiesDetail, goToSafetiesResponse } = useRouters({
    isFromSCR416,
    isFromSCR416History,
    pathname,
    safetyDetailId,
    safetyId,
    searchCondition,
  })

  const { columns } = useTableConfig()

  // APIを呼び出し、「カテゴリ一覧」コンボボックスを取得し、APIからの応答結果を「categoryList」変数に設定する。
  const { generalMasters: categoryList } = getGeneralMasters(CTGR)

  const breadcrumbItems = getBreadcrumbItems(pathname)

  const statusList = isFromSCR416History ? STATUS_LIST_HISTORY : STATUS_LIST

  return {
    breadcrumbItems,
    categoryList,
    columns,
    form,
    goToSafetiesDetail,
    goToSafetiesResponse,
    isCommonTravelerRefer,
    isDisabledDetailBtn,
    isDisabledResponseBtn,
    isFromSCR416History,
    isHiddenDetailBtn,
    isHiddenResponseBtn,
    isHiddenResponseHistoryBtn,
    isLoading,
    isLoadingLayout,
    isOpen,
    isRoleSubAdmin,
    onBlur,
    onCancel,
    onChange,
    onKeyDown,
    onOpen,
    pathname,
    perItem,
    push,
    rowSelection,
    safetyInfoList,
    searchCondition,
    setIsLoading,
    setIsLoadingLayout,
    setSafetyInfoList,
    setSearchCondition,
    state,
    statusList,
  }
}
