import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../../_webui/layout/components/Table/models"
import { Translate } from "../../../../../../../../constant/Translate"
import { getValueByLanguage } from "../../../../../../../../utils/getValueByLanguage"
import { SubAdminSettingListType } from "../../../../../models/subAdmin/setting"

const { TEXT_T002, TEXT_T003, TEXT_T006, TEXT_T025, TEXT_T461 } = Translate

export const useTableConfig =
  (): UseTableConfigType<SubAdminSettingListType> => {
    const columns: ColumnType<SubAdminSettingListType>[] = [
      {
        dataIndex: "fullNameKanji",
        sorter: (a, b) => a.fullNameKanji.localeCompare(b.fullNameKanji),
        title: TEXT_T003,
        width: 250,
      },
      {
        dataIndex: "fullNameRomaji",
        sorter: (a, b) => a.fullNameRomaji.localeCompare(b.fullNameRomaji),
        title: TEXT_T006,
        width: 250,
      },
      {
        dataIndex: "dispatchTypeDetail",
        sorter: (a, b) =>
          a.dispatchTypeDetail.localeCompare(b.dispatchTypeDetail),
        title: TEXT_T025,
        width: 250,
      },
      {
        dataIndex: "userId",
        sorter: (a, b) => a.userId.localeCompare(b.userId),
        title: TEXT_T002,
        width: 250,
      },
      {
        dataIndex: "staffNumber",
        sorter: (a, b) => a.staffNumber.localeCompare(b.staffNumber),
        title: TEXT_T461,
        width: getValueByLanguage(170, 200),
      },
    ]
    return {
      columns,
    }
  }
