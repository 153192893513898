import { AnyValue } from "../../../_webui/layout/models"
import { AxiosErrorType } from "../../../infrastructure/axiosError/models"
import { PushStateErrorType } from "../../pushStateError/models"

export const userIdNotExist = (
  data: AnyValue[],
  error: AxiosErrorType,
  stateError: PushStateErrorType,
) => {
  if (error?.debug?.response?.data?.message === "E0033") {
    const params = error?.debug?.response?.data?.params
    const paramList = params.split(",")
    const _data = data.filter((item) => paramList.includes(item.travelerId))
    const userIds = _data.map((item) => item.userId).join(", ")

    return {
      ...stateError,
      message: stateError.message.replace(params, userIds),
    }
  } else {
    return stateError
  }
}
