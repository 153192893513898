import { Translate } from "../../../../../../../constant/Translate"
import { PreLine } from "../../../../Typography/PreLine"
import { ColumnType } from "../../../models"

import { TableErrorRowType } from "../../models"
import { UseTableErrorConfigType } from "../../models/index"

const { TEXT_T041, TEXT_T599, TEXT_T600 } = Translate

export const useTableErrorConfig =
  (): UseTableErrorConfigType<TableErrorRowType> => {
    const columnErrors: ColumnType<TableErrorRowType>[] = [
      {
        align: "center",
        dataIndex: "id",
        title: TEXT_T041,
        width: 40,
      },
      {
        align: "center",
        dataIndex: "line",
        title: TEXT_T599,
        width: 40,
      },
      {
        dataIndex: "detail",
        render: (_, data) => <PreLine>{data.detail}</PreLine>,
        title: TEXT_T600,
        width: 800,
      },
    ]
    return {
      columnErrors,
    }
  }
