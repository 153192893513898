import { useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useAuthority } from "../../../../../../../utils/hook/useAuthority"
import { UseParamsType } from "../../../../../../../utils/useParams"
import { TravelersAddSuccessType } from "../../../../models/addSuccess"
import { useGridConfig } from "../useGridConfig"

export const useVariables = () => {
  const { push } = useHistory()
  const { id } = useParams<UseParamsType>()
  const { isTravelerRefer } = useAuthority()
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [detail, setDetail] = useState<TravelersAddSuccessType>()
  const { gridItems } = useGridConfig(detail)

  return {
    gridItems,
    id,
    isLoadingLayout,
    isTravelerRefer,
    push,
    setDetail,
    setIsLoadingLayout,
  }
}
