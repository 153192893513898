/* eslint-disable max-lines */
import { FC } from "react"
import { Card } from "../../../../../../_webui/layout/components/Card"
import { Form } from "../../../../../../_webui/layout/components/Form"
import { Layout } from "../../../../../../_webui/layout/components/Layout"
import { ModalConfirm } from "../../../../../../_webui/layout/components/Modal/Confirm"
import { Table } from "../../../../../../_webui/layout/components/Table"
import { Heading } from "../../../../../../_webui/layout/components/Typography/Heading"
import { Title } from "../../../../../../_webui/layout/components/Typography/Title"
import { TotalRecords } from "../../../../../../_webui/layout/components/Typography/TotalRecords"
import { Breadcrumbs } from "../../../../../../constant/Breadcrumbs"
import { Translate } from "../../../../../../constant/Translate"
import { SubAdminSettingButtonGroup } from "../../../components/subAdmin/setting/ButtonGroup"
import { SubAdminSettingFormSearch } from "../../../components/subAdmin/setting/FormSearch"
import { InitialValues } from "../../../constants/subAdmin/list/initialValues"
import { useSubAdminSetting } from "./modules/useSetting"
import { Styled } from "./styled"

const { MESSAGE_C0014, TEXT_T388, TEXT_T453, TEXT_T545, TEXT_T552, TEXT_T593 } =
  Translate
const { PATTERN_ID_INITIAL_VALUES } = InitialValues
const { StyledPatternId } = Styled
const { BRCR_SCR950 } = Breadcrumbs

export const SubAdminSetting: FC = () => {
  const {
    authorityPatterns,
    columns,
    dataSource,
    departments,
    form,
    formSearch,
    goBack,
    goToRolesSetting,
    goToSubAdmin,
    handleResetForm,
    isDisabledBtnSave,
    isHiddenBtn,
    isLoading,
    isLoadingLayout,
    isOpen,
    onCancel,
    onChange,
    onChangeAuthorityPatterns,
    onChangeTable,
    onFinish,
    onFinishSubmit,
    onOk,
    perItem,
    rowSelection,
  } = useSubAdminSetting()

  return (
    <Layout
      breadcrumbItems={BRCR_SCR950}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T388}
    >
      <Card heading={TEXT_T388} isLoading={isLoading}>
        <SubAdminSettingFormSearch
          departments={departments}
          form={formSearch}
          handleResetForm={handleResetForm}
          onFinish={onFinish}
        />
        <Heading title={TEXT_T552} />
        <Title titleLeft={TEXT_T545} />
        <Form
          form={form}
          initialValues={PATTERN_ID_INITIAL_VALUES}
          isCard={false}
          isHeading={false}
          layout="vertical"
          onFinish={onFinishSubmit}
        >
          <StyledPatternId
            label={TEXT_T453}
            name="patternId"
            onChange={onChangeAuthorityPatterns}
            selectItem={authorityPatterns}
          />
          <TotalRecords perItem={perItem} total={dataSource?.length} />
          <Table
            columns={columns}
            dataSource={dataSource}
            heading={TEXT_T593}
            onChange={onChange}
            onChangeTable={onChangeTable}
            rowKey="travelerId"
            rowSelection={rowSelection}
          />
          <SubAdminSettingButtonGroup
            goBack={goBack}
            goToRolesSetting={goToRolesSetting}
            goToSubAdmin={goToSubAdmin}
            isDisabledBtnSave={isDisabledBtnSave}
            isHiddenBtn={isHiddenBtn}
          />
        </Form>
      </Card>
      <ModalConfirm isOpen={isOpen} onCancel={onCancel} onOk={onOk}>
        {MESSAGE_C0014}
      </ModalConfirm>
    </Layout>
  )
}
