import { useEffect } from "react"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { TravelersDetailType } from "../../../../models/detail"
import { useVariables } from "../useVariables"

export const useTravelersDetail = (): TravelersDetailType => {
  const {
    breadcrumbItems,
    columns,
    getTravelerDetails,
    goBack,
    goToSchedulesList,
    goToTravelersEdit,
    id,
    isHiddenBtnBack,
    isHiddenBtnDelete,
    isHiddenBtnEdit,
    isHiddenBtnRequestDelete,
    isHiddenBtnSchedules,
    isLoading,
    isLoadingLayout,
    isNotAccessScreen,
    isOpen,
    isRoleAdmin,
    isRoleSubAdmin,
    isSystemIdentifier,
    onCancel,
    onOk,
    onOpen,
    push,
    travelerInfo,
  } = useVariables()

  useEffect(() => {
    // ユーザの画面アクセス許可チェックを処理する。
    if (isNotAccessScreen) {
      // 「SCR973_エラー情報の表示画面」に遷移する。
      const { pathname, state } = backToLogin()
      push({ pathname, state })
    } else getTravelerDetails()
  }, [id])

  return {
    breadcrumbItems,
    columns,
    goBack,
    goToSchedulesList,
    goToTravelersEdit,
    isHiddenBtnBack,
    isHiddenBtnDelete,
    isHiddenBtnEdit,
    isHiddenBtnRequestDelete,
    isHiddenBtnSchedules,
    isLoading,
    isLoadingLayout,
    isOpen,
    isRoleAdmin,
    isRoleSubAdmin,
    isSystemIdentifier,
    onCancel,
    onOk,
    onOpen,
    travelerInfo,
  }
}
