import { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { ApplicationFormDataSourceType } from "../../../../models/list"
import { Moment } from "moment"
import { useRowSelection } from "../useRowSelection"
import { useModal } from "../../../../../../../utils/hook/useModal"
import { ConstantManual } from "../../../../../systems/constants/manual"

const { BREADCRUMB_ITEMS } = ConstantManual

export const useVariablesApi = () => {
  const { push } = useHistory()
  const { pathname } = useLocation()
  const [fileInfoList, setFileInfoList] = useState<
    ApplicationFormDataSourceType[]
  >([])
  const [initDateTime, setInitDateTime] = useState<Moment>()
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const { isOpen, onCancel, onOpen } = useModal()
  const { breadcrumbItems, layoutTitle, type } = BREADCRUMB_ITEMS[pathname]

  const {
    isDisabledBtnDownload,
    onChangePagination,
    onChangeTable,
    perItem,
    rowSelection,
    selectedRowKeys,
  } = useRowSelection(fileInfoList)

  return {
    breadcrumbItems,
    fileInfoList,
    initDateTime,
    isDisabledBtnDownload,
    isLoading,
    isLoadingLayout,
    isOpen,
    layoutTitle,
    onCancel,
    onChangePagination,
    onChangeTable,
    onOpen,
    pathname,
    perItem,
    push,
    rowSelection,
    selectedRowKeys,
    setFileInfoList,
    setInitDateTime,
    setIsLoading,
    setIsLoadingLayout,
    type,
  }
}
