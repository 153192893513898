import { Translate } from "../../../../../constant/Translate"

const { TEXT_T517 } = Translate

export const selectItem = [
  { text: `25${TEXT_T517}`, value: 25 },
  { text: `50${TEXT_T517}`, value: 50 },
  { text: `100${TEXT_T517}`, value: 100 },
  { text: `250${TEXT_T517}`, value: 250 },
  { text: `500${TEXT_T517}`, value: 500 },
]
