/* eslint-disable max-lines */
import { FC, Fragment } from "react"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { Upload } from "../../../../../_webui/layout/components/Upload"
import { Breadcrumbs } from "../../../../../constant/Breadcrumbs"
import { Translate } from "../../../../../constant/Translate"
import { useFilesUpload } from "./modules/useUpload"
import { Title } from "../../../../../_webui/layout/components/Typography/Title"
import { Table } from "../../../../../_webui/layout/components/Table"
import { ButtonGroup } from "../../../../../_webui/layout/components/Button/Group"
import { FilesUploadModalConfirm } from "../../components/filesUpload/ModalConfirm"

const { TEXT_T012, TEXT_T507, TEXT_T621, TEXT_T622, TEXT_T623 } = Translate
const { BRCR_SCR980 } = Breadcrumbs

export const FilesUpload: FC = () => {
  const {
    beforeUpload,
    columns,
    fileNameList,
    fileUploadInfoList,
    isDisableUpload,
    isHiddenBtn,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenConfirm,
    isOpenReload,
    isOpenSuccess,
    onCancel,
    onCancelConfirm,
    onCancelReload,
    onOkSuccess,
    onOpenReload,
    onOkConfirm,
    onOkReload,
    onOkUpload,
    onOpen,
    rowSelection,
  } = useFilesUpload()

  return (
    <Layout
      breadcrumbItems={BRCR_SCR980}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T621}
    >
      <Card heading={TEXT_T621}>
        <Upload
          beforeUpload={beforeUpload}
          fileNameList={fileNameList}
          isHiddenUploadHint
          isMaxCount={false}
          multiple
          titleLeft={TEXT_T622}
        />
        {fileUploadInfoList?.length ? (
          <Fragment>
            <Title titleLeft={TEXT_T623}></Title>
            <Table
              columns={columns}
              dataSource={fileUploadInfoList}
              isFixed={false}
              isLoading={isLoading}
              pagination={false}
              rowKey="index"
              rowSelection={rowSelection}
            />
            <ButtonGroup
              buttonItems={[
                {
                  isHidden: isHiddenBtn,
                  onClick: onOpenReload,
                  text: TEXT_T012,
                  type: "ghost",
                },
                {
                  isDisabled: isDisableUpload,
                  isHidden: isHiddenBtn,
                  onClick: onOpen,
                  text: TEXT_T507,
                  type: "primary",
                },
              ]}
            />
          </Fragment>
        ) : null}

        <FilesUploadModalConfirm
          isOpen={isOpen}
          isOpenConfirm={isOpenConfirm}
          isOpenReload={isOpenReload}
          isOpenSuccess={isOpenSuccess}
          onCancel={onCancel}
          onCancelConfirm={onCancelConfirm}
          onCancelReload={onCancelReload}
          onOkConfirm={onOkConfirm}
          onOkReload={onOkReload}
          onOkSuccess={onOkSuccess}
          onOkUpload={onOkUpload}
        />
      </Card>
    </Layout>
  )
}
