import { FC } from "react"
import { Alert } from "../../../../../_webui/layout/components/Alert"
import { ButtonGroup } from "../../../../../_webui/layout/components/Button/Group"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { BreakText } from "../../../../../_webui/layout/components/Typography/BreakText"
import { Translate } from "../../../../../constant/Translate"
import { ScheduleAddSuccessCollapse } from "../../components/addSuccess/ContentCollapse"
import { useSchedulesAddSuccess } from "./modules/useAddSuccess"

const { TEXT_T016, TEXT_T288, TEXT_T289, TEXT_T291 } = Translate

export const SchedulesAddSuccess: FC = () => {
  const {
    breadcrumbItems,
    goToSchedules,
    isLoadingLayout,
    isVisibleBtnSearch,
    ...rest
  } = useSchedulesAddSuccess()

  return (
    <Layout
      breadcrumbItems={breadcrumbItems}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T288}
    >
      <Card heading={TEXT_T288}>
        <Alert
          type="success"
          content={<BreakText above={TEXT_T289} below={TEXT_T016} />}
        />

        <ScheduleAddSuccessCollapse {...rest} />

        <ButtonGroup
          buttonItems={[
            {
              absolutePosition: "right",
              isAbsolute: true,
              isHidden: !isVisibleBtnSearch,
              onClick: goToSchedules,
              order: 1,
              text: TEXT_T291,
            },
          ]}
        />
      </Card>
    </Layout>
  )
}
