import { FC } from "react"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { Breadcrumbs } from "../../../../../constant/Breadcrumbs"
import { Translate } from "../../../../../constant/Translate"
import { Table } from "../../../../../_webui/layout/components/Table"
import { Center } from "../../../../../_webui/layout/components/Center"
import { Button } from "../../../../../_webui/layout/components/Button"
import { TotalRecords } from "../../../../../_webui/layout/components/Typography/TotalRecords"
import { Images } from "../../../../../constant/Images"
import { useUploadResults } from "./modules/useUploadResults"
import { Space } from "../../../../../_webui/layout/components/Space"

const { TEXT_T628, TEXT_T629, TEXT_T630 } = Translate
const { BRCR_SCR981 } = Breadcrumbs
const { IMAGE_REFRESH } = Images

export const UploadResults: FC = () => {
  const {
    batchLogInfoList,
    columns,
    handleExportCSV,
    handleRefreshBatchLog,
    isHiddenBtn,
    isLoading,
    isLoadingLayout,
    onChange,
    onChangeTable,
    perItem,
    rowSelection,
  } = useUploadResults()

  return (
    <Layout
      breadcrumbItems={BRCR_SCR981}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T630}
    >
      <Card
        heading={TEXT_T630}
        isLoading={isLoading}
        isSearch={!isHiddenBtn}
        isSearchIcon
        isSmall
        onClick={handleRefreshBatchLog}
        searchIcon={IMAGE_REFRESH}
        type="ghost"
      >
        <Space isSpaceTop>
          <TotalRecords
            perItem={perItem}
            titleLeft={TEXT_T628}
            total={batchLogInfoList?.length}
          />
        </Space>
        <Table
          columns={columns}
          dataSource={batchLogInfoList}
          onChange={onChange}
          onChangeTable={onChangeTable}
          rowKey="id"
          rowSelection={rowSelection}
        />
        <Center>
          <Button
            htmlType="button"
            isFullWidth
            isHidden={isHiddenBtn}
            onClick={handleExportCSV}
            text={TEXT_T629}
            type="primary"
          />
        </Center>
      </Card>
    </Layout>
  )
}
