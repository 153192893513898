import { convertDataExport } from "./convertDataExport"
import { SchedulesListDataType } from "../../../../../models/list"
import { exportCsv } from "../../../../../../../../utils/exportCsv"
import { Translate } from "../../../../../../../../constant/Translate"

const { EXPORT_EP0194 } = Translate

export const exportFRM102 = (data: SchedulesListDataType[]) => {
  const convertData = convertDataExport(data)
  exportCsv(convertData, EXPORT_EP0194)
}
