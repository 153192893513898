/* eslint-disable max-lines */
import { Translate } from "../../../../../../../constant/Translate"
import { replaceString } from "../../../../../../../utils/replaceString"
import { SchedulesAddDetailTab } from "../../../../components/add/Form/ContentCollapse/Detail/Tabs"
import { SchedulesAddLodgingTab } from "../../../../components/add/Form/ContentCollapse/Lodging/Tabs"
import { SchedulesAddStayTab } from "../../../../components/add/Form/ContentCollapse/Stay/Tabs"
import { UseSchedulesAddTabConfig } from "./models"

const { TEXT_T266, TEXT_T267, TEXT_T268 } = Translate

export const useSchedulesAddTabConfig = (props: UseSchedulesAddTabConfig) => {
  const {
    allCountries,
    detailDataSource,
    flightNumberList,
    getItemTableRequiredField,
    handleAddDetailItem,
    handleAddLodgingItem,
    handleAddStayItem,
    handleDeleteDetailItem,
    handleDeleteLodgingItem,
    handleDeleteStayItem,
    isRequiredItemTableByPPTV,
    isSubmitValidate,
    isVisibleBtn,
    lodgingDataSource,
    onBlur,
    onChangeCity,
    onChangeCountry,
    onChangeDate,
    onChangeTransportation,
    onClearDepartureFlight,
    onKeyDown,
    onSelectDepartureFlight,
    stayDataSource,
    transportationList,
  } = props

  const detailTabsItems = detailDataSource.map((item, index) => {
    return {
      children: (
        <SchedulesAddDetailTab
          allCountries={allCountries}
          currentIndex={index}
          flightNumberList={flightNumberList}
          handleAddDetailItem={handleAddDetailItem}
          handleDeleteDetailItem={handleDeleteDetailItem}
          isSubmitValidate={isSubmitValidate}
          isVisibleBtn={isVisibleBtn}
          onBlur={onBlur}
          onChangeCity={onChangeCity}
          onChangeCountry={onChangeCountry}
          onChangeDate={onChangeDate}
          onChangeTransportation={onChangeTransportation}
          onClearDepartureFlight={onClearDepartureFlight}
          onKeyDown={onKeyDown}
          onSelectDepartureFlight={onSelectDepartureFlight}
          schedulesAddDetailItem={item}
          transportationList={transportationList}
        />
      ),
      key: `${index + 1}`,
      label: replaceString(TEXT_T266, `${index + 1}`),
    }
  })

  const lodgingTabsItems = lodgingDataSource.map((item, index) => {
    return {
      children: (
        <SchedulesAddLodgingTab
          getItemTableRequiredField={getItemTableRequiredField}
          handleAddLodgingItem={handleAddLodgingItem}
          handleDeleteLodgingItem={handleDeleteLodgingItem}
          isRequiredItemTableByPPTV={isRequiredItemTableByPPTV}
          isSubmitValidate={isSubmitValidate}
          isVisibleBtn={isVisibleBtn}
          onBlur={onBlur}
          onChangeDate={onChangeDate}
          onKeyDown={onKeyDown}
          schedulesAddLodgingItem={item}
        />
      ),
      key: `${index + 1}`,
      label: replaceString(TEXT_T267, ` ${index + 1}`),
    }
  })

  const stayTabsItems = stayDataSource.map((item, index) => ({
    children: (
      <SchedulesAddStayTab
        getItemTableRequiredField={getItemTableRequiredField}
        handleAddStayItem={handleAddStayItem}
        handleDeleteStayItem={handleDeleteStayItem}
        isRequiredItemTableByPPTV={isRequiredItemTableByPPTV}
        isVisibleBtn={isVisibleBtn}
        schedulesAddStayItem={item}
      />
    ),
    key: `${index + 1}`,
    label: replaceString(TEXT_T268, ` ${index + 1}`),
  }))

  return {
    detailTabsItems,
    lodgingTabsItems,
    stayTabsItems,
  }
}
