import { FC } from "react"
import { Translate } from "../../../../../../../constant/Translate"
import { ButtonGroup } from "../../../../../../../_webui/layout/components/Button/Group"
import { FilesListButtonGroupProps } from "../../../../models/files/list"

const { TEXT_T012, TEXT_T033, TEXT_T510 } = Translate

export const FilesListButtonGroup: FC<FilesListButtonGroupProps> = (props) => {
  const {
    goToHome,
    handleDownload,
    handlePreviewFile,
    isDisabledBtnDownload,
    isDisabledBtnPreview,
    isVisibleBtnCancel,
    isVisibleBtnDownload,
    isVisibleBtnReference,
  } = props

  return (
    <ButtonGroup
      buttonItems={[
        {
          isHidden: !isVisibleBtnCancel,
          onClick: goToHome,
          order: 3,
          text: TEXT_T012,
          type: "ghost",
        },
        {
          isDisabled: isDisabledBtnDownload,
          isHidden: !isVisibleBtnDownload,
          onClick: handleDownload,
          order: 1,
          text: TEXT_T510,
          type: "primary",
        },
        {
          isDisabled: isDisabledBtnPreview,
          isHidden: !isVisibleBtnReference,
          onClick: handlePreviewFile,
          order: 2,
          text: TEXT_T033,
        },
      ]}
      position="space-between"
    />
  )
}
