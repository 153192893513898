import { useState } from "react"
import { AttentionsInfoDataSourceType } from "../../../../models/info"
import { useApi } from "../useApi"
import { useTableConfig } from "../useTableConfig"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { getBreadcrumbItems } from "./getBreadcrumbItems"
import { getUserInformation } from "../../../../../../../utils/getUserInformation"

export const useVariables = () => {
  const [attentionInfoList, setAttentionInfoList] = useState<
    AttentionsInfoDataSourceType[]
  >([])
  const { columns } = useTableConfig()
  const {
    attentionInfo,
    getAttention,
    getAttentionTravelers,
    isFromSCR316,
    isFromSCR316History,
    isHiddenSubmit,
    isLoading,
    isLoadingLayout,
    push,
    sourceURL,
    state,
  } = useApi()

  const { isCommonRefer, isRoleSubAdmin } = useVariablesAuthority()
  const breadcrumbItems = getBreadcrumbItems(
    isFromSCR316,
    isFromSCR316History,
    sourceURL,
  )
  const userInformation = getUserInformation()

  return {
    attentionInfo,
    attentionInfoList,
    breadcrumbItems,
    columns,
    getAttention,
    getAttentionTravelers,
    isCommonRefer,
    isHiddenSubmit,
    isLoading,
    isLoadingLayout,
    isRoleSubAdmin,
    push,
    setAttentionInfoList,
    state,
    userInformation,
  }
}
