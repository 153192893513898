const BG_EXCEPTION = "#eeeeee"
const BG_WIDGET = "#f4faff"
const BORDER_CHECKBOX = "#707070"
const BORDER_EXCEPTION = "#ababab"
const BORDER_RADIO = "#4a4a4a"
const BORDER = "#e1e1e1"
const BOX_HEADER = "#ebf4fc"
const BOX_SHADOW = "#00000029"
const BTN_DEFAULT_BORDER = "#7cb4e5"
const BTN_DEFAULT = "#eef6ff"
const BTN_PRIMARY = "#005f8f"
const CARD_DIVIDER = "#dbedff"
const DANGER = "#d80f1a"
const DISABLED = "#e1e1e1"
const FOCUS = "#00000080"
const FOOTER = "#dfe6eb"
const FORM_ENTRY_BORDER = "#bebebe"
const GRID_KEY = "#f2f2f2"
const HEADING = "#e1e1e1"
const LABEL_BLUE = "#0070c0"
const LIGHT = "#fff"
const LIGHT_RGB = "255, 255, 255"
const MAIN = "#edf2f6"
const MENU_ACTIVE = "#f0f8ff"
const MENU_GRADIENT_1 = "194, 227, 255"
const MENU_GRADIENT_2 = "143, 202, 255"
const MENU_SUB = "#b4d4f3"
const PRIMARY = "#095ba5"
const SELECTED = "#ebfcfc"
const TABLE_HEADER_BORDER = "#979797"
const TABLE_HEADER = "#f2f2f2"
const TABLE_SELECTED = "#ebfcfc"
const TABS_ACTIVE_BORDER = "#47dadf"
const TABS_ACTIVE_total = "#008387"
const TABS_ACTIVE = "#cdf9fb"
const TAG_DEFAULT = "#707070"
const TAG_ERROR = "#d80f1a"
const TAG_STOP = "#4a4a4a"
const TAG_SUCCESS = "#545fff"
const TAG_WARNING = "#ab6700"
const TEXT_TABS_ACTIVE = "#00676b"
const TEXT = "#4a4a4a"

export const Colors = {
  BG_EXCEPTION,
  BG_WIDGET,
  BORDER,
  BORDER_CHECKBOX,
  BORDER_EXCEPTION,
  BORDER_RADIO,
  BOX_HEADER,
  BOX_SHADOW,
  BTN_DEFAULT,
  BTN_DEFAULT_BORDER,
  BTN_PRIMARY,
  CARD_DIVIDER,
  DANGER,
  DISABLED,
  FOCUS,
  FOOTER,
  FORM_ENTRY_BORDER,
  GRID_KEY,
  HEADING,
  LABEL_BLUE,
  LIGHT,
  LIGHT_RGB,
  MAIN,
  MENU_ACTIVE,
  MENU_GRADIENT_1,
  MENU_GRADIENT_2,
  MENU_SUB,
  PRIMARY,
  SELECTED,
  TABLE_HEADER,
  TABLE_HEADER_BORDER,
  TABLE_SELECTED,
  TABS_ACTIVE,
  TABS_ACTIVE_BORDER,
  TABS_ACTIVE_total,
  TAG_DEFAULT,
  TAG_ERROR,
  TAG_STOP,
  TAG_SUCCESS,
  TAG_WARNING,
  TEXT,
  TEXT_TABS_ACTIVE,
}
