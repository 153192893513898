import { Breadcrumbs } from "../../../../../../../../constant/Breadcrumbs"
import { Routers } from "../../../../../../../../constant/Routers"

const { SCR210, SCR210_TRAVELERS, SCR210_USER_SEARCH } = Routers
const {
  BRCR_SCR213_FROM_SCR212_USER,
  BRCR_SCR213_FROM_SCR212,
  BRCR_SCR213_FROM_210_USER_SEARCH,
  BRCR_SCR213,
} = Breadcrumbs

export const getBreadcrumbItems = (
  schedulesURL?: string,
  isFromSCR212?: boolean,
) => {
  if (isFromSCR212) {
    if (schedulesURL === SCR210) return BRCR_SCR213_FROM_SCR212

    if (schedulesURL === SCR210_TRAVELERS) return BRCR_SCR213_FROM_SCR212_USER

    if (schedulesURL === SCR210_USER_SEARCH)
      return BRCR_SCR213_FROM_210_USER_SEARCH

    return BRCR_SCR213
  }
}
