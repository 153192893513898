import { Endpoint } from "../../../../constant/Endpoint"
import { Environment } from "../../../../constant/Environment"
import { apiService } from "../../../../infrastructure/apiService"
import { replaceStringParam } from "../../../../utils/replaceStringParam"
import { SafetiesAnswerConfirmReqType } from "../models/answer"
import {
  SafetiesDetailExportReqType,
  SafetiesDetailReqType,
} from "../models/detail"
import { SafetiesInfoDataDetailReqType } from "../models/info"
import { SafetiesListSearchCondition } from "../models/list"
import { SafetiesSendReqType } from "../models/safety"

const { SAFETY_ENDPOINT } = Environment
const {
  FNC3_Add_Safety,
  FNC3_Confirm_Safety,
  FNC3_Count,
  FNC3_Export,
  FNC3_Get_Detail_Safety_Traveler,
  FNC3_Get_Safeties,
  FNC3_Get_Safety_Travelers,
  FNC3_Get_Safety,
} = Endpoint

export const safetyApi = {
  addSafety: (data: SafetiesSendReqType) =>
    apiService({
      data,
      method: "POST",
      url: `${SAFETY_ENDPOINT}${FNC3_Add_Safety}`,
    }),
  confirmSafety: (
    data: SafetiesAnswerConfirmReqType,
    safetyId: string,
    safetyDetailId: string,
  ) =>
    apiService({
      data: data,
      method: "PUT",
      url: `${SAFETY_ENDPOINT}${replaceStringParam(
        FNC3_Confirm_Safety,
        safetyId,
        safetyDetailId,
      )}`,
    }),
  count: (id: string) =>
    apiService({
      method: "GET",
      url: `${SAFETY_ENDPOINT}${replaceStringParam(
        FNC3_Count,
        id,
      )}?status=Security,SlightlyInjured,SeriouslyInjured,Noresponse,UndeliveredEmail`,
    }),
  export: (data: SafetiesDetailExportReqType) =>
    apiService({
      data: data.selectedRowKeys,
      method: "POST",
      url: `${SAFETY_ENDPOINT}${replaceStringParam(
        FNC3_Export,
        data.safetyId,
      )}`,
    }),
  getDetailSafetyTraveler: (data: SafetiesInfoDataDetailReqType) =>
    apiService({
      method: "GET",
      url: `${SAFETY_ENDPOINT}${replaceStringParam(
        FNC3_Get_Detail_Safety_Traveler,
        data.safetyId,
        data.safetyDetailId,
      )}`,
    }),
  getSafeties: (data?: Pick<SafetiesListSearchCondition, never>) =>
    apiService({
      data,
      method: "POST",
      url: `${SAFETY_ENDPOINT}${FNC3_Get_Safeties}`,
    }),
  getSafety: (param: SafetiesInfoDataDetailReqType) =>
    apiService({
      method: "GET",
      params: { safetyDetailId: param.safetyDetailId },
      url: `${SAFETY_ENDPOINT}${replaceStringParam(
        FNC3_Get_Safety,
        param.safetyId,
      )}`,
    }),
  getSafetyTravelers: (param: SafetiesDetailReqType) =>
    apiService({
      method: "GET",
      params: {
        safetyStatus: param.safetyStatus,
      },
      url: `${SAFETY_ENDPOINT}${replaceStringParam(
        FNC3_Get_Safety_Travelers,
        param.safetyId,
      )}`,
    }),
}
