/* eslint-disable max-lines */
import { GeneralCode } from "../../../../../../../../../constant/GeneralCode"
import { SelectItems } from "../../../../../../../../../constant/SelectItems"
import { Translate } from "../../../../../../../../../constant/Translate"
import { concat } from "../../../../../../../../../utils/concat"
import {
  getDateTimeZoneByUserInformation,
  getDay,
} from "../../../../../../../../../utils/getDate"
import { getTrainingType } from "../../../../../../../../../utils/getTrainingType"
import { getWorkPlaceLocation } from "../../../../../../../../../utils/getWorkPlaceLocation"
import { TravelersDetailDataType } from "../../../../../../models/detail/data"

const { OTHER } = GeneralCode
const { OTHER_CITY_SELECT_ITEMS } = SelectItems

const {
  TEXT_T017,
  TEXT_T046,
  TEXT_T047,
  TEXT_T048,
  TEXT_T049,
  TEXT_T050,
  TEXT_T051,
  TEXT_T052,
  TEXT_T053,
  TEXT_T054,
  TEXT_T055,
  TEXT_T056,
  TEXT_T057,
  TEXT_T058,
  TEXT_T059,
  TEXT_T060,
  TEXT_T061,
  TEXT_T062,
  TEXT_T063,
  TEXT_T064,
  TEXT_T065,
  TEXT_T066,
  TEXT_T075,
  TEXT_T076,
  TEXT_T077,
  TEXT_T078,
  TEXT_T079,
  TEXT_T080,
  TEXT_T081,
  TEXT_T082,
  TEXT_T218,
  TEXT_T246,
  TEXT_T247,
  TEXT_T248,
  TEXT_T249,
  TEXT_T250,
  TEXT_T263,
} = Translate

export const convertDataDetail = (
  data: TravelersDetailDataType,
  dispatchTypeDetailId: string,
  updateDate: string,
  isRoleAdmin: boolean,
  isRoleSubAdmin: boolean,
) => {
  const spouseInfor = [
    { key: TEXT_T246, text: data.othersRemarks1 },
    { key: TEXT_T247, text: data.othersRemarks2 },
    { key: TEXT_T248, text: data.othersRemarks3 },
    { key: TEXT_T249, text: data.othersRemarks4 },
    { key: TEXT_T250, text: data.othersRemarks5 },
  ]

  if (isRoleAdmin || isRoleSubAdmin) {
    spouseInfor.unshift.apply(spouseInfor, [
      { key: TEXT_T075, text: data.othersDispatchNumber },
      { key: TEXT_T076, text: data.othersStaffNumber },
      { key: TEXT_T077, text: data.othersMemberNumber },
    ])

    spouseInfor.push.apply(spouseInfor, [
      { key: TEXT_T078, text: data.othersAdministratorRemarks1 },
      { key: TEXT_T079, text: data.othersAdministratorRemarks2 },
      { key: TEXT_T080, text: data.othersAdministratorRemarks3 },
      { key: TEXT_T081, text: data.othersAdministratorRemarks4 },
      { key: TEXT_T082, text: data.othersAdministratorRemarks5 },
    ])
  }
  spouseInfor.push.apply(spouseInfor, [
    { key: TEXT_T263, text: getDateTimeZoneByUserInformation(updateDate) },
  ])

  return {
    assignmentInfor: [
      { key: TEXT_T058, text: data.assigneeInstitutionName },
      { key: TEXT_T059, text: data.assigneePhoneNumber },
      { key: TEXT_T060, text: data.assigneeName },
    ],
    contactJapan: [
      { key: TEXT_T218, text: data.personPrefectureResidenceName },
      { key: TEXT_T046, text: data.personCountryPhoneCode },
      { key: TEXT_T047, text: data.personPhoneNumber },
      { key: TEXT_T017, text: data.personEmailAddress },
      { key: TEXT_T048, text: data.personCompanyPosition },
      { key: TEXT_T049, text: data.personDispatcherName },
    ],
    contactPerson: [
      { key: TEXT_T050, text: data.urgentContactHome },
      { key: TEXT_T051, text: data.urgentContactName },
      { key: TEXT_T052, text: data.urgentAffiliationContact },
      { key: TEXT_T053, text: data.urgentAffiliationContactName },
    ],
    insuranceInfor: [
      { key: TEXT_T061, text: data.insuranceCompanyName },
      { key: TEXT_T062, text: data.insuranceCompanyPhoneNumber },
      { key: TEXT_T063, text: data.insuranceTicketNumber },
      { key: TEXT_T064, text: data.insuranceSonsignor },
    ],
    postingInfor: [
      {
        key: TEXT_T054,
        text: getWorkPlaceLocation(data.workPlaceLocation),
      },
      { key: TEXT_T055, text: data.workPlaceCountryName },
      {
        key: TEXT_T056,
        text:
          data.workPlaceCity === OTHER
            ? concat(OTHER_CITY_SELECT_ITEMS.text)
            : data.workPlaceCityName,
      },
      { key: TEXT_T057, text: data.workPlace },
    ],
    spouseInfor,
    trainingInfor: [
      {
        key: TEXT_T065,
        text: getTrainingType({
          dispatchTypeDetailId: dispatchTypeDetailId,
          trainingType: data.trainingType,
        }),
      },
      {
        key: TEXT_T066,
        text: getDay(data.trainingDate),
      },
    ],
  }
}
