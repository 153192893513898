import { Translate } from "../../../../../../../../constant/Translate"
import { GridItemType } from "../../../../../../../../_webui/layout/components/Grid/models"
import { ScheduleInfoType } from "../../../../../models/addSuccess"

const { TEXT_T199, TEXT_T200, TEXT_T201, TEXT_T236 } = Translate

export const getAssignmentGridConfig = (
  scheduleInfo: ScheduleInfoType,
): GridItemType[] => [
  {
    key: TEXT_T199,
    text: scheduleInfo.projectNameJp,
  },
  {
    key: TEXT_T200,
    text: scheduleInfo.projectNameEn,
  },
  {
    key: TEXT_T201,
    text: scheduleInfo.procurementControlNumber,
  },
  {
    key: TEXT_T236,
    text: scheduleInfo.locationPersonName,
  },
]
