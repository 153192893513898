/* eslint-disable max-lines */
import { RadioChangeEvent } from "antd"
import { useEffect } from "react"
import { GeneralCode } from "../../../../../../../constant/GeneralCode"
import { Routers } from "../../../../../../../constant/Routers"
import { Translate } from "../../../../../../../constant/Translate"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { generalMastersApi } from "../../../../../../../infrastructure/handlers/generalMasters"
import { GeneralMastersResType } from "../../../../../../../infrastructure/handlers/generalMasters/models"
import keycloak from "../../../../../../../utils/keycloak"
import { usersApi } from "../../../../apis"
import { UseRulesType } from "../../../../models/rules"
import { useVariables } from "../useVariables"
import { convertDataGeneralMasters } from "./convertDataGeneralMasters"
import { Environment } from "../../../../../../../constant/Environment"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { clearLocalStorage } from "../../../../../../../utils/clearLocalStorage"

const { URL } = Environment
const { TRMCNT } = GeneralCode
const { SCR915, SCR917, SCR920, SCR973 } = Routers
const { MESSAGE_E0051 } = Translate

export const useRules = (): UseRulesType => {
  const {
    acquisitionInfo,
    isAccept,
    isCommonRefer,
    isHidden,
    isLoading,
    isLoadingLayout,
    othersStaffNumber,
    push,
    setAcquisitionInfo,
    setIsAccept,
    setIsLoading,
    setIsLoadingLayout,
    setValueAccept,
    valueAccept,
  } = useVariables()

  // 「SCR910_ログインの画面」に遷移する。
  const goToLogin = () => {
    keycloak.logout({ redirectUri: `${URL}${SCR920}` })
  }

  // 個人情報の取得及び利用に関する同意を登録する。
  const goToChangePassword = () => {
    setIsLoading(true)
    if (othersStaffNumber) {
      usersApi
        .acceptAgreements()
        .then(() => {
          usersApi
            .updateFistLogin()
            .then(() => {
              // 「SCR920_ホーム画面」に遷移する」に遷移する。
              push(SCR920)
            })
            .catch((error: AxiosErrorType) => {
              clearLocalStorage()
              const state = pushStateError(error)
              push({
                pathname: SCR973,
                state: { ...state, isSideBar: true },
              })
            })
        })
        .catch((error: AxiosErrorType) => {
          // ユーザのアカウントがロックされている場合
          clearLocalStorage()
          const state = pushStateError(error)
          push({
            pathname: SCR973,
            state: { ...state, isSideBar: true },
          })
        })
    } else {
      // 「SCR917_パスワード変更画面」に遷移する。
      push({ pathname: SCR917, state: { sourceURL: SCR915 } })
    }
  }

  // SCR915_0001
  const onChange = (event: RadioChangeEvent) => {
    setValueAccept(event.target.value)
    setIsAccept(event.target.value)
  }

  const getGeneralMaster = () => {
    generalMastersApi
      .getGeneralMaster(TRMCNT)
      .then((res: GeneralMastersResType[]) => {
        const _acquisitionInfo = convertDataGeneralMasters(res)
        setAcquisitionInfo(_acquisitionInfo)
        setIsLoadingLayout(false)
      })
      .catch((error: AxiosErrorType) => {
        const state = pushStateError(error)
        push({
          pathname: SCR973,
          state: { ...state, isSideBar: true },
        })
      })
  }

  useEffect(() => {
    // ユーザーの画面アクセス許可チェックを処理する。
    if (!isCommonRefer) {
      if (keycloak?.idTokenParsed?.locale !== "ja") {
        console.log(keycloak?.idTokenParsed?.locale)
        window.location.reload()
        alert(MESSAGE_E0051)
        keycloak.logout()
      } else {
        alert(MESSAGE_E0051)
        keycloak.logout()
      }
    } else getGeneralMaster()
  }, [])

  return {
    acquisitionInfo,
    goToChangePassword,
    goToLogin,
    isAccept,
    isHidden,
    isLoading,
    isLoadingLayout,
    onChange,
    valueAccept,
  }
}
