import { FC, Fragment } from "react"
import { ItemInput } from "../../../../../../../_webui/layout/components/Form/Item/Input"
import { Translate } from "../../../../../../../constant/Translate"
import { validatorAlphanumeric } from "../../../../../../../utils/validator/alphanumeric"
import { validatorHalfSize } from "../../../../../../../utils/validator/halfSize"
import { validatorRequiredField } from "../../../../../../../utils/validator/requiredField"
import { InsurancePropsType } from "../../../../models/edit"
import { requiredField } from "../../../../../../../utils/requiredField"
import { Styled } from "./styled"

const { StyledItemInput } = Styled
const {
  HELP_H0014,
  HELP_H0015,
  HELP_H0016,
  MESSAGE_I0025,
  TEXT_T061,
  TEXT_T062,
  TEXT_T063,
  TEXT_T064,
} = Translate

export const Insurance: FC<InsurancePropsType> = (props) => {
  const { isInsuranceRequired } = props
  const insuranceRequired = requiredField(isInsuranceRequired)

  return (
    <Fragment>
      <StyledItemInput
        extra={isInsuranceRequired ? "" : MESSAGE_I0025}
        label={TEXT_T061}
        maxLength={100}
        name="insuranceCompanyName"
        required
        rules={[validatorRequiredField]}
        tooltip={HELP_H0014}
      />
      <ItemInput
        label={TEXT_T062}
        maxLength={20}
        name="insuranceCompanyPhoneNumber"
        required={isInsuranceRequired}
        tooltip={HELP_H0015}
        rules={[...insuranceRequired, validatorHalfSize]}
      />
      <ItemInput
        label={TEXT_T063}
        maxLength={25}
        name="insuranceTicketNumber"
        required={isInsuranceRequired}
        rules={[...insuranceRequired, validatorAlphanumeric]}
      />
      <ItemInput
        className="mt-50 mb-50"
        label={TEXT_T064}
        maxLength={100}
        name="insuranceSonsignor"
        required={isInsuranceRequired}
        rules={insuranceRequired}
        tooltip={HELP_H0016}
      />
    </Fragment>
  )
}
