import { Form } from "antd"
import { useHistory, useLocation } from "react-router-dom"
import {
  AttentionsListSearchConditionType,
  AttentionsListState,
  AttentionsListType,
} from "../../../../models/list"
import { Routers } from "../../../../../../../constant/Routers"
import { useState } from "react"
import { useModal } from "../../../../../../../utils/hook/useModal"
import { useDatePicker } from "../../../../../../../utils/hook/useDatePicker"

const { SCR316_HISTORY } = Routers

export const useVariablesForm = () => {
  const [form] = Form.useForm()
  const { push } = useHistory()
  const { state, pathname } = useLocation<AttentionsListState>()
  const { isOpen, onCancel, onOpen } = useModal()
  const { onBlur, onKeyDown } = useDatePicker({ form })
  const [attentionInfoList, setAttentionInfoList] = useState<
    AttentionsListType[]
  >([])
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [searchCondition, setSearchCondition] =
    useState<AttentionsListSearchConditionType>(state?.searchCondition)
  const isFromHistoryMenu = pathname === SCR316_HISTORY

  return {
    attentionInfoList,
    form,
    isFromHistoryMenu,
    isLoading,
    isLoadingLayout,
    isOpen,
    onBlur,
    onCancel,
    onKeyDown,
    onOpen,
    push,
    searchCondition,
    setAttentionInfoList,
    setIsLoading,
    setIsLoadingLayout,
    setSearchCondition,
  }
}
