import { FC } from "react"
import { Alert } from "../../../../../_webui/layout/components/Alert"
import { ButtonGroup } from "../../../../../_webui/layout/components/Button/Group"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { BreakText } from "../../../../../_webui/layout/components/Typography/BreakText"
import { Translate } from "../../../../../constant/Translate"
import { useSchedulesDeleteSuccess } from "./modules/useDeleteSuccess"

const { TEXT_T299, TEXT_T300, TEXT_T016, TEXT_T291 } = Translate

export const SchedulesDeleteSuccess: FC = () => {
  const {
    breadcrumbItems,
    goToSchedules,
    isLoadingLayout,
    isVisibleBtnSearch,
  } = useSchedulesDeleteSuccess()

  return (
    <Layout
      breadcrumbItems={breadcrumbItems}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T299}
    >
      <Card heading={TEXT_T299}>
        <Alert
          type="success"
          content={<BreakText above={TEXT_T300} below={TEXT_T016} />}
        />
        <ButtonGroup
          buttonItems={[
            {
              absolutePosition: "right",
              isAbsolute: true,
              isHidden: !isVisibleBtnSearch,
              onClick: goToSchedules,
              order: 1,
              text: TEXT_T291,
            },
          ]}
        />
      </Card>
    </Layout>
  )
}
