import { Translate } from "../../../../../../../constant/Translate"
import { getDateTimeZoneByUserInformation } from "../../../../../../../utils/getDate"
import { getSafetyStatus } from "../../../../../../../utils/getSafetyStatus"
import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../_webui/layout/components/Table/models"
import { SafetiesListType } from "../../../../models/list"
import { getValueByLanguage } from "../../../../../../../utils/getValueByLanguage"
import { concat } from "../../../../../../../utils/concat"
import { GeneralCode } from "../../../../../../../constant/GeneralCode"
import { BreakText } from "../../../../../../../_webui/layout/components/Typography/BreakText"
import { getFullName } from "../../../../../../../utils/getFullName"

const { TEXT_T009, TEXT_T151, TEXT_T315, TEXT_T333, TEXT_T312, TEXT_T179 } =
  Translate
const { CTGR_OTHER } = GeneralCode

export const useTableConfig = (): UseTableConfigType<SafetiesListType> => {
  const columns: ColumnType<SafetiesListType>[] = [
    {
      dataIndex: "implementationDate",
      render: (_, data) =>
        getDateTimeZoneByUserInformation(data.implementationDate),
      sorter: (a, b) =>
        a.implementationDate.localeCompare(b.implementationDate),
      title: TEXT_T333,
      width: 250,
    },
    {
      dataIndex: "bumonName",
      sorter: (a, b) => a.bumonName.localeCompare(b.bumonName),
      title: TEXT_T009,
      width: 300,
    },
    {
      dataIndex: "safetyTitle",
      sorter: (a, b) => a.safetyTitle.localeCompare(b.safetyTitle),
      title: TEXT_T315,
      width: 300,
    },
    {
      dataIndex: "statusId",
      /* 0 open 1 close */
      render: (_, data) => getSafetyStatus(data.statusId),
      sorter: (a, b) => a.statusId.localeCompare(b.statusId),
      title: TEXT_T179,
      width: 200,
    },
    {
      dataIndex: "category",
      render: (_, data) =>
        data.categoryId === CTGR_OTHER
          ? getValueByLanguage(
              `${data.categoryName}＿${data.categoryValue}`,
              `${data.categoryName}_${data.categoryValue}`,
            ).toString()
          : data.categoryName,
      sorter: (a, b) =>
        concat(a.categoryName, a.categoryValue).localeCompare(
          concat(b.categoryName, b.categoryValue),
        ),
      title: TEXT_T312,
      width: 200,
    },
    {
      dataIndex: "fullName",
      render: (_, data) => (
        <BreakText
          above={getFullName(data.kanjiSurname, data.kanjiName, true)}
          below={getFullName(data.romajiSurname, data.romajiName)}
        />
      ),
      sorter: (a, b) =>
        concat(a.romajiSurname, a.romajiName).localeCompare(
          concat(b.romajiSurname, b.romajiName),
        ),
      title: TEXT_T151,
      width: 200,
    },
  ]
  return {
    columns,
  }
}
