import { useHistory } from "react-router-dom"
import { FilesListDataSourceType } from "../../../../../models/files/list"
import { Moment } from "moment"
import { useState } from "react"
import { useRowSelection } from "../useRowSelection"
import { Form } from "antd"

export const useVariablesApi = () => {
  const [form] = Form.useForm()
  const { push } = useHistory()
  const [fileInfoList, setFileInfoList] = useState<FilesListDataSourceType[]>(
    [],
  )
  const [initDateTime, setInitDateTime] = useState<Moment>()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)

  const {
    isDisabledBtnDownload,
    isDisabledBtnPreview,
    onChangePagination,
    onChangeTable,
    perItem,
    rowSelection,
    selectedRowKeys,
    setIsDisabledBtnDownload,
    setIsDisabledBtnPreview,
    setSelectedRowKeys,
  } = useRowSelection(fileInfoList)

  return {
    fileInfoList,
    form,
    initDateTime,
    isDisabledBtnDownload,
    isDisabledBtnPreview,
    isLoading,
    isLoadingLayout,
    onChangePagination,
    onChangeTable,
    perItem,
    push,
    rowSelection,
    selectedRowKeys,
    setFileInfoList,
    setInitDateTime,
    setIsDisabledBtnDownload,
    setIsDisabledBtnPreview,
    setIsLoading,
    setIsLoadingLayout,
    setSelectedRowKeys,
  }
}
