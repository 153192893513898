import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { GridItemType } from "../../../../../../../_webui/layout/components/Grid/models"
import { Routers } from "../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { travelerApi } from "../../../../../travelers/apis"
import { InitialValues } from "../../../../constants/addSuccess/initialValues"
import {
  TravelerInfoResponseType,
  UseGridConfigsProps,
} from "../../../../models/addSuccess"
import { convertDataTravelerInfo } from "./convertDataSource"
import { getAssignmentGridConfig } from "./getAssignmentGridConfig"
import { getConfirmGridConfig } from "./getConfirmGridConfig"
import { getInsuranceGridConfig } from "./getInsuranceGridConfig"
import { getOtherGridConfig } from "./getOtherGridConfig"
import { getPersonalGridConfig } from "./getPersonalGridConfig"
import { getDetailsGridConfig } from "./getDetailsGridConfig"
import { TravelersDetailResType } from "../../../../../travelers/models/detail"

const { INITIAL_VALUES_TRAVELER_INFO } = InitialValues
const { SCR973 } = Routers

export const useGridConfigs = (props: UseGridConfigsProps) => {
  const { isVisibleOtherRemarks, scheduleInfo, travelerId } = props
  const { push } = useHistory()
  const { pathname } = useLocation()
  const [personalGridItem, setPersonalGridItem] = useState<GridItemType[]>(
    getPersonalGridConfig(INITIAL_VALUES_TRAVELER_INFO),
  )

  useEffect(() => {
    if (travelerId) {
      // APIを呼び出し、渡航者を取得する。
      const getTravelerDetails: Promise<TravelersDetailResType> =
        travelerApi.getTravelerDetails(travelerId)
      const getTravelerBase: Promise<TravelerInfoResponseType> =
        travelerApi.getTravelerBase(travelerId)
      Promise.all([getTravelerDetails, getTravelerBase])
        .then((res) => {
          const { othersStaffNumber } = res[0].detail
          const data = convertDataTravelerInfo(res[1])
          setPersonalGridItem(getPersonalGridConfig(data, othersStaffNumber))
        })
        .catch((error: AxiosErrorType) => {
          const stateError = pushStateError(error)
          push({ pathname: SCR973, state: stateError })
        })
    }
  }, [travelerId])

  const assignmentGridItem = getAssignmentGridConfig(scheduleInfo)
  const detailsGridItem = getDetailsGridConfig(scheduleInfo)
  const insuranceGridItem = getInsuranceGridConfig(scheduleInfo)
  const otherGridItem = getOtherGridConfig(
    pathname.includes("/schedules/detail"),
    isVisibleOtherRemarks,
    scheduleInfo,
  )
  const confirmGridItem = getConfirmGridConfig(scheduleInfo)

  return {
    assignmentGridItem,
    confirmGridItem,
    detailsGridItem,
    insuranceGridItem,
    otherGridItem,
    personalGridItem,
  }
}
