import styled from "styled-components"
import { Colors } from "../../../../../../constant/Styled/Colors"
import { Divider } from "../../../Divider"

const { CARD_DIVIDER } = Colors

const StyledDivider = styled(Divider)`
  background-color: ${CARD_DIVIDER};
`

const StyledCardHeadingText = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin: 0;
`

export const Styled = {
  StyledCardHeadingText,
  StyledDivider,
}
