/* eslint-disable complexity */
import { GeneralCode } from "../../../../../../../../../../constant/GeneralCode"
import { SelectItems } from "../../../../../../../../../../constant/SelectItems"
import { Translate } from "../../../../../../../../../../constant/Translate"
import { concat } from "../../../../../../../../../../utils/concat"
import { getWorkPlaceLocation } from "../../../../../../../../../../utils/getWorkPlaceLocation"
import { TravelersEditSuccessDetailType } from "../../../../../../../models/editSuccess"

const { TEXT_T054, TEXT_T055, TEXT_T056, TEXT_T057 } = Translate
const { OTHER } = GeneralCode
const { OTHER_CITY_SELECT_ITEMS } = SelectItems

export const getPostingInfor = (data: TravelersEditSuccessDetailType) => {
  const postingInfor = [
    {
      key: TEXT_T054,
      text: getWorkPlaceLocation(data.workPlaceLocation),
    },
  ]

  if (data.workPlaceLocation === 1) {
    postingInfor.push.apply(postingInfor, [
      { key: TEXT_T055, text: data.workPlaceCountryName },
      {
        key: TEXT_T056,
        text:
          data.workPlaceCity === OTHER
            ? concat(OTHER_CITY_SELECT_ITEMS.text)
            : data.workPlaceCityName,
      },
    ])

    if (data.workPlaceCity === OTHER)
      postingInfor.push({ key: TEXT_T057, text: data.workPlace })
  }

  return postingInfor
}
