import { Route, RouteProps } from "react-router-dom"
import keycloak from "../../../utils/keycloak"
import { getConfig } from "../../../_webui/i18n/OjicaI18n"
import { LoginActions } from "../../../constant/Login"
import { Spin } from "../../../_webui/layout/components/Spin"
import { FC } from "react"
import { usePrivateRoute } from "./modules/usePrivateRoute"

const { KEY, LOGIN } = LoginActions

const PrivateRoute: FC<RouteProps> = (props) => {
  const { isAuthenticated, itemKey } = usePrivateRoute()

  if (!isAuthenticated) {
    localStorage.setItem(KEY, LOGIN)
    keycloak.login({ locale: getConfig().selectedLang })
    return null
  }

  const { ...rest } = props

  return itemKey ? <Spin isFixed isLoading /> : <Route {...rest} />
}

export default PrivateRoute
