import { FC, Fragment } from "react"
import { ItemLabelProps } from "./models"
import { Translate } from "../../../../../../constant/Translate"
import { Tooltip } from "../../../Tooltip"
import { Styled } from "./styled"

const { TEXT_T513 } = Translate
const { StyledItemLabel, StyledItemLabelRequired } = Styled

export const ItemLabel: FC<ItemLabelProps> = (props) => {
  const { isBgBlue, isHiddenLabel, label, required, tooltip } = props

  return (
    <Fragment>
      {required ? (
        <Fragment>
          <StyledItemLabel className="form-label" isHiddenLabel={isHiddenLabel}>
            {label}
          </StyledItemLabel>
          <StyledItemLabelRequired isBgBlue={isBgBlue}>
            {TEXT_T513}
          </StyledItemLabelRequired>
          <Tooltip title={tooltip} />
        </Fragment>
      ) : (
        <Fragment>
          <StyledItemLabel className="form-label" isHiddenLabel={isHiddenLabel}>
            {label}
          </StyledItemLabel>
          <Tooltip title={tooltip} />
        </Fragment>
      )}
    </Fragment>
  )
}
