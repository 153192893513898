import { useModal } from "../../../../../../../utils/hook/useModal"

export const useVariablesModal = () => {
  const { isOpen, onCancel, onOpen } = useModal()
  const {
    isOpen: isOpenUploaded,
    onCancel: onCancelUploaded,
    onOpen: onOpenDeleted,
  } = useModal()

  return {
    isOpen,
    isOpenUploaded,
    onCancel,
    onCancelUploaded,
    onOpen,
    onOpenDeleted,
  }
}
