export const getFileSize = (bytes: number, decimals: number) => {
  if (bytes === 0) return "0 Bytes"

  const kb = 1024
  const _decimals = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"]
  const i = Math.floor(Math.log(bytes) / Math.log(kb))

  return (bytes / Math.pow(kb, i)).toFixed(_decimals) + " " + sizes[i]
}
