/* eslint-disable max-lines */
import { Translate } from "../../Translate"

const {
  TEXT_T021, // 渡航者情報の一覧
  TEXT_T119, // 作成・発信
  TEXT_T147, // 登録
  TEXT_T156, // タイムゾーンの設定
  TEXT_T162, // パスワードの変更
  TEXT_T190, // 渡航予定情報の一覧
  TEXT_T226, // 作成・発出
  TEXT_T228, // 設定
  TEXT_T271, // 渡航予定情報の登録
  TEXT_T290, // 申請書
  TEXT_T296, // テンプレート
  TEXT_T297, // テンプレートの一覧
  TEXT_T298, // 登録申請
  TEXT_T302, // 登録申請の一覧
  TEXT_T303, // 変更申請
  TEXT_T307, // 注意喚起の内容確認
  TEXT_T324, // 変更申請の一覧
  TEXT_T343, // 権限パターン
  TEXT_T345, // 権限パターンの一覧
  TEXT_T347, // サマータイム
  TEXT_T350, // サマータイムの一覧
  TEXT_T358, // 安否確認の内容確認
  TEXT_T363, // ファイル管理
  TEXT_T384, // 登録確認
  TEXT_T388, // 機能限定管理者の設定
  TEXT_T397, // 機能限定管理者の一覧
  TEXT_T398, // 権限メンテナンス
  TEXT_T399, // 権限メンテナンス設定の完了
  TEXT_T408, // 安全管理情報
  TEXT_T409, // 国別安全対策措置等
  TEXT_T434, // パスワード変更
  TEXT_T440, // 外部システム連携
  TEXT_T443, // アップロード結果確認
  TEXT_T444, // タイムゾーン設定
  TEXT_T448, // タイムゾーンの設定完了
  TEXT_T450, // 機能限定管理者設定
  TEXT_T455, // 機能限定管理者一覧
  TEXT_T457, // 能限定管理者設定の完了
  TEXT_T463, // 権限メンテナンス
  TEXT_T469, // 注意喚起
  TEXT_T470, // 安否確認
  TEXT_T486, // 渡航予定情報の複製
  TEXT_T493, // 注意喚起の複製
  TEXT_T495, // 安否確認の複製
  TEXT_T497, // アップロードファイル管理
  TEXT_T506, // ファイルアップロード
  TEXT_T509, // 安全対策措置の一覧
  TEXT_T519, // ホーム
  TEXT_T586, // 渡航スケジュール
  TEXT_T587, // 管理者メニュー
  TEXT_T591, // 新規予定登録
  TEXT_T621, // 外部システム連携ファイルアップロード
  TEXT_T630, // 外部システム連携アップロード結果確認
  TEXT_T670, // お問い合わせ
  TEXT_T680, // お問い合わせ送信の完了
  TEXT_T683, // システム関連
  TEXT_T684, // システムマニュアル
  TEXT_T685, // ユーザマニュアル
  TEXT_T687, // 機能限定管理者マニュアル
  TEXT_T688, // FAQ・よくある質問
  TEXT_T689, // お問い合わせフォーム
  TEXT_T690, // 安全対策ハンドブック
  TEXT_T691, // ユーザ向けマニュアルの一覧
  TEXT_T692, // 機能限定管理者マニュアルの一覧
  TEXT_T693, // FAQ・よくある質問の一覧
  TEXT_T694, // 安全対策のハンドブックの一覧
  TEXT_T695, // プライバシーポリシー（利用規約含む）
  TEXT_T696, // プライバシーポリシー
} = Translate

const BRCR_SCR917 = [TEXT_T519, TEXT_T228, TEXT_T434, TEXT_T162]
const BRCR_SCR930 = [TEXT_T519, TEXT_T228, TEXT_T444, TEXT_T156]
const BRCR_SCR931 = [TEXT_T519, TEXT_T228, TEXT_T444, TEXT_T156, TEXT_T448]
const BRCR_SCR950 = [TEXT_T519, TEXT_T228, TEXT_T450, TEXT_T388]
const BRCR_SCR951 = [TEXT_T519, TEXT_T228, TEXT_T450, TEXT_T388, TEXT_T457]
const BRCR_SCR952 = [TEXT_T519, TEXT_T228, TEXT_T455, TEXT_T397]
const BRCR_SCR953 = [TEXT_T519, TEXT_T228, TEXT_T463, TEXT_T398]
const BRCR_SCR953_FROM_SCR950 = [
  TEXT_T519,
  TEXT_T228,
  TEXT_T450,
  TEXT_T388,
  TEXT_T398,
]
const BRCR_SCR953_FROM_SCR951 = [
  TEXT_T519,
  TEXT_T228,
  TEXT_T450,
  TEXT_T388,
  TEXT_T457,
  TEXT_T398,
]
const BRCR_SCR953_FROM_SCR952 = [
  TEXT_T519,
  TEXT_T228,
  TEXT_T455,
  TEXT_T397,
  TEXT_T398,
]
const BRCR_SCR954 = [TEXT_T519, TEXT_T228, TEXT_T463, TEXT_T398, TEXT_T399]
const BRCR_SCR960 = [TEXT_T486]
const BRCR_SCR960_FROM_SCR212 = [
  TEXT_T519,
  TEXT_T586,
  TEXT_T147,
  TEXT_T271,
  TEXT_T486,
]
const BRCR_SCR960_FROM_SCR212_USER = [
  TEXT_T519,
  TEXT_T587,
  TEXT_T591,
  TEXT_T021,
  TEXT_T271,
  TEXT_T486,
]
const BRCR_SCR960_FROM_SCR210_USER_SEARCH = [
  TEXT_T519,
  TEXT_T384,
  TEXT_T190,
  TEXT_T271,
  TEXT_T486,
]
const BRCR_SCR961 = [
  TEXT_T519,
  TEXT_T469,
  TEXT_T119,
  TEXT_T190,
  TEXT_T307,
  TEXT_T493,
]
const BRCR_SCR962 = [
  TEXT_T519,
  TEXT_T470,
  TEXT_T226,
  TEXT_T190,
  TEXT_T358,
  TEXT_T495,
]
const BRCR_SCR970 = [TEXT_T519, TEXT_T363, TEXT_T506, TEXT_T497]
const BRCR_SCR971 = [TEXT_T519, TEXT_T506, TEXT_T497, TEXT_T506]
const BRCR_SCR972 = [TEXT_T519, TEXT_T363, TEXT_T408, TEXT_T409, TEXT_T509]
const BRCR_SCR980 = [TEXT_T519, TEXT_T440, TEXT_T506, TEXT_T621]
const BRCR_SCR981 = [TEXT_T519, TEXT_T440, TEXT_T443, TEXT_T630]
const BRCR_SCR991_FAQ = [TEXT_T519, TEXT_T688, TEXT_T693]
const BRCR_SCR991_SAFETY_MEASURES = [TEXT_T519, TEXT_T408, TEXT_T690, TEXT_T694]
const BRCR_SCR991_SYSTEM_MANUAL = [
  TEXT_T519,
  TEXT_T683,
  TEXT_T684,
  TEXT_T687,
  TEXT_T692,
]
const BRCR_SCR991_USER = [TEXT_T519, TEXT_T683, TEXT_T684, TEXT_T685, TEXT_T691]
const BRCR_SCR991_TEMPLATE = [TEXT_T519, TEXT_T290, TEXT_T296, TEXT_T297]
const BRCR_SCR991_REGISTRATION_APPLICATION_FORM = [
  TEXT_T519,
  TEXT_T290,
  TEXT_T298,
  TEXT_T302,
]
const BRCR_SCR991_CHANGE_APPLICATION_FORM = [
  TEXT_T519,
  TEXT_T290,
  TEXT_T303,
  TEXT_T324,
]
const BRCR_SCR991_AUTHORITY_PATTERN = [
  TEXT_T519,
  TEXT_T290,
  TEXT_T343,
  TEXT_T345,
]
const BRCR_SCR991_SUMMER_TIME = [TEXT_T519, TEXT_T290, TEXT_T347, TEXT_T350]
const BRCR_SCR992 = [TEXT_T519, TEXT_T670, TEXT_T689]
const BRCR_SCR993 = [TEXT_T519, TEXT_T670, TEXT_T689, TEXT_T680]
const BRCR_SCR994 = [TEXT_T519, TEXT_T683, TEXT_T695, TEXT_T696]

export const BreadcrumbsCommon = {
  BRCR_SCR917,
  BRCR_SCR930,
  BRCR_SCR931,
  BRCR_SCR950,
  BRCR_SCR951,
  BRCR_SCR952,
  BRCR_SCR953,
  BRCR_SCR953_FROM_SCR950,
  BRCR_SCR953_FROM_SCR951,
  BRCR_SCR953_FROM_SCR952,
  BRCR_SCR954,
  BRCR_SCR960,
  BRCR_SCR960_FROM_SCR210_USER_SEARCH,
  BRCR_SCR960_FROM_SCR212,
  BRCR_SCR960_FROM_SCR212_USER,
  BRCR_SCR961,
  BRCR_SCR962,
  BRCR_SCR970,
  BRCR_SCR971,
  BRCR_SCR972,
  BRCR_SCR980,
  BRCR_SCR981,
  BRCR_SCR991_AUTHORITY_PATTERN,
  BRCR_SCR991_CHANGE_APPLICATION_FORM,
  BRCR_SCR991_FAQ,
  BRCR_SCR991_REGISTRATION_APPLICATION_FORM,
  BRCR_SCR991_SAFETY_MEASURES,
  BRCR_SCR991_SUMMER_TIME,
  BRCR_SCR991_SYSTEM_MANUAL,
  BRCR_SCR991_TEMPLATE,
  BRCR_SCR991_USER,
  BRCR_SCR992,
  BRCR_SCR993,
  BRCR_SCR994,
}
