import { OptionItemType } from "../../_webui/layout/components/Select/models"
import { SelectItems } from "../../constant/SelectItems"
import { concat } from "../concat"

const { OTHER_CITY_SELECT_ITEMS } = SelectItems

export const getTextByKey = (value: string, data?: OptionItemType[]) => {
  const _text = data?.find((item) => item.value === value)?.text

  if (!_text) {
    return value === OTHER_CITY_SELECT_ITEMS.value
      ? concat(OTHER_CITY_SELECT_ITEMS.text)
      : ""
  }

  return _text
}
