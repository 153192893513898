/* eslint-disable max-lines */
import { useVariables } from "../useVariables"
import { useEffect } from "react"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { UseAttentionType } from "../../../../models/attention"
import { attentionApi } from "../../../../apis"
import { getSafetiesSelectedValue } from "../useApi/getSafetiesSelectedValue"
import { AttentionDataTableType } from "../../../../components/attention/Form/models"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { Routers } from "../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"

const { SCR973 } = Routers

export const useAttention = (): UseAttentionType => {
  const {
    columns,
    form,
    gridItems,
    isAttentionsSendAll,
    isDisabledBtnSelect,
    isDisabledSubmit,
    isHiddenBackBtn,
    isHiddenCopyBtn,
    isHiddenResetBtn,
    isHiddenSubmitBtn,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenForm,
    onAddOrRemoveSafeties,
    onCancel,
    onCancelForm,
    onCopy,
    onEdit,
    onFinish,
    onGoBack,
    onOpen,
    onOpenForm,
    onReset,
    onSend,
    push,
    rowSelectionAttention,
    safetiesColumns,
    safetiesList,
    safetiesSelected,
    setIsLoadingLayout,
    setSafetiesSelected,
    setSelectedKeys,
    setTravelerInfoList,
    state,
    travelerInfoList,
  } = useVariables()

  const getSafeties = () => {
    if (state?.selectedKeys?.length) {
      attentionApi
        .getSafeties()
        .then((res: AttentionDataTableType[]) => {
          setIsLoadingLayout(false)
          const { safetiesSelected: _safetiesSelected } =
            getSafetiesSelectedValue(res, state.selectedKeys)
          setSelectedKeys(state.selectedKeys)
          setSafetiesSelected(_safetiesSelected)
        })
        .catch((error: AxiosErrorType) => {
          // 「SCR973_エラー情報の表示画面」に遷移する。
          const stateError = pushStateError(error)
          push({ pathname: SCR973, state: stateError })
        })
    } else {
      setTimeout(() => {
        setIsLoadingLayout(false)
      }, 500)
    }
  }

  useEffect(() => {
    // ユーザの画面アクセス許可チェックを処理する。
    if (!isAttentionsSendAll) {
      // 「SCR973_エラー情報の表示画面」に遷移する。
      const { pathname, state } = backToLogin()
      push({ pathname, state })
    } else {
      if (state?.travelerInfoList) setTravelerInfoList(state.travelerInfoList)
      getSafeties()
    }
  }, [])

  return {
    columns,
    form,
    gridItems,
    isDisabledBtnSelect,
    isDisabledSubmit,
    isHiddenBackBtn,
    isHiddenCopyBtn,
    isHiddenResetBtn,
    isHiddenSubmitBtn,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenForm,
    onAddOrRemoveSafeties,
    onCancel,
    onCancelForm,
    onCopy,
    onEdit,
    onFinish,
    onGoBack,
    onOpen,
    onOpenForm,
    onReset,
    onSend,
    rowSelectionAttention,
    safetiesColumns,
    safetiesList,
    safetiesSelected,
    travelerInfoList,
  }
}
