import { Translate } from "../../../../../../../constant/Translate"
import { exportCsv } from "../../../../../../../utils/exportCsv"
import { TableErrorRowType } from "../../models"

const { TEXT_T599, TEXT_T600 } = Translate

const handleDecoder = (errorList: TableErrorRowType[]) => {
  const dataCSV = errorList
  let csvContent = `${TEXT_T599},${TEXT_T600}`

  // Convert errors array to a string of comma-separated values
  dataCSV.forEach((error) => {
    const row = `\r\n${error.line},${error.detail}`
    csvContent += row
  })

  // Convert content from ASCII to UTF-8
  csvContent = encodeURIComponent(csvContent)
  csvContent = decodeURIComponent(csvContent)

  return csvContent
}

export const handleExportCSV = (errorList: TableErrorRowType[]) => {
  const convertData = handleDecoder(errorList)

  exportCsv(convertData, "ログ")
}
