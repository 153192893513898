/* eslint-disable complexity */
import { ChangeEvent, FocusEvent, useState } from "react"
import { DefaultOptionType } from "../../../Form/Item/Select/models"
import { UseShowCountProps } from "../../models"

export const useShowCount = (props: UseShowCountProps) => {
  const { maxLength, onChange2, onClear2, options } = props

  const [countValue, setCountValue] = useState(maxLength || 0)
  const [value, setValue] = useState("")
  const [isFocus, setIsFocus] = useState(false)
  const [optionsNew, setOptionsNew] = useState<DefaultOptionType[]>([])

  const onFocus = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (maxLength) {
      const count = maxLength - event.target.value.length
      setCountValue(count >= 0 ? count : 0)
    }
    setIsFocus(true)
  }

  const onBlur = () => {
    setIsFocus(false)
  }

  const onChange = (_value: string | ChangeEvent<HTMLTextAreaElement>) => {
    if (maxLength) {
      const length =
        typeof _value === "string"
          ? _value?.length
          : _value?.target?.value?.length
      const count = maxLength - length
      setCountValue(count >= 0 ? count : 0)
    }
    const currentValue =
      typeof _value === "string" ? _value : _value?.target?.value
    setValue(currentValue)
  }

  const onChangeAutoComplete = (
    _value: string,
    option: DefaultOptionType | DefaultOptionType[],
  ) => {
    onChange(_value)
    onChange2 && onChange2(_value, option)
  }

  const onClear = (clearIndex?: number) => {
    setValue("")
    setCountValue(maxLength || 0)

    if (onClear2 && typeof clearIndex === "number") onClear2(clearIndex)
  }

  const onSearch = (value: string) => {
    if (!value) setOptionsNew([])
    else if (options) {
      const _options = options.map((item) => ({
        ...item,
        value: item.label,
      }))

      setOptionsNew(_options)
    }
  }

  return {
    countValue,
    isFocus,
    onBlur,
    onChange,
    onChangeAutoComplete,
    onClear,
    onFocus,
    onSearch,
    optionsNew,
    value,
  }
}
