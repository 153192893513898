import { concat } from "../../../../../../../../utils/concat"
import {
  GetSchedulesAddStaysSubmitProps,
  SchedulesAddStaysAddType,
} from "../../../../../models/add/DataAdd"

export const getSchedulesAddStaysSubmit = (
  data: GetSchedulesAddStaysSubmitProps,
): SchedulesAddStaysAddType => {
  const { id, isSchedulesAdd, isSchedulesEdit, item } = data
  const itemNew = { ...item }

  if (isSchedulesAdd) delete item["travelStayId"]
  else if (isSchedulesEdit) concat(id)
  if (!isSchedulesEdit) delete item["travelStayId"]

  return itemNew
}
