import { useState } from "react"
import { useHistory } from "react-router-dom"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { useApi } from "../useApi"

export const useVariables = () => {
  const { push } = useHistory()

  const [isLoading, setIsLoading] = useState(false)
  const { isCommonRefer, isHiddenReset, isHiddenSubmit } =
    useVariablesAuthority()

  const { callApi, form, isLoadingLayout, timeFormatItems, timeZoneItems } =
    useApi()

  return {
    callApi,
    form,
    isCommonRefer,
    isHiddenReset,
    isHiddenSubmit,
    isLoading,
    isLoadingLayout,
    push,
    setIsLoading,
    timeFormatItems,
    timeZoneItems,
  }
}
