import { FC } from "react"
import { HeadingProps } from "./models"
import { Styled } from "./styled"

const { StyledHeading } = Styled

export const Heading: FC<HeadingProps> = (props) => {
  const { className, style, title, type = "primary" } = props

  return (
    <StyledHeading className={className} style={style} type={type}>
      {title}
    </StyledHeading>
  )
}
