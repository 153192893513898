import { Breadcrumbs } from "../../../../../../../../constant/Breadcrumbs"
import { Routers } from "../../../../../../../../constant/Routers"
import { replaceString } from "../../../../../../../../utils/replaceString"

const { SCR212, SCR212_USER } = Routers
const { BRCR_SCR212_USER, BRCR_SCR212, BRCR_SCR212_FROM_210_USER_SEARCH } =
  Breadcrumbs

export const getBreadcrumbItems = (
  id: string,
  isFromSCR114UserSearch: boolean,
  pathname: string,
) => {
  if (pathname === replaceString(SCR212, id)) return BRCR_SCR212

  if (pathname === replaceString(SCR212_USER, id))
    return isFromSCR114UserSearch
      ? BRCR_SCR212_FROM_210_USER_SEARCH
      : BRCR_SCR212_USER
}
