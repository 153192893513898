import { FC } from "react"
import { AttentionFormProps } from "./models"
import { Form } from "../../../../../../_webui/layout/components/Form"
import { ItemInputGroup } from "../../../../../../_webui/layout/components/Form/Item/Input/Group"
import { InputItems } from "../../../../../../constant/InputItems"
import { Translate } from "../../../../../../constant/Translate"
import { Button } from "../../../../../../_webui/layout/components/Button"
import { Table } from "../../../../../../_webui/layout/components/Table"
import { Modal } from "../../../../../../_webui/layout/components/Modal"
import { Card } from "../../../../../../_webui/layout/components/Card"
import { ButtonGroup } from "../../../../../../_webui/layout/components/Button/Group"
import { Title } from "../../../../../../_webui/layout/components/Typography/Title"
import { Styled } from "./styled"

const { ROMAJI_FULL_NAME } = InputItems
const { StyledFrame } = Styled
const {
  TEXT_T006,
  TEXT_T012,
  TEXT_T022,
  TEXT_T233,
  TEXT_T269,
  TEXT_T275,
  TEXT_T242,
} = Translate

export const AttentionForm: FC<AttentionFormProps> = (props) => {
  const {
    form,
    isDisabledBtnSelect,
    isOpenForm,
    onAddOrRemoveSafeties,
    onCancelForm,
    onFinish,
    rowSelectionAttention,
    safetiesColumns,
    safetiesList,
  } = props
  return (
    <Modal
      isForm
      isOpen={isOpenForm}
      isShowFooter={false}
      onCancelCustom={onCancelForm}
    >
      <Card heading={TEXT_T233}>
        <StyledFrame>
          <Form
            form={form}
            isCard={false}
            isHeading={false}
            onFinish={onFinish}
          >
            <ItemInputGroup
              inputItems={ROMAJI_FULL_NAME}
              label={TEXT_T006}
              name="fullNameRomaji"
            />
            <Button htmlType="submit" text={TEXT_T022} type="primary" />
          </Form>
        </StyledFrame>
        <Title titleLeft={TEXT_T242} />
        <Table
          columns={safetiesColumns}
          dataSource={safetiesList}
          pagination={false}
          rowSelection={rowSelectionAttention}
          rowKey="userId"
        />
        <ButtonGroup
          buttonItems={[
            {
              onClick: onCancelForm,
              order: 3,
              text: TEXT_T012,
              type: "ghost",
            },
            {
              isDisabled: isDisabledBtnSelect,
              onClick: () => onAddOrRemoveSafeties(true),
              order: 1,
              text: TEXT_T269,
              type: "primary",
            },
            {
              isDisabled: isDisabledBtnSelect,
              onClick: () => onAddOrRemoveSafeties(false),
              order: 2,
              text: TEXT_T275,
              type: "danger",
            },
          ]}
        />
      </Card>
    </Modal>
  )
}
