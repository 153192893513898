import { FC } from "react"
import { Alert } from "../../../../../_webui/layout/components/Alert"
import { ButtonGroup } from "../../../../../_webui/layout/components/Button/Group"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { BreakText } from "../../../../../_webui/layout/components/Typography/BreakText"
import { Translate } from "../../../../../constant/Translate"
import { ScheduleAddSuccessCollapse } from "../../components/addSuccess/ContentCollapse"
import { useSchedulesEditSuccess } from "./modules/useEditSuccess"

const { TEXT_T016, TEXT_T020, TEXT_T291, TEXT_T293, TEXT_T294 } = Translate

export const SchedulesEditSuccess: FC = () => {
  const {
    breadcrumbItems,
    goToSchedules,
    goToSchedulesEdit,
    isLoadingLayout,
    isVisibleBtnEdit,
    isVisibleBtnSearch,
    ...rest
  } = useSchedulesEditSuccess()

  return (
    <Layout
      breadcrumbItems={breadcrumbItems}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T293}
    >
      <Card heading={TEXT_T293}>
        <Alert
          type="success"
          content={<BreakText above={TEXT_T294} below={TEXT_T016} />}
        />

        <ScheduleAddSuccessCollapse {...rest} />

        <ButtonGroup
          buttonItems={[
            {
              isHidden: !isVisibleBtnEdit,
              onClick: goToSchedulesEdit,
              text: TEXT_T020,
              type: "primary",
            },
            {
              absolutePosition: "right",
              isAbsolute: true,
              isHidden: !isVisibleBtnSearch,
              onClick: goToSchedules,
              text: TEXT_T291,
            },
          ]}
        />
      </Card>
    </Layout>
  )
}
