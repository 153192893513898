import { FC } from "react"
import { Translate } from "../../../../../constant/Translate"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { Alert } from "../../../../../_webui/layout/components/Alert"
import { useError } from "./modules/useError"
import { Card } from "../../../../../_webui/layout/components/Card"
import { PreLine } from "../../../../../_webui/layout/components/Typography/PreLine"

const { TEXT_T094, TEXT_T490 } = Translate

export const Error: FC = () => {
  const { isSideBar, message, title } = useError()

  // エラー情報を画面に表示する。
  return (
    <Layout
      isHeaderRightNone={isSideBar}
      isSideBar={!isSideBar}
      title={TEXT_T094}
    >
      <Card isHeading={false} isBreadcrumbs={false}>
        <Alert
          content={<PreLine>{message}</PreLine>}
          heading={TEXT_T490}
          isIcon
          title={title}
          type="danger"
        />
      </Card>
    </Layout>
  )
}
