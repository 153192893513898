import { Translate } from "../../../../../../../constant/Translate"
import { concat } from "../../../../../../../utils/concat"
import { GridItemType } from "../../../../../../../_webui/layout/components/Grid/models"
import { SafetiesTransmissionInfoType } from "../../../../models/safety"
import { GeneralCode } from "../../../../../../../constant/GeneralCode"
import { PreLine } from "../../../../../../../_webui/layout/components/Typography/PreLine"

const { TEXT_T312, TEXT_T315, TEXT_T316 } = Translate
const { CTGR_OTHER } = GeneralCode

export const useGridConfig = (props: SafetiesTransmissionInfoType) => {
  const gridItems: GridItemType[] = [
    {
      key: TEXT_T312,
      text: `${concat(props?.categoryName)} ${
        props?.categoryId === CTGR_OTHER ? concat(props?.categoryValue) : ""
      }`,
    },
    {
      key: TEXT_T315,
      text: props?.safetyTitle,
    },
    {
      key: TEXT_T316,
      text: <PreLine>{props?.bodyContent}</PreLine>,
    },
  ]
  return { gridItems }
}
