import { OptionItemType } from "../../../../../../_webui/layout/components/Select/models"

const STATUS_LIST_HISTORY: OptionItemType[] = [
  {
    text: "Open",
    value: "0",
  },
  {
    text: "Close",
    value: "1",
  },
]

const STATUS_LIST: OptionItemType[] = [
  {
    text: "Open",
    value: "0",
  },
]

export const Variables = {
  STATUS_LIST,
  STATUS_LIST_HISTORY,
}
