import {
  SafetiesDetailBaseType,
  SafetiesDetailInfoType,
} from "../../../../../models/detail"
import { exportCsvFRM303 } from "./csv"
import { exportExcelFRM303 } from "./excel"

export const convertDataExport = (
  data: SafetiesDetailBaseType[],
  safetyInfo?: SafetiesDetailInfoType,
) => {
  exportCsvFRM303(data, safetyInfo)
  exportExcelFRM303(data, safetyInfo)
}
