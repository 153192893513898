import { useModal } from "../../../../../../../../utils/hook/useModal"

export const useVariablesModal = () => {
  const { isOpen, onCancel, onOpen } = useModal()
  const {
    isOpen: isOpenLink,
    onCancel: onCancelLink,
    onOpen: onOpenLink,
  } = useModal()

  return {
    isOpen,
    isOpenLink,
    onCancel,
    onCancelLink,
    onOpen,
    onOpenLink,
  }
}
