import { Variables } from "../../../../../../constants/filesUpload"

const { SYSTEM_LENGTH } = Variables
const { ODAL_SYSTEM_LENGTH, TOKOUSHA_SYSTEM_LENGTH, TOKOUYOTEI_SYSTEM_LENGTH } =
  SYSTEM_LENGTH

export const getItemLength = (fileName: string) => {
  return fileName.includes("TOKOUSHA")
    ? TOKOUSHA_SYSTEM_LENGTH
    : fileName.includes("ODAL")
    ? ODAL_SYSTEM_LENGTH
    : TOKOUYOTEI_SYSTEM_LENGTH
}
