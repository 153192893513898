import { useHistory, useLocation, useParams } from "react-router-dom"
import { Routers } from "../../../../../../../constant/Routers"
import { replaceString } from "../../../../../../../utils/replaceString"
import { UseParamsType } from "../../../../../../../utils/useParams"
import {
  SchedulesDetailsStateType,
  UseSchedulesDetailRouterProps,
} from "../../../../models/detail"

const { SCR115_USER, SCR210, SCR214, SCR214_USER } = Routers

export const useRouters = (props: UseSchedulesDetailRouterProps) => {
  const { isSubBumon, travelerId } = props
  const { state, pathname } = useLocation<SchedulesDetailsStateType>()
  const { id: travelScheduleId } = useParams<UseParamsType>()
  const { push } = useHistory()

  const onEdit = () => {
    // 「SCR214_渡航予定情報の変更画面」に遷移する。
    const _pathname = state?.schedulesURL === SCR210 ? SCR214 : SCR214_USER
    push({
      pathname: replaceString(_pathname, travelScheduleId),
      state: {
        ...state,
        isFromSCR211: true,
        sourceURL: pathname,
      },
    })
  }

  const onSearch = () => {
    // 「SCR210_渡航予定情報の一覧画面」に遷移する。
    const _state = state?.isFromSCR114UserSearch
      ? {
          isFromSCR114UserSearch: true,
          isFromSCR115: true,
          searchConditionTraveler: state.searchConditionTraveler,
          travelerId: state.travelerId,
        }
      : { ...state, sourceURL: pathname }

    push({
      pathname: state.schedulesURL,
      state: _state,
    })
  }

  const goToTravelerDetail = () => {
    if (travelerId)
      push(replaceString(SCR115_USER, travelerId), {
        ...state,
        isFromSCR211: true,
        isSubBumon,
        travelScheduleId,
      })
  }

  return {
    goToTravelerDetail,
    onEdit,
    onSearch,
  }
}
