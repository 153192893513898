/* eslint-disable complexity */
import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const {
    isCommonTravelerRefer,
    isRoleAdmin,
    isRoleSubAdmin,
    isRoleUser,
    isScheduleDelete,
    isScheduleSearch,
  } = useAuthority()

  const isNotAccessScreen = !isCommonTravelerRefer && !isScheduleDelete

  const isVisibleBtn = isRoleAdmin || isRoleSubAdmin || isRoleUser
  const isVisibleBtnCancel =
    isVisibleBtn && (isCommonTravelerRefer || isScheduleSearch)
  const isVisibleBtnYes =
    isVisibleBtn && (isCommonTravelerRefer || isScheduleDelete)

  return {
    isNotAccessScreen,
    isRoleAdmin,
    isRoleSubAdmin,
    isRoleUser,
    isVisibleBtnCancel,
    isVisibleBtnYes,
  }
}
