import { getTextYesNo } from "../../../../../../../../utils/getTextYesNo"
import { getTrainingTypeExport } from "../../../../../../../../utils/getTrainingType"
import { getWorkPlaceLocation } from "../../../../../../../../utils/getWorkPlaceLocation"
import { joinString } from "../../../../../../../../utils/joinString"
import { TravelersListDataType } from "../../../../../models/list"
import { heading } from "./heading"

export const convertDataExport = (data: TravelersListDataType[]) => {
  let convertData = heading
  data.map((item) => {
    convertData += `\r\n"${item.base.userId}","${item.base.kanjiSurname}","${
      item.base.kanjiName
    }","${item.base.romajiSurname}","${item.base.romajiName}","${getTextYesNo(
      item.base.differentInforCheck,
    )}","${item.base.kanjiPassportSurname}","${item.base.kanjiPassportName}","${
      item.base.romajiPassportSurname
    }","${item.base.romajiPassportName}","${item.base.authorityType}","${
      item.base.patternId
    }","${joinString([item.base.bumonCd, item.base.bumonName])}","${
      item.base.dispatchTypeName
    }","${joinString([
      item.base.dispatchTypeDetailId,
      item.base.dispatchTypeDetailName,
    ])}","${item.base.passportNumber}","${item.familyRomajiSurname}","${
      item.familyRomajiName
    }","${item.familyRelationship}","${item.familyCount}","${joinString([
      item.detail.personPrefectureResidence,
      item.detail.personPrefectureResidenceName,
    ])}","${item.detail.personCountryPhoneCode}","${
      item.detail.personPhoneNumber
    }","${item.detail.personEmailAddress}","${
      item.detail.personCompanyPosition
    }","${item.detail.personDispatcherName}","${
      item.detail.urgentContactHome
    }","${item.detail.urgentContactName}","${
      item.detail.urgentAffiliationContact
    }","${item.detail.urgentAffiliationContactName}","${`${
      item.detail.workPlaceLocation
    }_${getWorkPlaceLocation(item.detail.workPlaceLocation)}`}","${
      item.detail.workPlaceCountryName
    }","${item.detail.workPlaceCountry}","${item.detail.workPlaceCityName}","${
      item.detail.workPlaceCity
    }","${item.detail.workPlace}","${item.detail.assigneeInstitutionName}","${
      item.detail.assigneePhoneNumber
    }","${item.detail.assigneeName}","${item.detail.insuranceCompanyName}","${
      item.detail.insuranceCompanyPhoneNumber
    }","${item.detail.insuranceTicketNumber}","${
      item.detail.insuranceSonsignor
    }","${`${getTrainingTypeExport({
      dispatchTypeDetailId: item.base.dispatchTypeDetailId,
      trainingType: item.detail.trainingType,
    })}`}","${item.detail.trainingDate}","${item.detail.systemIdentifier}","${
      item.detail.othersDispatchNumber
    }","${item.detail.othersStaffNumber}","${
      item.detail.othersMemberNumber
    }","${getTextYesNo(item.detail.usageAgreement)}","${getTextYesNo(
      item.detail.tabirejAgreement,
    )}","${getTextYesNo(item.detail.gdprAgreement)}","${
      item.detail.othersRemarks1
    }","${item.detail.othersRemarks2}","${item.detail.othersRemarks3}","${
      item.detail.othersRemarks4
    }","${item.detail.othersRemarks5}","${
      item.detail.othersAdministratorRemarks1
    }","${item.detail.othersAdministratorRemarks2}","${
      item.detail.othersAdministratorRemarks3
    }","${item.detail.othersAdministratorRemarks4}","${
      item.detail.othersAdministratorRemarks5
    }","${item.detail.timezone}","${item.detail.timeFormat}"`
  })
  return convertData
}
