const SAFETY = "SFTS001"
const MINOR_INJURY = "SFTS002"
const SERIOUS_ILLNESS = "SFTS003"
const NO_RESPONSE = "SFTS004"
const UNDELIVERED_EMAIL = "SFTS005"

export const Variables = {
  MINOR_INJURY,
  NO_RESPONSE,
  SAFETY,
  SERIOUS_ILLNESS,
  UNDELIVERED_EMAIL,
}
