import { Form } from "antd"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import { OptionItemType } from "../../../../../../../../_webui/layout/components/Select/models"

export const useVariablesApi = () => {
  const [form] = Form.useForm()
  const { push } = useHistory()
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [timeZoneItems, setTimeZoneItems] = useState<OptionItemType[]>([])
  const [timeFormatItems, setTimeFormatItems] = useState<OptionItemType[]>([])

  return {
    form,
    isLoadingLayout,
    push,
    setIsLoadingLayout,
    setTimeFormatItems,
    setTimeZoneItems,
    timeFormatItems,
    timeZoneItems,
  }
}
