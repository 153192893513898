import { Translate } from "../../../../../../../../../../../../constant/Translate"
import { Variables } from "../../../../../../../../../constants/detail/variables"
import { SafetiesDetailExcelSheetType } from "../../../../../../../../../models/detail/export"

const { EXPORT_EP0187 } = Translate
const {
  MINOR_INJURY,
  NO_RESPONSE,
  SAFETY,
  SERIOUS_ILLNESS,
  UNDELIVERED_EMAIL,
} = Variables

export const statusesCountHeader = (data: SafetiesDetailExcelSheetType[]) => {
  // SFTS001
  const selfSafetyCount = data.filter(
    (item) => item.safetyStatus === SAFETY && !item.proxyResponser,
  ).length
  // SFTS002
  const selfMinorInjuredCount = data.filter(
    (item) => item.safetyStatus === MINOR_INJURY && !item.proxyResponser,
  ).length
  // SFTS003
  const selfSeriousInjuredCount = data.filter(
    (item) => item.safetyStatus === SERIOUS_ILLNESS && !item.proxyResponser,
  ).length
  // SFTS001
  const proxySafetyCount = data.filter(
    (item) => item.safetyStatus === SAFETY && item.proxyResponser,
  ).length
  // SFTS002
  const proxyMinorInjuredCount = data.filter(
    (item) => item.safetyStatus === MINOR_INJURY && item.proxyResponser,
  ).length
  // SFTS003
  const proxySeriousInjuredCount = data.filter(
    (item) => item.safetyStatus === SERIOUS_ILLNESS && item.proxyResponser,
  ).length
  // SFTS004
  const noresponseCount = data.filter(
    (item) => item.safetyStatus === NO_RESPONSE,
  ).length
  // SFTS005
  const undeliveredCount = data.filter(
    (item) => item.safetyStatus === UNDELIVERED_EMAIL,
  ).length
  // 本人応答あり
  const selfCount =
    selfSafetyCount + selfMinorInjuredCount + selfSeriousInjuredCount
  // 代理応答
  const proxyCount =
    proxySafetyCount + proxyMinorInjuredCount + proxySeriousInjuredCount

  return EXPORT_EP0187.replace("{0}", selfCount.toString())
    .replace("{1}", selfSafetyCount.toString())
    .replace("{2}", selfMinorInjuredCount.toString())
    .replace("{3}", selfSeriousInjuredCount.toString())
    .replace("{4}", proxyCount.toString())
    .replace("{5}", proxySafetyCount.toString())
    .replace("{6}", proxyMinorInjuredCount.toString())
    .replace("{7}", proxySeriousInjuredCount.toString())
    .replace("{8}", noresponseCount.toString())
    .replace("{9}", undeliveredCount.toString())
}
