import { FC } from "react"
import { ItemRadioProps } from "./models"
import { RadioGroup } from "../../../Radio/Group"
import { FormItem } from ".."

export const ItemRadio: FC<ItemRadioProps> = (props) => {
  const {
    defaultValue,
    isBgBlue,
    isHiddenLabel,
    label,
    name,
    onChange,
    radioItems,
    required,
    rules,
    tooltip,
    value,
  } = props
  if (!radioItems?.length) return null

  return (
    <FormItem
      className="ant-form-item-radio"
      isBgBlue={isBgBlue}
      isHiddenLabel={isHiddenLabel}
      label={label}
      name={name}
      required={required}
      rules={rules}
      tooltip={tooltip}
    >
      <RadioGroup
        defaultValue={defaultValue}
        label={label}
        onChange={onChange}
        radioItems={radioItems}
        required={required}
        value={value}
      />
    </FormItem>
  )
}
