import styled from "styled-components"
import { Fonts } from "../../../../../../constant/Styled/Fonts"
import { Title } from "../../../Typography/Title"
import { getValueByDevice } from "../../../../../../utils/getValueByDevice"

const { FS_XX_LARGE, FW_BOLD } = Fonts

const StyledPopupContent = styled.div`
  max-height: ${getValueByDevice("60vh", "calc(60vh - 40px)")};
  overflow: auto;
`

const StyledPopupTitle = styled(Title)`
  font-size: ${FS_XX_LARGE};
  font-weight: ${FW_BOLD};
  justify-content: center;
  width: 100%;
`

export const Styled = {
  StyledPopupContent,
  StyledPopupTitle,
}
