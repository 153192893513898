/* eslint-disable max-lines */
import { useEffect } from "react"
import { Routers } from "../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { getDateMoment } from "../../../../../../../utils/getDate"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { safetyApi } from "../../../../apis"
import {
  SafetiesListSearchCondition,
  SafetiesListSearchFormCondition,
  SafetiesListType,
  UseSafetiesListType,
} from "../../../../models/list"
import { useVariables } from "../useVariables"
import { convertDataSearch } from "./convertDataSearch"
import { getSearchParam } from "./getRequestParams"
import { convertData } from "./convertData"

const { SCR973 } = Routers

export const useSafetiesList = (): UseSafetiesListType<SafetiesListType> => {
  const {
    breadcrumbItems,
    categoryList,
    columns,
    form,
    goToSafetiesDetail,
    goToSafetiesResponse,
    isCommonTravelerRefer,
    isDisabledDetailBtn,
    isDisabledResponseBtn,
    isFromSCR416History,
    isHiddenDetailBtn,
    isHiddenResponseBtn,
    isHiddenResponseHistoryBtn,
    isLoading,
    isLoadingLayout,
    isOpen,
    isRoleSubAdmin,
    onBlur,
    onCancel,
    onChange,
    onKeyDown,
    onOpen,
    pathname,
    perItem,
    push,
    rowSelection,
    safetyInfoList,
    searchCondition,
    setIsLoading,
    setIsLoadingLayout,
    setSafetyInfoList,
    setSearchCondition,
    state,
    statusList,
  } = useVariables()

  const handleSearchSafeties = (
    searchCondition: SafetiesListSearchCondition,
  ) => {
    // APIを呼び出し、指定されている条件に従って安否確認一覧を取得し、APIからの応答結果を「searchCondition」変数に設定する。
    safetyApi
      .getSafeties(searchCondition)
      .then((safetyInfoResponse) => {
        //「M.safetyInfo」に「safetyInfoResponse」を設定する。
        const data = convertData(safetyInfoResponse)
        setSafetyInfoList(data)
        setIsLoading(false)
        setIsLoadingLayout(false)
      })
      .catch((error: AxiosErrorType) => {
        //「SCR973_エラー情報の表示画面」に遷移する。
        const stateError = pushStateError(error)
        push({ pathname: SCR973, state: stateError })
      })
  }

  const onFinish = (values: SafetiesListSearchFormCondition) => {
    const data = convertDataSearch(values)
    setIsLoading(true)
    onCancel()
    setSearchCondition(data)
    handleSearchSafeties({
      ...data,
      isMenuSubAdmin: isRoleSubAdmin,
      statusId: isFromSCR416History ? data.statusId : "0",
    })
    setTimeout(() => {
      document.getElementById("title-right")?.focus()
    }, 600)
  }

  const onOpenPopup = () => {
    if (searchCondition) {
      const {
        categoryId,
        safetyTitle,
        statusId,
        isMenuSubAdmin,
        implementationDate,
      } = searchCondition
      form.setFieldsValue({
        categoryId,
        implementationDate:
          implementationDate && getDateMoment(implementationDate),
        isMenuSubAdmin,
        safetyTitle,
        statusId,
      })
    } else {
      form.resetFields()
    }
    onOpen()
  }

  const onCancelPopup = () => {
    form.setFieldsValue({
      ...searchCondition,
      implementationDate:
        searchCondition?.implementationDate &&
        getDateMoment(searchCondition.implementationDate),
    })
    onCancel()
  }

  useEffect(() => {
    //  ユーザの画面アクセス許可チェックを処理する。
    if (!isCommonTravelerRefer) {
      //「SCR973_エラー情報の表示画面」に遷移する。
      const { pathname, state } = backToLogin()
      push({ pathname, state })
    } else {
      const searchParam = getSearchParam(pathname, state, isRoleSubAdmin)
      handleSearchSafeties({
        ...searchParam,
        isMenuSubAdmin: isRoleSubAdmin,
      })

      form.setFieldsValue({
        ...state?.searchCondition,
        implementationDate:
          state?.searchCondition?.implementationDate &&
          getDateMoment(state.searchCondition.implementationDate),
      })
    }
  }, [])

  return {
    breadcrumbItems,
    categoryList,
    columns,
    form,
    goToSafetiesDetail,
    goToSafetiesResponse,
    isDisabledDetailBtn,
    isDisabledResponseBtn,
    isHiddenDetailBtn,
    isHiddenResponseBtn,
    isHiddenResponseHistoryBtn,
    isLoading,
    isLoadingLayout,
    isOpen,
    onBlur,
    onCancelPopup,
    onChange,
    onFinish,
    onKeyDown,
    onOpenPopup,
    perItem,
    rowSelection,
    safetyInfoList,
    statusList,
  }
}
