import { useEffect } from "react"
import { Routers } from "../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { travelerApi } from "../../../../apis"
import {
  TravelersEditSuccessColumnType,
  TravelersEditSuccessResType,
  UseTravelersEditSuccessType,
} from "../../../../models/editSuccess"
import { useVariables } from "../useVariables"
import { convertData } from "./convertData"

const { SCR920, SCR973 } = Routers

export const useTravelersEditSuccess =
  (): UseTravelersEditSuccessType<TravelersEditSuccessColumnType> => {
    const {
      breadcrumbItems,
      columns,
      detail,
      isCommonRefer,
      isHiddenBtn,
      isLoadingLayout,
      isRoleAdmin,
      isRoleSubAdmin,
      push,
      setDetail,
      setIsLoadingLayout,
      state,
      travelerId,
    } = useVariables()

    const goToHome = () => {
      // 「SCR920_ホーム画面」に遷移する。
      push(SCR920)
    }

    const getTravelerDetails = () => {
      // APIを呼び出し、データベースから渡航者情報を取得する。
      travelerApi
        .getTravelerDetails(travelerId)
        .then((travelerInfoJson: TravelersEditSuccessResType) => {
          //  画面上の各項目の画面初期表示処理を行う
          const data = convertData(
            travelerInfoJson,
            isRoleAdmin,
            isRoleSubAdmin,
            state?.userIdForChangeRequest,
          )
          setDetail(data)
          setIsLoadingLayout(false)
        })
        .catch((error: AxiosErrorType) => {
          // 「SCR973_エラー情報の表示画面」に遷移する。
          const stateError = pushStateError(error)
          push({ pathname: SCR973, state: stateError })
        })
    }

    useEffect(() => {
      // ユーザーの画面アクセス許可チェックを処理する。
      if (!isCommonRefer) {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const { pathname, state } = backToLogin()
        push({ pathname, state })
      } else getTravelerDetails()
    }, [])

    return {
      breadcrumbItems,
      columns,
      detail,
      fullName: state?.fullName,
      goToHome,
      isHiddenBtn,
      isLoadingLayout,
    }
  }
