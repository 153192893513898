import styled, { css } from "styled-components"
import { StyledTableType } from "../models"
import { Colors } from "../../../../../constant/Styled/Colors"

const { HEADING, TABLE_HEADER } = Colors

const StyledTable = styled.div<StyledTableType>`
  ${(props) =>
    props.isGroup &&
    css`
      .ant {
        &-table {
          &-thead {
            .ant {
              &-table {
                &-cell {
                  height: initial;
                }
              }
            }
            tr {
              &:nth-child(1) {
                .ant {
                  &-table {
                    &-cell {
                      &::before {
                        top: 14px;
                        transform: initial;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
  ${(props) =>
    props.isGroup &&
    !props.isSeparator &&
    css`
      .ant {
        &-table {
          &-thead {
            tr {
              &:not(:nth-child(1)) {
                .ant {
                  &-table {
                    &-cell {
                      &::before {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
  ${(props) =>
    props.isBackgroundFirstChild &&
    css`
      .ant-table-row .ant-table-cell:first-child {
        background-color: ${TABLE_HEADER};
      }
    `}
`

const StyledTableHeading = styled.div<StyledTableType>`
  background-color: ${HEADING};
  height: 30px;
  line-height: 30px;
  text-align: center;
  ${(props) =>
    props.isHeadingLeft &&
    css`
      padding-left: 10px;
      text-align: left;
    `}
`

export const Styled = {
  StyledTable,
  StyledTableHeading,
}
