import { Key, useState } from "react"

export const useVariablesRowSelection = () => {
  const [isCheckAllDetail, setIsCheckAllDetail] = useState(false)
  const [isCheckAllLodging, setIsCheckAllLodging] = useState(false)
  const [isCheckAllStay, setIsCheckAllStay] = useState(false)
  const [isDisabledCheckAllDetail, setIsDisabledCheckAllDetail] =
    useState(false)
  const [isDisabledCheckAllLodging, setIsDisabledCheckAllLodging] =
    useState(false)
  const [isDisabledCheckAllStay, setIsDisabledCheckAllStay] = useState(false)

  const [detailSelectedRowKeys, setDetailSelectedRowKeys] = useState<Key[]>([])
  const [lodgingSelectedRowKeys, setLodgingSelectedRowKeys] = useState<Key[]>(
    [],
  )
  const [staySelectedRowKeys, setStaySelectedRowKeys] = useState<Key[]>([])

  return {
    detailSelectedRowKeys,
    isCheckAllDetail,
    isCheckAllLodging,
    isCheckAllStay,
    isDisabledCheckAllDetail,
    isDisabledCheckAllLodging,
    isDisabledCheckAllStay,
    lodgingSelectedRowKeys,
    setDetailSelectedRowKeys,
    setIsCheckAllDetail,
    setIsCheckAllLodging,
    setIsCheckAllStay,
    setIsDisabledCheckAllDetail,
    setIsDisabledCheckAllLodging,
    setIsDisabledCheckAllStay,
    setLodgingSelectedRowKeys,
    setStaySelectedRowKeys,
    staySelectedRowKeys,
  }
}
