import { Breadcrumbs } from "../../../../../../../../constant/Breadcrumbs"

const { BRCR_SCR115, BRCR_SCR115_FROM_SCR114, BRCR_SCR115_USER } = Breadcrumbs

export const getBreadcrumbItems = (
  isFromSCR114User: boolean,
  isFromSCR114UserSearch: boolean,
) =>
  isFromSCR114User
    ? BRCR_SCR115_USER
    : isFromSCR114UserSearch
    ? BRCR_SCR115_FROM_SCR114
    : BRCR_SCR115
