import { FC } from "react"
import { Alert } from "../../../../../../_webui/layout/components/Alert"
import { ButtonGroup } from "../../../../../../_webui/layout/components/Button/Group"
import { Card } from "../../../../../../_webui/layout/components/Card"
import { Grid } from "../../../../../../_webui/layout/components/Grid"
import { Layout } from "../../../../../../_webui/layout/components/Layout"
import { Space } from "../../../../../../_webui/layout/components/Space"
import { Table } from "../../../../../../_webui/layout/components/Table"
import { TotalRecords } from "../../../../../../_webui/layout/components/Typography/TotalRecords"
import { Breadcrumbs } from "../../../../../../constant/Breadcrumbs"
import { Translate } from "../../../../../../constant/Translate"
import { useSubAdminConfirm } from "./modules/useConfirm"

const { TEXT_T072, TEXT_T463, TEXT_T457, TEXT_T458, TEXT_T460 } = Translate
const { BRCR_SCR951 } = Breadcrumbs

export const SubAdminConfirm: FC = () => {
  const {
    columns,
    dataSource,
    goBack,
    goToRolesSetting,
    gridItems,
    isHiddenBtn,
    isLoadingLayout,
    onChange,
    perItem,
  } = useSubAdminConfirm()

  return (
    <Layout
      breadcrumbItems={BRCR_SCR951}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T457}
    >
      <Card heading={TEXT_T457}>
        <Alert type="success" content={TEXT_T458} />
        <Grid gridItems={gridItems} />
        <Space isSpaceTop>
          <TotalRecords
            perItem={perItem}
            titleLeft={TEXT_T460}
            total={dataSource?.length}
          />
          <Table
            columns={columns}
            dataSource={dataSource}
            onChange={onChange}
            rowKey="travelerId"
          />
        </Space>
        <ButtonGroup
          buttonItems={[
            {
              isHidden: !isHiddenBtn,
              isSmall: true,
              onClick: goBack,
              order: 2,
              text: TEXT_T072,
              type: "ghost",
            },
            {
              isHidden: !isHiddenBtn,
              onClick: goToRolesSetting,
              order: 1,
              text: TEXT_T463,
              type: "default",
            },
          ]}
          position="space-between"
        />
      </Card>
    </Layout>
  )
}
