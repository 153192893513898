import { RadioItemType } from "../../../../../../_webui/layout/components/Radio/models"
import { Translate } from "../../../../../../constant/Translate"

const { TEXT_T427, TEXT_T428 } = Translate

const RADIO_ITEMS_INITIAL_VALUES: RadioItemType[] = [
  {
    text: TEXT_T427,
    value: 1,
  },
  {
    text: TEXT_T428,
    value: 0,
  },
]

export const InitialValues = {
  RADIO_ITEMS_INITIAL_VALUES,
}
