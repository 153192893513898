/* eslint-disable complexity */
import axios from "axios"
import { getSelectedLang } from "../../utils/getSelectedLang"
import keycloak from "../../utils/keycloak"
import { axiosError } from "../axiosError"
import { axiosResponse } from "../axiosResponse"
import { ApiServiceType } from "./models"

export const apiService = async (
  configs: ApiServiceType,
  acceptLanguage?: string,
) => {
  const httpHeaders = configs.headers || {}

  if (!httpHeaders["Content-Type"]) {
    httpHeaders["Content-Type"] = "application/json"
  }

  if (keycloak.token) {
    const keycloakToken = keycloak.token
    keycloak.updateToken(10)
    if (!httpHeaders["Authorization"]) {
      httpHeaders["Authorization"] = `Bearer ${keycloakToken}`
    }
  }

  httpHeaders["Accept-Language"] = acceptLanguage || getSelectedLang()

  configs["headers"] = httpHeaders

  const response = await axios(configs)
    .then(axiosResponse.bind(this))
    .catch(axiosError.bind(this))

  return response
}
