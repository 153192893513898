/* eslint-disable max-statements */
/* eslint-disable max-lines */
import { SyntheticEvent, useEffect } from "react"
import { scheduleApi } from "../../../../../../app/module/schedules/apis"
import { travelerApi } from "../../../../../../app/module/travelers/apis"
import { Routers } from "../../../../../../constant/Routers"
import { Translate } from "../../../../../../constant/Translate"
import { AxiosErrorType } from "../../../../../../infrastructure/axiosError/models"
import { pushStateError } from "../../../../../../utils/pushStateError"
import { Variables } from "../../../Form/FormProvider/constants"
import { menusApi } from "../../apis"
import { MenusType } from "../../models"
import { useVariables } from "../useVariables"

const { SCR973 } = Routers
const { MESSAGE_E9996 } = Translate
const { TEXT_IS_FORM_CHANGE } = Variables

export const useMenu = () => {
  const {
    isExpandableMenu,
    isOpen,
    isOpenFormChange,
    menus,
    nextLink,
    onCancel,
    onCancelFormChange,
    onOpen,
    onOpenFormChange,
    pathname,
    push,
    scrollDirection,
    setIsExpandableMenu,
    setMenus,
    setNextLink,
  } = useVariables()

  const handleActiveItem = (element: HTMLLIElement) => {
    element.classList.add("active")
    element?.querySelector(".nav-menu-title")?.classList.add("active")
    element
      .querySelector("div")
      ?.querySelector(".nav-arrow-icon")
      ?.classList.add("active")
  }

  const handleActiveMenu = () => {
    const elmNavMenu = isExpandableMenu ? ".nav-menu-icon" : ".nav-menu"
    const navSubMenuLevel1 = document.querySelectorAll(
      `${elmNavMenu} > li > ul > li > a`,
    )
    const navSubMenuLevel2 = document.querySelectorAll(
      `${elmNavMenu} > li > ul > li > ul > li> a`,
    )
    navSubMenuLevel1?.forEach((element) => {
      if (element.getAttribute("href") === pathname) {
        element.classList.add("active")
        const parent = element.closest("ul")?.closest("li")
        if (parent) {
          handleActiveItem(parent)
        }
      }
    })
    navSubMenuLevel2?.forEach((element) => {
      if (element.getAttribute("href") === pathname) {
        element.classList.add("active")
        const parent1 = element.closest("ul")?.closest("li")
        if (parent1) {
          handleActiveItem(parent1)
          const parent2 = parent1.closest("ul")?.closest("li")
          if (parent2) {
            handleActiveItem(parent2)
          }
        }
      }
    })
  }

  const handleExpandableMenu = () => {
    if (isExpandableMenu === "") {
      localStorage.setItem("isExpandableMenu", "true")
      setIsExpandableMenu("true")
    } else {
      localStorage.setItem("isExpandableMenu", "")
      setIsExpandableMenu("")
    }
  }

  const handleNavLinkClick = (event: SyntheticEvent) => {
    const isDataUpload = sessionStorage.getItem("dataUpload")
    const currentTargetLink = event.currentTarget.getAttribute("href")
    const link = typeof currentTargetLink === "string" ? currentTargetLink : ""
    const searchString = window.location.search

    setNextLink(link)
    if (link.startsWith("http")) {
      event.preventDefault()
      window.open(link)
    } else if (isDataUpload) {
      event.preventDefault()
      onOpen()
    } else if (searchString.includes(TEXT_IS_FORM_CHANGE)) {
      event.preventDefault()
      onOpenFormChange()
    } else {
      push(link)
    }
  }

  const onOkFormChange = () => {
    push(nextLink)
  }

  const onOk = () => {
    const dataUpload = sessionStorage.getItem("dataUpload")
    // APIを呼び出し、APIからの応答結果を「一時削除応答」変数に設定する。
    if (typeof dataUpload === "string") {
      if (dataUpload.startsWith("SCR1")) {
        travelerApi
          .bulkDeleteData(dataUpload)
          .then(() => {
            sessionStorage.removeItem("dataUpload")
            push(nextLink)
          })
          .catch((error: AxiosErrorType) => {
            // 「SCR973_エラー情報の表示画面」に遷移する。
            const stateError = pushStateError(error)
            push({ pathname: SCR973, state: stateError })
          })
      }
      if (dataUpload.startsWith("SCR2")) {
        scheduleApi
          .bulkDeleteData(dataUpload)
          .then(() => {
            sessionStorage.removeItem("dataUpload")
            push(nextLink)
          })
          .catch((error: AxiosErrorType) => {
            // 「SCR973_エラー情報の表示画面」に遷移する。
            const stateError = pushStateError(error)
            push({ pathname: SCR973, state: stateError })
          })
      }
    }
  }

  const getMenus = () => {
    const _menus = localStorage.getItem("menus")
    if (!_menus) {
      menusApi
        .menus()
        .then((res: MenusType[]) => {
          setMenus(res)
          localStorage.setItem("menus", JSON.stringify(res))
        })
        .catch((error: AxiosErrorType) => {
          if (error?.debug?.response?.data?.message === "E9999") {
            const state = pushStateError(error)
            push({
              pathname: SCR973,
              state: {
                ...state,
                message: MESSAGE_E9996,
              },
            })
          } else {
            const state = pushStateError(error)
            push({ pathname: SCR973, state })
          }
        })
    } else {
      setMenus(JSON.parse(_menus))
    }
  }

  useEffect(() => {
    if (menus) handleActiveMenu()
  }, [menus, isExpandableMenu])

  useEffect(() => {
    getMenus()
  }, [])

  return {
    handleExpandableMenu,
    handleNavLinkClick,
    isExpandableMenu,
    isOpen,
    isOpenFormChange,
    menus,
    onCancel,
    onCancelFormChange,
    onOk,
    onOkFormChange,
    scrollDirection,
  }
}
