/* eslint-disable complexity */
import { useLocation } from "react-router-dom"
import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const { isCommonRefer, isRoleAdmin, isRoleSubAdmin, isRoleUser } =
    useAuthority()
  const { search } = useLocation()
  const searchParam = new URLSearchParams(search)
  const stateParam = !!searchParam.get("state")
  const isHiddenBtn =
    (isRoleAdmin || isRoleSubAdmin || isRoleUser) && isCommonRefer
  const isHiddenBtnBack = isHiddenBtn && !stateParam
  const isHiddenBtnSubmit = isHiddenBtnBack || stateParam

  return {
    isCommonRefer,
    isHiddenBtnBack,
    isHiddenBtnSubmit,
  }
}
