import { FC } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { ButtonSubmit } from "../../../../../../_webui/layout/components/Button/Submit"
import { Form } from "../../../../../../_webui/layout/components/Form"
import { ItemDatePicker } from "../../../../../../_webui/layout/components/Form/Item/DatePicker"
import { ItemInput } from "../../../../../../_webui/layout/components/Form/Item/Input"
import { ItemSelect } from "../../../../../../_webui/layout/components/Form/Item/Select"
import { AttentionsFormSearchType } from "../../../models/list"
import { Modal } from "../../../../../../_webui/layout/components/Modal"

const {
  TEXT_T012,
  TEXT_T179,
  TEXT_T211,
  TEXT_T312,
  TEXT_T314,
  TEXT_T315,
  TEXT_T333,
} = Translate

export const AttentionsFormSearch: FC<AttentionsFormSearchType> = (props) => {
  const {
    categoryList,
    form,
    isOpen,
    onBlur,
    onCancel,
    onFinish,
    onKeyDown,
    severityList,
    statusList,
  } = props

  return (
    <Modal isOpen={isOpen} onCancel={onCancel} isShowFooter={false} isForm>
      <Form
        form={form}
        isFullHeight
        isHeading={false}
        labelAlign="left"
        onFinish={onFinish}
      >
        <ItemSelect
          label={TEXT_T314}
          name="severity"
          selectItem={severityList}
        />
        <ItemDatePicker
          label={TEXT_T333}
          name="implementationDateTime"
          onBlur={(event) => onBlur(event, TEXT_T333, "implementationDateTime")}
          onKeyDown={(event) =>
            onKeyDown(event, TEXT_T333, "implementationDateTime")
          }
        />
        <ItemInput label={TEXT_T315} maxLength={100} name="attentionTitle" />
        <ItemSelect label={TEXT_T179} name="statusId" selectItem={statusList} />
        <ItemSelect
          label={TEXT_T312}
          name="categoryId"
          selectItem={categoryList}
        />
        <ButtonSubmit
          isReset
          onClickReset={onCancel}
          resetText={TEXT_T012}
          submitText={TEXT_T211}
        />
      </Form>
    </Modal>
  )
}
