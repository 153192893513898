/* eslint-disable max-statements */
import { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import {
  SchedulesCloneDataType,
  SchedulesCloneStateType,
} from "../../../../models/clone"
import { SchedulesListDataType } from "../../../../models/list"
import { useRouters } from "../useRouters"
import { useRowSelection } from "../useRowSelection"
import { useTableConfig } from "../useTableConfig"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { getBreadcrumbItems } from "./getBreadcrumbItems"

export const useVariables = () => {
  const { push } = useHistory()
  const { state } = useLocation<SchedulesCloneStateType>()
  const { columns } = useTableConfig()
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [scheduleList, setScheduleList] = useState<SchedulesListDataType[]>([])
  const [dataSource, setDataSource] = useState<SchedulesCloneDataType[]>([])

  const { isNotAccessScreen, isVisibleBtnBack, isVisibleBtnCopy } =
    useVariablesAuthority(
      state?.isFromSCR114UserSearch,
      state?.isFromSCR212,
      state?.isFromSCR212User,
    )

  const {
    isDisabledBtnClone,
    onChange,
    onChangeTable,
    perItem,
    rowSelection,
    selectedRowKeys,
  } = useRowSelection(dataSource)

  const { goBackSchedulesAdd, goToSchedulesAdd } = useRouters({
    scheduleList,
    selectedRowKeys,
    state,
  })

  const breadcrumbItems = getBreadcrumbItems(
    state?.isFromSCR114UserSearch,
    state?.isFromSCR212,
    state?.isFromSCR212User,
  )

  return {
    breadcrumbItems,
    columns,
    dataSource,
    goBackSchedulesAdd,
    goToSchedulesAdd,
    isDisabledBtnClone,
    isLoadingLayout,
    isNotAccessScreen,
    isVisibleBtnBack,
    isVisibleBtnCopy,
    onChange,
    onChangeTable,
    perItem,
    push,
    rowSelection,
    selectedRowKeys,
    setDataSource,
    setIsLoadingLayout,
    setScheduleList,
    travelerId: state?.travelerId,
  }
}
