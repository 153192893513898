import { getDay } from "../../../../../../../../utils/getDate"
import { SchedulesBatchDeleteDataType } from "../../../../../models/batchDelete/data"

export const convertDataSource = (
  data: SchedulesBatchDeleteDataType[],
): SchedulesBatchDeleteDataType[] =>
  data?.map((item) => ({
    ...item,
    dispatchEndDate: getDay(item.dispatchEndDate),
    dispatchStartDate: getDay(item.dispatchStartDate),
  }))
