/* eslint-disable complexity */
import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const {
    isCommonRefer,
    isCommonTravelerRefer,
    isRoleAdmin,
    isRoleSubAdmin,
    isRoleUser,
    isScheduleRegister,
  } = useAuthority()

  const isNotAccessScreen = !isCommonTravelerRefer && !isScheduleRegister

  const isVisibleBtnBtn = isRoleAdmin || isRoleSubAdmin || isRoleUser
  const isVisibleBtnCancel = isVisibleBtnBtn && isCommonRefer
  const isVisibleBtnRegister =
    isVisibleBtnBtn && (isCommonTravelerRefer || isScheduleRegister)

  return {
    isNotAccessScreen,
    isVisibleBtnCancel,
    isVisibleBtnRegister,
  }
}
