import {
  SafetiesDetailDataSourceType,
  SafetiesDetailTravelersType,
} from "../../../../../models/detail"
import { getSafetyStatus } from "./getSafetyStatus"

export const convertDataSource = (
  data: SafetiesDetailTravelersType[],
): SafetiesDetailDataSourceType[] =>
  data.map((item) => ({
    ...item,
    ...getSafetyStatus(item.safetyStatus),
  }))
