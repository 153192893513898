/* eslint-disable max-statements */
/* eslint-disable complexity */
import { useLocation } from "react-router-dom"
import { Routers } from "../../../../../../../constant/Routers"
import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

const { SCR114, SCR114_USER, SCR114_USER_SEARCH } = Routers

export const useVariablesAuthority = () => {
  const { pathname } = useLocation()
  const {
    isRoleAdmin,
    isRoleSubAdmin,
    isScheduleRegister,
    isTravelerChange,
    isTravelerDelete,
    isTravelerRefer,
    isTravelerRegister,
    isTravelerSearch,
  } = useAuthority()

  const isPathnameTravelers = pathname === SCR114
  const isPathnameTravelersUser = pathname === SCR114_USER
  const isPathnameTravelersUserSearch = pathname === SCR114_USER_SEARCH

  const isNotAccessScreen =
    (isPathnameTravelers && !isScheduleRegister) ||
    (isPathnameTravelersUser && !isTravelerSearch)

  const isHiddenBtn = isRoleAdmin || isRoleSubAdmin

  const isHiddenBtnSearch =
    isHiddenBtn && isTravelerSearch && !isPathnameTravelersUserSearch

  const isHiddenBtnRefer =
    !isPathnameTravelers && isHiddenBtn && isTravelerRefer
  const isHiddenBtnExportFile =
    isPathnameTravelersUser &&
    isHiddenBtn &&
    (isTravelerChange ||
      isTravelerDelete ||
      isTravelerRefer ||
      isTravelerRegister)
  const isHiddenBtnRequestDelete =
    isPathnameTravelersUser && isRoleSubAdmin && isTravelerDelete
  const isHiddenBtnDelete =
    isPathnameTravelersUser && isRoleAdmin && isTravelerDelete

  const isHiddenBtnRegister =
    isPathnameTravelers && isHiddenBtn && isScheduleRegister

  return {
    isHiddenBtnDelete,
    isHiddenBtnExportFile,
    isHiddenBtnRefer,
    isHiddenBtnRegister,
    isHiddenBtnRequestDelete,
    isHiddenBtnSearch,
    isNotAccessScreen,
    isPathnameTravelers,
    isPathnameTravelersUser,
    isPathnameTravelersUserSearch,
  }
}
