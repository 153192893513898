import { FC } from "react"
import { FullName } from "../../../../../../_webui/layout/components/Typography/FullName"
import { Translate } from "../../../../../../constant/Translate"
import { Styled } from "./styled"
import { TravelersEditHeadingProps } from "./types"

const { StyledButton, StyledHeading } = Styled
const { TEXT_T400 } = Translate

export const TravelersEditHeading: FC<TravelersEditHeadingProps> = (props) => {
  const { fullName, handleGetTravelerDraft, isHiddenBtnTravelerDraft } = props

  return (
    <StyledHeading>
      <StyledButton
        htmlType="button"
        isHidden={isHiddenBtnTravelerDraft}
        onClick={handleGetTravelerDraft}
        text={TEXT_T400}
        type="warning"
      />
      <FullName fullName={fullName} />
    </StyledHeading>
  )
}
