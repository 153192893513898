import { TravelersUserSearchFormDataType } from "../../../../../components/list/FormSearch/modals"
import { TravelersListPostType } from "../../../../../models/list"

export const convertDataSearch = (
  values?: TravelersUserSearchFormDataType,
): TravelersListPostType => ({
  accompanies: {},
  base: {
    bumonCd: values?.bumonCd,
  },
  detail: {},
  fullName: values?.fullName,
  isFromSCR114UserSearch: true,
  isGetAllData: false,
})
