/* eslint-disable max-statements */
/* eslint-disable max-lines */
import { File } from "../../../../../../../constant/File"
import { Routers } from "../../../../../../../constant/Routers"
import { Translate } from "../../../../../../../constant/Translate"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { openNotification } from "../../../../../../../utils/openNotification"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { BreakMessage } from "../../../../../../../_webui/layout/components/Typography/BreakMessage"
import { UploadFileType } from "../../../../../../../_webui/layout/components/Upload/models"
import { SchedulesBatchImportErrorType } from "../../../../../schedules/models/batchImport"
import { travelerApi } from "../../../../apis"
import {
  TravelersBatchImportErrorType,
  TravelersBatchImportCheck,
  UseTravelersBatchImportApiProps,
  TravelersFileAddBatchImportType,
} from "../../../../models/batchImport"
import { TravelersBatchImportDataType } from "../../../../models/batchImport/data"
import { useVariablesApi } from "../useVariablesApi"
import { getFileNameRcFile } from "../../../../../../../utils/getFileNameRcFile"
import { setFieldsErrors } from "../../../../../../../utils/setFieldsErrors"

const { SCR973 } = Routers
const { MESSAGE_I0008, MESSAGE_E0022, MESSAGE_E0023, MESSAGE_E0037 } = Translate

const {
  FILE_COLUMN_LIMIT_TRAVELERS,
  FILE_SIZE_LIMIT_KB,
  FILE_SIZE_LIMIT_MB,
  FILE_TYPE_ALLOW,
  SCR122,
} = File

export const useApi = (props: UseTravelersBatchImportApiProps) => {
  const { onCancel, onCancelUploaded } = props
  const {
    countRecord,
    dataTemps,
    errorList,
    fileNameList,
    form,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isResetTable,
    onChange,
    perItem,
    push,
    setCountRecord,
    setDataTemps,
    setErrorList,
    setFileNameList,
    setIsDisabledSubmit,
    setIsLoading,
    setIsLoadingLayout,
    setIsResetTable,
  } = useVariablesApi()

  const handlePushStateError = (error: AxiosErrorType) => {
    const stateError = pushStateError(error)
    push({ pathname: SCR973, state: stateError })
  }

  const bulkCheck = () => {
    // APIを呼び出し、APIからの応答結果を「一括チェック応答」変数に設定する。
    travelerApi
      .bulkCheck(SCR122)
      .then((bulkCheckTemp: TravelersBatchImportCheck) => {
        const { message } = bulkCheckTemp
        if (message) {
          // 「TBL_TEMP_FOR_UPLOAD」一時テーブルのデータ削除の確認ダイアログを表示する。
          bulkDeleteData()
        } else {
          setIsLoadingLayout(false)
        }
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        handlePushStateError(error)
      })
  }

  const bulkData = () => {
    setIsLoading(true)
    setIsResetTable(true)
    setTimeout(() => {
      // APIを呼び出し、APIからの応答結果を「返却一時データ」変数に設定する。
      travelerApi
        .bulkData(SCR122)
        .then((dataTemps: TravelersBatchImportDataType[]) => {
          // 「M.dataTemps」の一覧にデータを設定する。
          sessionStorage.setItem("dataUpload", SCR122)
          setDataTemps(dataTemps)
          setErrorList([])
          onChange(1, 25)
        })
        .catch((error: AxiosErrorType) => {
          // 「SCR973_エラー情報の表示画面」に遷移する。
          handlePushStateError(error)
        })
        .finally(() => {
          setIsLoading(false)
          setIsDisabledSubmit(false)
          setIsResetTable(false)
        })
    }, 500)
  }

  const bulkAdd = () => {
    setIsLoading(true)
    setTimeout(() => {
      // APIを呼び出し、APIからの応答結果を「一括追加応答」変数に設定する。
      travelerApi
        .bulkAdd({
          registerRequestUserId: form.getFieldValue("registerRequestUserId"),
          screenId: SCR122,
        })
        .then((bulkAdd: TravelersBatchImportErrorType) => {
          // ComponentErrorを表示する。
          if (bulkAdd.errors.length) {
            setErrorList(bulkAdd.errors)
          }

          if (bulkAdd?.execute?.fail === 0) {
            // バックエンドから戻す「I0008」のメッセージを表示する。
            openNotification(MESSAGE_I0008)
            setDataTemps([])
          }

          //「渡航者情報の結果件数」コンポーネントの値を表示する。
          sessionStorage.removeItem("dataUpload")
          setCountRecord(bulkAdd.execute)
          onCancel()
          setIsDisabledSubmit(true)
          bulkDeleteData()
          setIsLoading(false)
        })
        .catch((error: AxiosErrorType) => {
          if (error?.debug?.response?.data?.entityName) {
            setFieldsErrors(error, form)
          } else handlePushStateError(error) // 「SCR973_エラー情報の表示画面」に遷移する。
          setIsLoading(false)
        })
    }, 500)
  }

  const bulkDeleteData = () => {
    // APIを呼び出し、APIからの応答結果を「一時削除応答」変数に設定する。
    travelerApi
      .bulkDeleteData(SCR122)
      .then(() => {
        sessionStorage.removeItem("dataUpload")
        onCancelUploaded()
        onCancel()
        setIsLoadingLayout(false)
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        handlePushStateError(error)
      })
  }

  const setDataBeforeUploadError = (message: string, validValue: string) => {
    openNotification(<BreakMessage message={message} validValue={validValue} />)
    setDataTemps([])
    setErrorList([])
    setFileNameList([])
  }

  const bulkFileAdd = (fileInfoCSV: UploadFileType) => {
    const formData = new FormData()
    formData.append("file", fileInfoCSV)
    setIsLoading(true)
    setIsResetTable(true)
    // APIを呼び出し、APIからの応答結果を「一時アップロード応答」変数に設定する。
    travelerApi
      .bulkFileAdd(formData)
      .then((response: SchedulesBatchImportErrorType) => {
        // バックエンドから戻す「E0037」のメッセージが表示される。
        onChangeUploadFile({ response, status: "done" })
        if (getFileNameRcFile(fileInfoCSV)) {
          setFileNameList([fileInfoCSV?.name])
        }
      })
      .catch((error) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        onChangeUploadFile({ response: error, status: "error" })
        setFileNameList([])
      })
      .finally(() => {
        setIsLoading(false)
        setIsResetTable(false)
      })
  }

  const beforeUpload = (
    fileInfoCSV: UploadFileType,
    uploadCallback: (upload: boolean) => void,
  ) => {
    if (typeof fileInfoCSV !== "string") {
      const { size, type } = fileInfoCSV

      const fileSize = size / 1024
      const isValidFileType = type.includes(FILE_TYPE_ALLOW)
      const isValidFileSize = fileSize < FILE_SIZE_LIMIT_KB
      const isValidFileSizeZero = fileSize === 0

      // ファイル拡張子のバリデーションチェックを行う。
      if (!isValidFileType) {
        setDataBeforeUploadError(MESSAGE_E0022, FILE_TYPE_ALLOW)
        uploadCallback(false)
        return false
      }

      // ファイルサイズのバリデーションチェックを行う。
      if (!isValidFileSize) {
        setDataBeforeUploadError(MESSAGE_E0023, String(FILE_SIZE_LIMIT_MB))
        uploadCallback(false)
        return false
      }

      // ファイルサイズのバリデーションチェックを行う。
      if (isValidFileSizeZero) {
        setDataBeforeUploadError(MESSAGE_E0037, String(FILE_SIZE_LIMIT_MB))
        uploadCallback(false)
        return false
      }

      const reader = new FileReader()

      // ファイルの項目個数のバリデーションチェックを行う。
      reader.onload = (event: ProgressEvent<FileReader>) => {
        const fileData = String(event.target?.result)
        const columnNames = fileData?.substring(0, fileData.indexOf("\r\n"))
        const fileColumns = columnNames.split(",")
        const isValidColumns =
          fileColumns.length === FILE_COLUMN_LIMIT_TRAVELERS

        if (!isValidColumns) {
          setDataBeforeUploadError(
            MESSAGE_E0037,
            String(FILE_COLUMN_LIMIT_TRAVELERS),
          )
          uploadCallback(false)
        } else {
          uploadCallback(true)
        }
      }

      reader.readAsText(fileInfoCSV)
    }
  }

  const onChangeUploadFile = (info: TravelersFileAddBatchImportType) => {
    const { status, response } = info
    if (status === "done") {
      if (response.errors?.length) {
        setErrorList(response.errors)
        setIsDisabledSubmit(true)
      } else {
        bulkData()
      }
      setCountRecord(response?.execute)
    } else if (status === "error") {
      setDataBeforeUploadError(
        MESSAGE_E0037,
        String(FILE_COLUMN_LIMIT_TRAVELERS),
      )
      setIsDisabledSubmit(true)
    }
  }

  return {
    beforeUpload,
    bulkAdd,
    bulkCheck,
    bulkDeleteData,
    bulkFileAdd,
    countRecord,
    dataTemps,
    errorList,
    fileNameList,
    form,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isResetTable,
    onChange,
    perItem,
  }
}
