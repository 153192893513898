/* eslint-disable complexity */
import { RcFile } from "antd/lib/upload"
import { Variables } from "../../../../../constants/filesUpload"
import { FilesUploadInfoType } from "../../../../../model/filesUpload"
import { getItemLength } from "./getItemLength"
import { checkItemLength } from "./checkItemLength"

const {
  BORAN_REGEX,
  ODAL_REGEX,
  SHINHAKEN_REGEX,
  SYSTEM_IDS,
  SYSTEM_LENGTH,
  SYSTEM_NAMES,
  ZAIGAI_REGEX,
} = Variables
const { ODAL_SYSTEM_LENGTH } = SYSTEM_LENGTH
const { BORAN, IDM, INVALID, SHINHAKEN, ZAIGAI } = SYSTEM_IDS
const { BORAN_NAME, IDM_NAME, INVALID_NAME, SHINHAKEN_NAME, ZAIGAI_NAME } =
  SYSTEM_NAMES

export const getSystemInfo = async (
  file: RcFile,
): Promise<FilesUploadInfoType> => {
  const { name } = file

  if (BORAN_REGEX.test(name))
    return await checkItemLength({
      file,
      itemLength: getItemLength(name),
      systemId: BORAN,
      systemName: BORAN_NAME,
    })

  if (ODAL_REGEX.test(name))
    return await checkItemLength({
      file,
      itemLength: ODAL_SYSTEM_LENGTH,
      systemId: IDM,
      systemName: IDM_NAME,
    })

  if (SHINHAKEN_REGEX.test(name))
    return await checkItemLength({
      file,
      itemLength: getItemLength(name),
      systemId: SHINHAKEN,
      systemName: SHINHAKEN_NAME,
    })

  if (ZAIGAI_REGEX.test(name))
    return await checkItemLength({
      file,
      itemLength: getItemLength(name),
      systemId: ZAIGAI,
      systemName: ZAIGAI_NAME,
    })

  return { systemId: INVALID, systemName: INVALID_NAME }
}
