import { getDateRange } from "../../../../../../../../utils/getDate"
import {
  AttentionsListSearchConditionType,
  AttentionsListSearchFormType,
} from "../../../../../models/list"

export const convertDataSearch = (
  data: AttentionsListSearchFormType,
): AttentionsListSearchConditionType => ({
  ...data,
  implementationDateTime:
    data?.implementationDateTime &&
    getDateRange(data.implementationDateTime).toString(),
})
