import { FC } from "react"
import { FormSearchRightProps } from "./models"
import { Styled } from "./styled"

const { StyledFormSearchRight } = Styled

export const FormSearchRight: FC<FormSearchRightProps> = (props) => {
  const { children } = props
  return <StyledFormSearchRight>{children}</StyledFormSearchRight>
}
