/* eslint-disable complexity */
import { Routers } from "../../../../../../../constant/Routers"
import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

const { SCR418 } = Routers

export const useVariablesAuthority = (sourceURL: string) => {
  const {
    isCommonTravelerRefer,
    isRoleSubAdmin,
    isRoleUser,
    isSafetiesBrowseResponse,
    isCommonRefer,
  } = useAuthority()

  const isFromEmail = sourceURL === SCR418

  const isNotAccessScreen = !isCommonTravelerRefer && !isFromEmail

  const isVisibleBtnHome =
    !isFromEmail && (isRoleSubAdmin || isRoleUser) && isCommonRefer
  const isVisibleBtnDetail =
    !isFromEmail && isRoleSubAdmin && isSafetiesBrowseResponse
  const isVisibleBtnList =
    !isFromEmail && (isRoleSubAdmin || isRoleUser) && isCommonTravelerRefer

  return {
    isFromEmail,
    isNotAccessScreen,
    isVisibleBtnDetail,
    isVisibleBtnHome,
    isVisibleBtnList,
  }
}
