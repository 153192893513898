import { FC } from "react"
import { CheckboxAllProps } from "./models"
import { Translate } from "../../../../../constant/Translate"
import { Checkbox } from "../../Checkbox"

const { TEXT_T023 } = Translate

export const CheckboxAll: FC<CheckboxAllProps> = (props) => {
  const { checked, isDisabled, onChange } = props
  return (
    <Checkbox
      checked={checked}
      isDisabled={isDisabled}
      onChange={onChange}
      text={TEXT_T023}
    />
  )
}
