import styled from "styled-components"
import { ItemSelect } from "../../../../../../../_webui/layout/components/Form/Item/Select"
import { Regex } from "../../../../../../../constant/Regex"

const { REGEX_IS_DESKTOP } = Regex

const StyledPatternId = styled(ItemSelect)`
  margin-left: ${REGEX_IS_DESKTOP && "32px"};
  max-width: 300px;
`

export const Styled = {
  StyledPatternId,
}
