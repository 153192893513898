import { FC } from "react"
import { validatorRequiredField } from "../../../../../../utils/validator/requiredField"
import { ItemInput } from "../../../../../../_webui/layout/components/Form/Item/Input"
import { ItemSelect } from "../../../../../../_webui/layout/components/Form/Item/Select"
import { AttentionsEditCategoryProps } from "../../../models/edit"
import { Styled } from "./styled"
import { Translate } from "../../../../../../constant/Translate"

const { AttentionEditGridCategory, AttentionEditGridCategoryInput } = Styled
const { TEXT_T312 } = Translate

export const AttentionsEditCategory: FC<AttentionsEditCategoryProps> = (
  props,
) => {
  const { categoryList, isShowCategoryOther, onChangeCategory } = props

  return (
    <AttentionEditGridCategory>
      <ItemSelect
        name="categoryName"
        rules={[validatorRequiredField]}
        selectItem={categoryList}
        onChange={onChangeCategory}
      />
      <ItemInput name="categoryId" isHidden={true} label={TEXT_T312} />
      {isShowCategoryOther && (
        <AttentionEditGridCategoryInput
          isHiddenLabel
          label={TEXT_T312}
          name="categoryValue"
          maxLength={50}
          rules={[validatorRequiredField]}
        />
      )}
    </AttentionEditGridCategory>
  )
}
