/* eslint-disable max-lines */
import { FC } from "react"
import { Card } from "../../../../../../_webui/layout/components/Card"
import { Layout } from "../../../../../../_webui/layout/components/Layout"
import { ModalConfirm } from "../../../../../../_webui/layout/components/Modal/Confirm"
import { Table } from "../../../../../../_webui/layout/components/Table"
import { TotalRecords } from "../../../../../../_webui/layout/components/Typography/TotalRecords"
import { Breadcrumbs } from "../../../../../../constant/Breadcrumbs"
import { Translate } from "../../../../../../constant/Translate"
import { FilesTitleLeft } from "../../../components/files/TitleLeft"
import { FilesUploadForm } from "../../../components/files/upload/Form"
import { useFilesManager } from "./modules/useManager"
import { FilesManagerButtonGroup } from "../../../components/files/manager/ButtonGroup"
import { Modal } from "../../../../../../_webui/layout/components/Modal"

const { MESSAGE_C0009, MESSAGE_C0052, TEXT_T189, TEXT_T497 } = Translate
const { BRCR_SCR970 } = Breadcrumbs

export const FilesManager: FC = () => {
  const {
    allCountries,
    columns,
    currentUsage,
    dataSource,
    deleteFiles,
    fileTypeList,
    form,
    goToFilesUpload,
    handleDownload,
    handlePreviewFile,
    isCountry,
    isDisabledBtnDelete,
    isDisabledBtnDownload,
    isDisabledBtnPreview,
    isDisabledCountries,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenPreviewFile,
    isResetTable,
    isVisibleBtnDelete,
    isVisibleBtnReference,
    isVisibleBtnRegister,
    onCancel,
    onCancelPreviewFile,
    onChangeCountryCode,
    onChangeFileType,
    onChangePagination,
    onChangeTable,
    onOpen,
    perItem,
    rowSelection,
  } = useFilesManager()

  return (
    <Layout
      breadcrumbItems={BRCR_SCR970}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T497}
    >
      <Card heading={TEXT_T497} isLoading={isLoading}>
        <FilesUploadForm
          allCountries={allCountries}
          fileTypeList={fileTypeList}
          form={form}
          isCountry={isCountry}
          isDisabledCountries={isDisabledCountries}
          isStateFileType={false}
          onChangeCountryCode={onChangeCountryCode}
          onChangeFileType={onChangeFileType}
        />
        <TotalRecords
          perItem={perItem}
          titleLeft={
            <FilesTitleLeft
              currentUsage={currentUsage}
              dataLength={dataSource?.length}
            />
          }
          total={dataSource?.length}
        />
        <Table
          columns={columns}
          dataSource={dataSource}
          isResetTable={isResetTable}
          onChange={onChangePagination}
          onChangeTable={onChangeTable}
          rowKey="uploadId"
          rowSelection={rowSelection}
        />
        <FilesManagerButtonGroup
          goToFilesUpload={goToFilesUpload}
          handleDownload={handleDownload}
          handlePreviewFile={handlePreviewFile}
          isDisabledBtnDelete={isDisabledBtnDelete}
          isDisabledBtnDownload={isDisabledBtnDownload}
          isDisabledBtnPreview={isDisabledBtnPreview}
          isVisibleBtnDelete={isVisibleBtnDelete}
          isVisibleBtnRegister={isVisibleBtnRegister}
          isVisibleBtnReference={isVisibleBtnReference}
          onOpen={onOpen}
        />
      </Card>
      <ModalConfirm isOpen={isOpen} onCancel={onCancel} onOk={deleteFiles}>
        {MESSAGE_C0009}
      </ModalConfirm>
      <Modal
        isCenter
        isOpen={isOpenPreviewFile}
        okText={TEXT_T189}
        onOk={onCancelPreviewFile}
      >
        {MESSAGE_C0052}
      </Modal>
    </Layout>
  )
}
