import { FC } from "react"
import { UploadInfoProps } from "./models"

export const UploadInfo: FC<UploadInfoProps> = (props) => {
  const { name } = props
  return (
    <div className="ant-upload-info">
      <div className="ant-upload-list-text">
        <span className="ant-upload-list-item-name" title={name}>
          {name}
        </span>
      </div>
    </div>
  )
}
