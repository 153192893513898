/* eslint-disable complexity */
/* eslint-disable max-lines */
import { FC } from "react"
import { Alert } from "../../../../../_webui/layout/components/Alert"
import { Button } from "../../../../../_webui/layout/components/Button"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Center } from "../../../../../_webui/layout/components/Center"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { BreakText } from "../../../../../_webui/layout/components/Typography/BreakText"
import { useAttentionsInfo } from "./modules/useInfo"
import { Heading } from "../../../../../_webui/layout/components/Typography/Heading"
import { getValueByLanguage } from "../../../../../utils/getValueByLanguage"
import { getFullName } from "../../../../../utils/getFullName"
import { ChangeLanguage } from "../../../../../_webui/layout/components/ChangeLanguage"
import { Styled } from "./styled"
import { getLang } from "../../../../../utils/translate"
import { Timezone } from "../../../../../constant/Timezone"
import moment from "moment"

const { StyledTitle } = Styled
const { DATE_TIME_FORMAT_DEFAULT_JP, DATE_TIME_FORMAT_DEFAULT_EN } = Timezone

export const AttentionsInfoMail: FC = () => {
  const {
    attentionInfo,
    changeLanguage,
    getAttentionTravelers,
    handleCloseBrowser,
    isLoading,
    isLoadingLayout,
    isShowFormSuccess,
    lang,
  } = useAttentionsInfo()

  const format =
    lang === "jp" ? DATE_TIME_FORMAT_DEFAULT_JP : DATE_TIME_FORMAT_DEFAULT_EN
  const date = attentionInfo?.implementationDateTime
  const implementationDateTime = date ? moment(date).utc().format(format) : ""

  const TEXT_T001 = getLang(lang, "TEXT_T001")
  const isFlagJudgmentTime = attentionInfo?.flagJudgmentTime === 0

  return (
    <Layout
      isFooter={false}
      isHeader={false}
      isLoadingLayout={isLoadingLayout}
      isSideBar={false}
      title={
        isShowFormSuccess
          ? getLang(lang, "TEXT_T003")
          : getLang(lang, "TEXT_T005")
      }
    >
      {isShowFormSuccess ? (
        <Card heading={getLang(lang, "TEXT_T003")}>
          <Alert
            content={
              <BreakText
                above={getLang(lang, "TEXT_T004")}
                below={getLang(lang, "TEXT_T006")}
              />
            }
            type="success"
          />
          <Center>
            <Button
              isFullWidth
              onClick={handleCloseBrowser}
              text={getLang(lang, "TEXT_T007")}
              type="primary"
            />
          </Center>
        </Card>
      ) : (
        <Card isHeading={false} isLoading={isLoading}>
          {isFlagJudgmentTime && (
            <Heading type="danger" title={getLang(lang, "TEXT_T002")} />
          )}

          <StyledTitle
            titleLeft={getValueByLanguage(
              getFullName(TEXT_T001, implementationDateTime, true),
              `${TEXT_T001} ${implementationDateTime}`,
            )}
            titleRight={
              <ChangeLanguage
                setLanguageToEnglish={() => changeLanguage("en")}
                setLanguageToJapanese={() => changeLanguage("jp")}
              />
            }
          />
          <Alert
            content={attentionInfo?.bodyContent}
            heading={attentionInfo?.attentionTitle}
            isIcon={true}
            title={getLang(lang, "TEXT_T008")}
            type="danger"
          />
          <Center isMarginTop={!isFlagJudgmentTime}>
            <Button
              isHidden={isFlagJudgmentTime}
              onClick={getAttentionTravelers}
              text={getLang(lang, "TEXT_T009")}
              type="primary"
            />
          </Center>
        </Card>
      )}
    </Layout>
  )
}
