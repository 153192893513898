const CODE_HELP_H0001 = "HELP.H0001"
const CODE_HELP_H0002 = "HELP.H0002"
const CODE_HELP_H0003 = "HELP.H0003"
const CODE_HELP_H0004 = "HELP.H0004"
const CODE_HELP_H0005 = "HELP.H0005"
const CODE_HELP_H0006 = "HELP.H0006"
const CODE_HELP_H0007 = "HELP.H0007"
const CODE_HELP_H0008 = "HELP.H0008"
const CODE_HELP_H0009 = "HELP.H0009"
const CODE_HELP_H0010 = "HELP.H0010"
const CODE_HELP_H0011 = "HELP.H0011"
const CODE_HELP_H0012 = "HELP.H0012"
const CODE_HELP_H0013 = "HELP.H0013"
const CODE_HELP_H0014 = "HELP.H0014"
const CODE_HELP_H0015 = "HELP.H0015"
const CODE_HELP_H0016 = "HELP.H0016"
const CODE_HELP_H0017 = "HELP.H0017"
const CODE_HELP_H0018 = "HELP.H0018"
const CODE_HELP_H0019 = "HELP.H0019"
const CODE_HELP_H0020 = "HELP.H0020"
const CODE_HELP_H0021 = "HELP.H0021"
const CODE_HELP_H0022 = "HELP.H0022"
const CODE_HELP_H0023 = "HELP.H0023"
const CODE_HELP_H0024 = "HELP.H0024"
const CODE_HELP_H0025 = "HELP.H0025"
const CODE_HELP_H0026 = "HELP.H0026"
const CODE_HELP_H0027 = "HELP.H0027"
const CODE_HELP_H0028 = "HELP.H0028"
const CODE_HELP_H0029 = "HELP.H0029"
const CODE_HELP_H0030 = "HELP.H0030"
const CODE_HELP_H0031 = "HELP.H0031"
const CODE_HELP_H0032 = "HELP.H0032"
const CODE_HELP_H0033 = "HELP.H0033"
const CODE_HELP_H0034 = "HELP.H0034"
const CODE_HELP_H0035 = "HELP.H0035"

export const I18nCodeHelp = {
  CODE_HELP_H0001,
  CODE_HELP_H0002,
  CODE_HELP_H0003,
  CODE_HELP_H0004,
  CODE_HELP_H0005,
  CODE_HELP_H0006,
  CODE_HELP_H0007,
  CODE_HELP_H0008,
  CODE_HELP_H0009,
  CODE_HELP_H0010,
  CODE_HELP_H0011,
  CODE_HELP_H0012,
  CODE_HELP_H0013,
  CODE_HELP_H0014,
  CODE_HELP_H0015,
  CODE_HELP_H0016,
  CODE_HELP_H0017,
  CODE_HELP_H0018,
  CODE_HELP_H0019,
  CODE_HELP_H0020,
  CODE_HELP_H0021,
  CODE_HELP_H0022,
  CODE_HELP_H0023,
  CODE_HELP_H0024,
  CODE_HELP_H0025,
  CODE_HELP_H0026,
  CODE_HELP_H0027,
  CODE_HELP_H0028,
  CODE_HELP_H0029,
  CODE_HELP_H0030,
  CODE_HELP_H0031,
  CODE_HELP_H0032,
  CODE_HELP_H0033,
  CODE_HELP_H0034,
  CODE_HELP_H0035,
}
