/* eslint-disable max-lines */
import { RadioChangeEvent } from "antd"
import moment from "moment"
import { useEffect } from "react"
import { GeneralCode } from "../../../../../../../constant/GeneralCode"
import { Routers } from "../../../../../../../constant/Routers"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { getDateMoment } from "../../../../../../../utils/getDate"
import { InitialValues } from "../../../../constants/search/initialValues"
import {
  TravelersSearchFormDataType,
  UseTravelersSearchType,
} from "../../../../models/search"
import { useVariables } from "../useVariables"
import { convertDataSubmit } from "./convertDataSubmit"

const { SCR120 } = Routers
const { OTHER } = GeneralCode
const { TRAVELERS_SEARCH_INITIAL_VALUES } = InitialValues

export const useTravelersSearch =
  (): UseTravelersSearchType<TravelersSearchFormDataType> => {
    const {
      allCountries,
      cities,
      countryCode,
      departments,
      dispatchType,
      familyRelationship,
      form,
      isLoadingLayout,
      isOtherCity,
      isTravelerSearch,
      onBlur,
      onKeyDown,
      pathname,
      prefectureList,
      push,
      setCountryCode,
      setIsLoadingLayout,
      setIsOtherCity,
      setWorkPlaceLocation,
      state,
      workPlaceLocation,
    } = useVariables()

    const onClickReset = () => {
      setTimeout(() => {
        form.setFieldsValue(TRAVELERS_SEARCH_INITIAL_VALUES)
        setWorkPlaceLocation("")
        onCountryChange("")
        document.getElementById("kanjiSurname")?.focus()
      }, 200)
    }

    const onFinish = (values: TravelersSearchFormDataType) => {
      const data = convertDataSubmit(values)
      // 「SCR114_渡航者情報一覧画面」に遷移する。
      push({
        pathname: pathname,
        state: { searchCondition: data, sourceURL: SCR120 },
      })
    }

    const onWorkPlaceLocationChange = (event: RadioChangeEvent) => {
      const { value } = event.target
      setWorkPlaceLocation(value)
    }

    const onCountryChange = (value: string) => {
      setCountryCode(value)
      form.setFieldValue("workPlaceCity", "")
      onWorkPlaceCityChange("")
    }

    const onWorkPlaceCityChange = (value: string) => {
      const _isOtherCity = value === OTHER
      setIsOtherCity(_isOtherCity)
      form.setFieldValue("workPlace", "")
    }

    const goToTravelersList = () => {
      // 「SCR114_渡航者情報一覧画面」に遷移する。
      push({
        pathname: pathname,
        state: { searchCondition: state?.searchCondition, sourceURL: SCR120 },
      })
    }

    useEffect(() => {
      // ユーザの画面アクセス許可チェックを処理する。
      if (!isTravelerSearch) {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const { pathname, state } = backToLogin()
        push({ pathname, state })
      } else {
        const searchCondition = state?.searchCondition
        if (searchCondition) {
          form.setFieldsValue({
            ...searchCondition.accompanies,
            ...searchCondition.base,
            ...searchCondition.detail,
            trainingDate: searchCondition.detail?.trainingDate
              ? getDateMoment(
                  moment(searchCondition.detail.trainingDate).toString(),
                )
              : "",
          })

          setWorkPlaceLocation(searchCondition.detail?.workPlaceLocation)
          setCountryCode(searchCondition.detail?.workPlaceCountry)
          setIsOtherCity(searchCondition.detail?.workPlaceCity === OTHER)
        } else
          form.setFieldsValue({
            trainingType: "",
            workPlaceLocation: "",
          })
        setTimeout(() => {
          setIsLoadingLayout(false)
        }, 500)
      }
    }, [])

    return {
      allCountries,
      cities,
      countryCode,
      departments,
      dispatchType,
      familyRelationship,
      form,
      goToTravelersList,
      isLoadingLayout,
      isOtherCity,
      onBlur,
      onClickReset,
      onCountryChange,
      onFinish,
      onKeyDown,
      onWorkPlaceCityChange,
      onWorkPlaceLocationChange,
      prefectureList,
      workPlaceLocation,
    }
  }
