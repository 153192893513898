const ATTENTIONS_EDIT_REQUIRED_FIELDS = [
  "attentionTitle",
  "bodyContent",
  "categoryName",
  "severity",
]

export const RequiredFields = {
  ATTENTIONS_EDIT_REQUIRED_FIELDS,
}
