const TRAVELERS_EDIT_REQUIRED_FIELDS = [
  "insuranceCompanyName",
  "passportNumber",
  "personEmailAddress",
  "personPrefectureResidence",
  "romajiName",
  "romajiSurname",
  "urgentContactHome",
  "urgentContactName",
  "workPlaceLocation",
]

export const RequiredFields = {
  TRAVELERS_EDIT_REQUIRED_FIELDS,
}
