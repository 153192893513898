import { FC } from "react"
import { Translate } from "../../../../../constant/Translate"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Divider } from "../../../../../_webui/layout/components/Divider"
import { Grid } from "../../../../../_webui/layout/components/Grid"
import { ModalConfirm } from "../../../../../_webui/layout/components/Modal/Confirm"
import { Space } from "../../../../../_webui/layout/components/Space"
import { SafetiesTable } from "../../components/safety/Table"
import { SafetiesTitle } from "../../components/safety/Title"
import { Layout } from "./../../../../../_webui/layout/components/Layout/index"
import { useSafety } from "./modules/useSafety"
import { Breadcrumbs } from "../../../../../constant/Breadcrumbs"
import { AttentionForm } from "../../../attentions/components/attention/Form"
import { AttentionButtonGroup } from "../../../attentions/components/attention/ButtonGroup"
import { Frame } from "../../../../../_webui/layout/components/Frame"

const { MESSAGE_C0007, TEXT_T265, TEXT_T358 } = Translate
const { BRCR_SCR412 } = Breadcrumbs

export const Safety: FC = () => {
  const {
    columns,
    form,
    goBack,
    goToSafetiesClone,
    goToSafetiesEdit,
    goToSchedulesList,
    gridItems,
    isDisabledBtnSelect,
    isDisabledSubmit,
    isHiddenBackBtn,
    isHiddenCopyBtn,
    isHiddenScheduleListBtn,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenForm,
    onAddOrRemoveSafeties,
    onCancel,
    onCancelForm,
    onFinish,
    onOk,
    onOpen,
    onOpenForm,
    rowSelectionAttention,
    safetiesColumns,
    safetiesList,
    safetiesSelected,
    travelerInfoList,
  } = useSafety()

  return (
    <Layout
      breadcrumbItems={BRCR_SCR412}
      isLoadingLayout={isLoadingLayout}
      title={TEXT_T358}
    >
      <Card heading={TEXT_T358} isLoading={isLoading}>
        <SafetiesTitle
          isHiddenCopyBtn={isHiddenCopyBtn}
          goToSafetiesClone={goToSafetiesClone}
          goToSafetiesEdit={goToSafetiesEdit}
        />
        <Space isSpaceBottom>
          <Grid isItemKeySmall gridItems={gridItems} />
        </Space>
        <Divider />
        <SafetiesTable
          columns={columns}
          travelerInfoList={travelerInfoList}
          goToSchedulesList={goToSchedulesList}
          isHiddenScheduleListBtn={isHiddenScheduleListBtn}
        />
        {safetiesSelected && (
          <Frame titleLeft={TEXT_T265}>{safetiesSelected}</Frame>
        )}
        <AttentionButtonGroup
          isDisabledSubmit={isDisabledSubmit}
          isHiddenBackBtn={isHiddenBackBtn}
          isHiddenSubmitBtn={isHiddenCopyBtn}
          onGoBack={goBack}
          onOpen={onOpen}
          onOpenForm={onOpenForm}
        />
      </Card>
      <ModalConfirm isOpen={isOpen} onCancel={onCancel} onOk={onOk}>
        {MESSAGE_C0007}
      </ModalConfirm>
      <AttentionForm
        form={form}
        isDisabledBtnSelect={isDisabledBtnSelect}
        isOpenForm={isOpenForm}
        onAddOrRemoveSafeties={onAddOrRemoveSafeties}
        onCancelForm={onCancelForm}
        onFinish={onFinish}
        rowSelectionAttention={rowSelectionAttention}
        safetiesColumns={safetiesColumns}
        safetiesList={safetiesList}
      />
    </Layout>
  )
}
