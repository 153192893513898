import React, { FC, Fragment } from "react"
import { Translate } from "../../../../../../../constant/Translate"
import { ItemInput } from "../../../../../../../_webui/layout/components/Form/Item/Input"

const { TEXT_T061, TEXT_T062, TEXT_T063, TEXT_T064 } = Translate

export const CollapseInsurance: FC = () => (
  <Fragment>
    <ItemInput label={TEXT_T061} maxLength={100} name="insuranceCompanyName" />
    <ItemInput
      label={TEXT_T062}
      maxLength={20}
      name="insuranceCompanyPhoneNumber"
    />
    <ItemInput label={TEXT_T063} maxLength={25} name="insuranceTicketNumber" />
    <ItemInput label={TEXT_T064} maxLength={100} name="insuranceSonsignor" />
  </Fragment>
)
