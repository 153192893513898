import { Breadcrumbs } from "../../../../../../../../constant/Breadcrumbs"

const {
  BRCR_SCR420,
  BRCR_SCR420_FROM_SCR416,
  BRCR_SCR420_FROM_SCR416_HISTORY,
  BRCR_SCR420_FROM_SCR417,
} = Breadcrumbs

export const getBreadcrumbItems = (
  isFromSCR416?: boolean,
  isFromSCR416History?: boolean,
  isFromSCR417?: boolean,
) => {
  if (isFromSCR416) return BRCR_SCR420_FROM_SCR416
  if (isFromSCR416History) return BRCR_SCR420_FROM_SCR416_HISTORY
  if (isFromSCR417) return BRCR_SCR420_FROM_SCR417
  return BRCR_SCR420
}
