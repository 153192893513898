import { Translate } from "../../../../../../../../../constant/Translate"
import { getTextYesNo } from "../../../../../../../../../utils/getTextYesNo"
import { getFullName } from "../../../../../../../../../utils/getFullName"
import { KanjiPassportFullName } from "../../../../../../../../../_webui/layout/components/Typography/KanjiPassportFullName"
import { RomajiPassportFullName } from "../../../../../../../../../_webui/layout/components/Typography/RomajiPassportFullName"
import { TravelersDetailBaseType } from "../../../../../../models/detail/data"

const {
  TEXT_T002,
  TEXT_T003,
  TEXT_T006,
  TEXT_T009,
  TEXT_T025,
  TEXT_T036,
  TEXT_T039,
  TEXT_T040,
} = Translate

export const convertDataBase = (data: TravelersDetailBaseType) => ({
  travelerDetail: [
    { key: TEXT_T002, text: data.userId },
    {
      key: TEXT_T003,
      text: getFullName(data.kanjiSurname, data.kanjiName, true),
    },
    {
      key: TEXT_T006,
      text: getFullName(data.romajiSurname, data.romajiName),
    },
    { key: TEXT_T036, text: getTextYesNo(data.differentInforCheck) },
    {
      key: <KanjiPassportFullName />,
      text: getFullName(
        data.kanjiPassportSurname,
        data.kanjiPassportName,
        true,
      ),
    },
    {
      key: <RomajiPassportFullName />,
      text: getFullName(data.romajiPassportSurname, data.romajiPassportName),
    },
    { key: TEXT_T009, text: data.bumonName },
    { key: TEXT_T039, text: data.dispatchTypeName },
    { key: TEXT_T025, text: data.dispatchTypeDetailName },
    { key: TEXT_T040, text: data.passportNumber },
  ],
})
