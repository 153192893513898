import { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { AttentionAnswerStateType } from "../../../../models/answer"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { getBreadcrumbItems } from "./getBreadcrumbItems"

export const useVariables = () => {
  const { state } = useLocation<AttentionAnswerStateType>()
  const { push } = useHistory()
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const {
    isNotAccessScreen,
    isVisibleBtnBack,
    isVisibleBtnDetail,
    isVisibleBtnList,
  } = useVariablesAuthority()
  const breadcrumbItems = getBreadcrumbItems(
    state?.isFromSCR316,
    state?.isFromSCR316History,
    state?.sourceURL,
  )

  return {
    breadcrumbItems,
    isFromEmail: state?.isFromEmail,
    isLoadingLayout,
    isNotAccessScreen,
    isVisibleBtnBack,
    isVisibleBtnDetail,
    isVisibleBtnList,
    push,
    setIsLoadingLayout,
    state,
  }
}
