import { useAuthority } from "../../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const { isCommonRefer, isRoleAdmin, isRoleSubAdmin, isRoleUser } =
    useAuthority()

  // 画面にボタンを表示する条件
  const isHiddenBtn =
    (isRoleAdmin || isRoleSubAdmin || isRoleUser) && isCommonRefer
  const isHiddenReset = !isHiddenBtn
  const isHiddenSubmit = !isHiddenBtn

  return {
    isCommonRefer,
    isHiddenReset,
    isHiddenSubmit,
  }
}
