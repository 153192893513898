import { FC, Fragment } from "react"
import { Images } from "../../../../../../../constant/Images"
import { Translate } from "../../../../../../../constant/Translate"
import { Image } from "../../../../../../../_webui/layout/components/Image"
import { Title } from "../../../../../../../_webui/layout/components/Typography/Title"
import { RolesSettingTitleProps } from "../../../../models/roles/setting"
import { Styled } from "./styled"
import { Carousel } from "antd"

const {
  IMAGE_PATTERN_1,
  IMAGE_PATTERN_2,
  IMAGE_PATTERN_3,
  IMAGE_PATTERN_4,
  IMAGE_PATTERN_5,
  IMAGE_PATTERN_6,
  IMAGE_PATTERN_7,
  IMAGE_URL,
} = Images
const { StyledModal, StyledRolesSettingLink } = Styled
const { TEXT_T478, TEXT_T480 } = Translate

export const RolesSettingTitle: FC<RolesSettingTitleProps> = (props) => {
  const { isOpenLink, onCancelLink, onOpenLink } = props
  const listImage = [
    IMAGE_PATTERN_1,
    IMAGE_PATTERN_2,
    IMAGE_PATTERN_3,
    IMAGE_PATTERN_4,
    IMAGE_PATTERN_5,
    IMAGE_PATTERN_6,
    IMAGE_PATTERN_7,
  ]

  return (
    <Fragment>
      <Title
        titleLeft={
          <Fragment>
            <StyledRolesSettingLink onClick={onOpenLink}>
              {TEXT_T478} <Image src={IMAGE_URL} />
            </StyledRolesSettingLink>
            {TEXT_T480}
          </Fragment>
        }
      />
      <StyledModal
        isClose
        isFull
        isOpen={isOpenLink}
        isShowFooter={false}
        onCancel={onCancelLink}
      >
        <Carousel arrows={true} dots={false} infinite={false}>
          {listImage.map((item) => (
            <div key={item}>
              <Image src={item} />
            </div>
          ))}
        </Carousel>
      </StyledModal>
    </Fragment>
  )
}
