import "./style/index.scss"
import { ConfigProvider, DatePicker } from "antd"
import localeEn from "antd/lib/locale/en_US"
import localeJa from "antd/lib/locale/ja_JP"
import { FC } from "react"
import { FormItem } from ".."
import { Constant } from "../../../../../../constant"
import { Images } from "../../../../../../constant/Images"
import { Image } from "../../../Image"
import { format } from "./constants"
import { ItemDatePickerProps } from "./models"

const { IMAGE_CALENDAR, IMAGE_CLEAR_CIRCLE } = Images
const { CHECK_SELECTED_LANG } = Constant

export const ItemDatePicker: FC<ItemDatePickerProps> = (props) => {
  const {
    className,
    disabledDate,
    isBgBlue,
    isDisabled,
    isHiddenLabel,
    label,
    name,
    onBlur,
    onChange,
    onFocus,
    onKeyDown,
    required,
    rules,
    suffixIcon = <Image src={IMAGE_CALENDAR} />,
    tooltip,
  } = props

  const locale = CHECK_SELECTED_LANG ? localeJa : localeEn

  return (
    <ConfigProvider locale={locale}>
      <FormItem
        className={className}
        isBgBlue={isBgBlue}
        isHiddenLabel={isHiddenLabel}
        label={label}
        name={name}
        required={required}
        rules={rules}
        tooltip={tooltip}
      >
        <DatePicker
          clearIcon={
            <span
              aria-label="Clear calendar"
              className="anticon anticon-close-circle"
              role="img"
            >
              <Image src={IMAGE_CLEAR_CIRCLE} />
            </span>
          }
          disabled={isDisabled}
          disabledDate={disabledDate}
          format={format}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          placeholder=""
          suffixIcon={suffixIcon}
        />
      </FormItem>
    </ConfigProvider>
  )
}
