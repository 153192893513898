import { SchedulesExportExcelFRM301Type } from "../../../../../../../models/list/export"

export const INITIAL_VALUES: SchedulesExportExcelFRM301Type = {
  departureDateTimeAndArrivalDateTime: "",
  dispatchTypeDetailName: "",
  emailAddress1: "",
  emailAddress2: "",
  fullNameKanji: "",
  fullNameRomaji: "",
  groupName: "",
  locationPersonName: "",
  locationPhoneNumber1: "",
  locationPhoneNumber2: "",
  othersRemarks1: "",
  personEmailAddress: "",
  personPhoneNumber: "",
  phoneNumber: "",
  projectNameJpOrProjectNameEn: "",
  satellitePhoneNumber: "",
  travelPurposeName: "",
  travelerLocation: "",
  workPlaceCountryAndWorkPlaceCity: "",
}

export const initialValues = {
  INITIAL_VALUES,
}
