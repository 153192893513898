import { useState } from "react"
import { SafetiesDetailExportType } from "../../../../models/detail"
import { convertDataExport } from "./convertDataExport"

export const useExportFile = (props: SafetiesDetailExportType) => {
  const { safetyInfo, selectedRowKeys, safetyTravelers } = props
  const [isLoading, setIsLoading] = useState(false)

  const handleExportFile = () => {
    const _dataSource = selectedRowKeys?.length
      ? safetyTravelers.filter((item) =>
          selectedRowKeys.includes(item.safetyDetailId),
        )
      : safetyTravelers

    setIsLoading(true)
    try {
      convertDataExport(_dataSource, safetyInfo)
    } finally {
      setIsLoading(false)
    }
  }

  return {
    handleExportFile,
    isLoading,
    setIsLoading,
  }
}
