import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../../_webui/layout/components/Table/models"
import { Translate } from "../../../../../../../../constant/Translate"
import { UploadFileType } from "../../../../../models/files/upload"

const { TEXT_T055, TEXT_T501, TEXT_T065, TEXT_T504 } = Translate

export const useTableConfig = (
  isCountry: boolean,
): UseTableConfigType<UploadFileType> => {
  const countryNameColumn: ColumnType<UploadFileType>[] = isCountry
    ? [
        {
          dataIndex: "countryName",
          sorter: (a, b) => a.countryName.localeCompare(b.countryName),
          title: TEXT_T055,
          width: 200,
        },
      ]
    : []
  const columns: ColumnType<UploadFileType>[] = [
    {
      dataIndex: "fileName",
      sorter: (a, b) => a.fileName.localeCompare(b.fileName),
      title: TEXT_T501,
      width: 200,
    },
    {
      dataIndex: "fileTypeName",
      sorter: (a, b) => a?.fileTypeName.localeCompare(b?.fileTypeName),
      title: TEXT_T065,
      width: 200,
    },
    ...countryNameColumn,
    {
      dataIndex: "fileSize",
      render: (_, data) => `${Math.round(data.fileSize)}KB`,
      sorter: (a, b) => +a.fileSize - +b.fileSize,
      title: TEXT_T504,
      width: 200,
    },
  ]
  return {
    columns,
  }
}
