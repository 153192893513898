import { ColumnType } from "../../../../../../../../_webui/layout/components/Table/models"
import { BreakText } from "../../../../../../../../_webui/layout/components/Typography/BreakText"
import { Translate } from "../../../../../../../../constant/Translate"
import { getFullName } from "../../../../../../../../utils/getFullName"
import { getSortOrder } from "../../../../../../../../utils/getSortOrder"
import { SortedInfoType } from "../../../../../../../../utils/hook/useSort/models"
import { SchedulesListDataType } from "../../../../../models/list"

const { TEXT_T003, TEXT_T006, TEXT_T025, TEXT_T217 } = Translate

export const getColumItem1 = (
  isPathnameSchedules: boolean,
  sortedInfo: SortedInfoType,
): ColumnType<SchedulesListDataType>[] =>
  !isPathnameSchedules
    ? [
        {
          dataIndex: "fullName",
          render: (_, data) => (
            <BreakText
              above={getFullName(
                data.info.kanjiSurname,
                data.info.kanjiName,
                true,
              )}
              below={getFullName(data.info.romajiSurname, data.info.romajiName)}
            />
          ),
          sortOrder: getSortOrder(sortedInfo, "fullName"),
          sorter: (a, b) =>
            getFullName(a.info.romajiSurname, a.info.romajiName).localeCompare(
              getFullName(b.info.romajiSurname, b.info.romajiName),
            ),
          title: <BreakText above={TEXT_T003} below={TEXT_T006} />,
          width: 250,
        },
        {
          dataIndex: "dispatchTypeDetailName",
          render: (_, data) => data.info.dispatchTypeDetailName,
          sortOrder: getSortOrder(sortedInfo, "dispatchTypeDetailName"),
          sorter: (a, b) =>
            a.info.dispatchTypeDetailName.localeCompare(
              b.info.dispatchTypeDetailName,
            ),
          title: TEXT_T025,
          width: 250,
        },
      ]
    : []
export const getColumItem2 = (
  isPathnameSchedules: boolean,
  sortedInfo: SortedInfoType,
): ColumnType<SchedulesListDataType>[] =>
  !isPathnameSchedules
    ? [
        {
          dataIndex: "currentLocation",
          render: (_, data) => data.info.currentLocation,
          sortOrder: getSortOrder(sortedInfo, "currentLocation"),
          sorter: (a, b) =>
            a.info.currentLocation.localeCompare(b.info.currentLocation),
          title: TEXT_T217,
          width: 250,
        },
      ]
    : []
