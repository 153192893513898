import styled, { css } from "styled-components"
import { Regex } from "../../../../../../constant/Regex"
import { Colors } from "../../../../../../constant/Styled/Colors"
import { Fonts } from "../../../../../../constant/Styled/Fonts"
import { StyledTitleProps, StyledTitleRightProps } from "../models"

const { REGEX_IS_DESKTOP } = Regex
const { DANGER, PRIMARY } = Colors
const { FS_LARGE, FW_MEDIUM } = Fonts

const StyledTitle = styled.div<StyledTitleProps>`
  align-items: ${(props) => props.alignItems};
  color: ${(props) =>
    (props.type === "danger" && DANGER) ||
    (props.type === "primary" && PRIMARY)};
  display: flex;
  font-size: ${FS_LARGE};
  font-weight: ${FW_MEDIUM};
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 12px;
`

const StyledTitleRight = styled.div<StyledTitleRightProps>`
  width: ${(props) =>
    !REGEX_IS_DESKTOP && props.isTitleRightFullWidth && "335px"};
  ${!REGEX_IS_DESKTOP &&
  css`
    width: 100%;
    text-align: right;
  `}
`

export const Styled = {
  StyledTitle,
  StyledTitleRight,
}
