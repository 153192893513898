import { useGridConfig } from "../useGridConfig"
import { useApi } from "../useApi"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { getBreadcrumbItems } from "./getBreadcrumbItems"
import { Translate } from "../../../../../../../constant/Translate"

const { TEXT_T406, TEXT_T407 } = Translate

export const useVariables = () => {
  const { isCommonTravelerRefer, isRoleAdmin, isRoleUser, isRoleSubAdmin } =
    useVariablesAuthority()

  const {
    form,
    handleGetSafetyDetail,
    isLoading,
    isLoadingLayout,
    onFinish,
    push,
    safetyDetailInfo,
    safetyInfo,
    state,
  } = useApi()
  const isHiddenSubmit = state?.isHiddenSubmit
  const { gridItems } = useGridConfig({
    isRoleSubAdmin,
    isRoleUser,
    safetyDetailInfo,
    safetyInfo,
  })
  const breadcrumbItems = getBreadcrumbItems(
    state?.isFromSCR416,
    state?.isFromSCR416History,
    state?.isFromSCR417,
    isRoleSubAdmin,
  )

  const titleHeading =
    isRoleSubAdmin && state?.isFromSCR417 ? TEXT_T407 : TEXT_T406

  return {
    breadcrumbItems,
    form,
    gridItems,
    handleGetSafetyDetail,
    isCommonTravelerRefer,
    isHiddenSubmit,
    isLoading,
    isLoadingLayout,
    isRoleAdmin,
    isRoleSubAdmin,
    onFinish,
    push,
    safetyDetailInfo,
    state,
    titleHeading,
  }
}
