/* eslint-disable max-lines */
import { useHistory } from "react-router-dom"
import { useApi } from "../useApi"
import { useRouters } from "../useRouters"
import { useTableConfig } from "../useTableConfig"
import { useVariablesUtils } from "../useVariablesUtils"
import { useHandle } from "../../../list/modules/useHandle"
import { FilesType } from "../../../../../../../../utils/downloadFiles/models"
import { Translate } from "../../../../../../../../constant/Translate"

const { TEXT_T497 } = Translate

export const useVariables = () => {
  const { push } = useHistory()
  const { allCountries, fileTypeList, setFileTypeList, typeInfo } =
    useVariablesUtils()
  const {
    currentUsage,
    deleteFiles,
    fileInfoList,
    form,
    getFiles,
    initDateTime,
    isCommonUploadRegister,
    isCountry,
    isDisabledBtnDelete,
    isDisabledBtnDownload,
    isDisabledBtnPreview,
    isDisabledCountries,
    isLoading,
    isLoadingLayout,
    isOpen,
    isResetTable,
    isRoleAdmin,
    isVisibleBtnDelete,
    isVisibleBtnReference,
    isVisibleBtnRegister,
    onCancel,
    onChangeCountryCode,
    onChangeFileType,
    onChangePagination,
    onChangeTable,
    onOpen,
    perItem,
    rowSelection,
    selectedRowKeys,
    setIsDisabledCountries,
    typeInfoSubAdmin,
  } = useApi()
  const _fileInfoList: FilesType[] = fileInfoList.map((item) => ({
    fileName: item.fileName,
    filePath: item.filePath,
    fileTypeName: item.fileTypeName,
    id: item.uploadId,
  }))
  const {
    handleDownload,
    handlePreviewFile,
    isLoading: isLoadingExport,
    isOpenPreviewFile,
    onCancelPreviewFile,
  } = useHandle({
    fileInfoList: _fileInfoList,
    fileName: TEXT_T497,
    initDateTime,
    selectedRowKeys,
  })
  const { goToFilesUpload } = useRouters()

  const { columns } = useTableConfig(isCountry)

  return {
    allCountries,
    columns,
    currentUsage,
    deleteFiles,
    fileInfoList,
    fileTypeList,
    form,
    getFiles,
    goToFilesUpload,
    handleDownload,
    handlePreviewFile,
    isCommonUploadRegister,
    isCountry,
    isDisabledBtnDelete,
    isDisabledBtnDownload,
    isDisabledBtnPreview,
    isDisabledCountries,
    isLoading: isLoading || isLoadingExport,
    isLoadingLayout,
    isOpen,
    isOpenPreviewFile,
    isResetTable,
    isRoleAdmin,
    isVisibleBtnDelete,
    isVisibleBtnReference,
    isVisibleBtnRegister,
    onCancel,
    onCancelPreviewFile,
    onChangeCountryCode,
    onChangeFileType,
    onChangePagination,
    onChangeTable,
    onOpen,
    perItem,
    push,
    rowSelection,
    setFileTypeList,
    setIsDisabledCountries,
    typeInfo,
    typeInfoSubAdmin,
  }
}
