import { FC } from "react"
import { Alert } from "../../../../../../_webui/layout/components/Alert"
import { Card } from "../../../../../../_webui/layout/components/Card"
import { Translate } from "../../../../../../constant/Translate"
import { getDateTimeZoneByUserInformation } from "../../../../../../utils/getDate"
import { getFullName } from "../../../../../../utils/getFullName"
import {
  AttentionsInfoDataSourceType,
  UseAttentionsInfoType,
} from "../../../models/info"
import { Heading } from "../../../../../../_webui/layout/components/Typography/Heading"
import { getValueByLanguage } from "../../../../../../utils/getValueByLanguage"
import { Styled } from "./styled"
import { Table } from "../../../../../../_webui/layout/components/Table"
import { ButtonGroup } from "../../../../../../_webui/layout/components/Button/Group"
import { Title } from "../../../../../../_webui/layout/components/Typography/Title"

const { StyledAttentionsInfo } = Styled
const { TEXT_T028, TEXT_T072, TEXT_T333, TEXT_T353, TEXT_T354, TEXT_T664 } =
  Translate

export const AttentionsInfoCard: FC<
  UseAttentionsInfoType<AttentionsInfoDataSourceType>
> = (props) => {
  const {
    attentionInfo,
    attentionInfoList,
    columns,
    getAttentionTravelers,
    goBack,
    isHiddenSubmit,
    isLoading,
    isRoleSubAdmin,
  } = props

  return (
    <Card isHeading={false} isLoading={isLoading}>
      {attentionInfo?.flagJudgmentTime === 0 && (
        <Heading type="danger" title={TEXT_T353} />
      )}

      <Title
        titleLeft={getValueByLanguage(
          getFullName(
            TEXT_T333,
            getDateTimeZoneByUserInformation(
              attentionInfo?.implementationDateTime,
            ),
            true,
          ),
          `${TEXT_T333} ${getDateTimeZoneByUserInformation(
            attentionInfo?.implementationDateTime,
          )}`,
        )}
      />
      {isRoleSubAdmin && (
        <StyledAttentionsInfo>
          <Table
            columns={columns}
            dataSource={attentionInfoList}
            heading={TEXT_T028}
            isHeadingLeft
            pagination={false}
            rowKey="travelerId"
          />
        </StyledAttentionsInfo>
      )}
      <Alert
        content={attentionInfo?.bodyContent}
        heading={attentionInfo?.attentionTitle}
        isIcon={true}
        title={TEXT_T664}
        type="danger"
      />
      <ButtonGroup
        isMargin
        buttonItems={[
          {
            isHidden: isHiddenSubmit,
            onClick: getAttentionTravelers,
            text: TEXT_T354,
            type: "primary",
          },
          {
            absolutePosition: "right",
            isAbsolute: true,
            isSmall: true,
            onClick: goBack,
            text: TEXT_T072,
            type: "ghost",
          },
        ]}
      />
    </Card>
  )
}
