/* eslint-disable max-nested-callbacks */
/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable max-statements */
import { useEffect } from "react"
import { OptionItemType } from "../../../../../../../_webui/layout/components/Select/models"
import { GeneralCode } from "../../../../../../../constant/GeneralCode"
import { Routers } from "../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { departmentsApi } from "../../../../../../../infrastructure/handlers/departments"
import { DepartmentsResType } from "../../../../../../../infrastructure/handlers/departments/models"
import { generalMastersApi } from "../../../../../../../infrastructure/handlers/generalMasters"
import { GeneralMastersResType } from "../../../../../../../infrastructure/handlers/generalMasters/models"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { getValueByLanguage } from "../../../../../../../utils/getValueByLanguage"
import { replaceString } from "../../../../../../../utils/replaceString"
import { setFieldsErrors } from "../../../../../../../utils/setFieldsErrors"
import { travelerApi } from "../../../../../travelers/apis"
import { scheduleApi } from "../../../../apis"
import { TravelerInfoResponseType } from "../../../../components/add/Form/ContentCollapse/Personal/models"
import { Variables } from "../../../../constants/add/variables"
import { ScheduleDataType } from "../../../../models/add"
import { ScheduleDataAddType } from "../../../../models/add/DataAdd"
import { getScheduleDataAdd } from "../../../add/utils/getDataAdd"
import { getTravelerInfo } from "../../../add/utils/getTravelerInfo"
import { useVariables } from "../useVariables"
import { CitiesResType } from "../../../../../../../infrastructure/handlers/countries/models"
import { countriesApi } from "../../../../../../../infrastructure/handlers/countries"
import { SelectItems } from "../../../../../../../constant/SelectItems"
import { TravelersDetailResType } from "../../../../../travelers/models/detail"
import { Translate } from "../../../../../../../constant/Translate"
import { setNameRowDetail } from "../../../add/utils/setNameRowDetail"
import { translateMessage } from "../../../../../../../utils/translateMessage"
import { getWorkPlaceLocation } from "../../../../../../../utils/getWorkPlaceLocation"
import { Variables as TravelerEditVariables } from "../../../../../travelers/constants/edit/variables"
import { UseSchedulesEditType } from "../../../../models/edit"

const { SCR211, SCR215, SCR920 } = Routers
const { MESSAGE_E0138 } = Translate
const { OTHER_CITY_SELECT_ITEMS } = SelectItems
const { DPTD, DPTD_SUBADMIN, PPTV, PPTV_SCR212 } = GeneralCode
const { DPTD_OTHERS_STAFF_NUMBER, E0017, E0104, E0105, E0106, E0107, E0152 } =
  Variables
const { KEYS_ACCOMPANYING_FAMILY } = TravelerEditVariables

export const useSchedulesEdit = (): UseSchedulesEditType => {
  const {
    allCountries,
    assignmentValidMsg,
    breadcrumbItems,
    dataSubmit,
    defaultActiveKey,
    departments,
    detailColumnsFirst,
    detailColumnsSecond,
    detailDataSource,
    detailIndexIdentity,
    detailIndexTabActive,
    detailRowSelection,
    detailSelectedRowKeys,
    detailTabsItems,
    detailValidMsg,
    dispatchType,
    form,
    getFlightInfo,
    getInitialValues,
    getPurposeTravelList,
    goToSchedulesClone,
    handleAddDetailItem,
    handleAddLodgingItem,
    handleAddStayItem,
    handleCheckInsuranceAndUrgent,
    handleCheckRequiredDispatchDate,
    handleCheckTwoWay,
    handleDeleteDetailItem,
    handleDeleteLodgingItem,
    handleDeleteStayItem,
    handleError,
    handleErrorValidate,
    handleSetDetailRequiredFields,
    handleSetLodgingRequiredFields,
    handleSetStayRequiredFields,
    id,
    isAccompanyingFamily,
    isActiveBumon,
    isDifferentInfoChecked,
    isDisabledSubmit,
    isInsuranceAndUrgent,
    isInsuranceRequired,
    isLoading,
    isLoadingLayout,
    isNotAccessScreen,
    isOpen,
    isOpenRoundTrip,
    isOtherStaffNumber,
    isRequiredDispatchDate,
    isRequiredItemTableByDate,
    isRequiredItemTableByPPTV,
    isRequiredItemTableLodgings,
    isRoleAdmin,
    isSchedulesAdd,
    isSchedulesEdit,
    isSubmitValidate,
    isTableLoading,
    isTwoWay,
    isValidForm,
    isVisibleBtn,
    isVisibleBtnCancel,
    isVisibleBtnConfirm,
    isVisibleBtnCopy,
    isVisibleOtherRemarks,
    listPPTVIsValue3Equal1,
    listPPTVIsValue4Equal2,
    lodgingColumns,
    lodgingDataSource,
    lodgingIndexIdentity,
    lodgingIndexTabActive,
    lodgingRowSelection,
    lodgingSelectedRowKeys,
    lodgingTabsItems,
    lodgingValidMsg,
    onBlur,
    onCancel,
    onCancelRoundTrip,
    onChangeCollapse,
    onChangeDate,
    onChangeDifferentInfo,
    onChangeTravelPurpose,
    onClickDetailTab,
    onClickLodgingTab,
    onClickStayTab,
    onDispatchTypeDetailChange,
    onFinish,
    onKeyDown,
    onOkRoundTrip,
    onValidateChangeDate,
    onValuesChange,
    pathname,
    purposeTravelList,
    push,
    scheduleDetails,
    scheduleLodgings,
    schedulesData,
    scheduleStays,
    setAssignmentValidMsg,
    setDataScheduleDetails,
    setDataScheduleLodgings,
    setDataScheduleStays,
    setDefaultCountries,
    setDepartments,
    setDetailValidMsg,
    setDispatchType,
    setDispatchTypeDetailRes,
    setIsAccompanyingFamily,
    setIsActiveBumon,
    setIsDifferentInfoChecked,
    setIsLoading,
    setIsLoadingLayout,
    setIsOtherStaffNumber,
    setIsRequiredItemTableByPPTV,
    setIsRequiredItemTableLodgings,
    setIsTableLoading,
    setIsTwoWay,
    setLodgingValidMsg,
    setPurposeTravelRes,
    setSchedulesData,
    setTravelerBumonCd,
    setTravelerId,
    setWorkPlaceCountryCode,
    state,
    stayColumns,
    stayDataSource,
    stayIndexIdentity,
    stayIndexTabActive,
    stayRowSelection,
    staySelectedRowKeys,
    stayTabsItems,
    transportationList,
    travelerBumonCd,
    travelerId,
  } = useVariables()

  const goBack = () => {
    // 「SCR211_渡航予定情報の参照画面」に戻る。
    // 「SCR210_渡航予定情報の一覧画面」に戻る。
    const pathname = state?.isFromSCR211
      ? replaceString(SCR211, id)
      : state?.sourceURL && replaceString(SCR215, id) !== state.sourceURL
      ? state?.sourceURL
      : state?.schedulesURL
      ? state.schedulesURL
      : SCR920
    push({
      pathname,
      state: { ...state, sourceURL: pathname },
    })
  }

  const onOk = () => {
    const check = handleCheckTwoWay()
    setIsTwoWay(check)
    if (check) {
      setTimeout(() => {
        document.querySelectorAll(".ant-collapse-item")[4]?.scrollIntoView()
      }, 300)
    } else if (travelerId) {
      const data: ScheduleDataAddType = getScheduleDataAdd({
        allCountries,
        dataSubmit,
        departments,
        detailDataSource,
        detailIndexIdentity,
        id,
        isOtherStaffNumber,
        isSchedulesAdd,
        isSchedulesEdit,
        lodgingIndexIdentity,
        purposeTravelList,
        schedulesData,
        stayIndexIdentity,
        transportationList,
        travelerId,
      })
      // データをデータベースに登録する。
      setAssignmentValidMsg("")
      setIsLoading(true)
      scheduleApi
        .updateSchedule(id, data)
        .then((res) => {
          // 「SCR215_渡航予定情報の変更後画面」に遷移する。
          push(replaceString(SCR215, res.travelScheduleId), {
            ...state,
            isFromSCR214: true,
            sourceURL: pathname,
          })
        })
        .catch((error: AxiosErrorType) => {
          const data = error?.debug?.response?.data
          const messages = [E0017, E0104, E0105, E0106, E0107, E0152]
          const entityName = error?.debug?.response?.data?.entityName
          if (data.message === "E0138") {
            const _entityName = entityName.split(",")
            for (let index = 0; index < _entityName.length; index++) {
              form.setFields([
                {
                  errors: [MESSAGE_E0138],
                  name: `${setNameRowDetail(
                    +_entityName[index],
                  )}.departureDate`,
                },
              ])
            }
            form.scrollToField(
              `${setNameRowDetail(+_entityName[0])}.departureDate`,
            )
          } else {
            if (entityName && !["detail", "lodging"].includes(entityName))
              setFieldsErrors(error, form)
            else if (messages.includes(data?.message)) {
              if (entityName === "detail") {
                setDetailValidMsg(translateMessage(`MESSAGE.${data.message}`))
              } else if (entityName === "lodging") {
                setLodgingValidMsg(translateMessage(`MESSAGE.${data.message}`))
              } else {
                handleErrorValidate(data.message, entityName)
              }
            } else handleError(error)
          }
        })
        .finally(() => setIsLoading(false))
    }
  }

  const getTravelerBase = async (travelerId: string) => {
    // APIを呼び出し、渡航者の基本情報を取得し
    return travelerApi
      .getTravelerBase(travelerId)
      .then((travelerInfoResponse: TravelerInfoResponseType) => {
        const travelerInfo = getTravelerInfo(travelerInfoResponse)
        form.setFieldsValue(travelerInfo)
        // APIを呼び出し、データベースから渡航者情報を取得する。
        travelerApi
          .getTravelerDetails(travelerInfoResponse.travelerId)
          .then((res: TravelersDetailResType) => {
            handleCheckInsuranceAndUrgent(res)
            const { workPlaceCountry, othersStaffNumber, workPlaceLocation } =
              res.detail
            const { bumonCd, dispatchTypeDetailId } = res.base
            form.setFieldsValue({
              travelerBumonCd: bumonCd,
              travelerDispatchTypeDetailId: dispatchTypeDetailId,
            })
            const _workPlaceLocation = getWorkPlaceLocation(workPlaceLocation)
            form.setFieldValue("workPlaceLocation", _workPlaceLocation)
            setWorkPlaceCountryCode(workPlaceCountry)
            setTravelerBumonCd(bumonCd)
            setIsOtherStaffNumber(!!othersStaffNumber)
            // APIを呼び出し、都市一覧情報を取得する。
            countriesApi
              .getCitiesByCountry(workPlaceCountry)
              .then((res: CitiesResType[]) => {
                const data = res.map((item) => ({
                  text: item.cityDistrict,
                  value: item.cityCode,
                }))
                setDefaultCountries([...data, OTHER_CITY_SELECT_ITEMS])
              })
              .catch((error: AxiosErrorType) => {
                handleError(error)
              })
          })
          .catch((error: AxiosErrorType) => {
            handleError(error)
          })
        return travelerInfoResponse
      })
      .catch((error: AxiosErrorType) => {
        handleError(error)
      })
  }

  const getSchedule = async (id: string) => {
    return scheduleApi
      .getSchedule(id)
      .then((res: ScheduleDataType) => res)
      .catch((error: AxiosErrorType) => {
        handleError(error)
      })
  }

  const getData = () => {
    const getDispatchTypeDetailListAPI: Promise<GeneralMastersResType[]> =
      generalMastersApi.getGeneralMaster(DPTD)
    // APIを呼び出し、渡航目的一覧情報を取得し
    const getPurposeTravelListAPI: Promise<GeneralMastersResType[]> =
      generalMastersApi.getGeneralMaster(PPTV)
    // APIを呼び出し、部門一覧情報を取得し
    const getDepartmentsAPI: Promise<DepartmentsResType[]> =
      departmentsApi.getAllDepartments()

    Promise.all([
      getDispatchTypeDetailListAPI,
      getPurposeTravelListAPI,
      getDepartmentsAPI,
    ])
      .then(async (res) => {
        const optionItemDepartment: OptionItemType[] = res[2].map((item) => ({
          text: String(
            getValueByLanguage(
              item.departmentNameJapan,
              item.departmentNameEnglish,
            ),
          ),
          value: item.bumonCd,
        }))

        setDepartments(optionItemDepartment)

        setDispatchTypeDetailRes(res[0])
        setPurposeTravelRes(res[1])

        setIsTableLoading(true)

        const _scheduleData = await getSchedule(id)

        if (_scheduleData) {
          const _travelerId = _scheduleData.info?.travelerId
          setTravelerId(_travelerId)

          const travelerData = await getTravelerBase(_travelerId)

          if (travelerData) {
            setIsAccompanyingFamily(
              KEYS_ACCOMPANYING_FAMILY.includes(
                travelerData.dispatchTypeDetailId,
              ),
            )
          }

          const optionItem: OptionItemType[] = res[0].map((item) => ({
            text: String(getValueByLanguage(item.value1, item.value2)),
            value: item.key1,
          }))
          const dispatchTypeDetailId =
            travelerData &&
            DPTD_SUBADMIN.includes(travelerData.dispatchTypeDetailId)
              ? [travelerData.dispatchTypeDetailId]
              : []
          const _dispatchType = optionItem.filter((item) =>
            [...DPTD_OTHERS_STAFF_NUMBER, ...dispatchTypeDetailId].includes(
              String(item.value),
            ),
          )
          setDispatchType(_dispatchType)

          getFlightInfo(_travelerId)

          const _purposeTravelList = getPurposeTravelList(
            travelerData?.dispatchTypeDetailId,
            res[0],
            res[1],
          )

          if (_purposeTravelList) {
            const initialValues = getInitialValues(
              _scheduleData.info,
              _purposeTravelList,
              optionItemDepartment,
            )
            form.setFieldsValue(initialValues)
          }

          setSchedulesData(_scheduleData)
          onValidateChangeDate()
          setIsRequiredItemTableLodgings(
            !PPTV_SCR212.includes(_scheduleData?.info?.travelPurposeId),
          )
          if (_scheduleData.info?.differentInforCheck)
            setIsDifferentInfoChecked(true)

          if (!_scheduleData.details.length) handleAddDetailItem()
          if (!_scheduleData.lodgings.length) handleAddLodgingItem()
          if (!_scheduleData.stays.length) handleAddStayItem()
        }

        setIsTableLoading(false)
      })
      .catch((error: AxiosErrorType) => {
        handleError(error)
      })
      .finally(() => {
        setIsLoadingLayout(false)
      })
  }

  useEffect(() => {
    onValuesChange()
  })

  useEffect(() => {
    if (scheduleDetails.length) setDataScheduleDetails()
  }, [scheduleDetails])

  useEffect(() => {
    if (scheduleLodgings.length) setDataScheduleLodgings()
  }, [scheduleLodgings])

  useEffect(() => {
    if (scheduleStays.length) setDataScheduleStays()
  }, [scheduleStays])

  useEffect(() => {
    const timeId = setTimeout(() => {
      handleSetDetailRequiredFields()
      onValidateChangeDate()
    }, 100)

    return () => clearTimeout(timeId)
  }, [detailDataSource])

  useEffect(() => {
    const timeId = setTimeout(() => {
      handleSetLodgingRequiredFields()
    }, 100)

    return () => clearTimeout(timeId)
  }, [
    lodgingDataSource,
    isRequiredItemTableByDate,
    isRequiredItemTableByPPTV,
    isRequiredItemTableLodgings,
  ])

  useEffect(() => {
    const timeId = setTimeout(() => {
      handleSetStayRequiredFields()
    }, 100)

    return () => clearTimeout(timeId)
  }, [stayDataSource])

  useEffect(() => {
    if (schedulesData.info.travelPurposeId) {
      if (
        listPPTVIsValue3Equal1.length &&
        listPPTVIsValue3Equal1.includes(schedulesData.info.travelPurposeId)
      ) {
        if (!form.getFieldValue("bumonCd")) {
          form.setFieldValue("bumonCd", travelerBumonCd)
        }
        setIsActiveBumon(true)
      }
      if (
        listPPTVIsValue4Equal2.length &&
        listPPTVIsValue4Equal2.includes(schedulesData.info.travelPurposeId)
      )
        setIsRequiredItemTableByPPTV(false)
    }
  }, [
    schedulesData.info.travelPurposeId,
    listPPTVIsValue3Equal1,
    listPPTVIsValue4Equal2,
  ])

  useEffect(() => {
    handleCheckRequiredDispatchDate(schedulesData.info.travelPurposeId)
  }, [schedulesData.info.travelPurposeId])

  useEffect(() => {
    // ユーザーの画面アクセス許可チェックを処理する。
    if (isNotAccessScreen) {
      // 「SCR973_エラー情報の表示画面」に遷移する。
      const { pathname, state } = backToLogin()
      push({ pathname, state })
    } else {
      getData()
    }
  }, [])

  return {
    assignmentValidMsg,
    breadcrumbItems,
    defaultActiveKey,
    departments,
    detailColumnsFirst,
    detailColumnsSecond,
    detailDataSource,
    detailIndexTabActive,
    detailRowSelection,
    detailSelectedRowKeys,
    detailTabsItems,
    detailValidMsg,
    dispatchType,
    form,
    goBack,
    goToSchedulesClone,
    handleAddDetailItem,
    handleAddLodgingItem,
    handleAddStayItem,
    handleDeleteDetailItem,
    handleDeleteLodgingItem,
    handleDeleteStayItem,
    isAccompanyingFamily,
    isActiveBumon,
    isDifferentInfoChecked,
    isDisabledSubmit,
    isInsuranceAndUrgent,
    isInsuranceRequired,
    isLoading,
    isLoadingLayout,
    isOpen,
    isOpenRoundTrip,
    isOtherStaffNumber,
    isRequiredDispatchDate,
    isRoleAdmin,
    isSchedulesAdd,
    isSchedulesEdit,
    isSubmitValidate,
    isTableLoading,
    isTwoWay,
    isValidForm,
    isVisibleBtn,
    isVisibleBtnCancel,
    isVisibleBtnConfirm,
    isVisibleBtnCopy,
    isVisibleOtherRemarks,
    lodgingColumns,
    lodgingDataSource,
    lodgingIndexTabActive,
    lodgingRowSelection,
    lodgingSelectedRowKeys,
    lodgingTabsItems,
    lodgingValidMsg,
    onBlur,
    onCancel,
    onCancelRoundTrip,
    onChangeCollapse,
    onChangeDate,
    onChangeDifferentInfo,
    onChangeTravelPurpose,
    onClickDetailTab,
    onClickLodgingTab,
    onClickStayTab,
    onDispatchTypeDetailChange,
    onFinish,
    onKeyDown,
    onOk,
    onOkRoundTrip,
    onValuesChange,
    purposeTravelList,
    stayColumns,
    stayDataSource,
    stayIndexTabActive,
    stayRowSelection,
    staySelectedRowKeys,
    stayTabsItems,
  }
}
