import { Breadcrumbs } from "../../../../../../../../constant/Breadcrumbs"
import { Routers } from "../../../../../../../../constant/Routers"

const { BRCR_SCR416, BRCR_SCR416_HISTORY } = Breadcrumbs
const { SCR416, SCR416_HISTORY } = Routers

export const getBreadcrumbItems = (pathname?: string) => {
  if (pathname === SCR416) return BRCR_SCR416

  if (pathname === SCR416_HISTORY) return BRCR_SCR416_HISTORY
}
