/* eslint-disable complexity */
import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const {
    isCommonRefer,
    isRoleSubAdmin,
    isAttentionsSendAll,
    isScheduleSearch,
  } = useAuthority()
  const isHiddenBackBtn = !(
    isRoleSubAdmin &&
    (isScheduleSearch || isAttentionsSendAll)
  )

  const isHiddenResetBtn = !(isRoleSubAdmin && isScheduleSearch)
  const isHiddenSubmitBtn = !(isRoleSubAdmin && isAttentionsSendAll)
  const isHiddenCopyBtn = !(isRoleSubAdmin && isAttentionsSendAll)

  return {
    isAttentionsSendAll,
    isCommonRefer,
    isHiddenBackBtn,
    isHiddenCopyBtn,
    isHiddenResetBtn,
    isHiddenSubmitBtn,
  }
}
