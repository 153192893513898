import { useEffect } from "react"
import { Routers } from "../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { replaceString } from "../../../../../../../utils/replaceString"
import { travelerApi } from "../../../../apis"
import {
  TravelersAddSuccessResType,
  TravelersDetailsFormProps,
} from "../../../../models/addSuccess"
import { useVariables } from "../useVariables"
import { convertDataSource } from "./convertDataSource"

const { SCR112, SCR113, SCR116, SCR920, SCR973 } = Routers

export const useTravelersAddSuccess = (): TravelersDetailsFormProps => {
  const {
    gridItems,
    id,
    isLoadingLayout,
    isTravelerRefer,
    push,
    setDetail,
    setIsLoadingLayout,
  } = useVariables()

  const goToHome = () => {
    // 「SCR920_ホーム画面」に遷移する。
    push(SCR920)
  }

  const goToTravelersAdd = () => {
    // 「SCR112_渡航者の登録画面」に遷移する。
    push(SCR112)
  }

  const goToTravelersEdit = () => {
    //「SCR116_渡航者情報の変更画面」に遷移する。
    push({
      pathname: replaceString(SCR116, id),
      state: {
        isFromSCR113: true,
        sourceURL: SCR113,
      },
    })
  }

  const getTravelerBase = () => {
    // APIを呼び出し、データベースから渡航者情報を取得する。
    travelerApi
      .getTravelerBase(id)
      .then((res: TravelersAddSuccessResType) => {
        // 渡航者情報の変数を初期化する。
        const data = convertDataSource(res)
        setDetail(data)
        setIsLoadingLayout(false)
      })
      .catch((error: AxiosErrorType) => {
        const stateError = pushStateError(error)
        // 「SCR973_エラー情報の表示画面」に遷移する
        push({ pathname: SCR973, state: stateError })
      })
  }

  useEffect(() => {
    // ユーザーの画面アクセス許可チェックを処理する。
    if (!isTravelerRefer) {
      // 「SCR973_エラー情報の表示画面」に遷移する。
      const { pathname, state } = backToLogin()
      push({ pathname, state })
    } else getTravelerBase()
  }, [])

  return {
    goToHome,
    goToTravelersAdd,
    goToTravelersEdit,
    gridItems,
    isLoadingLayout,
  }
}
