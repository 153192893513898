import { getFullName } from "../../../../../../../../../utils/getFullName"
import {
  GetUserAuthorityTypeSettingResType,
  SubAdminSettingListType,
} from "../../../../../../models/subAdmin/setting"

export const convertDataSource = (
  data: GetUserAuthorityTypeSettingResType[],
): SubAdminSettingListType[] =>
  data.map((item) => ({
    dispatchTypeDetail: item.dispatchTypeDetailName,
    fullNameKanji: getFullName(item.kanjiSurname, item.kanjiName, true),
    fullNameRomaji: getFullName(item.romajiSurname, item.romajiName),
    patternName: item.patternName,
    staffNumber: item.staffNumber,
    travelerId: item.travelerId,
    userId: item.userId,
  }))
