/* eslint-disable max-lines */
import { useEffect } from "react"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import {
  AttentionsDetailCountType,
  AttentionsDetailDataSourceType,
  AttentionsInfoDataType,
  UseAttentionsDetailType,
} from "../../../../models/detail"
import { useVariables } from "../useVariables"
import { attentionApi } from "../../../../apis"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { Routers } from "../../../../../../../constant/Routers"

const { SCR973 } = Routers

export const useAttentionsDetail =
  (): UseAttentionsDetailType<AttentionsDetailDataSourceType> => {
    const {
      activeKey,
      attentionId,
      attentionInfo,
      breadcrumbItems,
      columns,
      countAttentionStatus,
      goBack,
      goToAttentionsInfo,
      handleExportFile,
      isAttentionsBrowseResponse,
      isCommonTravelerRefer,
      isHiddenAnswerBtn,
      isHiddenBackBtn,
      isHiddenExportBtn,
      isLoading,
      isLoadingLayout,
      isOpen,
      isOpenExport,
      isResetTable,
      isResponse,
      onCancelExport,
      onCancel,
      onChangePage,
      onOpen,
      onOpenExport,
      perItem,
      push,
      rowSelection,
      setActiveKey,
      setAttentionInfo,
      setCountAttentionStatus,
      setIsLoadingLayout,
      setIsLoadingTable,
      setIsResetTable,
      setSelectedRowKeys,
      setSeverity,
      setUserInfoList,
      severity,
      severityList,
      userInfoList,
    } = useVariables()

    const handlePushStateError = (error: AxiosErrorType) => {
      const stateError = pushStateError(error)
      push({ pathname: SCR973, state: stateError })
    }

    const getAttention = () => {
      // APIを呼び出し、注意喚起情報を取得し、返却結果を「attentionInfo」変数に設定する。
      attentionApi
        .getAttention({ attentionDetailId: "", attentionId })
        .then((attentionInfoJson: AttentionsInfoDataType) => {
          //「M.attentionInfoJson」から「attentionInfo」情報を更新する。
          setAttentionInfo(attentionInfoJson)
        })
        .catch((error: AxiosErrorType) => {
          // 「SCR973_エラー情報の表示画面」に遷移する。
          handlePushStateError(error)
        })
    }

    const getCount = () => {
      // APIを呼び出し、応答状態に従って注意喚起対象人数を取得し、返却結果を「countAttentionResponse」変数に設定する。
      attentionApi
        .count(attentionId)
        .then((countAttentionResponse: AttentionsDetailCountType) => {
          //「countAttentionResponse」から「M.countAttentionStatus」情報を更新する。
          setCountAttentionStatus(countAttentionResponse)
        })
        .catch((error: AxiosErrorType) => {
          //「SCR973_エラー情報の表示画面」に遷移する。
          handlePushStateError(error)
        })
    }

    const onTabClick = (_activeKey: string) => {
      if (_activeKey !== activeKey) {
        setIsLoadingTable(true)
        setActiveKey(_activeKey)
      }
    }

    const onChange = (page: number, pageSize: number) => {
      onChangePage(page, pageSize)
      setSelectedRowKeys([])
    }

    const getAttentionTravelers = (isCallApi: boolean) => {
      attentionApi
        .getAttentionTravelers({
          attentionId: attentionId,
          attentionStatus: activeKey,
        })
        .then((userInfoJson: AttentionsDetailDataSourceType[]) => {
          if (isCallApi) {
            // 「userInfoJson」から「userInfoList」情報を更新する。
            setUserInfoList(userInfoJson)
          }
        })
        .catch((error: AxiosErrorType) => {
          //「SCR973_エラー情報の表示画面」に遷移する。
          handlePushStateError(error)
        })
        .finally(() => {
          setIsLoadingLayout(false)
          setIsLoadingTable(false)
          setIsResetTable(false)
        })
    }

    useEffect(() => {
      let isCallApi = true
      setIsResetTable(true)
      getAttentionTravelers(isCallApi)
      if (isAttentionsBrowseResponse) {
        getCount()
      }

      return () => {
        isCallApi = false
      }
    }, [activeKey])

    useEffect(() => {
      // ユーザの画面アクセス許可チェックを処理する。
      if (!isCommonTravelerRefer) {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const { pathname, state } = backToLogin()
        push({ pathname, state })
      } else {
        getAttention()
      }
    }, [])

    useEffect(() => {
      if (attentionInfo && severityList?.length) {
        const _severity =
          severityList.find((item) => item.value === attentionInfo.severity)
            ?.text ?? ""

        setSeverity(_severity)
      }
    }, [attentionInfo, severityList])

    return {
      attentionInfo,
      breadcrumbItems,
      columns,
      countAttentionStatus,
      goBack,
      goToAttentionsInfo,
      handleExportFile,
      isAttentionsBrowseResponse,
      isHiddenAnswerBtn,
      isHiddenBackBtn,
      isHiddenExportBtn,
      isLoading,
      isLoadingLayout,
      isOpen,
      isOpenExport,
      isResetTable,
      isResponse,
      onCancel,
      onCancelExport,
      onChange,
      onOpen,
      onOpenExport,
      onTabClick,
      perItem,
      rowSelection,
      severity,
      userInfoList,
    }
  }
