const FNC3_Add_Safety = "/v1"
const FNC3_Get_Safeties = "/v1/browse"
const FNC3_Get_Safety = "/v1/{0}"
const FNC3_Get_Safety_Travelers = "/v1/{0}/travelers"
const FNC3_Get_Detail_Safety_Traveler = "/v1/{0}/travelers/{1}"
const FNC3_Count = "/v1/{0}/travelers/count"
const FNC3_Confirm_Safety = "/v1/{0}/travelers/{1}"
const FNC3_Export = "/v1/{0}/travelers/export"

export const SAFETY_ENDPOINT = {
  FNC3_Add_Safety,
  FNC3_Confirm_Safety,
  FNC3_Count,
  FNC3_Export,
  FNC3_Get_Detail_Safety_Traveler,
  FNC3_Get_Safeties,
  FNC3_Get_Safety,
  FNC3_Get_Safety_Travelers,
}
