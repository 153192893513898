import { Variables } from "../../app/module/travelers/constants/edit/variables"
import { Translate } from "../../constant/Translate"
import { GetTrainingTypeProps } from "./models"

const { TEXT_T092, TEXT_T093, TEXT_T138 } = Translate
const { KEYS_ACCOMPANYING_FAMILY } = Variables

export const getTrainingType = (props: GetTrainingTypeProps) => {
  const { dispatchTypeDetailId, trainingType } = props
  return trainingType === 1
    ? TEXT_T092
    : trainingType === 2
    ? TEXT_T093
    : trainingType === 0 &&
      KEYS_ACCOMPANYING_FAMILY.includes(dispatchTypeDetailId)
    ? TEXT_T138
    : ""
}

export const getTrainingTypeExport = (props: GetTrainingTypeProps) => {
  const { dispatchTypeDetailId, trainingType } = props
  return trainingType === 1
    ? `${trainingType}_${TEXT_T092}`
    : trainingType === 2
    ? `${trainingType}_${TEXT_T093}`
    : trainingType === 0 &&
      KEYS_ACCOMPANYING_FAMILY.includes(dispatchTypeDetailId)
    ? `${trainingType}_${TEXT_T138}`
    : ""
}
