import { useState } from "react"
import { OptionItemType } from "../../../../../../../_webui/layout/components/Select/models"
import { Options } from "../../../../../../../_webui/layout/components/Form/Item/DateTimePicker/constant"

const { SELECT_HOUR_ITEMS, SELECT_MINUTE_ITEMS } = Options

export const useVariablesFormAdvanced = () => {
  const [arrivalMinuteItems, setArrivalMinuteItems] =
    useState<OptionItemType[]>(SELECT_MINUTE_ITEMS)
  const [arrivalHourItems, setArrivalHourItems] =
    useState<OptionItemType[]>(SELECT_HOUR_ITEMS)

  const [isDefaultValueArrivalDate, setIsDefaultValueArrivalDate] =
    useState(true)
  const [isDefaultValueDepartureDate, setIsDefaultValueDepartureDate] =
    useState(true)
  return {
    arrivalHourItems,
    arrivalMinuteItems,
    isDefaultValueArrivalDate,
    isDefaultValueDepartureDate,
    setArrivalHourItems,
    setArrivalMinuteItems,
    setIsDefaultValueArrivalDate,
    setIsDefaultValueDepartureDate,
  }
}
