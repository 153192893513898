import { FC, Fragment } from "react"
import { Translate } from "../../../../../constant/Translate"
import { replaceStringParam } from "../../../../../utils/replaceStringParam"
import { Title } from "../Title"
import { BreakMessageProps } from "./models"
import { PreLine } from "../PreLine"

const { MESSAGE_E0037 } = Translate
export const BreakMessage: FC<BreakMessageProps> = (props) => {
  const { message, validValue, validValue2 } = props
  return message === MESSAGE_E0037 ? (
    <Title
      titleLeft={
        <PreLine>
          {replaceStringParam(MESSAGE_E0037, String(validValue), validValue2)}
        </PreLine>
      }
    />
  ) : (
    <Fragment>{replaceStringParam(message, validValue, validValue2)}</Fragment>
  )
}
