import styled from "styled-components"
import { getValueByDevice } from "../../../../../../../../utils/getValueByDevice"
import { ItemSelect } from "../../../../../../../../_webui/layout/components/Form/Item/Select"
import { ItemInput } from "../../../../../../../../_webui/layout/components/Form/Item/Input"
import { Colors } from "../../../../../../../../constant/Styled/Colors"

const { FOCUS } = Colors

const StyledItemSelect = styled(ItemSelect)`
  max-width: ${getValueByDevice("300px", "100%")};
`

const StyledItemInput = styled(ItemInput)`
  .ant-input {
    border: 1px solid #bebebe;
    max-width: ${getValueByDevice("300px", "100%")};
    padding: 0 11px;
    &:focus {
      box-shadow: 0px 0px 4px ${FOCUS};
    }
  }
`

export const Styled = {
  StyledItemInput,
  StyledItemSelect,
}
