import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const { isCommonRefer, isRoleAdmin, isRoleSubAdmin, isRoleUser } =
    useAuthority()

  // 初期表示を行う。
  const isHidden =
    (isRoleAdmin || isRoleSubAdmin || isRoleUser) && isCommonRefer
  return {
    isCommonRefer,
    isHidden,
  }
}
