import { GridItemType } from "../../../../../../../../_webui/layout/components/Grid/models"
import { Translate } from "../../../../../../../../constant/Translate"
import { getDateTimeZoneByUserInformation } from "../../../../../../../../utils/getDate"
import { ScheduleInfoType } from "../../../../../models/addSuccess"

const { TEXT_T078, TEXT_T079, TEXT_T080, TEXT_T081, TEXT_T082, TEXT_T263 } =
  Translate

export const getOtherGridConfig = (
  isSchedulesDetail: boolean,
  isVisibleOtherRemarks: boolean,
  scheduleInfo: ScheduleInfoType,
): GridItemType[] => {
  const gridItems: GridItemType[] = isSchedulesDetail
    ? [
        {
          key: TEXT_T263,
          text: getDateTimeZoneByUserInformation(scheduleInfo.updateDate),
        },
      ]
    : []
  isVisibleOtherRemarks &&
    gridItems.push.apply(gridItems, [
      {
        key: TEXT_T078,
        text: scheduleInfo.managerRemarks1,
      },
      {
        key: TEXT_T079,
        text: scheduleInfo.managerRemarks2,
      },
      {
        key: TEXT_T080,
        text: scheduleInfo.managerRemarks3,
      },
      {
        key: TEXT_T081,
        text: scheduleInfo.managerRemarks4,
      },
      {
        key: TEXT_T082,
        text: scheduleInfo.managerRemarks5,
      },
    ])
  return gridItems
}
