import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const { isAttentionsSendAll, isRoleSubAdmin } = useAuthority()
  // 画面上のボタン表示条件
  const isHiddenCopyBtn = !(isAttentionsSendAll && isRoleSubAdmin)
  return {
    isAttentionsSendAll,
    isHiddenCopyBtn,
  }
}
