/* eslint-disable complexity */
/* eslint-disable max-lines */
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { safetyApi } from "../../../../apis"
import {
  SafetiesInfoDataDetailType,
  SafetiesInfoDataType,
} from "../../../../models/info"
import { useVariablesApi } from "../useVariablesApi"
import { Variables } from "../../../../constants/answer/variables"
import { setFieldsErrors } from "../../../../../../../utils/setFieldsErrors"
import {
  SafetiesAnswerConfirmReqType,
  SafetiesAnswerFormType,
} from "../../../../models/answer"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { Routers } from "../../../../../../../constant/Routers"
import { Translate } from "../../../../../../../constant/Translate"
import keycloak from "../../../../../../../utils/keycloak"
import { getFullName } from "../../../../../../../utils/getFullName"

const { SCR973 } = Routers
const { MESSAGE_E9997, MESSAGE_E9998, MESSAGE_E9999_TITLE } = Translate
const { FSST_LIST, SFTS_LIST } = Variables

export const useApi = () => {
  const {
    form,
    isLoading,
    isLoadingLayout,
    isShowFormSuccess,
    push,
    safetiesInfo,
    safetyDetailId,
    safetyId,
    safetyInfo,
    setIsLoading,
    setIsLoadingLayout,
    setIsShowFormSuccess,
    setSafetiesInfo,
    setSafetyInfo,
    setUpdateDate,
    updateDate,
  } = useVariablesApi()

  const handleError = (error: AxiosErrorType) => {
    if (error?.debug?.response?.data?.entityName) {
      setFieldsErrors(error, form)
      setIsLoading(false)
    } else if (error?.debug?.response?.data?.message) {
      const state = pushStateError(error)
      push({
        pathname: SCR973,
        state: { ...state, isSideBar: keycloak.token ? false : true },
      })
    } else {
      push({
        pathname: SCR973,
        state: {
          isSideBar: keycloak.token ? false : true,
          message: keycloak.token ? MESSAGE_E9997 : MESSAGE_E9998,
          title: MESSAGE_E9999_TITLE,
        },
      })
    }
  }

  const handleGetSafetyDetail = async () => {
    const getDetailSafetyTraveler: Promise<SafetiesInfoDataDetailType> =
      await safetyApi.getDetailSafetyTraveler({ safetyDetailId, safetyId })
    const getSafety: Promise<SafetiesInfoDataType> = await safetyApi.getSafety({
      safetyDetailId,
      safetyId,
    })
    Promise.all([getDetailSafetyTraveler, getSafety])
      .then((safetyDetailResponse) => {
        const {
          dispatchTypeDetailNameEn,
          dispatchTypeDetailNameJp,
          familySafetyStatus,
          kanjiName,
          kanjiSurname,
          romajiName,
          romajiSurname,
          message,
          safetyStatus,
          updateDate,
        } = safetyDetailResponse[0]

        setSafetiesInfo({
          dispatchTypeDetailNameEn: dispatchTypeDetailNameEn,
          dispatchTypeDetailNameJp: dispatchTypeDetailNameJp,
          travelerNameEn: getFullName(romajiSurname, romajiName),
          travelerNameJp: getFullName(kanjiSurname, kanjiName, true),
        })
        form.setFieldsValue({
          familySafetyStatus: FSST_LIST.some(
            (item) => item.value === familySafetyStatus,
          )
            ? familySafetyStatus
            : FSST_LIST[0].value,
          message: message,
          safetyStatus: SFTS_LIST.some((item) => item.value === safetyStatus)
            ? safetyStatus
            : SFTS_LIST[0].value,
        })
        setUpdateDate(updateDate)
        setSafetyInfo(safetyDetailResponse[1])
      })
      .catch((error: AxiosErrorType) => {
        handleError(error)
      })
      .finally(() => {
        setIsLoadingLayout(false)
      })
  }

  const onFinish = (values: SafetiesAnswerFormType) => {
    const data: SafetiesAnswerConfirmReqType = {
      ...values,
      isCheckExpired: true,
      isFromEmail: true,
      updateDate,
    }
    setIsLoading(true)
    setTimeout(() => {
      safetyApi
        .confirmSafety(data, safetyId, safetyDetailId)
        .then(() => {
          setIsShowFormSuccess(true)
        })
        .catch((error: AxiosErrorType) => {
          handleError(error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }, 500)
  }

  return {
    form,
    handleGetSafetyDetail,
    isLoading,
    isLoadingLayout,
    isShowFormSuccess,
    onFinish,
    safetiesInfo,
    safetyInfo,
  }
}
