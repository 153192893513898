/* eslint-disable max-depth */
/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable max-lines */
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { OptionItemType } from "../../../../../../../_webui/layout/components/Select/models"
import { GeneralCode } from "../../../../../../../constant/GeneralCode"
import { Routers } from "../../../../../../../constant/Routers"
import { SelectItems } from "../../../../../../../constant/SelectItems"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { countriesApi } from "../../../../../../../infrastructure/handlers/countries"
import { CitiesResType } from "../../../../../../../infrastructure/handlers/countries/models"
import { getDateRange, getDateUtc } from "../../../../../../../utils/getDate"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { replaceString } from "../../../../../../../utils/replaceString"
import { scrollToCollapseItem } from "../../../../../../../utils/scrollToCollapseItem"
import { translateMessage } from "../../../../../../../utils/translateMessage"
import { scheduleApi } from "../../../../apis"
import { SchedulesAddDetailRow } from "../../../../constants/add/addDetailRow"
import { InitialValues } from "../../../../constants/add/initialValues"
import { RequiredFields } from "../../../../constants/add/requiredFields"
import { Variables } from "../../../../constants/add/variables"
import {
  HandleSetDetailDataSourceType,
  InitialValuesType,
  ScheduleDetailsType,
  ScheduleInfoDetailsType,
  ScheduleInfoType,
} from "../../../../models/add"
import { getDetailRequiredFields } from "../../utils/getDetailRequiredFields"
import { setDetailFieldValue } from "../../utils/setDetailFieldValue"
import { setNameRowDetail } from "../../utils/setNameRowDetail"
import { setNameRowStay } from "../../utils/setNameRowStay"
import { useSchedulesAddTabConfig } from "../useTabConfigs"
import { useTableDetailsConfig } from "../useTableConfigs/details"
import { useTableRemarksConfig } from "../useTableConfigs/remarks"
import { useTableLodgingsConfig } from "../useTableConfigs/lodgings"
import { useTableStaysConfig } from "../useTableConfigs/stays"
import { useVariablesHandle } from "../useVariablesHandle"
import { getLodgingRequiredFields } from "../../utils/getLodgingRequiredFields"
import { getStayRequiredFields } from "../../utils/getStayRequiredFields"
import { FormErrorType } from "../../../../../../../_webui/layout/components/Form/models"
import { useDatePicker } from "../../../../../../../utils/hook/useDatePicker"
import { requiredField } from "../../../../../../../utils/requiredField"
import { setNameRowLodging } from "../../utils/setNameRowLodging"
import { Translate } from "../../../../../../../constant/Translate"
import { TravelersDetailResType } from "../../../../../travelers/models/detail"
import { getValueByLanguage } from "../../../../../../../utils/getValueByLanguage"
import { GeneralMastersResType } from "../../../../../../../infrastructure/handlers/generalMasters/models"
import { MouseEvent } from "react"

const {
  ACCOMMODATION_DATA_SOURCE_NEW,
  INITIAL_VALUES_REMARK_NAMES,
  ITINERARY_DATA_SOURCE_NEW,
  PLACE_OF_STAY_CONTACT_DATA_SOURCE_NEW,
} = InitialValues
const { OTHER, TPTT001, PPTV_SCR212 } = GeneralCode
const { INSURANCE_REQUIRED_FIELDS, SCHEDULES_ADD_REQUIRED_FIELDS } =
  RequiredFields
const { SCR212, SCR212_USER, SCR960, SCR973 } = Routers
const { DEPARTURE } = SchedulesAddDetailRow
const { OTHER_CITY_SELECT_ITEMS } = SelectItems
const {
  DPTD_REQUIRED_URGENT_CONTACT,
  E0017,
  E0105,
  E0107,
  PPTV_FUNIN_AND_FUYO,
} = Variables
const { TEXT_T100 } = Translate

export const useHandle = (isVisibleBtn: boolean) => {
  const {
    allCountries,
    assignmentValidMsg,
    dataSubmit,
    defaultActiveKey,
    defaultCountries,
    departments,
    detailDataSource,
    detailIndexIdentity,
    detailIndexTabActive,
    detailRequiredFields,
    detailRowSelection,
    detailSelectedRowKeys,
    detailValidMsg,
    dispatchType,
    dispatchTypeDetailRes,
    flightNumberList,
    flightNumberListAll,
    form,
    generalMastersRemark,
    id,
    isAccompanyingFamily,
    isActiveBumon,
    isCheckTwoWay,
    isDifferentInfoChecked,
    isDisabledSubmit,
    isInsuranceAndUrgent,
    isInsuranceRequired,
    isOpen,
    isOpenRoundTrip,
    isOtherStaffNumber,
    isRequiredDispatchDate,
    isRequiredItemTableByDate,
    isRequiredItemTableByPPTV,
    isRequiredItemTableLodgings,
    isSubmitValidate,
    isTwoWay,
    listPPTVIsValue3Equal1,
    listPPTVIsValue4Equal2,
    listPPTVIsValue5EqualTwoWay,
    lodgingDataSource,
    lodgingIndexIdentity,
    lodgingIndexTabActive,
    lodgingRequiredFields,
    lodgingRowSelection,
    lodgingSelectedRowKeys,
    lodgingValidMsg,
    onCancel,
    onCancelRoundTripModal,
    onOpen,
    onOpenRoundTrip,
    pathname,
    purposeTravelList,
    purposeTravelRes,
    push,
    scheduleDetails,
    scheduleLodgings,
    schedulesData,
    scheduleStays,
    setAssignmentValidMsg,
    setDataSubmit,
    setDefaultActiveKey,
    setDefaultCountries,
    setDepartments,
    setDetailDataSource,
    setDetailIndexIdentity,
    setDetailIndexTabActive,
    setDetailRequiredFields,
    setDetailSelectedRowKeys,
    setDetailValidMsg,
    setDispatchType,
    setDispatchTypeDetailRes,
    setFlightNumberList,
    setFlightNumberListAll,
    setIsAccompanyingFamily,
    setIsActiveBumon,
    setIsCheckAllDetail,
    setIsCheckAllLodging,
    setIsCheckAllStay,
    setIsCheckTwoWay,
    setIsDifferentInfoChecked,
    setIsDisabledSubmit,
    setIsInsuranceAndUrgent,
    setIsInsuranceRequired,
    setIsOtherStaffNumber,
    setIsRequiredDispatchDate,
    setIsRequiredItemTableByDate,
    setIsRequiredItemTableByPPTV,
    setIsRequiredItemTableLodgings,
    setIsSubmitValidate,
    setIsTwoWay,
    setListPPTVIsValue3Equal1,
    setListPPTVIsValue4Equal2,
    setListPPTVIsValue5EqualTwoWay,
    setLodgingDataSource,
    setLodgingIndexIdentity,
    setLodgingIndexTabActive,
    setLodgingRequiredFields,
    setLodgingSelectedRowKeys,
    setLodgingValidMsg,
    setPurposeTravelList,
    setPurposeTravelRes,
    setSchedulesData,
    setStayDataSource,
    setStayIndexIdentity,
    setStayIndexTabActive,
    setStayRequiredFields,
    setStaySelectedRowKeys,
    setTravelerBumonCd,
    setTravelerId,
    setWorkPlaceCountryCode,
    state,
    stayDataSource,
    stayIndexIdentity,
    stayIndexTabActive,
    stayRequiredFields,
    stayRowSelection,
    staySelectedRowKeys,
    transportationList,
    travelerBumonCd,
    travelerId,
    workPlaceCountryCode,
  } = useVariablesHandle()

  const isRequiredLabelLodgings =
    isRequiredItemTableByPPTV &&
    (isRequiredItemTableByDate || isRequiredItemTableLodgings)

  const handleError = (error: AxiosErrorType) => {
    // 「SCR973_エラー情報の表示画面」に遷移する。
    const stateError = pushStateError(error)
    push({ pathname: SCR973, state: stateError })
  }

  const onClickDetailTab = (activeKey: string) => {
    setDetailIndexTabActive(activeKey)
  }

  const handleSetDetailItem = () => {
    const travelDetailId = `tempId${new Date().getTime().toString()}`
    const currentElement = setNameRowDetail(detailIndexIdentity)
    const previousItem = detailDataSource[detailDataSource.length - 1]

    if (PPTV_SCR212.includes(form.getFieldValue("travelPurposeId"))) {
      form.setFieldsValue({
        [`${currentElement}.departureCountryCode`]: workPlaceCountryCode,
        [`${currentElement}.arrivalCountryCode`]: workPlaceCountryCode,
        [`${currentElement}.departureCityCode`]: "",
        [`${currentElement}.arrivalCityCode`]: "",
      })

      setDetailDataSource((prevState) => [
        ...prevState,
        {
          ...ITINERARY_DATA_SOURCE_NEW,
          arrivalCities: defaultCountries,
          departureCities: defaultCountries,
          index: detailIndexIdentity,
          key: travelDetailId,
          remarkNames: INITIAL_VALUES_REMARK_NAMES,
          travelDetailId,
        },
      ])
    } else {
      setDetailDataSource((prevState) => [
        ...prevState,
        {
          ...ITINERARY_DATA_SOURCE_NEW,
          index: detailIndexIdentity,
          key: travelDetailId,
          remarkNames: INITIAL_VALUES_REMARK_NAMES,
          travelDetailId,
        },
      ])
    }
    form.setFieldValue(`${currentElement}.travelDetailId`, travelDetailId)

    const identityValue = detailIndexIdentity + 1
    setIsCheckAllDetail(false)
    setDetailIndexIdentity(identityValue)
    setDetailIndexTabActive((detailDataSource.length + 1).toString())

    return { currentElement, previousItem }
  }

  // SCR212_detail_add
  const handleAddDetailItem = (event?: MouseEvent<HTMLElement>) => {
    const { currentElement } = handleSetDetailItem()

    if (detailIndexIdentity) {
      setTimeout(() => {
        if (event)
          document.getElementById(`${currentElement}.transportation`)?.focus()
      }, 100)
    }
  }

  // SCR212_detail_delete
  const handleDeleteDetailItem = (travelDetailId?: string) => {
    if (travelDetailId) {
      const _detailDataSource = detailDataSource.filter(
        (item) => item.travelDetailId !== travelDetailId,
      )

      setDetailDataSource(_detailDataSource)

      setDetailIndexTabActive("1")

      if (!_detailDataSource.length) handleSetDetailItem()
    } else {
      const _detailDataSource = detailDataSource.filter(
        (item) => !detailSelectedRowKeys.includes(item.travelDetailId),
      )

      setDetailDataSource(_detailDataSource)

      setDetailSelectedRowKeys([])

      if (!_detailDataSource.length) handleSetDetailItem()
    }

    setTimeout(() => {
      const elmRows = document.querySelectorAll(
        "#table-schedules-add-detail tbody > tr",
      )
      const emlInput = elmRows[elmRows.length - 1]?.querySelector(
        "td:nth-child(4) input",
      ) as HTMLInputElement
      emlInput?.focus()
    }, 100)
  }

  const handleSetFieldsValue = (
    data: ScheduleInfoDetailsType[],
    currentItem: ScheduleInfoDetailsType,
    currentIndex: number,
    fieldNames: string,
    value: string | OptionItemType[],
    isSetList: boolean,
    cityNames: string[],
    arrivalCountryCode?: string,
  ) => {
    const baseFieldName = `${setNameRowDetail(currentItem.index)}`

    if (isSetList) {
      if (arrivalCountryCode) {
        const dataRemark = generalMastersRemark?.find(
          (item) => item.key1 === arrivalCountryCode,
        )

        const remarkNames = dataRemark
          ? dataRemark
          : INITIAL_VALUES_REMARK_NAMES

        data.splice(currentIndex, 1, {
          ...currentItem,
          arrivalCountryCode: arrivalCountryCode,
          [fieldNames]: [...value, OTHER_CITY_SELECT_ITEMS],
          [cityNames[0]]: "",
          remarkNames,
        })
      } else
        data.splice(currentIndex, 1, {
          ...currentItem,
          [fieldNames]: [...value, OTHER_CITY_SELECT_ITEMS],
          [cityNames[0]]: "",
        })

      form?.setFieldsValue({
        [`${baseFieldName}.${cityNames[0]}`]: "",
        [`${baseFieldName}.${cityNames[1]}`]: "",
      })
    } else {
      data.splice(currentIndex, 1, {
        ...currentItem,
        [fieldNames]: value,
      })

      form?.setFieldsValue({
        [`${baseFieldName}.${cityNames[1]}`]: "",
      })

      if (value !== OTHER) {
        if (fieldNames === "departureCityCode")
          form?.resetFields([
            `${setNameRowDetail(currentIndex)}.departureCityName`,
          ])

        if (fieldNames === "arrivalCityCode")
          form?.resetFields([
            `${setNameRowDetail(currentIndex)}.arrivalCityName`,
          ])
      }
    }
  }

  const handleSetDetailRequiredFields = () => {
    if (detailDataSource.length) {
      const requiredFields: string[] = []

      detailDataSource.forEach((item) => {
        if (typeof item.index === "number") {
          const { index, transportation, departureCityCode, arrivalCityCode } =
            item

          const data = getDetailRequiredFields(index)

          if (transportation === TPTT001)
            data.push(`${setNameRowDetail(index)}.departureFlightNumber`)

          if (departureCityCode === OTHER)
            data.push(`${setNameRowDetail(index)}.departureCityName`)

          if (arrivalCityCode === OTHER)
            data.push(`${setNameRowDetail(index)}.arrivalCityName`)

          requiredFields.push(...data)
        }
      })

      setDetailRequiredFields(requiredFields)
    }
  }

  const handleSetLodgingRequiredFields = () => {
    if (lodgingDataSource.length) {
      const requiredFields: string[] = []

      lodgingDataSource.forEach((item) => {
        if (typeof item.index === "number") {
          const { index } = item

          if (isRequiredLabelLodgings) {
            const data = getLodgingRequiredFields(index)
            requiredFields.push(...data)
          } else {
            const data = getLodgingRequiredFields(index)
            requiredFields.filter((item) => !data.includes(item))
            form.setFields([
              {
                errors: [],
                name: `${setNameRowLodging(index)}.lodgingCheckin`,
              },
              {
                errors: [],
                name: `${setNameRowLodging(index)}.lodgingCheckout`,
              },
              {
                errors: [],
                name: `${setNameRowLodging(index)}.lodgingName`,
              },
            ])
          }
        }
      })

      setLodgingRequiredFields(requiredFields)
    }
  }

  const handleSetStayRequiredFields = () => {
    if (stayDataSource.length) {
      const requiredFields: string[] = []

      stayDataSource.forEach((item) => {
        if (typeof item.index === "number") {
          const { index } = item

          const data = getStayRequiredFields(index)

          requiredFields.push(...data)
        }
      })

      setStayRequiredFields(requiredFields)
    }
  }

  const handleSetDataSource = (
    travelerDetailId: string,
    type: string,
    fieldNames: string[],
    value: string | OptionItemType[],
    isSetList: boolean,
    arrivalCountryCode?: string,
  ) => {
    const currentItem = detailDataSource.find(
      (item) => item.travelDetailId === travelerDetailId,
    )

    if (currentItem) {
      const currentIndex = detailDataSource.findIndex(
        (item) => item.travelDetailId === travelerDetailId,
      )

      setDetailDataSource((prevState) => {
        const data = [...prevState]

        if (type === DEPARTURE) {
          handleSetFieldsValue(
            data,
            currentItem,
            currentIndex,
            fieldNames[0],
            value,
            isSetList,
            ["departureCityCode", "departureCityName"],
          )
        } else {
          handleSetFieldsValue(
            data,
            currentItem,
            currentIndex,
            fieldNames[1],
            value,
            isSetList,
            ["arrivalCityCode", "arrivalCityName"],
            arrivalCountryCode,
          )
        }

        return data
      })
    }
  }

  const onChangeCountry = (
    travelerDetailId: string,
    value: string,
    type: string,
  ) => {
    // APIを呼び出し、都市一覧情報を取得する。
    countriesApi
      .getCitiesByCountry(value)
      .then((res: CitiesResType[]) => {
        const optionItem: OptionItemType[] = res.map((item) => ({
          text: item.cityDistrict,
          value: item.cityCode,
        }))

        handleSetDataSource(
          travelerDetailId,
          type,
          ["departureCities", "arrivalCities"],
          optionItem,
          true,
          value,
        )
      })
      .catch((error: AxiosErrorType) => {
        handleError(error)
      })
  }

  const onChangeCity = (
    travelerDetailId: string,
    value: string,
    type: string,
  ) => {
    handleSetDataSource(
      travelerDetailId,
      type,
      ["departureCityCode", "arrivalCityCode"],
      value,
      false,
    )
    setIsTwoWay(false)
  }

  const getCities = async (
    departureCountryCode: string,
    arrivalCountryCode: string,
  ) => {
    const newPromise = new Promise<CitiesResType[]>((res) => res([]))

    // APIを呼び出し、都市一覧情報を取得する。
    const getDepartureCities: Promise<CitiesResType[]> = departureCountryCode
      ? countriesApi
          .getCitiesByCountry(departureCountryCode)
          .catch((error: AxiosErrorType) => {
            handleError(error)
          })
      : newPromise
    // APIを呼び出し、都市一覧情報を取得する。
    const getArrivalCities: Promise<CitiesResType[]> = arrivalCountryCode
      ? countriesApi
          .getCitiesByCountry(arrivalCountryCode)
          .catch((error: AxiosErrorType) => {
            handleError(error)
          })
      : newPromise

    const dataCities: CitiesResType[][] = await Promise.all([
      getDepartureCities,
      getArrivalCities,
    ])

    const departureCities = dataCities[0].map((item: CitiesResType) => ({
      text: item.cityDistrict,
      value: item.cityCode,
    }))

    const arrivalCities = dataCities[1].map((item: CitiesResType) => ({
      text: item.cityDistrict,
      value: item.cityCode,
    }))

    return {
      arrivalCities,
      departureCities,
    }
  }

  // SCR212_detail_set_row_data_table
  const handleSetDetailDataSource = async (
    data: HandleSetDetailDataSourceType,
  ) => {
    const {
      detailData,
      index,
      isClearDepartureFlight,
      isInitial,
      isSelectDepartureFlight,
    } = data

    const dataRemark = generalMastersRemark?.find(
      (item) => item.key1 === detailData.arrivalCountryCode,
    )
    const remarkNames = dataRemark ?? INITIAL_VALUES_REMARK_NAMES

    await setDetailFieldValue({
      detailData,
      form,
      index,
      isSelectDepartureFlight,
    })

    const { arrivalCities, departureCities } = await getCities(
      detailData.departureCountryCode,
      detailData.arrivalCountryCode,
    )

    const dataInsert = {
      ...detailData,
      arrivalCities: [...arrivalCities, OTHER_CITY_SELECT_ITEMS],
      departureCities: [...departureCities, OTHER_CITY_SELECT_ITEMS],
      index,
      key: detailData.travelDetailId,
      remarkNames,
    }

    if (isInitial) {
      setDetailDataSource((prevState) => [...prevState, dataInsert])

      setDetailIndexIdentity((prevState) => prevState + 1)
    } else {
      const _detailDataSource = [...detailDataSource]

      const dataSourceIndex = _detailDataSource.findIndex(
        (item) => item.travelDetailId === detailData.travelDetailId,
      )

      _detailDataSource.splice(dataSourceIndex, 1, dataInsert)

      setDetailDataSource(_detailDataSource)

      if (isSelectDepartureFlight || isClearDepartureFlight) {
        setTimeout(() => {
          form.setFields([
            {
              errors: [],
              name: `${setNameRowDetail(index)}.departureFlightNumber`,
            },
            {
              errors: [],
              name: `${setNameRowDetail(index)}.departureCityName`,
            },
            {
              errors: [],
              name: `${setNameRowDetail(index)}.arrivalCityName`,
            },
          ])
        }, 100)
      }
    }
  }

  const getItemTableRequiredField = () =>
    requiredField(isRequiredItemTableByPPTV)

  const onChangeTransportation = (
    transportationValue: string,
    currentIndex: number,
  ) => {
    const _detailDataSource = [...detailDataSource]
    const currentItem = { ..._detailDataSource[currentIndex] }

    if (currentItem.transportation === TPTT001)
      form.setFields([
        {
          errors: [],
          name: `${setNameRowDetail(currentItem.index)}.departureFlightNumber`,
        },
      ])

    currentItem.transportation = transportationValue

    _detailDataSource.splice(currentIndex, 1, currentItem)
    setDetailDataSource(_detailDataSource)
  }

  // 「M.flightNumberList」に応じて「旅程情報」の初期表示処理を行う。
  const onSelectDepartureFlight = (
    value: string,
    travelDetailId: string,
    index?: number,
  ) => {
    const dataSelect = flightNumberListAll.find(
      (item) => item.departureFlightNumber === value,
    )

    if (dataSelect && typeof index === "number") {
      const itemRemark = `${setNameRowDetail(index)}.othersRemarks`
      handleSetDetailDataSource({
        detailData: {
          ...dataSelect,
          arrivalCities: [],
          departureCities: [],
          othersRemarks1: form.getFieldValue(`${itemRemark}1`),
          othersRemarks2: form.getFieldValue(`${itemRemark}2`),
          othersRemarks3: form.getFieldValue(`${itemRemark}3`),
          othersRemarks4: form.getFieldValue(`${itemRemark}4`),
          othersRemarks5: form.getFieldValue(`${itemRemark}5`),
          travelDetailId,
        },
        index,
        isSelectDepartureFlight: true,
      })
    }
  }

  const onClearDepartureFlight = (travelDetailId: string, index?: number) => {
    if (typeof index === "number") {
      const itemRemark = `${setNameRowDetail(index)}.othersRemarks`
      handleSetDetailDataSource({
        detailData: {
          ...ITINERARY_DATA_SOURCE_NEW,
          othersRemarks1: form.getFieldValue(`${itemRemark}1`),
          othersRemarks2: form.getFieldValue(`${itemRemark}2`),
          othersRemarks3: form.getFieldValue(`${itemRemark}3`),
          othersRemarks4: form.getFieldValue(`${itemRemark}4`),
          othersRemarks5: form.getFieldValue(`${itemRemark}5`),
          transportation: form.getFieldValue(
            `${setNameRowDetail(index)}.transportation`,
          ),
          travelDetailId,
        },
        index,
        isClearDepartureFlight: true,
      })
    }
  }

  const onChangeTravelPurpose = async (value: string) => {
    handleCheckRequiredDispatchDate(value)
    if (PPTV_SCR212.includes(value)) {
      detailDataSource.forEach((item) => {
        form.setFieldsValue({
          [`${setNameRowDetail(item.index)}.departureCountryCode`]:
            workPlaceCountryCode,
          [`${setNameRowDetail(item.index)}.arrivalCountryCode`]:
            workPlaceCountryCode,
          [`${setNameRowDetail(item.index)}.departureCityCode`]: "",
          [`${setNameRowDetail(item.index)}.arrivalCityCode`]: "",
        })
      })

      const _detailDataSource = detailDataSource.map((item) => ({
        ...item,
        arrivalCities: defaultCountries,
        departureCities: defaultCountries,
      }))
      setDetailDataSource(_detailDataSource)
      setIsRequiredItemTableLodgings(false)
    } else {
      setIsRequiredItemTableLodgings(true)
    }
    if (listPPTVIsValue3Equal1.includes(value)) {
      if (!form.getFieldValue("bumonCd")) {
        form.setFieldValue("bumonCd", travelerBumonCd)
      }
      setIsActiveBumon(true)
    } else setIsActiveBumon(false)

    setIsRequiredItemTableByPPTV(!listPPTVIsValue4Equal2.includes(value))
    if (listPPTVIsValue5EqualTwoWay.includes(value)) {
      onOpenRoundTrip()
    } else {
      setIsTwoWay(false)
    }
  }

  const handleCheckTwoWay = () => {
    if (isCheckTwoWay) {
      return (
        detailDataSource.length === 1 &&
        listPPTVIsValue5EqualTwoWay.includes(
          form.getFieldValue("travelPurposeId"),
        )
      )
    } else {
      return false
    }
  }

  const onOkRoundTrip = () => {
    onCancelRoundTripModal()
    setIsCheckTwoWay(true)
  }

  const onCancelRoundTrip = () => {
    onCancelRoundTripModal()
    setIsCheckTwoWay(false)
    setIsTwoWay(false)
  }

  const onChangeDifferentInfo = (event: CheckboxChangeEvent) => {
    setIsDifferentInfoChecked(event.target.checked)
  }

  const onClickLodgingTab = (activeKey: string) => {
    setLodgingIndexTabActive(activeKey)
  }

  // SCR212_lodging_add
  const handleAddLodgingItem = (event?: MouseEvent<HTMLElement>) => {
    const travelLodgingId = new Date().getTime().toString()

    setLodgingDataSource((prevState) => [
      ...prevState,
      {
        ...ACCOMMODATION_DATA_SOURCE_NEW,
        index: lodgingIndexIdentity,
        key: travelLodgingId,
        travelLodgingId,
      },
    ])

    setIsCheckAllLodging(false)
    setLodgingIndexIdentity(lodgingIndexIdentity + 1)
    setLodgingIndexTabActive((lodgingDataSource.length + 1).toString())
    const currentElement = setNameRowLodging(lodgingIndexIdentity)
    form.setFieldValue(`${currentElement}.travelLodgingId`, travelLodgingId)

    if (lodgingIndexIdentity && event) {
      setTimeout(() => {
        document.getElementById(`${currentElement}.lodgingCheckin`)?.focus()
      }, 100)
    }
  }

  // SCR212_lodging_delete
  const handleDeleteLodgingItem = (travelLodgingId?: string) => {
    if (travelLodgingId) {
      const _lodgingDataSource = lodgingDataSource.filter(
        (item) => item.travelLodgingId !== travelLodgingId,
      )

      setLodgingDataSource(_lodgingDataSource)

      setLodgingIndexTabActive("1")

      if (!_lodgingDataSource.length) handleAddLodgingItem()
    } else {
      const _lodgingDataSource = lodgingDataSource.filter(
        (item) =>
          item.travelLodgingId &&
          !lodgingSelectedRowKeys.includes(item.travelLodgingId),
      )

      setLodgingDataSource(_lodgingDataSource)

      setLodgingSelectedRowKeys([])

      if (!_lodgingDataSource.length) handleAddLodgingItem()
    }

    setTimeout(() => {
      const elmRows = document.querySelectorAll(
        "#table-schedules-add-lodging tbody > tr",
      )
      const emlInput = elmRows[elmRows.length - 1]?.querySelector(
        "td:nth-child(4) input",
      ) as HTMLInputElement
      emlInput?.focus()
    }, 100)
  }

  const onClickStayTab = (activeKey: string) => {
    setStayIndexTabActive(activeKey)
  }

  // SCR212_stay_add
  const handleAddStayItem = (event?: MouseEvent<HTMLElement>) => {
    const travelStayId = new Date().getTime().toString()

    setStayDataSource((prevState) => [
      ...prevState,
      {
        ...PLACE_OF_STAY_CONTACT_DATA_SOURCE_NEW,
        index: stayIndexIdentity,
        key: travelStayId,
        travelStayId,
      },
    ])

    const emailAddress1FieldName = `${setNameRowStay(
      stayIndexIdentity,
    )}.emailAddress1`

    const personEmailAddress = form.getFieldValue("personEmailAddress")

    form.setFieldValue(emailAddress1FieldName, personEmailAddress)
    const currentElement = setNameRowStay(stayIndexIdentity)
    form.setFieldValue(`${currentElement}.travelStayId`, travelStayId)

    setIsCheckAllStay(false)
    setStayIndexIdentity(stayIndexIdentity + 1)
    setStayIndexTabActive((stayDataSource.length + 1).toString())

    if (stayIndexIdentity && event) {
      setTimeout(() => {
        document
          .getElementById(`${currentElement}.locationPhoneNumber1`)
          ?.focus()
      }, 100)
    }
  }

  // SCR212_stay_delete
  const handleDeleteStayItem = (travelStayId?: string) => {
    if (travelStayId) {
      const _stayDataSource = stayDataSource.filter(
        (item) => item.travelStayId !== travelStayId,
      )

      setStayDataSource(_stayDataSource)

      setStayIndexTabActive("1")

      if (!_stayDataSource.length) handleAddStayItem()
    } else {
      const _stayDataSource = stayDataSource.filter(
        (item) => !staySelectedRowKeys.includes(item.travelStayId),
      )

      setStayDataSource(_stayDataSource)

      setStaySelectedRowKeys([])

      if (!_stayDataSource.length) handleAddStayItem()
    }

    setTimeout(() => {
      const elmRows = document.querySelectorAll(
        "#table-schedules-add-stay tbody > tr",
      )
      const emlInput = elmRows[elmRows.length - 1]?.querySelector(
        "td:nth-child(4) input",
      ) as HTMLInputElement
      emlInput?.focus()
    }, 100)
  }

  const onValuesChange = () => {
    setIsSubmitValidate(false)
    const dataFields = [
      ...SCHEDULES_ADD_REQUIRED_FIELDS,
      ...detailRequiredFields,
    ]

    if (isRequiredItemTableByPPTV) {
      dataFields.push.apply(dataFields, [
        ...lodgingRequiredFields,
        ...stayRequiredFields,
      ])
    } else {
      const list = [...lodgingRequiredFields, ...stayRequiredFields]

      const fields = list.map((item) => ({
        errors: [],
        name: item,
      }))

      form.setFields(fields)
    }

    if (isActiveBumon) dataFields.push("bumonCd")
    if (isRequiredDispatchDate)
      dataFields.push(...["dispatchStartDate", "dispatchEndDate"])
    else {
      dataFields.splice(dataFields.indexOf("dispatchStartDate"), 1)
      dataFields.splice(dataFields.indexOf("dispatchEndDate"), 1)
    }

    const valueDifferentInfoChecked = form.getFieldValue("differentInforCheck")
    if (valueDifferentInfoChecked) dataFields.push("insuranceCompanyName")
    else
      form.setFields([
        {
          errors: [],
          name: "insuranceCompanyName",
        },
      ])

    const _isInsuranceRequired = !form
      .getFieldValue("insuranceCompanyName")
      ?.includes(TEXT_T100)

    setIsInsuranceRequired(_isInsuranceRequired)

    if (valueDifferentInfoChecked && _isInsuranceRequired)
      dataFields.push(...INSURANCE_REQUIRED_FIELDS)
    else
      form.setFields([
        {
          errors: [],
          name: INSURANCE_REQUIRED_FIELDS[0],
        },
        {
          errors: [],
          name: INSURANCE_REQUIRED_FIELDS[1],
        },
        {
          errors: [],
          name: INSURANCE_REQUIRED_FIELDS[2],
        },
      ])

    const formValues = form.getFieldsValue(dataFields)

    if (!formValues.codeConductConfirmation) setIsDisabledSubmit(true)
    else setIsDisabledSubmit(Object.values(formValues).some((item) => !item))
  }

  const onValidateChangeDate = () => {
    const data: string[] = []
    if (detailDataSource.length) {
      detailDataSource.forEach((item) => {
        const arrivalDate = form.getFieldValue(
          `${setNameRowDetail(item.index)}.arrivalDate`,
        )
        const departureDate = form.getFieldValue(
          `${setNameRowDetail(item.index)}.departureDate`,
        )
        data.push(getDateRange(arrivalDate, true))
        data.push(getDateRange(departureDate, true))
      })
      const isAllEqual = data.every((item) => item === data[0] && item !== "")
      setIsRequiredItemTableByDate(!isAllEqual)
    } else {
      setIsRequiredItemTableByDate(true)
    }
  }

  const onChangeDate = (name: string) => {
    form.setFields([
      {
        errors: [],
        name,
      },
    ])
  }

  const { onBlur, onKeyDown } = useDatePicker({
    form,
    isRequired: isRequiredDispatchDate,
    onValuesChange,
  })

  const { onBlur: onLodgingsBlur, onKeyDown: onLodgingsKeyDown } =
    useDatePicker({
      form,
      isRequired: isRequiredLabelLodgings,
      onValuesChange,
    })

  const { detailTabsItems, lodgingTabsItems, stayTabsItems } =
    useSchedulesAddTabConfig({
      allCountries,
      detailDataSource,
      flightNumberList,
      getItemTableRequiredField,
      handleAddDetailItem,
      handleAddLodgingItem,
      handleAddStayItem,
      handleDeleteDetailItem,
      handleDeleteLodgingItem,
      handleDeleteStayItem,
      isRequiredItemTableByPPTV,
      isSubmitValidate,
      isVisibleBtn,
      lodgingDataSource,
      onBlur,
      onChangeCity,
      onChangeCountry,
      onChangeDate,
      onChangeTransportation,
      onClearDepartureFlight,
      onKeyDown,
      onSelectDepartureFlight,
      stayDataSource,
      transportationList,
    })

  const { columns: detailColumnsFirst } = useTableDetailsConfig({
    allCountries,
    flightNumberList,
    onBlur,
    onChangeCity,
    onChangeCountry,
    onChangeDate,
    onChangeTransportation,
    onClearDepartureFlight,
    onKeyDown,
    onSelectDepartureFlight,
    onValidateChangeDate,
    transportationList,
  })

  const { columns: detailColumnsSecond } = useTableRemarksConfig()

  const { columns: lodgingColumns } = useTableLodgingsConfig({
    isRequiredLabel: isRequiredLabelLodgings,
    isSubmitValidate,
    onBlur: onLodgingsBlur,
    onChangeDate,
    onKeyDown: onLodgingsKeyDown,
  })

  const { columns: stayColumns } = useTableStaysConfig({
    getItemTableRequiredField,
    isRequiredItemTableByPPTV,
  })

  const handleErrorValidate = (message: string, entityName?: string) => {
    const messages = [E0017, E0105, E0107]
    if (messages.includes(message)) {
      if (entityName === "lodging") {
        setLodgingValidMsg(translateMessage(`MESSAGE.${message}`))
        setDefaultActiveKey((prevState) => [...prevState, "6"])
        scrollToCollapseItem(5)
      } else {
        setDetailValidMsg(translateMessage(`MESSAGE.${message}`))
        setDefaultActiveKey((prevState) => [...prevState, "5"])
        scrollToCollapseItem(4)
      }
    } else {
      setAssignmentValidMsg(translateMessage(`MESSAGE.${message}`))
      setDefaultActiveKey((prevState) => [...prevState, "3"])
      scrollToCollapseItem(2)
    }
  }

  const onChangeCollapse = (key: string[]) => {
    setDefaultActiveKey(key)
  }

  // 「SCR960_渡航予定情報の複製画面」に遷移する 。
  const goToSchedulesClone = () => {
    if (pathname === replaceString(SCR212, id))
      push({
        pathname: SCR960,
        state: {
          ...state,
          isFromSCR212: true,
          sourceURL: pathname,
          travelerId,
        },
      })

    if (pathname === replaceString(SCR212_USER, id))
      push({
        pathname: SCR960,
        state: {
          ...state,
          isFromSCR212User: true,
          sourceURL: pathname,
          travelerId,
        },
      })
  }

  const getInitialValues = (
    _schedulesInfo: ScheduleInfoType,
    _purposeTravelList: OptionItemType[],
    _departments: OptionItemType[],
  ): InitialValuesType => ({
    ..._schedulesInfo,
    bumonCd: _departments.some((item) => item.value === _schedulesInfo.bumonCd)
      ? _schedulesInfo.bumonCd
      : "",
    dispatchEndDate: getDateUtc(_schedulesInfo.dispatchEndDate),
    dispatchStartDate: getDateUtc(_schedulesInfo.dispatchStartDate),
    travelPurposeId: _purposeTravelList.some(
      (item) => item.value === _schedulesInfo.travelPurposeId,
    )
      ? _schedulesInfo.travelPurposeId
      : "",
  })

  const onFinish = () => {
    // 確認ダイアログボックスを作成する。
    setIsSubmitValidate(true)
    form
      .validateFields()
      .then(() => {
        onOpen()
        setDataSubmit(form.getFieldsValue())
      })
      .catch((error: FormErrorType) => {
        form.scrollToField(error.errorFields[0].name.toString())
        const elmError = document.getElementById(
          error.errorFields[0].name.toString(),
        ) as HTMLInputElement
        if (elmError) elmError.focus()
      })
  }

  const getFlightInfo = async (_travelerId: string) => {
    // APIを呼び出し、渡航者の出発便名情報を取得し
    await scheduleApi
      .getFlightInfo(_travelerId)
      .then((res: ScheduleDetailsType[]) => {
        const _flightNumberListAll = res.map((item) => ({
          ...item,
          arrivalCityNameOther:
            item.arrivalCityCode === OTHER ? item.arrivalCityName : "",
          departureCityNameOther:
            item.departureCityCode === OTHER ? item.departureCityName : "",
        }))

        setFlightNumberListAll(_flightNumberListAll)

        setFlightNumberList(
          res.map((item) => ({
            label: item.departureFlightNumber,
          })),
        )
      })
      .catch((error: AxiosErrorType) => {
        const stateError = pushStateError(error)
        push({ pathname: SCR973, state: stateError })
      })
  }

  const setDataScheduleLodgings = () => {
    scheduleLodgings.forEach((item, index) => {
      const baseFieldName = `schedulesAddLodging.${index}`

      form?.setFieldsValue({
        [`${baseFieldName}.lodgingAddress`]: item.lodgingAddress,
        [`${baseFieldName}.lodgingCheckin`]: getDateUtc(item.lodgingCheckin),
        [`${baseFieldName}.lodgingCheckout`]: getDateUtc(item.lodgingCheckout),
        [`${baseFieldName}.lodgingName`]: item.lodgingName,
        [`${baseFieldName}.lodgingPhoneNumber`]: item.lodgingPhoneNumber,
        [`${baseFieldName}.travelLodgingId`]: item.travelLodgingId,
      })
    })

    setLodgingDataSource(
      [...scheduleLodgings].map((item, index) => {
        item.index = index
        item.key = item.travelLodgingId

        setLodgingIndexIdentity((prevState) => prevState + 1)

        return item
      }),
    )
  }

  const setDataScheduleDetails = () => {
    scheduleDetails.reduce(async (prevItem, currentItem, currentIndex) => {
      await prevItem

      return await handleSetDetailDataSource({
        detailData: currentItem,
        index: currentIndex,
        isInitial: true,
      })
    }, Promise.resolve())
  }

  const setDataScheduleStays = () => {
    scheduleStays.forEach((item, index) => {
      const baseFieldName = `schedulesAddStay.${index}`

      form?.setFieldsValue({
        [`${baseFieldName}.emailAddress1`]: item.emailAddress1,
        [`${baseFieldName}.emailAddress2`]: item.emailAddress2,
        [`${baseFieldName}.locationPhoneNumber1`]: item.locationPhoneNumber1,
        [`${baseFieldName}.locationPhoneNumber2`]: item.locationPhoneNumber2,
        [`${baseFieldName}.satellitePhoneNumber`]: item.satellitePhoneNumber,
        [`${baseFieldName}.travelStayId`]: item.travelStayId,
      })
    })

    setStayDataSource(
      [...scheduleStays].map((item, index) => {
        item.index = index
        item.key = item.travelStayId

        setStayIndexIdentity((prevState) => prevState + 1)

        return item
      }),
    )
  }

  const handleCheckRequiredDispatchDate = (purposeTravel?: string) => {
    setIsRequiredDispatchDate(
      !!(purposeTravel && PPTV_FUNIN_AND_FUYO.includes(purposeTravel)),
    )
    form.setFields([
      { errors: [], name: "dispatchStartDate" },
      { errors: [], name: "dispatchEndDate" },
    ])
  }

  const handleCheckInsuranceAndUrgent = (data: TravelersDetailResType) => {
    const { base, detail } = data
    const { dispatchTypeDetailId } = base
    const {
      insuranceCompanyName,
      insuranceCompanyPhoneNumber,
      insuranceSonsignor,
      insuranceTicketNumber,
      urgentAffiliationContact,
      urgentAffiliationContactName,
      urgentContactHome,
      urgentContactName,
    } = detail
    const checkData = (length: number, ...res: string[]) =>
      res.filter(Boolean).length < length

    const isRequiredDefault = checkData(
      3,
      urgentContactHome,
      urgentContactName,
      insuranceCompanyName,
    )

    const isRequiredUrgent = checkData(
      2,
      urgentAffiliationContact,
      urgentAffiliationContactName,
    )

    const isRequiredInsurance = checkData(
      3,
      insuranceCompanyPhoneNumber,
      insuranceSonsignor,
      insuranceTicketNumber,
    )

    if (
      isRequiredDefault ||
      (DPTD_REQUIRED_URGENT_CONTACT.includes(dispatchTypeDetailId) &&
        isRequiredUrgent) ||
      (insuranceCompanyName !== TEXT_T100 && isRequiredInsurance)
    ) {
      setIsInsuranceAndUrgent(true)
    }
  }

  const getPurposeTravelList = (
    dispatchTypeDetailId?: string,
    dispatchTypeDetailRes?: GeneralMastersResType[],
    purposeTravelRes?: GeneralMastersResType[],
  ) => {
    if (dispatchTypeDetailId) {
      const dispatchTypeDetailInfo = dispatchTypeDetailRes?.find(
        (item) => item.key1 === dispatchTypeDetailId,
      )

      if (dispatchTypeDetailInfo) {
        const _purposeTravelList = purposeTravelRes
          ?.filter((item) => item.key2 === dispatchTypeDetailInfo.key3)
          .map((item) => {
            if (item.value3 === "1")
              setListPPTVIsValue3Equal1((prev) => [...prev, item.key1])

            if (item.value4 === "2")
              setListPPTVIsValue4Equal2((prev) => [...prev, item.key1])
            if (item.value5 === "往復")
              setListPPTVIsValue5EqualTwoWay((prev) => [...prev, item.key1])
            return {
              text: String(getValueByLanguage(item.value1, item.value2)),
              value: item.key1,
            }
          })
        if (_purposeTravelList) {
          setPurposeTravelList(_purposeTravelList)
        }
        return _purposeTravelList
      }
      return []
    }
    return []
  }

  const onDispatchTypeDetailChange = (value: string) => {
    form.setFieldValue("travelPurposeId", "")
    getPurposeTravelList(value, dispatchTypeDetailRes, purposeTravelRes)
  }

  return {
    assignmentValidMsg,
    dataSubmit,
    defaultActiveKey,
    departments,
    detailColumnsFirst,
    detailColumnsSecond,
    detailDataSource,
    detailIndexIdentity,
    detailIndexTabActive,
    detailRowSelection,
    detailSelectedRowKeys,
    detailTabsItems,
    detailValidMsg,
    dispatchType,
    dispatchTypeDetailRes,
    form,
    getFlightInfo,
    getInitialValues,
    getPurposeTravelList,
    goToSchedulesClone,
    handleAddDetailItem,
    handleAddLodgingItem,
    handleAddStayItem,
    handleCheckInsuranceAndUrgent,
    handleCheckRequiredDispatchDate,
    handleCheckTwoWay,
    handleDeleteDetailItem,
    handleDeleteLodgingItem,
    handleDeleteStayItem,
    handleError,
    handleErrorValidate,
    handleSetDetailRequiredFields,
    handleSetLodgingRequiredFields,
    handleSetStayRequiredFields,
    isAccompanyingFamily,
    isActiveBumon,
    isDifferentInfoChecked,
    isDisabledSubmit,
    isInsuranceAndUrgent,
    isInsuranceRequired,
    isOpen,
    isOpenRoundTrip,
    isOtherStaffNumber,
    isRequiredDispatchDate,
    isRequiredItemTableByDate,
    isRequiredItemTableByPPTV,
    isRequiredItemTableLodgings,
    isSubmitValidate,
    isTwoWay,
    listPPTVIsValue3Equal1,
    listPPTVIsValue4Equal2,
    lodgingColumns,
    lodgingDataSource,
    lodgingIndexIdentity,
    lodgingIndexTabActive,
    lodgingRowSelection,
    lodgingSelectedRowKeys,
    lodgingTabsItems,
    lodgingValidMsg,
    onBlur,
    onCancel,
    onCancelRoundTrip,
    onChangeCollapse,
    onChangeDate,
    onChangeDifferentInfo,
    onChangeTravelPurpose,
    onClickDetailTab,
    onClickLodgingTab,
    onClickStayTab,
    onDispatchTypeDetailChange,
    onFinish,
    onKeyDown,
    onOkRoundTrip,
    onValidateChangeDate,
    onValuesChange,
    purposeTravelList,
    scheduleDetails,
    scheduleLodgings,
    scheduleStays,
    schedulesData,
    setAssignmentValidMsg,
    setDataScheduleDetails,
    setDataScheduleLodgings,
    setDataScheduleStays,
    setDefaultCountries,
    setDepartments,
    setDetailDataSource,
    setDetailValidMsg,
    setDispatchType,
    setDispatchTypeDetailRes,
    setIsAccompanyingFamily,
    setIsActiveBumon,
    setIsDifferentInfoChecked,
    setIsOtherStaffNumber,
    setIsRequiredItemTableByPPTV,
    setIsRequiredItemTableLodgings,
    setIsTwoWay,
    setListPPTVIsValue3Equal1,
    setListPPTVIsValue5EqualTwoWay,
    setLodgingDataSource,
    setLodgingValidMsg,
    setPurposeTravelRes,
    setSchedulesData,
    setStayDataSource,
    setTravelerBumonCd,
    setTravelerId,
    setWorkPlaceCountryCode,
    stayColumns,
    stayDataSource,
    stayIndexIdentity,
    stayIndexTabActive,
    stayRowSelection,
    staySelectedRowKeys,
    stayTabsItems,
    travelerBumonCd,
    travelerId,
  }
}
