import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const { isCommonExternalSystemLinkage, isRoleAdmin } = useAuthority()
  const isHiddenBtn = !(isRoleAdmin && isCommonExternalSystemLinkage)

  return {
    isCommonExternalSystemLinkage,
    isHiddenBtn,
  }
}
