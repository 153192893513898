import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const { isRoleSubAdmin, isRoleUser, isCommonRefer, isRoleAdmin } =
    useAuthority()

  const isNotAccessScreen = !isCommonRefer

  // 画面上のボタン表示条件
  const isVisibleBtn =
    (isRoleUser || isRoleSubAdmin || isRoleAdmin) && isCommonRefer

  return {
    isNotAccessScreen,
    isRoleUser,
    isVisibleBtn,
  }
}
