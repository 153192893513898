import { FC } from "react"
import { Routers } from "../../../../constant/Routers"
import { Switch } from "react-router-dom"
import { ContactPage } from "../../../../app/pages/systems/contact"
import PrivateRoute from "../../../../app/routing/privateRoute"
import { ContactSuccessPage } from "../../../../app/pages/systems/contactSuccess"
import { PrivacyPolicyPage } from "../../../../app/pages/systems/privacyPolicy"
import { SystemsManualFAQPage } from "../../../../app/pages/systems/manual/faq"
import { SystemsManualUserPage } from "../../../../app/pages/systems/manual/user"
import { SystemsManualSubAdminPage } from "../../../../app/pages/systems/manual/subAdmin"
import { SystemsSafetyMeasuresPage } from "../../../../app/pages/systems/manual/safetyMeasures"

const {
  SCR991_FAQ,
  SCR991_SAFETY_MEASURES,
  SCR991_SYSTEM_MANUAL,
  SCR991_USER,
  SCR992,
  SCR993,
  SCR994,
} = Routers

export const SystemsRoute: FC = () => (
  <Switch>
    <PrivateRoute component={SystemsManualFAQPage} exact path={SCR991_FAQ} />
    <PrivateRoute
      component={SystemsSafetyMeasuresPage}
      exact
      path={SCR991_SAFETY_MEASURES}
    />
    <PrivateRoute component={SystemsManualUserPage} exact path={SCR991_USER} />
    <PrivateRoute
      component={SystemsManualSubAdminPage}
      exact
      path={SCR991_SYSTEM_MANUAL}
    />
    <PrivateRoute component={ContactPage} exact path={SCR992} />
    <PrivateRoute component={ContactSuccessPage} exact path={SCR993} />
    <PrivateRoute component={PrivacyPolicyPage} exact path={SCR994} />
  </Switch>
)
