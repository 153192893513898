import { FC, Fragment } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { ModalConfirm } from "../../../../../../_webui/layout/components/Modal/Confirm"
import { Modal } from "../../../../../../_webui/layout/components/Modal"
import { Title } from "../../../../../../_webui/layout/components/Typography/Title"
import { FilesUploadModalConfirmProps } from "../../../model/filesUpload"
import { PreLine } from "../../../../../../_webui/layout/components/Typography/PreLine"

const { MESSAGE_C0031, MESSAGE_C0015, MESSAGE_C0019, MESSAGE_I0019 } = Translate

export const FilesUploadModalConfirm: FC<FilesUploadModalConfirmProps> = (
  props,
) => {
  const {
    isOpen,
    isOpenConfirm,
    isOpenReload,
    isOpenSuccess,
    onCancel,
    onCancelConfirm,
    onCancelReload,
    onOkSuccess,
    onOkConfirm,
    onOkReload,
    onOkUpload,
  } = props

  return (
    <Fragment>
      <ModalConfirm onCancel={onCancel} onOk={onOkUpload} isOpen={isOpen}>
        {MESSAGE_C0015}
      </ModalConfirm>

      <ModalConfirm
        isOpen={isOpenConfirm}
        onCancel={onCancelConfirm}
        onOk={onOkConfirm}
      >
        {MESSAGE_C0031}
      </ModalConfirm>

      <ModalConfirm
        isOpen={isOpenReload}
        onCancel={onCancelReload}
        onOk={onOkReload}
      >
        {MESSAGE_C0019}
      </ModalConfirm>

      <Modal isOpen={isOpenSuccess} onOk={onOkSuccess} okText="OK" isCenter>
        <Title
          alignItems="flex-start"
          titleLeft={<PreLine>{MESSAGE_I0019}</PreLine>}
        ></Title>
      </Modal>
    </Fragment>
  )
}
