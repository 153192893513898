/* eslint-disable complexity */
import { getDate, getDateRange } from "../../../../../../../../utils/getDate"
import { getTime } from "../../../../../../../../utils/getTime"
import { SchedulesSearchConditionAdvancedType } from "../../../../../models/list"
import { SchedulesAdvancedSearchFormDataType } from "../../../../../models/list/search"

export const getDataSearchAdvanced = (
  data: SchedulesAdvancedSearchFormDataType,
): SchedulesSearchConditionAdvancedType => {
  const arrivalTime = getTime(
    data.arrivalDateTimeHour,
    data.arrivalDateTimeMinutes,
  )
  const arrivalDateTime =
    data.arrivalDateTimeDate &&
    getDate(getDateRange(data.arrivalDateTimeDate), arrivalTime)

  const departureTime = getTime(
    data.departureDateTimeHour,
    data.departureDateTimeMinutes,
  )
  const departureDateTime =
    data.departureDateTimeDate &&
    getDate(getDateRange(data.departureDateTimeDate), departureTime)

  return {
    ...data,
    arrivalDateTimeDate:
      data.arrivalDateTimeDate && arrivalDateTime ? arrivalDateTime : "",
    departureDateTimeDate:
      data.departureDateTimeDate && departureDateTime ? departureDateTime : "",
  }
}
