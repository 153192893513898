/* eslint-disable max-lines */
import { useVariables } from "../useVariables"
import { useEffect } from "react"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { Routers } from "../../../../../../../constant/Routers"
import {
  AttentionsInfoDataSourceType,
  UseAttentionsInfoType,
} from "../../../../models/info"
import { replaceString } from "../../../../../../../utils/replaceString"
import { concat } from "../../../../../../../utils/concat"
const { SCR317 } = Routers

export const useAttentionsInfo =
  (): UseAttentionsInfoType<AttentionsInfoDataSourceType> => {
    const {
      attentionInfo,
      attentionInfoList,
      breadcrumbItems,
      columns,
      getAttention,
      getAttentionTravelers,
      isCommonRefer,
      isHiddenSubmit,
      isLoading,
      isLoadingLayout,
      isRoleSubAdmin,
      push,
      setAttentionInfoList,
      state,
      userInformation,
    } = useVariables()

    const goBack = () => {
      if (state) {
        // 「SCR317_注意喚起の詳細画面」に遷移する。
        push({
          pathname:
            state.sourceURL === SCR317
              ? replaceString(SCR317, state.attentionId)
              : state.sourceURL,
          state,
        })
      }
    }

    useEffect(() => {
      // ユーザの画面アクセス許可チェックを処理する。
      if (!isCommonRefer) {
        //「SCR973_エラー情報の表示画面」に遷移する。
        const { pathname, state } = backToLogin()
        push({ pathname, state })
      } else if (state) {
        const { travelerList } = state
        getAttention()
        if (travelerList) {
          setAttentionInfoList(travelerList)
        } else {
          setAttentionInfoList([
            {
              attentionDetailId: "",
              fullNameKanji: concat(userInformation?.fullNameKanji),
              fullNameRomaji: concat(userInformation?.fullNameRomaji),
              travelerId: "",
            },
          ])
        }
      }
    }, [])

    return {
      attentionInfo,
      attentionInfoList,
      breadcrumbItems,
      columns,
      getAttentionTravelers,
      goBack,
      isHiddenSubmit,
      isLoading,
      isLoadingLayout,
      isRoleSubAdmin,
    }
  }
