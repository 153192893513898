import { useHistory } from "react-router-dom"
import { Routers } from "../../../../../../../../constant/Routers"

const { SCR971 } = Routers

export const useRouters = () => {
  const { push } = useHistory()

  const goToFilesUpload = () => {
    // 「SCR971_ファイルアップロード画面」に遷移する。
    push(SCR971)
  }

  return {
    goToFilesUpload,
  }
}
