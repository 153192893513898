import { FC, Fragment } from "react"
import { Translate } from "../../../../../../../../constant/Translate"
import { ButtonGroup } from "../../../../../../../../_webui/layout/components/Button/Group"
import { SubAdminSettingButtonGroupProps } from "../../../../../models/subAdmin/setting"

const { TEXT_T072, TEXT_T454, TEXT_T455, TEXT_T463 } = Translate

export const ButtonGroupPC: FC<SubAdminSettingButtonGroupProps> = (props) => {
  const {
    goBack,
    goToRolesSetting,
    goToSubAdmin,
    isDisabledBtnSave,
    isHiddenBtn,
  } = props

  return (
    <Fragment>
      <ButtonGroup
        buttonItems={[
          {
            className: "mr-0",
            isAbsolute: true,
            isHidden: !isHiddenBtn,
            isSmall: true,
            onClick: goBack,
            order: 2,
            text: TEXT_T072,
            type: "ghost",
          },
          {
            htmlType: "submit",
            isDisabled: isDisabledBtnSave,
            isHidden: !isHiddenBtn,
            order: 1,
            text: TEXT_T454,
            type: "primary",
          },
        ]}
      />

      <ButtonGroup
        buttonItems={[
          {
            isHidden: !isHiddenBtn,
            onClick: goToSubAdmin,
            order: 3,
            text: TEXT_T455,
            type: "default",
          },
          {
            isHidden: !isHiddenBtn,
            onClick: goToRolesSetting,
            order: 4,
            text: TEXT_T463,
            type: "default",
          },
        ]}
        isMargin={false}
      />
    </Fragment>
  )
}
