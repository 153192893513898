import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { TableCurrentDataSource } from "antd/lib/table/interface"
import { useEffect } from "react"
import { CheckboxAll } from "../../../../../../../../_webui/layout/components/RowSelection/CheckboxAll"
import { CheckboxItem } from "../../../../../../../../_webui/layout/components/RowSelection/CheckboxItem"
import { TableRowSelection } from "../../../../../../../../_webui/layout/components/Table/models"
import { UploadFileType } from "../../../../../models/files/upload"
import { useVariablesRowSelection } from "../useVariablesRowSelection"

export const useRowSelection = (fileInfoList: UploadFileType[]) => {
  const {
    dataCurrent,
    fileInfoListCurrent,
    isCheckAll,
    onChangePage,
    perItem,
    perItemEnd,
    perItemStart,
    selectedRowKeys,
    setDataCurrent,
    setFileInfoListCurrent,
    setIsCheckAll,
    setSelectedRowKeys,
  } = useVariablesRowSelection(fileInfoList.length)

  const onSelectChange = () => {
    if (selectedRowKeys.length === dataCurrent.length) setIsCheckAll(true)
    else setIsCheckAll(false)
  }

  const onChangeCheckAll = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    setIsCheckAll(checked)
    if (checked) {
      const _selectedRowKeys = dataCurrent.map((item) => item.index)
      setSelectedRowKeys(_selectedRowKeys)
    } else setSelectedRowKeys([])
  }

  const onChangeCheckItem = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    const value = event.target.value
    if (checked) setSelectedRowKeys([...selectedRowKeys, value])
    else {
      const _selectedRowKeys = selectedRowKeys.filter((item) => item !== value)
      setSelectedRowKeys(_selectedRowKeys)
    }
  }

  const rowSelection: TableRowSelection<UploadFileType> = {
    columnTitle: (
      <CheckboxAll
        checked={isCheckAll}
        isDisabled={!fileInfoList.length}
        onChange={onChangeCheckAll}
      />
    ),
    renderCell: (_, record) => (
      <CheckboxItem
        checked={selectedRowKeys.includes(record.index)}
        onChange={onChangeCheckItem}
        value={record.index}
      />
    ),
    selectedRowKeys,
  }

  const onChangePagination = (page: number, pageSize: number) => {
    onChangePage(page, pageSize)
    setIsCheckAll(false)
    setSelectedRowKeys([])
  }

  const onChangeTable = (extra: TableCurrentDataSource<UploadFileType>) => {
    setFileInfoListCurrent(extra.currentDataSource)

    setIsCheckAll(false)
    setSelectedRowKeys([])
  }

  useEffect(() => {
    if (fileInfoListCurrent?.length) {
      const _dataCurrent = fileInfoListCurrent.slice(perItemStart, perItemEnd)
      setDataCurrent(_dataCurrent)
    }
  }, [fileInfoListCurrent, setDataCurrent, perItemStart, perItemEnd])

  useEffect(() => {
    if (fileInfoList?.length) setFileInfoListCurrent(fileInfoList)
  }, [fileInfoList])

  useEffect(() => {
    if (fileInfoListCurrent?.length) onSelectChange()
  }, [selectedRowKeys, dataCurrent])

  return {
    onChangePagination,
    onChangeTable,
    perItem,
    rowSelection,
    selectedRowKeys,
  }
}
