import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const { isRoleSubAdmin, isAttentionsSendAll } = useAuthority()
  const isHiddenSubmitBtn = !(isAttentionsSendAll && isRoleSubAdmin)

  return {
    isAttentionsSendAll,
    isHiddenSubmitBtn,
  }
}
