import { Key, useState } from "react"
import { useTable } from "../../../../../../../utils/hook/useTable"
import { SafetiesListType } from "../../../../models/list"

export const useVariablesRowSelection = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [currentData, setCurrentData] = useState<SafetiesListType[]>([])
  const [isDisabledDetailBtn, setIsDisabledDetailBtn] = useState(true)
  const [isDisabledResponseBtn, setIsDisabledResponseBtn] = useState(true)
  const [safetyId, setSafetyId] = useState("")
  const [safetyDetailId, setSafetyDetailId] = useState("")
  const { onChange: onChangePage, perItem } = useTable(currentData.length)

  return {
    currentData,
    isDisabledDetailBtn,
    isDisabledResponseBtn,
    onChangePage,
    perItem,
    safetyDetailId,
    safetyId,
    selectedRowKeys,
    setCurrentData,
    setIsDisabledDetailBtn,
    setIsDisabledResponseBtn,
    setSafetyDetailId,
    setSafetyId,
    setSelectedRowKeys,
  }
}
