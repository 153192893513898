export const HTTP_STATUS_CODE = {
  BAD_REQUEST: 400,
  FORBIDDEN: 403,
  INTERNAL_SERVER_ERROR: 500,
  UNAUTHORIZED: 401,
}

export const ERROR_TYPE = {
  ERROR: "error",
  NETWORK_ERROR: "network_error",
  SERVER_ERROR: "server_error",
  SESSION_ERROR: "session_error",
  SESSION_FORBIDDEN: "session_forbidden",
}

export const ERROR_MESSAGE = {
  CLIENT_ERROR: "Client Error",
  ERROR: "Error",
  INVALID_SESSION: "Invalid_Session",
  NETWORK_ERROR: "Network Error",
  SERVER_ERROR: "Server Error",
}

export const DEBUG_TYPE = {
  NO_RESPONSE: "No response",
}

export const KEYCLOAK = {
  LIFESPAN: 3600,
  REQUIRED_ACTION: {
    UPDATE_PASSWORD: "UPDATE_PASSWORD",
  },
}
