import { TranslateMessageConfirm } from "./Confirm"
import { TranslateMessageError } from "./Error"
import { TranslateMessageInfo } from "./Info"
import { TranslateMessageWarning } from "./Warning"

export const TranslateMessage = {
  ...TranslateMessageConfirm,
  ...TranslateMessageError,
  ...TranslateMessageInfo,
  ...TranslateMessageWarning,
}
