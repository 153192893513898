import { Routers } from "../../../../../../../constant/Routers"
import { useHistory, useLocation } from "react-router-dom"
import { AttentionStateBackType } from "../../../../models/attention"
import { Key } from "react"

const { SCR210_ATTENTIONS, SCR312, SCR313, SCR961 } = Routers

export const useRouters = (selectedKeys: Key[]) => {
  const { push } = useHistory()
  const { state } = useLocation<AttentionStateBackType>()

  const handlePush = (pathname: string) => {
    if (pathname) {
      push({
        pathname: pathname,
        state: {
          ...state,
          selectedKeys,
          sourceURL: SCR312,
        },
      })
    }
  }

  /**
   *「SCR210_渡航予定情報の一覧画面」に遷移する。
   *「SCR313_注意喚起の内容変更画面」に遷移する。
   *「SCR961_注意喚起の複製画面」に遷移する。
   */
  const onGoBack = () => {
    handlePush(state?.sourceURL)
  }

  //「SCR210_渡航予定情報の一覧画面」に遷移する。
  const onReset = () => {
    handlePush(SCR210_ATTENTIONS)
  }

  //「SCR313_注意喚起の内容変更画面」に遷移する。
  const onEdit = () => {
    handlePush(SCR313)
  }

  //「SCR961_注意喚起の複製画面」に遷移する。
  const onCopy = () => {
    handlePush(SCR961)
  }

  return {
    onCopy,
    onEdit,
    onGoBack,
    onReset,
  }
}
