import { Key, useState } from "react"
import { TravelersEditColumnType } from "../../../../models/edit"

export const useVariablesRowSelection = () => {
  const [accompanies, setAccompanies] = useState<TravelersEditColumnType[]>([])
  const [accompaniesTemp, setAccompaniesTemp] = useState<
    TravelersEditColumnType[]
  >([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [total, setTotal] = useState(0)
  const [isDisabledBtnDelete, setIsDisabledBtnDelete] = useState(true)
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [isDisabledCheckAll, setIsDisabledCheckAll] = useState(false)
  const [indexTabActive, setIndexTabActive] = useState("1")

  return {
    accompanies,
    accompaniesTemp,
    indexTabActive,
    isCheckAll,
    isDisabledBtnDelete,
    isDisabledCheckAll,
    selectedRowKeys,
    setAccompanies,
    setAccompaniesTemp,
    setIndexTabActive,
    setIsCheckAll,
    setIsDisabledBtnDelete,
    setIsDisabledCheckAll,
    setSelectedRowKeys,
    setTotal,
    total,
  }
}
