const FNC9_Get_Authority_Pattern_Setting = "/v1/authority-pattern-settings/{0}"
const FNC9_Add_Authority_Pattern_Setting = "/v1/authority-pattern-settings"
const FNC9_Update_Authority_Pattern_Setting = "/v1/authority-pattern-settings"
const FNC9_Get_Authority_Pattern = "/v1/authority-patterns/{0}"
const FNC9_Get_Authority_Patterns = "/v1/authority-patterns"
const FNC9_Get_User_Authorities = "/v1/authorities"
const FNC9_Get_Files = "/v1/files"
const FNC9_Add_Files = "/v1/files"
const FNC9_Delete_Files = "/v1/files"
const FNC9_Get_General_Master = "/v1/general-masters/{0}"
const FNC9_Get_Departments = "/v1/departments"
const FNC9_Get_All_Departments = "/v1/all-departments"
const FNC9_Get_All_Countries = "/v1/all-countries"
const FNC9_Get_Countries = "/v1/countries"
const FNC9_Get_Cities = "/v1/cities"
const FNC9_Get_User_Authority_Pattern_Setting = "/v1/authority-pattern-setting"
const FNC9_Get_User_Authority_Type_Setting = "/v1/authority-type-setting"
const FNC9_Send_FAQ = "/v1/faq"
const FNC9_Get_Url = "/v1/url"
const FNC9_Get_Timezones = "/v1/timezones"
const FNC9_Get_Purpose_Travel = "/v1/purpose-travel"

export const COMMON_ENDPOINT = {
  FNC9_Add_Authority_Pattern_Setting,
  FNC9_Add_Files,
  FNC9_Delete_Files,
  FNC9_Get_All_Countries,
  FNC9_Get_All_Departments,
  FNC9_Get_Authority_Pattern,
  FNC9_Get_Authority_Pattern_Setting,
  FNC9_Get_Authority_Patterns,
  FNC9_Get_Cities,
  FNC9_Get_Countries,
  FNC9_Get_Departments,
  FNC9_Get_Files,
  FNC9_Get_General_Master,
  FNC9_Get_Purpose_Travel,
  FNC9_Get_Timezones,
  FNC9_Get_Url,
  FNC9_Get_User_Authorities,
  FNC9_Get_User_Authority_Pattern_Setting,
  FNC9_Get_User_Authority_Type_Setting,
  FNC9_Send_FAQ,
  FNC9_Update_Authority_Pattern_Setting,
}
