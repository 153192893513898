import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const { isRoleSubAdmin, isCommonRefer } = useAuthority()

  return {
    isCommonRefer,
    isRoleSubAdmin,
  }
}
