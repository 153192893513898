import { FC } from "react"
import { SafetiesAnswerForm } from "../../components/answer/Form"
import { Layout } from "./../../../../../_webui/layout/components/Layout/index"
import { useSafetiesAnswer } from "./modules/useAnswer"

export const SafetiesAnswer: FC = () => {
  const {
    breadcrumbItems,
    form,
    gridItems,
    isHiddenSubmit,
    isLoading,
    isLoadingLayout,
    isRoleAdmin,
    onFinish,
    onGoBack,
    safetyDetailInfo,
    titleHeading,
  } = useSafetiesAnswer()

  return (
    <Layout
      breadcrumbItems={breadcrumbItems}
      isHeader
      isLoadingLayout={isLoadingLayout}
      isSideBar
      title={titleHeading}
    >
      <SafetiesAnswerForm
        form={form}
        gridItems={gridItems}
        isHiddenSubmit={isHiddenSubmit}
        isLoading={isLoading}
        isRoleAdmin={isRoleAdmin}
        onFinish={onFinish}
        onGoBack={onGoBack}
        safetyDetailInfo={safetyDetailInfo}
        titleHeading={titleHeading}
      />
    </Layout>
  )
}
