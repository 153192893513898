import { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { MenusType } from "../../models"
import { useScrollDirection } from "../../../../../../utils/hook/useScrollDirection"
import { useModal } from "../../../../../../utils/hook/useModal"

export const useVariables = () => {
  const { push } = useHistory()
  const { pathname } = useLocation()
  const { isOpen, onCancel, onOpen } = useModal()
  const {
    isOpen: isOpenFormChange,
    onCancel: onCancelFormChange,
    onOpen: onOpenFormChange,
  } = useModal()
  const expandableMenu = localStorage.getItem("isExpandableMenu") || ""
  const [menus, setMenus] = useState<MenusType[]>([])
  const [nextLink, setNextLink] = useState("")
  const [isExpandableMenu, setIsExpandableMenu] = useState(expandableMenu)
  const { scrollDirection } = useScrollDirection()

  return {
    isExpandableMenu,
    isOpen,
    isOpenFormChange,
    menus,
    nextLink,
    onCancel,
    onCancelFormChange,
    onOpen,
    onOpenFormChange,
    pathname,
    push,
    scrollDirection,
    setIsExpandableMenu,
    setMenus,
    setNextLink,
  }
}
