import { Breadcrumbs } from "../../../../../../../../constant/Breadcrumbs"
import { Routers } from "../../../../../../../../constant/Routers"

const { BRCR_SCR316, BRCR_SCR316_HISTORY } = Breadcrumbs
const { SCR316, SCR316_HISTORY } = Routers

export const getBreadcrumbItems = (sourceURL?: string) => {
  if (sourceURL === SCR316) return BRCR_SCR316

  if (sourceURL === SCR316_HISTORY) return BRCR_SCR316_HISTORY
}
