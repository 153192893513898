import React, { FC, Fragment } from "react"
import { Translate } from "../../../../../../../constant/Translate"
import { ItemInput } from "../../../../../../../_webui/layout/components/Form/Item/Input"

const {
  TEXT_T078,
  TEXT_T079,
  TEXT_T080,
  TEXT_T081,
  TEXT_T082,
  TEXT_T246,
  TEXT_T247,
  TEXT_T248,
  TEXT_T249,
  TEXT_T250,
} = Translate

export const CollapseOthers: FC = () => (
  <Fragment>
    <ItemInput label={TEXT_T246} maxLength={200} name="othersRemarks1" />
    <ItemInput label={TEXT_T247} maxLength={200} name="othersRemarks2" />
    <ItemInput label={TEXT_T248} maxLength={200} name="othersRemarks3" />
    <ItemInput label={TEXT_T249} maxLength={200} name="othersRemarks4" />
    <ItemInput label={TEXT_T250} maxLength={200} name="othersRemarks5" />
    <ItemInput
      label={TEXT_T078}
      maxLength={200}
      name="othersAdministratorRemarks1"
    />
    <ItemInput
      label={TEXT_T079}
      maxLength={200}
      name="othersAdministratorRemarks2"
    />
    <ItemInput
      label={TEXT_T080}
      maxLength={200}
      name="othersAdministratorRemarks3"
    />
    <ItemInput
      label={TEXT_T081}
      maxLength={200}
      name="othersAdministratorRemarks4"
    />
    <ItemInput
      label={TEXT_T082}
      maxLength={200}
      name="othersAdministratorRemarks5"
    />
  </Fragment>
)
