import moment from "moment"
import { Translate } from "../../../../../../../../../../../../constant/Translate"
import { getDateTimeZoneByUserInformation } from "../../../../../../../../../../../../utils/getDate"
import { replaceStringParam } from "../../../../../../../../../../../../utils/replaceStringParam"
import { SafetiesDetailInfoType } from "../../../../../../../../../models/detail"
import { SafetyInfoHeaderType } from "../../../../../../../../../models/detail/export"

const {
  EXPORT_EP0186,
  EXPORT_EP0018, // 安否確認件名    Safety confirmation subject
  EXPORT_EP0156, // 発出日時    Departure date and time
  EXPORT_EP0099, // 帳票出力日時    Form output date and time
} = Translate

export const safetyInfoHeader = (
  dataLength: number,
  safetyInfo?: SafetiesDetailInfoType,
): SafetyInfoHeaderType => {
  const safetyTitle = `【${EXPORT_EP0018}】${safetyInfo?.safetyTitle}`
  const implementationDate = `${EXPORT_EP0156} ${getDateTimeZoneByUserInformation(
    safetyInfo?.implementationDate,
  )}`
  const currentDate = `${EXPORT_EP0099} ${getDateTimeZoneByUserInformation(
    moment().toString(),
  )}`
  const totalCountHeader = replaceStringParam(EXPORT_EP0186, dataLength)
  return {
    currentDate,
    implementationDate,
    safetyTitle,
    totalCountHeader,
  }
}
