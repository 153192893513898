/* eslint-disable complexity */
import { useState } from "react"
import { getUserInformation } from "../../../../../../../../utils/getUserInformation"
import keycloak from "../../../../../../../../utils/keycloak"
import { Environment } from "../../../../../../../../constant/Environment"
import { LoginActions } from "../../../../../../../../constant/Login"
import { translateHelper } from "../../../../../../../../utils/translateHelper"
import { checkPublicRouter } from "../../../../../../../../utils/checkPublicRouter"

const { KEY, SESSION, TIMEOUT } = LoginActions

export const useMenuItem = () => {
  const [active, setActive] = useState(false)
  const userInformation = getUserInformation()

  if (
    !userInformation &&
    keycloak.authenticated &&
    localStorage.getItem(KEY) !== SESSION
  ) {
    if (localStorage.getItem(KEY) !== TIMEOUT && !checkPublicRouter()) {
      localStorage.setItem(KEY, SESSION)
      alert(translateHelper.getSessionTimeOutMessage())
    }
    setTimeout(() => {
      if (localStorage.getItem(KEY) === SESSION) {
        localStorage.removeItem(KEY)
      }
      keycloak.logout({ redirectUri: `${Environment.URL}/home` })
    }, 500)
  }
  const handleActiveSubMenu = () => {
    const expandableMenu = localStorage.getItem("isExpandableMenu")
    if (expandableMenu !== "true") {
      setActive(!active)
    }
  }

  return {
    active,
    handleActiveSubMenu,
    travelerId: userInformation?.travelerId,
  }
}
