import styled, { css } from "styled-components"
import { Regex } from "../../../../../constant/Regex"
import { Colors } from "../../../../../constant/Styled/Colors"
import { Fonts } from "../../../../../constant/Styled/Fonts"
import { getValueByLanguage } from "../../../../../utils/getValueByLanguage"

const { REGEX_IS_DESKTOP } = Regex
const { CARD_DIVIDER } = Colors
const { FS_LARGE } = Fonts

const StyledNotification = styled.div`
  max-height: 550px;
  overflow: auto;
  position: relative;
`

const StyledNotificationItem = styled.div`
  border-bottom: 1px solid ${CARD_DIVIDER};
  &:last-child {
    border-bottom: none;
  }
  ${() =>
    REGEX_IS_DESKTOP
      ? css`
          display: flex;
          padding: 15px 20px;
        `
      : css`
          font-size: ${FS_LARGE};
          padding-bottom: 8px;
          padding-top: 8px;
        `}
`

const StyledNotificationTitle = styled.div`
  ${() =>
    REGEX_IS_DESKTOP &&
    css`
      min-width: ${getValueByLanguage("240px", "155px")};
      margin-right: 30px;
    `}
`

export const Styled = {
  StyledNotification,
  StyledNotificationItem,
  StyledNotificationTitle,
}
