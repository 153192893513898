/* eslint-disable max-statements */
import { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import {
  SubAdminSettingFormDataPostType,
  SubAdminSettingListType,
  UseSubAdminSettingStateType,
} from "../../../../../models/subAdmin/setting"
import { InitialValues } from "../../../../../constants/subAdmin/list/initialValues"
import { PatternId } from "../../../../../../../../constant/PatternId"
import { SubAdminFormSearchConditionType } from "../../../../../models/subAdmin/list"
import { useRowSelection } from "../useRowSelection"
import { useRouters } from "../useRouters"
import { OptionItemType } from "../../../../../../../../_webui/layout/components/Select/models"
import { Form } from "antd"

const { SEARCH_CONDITION_INITIAL_VALUES } = InitialValues
const { PATTERN_ADMIN, PATTERN_USER } = PatternId

export const useVariablesApi = () => {
  const [form] = Form.useForm()
  const [formSearch] = Form.useForm()
  const { push } = useHistory()
  const { state } = useLocation<UseSubAdminSettingStateType>()
  const patternIds = [PATTERN_ADMIN, PATTERN_USER]
  const [patternId, setPatternId] = useState(
    !patternIds.includes(String(state?.searchCondition?.patternId))
      ? state?.searchCondition?.patternId
      : "",
  )
  const [searchCondition, setSearchCondition] =
    useState<SubAdminFormSearchConditionType>(
      state?.searchCondition || SEARCH_CONDITION_INITIAL_VALUES,
    )
  const [values, setValues] = useState<SubAdminSettingFormDataPostType>()
  const [travelerList, setTravelerList] = useState<SubAdminSettingListType[]>(
    [],
  )
  const [authorityPatterns, setAuthorityPatterns] = useState<OptionItemType[]>(
    [],
  )
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)

  const {
    handleSetDefaultSortedInfo,
    isDisabledBtnSave,
    onChangePage,
    onChangeTable,
    perItem,
    rowSelection,
    selectedRowKeys,
    setIsDisabledBtnSave,
    setSelectedRowKeys,
    sortedInfo,
  } = useRowSelection({
    patternId,
    travelerList,
  })

  const { goBack, goToRolesSetting, goToSubAdmin, goToSubAdminConfirm } =
    useRouters({
      patternId,
      searchCondition,
      selectedRowKeys,
    })

  return {
    authorityPatterns,
    form,
    formSearch,
    goBack,
    goToRolesSetting,
    goToSubAdmin,
    goToSubAdminConfirm,
    handleSetDefaultSortedInfo,
    isDisabledBtnSave,
    isLoading,
    isLoadingLayout,
    onChangePage,
    onChangeTable,
    patternId,
    patternIds,
    perItem,
    push,
    rowSelection,
    searchCondition,
    selectedRowKeys,
    setAuthorityPatterns,
    setIsDisabledBtnSave,
    setIsLoading,
    setIsLoadingLayout,
    setPatternId,
    setSearchCondition,
    setSelectedRowKeys,
    setTravelerList,
    setValues,
    sortedInfo,
    state,
    travelerList,
    values,
  }
}
