import { useState } from "react"
import { useHistory } from "react-router-dom"
import { useModal } from "../../../../../../../utils/hook/useModal"
import { useGridConfig } from "../useGridConfig"
import { useRouters } from "../useRouters"
import { useTableConfig } from "../useTableConfig"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { useApi } from "../../../../../attentions/containers/attention/modules/useApi"
import { useTableFormConfig } from "../../../../../attentions/containers/attention/modules/useTableFormConfig"

export const useVariables = () => {
  const { push } = useHistory()
  const { isOpen, onCancel, onOpen } = useModal()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const {
    form,
    isDisabledBtnSelect,
    isLoading: isLoadingApi,
    isOpenForm,
    onAddOrRemoveSafeties,
    onCancelForm,
    onFinish,
    onOpenForm,
    rowSelectionAttention,
    safetiesList,
    safetiesSelected,
    selectedKeys,
    setSafetiesSelected,
    setSelectedKeys,
  } = useApi()
  const {
    travelerInfoList,
    goBack,
    goToSafetiesClone,
    goToSafetiesEdit,
    goToSchedulesList,
    setTravelerInfoList,
    state,
  } = useRouters(selectedKeys)
  const { columns } = useTableConfig()
  const { gridItems } = useGridConfig(state?.transmissionInfo)
  const { columns: safetiesColumns } = useTableFormConfig()

  const {
    isHiddenBackBtn,
    isHiddenCopyBtn,
    isHiddenScheduleListBtn,
    isRoleSubAdmin,
    isSafetiesSendAll,
  } = useVariablesAuthority()

  const isDisabledSubmit =
    !isRoleSubAdmin ||
    !state?.travelerInfoList?.length ||
    !state?.transmissionInfo

  return {
    columns,
    form,
    goBack,
    goToSafetiesClone,
    goToSafetiesEdit,
    goToSchedulesList,
    gridItems,
    isDisabledBtnSelect,
    isDisabledSubmit,
    isHiddenBackBtn,
    isHiddenCopyBtn,
    isHiddenScheduleListBtn,
    isLoading: isLoading || isLoadingApi,
    isLoadingLayout,
    isOpen,
    isOpenForm,
    isSafetiesSendAll,
    onAddOrRemoveSafeties,
    onCancel,
    onCancelForm,
    onFinish,
    onOpen,
    onOpenForm,
    push,
    rowSelectionAttention,
    safetiesColumns,
    safetiesList,
    safetiesSelected,
    selectedKeys,
    setIsLoading,
    setIsLoadingLayout,
    setSafetiesSelected,
    setSelectedKeys,
    setTravelerInfoList,
    state,
    travelerInfoList,
  }
}
