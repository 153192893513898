import { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import {
  AttentionsCloneDataType,
  AttentionsStateType,
} from "../../../../models/clone"
import { useRowSelection } from "../useRowSelection"
import { useTableConfig } from "../useTableConfig"
import { useVariablesAuthority } from "../useVariablesAuthority"

export const useVariables = () => {
  const { push } = useHistory()
  const { state } = useLocation<AttentionsStateType>()
  const { columns } = useTableConfig()
  const [attentionInfoList, setAttentionInfoList] = useState<
    AttentionsCloneDataType[]
  >([])
  const {
    isActiveBtnClone,
    onChange,
    onChangeTable,
    perItem,
    rowSelection,
    selectedRowKeys,
  } = useRowSelection(attentionInfoList)
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const { isHiddenCopyBtn, isAttentionsSendAll } = useVariablesAuthority()

  return {
    attentionInfoList,
    columns,
    isActiveBtnClone,
    isAttentionsSendAll,
    isHiddenCopyBtn,
    isLoadingLayout,
    onChange,
    onChangeTable,
    perItem,
    push,
    rowSelection,
    selectedRowKeys,
    setAttentionInfoList,
    setIsLoadingLayout,
    state,
  }
}
