import { Key, useState } from "react"
import { useTable } from "../../../../../../../../utils/hook/useTable"
import { SubAdminListType } from "../../../../../models/subAdmin/list"

export const useVariablesRowSelection = (dataLength: number) => {
  const [dataCurrent, setDataCurrent] = useState<SubAdminListType[]>([])
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [isDisabledCheckAll, setIsDisabledCheckAll] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [authorityPatternSettingsCurrent, setAuthorityPatternSettingsCurrent] =
    useState<SubAdminListType[]>([])

  const {
    onChange: onChangePage,
    perItem,
    perItemEnd,
    perItemStart,
  } = useTable(dataLength)

  return {
    authorityPatternSettingsCurrent,
    dataCurrent,
    isCheckAll,
    isDisabledCheckAll,
    onChangePage,
    perItem,
    perItemEnd,
    perItemStart,
    selectedRowKeys,
    setAuthorityPatternSettingsCurrent,
    setDataCurrent,
    setIsCheckAll,
    setIsDisabledCheckAll,
    setSelectedRowKeys,
  }
}
