import { Form } from "antd"
import { FC } from "react"
import { Regex } from "../../../../../constant/Regex"
import { FormAntProps } from "./models"

const { REGEX_IS_DESKTOP } = Regex

export const FormAnt: FC<FormAntProps> = (props) => {
  const {
    children,
    className,
    form,
    initialValues,
    labelAlign = "left",
    layout = REGEX_IS_DESKTOP ? "horizontal" : "vertical",
    name,
    onFinish,
    onFinishFailed,
    onValuesChange,
    ...rest
  } = props

  return (
    <Form
      className={className}
      form={form}
      initialValues={initialValues}
      labelAlign={labelAlign}
      layout={layout}
      name={name}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      scrollToFirstError
      {...rest}
    >
      {children}
    </Form>
  )
}
