/* eslint-disable complexity */
import { FC, ReactNode, useEffect } from "react"
import { ItemRenderType, PaginationProps } from "./models"
import { selectItem } from "./constants"
import { Styled } from "./styled"
import { Translate } from "../../../../constant/Translate"

const {
  StyledArrowIconLeft,
  StyledArrowIconRight,
  StyledPagination,
  StyledPaginationAnt,
  StyledPaginationPerItem,
} = Styled
const { TEXT_T517 } = Translate

export const Pagination: FC<PaginationProps> = (props) => {
  const {
    current,
    defaultCurrent,
    defaultPageSize,
    onChange,
    onChangeSelect,
    pageSize,
    total,
  } = props

  useEffect(() => {
    document.querySelectorAll(".ant-pagination-page-number")?.forEach((elm) => {
      const emlLi = elm.closest("li")
      if (emlLi?.classList.contains("ant-pagination-item-active")) {
        emlLi?.setAttribute(
          "aria-label",
          `Page ${elm.getAttribute("data-page")}: Current selected`,
        )
      } else {
        emlLi?.setAttribute(
          "aria-label",
          `Page ${elm.getAttribute("data-page")}`,
        )
      }
      emlLi?.removeAttribute("title")
    })
  })

  return (
    <StyledPagination className="pagination">
      <StyledPaginationPerItem
        placeholder={TEXT_T517}
        defaultValue={25}
        onChange={onChangeSelect}
        selectItem={selectItem}
        value={`${pageSize}${TEXT_T517}`}
      />
      <StyledPaginationAnt
        current={current}
        defaultCurrent={defaultCurrent}
        defaultPageSize={defaultPageSize}
        pageSize={pageSize}
        itemRender={(
          page: number,
          type: ItemRenderType,
          element: ReactNode,
        ) => {
          if (type === "page")
            return (
              <span
                aria-label={`Page ${page}`}
                className="ant-pagination-page-number"
                data-page={page}
                role="button"
              >
                {page}
              </span>
            )
          if (type === "prev") {
            return <StyledArrowIconLeft />
          }
          if (type === "next") {
            return <StyledArrowIconRight />
          }
          if (type === "jump-next" || type === "jump-prev") {
            return "•••"
          }
          return element
        }}
        onChange={onChange}
        showSizeChanger={false}
        total={total}
      />
    </StyledPagination>
  )
}
