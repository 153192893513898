import { FC } from "react"
import { ItemInput } from "../../../../../../_webui/layout/components/Form/Item/Input"
import { ItemSelect } from "../../../../../../_webui/layout/components/Form/Item/Select"
import { FormSearch } from "../../../../../../_webui/layout/components/Form/Search"
import { FormSearchLeft } from "../../../../../../_webui/layout/components/Form/Search/Left"
import { FormSearchRight } from "../../../../../../_webui/layout/components/Form/Search/Right"
import { Translate } from "../../../../../../constant/Translate"
import { TravelersUserSearchFormProps } from "./modals"

const { TEXT_T009, TEXT_T167, TEXT_T192 } = Translate

export const FormSearchTravelersUser: FC<TravelersUserSearchFormProps> = (
  props,
) => {
  const { onFinish, departments, form } = props
  return (
    <FormSearch
      form={form}
      heading={TEXT_T167}
      labelAlign="left"
      onFinish={onFinish}
    >
      <FormSearchLeft>
        <ItemInput label={TEXT_T192} name="fullName" />
      </FormSearchLeft>

      <FormSearchRight>
        <ItemSelect label={TEXT_T009} name="bumonCd" selectItem={departments} />
      </FormSearchRight>
    </FormSearch>
  )
}
