import { FC } from "react"
import { CenterProps } from "./models"
import { Styled } from "./styled"

const { StyledCenter } = Styled

export const Center: FC<CenterProps> = (props) => {
  const { children, isBtnFullWidth, isFlex, isMarginTop } = props

  return (
    <StyledCenter
      isBtnFullWidth={isBtnFullWidth}
      isFlex={isFlex}
      isMarginTop={isMarginTop}
    >
      {children}
    </StyledCenter>
  )
}
