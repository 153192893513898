import { Breadcrumbs } from "../../../../../../../../constant/Breadcrumbs"

const {
  BRCR_SCR117_FROM_SCR113,
  BRCR_SCR117_FROM_SCR116_USER,
  BRCR_SCR117_FROM_SCR116,
  BRCR_SCR117,
} = Breadcrumbs

export const getBreadcrumbItems = (
  isPersonUrl: boolean,
  isFromSCR116?: boolean,
  isFromSCR113?: boolean,
) =>
  isFromSCR116
    ? isFromSCR113
      ? BRCR_SCR117_FROM_SCR113
      : isPersonUrl
      ? BRCR_SCR117_FROM_SCR116
      : !isPersonUrl
      ? BRCR_SCR117_FROM_SCR116_USER
      : BRCR_SCR117
    : BRCR_SCR117
