const SCR312 = "/attention"
const SCR313 = "/attentions/edit"
const SCR315 = "/attentions/success"
const SCR316 = "/attentions"
const SCR316_HISTORY = "/attentions/history"
const SCR317 = "/attentions/detail/:id"
const SCR318 = "/attentions/info/:id"
const SCR318_MAIL = "/attentions/info-mail"
const SCR319 = "/attentions/answer"

export const AttentionsRouters = {
  SCR312,
  SCR313,
  SCR315,
  SCR316,
  SCR316_HISTORY,
  SCR317,
  SCR318,
  SCR318_MAIL,
  SCR319,
}
