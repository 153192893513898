import { concat } from "../../../../../../../../utils/concat"
import { formatDateSaveDB } from "../../../../../../../../utils/getDate"
import {
  GetSchedulesAddLodgingsSubmitProps,
  SchedulesAddLodgingsAddType,
} from "../../../../../models/add/DataAdd"

export const getSchedulesAddLodgingsSubmit = (
  data: GetSchedulesAddLodgingsSubmitProps,
): SchedulesAddLodgingsAddType => {
  const { id, isSchedulesAdd, isSchedulesEdit, item } = data

  const itemNew = { ...item }

  if (itemNew["lodgingCheckin"])
    itemNew["lodgingCheckin"] = formatDateSaveDB(itemNew["lodgingCheckin"])

  if (itemNew["lodgingCheckout"])
    itemNew["lodgingCheckout"] = formatDateSaveDB(itemNew["lodgingCheckout"])

  if (isSchedulesAdd) delete itemNew["travelLodgingId"]
  else if (isSchedulesEdit) concat(id)

  return itemNew
}
