import { useState } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { getUserInformation } from "../../../../../../../utils/getUserInformation"
import { UseParamsType } from "../../../../../../../utils/useParams"
import {
  TravelersEditSuccessCollapseType,
  TravelersEditSuccessStateProps,
} from "../../../../models/editSuccess"
import { useTableConfig } from "../useTableConfig"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { getBreadcrumbItems } from "./getBreadcrumbItems"

export const useVariables = () => {
  const { state } = useLocation<TravelersEditSuccessStateProps>()
  const { push } = useHistory()
  const { id: travelerId } = useParams<UseParamsType>()
  const userInformation = getUserInformation()
  const { columns } = useTableConfig()
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [detail, setDetail] = useState<TravelersEditSuccessCollapseType>()
  const { isCommonRefer, isHiddenBtn, isRoleAdmin, isRoleSubAdmin } =
    useVariablesAuthority()
  const isPersonUrl = travelerId === userInformation?.travelerId

  const breadcrumbItems = getBreadcrumbItems(
    isPersonUrl,
    state?.isFromSCR116,
    state?.isFromSCR113,
  )

  return {
    breadcrumbItems,
    columns,
    detail,
    isCommonRefer,
    isHiddenBtn,
    isLoadingLayout,
    isRoleAdmin,
    isRoleSubAdmin,
    push,
    setDetail,
    setIsLoadingLayout,
    state,
    travelerId,
  }
}
