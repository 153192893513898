import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { Routers } from "../../../../../../../constant/Routers"
import { pushStateError } from "../../../../../../../utils/pushStateError"
import { useState } from "react"
import {
  AttentionsInfoAttentionType,
  AttentionsInfoStateType,
} from "../../../../models/info"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { attentionApi } from "../../../../apis"
import { UseParamsType } from "../../../../../../../utils/useParams"
import { GeneralCode } from "../../../../../../../constant/GeneralCode"

const { IPTC003 } = GeneralCode
const { SCR317, SCR318, SCR319, SCR973 } = Routers

export const useApi = () => {
  const { push } = useHistory()
  const { state } = useLocation<AttentionsInfoStateType>()
  const { id: attentionId } = useParams<UseParamsType>()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [isHiddenSubmit, setIsHiddenSubmit] = useState(state?.isHiddenSubmit)
  const [attentionInfo, setAttentionInfo] =
    useState<AttentionsInfoAttentionType>()

  const getAttention = () => {
    // APIを呼び出し、APIからの応答結果を「attentionInfo」変数に設定する。
    attentionApi
      .getAttention({ attentionDetailId: "", attentionId })
      .then((res: AttentionsInfoAttentionType) => {
        // 「attentionInfo」の値を設定する。
        setAttentionInfo(res)
        setIsLoadingLayout(false)
        if (res.severity === IPTC003) setIsHiddenSubmit(true)
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const stateError = pushStateError(error)
        push({ pathname: SCR973, state: stateError })
      })
  }

  const getAttentionTravelers = () => {
    setIsLoading(true)
    // APIを呼び出し、APIからの応答結果を「attentionInfoJson」変数に設定する。
    const _listAttentionDetailId = state.travelerList?.map(
      (item) => item.attentionDetailId,
    )
    attentionApi
      .confirmAttention({
        attentionId,
        isCheckExpired: state.isFromSCR316,
        isFromEmail: false,
        listAttentionDetailId:
          state.sourceURL === SCR317
            ? _listAttentionDetailId
            : state.listAttentionDetailId,
      })
      .then(() => {
        // 「SCR319_注意喚起情報の応答画面」に遷移する。
        push({
          pathname: SCR319,
          state: {
            attentionId: attentionId,
            isFromEmail: false,
            isFromSCR316: state?.isFromSCR316,
            isFromSCR316History: state?.isFromSCR316History,
            sourceURL: SCR318,
          },
        })
        setIsLoading(false)
      })
      .catch((error: AxiosErrorType) => {
        // 「SCR973_エラー情報の表示画面」に遷移する。
        const stateError = pushStateError(error)
        push({ pathname: SCR973, state: stateError })
      })
  }

  return {
    attentionInfo,
    getAttention,
    getAttentionTravelers,
    isFromSCR316: state?.isFromSCR316,
    isFromSCR316History: state?.isFromSCR316History,
    isHiddenSubmit,
    isLoading,
    isLoadingLayout,
    push,
    sourceURL: state?.sourceURL,
    state,
  }
}
