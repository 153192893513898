import styled, { css } from "styled-components"
import { Regex } from "../../../../../../../constant/Regex"

const { REGEX_IS_DESKTOP } = Regex

const StyledFormSearchLeft = styled.div`
  ${REGEX_IS_DESKTOP &&
  css`
    width: 40.75%;
  `}
`

export const Styled = {
  StyledFormSearchLeft,
}
