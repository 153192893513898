import { FC } from "react"
import { Translate } from "../../../../../constant/Translate"
import { useHome } from "./modules/useHome"
import { Notification } from "../../../../../_webui/layout/components/Notification"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { Timezone } from "../../components/Timezone"
import { Select } from "../../../../../_webui/layout/components/Select"
import { Space } from "../../../../../_webui/layout/components/Space"
import { Styled } from "./styled"
import { Regex } from "../../../../../constant/Regex"
import { Popup } from "../../../../../_webui/layout/components/Modal/Popup"

const { REGEX_IS_DESKTOP } = Regex
const {
  StyledChangeLanguage,
  StyledHomeNotice,
  StyledHomeNoticeCategory,
  StyledHomeWidget,
  StyledHomeWidgetContent,
} = Styled
const { MESSAGE_W0016, TEXT_T135, TEXT_T414, TEXT_T438, TEXT_T441, TEXT_T445 } =
  Translate

export const Home: FC = () => {
  const {
    generalMasters,
    goToTimezoneSetting,
    isLoading,
    isLoadingLayout,
    isOpen,
    notificationItems,
    onChangeNoticeCategory,
    setLanguageToEnglish,
    setLanguageToJapanese,
    timezone,
    timezoneValue,
  } = useHome()

  return (
    <Layout isLoadingLayout={isLoadingLayout} title={TEXT_T438}>
      <Space isSpaceBottom>
        <StyledHomeWidget>
          <StyledHomeWidgetContent heading={TEXT_T445} isCenter>
            <Timezone timezone={timezone} timezoneValue={timezoneValue} />
          </StyledHomeWidgetContent>
          {REGEX_IS_DESKTOP && (
            <StyledHomeWidgetContent heading={TEXT_T135} isCenter>
              <StyledChangeLanguage
                setLanguageToEnglish={setLanguageToEnglish}
                setLanguageToJapanese={setLanguageToJapanese}
              />
            </StyledHomeWidgetContent>
          )}
        </StyledHomeWidget>
      </Space>
      {!REGEX_IS_DESKTOP && (
        <Space isSpaceAll>
          <StyledChangeLanguage
            setLanguageToEnglish={setLanguageToEnglish}
            setLanguageToJapanese={setLanguageToJapanese}
          />
        </Space>
      )}
      <StyledHomeNotice
        childrenCustom={
          <StyledHomeNoticeCategory>
            <Select
              onChange={onChangeNoticeCategory}
              isBordered={false}
              placeholder={TEXT_T441}
              selectItem={generalMasters}
            />
          </StyledHomeNoticeCategory>
        }
        heading={TEXT_T414}
        isCenter
        isLoading={isLoading}
      >
        <Notification notificationItems={notificationItems} />
      </StyledHomeNotice>
      <Popup
        okText="OK"
        cancelText=""
        isOpen={isOpen}
        onOk={goToTimezoneSetting}
      >
        {MESSAGE_W0016}
      </Popup>
    </Layout>
  )
}
