import { convertDataExport } from "./convertDataExport"
import { SchedulesListDataType } from "../../../../../models/list"
import { exportCsv } from "../../../../../../../../utils/exportCsv"
import { Translate } from "../../../../../../../../constant/Translate"

const { EXPORT_EP0188 } = Translate

export const exportFRMDefault = (
  data: SchedulesListDataType[],
  pathname: string,
) => {
  const convertData = convertDataExport(data, pathname)
  exportCsv(convertData, EXPORT_EP0188)
}
