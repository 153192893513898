import { Breadcrumbs } from "../../../../../../../../../constant/Breadcrumbs"

const {
  BRCR_SCR953_FROM_SCR950,
  BRCR_SCR953_FROM_SCR951,
  BRCR_SCR953_FROM_SCR952,
  BRCR_SCR953,
} = Breadcrumbs

export const getBreadcrumbItems = (
  isFromSCR950?: boolean,
  isFromSCR951?: boolean,
  isFromSCR952?: boolean,
) => {
  if (isFromSCR950) return BRCR_SCR953_FROM_SCR950

  if (isFromSCR951) return BRCR_SCR953_FROM_SCR951

  if (isFromSCR952) return BRCR_SCR953_FROM_SCR952

  return BRCR_SCR953
}
