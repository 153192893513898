/* eslint-disable max-lines */
import { Translate } from "../../../../../../../constant/Translate"
import { concat } from "../../../../../../../utils/concat"
import { getFullName } from "../../../../../../../utils/getFullName"
import { ItemRadio } from "../../../../../../../_webui/layout/components/Form/Item/Radio"
import { ItemTextArea } from "../../../../../../../_webui/layout/components/Form/Item/TextArea"
import { GridItemType } from "../../../../../../../_webui/layout/components/Grid/models"
import { Tooltip } from "../../../../../../../_webui/layout/components/Tooltip"
import { UseSafetiesAnswerGridItemProps } from "../../../../models/answer"
import { Variables } from "../../../../constants/answer/variables"
import { getValueByLanguage } from "../../../../../../../utils/getValueByLanguage"
import { getDateTimeZoneByUserInformation } from "../../../../../../../utils/getDate"

const {
  HELP_H0027,
  TEXT_T025,
  TEXT_T315,
  TEXT_T333,
  TEXT_T340,
  TEXT_T375,
  TEXT_T378,
  TEXT_T381,
  TEXT_T396,
} = Translate
const { FSST_LIST, SFTS_LIST } = Variables

export const useGridConfig = (props: UseSafetiesAnswerGridItemProps) => {
  const { safetyDetailInfo, safetyInfo, isRoleUser, isRoleSubAdmin } = props

  const gridItemRoleUser = [
    {
      key: TEXT_T315,
      text: safetyInfo?.safetyTitle,
    },
    {
      key: TEXT_T333,
      text:
        safetyInfo?.implementationDate &&
        getDateTimeZoneByUserInformation(safetyInfo.implementationDate),
    },
    {
      key: TEXT_T396,
      text: getValueByLanguage(
        getFullName(
          concat(safetyDetailInfo?.surname),
          concat(safetyDetailInfo?.name),
          true,
        ),
        getFullName(
          concat(safetyDetailInfo?.surname),
          concat(safetyDetailInfo?.name),
        ),
      ),
    },
    {
      key: TEXT_T025,
      text: safetyDetailInfo?.dispatchTypeDetailName,
    },
  ]

  const gridItemsRoleSubAdmin = [
    ...gridItemRoleUser,
    {
      key: TEXT_T340,
      text: getValueByLanguage(
        getFullName(
          concat(safetyDetailInfo?.proxySurname),
          concat(safetyDetailInfo?.proxyName),
          true,
        ),
        getFullName(
          concat(safetyDetailInfo?.proxySurname),
          concat(safetyDetailInfo?.proxyName),
        ),
      ),
    },
  ]

  const gridItemByRole = isRoleUser
    ? gridItemRoleUser
    : isRoleSubAdmin
    ? gridItemsRoleSubAdmin
    : []

  const gridItems: GridItemType[] = [
    ...gridItemByRole,
    {
      key: TEXT_T375,
      text: <ItemRadio name="safetyStatus" radioItems={SFTS_LIST} />,
    },
    {
      key: <Tooltip title={HELP_H0027}>{TEXT_T378}</Tooltip>,
      text: <ItemRadio name="familySafetyStatus" radioItems={FSST_LIST} />,
    },
    {
      key: <label htmlFor="message">{TEXT_T381}</label>,
      text: <ItemTextArea maxLength={500} name="message" required rows={7} />,
    },
  ]

  return {
    gridItems,
  }
}
