/* eslint-disable max-lines */
import { useEffect } from "react"
import { GeneralCode } from "../../../../../../../constant/GeneralCode"
import { Routers } from "../../../../../../../constant/Routers"
import { backToLogin } from "../../../../../../../utils/backToLogin"
import { RequiredFields } from "../../../../constants/edit/requiredFields"
import {
  SafetiesEditDataType,
  UseSafetiesEditType,
} from "../../../../models/edit"
import { useVariables } from "../useVariables"

const { SCR412, SCR413 } = Routers
const { CTGR_OTHER } = GeneralCode
const { SAFETIES_EDIT_REQUIRED_FIELDS } = RequiredFields

export const useSafetiesEdit =
  (): UseSafetiesEditType<SafetiesEditDataType> => {
    const {
      breadcrumbItems,
      categoryValueList,
      columns,
      form,
      gridItems,
      isDisabledBtnSubmit,
      isHiddenEditBtn,
      isLoading,
      isLoadingLayout,
      isSafetiesSendAll,
      push,
      setIsDisabledBtnSubmit,
      setIsLoading,
      setIsLoadingLayout,
      setTravelerInfoList,
      state,
      travelerInfoList,
    } = useVariables()

    const onValuesChange = () => {
      const fieldsRequiredNew =
        form.getFieldValue("categoryId") === CTGR_OTHER
          ? [...SAFETIES_EDIT_REQUIRED_FIELDS, "categoryValue"]
          : SAFETIES_EDIT_REQUIRED_FIELDS

      const formValues = form.getFieldsValue(fieldsRequiredNew)

      setIsDisabledBtnSubmit(Object.values(formValues).some((item) => !item))
    }

    const onFinish = (values: SafetiesEditDataType) => {
      setIsLoading(true)
      const { categoryId, categoryValue } = values
      const categoryName = categoryValueList?.find(
        (item) => item.value === categoryId,
      )?.text

      //「categoryId」がカテゴリ（その他）のIDである場合
      const transmissionInfo = {
        ...values,
        categoryName: categoryName || state.transmissionInfo?.categoryName,
        categoryValue:
          categoryId === CTGR_OTHER
            ? categoryValue
            : state?.transmissionInfo?.categoryValue,
      }

      setTimeout(() => {
        // 「SCR412_安否確認の内容確認画面」に遷移する。
        push({
          pathname: SCR412,
          state: {
            ...state,
            sourceURL: SCR413,
            transmissionInfo: transmissionInfo || state?.transmissionInfo,
          },
        })
        setIsLoading(false)
      }, 500)
    }

    /**
     * 「SCR412_安否確認の内容確認画面」に遷移する。
     * 「SCR962_安否確認の複製画面」に遷移する。
     */
    const goBack = () => {
      if (state) {
        const { sourceURL } = state
        push({
          pathname: sourceURL,
          state: {
            ...state,
            sourceURL: SCR413,
          },
        })
      }
    }

    useEffect(() => {
      if (state && categoryValueList?.length) {
        const { transmissionInfo, travelerInfoList } = state

        if (transmissionInfo) {
          const isCategoryId = categoryValueList.some(
            (item) => item.value === transmissionInfo.categoryId,
          )

          form.setFieldsValue({
            bodyContent: transmissionInfo.bodyContent,
            categoryId: isCategoryId ? transmissionInfo.categoryId : "",
            categoryName: transmissionInfo.categoryName,
            categoryValue: transmissionInfo.categoryValue,
            safetyTitle: transmissionInfo.safetyTitle,
          })
        }

        setTravelerInfoList(travelerInfoList)

        setTimeout(() => {
          onValuesChange()
        }, 100)
        setTimeout(() => {
          setIsLoadingLayout(false)
        }, 500)
      }
    }, [categoryValueList])

    useEffect(() => {
      // ユーザの画面アクセス許可チェックを処理する。
      if (!isSafetiesSendAll) {
        //「SCR973_エラー情報の表示画面」に遷移する。
        const { pathname, state } = backToLogin()
        push({ pathname, state })
      }
    }, [])

    return {
      breadcrumbItems,
      columns,
      form,
      goBack,
      gridItems,
      isDisabledBtnSubmit,
      isHiddenEditBtn,
      isLoading,
      isLoadingLayout,
      onFinish,
      onValuesChange,
      travelerInfoList,
    }
  }
