/* eslint-disable max-lines */
import { Translate } from "../../Translate"

const {
  TEXT_T001, // 渡航者情報の個人登録
  TEXT_T021, // 渡航者情報の一覧
  TEXT_T034, // 削除
  TEXT_T035, // 渡航者情報の参照
  TEXT_T084, // 渡航者情報の変更
  TEXT_T097, // 渡航者情報の変更完了
  TEXT_T099, // マイプロフィール
  TEXT_T101, // 確認・変更
  TEXT_T103, // 一括処理（ユーザ情報）
  TEXT_T108, // 渡航者情報の検索
  TEXT_T110, // ユーザ削除依頼状況
  TEXT_T114, // 渡航者情報の一括登録
  TEXT_T147, // 登録
  TEXT_T149, // 渡航者情報の一括変更
  TEXT_T150, // ユーザ登録
  TEXT_T161, // 渡航者情報の一括削除
  TEXT_T166, // 渡航者情報の削除依頼
  TEXT_T187, // 渡航者情報の削除依頼確認
  TEXT_T384, // 登録確認
  TEXT_T476, // 変更
  TEXT_T519, // ホーム
  TEXT_T534, // 渡航者情報の個人登録完了
  TEXT_T587, // 管理者メニュー
  TEXT_T590, // ユーザ管理
  TEXT_T591, // 新規予定登録
  TEXT_T611, // 渡航者情報の削除確認
} = Translate

const BRCR_SCR112 = [TEXT_T519, TEXT_T587, TEXT_T150, TEXT_T001]
const BRCR_SCR113 = [TEXT_T519, TEXT_T587, TEXT_T150, TEXT_T001, TEXT_T534]
const BRCR_SCR114 = [TEXT_T519, TEXT_T587, TEXT_T591, TEXT_T021]
const BRCR_SCR114_USER = [TEXT_T519, TEXT_T587, TEXT_T590, TEXT_T021]
const BRCR_SCR114_USER_SEARCH = [TEXT_T519, TEXT_T384, TEXT_T021]
const BRCR_SCR115 = [TEXT_T519, TEXT_T099, TEXT_T101, TEXT_T035]
const BRCR_SCR115_USER = [TEXT_T519, TEXT_T587, TEXT_T590, TEXT_T021, TEXT_T035]
const BRCR_SCR115_FROM_SCR114 = [TEXT_T519, TEXT_T384, TEXT_T035]
const BRCR_SCR116 = [TEXT_T084]
const BRCR_SCR116_FROM_SCR113 = [
  TEXT_T519,
  TEXT_T587,
  TEXT_T150,
  TEXT_T001,
  TEXT_T534,
  TEXT_T084,
]
const BRCR_SCR116_FROM_SCR115 = [
  TEXT_T519,
  TEXT_T099,
  TEXT_T101,
  TEXT_T035,
  TEXT_T084,
]
const BRCR_SCR116_FROM_SCR115_USER = [
  TEXT_T519,
  TEXT_T587,
  TEXT_T590,
  TEXT_T021,
  TEXT_T035,
  TEXT_T084,
]
const BRCR_SCR117 = [TEXT_T097]
const BRCR_SCR117_FROM_SCR113 = [
  TEXT_T519,
  TEXT_T587,
  TEXT_T150,
  TEXT_T001,
  TEXT_T534,
  TEXT_T084,
  TEXT_T097,
]
const BRCR_SCR117_FROM_SCR116 = [
  TEXT_T519,
  TEXT_T099,
  TEXT_T101,
  TEXT_T035,
  TEXT_T084,
  TEXT_T097,
]
const BRCR_SCR117_FROM_SCR116_USER = [
  TEXT_T519,
  TEXT_T587,
  TEXT_T590,
  TEXT_T021,
  TEXT_T084,
  TEXT_T097,
]
const BRCR_SCR119_FROM_SCR115 = [
  TEXT_T519,
  TEXT_T587,
  TEXT_T590,
  TEXT_T021,
  TEXT_T035,
]
const BRCR_SCR119_FROM_SCR126 = [TEXT_T519, TEXT_T587, TEXT_T590, TEXT_T021]
const BRCR_SCR120 = [TEXT_T519, TEXT_T587, TEXT_T590, TEXT_T021, TEXT_T108]
const BRCR_SCR122 = [TEXT_T519, TEXT_T587, TEXT_T103, TEXT_T147, TEXT_T114]
const BRCR_SCR123 = [TEXT_T519, TEXT_T587, TEXT_T103, TEXT_T476, TEXT_T149]
const BRCR_SCR124 = [TEXT_T519, TEXT_T587, TEXT_T103, TEXT_T034, TEXT_T161]
const BRCR_SCR125 = [TEXT_T519, TEXT_T587, TEXT_T110, TEXT_T166]
const BRCR_SCR126 = [TEXT_T519, TEXT_T587, TEXT_T590, TEXT_T021, TEXT_T187]
const BRCR_SCR126_ADMIN = [
  TEXT_T519,
  TEXT_T587,
  TEXT_T590,
  TEXT_T021,
  TEXT_T611,
]

export const BreadcrumbsTravelers = {
  BRCR_SCR112,
  BRCR_SCR113,
  BRCR_SCR114,
  BRCR_SCR114_USER,
  BRCR_SCR114_USER_SEARCH,
  BRCR_SCR115,
  BRCR_SCR115_FROM_SCR114,
  BRCR_SCR115_USER,
  BRCR_SCR116,
  BRCR_SCR116_FROM_SCR113,
  BRCR_SCR116_FROM_SCR115,
  BRCR_SCR116_FROM_SCR115_USER,
  BRCR_SCR117,
  BRCR_SCR117_FROM_SCR113,
  BRCR_SCR117_FROM_SCR116,
  BRCR_SCR117_FROM_SCR116_USER,
  BRCR_SCR119_FROM_SCR115,
  BRCR_SCR119_FROM_SCR126,
  BRCR_SCR120,
  BRCR_SCR122,
  BRCR_SCR123,
  BRCR_SCR124,
  BRCR_SCR125,
  BRCR_SCR126,
  BRCR_SCR126_ADMIN,
}
