import {
  ColumnType,
  UseTableConfigType,
} from "../../../../../../../_webui/layout/components/Table/models"
import { Translate } from "../../../../../../../constant/Translate"
import { UploadFileType } from "../../../../model/filesUpload"

const { TEXT_T501, TEXT_T624 } = Translate

export const useTableConfig = (): UseTableConfigType<UploadFileType> => {
  const columns: ColumnType<UploadFileType>[] = [
    {
      dataIndex: "fileName",
      sorter: (a, b) => a.fileName.localeCompare(b.fileName),
      title: TEXT_T501,
      width: 500,
    },
    {
      dataIndex: "systemName",
      sorter: (a, b) => a.systemName.localeCompare(b.systemName),
      title: TEXT_T624,
      width: 500,
    },
  ]
  return {
    columns,
  }
}
