import { FC, Fragment } from "react"
import { Translate } from "../../../../../../../constant/Translate"
import { validatorHalfSize } from "../../../../../../../utils/validator/halfSize"
import { validatorRequiredField } from "../../../../../../../utils/validator/requiredField"
import { ItemInput } from "../../../../../../../_webui/layout/components/Form/Item/Input"
import { TravelersEditUrgentProps } from "./models"
import { requiredField } from "../../../../../../../utils/requiredField"

const { HELP_H0010, HELP_H0011, TEXT_T050, TEXT_T051, TEXT_T052, TEXT_T053 } =
  Translate

export const Urgent: FC<TravelersEditUrgentProps> = (props) => {
  const { isRequiredUrgentContact } = props

  const requiredItem = requiredField(isRequiredUrgentContact)

  return (
    <Fragment>
      <ItemInput
        label={TEXT_T050}
        maxLength={20}
        name="urgentContactHome"
        required
        rules={[validatorRequiredField, validatorHalfSize]}
        tooltip={HELP_H0010}
      />
      <ItemInput
        label={TEXT_T051}
        maxLength={100}
        name="urgentContactName"
        required
        rules={[validatorRequiredField]}
      />
      <Fragment>
        <ItemInput
          label={TEXT_T052}
          maxLength={20}
          name="urgentAffiliationContact"
          required={isRequiredUrgentContact}
          rules={[...requiredItem, validatorHalfSize]}
          tooltip={HELP_H0011}
        />
        <ItemInput
          label={TEXT_T053}
          maxLength={100}
          name="urgentAffiliationContactName"
          required={isRequiredUrgentContact}
          rules={requiredItem}
        />
      </Fragment>
    </Fragment>
  )
}
