/* eslint-disable max-statements */
/* eslint-disable complexity */
import { Breadcrumbs } from "../../../../../../../../constant/Breadcrumbs"
import { Routers } from "../../../../../../../../constant/Routers"

const { SCR210, SCR210_TRAVELERS } = Routers
const {
  BRCR_SCR217_FROM_SCR211_TRAVELERS,
  BRCR_SCR217_FROM_SCR211,
  BRCR_SCR217_FROM_SCR216_TRAVELERS,
  BRCR_SCR217_FROM_SCR216,
  BRCR_SCR217,
} = Breadcrumbs

export const getBreadcrumbItems = (
  isFromSCR211?: boolean,
  isFromSCR216?: boolean,
  schedulesURL?: string,
) => {
  if (isFromSCR216) {
    if (schedulesURL === SCR210) return BRCR_SCR217_FROM_SCR216

    if (schedulesURL === SCR210_TRAVELERS)
      return BRCR_SCR217_FROM_SCR216_TRAVELERS

    return BRCR_SCR217
  }

  if (isFromSCR211) {
    if (schedulesURL === SCR210) return BRCR_SCR217_FROM_SCR211

    if (schedulesURL === SCR210_TRAVELERS)
      return BRCR_SCR217_FROM_SCR211_TRAVELERS

    return BRCR_SCR217
  }

  return BRCR_SCR217
}
