import Excel from "exceljs"

export const cellConfig = (worksheet: Excel.Worksheet) => {
  worksheet.eachRow((sheetColumn, rowNumber) => {
    sheetColumn.eachCell((cell) => {
      cell.style = {
        alignment: {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        },
      }
      if (rowNumber === 1) {
        cell.fill = {
          fgColor: { argb: "99cc01" },
          pattern: "solid",
          type: "pattern",
        }
      }
      cell.border = {
        bottom: {
          color: {
            argb: "000000",
          },
          style: "thin",
        },
        left: {
          color: {
            argb: "000000",
          },
          style: "thin",
        },
        right: {
          color: {
            argb: "000000",
          },
          style: "thin",
        },
        top: {
          color: {
            argb: "000000",
          },
          style: "thin",
        },
      }
    })
  })
}
