import { FC } from "react"
import { Translate } from "../../../../../../constant/Translate"
import { ButtonSubmit } from "../../../../../../_webui/layout/components/Button/Submit"
import { Form } from "../../../../../../_webui/layout/components/Form"
import { Grid } from "../../../../../../_webui/layout/components/Grid"
import { Space } from "../../../../../../_webui/layout/components/Space"
import {
  SafetiesAnswerFormProps,
  SafetiesAnswerFormType,
} from "../../../models/answer"

const { TEXT_T072, TEXT_T013 } = Translate

export const SafetiesAnswerForm: FC<
  SafetiesAnswerFormProps<SafetiesAnswerFormType>
> = (props) => {
  const {
    form,
    gridItems,
    isHiddenSubmit,
    isLoading,
    isRoleAdmin,
    onFinish,
    onGoBack,
    safetyDetailInfo,
    titleHeading,
  } = props

  return (
    <Form
      form={form}
      heading={titleHeading}
      initialValues={safetyDetailInfo}
      isBackground
      isFullHeight
      isLoading={isLoading}
      layout="vertical"
      onFinish={onFinish}
    >
      <Space isSpaceTop isSpaceBottom>
        <Grid isForm gridItems={gridItems} />
        <ButtonSubmit
          isAbsoluteReset={!isHiddenSubmit}
          isDisabledSubmit={isRoleAdmin}
          isHiddenSubmit={isHiddenSubmit}
          isReset
          onClickReset={onGoBack}
          resetText={TEXT_T072}
          submitText={TEXT_T013}
          isFlex={isHiddenSubmit}
        />
      </Space>
    </Form>
  )
}
