import { Key, useState } from "react"
import { useTable } from "../../../../../../../utils/hook/useTable"

export const useRowSelection = (dataLength: number) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const { onChange: onChangePage, perItem } = useTable(dataLength)

  const onSelectChange = (newSelectedRowKeys: Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    onChange: onSelectChange,
    selectedRowKeys: selectedRowKeys,
  }

  const onChange = (page: number, pageSize: number) => {
    onChangePage(page, pageSize)
    setSelectedRowKeys([])
  }

  return {
    onChange,
    perItem,
    rowSelection,
    selectedRowKeys,
  }
}
