import { useState } from "react"

export const useModal = () => {
  const [isOpen, setIsOpen] = useState(false)
  const onCancel = () => {
    document.body.classList.remove("no-scroll")
    setIsOpen(false)
  }

  const onOpen = () => {
    document.body.classList.add("no-scroll")
    setIsOpen(true)
  }

  return {
    isOpen,
    onCancel,
    onOpen,
  }
}
