import { useAuthority } from "../../../../../../../utils/hook/useAuthority"

export const useVariablesAuthority = () => {
  const { isAttentionsSendAll, isCommonRefer } = useAuthority()

  return {
    isAttentionsSendAll,
    isCommonRefer,
  }
}
