import { Form } from "antd"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import {
  I18N_CONFIG_KEY,
  defaultLang,
  setLanguage,
} from "../../../../../../../_webui/i18n/OjicaI18n"
import { InitialStateProps } from "../../../../../../../_webui/i18n/OjicaI18n/models"
import { Environment } from "../../../../../../../constant/Environment"
import { Routers } from "../../../../../../../constant/Routers"
import { AxiosErrorType } from "../../../../../../../infrastructure/axiosError/models"
import { checkIsArray } from "../../../../../../../utils/checkIsArray"
import keycloak from "../../../../../../../utils/keycloak"
import { setFieldsErrors } from "../../../../../../../utils/setFieldsErrors"
import { translateMessage } from "../../../../../../../utils/translateMessage"
import { usersApi } from "../../../../apis"
import { RequiredFields } from "../../../../constant/resetPassword/requiredFields"
import {
  ResetPasswordFormDataType,
  UseFormResetPasswordType,
} from "../../../../models/resetPassword"

const { KEYCLOAK, URL } = Environment
const { RESET_PASSWORD_REQUIRED_FIELDS } = RequiredFields
const { SCR920 } = Routers

export const useResetPassword =
  (): UseFormResetPasswordType<ResetPasswordFormDataType> => {
    const searchParams = new URLSearchParams(useLocation().search)
    const [form] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingLayout, setIsLoadingLayout] = useState(true)
    const [messageError, setMessageError] = useState("")
    const [isDisabledSubmit, setIsDisabledSubmit] = useState(true)

    const onValuesChange = () => {
      const formValues = form.getFieldsValue(RESET_PASSWORD_REQUIRED_FIELDS)

      setTimeout(() => {
        const _isDisabledSubmit =
          checkIsArray(
            form.getFieldsError().filter(({ errors }) => errors.length),
          ) || checkIsArray(Object.values(formValues).filter((item) => !item))

        setIsDisabledSubmit(_isDisabledSubmit)
      }, 100)
    }

    const goBack = () => {
      keycloak.login({ redirectUri: `${URL}${SCR920}` })
    }

    const onFinish = (values: ResetPasswordFormDataType) => {
      setIsLoading(true)

      const resetPasswordInfo: ResetPasswordFormDataType = {
        ...values,
        keycloakClientId: KEYCLOAK.CLIENT_ID,
      }
      usersApi
        .resetPassword(resetPasswordInfo)
        .then(() => {
          keycloak.login({ redirectUri: `${URL}${SCR920}` })
        })
        .catch((error: AxiosErrorType) => {
          const dataError = error?.debug?.response?.data
          if (dataError?.entityName) {
            setFieldsErrors(error, form)
            setIsLoading(false)
            setMessageError("")
          } else if (dataError?.message) {
            setMessageError(translateMessage(`MESSAGE.${dataError.message}`))
          }

          setIsLoading(false)
        })
    }

    useEffect(() => {
      const ls = localStorage.getItem(I18N_CONFIG_KEY)
      let lang = ""

      if (ls) {
        const langProps = JSON.parse(ls) as InitialStateProps
        lang = langProps.selectedLang
      }

      if (searchParams.get("lang") !== lang)
        setLanguage(searchParams.get("lang") || defaultLang)
      else {
        setTimeout(() => {
          setIsLoadingLayout(false)
        }, 500)
      }
    }, [])

    return {
      form,
      goBack,
      isDisabledSubmit,
      isLoading,
      isLoadingLayout,
      messageError,
      onFinish,
      onValuesChange,
    }
  }
