/* eslint-disable max-statements */
import Excel from "exceljs"
import { Translate } from "../../../../../../../../../../../constant/Translate"

const { EXPORT_EP0203, EXPORT_EP0204, EXPORT_EP0205 } = Translate

export const cellConfig = (worksheet: Excel.Worksheet, sheetName: string) => {
  worksheet.eachRow((sheetColumn, rowNumber) => {
    // eslint-disable-next-line complexity
    sheetColumn.eachCell((cell) => {
      if (rowNumber < 3) {
        if (
          ["$L$1", "$L$2", "$N$1", "$N$2", "$O$1", "$O$2"].includes(
            cell.$col$row,
          )
        ) {
          cell.alignment = {
            horizontal: "right",
          }
        }
        cell.font = {
          size: 24,
        }
      }
      if (rowNumber > 5) {
        cell.alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        }
        cell.border = {
          bottom: {
            color: {
              argb: "000000",
            },
            style: "thin",
          },
          left: {
            color: {
              argb: "000000",
            },
            style: "thin",
          },
          right: {
            color: {
              argb: "000000",
            },
            style: "thin",
          },
          top: {
            color: {
              argb: "000000",
            },
            style: "thin",
          },
        }
        if (/^[FGHIKL]/.test(cell.address) && sheetName === EXPORT_EP0203) {
          cell.alignment = {
            horizontal: "left",
            vertical: "middle",
            wrapText: true,
          }
        }
        if (/^[FGHIJKLMNO]/.test(cell.address) && sheetName === EXPORT_EP0204) {
          cell.alignment = {
            horizontal: "left",
            vertical: "middle",
            wrapText: true,
          }
        }
        if (/^[FGHIJKLMN]/.test(cell.address) && sheetName === EXPORT_EP0205) {
          cell.alignment = {
            horizontal: "left",
            vertical: "middle",
            wrapText: true,
          }
        }
      }
      if (rowNumber === 6) {
        cell.fill = {
          fgColor: { argb: "99cc01" },
          pattern: "solid",
          type: "pattern",
        }
        cell.alignment = {
          horizontal: "left",
          vertical: "middle",
          wrapText: true,
        }
      }
    })
  })
}
