import { useState } from "react"
import { useHistory } from "react-router-dom"
import {
  TableErrorRowType,
  CountRecordType,
} from "../../../../../../../_webui/layout/components/Table/Error/models"
import { TravelersBatchDeleteDataType } from "../../../../models/batchDelete/data"
import { useTable } from "../../../../../../../utils/hook/useTable"

export const useVariablesApi = () => {
  const { push } = useHistory()
  const [dataTemps, setDataTemps] = useState<TravelersBatchDeleteDataType[]>([])
  const [errorList, setErrorList] = useState<TableErrorRowType[]>([])
  const [countRecord, setTotalReport] = useState<CountRecordType>()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(true)
  const [isResetTable, setIsResetTable] = useState(false)
  const [fileNameList, setFileNameList] = useState<string[]>([])
  const { onChange, perItem } = useTable(dataTemps?.length)

  return {
    countRecord,
    dataTemps,
    errorList,
    fileNameList,
    isDisabledSubmit,
    isLoading,
    isLoadingLayout,
    isResetTable,
    onChange,
    perItem,
    push,
    setDataTemps,
    setErrorList,
    setFileNameList,
    setIsDisabledSubmit,
    setIsLoading,
    setIsLoadingLayout,
    setIsResetTable,
    setTotalReport,
  }
}
