import { FC } from "react"
import { ButtonSubmit } from "../../../../../_webui/layout/components/Button/Submit"
import { Card } from "../../../../../_webui/layout/components/Card"
import { Center } from "../../../../../_webui/layout/components/Center"
import { Layout } from "../../../../../_webui/layout/components/Layout"
import { List } from "../../../../../_webui/layout/components/List"
import { RadioGroup } from "../../../../../_webui/layout/components/Radio/Group"
import { Title } from "../../../../../_webui/layout/components/Typography/Title"
import { Translate } from "../../../../../constant/Translate"
import { InitialValues } from "../../constant/rules/initialValues"
import { useRules } from "./modules/useRules"

const { RADIO_ITEMS_INITIAL_VALUES } = InitialValues
const { TEXT_T012, TEXT_T429, TEXT_T142, TEXT_T423, TEXT_T531 } = Translate

export const Rules: FC = () => {
  const {
    acquisitionInfo,
    goToChangePassword,
    goToLogin,
    isAccept,
    isHidden,
    isLoading,
    isLoadingLayout,
    onChange,
    valueAccept,
  } = useRules()

  return (
    <Layout
      isHeaderRightNone
      isLoadingLayout={isLoadingLayout}
      isSideBar={false}
      title={TEXT_T142}
    >
      <Card heading={TEXT_T142} isFullHeight isLoading={isLoading}>
        <Title titleLeft={TEXT_T423} />
        <List heading={TEXT_T531} listItems={acquisitionInfo} />
        <Center>
          <RadioGroup
            defaultValue={1}
            isHidden={!isHidden}
            radioItems={RADIO_ITEMS_INITIAL_VALUES}
            onChange={onChange}
            value={valueAccept}
          />
          <ButtonSubmit
            isDisabledSubmit={!isAccept}
            isHiddenReset={!isHidden}
            isHiddenSubmit={!isHidden}
            isReset
            onClickReset={goToLogin}
            onClickSubmit={goToChangePassword}
            resetText={TEXT_T012}
            submitHtmlType="button"
            submitText={TEXT_T429}
          />
        </Center>
      </Card>
    </Layout>
  )
}
