import { useEffect, useState } from "react"
import keycloak from "../../../../../../../utils/keycloak"
import { UseLogoutType } from "../../../../models/logout"
import { Routers } from "../../../../../../../constant/Routers"
import { Environment } from "../../../../../../../constant/Environment"
import { getConfig } from "../../../../../../../_webui/i18n/OjicaI18n"

const { URL } = Environment
const { SCR920 } = Routers

export const useLogout = (): UseLogoutType => {
  const [isLoadingLayout, setIsLoadingLayout] = useState(true)

  // 「SCR910_ログインの画面」に遷移する。
  const handleLogin = () => {
    keycloak.login({
      locale: `${getConfig().selectedLang}`,
      redirectUri: `${URL}${SCR920}`,
    })
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoadingLayout(false)
    }, 500)
  }, [])

  return {
    handleLogin,
    isLoadingLayout,
  }
}
