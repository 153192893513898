import { useHistory } from "react-router-dom"
import { useApi } from "../useApi"
import { useTableConfig } from "../useTableConfig"
import { useVariablesAuthority } from "../useVariablesAuthority"
import { useHandle } from "../../../../../settings/containers/files/list/modules/useHandle"
import { FilesType } from "../../../../../../../utils/downloadFiles/models"

export const useVariables = () => {
  const { push } = useHistory()
  // APIを呼び出し、APIからの応答結果を「戻り国情報」変数に設定する。
  const { columns } = useTableConfig()

  const {
    breadcrumbItems,
    deleteFiles,
    fileInfoList,
    getFiles,
    initDateTime,
    isDisabledBtnDownload,
    isLoading,
    isLoadingLayout,
    isOpen,
    layoutTitle,
    onCancel,
    onChangePagination,
    onChangeTable,
    onOpen,
    pathname,
    perItem,
    rowSelection,
    selectedRowKeys,
    type,
  } = useApi()

  const {
    isNotAccessScreen,
    isVisibleBtnDelete,
    isVisibleBtnReference,
    isVisibleBtnRegister,
  } = useVariablesAuthority(pathname)

  const _fileInfoList: FilesType[] = fileInfoList.map((item) => ({
    fileName: item.fileName,
    filePath: item.filePath,
    fileTypeName: item.fileTypeName,
    id: item.uploadId,
  }))
  const { handleDownload } = useHandle({
    fileInfoList: _fileInfoList,
    fileName: layoutTitle,
    initDateTime,
    selectedRowKeys,
  })

  return {
    breadcrumbItems,
    columns,
    deleteFiles,
    fileInfoList,
    getFiles,
    handleDownload,
    isDisabledBtnDownload,
    isLoading,
    isLoadingLayout,
    isNotAccessScreen,
    isOpen,
    isVisibleBtnDelete,
    isVisibleBtnReference,
    isVisibleBtnRegister,
    layoutTitle,
    onCancel,
    onChangePagination,
    onChangeTable,
    onOpen,
    pathname,
    perItem,
    push,
    rowSelection,
    type,
  }
}
