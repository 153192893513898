import { FC } from "react"
import { PreLineProps } from "./models"
import { Styled } from "./styled"

const { StyledPreLine } = Styled

export const PreLine: FC<PreLineProps> = (props) => {
  const { children, textAlign } = props

  return <StyledPreLine textAlign={textAlign}>{children}</StyledPreLine>
}
