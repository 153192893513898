import { FC } from "react"
import { Card } from "../Card"
import { FormAnt } from "./FormAnt"
import { FormProps } from "./models"

export const Form: FC<FormProps> = (props) => {
  const {
    children,
    className,
    classNameForm,
    form,
    heading,
    initialValues,
    isBackground,
    isCard = true,
    isHeading,
    isLoading,
    labelAlign,
    layout,
    name,
    onFinish,
    onFinishFailed,
    onValuesChange,
    searchIcon,
    type,
    ...rest
  } = props

  return isCard ? (
    <Card
      className={className}
      heading={heading}
      isBackground={isBackground}
      isHeading={isHeading}
      isLoading={isLoading}
      isSpin
      type={type}
      searchIcon={searchIcon}
      {...rest}
    >
      <FormAnt
        className={classNameForm}
        form={form}
        initialValues={initialValues}
        labelAlign={labelAlign}
        layout={layout}
        name={name}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
      >
        {children}
      </FormAnt>
    </Card>
  ) : (
    <FormAnt
      className={classNameForm}
      form={form}
      initialValues={initialValues}
      labelAlign={labelAlign}
      layout={layout}
      name={name}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      {...rest}
    >
      {children}
    </FormAnt>
  )
}
