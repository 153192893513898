import React, { FC, Fragment } from "react"
import { Translate } from "../../../../../../../constant/Translate"
import { ItemInput } from "../../../../../../../_webui/layout/components/Form/Item/Input"
import { validatorHalfSize } from "../../../../../../../utils/validator/halfSize"

const { TEXT_T058, TEXT_T059, TEXT_T060 } = Translate

export const CollapseAssignee: FC = () => (
  <Fragment>
    <ItemInput
      label={TEXT_T058}
      maxLength={100}
      name="assigneeInstitutionName"
    />
    <ItemInput
      label={TEXT_T059}
      maxLength={20}
      name="assigneePhoneNumber"
      rules={[validatorHalfSize]}
    />
    <ItemInput label={TEXT_T060} maxLength={100} name="assigneeName" />
  </Fragment>
)
