import { FC, Fragment } from "react"
import { Image } from "../../Image"
import { HeaderDropdownProps } from "./models"
import { Styled } from "./styled"

const { StyledHeaderActionArrow, StyledHeaderActionText } = Styled

export const HeaderDropdown: FC<HeaderDropdownProps> = (props) => {
  const { src, text } = props

  return (
    <Fragment>
      <Image src={src} />
      <StyledHeaderActionText className="header-action-text">
        {text}
      </StyledHeaderActionText>
      <StyledHeaderActionArrow className="header-action-arrow arrow arrow-down" />
    </Fragment>
  )
}
