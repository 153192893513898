/* eslint-disable max-lines */
/* eslint-disable max-statements */
import { SubFunctionId } from "../../../constant/SubFunctionId"
import keycloak from "../../keycloak"

const {
  ATTENTIONS_BROWSE_RESPONSE,
  ATTENTIONS_REPORT,
  ATTENTIONS_SEND_ALL,
  ATTENTIONS_TARGET_SEARCH,
  COMMON_EXTERNAL_SYSTEM_LINKAGE,
  COMMON_PERMISSION_SETTING,
  COMMON_PERMISSION_SETTINGS,
  COMMON_REFER,
  COMMON_TRAVELER_REFER,
  COMMON_UPLOAD_DELETE,
  COMMON_UPLOAD_REFER,
  COMMON_UPLOAD_REGISTER,
  ROLE_ADMIN,
  ROLE_SUBADMIN,
  ROLE_USER,
  SAFETIES_BROWSE_RESPONSE,
  SAFETIES_REPORT,
  SAFETIES_SEND_ALL,
  SAFETIES_TARGET_SEARCH,
  SCHEDULE_CHANGE,
  SCHEDULE_DELETE,
  SCHEDULE_REFER,
  SCHEDULE_REGISTER,
  SCHEDULE_REPORT,
  SCHEDULE_SEARCH,
  TRAVELER_CHANGE,
  TRAVELER_DELETE,
  TRAVELER_REFER,
  TRAVELER_REGISTER,
  TRAVELER_REPORT,
  TRAVELER_SEARCH,
} = SubFunctionId

export const useAuthority = () => {
  const authorities: string[] = keycloak.tokenParsed?.authorities || []

  const isAttentionsBrowseResponse = authorities.includes(
    ATTENTIONS_BROWSE_RESPONSE,
  )
  const isAttentionsReport = authorities.includes(ATTENTIONS_REPORT)
  const isAttentionsSendAll = authorities.includes(ATTENTIONS_SEND_ALL)
  const isAttentionsTargetSearch = authorities.includes(
    ATTENTIONS_TARGET_SEARCH,
  )
  const isCommonExternalSystemLinkage = authorities.includes(
    COMMON_EXTERNAL_SYSTEM_LINKAGE,
  )
  const isCommonPermissionSetting = authorities.includes(
    COMMON_PERMISSION_SETTING,
  )
  const isCommonPermissionSettings = authorities.includes(
    COMMON_PERMISSION_SETTINGS,
  )
  const isCommonRefer = authorities.includes(COMMON_REFER)
  const isCommonTravelerRefer = authorities.includes(COMMON_TRAVELER_REFER)
  const isCommonUploadDelete = authorities.includes(COMMON_UPLOAD_DELETE)
  const isCommonUploadRefer = authorities.includes(COMMON_UPLOAD_REFER)
  const isCommonUploadRegister = authorities.includes(COMMON_UPLOAD_REGISTER)
  const isRoleAdmin = authorities.includes(ROLE_ADMIN)
  const isRoleSubAdmin = authorities.includes(ROLE_SUBADMIN)
  const isRoleUser = authorities.includes(ROLE_USER)
  const isSafetiesBrowseResponse = authorities.includes(
    SAFETIES_BROWSE_RESPONSE,
  )
  const isSafetiesReport = authorities.includes(SAFETIES_REPORT)
  const isSafetiesSendAll = authorities.includes(SAFETIES_SEND_ALL)
  const isSafetiesTargetSearch = authorities.includes(SAFETIES_TARGET_SEARCH)
  const isScheduleChange = authorities.includes(SCHEDULE_CHANGE)
  const isScheduleDelete = authorities.includes(SCHEDULE_DELETE)
  const isScheduleRefer = authorities.includes(SCHEDULE_REFER)
  const isScheduleRegister = authorities.includes(SCHEDULE_REGISTER)
  const isScheduleReport = authorities.includes(SCHEDULE_REPORT)
  const isScheduleSearch = authorities.includes(SCHEDULE_SEARCH)
  const isTravelerChange = authorities.includes(TRAVELER_CHANGE)
  const isTravelerDelete = authorities.includes(TRAVELER_DELETE)
  const isTravelerRefer = authorities.includes(TRAVELER_REFER)
  const isTravelerRegister = authorities.includes(TRAVELER_REGISTER)
  const isTravelerReport = authorities.includes(TRAVELER_REPORT)
  const isTravelerSearch = authorities.includes(TRAVELER_SEARCH)

  return {
    authorities,
    isAttentionsBrowseResponse,
    isAttentionsReport,
    isAttentionsSendAll,
    isAttentionsTargetSearch,
    isCommonExternalSystemLinkage,
    isCommonPermissionSetting,
    isCommonPermissionSettings,
    isCommonRefer,
    isCommonTravelerRefer,
    isCommonUploadDelete,
    isCommonUploadRefer,
    isCommonUploadRegister,
    isRoleAdmin,
    isRoleSubAdmin,
    isRoleUser,
    isSafetiesBrowseResponse,
    isSafetiesReport,
    isSafetiesSendAll,
    isSafetiesTargetSearch,
    isScheduleChange,
    isScheduleDelete,
    isScheduleRefer,
    isScheduleRegister,
    isScheduleReport,
    isScheduleSearch,
    isTravelerChange,
    isTravelerDelete,
    isTravelerRefer,
    isTravelerRegister,
    isTravelerReport,
    isTravelerSearch,
  }
}
